import { singleTextEllipsis } from '@byecode/ui'
import type { Field, HighLightConditions, NumberValue } from '@lighthouse/core'
import { mergeRefs, nanoid } from '@lighthouse/tools'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { CellHighlighter } from '../Highlighter'
import { TooltipList, useWidth } from '../TooltipList'
import { TooltipText } from '../TooltipText'

// import type { FieldValuePreviewerProps } from './types'
interface IdValuePreviewerProps {
    field: Field
    data?: NumberValue | NumberValue[]
    isWrap?: boolean
    width?: number
    isBold?: boolean
    color?: string
    styleConditions?: HighLightConditions
}

interface IdItemPreviewerProps {
    id: string
    data: NumberValue
    isWrap: boolean
    isBold?: boolean
    isLast?: boolean
    color?: string
    disableTooltip?: boolean
}

interface IdListPreviewerProps {
    data: NumberValue[]
    isWrap: boolean
    width: number
    isBold?: boolean
}

const SCxText = styled.div<{ isWrap?: boolean; isBold?: boolean; color?: string }>`
    width: 100%;
    white-space: ${({ isWrap }) => (isWrap ? 'pre-wrap' : 'nowrap')};
    font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
    color: ${({ color }) => color || 'var(--color-black)'};
    overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'hidden')};
    text-overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'ellipsis')};
`

const SCxItem = styled.div<{ isLast?: boolean }>`
    display: flex;
    align-items: center;
    ${({ isLast }) => isLast && singleTextEllipsis}
`

const SCxSplit = styled.div`
    display: flex;
`

const SCxIdListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean }>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    overflow: hidden;
`

export const IdItemPreviewer = React.forwardRef<HTMLDivElement, IdItemPreviewerProps>(
    ({ id, data = '', isWrap, isBold, isLast, color, disableTooltip }, ref) => {
        const { ref: widthRef } = useWidth<HTMLDivElement>(id)
        return (
            <TooltipText
                title={data}
                disabled={disableTooltip || isWrap}
                render={ref => {
                    return (
                        <SCxItem ref={mergeRefs([ref, widthRef])} isLast={isLast}>
                            <SCxText isWrap={isWrap} isBold={isBold} color={color}>
                                {data}
                            </SCxText>
                        </SCxItem>
                    )
                }}
            />
        )
    }
)

export const IdListPreviewer: React.FC<IdListPreviewerProps> = ({ data, width, isWrap, isBold }) => {
    return (
        <TooltipList
            data={data.map((label, index) => ({ label, value: String(index) }))}
            width={width}
            key={data.join('-')}
            disabled={isWrap}
            render={visibleNum => {
                const renderList = data.slice(0, visibleNum)

                return (
                    <SCxIdListWrapper isPadding={visibleNum < data.length} isWrap={isWrap}>
                        {renderList.map((item, index) => (
                            <SCxSplit key={index}>
                                <IdItemPreviewer
                                    id={String(index)}
                                    isBold={isBold}
                                    data={item}
                                    isWrap={isWrap}
                                    disableTooltip
                                    isLast={index === renderList.length - 1}
                                />
                                {index !== renderList.length - 1 && ','}
                            </SCxSplit>
                        ))}
                    </SCxIdListWrapper>
                )
            }}
        />
    )
}

export const IdValuePreviewer: React.FC<IdValuePreviewerProps> = ({
    field,
    data = '',
    isWrap = true,
    width = 160,
    color,
    isBold,
    styleConditions
}) => {
    const { id, type } = field

    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (Array.isArray(data)) {
            return <IdListPreviewer data={data} isWrap={isWrap} width={width} isBold={isBold} />
        }
        const id = nanoid()
        return <IdItemPreviewer id={id} color={color} isWrap={isWrap} data={data} isBold={isBold} isLast />
    }, [color, data, isBold, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={id} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
