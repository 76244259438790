import { pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    font-size: var(--font-size-normal);
    border-radius: inherit;

    > * {
        background-color: transparent;
    }
`

export const Wrapper = styled.div`
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 100%;
    min-height: 38px;
`

export const TagWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    gap: 4px;
`
