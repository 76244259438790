/* eslint-disable etc/no-misused-generics */
/* eslint-disable @typescript-eslint/no-explicit-any */
type PolymorphicProps<C extends React.ElementType, P> = P & Omit<React.ComponentPropsWithoutRef<C>, keyof P>
type PolymorphicRef<C> = C extends React.ElementType ? React.ComponentPropsWithRef<C>['ref'] : never

type PolymorphicComponentProps<C, P> = C extends React.ElementType
    ? PolymorphicProps<C, P & { component?: C } & { ref?: PolymorphicRef<C> }>
    : P & { component: React.ElementType }

/**
 * @beta
 * @param component
 */
export function createPolymorphicComponent<DefaultComponent, Props>(component: any) {
    type ComponentProps<C> = PolymorphicComponentProps<C, Props>

    type PolymorphicComponent = <C = DefaultComponent>(props: ComponentProps<C>) => React.ReactElement
    type ComponentProperties = Omit<React.FC<ComponentProps<any>>, never>

    return component as PolymorphicComponent & ComponentProperties
}
