import { Empty } from '@byecode/ui'
import type {
    AiFieldStatus,
    ButtonAction,
    DataSourceAbstract,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SelectedMode,
    ViewBlockAbstract
} from '@lighthouse/core'
import { ARRANGEMENT, BlockSize, DIRECTION, GalleryCardStyle } from '@lighthouse/core'
import { conversionHighLightRecord, EmptyNoRecordSvg, getViewColumns } from '@lighthouse/shared'
import { BreakPointSize } from '@lighthouse/tools'
import type { atomWithImmer } from 'jotai-immer'
import type { FC, ReactNode } from 'react'
import React, { memo, useCallback, useEffect, useMemo } from 'react'

import { HorizontalScrollView } from './HorizontalScrollView'
import { VerticalView } from './VerticalView'

interface GalleryBlockProps {
    scale?: number
    blockData: ViewBlockAbstract
    dataSource: DataSourceAbstract
    records?: RecordLikeProtocol[]
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    selectedRecords: string[]
    breakPoint: BreakPointSize
    blockWidth?: number
    onSelectedRecords: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onRecordEdit: (recordId: string) => void
    renderItem?: (record: RecordLikeProtocol) => ReactNode
    onRecordDelete: (dsId: string, ids: string[], selectedMode?: SelectedMode) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (params: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (params: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
    onLoadMoreData?: (pageNum: number) => Promise<RecordLikeProtocol[]>
}

/** 画廊Block */
const GalleryBlock: FC<GalleryBlockProps> = props => {
    const {
        scale,
        blockData,
        dataSource,
        records,
        aiFieldStatusListAtom,
        selectedRecords,
        breakPoint,
        blockWidth,
        onSelectedRecords,
        onRecordClick,
        renderItem,
        onRecordDelete,
        onRecordEdit,
        onAiGeneration,
        onRecordOperatorActionTrigger,
        onRecordClickedActionTrigger,
        onRenderButtonTitle,
        onLoadMoreData
    } = props
    const {
        schema,
        viewOptions: { tableProps }
    } = dataSource
    const { id, title, config, appId } = blockData
    const isEmptyImg = blockWidth && blockWidth >= 200
    const {
        pointer,
        pagination,
        headCover,
        headAvatar,
        headTitle,
        headTags,
        contentTags,
        footTags,
        style = GalleryCardStyle.block,
        arrangement,
        cols,
        canDeleteRecord = false,
        canExport,
        canPrint,
        canViewRecord,
        highLightRules = [],
        size = BlockSize.md,
        viewFieldSettings,
        canViewEdit = false,
        actions,
        direction = DIRECTION.vertical
    } = config
    const noData = !pointer
    const galleryItemCheckable = !!canDeleteRecord || !!canExport || !!canPrint
    const isHorizontal = direction === DIRECTION.horizontal

    useEffect(() => {
        if (!canDeleteRecord) {
            onSelectedRecords([])
        }
    }, [canDeleteRecord, onSelectedRecords])

    // block 设置后的tableProps
    const settingColumns = useMemo(
        () =>
            getViewColumns({
                blockId: id,
                tableProps,
                value: viewFieldSettings,
                schema
            }),
        [id, schema, tableProps, viewFieldSettings]
    )

    const autoCols = useMemo(() => {
        const xLargeSize = new Map([
            [BreakPointSize.lg, 2],
            [BreakPointSize.md, 1],
            [BreakPointSize.sm, 1],
            [BreakPointSize.xs, 1]
        ])
        const largeSize = new Map([
            [BreakPointSize.lg, 3],
            [BreakPointSize.md, 2],
            [BreakPointSize.sm, 1],
            [BreakPointSize.xs, 1]
        ])
        const middleSize = new Map([
            [BreakPointSize.lg, 4],
            [BreakPointSize.md, 3],
            [BreakPointSize.sm, 2],
            [BreakPointSize.xs, 1]
        ])
        const smallSize = new Map([
            [BreakPointSize.lg, 5],
            [BreakPointSize.md, 4],
            [BreakPointSize.sm, 3],
            [BreakPointSize.xs, 2]
        ])

        const sizeMap = new Map([
            [BlockSize.xl, xLargeSize],
            [BlockSize.lg, largeSize],
            [BlockSize.md, middleSize],
            [BlockSize.sm, smallSize]
        ])

        return sizeMap.get(size)?.get(breakPoint) ?? 3
    }, [breakPoint, size])

    const { row: rowStyleConditions } = useMemo(() => conversionHighLightRecord(highLightRules), [highLightRules])

    const isMobile = breakPoint === BreakPointSize.xs
    const finalCols =
        arrangement === ARRANGEMENT.fixed && cols ? (isMobile ? Math.min(2, cols) : cols) : isHorizontal ? autoCols + 0.3 : autoCols

    const handleRecordOperatorDelete = useCallback(
        async (dsId: string, ids: string[]) => {
            const isDelete = await onRecordDelete?.(dsId, ids)
            const newSelectedRecords = selectedRecords.reduce<string[]>((prev, cur) => {
                if (!ids.includes(cur)) {
                    prev.push(cur)
                }
                return prev
            }, [])
            onSelectedRecords(newSelectedRecords)
            return !!isDelete
        },
        [onRecordDelete, onSelectedRecords, selectedRecords]
    )

    const handleSelectRecord = useCallback(
        (checked: boolean, id: string) => {
            if (!selectedRecords) {
                return
            }
            if (checked) {
                onSelectedRecords?.([...selectedRecords, id])
            } else {
                onSelectedRecords?.(selectedRecords.filter(rId => rId !== id))
            }
        },
        [onSelectedRecords, selectedRecords]
    )

    return useMemo(() => {
        if (noData || !records || records?.length === 0) {
            return (
                <Empty
                    styles={{ root: { padding: '40px 0' } }}
                    icon={isEmptyImg ? <EmptyNoRecordSvg color="var(--color-app-main)" /> : undefined}
                    description="未找到数据"
                />
            )
        }

        if (isHorizontal) {
            return (
                <HorizontalScrollView
                    blockId={id}
                    key="horizontal"
                    scale={scale}
                    cols={finalCols}
                    pagination={pagination}
                    records={records}
                    canViewRecord={canViewRecord}
                    headCover={headCover}
                    schema={schema}
                    columns={settingColumns}
                    recordEditOpenable={canViewEdit}
                    recordDeleteAble={canDeleteRecord}
                    rowStyleConditions={rowStyleConditions}
                    selectedRecords={selectedRecords}
                    galleryItemCheckable={galleryItemCheckable}
                    headAvatar={headAvatar}
                    headTitle={headTitle}
                    headTags={headTags}
                    contentTags={contentTags}
                    footTags={footTags}
                    recordStyle={style}
                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                    actions={actions}
                    renderItem={renderItem}
                    onClickItem={onRecordClick}
                    onRecordEdit={onRecordEdit}
                    onRecordDelete={handleRecordOperatorDelete}
                    onSelectRecord={handleSelectRecord}
                    onAiGeneration={onAiGeneration}
                    onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                    onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                    onRenderButtonTitle={onRenderButtonTitle}
                    onLoadMoreData={onLoadMoreData}
                />
            )
        }

        return (
            <VerticalView
                blockId={id}
                key="vertical"
                cols={finalCols}
                records={records}
                canViewRecord={canViewRecord}
                headCover={headCover}
                schema={schema}
                columns={settingColumns}
                recordEditOpenable={canViewEdit}
                recordDeleteAble={canDeleteRecord}
                rowStyleConditions={rowStyleConditions}
                selectedRecords={selectedRecords}
                galleryItemCheckable={galleryItemCheckable}
                headAvatar={headAvatar}
                headTitle={headTitle}
                headTags={headTags}
                contentTags={contentTags}
                footTags={footTags}
                recordStyle={style}
                aiFieldStatusListAtom={aiFieldStatusListAtom}
                actions={actions}
                renderItem={renderItem}
                onClickItem={onRecordClick}
                onRecordEdit={onRecordEdit}
                onRecordDelete={handleRecordOperatorDelete}
                onSelectRecord={handleSelectRecord}
                onAiGeneration={onAiGeneration}
                onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                onRenderButtonTitle={onRenderButtonTitle}
            />
        )
    }, [
        noData,
        records,
        isHorizontal,
        id,
        finalCols,
        canViewRecord,
        headCover,
        schema,
        settingColumns,
        canViewEdit,
        canDeleteRecord,
        rowStyleConditions,
        selectedRecords,
        galleryItemCheckable,
        headAvatar,
        headTitle,
        headTags,
        contentTags,
        footTags,
        style,
        aiFieldStatusListAtom,
        actions,
        renderItem,
        onRecordClick,
        onRecordEdit,
        handleRecordOperatorDelete,
        handleSelectRecord,
        onAiGeneration,
        onRecordOperatorActionTrigger,
        onRecordClickedActionTrigger,
        onRenderButtonTitle,
        isEmptyImg,
        scale,
        pagination,
        onLoadMoreData
    ])
}

export default memo(GalleryBlock)
