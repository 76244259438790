import { IconFont } from '@byecode/ui'
import { lightFormat } from 'date-fns'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface StatusBarProps {
    color: string
    backgroundColor: string
}
const SCxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 56px;
    padding: 0 36px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-normal);
`

const SCxIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
`

const SCxSmartIsLand = styled.div`
    width: 100%;
    z-index: 45;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    > svg {
        width: 100%;
    }
`

const SCxDate = styled.div``

export const StatusBar: React.FunctionComponent<StatusBarProps> = ({ color, backgroundColor}) => {
    // const headerStyles = useAppNavbarStylesContext()
    // const { color, backgroundColor } = headerStyles
    const [date, setDate] = useState(Date.now())

    useEffect(() => {
        const timer = setInterval(() => {
            setDate(Date.now())
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [])

    return (
        <SCxContainer style={{ color, backgroundColor }}>
            <SCxDate>{lightFormat(date, 'HH:mm')}</SCxDate>
            <SCxSmartIsLand>
                <svg width="393" height="54" viewBox="0 0 393 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <text
                        fontFamily="-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif"
                        fontSize="17px"
                        fontWeight="600"
                        x="52"
                        y="36"
                    >
                        {/* 4:21 */}
                    </text>
                    <rect x="134" y="11" width="124" height="36" rx="18" fill="black" />
                    <g>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M300.824 23.6514H299.647C298.997 23.6514 298.471 24.1521 298.471 24.7697V34.4621C298.471 35.0798 298.997 35.5805 299.647 35.5805H300.824C301.473 35.5805 302 35.0798 302 34.4621V24.7697C302 24.1521 301.473 23.6514 300.824 23.6514ZM294.157 26.2617H295.334C295.984 26.2617 296.51 26.7624 296.51 27.3801V34.463C296.51 35.0806 295.984 35.5813 295.334 35.5813H294.157C293.508 35.5813 292.981 35.0806 292.981 34.463V27.3801C292.981 26.7624 293.508 26.2617 294.157 26.2617ZM289.843 28.8706H288.667C288.017 28.8706 287.49 29.3713 287.49 29.989V34.4624C287.49 35.08 288.017 35.5807 288.667 35.5807H289.843C290.493 35.5807 291.02 35.08 291.02 34.4624V29.989C291.02 29.3713 290.493 28.8706 289.843 28.8706ZM284.353 31.1074H283.176C282.527 31.1074 282 31.6081 282 32.2258V34.4625C282 35.0801 282.527 35.5808 283.176 35.5808H284.353C285.003 35.5808 285.529 35.0801 285.529 34.4625V32.2258C285.529 31.6081 285.003 31.1074 284.353 31.1074Z"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M317.229 25.4616C319.748 25.4618 322.17 26.4294 323.995 28.1645C324.132 28.2985 324.352 28.2968 324.487 28.1608L325.801 26.8349C325.869 26.7659 325.908 26.6724 325.907 26.5751C325.907 26.4779 325.867 26.3849 325.798 26.3166C321.008 21.7258 313.45 21.7258 308.66 26.3166C308.591 26.3848 308.551 26.4778 308.551 26.5751C308.55 26.6723 308.588 26.7658 308.657 26.8349L309.971 28.1608C310.106 28.297 310.326 28.2987 310.463 28.1645C312.288 26.4293 314.711 25.4616 317.229 25.4616ZM317.229 29.7749C318.613 29.7748 319.947 30.2891 320.973 31.2179C321.111 31.3497 321.33 31.3468 321.465 31.2114L322.777 29.8855C322.846 29.816 322.885 29.7216 322.884 29.6236C322.883 29.5256 322.842 29.432 322.772 29.3639C319.649 26.4586 314.812 26.4586 311.689 29.3639C311.618 29.432 311.578 29.5256 311.577 29.6237C311.576 29.7218 311.615 29.8161 311.684 29.8855L312.996 31.2114C313.131 31.3468 313.349 31.3497 313.488 31.2179C314.513 30.2897 315.846 29.7755 317.229 29.7749ZM319.857 32.6774C319.859 32.7757 319.821 32.8705 319.751 32.9394L317.481 35.2301C317.414 35.2974 317.323 35.3353 317.229 35.3353C317.134 35.3353 317.043 35.2974 316.977 35.2301L314.707 32.9394C314.636 32.8704 314.598 32.7756 314.6 32.6773C314.602 32.579 314.645 32.4859 314.718 32.42C316.167 31.1939 318.29 31.1939 319.74 32.42C319.813 32.4859 319.855 32.5791 319.857 32.6774Z"
                        />
                        <path
                            opacity="0.4"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M337.5 23.6514H353.501C355.434 23.6514 357.001 25.2184 357.001 27.1514V31.1514C357.001 33.0844 355.434 34.6514 353.501 34.6514H337.5C335.567 34.6514 334 33.0844 334 31.1514V27.1514C334 25.2184 335.567 23.6514 337.5 23.6514ZM333 27.1514C333 24.6661 335.015 22.6514 337.5 22.6514H353.501C355.987 22.6514 358.001 24.6661 358.001 27.1514V31.1514C358.001 33.6366 355.987 35.6514 353.501 35.6514H337.5C335.015 35.6514 333 33.6366 333 31.1514V27.1514ZM360.517 29.1985C360.517 30.2074 359.913 31.1179 358.983 31.5094V26.8877C359.913 27.2791 360.517 28.1897 360.517 29.1985Z"
                        />
                        <path d="M335 27.1514C335 25.7707 336.119 24.6514 337.5 24.6514H353.501C354.882 24.6514 356.001 25.7707 356.001 27.1514V31.1514C356.001 32.5321 354.882 33.6514 353.501 33.6514H337.5C336.119 33.6514 335 32.5321 335 31.1514V27.1514Z" />
                    </g>
                </svg>
            </SCxSmartIsLand>
            <SCxIconWrapper>
                <IconFont color={color} size={16} type="MobileSignal" />
                <IconFont color={color} size={16} type="Wifi" />
                <IconFont color={color} size={16} type="Battery" />
            </SCxIconWrapper>
        </SCxContainer>
    )
}
