import type { FlowNode } from '@lighthouse/shared'
import { waitFor } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

export const handleGoBack = async (node: FlowNode, excParams: AnyObject) => {
    const { actionEvents } = excParams

    if (actionEvents) {
        const { closePage } = actionEvents

        closePage()

        await waitFor(300)
    }

    return {
        success: true
    }
}
