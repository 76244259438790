// 纹理相关配置
// import { getAssetUrl } from '@lighthouse/assets'
import AngledDotsCircleRaw from '@lighthouse/assets/public/static/veins/Angled Dots (Circle).svg?raw'
import AngledDotsCircleThumbRaw from '@lighthouse/assets/public/static/veins/Angled Dots (Circle)_Thumb.svg?raw'
import AngledDotsPlusSignRaw from '@lighthouse/assets/public/static/veins/Angled Dots (Plus Sign).svg?raw'
import AngledDotsPlusSignThumbRaw from '@lighthouse/assets/public/static/veins/Angled Dots (Plus Sign)_Thumb.svg?raw'
import AngledDotsSquareRaw from '@lighthouse/assets/public/static/veins/Angled Dots (Square).svg?raw'
import AngledDotsSquareThumbRaw from '@lighthouse/assets/public/static/veins/Angled Dots (Square)_Thumb.svg?raw'
import BokehRaw from '@lighthouse/assets/public/static/veins/Bokeh.svg?raw'
import BokehThumbRaw from '@lighthouse/assets/public/static/veins/Bokeh_Thumb.svg?raw'
import BricksRaw from '@lighthouse/assets/public/static/veins/Bricks.svg?raw'
import BricksThumbRaw from '@lighthouse/assets/public/static/veins/Bricks_Thumb.svg?raw'
import CheckerRaw from '@lighthouse/assets/public/static/veins/Checker.svg?raw'
import CheckerThumbRaw from '@lighthouse/assets/public/static/veins/Checker_Thumb.svg?raw'
import ChevronsRaw from '@lighthouse/assets/public/static/veins/Chevrons.svg?raw'
import ChevronsThumbRaw from '@lighthouse/assets/public/static/veins/Chevrons_Thumb.svg?raw'
import ConfettiRaw from '@lighthouse/assets/public/static/veins/Confetti.svg?raw'
import ConfettiThumbRaw from '@lighthouse/assets/public/static/veins/Confetti_Thumb.svg?raw'
import { Enum } from '@lighthouse/tools'

// const AngledDotsCircleRaw = getAssetUrl('veins','Angled Dots (Circle).svg')
// const AngledDotsCircleThumbRaw = getAssetUrl('veins','Angled Dots (Circle)_Thumb.svg')
// const AngledDotsPlusSignRaw = getAssetUrl('veins','Angled Dots (Plus Sign).svg')
// const AngledDotsPlusSignThumbRaw = getAssetUrl('veins','Angled Dots (Plus Sign)_Thumb.svg')
// const AngledDotsSquareRaw = getAssetUrl('veins','Angled Dots (Square).svg')
// const AngledDotsSquareThumbRaw = getAssetUrl('veins','Angled Dots (Square)_Thumb.svg')
// const BokehRaw = getAssetUrl('veins','Bokeh.svg')
// const BokehThumbRaw = getAssetUrl('veins','Bokeh_Thumb.svg')
// const BricksRaw = getAssetUrl('veins','Bricks.svg')
// const BricksThumbRaw = getAssetUrl('veins','Bricks_Thumb.svg')
// const CheckerRaw = getAssetUrl('veins','Checker.svg')
// const CheckerThumbRaw = getAssetUrl('veins','Checker_Thumb.svg')
// const ChevronsRaw = getAssetUrl('veins','Chevrons.svg')
// const ChevronsThumbRaw = getAssetUrl('veins','Chevrons_Thumb.svg')
// const ConfettiRaw = getAssetUrl('veins','Confetti.svg')
// const ConfettiThumbRaw = getAssetUrl('veins','Confetti_Thumb.svg')

export const VEINS_TYPE = Enum(
    'Angled Dots (Circle)',
    'Angled Dots (Plus Sign)',
    'Angled Dots (Square)',
    'Bokeh',
    'Bricks',
    'Checker',
    'Chevrons',
    'Confetti'
)
export type VeinsType = Enum<typeof VEINS_TYPE>
export const VEINS_OPTIONS = [
    {
        label: 'Angled Dots (Circle)',
        value: 'Angled Dots (Circle)',
        raw: AngledDotsCircleRaw,
        thumb: AngledDotsCircleThumbRaw,
        strokeWidth: true,
        size: true
    },
    {
        label: 'Angled Dots (Plus Sign)',
        value: 'Angled Dots (Plus Sign)',
        raw: AngledDotsPlusSignRaw,
        thumb: AngledDotsPlusSignThumbRaw,
        strokeWidth: true,
        size: true
    },
    {
        label: 'Angled Dots (Square)',
        value: 'Angled Dots (Square)',
        raw: AngledDotsSquareRaw,
        thumb: AngledDotsSquareThumbRaw,
        strokeWidth: true,
        size: true
    },
    {
        label: 'Bokeh',
        value: 'Bokeh',
        raw: BokehRaw,
        thumb: BokehThumbRaw,
        strokeWidth: false,
        size: true
    },
    {
        label: 'Bricks',
        value: 'Bricks',
        raw: BricksRaw,
        thumb: BricksThumbRaw,
        strokeWidth: true,
        size: true
    },
    {
        label: 'Checker',
        value: 'Checker',
        raw: CheckerRaw,
        thumb: CheckerThumbRaw,
        strokeWidth: false,
        size: false
    },
    {
        label: 'Chevrons',
        value: 'Chevrons',
        raw: ChevronsRaw,
        thumb: ChevronsThumbRaw,
        strokeWidth: true,
        size: true
    },
    {
        label: 'Confetti',
        value: 'Confetti',
        raw: ConfettiRaw,
        thumb: ConfettiThumbRaw,
        strokeWidth: false,
        size: true
    }
]
