import { IconFont } from '@byecode/ui'
import type { FieldProtocol, HighLightConditions, RichTextContentProtocol } from '@lighthouse/core'
import React from 'react'
import styled from 'styled-components'

import { CellHighlighter } from '../Highlighter'
import { generateText, isEmptyRichTextValue } from '../RichText/helper'

interface NotesValuePreviewerProps {
    field: FieldProtocol
    data?: RichTextContentProtocol
    isWrap?: boolean
    styleConditions?: HighLightConditions
}

const SCxNotes = styled.div<{ isWrap: boolean }>`
    display: flex;
    position: relative;
    color: var(--color-black);
    white-space: ${({ isWrap }) => (isWrap ? 'normal' : 'nowrap')};
    overflow: hidden;
`

const SCxNotesIcon = styled(IconFont)`
    font-size: var(--font-size-lg);
`

export const NotesValuePreviewer: React.FC<NotesValuePreviewerProps> = ({ field, data, isWrap = true, styleConditions }) => {
    const { id } = field

    if (Array.isArray(data) || typeof data === 'string') {
        return null
    }
    const isEmptyNotes = !data || isEmptyRichTextValue(data)
    const parsedValue = generateText(data)
    const notesIcon = isEmptyNotes ? 'PropertyNoteBlank' : 'PropertyNote'
    const notesIconColor = isEmptyNotes ? 'var(--color-gray-400)' : 'var(--color-gray-500)'

    return (
        <>
            <CellHighlighter fieldId={id} type="notes" value={parsedValue} conditions={styleConditions} />
            <SCxNotes isWrap={isWrap}>
                <SCxNotesIcon type={notesIcon} color={notesIconColor} />
            </SCxNotes>
        </>
    )
}
