import { Carousel } from '@mantine/carousel'
import { usePinch } from '@use-gesture/react'
import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useImmer } from 'use-immer'

import { FileControl } from '../FileControl'
import type { FileItemProps } from '../types'
import { AudioFile } from './Audio'
import { NotSupportFile } from './NotSupportFile'
import { VideoFile } from './Video'

const ImageFile = React.lazy(() => import('./Image').then(module => ({ default: module.ImageFile })))
const PdfFile = React.lazy(() => import('./Pdf').then(module => ({ default: module.PdfFile })))

// 初始形状
const INIT_SCALE = 100
const INIT_ROTATE = 0
// 限制
const MAX_SCALE = 300
const MIN_SCALE = 30
// 增量
const SCALE_STEP = 10
const ROTATE_STEP = 90

const ALLOW_SCALE = new Set(['image', 'doc', 'pdf', 'excel', 'ppt'])
const ALLOW_ROTATE = new Set(['image'])

interface Props extends FileItemProps {
    active: boolean
    disabledSlider?: boolean
}

export const FileItem = ({ file, active, disabledSlider, prefix, showPdfThumbs, onlyPdfSinglePage }: Props) => {
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        if (active) {
            setMounted(true)
        }
    }, [active])

    const [transform, setTransform] = useImmer({
        scale: INIT_SCALE,
        rotate: INIT_ROTATE
    })

    const onZoomIn = useCallback(() => {
        setTransform(draft => {
            if (draft.scale <= MIN_SCALE) {
                return
            }
            draft.scale -= SCALE_STEP
        })
    }, [setTransform])

    const onZoomOut = useCallback(() => {
        setTransform(draft => {
            if (draft.scale >= MAX_SCALE) {
                return
            }
            draft.scale += SCALE_STEP
        })
    }, [setTransform])

    const onRotate = useCallback(() => {
        setTransform(draft => {
            draft.rotate = (draft.rotate + ROTATE_STEP) % 360
        })
    }, [setTransform])

    // const onDownload = useCallback(() => {
    //     download(file)
    // }, [file])

    const content = useMemo(() => {
        if (!mounted || !file?.url) {
            return null
        }
        switch (file.type) {
            case 'image': {
                return (
                    <Suspense fallback={null}>
                        <ImageFile file={file} transform={transform} />
                    </Suspense>
                )
            }
            case 'video': {
                return <VideoFile file={file} />
            }
            case 'audio': {
                return <AudioFile file={file} />
            }
            case 'pdf': {
                return (
                    <Suspense fallback={<div>Loading...</div>}>
                        <PdfFile
                            onlyPdfSinglePage={onlyPdfSinglePage}
                            showPdfThumbs={showPdfThumbs}
                            file={file}
                            prefix={prefix}
                            transform={transform}
                            disableIntersectionObserver={disabledSlider}
                        />
                    </Suspense>
                )
            }
            case 'xls':
            case 'xlsx':
            case 'doc':
            case 'docx':
            case 'ppt':
            case 'pptx': {
                const OfficeFile = React.lazy(() => import('./Office').then(module => ({ default: module.OfficeFile })))
                return (
                    <Suspense fallback={null}>
                        <OfficeFile file={file} transform={transform} />
                    </Suspense>
                )
            }
            // case 'xls':
            // case 'xlsx': {
            //     return <NotSupportFile name={file.name} icon="FileXsl" />
            // }
            // case 'doc':
            // case 'docx': {
            //     return <NotSupportFile name={file.name} icon="FileDoc" />
            // }
            // case 'ppt':
            // case 'pptx': {
            //     return <NotSupportFile name={file.name} icon="FilePpt" />
            // }
            case 'txt': {
                return <NotSupportFile name={file.name} icon="FileTxt" />
            }
            case 'zip': {
                return <NotSupportFile name={file.name} icon="FileZip" />
            }

            default: {
                return <NotSupportFile name={file.name} icon="FileNothing" />
            }
        }
    }, [disabledSlider, file, mounted, prefix, onlyPdfSinglePage, showPdfThumbs, transform])

    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        function preventDefault(e: Event) {
            e.preventDefault()
        }
        document.addEventListener('gesturestart', preventDefault)
        document.addEventListener('gesturechange', preventDefault)

        return () => {
            document.removeEventListener('gesturestart', preventDefault)
            document.removeEventListener('gesturechange', preventDefault)
        }
    }, [])

    usePinch(
        e => {
            if (e.axis === 'scale') {
                setTransform(draft => {
                    draft.scale = e.offset[0] * 100
                })
            }
        },
        { target: ref.current || undefined, scaleBounds: { min: MIN_SCALE / 100, max: MAX_SCALE / 100 } }
    )
    // useGesture(
    //     {
    //         onPinchStart: console.log,
    //         onPinch: console.log,
    //         onPinchEnd: console.log
    //     },
    //     {
    //         pinch: { pointer: { touch: true } },
    //         target: ref.current || undefined
    //     }
    // )

    const contentEle = useMemo(() => {
        return (
            <>
                {content}
                <FileControl
                    zoomIn={ALLOW_SCALE.has(file.type) ? { handler: onZoomIn, disabled: transform.scale <= MIN_SCALE } : false}
                    zoomOut={ALLOW_SCALE.has(file.type) ? { handler: onZoomOut, disabled: transform.scale >= MAX_SCALE } : false}
                    rotate={ALLOW_ROTATE.has(file.type) ? { handler: onRotate } : false}
                    // download={{ handler: onDownload }}
                />
            </>
        )
    }, [content, file.type, onRotate, onZoomIn, onZoomOut, transform.scale])

    return disabledSlider ? contentEle : <Carousel.Slide ref={ref}>{contentEle}</Carousel.Slide>
}
