import type {
    ActionsProtocol,
    BlockVisibilityProtocol,
    ChartOptionProtocol,
    DataPointerProtocol,
    DisplayProtocol,
    EditableProtocol,
    ExportProtocol,
    GroupByProtocol,
    HighlightingProtocol,
    ImportProtocol,
    PaginableProtocol,
    PaginationProtocol,
    PrintProtocol,
    RecordViewableProtocol,
    RequiredProtocol,
    SearchableProtocol,
    SearchProtocol,
    SortableProtocol,
    SortProtocol,
    TableRowHeightProtocol,
    ViewFieldSettingProtocol,
    ViewFilterableProtocol,
    ViewRecordEditProtocol,
    ViewRecordOperateProtocol,
    ViewTypeProtocol
} from '../protocols'
import type { TitleConfig } from './Basic'
import type { CalendarViewOptions } from './blocks/CalendarView'
import type { CustomViewOptions } from './blocks/CustomView'
import type { GalleryViewOptions } from './blocks/GalleryView'
import type { KanbanViewOptions } from './blocks/KanbanView'

export enum ViewDesignStyle {
    'classic' = 'classic',
    'simple' = 'simple',
    'stripe' = 'stripe'
}

export type DesignType = 'default' | 'card' | 'colorBlock'
export interface TableViewOptions {
    designStyle?: ViewDesignStyle
    designType?: DesignType
    listAvatar?: string
    isShowListAvatar?: boolean
    avatarType?: 'round' | 'square'
    isShowEmphasizeText?: boolean
    emphasizeTextField?: string
    hiddenHeader?: boolean
}

// export interface ListViewOptions {
//     listAvatar?: string
//     isShowListAvatar?: boolean
// }

/** 图表视图配置项 */
export type ChartViewOptions = BlockVisibilityProtocol & ChartOptionProtocol

/** 视图通用配置项 */
export interface CommonViewOptions
    extends TitleConfig,
        DataPointerProtocol,
        GroupByProtocol,
        ViewFilterableProtocol,
        SortableProtocol,
        PaginationProtocol,
        ViewRecordOperateProtocol,
        EditableProtocol,
        RequiredProtocol,
        SearchableProtocol,
        ImportProtocol,
        ExportProtocol,
        PrintProtocol,
        HighlightingProtocol,
        SortProtocol,
        DisplayProtocol,
        PaginableProtocol,
        ViewFieldSettingProtocol,
        SearchProtocol,
        RecordViewableProtocol,
        ViewRecordEditProtocol,
        ViewTypeProtocol,
        ActionsProtocol,
        BlockVisibilityProtocol {
    /** 表格行高 */
    tableRowHeight?: TableRowHeightProtocol
}

export interface ViewOptions
    extends CommonViewOptions,
        TableViewOptions,
        GalleryViewOptions,
        KanbanViewOptions,
        CalendarViewOptions,
        CustomViewOptions {}
