import type { CollisionDetection, DragEndEvent, DragOverEvent, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core'
import { closestCenter, getFirstCollision, MouseSensor, pointerWithin, rectIntersection, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { useLatestValue } from '@dnd-kit/utilities'
import type { AppUser, Field, FieldADTValue, FieldMember, PersonValue, RecordLikeProtocol } from '@lighthouse/core'
import type { StoredGroupVisibleOption } from '@lighthouse/shared'
import { ANONYMOUS, EMPTY_COLUMN_GROUP, PERSON_ID, systemPersonFieldId } from '@lighthouse/shared'
import { useUpdateEffect } from '@react-hookz/web'
import { current } from 'immer'
import { clone } from 'rambda'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useImmer } from 'use-immer'

import type { SortItemsDataWithKey } from './utils'
import { findContainer } from './utils'

interface GroupConfig {
    records: RecordLikeProtocol[]
    visibleConfig?: StoredGroupVisibleOption[]
    personOptions?: AppUser[]
}

/**
 * 选项字段数据分组
 * @date 7/17/2023 - 3:53:14 PM
 *
 * @param {string} groupId
 * @param {GroupConfig} { field, records, visibleConfig }
 * @returns {*}
 */
function optionTypeGroup(groupId: string, field: FieldMember<'select'>, { records, visibleConfig }: GroupConfig) {
    const options = [...field.select.options.map(item => item.label), EMPTY_COLUMN_GROUP]
    const initialGroup = options.reduce<Record<PropertyKey, SortItemsDataWithKey[]>>((p, n) => {
        if (visibleConfig ? !visibleConfig.some(item => n === item.value && !item.visible) : true) {
            p[n] = []
        }
        return p
    }, {})

    return records.reduce((prev, next) => {
        const fieldValue = next.content?.[groupId]

        if (!fieldValue || !fieldValue.value) {
            if (EMPTY_COLUMN_GROUP in prev) {
                prev[EMPTY_COLUMN_GROUP].push({ ...next, key: `${EMPTY_COLUMN_GROUP}-${next.id}` })
                return prev
            }
            return prev
        }

        if (Array.isArray(fieldValue.value)) {
            if (fieldValue.value.length === 0) {
                if (EMPTY_COLUMN_GROUP in prev) {
                    prev[EMPTY_COLUMN_GROUP].push({ ...next, key: `${EMPTY_COLUMN_GROUP}-${next.id}` })
                    return prev
                }
                // prev[EMPTY_COLUMN_GROUP] = [{ ...next, key: `${EMPTY_COLUMN_GROUP}-${next.id}` }]
            } else {
                fieldValue.value.forEach(v => {
                    // 是否是select值、且存在该字段分组
                    if (typeof v === 'string' && v in prev) {
                        prev[v].push({ ...next, key: `${v}-${next.id}` })
                    }
                })
            }
        }

        return prev
    }, initialGroup)
}

/**
 * 成员字段数据分组
 * @date 7/17/2023 - 3:54:25 PM
 *
 * @param {string} groupId
 * @param {GroupConfig} { field, records, visibleConfig, personOptions }
 * @returns {*}
 */
function personTypeGroup(groupId: string, { records, visibleConfig, personOptions }: GroupConfig) {
    const isSystemPerson = systemPersonFieldId.has(groupId)
    const staticAnonymous = isSystemPerson ? [...PERSON_ID] : [ANONYMOUS]
    const options = [...(personOptions ? personOptions.map(item => item.userId) : []), ...staticAnonymous, EMPTY_COLUMN_GROUP]
    const initialGroup = options.reduce<Record<PropertyKey, SortItemsDataWithKey[]>>((prev, next) => {
        if (visibleConfig ? !visibleConfig.some(item => next === item.value && !item.visible) : true) {
            prev[next] = []
        }
        return prev
    }, {})

    return records.reduce((prev, next) => {
        const value = (next.content?.[groupId]?.value || []) as PersonValue

        if (value.length === 0 && EMPTY_COLUMN_GROUP in prev) {
            prev[EMPTY_COLUMN_GROUP].push({ ...next, key: `${EMPTY_COLUMN_GROUP}-${next.id}` })
        }

        value.forEach(v => {
            if (v in prev) {
                prev[v].push({ ...next, key: `${v}-${next.id}` })
            }
        })

        return prev
    }, initialGroup)
}

/**
 * 根据schema及字段对记录分组
 * @param groupId 分组字段id
 * @param records 记录列表
 * @param schema 数据源信息
 * @returns {Record<string, RecordLikeProtocol[]>}
 */
export const useGroupRecordByField = (
    groupId: string | undefined,
    schema: { [key: string]: Field },
    { records, visibleConfig, personOptions }: GroupConfig
) => {
    return useMemo(() => {
        if (!groupId) {
            return {}
        }

        const field = schema[groupId]

        if (!field) {
            return {}
        }

        switch (field.type) {
            case 'select': {
                return optionTypeGroup(groupId, field, { records, visibleConfig })
            }

            case 'person': {
                return personTypeGroup(groupId, { records, visibleConfig, personOptions })
            }

            default: {
                return {}
            }
        }
    }, [visibleConfig, groupId, personOptions, records, schema])
}

/**
 * 嵌套dnd拖拽逻辑
 * @param data 分组数据
 * @returns
 */
export const useDndSortable = (
    data: Record<string, SortItemsDataWithKey[]>,
    kanbanGroupByField: Field | undefined,
    sortRule: string[] | undefined,
    onFieldChange?: (recordId: string, fieldValue: FieldADTValue) => void,
    onColumnSortChange?: (before: string, after: string) => void
) => {
    const latestProps = useLatestValue({ onFieldChange, onColumnSortChange })

    const [items, setItems] = useImmer(data)
    const latestItems = useLatestValue(items)

    const cacheItems = useLatestValue<null | Record<string, SortItemsDataWithKey[]>>(null)
    const [draggingData, setDraggingData] = useState<undefined | SortItemsDataWithKey | SortItemsDataWithKey[]>(undefined)

    const [containers, setContainers] = useImmer(sortRule ?? Object.keys(items))

    useUpdateEffect(() => {
        setItems(data)
        setContainers(sortRule ?? Object.keys(data))
    }, [data, setItems])

    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
    const lastOverId = useRef<UniqueIdentifier | null>(null)
    const recentlyMovedToNewContainer = useRef(false)

    const sensors = useSensors(
        useSensor(MouseSensor, { activationConstraint: { distance: 10 } })
        // useSensor(TouchSensor, { activationConstraint: { distance: 10 } })
    )

    const collisionDetection: CollisionDetection = useCallback(
        args => {
            if (activeId && activeId in items) {
                return closestCenter({
                    ...args,
                    droppableContainers: args.droppableContainers.filter(container => container.id in items)
                })
            }

            // Start by finding any intersecting droppable
            const pointerIntersections = pointerWithin(args)
            const intersections =
                pointerIntersections.length > 0
                    ? // If there are droppables intersecting with the pointer, return those
                      pointerIntersections
                    : rectIntersection(args)
            let overId = getFirstCollision(intersections, 'id')

            if (overId !== null) {
                if (overId in items) {
                    const containerItems = items[overId]

                    // If a container is matched and it contains items (columns 'A', 'B', 'C')
                    if (containerItems.length > 0) {
                        // Return the closest droppable within that container
                        overId = closestCenter({
                            ...args,
                            droppableContainers: args.droppableContainers.filter(
                                container => container.id !== overId && containerItems.some(item => item.key === container.id)
                            )
                        })[0]?.id
                    }
                }

                lastOverId.current = overId

                return [{ id: overId }]
            }

            // When a draggable item moves to a new container, the layout may shift
            // and the `overId` may become `null`. We manually set the cached `lastOverId`
            // to the id of the draggable item that was moved to the new container, otherwise
            // the previous `overId` will be returned which can cause items to incorrectly shift positions
            if (recentlyMovedToNewContainer.current) {
                lastOverId.current = activeId
            }

            // If no droppable is matched, return the last match
            return lastOverId.current ? [{ id: lastOverId.current }] : []
        },
        [activeId, items]
    )

    const onDragStart = useCallback(
        (e: DragStartEvent) => {
            cacheItems.current = clone(latestItems.current)
            setActiveId(e.active.id)

            const activeContainer = findContainer(e.active.id as string, latestItems.current)
            if (activeContainer) {
                const dragItem = latestItems.current[activeContainer].find(item => item.key === e.active.id)
                setDraggingData(e.active.id in latestItems.current ? latestItems.current[activeContainer] : dragItem)
            }
        },
        [cacheItems, latestItems]
    )
    const onDragOver = useCallback(
        (e: DragOverEvent) => {
            const { active, over } = e

            const mutableItems = latestItems.current

            if (!over || active.id in mutableItems || !kanbanGroupByField) {
                return
            }
            const activeId = active.id
            const overId = over.id

            const overContainer = findContainer(overId as string, mutableItems)
            const activeContainer = findContainer(activeId as string, mutableItems)
            if (!overContainer || !activeContainer) {
                return
            }

            if (activeContainer !== overContainer) {
                setItems(draft => {
                    const activeItems = draft[activeContainer]
                    const overItems = draft[overContainer]
                    const activeIndex = activeItems.findIndex(item => item.key === activeId)
                    const overIndex = overItems.findIndex(item => item.key === overId)

                    let newIndex: number

                    if (overId in draft) {
                        newIndex = overItems.length + 1
                    } else {
                        const isBelowOverItem =
                            active.rect.current.translated && active.rect.current.translated.top > over.rect.top + over.rect.height
                        const modifier = isBelowOverItem ? 1 : 0
                        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1
                    }

                    recentlyMovedToNewContainer.current = true

                    // 移动分组位置
                    const [deletedItem] = draft[activeContainer].splice(activeIndex, 1)
                    deletedItem.key = `${overContainer}-${deletedItem.id}`
                    // 修改该记录的分组字段值
                    const fieldValue = deletedItem.content[kanbanGroupByField.id].value as string[]

                    if (Array.isArray(fieldValue) && fieldValue.length === 0) {
                        fieldValue.push(overContainer)
                        draft[overContainer].splice(newIndex, 0, deletedItem)
                    } else {
                        const oldOptionIndex = fieldValue.indexOf(activeContainer)
                        if (!fieldValue.includes(overContainer)) {
                            fieldValue.splice(oldOptionIndex, 1)
                            if (overContainer !== EMPTY_COLUMN_GROUP) {
                                fieldValue.push(overContainer)
                                draft[overContainer].splice(newIndex, 0, deletedItem)
                            }
                        }
                    }

                    latestProps.current.onFieldChange?.(deletedItem.id, {
                        ...kanbanGroupByField,
                        value: current(fieldValue)
                    } as FieldADTValue)
                })
            }
        },
        [kanbanGroupByField, latestItems, latestProps, setItems]
    )
    const onDragCancel = useCallback(() => {
        if (cacheItems.current) {
            setItems(cacheItems.current)
        }

        setActiveId(null)
        setDraggingData(undefined)
        cacheItems.current = null
    }, [cacheItems, setItems])

    const onDragEnd = useCallback(
        (e: DragEndEvent) => {
            const { active, over } = e
            const activeId = active.id
            const overId = over?.id
            const mutableItems = latestItems.current

            if (overId) {
                if (activeId in mutableItems) {
                    setContainers(draft => {
                        const activeIndex = draft.indexOf(activeId as string)
                        const overIndex = draft.indexOf(overId as string)

                        const sortedContainers = arrayMove(draft, activeIndex, overIndex)

                        latestProps.current.onColumnSortChange?.(activeId as string, overId as string)

                        return sortedContainers
                    })
                } else {
                    const activeContainer = findContainer(activeId as string, mutableItems)
                    const overContainer = findContainer(overId as string, mutableItems)
                    if (kanbanGroupByField && activeContainer && overContainer && activeContainer !== overContainer) {
                        setItems(draft => {
                            const activeItems = draft[activeContainer]
                            const overItems = draft[overContainer]
                            const activeIndex = activeItems.findIndex(item => item.key === activeId)
                            const overIndex = overItems.findIndex(item => item.key === overId)

                            let newIndex: number

                            if (overId in draft) {
                                newIndex = overItems.length + 1
                            } else {
                                const isBelowOverItem =
                                    active.rect.current.translated && active.rect.current.translated.top > over.rect.top + over.rect.height
                                const modifier = isBelowOverItem ? 1 : 0
                                newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1
                            }

                            recentlyMovedToNewContainer.current = true

                            // 移动分组位置
                            const [deletedItem] = draft[activeContainer].splice(activeIndex, 1)
                            deletedItem.key = `${overContainer}-${deletedItem.id}`
                            // 修改该记录的分组字段值
                            const fieldValue = deletedItem.content[kanbanGroupByField.id].value as string[]

                            if (Array.isArray(fieldValue) && fieldValue.length === 0 && overContainer !== EMPTY_COLUMN_GROUP) {
                                fieldValue.push(overContainer)
                                draft[overContainer].splice(newIndex, 0, deletedItem)
                            } else {
                                const oldOptionIndex = fieldValue.indexOf(activeContainer)
                                if (overContainer === EMPTY_COLUMN_GROUP) {
                                    fieldValue.splice(0, fieldValue.length)
                                } else {
                                    fieldValue.splice(oldOptionIndex, 1)
                                }
                                // 遍历有该记录的其它列，都要删除该分组值
                                Object.values(draft).forEach(v => {
                                    v.forEach(item => {
                                        if (item.id === deletedItem.id) {
                                            const value = item.content[kanbanGroupByField.id].value as string[]
                                            const removeIndex = value.indexOf(activeContainer)
                                            if (overContainer === EMPTY_COLUMN_GROUP) {
                                                value.splice(0, value.length)
                                            } else {
                                                value.splice(removeIndex, 1)
                                                if (!value.includes(overContainer)) {
                                                    value.push(overContainer)
                                                }
                                            }
                                        }
                                    })
                                })

                                if (!fieldValue.includes(overContainer) && overContainer !== EMPTY_COLUMN_GROUP) {
                                    fieldValue.push(overContainer)
                                    draft[overContainer].splice(newIndex, 0, deletedItem)
                                }
                            }
                            latestProps.current.onFieldChange?.(deletedItem.id, {
                                ...kanbanGroupByField,
                                value: current(fieldValue)
                            } as FieldADTValue)
                        })
                        // const activeIndex = mutableItems[activeContainer].findIndex(item => item.id === activeId)
                        // const overIndex = mutableItems[overContainer].findIndex(item => item.id === overId)
                        // if (activeIndex !== overIndex) {
                        //     setItems(draft => {
                        //         draft[overContainer] = arrayMove(draft[overContainer], activeIndex, overIndex)
                        //     })
                        // }
                    }
                }
            }

            setActiveId(null)
            setDraggingData(undefined)
        },
        [kanbanGroupByField, latestItems, latestProps, setContainers, setItems]
    )

    useEffect(() => {
        requestAnimationFrame(() => {
            recentlyMovedToNewContainer.current = false
        })
    }, [items])

    return { draggingData, activeId, items, containers, sensors, onDragStart, onDragOver, onDragEnd, onDragCancel, collisionDetection }
}
