import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { createStyles } from '@byecode/ui/theme/createStyles'
import { css, styled } from '@byecode/ui/theme/stitches.config'
import type { StyleComponentProps } from '@byecode/ui/theme/types'
import type { ByecodeSize } from '@byecode/ui/types'
import type { Placement } from '@floating-ui/react'
import clsx from 'clsx'
import React, { forwardRef, useMemo, useState } from 'react'

import { Box } from '../Box'
import { IconFont } from '../IconFont'
import { Input } from '../Input'
import type { PopoverProps } from '../Popover'
import { Popover } from '../Popover'
import type { MultiSelectDropdownProps } from './MultiSelectDropdown/MultiSelectDropdown'
import { MultiSelectDropdown } from './MultiSelectDropdown/MultiSelectDropdown'
import type { MultiOption, MultiSelectStylesNames, MultiSelectValueType } from './types'

const SCxIcon = styled(IconFont, {
    color: '$colorGray400',
    '&:hover': {
        color: '$colorGray800'
    }
})

const SCxCustomMultiSelectOption = styled('div', {})

const useStyles = createStyles(() => ({
    root: css({
        position: 'relative'
    }),
    input: css({
        input: {
            cursor: 'pointer'
        }
    })
}))

export interface CustomInputValueRenderProps {
    selectOptions: MultiOption[]
    options?: MultiOption[]
    placeholder?: string
}

export interface MultiSelectProps
    extends StyleComponentProps<MultiSelectStylesNames>,
        Omit<React.ComponentPropsWithoutRef<'input'>, 'size' | 'onChange'> {
    size?: ByecodeSize

    disabledPortal?: boolean
    options?: MultiOption[]
    optionComponent?: MultiSelectDropdownProps['optionComponent']
    icon?: React.ReactNode
    defaultValue?: MultiSelectValueType
    value?: MultiSelectValueType
    onChange?: (value: MultiSelectValueType) => void
    position?: Placement
    focusOutLine?: boolean
    clearable?: boolean
    searchable?: boolean
    target?: PopoverProps['target']
    dropdownProps?: Omit<MultiSelectDropdownProps, 'options' | 'searchable' | 'onMultiSelect'>
    dropdownWidth?: PopoverProps['width']
    zIndex?: number
    trapFocus?: boolean
    /** 暂时不生效、Input外层包了层div，导致floating拿不到input dom */
    returnFocus?: boolean
    customInputValueRender?: React.FC<CustomInputValueRenderProps>
    hiddenEmpty?: boolean
}

export const MultiSelect = forwardRef<HTMLInputElement, MultiSelectProps>((props, ref) => {
    const {
        options,
        optionComponent,
        defaultValue = [],
        value,
        disabledPortal,
        position,
        onChange,
        dropdownProps,
        clearable,
        searchable,
        icon,
        size = 'md',
        className,
        classNames,
        styles,
        unstyled,
        onFocus,
        focusOutLine,
        disabled,
        target,
        dropdownWidth,
        zIndex,
        trapFocus = false,
        returnFocus = false,
        customInputValueRender: CustomInputValueRender,
        hiddenEmpty = false,
        placeholder,
        ...rest
    } = props

    const [_value, _setValue] = useUncontrolled({ defaultValue, value, onChange })

    // 拍平 options
    const flatOptions = useMemo(() => {
        const result: MultiOption[] = []
        options?.forEach(item => {
            if (item.children) {
                result.push(...item.children)
            } else {
                result.push(item)
            }
        })
        return result
    }, [options])
    // 查找当前选中 option

    const currentOption = useMemo(() => flatOptions?.filter(item => _value?.includes(item.value)), [flatOptions, _value])

    const [open, setOpen] = useState(false)

    const { classes } = useStyles({ size }, { name: 'select', classNames, styles, unstyled })

    const internalDropdownProps = useMemo(
        () => ({
            options,
            optionComponent,
            searchable,
            value: _value,
            styles,
            hiddenEmpty,
            onMultiSelect: (v: string[]) => {
                _setValue(v)
            },
            onClose: () => setOpen(false),
            ...dropdownProps
        }),
        [_setValue, _value, dropdownProps, hiddenEmpty, optionComponent, options, searchable, styles]
    )

    const selectOptions = useMemo(() => options?.filter(item => _value?.includes(item.value)), [_value, options])

    const selectSuffixDom =
        _value && clearable ? (
            <SCxIcon
                type="CloseCircle"
                style={{ cursor: 'pointer' }}
                size={16}
                onClick={e => {
                    e.stopPropagation()
                    _setValue([])
                }}
            />
        ) : (
            <IconFont color="var(--color-gray-400)" size={16} type="ArrowDownSmall" />
        )

    return (
        <Popover
            withinPortal={!disabledPortal}
            opened={open}
            zIndex={zIndex}
            onChange={setOpen}
            position={position}
            trapFocus={trapFocus}
            width={dropdownWidth}
            disabled={disabled}
            returnFocus={returnFocus}
        >
            <Popover.Target>
                <Box className={classes.root}>
                    {CustomInputValueRender && selectOptions ? (
                        <CustomInputValueRender selectOptions={selectOptions} options={options} placeholder={placeholder} />
                    ) : (
                        <Input
                            ref={ref}
                            value={currentOption?.map(item => item.label).join(',') || ''}
                            readOnly
                            size={size}
                            focusOutLine={focusOutLine}
                            className={clsx(classes.input, className)}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            // todo input需要统一使用样式系统
                            styles={{ input: styles?.input, wrapper: { ...styles?.wrapper, color: styles?.wrapper?.color } }}
                            // prefix={
                            //     currentOption?.icon && typeof currentOption.icon === 'string' ? (
                            //         <IconFont type={currentOption.icon} />
                            //     ) : (
                            //         currentOption?.icon
                            //     )
                            // }
                            suffix={selectSuffixDom}
                            disabled={disabled}
                            {...rest}
                        />
                    )}
                </Box>
            </Popover.Target>
            <Popover.Dropdown py={8}>
                <MultiSelectDropdown {...internalDropdownProps} />
            </Popover.Dropdown>
        </Popover>
    )
})
