import { createStyles } from '../../../theme/createStyles'
import { css } from '../../../theme/stitches.config'

export const useStyles = createStyles(() => ({
    yearBody: css({
        padding: 16,
        display: 'grid',
        gap: 16,
        gridTemplateColumns: 'repeat(3, 1fr)'
    }),
    yearCell: css({
        position: 'relative',
        width: 64,
        height: '24px !important',
        padding: 0,
        borderRadius: '12px !important',
        fontSize: '14px',
        lineHeight: '22px',
        justifyContent: 'center',
        variants: {
            color: {
                gray: {
                    color: '$colorGray900'
                }
            }
        }
    }),
    yearIndicator: css({
        width: 4,
        height: 4,
        borderRadius: '50%',
        position: 'absolute',
        left: '50%',
        bottom: '-10px',
        transform: 'translateX(-50%)',
        backgroundColor: '$colorPrimary'
    })
}))
