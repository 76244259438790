import { Enum } from '@lighthouse/tools'
import type { MakeADT } from 'ts-adt/MakeADT'

import type { RichTextContentProtocol } from './richtext'

// export type BaseInnerType = 'TEXT' | 'NUMBER' | 'DATE' | 'BOOL' | 'ARRAY'

export type TypeInstanceMap = 'TEXT' | 'NUMBER' | 'DATE' | 'BOOL' | 'ARRAY' | 'NULL' | 'RICH_TEXT'

export enum SortEnum {
    normal,
    positive,
    reverse
}

export enum Order {
    ASC = 'ASC',
    DESC = 'DESC'
}

export enum IdTypeEnum {
    CUSTOM = 'CUSTOM',
    ORDER_ID = 'ORDER_ID'
}

export enum IdRuleEnum {
    DATE = 'DATE',
    FIXED_CHARACTER = 'FIXED_CHARACTER',
    ID = 'ID'
}

export interface DTSelectItem {
    id: string
    label: string
    color?: string
}

export interface DTTextGeneration {
    variable?: RichTextContentProtocol
}

export interface DTSelectGenerationByText {
    variable?: RichTextContentProtocol
    options: DTSelectItem[]
    multipleMatch?: boolean
}

export interface DTNumber {
    accuracy?: number
    prefix?: string
    suffix?: string
}

export interface DTDate {
    format?: string
}

export type IdRuleNumber = {
    length: number
}

// export interface LookupFieldRuleItem {
//     matchPointer: string // 匹配的字段 id
//     operator: string // 匹配的操作符
//     params: {
//         type: string // value | field
//         value: string
//     }
// }

// export interface LookupFieldRule {
//     matchType?: string
//     rules?: LookupFieldRuleItem[]
// }

export type IdRuleDate = {
    dateFormat: string
}

export type IdRuleFixedCharacter = {
    fixedCharacter: string
}

export type IdRule = MakeADT<
    'type',
    {
        ID: IdRuleNumber
        DATE: IdRuleDate
        FIXED_CHARACTER: IdRuleFixedCharacter
    }
>

export interface DTId {
    type: IdTypeEnum
    rules: IdRule[]
}

export interface DTSelect {
    sort?: SortEnum
    multiple?: boolean
    options: DTSelectItem[]
}

export interface DTPerson {
    multiple?: boolean
}

export interface DTFormula {
    expression?: string
}

export interface DTCheckbox {
    icon?: string
    color?: string
}

export interface DTAggregate {
    isFormula?: boolean
    formula?: DTFormula
}

export interface DTUserStatusItem {
    label: string
    color?: string
}

export type DTData = DTSelect | DTFormula

export const FileType = Enum('image', 'video', 'audio', 'pdf', 'doc', 'ppt', 'txt', 'xls', 'xlsx', 'archive', 'other')

export type FileType = Enum<typeof FileType>

export const FileSourceType = Enum('album', 'camera')

export type FileSourceType = Enum<typeof FileSourceType>
export interface FileProtocols {
    type: FileType
    name: string
    url: string
    size?: number
}

export type DTFile = string

export interface AiFieldIdentity {
    dataSourceId: string
    fieldId: string
    recordId: string
}

export type AiFieldState = 'STARTED' | 'FINISHED'

export interface AiFieldStatus {
    dataSourceId: string
    fieldId: string
    id: string
    recordId: string
    state: AiFieldState
}


export type ScannerType = 'barCode' | 'qrCode'
