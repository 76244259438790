import React from 'react'

import { TooltipText } from '../TooltipText'
import * as SC from './styles'

export interface FieldListItemProps {
    children?: React.ReactNode
    icon?: string
    iconColor?: string
    title: string
    suffix?: React.ReactNode
    description?: string
    className?: string
    style?: React.CSSProperties
    sortable?: boolean
    onClick?: () => void
}

export const FieldListItem: React.FC<FieldListItemProps> = ({
    icon,
    iconColor,
    title,
    suffix,
    description,
    style,
    sortable,
    className,
    onClick
}) => {
    return (
        <SC.ItemWrapper className={className} sortable={sortable} style={style} onClick={onClick}>
            <SC.InfoContainer>
                <SC.InfoWrapper>
                    {icon && <SC.InfoIcon type={icon} fill={iconColor} />}
                    <TooltipText title={title} render={ref => <SC.InfoText ref={ref}>{title}</SC.InfoText>} />
                </SC.InfoWrapper>
                {description && <SC.Describe>{description}</SC.Describe>}
            </SC.InfoContainer>
            <SC.ActionWrapper>{suffix}</SC.ActionWrapper>
        </SC.ItemWrapper>
    )
}
