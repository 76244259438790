import { backgroundTransitionOnClick, flex, IconFont, pointer } from '@byecode/ui'
import type { NavigationShowMode } from '@lighthouse/core'
import styled, { css } from 'styled-components'

export const Header = styled.div<{ disableEvent?: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    color: transparent;
`

export const LeftGroup = styled.div`
    ${flex};
    flex: 1;
    align-items: center;
    overflow: hidden;
    & > *:not(:first-child) {
        margin-left: 12px;
    }
`

export const RightGroup = styled.div`
    ${flex}
    align-items: center;
    & > * {
        margin-left: 12px;
    }
`

export const LinkGroup = styled.div`
    display: flex;
    gap: 12px;
`

export const Icon = styled(IconFont)<{ color?: string; size?: number | string }>`
    font-size: ${({ size }) => (typeof size === 'number' ? `${size || 16}px` : size)};
    ${pointer}
    color: ${({ color }) => color};
`

export const BtnGroup = styled.div`
    ${flex}
    align-items: center;

    & > * {
        margin-left: 12px;
    }
`

export const UserCenter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`
export const Avatar = styled.div<{ background?: string }>`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    color: var(--color-black);

    ${({ background }) => {
        return (
            background &&
            css`
                background: url('${background}');
            `
        )
    }}

    background-position: center;
    background-size: contain;
`

export const UserBoxText = styled.div`
    width: 28px;
    height: 28px;
    line-height: 28px;
    background: var(--color-gray-100);
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    margin-left: 8px;
    color: var(--color-black);
`

export const Background = styled.div`
    width: 100%;

    /* 背景模糊，背景叠加 */
    &.backgroundBlur {
        backdrop-filter: blur(12px);
    }
`
