import { createStyles } from '@byecode/ui/theme/createStyles'
import { css, keyframes } from '@byecode/ui/theme/stitches.config'
import type { CSSProperties } from 'react'

interface ScannerStylesParams {}

const scanningAnim = keyframes({
    '0%': {
        transform: 'translateY(-100%)'
    },
    '100%': {
        transform: 'translateY(100%)'
    }
})

export const useStyles = createStyles(({}: ScannerStylesParams) => {
    return {
        root: css({
            position: 'relative',
            width: '100vw',
            height: '100vh',
            background: '#000',
            zIndex: 999999999
        }),
        canvas: css({}),
        video: css({
            position: 'absolute',
            inset: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }),
        uploadBtn: css({
            position: 'absolute',
            bottom: '20%',
            right: 20,
            width: 48,
            height: 48,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '100%',
            background: 'rgba(196, 196, 196, 0.2)',
            zIndex: 1
        }),
        flashBtn: css({
            position: 'absolute',
            top: 20,
            left: 20,
            width: 32,
            height: 32,
            zIndex: 3
        }),
        closeBtn: css({
            position: 'absolute',
            top: 20,
            right: 20,
            width: 32,
            height: 32,
            zIndex: 3
        }),
        scanning: css({
            width: '100%',
            height: '100%',
            background: 'linear-gradient(180deg, rgba(0, 255, 51, 0) 50%, #fff 300%)',
            borderBottom: '1px solid #fff',
            animation: `3s ${scanningAnim} infinite`,
            animationTimingFunction: 'cubic-bezier(0.53, 0, 0.43, 0.99)',
            animationDelay: '0.5s'
        }),
        error: css({
            color: '#fff',
            padding: 20,
            fontSize: 12,
            backgroundColor: '#333',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 275,
            borderRadius: 10,
            ul: {
                display: 'flex',
                flexDirection: 'column',
                gap: 4
            }
        }),
        fail: css({
            position: 'absolute',
            inset: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#000',
            zIndex: 2,
            gap: 14
        })
    }
})
