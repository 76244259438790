import type { FieldInputADTValue, InputValueItem, SubFormColumn } from '@lighthouse/core'
import { FieldInput } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useSubFormFieldInputContext } from './SubFormFieldInputProvider'

interface SubFormCellProps {
    value: InputValueItem
    column: SubFormColumn
    recordId: string
}

const SCxCell = styled.div<{ width: number }>`
    width: ${({ width }) => width}px;
`

export const SubFormCell: React.FunctionComponent<SubFormCellProps> = ({ column, value, recordId }) => {
    const { config, id, width } = column
    const { fieldPointer } = config
    const fieldInputProps = useSubFormFieldInputContext()
    const fieldInputConfig = useMemo(
        () => ({
            ...config,
            showTitle: false,
            previewType: fieldInputProps.previewType,
            uploadyOptions: fieldPointer ? fieldInputProps?.uploadyOptions : undefined,
            richTextUploadOptions: fieldPointer ? fieldInputProps?.richTextUploadOptions : undefined,
            videoUploadyOption: fieldPointer ? fieldInputProps?.videoUploadyOption : undefined
        }),
        [config, fieldInputProps, fieldPointer]
    )

    const error = useMemo(
        () => find(v => recordId === v.recordId && v.columnId === id, fieldInputProps?.errors ?? [])?.error,
        [fieldInputProps?.errors, id, recordId]
    )

    const handleSaveChange = useCallback(
        (fieldValue: FieldInputADTValue) => {
            fieldInputProps.onCellChange?.(recordId, id, fieldValue)
        },
        [fieldInputProps, id, recordId]
    )

    const handleBlur = useCallback(() => {
        fieldInputProps.onCellBlur?.(recordId, id)
    }, [fieldInputProps, id, recordId])

    const handleSmsCodeChange = useCallback(
        (v: string) => {
            fieldInputProps.onChangeSmsCode?.(recordId, id, v)
        },
        [fieldInputProps, id, recordId]
    )

    return (
        <SCxCell key={id} width={fieldInputProps.columnsWidth?.[id] || width}>
            <FieldInput
                value={value?.value}
                config={fieldInputConfig}
                error={error}
                {...fieldInputProps}
                onSaveChange={handleSaveChange}
                onChange={handleBlur}
                onChangeSmsCode={handleSmsCodeChange}
            />
        </SCxCell>
    )
}
