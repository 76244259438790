import type { ApplicationSettingNavbar } from '@lighthouse/core'
import {
    type ApplicationSettingTheme,
    ApplicationSettingThemeNavBarMode,
    MAX_CONTAINER_WIDTH_SIZE,
    NavigationShowMode
} from '@lighthouse/core'
import cls from 'classnames'
import React, { useMemo } from 'react'
import { useCss } from 'react-use'

import { SIZE_OPTIONS } from '../../FlowLayout'
import { useAppContainerContext } from '../AppContainerContext'
import { useAppNavbarStylesContext } from '../AppNavbarStylesContext'
import { useAppThemeContext } from '../AppThemeContext'
import { getRealPx } from '../help'
import type { ApplicationSettingThemeNavBarModeColor, Mode } from '../type'
import * as SC from './styles'

interface ApplicationHeaderLayoutProps {
    navbar: ApplicationSettingNavbar
    mode: NavigationShowMode
    children?: React.ReactNode
    selected: boolean
    disableEvent?: boolean
    headerStyle?: ApplicationSettingThemeNavBarModeColor
    onClick?: () => void
    style?: React.CSSProperties
}

const modeClassName: Record<NavigationShowMode, Mode> = {
    1: 'horizontal',
    2: 'verticalWide',
    3: 'verticalNarrow'
}

const ApplicationHeaderLayout = React.forwardRef<HTMLDivElement, ApplicationHeaderLayoutProps>(
    ({ mode, navbar, children, selected, style, disableEvent, onClick }, ref) => {
        const { themeMode, width, height, align, isSticky, backgroundBlur, radius, backgroundOverlay, maxWidth, backgroundPadding } = navbar
        const { padding } = useAppNavbarStylesContext()
        const { showMode } = useAppContainerContext()
        const { mainColor } = useAppThemeContext()

        const radiusStyle = radius?.map(v => `${v}px`).join(' ')

        const backgroundColor = useMemo(() => {
            const isAddTransparent = isSticky && backgroundBlur && showMode === NavigationShowMode.horizontal
            switch (themeMode) {
                case ApplicationSettingThemeNavBarMode.custom: {
                    return isAddTransparent ? `${mainColor}7f` : mainColor
                }
                case ApplicationSettingThemeNavBarMode.light: {
                    return isAddTransparent ? `#ffffff7f` : '#ffffff'
                }
                case ApplicationSettingThemeNavBarMode.transparent: {
                    return isAddTransparent ? `#ffffff7f` : 'transparent'
                }
                case ApplicationSettingThemeNavBarMode.dark: {
                    return isAddTransparent ? `#2D32407f` : '#2D3240'
                }
                default: {
                    return 'transparent'
                }
            }
        }, [backgroundBlur, isSticky, mainColor, showMode, themeMode])

        return (
            <SC.Container
                ref={ref}
                style={style}
                width={getRealPx(width)}
                height={getRealPx(height)}
                backgroundPadding={backgroundPadding}
                maxWidth={maxWidth === MAX_CONTAINER_WIDTH_SIZE.unlimited ? undefined : SIZE_OPTIONS[maxWidth]}
                padding={padding}
                borderRadius={radiusStyle}
                mode={showMode}
                selected={selected}
                linkListAlgin={align}
                disableEvent={disableEvent}
                backgroundColor={backgroundColor}
                onClick={e => {
                    e.stopPropagation()
                    onClick?.()
                }}
                className={cls({
                    [`${modeClassName[mode]}`]: true,
                    isSticky,
                    // selected,
                    backgroundOverlay: isSticky && backgroundOverlay
                })}
            >
                {children}

                {/* 选中边框 */}
                {selected && (
                    <>
                        <div
                            style={{ position: 'absolute', top: 0, bottom: 0, left: 0, width: 2, background: 'var(--color-purple-500)' }}
                        />
                        <div
                            style={{ position: 'absolute', top: 0, bottom: 0, right: 0, width: 2, background: 'var(--color-purple-500)' }}
                        />
                        <div
                            style={{ position: 'absolute', left: 0, right: 0, top: 0, height: 2, background: 'var(--color-purple-500)' }}
                        />
                        <div
                            style={{ position: 'absolute', left: 0, right: 0, bottom: 0, height: 2, background: 'var(--color-purple-500)' }}
                        />
                    </>
                )}
            </SC.Container>
        )
    }
)

export default ApplicationHeaderLayout
