import { Button, Modal, Popover, Select } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, DragOverlay } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext } from '@dnd-kit/sortable'
import { type DTId, type Field, type IdField, type IdRule, IdTypeEnum } from '@lighthouse/core'
import { lightFormat } from 'date-fns'
import isDeepEqual from 'fast-deep-equal'
import { find, findIndex } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useHotkeys } from 'react-hotkeys-hook'

import { InnerTypeMapByFieldType } from '../../constants'
import { TooltipText } from '../TooltipText'
import { ID_CONFIG_LABEL, ID_CONFIG_OPTIONS, IdMaxLength } from './constants'
import { IdConfigItem } from './IdConfigItem'
import { SortableItem } from './SortableItem'
import * as SC from './styles'

export interface IdConfigViewProps {
    config: IdField
    onOk?: (data: Field) => void
    onCancel: () => void
}

const idTypeOptions = [
    {
        label: '自定义编号',
        value: IdTypeEnum.CUSTOM
    },
    {
        label: '随机编号',
        value: IdTypeEnum.ORDER_ID
    }
]

export const IdConfigView: React.FC<IdConfigViewProps> = ({ config, onOk, onCancel }) => {
    const lengthRef = useRef(Number.NaN)
    const [open, setOpen] = useState(false)
    const [dragId, setDragId] = useState<string | number>('')
    const methods = useForm<DTId>({
        defaultValues: {
            type: config?.idConfig.type || IdTypeEnum.CUSTOM,
            rules: config?.idConfig?.rules || []
        },
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true
    })

    const {
        control,
        watch,
        handleSubmit,
        formState: { errors }
    } = methods

    const { type, rules } = watch()

    const list = useMemo(
        () => ID_CONFIG_OPTIONS.filter(item => item.value === 'FIXED_CHARACTER' || !find(rule => rule.type === item.value, rules)),
        [rules]
    )

    const handleDragStart = useCallback((data: DragStartEvent) => {
        const { active } = data
        setDragId(active.id)
    }, [])
    const handleDragCancel = () => setDragId('')

    const preview = useMemo(() => {
        return rules.reduce((acc, rule) => {
            if (rule.type === 'ID') {
                acc += '1'.padStart(rule.length, '0')
            }
            if (rule.type === 'DATE' && rule.dateFormat) {
                acc += lightFormat(new Date(), rule.dateFormat)
            }
            if (rule.type === 'FIXED_CHARACTER') {
                acc += rule.fixedCharacter
            }
            return acc
        }, '')
    }, [rules])

    const isExcessIdLength = useMemo(() => preview.length > IdMaxLength, [preview])

    const { fields, remove, append, move } = useFieldArray({ control, name: 'rules' })

    const handleOk = useCallback(
        async (idConfig: DTId) => {
            if (isDeepEqual(idConfig, config.idConfig)) {
                onOk?.({ ...config, type: 'id', idConfig, innerType: InnerTypeMapByFieldType['id'] })
                return
            }
            const isConfirm = await Modal.confirm({
                title: '确定修改 ID 规则',
                content: '调整后的 ID 规则，只作用于新创建的记录。已创建记录的 ID 不变',
                okStatus: 'primary',
                okText: '确定'
            })
            if (isConfirm) {
                onOk?.({ ...config, type: 'id', idConfig })
            }
        },
        [config, onOk]
    )

    useHotkeys(
        'Enter',
        () => {
            handleSubmit(handleOk)()
        },
        { enableOnTags: ['INPUT'] }
    )

    const handleSortEnd = useCallback(
        (ev: DragEndEvent) => {
            const {
                active: { id: activeId },
                over
            } = ev
            const overId = over?.id
            if (!overId) {
                return
            }
            const activeIndex = findIndex(({ id }) => id === activeId, fields)
            const overIndex = findIndex(({ id }) => id === overId, fields)
            move(activeIndex, overIndex)
        },
        [fields, move]
    )

    const handleOptionAdd = useCallback(
        (type: IdRule['type']) => {
            lengthRef.current = fields.length
            if (type === 'ID') {
                append({ type, length: 3 })
                setOpen(false)
                return
            }
            if (type === 'DATE') {
                append({ type, dateFormat: 'yyyyMMddHHmm' })
                setOpen(false)
                return
            }
            append({ type, fixedCharacter: '' })
            setOpen(false)
        },
        [append, fields.length]
    )

    const footer = useMemo(() => {
        if (type === IdTypeEnum.CUSTOM) {
            return (
                <SC.FooterWrapper error={isExcessIdLength}>
                    {isExcessIdLength ? `编号总位数不能超过 ${IdMaxLength} 位` : `编号预览：${preview}`}
                </SC.FooterWrapper>
            )
        }
        const val = 'A1b2C3D4e5F6g7H8I9j0K1L2M3N4O5P6Q7R8S9T0'
        return <TooltipText title={val} render={ref => <SC.FooterWrapper ref={ref}>{`编号预览：${val}`}</SC.FooterWrapper>} />
    }, [type, isExcessIdLength, preview])

    const overlay = useMemo(() => {
        if (!dragId) {
            return null
        }
        const index = findIndex(item => item.id === dragId, fields)
        if (index < 0) {
            return null
        }
        const itemData = fields[index]
        return (
            <SC.OptionItem>
                <SC.MoveIcon type="Move" fill="var(--color-gray-400)" />
                <SC.FieldConfigLabel>{ID_CONFIG_LABEL[itemData.type]}</SC.FieldConfigLabel>
                <IdConfigItem type={itemData.type} index={index} configLength={lengthRef.current} errors={errors} />
                {itemData.type !== 'ID' && (
                    <SC.Icon
                        type="Close"
                        onClick={() => {
                            remove(index)
                        }}
                        fill="var(--color-gray-400)"
                    />
                )}
            </SC.OptionItem>
        )
    }, [dragId, errors, fields, remove])

    return (
        <SC.FieldForm>
            <SC.FieldConfigWrapper>
                <SC.FormItemWrapper>
                    <SC.FieldConfigHeader>编号类型</SC.FieldConfigHeader>
                    <Controller
                        control={control}
                        name="type"
                        render={({ field }) => {
                            return <Select options={idTypeOptions} value={field.value} onChange={field.onChange} />
                        }}
                    />
                </SC.FormItemWrapper>

                {type === IdTypeEnum.CUSTOM && (
                    <SC.FormItemWrapper>
                        <SC.FieldConfigHeader>编号规则</SC.FieldConfigHeader>
                        <SC.OptionList>
                            <FormProvider {...methods}>
                                <DndContext
                                    modifiers={[restrictToParentElement, restrictToFirstScrollableAncestor]}
                                    onDragStart={handleDragStart}
                                    onDragCancel={handleDragCancel}
                                    onDragEnd={handleSortEnd}
                                >
                                    <SortableContext items={fields.map(d => d.id)}>
                                        {fields.map((item, index) => (
                                            <SortableItem key={item.id} id={item.id} wrapperStyle={{ height: 40 }}>
                                                <SC.FieldConfigLabel>{ID_CONFIG_LABEL[item.type]}</SC.FieldConfigLabel>
                                                <IdConfigItem
                                                    type={item.type}
                                                    index={index}
                                                    configLength={lengthRef.current}
                                                    errors={errors}
                                                />
                                                {item.type === 'ID' ? (
                                                    <SC.IdLengthUnit>位</SC.IdLengthUnit>
                                                ) : (
                                                    <SC.Icon
                                                        type="Close"
                                                        onClick={() => {
                                                            remove(index)
                                                        }}
                                                        fill="var(--color-gray-400)"
                                                    />
                                                )}
                                            </SortableItem>
                                        ))}
                                    </SortableContext>
                                    <DragOverlay dropAnimation={{ duration: 0, easing: 'ease' }}>{overlay}</DragOverlay>
                                </DndContext>
                            </FormProvider>
                        </SC.OptionList>
                        <Popover opened={open} onChange={setOpen} width="auto">
                            <Popover.Target>
                                <SC.OptionAdder>
                                    <SC.Icon fill="var(--color-main)" type="Add" />
                                    添加规则
                                </SC.OptionAdder>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <SC.ListWrapper>
                                    {list.map(({ label, value }) => (
                                        <SC.ItemInfoWrapper
                                            key={value}
                                            onClick={() => {
                                                handleOptionAdd(value)
                                            }}
                                        >
                                            <SC.ItemInfoLabel>{label}</SC.ItemInfoLabel>
                                        </SC.ItemInfoWrapper>
                                    ))}
                                </SC.ListWrapper>
                            </Popover.Dropdown>
                        </Popover>
                    </SC.FormItemWrapper>
                )}
            </SC.FieldConfigWrapper>
            <SC.ActionWrapper>
                <Button style={{ marginRight: 8 }} onClick={onCancel}>
                    取消
                </Button>
                <Button type="primary" disabled={isExcessIdLength} onClick={handleSubmit(handleOk)}>
                    确定
                </Button>
            </SC.ActionWrapper>
            {footer}
            {/* <SC.FooterWrapper error={isExcessIdLength}>
                {isExcessIdLength ? `编号总位数不能超过 ${IdMaxLength} 位` : `编号预览：${preview}`}
            </SC.FooterWrapper> */}
        </SC.FieldForm>
    )
}
