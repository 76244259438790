import type { DTFile } from '@lighthouse/core'
import React, { useMemo } from 'react'

import type { EnableActionsParams } from '../../types'
import { ApplicationPreviewEnum } from '../../types'
import type { UseUploadFileSParameter } from '../UploadManage'
import { FileListPreviewer } from './FileListPreviewer'
import { WithoutPopperFileUploader } from './WithoutPopperFileUploader'

export interface FileUploaderProps {
    id?: string
    value?: DTFile[]
    previewType?: ApplicationPreviewEnum
    accept?: string
    withoutPopper?: boolean
    enableItemActions?: EnableActionsParams
    isPexels?: boolean
    isLink?: boolean
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    autoOpenWhenEmpty?: boolean
    disableUpload?: boolean
    disableDownload?: boolean
    isUsedLink?: boolean
    uploadFileType?: 'file' | 'video'
    multiple?: boolean
    onChange?: (files: DTFile[]) => void
}

export const FileUploader: React.FC<FileUploaderProps> = ({
    id,
    value,
    accept,
    enableItemActions,
    isPexels,
    withoutPopper,
    uploadyOptions,
    autoOpenWhenEmpty = true,
    previewType = ApplicationPreviewEnum.desktop,
    disableUpload,
    disableDownload,
    uploadFileType,
    isUsedLink,
    multiple,
    onChange
}) => {
    return useMemo(() => {
        return withoutPopper ? (
            <WithoutPopperFileUploader
                uploadyOptions={uploadyOptions}
                previewType={previewType}
                disableUpload={disableUpload}
                enableItemActions={enableItemActions}
                files={value ?? []}
                uploadFileType={uploadFileType}
                onChange={onChange}
            />
        ) : (
            <FileListPreviewer
                id={id}
                accept={accept}
                uploadyOptions={uploadyOptions}
                autoOpenWhenEmpty={autoOpenWhenEmpty}
                enableItemActions={enableItemActions}
                isPexels={isPexels}
                files={value ?? []}
                onChange={onChange}
                previewType={previewType}
                isUsedLink={isUsedLink}
                disableUpload={disableUpload}
                disableDownload={disableDownload}
                multiple={multiple}
            />
        )
    }, [
        withoutPopper,
        uploadyOptions,
        previewType,
        disableUpload,
        enableItemActions,
        value,
        uploadFileType,
        onChange,
        id,
        accept,
        autoOpenWhenEmpty,
        isPexels,
        isUsedLink,
        disableDownload,
        multiple
    ])
}
