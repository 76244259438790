import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import type { CSSProperties } from 'react'
import React from 'react'

import { Box } from '../Box'
import { useStyles } from './Text.styles'

type TextStylesNames = Selectors<typeof useStyles>

interface TextProps extends StyleComponentProps<TextStylesNames>, React.ComponentPropsWithoutRef<'div'> {
    inline?: boolean
    ellipsis?: boolean
    color?: string
    size?: number
    lineClamp?: CSSProperties['lineClamp']
    algin?: 'center' | 'left' | 'right'
    fontFamily?: string
    lineHeight?: number | string
    weight?: number
    whiteSpace?: CSSProperties['whiteSpace']
    children?: React.ReactNode | string
}

export const Text = React.forwardRef<HTMLDivElement, TextProps>(
    (
        {
            styles,
            classNames,
            className,
            unstyled,

            inline,
            ellipsis = true,
            color,
            size,
            lineClamp,
            algin,
            weight,
            fontFamily,
            lineHeight,
            whiteSpace = 'nowrap',
            children,
            ...rest
        },
        ref
    ) => {
        const { classes } = useStyles(
            { inline, ellipsis, color, lineClamp, size, algin, weight, whiteSpace, fontFamily, lineHeight },
            { name: 'text', styles, classNames, unstyled }
        )
        return (
            <Box className={clsx(classes.root, className)} {...rest} ref={ref}>
                {children}
            </Box>
        )
    }
)
