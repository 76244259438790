import { useAtomData } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import { useCallback } from 'react'

import { appAtom, applicationIdProxyAtom, currentEnvIdAtom, currentVersionIdAtom, previewAtom, websiteApplicationSettingAtom } from '@/atoms/application/state'
import { defaultPageListAtom, pageListAtom } from '@/atoms/page/state'

export const useApplication = () => {
    return useAtomValue(appAtom)
}

export const useCurrentAppId = () => {
    return useAtomValue(applicationIdProxyAtom).id
}

export const useCurrentVersionId = () => {
    return useAtomValue(currentVersionIdAtom)
}

export const useCurrentEnvId = () => {
    return useAtomValue(currentEnvIdAtom)
}

export const usePageList = () => {
    return useAtomValue(pageListAtom)
}

export const useDefaultPageList = () => {
    return useAtomValue(defaultPageListAtom)
}

export const usePreview = () => {
    return useAtomValue(previewAtom)
}

export const useAppLanguageList = () => {
    return useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.language?.list ?? [], [])
    )
}
