import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

export const useStyles = createStyles(() => ({
    tooltip: css({
        borderRadius: 4,
        color: '$colorWhite',
        backgroundColor: '$colorGray900',
        padding: '4px 8px',
        fontSize: 12
    })
}))
