import type { ApplicationSettingThemeColor } from '@lighthouse/core'
import React from 'react'

type FillPickerContextValue = {
    palettes: ApplicationSettingThemeColor[]
}

const FillPickerContext = React.createContext<FillPickerContextValue>({ palettes: [] })

export const FillPickerProvider = FillPickerContext.Provider
export const useFillPickerContext = () => React.useContext(FillPickerContext)
