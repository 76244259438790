import styled from 'styled-components'

export const ValueContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 36px;
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);

    &.enable-edit {
        /* padding: 7px 11px 0; */
        padding: 0 8px;
    }

    &.overflow {
        /* max-width: calc(100% - 2px); */
        overflow: hidden;
        white-space: nowrap;
    }
`

export const ValuePlaceHolder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* padding: 7px 11px 0; */
    padding: 0 11px;
    text-decoration: none;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-gray-300);
    background-color: var(--color-gray-50);
    z-index: 1;
`

export const ValuePreview = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
`
