import type React from 'react'
import { useEffect } from 'react'

import { usePopoverContext } from '../Popover.context'

interface PopoverStateProps {
    anchorEl?: HTMLElement | null
}

export const PopoverAnchorEl: React.FC<PopoverStateProps> = ({ anchorEl }) => {
    const ctx = usePopoverContext()
    // const anchorElRef = useRef(anchorEl)
    // const _ref = useMergeRefs([ctx.reference, anchorElRef])
    useEffect(() => {
        if (anchorEl) {
            ctx.reference({
                getBoundingClientRect: () => anchorEl.getBoundingClientRect(),
                getClientRects: () => anchorEl.getClientRects()
                // ...ctx.interactionProps.getReferenceProps()
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorEl])

    return null
}

PopoverAnchorEl.displayName = 'Bui-PopoverAnchorEl'
