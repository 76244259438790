type HookFunction<T extends unknown[]> = (...args: T) => void

interface Tap<T extends unknown[]> {
    name: string
    fn: HookFunction<T>
    priority: number
}

export class SyncHook<T extends unknown[]> {
    private readonly taps: Tap<T>[]

    constructor(private readonly args: string[] = []) {
        this.taps = []
    }

    // 注册一个带有优先级的钩子函数
    tap(name: string, fn: HookFunction<T>, priority = 0): void {
        this.taps.push({ name, fn, priority })
        // 按优先级降序排列
        this.taps.sort((a, b) => b.priority - a.priority)
    }

    // 触发所有钩子函数
    call(...args: T): void {
        if (args.length !== this.args.length) {
            throw new Error(`Expected ${this.args.length} arguments, but got ${args.length}`)
        }

        this.taps.forEach(tap => {
            tap.fn(...args)
        })
    }
}
