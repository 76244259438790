import { useContextPopoverHeight, usePopoverContext } from '@byecode/ui'
import type { DataSourceAbstract, Field, FieldType, ViewFieldProtocol } from '@lighthouse/core'
import { DataSourceType } from '@lighthouse/core'
import { generateName, nanoid } from '@lighthouse/tools'
import { find, findIndex } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useHotkeys } from 'react-hotkeys-hook'

import {
    FieldNameMap,
    FieldTypeColorMap,
    InnerTypeMapByFieldType,
    joinDataSourceFieldType,
    notFieldTypeSwitch,
    SYSTEM_FIELD
} from '../../../constants/dataSource'
import { getIsAppointField, getIsJoinField } from '../../../utils'
import type { TableFieldConfigure } from '../../EditorConfigViewRenderer'
import EditorConfigViewRenderer, { extraViewKeys } from '../../EditorConfigViewRenderer'
import { ErrorMessage } from '../../ErrorMessage'
import { FieldTypeTag } from '../../FieldTypeTag'
import { TableFieldInput } from '../../TableFieldInput'
import { TYPELISTITEMS } from './constants'
import { JoinTableField } from './JoinTableField'
import * as SC from './styles'
import type { EditStage } from './types'

export interface EditorProps {
    stage?: EditStage
    dataSource: DataSourceAbstract
    columns: ViewFieldProtocol[]
    fieldId: string
    dataSourceList: DataSourceAbstract[]
    onOk?: (data: Field) => void
    onCancel?: () => void
}

export const TableFieldEditor: React.FC<EditorProps> = ({
    stage = 'edit',
    dataSource,
    columns,
    fieldId,
    dataSourceList,
    onOk,
    onCancel
}) => {
    const { context } = usePopoverContext()
    const [maxHeight, internalRef] = useContextPopoverHeight({ context })
    const { schema, type: dataSourceType = DataSourceType.dataSource, sync: dataSourceSync, viewOptions, id: dsId } = dataSource
    const isEmittedShortcutRef = useRef(false)
    const field = useMemo(() => schema[fieldId], [fieldId, schema])

    const initialType = useMemo(() => {
        if (!field) {
            return {}
        }

        if (dataSourceType === DataSourceType.joinDataSource && field.join?.joinDsId !== viewOptions.joinConfig?.primaryDsId && getIsJoinField(field) ) {
            return {
                currentType: field.type,
                joinField: field
            }
        }
        return {
            currentType: field.type
        }
    }, [dataSourceType, field, viewOptions.joinConfig?.primaryDsId])

    const schemaNames = useMemo(() => Object.values(schema).map(({ name }) => name), [schema])

    const {
        control,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            keywords: '',
            title: field?.name || ''
        }
    })

    const { keywords = '', title } = useWatch({ control })
    const [state, setState] = useState<Partial<TableFieldConfigure>>(initialType)
    const [currentStage, setCurrentStage] = useState<EditStage>(stage)
    const { currentType, joinField } = state

    const isJoinDataSourceFormulaField = dataSourceType === DataSourceType.joinDataSource && field?.type === 'formula'
    const disableTypeSwitch =
        !!fieldId &&
        (getIsAppointField(fieldId, SYSTEM_FIELD) || notFieldTypeSwitch.has(field?.type) || !!joinField || isJoinDataSourceFormulaField)

    const handleItemClick = useCallback(
        (type: FieldType) => {
            setState({
                currentType: type
            })
            setCurrentStage('set')
        },
        [setState]
    )

    const handleSelectJoinField = useCallback(
        (field: Field) => {
            setCurrentStage('set')
            setState({
                currentType: field.type,
                joinField: field
            })
        },
        [setState]
    )

    const handleTypeSwitch = useCallback(() => {
        if (disableTypeSwitch) {
            return
        }
        setCurrentStage('edit')
    }, [disableTypeSwitch])

    const fieldName = useMemo(() => {
        if (title) {
            return title
        }
        if (joinField) {
            return joinField.name
        }
        if (currentType) {
            return generateName(schemaNames, FieldNameMap[currentType])
        }
        return ''
    }, [title, joinField, currentType, schemaNames])

    const getFieldId = useCallback(
        (fieldId: string | undefined) => {
            if (fieldId) {
                return fieldId
            }
        },
        []
    )

    const getFieldDataSource = useCallback(() => {
        if (joinField) {
            const { id, dsId, dsName } = joinField
            return {
                join: {
                    joinDsId: dsId,
                    joinFieldId: id
                },
                dsId,
                dsName
            }
        }
        return {
            dsId,
            dsName: dataSource.name
        }
    }, [dataSource.name, dsId, joinField])

    const handleOk = useCallback(
        (data: Field) => {
            const isError = errors.title?.message
            if (isError) {
                return
            }
            const fieldId = getFieldId(data.id)
            const fieldDataSource = getFieldDataSource()
            const name = fieldName
            const fieldType = currentType || 'text'
            const field = {
                ...data,
                ...fieldDataSource,
                id: fieldId,
                type: fieldType,
                name,
                innerType: InnerTypeMapByFieldType[fieldType]
            } as Field
            onOk?.(field)
            onCancel?.()
        },
        [currentType, errors.title?.message, fieldName, getFieldDataSource, getFieldId, onCancel, onOk]
    )

    const list = useMemo(() => {
        const searchedTypeItems = TYPELISTITEMS.map(({ items, ...restGroup }) => {
            const filteredItems = items.map(({ value, ...restItem }) => {
                if (dataSourceType === DataSourceType.joinDataSource && !joinDataSourceFieldType.has(restItem.name)) {
                    return false
                }

                return value.includes(keywords) ? { ...restItem, value } : false
            })
            return filteredItems.some(Boolean) ? { ...restGroup, items: filteredItems } : false
        }).filter(Boolean)

        return searchedTypeItems.map(group => {
            if (!group) {
                return null
            }
            const { groupName, groupDescription, items } = group
            return (
                <SC.ListGroup key={groupName}>
                    <SC.ListGroupHeader>
                        <SC.ListGroupTitle>{groupName}</SC.ListGroupTitle>
                        {groupDescription && <SC.ListGroupDescription>{groupDescription}</SC.ListGroupDescription>}
                    </SC.ListGroupHeader>
                    {items.map(item => {
                        if (!item) {
                            return null
                        }
                        const { name, value, disable, remark } = item
                        const selected = name === currentType
                        return (
                            <SC.ListItem
                                selected={selected}
                                key={name}
                                onClick={() => {
                                    !disable && handleItemClick(name)
                                }}
                            >
                                <SC.ListItemInfo>
                                    <SC.ItemIcon id="" type={name} fill={FieldTypeColorMap[name]} />
                                    <SC.ListItemText>{value}</SC.ListItemText>
                                    {/* <SC.CheckedIcon type="Tick" /> */}
                                    {remark && <SC.Remark>{remark}</SC.Remark>}
                                </SC.ListItemInfo>
                                <FieldTypeTag type={name} />
                            </SC.ListItem>
                        )
                    })}
                </SC.ListGroup>
            )
        })
    }, [dataSourceType, keywords, currentType, handleItemClick])

    const joinList = useMemo(() => {
        if (dataSourceType !== DataSourceType.joinDataSource || !viewOptions?.joinConfig?.conditions) {
            return null
        }
        const joinedDs = viewOptions.joinConfig.conditions.map(condition => find(item => item.id === condition.joinedDsId, dataSourceList))
        return (
            <SC.ListGroup>
                <SC.ListGroupHeader>
                    <SC.ListGroupTitle>连接表</SC.ListGroupTitle>
                </SC.ListGroupHeader>
                {joinedDs.map(item => {
                    if (!item) {
                        return null
                    }
                    return <JoinTableField key={item.id} currentSchema={schema} dataSource={item} onSelect={handleSelectJoinField} />
                })}
            </SC.ListGroup>
        )
    }, [dataSourceList, dataSourceType, handleSelectJoinField, schema, viewOptions.joinConfig?.conditions])

    const editExtraView = useMemo(() => {
        return (
            <>
                <SC.SearchWrapper>
                    <Controller
                        name="keywords"
                        control={control}
                        render={({ field }) => (
                            <SC.TypeSearch
                                prefix={<SC.Icon type="Search" size={16} fill="var(--color-gray-400)" />}
                                suffix={
                                    field.value && (
                                        <SC.Icon
                                            type="CloseCircle"
                                            size={16}
                                            fill="var(--color-gray-500)"
                                            onClick={() => field.onChange('')}
                                        />
                                    )
                                }
                                placeholder="搜索"
                                size="md"
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </SC.SearchWrapper>
                <SC.List>
                    {list}
                    {joinList}
                </SC.List>
            </>
        )
    }, [control, joinList, list])

    const getFieldConfigWidth = useCallback((type: FieldType) => {
        return 280
    }, [])

    const setExtraView = useMemo(() => {
        if (!currentType) {
            return null
        }
        const config = {
            currentType,
            joinField
        }
        const initFieldName = joinField ? joinField.name : FieldNameMap[currentType]
        if (!initFieldName) {
            return null
        }
        return (
            <SC.SetView width={getFieldConfigWidth(currentType)}>
                <SC.TypeSwitcher onClick={handleTypeSwitch} disable={disableTypeSwitch}>
                    {/* <SC.TypeSwitcher disable> */}
                    <SC.TypeDesc>
                        <SC.ItemIcon id="" type={currentType} fill={FieldTypeColorMap[currentType]} />
                        <SC.TypeName>{initFieldName}</SC.TypeName>
                    </SC.TypeDesc>
                    <SC.Icon fill="var(--color-gray-500)" type="ArrowDownSmall" />
                </SC.TypeSwitcher>
                <EditorConfigViewRenderer
                    dataSourceList={dataSourceList}
                    dataSource={dataSource}
                    columns={columns}
                    config={config}
                    field={field}
                    onOk={handleOk}
                    onCancel={onCancel}
                />
            </SC.SetView>
        )
    }, [
        columns,
        currentType,
        dataSource,
        dataSourceList,
        disableTypeSwitch,
        field,
        getFieldConfigWidth,
        handleOk,
        handleTypeSwitch,
        joinField,
        onCancel
    ])

    const handleDummyOk = useCallback(() => {
        if (!currentType) {
            return
        }
        const initFiled = { id: fieldId, type: currentType } as Field
        handleOk(initFiled)
    }, [currentType, fieldId, handleOk])

    const placeHolder = useMemo(() => {
        if (!currentType || !!joinField) {
            return '字段名'
        }
        return FieldNameMap[currentType]
    }, [currentType, joinField])

    useHotkeys(
        'Enter',
        () => {
            handleDummyOk()
            isEmittedShortcutRef.current = true
        },
        {
            enableOnTags: ['INPUT'],
            enabled: !!joinField || (!extraViewKeys.includes(currentType || '') && !isEmittedShortcutRef.current)
        }
    )

    const extraView = currentStage === 'edit' ? editExtraView : setExtraView

    // const propertyTitleProps = {
    //     defaultValue: field?.name || '',
    //     autoSelect: true,
    //     placeholder: '字段名',
    //     borderRadius: false
    // }

    return (
        <SC.EditorContainer style={{ maxHeight }} ref={internalRef}>
            <SC.EditorHeader>
                <ErrorMessage name="title" enablePortal={false} errors={errors}>
                    <Controller
                        name="title"
                        control={control}
                        rules={{
                            required: '请输入字段名',
                            validate(val) {
                                const schemaIds = []
                                const schemaNames = []
                                for (const [schemaId, schemaItem] of Object.entries(schema)) {
                                    schemaIds.push(schemaId)
                                    schemaNames.push(schemaItem.name)
                                }
                                const schemaIndex = findIndex(name => name === val, schemaNames)
                                const isRepeated = schemaIndex !== -1 && schemaIds[schemaIndex] !== fieldId
                                return isRepeated && '同表内不能有重名字段'
                            }
                        }}
                        render={({ field }) => (
                            <TableFieldInput autoSelect value={field.value} placeholder={placeHolder} onChange={field.onChange} />
                        )}
                    />
                </ErrorMessage>
            </SC.EditorHeader>
            {/* <SC.PropertyLine /> */}
            {extraView}
        </SC.EditorContainer>
    )
}
