import { NativeSelect } from '@mantine/core'
import type { NodeView } from '@tiptap/react'
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const Container = styled(NodeViewWrapper)`
    position: relative;
`
const Select: typeof NativeSelect = styled(NativeSelect)`
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
`

export const CodeBlockSelector: FC<NodeView<typeof NodeViewContent>> = props => {
    const { updateAttributes, extension } = props

    // bug、目前库是默认注册了所有语言
    const options = useMemo(() => {
        return extension.options.lowlight.listLanguages().filter(extension.options.lowlight.registered)
    }, [extension.options.lowlight])

    return (
        <Container>
            <pre>
                <NodeViewContent as="code" className="hljs" />
            </pre>

            <Select
                contentEditable={false}
                size="xs"
                w={80}
                defaultValue={extension.options.defaultLanguage}
                data={options}
                onChange={event => updateAttributes({ language: event.target.value })}
            />
        </Container>
    )
}
