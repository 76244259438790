import 'highlight.js/styles/github-dark.css'

import { mergeAttributes } from '@tiptap/core'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import { ReactNodeViewRenderer } from '@tiptap/react'
// import css from 'highlight.js/lib/languages/css'
// import js from 'highlight.js/lib/languages/javascript'
// import ts from 'highlight.js/lib/languages/typescript'
// import html from 'highlight.js/lib/languages/xml'
import { lowlight } from 'lowlight'
import React from 'react'

import { CodeBlockSelector } from './CodeBlockSelector'

// lowlight.registerLanguage('html', html)
// lowlight.registerLanguage('css', css)
// lowlight.registerLanguage('js', js)
// lowlight.registerLanguage('ts', ts)

export const CustomizeCodeBlock = CodeBlockLowlight.extend({
    // renderHTML({ node, HTMLAttributes }) {
    //     const codeClass = ['hljs']
    //     if (node.attrs.language) {
    //         const languageClass = `${this.options.languageClassPrefix}${node.attrs.language as string}`
    //         codeClass.push(languageClass)
    //     }
    //     return [
    //         'pre',
    //         mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    //         [
    //             'code',
    //             {
    //                 class: codeClass.join(' ')
    //             },
    //             0
    //         ]
    //     ]
    // },

    addNodeView() {
        return ReactNodeViewRenderer(CodeBlockSelector)
    }
}).configure({
    lowlight,
    defaultLanguage: 'sql'
})
