import { Checkbox } from '@mantine/core'
import cls from 'classnames'
import React, { useCallback } from 'react'
import styled from 'styled-components'

export interface ListCheckerProps {
    index?: number
    checked?: boolean
    checkable?: boolean
    isRecordSelecting?: boolean
    onChange?: (checked: boolean) => void
}

const ListRecordIndex = styled.p`
    position: absolute;
    color: var(--color-gray-400);
    opacity: 1;
`

const ListRecordCheckerWrapper = styled.div`
    position: absolute;
    opacity: 0;
    top: 50%;
    margin-top: -8px;
`

export const ListCheckerWrapper = styled.div`
    position: sticky;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    min-height: 36px;
    flex-shrink: 0;
    font-size: var(--font-size-normal);

    &.list-checkable {
        &.list-checked,
        &:not(.list-checked):hover {
            ${ListRecordCheckerWrapper} {
                opacity: 1;
            }
            ${ListRecordIndex} {
                opacity: 0;
            }
        }
    }
`

export const ListChecker: React.FC<ListCheckerProps> = ({ index, checked, checkable, isRecordSelecting, onChange }) => {
    const handleChange = useCallback(
        (ev: React.MouseEvent | React.ChangeEvent) => {
            ev.stopPropagation()
            if (!checkable) {
                return
            }
            onChange?.(!checked)
        },
        [checkable, checked, onChange]
    )
    return (
        <ListCheckerWrapper className={cls({ 'list-checked': checked, 'list-checkable': checkable })} onClick={handleChange}>
            <ListRecordIndex>{index === undefined ? '#' : index + 1}</ListRecordIndex>
            <ListRecordCheckerWrapper style={isRecordSelecting ? { opacity: 1 } : {}}>
                <Checkbox size="xs" checked={checked} onChange={handleChange} />
            </ListRecordCheckerWrapper>
        </ListCheckerWrapper>
    )
}
