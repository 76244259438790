import type { FieldADTValue, FieldInputADTValue, FieldInputConfigProtocol, FieldType } from '@lighthouse/core'
import { reduce } from 'rambda'

import { fieldConvertValue, isEmptyFieldInputValue, isNumberValue } from '../../utils'
import type { FieldInputError, FieldInputErrorInfo, FieldInputErrorRuleType, FieldInputRuleInfo, FieldInputRules } from './types'

export function getFieldInputError(params: {
    value: FieldInputADTValue
    fieldType: FieldType
    rule: FieldInputRules
    isRepeat?: boolean
    repeatIds?: string[]
}): FieldInputError {
    const { value: fieldInputValue, fieldType, rule, isRepeat, repeatIds } = params
    const { required, repeat, min, max, maxFontNumber, minFontNumber, regular, rangeFontNumber, rangeNumber, validate } = rule
    const { value } = fieldInputValue
    if (isEmptyFieldInputValue(fieldInputValue, fieldType)) {
        if (required?.value) {
            return {
                required: {
                    message: required.label,
                    type: 'required'
                }
            }
        }
        return {}
    }
    if (repeat?.value && isRepeat) {
        return {
            repeat: {
                message: repeat.label,
                type: 'repeat',
                value: repeatIds
            }
        }
    }

    if (min?.value && Number(min?.value) > Number(value)) {
        return {
            min: {
                message: min.label,
                type: 'min'
            }
        }
    }
    if (max?.value && Number(max?.value) < Number(value)) {
        return {
            max: {
                message: max.label,
                type: 'max'
            }
        }
    }
    if (rangeNumber?.value) {
        const numberValue = Number(value)
        if (numberValue > Number(rangeNumber?.value[1]) || numberValue < Number(rangeNumber?.value[0])) {
            return {
                maxFontNumber: {
                    message: rangeNumber.label,
                    type: 'rangeNumber'
                }
            }
        }
    }
    if (minFontNumber?.value && (typeof value === 'string' ? value : '').length < Number(minFontNumber?.value)) {
        return {
            minFontNumber: {
                message: minFontNumber.label,
                type: 'repeat'
            }
        }
    }
    if (maxFontNumber?.value && (typeof value === 'string' ? value : '').length > Number(maxFontNumber?.value)) {
        return {
            maxFontNumber: {
                message: maxFontNumber.label,
                type: 'maxFontNumber'
            }
        }
    }
    if (rangeFontNumber?.value) {
        const textNumber = (typeof value === 'string' ? value : '').length
        if (textNumber > Number(rangeFontNumber?.value[1]) || textNumber < Number(rangeFontNumber?.value[0])) {
            return {
                maxFontNumber: {
                    message: rangeFontNumber.label,
                    type: 'rangeFontNumber'
                }
            }
        }
    }
    if (regular?.value) {
        if (regular?.value?.test(typeof value === 'string' ? value : '')) {
            return {}
        }
        return {
            maxFontNumber: {
                message: regular.label,
                type: 'maxFontNumber'
            }
        }
    }
    if (validate && Object.keys(validate).length > 0) {
        const validateFunction = function (): FieldInputErrorInfo | undefined {
            const fcArr = Object.entries(validate)
            for (const [name, fc] of fcArr) {
                const res = fc(fieldInputValue)
                if (typeof res === 'string' || !res) {
                    return {
                        message: typeof res === 'boolean' ? '' : res,
                        type: name as FieldInputErrorRuleType
                    }
                }
            }
        }
        const validateRes = validateFunction()
        if (!validateRes) {
            return {}
        }
        return {
            validate: validateRes
        }
    }
    return {}
}

export function getTextFontRule(params: {
    config: FieldInputConfigProtocol
    title: string
}): null | [FieldInputErrorRuleType, { label: string; value: undefined | number | number[] }] {
    const { config, title } = params
    const { required, noRepeat, inputType } = config
    if (inputType === 'text') {
        const { text } = config
        const { minFontNumber, maxFontNumber } = text ?? {}
        if (isNumberValue(minFontNumber) && isNumberValue(maxFontNumber)) {
            return [
                'rangeFontNumber',
                {
                    label: `请输入 ${minFontNumber}~${maxFontNumber} 个字`,
                    value: [Number(minFontNumber), Number(maxFontNumber)]
                }
            ]
        }
        if (isNumberValue(maxFontNumber)) {
            return [
                'maxFontNumber',
                {
                    label: `请输入至多 ${maxFontNumber} 个字`,
                    value: maxFontNumber
                }
            ]
        }
        if (isNumberValue(minFontNumber)) {
            return [
                'minFontNumber',
                {
                    label: `请输入至少 ${minFontNumber} 个字`,
                    value: minFontNumber
                }
            ]
        }
    }

    return null
}

export function getNumberSizeRule(params: {
    config: FieldInputConfigProtocol
    title: string
}): null | [FieldInputErrorRuleType, { label: string; value: undefined | number | number[] }] {
    const { config, title } = params
    const { required, noRepeat, inputType } = config
    if (inputType === 'number') {
        const { number } = config
        const { min = '', max = '' } = number ?? {}
        if (isNumberValue(min) && isNumberValue(max)) {
            return [
                'rangeNumber',
                {
                    label: `${title}必须在 ${min}~${max} 之间`,
                    value: [Number(min), Number(max)]
                }
            ]
        }
        if (isNumberValue(max)) {
            return [
                'max',
                {
                    label: `${title}必须≤${max}`,
                    value: Number(max)
                }
            ]
        }
        if (isNumberValue(min)) {
            return [
                'min',
                {
                    label: `${title}必须≥${min}`,
                    value: Number(min)
                }
            ]
        }
    }

    return null
}

/** 获取fieLdInput 的规则 */
export function getFieldInputRules(params: {
    config: FieldInputConfigProtocol
    title: string
    id: string
    custom?: [FieldInputErrorRuleType, FieldInputRuleInfo]
}): FieldInputRules {
    const { config, custom, title, id } = params
    const { required, noRepeat, inputType, isRequiredWhenHidden } = config
    const  isExitEle =  isRequiredWhenHidden ? !!document.querySelector(`[data-node-id = ${id}]`) : true
    const RuleArr: ([FieldInputErrorRuleType, { label: string; value: RegExp | undefined | boolean | number | number[] }] | null)[] = [
        [
            'required',
            {
                label: `${title}不能为空`,
                value: (required && isExitEle) ?? false
            }
        ],
        getNumberSizeRule({ config, title }),
        getTextFontRule({ config, title }),
        inputType === 'text' && config.text?.isRegular
            ? [
                  'regular',
                  {
                      label: config.text?.regularErrorLabel ?? '',
                      value: config.text?.regular ? new RegExp(config.text?.regular) : undefined
                  }
              ]
            : null,
        custom ?? null
    ]
    return Object.fromEntries(
        reduce<[FieldInputErrorRuleType, FieldInputRuleInfo] | null, [FieldInputErrorRuleType, FieldInputRuleInfo][]>(
            (pre, cur) => (cur ? [...pre, cur] : pre),
            [],
            RuleArr
        )
    )
}


export function isValidColor(color: string): boolean {
    // 正则表达式用于匹配合法的颜色格式：RGB 或十六进制
    const hexRegex = /^#([\dA-Fa-f]{6}|[\dA-Fa-f]{3})$/
    const rgbRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/

    if (hexRegex.test(color)) {
        return true
    }

    const rgbMatch = rgbRegex.exec(color)
    if (rgbMatch) {
        // 验证 RGB 值是否在 0 到 255 之间
        const [r, g, b] = rgbMatch.slice(1).map(Number)
        return r <= 255 && g <= 255 && b <= 255
    }

    return false
}
