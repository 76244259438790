import { singleTextEllipsis } from '@byecode/ui'
import type { FieldADTValue, HighLightConditions, NumberField, NumberValue } from '@lighthouse/core'
import { mergeRefs, nanoid } from '@lighthouse/tools'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { fieldConvertValue } from '../../utils'
import { CellHighlighter } from '../Highlighter'
import { TooltipList, useWidth } from '../TooltipList'
import { TooltipText } from '../TooltipText'

interface NumberValuePreviewerProps {
    data?: NumberValue | NumberValue[]
    field: NumberField
    width?: number
    isWrap?: boolean
    isBold?: boolean
    styleConditions?: HighLightConditions
}

interface NumberListPreviewerProps {
    field: NumberField
    data: NumberValue[]
    width: number
    isWrap: boolean
    isBold?: boolean
}
interface NumberItemPreviewerProps {
    id: string
    field: NumberField
    data: NumberValue
    isWrap: boolean
    isBold?: boolean
    isLast?: boolean
    disableTooltip?: boolean
}

const SCxNumber = styled.div<{ isWrap: boolean; isBold?: boolean }>`
    position: relative;
    white-space: ${({ isWrap }) => (isWrap ? 'warp' : 'nowrap')};
    word-break: break-word;
    overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'hidden')};
    text-overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'ellipsis')};
    font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
`

const SCxNumberListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean }>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    overflow: hidden;
`

const SCxItem = styled.div<{ isLast?: boolean }>`
    display: flex;
    align-items: center;
    ${({ isLast }) => isLast && singleTextEllipsis}
`

const SCxSplit = styled.div`
    display: flex;
`

export const NumberItemPreviewer: React.FC<NumberItemPreviewerProps> = ({ id, field, data, isWrap, isBold, isLast, disableTooltip }) => {
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)
    const fieldValue = {
        ...field,
        value: data
    } as FieldADTValue
    const text = fieldConvertValue(fieldValue)
    return (
        <TooltipText
            title={text}
            disabled={isWrap || disableTooltip}
            render={ref => {
                return (
                    <SCxItem ref={mergeRefs([ref, widthRef])} isLast={isLast}>
                        <SCxNumber isWrap={isWrap} isBold={isBold}>
                            {text}
                        </SCxNumber>
                    </SCxItem>
                )
            }}
        />
    )
}

export const NumberListPreviewer: React.FC<NumberListPreviewerProps> = ({ data, field, width, isWrap, isBold }) => {
    return (
        <TooltipList
            data={data.map((label, index) => ({ label, value: String(index) }))}
            width={width}
            key={data.join('-')}
            disabled={isWrap}
            render={visibleNum => {
                const renderList = data.slice(0, visibleNum)

                return (
                    <SCxNumberListWrapper isPadding={visibleNum < data.length} isWrap={isWrap}>
                        {renderList.map((item, index) => (
                            <SCxSplit key={index}>
                                <NumberItemPreviewer
                                    id={String(index)}
                                    field={field}
                                    data={item}
                                    isWrap={isWrap}
                                    disableTooltip
                                    isBold={isBold}
                                    isLast={index === renderList.length - 1}
                                />
                                {index !== renderList.length - 1 && ','}
                            </SCxSplit>
                        ))}
                    </SCxNumberListWrapper>
                )
            }}
        />
    )
}

export const NumberValuePreviewer: React.FC<NumberValuePreviewerProps> = ({
    field,
    data,
    width = 160,
    isWrap = true,
    isBold,
    styleConditions
}) => {
    const { id, type } = field

    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (!data && data !== 0) {
            return ''
        }
        if (Array.isArray(data)) {
            return <NumberListPreviewer data={data} field={field} width={width} isWrap={isWrap} isBold={isBold} />
        }

        const id = nanoid()
        return <NumberItemPreviewer id={id} field={field} isWrap={isWrap} data={data} isBold={isBold} isLast />
    }, [data, field, isBold, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={id} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
