import { DIRECTION } from '@lighthouse/core'
import { mergeRefs } from '@lighthouse/tools'
import { useDrag } from '@use-gesture/react'
import React, { forwardRef, Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLatest } from 'react-use'

import { useSharedConfigDisabledWithVersion } from '../../../contexts/SharedConfigContext'
import { Gap } from '../Base/Gap'
import type { FlowLayoutNodeProps } from '../Base/Node'
import { Node } from '../Base/Node'
import { useFlowContainerContext } from '../Context'
import { applyTransform } from '../utils/transform'
import { SortableContainerContext, useResizeEventsContext, useSensorContext, useSortableContext, useSortableMonitor } from './Context'
import { NodeMask } from './Mask'
import { Placeholder } from './Placeholder'
import { ResizeHandler } from './ResizeHandler'
import { useReturnTransform } from './useReturnTransform'

export const SortableNodeRender = forwardRef<HTMLDivElement, FlowLayoutNodeProps>(
    ({ data, style, disabled: propDisabled, ...props }, ref) => {
        const disabled = propDisabled ?? data.disabled
        const {
            selectedId,
            onSelectedIdChange,
            boxSelectionIds,
            onBoxSelectionIdsChange,
            draggableNodes,
            activeId,
            triggerActionId,
            setTriggerActionId
        } = useSortableMonitor()
        const { onStart } = useSensorContext()
        const { parentId, items } = useSortableContext()
        const { nodeUnitWidth } = useFlowContainerContext()

        const latestEvents = useLatest({ onSelectedIdChange, onBoxSelectionIdsChange, onStart })

        const innerRef = useRef<HTMLDivElement>(null)
        const rect = useRef<DOMRect | null>(null)

        useLayoutEffect(() => {
            const el = innerRef.current
            if (!el) {
                return
            }

            if (activeId) {
                rect.current = el.getBoundingClientRect()
            }
        }, [activeId])

        // 注册栅格节点
        useEffect(() => {
            if (disabled) {
                return
            }

            const el = innerRef.current
            if (!el) {
                return
            }

            draggableNodes.current.set(data.id, { node: el, data })
        }, [data, draggableNodes, disabled])

        const index = items.indexOf(data.id)
        const isCurrentSelected = selectedId === data.id
        const isDragging = !!activeId
        const isCurrentDragging = activeId === data.id

        const disabledWithVersion = useSharedConfigDisabledWithVersion()

        const bind = useDrag(
            state => {
                if (disabled || data.virtual) {
                    return
                }

                // 分离触发对象
                if (triggerActionId.current && triggerActionId.current !== data.id) {
                    return
                }

                if (!triggerActionId.current) {
                    setTriggerActionId(data.id)
                }

                if (isCurrentSelected) {
                    return
                }

                // 多选
                if (state.shiftKey && state.tap) {
                    window.getSelection()?.removeAllRanges()
                    latestEvents.current.onBoxSelectionIdsChange?.([data.id])
                    return
                }

                // 选中
                if (state.tap) {
                    latestEvents.current.onSelectedIdChange?.(data.id)
                    latestEvents.current.onBoxSelectionIdsChange?.([])

                    return
                }

                if (data.static) {
                    return
                }

                // 拖拽事件
                if (state.first) {
                    if (disabledWithVersion) {
                        return
                    }
                    latestEvents.current.onStart(
                        { x: state.initial[0], y: state.initial[1] },
                        data,
                        (state.currentTarget as HTMLElement).getBoundingClientRect()
                    )
                }
            },
            {
                // enabled: !disabled && !data.static,
                filterTaps: true,
                tapsThreshold: 2,
                triggerAllEvents: true,
                preventScroll: true,
                pointer: { mouse: false, touch: false, capture: false }
            }
        )

        const { onResizeStart, onResizing, onResizeEnd, onFillSize } = useResizeEventsContext()

        const transform = useReturnTransform({ index, rect, node: innerRef })

        const mergeStyle: React.CSSProperties = {
            ...style,
            pointerEvents: isCurrentDragging || (isDragging && disabled) ? 'none' : undefined,
            cursor: isDragging ? 'grabbing' : isCurrentSelected ? 'initial' : 'pointer',
            touchAction: 'manipulation',
            opacity: isCurrentDragging ? 0.8 : disabled && isDragging ? 0.75 : undefined,
            transform: applyTransform(transform)
        }

        const isBasicNode = data.type === 'block'
        const isContainer = data.type === 'container'

        const omitClickCaptureDOMAttributes = useMemo(() => {
            const { onClickCapture, ...rest } = bind()
            return rest
        }, [bind])

        const [isMounted, setIsMounted] = useState(false)

        useEffect(() => {
            setIsMounted(true)
        }, [])

        return (
            <Node
                disabled={disabled}
                tabIndex={0}
                {...omitClickCaptureDOMAttributes}
                ref={mergeRefs([ref, innerRef])}
                data={data}
                style={mergeStyle}
                {...props}
            >
                {isContainer && (
                    <>
                        <Placeholder
                            data-relative-id={data.id}
                            data-actual-relative-id={data.virtual ? parentId : undefined}
                            data-virtual-parent-id={data.virtual ? data.id : undefined}
                            data-type="placeholder-begin"
                            style={{
                                flexGrow:
                                    data.data.direction === DIRECTION.horizontal
                                        ? !data.data.alignX || data.data.alignX === 'flex-start'
                                            ? 0
                                            : 1
                                        : !data.data.alignY || data.data.alignY === 'flex-start'
                                        ? 0
                                        : 1
                            }}
                        />
                        {data.children && (
                            <SortableContainerContext
                                parentId={data.virtual ? parentId : data.id}
                                items={data.children.map(item => item.id)}
                            >
                                {data.children.map((item, index, array) => (
                                    <Fragment key={item.id}>
                                        <SortableNodeRender data={item} onDataDrawerVisible={props.onDataDrawerVisible} />
                                        {index !== array.length - 1 && (
                                            <Gap
                                                containerId={data.id}
                                                data-relative-id={item.id}
                                                data-actual-relative-id={data.virtual ? parentId : undefined}
                                                data-virtual-parent-id={data.virtual ? data.id : undefined}
                                            />
                                        )}
                                    </Fragment>
                                ))}
                            </SortableContainerContext>
                        )}
                        <Placeholder
                            data-relative-id={data.id}
                            data-actual-relative-id={data.virtual ? parentId : undefined}
                            data-virtual-parent-id={data.virtual ? data.id : undefined}
                            data-type="placeholder-after"
                            style={{
                                flexGrow:
                                    data.data.direction === DIRECTION.horizontal
                                        ? data.data.alignX === 'flex-end'
                                            ? 0
                                            : 1
                                        : data.data.alignY === 'flex-end'
                                        ? 0
                                        : 1
                            }}
                        />
                    </>
                )}

                {isBasicNode && !isCurrentSelected && !data.disabled && <NodeMask />}

                {selectedId === data.id &&
                    !data.static &&
                    !data.virtual &&
                    isMounted &&
                    innerRef.current &&
                    createPortal(
                        <>
                            <ResizeHandler
                                direction="right"
                                disabled={isDragging}
                                onDoubleClick={e => {
                                    e.preventDefault()
                                    onFillSize?.(data.id)
                                }}
                                onResizeStart={coordinates =>
                                    onResizeStart?.({ direction: DIRECTION.horizontal, nodeId: data.id, nodeUnitWidth, coordinates })
                                }
                                onResizing={coordinates =>
                                    onResizing?.({ direction: DIRECTION.horizontal, nodeId: data.id, nodeUnitWidth, coordinates })
                                }
                                onResizeEnd={() => onResizeEnd?.({ direction: DIRECTION.horizontal, nodeId: data.id, nodeUnitWidth })}
                            />
                            {!!data.height && (
                                <ResizeHandler
                                    direction="bottom"
                                    disabled={isDragging}
                                    onResizeStart={coordinates =>
                                        onResizeStart?.({ direction: DIRECTION.vertical, nodeId: data.id, nodeUnitWidth, coordinates })
                                    }
                                    onResizing={coordinates =>
                                        onResizing?.({ direction: DIRECTION.vertical, nodeId: data.id, nodeUnitWidth, coordinates })
                                    }
                                    onResizeEnd={() => onResizeEnd?.({ direction: DIRECTION.vertical, nodeId: data.id, nodeUnitWidth })}
                                />
                            )}
                        </>,
                        innerRef.current
                    )}
            </Node>
        )
    }
)
