import type { AppUser } from '@lighthouse/core'

export const INITIAL_AUTH_DATA = {
    userId: '',
    accessToken: '',
    refreshToken: ''
}

export const INITIAL_USER_DATA: AppUser = {
    userId: '',
    uniqueUserId: '',
    username: '',
    mobile: '',
    email: '',
    avatar: ''
}
