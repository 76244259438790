import React, { forwardRef } from 'react'
import styled from 'styled-components'

const Box = styled.div`
    position: absolute;
    inset: 0;
    z-index: 10;
`

export const NodeMask = forwardRef<HTMLDivElement>((props, ref) => {
    return <Box ref={ref} />
})
