import type { Option } from '@byecode/ui'
import { Flex, IconFont } from '@byecode/ui'
import cls from 'classnames'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

const SCxItem = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const SCxCheckBox = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-gray-400);

    &:not(.active):hover {
        border-color: var(--color-main);
        background-color: var(--color-purple-25);
    }

    &.active {
        border-color: transparent;
        background: var(--color-main);
    }
`

export interface BoolItemProps extends Option, Omit<React.ComponentPropsWithoutRef<'div'>, 'onSelect' | 'value' | 'children'> {}

export const BoolItem = forwardRef<HTMLDivElement, BoolItemProps>((props, ref) => {
    const { label, value, icon, extra, describe, ...rest } = props
    // const { classes } = useStyles({}, { name: 'selectItem', classNames, styles, unstyled })

    return (
        <SCxItem ref={ref} {...rest}>
            <Flex alignItems="center" justifyContent="space-between">
                <Flex gap="8px" styles={{ root: { lineHeight: '18px', overflow: 'hidden' } }}>
                    {typeof icon === 'string' ? <IconFont type={icon} /> : icon}
                    {Number(value) ? (
                        <SCxCheckBox className={cls({ active: Number(value) })}>
                            <IconFont type="CheckboxTick" size={18} fill="var(--color-white)" />
                        </SCxCheckBox>
                    ) : (
                        <SCxCheckBox />
                    )}
                    {/* <Box style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{label}</Box> */}
                </Flex>
            </Flex>
        </SCxItem>
    )
})

export const BoolInput = ({ value }: Option) => {
    return (
        <Flex alignItems="center">
            {Number(value) ? (
                <SCxCheckBox className={cls({ active: Number(value) })}>
                    <IconFont type="CheckboxTick" size={18} fill="var(--color-white)" />
                </SCxCheckBox>
            ) : (
                <SCxCheckBox />
            )}
        </Flex>
    )
}
