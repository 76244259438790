import { IconFont, Tooltip } from '@byecode/ui'
import { Text } from '@mantine/core'
import type { Editor } from '@tiptap/react'
import classNames from 'classnames'
import type { FC } from 'react'
import React from 'react'

import { useTipTapContext } from '../Context'
import { CustomActionIcon } from './styles'

export const OrderedListMenu = () => {
    const editor = useTipTapContext()

    return (
        <Tooltip
            title={
                <div style={{ textAlign: 'center' }}>
                    <Text>有序列表</Text>
                    <Text color="var(--color-gray-400)">⌘+Shift+7</Text>
                </div>
            }
        >
            <CustomActionIcon
                className={classNames({ active: editor?.isActive('orderedList') })}
                onClick={() => editor?.chain().focus().toggleOrderedList().run()}
            >
                <IconFont type="ListNumbers" />
            </CustomActionIcon>
        </Tooltip>
    )
}

export const BulletListMenu = () => {
    const editor = useTipTapContext()

    return (
        <Tooltip
            title={
                <div style={{ textAlign: 'center' }}>
                    <Text>无序列表</Text>
                    <Text color="var(--color-gray-400)">⌘+Shift+8</Text>
                </div>
            }
        >
            <CustomActionIcon
                className={classNames({ active: editor?.isActive('bulletList') })}
                onClick={() => editor?.chain().focus().toggleBulletList().run()}
            >
                <IconFont type="ListBullets" />
            </CustomActionIcon>
        </Tooltip>
    )
}

export const TaskListMenu = () => {
    const editor = useTipTapContext()

    return (
        <Tooltip
            title={
                <div style={{ textAlign: 'center' }}>
                    <Text>待办列表</Text>
                    <Text color="var(--color-gray-400)">⌘+Shift+9</Text>
                </div>
            }
        >
            <CustomActionIcon
                className={classNames({ active: editor?.isActive('taskList') })}
                onClick={() => editor?.chain().focus().toggleTaskList().run()}
            >
                <IconFont type="Todo" />
            </CustomActionIcon>
        </Tooltip>
    )
}
