import { Popover, usePopoverContext } from '@byecode/ui/components/Popover'
import { mergeRefs } from '@byecode/ui/hooks/useMergedRef'
import { useContextPopoverHeight } from '@byecode/ui/hooks/usePopoverHeight'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

import type { PopoverDropdownProps } from '../../Popover/PopoverDropdown'
import { useStyles } from './MenuDropdown.style'

export interface MenuDropdownProps extends PopoverDropdownProps {}

export const MenuDropdown = forwardRef<HTMLDivElement, MenuDropdownProps>((props, ref) => {
    const { className, classNames, styles, unstyled, ...rest } = props
    const { context } = usePopoverContext()
    const { classes } = useStyles({}, { name: 'menu-dropdown', classNames, styles, unstyled })
    const [maxHeight, internalRef] = useContextPopoverHeight({ context })

    return (
        <Popover.Dropdown
            style={{ maxHeight, overflow: 'hidden auto' }}
            className={clsx(classes.menuDropdown, className)}
            ref={mergeRefs(ref, internalRef)}
            {...rest}
        />
    )
})

MenuDropdown.displayName = 'Bui-MenuDropdown'
