import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { Box } from '../Box'

interface BadgeStylesParams {
    radius?: string
    color?: string
    backgroundColor?: string
}

const useStyles = createStyles((params: BadgeStylesParams) => {
    return {
        root: css({
            padding: '2px 6px',
            fontSize: '14px',
            borderRadius: params.radius || '50%',
            color: params.color || 'var(--color-app-main)',
            backgroundColor: params.backgroundColor || 'var(--color-app-main-tint)'
        })
    }
})

export interface BadgeProps
    extends BadgeStylesParams,
        StyleComponentProps<Selectors<typeof useStyles>>,
        React.ComponentPropsWithoutRef<'div'> {}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
    ({ color, backgroundColor, radius, styles, classNames, unstyled, ...rest }, ref) => {
        const { classes } = useStyles({ color, backgroundColor, radius }, { name: 'Badge', styles, classNames, unstyled })

        return <Box {...rest} ref={ref} className={clsx(classes.root)} />
    }
)
