import { IconFont, pointer } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const FormulaDefinitions = styled.div`
    display: flex;
    width: 100%;
    height: 360px;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid #dfdfdd;
`

export const FormulaEmpty = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const FormulaEmptyText = styled.div`
    color: var(--color-gray-400);
    font-size: 12px;
`

export const FormulaAdapters = styled.div`
    position: relative;
    width: 228px;
    height: 100%;
    padding-top: 8px;
    border-right: 1px solid #dfdfdd;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
    }
`

export const AdaptersList = styled.div`
    padding: 0 4px 0 8px;
    height: auto;
`

export const AdaptersHeader = styled.div<{ top?: number }>`
    color: var(--color-gray-500);
    font-size: 12px;
    padding: 5px 12px;
    height: 24px;
    line-height: 12px;
    margin-top: ${({ top }) => (top ? `${top}px` : '0px')};
`

export const DataSourceLi = styled.div``

export const AdaptersLi = styled.div<{ active: boolean; left?: number }>`
    position: relative;
    flex: 1;
    height: 36px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    color: var(--color-black);
    background-color: ${({ active }) => (active ? 'var(--color-gray-100)' : '#fff')};
    padding-left: ${({ left }) => (left ? `${left + 12}px` : '12px')};
    padding-right: 8px;
    ${pointer}
`

export const AdaptersContent = styled.div`
    flex: 1;
    height: 36px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const AdaptersType = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
`

// export const AdaptersTagType = styled.div`
//     padding: 1.5px 8px;
//     font-size: var(--font-size-sm);
//     overflow: hidden;
//     text-overflow: ellipsis;
//     text-align: center;
//     color: var(--color-gray-500);
//     border-radius: 100px;
//     box-sizing: border-box;
//     white-space: nowrap;
//     background: var(--color-gray-100);
// `

export const AdaptersTagTab = styled.div<{ active?: boolean }>`
    width: 34px;
    height: 20px;
    font-size: var(--font-size-sm);
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: var(--color-black);
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    border: 1px solid var(--color-gray-300);
    display: ${({ active }) => (active ? 'block' : 'none')};
`

export const Arrow = styled(IconFont)`
    font-size: 16px;
    margin: 0 10px;
`

export const AdaptersIcon = styled(IconFont)`
    font-size: 16px;
`

export const AdaptersName = styled.div`
    margin-left: 8px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 4px;
`
