import { type FieldBlockInfo, transformInitialValue } from '@lighthouse/block'
import { type InputValueItem, PageType } from '@lighthouse/core'
import {
    getFieldInputInitialValue,
    getWindowUrl,
    transformFilterNormalFilter,
    useApplicationContext,
    useAtomData
} from '@lighthouse/shared'
import { useCallback, useMemo } from 'react'

import { pageAtomFamily } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import * as srv from '@/services'

import { useAccount } from './useAccount'
import { useCurrentAppId, useCurrentEnvId } from './useApplication'
import { useDataSourceList, useRecord } from './useDataSource'
import { usePageDataSourceForVariableSelector } from './usePage'
import { useUserRecord } from './useUserRecord'

export function useFieldBlockDefaultValueRender(pointer: string, initialRecord?: Record<string, string | number>) {
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()
    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const user = useAccount()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const dataSourceList = useDataSourceList(appId)
    const dataSource = useMemo(() => dataSourceList.find(item => item.id === pointer), [dataSourceList, pointer])
    const { schema } = dataSource ?? {}

    const currentRecord = useRecord(appId, envId, curr.datasource?.id ?? '', curr.recordId || '')
    const prevRecord = useRecord(appId, envId, prev.datasource?.id ?? '', prev.recordId || '')
    const { record: userRecord } = useUserRecord()

    const [pageType, pageDsId = ''] = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => [s?.type, s?.dsId], [])
    )

    return useCallback(
        async (fieldBlockInfo: FieldBlockInfo, fieldBlockValueMap?: Record<string, InputValueItem>): Promise<InputValueItem> => {
            const {
                initialValue,
                fieldId,
                inputType,
                id,
                config,
                config: { noRepeat: isCheckRepeat }
            } = fieldBlockInfo
            const field = schema?.[fieldId]
            const defaultInputValue = await getFieldInputInitialValue({
                config,
                fieldType: field?.type ?? 'text',
                record: currentRecord,
                prevRecord,
                currentUser: user,
                userRecord,
                currentPageUrl: getWindowUrl(),
                // select 不同field不需要处理
                dataSourceList,
                personOptions,
                roleOptions,
                departmentOptions,
                onFetchRecord: params =>
                    srv.getRecordByFilter({
                        ...params,
                        appId,
                        envId,
                        filter: transformFilterNormalFilter(params.filter, fieldBlockValueMap),
                        pageId
                    })
            })

            const initKanbanRecord = initialRecord?.[fieldId]
            if (pageType === PageType.edit) {
                return {
                    value: currentRecord?.content?.[fieldId]?.value ?? defaultInputValue,
                    type: inputType,
                    isCheckRepeat,
                    id,
                    fieldType: field?.type,
                    fieldId
                } as InputValueItem
            }
            const finalValue = initKanbanRecord && field ? transformInitialValue(field.type, initKanbanRecord) : defaultInputValue
            return {
                value: finalValue,
                type: inputType,
                fieldType: field?.type,
                isCheckRepeat,
                id,
                fieldId
            } as InputValueItem
        },
        [appId, currentRecord, dataSourceList, envId, initialRecord, pageId, pageType, personOptions, prevRecord, schema, user, userRecord]
    )
}
