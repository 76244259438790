import { IconFont, singleTextEllipsis } from '@byecode/ui'
import type { ApplicationSettingTheme, ButtonShowType } from '@lighthouse/core'
import { ApplicationSettingThemeNavBarMode, ButtonPattern } from '@lighthouse/core'
import { useElementSize } from '@mantine/hooks'
import cls from 'classnames'
import React, { useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'

import * as CM from '../ApplicationHeader/styles'
import { useAppNavbarStylesContext } from '../AppNavbarStylesContext'
import { navbarButtonThemeStyleMap } from '../constant'

interface ApplicationNavBarButtonProps {
    type?: ButtonPattern | 'onlyIcon'
    name?: string
    size?: 'large' | 'small' | 'middle'
    iconSize?: number
    btnType?: 'button' | 'submit' | 'reset'
    icon?: string
    disabled?: boolean
    showType?: ButtonShowType
    onlyShowIcon?: boolean
    isUsedPx?: boolean
    theme?: ApplicationSettingTheme
    // btnTheme?: ApplicationSettingThemeButton
    style?: React.CSSProperties
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onChangeSize?: (size: { width: number; height: number }) => void
}
const buttonSize = () => css`
    border-radius: 6px;
    padding: 0 12px;
`

const Button = styled.button<{ isUsedPx: boolean }>`
    position: relative;
    min-width: 32px;
    font-size: ${({ isUsedPx }) => (isUsedPx ? '14px' : '0.875em')};
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: none;
    gap: 8px;
    border: 1px solid transparent;
    line-height: normal;
    cursor: pointer;
    transition: opacity 0.2s ease-out;
    box-sizing: border-box;

    &.btn-middle {
        height: ${({ isUsedPx }) => (isUsedPx ? '32px' : '2rem')};
        ${buttonSize()}
    }

    &.btn-large {
        height: ${({ isUsedPx }) => (isUsedPx ? '36px' : '2.25rem')};
        ${buttonSize()}
    }

    :hover::before {
        position: absolute;
        top: -1px;
        left: -1px;
        content: '';
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        /* cursor: not-allowed; */
        z-index: 2;
    }
`

const BtnText = styled.div<{ isUsedPx?: boolean }>`
    position: relative;
    /* width: 100%; */
    /* line-height: 16px; */
    /* word-wrap: break-word; */
    white-space: nowrap;
    ${singleTextEllipsis}

    z-index: 3;
`

export const ApplicationNavBarButton: React.FC<ApplicationNavBarButtonProps> = ({
    theme,
    type = ButtonPattern.primary,
    name = '',
    icon = '',
    btnType = 'button',
    disabled = false,
    showType,
    iconSize = 16,
    size = 'middle',
    onlyShowIcon,
    style,
    isUsedPx = true,
    onClick,
    onChangeSize
}) => {
    const { ref: linkBtnRef, width, height } = useElementSize<HTMLButtonElement>()

    const { themeMode } = useAppNavbarStylesContext()

    useEffect(() => {
        onChangeSize?.({
            width,
            height
        })
    }, [height, onChangeSize, width])

    const btnStyle = useMemo(() => {
        const themeStyle = navbarButtonThemeStyleMap.get(themeMode)
        return themeStyle?.[type]
    }, [themeMode, type])

    const isShowName = useMemo(() => showType !== 'icon' && name !== '', [name, showType])

    const isShowIcon = useMemo(() => showType !== 'name' && icon !== '', [icon, showType])

    const btnSizeClassName = `btn-${size}`

    return (
        <Button
            type={btnType}
            disabled={disabled}
            className={onlyShowIcon ? undefined : cls(btnSizeClassName)}
            style={{
                ...style,
                ...btnStyle
            }}
            isUsedPx={isUsedPx}
            onClick={e => {
                onClick?.(e)
            }}
            ref={linkBtnRef}
        >
            {isShowIcon && <CM.Icon type={icon} size={isUsedPx ? '16px' : '1rem'} color={btnStyle?.color} />}
            {isShowName && <BtnText>{name}</BtnText>}
        </Button>
    )
}
