import { uploadManageEvents } from './events'
import type { UseUploadFileSParameter } from './type'

const emitUpload = (params: UseUploadFileSParameter) => {
    uploadManageEvents.emit('upload', params)
}

/**
 * 使用文件管理器的上传方法
 * @date 8/18/2023 - 3:24:23 PM
 *
 * @returns {(params: UseUploadFileSParameter) => void}
 */
export const useUploadFiles = () => {
    return emitUpload
}
