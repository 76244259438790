import { Checkbox, Loading } from '@byecode/ui'
import { Radio } from '@mantine/core'
import cls from 'classnames'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

export interface TableIndexProps {
    index?: number
    recordId?: string
    loading?: boolean
    background?: string
    checked?: boolean
    disabled?: boolean
    type?: 'radio' | 'checkbox'
    style?: React.CSSProperties

    onRecordClick?: (recordId: string) => void
}

const SCxTableRecordIndex = styled.div`
    color: var(--color-gray-400);
    opacity: 1;

    .mantine-Checkbox-input:checked {
        background-color: var(--color-app-main);
        border-color: var(--color-app-main);
    }
`

export const SCxTableCheckerWrapper = styled.div<{ background: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-height: 38px;
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    border-radius: inherit;
`

export const TableIndex: React.FC<TableIndexProps> = React.memo(
    ({ index, checked, background = '', type, recordId, loading, disabled, style, onRecordClick }) => {
        const ele = useMemo(() => {
            switch (type) {
                case 'checkbox': {
                    return (
                        <Checkbox
                            checked={checked}
                            disabled={disabled}
                            size="md"
                            radius="100%"
                            color="var(--color-app-main)"
                            onChange={e => recordId && onRecordClick?.(recordId)}
                        />
                    )
                }
                case 'radio': {
                    return (
                        <Radio
                            color="var(--color-app-main)"
                            checked={checked}
                            disabled={disabled}
                            size="xs"
                            onChange={e => recordId && onRecordClick?.(recordId)}
                        />
                    )
                }
                default: {
                    return null
                }
            }
        }, [checked, disabled, onRecordClick, recordId, type])

        return (
            <SCxTableCheckerWrapper background={background} style={style} onClick={e => e.stopPropagation()}>
                {loading ? <Loading outlined /> : <SCxTableRecordIndex>{ele}</SCxTableRecordIndex>}
            </SCxTableCheckerWrapper>
        )
    }
)
