import { Box, Input } from '@byecode/ui'
import type { NumberRangeArray } from '@lighthouse/core'
import { NumberInput } from '@lighthouse/shared'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import type { CustomProps } from './types'

interface TileCustomNumberFilterProps extends CustomProps {
    disabled?: boolean
    style?: React.CSSProperties
}

const SCxInputWrapper = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    padding: 4px 16px;
    gap: 4px;
    align-items: center;
`

const SCxNumberInput = styled(Input)`
    flex: 1;
    & input[type='number']::-webkit-outer-spin-button,
    & input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    & input[type='number'] {
        -moz-appearance: textfield;
    }
`

export const CustomNumberFilter: React.FC<TileCustomNumberFilterProps> = ({ customValue, disabled, style, onCustomChange }) => {
    const [range, setRange] = useState(customValue)

    const handleMinChange = useCallback(
        (val: number | undefined) => {
            const newValue: NumberRangeArray = [val, range?.[1]]
            setRange(newValue)
            onCustomChange?.(newValue)
        },
        [onCustomChange, range]
    )

    const handleMaxChange = useCallback(
        (val: number | undefined) => {
            const newValue: NumberRangeArray = [range?.[0], val]
            setRange(newValue)
            onCustomChange?.(newValue)
        },
        [onCustomChange, range]
    )
    return (
        <SCxInputWrapper style={style}>
            <SCxNumberInput
                disabled={disabled}
                placeholder="最小值"
                max={range?.[1]}
                type="number"
                value={range?.[0] === undefined ? '' : range?.[0]}
                onChange={ev => handleMinChange(ev.target.value ? Number(ev.target.value) : undefined)}
                onBlur={e => {
                    const newValue = e.target.value
                    const max = range?.[1]
                    if (max === undefined) {
                        return
                    }
                    if (max && Number(newValue) >= max) {
                        handleMaxChange(Number(newValue))
                    }
                }}
            />
            <Box style={{ color: 'var(--color-black)', fontSize: 14 }}>-</Box>
            <SCxNumberInput
                disabled={disabled}
                placeholder="最大值"
                min={range?.[0]}
                type="number"
                value={range?.[1] === undefined ? '' : range?.[1]}
                onChange={ev => handleMaxChange(ev.target.value ? Number(ev.target.value) : undefined)}
                style={{ flex: 1 }}
                onBlur={e => {
                    const newValue = e.target.value
                    if (newValue.trim() === '') {
                        return
                    }
                    const min = range?.[0]
                    if (min && Number(newValue) <= min) {
                        handleMaxChange(min)
                    }
                }}
            />
        </SCxInputWrapper>
    )
}
