import equal from 'fast-deep-equal'
import type { Atom } from 'jotai'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

export function useAtomData<V>(someAtom: Atom<V>): V
export function useAtomData<V, T>(someAtom: Atom<V>, selector: (v: V) => T): T
export function useAtomData<V, T>(someAtom: Atom<V>, selector?: (v: V) => T) {
    return useAtomValue(useMemo(() => (selector ? selectAtom(someAtom, selector, equal) : someAtom), [selector, someAtom]))
}
