import { IconFont, Input } from '@byecode/ui'
import styled from 'styled-components'

export const SearchWrapper = styled.div<{ isFull?: boolean; focusColor: string }>`
    width: ${({ isFull }) => (isFull ? '100%' : '140px')};
    height: 32px;
    display: flex;
    align-items: center;
    border: 1px solid var(--color-gray-200);
    border-radius: 4px;
    padding: 0 8px;
    margin: 8px 8px 8px 0;

    :focus-within {
        border-color: ${({ focusColor }) => focusColor};
    }
`

export const SearchIcon = styled(IconFont)`
    width: 16px;
    margin-right: 4px;
`

export const KeywordsInput = styled.input`
    flex: 1;
    outline: none;
    font-size: var(--font-size-normal);
    border: none;
    overflow: hidden;

    &::placeholder {
        color: var(--color-gray-300);
    }
`

export const SearchInput = styled(Input)<{ color: string }>`
    :focus-within {
        border-color: ${({ color }) => color};
    }
`
