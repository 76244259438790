import { Divider, IconFont } from '@byecode/ui'
import chroma from 'chroma-js'
import React from 'react'
import styled from 'styled-components'

import { APP_MAIN_COLOR_VARIABLE, PRESET_PALETTES } from '../../../constants'
import type { HsvaColor } from '../type'

const Container = styled.div``

const Title = styled.div`
    font-size: 14px;
    line-height: 22px;
    color: var(--color-gray-500);
`

const List = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 8px 0;
`

const Item = styled.li`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
`

const THEME_COLORS_VARIABLE = [APP_MAIN_COLOR_VARIABLE, ...Object.values(PRESET_PALETTES)]

interface ThemeColorContainerProps {
    onChange: (value: HsvaColor | typeof APP_MAIN_COLOR_VARIABLE) => void
}

export const ThemeColorContainer = ({ onChange }: ThemeColorContainerProps) => {
    return (
        <Container>
            <Divider my={16} />
            <Title>主题单色</Title>
            <List>
                {THEME_COLORS_VARIABLE.map(item => {
                    const isVariable = item === APP_MAIN_COLOR_VARIABLE
                    return (
                        <Item
                            key={item}
                            style={{ background: isVariable ? undefined : item }}
                            onClick={() => {
                                if (isVariable) {
                                    onChange(item)
                                } else {
                                    const color = chroma(item)
                                    const [h, s, v] = color.hsv()
                                    const alpha = color.alpha()
                                    onChange({ h, s, v, a: alpha })
                                }
                            }}
                        >
                            {isVariable && <IconFont size={24} type="ThemeColor" />}
                        </Item>
                    )
                })}
            </List>
        </Container>
    )
}
