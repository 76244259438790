import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'

import { Box } from '../Box'
import { Empty } from '../Empty'
import type { TreeContextValue } from './context'
import { TreeProvider } from './context'
import type { TreeNode } from './TreeItem'
import { TreeItem } from './TreeItem'

const useStyles = createStyles(() => {
    return {
        root: css({
            listStyle: 'none'
        })
    }
})

function findNode(id: string, tree: TreeNode[]): TreeNode | undefined {
    for (const node of tree) {
        if (node.value === id) {
            return node
        }

        if (node.children) {
            const res = findNode(id, node.children)
            if (res) {
                return res
            }
        }
    }
}

/**
 * 获取叶子节点的值
 *
 * @param {TreeNode} node
 * @returns {string[]}
 */
export function getNodeLeafsIds(node: TreeNode): string[] {
    if (node.children) {
        return node.children.reduce<string[]>((total, curr) => [...total, ...getNodeLeafsIds(curr)], [])
    }

    return [node.value]
}

function nodeChecked(node: TreeNode, checkedNodes: string[]): boolean {
    if (node.children && node.children.length > 0) {
        return node.children.every(item => nodeChecked(item, checkedNodes))
    }

    return checkedNodes.includes(node.value)
}

function nodeIndeterminate(node: TreeNode, checkedNodes: string[]): boolean {
    if (node.children) {
        return node.children.some(item => nodeIndeterminate(item, checkedNodes))
    }

    return checkedNodes.includes(node.value)
}

export interface TreeRef {
    toggleCheckedAll: () => void
}

export interface TreeProps extends StyleComponentProps<Selectors<typeof useStyles>>, React.ComponentPropsWithoutRef<'ul'> {
    data: TreeNode[]
    checkable?: boolean

    checkedNodes?: string[]
    defaultCheckedNodes?: string[]
    onCheckedNodesChange?: (value: string[]) => void

    treeRef?: React.RefObject<TreeRef>
}

/** 当前只收集叶子节点的值，后续如有需要非叶子节点的需求，再添加额外的模式 */
export const Tree = forwardRef<HTMLUListElement, TreeProps>((props, ref) => {
    const {
        styles,
        unstyled,
        className,
        classNames,
        checkable = true,
        data,
        checkedNodes: propsCheckedNodes,
        defaultCheckedNodes: propsDefaultCheckedNodes,
        onCheckedNodesChange: propsOnCheckedNodesChange,

        treeRef,

        ...rest
    } = props

    const { classes } = useStyles({}, { name: 'tree', unstyled, classNames, styles })

    const [checkedNodes, setCheckedNodes] = useUncontrolled({
        value: propsCheckedNodes,
        defaultValue: propsDefaultCheckedNodes,
        onChange: propsOnCheckedNodesChange,
        finalValue: []
    })
    const [expandedNodes, setExpandedNodes] = useState<string[]>([])

    const handleExpand = useCallback((id: string) => {
        setExpandedNodes(s => [...s, id])
    }, [])

    const handleUnExpand = useCallback((id: string) => {
        setExpandedNodes(s => s.filter(item => item !== id))
    }, [])

    const handleToggleExpand = useCallback((id: string) => {
        setExpandedNodes(s => {
            return s.includes(id) ? s.filter(item => item !== id) : [...s, id]
        })
    }, [])

    const handleCheck = useCallback(
        (id: string) => {
            const node = findNode(id, data)
            if (!node) {
                return
            }

            setCheckedNodes([...new Set([...checkedNodes, ...getNodeLeafsIds(node)])])
        },
        [checkedNodes, data, setCheckedNodes]
    )

    const handleUnCheck = useCallback(
        (id: string) => {
            const node = findNode(id, data)
            if (!node) {
                return
            }

            const removeList = new Set(getNodeLeafsIds(node))
            setCheckedNodes(checkedNodes.filter(item => !removeList.has(item)))
        },
        [checkedNodes, data, setCheckedNodes]
    )

    const isNodeChecked = useCallback(
        (id: string): boolean => {
            const node = findNode(id, data)
            if (!node) {
                return false
            }

            return nodeChecked(node, checkedNodes)
        },
        [checkedNodes, data]
    )

    const isNodeIndeterminate = useCallback(
        (id: string): boolean => {
            const node = findNode(id, data)
            if (!node || !node.children) {
                return false
            }

            return !nodeChecked(node, checkedNodes) && nodeIndeterminate(node, checkedNodes)
        },
        [checkedNodes, data]
    )

    const handleToggleCheckedAll = useCallback(() => {
        const allLeafsIds = data.reduce<string[]>((total, curr) => [...total, ...getNodeLeafsIds(curr)], [])
        const isAllChecked = allLeafsIds.length === checkedNodes.length

        if (isAllChecked) {
            setCheckedNodes([])
        } else {
            setCheckedNodes(allLeafsIds)
        }
    }, [checkedNodes.length, data, setCheckedNodes])

    useImperativeHandle(treeRef, () => ({ toggleCheckedAll: handleToggleCheckedAll }), [handleToggleCheckedAll])

    const renderChildren = useMemo(() => {
        return data.map(item => {
            return <TreeItem key={item.value} node={item} />
        })
    }, [data])

    const context = useMemo<TreeContextValue>(
        () => ({
            checkable,
            checkedNodes,
            expandedNodes,
            expandTree: handleExpand,
            unExpandTree: handleUnExpand,
            toggleExpandedNode: handleToggleExpand,
            checkTree: handleCheck,
            unCheckTree: handleUnCheck,
            isNodeChecked,
            isNodeIndeterminate
        }),
        [
            checkable,
            checkedNodes,
            expandedNodes,
            handleCheck,
            handleExpand,
            handleToggleExpand,
            handleUnCheck,
            handleUnExpand,
            isNodeChecked,
            isNodeIndeterminate
        ]
    )

    return (
        <TreeProvider value={context}>
            {data.length === 0 ? (
                <Empty py={20} description="暂无数据" />
            ) : (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                <Box component="ul" role="tree" ref={ref} className={clsx(classes.root, className)} {...rest}>
                    {renderChildren}
                </Box>
            )}
        </TreeProvider>
    )
})
