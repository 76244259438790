import { IconFont } from '@byecode/ui/components/IconFont'
import { Menu } from '@byecode/ui/components/Menu'
import type { FC } from 'react'
import React from 'react'

import { useTipTapContext } from '../Context'
import { DropdownActionButton } from './styles'

const OPTIONS = [
    {
        label: '居中',
        icon: <IconFont type="TextAlignCenter" />,
        value: 'center'
    },
    {
        label: '居左',
        icon: <IconFont type="TextAlignLeft" />,
        value: 'left'
    },
    {
        label: '居右',
        icon: <IconFont type="TextAlignRight" />,
        value: 'right'
    }
]

export const AlignMenu: FC = () => {
    const editor = useTipTapContext()

    const current = editor?.isActive({ textAlign: 'center' })
        ? 'TextAlignCenter'
        : editor?.isActive({ textAlign: 'right' })
        ? 'TextAlignRight'
        : 'TextAlignLeft'

    return (
        <Menu withinPortal width={120} trigger="hover" position="bottom">
            <Menu.Target>
                <DropdownActionButton>
                    <IconFont size={16} type={current} />
                    <IconFont size={10} type="ArrowDownSmallGray" color="var(--color-gray-500)" />
                </DropdownActionButton>
            </Menu.Target>
            <Menu.Dropdown>
                {OPTIONS.map(item => (
                    <Menu.Item
                        key={item.value}
                        icon={item.icon}
                        style={editor?.isActive({ textAlign: item.value }) ? { backgroundColor: 'var(--color-gray-100)' } : {}}
                        onClick={() =>
                            editor?.isActive({ textAlign: item.value })
                                ? editor.chain().focus().unsetTextAlign().run()
                                : editor?.chain().focus().setTextAlign(item.value).run()
                        }
                    >
                        {item.label}
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    )
}
