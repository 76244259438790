import React from 'react'

interface EmptyNoRecordProps {
    color?: string
}

export const EmptyNoRecordSvg: React.FC<EmptyNoRecordProps> = ({ color = '#5358FD' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={140} height={140} fill="none" viewBox="0 0 140 140">
            <g clipPath="url(#clip0)">
                <rect fill="color(display-p3 .9882 .9882 .9922)" rx="70" height="140" width="140" />
                <path
                    fill="color(display-p3 .8157 .8353 .8667)"
                    d="M92.653 105.81H49.887c-3.01 0-5.474-2.5-5.474-5.556v-61.7c0-3.055 2.464-5.554 5.474-5.554h42.766c3.011 0 5.475 2.499 5.475 5.554v61.7c0 3.056-2.464 5.556-5.475 5.556Z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                />
                <path
                    fill="color(display-p3 .8941 .9059 .9255)"
                    d="M89.89 108.448H47.124c-3.01 0-5.474-2.5-5.474-5.556v-61.7c0-3.055 2.464-5.554 5.474-5.554H89.89c3.011 0 5.475 2.499 5.475 5.554v61.7c0 3.056-2.463 5.556-5.475 5.556Z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                />
                <path
                    fill="color(display-p3 .9369 .9438 .9583)"
                    d="M87.34 111.508H44.485c-3.016 0-5.485-2.489-5.485-5.532V44.531C39 41.49 41.469 39 44.485 39H87.34c3.017 0 5.486 2.489 5.486 5.531v61.445c0 3.043-2.469 5.532-5.486 5.532Z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                />
                <path
                    fillOpacity=".1"
                    fill="#fff"
                    d="M106.334 98.21a18.107 18.107 0 0 1-23.241 2.182 19.223 19.223 0 0 1-2.582-2.181 17.773 17.773 0 0 1-3.09-4.186 17.78 17.78 0 0 1-1.913-5.342c-1.118-5.818.553-12.08 5.003-16.58a18.128 18.128 0 0 1 16.404-5.074 17.915 17.915 0 0 1 5.281 1.936 18.263 18.263 0 0 1 4.126 3.137c.809.816 1.533 1.69 2.157 2.611 4.888 7.195 4.173 17.108-2.145 23.498Z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                    data-follow-fill="#fff"
                />
                <path
                    fill="color(display-p3 .8941 .9059 .9255)"
                    d="M104.563 96.95a16.718 16.718 0 0 1-23.842 0c-6.577-6.655-6.577-17.452.009-24.114a16.713 16.713 0 0 1 23.826 0c6.585 6.662 6.585 17.46.007 24.114Zm3.38-27.537a21.458 21.458 0 0 0-30.601 0c-8.451 8.55-8.458 22.416-.007 30.967a21.487 21.487 0 0 0 28.23 2.094 22.078 22.078 0 0 0 2.386-2.094c.76-.77 1.452-1.577 2.069-2.415 6.314-8.58 5.614-20.77-2.077-28.552Z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                />
                <path
                    // blue
                    fillOpacity=".3"
                    fill={color}
                    // fill="color(display-p3 .3255 .3451 .9922)"
                    d="m141.207 132.613-.355.359a6.006 6.006 0 0 1-8.541 0l-21.676-21.929 8.895-9 21.677 21.93c2.348 2.376 2.348 6.264 0 8.64Z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                />
                <path
                    fill="color(display-p3 .8941 .9059 .9255)"
                    d="m110.487 97.294 6.405 6.481-4.547 4.599-6.405-6.48a22.598 22.598 0 0 0 2.436-2.137 22.378 22.378 0 0 0 2.111-2.463Z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                />
                <path
                    // blue
                    // fill="color(display-p3 .3333 .3176 1)"
                    fill={color}
                    d="m140.881 123.703-8.839 8.941-1.591-1.611 8.838-8.941 1.592 1.611Z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                />
                <path
                    // blue
                    fill={color}
                    // fill="color(display-p3 .3255 .3451 .9922)"
                    d="m120.022 102.353-9.081 9.187-1.349-1.365 9.081-9.187 1.349 1.365Z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                />
                <path
                    fillOpacity=".45"
                    fill="#fff"
                    d="M100.48 69.693 78.154 93.347a17.266 17.266 0 0 1-1.723-5.042l19.29-20.44c1.652.343 3.252.947 4.759 1.828ZM106.281 74.834 82.042 98.124c-.801-.398-1.603-1.593-2.285-2.264a16.008 16.008 0 0 1-1.115-1.17l24.369-23.26c.427.334.832.69 1.225 1.066.765.73 1.454 1.512 2.045 2.337Z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                    data-follow-fill="#fff"
                />
                <path
                    fill="color(display-p3 .8157 .8353 .8667)"
                    d="M100.719 22.107a1.5 1.5 0 0 1 .839 1.948l-1.664 4.18a1.5 1.5 0 1 1-2.787-1.109l1.664-4.18a1.5 1.5 0 0 1 1.948-.84ZM110.723 25.38a1.5 1.5 0 0 1-.263 2.105l-6.94 5.398a1.5 1.5 0 0 1-1.842-2.368l6.94-5.398a1.5 1.5 0 0 1 2.105.263ZM111.385 35.8a1.5 1.5 0 0 1-1.239 1.722l-3.977.65a1.5 1.5 0 1 1-.484-2.961l3.978-.65a1.5 1.5 0 0 1 1.722 1.239Z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect fill="#fff" rx="70" height="140" width="140" data-follow-fill="#fff" />
                </clipPath>
            </defs>
        </svg>
    )
}
