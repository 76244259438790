import { Empty } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { type QrBarcodeBlockAbstract, QrBarcodeEnum } from '@lighthouse/core'
import { BarcodeBox, QRCodeBox } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const emptyApplicationImg = getAssetUrl('empty', 'no_content.svg')
interface QrBarcodeBlockProps {
    blockData: QrBarcodeBlockAbstract
    code: string
}

const SCxEmptyImg = styled.img`
    max-width: 140px;
    width: 100%;
`

const QrBarcodeBlock: React.FC<QrBarcodeBlockProps> = ({ blockData, code }) => {
    const { id, config } = blockData
    const { codeType, barWidth } = config

    return useMemo(() => {
        if (!code) {
            return <Empty icon={<SCxEmptyImg src={emptyApplicationImg} alt="" />} description="请在右侧输入内容" />
        }
        if (codeType === QrBarcodeEnum.BARCODE) {
            return <BarcodeBox code={code} barWidth={barWidth} height={80} />
        }
        return <QRCodeBox code={code} />
    }, [barWidth, codeType, code])
}

export default QrBarcodeBlock
