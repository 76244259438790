import { IconFont } from '@byecode/ui'
import type { InclusionRelation, OperatorOptions } from '@lighthouse/core'
import React, { useCallback, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { MobileDrawer } from '../MobileDrawer'
import * as SC from './styles'

interface OperatorDrawerProps {
    value: InclusionRelation
    opened: boolean
    operatorOptions: OperatorOptions[]
    target?: string
    onClose: () => void
    onFinish?: (val: InclusionRelation) => void
}

export const OperatorDrawer: React.FC<OperatorDrawerProps> = ({ value, opened, operatorOptions, target, onClose, onFinish }) => {
    const [operator, setOperator] = useState(value)
    const handleSelect = useCallback((val: InclusionRelation) => {
        setOperator(val)
    }, [])

    const handleFinish = useCallback(() => {
        onFinish?.(operator)
    }, [onFinish, operator])

    useUpdateEffect(() => {
        setOperator(value)
    }, [value])

    return (
        <MobileDrawer
            target={target}
            leftNode={<IconFont type="Close" />}
            title="选择条件类型"
            opened={opened}
            onClose={onClose}
            rightNode={
                <SC.FinishButton role="button" onClick={handleFinish}>
                    完成
                </SC.FinishButton>
            }
        >
            <SC.TagList>
                {operatorOptions.map(item => (
                    <SC.TagItem key={item.value} onClick={() => handleSelect(item.value)}>
                        <SC.TagItemContent>{item.label}</SC.TagItemContent>
                        {operator === item.value && <SC.Check type="Tick" />}
                    </SC.TagItem>
                ))}
            </SC.TagList>
        </MobileDrawer>
    )
}
