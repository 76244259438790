import type { Batch, BatchItem } from '@rpldy/uploady'
import { FILE_STATES } from '@rpldy/uploady'
import { useEffect, useRef } from 'react'

import { useListenersContext } from '../UploadManage.context'

export const useUploadBatchFinished = (id: string, func: (data: BatchItem[]) => void) => {
    const { batchListener } = useListenersContext()

    const callback = (data: BatchItem[]) => {
        func(data)
        const batchItemIds = data.reduce<string[]>((prev, cur) => {
            if(cur.state === FILE_STATES.FINISHED || cur.state === FILE_STATES.ABORTED){
                prev.push(cur.id)
            }
            return prev
        }, [])
        batchListener.removeUploadingBatchItem(id, batchItemIds)
    }

    const latestFunc = useRef<(data: BatchItem[]) => void>(callback)
    latestFunc.current = callback

    useEffect(() => {
        batchListener.add(latestFunc.current, id, FILE_STATES.FINISHED)
        // return () => {
        //     batchListener.remove(id)
        // }
    }, [id, batchListener])
}


export const useUploadBatchFinishedWithRemoveFinish = (id: string, func: (data: BatchItem[]) => void) => {
    const { batchListener } = useListenersContext()

    const callback = (data: BatchItem[]) => {
        func(data)
        const batchItemIds = data.reduce<string[]>((prev, cur) => {
            if(cur.state === FILE_STATES.FINISHED || cur.state === FILE_STATES.ABORTED){
                prev.push(cur.id)
            }
            return prev
        }, [])
        batchListener.removeFinishBatchItem(id, batchItemIds)
    }

    const latestFunc = useRef<(data: BatchItem[]) => void>(callback)
    latestFunc.current = callback

    useEffect(() => {
        batchListener.add(latestFunc.current, id, FILE_STATES.FINISHED)
        // return () => {
        //     batchListener.remove(id)
        // }
    }, [id, batchListener])
}
