import type { CallActionConfig } from '@lighthouse/shared'
import { type ActionFlowNode, resolveVariable } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

export const handleCall = async (node: ActionFlowNode<CallActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return excParams
    }

    const phone = resolveVariable(config.phone, excParams)

    if (typeof phone === 'string') {
        const a = document.createElement('a')
        a.href = `tel:${phone}`
        a.click()
        a.remove()
    }

    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        phone
    })
}
