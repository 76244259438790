import type { DateRangeSystemVariableValue, SystemVariableValue } from '@lighthouse/core'

export const variableSystemNameMap: Record<SystemVariableValue, string> = {
    AFTER_SOMEWHAT_DAYS: '多少天后',
    BEFORE_SOMEWHAT_DAYS: '多少天前',
    TODAY: '今天',
    TOMORROW: '明天',
    YESTERDAY: '昨天',
    THIS_WEEK: '本周',
    LAST_WEEK: '上周',
    THIS_MONTH: '本月',
    LAST_MONTH: '上月',
    THIS_YEAR: '今年',
    PAST_SEVEN_DAYS: '过去 7 天',
    FUTURE_SEVEN_HEAVENS: '未来 7 天',
    PAST_THIRTY_DAYS: '过去 30 天',
    FUTURE_THIRTY_HEAVENS: '未来 30 天',
    NOW: '此刻'
}

export const dateRangeVariableSystemNameMap: Record<DateRangeSystemVariableValue, string> = {
    TODAY: '今天',
    YESTERDAY: '昨天',
    TOMORROW: '明天',
    THIS_WEEK: '本周',
    LAST_WEEK: '上周',
    NEXT_WEEK: '下周',
    THIS_MONTH: '本月',
    LAST_MONTH: '上月',
    NEXT_MONTH: '下月',
    THIS_QUARTER: '本季度',
    LAST_QUARTER: '上季度',
    NEXT_QUARTER: '下季度',
    THIS_YEAR: '今年',
    LAST_YEAR: '去年',
    NEXT_YEAR: '明年',
    PAST_SEVEN_DAYS: '过去 7 天',
    FUTURE_SEVEN_HEAVENS: '未来 7 天',
    PAST_FOURTEEN_DAYS: '过去 14 天',
    FUTURE_FOURTEEN_HEAVENS: '未来 14 天',
    PAST_THIRTY_DAYS: '过去 30 天',
    FUTURE_THIRTY_HEAVENS: '未来 30 天'
}
