import { Box, IconFont } from '@byecode/ui'
import type { StyleComponentProps } from '@byecode/ui/theme/types'
import React, { useMemo } from 'react'

import type { BaseChange, IconPickerStylesNames } from '../IconPicker.types'
import { useStyles } from './IconRender.styles'

export interface IconRenderProps extends StyleComponentProps<IconPickerStylesNames>, BaseChange {
    disabled?: boolean
    size?: number
    color?: string
    clearable?: boolean
    readonly?: boolean
    isEmpty?: boolean
    isCompact?: boolean
    onClear?: () => void
}

export const IconRender = React.forwardRef<HTMLDivElement, IconRenderProps>(
    ({ value: icon, styles, size = 32, disabled, clearable, color, isEmpty, readonly, isCompact, onGetFullUrl, onClear }, ref) => {
        const { classes } = useStyles({ disabled, readonly, size, isEmpty, isCompact }, { name: 'IconPicker', styles })

        const iconEle = useMemo(() => {
            const iconSize = isCompact ? size : size - 8
            if (!icon?.value) {
                return <IconFont type={icon?.value ?? ''} size={iconSize} color={color} />
            }
            switch (icon?.type) {
                case 'icon': {
                    return <IconFont type={icon?.value ?? ''} size={iconSize} color={color} />
                }
                case 'upload': {
                    /**
                     * 代张琪改图片比例
                     * @see https://roadmap.byecode.site/P/4e63552d99fd4b01851c36cfbedd91de/D/a130da191ae241bebf623c645c7977e2/a/95ee6ff25df04fb689c7e9f55159ac04/d/ChD21jm645da569cncrLpuCzd8qclma7/v/view-g646f22c9uc1/r/9dc4051519dc469488ad5bf4d8a44690
                     */
                    return (
                        <img
                            alt="图片"
                            width={iconSize}
                            height={iconSize}
                            style={{ objectFit: 'contain' }}
                            src={onGetFullUrl ? onGetFullUrl(icon?.value) : icon.value}
                        />
                    )
                }
                default: {
                    return <IconFont type={icon?.value ?? ''} size={iconSize} color={color} />
                }
            }
        }, [color, icon?.type, icon?.value, isCompact, onGetFullUrl, size])

        return (
            <Box ref={ref} className={classes.root} data-aria-readonly={readonly}>
                {iconEle}
                {clearable && !disabled && !readonly && (
                    <Box
                        className={classes.close}
                        onClick={e => {
                            e.stopPropagation()
                            onClear?.()
                        }}
                    >
                        <IconFont size={8} type="Close" color="var(--color-gray-500)" />
                    </Box>
                )}
            </Box>
        )
    }
)
