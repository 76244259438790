import { IconFont } from '@byecode/ui/components/IconFont'
import { Menu } from '@byecode/ui/components/Menu'
import type { FC } from 'react'
import React from 'react'

import { useTipTapContext } from '../Context'
import { DropdownActionButton } from './styles'

const OPTIONS = [9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 42, 48, 72]

export const FontSizeMenu: FC = () => {
    const editor = useTipTapContext()

    const currentSize = editor
        ?.getAttributes('textStyle')
        .fontSize?.toString()
        ?.replace(/["'px]/g, '')
    const defaultSize = editor?.extensionManager.attributes.find(item => item.name === 'fontSize')?.attribute.default

    return (
        <Menu withinPortal width={120} trigger="hover" position="bottom">
            <Menu.Target>
                <DropdownActionButton>
                    <span style={{ fontSize: 12 }}>{currentSize || defaultSize}</span>
                    <IconFont size={10} type="ArrowDownSmallGray" color="var(--color-gray-500)" />
                </DropdownActionButton>
            </Menu.Target>
            <Menu.Dropdown>
                {OPTIONS.map(item => (
                    <Menu.Item
                        key={item}
                        style={currentSize === `${item}` ? { backgroundColor: 'var(--color-gray-100)' } : {}}
                        onClick={() =>
                            editor?.isActive('textStyle', { fontSize: `${item}px` })
                                ? editor.chain().focus().unsetFontSize().run()
                                : editor?.chain().focus().setFontSize(item).run()
                        }
                    >
                        {item}
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    )
}
