import { useEffect, useRef, useState } from 'react'
import { useLatest } from 'react-use'

type HandleEvent = { event: MouseEvent; ratio: { x: number; y: number } }

interface UseMoveOptions {
    disabled?: boolean
    onStart?: (e: HandleEvent) => void
    onEnd?: (e: HandleEvent) => void
}

// eslint-disable-next-line etc/no-misused-generics
export const useMove = <E extends HTMLElement = HTMLDivElement>(onChange: (e: HandleEvent) => void, options?: UseMoveOptions) => {
    const { disabled, onStart, onEnd } = options ?? {}
    const ref = useRef<E>(null)

    const [active, setActive] = useState(false)

    const latestOnChange = useLatest({ onChange, onStart, onEnd })

    const latestRatio = useRef({ x: 0, y: 0 })

    useEffect(() => {
        const el = ref.current
        if (!el || disabled) {
            return
        }

        let dragging = false

        function bindEvents() {
            dragging = true
            document.addEventListener('mousemove', move)
            document.addEventListener('mouseup', end)
        }
        function unBindEvents() {
            if (dragging) {
                dragging = false
                document.removeEventListener('mousemove', move)
                document.removeEventListener('mouseup', end)
            }
        }

        function compute(e: MouseEvent) {
            if (el) {
                const rect = el.getBoundingClientRect()
                const ratioX = Math.min(1, Math.max(0, (e.x - rect.x) / rect.width))
                const ratioY = Math.min(1, Math.max(0, (e.y - rect.y) / rect.height))
                latestRatio.current.x = ratioX
                latestRatio.current.y = ratioY
            }
        }

        function start(e: MouseEvent) {
            compute(e)
            latestOnChange.current.onStart?.({ event: e, ratio: { ...latestRatio.current } })
            latestOnChange.current.onChange({ event: e, ratio: { ...latestRatio.current } })
            bindEvents()
            setActive(true)
        }

        function move(e: MouseEvent) {
            window.getSelection()?.removeAllRanges()
            compute(e)
            latestOnChange.current.onChange({ event: e, ratio: { ...latestRatio.current } })
        }

        function end(e: MouseEvent) {
            latestOnChange.current.onEnd?.({ event: e, ratio: { ...latestRatio.current } })
            latestRatio.current = { x: 0, y: 0 }
            setActive(false)
            unBindEvents()
        }

        el.addEventListener('mousedown', start)

        return () => {
            el.removeEventListener('mousedown', start)
            unBindEvents()
        }
    }, [disabled, latestOnChange, ref])

    return { ref, active }
}
