import { IconFont, singleTextEllipsis } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import { Checkbox } from '@mantine/core'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { useApplicationContext } from '../../contexts'
import { getColorById, getImageFullUrlInApplication } from '../../utils'

export interface SelectItemValue {
    label: string
    color?: string
}

interface SelectItemPreviewerProps {
    enableCheckBox?: boolean
    data: AppUser
    value?: string[]
    textStyles?: React.CSSProperties
    // rightProperty: keyof AppUser
    extraText?: string
    color?: string
}

const SCxCellValue = styled.div`
    padding: 0 12px;
    display: flex;
    width: 100%;
    gap: 16px;
    align-items: center;
`

const SCxLeft = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;
    width: 100%;
    ${singleTextEllipsis}
`

const SCxRight = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row nowrap;
    max-width: 50%;
`

const SCxAvatar = styled.img`
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 8px;
    border-radius: 50%;
`

const SCxIcon = styled(IconFont)`
    margin-right: 4px;
`

const SCxAvatarText = styled.div<{ background?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    font-size: var(--font-size-sm);
    margin-right: 8px;
    border-radius: 50%;
    overflow: hidden;
    color: var(--color-white);
    background-color: ${({ background }) => background || 'var(--color-gray-2)'};
`

const SCxTitle = styled.div`
    width: 100%;
    height: 100%;
    ${singleTextEllipsis}
`

const SCxText = styled.div`
    width: 100%;
    height: 100%;
    color: var(--color-gray-500);
    text-align: right;
    ${singleTextEllipsis}
`

const SCxCheckbox = styled(Checkbox)<{ color?: string }>`
    margin-top: 2px;
    margin-right: 10px;

    .mantine-Checkbox-input:checked {
        background-color: ${({ color }) => color || 'var(--color-main)'};
        border-color: ${({ color }) => color || 'var(--color-main)'};
    }
`

export const SelectItemPreviewer: React.FC<SelectItemPreviewerProps> = ({
    data,
    value,
    enableCheckBox = false,
    color,
    extraText,
}) => {
    const { userId, username, avatar, uniqueUserId } = data
    const background = getColorById(uniqueUserId)
    const { appId } = useApplicationContext()
    const avatarContent = useMemo(() => {
        if (userId === '{currentUserId}') {
            return <SCxIcon size={24} type="UserCircle" />
        }
        if (avatar) {
            return <SCxAvatar src={getImageFullUrlInApplication(appId, avatar)} />
        }
        return <SCxAvatarText background={background}>{username.charAt(0)}</SCxAvatarText>
    }, [appId, avatar, background, userId, username])

    return (
        <SCxCellValue key={userId}>
            <SCxLeft>
                {enableCheckBox && <SCxCheckbox color={color} size="xs" checked={value?.includes(userId)} readOnly />}
                {avatarContent}
                <SCxTitle>{username}</SCxTitle>
            </SCxLeft>
            {extraText && (
                <SCxRight>
                    <SCxText>{extraText}</SCxText>
                </SCxRight>
            )}
        </SCxCellValue>
    )
}
