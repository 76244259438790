import { Modal, Toast } from '@byecode/ui'
import type { FilterOption, ViewBlockAbstract } from '@lighthouse/core'
import {
    generateLinkFilter,
    getFilterBlockItemIdsInFilter,
    getMainTableRecordId,
    getQuickFilterRule,
    pageStackPubSub,
    PrintModal,
    resolveFilter,
    SuspendPagination,
    useApplicationContext,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import { getDefaultStore, useAtomValue } from 'jotai'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { filterBlockOptionsAtom } from '@/atoms/blockRecordsDict/state'
import { deleteRecordAtom, deleteViewRecordAtom } from '@/atoms/dataSource/action'
import { pageBlocksAtom, pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { filterBlockIdsCacheAtomFamily, quickFilterCacheAtomFamily, sortsCacheAtomFamily } from '@/atoms/storage/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
// import { useRootPageContext } from '@/contexts/PageContext'
import { useCurrentAppId, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList, useRecord } from '@/hooks/useDataSource'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useUserRecord } from '@/hooks/useUserRecord'
// import { usePageCurrentDsAndRecord } from '@/hooks/usePageCurrentDsAndRecord'
import * as srv from '@/services'

export const SCxSuspendWrapper = styled.div`
    position: fixed;
    bottom: 44px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
`

export const PageSuspendPagination: React.FC = () => {
    const store = getDefaultStore()
    const appId = useCurrentAppId()
    const { personOptions } = useApplicationContext()
    const { pageId } = useCurrentPageContext()
    const envId = useCurrentEnvId()
    const stackId = useCurrentStackIdContext()
    const { curr, prev } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const userRecord = useUserRecord()
    const prevRecord = useRecord(appId, envId, prev.datasource?.id ?? '', prev.recordId ?? '')
    const currentRecord = useRecord(appId, envId, curr.datasource?.id ?? '', curr.recordId ?? '')
    const [printModalOpen, setPrintModalOpen] = useState(false)
    const filterOptions = useAtomData(filterBlockOptionsAtom)
    const dataSourceList = useDataSourceList(appId)
    const filterBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId]?.filter(block => block.type === 'filter') || [], [pageId])
    )
    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const selectState = useAtomData(
        pageStackAtomFamily({ pageId, stackId }),
        useCallback(s => {
            if (!s) {
                return
            }
            return s.blockRuntimeState.view
            // return s.state.blockRuntimeState.tabs?.[blockData.id].currentTab
        }, [])
    )
    const viewBlock = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId]?.find(block => block.type === 'view' && block.id === selectState?.viewId), [pageId, selectState?.viewId])
    ) as ViewBlockAbstract | undefined
    const linkFilterController = useMemo(() => viewBlock?.config.linkFilterController, [viewBlock?.config.linkFilterController])
    const { filterBlockItemIds } = useMemo(() => getFilterBlockItemIdsInFilter(linkFilterController), [linkFilterController])
    const filterValue = useAtomValue(filterBlockIdsCacheAtomFamily({ appId, envId, pageId, recordId: curr.recordId, filterBlockItemIds }))

    const { run: deleteRecord } = useAtomAction(deleteRecordAtom)
    const { run: deleteViewRecord } = useAtomAction(deleteViewRecordAtom)

    const sorts = useMemo(() => {
        if (!viewBlock) {
            return []
        }
        const { id, config } = viewBlock
        const { canSort } = config
        return canSort ? store.get(sortsCacheAtomFamily({ appId, envId, id })) : []
    }, [appId, envId, store, viewBlock])

    const resolvedFilter = useMemo(() => {
        const usedFilterOptions = filterBlockItemIds.reduce<Record<string, FilterOption[]>>((prev, filterItem) => {
            const id = `${filterItem.blockId}-${filterItem.itemId}`
            const options = filterOptions[id]
            if (options) {
                prev[id] = options
            }
            return prev
        }, {})

        return generateLinkFilter({
            filterBlockItemIds,
            filterOptions: usedFilterOptions,
            linkFilterController,
            filterValue,
            filterBlocks
        })
    }, [filterBlockItemIds, filterBlocks, filterOptions, filterValue, linkFilterController])

    const quickFiltersRule = useMemo(() => {
        if (!viewBlock) {
            return
        }
        const { config, id } = viewBlock
        const { pointer, quickFilter } = config
        const dataSource = find(item => item.id === pointer, dataSourceList)
        if (!dataSource) {
            return []
        }
        const quickFiltersCache = store.get(quickFilterCacheAtomFamily({ appId, envId, id }))
        return getQuickFilterRule({ quickFiltersCache, quickFilter, dataSource, personOptions })
    }, [appId, dataSourceList, envId, personOptions, store, viewBlock])

    const filter = useMemo(() => {
        if (!viewBlock) {
            return
        }
        const { config, id } = viewBlock
        const { filter: configFilter } = config
        if (!configFilter) {
            return
        }
        return resolveFilter({
            filter: configFilter,
            shouldUseEmptyPlaceholder: true,
            extraParams: {
                clickTriggerNodeParams: {
                    prevRecord
                },
                userRecord,
                dataSourceList,
                pageRecord: currentRecord,
                pageStackFormState: curr.formState
            }
        })
    }, [curr.formState, currentRecord, dataSourceList, prevRecord, userRecord, viewBlock])

    const handleGetExportTemplateList = useCallback(async () => {
        const pointer = viewBlock?.config.pointer
        if (!pointer) {
            return []
        }
        const list = await srv.getPrintTemplateList(pointer, pageId)
        if (!list) {
            return []
        }
        return list.map(item => ({ value: item.templateId, label: item.name }))
    }, [pageId, viewBlock?.config.pointer])

    const handleClose = useCallback(() => {
        setPageStack(draft => {
            const stack = draft.find(equalPageStack(pageId, stackId))
            if (!stack) {
                return
            }

            stack.blockRuntimeState.view = undefined
        })
    }, [pageId, setPageStack, stackId])

    const handleDelete = useCallback(async () => {
        if (!viewBlock) {
            return
        }
        const recordIds = selectState?.selectedIds || []
        const { config, id } = viewBlock
        const { pointer, pagination } = config
        const { rowTotal } = pagination
        const isConfirm = await Modal.confirm({
            title: '确认删除？',
            content: `删除选中的 ${selectState?.selectedMode === 'ALL' ? rowTotal : recordIds.length} 条记录后无法恢复，请谨慎操作`,
            okStatus: 'error'
        })
        if (isConfirm) {
            const isDelete =
                selectState?.selectedMode === 'ALL'
                    ? await deleteViewRecord({
                          appId,
                          dsId: pointer,
                          pageId,
                          viewId: id,
                          recordIds,
                          mode: selectState?.selectedMode,
                          sorts: sorts ?? [],
                          filter,
                          quickFilters: quickFiltersRule,
                          linkFilter: resolvedFilter
                      })
                    : await deleteRecord({ appId, dsId: pointer, pageId, recordIds, mode: selectState?.selectedMode })
            if (isDelete) {
                handleClose()
                pageStackPubSub.emit(`${pointer}-UPDATE`)
            }
            return isDelete
        }
        return false
    }, [
        appId,
        deleteRecord,
        deleteViewRecord,
        filter,
        handleClose,
        pageId,
        quickFiltersRule,
        resolvedFilter,
        selectState?.selectedIds,
        selectState?.selectedMode,
        sorts,
        viewBlock
    ])

    const handlePrint = useCallback(
        async (templateId: string) => {
            if (!viewBlock) {
                return
            }
            Toast.warning('正在导出, 请勿重复点击')
            const { config } = viewBlock
            const { pointer } = config
            const recordIds = [...new Set(selectState?.selectedIds?.map(recordId => getMainTableRecordId(recordId)))]
            const params = {
                appId,
                dsId: pointer,
                pageId,
                recordIds,
                templateId,
                mode: selectState?.selectedMode,
                sorts,
                filter,
                quickFilters: quickFiltersRule,
                linkFilter: resolvedFilter
            }
            const res = await srv.printByTemplate(params)
            const { data, headers } = res
            const fileName = headers?.['content-disposition']?.replace?.("attachment;filename*=utf-8''", '') ?? '导出文件.pdf'
            const dom = document.createElement('a')
            const url = window.URL.createObjectURL(data)
            dom.href = url
            dom.download = decodeURI(fileName)
            dom.style.display = 'none'
            document.body.append(dom)
            dom.click()
            dom.remove()
            window.URL.revokeObjectURL(url)
        },
        [appId, filter, pageId, quickFiltersRule, resolvedFilter, selectState?.selectedIds, selectState?.selectedMode, sorts, viewBlock]
    )

    const handleExport = useCallback(async () => {
        if (!viewBlock) {
            return
        }
        const { id, config, title, appId } = viewBlock
        const { search, filter: configFilter } = config
        // const dsId = viewBlock.config.appId

        Toast.warning('正在导出, 请勿重复点击')
        const recordIds = [...new Set(selectState?.selectedIds?.map(recordId => getMainTableRecordId(recordId)))]
        const res = await srv.exportView(
            {
                viewId: id,
                recordIds,
                mode: selectState?.selectedMode,
                fileType: 'xlsx',
                sorts,
                filter,
                linkFilter: resolvedFilter,
                search,
                quickFilters: quickFiltersRule
            },
            pageId
        )
        const { data, headers } = res
        const fileName = headers?.['content-disposition']?.replace?.("attachment;filename*=utf-8''", '') ?? `${title}.xlsx`
        const dom = document.createElement('a')
        const url = window.URL.createObjectURL(data)
        dom.href = url
        dom.download = decodeURI(fileName)
        dom.style.display = 'none'
        document.body.append(dom)
        dom.click()
        dom.remove()
        window.URL.revokeObjectURL(url)
    }, [filter, pageId, quickFiltersRule, resolvedFilter, selectState?.selectedIds, selectState?.selectedMode, sorts, viewBlock])

    const handlePrintModalClose = useCallback(() => {
        setPrintModalOpen(false)
    }, [setPrintModalOpen])

    return useMemo(() => {
        if (!viewBlock || viewBlock.type !== 'view' || !selectState?.selectedIds || selectState.selectedIds.length === 0) {
            return null
        }
        const enableExport = viewBlock.config.canExport
        const enableDeleteRecord = viewBlock.config.canDeleteRecord
        const enablePrint = viewBlock.config.canPrint
        const total = viewBlock.config.pagination.rowTotal
        return (
            <>
                <SCxSuspendWrapper>
                    <SuspendPagination
                        mode={selectState?.selectedMode}
                        total={total}
                        selectIds={selectState?.selectedIds}
                        enablePrint={enablePrint}
                        enableDelete={enableDeleteRecord}
                        enableExport={enableExport}
                        onDelete={handleDelete}
                        onPrint={() => setPrintModalOpen(true)}
                        onExport={handleExport}
                        onClose={handleClose}
                    />
                </SCxSuspendWrapper>
                <PrintModal
                    open={printModalOpen}
                    onGetExportTemplateList={handleGetExportTemplateList}
                    onPrint={handlePrint}
                    onClose={handlePrintModalClose}
                />
            </>
        )
    }, [
        handleClose,
        handleDelete,
        handleExport,
        handleGetExportTemplateList,
        handlePrint,
        handlePrintModalClose,
        printModalOpen,
        selectState?.selectedIds,
        selectState?.selectedMode,
        viewBlock
    ])
}
