import type { RangeSliderValue} from '@byecode/ui';
import { Slider } from '@byecode/ui'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import type { CustomProps } from './types'

interface CustomNumberSliderFilterProps extends CustomProps {
    step?: number
    min: number
    max: number
    style?: React.CSSProperties
}

const SCxSliderRangeWrapper = styled.div`
    width: 100%;
    height: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-gray-50);
    border-radius: 8px;
`

const SCxSliderRangeLabel = styled.div`
    text-align: center;
    color: var(--color-gray-500);
    font-size: var(--font-size-normal);
`

export const CustomNumberSliderFilter: React.FC<CustomNumberSliderFilterProps> = ({
    disabled,
    step = 1,
    min,
    max,
    customValue,
    style,
    onCustomChange
}) => {
    const [range, setRange] = useState(customValue)
    // const theme = useAppThemeContext()
    const handleChange = useCallback(
        (val: RangeSliderValue) => {
            setRange(val)
            onCustomChange?.(val)
        },
        [onCustomChange]
    )

    const validateRange: RangeSliderValue = useMemo(() => [range?.[0] ?? min ?? 0, range?.[1] ?? max ?? 0], [max, min, range])

    return (
        <SCxSliderRangeWrapper style={style} data-ignore-click-away>
            <SCxSliderRangeLabel>{validateRange.join('~')}</SCxSliderRangeLabel>
            <Slider.Range
                style={{ height: 34, padding: '0 16px' }}
                disabled={disabled}
                // color={theme.mainColor}
                step={step}
                min={min}
                max={max}
                value={validateRange}
                onChange={handleChange}
            />
        </SCxSliderRangeWrapper>
    )
}
