import { backgroundTransitionOnClick, IconFont, singleTextEllipsis } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    position: relative;
    background: var(--color-gray-50);
`

export const Icon = styled(IconFont)<{ isAction?: boolean }>`
    color: var(--color-black);

    ${({ isAction }) => isAction && `${backgroundTransitionOnClick}`}
`

export const Header = styled.div`
    width: 100%;
    display: flex;
    height: 52px;
    padding: 0 8px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--color-gray-200);
`

export const Content = styled.div<{ height?: string | number }>`
    width: 100%;
    padding: 16px;
    height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
    overflow: hidden auto;
`

export const FileItem = styled.div`
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    /* height: 65px; */
    /* padding: 12px; */
    /* background-color: var(--color-white); */
    color: var(--color-black);
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
`

export const List = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
`

export const Item = styled.div`
    width: 100%;
    height: 52px;
    line-height: 52px;
    text-align: center;
    background-color: var(--color-white);
    font-size: var(--font-size-base);
    color: var(--color-black);
    ${backgroundTransitionOnClick}
`

export const Footer = styled.div`
    width: 100%;
    padding: 16px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-gray-50);
    z-index: 1;
`

export const Text = styled.span<{ size?: number; color?: string; isOmit?: boolean }>`
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-size: ${({ size }) => size ?? '14'}px;
    ${({ isOmit }) => isOmit && singleTextEllipsis}
    line-height: 100%;
`

export const FileItemContent = styled.div`
    margin-left: 8px;
    width: calc(100% - 48px);
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
`

export const RightFill = styled.div`
    flex: 0;
    display: flex;
    align-items: center;
`

export const LeftFill = styled.div`
    width: calc(100% - 32px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
`

export const ImageFile = styled.div`
    width: 40px;
    height: 40px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 2px;
    background-color: var(--color-gray-100);
    cursor: zoom-in;
`
