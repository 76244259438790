import { singleTextEllipsis, Tooltip } from '@byecode/ui'
import type { FieldProtocol, HighLightConditions, SimpleTextValue } from '@lighthouse/core'
import { mergeRefs, nanoid } from '@lighthouse/tools'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { CellHighlighter } from '../Highlighter'
import { TooltipList, useWidth } from '../TooltipList'
import { TooltipText } from '../TooltipText'

// import type { FieldValuePreviewerProps } from './types'
interface SimpleTextValuePreviewerProps {
    field: FieldProtocol
    data?: SimpleTextValue | SimpleTextValue[]
    isWrap?: boolean
    width?: number
    isBold?: boolean
    styleConditions?: HighLightConditions
}

interface SimpleTextListPreviewerProps {
    data: SimpleTextValue[]
    field: FieldProtocol
    width: number
    isWrap: boolean
    isBold?: boolean
}

interface SimpleTextItemPreviewerProps {
    id: string
    field: FieldProtocol
    data: SimpleTextValue
    isWrap: boolean
    isBold?: boolean
    isLast?: boolean
    disableTooltip?: boolean
}

const SCxIconWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 3px;
    display: none;
    cursor: pointer;
`

const SCxLinkTo = styled.div<{ isWrap: boolean; isBold?: boolean }>`
    position: relative;
    text-decoration: underline;
    width: 100%;
    font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
    color: var(--color-black);
    white-space: ${({ isWrap }) => (isWrap ? 'pre-wrap' : 'nowrap')};
    overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'hidden')};
    text-overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'ellipsis')};

    word-break: break-word;
    cursor: pointer;

    :hover {
        color: var(--color-app-main);
        text-decoration-color: var(--color-app-main);

        ${SCxIconWrapper} {
            display: block;
        }
    }
`
const SCxItem = styled.div<{ isLast?: boolean }>`
    display: flex;
    align-items: center;
    ${({ isLast }) => isLast && singleTextEllipsis}
`

const SCxSplit = styled.div<{ isLast?: boolean }>`
    display: flex;
    overflow: ${({ isLast }) => (isLast ? 'hidden' : 'unset')};
`

const SCxSimpleTextListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean }>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    overflow: hidden;
`

export const SimpleTextItemPreviewer: React.FC<SimpleTextItemPreviewerProps> = ({
    id,
    field,
    data,
    isWrap,
    isBold,
    isLast,
    disableTooltip
}) => {
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)
    const { type } = field
    const href = useMemo(() => {
        switch (type) {
            case 'phoneNumber': {
                return `tel:${data}`
            }
            case 'email': {
                return `mailto:${data}`
            }
            case 'url': {
                const urlReg = new RegExp(/^(http(s?):\/\/)/g)
                if (urlReg.test(data)) {
                    return data
                }
                return `http://${data}`
            }
            default: {
                return data
            }
        }
    }, [data, type])

    const handleNavigate = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation()
            if (type === 'url') {
                window.open(href)
                return
            }
            window.location.href = href
        },
        [href, type]
    )

    return (
        <TooltipText
            title={data}
            disabled={isWrap || disableTooltip}
            render={ref => {
                return (
                    <SCxItem isLast={isLast} ref={mergeRefs([ref, widthRef])}>
                        <SCxLinkTo isWrap={isWrap} onClick={handleNavigate} isBold={isBold}>
                            {data}
                        </SCxLinkTo>
                    </SCxItem>
                )
            }}
        />
    )
}

export const SimpleTextListPreviewer: React.FC<SimpleTextListPreviewerProps> = ({ data, field, width, isWrap, isBold }) => {
    return (
        <TooltipList
            data={data.map((label, index) => ({ label, value: String(index) }))}
            width={width}
            key={data.join('-')}
            disabled={isWrap}
            render={visibleNum => {
                const renderList = data.slice(0, visibleNum)

                return (
                    <SCxSimpleTextListWrapper isPadding={visibleNum < data.length} isWrap={isWrap}>
                        {renderList.map((item, index) => (
                            <SCxSplit key={index} isLast={index === renderList.length - 1}>
                                <SimpleTextItemPreviewer
                                    id={String(index)}
                                    field={field}
                                    data={item}
                                    disableTooltip
                                    isWrap={isWrap}
                                    isBold={isBold}
                                    isLast={index === renderList.length - 1}
                                />
                                {index !== renderList.length - 1 && ','}
                            </SCxSplit>
                        ))}
                    </SCxSimpleTextListWrapper>
                )
            }}
        />
    )
}

export const SimpleTextValuePreviewer: React.FC<SimpleTextValuePreviewerProps> = ({
    field,
    data,
    width = 160,
    isWrap = true,
    isBold,
    styleConditions
}) => {
    const { id, type } = field
    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (!data) {
            return ''
        }
        if (Array.isArray(data)) {
            return <SimpleTextListPreviewer data={data} field={field} width={width} isWrap={isWrap} isBold={isBold} />
        }
        const id = nanoid()
        return <SimpleTextItemPreviewer id={id} field={field} isWrap={isWrap} data={data} isLast isBold={isBold} />
    }, [data, field, isBold, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={id} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
