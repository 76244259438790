import { singleTextEllipsis } from '@byecode/ui'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Input } from '@byecode/ui/components/Input'
import type { FileProtocols } from '@lighthouse/core'
import { getFileNameWithoutExtension } from '@lighthouse/tools'
import { Anchor, Image, ScrollArea, Skeleton, Text } from '@mantine/core'
import React, { useCallback, useRef, useState } from 'react'
import { useAsyncFn, useMount } from 'react-use'
import styled from 'styled-components'

import type { PexelsItem, PexelsResponse } from './type'

interface PexelsProps {
    style?: React.CSSProperties
    onClick?: (v: FileProtocols) => void
}

const SCxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: var(--color-white);
`

const SCxList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`

const SCxListContainer = styled.div`
    overflow: hidden;
    flex: 1;
`

const SCxImageContainer = styled.div`
    position: relative;
    cursor: pointer;

    &:hover {
        ::before {
            position: absolute;
            inset: 0;
            content: '';
            background-color: rgba(255, 255, 255, 0.3);
            z-index: 1;
        }
    }
`
const SCxImageAuthor = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20px;
    line-height: 20px;
    color: white;
    font-size: 8px;
    padding: 0 4px;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.27) 0%, rgba(20, 18, 18, 0) 100%);
    ${singleTextEllipsis}
    &:hover {
        text-decoration: underline;
    }
`

const SCxTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: var(--font-size-sm);
    line-height: 20px;
`

type SearchParams = {
    search: string
    currentPage: number
}
const pageSize = 40

const loadingArr = Array.from({ length: 20 })
const loadings = loadingArr.map((_, i) => i)

const fetchPexelsList = function (params: SearchParams): Promise<PexelsResponse> {
    const { search, currentPage } = params
    return fetch(`${window.location.origin}/lighthouse/api/v1/third/pexels/search?keyword=${search}&&currentPage=${currentPage}`, {
        headers: {
            // authorization: PEXELS_AUTHORIZATION
        }
    })
        .then(response => response.json())
        .catch(reject => {
            console.log(JSON.stringify(reject))
        })
}

export const Pexels: React.FunctionComponent<PexelsProps> = ({ style, onClick }) => {
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [data, setData] = useState<PexelsItem[]>([])
    const isNoNextDataRef = useRef(false)
    const scrollRef = useRef<HTMLDivElement>(null)
    const [{ loading }, doFetchPexelsList] = useAsyncFn(fetchPexelsList)

    const handleFetch = useCallback(
        async (currentPage = 1) => {
            const res = await doFetchPexelsList({ search, currentPage })
            const {
                content: { photos }
            } = res
            isNoNextDataRef.current = photos.length / pageSize !== 1
            if (currentPage === 1) {
                scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
                setData(photos)
                return
            }
            setData(v => [...v, ...photos])
        },
        [doFetchPexelsList, search]
    )

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                e.stopPropagation()
                isNoNextDataRef.current = false
                setCurrentPage(1)
                handleFetch(1)
            }
        },
        [handleFetch]
    )

    const handleScroll = useCallback(
        (position: { x: number; y: number }) => {
            const { x, y } = position
            const scrollEle = scrollRef.current
            if (!scrollEle || loading) {
                return
            }
            const offsetBottom = scrollEle.scrollHeight - y - scrollEle.clientHeight
            if (offsetBottom < 80 && offsetBottom > 0 && !isNoNextDataRef.current) {
                setCurrentPage(draft => {
                    const reqPage = draft + 1
                    handleFetch(reqPage)
                    return reqPage
                })
            }
        },
        [handleFetch, loading]
    )

    useMount(() => {
        handleFetch()
    })

    return (
        <SCxContainer style={style}>
            <Input
                value={search}
                prefix={<IconFont type="Search" color="var(--color-gray-400)" size={16} />}
                onKeyDown={handleKeyDown}
                onChange={e => setSearch(e.target.value)}
            />
            <SCxTitle>
                图片来源于
                <Anchor href="https://www.pexels.com/" target="_blank" style={{ color: 'var(--color-gray-400)' }} underline>
                    Pexels
                </Anchor>
            </SCxTitle>
            <SCxListContainer>
                <ScrollArea viewportRef={scrollRef} style={{ height: '100%' }} onScrollPositionChange={position => handleScroll(position)}>
                    <SCxList>
                        {data.map(item => {
                            const fileName = getFileNameWithoutExtension(item.src.original)
                            return (
                                <SCxImageContainer
                                    key={item.id}
                                    onClick={e => onClick?.({ name: fileName, url: item.src.original, type: 'image' })}
                                >
                                    <Image key={item.id} width={106} height={105} alt={item.alt} src={item.src.tiny} />
                                    <Anchor href={item.photographer_url} target="_blank">
                                        <SCxImageAuthor>由{item.photographer}拍摄</SCxImageAuthor>
                                    </Anchor>
                                </SCxImageContainer>
                            )
                        })}
                        {loading &&
                            loadings.map(i => (
                                <Skeleton
                                    key={i}
                                    width={106}
                                    height={105}
                                    radius={0}
                                    visible={loading}
                                    animate
                                    style={{ background: 'linear-gradient(270deg, #DADADA 0%, #F3F3F3 100%)' }}
                                />
                            ))}
                    </SCxList>
                </ScrollArea>
            </SCxListContainer>
        </SCxContainer>
    )
}
