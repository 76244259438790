import type { BaseModalProps } from '@byecode/ui'
import { Empty, IconFont, MobileModal } from '@byecode/ui'
import type { DTSelectItem } from '@lighthouse/core'
import { clone, findIndex } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as SC from './styles'

interface SelectDrawerProps {
    opened: boolean
    isMultiple?: boolean
    leftIcon?: string
    selectOptions: DTSelectItem[]
    value: string[]
    title: string
    tagComponent?: React.FC<{ data: DTSelectItem }>
    target?: BaseModalProps['target']
    onChange?: (val: string[]) => void
    onClose: () => void
}

export const SelectDrawer: React.FC<SelectDrawerProps> = ({
    opened,
    leftIcon,
    isMultiple = false,
    selectOptions,
    tagComponent,
    title,
    value,
    target,
    onClose,
    onChange
}) => {
    const [selectValue, setSelectValue] = useState(value)
    const [searchVal, setSearchVal] = useState('')
    const { t } = useTranslation()

    const handleFinish = useCallback(() => {
        onChange?.(selectValue)
        onClose()
    }, [onChange, onClose, selectValue])

    const handleSelectVal = useCallback(
        (val: string) => {
            const cloneSelectValue = new Set(selectValue)
            const isSelected = cloneSelectValue.has(val)

            if (isSelected) {
                cloneSelectValue.delete(val)
                return [...cloneSelectValue]
            }
            cloneSelectValue.add(val)
            return [...cloneSelectValue]
        },
        [selectValue]
    )

    const handleSelectChange = useCallback(
        (val: string) => {
            if (!isMultiple) {
                onChange?.([val])
                setSelectValue([val])
                onClose()
                return
            }
            const newValue = handleSelectVal(val)
            setSelectValue(newValue)
        },
        [handleSelectVal, isMultiple, onChange, onClose]
    )

    const list = useMemo(() => selectOptions.filter(item => item.label.includes(searchVal)), [searchVal, selectOptions])

    return (
        <MobileModal
            leftSlot={!!leftIcon && <IconFont type={leftIcon} />}
            withCloseIcon={!isMultiple}
            data-ignore-click-away
            target={target}
            title={title}
            open={opened}
            styles={{
                modal: {
                    height: '50%'
                }
            }}
            onClose={onClose}
            extra={
                isMultiple ? (
                    <SC.FinishButton role="button" onClick={handleFinish}>
                        完成
                    </SC.FinishButton>
                ) : null
            }
        >
            <SC.Container>
                <SC.SearchInput
                    value={searchVal}
                    placeholder={t('search')}
                    onChange={e => setSearchVal(e.currentTarget.value)}
                    onClear={() => setSearchVal('')}
                    size="lg"
                    clearable
                    prefix={<IconFont type="SearchLine" />}
                />
                {list.length > 0 ? (
                    <SC.TagList>
                        <SC.TagWrapper>
                            {list.map(item => (
                                <SC.TagItem key={item.label} onClick={() => handleSelectChange(item.label)}>
                                    {tagComponent ? tagComponent({ data: item }) : <SC.TagItemContent>{item.label}</SC.TagItemContent>}
                                    {selectValue.includes(item.label) && <SC.Icon type="Tick" />}
                                </SC.TagItem>
                            ))}
                        </SC.TagWrapper>
                    </SC.TagList>
                ) : (
                    <Empty
                        styles={{
                            root: {
                                padding: '42px'
                            }
                        }}
                        icon="SearchLine"
                        description={t('noFindData')}
                    />
                )}
            </SC.Container>
        </MobileModal>
    )
}
