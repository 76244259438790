import React, { useRef } from 'react'

import { useDetectTextOverflow } from '../../hooks/useDetectTextOverflow'
import { isElement } from '../../utils/isElement'
import type { TooltipProps } from '../Tooltip/Tooltip'
import { Tooltip } from '../Tooltip/Tooltip'

export const TooltipWithTextOverflow = ({ disabled, children, ...props }: TooltipProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const overflow = useDetectTextOverflow(ref)

    if (!isElement(children)) {
        throw new Error('Must use React.Element, not string | number | symbol')
    }

    return (
        <Tooltip {...props} disabled={!overflow || disabled}>
            {React.cloneElement(children, { ref })}
        </Tooltip>
    )
}
