import type { HighLightConditions, NumberField, NumberValue, NumberViewField } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import React, { useMemo } from 'react'

import { NumberItemPreviewer, NumberListPreviewer } from '../FieldValuePreviewer/NumberValuePreviewer'
import { CellHighlighter } from '../Highlighter'

interface NumberValuePreviewerProps {
    data?: NumberValue | NumberValue[]
    field: NumberField
    isWrap?: boolean
    width?: number
    styleConditions?: HighLightConditions
    isBold?: boolean
}

export const NumberValuePreviewer: React.FC<NumberValuePreviewerProps> = ({
    field,
    data,
    width = 160,
    isWrap = true,
    isBold,
    styleConditions
}) => {
    const { id: fieldId, type } = field

    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (!data && data !== 0) {
            return ''
        }
        if (Array.isArray(data)) {
            return <NumberListPreviewer data={data} field={field} width={width} isWrap={isWrap} isBold={isBold} />
        }
        const id = nanoid()
        return <NumberItemPreviewer id={id} field={field} isWrap={isWrap} data={data} isBold={isBold} isLast />
    }, [data, field, isBold, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={fieldId} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
