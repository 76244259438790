import { IconFont, Input } from '@byecode/ui'
import { type FilterCommonCondition, VariableType } from '@lighthouse/core'
import { lightFormat } from 'date-fns'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const SCxIcon = styled(IconFont)`
    color: var(--color-gray-400);
    &:hover {
        color: var(--color-gray-800);
    }
`

interface DateParamsProps {
    filterItem: FilterCommonCondition
    index?: 0 | 1
    onClick?: () => void
    onClear?: () => void
}

export const DateParams: React.FC<DateParamsProps> = ({ filterItem, onClick, index = 0, onClear }) => {
    const value = useMemo(() => {
        const param = filterItem.paramList?.[index]
        if (param && param.type === VariableType.VALUE && param.valueVariable && typeof param.valueVariable.value) {
            return param.valueVariable.value
        }
        return ''
    }, [filterItem.paramList, index])

    const selectSuffixDom = value ? (
        <SCxIcon
            type="CloseCircle"
            style={{ cursor: 'pointer' }}
            size={16}
            onClick={e => {
                e.stopPropagation()
                onClear?.()
            }}
        />
    ) : (
        <IconFont color="var(--color-gray-400)" size={16} type="ArrowDownSmall" />
    )

    const dateText = value ? lightFormat(Number(value), 'yyyy-MM-dd HH:mm:ss') : ''
    return <Input readOnly placeholder="请选择" focusOutLine={false} onClick={onClick} value={dateText} suffix={selectSuffixDom} />
}
