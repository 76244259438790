import type { DateField, DateValue, HighLightConditions } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import React, { useMemo } from 'react'

import { DateItemPreviewer, DateListPreviewer } from '../FieldValuePreviewer/DateValuePreviewer'
import { CellHighlighter } from '../Highlighter'

interface DateValuePreviewerProps {
    field: DateField
    data?: DateValue | DateValue[]
    isWrap?: boolean
    width?: number
    styleConditions?: HighLightConditions
    isBold?: boolean
}

export const DateValuePreviewer: React.FC<DateValuePreviewerProps> = ({
    data,
    field,
    width = 160,
    isWrap = true,
    isBold,
    styleConditions
}) => {
    const { id: fieldId } = field

    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (!data) {
            return ''
        }
        if (Array.isArray(data)) {
            return <DateListPreviewer field={field} data={data} isWrap={isWrap} width={width} isBold={isBold} />
        }
        const id = nanoid()
        return <DateItemPreviewer id={id} field={field} isWrap={isWrap} data={data} isLast isBold={isBold} />
    }, [data, field, isBold, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={fieldId} type="date" value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
