import React, { useEffect, useLayoutEffect, useState } from 'react'
import { debounce } from 'throttle-debounce'

import { PAGE_CONTAINER_HOST } from '../constants'

const getRootSize = () => {
    const newRemBase = window.getComputedStyle(document.documentElement).fontSize
    return Number(newRemBase.split('px')[0])
}

/**
 * 获取html fontsize
 * @date 2024/3/29 - 17:26:28
 *
 * @returns {*}
 */
export const useMediaQueryRemBase = () => {
    const [remBase, setRemBase] = React.useState(getRootSize)

    useEffect(() => {
        // 如果是管理端， 监听模拟器的变化，否则监听根节点的resize变化
        const simulatorDom = document.querySelector(PAGE_CONTAINER_HOST)
        if (simulatorDom) {
            const observer = new MutationObserver(() => {
                setRemBase(getRootSize())
            })
            observer.observe(document.documentElement, { attributeFilter: ['style'] })
            // const observer = new ResizeObserver(() => {
            //     console.log('when use')
            //     setRemBase(getRootSize())
            // })
            // observer.observe(simulatorDom)

            return () => {
                observer.disconnect()
            }
        }

        // const computeRemBase = debounce(1000 / 60, () => {
        //     setRemBase(getRootSize())
        // })

        // window.addEventListener('resize', computeRemBase)

        // return () => {
        //     window.removeEventListener('resize', computeRemBase)
        // }
    }, [])

    return remBase
}

export type EndPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
const getEndPoints = (windowSize: number): { query: boolean; remBase: number; emBase: number; endpoint: EndPoint }[] => [
    {
        query: windowSize <= 736,
        remBase: 8,
        emBase: 12,
        endpoint: 'xs'
    },
    {
        query: windowSize > 736 && windowSize <= 980,
        remBase: 10,
        emBase: 10,
        endpoint: 'sm'
    },
    {
        query: windowSize > 980 && windowSize <= 1280,
        remBase: 12,
        emBase: 12,
        endpoint: 'md'
    },
    {
        query: windowSize > 1280 && windowSize <= 1680,
        remBase: 16,
        emBase: 12,
        endpoint: 'lg'
    },
    {
        query: windowSize > 1680,
        remBase: 16,
        emBase: 16,
        endpoint: 'xl'
    }
]

/**
 * 手动设置remBase
 * @date 2024/3/29 - 17:26:28
 *
 * @returns {*}
 */

const setRemBaseSize = (containerDom?: HTMLElement) => {
    const simulatorDom = containerDom ?? document.querySelector<HTMLDivElement>(PAGE_CONTAINER_HOST)
    if (!simulatorDom) {
        return 'xl'
    }

    const windowSize = simulatorDom.clientWidth
    const endPoints = getEndPoints(windowSize).find(item => item.query)
    const remBase = endPoints?.remBase
    const emBase = endPoints?.emBase
    document.documentElement.style.fontSize = `${remBase}px`
    // simulatorDom.style.fontSize = `${emBase}px`

    return endPoints?.endpoint ?? 'xl'
}

export const useManualSetMediaQueryRemBase = (): EndPoint => {
    const [endpoint, setEndpoint] = useState('xl')
    // 首次设值
    useEffect(() => {
        const endpoint = setRemBaseSize()
        setEndpoint(endpoint)
    }, [])

    // 监听容器 resize
    useEffect(() => {
        const simulatorDom = document.querySelector<HTMLDivElement>(PAGE_CONTAINER_HOST)
        if (!simulatorDom) {
            return
        }
        const observer = new ResizeObserver(() => {
            const endpoint = setRemBaseSize(simulatorDom)
            setEndpoint(endpoint)
        })
        observer.observe(simulatorDom)
        return () => {
            observer.disconnect()
        }
    }, [])

    return endpoint as EndPoint
}
