import { IconFont } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const cardWithShadow = (radius = '8px') => css`
    overflow: hidden;
    border: none;
    border-radius: ${radius};
    background-color: #fff;
    box-shadow: var(--box-shadow);
`

export const fontFamilyList = () => css``

export const Container = styled.div`
    position: relative;
    padding: 0;
    width: 680px;
    background: transparent;
    overflow: visible;
    background-color: #fff;
    border: 1px solid transparent;
    ${cardWithShadow('12px')}
    box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 12%), 0px 16px 20px 1px rgb(0 0 0 / 10%), 0px 6px 28px 4px rgb(0 0 0 / 8%);
`

export const CodeContainer = styled.div`
    padding: 0;
    margin: 0;
    width: 680px;
    height: 100%;
`

export const CodeHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    border-bottom: 1px solid var(--color-gray-200);
`

export const CodeTitle = styled.div`
    color: var(--color-black);
    font-size: var(--h3-font-size);
`

export const CodeSpread = styled(IconFont)``
