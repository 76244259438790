import type { BackgroundNoImageProtocol } from '@lighthouse/core'
import { type TabsType, TABS_TYPE } from '@lighthouse/core'
import type { FC } from 'react'
import React from 'react'

import { CardSlotTabs } from './CardSlotTabs'
import { LineTabs } from './LineTabs'
import { RoundTabs } from './RoundTabs'
import { SegmentedTabs } from './SegmentedTabs'
import type { BaseTabsProps } from './Tabs.type'

export interface TabsProps extends BaseTabsProps {
    mode?: TabsType
    scale?: number
    lineColor?: BackgroundNoImageProtocol
}

export const Tabs: FC<TabsProps> = ({ mode, scale, lineColor, ...rest }) => {
    switch (mode) {
        case TABS_TYPE.LINE: {
            return <LineTabs scale={scale} lineColor={lineColor} {...rest} />
        }

        case TABS_TYPE.CARD_SLOT: {
            return <CardSlotTabs {...rest} />
        }

        case TABS_TYPE.ROUND: {
            return <RoundTabs {...rest} />
        }

        case TABS_TYPE.SEGMENTED: {
            return <SegmentedTabs {...rest} />
        }

        default: {
            return null
        }
    }
}
