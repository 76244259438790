import { Empty } from '@byecode/ui'
import type { BatchItem, UploadOptions } from '@rpldy/shared-ui'
import type { FC } from 'react'
import React from 'react'

import { UploadItem } from '../UploadItem'
import * as SC from './UploadList.style'

interface UploadListProps {
    label: string
    options?: UploadOptions
    items: BatchItem[]
    onDeleteItem: (id: string) => void
    onPreview: (id: string) => void
}

export const UploadList: FC<UploadListProps> = ({ label, options, items, onDeleteItem, onPreview }) => {
    return (
        <SC.Root>
            <SC.Label>{label}</SC.Label>

            {items.length === 0 ? (
                <Empty description="暂无内容" styles={{ root: { padding: 20 } }} />
            ) : (
                <SC.List>
                    {items.map(item => (
                        <UploadItem
                            key={item.id}
                            id={item.id}
                            file={item.file as File}
                            status={item.state}
                            progress={item.completed}
                            options={options}
                            onDeleteItem={onDeleteItem}
                            onPreview={onPreview}
                        />
                    ))}
                </SC.List>
            )}
        </SC.Root>
    )
}
