import { Button } from '@byecode/ui'
import styled from 'styled-components'

export const Root = styled.div`
    position: fixed;
    z-index: 1000;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: min(480px, 100vw);
    max-height: min(600px, 100vh);
    border-radius: 12px 12px 10px 0px;
    border: 1px solid var(--color-gray-100);
    background: var(--color-white);
    box-shadow: 0px 4px 12px 0px rgba(16, 24, 40, 0.1);
    overflow: hidden;
`

export const CollapseHeader = styled.div`
    padding: 10px 16px;
    font-size: 14px;
    line-height: 16px;
    background: var(--color-gray-100);
`

export const MainArea = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    &[data-collapse] {
        border-top: 1px solid var(--color-gray-200);
    }
`

export const MainTabs = styled.div`
    display: flex;
    align-items: stretch;
    gap: 12px;
`

export const MainTabLabel = styled.div``
export const MainTabBadge = styled.div``

export const MainTab = styled(Button).attrs({ size: 'lg', type: 'text' })`
    position: relative;
    gap: 6px;
    border-radius: unset;
    height: unset;
    padding: 12px 8px;
    font-size: 16px;
    line-height: 16px;
    &::before {
        opacity: 0;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        border-radius: 11px;
        background-color: var(--color-main);
    }
    &[data-active='true'] {
        color: var(--color-gray-900);
        font-weight: 600;
        &::before {
            opacity: 1;
        }
    }
`

export const MainActions = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`
