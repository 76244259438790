import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

export const useStyles = createStyles(() => ({
    root: css({
        position: 'relative'
    }),
    loading: css({
        position: 'absolute',
        zIndex: 1,
        inset: 0
    }),
    pagination: css({
        marginTop: 12
    })
}))
