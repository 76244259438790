import { Button, Input, Select } from '@byecode/ui'
import type { DTNumber, Field, NumberField } from '@lighthouse/core'
import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHotkeys } from 'react-hotkeys-hook'
import styled from 'styled-components'

import { InnerTypeMapByFieldType } from '../../constants'
import * as SC from './styles'

interface NumberConfigViewProps {
    children?: React.ReactNode
    config: NumberField
    onOk?: (data: Field) => void
    onCancel: () => void
}

const SCxUnitInput = styled(Input)`
    flex: 1;
    font-size: var(--font-size-normal);
`

const NumberConfigView: React.FC<NumberConfigViewProps> = ({ config, onOk, onCancel }) => {
    const { watch, control, getValues } = useForm<DTNumber>({
        defaultValues: {
            accuracy: 2,
            ...config?.number
        }
    })
    // const [unit, unitPosition, accuracy] = watch(['unit', 'unitPosition', 'accuracy'])

    // const numberTip = [unit, (100).toFixed(accuracy || 0)]
    // unitPosition 0为后缀 1 为前缀
    // const positionedNumberTip = unitPosition ? numberTip.reverse() : numberTip

    const handleOk = useCallback(() => {
        onOk?.({ ...config, type: 'number', number: getValues(), innerType: InnerTypeMapByFieldType['number'] })
    }, [config, getValues, onOk])

    useHotkeys(
        'Enter',
        () => {
            handleOk()
        },
        { enableOnTags: ['INPUT'] }
    )

    return (
        <>
            <SC.GroupWrapper>
                <SC.FormItemWrapper>
                    <SC.FieldConfigHeader>前缀</SC.FieldConfigHeader>
                    <SC.FormItemLimitCell>
                        <Controller
                            control={control}
                            name="prefix"
                            render={({ field }) => <SCxUnitInput {...field} placeholder="请输入" />}
                        />
                    </SC.FormItemLimitCell>
                </SC.FormItemWrapper>
                <SC.FormItemWrapper>
                    <SC.FieldConfigHeader>后缀</SC.FieldConfigHeader>
                    <SC.FormItemLimitCell>
                        <Controller
                            control={control}
                            name="suffix"
                            render={({ field }) => <SCxUnitInput {...field} placeholder="请输入" />}
                        />
                    </SC.FormItemLimitCell>
                </SC.FormItemWrapper>
                <SC.FormItemWrapper>
                    <SC.FieldConfigHeader>精度</SC.FieldConfigHeader>
                    <SC.FormItemLimitCell>
                        <Controller
                            control={control}
                            name="accuracy"
                            render={({ field }) => (
                                <SC.FieldConfigSelect
                                    value={String(field.value)}
                                    styles={{ root: { width: '100%' } }}
                                    onChange={val => field.onChange(Number(val))}
                                    options={[
                                        { label: '1', value: '0' },
                                        { label: '1.0', value: '1' },
                                        { label: '1.00', value: '2' },
                                        { label: '1.000', value: '3' }
                                    ]}
                                />
                            )}
                        />
                    </SC.FormItemLimitCell>
                </SC.FormItemWrapper>
            </SC.GroupWrapper>

            <SC.ActionWrapper>
                <Button style={{ marginRight: 8 }} onClick={onCancel}>
                    取消
                </Button>
                <Button type="primary" onClick={handleOk}>
                    确定
                </Button>
            </SC.ActionWrapper>
            {/* <SC.ConfigPreviewTip>示例：{positionedNumberTip.join(' ')}</SC.ConfigPreviewTip> */}
        </>
    )
}

export default NumberConfigView
