import type { Field, TypeInstanceMap } from '@lighthouse/core'
import { type DataSourceAbstract, DataSourceType } from '@lighthouse/core'
import { find, reduce } from 'rambda'

import { NO_EDIT_SYSTEM_FIELD, notEditableFieldTypes, USER_DATASOURCE } from '../constants'
import type { AppDepartment } from '../types'
import { getIsJoinNoEditSystemField } from './field'

// 获取任意表的主表的字段ID
export const getPrimaryDataSourceEnableFieldIds = (dataSource: DataSourceAbstract, dataSourceList: DataSourceAbstract[]) => {
    const fieldIds = new Set<string>()
    const { viewOptions, type, schema: currentSchema } = dataSource
    const { joinConfig } = viewOptions
    if (type !== 5 || !joinConfig) {
        return new Set<string>(Object.values(currentSchema).map(item => item.id))
    }
    const { primaryDsId } = joinConfig
    // const primaryDs = find(item => item.id === primaryDsId, dataSourceList)
    // if (!primaryDs) {
    //     return new Set<string>(Object.values(currentSchema).map(item => item.id))
    // }
    // let joinPrimaryDsId = primaryDsId
    // const { sync, syncDsId } = primaryDs
    // if (sync && syncDsId) {
    //     joinPrimaryDsId = syncDsId
    // }
    return Object.entries(currentSchema).reduce<Set<string>>((ids, [fieldId, field]) => {
        // const [dsId, id] = fieldId.split('__')
        const { join } = field
        if (join?.joinDsId === primaryDsId) {
            ids.add(fieldId)
        }
        return ids
    }, fieldIds)
}

export const getPrimaryDsSystemFieldId = (id: string, params: { dataSource: DataSourceAbstract; dataSourceList: DataSourceAbstract[] }) => {
    const { dataSource, dataSourceList } = params
    const fieldIds = getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)
    return [...fieldIds].find(fieldId => {
        const mainFieldId = fieldId?.includes('__') ? fieldId.split('__')?.[1] : fieldId
        return mainFieldId === id
    })
}

export const getPrimaryDsIdByJoinDataSource = (dataSource: DataSourceAbstract) => {
    if (dataSource.type !== DataSourceType.joinDataSource) {
        return dataSource.id
    }
    const {
        viewOptions: { joinConfig }
    } = dataSource
    return joinConfig?.primaryDsId
}

export const getIsIncludesDisableDataSourceInWorkFlow = (dataSource: DataSourceAbstract) => {
    // if (dataSource.type !== DataSourceType.joinDataSource) {
    //     return (isOmitUserDataSource && dataSource.id === USER_DATASOURCE) || dataSource.type === DataSourceType.aggregateDataSource
    // }
    // const {
    //     viewOptions: { joinConfig }
    // } = dataSource
    // return joinConfig?.primaryDsId === USER_DATASOURCE
    return dataSource.type === DataSourceType.aggregateDataSource
}


export const getIsAllowedDataSourceAsFlowOption = (dataSource: DataSourceAbstract) => {
    return dataSource.type !== DataSourceType.aggregateDataSource
}

// 获取可编辑的字段
export const getCanEditFieldIds = (dataSource: DataSourceAbstract, dataSourceList: DataSourceAbstract[]) => {
    const mainFieldIds = getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)
    return reduce<[string, Field], string[]>(
        (pre, cur) => {
            const [fieldId, field] = cur
            if (mainFieldIds.has(fieldId) && !notEditableFieldTypes.has(field.type) && !NO_EDIT_SYSTEM_FIELD.has(fieldId) && !getIsJoinNoEditSystemField(field)) {
                return [...pre, fieldId]
            }
            return pre
        },
        [],
        Object.entries(dataSource.schema)
    )
}

export const getIsMatchInnerTypeByOperator = (innerType: TypeInstanceMap | undefined, operator: string, matchInnerType: TypeInstanceMap) => {
    if (innerType === 'ARRAY' && ['contains', 'notContains'].includes(operator)) {
        return ['TEXT', 'NUMBER', 'ARRAY'].includes(matchInnerType)
    }
    return innerType === matchInnerType
}


const getDepartmentParentPath = (list: AppDepartment[], data: AppDepartment, path: string): string => {
    const parent = find(item => item.id === data.parentId?.[0], list)
    if (!parent) {
        return path
    }
    const parentPath = `${parent.name}/${path}`
    if (parent.parentId) {
        return getDepartmentParentPath(list, parent, parentPath)
    }
    return parentPath
}

export const getDepartmentListWithPath = (list: AppDepartment[]) => {
    return list.map(item => {
        const labelPath = getDepartmentParentPath(list, item, item.name)
        return {
            ...item,
            labelPath
        }
    })
}
