import { Flex } from '@byecode/ui'
import type { AnyObject } from '@byecode/ui/types'
import type { ApplicationSettingAuthenticationField, DataSourceAbstract } from '@lighthouse/core'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { ApplicationPreviewEnum } from '../../../../types'
import { getFieldInputTypeByFieldType } from '../../../../utils'
import { ErrorMessage } from '../../../ErrorMessage'
import { FieldInput } from '../../../FieldInput'
import type { CascadeParam, FieldInputConfig, RelativeFieldParam } from '../../../FieldInput/types'
import { commonFieldInputConfig } from '../../constant'
import { getFieldInputConfig } from '../../helps'
import type { AccountPerfectForm } from '../../types'

export type Fields = ApplicationSettingAuthenticationField[]

export const SCxItem = styled.div`
    width: 100%;
`
interface PerfectFieldsProps extends RelativeFieldParam, CascadeParam {
    // data: Fields
    dataSource?: DataSourceAbstract
    errors: AnyObject
    previewType?: ApplicationPreviewEnum
}

export const PerfectFields: React.FunctionComponent<PerfectFieldsProps> = ({
    dataSource,
    errors,
    previewType,
    relativeDataSource,
    dataSourceList,
    onFetchCascadeOptions,
    onFetchDataSource,
    onLoadMoreData
}) => {
    const { control, register, setValue } = useFormContext<AccountPerfectForm>()
    const { t } = useTranslation()

    const { fields } = useFieldArray({
        control,
        name: 'fields'
    })
    return (
        <Flex direction="column" gap={20}>
            {fields.map((config, index) => {
                const { fieldId, title, placeHolder, required } = config
                return (
                    <SCxItem key={fieldId}>
                        <Controller
                            name={`fields.${index}.value`}
                            control={control}
                            rules={required ? { required: t('notEmpty') } : undefined}
                            render={({ field: { value, onChange } }) => (
                                <ErrorMessage name={`fields.${index}.value`} errors={errors}>
                                    <FieldInput
                                        dataSource={dataSource}
                                        mode="datasource"
                                        config={getFieldInputConfig({ previewType, field: dataSource?.schema?.[fieldId], config })}
                                        value={value}
                                        style={{ padding: 0 }}
                                        relativeDataSource={relativeDataSource}
                                        dataSourceList={dataSourceList}
                                        onSaveChange={fieldInputValue => {
                                            onChange(fieldInputValue.value)
                                        }}
                                        onFetchCascadeOptions={onFetchCascadeOptions}
                                        onFetchDataSource={onFetchDataSource}
                                        onLoadMoreData={onLoadMoreData}
                                    />
                                </ErrorMessage>
                            )}
                        />
                    </SCxItem>
                )
            })}
        </Flex>
    )
}
