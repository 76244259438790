import { createStyles } from '../../theme/createStyles'
import { css } from '../../theme/stitches.config'
import type { Selectors } from '../../theme/types'

export const useStyles = createStyles(({ color }: { color?: string }) => ({
    root: css({
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        fontSize: '16px',
        // overflow: 'hidden',
        minHeight: 18
    }),
    track: css({
        flex: 1,
        position: 'relative',
        height: 6,
        borderRadius: '12px',
        backgroundColor: '$colorGray300',
        cursor: 'pointer'
    }),
    rail: css({
        position: 'absolute',
        top: 0,
        bottom: 0,
        borderRadius: 'inherit',
        backgroundColor: color || '$colorPrimary',
    }),
    thumb: css({
        position: 'absolute',
        zIndex: 2,
        left: 0,
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: 18,
        height: 18,
        border: '1px solid $colorGray200',
        borderRadius: '50%',
        backgroundColor: '$colorWhite',
        boxShadow: '0px 4px 12px 0px rgba(16, 24, 40, 0.2)',
        '&[data-active = true]': {
            borderColor: '$colorPrimary',
            backgroundColor: '$colorPrimary'
        }
    })
}))

export type SliderStyleNames = Selectors<typeof useStyles>
