import { Box } from '@byecode/ui/components/Box'
import { Flex } from '@byecode/ui/components/Flex'
import { IconFont } from '@byecode/ui/components/IconFont'
import type { StyleComponentProps } from '@byecode/ui/theme/types'
import React, { forwardRef } from 'react'

import type { Option } from '../types'
import { useStyles } from './SelectItem.style'
import type { SelectItemStyleNames } from './SelectItem.type'

export interface SelectItemProps
    extends Option,
        StyleComponentProps<SelectItemStyleNames>,
        Omit<React.ComponentPropsWithoutRef<'div'>, 'onSelect' | 'value' | 'children'> {}

export const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>((props, ref) => {
    const { classNames, styles, unstyled, label, value, icon, extra, describe, ...rest } = props
    const { classes } = useStyles({}, { name: 'selectItem', classNames, styles, unstyled })

    return (
        <Box ref={ref} {...rest}>
            <Flex gap="8px" alignItems="center" justifyContent="space-between" className={classes.body}>
                <Flex gap="8px" styles={{ root: { lineHeight: '16px', overflow: 'hidden' } }}>
                    {typeof icon === 'string' ? <IconFont type={icon} size={16} className={classes.icon} /> : icon}
                    <Box className={classes.label}>{label}</Box>
                </Flex>
                {extra}
            </Flex>
            {describe && <Box className={classes.describe}>{describe}</Box>}
        </Box>
    )
})
