import { getBrowser } from '@lighthouse/tools'

import { ApplicationPreviewEnum } from '../types'

export const getApplicationPreviewType = () => {
    const browser = getBrowser()

    const result = browser.parseUserAgent()

    return result.isMobile ? ApplicationPreviewEnum.mobile : ApplicationPreviewEnum.desktop
}
