// import { FieldGroupBlock } from '@lighthouse/block'
import { useCustomViewBlockContext } from '@lighthouse/block'
import type { FieldGroupBlockAbstract } from '@lighthouse/core'
import { getViewColumns, useAtomAction, useAtomData, useFormModuleContext } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import React, { Suspense, useCallback } from 'react'

import { addAiFieldStatusAtom } from '@/atoms/dataSource/action'
import { aiFieldStatusListAtom } from '@/atoms/dataSource/state'
import { pageAtomFamily } from '@/atoms/page/state'
import { useCurrentPageContext } from '@/context/PageContext'
import { useCurrentAppId, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { usePageCurrentDsRecord } from '@/hooks/useParentRecord'
import * as srv from '@/services'

const FieldGroupBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.FieldGroupBlock })))

interface FieldGroupBlockControllerProps {
    blockData: FieldGroupBlockAbstract
}

const FieldGroupBlockController: React.FunctionComponent<FieldGroupBlockControllerProps> = ({ blockData }) => {
    const { config, id: blockId } = blockData
    const { viewFields } = config
    const { run: addAiFieldStatus } = useAtomAction(addAiFieldStatusAtom)

    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const formModule = useFormModuleContext()
    const { pageId } = useCurrentPageContext()
    const { pointer: viewPointer, record: viewRecord } = useCustomViewBlockContext()
    const pageDsId = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.dsId ?? '', [])
    )
    const pointer = viewPointer || formModule?.pointer || pageDsId
    const dataSource = useDataSource(appId, envId, pointer)
    const { record } = usePageCurrentDsRecord()

    const currentRecord = viewRecord || record

    const handleAiGeneration = useCallback(
        async (recordId: string, fieldId: string) => {
            const statusId = nanoid()
            const isSuccess = await srv.aiGenerate({ dsId: pointer, recordId, fieldId, pageId })
            if (isSuccess) {
                addAiFieldStatus({
                    id: statusId,
                    dataSourceId: pointer,
                    recordId,
                    fieldId,
                    state: 'STARTED'
                })
            }
            return isSuccess
        },
        [addAiFieldStatus, pageId, pointer]
    )

    const currentColumns = React.useMemo(
        () =>
            getViewColumns({
                tableProps: dataSource?.viewOptions.tableProps ?? [],
                value: viewFields,
                schema: dataSource?.schema ?? {}
            }).filter(item => item.type !== 'notes'),
        [dataSource?.schema, dataSource?.viewOptions.tableProps, viewFields]
    )

    return (
        <Suspense fallback={<div />}>
            <FieldGroupBlock
                dsId={pointer}
                blockData={blockData}
                dataSource={dataSource}
                columns={currentColumns}
                record={currentRecord}
                aiFieldStatusListAtom={aiFieldStatusListAtom}
                onAiGeneration={handleAiGeneration}
            />
        </Suspense>
    )
}

export default FieldGroupBlockController
