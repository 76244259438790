import { IconFont, Popover } from '@byecode/ui'
import { type DataSourceAbstract, type Field, type ViewFieldProtocol, DataSourceType } from '@lighthouse/core'
import { subtract } from 'rambda'
import React, { useCallback, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import type { DraftFunction } from 'use-immer'

import type { TableFieldState } from '../TableField'
import { TableFieldConfigurator } from '../TableFieldConfigurator'
import { TableFieldEditor } from '../TableFieldEditor'
import type { InsertDirection } from '../types'

interface TableFieldSettingProps {
    width?: number
    dataSource: DataSourceAbstract
    columns: ViewFieldProtocol[]
    currentFieldId: string
    // fieldId: string
    // mode: string
    config: TableFieldState
    configurable?: boolean
    absolute?: boolean
    dataSourceList: DataSourceAbstract[]
    disableFindUse?: boolean
    disableHidden?: boolean
    onStateChange: (state: TableFieldState | DraftFunction<TableFieldState>) => void
    onCreateField: (data: Field) => void
    onUpdateField?: (data: Field) => Promise<boolean>
    onDeleteField?: (data: Field) => Promise<boolean>
    onFindUse?: (fieldId: string) => void
}

export const SCxMoreIcon = styled.div<{ absolute?: boolean, open?: boolean }>`
    display: inline-flex;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    opacity: ${({ open }) => open ? 1 : 0};

    cursor: pointer;

    ${({ absolute }) =>
        absolute &&
        css`
            position: absolute;
            right: 6px;
            top: 50%;
            margin-top: -12px;
        `}
`

export const TableFieldSetting: React.FC<TableFieldSettingProps> = ({
    width,
    dataSource,
    columns,
    currentFieldId,
    config,
    configurable,
    absolute,
    dataSourceList,
    disableFindUse,
    disableHidden,
    onStateChange,
    onCreateField,
    onUpdateField,
    onDeleteField,
    onFindUse
}) => {
    const { fieldId, mode, open, action } = config
    const { schema, sync: dataSourceSync, type } = dataSource
    const field = schema?.[fieldId]

    const dotRef = useRef<HTMLDivElement>(null)

    const handleOpenChange = useCallback(
        (opened: boolean) => {
            onStateChange(draft => {
                draft.open = opened
            })
        },
        [onStateChange]
    )

    const handleCancel = useCallback(() => {
        handleOpenChange(false)
    }, [handleOpenChange])

    const handleSwitchEditMode = useCallback(() => {
        onStateChange(draft => {
            draft.mode = 'edit'
            draft.action = 'update'
            draft.fieldId = currentFieldId
            draft.sourceId = ''
            draft.direction = ''
        })
    }, [currentFieldId, onStateChange])

    const handleConfigPopoverOpen = useCallback(() => {
        onStateChange(draft => {
            draft.mode = 'config'
            draft.action = 'update'
            draft.fieldId = currentFieldId
            draft.open = true
        })
    }, [currentFieldId, onStateChange])

    const mainAxis = useMemo(() => {
        if (type === DataSourceType.joinDataSource) {
            return 15
        }
        return 5
    }, [type])

    const crossAxis = useMemo(() => {
        if (!width) {
            return 5
        }
        return -subtract(width, 30)
    }, [width])

    const handleOk = useCallback(
        (data: Field) => {
            onStateChange(draft => {
                draft.open = false
            })
            if (action === 'update') {
                onUpdateField?.(data)
                return
            }
            onCreateField?.(data)
        },
        [action, onCreateField, onStateChange, onUpdateField]
    )

    const handleInsertField = useCallback(
        (sourceId: string, direction: InsertDirection) => {
            onStateChange(draft => {
                draft.mode = 'edit'
                draft.action = 'create'
                draft.fieldId = ''
                draft.sourceId = sourceId
                draft.direction = direction
                draft.open = true
            })
        },
        [onStateChange]
    )

    const handleFindUse = useCallback(
        (fieldId: string) => {
            onStateChange(draft => {
                draft.open = false
            })
            onFindUse?.(fieldId)
        },
        [onFindUse, onStateChange]
    )

    const handleDeleteField = useCallback(() => {
        if (onDeleteField) {
            return onDeleteField?.(field)
        }
        return Promise.resolve(false)
    }, [field, onDeleteField])

    if (!configurable) {
        return null
    }
    return (
        <Popover
            opened={open}
            width="auto"
            withinPortal
            offsetOptions={{ mainAxis, crossAxis }}
            onChange={handleOpenChange}
            position="bottom-start"
        >
            <Popover.Target>
                <SCxMoreIcon open={open || disableHidden} ref={dotRef} className="dotsThree" absolute={absolute} onClick={handleConfigPopoverOpen}>
                    <IconFont type="DotsThree" size={14} />
                </SCxMoreIcon>
            </Popover.Target>
            <Popover.Dropdown
                style={{
                    padding: 0,
                    borderRadius: '8px',
                    overflow: 'hidden'
                }}
            >
                {mode === 'config' ? (
                    <TableFieldConfigurator
                        field={field}
                        dataSource={dataSource}
                        disableFindUse={disableFindUse}
                        onClose={handleCancel}
                        onDeleteField={handleDeleteField}
                        onInsertField={handleInsertField}
                        onFindUse={handleFindUse}
                        onSwitchEditMode={handleSwitchEditMode}
                    />
                ) : (
                    <TableFieldEditor
                        dataSourceList={dataSourceList}
                        stage={fieldId ? 'set' : 'edit'}
                        fieldId={field?.id}
                        dataSource={dataSource}
                        columns={columns}
                        // onResetPosition={handleResetPosition}
                        // onOpenFormulaConfigView={handleEditPopoverOpen}
                        onCancel={handleCancel}
                        onOk={handleOk}
                    />
                )}
            </Popover.Dropdown>
            {/* <SCxPopover
                // disablePortal
                // disableEnforceFocus
                // disableRestoreFocus
                style={{ position: 'absolute', zIndex: 100 }}
                height={getPopoverHeight()}
                {...bindPopover(popoverState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >

            </SCxPopover> */}
        </Popover>
    )
}
