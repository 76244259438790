import styled, { css } from 'styled-components'

export const FormulaDescribe = styled.div`
    flex: 1;
    height: 100%;
    padding: 20px 24px;
    font-size: 14px;
    overflow-y: auto;
`

export const Name = styled.div`
    font-size: 20px;
    color: var(--color-black);
    margin-bottom: 16px;
`

export const Describe = styled.div`
    margin-bottom: 16px;
    color: var(--color-black);
`

export const Usage = styled.div`
    border: 1px solid #dfdfdd;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 16px;
`
// padding: 10px 12px;
export const Example = styled.div`
    background-color: #fafafa;
    padding: 10px 12px 0 12px;
`

export const UsageContent = styled.div`
    color: var(--color-black);
    line-height: 24px;
    padding-bottom: 12px;
`

export const List = styled.div``

export const Help = styled.div`
    border-top: 1px solid #dfdfdd;
    background-color: #fff;
`

export const ParamDescribe = styled.div`
    margin-bottom: 16px;
`

export const Title = styled.div`
    width: 100%;
    height: 28px;
    line-height: 20px;
    color: var(--color-black);
    font-weight: bold;
`

export const InputList = styled.div`
    display: grid;
    grid-template-columns: 2;
`

export const InputTitle = styled.div`
    background-color: #fafafa;
    border: 1px solid #dfdfdd;
    padding: 2px 4px;
    color: var(--color-black);
    border-radius: 6px;
    text-align: center;
`

export const Table = styled.table`
    border-collapse: separate;
    border-spacing: 0 8px;
`

export const Tr = styled.tr``

export const Td = styled.td`
    vertical-align: top;
`

export const InputDescribe = styled.div`
    padding-left: 16px;
    line-height: 24px;
`

export const HelpContent = styled.div`
    padding: 10px 12px;
`
