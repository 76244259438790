import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import type { FC } from 'react'
import React from 'react'

import { Box } from '../../Box'

const useStyles = createStyles(() => ({
    td: css({
        fontSize: 14,
        lineHeight: '22px',
        padding: '9px 16px',
        borderBottom: '1px solid $colorGray200',
        textEllipsis: true
    })
}))

export type TableTdStyleNames = Selectors<typeof useStyles>

export interface TableTdProps extends StyleComponentProps<TableTdStyleNames>, React.ComponentPropsWithoutRef<'td'> {}

export const TableTd: FC<TableTdProps> = props => {
    const { styles, classNames, unstyled, className, ...rest } = props

    const { classes } = useStyles({}, { name: 'body', styles, classNames, unstyled })

    return <Box component="td" className={clsx(classes.td, className)} {...rest} />
}
