import React from 'react'
import styled from 'styled-components'

export interface CenteredWrapperProps {
    children?: React.ReactNode
    style?: React.CSSProperties
}

const SCxCenteredContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const CenteredWrapper: React.FC<CenteredWrapperProps> = ({ children, style }) => {
    return <SCxCenteredContainer style={style}>{children}</SCxCenteredContainer>
}
