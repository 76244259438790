import { useResizeObserver } from '@byecode/ui'
import type { RelativeSelectConfig } from '@lighthouse/core'
import React, { useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import styled from 'styled-components'

import { useApplicationContext } from '../../../../contexts'
import type { FieldBaseProps } from '../../types'
import { RelationCheckboxPreview } from './RelationCheckboxPreview'
import { RecordRecordSelect } from './RelationRecordSelect'
import { RelationTagPreview } from './RelationTagPreview'
import { useRelativeDataController } from './RelativeDataController'

interface RelativeSelectFieldProps extends FieldBaseProps {
    value?: string
    relativeSelectConfig: RelativeSelectConfig
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: inherit;
`

const RelativeSelectField: React.FunctionComponent<RelativeSelectFieldProps> = props => {
    const {
        onCellChange,
        value,
        readOnly,
        dataSource,
        isControlled,
        onFetchDataSource,
        onLoadMoreData,
        onOpenPage,
        config,
        dataSourceList,
        relativeDataSource,
        relativeSelectConfig
    } = props
    const { relativeSelect } = relativeSelectConfig
    const {
        relativePointer = '',
        showType = 'table',
        showMode,
        direction,
        canMultipleChoice,
        relativeShowFieldPointer = ''
    } = relativeSelect ?? {}

    const {
        relativeDataSource: newRelativeDataSource,
        recordValue,
        value: usedValue,
        options,
        userRecord,
        records,
        onRecordChange,
        onChange
    } = useRelativeDataController({ onCellChange, value, isControlled, config, relativeDataSource, relativeSelectConfig, dataSourceList })

    const [containerRef, containerRect] = useResizeObserver<HTMLDivElement>()
    const dropDownRef = useRef<HTMLDivElement>(null)
    const [opened, setOpened] = useState(false)
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()

    useClickAway(dropDownRef, e => {
        setOpened(false)
    })

    return useMemo(() => {
        switch (showMode) {
            case 'checkbox': {
                return (
                    <SCxContainer>
                        <RelationCheckboxPreview
                            options={options}
                            isMultiple={canMultipleChoice}
                            direction={direction}
                            value={recordValue}
                            disabled={readOnly}
                            onChange={onChange}
                        />
                    </SCxContainer>
                )
            }
            case 'tag': {
                return (
                    <SCxContainer>
                        <RelationTagPreview
                            options={options}
                            isMultiple={canMultipleChoice}
                            direction={direction}
                            value={recordValue}
                            disabled={readOnly}
                            onChange={onChange}
                        />
                    </SCxContainer>
                )
            }
            default: {
                return (
                    <SCxContainer ref={containerRef} data-field-border={opened}>
                        <RecordRecordSelect
                            dsId={relativePointer}
                            dataSource={newRelativeDataSource}
                            records={records}
                            readOnly={readOnly}
                            opened={opened}
                            containerRect={containerRect}
                            containerRef={containerRef}
                            relativeSelectConfig={relativeSelectConfig}
                            showType={showType}
                            ref={dropDownRef}
                            dataSourceList={dataSourceList || []}
                            personOptions={personOptions}
                            roleOptions={roleOptions}
                            departmentOptions={departmentOptions}
                            userRecord={userRecord}
                            value={recordValue}
                            onChange={onRecordChange}
                            onFetchDataSource={onFetchDataSource}
                            onLoadMoreData={onLoadMoreData}
                            onOpenPage={onOpenPage}
                            onChangeOpened={setOpened}
                        />
                    </SCxContainer>
                )
            }
        }
    }, [
        canMultipleChoice,
        containerRect,
        containerRef,
        dataSourceList,
        departmentOptions,
        direction,
        newRelativeDataSource,
        onChange,
        onFetchDataSource,
        onLoadMoreData,
        onOpenPage,
        onRecordChange,
        opened,
        options,
        personOptions,
        readOnly,
        recordValue,
        records,
        relativePointer,
        relativeSelectConfig,
        roleOptions,
        showMode,
        showType,
        userRecord
    ])
}

export default RelativeSelectField
