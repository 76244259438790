import type { TypeInstanceMap } from '@lighthouse/core'

export interface Usage {
    title: string
    describe?: string
    examples: string[]
}

export interface InputContent {
    title: string
    content: string | string[]
}

export interface FunctionObject {
    id: string
    name: string
    type: string
    class: 'function' | 'field'
    title: string
    describe: string
    usage?: Usage[]
    input?: InputContent[]
    output?: string
    // 编辑器显示
    icon?: string
    extra?: string
}

export interface MethodList {
    type: string
    name: string
    list: FunctionObject[]
}

export const numberFunction: FunctionObject[] = [
    {
        id: 'max',
        name: 'Max()',
        type: 'number',
        class: 'function',
        title: 'Max(num...)',
        describe: '找到一组数值中的最大值',
        usage: [
            {
                title: '示例',
                examples: ['Max(1, 2, 3, 4, 5)  #输出为 5']
            }
        ],
        input: [
            {
                title: 'num, ...',
                content: '表示任意多个数值类型的参数'
            }
        ],
        output: '返回参数中的最大值，数值类型'
    },
    {
        id: 'min',
        name: 'Min()',
        type: 'number',
        class: 'function',
        title: 'Min(num, ...)',
        describe: '找到一组数值中的最小值',
        usage: [
            {
                title: '示例',
                examples: ['Min(1, 2, 3, 4, 5)  #输出为 1']
            }
        ],
        input: [
            {
                title: 'num, ...',
                content: '表示任意多个数值类型的参数'
            }
        ],
        output: '返回参数中的最小值，数值类型'
    },
    {
        id: 'round',
        name: 'Round()',
        type: 'number',
        class: 'function',
        title: 'Round(num, precision)',
        describe: '按指定的位数对数组进行四舍五入',
        usage: [
            {
                title: '示例',
                examples: ['Round(123.78, 0)  #输出为 124', 'Round(123.78, 1)  #输出为 123.8', 'Round(123.78, -1)  #输出为 120']
            }
        ],
        input: [
            {
                title: 'num',
                content: '需要进行四舍五入的值，为数值类型'
            },
            {
                title: 'precision',
                content:
                    '非必填，表示要进行四舍五入运算的位数，为数值类型整数。如果位数大于 0，则四舍五入到指定的小数位，如果位数等于 0，则四舍五入到最接近的整数。如果位数小于 0，则在小数点左侧进行四舍五入'
            }
        ],
        output: '返回四舍五入后的值'
    },
    {
        id: 'avg',
        name: 'Avg()',
        type: 'number',
        title: 'Avg(num, ...)',
        class: 'function',
        describe: '计算一组数值的平均值',
        usage: [
            {
                title: '示例',
                examples: ['Avg(1, 2, 3, 4, 5)  #输出为 3']
            }
        ],
        input: [
            {
                title: 'num, ...',
                content: '表示任意多个数值类型的参数'
            }
        ],
        output: '返回参数中所有数值的平均值，数值类型'
    },

    {
        id: 'sum',
        name: 'Sum()',
        type: 'number',
        class: 'function',
        title: 'Sum(num, ...)',
        describe: '计算一组数值的总和',
        usage: [
            {
                title: '示例',
                examples: ['Sum(1, 2, 3, 4, 5)  #输出为 15']
            }
        ],
        input: [
            {
                title: 'num, ...',
                content: '表示任意多个数值类型的参数'
            }
        ],
        output: '返回参数中所有数值的总和，数值类型'
    },
    {
        id: 'roundUp',
        name: 'RoundUp()',
        type: 'number',
        class: 'function',
        title: 'RoundUp(num, [precision])',
        describe: '将数值朝绝对值增大方向舍入到最接近的整数',
        usage: [
            {
                title: '示例',
                examples: ['RoundUp(1.2)  #输出为 2', 'RoundUp(1.11, 1)  #输出为 1.2', 'RoundUp(123.21, -1)  #输出为 130']
            }
        ],
        input: [
            {
                title: 'num',
                content: '被舍入的值，数值类型'
            },
            {
                title: 'precision',
                content: '舍入的精度，数值类型的整数，可选，默认为0'
            }
        ],
        output: '返回舍入后的值，数值类型'
    },
    {
        id: 'roundDown',
        name: 'RoundDown()',
        type: 'number',
        class: 'function',
        title: 'RoundDown(num, [precision])',
        describe: '将数值朝绝对值减小方向舍入到最接近的整数',
        usage: [
            {
                title: '示例',
                examples: ['RoundUp(1.2)  #输出为 1', 'RoundUp(1.11, 1)  #输出为 1.10', 'RoundUp(123.21, -1)  #输出为 120']
            }
        ],
        input: [
            {
                title: 'num',
                content: '被舍入的值，数值类型'
            },
            {
                title: 'precision',
                content: '舍入的精度，数值类型的整数，可选，默认为0'
            }
        ],
        output: '返回舍入后的值，数值类型'
    },
    {
        id: 'toText',
        name: 'toText()',
        type: 'number',
        class: 'function',
        title: 'toText(number, format)',
        describe: '将指定数值按转换为文本类型',
        usage: [
            {
                title: '示例',
                examples: ['ToText(123.23)       #输出为 "123.23"   ', '123.23.toText()      #输出为 "123.23"']
            }
        ],
        input: [
            {
                title: 'number',
                content: '需要转换的数值，数值类型'
            },
            {
                title: 'format',
                content: [
                    '可选参数',
                    '格式化模板，为文本类型, 规则如下:',
                    'yyyy表示年份',
                    'MM表示月份',
                    'dd表示日期',
                    'HH表示24小时制的小时',
                    'hh表示12小时制的小时',
                    'mm表示分钟',
                    'ss表示秒'
                ]
            }
        ],
        output: '返回转换后的结果，值为文本类型'
    }
]

export const textFunction: FunctionObject[] = [
    {
        id: 'concat',
        name: 'Concat()',
        type: 'text',
        class: 'function',
        title: 'Concat(text, ...)',
        describe: '对文本内容进行拼接',
        usage: [
            {
                title: '示例',
                examples: [
                    'Concat("Hello","  ", "ByeCode")  #输出为 "Hello  ByeCode"  ',
                    '"Hello".Concat("  ", "ByeCode")  #输出为 "Hello  ByeCode"'
                ]
            }
        ],
        input: [
            {
                title: 'text, ...',
                content: '表示任意多个文本类型参数'
            }
        ],
        output: '输出经过拼接后的文本类型的值'
    },
    {
        id: 'startsWith',
        name: 'StartsWith()',
        type: 'text',
        class: 'function',
        title: 'StartsWith(text, prefix)',
        describe: '判断文本是否以指定的前缀开头',
        usage: [
            {
                title: '示例',
                examples: [
                    'StartsWith("Hello ByeCode", "Hello")     #输出为 true   ',
                    '"Hello ByeCode".StartsWith("ByeCode")    #输出为 false'
                ]
            }
        ],
        input: [
            {
                title: 'text',
                content: '文本类型，需要检查的文本'
            },
            {
                title: 'prefix',
                content: '文本类型，指定的前缀'
            }
        ],
        output: '返回判断的结果,值为布尔类型'
    },
    {
        id: 'endsWith',
        name: 'EndsWith()',
        type: 'text',
        class: 'function',
        title: 'EndsWith(text, suffix)',
        describe: '判断文本是否以指定的后缀结尾',
        usage: [
            {
                title: '示例',
                examples: ['EndsWith("Hello ByeCode","ByeCode")   #输出为 true', '"Hello ByeCode!".EndsWith("ByeCode")  #输出为 false']
            }
        ],
        input: [
            {
                title: 'text',
                content: '文本类型，需要检查的文本'
            },
            {
                title: 'prefix',
                content: '文本类型，指定的后缀'
            }
        ],
        output: '返回判断的结果,值为布尔类型'
    },
    // {
    //     id: 'ifBlank',
    //     name: 'IfBlank()',
    //     type: 'text',
    //     class: 'function',
    //     title: 'IfBlank(value,ifBlank)',
    //     describe: '检查给定的值是否为空，并返回一个不为空的值',
    //     usage: [
    //         {
    //             title: '示例',
    //             describe: '如果指定文本为空则返回"hello"，否则返回该文本',
    //             examples: ['IfBlank("","hello") = hello', '{文本}.ifBlank("hello”)']
    //         }
    //     ],
    //     input: [
    //         {
    //             title: 'value',
    //             content: '需要检查的值，该值为文本类型'
    //         },
    //         {
    //             title: 'ifBlank',
    //             content: '当检查的值为空时需要返回的值，该值为文本类型'
    //         }
    //     ],
    //     output: '当给定的目标值为空时,返回第二个参数的值，否则返回目标值'
    // },
    {
        id: 'slice',
        name: 'Slice()',
        type: 'text',
        class: 'function',
        title: 'Slice(text, start,end)',
        describe: '对文本内容进行截取',
        usage: [
            {
                title: '示例',
                examples: ['Slice("Hello ByeCode", 1, 5)  #输出为 "Hello"', '"Hello".Slice(1, 5)   #输出为 "Hello"']
            }
        ],
        input: [
            {
                title: 'text',
                content: '需要截取的文本，文本类型'
            },
            {
                title: 'start',
                content: '截取的起始位置，最小值为1，该值只能是数值类型的正整数'
            },
            {
                title: 'end',
                content: '截取的结束位置，最大值为需要截取的文本长度，该值只能是数值类型的正整数'
            }
        ],
        output: '按照参数中提供的截取规则进行截取后文本，值为文本类型'
    },
    {
        id: 'toNumber',
        name: 'ToNumber()',
        type: 'text',
        class: 'function',
        title: 'ToNumber(value)',
        describe: '将文本内容转换为数值',
        usage: [
            {
                title: '示例',
                examples: [
                    'ToNumber("1234")       #输出为 1234',
                    'ToNumber("123ab4")     #输出为 1234',
                    'ToNumber("-123ab.04")  #输出为 -123.04',
                    '"-123ab.04".toNumber() #输出为 -123.04'
                ]
            }
        ],
        input: [
            {
                title: 'text',
                content: '需要转换的值，文本类型'
            }
        ],
        output: '返回转换后的值，为数值类型，在转换过程中会过滤掉文本中无法转换为数字的字符'
    },
    {
        id: 'toDate',
        name: 'ToDate()',
        type: 'text',
        class: 'function',
        title: 'ToDate(text, format)',
        describe: '将文本按格式转换为时间类型',
        usage: [
            {
                title: '示例',
                examples: ['ToDate("2022/02/03", "YYYY/MM/DD")  = 2022/02/03', '{文本}.ToDate("YYYY/MM/DD")']
            }
        ],
        input: [
            {
                title: 'text',
                content: '需要转换的文本，文本类型'
            },
            {
                title: 'format',
                content: [
                    '格式化模板，为文本类型, 规则如下:',
                    'yyyy表示年份',
                    'MM表示月份',
                    'dd表示日期',
                    'HH表示24小时制的小时',
                    'hh表示12小时制的小时',
                    'mm表示分钟',
                    'ss表示秒'
                ]
            }
        ],
        output: '两个日期之差的数值'
    },
    {
        id: 'toArray',
        name: 'ToArray()',
        type: 'text',
        class: 'function',
        title: 'ToArray(text)',
        describe: '将文本按英文逗号分隔转换为数组',
        usage: [
            {
                title: '示例',
                examples: ['ToArray("A,B,C")  = ["A","B","C"]']
            },
        ],
        input: [
            {
                title: 'text',
                content: '需要转换的文本，文本类型'
            },
        ],
        output: '返回转换后的文本数组，数组类型'
    }
]

export const dateFunction: FunctionObject[] = [
    {
        id: 'year',
        name: 'Year()',
        type: 'date',
        class: 'function',
        title: 'Year(time)',
        describe: '获取指定时间的年份',
        usage: [
            {
                title: '示例',
                examples: ['Year(2023/08/23 12:12:12)     #输出为 2023', '(2023/08/23 12:12:12).year()  #输出为 2023']
            }
        ],
        input: [
            {
                title: 'time',
                content: '需要获取年份的时间，为时间类型'
            }
        ],
        output: '返回指定时间的年份的数字，数值类型'
    },
    {
        id: 'month',
        name: 'Month()',
        type: 'date',
        class: 'function',
        title: 'Month(time)',
        describe: '获取指定时间在当年的月份',
        usage: [
            {
                title: '示例',
                examples: ['Month(2023/08/23 12:12:12)     #输出为 8', '(2023/08/23 12:12:12).month()  #输出为 8']
            }
        ],
        input: [
            {
                title: 'time',
                content: '需要获取月份的时间，为时间类型'
            }
        ],
        output: '返回指定时间的月份的数字，数值类型'
    },
    {
        id: 'day',
        name: 'Day()',
        type: 'date',
        class: 'function',
        title: 'Day(time)',
        describe: '获取指定时间在当月的天数',
        usage: [
            {
                title: '示例',
                examples: ['Day(2023/08/23 12:12:12)     #输出为 23', '(2023/08/23 12:12:12).day()  #输出为 23']
            }
        ],
        input: [
            {
                title: 'time',
                content: '需要获取天数的时间，为时间类型'
            }
        ],
        output: '返回指定时间在当月的天数的数字，数值类型'
    },
    {
        id: 'hour',
        name: 'Hour()',
        type: 'date',
        class: 'function',
        title: 'Hour(time)',
        describe: '获取指定时间在当天的小时数',
        usage: [
            {
                title: '示例',
                examples: ['Hour(2022/02/02 12:23:12) = 12', '(2023/08/23 12:12:12).Hour()  #输出为 12']
            }
        ],
        input: [
            {
                title: 'time',
                content: '指定的时间'
            }
        ],
        output: '返回指定时间在当天的小时数的数字, 数值类型'
    },
    {
        id: 'minute',
        name: 'Minute()',
        type: 'date',
        class: 'function',
        title: 'Minute(value)',
        describe: '获取指定时间在当前小时的分钟数',
        usage: [
            {
                title: '示例',
                examples: ['Minute(2023/08/23 12:23:56)        #输出为 23', '(2023/08/23 12:23:56).minute()     #输出为 23']
            }
        ],
        input: [
            {
                title: 'time',
                content: '需要获取分钟数的时间，为时间类型'
            }
        ],
        output: '指定时间分钟数的数值'
    },
    {
        id: 'second',
        name: 'Second()',
        type: 'date',
        class: 'function',
        title: 'Second(time)',
        describe: '获取指定时间在当前分钟的秒数',
        usage: [
            {
                title: '示例',
                examples: ['Second(2023/08/23 12:23:56)        #输出为 56', '(2023/08/23 12:23:56).second()     #输出为 56']
            }
        ],
        input: [
            {
                title: 'time',
                content: '需要获取秒数的时间，为时间类型'
            }
        ],
        output: '返回指定时间在当前分钟的秒数的数字，数值类型'
    },
    {
        id: 'now',
        name: 'Now()',
        type: 'date',
        class: 'function',
        title: 'Now()',
        describe: '获取当前时间',
        usage: [
            {
                title: '示例',
                examples: ['Now()    #输出为 2023/08/23 12:12:12']
            }
        ],
        input: [],
        output: '返回当前时间，时间类型'
    },
    {
        id: 'dateDif',
        name: 'DateDif()',
        type: 'date',
        class: 'function',
        title: 'DateDif(startTime, endTime, type)',
        describe: '获取两个日期之间相差的年，月或者天数',
        usage: [
            {
                title: '示例',
                examples: [
                    'DateDif(2022/06/24 12:12:12, 2028/09/03 12:12:12, "y")      #返回 6',
                    'DateDif(2022/06/24 12:12:12, 2028/09/03 12:12:12, "M")      #返回 74',
                    'DateDif(2022/06/24 12:12:12, 2028/09/03 12:12:12, "d")      #返回2263',
                    '(2022/06/24 12:12:12).dateDif(2028/09/03 12:12:12 , "y")    #返回 6'
                ]
            }
        ],
        input: [
            {
                title: 'startTime',
                content: '需要比较的开始时间，为时间类型'
            },
            {
                title: 'endTime',
                content: '需要比较的结束时间，为时间类型'
            },
            {
                title: 'type',
                content: [
                    '比较的方式，为文本类型，有效值包括:',
                    '"y"(计算两个时间之间的整年数)',
                    '"M"(计算两个时间之间的整月数)',
                    '"d"(计算两个时间之间的整天数)',
                    '"h"(计算两个时间之间的整小时数)',
                    '"m"(计算两个时间之间的整分钟数)',
                    '"s"(计算两个时间之间的整秒数)'
                ]
            }
        ],
        output: '返回两个时间根据条件比较的差值，数值类型'
    },
    {
        id: 'max',
        name: 'Max()',
        type: 'number',
        class: 'function',
        title: 'Max(time...)',
        describe: '找到一组时间中的最大的时间',
        usage: [
            {
                title: '示例',
                examples: ['Max(2023/09/12 12:12:12, 2024/02/21 12:12:12)  #输出为 2024/02/21 12:12:12']
            }
        ],
        input: [
            {
                title: 'time, ...',
                content: '表示任意多个时间类型的参数'
            }
        ],
        output: '返回参数中的最大的时间，时间类型'
    },
    {
        id: 'min',
        name: 'Min()',
        type: 'number',
        class: 'function',
        title: 'Min(time, ...)',
        describe: '找到一组时间中的最小的时间',
        usage: [
            {
                title: '示例',
                examples: ['Min(2023/09/12 12:12:12, 2024/02/21 12:12:12)  #输出为 2024/02/21 12:12:12']
            }
        ],
        input: [
            {
                title: 'time, ...',
                content: '表示任意多个时间类型的参数'
            }
        ],
        output: '返回参数中的最小值，数值类型'
    },
    {
        id: 'weekDay',
        name: 'WeekDay()',
        type: 'date',
        class: 'function',
        title: 'WeekDay(time,[type])',
        describe: '返回指定时间在当周的第几天',
        usage: [
            {
                title: '示例',
                examples: [
                    'Weekday(2023/08/04 12:12:12)      #返回 6',
                    'Weekday(2023/08/04 12:12:12, 1)   #返回 7',
                    '(2023/08/04 12:12:12).Weekday(1)  #返回 7'
                ]
            }
        ],
        input: [
            {
                title: 'time',
                content: '指定的时间，时间类型'
            },
            {
                title: 'type',
                content:
                    '数值类型，可选参数，表示一周的第1天从星期几开始，若为 1 则该周的第 1 天从星期天开始，若为 2 则从星期一开始，默认为 1'
            }
        ],
        output: '数值类型，返回代表星期几的1-7的数字'
    },
    {
        id: 'weekNum',
        name: 'WeekNum()',
        type: 'date',
        class: 'function',
        title: 'WeekNum(time,[type])',
        describe: '返回指定时间在当年的第几周',
        usage: [
            {
                title: '示例',
                examples: [
                    'WeekNum(2023/08/04 12:12:12)      #返回 31',
                    'WeekNum(2023/08/04 12:12:12, 1)   #返回 31',
                    '(2023/08/04 12:12:12).WeekNum(1)  #返回 32'
                ]
            }
        ],
        input: [
            {
                title: 'time',
                content: '指定的时间，时间类型'
            },
            {
                title: 'type',
                content:
                    '数值类型，可选参数，表示一周的第1天从星期几开始，若为 1 则该周的第 1 天从星期天开始，若为 2 则从星期一开始，默认为 1'
            }
        ],
        output: '数值类型，返回指定时间在当年的第几周'
    },
    {
        id: 'dateAdd',
        name: 'DateAdd()',
        type: 'date',
        class: 'function',
        title: 'DateAdd(time, count, units)',
        describe: '为指定的日期增加固定的时间间隔',
        usage: [
            {
                title: '示例',
                examples: [
                    'DateAdd(2023/08/04 12:12:12 , 2 , "d") =  2023/08/06 12:12:12',
                    'DateAdd(2023/08/04 12:12:12 , 2 , "h") =  2023/08/06 14:12:12'
                ]
            }
        ],
        input: [
            {
                title: 'time',
                content: '时间类型，指定的时间，本函数将在该时间基础上增加或减少一定的时间间隔'
            },
            {
                title: 'count',
                content:
                    '数值类型，表示时间间隔，支持输入带正负号的数字，如果为正数，即表示增加几个时间单位 (可白定义计时单位)，如果为负数，即表示减少几个时间单位'
            },
            {
                title: 'units',
                content: [
                    '文本类型，代表计时单位，即增加时间间隔的单位，时间单位格式如下：',
                    '"秒"->"s"',
                    '"分钟"->"m"',
                    '"小时"->"h"',
                    '"天"->"d"',
                    '"周"->"w"',
                    '"月"->"M”',
                    '"年"->"y"'
                ]
            }
        ],
        output: '返回按照指定时间间隔增加或减少后的时间'
    },
    {
        id: 'isSame',
        name: 'IsSame()',
        type: 'date',
        class: 'function',
        title: 'IsSame(date1,date2, units)',
        describe: '根据条件判断两个时间是否相同',
        usage: [
            {
                title: '示例',
                examples: [
                    'IsSame(2023/08/04 12:12:12, 2023/08/04 18:34:12, "d")     # 比较是否是同一天, 返回 true',
                    'IsSame(2023/08/04 12:12:12, 2023/08/04 18:34:12, "h")     # 比较是否是同一小时, 返回 false',
                    '(2023/08/04 12:12:12).isSame(2023/08/04 18:34:12, "h")    # 比较是否是同一小时, 返回 false'
                ]
            }
        ],
        input: [
            {
                title: 'time1',
                content: '时间类型，需要比较的时间'
            },
            {
                title: 'time2',
                content: '时间类型，需要比较的时间'
            },
            {
                title: 'units',
                content: [
                    '比较的时间单位，时间单位格式如下：',
                    '"秒"->"s"',
                    '"分钟"->"m"',
                    '"小时"->"h"',
                    '"天"->"d"',
                    '"周"->"w"',
                    '"月"->"M”',
                    '"年"->"y"'
                ]
            }
        ],
        output: '布尔类型，如果成功则返回true'
    }
]

const logicListItemValue = '代表列表中的每一个值'

const logicFunction: FunctionObject[] = [
    {
        id: 'If',
        name: 'If()',
        type: 'logic',
        class: 'function',
        title: 'If(condition, value1, value2)',
        describe: '判断是否满足指定的条件',
        usage: [
            {
                title: '示例',
                examples: ['If(3>4,"正确", "错误")      #返回 "错误"', 'If(4>=3,"正确", "错误")     #返回 "正确"']
            }
        ],
        input: [
            {
                title: 'condition',
                content: '需要判断的条件，为布尔类型'
            },
            {
                title: 'value1',
                content: ' 可以是任意类型，当条件为真是返回该值 '
            },
            {
                title: 'value2',
                content: '需要与value1的类型保持一致，当条件为假是返回该值 '
            }
        ],
        output: '根据条件返回value1或者value2，返回值的类型与value1的类型相同'
    },
    {
        id: 'and',
        name: 'And()',
        type: 'logic',
        class: 'function',
        title: 'And(condition, ...)',
        describe: '判断是否满足所有的条件，与"&"符号的功能类似',
        usage: [
            {
                title: '示例',
                examples: ['And(3>4, 3>2, 5>3) #返回 false', 'And(6>4, 3>2, 5>3) #返回 true', '6>4 & 3>2 & 5>3 #返回 true']
            }
        ],
        input: [
            {
                title: 'condition, ...',
                content: '一组需要判断的条件，为布尔类型'
            }
        ],
        output: '布尔类型，当所有条件都正确时返回true，有任意一个条件错误则返回false'
    },
    {
        id: 'or',
        name: 'Or()',
        type: 'logic',
        class: 'function',
        title: 'Or(condition, ...)',
        describe: '判断是否满足任意一个条件，与"|"符号的功能类似',
        usage: [
            {
                title: '示例',
                examples: ['Or(3>4, 3>7, 5>9) #返回 false', 'Or(6>4, 3<2, 5<3) #返回 true', '6>4 | 3<2 | 5<3 #返回 true']
            }
        ],
        input: [
            {
                title: 'condition, ...',
                content: '一组需要判断的条件，为布尔类型'
            }
        ],
        output: '当任意一个条件正确时返回true，所有条件都错误则返回false'
    },
    {
        id: 'not',
        name: 'Not()',
        type: 'logic',
        class: 'function',
        title: 'Not(condition)',
        describe: '对输入的条件取反',
        usage: [
            {
                title: '示例',
                examples: ['Not(3>4) #返回 true', 'Not(6>4) #返回 false']
            }
        ],
        input: [
            {
                title: 'condition',
                content: '需要取反的条件，为布尔类型'
            }
        ],
        output: '布尔类型，当输入的条件为真时，返回false，当输入的条件为假时，返回true'
    },
    {
        id: 'switch',
        name: 'Switch()',
        type: 'logic',
        class: 'function',
        title: 'Switch(expression, [pattern,result]... , [default])',
        describe: '分支选择函数，它由表达式 + 多个 [分支值 + 返回值] 组成，如果表达式等于某个分支值，则函数输出该分支对应的返回值',
        usage: [
            {
                title: '示例',
                examples: [
                    'Switch(Year(2023/08/12 12:12:12), 2022, "过去", 2023, "现在", 2088, "将来")  #返回 "现在"',
                    'Switch(Year(2000/08/12 12:12:12), 2022, "过去", 2023, "现在", 2088, "将来", "无匹配的时间")  #返回 "无匹配的时间"'
                ]
            }
        ],
        input: [
            {
                title: 'expression',
                content: '需要进行分支匹配的表达式或值，可以是任意类型'
            },
            {
                title: 'pattern',
                content: '当前分支的值，用于与expression的值进行比较'
            },
            {
                title: 'result',
                content: '当前分支对应的值，如果当前分支匹配成功，则返回该值，可以是任意类型，但所有分支中的result都需要是同一类型'
            },
            {
                title: 'default',
                content: '如果所有分支都未匹配，则返回该值'
            }
        ],
        output: '返回第一个匹配成功的分支所对应的值，返回值类型与第一个分支对应的值一致，如果所有分支都未匹配，则返回default参数对应的值'
    },
    {
        id: 'switchIf',
        name: 'SwitchIf()',
        type: 'logic',
        class: 'function',
        title: 'SwitchIf([condition,result]... , [default])',
        describe: '多分支选择函数，它由表达式 +多个 [条件分支 + 返回值] 组成，如果表达式符合某个分支的条件，则函数输出该分支对应的返回值',
        usage: [
            {
                title: '示例',
                examples: [
                    'SwitchIf(成绩<60,"不及格”,And(成绩>=60,成绩<80),"良好","优秀")  #当成绩是70时返回 "良好" ，当成绩是90时，返回 "优秀"'
                ]
            }
        ],
        input: [
            {
                title: 'condition',
                content: '需要判断的分支条件，布尔类型'
            },
            {
                title: 'result',
                content: '当前分支对应的值，如果当前分支匹配成功，则返回该值，可以是任意类型，但所有分支中的result都需要是同一类型'
            },
            {
                title: 'default',
                content: '如果所有分支都未匹配，则返回该值'
            }
        ],
        output: '返回第一个匹配成功的分支所对应的值，返回值类型与第一个分支对应的值一致，如果所有分支都未匹配，则返回default参数对应的值'
    }
    // {
    //     id: 'isBlank',
    //     name: 'IsBlank()',
    //     type: 'logic',
    //     class: 'function',
    //     title: 'IsBlank(value)',
    //     describe: '判断指定的值是否为空',
    //     usage: [
    //         {
    //             title: '示例',
    //             examples: ['IsBlank("") #返回 true', '"".isBlank() #返回 true', '[].isBlank()         #返回 true', 'IsBlank([].first())  #返回 true]
    //         }
    //     ],
    //     input: [
    //         {
    //             title: 'value',
    //             content: '需要判断的值，可以是任意类型'
    //         }
    //     ],
    //     output: '当指定的值为空时返回true，否则返回false，当一段公式出现一些数据错误导致返回为空时，该函数也能正确判断其是否为空'
    // }
]

const arrayFunction: FunctionObject[] = [
    {
        id: 'contains',
        name: 'Contains()',
        type: 'array',
        class: 'function',
        title: 'Contains(array, value)',
        describe: '判断数组中是否包含指定的元素',
        usage: [
            {
                title: '示例',
                examples: [
                    'Contains(["今天", "明天", "后天"], "明天")   #返回true',
                    '["今天", "明天", "后天"].contains("明天")    #返回true'
                ]
            }
        ],
        input: [
            {
                title: 'array',
                content: '需要查找的数组，数组类型'
            },
            {
                title: 'value',
                content: '需要查找的元素'
            }
        ],
        output: '如果包含指定元素则返回true，否则返回false，布尔类型'
    },
    {
        id: 'containsAll',
        name: 'ContainsAll()',
        type: 'array',
        class: 'function',
        title: 'ContainsAll(array, value...)',
        describe: '判断数组中是否包含指定的所有元素',
        usage: [
            {
                title: '示例',
                examples: [
                    'ContainsAll(["今天", "明天", "后天"], "今天" ,"明天") #返回 false',
                    'ContainsAll(["今天", "明天", "后天"], "今天", "明天", "后天")  #返回 true',
                    '["今天", "明天", "后天"].containsAll("今天", "明天")#返回 false'
                ]
            }
        ],
        input: [
            {
                title: 'array',
                content: '需要查找的数组，数组类型'
            },
            {
                title: 'value',
                content: '指定的一组需要查找的值'
            }
        ],
        output: '如果包含指定元素则返回true，否则返回false，布尔类型'
    },
    {
        id: 'count',
        name: 'Count()',
        type: 'array',
        class: 'function',
        title: 'Count(value)',
        describe: '统计数组中一共有几个元素',
        usage: [
            {
                title: '示例',
                examples: ['Count([1,2,3,4,5]) # 返回 5', '[1,2,3,4,5].count() # 返回 5']
            }
        ],
        input: [
            {
                title: 'array',
                content: '需要统计的数组，数组类型'
            }
        ],
        output: '数值类型，返回数组中元素的个数'
    },
    // {
    //     id: 'map',
    //     name: 'Map()',
    //     type: 'array',
    //     title: 'Map(currentValue => expression)',
    //     describe: '对列表中的每一个元素进行计算，返回计算后的列表',
    //     usage: [
    //         {
    //             title: '示例',
    //             examples: [
    //                 '[1,2,3,4].Map(currentValue=>currentValue+1) = [2,3,4,5]',
    //                 '{商品价格}.Map(currentValue => currentValue*0.8)   //返回打八折后的商品价格列表'
    //             ]
    //         }
    //     ],
    //     input: [
    //         {
    //             title: 'currentValue',
    //             content: logicListItemValue
    //         },
    //         {
    //             title: 'expression',
    //             content: '计算表达式'
    //         }
    //     ],
    //     output: '返回计算后的列表'
    // },
    // {
    //     id: 'filter',
    //     name: 'Filter()',
    //     type: 'array',
    //     title: 'Filter(currentValue => expression)',
    //     describe: '按照条件对列表元素进行过滤',
    //     usage: [
    //         {
    //             title: '示例',
    //             examples: [
    //                 '[1,2,3,4,5].Filter(currentValue=>currentValue>3)  = [4,5]',
    //                 '{学生表}.Filter(currentValue.{学生成绩} => currentValue >60)   // 返回成绩大于60分的学生'
    //             ]
    //         }
    //     ],
    //     input: [
    //         {
    //             title: 'currentValue',
    //             content: logicListItemValue
    //         },
    //         {
    //             title: 'expression',
    //             content: '计算表达式'
    //         }
    //     ],
    //     output: '返回计算后的列表'
    // },
    {
        id: 'first',
        name: 'First()',
        type: 'array',
        class: 'function',
        title: 'First(array)',
        describe: '查找并返回数组中的第一个元素',
        usage: [
            {
                title: '示例',
                examples: ['First([1,2,3,4,5]) # 返回 1', '[1,2,3,4,5].first() # 返回 1']
            }
        ],
        input: [
            {
                title: 'array',
                content: '需要查找的数组'
            }
        ],
        output: '数组中的第一个元素，返回的类型与数组中的元素的类型一致'
    },
    {
        id: 'sum',
        name: 'Sum()',
        type: 'array',
        class: 'function',
        title: 'Sum(array)',
        describe: '统计数组中所有数值的总和，该函数要求数组中的元素必须是数值类型',
        usage: [
            {
                title: '示例',
                examples: ['Sum([1,2,3,4,5]) # 返回 15', '[1,2,3,4,5].sum()       # 返回 15']
            }
        ],
        input: [
            {
                title: 'array',
                content: ' 需要统计的数组'
            }
        ],
        output: '数值类型，返回统计后的结果'
    },
    {
        id: 'max',
        name: 'Max()',
        type: 'array',
        class: 'function',
        title: 'Max(array)',
        describe: '查找数值中元素的最大值，如果数组中的元素是数值类型，则返回最大的数值，如果数组中的元素是日期类型，则返回最大的日期',
        usage: [
            {
                title: '示例',
                examples: [
                    'Max([1,2,3,4,5]) #返回 5',
                    '[1,2,3,4,5].max() #返回 5',
                    'Max([2022/02/21 12:12:12, 2023/08/23 12:12:12])  #返回  2023/08/23 12:12:12'
                ]
            }
        ],
        input: [
            {
                title: 'array',
                content: '需要统计的数组，数组中的元素可以是数值类型或者日期类型'
            }
        ],
        output: '返回统计后的结果，如果数组中的元素是数值类型，则返回类型为数值类型，如果数组中的元素为日期类型，则返回类型为日期类型'
    },
    {
        id: 'min',
        name: 'Min()',
        type: 'array',
        class: 'function',
        title: 'Min(array)',
        describe: '查找数值中元素的最小值，如果数组中的元素是数值类型，则返回最小的数值，如果数组中的元素是日期类型，则返回最小的日期',
        usage: [
            {
                title: '示例',
                examples: [
                    'Min([1,2,3,4,5]) #返回 1',
                    '[1,2,3,4,5].min() #返回 1',
                    'Min([2022/02/21 12:12:12, 2023/08/23 12:12:12])  #返回  2022/02/21 12:12:12'
                ]
            }
        ],
        input: [
            {
                title: 'array',
                content: '需要统计的数组，数组中的元素可以是数值类型或者日期类型'
            }
        ],
        output: '返回统计后的结果，如果数组中的元素是数值类型，则返回类型为数值类型，如果数组中的元素为日期类型，则返回类型为日期类型'
    },
    {
        id: 'avg',
        name: 'Avg()',
        type: 'array',
        class: 'function',
        title: 'Avg(array)',
        describe: '统计数组中所有数值的平均值，该函数要求数组中的元素必须是数值类型',
        usage: [
            {
                title: '示例',
                examples: ['Avg([1,2,3,4,5]) # 返回 3', '[1,2,3,4,5].avg() # 返回 3']
            }
        ],
        input: [
            {
                title: 'array',
                content: '需要统计的数组'
            }
        ],
        output: '数值类型，返回统计后的结果'
    }
]

const otherFunction: FunctionObject[] = [
    {
        id: 'isBlank',
        name: 'IsBlank()',
        type: 'other',
        class: 'function',
        title: 'IsBlank(value)',
        describe: '判断指定的值是否为空',
        usage: [
            {
                title: '示例',
                examples: [
                    'IsBlank("") #返回 true',
                    '"".isBlank() #返回 true',
                    '[].isBlank()         #返回 true',
                    'IsBlank([].first())  #返回 true'
                ]
            }
        ],
        input: [
            {
                title: 'value',
                content: '需要判断的值，可以是任意类型'
            }
        ],
        output: '当指定的值为空时返回true，否则返回false，当一段公式出现一些数据错误导致返回为空时，该函数也能正确判断其是否为空'
    }
]

export const innerTypeToMethods: Record<TypeInstanceMap, string[]> = {
    TEXT: ['text', 'other'],
    NUMBER: ['number', 'other'],
    ARRAY: ['array', 'other'],
    DATE: ['date', 'other'],
    BOOL: ['other'],
    RICH_TEXT: ['other'],
    NULL: []
}

export const methodList: MethodList[] = [
    {
        type: 'number',
        name: '数值函数',
        list: numberFunction
    },
    {
        type: 'text',
        name: '文本函数',
        list: textFunction
    },
    {
        type: 'date',
        name: '日期函数',
        list: dateFunction
    },
    {
        type: 'logic',
        name: '逻辑函数',
        list: logicFunction
    },
    {
        type: 'array',
        name: '数组函数',
        list: arrayFunction
    },
    {
        type: 'other',
        name: '其他函数',
        list: otherFunction
    }
]
