import { NavigationShowMode } from '@lighthouse/core'
import { clone } from 'rambda'
import { proxy, useSnapshot } from 'valtio'

type CollapseMap = Record<string, boolean>

class CollapseListMap {
    #data: CollapseMap = {}

    // #showMode: NavigationShowMode = NavigationShowMode.horizontal

    constructor() {
        this.#data = proxy({})
    }

    public setData(data: CollapseMap) {
        this.#data = proxy(clone(data))
    }

    public getData() {
        return clone(this.#data)
    }

    // public getShowMode() {
    //     return this.#showMode
    // }

    // public setShowMode(mode?: NavigationShowMode) {
    //     if (mode) {
    //         this.#showMode = mode
    //     }
    // }

    public getItemCollapse(collapseId: string) {
        return this.#data[collapseId] ?? null
    }

    public setItemCollapse(collapseId: string, value: boolean) {
        this.#data[collapseId] = value
        return this.#data[collapseId]
    }

    public clearItemCollapse(collapseId: string) {
        Reflect.deleteProperty(this.#data, collapseId)
    }

    public useCollapse() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useSnapshot(this.#data)
    }

    public clearCollapse() {
        this.#data = proxy({})
    }
}

export const CollapseManager = new CollapseListMap()
