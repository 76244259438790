import { IconFont } from '@byecode/ui/components/IconFont'
import type { ButtonAction, RecordLikeProtocol, ViewBlockAbstract } from '@lighthouse/core'
import type { FlowLayoutContainerNode, FlowLayoutNode, NodeRenderProps } from '@lighthouse/shared'
import { actionTypeInfos, DEFAULT_ACTION, NodeRender, SortableNodeRender } from '@lighthouse/shared'
import { mergeRefs } from '@lighthouse/tools'
import { produce } from 'immer'
import React, { forwardRef, memo, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { ContainerLayout } from '../../components/ContainerLayout'
import type { CustomViewBlockContextValue } from './Context'
import { CustomViewBlockProvider } from './Context'
import { ForActionProvider, useForActionContext } from './ForActionContext'

const ActionSection = styled.button`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    right: 0;
    top: -1px;
    /* transform: translateY(-100%); */
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    padding: 2px 8px;
    color: var(--color-purple-700);
    font-size: 12px;
    line-height: 20px;
    background-color: #5551ff33;
    &:hover {
        background-color: #5551ff4d;
    }
`

interface FakeContainerRenderProps extends NodeRenderProps {
    // blockData: ViewBlockAbstract
    // showAction?: boolean
    // onTriggerAction?: (action: ButtonAction) => void
}

const FakeContainerRender = memo(({ node, /* blockData, showAction, onTriggerAction, */ ...p }: FakeContainerRenderProps) => {
    const { blockData, showAction, onTriggerAction } = useForActionContext()
    const {
        config: {
            actions: {
                recordClicked: { customized, action: unknownAction }
            }
        }
    } = blockData

    const action = (customized && unknownAction) || DEFAULT_ACTION

    const actionInfo = useMemo(() => {
        if (action.type === 'none') {
            return
        }
        const info = actionTypeInfos.find(item => item.value === action.type)
        if (!info) {
            return
        }

        return info
    }, [action.type])

    if (node.type === 'container') {
        return (
            <>
                <ContainerLayout node={produce(node, draft => void (draft.data.crop = true))} {...p} />
                {showAction && actionInfo && (
                    <ActionSection onClick={() => onTriggerAction?.(action)}>
                        <text>{actionInfo.label}</text>
                        <IconFont type="ArrowUpRight" size={16} />
                    </ActionSection>
                )}
            </>
        )
    }
    return null
})

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    data: FlowLayoutContainerNode
    readonly?: boolean
    gap: number
    isWaterfall: boolean

    showAction?: boolean
    onTriggerAction?: (action: ButtonAction) => void
    blockData: ViewBlockAbstract

    title: string
    viewId: string
    pointer: string
    record: RecordLikeProtocol
    records: RecordLikeProtocol[]

    onFilterNode?: (node: FlowLayoutNode, record: RecordLikeProtocol) => FlowLayoutNode
}

export const Item = forwardRef<HTMLDivElement, ItemProps>(
    (
        {
            blockData,
            data,
            showAction,
            onTriggerAction,
            readonly,
            gap,
            isWaterfall,
            title,
            viewId,
            pointer,
            record,
            records,
            onFilterNode,
            ...rest
        },
        ref
    ) => {
        const innerRef = useRef<HTMLDivElement>(null)

        const node = useMemo(() => {
            if (!onFilterNode) {
                return data
            }
            return onFilterNode(data, record)
        }, [data, onFilterNode, record])

        const customViewContextValue: CustomViewBlockContextValue = useMemo(
            () => ({ name: title, viewId, pointer, record, records }),
            [pointer, record, records, title, viewId]
        )

        const Component = readonly ? NodeRender : SortableNodeRender

        // const nodeRender = useMemo(() => {
        //     return (props: NodeRenderProps) => (
        //         <FakeContainerRender
        //             {...props}
        //             blockData={blockData}
        //             showAction={!readonly && showAction}
        //             onTriggerAction={onTriggerAction}
        //         />
        //     )
        // }, [onTriggerAction])

        const forActionContextValue = useMemo(() => ({ blockData, showAction, onTriggerAction }), [blockData, showAction, onTriggerAction])

        return (
            <CustomViewBlockProvider value={customViewContextValue}>
                <ForActionProvider value={forActionContextValue}>
                    <Component
                        style={{ cursor: 'pointer' }}
                        ref={mergeRefs([ref, innerRef])}
                        data={node}
                        nodeRender={FakeContainerRender}
                        {...rest}
                    />
                </ForActionProvider>
            </CustomViewBlockProvider>
        )
    }
)
