/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-spread */
import type { WritableAtom } from 'jotai'
import { useSetAtom } from 'jotai'
import { useCallback, useState } from 'react'
import { useAsyncFn } from 'react-use'
import type { PromiseType } from 'react-use/lib/misc/types'

const isAsyncFunction = <Args extends unknown[], Result>(
    fn: (...args: Args) => Result
    // @ts-ignore
): fn is (...args: Args) => Promise<PromiseType<Result>> => fn.constructor.name === 'AsyncFunction'

export const useAtomAction = <Value, Args extends any[], Result>(atom: WritableAtom<Value, Args, Result>) => {
    const call = useSetAtom(atom)

    const [value, setValue] = useState<(Result extends Promise<any> ? PromiseType<Result> : Result) | undefined>(undefined)

    // const [loading, setLoading] = useState(false)

    const run = useCallback(
        (...args: Args) => {
            if (isAsyncFunction(atom.write)) {
                // setLoading(true)
                // @ts-ignore
                return (call as (...args: Args) => Promise<PromiseType<Result>>)
                    .apply(null, args)
                    .then(val => {
                        setValue(val)
                        return val
                    })
                    .finally(() => {
                        // setLoading(false)
                    })
            }

            const res = call.apply(null, args)
            // @ts-expect-error
            setValue(res)
            return res
        },
        [atom.write, call]
    )

    return { loading: false, run, value }
}

// TODO: 临时策略
export const useAtomAsyncAction = <Value, Args extends any[], Result extends Promise<any>>(atom: WritableAtom<Value, Args, Result>) => {
    const call = useSetAtom(atom)

    // @ts-ignore
    const [{ loading, value }, run] = useAsyncFn<(...args: Args) => Promise<PromiseType<Result>>>(
        (...args: Args) => call.apply(null, args),
        []
    )

    return { loading, run, value }
}
