import type { DrawerProps } from '@mantine/core'
import { Drawer } from '@mantine/core'
import type { ReactNode } from 'react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useImmer } from 'use-immer'

import * as SC from './styles'
import { useTouch } from './useTouch'

export interface MobileDrawerProps {
    children?: ReactNode
    title?: ReactNode
    leftNode?: ReactNode
    rightNode?: ReactNode
    highHeight?: boolean
    opened: boolean
    enableTouch?: boolean
    isClip?: boolean
    onClose: () => void
    target?: DrawerProps['target']
    withinPortal?: DrawerProps['withinPortal']
    disableContentPadding?: boolean
}

export const getDrawerConfig: (isHighHeight?: boolean, isClip?: boolean) => Pick<DrawerProps, 'zIndex' | 'styles' | 'position'> = (
    isHighHeight,
    isClip
) => ({
    zIndex: 200,
    styles: {
        root: { position: isClip ? 'absolute' : 'fixed', overflow: 'hidden' },
        drawer: {
            borderRadius: '16px 16px 0 0',
            // position: 'absolute',
            // borderRadius: isClip ? '0 0 55px 55px' : '16px 16px 0 0',
            overflow: 'clip',
            height: isHighHeight ? '75%' : '50%'
        },
        header: {
            display: 'none'
        },
        overlay: {
            // borderRadius: isClip ? '0 0 55px 55px!important' : 'none',
        },
        body: {
            backgroundColor: 'var(--color-gray-50)',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }
    },
    position: 'bottom'
})

export const MobileDrawer: React.FC<MobileDrawerProps> = ({
    highHeight,
    leftNode,
    children,
    title,
    opened,
    enableTouch,
    target,
    withinPortal,
    rightNode,
    isClip = true,
    disableContentPadding,
    onClose
}) => {
    const [drawerHeaderElement, setDrawerHeaderElement] = useState<HTMLDivElement | null>(null)
    const [state, setState] = useImmer({
        isHighHeight: !!highHeight
    })
    const { isHighHeight } = state
    const drawerConfig = useMemo(() => getDrawerConfig(isHighHeight, Boolean(target) && isClip), [isHighHeight, target, isClip])

    const distance = useTouch(drawerHeaderElement)

    const handleSetDrawerElement = useCallback(
        (ref: HTMLDivElement | null) => {
            if (ref && !drawerHeaderElement && enableTouch) {
                setDrawerHeaderElement(ref)
            }
        },
        [enableTouch, drawerHeaderElement]
    )

    useEffect(() => {
        if (distance > 30 && isHighHeight) {
            setState(draft => {
                draft.isHighHeight = !draft.isHighHeight
            })
        }

        if (distance < -30 && !isHighHeight) {
            setState(draft => {
                draft.isHighHeight = !draft.isHighHeight
            })
        }
    }, [distance, isHighHeight, setState])

    return (
        <Drawer {...drawerConfig} withinPortal={withinPortal} target={target} opened={opened} onClose={onClose}>
            <SC.Header>
                <SC.LeftExtra onClick={onClose}>{leftNode}</SC.LeftExtra>
                <SC.Title ref={handleSetDrawerElement}>
                    {enableTouch && <SC.TopBar />}
                    {title}
                </SC.Title>

                <SC.RightExtra>{rightNode}</SC.RightExtra>
            </SC.Header>
            <SC.Content disableContentPadding={disableContentPadding}>{children}</SC.Content>
        </Drawer>
    )
}
