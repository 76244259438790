import { backgroundTransitionOnClick, singleTextEllipsis, Text } from '@byecode/ui'
import type { FieldADTValue, SelectValue, SingleSelectField } from '@lighthouse/core'
import { clone, findIndex } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import type { SelectChangeEvent, TagSelectOption } from '../TagSelect'
import { TagSelect } from '../TagSelect'
import * as SC from './styles'

const SCxCellValue = styled.div<{ direction?: 'horizontal' | 'vertical' }>`
    display: flex;
    gap: 6px;
    align-items: center;
    padding: 2px 1px;
    font-size: 14px;
    height: 22px;
    white-space: nowrap;
`

const SCxDot = styled.div<{ background: string | undefined }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${({ background }) => background || 'none'};
`

const SCxListItemWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin-right: 2px;
    gap: 6px;
    cursor: pointer;
    ${backgroundTransitionOnClick}
`

const SCxItemName = styled.div`
    flex: 1;
    ${singleTextEllipsis}
    color: var(--color-black);
    font-size: var(--font-size-normal);
`

interface SingleSelectValueEditorProps {
    recordId: string
    field: SingleSelectField
    data: SelectValue
    style?: React.CSSProperties
    sortable?: boolean
    autoFocus?: boolean
    onCellChange?: (recordId: string, field: FieldADTValue) => Promise<boolean>
    onClose?: (isActive?: boolean) => void
}

export const SingleSelectValueEditor: React.FC<SingleSelectValueEditorProps> = ({
    recordId,
    field,
    data,
    style,
    sortable = false,
    autoFocus,
    onCellChange,
    onClose
}) => {
    const [selectValues, setSelectValues] = React.useState<SelectValue>(data)
    const { singleSelect } = field
    const { options } = singleSelect

    const handleSingleChange = useCallback(
        (ev: SelectChangeEvent) => {
            const { action, option } = ev
            let value: string[] = []

            switch (action) {
                case 'add': {
                    value = [option]
                    break
                }
                case 'delete': {
                    const values: string[] = clone(selectValues || [])
                    const deleteIndex = findIndex(val => val === option, values)
                    if (deleteIndex !== -1) {
                        values.splice(deleteIndex, 1)
                        value = values.slice(0, 1)
                    }
                    break
                }
                default: {
                    break
                }
            }
            setSelectValues(value)
            onCellChange?.(recordId, { ...field, type: 'singleSelect', value })
            onClose?.()
        },
        [field, onCellChange, onClose, recordId, selectValues]
    )

    const renderTagItem = useCallback((item: TagSelectOption) => {
        const { label, color } = item
        return (
            <SCxCellValue>
                <SCxDot background={color} />
                <Text>{label}</Text>
            </SCxCellValue>
        )
    }, [])

    const renderListItem = useCallback((item: TagSelectOption) => {
        // const background = getColorById(item.value)
        return (
            <SCxListItemWrapper>
                    <SCxDot background={item.color} />
                    <SCxItemName>{item.label}</SCxItemName>
            </SCxListItemWrapper>
        )
    }, [])

    const selectOptions = useMemo(
        () =>
            options.map(({ label, color }) => ({
                label,
                value: label,
                color
            })),
        [options]
    )

    return (
        <SC.TagSelectWrapper style={style}>
            <TagSelect
                description="选择账号状态"
                sortable={sortable}
                // multiple={multiple}
                value={selectValues}
                autoFocus={autoFocus}
                options={selectOptions}
                disabledRemove
                disabledSearch
                onChange={handleSingleChange}
                tagItemRender={renderTagItem}
                listItemRender={renderListItem}
            />
        </SC.TagSelectWrapper>
    )
}
