import { mergeRefs, nanoid, useMounted } from '@lighthouse/tools'
import { Text } from '@mantine/core'
import { max } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ApplicationPreviewEnum } from '../../../types'
import { getApplicationPreviewType } from '../../../utils'
import * as SC from './styles'

interface CodeInputProps {
    maxLength: number
    value?: number | string
    onChange?: (v: string) => void
}

export const CodeInput = React.forwardRef<HTMLInputElement, CodeInputProps>(({ maxLength = 4, value = '', onChange }, ref) => {
    const [isFocus, setIsFocus] = useState(false)
    const focusRef = useRef<HTMLInputElement>(null)
    const valueArray = useMemo(() => [...value.toString().slice(0, max(0, maxLength))], [maxLength, value])
    const arrayLength = useMemo(() => Array.from({ length: maxLength }, () => nanoid()), [maxLength])

    const isMobile = getApplicationPreviewType() === ApplicationPreviewEnum.mobile
    useMounted(() => {
        focusRef.current?.focus()
    })

    useEffect(() => {
        if (value.toString().length === maxLength) {
            focusRef.current?.blur()
        }
    }, [maxLength, value])

    return (
        <SC.Container>
            {arrayLength.map((id, i) => (
                <SC.Item key={id} className={isMobile ? 'mobile' : 'desktop'}>
                    <Text size={36} color="var(--color-black)" weight={500}>
                        {valueArray[i]}
                    </Text>
                    {isFocus && i === value.toString().length && <SC.Cursor />}
                </SC.Item>
            ))}
            <SC.Input
                ref={mergeRefs([focusRef, ref])}
                value={value}
                maxLength={maxLength}
                autoComplete="one-time-code"
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                onChange={e => {
                    e.target.value = e.target.value.slice(0, maxLength)
                    onChange?.(e.target.value.slice(0, maxLength))
                }}
            />
        </SC.Container>
    )
})
