import cls from 'classnames'
import { min, reduce } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { sizeFontWeighMap, sizeMap } from './constant'
import type { Size } from './types'

type BaseInputStyles = 'container' | 'input'

interface BaseInputProps {
    value?: string
    type?: React.HTMLInputTypeAttribute
    size?: Size
    readOnly?: boolean
    placeholder?: string
    borderRadius?: string | number
    leftSection?: React.ReactNode
    rightSection?: React.ReactNode
    pattern?: string
    autoComplete?: string
    isWrap?: boolean
    className?: string
    maxLength?: number
    style?: React.CSSProperties
    styles?: Partial<Record<BaseInputStyles, React.CSSProperties>>
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
    onFocus?: React.FocusEventHandler<HTMLTextAreaElement>
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
    onMouseEnter?: React.MouseEventHandler<HTMLTextAreaElement>
    onMouseLeave?: React.MouseEventHandler<HTMLTextAreaElement>
    onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>
}

const SCxContainer = styled.div<{ borderRadius?: string | number }>`
    position: relative;
    width: 100%;
    min-height: 38px;
    display: flex;
    padding: 10px 7px;
    cursor: text;

    // 增加选择器权重防止被简单覆盖
    &.readonly.readonly.readonly {
        background-color: var(--color-gray-50);
        cursor: not-allowed;
    }
`

const SCxInputWrapper = styled.div`
    position: absolute;
    inset: 0;
    z-index: 0;
    flex: 1;
    display: flex;
    align-items: center;
`

const SCxInput = styled.textarea<{ textSize: Size }>`
    width: 100%;
    height: 100%;
    outline: none;
    color: var(--color-black);
    font-size: ${({ textSize }) => sizeMap[textSize]}px;
    font-weight: ${({ textSize }) => sizeFontWeighMap[textSize]};
    line-height: 20px;
    resize: none;
    overflow: hidden;
    word-break: break-word;
    min-height: 20px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: inherit;

    ::placeholder {
        font-weight: var(--font-weight-normal);
        color: var(--color-gray-300);
    }
`

const SCxPreview = styled.p<{ textSize: Size; isWrap?: boolean }>`
    position: relative;
    height: auto;
    max-width: 100%;
    line-height: 20px;
    min-height: 20px;
    flex: 1;
    color: transparent;
    font-size: ${({ textSize }) => sizeMap[textSize]}px;
    font-weight: ${({ textSize }) => sizeFontWeighMap[textSize]};
    word-break: break-word;
    white-space: ${({ isWrap }) => (isWrap ? 'pre-wrap' : 'nowrap')};
    font-family: inherit;
    overflow: hidden;
`

const reg = /\n+$/g
const BaseInput = React.forwardRef<HTMLTextAreaElement, BaseInputProps>(
    (
        {
            rightSection,
            size = 'middle',
            readOnly,
            placeholder,
            borderRadius,
            styles,
            leftSection,
            value,
            maxLength,
            className,
            isWrap = true,
            onBlur,
            onChange,
            onFocus,
            onKeyDown,
            onMouseEnter,
            onMouseLeave,
            ...rest
        },
        ref
    ) => {
        const inputProps = { placeholder, maxLength, onBlur, onChange, onFocus, onKeyDown, onMouseEnter, onMouseLeave }
        const height = useMemo(() => {
            if (!isWrap) {
                return
            }
            const [enterNum] = reduce<string, [number, boolean]>(
                ([num, isEnd], curVal) => {
                    if (curVal === '\n' && isEnd) {
                        return [num + 1, true]
                    }
                    return [num, false]
                },
                [0, true],
                value?.split('').reverse() ?? []
            )
            const computeHeight = min(enterNum * 20, 20)
            return value?.endsWith('\n') ? computeHeight : undefined
        }, [isWrap, value])

        return (
            <SCxContainer borderRadius={borderRadius} className={cls([{ readonly: readOnly }, className])} {...rest}>
                <SCxPreview textSize={size} isWrap={isWrap} style={{ paddingBottom: height }}>
                    {value}
                    <SCxInputWrapper>
                        {leftSection}
                        <SCxInput textSize={size} {...inputProps} value={value} disabled={readOnly} readOnly={readOnly} ref={ref} />
                    </SCxInputWrapper>
                </SCxPreview>
                {rightSection}
            </SCxContainer>
        )
    }
)

export default BaseInput
