import type { ByecodeSize } from '@byecode/ui/types'
import { useDebounce } from '@lighthouse/tools'
import React, { useCallback, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import * as SC from './styles'

export interface DebounceSearchProps {
    value?: string
    isFull?: boolean
    isTheme?: boolean
    children?: React.ReactNode
    placeholder?: string
    size?: ByecodeSize
    onSearch?: (value: string) => void
}

export const DebounceSearch: React.FC<DebounceSearchProps> = ({
    value: search,
    placeholder = '搜索...',
    isFull,
    isTheme,
    size = 'md',
    onSearch
}) => {
    const [value, setValue] = useState(search || '')

    const debouncedTitle = useDebounce(value, 400)

    useUpdateEffect(() => {
        onSearch?.(debouncedTitle)
    }, [debouncedTitle])

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
    }, [])

    return (
        <SC.SearchInput
            styles={{
                wrapper: {
                    margin: isFull ? '12px 0 0 0' : '0 8px 0 0',
                    width: isFull ? '100%' : '140px'
                }
            }}
            size={size}
            color={isTheme ? 'var(--color-app-main)' : 'var(--color-main)'}
            prefix={<SC.SearchIcon type="SearchLine" fill="var(--color-gray-400)" />}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
        />
        // <SC.SearchWrapper isFull={isFull} isUseTheme={isUseTheme}>
        //     <SC.SearchIcon type="SearchLine" fill="var(--color-gray-400)" />
        //     <SC.KeywordsInput placeholder={placeholder} value={value} onChange={handleChange} />
        // </SC.SearchWrapper>
    )
}
