import type { SegmentedControlItem } from '@byecode/ui'
import type {
    ButtonAction,
    ButtonActionType,
    ButtonBlockHandleEvent,
    ButtonEvents,
    ButtonHandleEvent,
    ButtonShowType,
    ButtonSizeType,
    VariableADTvalue} from '@lighthouse/core';
import {
ActionItemPattern, ButtonSize, RecordOpenType,    VariableType
 } from '@lighthouse/core'

import type { FlowNode } from '../types'
import type { ButtonConfig } from '../types/button'

export const btnStyleMap = new Map<ActionItemPattern, React.CSSProperties>([
    [
        ActionItemPattern.primary,
        {
            color: '#fff',
            backgroundColor: 'var(--color-app-main)'
        }
    ],
    [
        ActionItemPattern.secondary,
        {
            borderColor: 'var(--color-gray-200)',
            color: '#101828',
            backgroundColor: '#fff'
        }
    ]
])

export type ButtonConfigType = 'select' | 'input' | 'segmented'

export const OpenTypeData = [
    {
        label: '弹窗',
        value: RecordOpenType.modal
    },
    {
        label: '侧边栏',
        value: RecordOpenType.drawer
    },
    {
        label: '页面',
        value: RecordOpenType.page
    }
]

export const buttonActions: Record<ButtonBlockHandleEvent, ButtonConfig[]> = {
    none: [],
    openPage: [
        {
            type: 'select',
            placeHolder: '请选择页面',
            label: '选择页面',
            name: 'openPageUrl',
            index: 0
        },
        {
            type: 'segmented',
            placeHolder: '',
            label: '展开方式',
            name: 'pageOpenType',
            data: OpenTypeData,
            index: 1
        }
    ],
    openFormPage: [],
    openRecordPage: [],
    openRecordEditPage: [],
    openLink: [
        {
            type: 'input',
            placeHolder: 'https://',
            label: '链接',
            name: 'link',
            index: 0
        }
    ],
    scrollTo: [],
    control: [],
    goBack: [],
    notification: [
        {
            type: 'input',
            placeHolder: '请输入',
            label: '内容',
            name: 'notificationContent',
            index: 0
        },
        {
            type: 'select',
            placeHolder: '',
            label: '提示类型',
            name: 'notificationStatus',
            index: 1
        }
    ],
    copy: [
        {
            type: 'input',
            placeHolder: '请输入',
            label: '自定义',
            name: 'copyText',
            index: 1
        }
    ],
    call: [],
    createRecord: [
        {
            type: 'select',
            placeHolder: '请选择数据表',
            label: '数据表',
            name: 'dataSourceId',
            index: 0
        }
    ],
    updateRecord: [],
    deleteRecord: [],
    shareToWechat: [],
    downloadFile: [],
    iClick: [],
    aliyunMessage: [],
    wechatPay: [],
    wechatTemplateMsg: [],
    stripe: [],
    refreshPage: []
}

export const optionActions = [
    {
        section: '打开',
        items: [
            {
                icon: 'ArrowSquareIn',
                name: '打开页面',
                action: 'openPage'
            },
            {
                icon: 'LinkSimple',
                name: '打开链接',
                action: 'openLink'
            }
        ]
    },
    {
        section: '记录',
        items: [
            {
                icon: 'Add',
                name: '创建记录',
                action: 'createRecord'
            },
            {
                icon: 'ArrowCounterClockwise',
                name: '更新记录',
                action: 'updateRecord'
                // disable: true
            },
            {
                icon: 'Trash',
                name: '删除记录',
                action: 'deleteRecord'
                // disable: true
            }
        ]
    }
]

export const btnTypeList: { value: ButtonShowType; label: string }[] = [
    {
        value: 'name',
        label: '仅文字'
    },
    {
        value: 'icon',
        label: '仅图标'
    },
    {
        value: 'icon-name',
        label: '图标加文字'
    }
]

export const btnStyleList: { value: string; label: string }[] = [
    {
        value: '0',
        label: '主按钮'
    },
    {
        value: '1',
        label: '次级按钮'
    }
]

export const commonPagesOptions = [
    {
        label: '注册页面',
        value: '/account/register',
        group: 'common'
    },
    {
        label: '登录页面',
        value: '/account/login',
        group: 'common'
    }
]

/** 按钮样式 */
export const BUTTON_PATTERN_CONTROL: SegmentedControlItem[] = [
    {
        label: '主按钮',
        value: ActionItemPattern.primary
    },
    {
        label: '次按钮',
        value: ActionItemPattern.secondary
    },
    {
        label: '自定义',
        value: ActionItemPattern.custom
    }
]

export const actionTypeInfos = [
    { label: '无动作', divider: true, value: 'none', icon: 'Forbidden', color: 'var(--color-gray-900)' },
    {
        label: '从动作流中选择',
        children: [] as { label: string; value: string; icon: string }[],
        value: 'flow',
        flowType: 'action',
        icon: 'MouseClick',
        color: 'var(--color-gray-700)'
    },
    {
        label: '从自动化中选择',
        divider: true,
        children: [] as { label: string; value: string; icon: string }[],
        value: 'automation',
        flowType: 'automation',
        icon: 'FlowArrow',
        color: 'var(--color-gray-700)',
        disabledTooltipText: '该对象使用的是从其他应用同步的表不支持执行自动化'
    },
    { label: '创建记录', value: 'createRecord', icon: 'Add', color: 'var(--color-green-500)' },
    { label: '更新记录', divider: true, value: 'updateRecord', icon: 'ArrowCounterClockwise', color: 'var(--color-green-500)' },
    { label: '打开页面', value: 'openPage', icon: 'BlockLinkToPage', color: 'var(--color-blue-700)' },
    { label: '打开表单页', value: 'openFormPage', icon: 'Form', color: 'var(--color-blue-700)' },
    { label: '打开详情页', value: 'openRecordPage', icon: 'PageRecord', color: 'var(--color-blue-700)' },
    { label: '打开编辑页', value: 'openRecordEditPage', icon: 'PageForm', color: 'var(--color-blue-700)' },
    { label: '打开链接', value: 'openLink', icon: 'Link', color: 'var(--color-blue-700)' },
    { label: '定位到组件', value: 'scrollTo', icon: 'LocateTo', color: 'var(--color-blue-700)' },
    { label: '控制组件', value: 'control', icon: 'Regulation', color: 'var(--color-blue-700)' },
    // { label: '分享到微信', value: 'shareToWechat', icon: 'BrandLogoqiyeweixin', group: '打开' },
    { label: '拨打电话', value: 'call', icon: 'PhoneIconLibrary', color: 'var(--color-blue-700)' },
    { label: '返回上一步', value: 'goBack', icon: 'Undo', color: 'var(--color-blue-700)' },
    { label: '显示提示', value: 'notification', icon: 'Bell', color: 'var(--color-blue-700)' },
    { label: '复制到剪切板', value: 'copy', icon: 'Duplicate', color: 'var(--color-blue-700)' },
    { label: '删除记录', value: 'deleteRecord', icon: 'Trash', color: 'var(--color-blue-700)' },
    { label: '下载', value: 'downloadFile', icon: 'Import', color: 'var(--color-blue-700)' },
    {
        label: '刷新页面数据',
        value: 'refreshPage',
        icon: 'ArrowCounterClockwise',
        color: 'var(--color-blue-700)',
        divider: true
    },
    {
        label: 'iClick',
        value: 'iClick',
        icon: 'BrandLogoIClick',
        color: 'var(--color-orange-500)'
    },
    {
        label: '微信支付',
        value: 'wechatPay',
        icon: 'WechatPay',
        color: 'var(--color-green-500)'
    },
    {
        label: '微信模板消息',
        value: 'wechatTemplateMsg',
        icon: 'WechatPay', // WeChatLogo
        color: 'var(--color-green-500)'
    },
    {
        label: 'Stripe',
        value: 'stripe',
        icon: 'Stripe', // WeChatLogo
        color: 'var(--color-black)'
    },
    {
        label: '发送短信',
        value: 'aliyunMessage',
        icon: 'aliyun',
        color: 'var(--color-orange-500)'
    }
]

export const beActionFlowTypes = ['action', 'automation'] as const

export type BeActionFlowTypes = typeof beActionFlowTypes[number]

export const flowActionTypes = ['flow', 'automation'] as const

export type FlowActionTypes = typeof flowActionTypes[number]

export const buttonEventsMap = new Map<ButtonHandleEvent, ButtonEvents>([
    [
        'none',
        {
            handleEvent: 'none',
            params: [],
            triggerEvent: 'click'
        }
    ],
    [
        'createRecord',
        {
            handleEvent: 'createRecord',
            params: ['', ''],
            triggerEvent: 'click'
        }
    ],
    [
        'deleteRecord',
        {
            handleEvent: 'deleteRecord',
            params: ['', ''],
            triggerEvent: 'click'
        }
    ],
    [
        'updateRecord',
        {
            handleEvent: 'updateRecord',
            params: ['', ''],
            triggerEvent: 'click'
        }
    ],
    [
        'openLink',
        {
            handleEvent: 'openLink',
            params: [''],
            triggerEvent: 'click'
        }
    ],
    [
        'scrollTo',
        {
            handleEvent: 'scrollTo',
            params: [''],
            triggerEvent: 'click'
        }
    ],
    [
        'control',
        {
            handleEvent: 'control',
            params: [''],
            triggerEvent: 'click'
        }
    ],
    [
        'openPage',
        {
            handleEvent: 'openPage',
            params: ['', RecordOpenType.modal],
            triggerEvent: 'click'
        }
    ],
    [
        'goBack',
        {
            handleEvent: 'goBack',
            params: [],
            triggerEvent: 'click'
        }
    ],
    [
        'notification',
        {
            handleEvent: 'notification',
            params: ['', 'info'],
            triggerEvent: 'click'
        }
    ],
    [
        'copy',
        {
            handleEvent: 'copy',
            params: ['custom', ''],
            triggerEvent: 'click'
        }
    ]
])

export const buttonActionsMap = new Map<ButtonActionType, ButtonAction>([
    [
        'none',
        {
            type: 'none',
            trigger: 'click',
            data: {
                none: {}
            }
        }
    ],
    [
        'createRecord',
        {
            type: 'createRecord',
            trigger: 'click',
            data: {
                createRecord: {
                    dataSourceId: '',
                    fields: []
                }
            }
        }
    ],
    [
        'deleteRecord',
        {
            type: 'deleteRecord',
            trigger: 'click',
            data: {
                deleteRecord: {}
            }
        }
    ],
    [
        'updateRecord',
        {
            type: 'updateRecord',
            trigger: 'click',
            data: {
                updateRecord: {
                    dataSourceId: '',
                    selectType: 'CURRENT_RECORD',
                    nodeId: '',
                    fields: []
                }
            }
        }
    ],
    [
        'openLink',
        {
            type: 'openLink',
            trigger: 'click',
            data: {
                openLink: {
                    link: {}
                }
            }
        }
    ],
    [
        'scrollTo',
        {
            type: 'scrollTo',
            trigger: 'click',
            data: {
                scrollTo: {
                    scrollToElementId: ''
                }
            }
        }
    ],
    [
        'control',
        {
            type: 'control',
            trigger: 'click',
            data: {
                control: {
                    controlElementId: '',
                    controlElementType: 'view',
                }
            }
        }
    ],
    [
        'openPage',
        {
            type: 'openPage',
            trigger: 'click',
            data: {
                openPage: {
                    openPageUrl: '',
                    pageOpenType: RecordOpenType.modal
                }
            }
        }
    ],
    [
        'openFormPage',
        {
            type: 'openFormPage',
            trigger: 'click',
            data: {
                openFormPage: {
                    formPageId: '',
                    pageOpenType: RecordOpenType.modal
                }
            }
        }
    ],
    [
        'openRecordPage',
        {
            type: 'openRecordPage',
            trigger: 'click',
            data: {
                openRecordPage: {
                    dataSourceId: '',
                    filter: {
                        expression: {
                            conditions: []
                        }
                    },
                    recordPageId: '',
                    pageOpenType: RecordOpenType.modal,
                    selectedRecordType: 'CURRENT_RECORD'
                }
            }
        }
    ],
    [
        'openRecordEditPage',
        {
            type: 'openRecordEditPage',
            trigger: 'click',
            data: {
                openRecordEditPage: {
                    dataSourceId: '',
                    filter: {
                        expression: {
                            conditions: []
                        }
                    },
                    recordEditPageId: '',
                    pageOpenType: RecordOpenType.modal,
                    selectedRecordType: 'CURRENT_RECORD'
                }
            }
        }
    ],
    [
        'goBack',
        {
            type: 'goBack',
            trigger: 'click',
            data: {
                goBack: {}
            }
        }
    ],
    [
        'notification',
        {
            type: 'notification',
            trigger: 'click',
            data: {
                notification: {
                    notificationContent: {},
                    notificationStatus: 'INFO'
                }
            }
        }
    ],
    [
        'copy',
        {
            type: 'copy',
            trigger: 'click',
            data: {
                copy: {
                    copyText: {}
                }
            }
        }
    ],
    [
        'shareToWechat',
        {
            type: 'shareToWechat',
            trigger: 'click',
            data: {
                shareToWechat: {
                    title: '',
                    desc: '',
                    imgUrl: ''
                }
            }
        }
    ],
    [
        'downloadFile',
        {
            type: 'downloadFile',
            trigger: 'click',
            data: {
                downloadFile: {
                    fileUrl: {
                        type: VariableType.VALUE
                    }
                }
            }
        }
    ],
    [
        'iClick',
        {
            type: 'iClick',
            trigger: 'click',
            data: {
                iClick: {
                    email: null,
                    name: null,
                    mobile: null,
                    iClickId: ''
                }
            }
        }
    ],
    [
        'wechatPay',
        {
            type: 'wechatPay',
            trigger: 'click',
            data: {
                wechatPay: {
                    operationType: 'PAY',
                    payConfig: {
                        descFieldId: '',
                        autoRedirectAfterPayment: true,
                        /** 订单金额 */
                        amountFieldId: '',
                        succeedFieldSettings: [],
                        failedFieldSettings: []
                    },
                    refundConfig: {
                        // 订单id对应的字段id
                        outTradeNoFieldId: '',
                        refundAmountFieldId: '',
                        reasonFieldId: '',
                        succeedFieldSettings: [],
                        failedFieldSettings: []
                    }
                }
            }
        }
    ],
    [
        'call',
        {
            type: 'call',
            trigger: 'click',
            data: {
                call: {
                    phone: {
                        type: 'VALUE'
                    } as VariableADTvalue
                }
            }
        }
    ],
    [
        'wechatTemplateMsg',
        {
            type: 'wechatTemplateMsg',
            trigger: 'click',
            data: {
                wechatTemplateMsg: {
                    personList: [],
                    templateId: '',
                    values: [],
                    url: {}
                }
            }
        }
    ],
    [
        'stripe',
        {
            type: 'stripe',
            trigger: 'click',
            data: {
                stripe: {
                    operationType: 'PAY',
                    payConfig: {
                        autoRedirectAfterPayment: true,
                        productPictureFieldId: '',
                        currency: '',
                        descFieldId: '',
                        productNameFieldId: '',
                        amountFieldId: '',
                        succeedFieldSettings: [],
                        failedFieldSettings: []
                    }
                }
            }
        }
    ],
    [
        'refreshPage',
        {
            type: 'refreshPage',
            trigger: 'click',
            data: {
                refreshPage: {}
            }
        }
    ],
    [
        'aliyunMessage',
        {
            type: 'aliyunMessage',
            trigger: 'click',
            data: {
                aliyunMessage: {
                    id: '',
                    mobileList: {
                        type: 'VALUE'
                    } as VariableADTvalue,
                    signatureId: '',
                    templateId: '',
                    values: []
                }
            }
        }
    ]
])

export const dummyTriggerNodeId = '1'

export const getActionDummyTriggerNodeData = (dsId: string): FlowNode => ({
    id: '1',
    type: 'ACTION',
    data: {
        name: '当前页面数据',
        nodeType: 'CLICK_TRIGGER',
        config: {
            dataSourceId: dsId
        }
    },
    position: { x: 0, y: 0 }
})

export const buttonSizeMap = new Map<ButtonSizeType, number>([
    [ButtonSize.sm, 32],
    [ButtonSize.md, 36],
    [ButtonSize.lg, 40]
])
