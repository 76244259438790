import type { MaxContainerWidthSize } from '@lighthouse/core'
import { MAX_CONTAINER_WIDTH_SIZE } from '@lighthouse/core'
import { useEffect, useRef, useState } from 'react'

export const SIZE_OPTIONS = {
    [MAX_CONTAINER_WIDTH_SIZE.sm]: 840,
    [MAX_CONTAINER_WIDTH_SIZE.md]: 960,
    [MAX_CONTAINER_WIDTH_SIZE.lg]: 1200,
    [MAX_CONTAINER_WIDTH_SIZE.xl]: 1440
}

const NONE_RECT = {
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    x: 0,
    y: 0
}

/**
 * 获取容器的对应的内容区域宽度
 * @param strategy 宽度策略
 * @param size 尺寸
 * @returns
 */
export const useContainerWidth = (size: MaxContainerWidthSize = MAX_CONTAINER_WIDTH_SIZE.unlimited) => {
    const ref = useRef<HTMLDivElement>(null)

    const [rect, setRect] = useState(ref.current?.getBoundingClientRect() ?? NONE_RECT)
    const actualWidth = size === MAX_CONTAINER_WIDTH_SIZE.unlimited ? rect.width : Math.min(SIZE_OPTIONS[size], rect.width)

    useEffect(() => {
        const el = ref.current

        if (!el) {
            return
        }

        const handler: ResizeObserverCallback = ([e]) => {
            setRect(e.contentRect)
        }

        const ob = new ResizeObserver(handler)
        ob.observe(el)

        return () => {
            ob.unobserve(el)
        }
    }, [])

    return { ref, width: actualWidth, rect }
}
