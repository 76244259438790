import { Button, IconFont, useDisclosure } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import type { AppendParams, ImportParams } from '@lighthouse/shared'
import { TableUploader } from '@lighthouse/shared'
import { Modal } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

interface SubFormFooterProps {
    appId: string
    envId?: string
    dataSource?: DataSourceAbstract
    onRecordsAdd: () => void
    onImport?: (params: ImportParams) => void
    onAppend?: (params: AppendParams) => void
}

const SCxContainer = styled.div`
    /*  */
    margin-top: 12px;
    display: flex;
    gap: 8px;
`
export const SubFormFooter: React.FunctionComponent<SubFormFooterProps> = ({
    envId = '',
    appId,
    dataSource,
    onRecordsAdd,
    onAppend,
    onImport
}) => {
    const [opened, { close, open }] = useDisclosure(false)
    return (
        <SCxContainer>
            <Button
                radius={6}
                // style={{ height: 32 }}

                icon={<IconFont color="var(--color-gray-400)" type="Add" size={16} />}
                onClick={() => onRecordsAdd()}
            >
                添加一行
            </Button>
            {/* <Button radius={6} style={{ height: 28 }} onClick={open}>
                批量导入
            </Button> */}
            <Modal
                centered
                closeOnClickOutside={false}
                padding={0}
                radius={12}
                size={690}
                overlayColor="var(--color-black)"
                overlayOpacity={0.75}
                overlayBlur={3}
                withCloseButton={false}
                opened={opened}
                onClose={close}
            >
                <TableUploader
                    appId={appId}
                    envId={envId}
                    dataSource={dataSource}
                    defaultImportType="append"
                    disableSelectDataSource
                    onImport={onImport}
                    onAppend={onAppend}
                    onClose={close}
                />
            </Modal>
        </SCxContainer>
    )
}
