import { IconFont, Text } from '@byecode/ui'
import { backgroundTransitionOnClick, pointer } from '@byecode/ui/styles/mixins'
import type { SelectDataSourceVariable } from '@lighthouse/core'
import React from 'react'
import styled from 'styled-components'


interface SelectDataSourceProps {
    value?: SelectDataSourceVariable
    onOpen?: () =>void
}

const SCxLeftFill = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    gap: 6px;
    height: 100%;
    font-size: 14px;
`

const SCxRightFill = styled.div``

const SCxListItem = styled.div`
    width: 100%;
    height: 36px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    overflow: hidden;
    ${backgroundTransitionOnClick}
    ${pointer}
`

const VariableSelectDataSource: React.FunctionComponent<SelectDataSourceProps> = ({
    value,
    onOpen
}) => {
    return (
        <SCxListItem onClick={onOpen}>
            <SCxLeftFill>
                <IconFont color="var(--color-gray-400)" size={16} type="QueryData" />
                <Text size={14} style={{ lineHeight: 1 }}>
                    查询数据表
                </Text>
            </SCxLeftFill>
            <SCxRightFill>
                {value?.selectDataSourceVariable?.fieldId && <IconFont color="var(--color-purple-500)" type="Tick" size={16} />}
            </SCxRightFill>
        </SCxListItem>
    )
}

export default VariableSelectDataSource
