import { mergeRefs } from '@lighthouse/tools'
import React, { useCallback, useEffect, useRef } from 'react'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const NumberInput = React.forwardRef<HTMLInputElement, InputProps>((props, propRef) => {
    const ref = useRef<HTMLInputElement>(null)
    useEffect(() => {
        const ignoreScroll = (e: WheelEvent) => {
            e.preventDefault()
        }
        if (!ref || typeof ref === 'function') {
            return
        }
        ref.current && ref.current.addEventListener('wheel', ignoreScroll)
        return () => {
            ref.current && ref.current.removeEventListener('wheel', ignoreScroll)
        }
    }, [ref])

    return <input ref={mergeRefs([propRef, ref])} type="number" {...props} />
})
