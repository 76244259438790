import type { AiFieldStatus } from '@lighthouse/core'
import type { atomWithImmer } from 'jotai-immer'
import React, { useCallback } from 'react'

import { useAtomData } from '../../hooks'
import FieldValuePreviewer from '../FieldValuePreviewer'
import type { FieldValuePreviewerProps } from '../FieldValuePreviewer/types'
import ViewFieldValuePreviewer from '../ViewFieldValuePreviewer'
import type { ViewFieldValuePreviewerProps } from '../ViewFieldValuePreviewer/types'

interface FieldStatusPreviewerProps extends Exclude<FieldValuePreviewerProps, 'aiFieldStatus'> {
    dsId: string
    recordId?: string
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
}

interface ViewFieldStatusPreviewerProps extends Exclude<ViewFieldValuePreviewerProps, 'aiFieldStatus'> {
    dsId: string
    recordId?: string
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
}

export const FieldStatusPreviewer: React.FC<FieldStatusPreviewerProps> = ({ dsId, recordId, field, aiFieldStatusListAtom, ...rest }) => {
    const { id: fieldId } = field
    const aiFieldStatus = useAtomData(
        aiFieldStatusListAtom,
        useCallback(
            draft => draft.find(item => item.dataSourceId === dsId && item.recordId === recordId && item.fieldId === fieldId)?.state,
            [dsId, fieldId, recordId]
        )
    )

    return <FieldValuePreviewer field={field} aiFieldStatus={aiFieldStatus} {...rest} />
}

export const ViewFieldStatusPreviewer: React.FC<ViewFieldStatusPreviewerProps> = ({
    dsId,
    recordId,
    field,
    aiFieldStatusListAtom,
    ...rest
}) => {
    const { id: fieldId } = field
    const aiFieldStatus = useAtomData(
        aiFieldStatusListAtom,
        useCallback(
            draft => draft.find(item => item.dataSourceId === dsId && item.recordId === recordId && item.fieldId === fieldId)?.state,
            [dsId, fieldId, recordId]
        )
    )

    return <ViewFieldValuePreviewer field={field} aiFieldStatus={aiFieldStatus} {...rest} />
}
