import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

export const useStyles = createStyles(() => ({
    describe: css({
        marginTop: 4,
        color: '$colorGray600'
    }),
    icon: css({
        color: '$colorGray400'
    })
}))
