import type { PageMetaData } from '../page/type'

/**
 * 是否符合给定的page stack
 * @date 9/20/2023 - 6:42:52 PM
 *
 * @param {string} pageId
 * @param {?string} [stackId]
 * @returns {(stack: PageMetaData) => boolean}
 */
export const equalPageStack = (pageId: string, stackId?: string) => (stack: PageMetaData) =>
    stack.pageId === pageId && (stackId ? stack.stackId === stackId : true)
