/* eslint-disable @typescript-eslint/no-explicit-any */
import type { StyleComponentProps } from '@byecode/ui/theme/types'
import { createPolymorphicComponent } from '@byecode/ui/utils/create-Polymorphic-component'
import { css } from '@stitches/react'
import clsx from 'clsx'
import { isEmpty } from 'rambda'
import React, { forwardRef } from 'react'

import { extractSystemStyles } from './extract-system-styles'
import { parseSystemStyles } from './parse-system-styles'

export interface BoxProps extends StyleComponentProps, React.ComponentPropsWithoutRef<'div'> {}

const _Box = forwardRef<HTMLDivElement, BoxProps & { component?: any }>((props, ref) => {
    const { systemStyles, rest } = extractSystemStyles(props)
    const { component: Component = 'div', className, ...other } = rest

    return (
        <Component
            ref={ref}
            {...other}
            className={clsx(
                className,
                isEmpty(systemStyles) ? undefined : css(parseSystemStyles(systemStyles) as Record<string, any>)().className
            )}
        />
    )
})

export const Box = createPolymorphicComponent<'div', BoxProps>(_Box)
Box.displayName = 'Bui-Box'
