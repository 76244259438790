import cls from 'classnames'
import React, { useEffect, useRef } from 'react'

import * as SC from './styles'
import type { ListItemData } from './types'

interface ListNormalItemProps<T> {
    hovered?: boolean
    onClick: () => void
    children?: React.ReactNode
}

function ListNormalItem<T extends ListItemData>({ hovered, onClick, children }: ListNormalItemProps<T>) {
    const listNormalItemRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (hovered && listNormalItemRef.current) {
            const { current: currentItemDom } = listNormalItemRef
            const { offsetTop: top, offsetHeight: height } = currentItemDom
            const { parentElement } = currentItemDom
            const scrollerDom = parentElement?.parentElement
            if (!scrollerDom) {
                return
            }

            const { offsetHeight: scrollerHeight } = scrollerDom
            scrollerDom.scrollTo({ top: top + height - scrollerHeight })
        }
    }, [hovered])
    return (
        <SC.NormalListItem ref={listNormalItemRef} className={cls({ hovered })} onClick={onClick}>
            {children}
        </SC.NormalListItem>
    )
}

export default ListNormalItem
