import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef, useCallback, useMemo } from 'react'

import { Box } from '../Box'
import { RadioGroupProvider } from './context'

const useStyles = createStyles(() => ({
    root: css({
        display: 'flex',
        flexWrap: 'wrap'
    })
}))

export interface RadioGroupProps
    extends StyleComponentProps<Selectors<typeof useStyles>>,
        Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
    name: string
    defaultValue?: string
    value?: string
    onChange?: (v: string) => void
}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>((props, ref) => {
    const { name, defaultValue, value: propValue, onChange: propOnChange, className, classNames, styles, children, ...rest } = props

    const { classes } = useStyles({}, { name: 'radioGroup', classNames, styles })

    const [value, onChange] = useUncontrolled({ value: propValue, defaultValue, onChange: propOnChange })

    const handleChange = useCallback(
        (v: string) => {
            onChange(v)
        },
        [onChange]
    )

    const contextValue = useMemo(() => ({ name, value, onChange: handleChange }), [name, handleChange, value])

    return (
        <RadioGroupProvider value={contextValue}>
            <Box ref={ref} className={clsx(className, classes.root)} {...rest}>
                {children}
            </Box>
        </RadioGroupProvider>
    )
})
