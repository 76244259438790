import { singleTextEllipsis } from '@byecode/ui'
import type { DateField, DateValue, FieldProtocol, HighLightConditions } from '@lighthouse/core'
import { mergeRefs, nanoid } from '@lighthouse/tools'
import { lightFormat } from 'date-fns'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { DATE_FORMAT } from '../../utils/helper'
import { CellHighlighter } from '../Highlighter'
import { TooltipList, useWidth } from '../TooltipList'
import { TooltipText } from '../TooltipText'

interface DateValuePreviewerProps {
    field: DateField
    data?: DateValue | DateValue[]
    isWrap?: boolean
    width?: number
    isBold?: boolean
    styleConditions?: HighLightConditions
}

interface DateListPreviewerProps {
    field: DateField
    data: DateValue[]
    isWrap: boolean
    width: number
    isBold?: boolean
}

interface DateItemPreviewerProps {
    id: string
    field: DateField
    data: DateValue
    isWrap: boolean
    isLast?: boolean
    isBold?: boolean
    disableTooltip?: boolean
}

const SCxDate = styled.div<{ isWrap: boolean; isBold?: boolean }>`
    position: relative;
    white-space: ${({ isWrap }) => (isWrap ? 'warp' : 'nowrap')};
    font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
    word-break: break-word;
    overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'hidden')};
    text-overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'ellipsis')};
`

const SCxDateListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean }>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    overflow: hidden;
`

const SCxItem = styled.div<{ isLast?: boolean }>`
    display: flex;
    align-items: center;
    ${({ isLast }) => isLast && singleTextEllipsis}
`

const SCxSplit = styled.div<{ isLast?: boolean }>`
    display: flex;
    overflow: ${({ isLast }) => (isLast ? 'hidden' : 'unset')};
`

export const DateItemPreviewer: React.FC<DateItemPreviewerProps> = ({ id, field, data, isWrap, isLast, isBold, disableTooltip }) => {
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)
    const {
        date: { format }
    } = field
    const text = lightFormat(Number(data), format || DATE_FORMAT)

    return (
        <TooltipText
            title={text}
            disabled={disableTooltip || isWrap}
            render={ref => {
                return (
                    <SCxItem ref={mergeRefs([ref, widthRef])} isLast={isLast}>
                        <SCxDate isWrap={isWrap} isBold={isBold}>
                            {text}
                        </SCxDate>
                    </SCxItem>
                )
            }}
        />
    )
}

export const DateListPreviewer: React.FC<DateListPreviewerProps> = ({ field, data, isWrap, width, isBold }) => {
    const {
        date: { format }
    } = field
    const dateValue = data.filter(item => Boolean(item) || item === 0)
    const getText = useCallback((val: DateValue) => (val ? lightFormat(Number(val), format || DATE_FORMAT) : ''), [format])
    return (
        <TooltipList
            data={dateValue.map((label, index) => ({ label: getText(label), value: String(index) }))}
            width={width}
            key={dateValue.join('-')}
            disabled={isWrap}
            render={visibleNum => {
                const renderList = dateValue.slice(0, visibleNum)

                return (
                    <SCxDateListWrapper isPadding={visibleNum < dateValue.length} isWrap={isWrap}>
                        {renderList.map((item, index) => (
                            <SCxSplit key={index} isLast={index === renderList.length - 1}>
                                <DateItemPreviewer
                                    field={field}
                                    id={String(index)}
                                    data={item}
                                    isWrap={isWrap}
                                    disableTooltip
                                    isBold={isBold}
                                    isLast={index === renderList.length - 1}
                                />
                                {index !== renderList.length - 1 && ','}
                            </SCxSplit>
                        ))}
                    </SCxDateListWrapper>
                )
            }}
        />
    )
}

export const DateValuePreviewer: React.FC<DateValuePreviewerProps> = ({
    data,
    field,
    width = 160,
    isWrap = true,
    isBold,
    styleConditions
}) => {
    const { id } = field

    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (!data) {
            return ''
        }
        if (Array.isArray(data)) {
            return <DateListPreviewer field={field} data={data} isWrap={isWrap} width={width} isBold={isBold} />
        }
        const id = nanoid()
        return <DateItemPreviewer field={field} id={id} isWrap={isWrap} data={data} isLast isBold={isBold} />
    }, [data, field, isBold, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={id} type="date" value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
