import { IconFont, Input } from '@byecode/ui'
import styled from 'styled-components'

export const FileUploaderWrapper = styled.div`
    width: 480px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

export const TabWrapper = styled.div`
    /* display: flex; */
`

export const TabButton = styled.div`
    display: inline-flex;
    padding: 4px 6px;
    margin-right: 12px;
    border-radius: 3px;
    background-color: transparent;
    color: var(--color-gray-400);
    font-size: var(--font-size-normal);
    cursor: pointer;
    transition: all 0.2s ease-out;

    &.active {
        color: var(--color-white);
        background-color: var(--color-black);
        transition: all 0.2s ease-in;
    }
`

export const TabIcon = styled(IconFont)`
    font-size: 16px;
    color: var(--color-gray-400);
`

export const TabLabel = styled.div``

export const TabContentWrapper = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-normal);
`

export const UploaderArea = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 64px;
    border-radius: 5px;
    border: 1px dashed var(--color-gray-200);
    background-color: var(--color-gray-50);
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const ImageUrlAdderWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const ImageUrlInput = styled(Input)`
    margin-right: 8px;
    flex: 1;
`

export const PexelsContainer = styled.div`
    width: 100%;
    height: 445px;
`
