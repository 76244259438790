import { pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.button`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: var(--font-size-normal);
    border-radius: inherit;
    padding: 0 8px;

    > * {
        background-color: transparent;
    }
`

export const IconWrapper = styled.div`
    ${pointer}
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
`

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 100%;
    min-height: 38px;
    overflow: hidden;
`

export const TagWrapper = styled.div`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
