import { parse } from 'papaparse'

export const readCsv = async (file: File) => {
    const csvText = await new Promise<string>((resolve, reject) => {
        try {
            const reader = new FileReader()
            reader.addEventListener('loadend', () => {
                resolve(reader.result?.toString() || '')
            })
            reader.readAsText(file)
        } catch (e) {
            reject(e)
        }
    })

    return parse<string[]>(csvText)
}

export const isCSV = (file?: File) => file?.type === 'text/csv'
