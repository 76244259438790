import type { ApplicationSettingNavbar, NavigationShowMode } from '@lighthouse/core'
import { ApplicationSettingThemeNavBarMode } from '@lighthouse/core'
import React, { createContext, useContext, useMemo } from 'react'

import { getNavbarThemeStyles } from './help'
import type { ApplicationSettingThemeNavBarModeColor } from './type'

interface AppNavbarStylesContextProviderProps {
    navbar: ApplicationSettingNavbar
    showMode?: NavigationShowMode
    children?: React.ReactNode
}

interface AppNavbarStylesContextInterface extends ApplicationSettingThemeNavBarModeColor {
    children?: React.ReactNode
    themeMode: ApplicationSettingThemeNavBarMode
}

const AppNavbarStylesContext = createContext<AppNavbarStylesContextInterface>({
    backgroundColor: 'var(--color-app-main)',
    color: 'var(--color-white)',
    stressColor: 'var(--color-white)',
    borderColor: 'var(--color-white)',
    logoColor: 'var(--color-app-main)',
    logoBgcolor: 'var(--color-white)',
    themeMode: ApplicationSettingThemeNavBarMode.custom
})

export const AppNavbarStylesContextProvider = ({ children, navbar, showMode }: AppNavbarStylesContextProviderProps) => {
    const navbarStyles = useMemo(() => ({ ...getNavbarThemeStyles(navbar, showMode), themeMode: navbar.themeMode }), [navbar, showMode])

    return <AppNavbarStylesContext.Provider value={navbarStyles}>{children}</AppNavbarStylesContext.Provider>
}

export const useAppNavbarStylesContext = () => useContext(AppNavbarStylesContext)
