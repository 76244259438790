import { Empty, Popover } from '@byecode/ui'
import type { DataSourceAbstract, Field, SchemaProtocol } from '@lighthouse/core'
import React, { useMemo, useState } from 'react'

import { FieldTypeColorMap } from '../../../constants'
import { getTableIcon } from '../../../utils'
import { FieldTypeTag } from '../../FieldTypeTag'
import * as SC from './styles'

interface JoinTableFieldProps {
    key: string
    currentSchema: SchemaProtocol['schema']
    dataSource: DataSourceAbstract
    onSelect: (field: Field) => void
}

export const JoinTableField: React.FC<JoinTableFieldProps> = ({ key, currentSchema, dataSource, onSelect }) => {
    const [open, setOpen] = useState(false)
    const { name, schema, id: dsId } = dataSource
    const addedFields = useMemo(
        () =>
            Object.values(currentSchema)
                .filter(field => field.join?.joinDsId === dsId)
                .map(item => item),
        [currentSchema, dsId]
    )
    const fieldList = useMemo(() => Object.values(schema), [schema])
    const fieldLength = useMemo(() => fieldList.length, [fieldList.length])
    return (
        <Popover trigger="hover" width="auto" withinPortal key={key} position="right-start" opened={open} onChange={setOpen}>
            <Popover.Target>
                <SC.DataSourceItem selected={open}>
                    <SC.ListItemInfo>
                        <SC.TableIcon id="" type={getTableIcon(dataSource)} size={16} fill="var(--color-gray-400)" />
                        <SC.ListItemText>{name}</SC.ListItemText>
                    </SC.ListItemInfo>
                    <SC.ListExtra>
                        <SC.JoinFieldAmount>
                            {addedFields.length}/{fieldLength}
                        </SC.JoinFieldAmount>
                        <SC.TableIcon fill="var(--color-gray-400)" size={16} type="ArrowRightSmall" />
                    </SC.ListExtra>
                </SC.DataSourceItem>
            </Popover.Target>
            <Popover.Dropdown>
                <SC.List>
                    {addedFields.length === fieldLength && <Empty description="字段已全部连接" />}
                    {fieldList.map(field => {
                        if (addedFields.some(f => f.join?.joinFieldId === field.id)) {
                            return ''
                        }
                        return (
                            <SC.ListItem key={field.id} onClick={() => onSelect(field)}>
                                <SC.ListItemInfo>
                                    <SC.ItemIcon id={field.id} type={field.type} fill={FieldTypeColorMap[field.type]} />
                                    <SC.ListItemText>{field.name}</SC.ListItemText>
                                </SC.ListItemInfo>
                                <FieldTypeTag type={field.type} innerType={field.innerType} />
                            </SC.ListItem>
                        )
                    })}
                </SC.List>
            </Popover.Dropdown>
        </Popover>
    )
}
