import { IconFont } from '@byecode/ui'
import { Checkbox } from '@mantine/core'
import cls from 'classnames'
import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

export interface GalleryCheckerProps {
    checked?: boolean
    isRecordSelecting?: boolean
    checkable?: boolean
    showOpenerIcon?: boolean
    className?: string
    disableCheck?: boolean
    onChange?: (checked: boolean) => void
    onRecordOpen?: () => void
}

const SCxTableRecordIndex = styled.p`
    position: absolute;
    color: var(--color-gray-400);
    opacity: 1;
`

const SCxTableRecordCheckerWrapper = styled.div<{ checked?: boolean }>`
    padding: 4px;
    opacity: ${p => (p.checked ? 1 : 0)};
`

const SCxGalleryChecker = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 100%;
`

const SCxTableExtra = styled.div`
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
`

const SCxIcon = styled(IconFont)`
    font-size: 18px;
    color: var(--color-black);
`

export const SCxGalleryCheckerContent = styled.div<{ borderTop?: boolean; isStripe?: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${({ isStripe }) => (isStripe ? 'var(--color-gray-100)' : 'none')};
    border-top: ${props => (props.borderTop ? '1px solid var(--color-gray-200)' : 'none')};
`

export const SCxCheckerPrefix = styled.div<{
    borderTop?: boolean
    isStripe?: boolean
    borderRadius?: boolean
    height?: number
}>`
    width: 8px;
    height: ${props => (props.height ? `${props.height}px` : '37px')};

    background-color: ${({ isStripe }) => (isStripe ? 'var(--color-gray-100)' : 'none')};

    ${props =>
        props.borderRadius &&
        css`
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        `}

    border-top: ${({ borderTop }) => (borderTop ? '1px solid var(--color-gray-200)' : 'none')};
`

const SCxGalleryCheckerWrapper = styled.div<{ height?: number; border?: boolean }>`
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: ${props => (props.height ? `${props.height}px` : '36px')};
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    overflow: hidden;

    border-top: ${props => (props.border ? '1px solid var(--color-gray-200)' : 'none')};

    &:hover {
        ${SCxTableExtra} {
            opacity: 1;
        }
    }

    &.gallery-checkable {
        &.checked,
        &:not(.table-checked):hover {
            ${SCxTableRecordCheckerWrapper} {
                opacity: 1;
            }
            ${SCxTableRecordIndex} {
                opacity: 0;
            }
            ${SCxIcon} {
                display: none;
            }
        }
    }
`

export const SCXCheckBox = styled(Checkbox)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    [type='checkbox']:checked {
        border-color: var(--color-app-main);
        background-color: var(--color-app-main);
    }
`

export const GalleryChecker: React.FC<GalleryCheckerProps> = ({
    checked,
    checkable,
    isRecordSelecting,
    className,
    disableCheck,
    onChange
}) => {
    const handleChange = useCallback(
        (ev: React.MouseEvent | React.ChangeEvent) => {
            ev.stopPropagation()
            if (!checkable) {
                return
            }
            onChange?.(!checked)
        },
        [checkable, checked, onChange]
    )
    return (
        <SCxGalleryCheckerWrapper className={cls({ checked, 'gallery-checkable': checkable }, className)}>
            {!disableCheck && (
                <SCxGalleryCheckerContent>
                    <SCxGalleryChecker onClick={handleChange}>
                        <SCxTableRecordCheckerWrapper style={isRecordSelecting ? { opacity: 1 } : {}}>
                            <SCXCheckBox size="xs" checked={checked} onChange={handleChange} />
                        </SCxTableRecordCheckerWrapper>
                    </SCxGalleryChecker>
                </SCxGalleryCheckerContent>
            )}
        </SCxGalleryCheckerWrapper>
    )
}
