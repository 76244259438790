import type { ActionFlowNode, IClickActionConfig } from '@lighthouse/shared'
import { getActiveAddIClickUserVariableValue } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleAddIClickUser = async (node: ActionFlowNode<IClickActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return excParams
    }

    const addIClickUserParams = getActiveAddIClickUserVariableValue(config, excParams)
    const pageId = excParams?.getCurrentPageDeps()?.pageId ?? ''

    if (addIClickUserParams) {
        await srv.activeAddIClickUser({ ...addIClickUserParams, pageId })
    }

    return addIClickUserParams
}
