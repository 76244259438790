import type { Field, Pagination, RecordLikeProtocol } from '@lighthouse/core'
import React from 'react'
import styled from 'styled-components'

import { TableBody } from './TableBody'

export interface TableContentProps {
    columns: Field[]
    tableWidth?: number
    tableHeight?: number
    scrollXRef: React.RefObject<HTMLDivElement>
    scrollYRef: React.RefObject<HTMLDivElement>
}

export const SCxTableContent = styled.div`
    min-width: 100%;
    width: fit-content;
    /* background-color: var(--color-white); */
`

export const TableContent: React.FC<TableContentProps> = ({ columns, tableWidth, tableHeight, scrollXRef, scrollYRef }) => {
    return (
        <SCxTableContent>
            <TableBody
                tableWidth={tableWidth}
                columns={columns}
                tableHeight={tableHeight}
                scrollXRef={scrollXRef}
                scrollYRef={scrollYRef}
            />
        </SCxTableContent>
    )
}
