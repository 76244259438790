import { Extension } from '@tiptap/core'

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        lineHeight: {
            setLineHeight: (size: number) => ReturnType
            unsetLineHeight: () => ReturnType
        }
    }
}

export type FontSizeOptions = {
    types: string[]
    defaultLineHeight: number
}

export const LineHeight = Extension.create<FontSizeOptions>({
    name: 'lineHeight',

    addOptions() {
        return {
            types: ['textStyle'],
            defaultLineHeight: 1.5
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    lineHeight: {
                        default: this.options.defaultLineHeight,
                        parseHTML: element => {
                            return element.style.lineHeight || this.options.defaultLineHeight
                        },
                        renderHTML: attributes => {
                            if (attributes.lineHeight === this.options.defaultLineHeight) {
                                return {}
                            }

                            return {
                                style: `line-height: ${attributes.lineHeight};`
                            }
                        }
                    }
                }
            }
        ]
    },

    addCommands() {
        return {
            setLineHeight:
                lineHeight =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { lineHeight }).run()
                },
            unsetLineHeight:
                () =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { lineHeight: null }).removeEmptyTextStyle().run()
                }
        }
    }
})
