import { Center } from '@mantine/core'
import React from 'react'

import { VideoPlayer } from '../../VideoPlayer'
import type { FileItemProps } from '../types'

export const VideoFile = ({ file, ...rest }: FileItemProps) => {
    return (
        <Center sx={{ height: '100%', display: 'flex', alignItems: 'center' }} {...rest}>
            <VideoPlayer source={file.url} isController />
            {/* <video src={file.url} playsInline controls controlsList="nodownload noplaybackrate" disablePictureInPicture preload="metadata">
                您的浏览器版本较低，不支持该功能，请升级至最新版本
            </video> */}
        </Center>
    )
}
