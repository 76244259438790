import React from 'react'

import { Box } from '../Box'
import type { TagStylesParams } from './Tag.styles'
import { useStyles } from './Tag.styles'

interface TagProps extends TagStylesParams, React.ComponentPropsWithoutRef<'div'> {
    children?: React.ReactNode | string
}

export const Tag = React.forwardRef<HTMLDivElement, TagProps>(({ color, background, radius, fill, children, ...rest }, ref) => {
    const { classes } = useStyles({ color, background, radius, fill }, { name: 'tag' })
    return (
        <Box className={classes.root} {...rest} ref={ref}>
            {children}
        </Box>
    )
})
