import { IconFont } from '@byecode/ui'
import copyToClipboard from 'copy-to-clipboard'
import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

interface CopyButtonProps {
    value?: string
    className?: string
    style?:React.CSSProperties
    onSuccess?: () => void
}

const SCxButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    *{
        cursor: pointer;
    }
`

export const CopyButton = React.forwardRef<HTMLButtonElement, CopyButtonProps>(({ value, className, style, onSuccess }, ref) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            if (loading) {
                setLoading(false)
            }
        }, 3000)

        return () => {
            clearTimeout(timer)
        }
    }, [loading])

    const iconEle = loading ? (
        <IconFont size={16} color="var(--color-green-500)" type="Tick" />
    ) : (
        <IconFont
            color="var(--color-gray-400)"
            type="Duplicate"
            size={16}
            onClick={() => {
                if (!value) {
                    return
                }
                setLoading(true)
                const isCopied = copyToClipboard(value)
                if (isCopied) {
                    onSuccess?.()
                }
            }}
        />
    )

    return (
        <SCxButton ref={ref} type="button" className={className} style={style}>
            {iconEle}
        </SCxButton>
    )
})
