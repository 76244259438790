import { flex, hideScrollBar, IconFont, overlayPageScrollY, pointer, singleTextEllipsis } from '@byecode/ui'
import type { CSSProperties } from 'react'
import styled, { css } from 'styled-components'

import { PAGE_LAYOUT_HOST, PAGE_SCROLL_PARENT_CONTENT } from '../../constants'

export const Wrapper = styled.div<{ scale?: number; width?: number; height?: number }>`
    /* width: ${({ width }) => (width ? `${width}px` : '100%')};
    height: ${({ height }) => (height ? `${height}px` : '100%')}; */
    height: 100%;
    display: flex;
    flex-direction: column;
    transform: scale(${({ scale }) => scale ?? 'none'});
    overflow: hidden;
`

export const Layout = styled.div`
    position: relative;
    z-index: 0;
    width: 100%;
    /* height: 100%; */
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    overflow: hidden;

    &.mobile {
        height: 100%;
        margin: 0 auto;
        flex-direction: column;
        justify-content: space-between;
        /* background-color: #fff; */
        z-index: 2;
    }
`

export const PageContainer = styled.div<{ direction?: 'row' | 'column' }>`
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-direction: ${({ direction }) => direction};
    overflow: hidden;
`

export const Text = styled.span<{ color?: string; size?: number; isOmit?: boolean }>`
    font-weight: 400;
    font-size: ${({ size }) => size ?? 14}px;
    color: ${({ color }) => color ?? 'var(--color-black)'};
    line-height: 16px;
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

export const Icon = styled(IconFont)<{ size?: number }>`
    font-size: ${({ size }) => size ?? 16}px;
    line-height: 16px;
    ${pointer}
`

export const PlaceholderDom = styled.div`
    width: 100%;
    flex-shrink: 0;
    /* background-color: transparent; */
`

export const Container = styled.div<{ themeColor?: string }>`
    position: relative;
    width: 100%;
    height: 100%;
    /* background: var(--color-gray-50); */
    overflow: hidden;

    &.simulationMobile {
        /* min-width: 393px; */
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        border-style: none;
        border-color: none;
        border-width: 1px 1px 0 1px;
        overflow: initial;

        .applicationWrapper{
            position: relative;
            height: calc(100% - 100px);
            min-height: 852px;
            min-width: 393px;
            border-radius: 48px;
            opacity: 1;
            ---app-color-accent: ${({ themeColor }) => themeColor};
            box-shadow: 0 0 0 5px #151515, 0 0 0 6px var(---app-color-accent), 0 -7.5px 1.5px rgba(255, 255, 255, 0.4),
                7.5px 0 1.5px rgba(255, 255, 255, 0.25), -7.5px 0 1.5px rgba(255, 255, 255, 0.4), 0 7.5px 1.5px rgba(255, 255, 255, 0.25),
                0 0 0 9px var(---app-color-accent), 6px 8px 16px rgba(0, 0, 0, 0.25), 10px 5px 10px rgba(0, 0, 0, 0.2);
            transition: opacity 50ms linear 0s;
            overflow: clip;

            aspect-ratio: 393/ 852;
        }

        .appLayout {
            width: 100%;
            height: inherit;
            z-index: 0;
            /* clip-path: inset(0 round 0 0 48px 48px); */
            overflow: clip;
            display: flex;
            flex-direction: column;
            justify-content: stretch;
        }
    }

    &.simulationWebsite {
        border-radius: 12px;
        border-style: none;
        border-color: var(--color-gray-200);
        border-width: 1px 1px 0 1px;
        overflow: hidden;

        .applicationWrapper {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            /* border: 1px solid var(--color-gray-200);
            border-radius: 0  0 12px 12px; */
            /* overflow: hidden; */
        }

        .appLayout {
            height: calc(100% - 44px);
        }

        .verticalWide {
            border-radius: 0 0 0 12px;
        }
    }
`

export const ScrollArea = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    &.enable {
        flex: 1;
        width: 100%;
        overflow: hidden auto;
        display: flex;
        flex-direction: column;
        ${hideScrollBar()}

        #${PAGE_LAYOUT_HOST} {
            height: auto;
            overflow: unset;
        }

        #${PAGE_SCROLL_PARENT_CONTENT} {
            overflow: unset;
        }
    }
`
