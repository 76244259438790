import { pointer, singleTextEllipsis } from '@byecode/ui'
import React from 'react'
import styled, { css } from 'styled-components'

import { TooltipText } from '../TooltipText'

export interface BlockTitleProps {
    title?: string
    direction?: 'row' | 'column'
    required?: boolean
    fontSize?: number
    style?: React.CSSProperties
}

const SCxBlockTitleWrapper = styled.div<{ direction?: 'row' | 'column'; fontSize: number }>`
    ${({ direction }) =>
        direction === 'row'
            ? css`
                  height: 36px;
                  flex-basis: 40px;
                  p {
                      ${singleTextEllipsis}
                  }
              `
            : css`
                  margin-bottom: 4px;
                  min-height: 24px;
                  flex-basis: 30px;
              `};
    font-size: ${({ fontSize }) => fontSize}px;
    flex: none;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    color: var(--color-black);
    /** 展示左侧对齐辅助线 */
    /*
    position: relative;
    &::before {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';
        height: 800px;
        width: 1px;
        background-color: red;
        z-index: 99999999999;
    } */
`

const SCxBlockTitle = styled.p<{ isPointer?: boolean }>`
    /* width: 100%; */
    max-width: 100%;
    word-break: break-word;
    ${({ isPointer }) => isPointer && pointer}
`

const SCxFieldTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`

const SCxFieldRequiredTip = styled.span`
    margin-left: 6px;
    color: rgb(236, 118, 113);
`

export const BlockTitle: React.FC<BlockTitleProps> = ({ title, direction, fontSize = 14, required, style }) => {
    return (
        <SCxBlockTitleWrapper style={style} direction={direction} fontSize={fontSize}>
            <SCxFieldTitleContainer>
                <TooltipText
                    render={(ref, isOmit, range, target) => {
                        return (
                            <SCxBlockTitle isPointer={isOmit} ref={ref}>
                                {title}
                            </SCxBlockTitle>
                        )
                    }}
                    title={title ?? ''}
                />
                {required && <SCxFieldRequiredTip>*</SCxFieldRequiredTip>}
            </SCxFieldTitleContainer>
        </SCxBlockTitleWrapper>
    )
}
