import { useMergeRefs } from '@floating-ui/react'
import type React from 'react'
import { cloneElement, forwardRef } from 'react'

import { isElement } from '../../../utils/isElement'
import { usePopoverContext } from '../Popover.context'

const ERROR_MESSAGE = `[PopoverTarget]'s children must be an React.ReactElement, not React Fragment`

export interface PopoverTargetProps {
    children: React.ReactNode
}

export const PopoverTarget = forwardRef<HTMLElement, PopoverTargetProps>(({ children, ...props }, ref) => {
    if (!isElement(children)) {
        throw new Error(ERROR_MESSAGE)
    }

    const ctx = usePopoverContext()
    const _ref = useMergeRefs([ctx.reference, ref])

    return cloneElement(children, {
        ref: _ref,
        ...ctx.interactionProps.getReferenceProps({ ...props, ...children.props })
    })
})

PopoverTarget.displayName = 'Bui-PopoverTarget'
