import { useDeferredValue, useEffect, useRef, useState } from 'react'
import { debounce } from 'throttle-debounce'

/**
 * 获取容器的对应的内容区域高度
 * @returns
 */
export const useContainerHeight = () => {
    const ref = useRef<HTMLDivElement>(null)

    const [height, setHeight] = useState(ref.current?.getBoundingClientRect().height ?? 0)
    const finalHeight = useDeferredValue(height)

    useEffect(() => {
        const el = ref.current

        if (!el) {
            return
        }

        const handler: ResizeObserverCallback = debounce(1000 / 60, ([e]: ResizeObserverEntry[]) => {
            setHeight(e.contentRect.height)
        })

        const ob = new ResizeObserver(handler)
        ob.observe(el)

        return () => ob.unobserve(el)
    }, [])

    return { ref, height: finalHeight }
}
