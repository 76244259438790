import type { BlockAbstract, InputValueItem, LayoutNode, PageAbstract } from '@lighthouse/core'
import equal from 'fast-deep-equal'
import type { Draft } from 'immer'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { atomWithImmer } from 'jotai-immer'
import { findLast } from 'rambda'

import { languageAtom } from '../application/state'
import { applyDraftPayload } from '../utils/applyDraftPayload'
import { equalPageStack } from '../utils/equalPageStack'
import type { PageMetaData } from './type'

export const pageListAtom = atomWithImmer<PageAbstract[]>([])

export const homePageAtom = atom(get => {
    const list = get(pageListAtom)
    const language = get(languageAtom)
    return list.find(page => page.isHome && page.language === language)
})

export const defaultPageListAtom = atom(get => {
    const list = get(pageListAtom)
    return list.filter(page => page.type === 'default')
})

export const pageAtomFamily = atomFamily((pageID: string) =>
    atom(
        get => get(pageListAtom)?.find(item => item.id === pageID) ?? null,
        (_, set, payload: PageAbstract | null | ((draft: Draft<PageAbstract | null>) => void)) =>
            set(pageListAtom, draft => {
                const page = draft?.find(item => item.id === pageID) ?? null
                applyDraftPayload(page, payload)
            })
    )
)

export const pageNodesAtom = atomWithImmer<Partial<Record<string, LayoutNode[]>>>({})
export const pageBlocksAtom = atomWithImmer<Partial<Record<string, BlockAbstract[]>>>({})

export const pageStackAtom = atomWithImmer<PageMetaData[]>([])

export const pageStackAtomFamily = atomFamily(
    ({ pageId, stackId }: { pageId: string; stackId?: string }) =>
        atom(
            get => {
                const stack = get(pageStackAtom)
                return stack.find(equalPageStack(pageId, stackId))
            },
            (_, set, payload: (draft: Draft<PageMetaData>) => void) => {
                set(pageStackAtom, draft => {
                    const pageStack = draft?.find(item => item.stackId === stackId)
                    if (pageStack) {
                        applyDraftPayload(pageStack, payload)
                    }
                })
            }
        ),
    equal
)

export const firstPageOfStackAtom = atom<PageMetaData | undefined>(get => {
    const stack = get(pageStackAtom)
    return stack[0]
})

export const lastPageOfStackAtom = atom<PageMetaData | undefined>(get => {
    const stack = get(pageStackAtom)
    return stack[stack.length - 1]
})

export const lastRootPageOfStackAtom = atom<PageMetaData | undefined>(get => {
    const stackList = get(pageStackAtom)
    return findLast(stack => stack.pageId === stack.rootPageId, stackList)
})

export const pageStackOfFieldBlockAtom = atomWithImmer<Record<string, Record<string, InputValueItem>>>({})

export const pageStackOfFormContainerBlockChangedAtom = atomWithImmer<Record<string, boolean>>({})
