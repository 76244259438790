import type {
    Conditions,
    DataSourceAbstract,
    DateFilterConfig,
    DateRangeSystemVariableValue,
    FieldInputType,
    FilterBlockAbstract,
    FilterBlockItemConfig,
    FilterBlockItemValue,
    FilterBlockValue,
    FilterCommonCondition,
    FilterFormType,
    FilterGroupType,
    FilterOption,
    FilterVariable,
    NumberFilterConfig,
    RecordLikeProtocol,
    SelectFilterConfig,
    ValueVariable,
    VariableADTvalue,
    VariableFieldADTValue
} from '@lighthouse/core'
import { FilterWay, VariableType } from '@lighthouse/core'
import type { AnyObject } from 'immer/dist/internal'
import { filter } from 'rambda'

import { FILTER_OPERATOR } from '../../components'
import { defaultFormat, innerTypeToFieldTypeMapInCondition, InputTypeToFieldTypeMap } from '../../constants'
import { getValueVariableValueEmptyPlaceholder } from '../condition'
import { getRealFieldByInnerType } from '../field'
import { getUpstreamRealDsId } from '../flow'
import { isArrayCellValue } from '../helper'
import { generateDataRangeByRangeValue } from './dateRangeGenerator'
import type { resolveFilterItemParams, ResolveFilterParams, resolveFilterToValueParams } from './helper'

interface ConvertFilterBlockConditionsParams {
    filterCondition: FilterCommonCondition
    filterVariable: FilterVariable['filterVariable']
    filterBlocks: FilterBlockAbstract[]
    filterValue: {
        blockId: string
        data: FilterBlockValue
    }[]
    filterOptions: Record<string, FilterOption[]>
}

interface GetFilterItemByBlockIdAndItemIdParams {
    blockId: string
    itemId: string
    filterBlocks: FilterBlockAbstract[]
}

interface AssembleFilterConditionsParams {
    filterCondition: FilterCommonCondition
    filterOptions: Record<string, FilterOption[]>
    filterItemConfig: FilterBlockItemConfig
    filterItemData: FilterBlockItemValue
}

export const validateFilterFieldData = (filter: FilterFormType, dataSource: DataSourceAbstract): FilterFormType => {
    const { expression } = filter
    const { schema } = dataSource
    if (!expression || !expression.conditions) {
        return filter
    }
    const newConditions = expression.conditions.filter(condition => {

        const idVariable = (condition as FilterCommonCondition)?.idVariable
        if (!idVariable) {
            return false
        }
        if (idVariable.type === VariableType.FIELD_ID) {
            if (!idVariable.fieldIdVariable?.fieldId) {
                return false
            }
            const field = schema[idVariable.fieldIdVariable.fieldId]
            if (!field) {
                return false
            }
        }
        return true
    })

    return {
        ...filter,
        expression: {
            ...expression,
            conditions: newConditions
        }
    }
}

/**
 * 处理筛选器左侧筛选内容
 * @param idVariable
 * @param extraParams
 * @returns
 */
const resolveIdVariable = (idVariable: VariableADTvalue, extraParams?: AnyObject): VariableADTvalue | undefined => {
    const { type } = idVariable

    // 如果是左边是上游节点数据，需要将其转换为真实数据包含 dsId、fieldId
    if (type === VariableType.UPSTREAM) {
        const { upstreamVariable } = idVariable

        if (upstreamVariable) {
            const { nodeId = '', fieldId = '' } = upstreamVariable

            const dsId = getUpstreamRealDsId(nodeId, extraParams?.nodes)

            const record: RecordLikeProtocol = extraParams?.[nodeId]?.record
            const fieldValue = record?.content?.[fieldId]?.value
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const dataSource = dataSourceList?.find(({ id }) => id === dsId)
            const field = dataSource?.schema[fieldId]

            if (field) {
                const realFiled = getRealFieldByInnerType(field, innerTypeToFieldTypeMapInCondition)

                const fieldVariable: ValueVariable = {
                    type: VariableType.VALUE,
                    valueVariable: {
                        type: realFiled.type,
                        value: fieldValue,
                        innerType: realFiled.innerType
                    } as VariableFieldADTValue
                }

                return fieldVariable
            }

            return undefined
        }
    }

    return idVariable
}

/**
 * 通过 blockId 和 itemId 获取筛选项
 * @returns
 */
const getFilterItemByBlockIdAndItemId = ({ blockId, itemId, filterBlocks }: GetFilterItemByBlockIdAndItemIdParams) => {
    const filterBlock = filterBlocks.find(block => block.id === blockId)
    return filterBlock?.config?.filterItems?.find(item => item.id === itemId)
}

/**
 * 根据筛选控制器选项筛选数据，生成筛选器筛选条件（被包含关系）
 * 文本，统一转为 = 条件，并用 OR 连接
 * 数值，统一转为区间判断，使用 >= 和 <= 条件，项内用 AND 连接，项间使用 OR 链接
 * 日期，统一日期区间，使用 >= 和 <= 条件，项内用 AND 连接，项间使用 OR 链接
 * 人员，统一转为 contains 条件，并用 OR 连接
 * 选项，统一转为 contains 条件，并用 OR 连接
 */
/**
 * 处理文本筛选
 * @param params
 * @returns
 */
const assembleTextFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterCondition, filterItemData } = params
    const { idVariable } = filterCondition
    const values = filterItemData?.value

    return values?.map(value => {
        return {
            idVariable,
            operator: 'contains',
            paramList: [
                {
                    type: VariableType.VALUE,
                    valueVariable: {
                        type: 'text',
                        value
                    }
                }
            ]
        }
    })
}
/**
 * 处理数字筛选
 * @param params
 * @returns
 */
const assembleNumberFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterCondition, filterOptions, filterItemData } = params
    const filterItemConfig = params.filterItemConfig as NumberFilterConfig
    const { idVariable } = filterCondition
    // 拿到数值筛选器的选项
    const numberOptions = filterItemConfig?.numberOptions
    const values = filterItemData?.value
    const realValues = values?.map(value => {
        if (value === 'custom') {
            // TODO: @KeLin 初始化时，customValue 为 undefined ，需要处理
            return filterItemData?.customValue
        }
        return numberOptions?.find(o => o.id === value)?.value
    })

    return realValues?.map(value => {
        if (!value) {
            return undefined
        }
        // 对应于 value 的 [min, max]
        const operators = ['>=', '<=']
        return {
            where: 'AND',
            conditions: operators
                .map(
                    (operator, i) =>
                        value[i] !== undefined && {
                            idVariable,
                            operator,
                            paramList: [
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: {
                                        type: 'number',
                                        value: value[i]
                                    }
                                }
                            ]
                        }
                )
                .filter(Boolean)
        }
    })
}
/**
 * 处理日期筛选
 * @param params
 * @returns
 */
const assembleDateFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterCondition, filterItemData } = params
    const filterItemConfig = params.filterItemConfig as DateFilterConfig
    // 日期常量
    const { dateVariables } = filterItemConfig
    const { idVariable } = filterCondition
    // 选中数据
    const values = filterItemData?.value
    const realValues = values?.map(value => {
        if (value === 'custom') {
            // TODO: @KeLin 初始化时，customValue 为 undefined ，需要处理
            return filterItemData?.customValue
        }
        return dateVariables?.includes(value) ? generateDataRangeByRangeValue(value as DateRangeSystemVariableValue) : undefined
    })

    return realValues?.map(value => {
        if (!value) {
            return undefined
        }
        // 对应于 value 的 [min, max]
        const operators = ['>=', '<=']
        return {
            where: 'AND',
            conditions: operators
                .map((operator, i) => {
                    const dateValue = value[i]
                    return (
                        dateValue && {
                            idVariable,
                            operator,
                            paramList: [
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: {
                                        type: 'date',
                                        value: dateValue.valueOf()
                                    }
                                }
                            ]
                        }
                    )
                })
                .filter(Boolean)
        }
    })
}
/**
 * 处理人员筛选
 * @param params
 * @returns
 */
const assemblePersonFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterCondition, filterItemData } = params
    const { idVariable } = filterCondition
    const values = filterItemData?.value

    return values?.map(value => {
        return {
            idVariable,
            operator: 'contains',
            paramList: [
                {
                    type: VariableType.VALUE,
                    valueVariable: {
                        type: 'user',
                        value
                    }
                }
            ]
        }
    })
}
/**
 * 处理选项筛选
 * @param params
 * @returns
 */
const assembleSelectFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterCondition, filterItemData } = params
    const filterItemConfig = params.filterItemConfig as SelectFilterConfig
    const { customOptions } = filterItemConfig
    const { idVariable } = filterCondition
    const values = filterItemData?.value

    return values?.map(value => {
        if (!customOptions?.includes(value)) {
            return undefined
        }
        return {
            idVariable,
            operator: 'contains',
            paramList: [
                {
                    type: VariableType.VALUE,
                    valueVariable: {
                        type: 'select',
                        value
                    }
                }
            ]
        }
    })
}


/**
 * 处理布尔筛选
 * @param params
 * @returns
 */
const assembleBoolFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterCondition, filterItemData } = params
    const { idVariable } = filterCondition
    const values = filterItemData?.value

    return values?.map(value => {
        return {
            idVariable,
            operator: '=',
            paramList: [
                {
                    type: VariableType.VALUE,
                    valueVariable: {
                        type: 'boolean',
                        value: value === 'true' ? true : value === 'false' ? false : undefined
                    }
                }
            ]
        }
    })
}

const assembleFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterItemConfig, filterItemData } = params

    const { type: cachedFilterWay } = filterItemData
    const { filterWay } = filterItemConfig

    // 处理当前筛选器的类型变动后，不再使用缓存的筛选器类型
    if (cachedFilterWay && cachedFilterWay !== filterWay) {
        return []
    }

    switch (filterWay) {
        case FilterWay.textFilter: {
            return assembleTextFilterConditions(params)
        }
        case FilterWay.numberFilter: {
            return assembleNumberFilterConditions(params)
        }
        case FilterWay.dateFilter: {
            return assembleDateFilterConditions(params)
        }
        case FilterWay.personFilter: {
            return assemblePersonFilterConditions(params)
        }
        case FilterWay.selectFilter: {
            return assembleSelectFilterConditions(params)
        }
        case FilterWay.cascadeFilter: {
            return assembleTextFilterConditions(params)
        }
        case FilterWay.boolFilter: {
            return assembleBoolFilterConditions(params)
        }
        default: {
            return []
        }
    }
}

/**
 * 转换筛选器中的变量，主要针对全局筛选控制器变量
 * @returns
 */
const convertFilterBlockConditions = ({
    filterCondition,
    filterVariable,
    filterBlocks,
    filterValue,
    filterOptions
}: ConvertFilterBlockConditionsParams) => {
    if (!filterVariable) {
        return
    }
    const { blockId, itemId } = filterVariable

    // 如果当前筛选项不存在，直接返回
    if (!blockId || !itemId) {
        return
    }

    const filterItemConfig = getFilterItemByBlockIdAndItemId({ blockId, itemId, filterBlocks })

    // 如果当前筛选项不存在，直接返回
    if (!filterItemConfig) {
        return
    }
    const filterItemData = filterValue.find(item => item.blockId === blockId)?.data[itemId]

    // 如果当前筛选项的值不存在，直接返回
    if (!filterItemData) {
        return
    }

    const conditions = filter(
        Boolean,
        assembleFilterConditions({
            filterCondition,
            filterOptions,
            filterItemConfig,
            filterItemData
        }) as FilterCommonCondition[]
    )

    return {
        where: 'OR',
        conditions
    }
}

/**
 * 解析过滤器相关变量
 * 主要使用在动作、动作流、筛选器中，将过滤器中的变量转换为真实数据
 * @param ResolveFilterParams
 * @returns
 */
export const resolveFilterItem = ({ condition, extraParams, shouldUseEmptyPlaceholder, useInFilterRecordChecker }: resolveFilterItemParams) => {


    const { paramList, idVariable, operator } = condition

    if (!paramList) {
        return condition
    }

    const newParamList: VariableADTvalue[] = []

    const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList

    for (const param of paramList) {
        const { type } = param

        /**
         * 如果是筛选器校验，直接返回
         */
        if (useInFilterRecordChecker && type !== VariableType.UPSTREAM) {
            newParamList.push(param)
            continue
        }

        switch (type) {
            case VariableType.UPSTREAM: {
                const { upstreamVariable } = param

                if (!upstreamVariable || !upstreamVariable.nodeId || !upstreamVariable.fieldId) {
                    newParamList.push(param)
                    continue
                }

                const { nodeId, fieldId } = upstreamVariable

                const record = extraParams?.[nodeId]?.record

                const fieldValue = record?.content?.[fieldId]?.value

                const dsId = getUpstreamRealDsId(nodeId, extraParams?.nodes)

                const dataSource = dataSourceList?.find(({ id }) => id === dsId)

                const field = dataSource?.schema[fieldId]

                if (field) {
                    const realFiled = getRealFieldByInnerType(field, innerTypeToFieldTypeMapInCondition)
                    if (!realFiled) {
                        continue
                    }
                    if (realFiled.type === 'date') {
                        newParamList.push({
                            type: VariableType.VALUE,
                            valueVariable: {
                                type: realFiled.type,
                                value: shouldUseEmptyPlaceholder ? getValueVariableValueEmptyPlaceholder(fieldValue) : fieldValue,
                                format: realFiled.date.format || defaultFormat,
                                innerType: realFiled.innerType
                            } as VariableFieldADTValue
                        })
                        continue
                    }
                    newParamList.push({
                        type: VariableType.VALUE,
                        valueVariable: {
                            type: realFiled.type,
                            value: shouldUseEmptyPlaceholder ? getValueVariableValueEmptyPlaceholder(fieldValue) : fieldValue,
                            innerType: realFiled.innerType
                        } as VariableFieldADTValue
                    })
                }
                continue
            }
            case VariableType.VALUE: {
                const { valueVariable } = param

                if (!valueVariable) {
                    newParamList.push(param)
                    continue
                }

                const { type, value } = valueVariable

                const newValue = value

                if (newValue || type === 'checkbox') {
                    // 过滤掉空值
                    newParamList.push({
                        type: VariableType.VALUE,
                        valueVariable: { type, value: newValue } as VariableFieldADTValue // 这里要过滤
                    })
                }
                continue
            }
            case VariableType.INPUT: {
                const pageStackFormState = extraParams?.pageStackFormState
                const { inputVariable } = param
                const { blockId = '' } = inputVariable ?? {}
                if (!pageStackFormState) {
                    newParamList.push({
                        type: VariableType.VALUE,
                        valueVariable: {
                            type: 'text',
                            value: getValueVariableValueEmptyPlaceholder('')
                        }
                    })
                    continue
                }
                const formState = pageStackFormState[blockId]
                if (!formState) {
                    newParamList.push({
                        type: VariableType.VALUE,
                        valueVariable: {
                            type: 'text',
                            value: getValueVariableValueEmptyPlaceholder('')
                        }
                    })
                    continue
                }
                const fieldType = InputTypeToFieldTypeMap[formState.type as FieldInputType]
                newParamList.push({
                    type: VariableType.VALUE,
                    valueVariable: { type: fieldType, value: shouldUseEmptyPlaceholder ? getValueVariableValueEmptyPlaceholder(formState.value) : formState.value } as VariableFieldADTValue // 这里要过滤
                    // valueVariable: { type: fieldType, value: formState.value } as VariableFieldADTValue // 这里要过滤
                })
                continue
            }
            case VariableType.SYSTEM: {
                // 这个变量后端能处理，不用前端转了
                newParamList.push(param)
                // const value = getSystemVariableValue(param)
                // newParamList.push({
                //     type: VariableType.VALUE,
                //     valueVariable: { type: 'date', value } as VariableFieldADTValue
                // })
                break
            }
            case VariableType.PAGE: {
                const { pageVariable } = param

                if (!pageVariable || !pageVariable.fieldId) {
                    break
                }

                const { fieldId, type } = pageVariable

                const pageRecord = type === 'page' ? extraParams?.pageRecord : extraParams?.clickTriggerNodeParams?.prevRecord

                const fieldValue = pageRecord?.content?.[fieldId]?.value
                const dsId = pageRecord?.dsId
                const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
                const dataSource = dataSourceList?.find(({ id }) => id === dsId)
                const field = dataSource?.schema[fieldId]

                newParamList.push({
                    type: VariableType.VALUE,
                    valueVariable: { type: field?.type, value: shouldUseEmptyPlaceholder ? getValueVariableValueEmptyPlaceholder(fieldValue) : fieldValue } as VariableFieldADTValue
                })
                break
            }
            case VariableType.USER: {
                newParamList.push(param)
                // const { userVariable } = param

                // if (!userVariable || !userVariable.fieldId) {
                //     break
                // }

                // const { fieldId } = userVariable

                // const userRecord = extraParams?.userRecord?.record

                // const fieldValue = userRecord?.content?.[fieldId]?.value
                // const dsId = userRecord?.dsId
                // const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
                // const dataSource = dataSourceList?.find(({ id }) => id === dsId)
                // const field = dataSource?.schema[fieldId]

                // newParamList.push({
                //     type: VariableType.VALUE,
                //     valueVariable: { type: field?.type, value: shouldUseEmptyPlaceholder ? getValueVariableValueEmptyPlaceholder(fieldValue) : fieldValue } as VariableFieldADTValue
                // })
                continue
            }
            case VariableType.VIEW: {
                const { viewVariable } = param

                if (!viewVariable || !viewVariable.fieldId) {
                    break
                }

                const { fieldId } = viewVariable

                const viewRecord = extraParams?.viewRecord

                const fieldValue = viewRecord?.content?.[fieldId]?.value
                const dsId = viewRecord?.dsId
                const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
                const dataSource = dataSourceList?.find(({ id }) => id === dsId)
                const field = dataSource?.schema[fieldId]

                newParamList.push({
                    type: VariableType.VALUE,
                    valueVariable: { type: field?.type, value: shouldUseEmptyPlaceholder ? getValueVariableValueEmptyPlaceholder(fieldValue) : fieldValue } as VariableFieldADTValue
                })
                break
            }
            case VariableType.PAGE_LINK: {
                const { pageLinkVariable } = param
                if (pageLinkVariable?.value === 'CURRENT_PAGE') {
                    const link = extraParams?.getCurrentPageLink?.()
                    newParamList.push({
                        type: VariableType.VALUE,
                        valueVariable: { type: 'url', value: shouldUseEmptyPlaceholder ? getValueVariableValueEmptyPlaceholder(link) : link } as VariableFieldADTValue
                    })
                }
                break
            }
            case VariableType.FILTER: {
                const { filterVariable } = param
                if (extraParams) {
                    const { filterBlockParams } = extraParams
                    const { filterValue, filterOptions } = filterBlockParams
                    const filterBlocks: FilterBlockAbstract[] = filterBlockParams?.filterBlocks

                    return convertFilterBlockConditions({
                        filterCondition: condition,
                        filterVariable,
                        filterBlocks,
                        filterValue,
                        filterOptions
                    })
                }

                break
            }
            default: {
                break
            }
        }
    }

    if (operator !== FILTER_OPERATOR && newParamList?.length) {
        return {
            ...condition,
            idVariable: idVariable && resolveIdVariable(idVariable, extraParams),
            paramList: newParamList
        }
    }

    return condition

    // return {
    //     ...filter,
    //     expression: {
    //         ...expression,
    //         conditions: newConditions
    //     }
    // }
}

// where: 'AND' | 'OR'
// conditions: FilterConditions[]
export const resolveExpression = ({ where, conditions, extraParams, shouldUseEmptyPlaceholder, useInFilterRecordChecker }: resolveFilterToValueParams): FilterGroupType => {
    const newConditions = conditions?.map(condition => {
        if (condition.where && condition.conditions) {
            return resolveExpression({ where: condition.where, conditions: condition.conditions, extraParams, shouldUseEmptyPlaceholder, useInFilterRecordChecker })
        }
        return resolveFilterItem({ condition, extraParams, shouldUseEmptyPlaceholder, useInFilterRecordChecker })
    })?.filter(Boolean) as Conditions

    return {
        where,
        conditions: newConditions
    }
}


export const resolveFilter = ({ filter, extraParams, shouldUseEmptyPlaceholder, useInFilterRecordChecker }: ResolveFilterParams): FilterFormType => {
    const { expression } = filter

    if (!expression || !expression.conditions || !expression.where) {
        return filter
    }
    const { where, conditions } = expression
    const newExpression = resolveExpression({ where, conditions, extraParams, shouldUseEmptyPlaceholder, useInFilterRecordChecker })
    return {
        expression: newExpression
    }
}
