import type { Workflow } from '@lighthouse/shared'
import { atom } from 'jotai'
import { atomWithImmer } from 'jotai-immer'

export const workflowListAtom = atomWithImmer<Workflow[]>([])

export const actionFlowListAtom = atom(get => {
    const list = get(workflowListAtom)
    return list.filter(item => item.type === 'action')
})
