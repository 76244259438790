import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { ByecodeSize } from '@byecode/ui/types'

export type InputStylesParams = {
    size: ByecodeSize
    focusOutLine?: boolean
    disabled?: boolean
    hasPrefix?: boolean
    highlighting?: boolean
}

const sizeStyles = {
    xs: {
        height: '24px',
        borderRadius: '5px'
    },
    sm: {
        height: '28px',
        borderRadius: '6px'
    },
    md: {
        height: '32px',
        borderRadius: '6px'
    },
    lg: {
        height: '36px',
        borderRadius: '8px'
    },
    xl: {
        height: '40px',
        borderRadius: '6px'
    }
}

export const useStyles = createStyles(({ size, focusOutLine, hasPrefix, disabled, highlighting }: InputStylesParams) => ({
    wrapper: css({
        position: 'relative',
        // ts-ignore eslint
        background: '$colorGray100',
        border: '1.5px solid transparent',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        // gap: 4,
        cursor: 'pointer',
        overflow: 'hidden',

        ':placeholder': {
            fontSize: '14px',
            color: '$colorGray400'
        },

        '&:focus, &:focus-within': focusOutLine && {
            backgroundColor: '$colorWhite',
            borderColor: '$colorPrimary'
        },

        ...(disabled
            ? {
                backgroundColor: '$colorGray200',
                '& *': {
                      cursor: 'not-allowed',
                      color: '$colorGray500'
                  }
              }
            : {
                  '&:not([data-focused]):hover': {
                      backgroundColor: '$colorGray200'
                  }
              }),

        '&[data-highlighting=true]': {
            borderColor: '$colorPrimary',
            boxShadow: '0 0 4px 0 $colorPrimary'
        },

        ...sizeStyles[size]
    }),

    prefix: css({
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        marginRight: '4px',
        width: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
        color: '$colorGray400'
    }),

    suffix: css({
        minWidth: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '$colorGray400'
    }),

    input: css({
        width: '100%',
        height: '100%',
        padding: `2px 0 2px ${hasPrefix ? 30 : 8}px`,
        border: 'none',
        flex: 1,
        color: '$colorGray900',
        '&:disabled': {
            cursor: 'not-allowed'
        },
        '&::placeholder': {
            fontSize: 14,
            fontWeight: 400,
            color: '$colorGray400'
        }
    })
}))

// export const StyledInputWrapper = styled('div', {
//     // TODO: 后面换成 theme 取值
//     position: 'relative',
//     backgroundColor: '$colorGray100',
//     border: '1.5px solid transparent',
//     fontSize: '14px',
//     display: 'flex',
//     alignItems: 'center',
//     // gap: 4,
//     cursor: 'pointer',
//     overflow: 'hidden',

//     ':placeholder': {
//         fontSize: '14px',
//         color: '$colorGray400'
//     },

//     // '&:not(:disabled):not(:focus-within):hover': {
//     //     backgroundColor: '$colorGray200',
//     // },

//     variants: {
//         size: {
//             xs: {
//                 height: '24px'
//             },
//             sm: {
//                 height: '28px'
//             },
//             md: {
//                 height: '32px'
//             },
//             lg: {
//                 height: '36px'
//             },
//             xl: {
//                 height: '40px'
//             }
//         },
//         borderRadius: {
//             true: {
//                 borderRadius: '6px'
//             }
//         },
//         focusOutLine: {
//             true: {
//                 '&:focus, &:focus-within': {
//                     backgroundColor: '$colorWhite',
//                     borderColor: '$colorPrimary'
//                 }
//             }
//         },
//         disabled: {
//             true: {
//                 backgroundColor: '$colorGray100',

//                 '& *': {
//                     cursor: 'not-allowed',
//                     color: '$colorGray500'
//                 }
//             },
//             false: {
//                 '&:not([data-focused]):hover': {
//                     backgroundColor: '$colorGray200'
//                 }
//             }
//         }
//     },
//     compoundVariants: [
//         {
//             size: 'xs',
//             borderRadius: true,
//             css: {
//                 borderRadius: '5px'
//             }
//         },
//         {
//             size: 'sm',
//             borderRadius: true,
//             css: {
//                 borderRadius: '6px'
//             }
//         },
//         {
//             size: 'md',
//             borderRadius: true,
//             css: {
//                 borderRadius: '6px'
//             }
//         },
//         {
//             size: 'lg',
//             borderRadius: true,
//             css: {
//                 borderRadius: '8px'
//             }
//         }
//     ]
// })

// export const StyledInputPrefix = styled('div', {
//     pointerEvents: 'none',
//     position: 'absolute',
//     top: 0,
//     bottom: 0,
//     left: 0,
//     marginRight: '4px',
//     width: '30px',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     fontSize: 16,
//     color: '$colorGray400'
// })

// export const StyledInputSuffix = styled('div', {
//     minWidth: '24px',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
// })

// export const StyledInput = styled('input', {
//     width: '100%',
//     height: '100%',
//     padding: '2px 0 2px 8px',
//     border: 'none',
//     flex: 1,
//     color: '$colorGray900',
//     '&:disabled': {
//         cursor: 'not-allowed'
//     },
//     '&::placeholder': {
//         fontSize: 14,
//         fontWeight: 400,
//         color: '$colorGray400'
//     },
//     variants: {
//         hasPrefix: {
//             true: {
//                 paddingLeft: '30px'
//             }
//         }
//     }
// })
