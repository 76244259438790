import { backgroundTransitionOnClick, Input,pointer, tinyButtons } from '@byecode/ui'
import styled from 'styled-components'

export const DropDownContainer = styled.div`
    width: 100%;
    /* align-items: center; */
    /* justify-content: center; */
    font-size: var(--font-size-normal);

`
export const DropDownItem = styled.div`
    width: 100%;
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    ${backgroundTransitionOnClick}
    ${pointer}
`

export const TargetContainer = styled.button<{ disabled?: boolean }>`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: left;
    min-height: 38px;
    padding: 8px;
`
export const TagWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
`
export const ValueContainer = styled.div`
    /* width: calc(100% - 30px); */
    flex: 1;
`

export const SearchInput: typeof Input = styled(Input)`
    margin: 12px 12px 4px 12px;
    &:focus-within {
        border-color: var(--color-app-main)
    }
`

export const RightSection = styled.div`
    min-width: 24px;
    height: 22px;
    display: flex;
    justify-content: center;
    background-color: inherit;
    z-index: 1;
`

export const List = styled.div`
    padding: 8px 0px;
    max-height: 560px;
    width: 100%;
    overflow-y: auto;
    ${tinyButtons};
`
