import { Toast } from '@byecode/ui'
import type { ButtonAction, ButtonTriggerEvent, RecordLikeProtocol } from '@lighthouse/core'
import { useCallback } from 'react'

import { useLoadings } from './useLoadings'

export const useActionRunningLoadings = () => {
    const { loadings, startLoading, stopLoading } = useLoadings()

    const handleActionTriggerWithLoading = useCallback(
        async (params: {
            id: string
            type: ButtonTriggerEvent
            action: ButtonAction
            record?: RecordLikeProtocol
            trigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
        }) => {
            const { type, action, id, record, trigger } = params
            if (type !== action.trigger || loadings[id]) {
                return
            }

            if (action.type === 'none') {
                return
            }

            if (action.type === 'automation') {
                startLoading(id, false)
                const isSuccess = await trigger?.(action, record)
                stopLoading(id)
                if (!(isSuccess ?? true)) {
                    Toast.error('执行失败')
                }
                return
            }

            startLoading(id)

            trigger?.(action, record)
        },
        [loadings, startLoading, stopLoading]
    )

    return { loadings, startLoading, stopLoading, handleActionTriggerWithLoading }
}
