import type { ChangeEvent } from 'react'
import React, { forwardRef, useCallback, useId, useMemo } from 'react'

import { useComponentConfig } from '../../hooks/useComponentConfig'
import { useUncontrolled } from '../../hooks/useUncontrolled'
import * as SC from './styles'
import type { SwitchProps } from './types'

const defaultProps: Partial<SwitchProps> = {
    size: 'sm',
    radius: 'xl'
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
    ({ size = 'md', color, checked, disabled: _disabled, defaultChecked, onChange, ...rest }, ref) => {
        const { disabled } = useComponentConfig('switch', { disabled: _disabled })
        const [_value, handleChange] = useUncontrolled({ value: checked, defaultValue: defaultChecked ?? false })
        const id = useId()

        const trackCss = useMemo(() => {
            return {
                backgroundColor: _value ? color ?? '$colorPrimary' : '$colorGray300'
            }
        }, [_value, color])

        const handleCheckedChange = useCallback(
            (ev: ChangeEvent<HTMLInputElement>) => {
                handleChange(ev.target.checked)

                onChange?.(ev)
            },
            [handleChange, onChange]
        )

        return (
            <SC.SwitchWrapper {...rest} ref={ref}>
                <SC.SwitchInput disabled={disabled} type="checkbox" id={id} checked={_value} onChange={handleCheckedChange} />
                <SC.SwitchTrack disabled={disabled} htmlFor={id} css={trackCss} size={size}>
                    <SC.SwitchThumb size={size} checked={_value} />
                </SC.SwitchTrack>
            </SC.SwitchWrapper>
        )
    }
)
