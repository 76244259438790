import type { DataSourceAbstract } from '@lighthouse/core'
import { type VariableADTvalue } from '@lighthouse/core'
import { Node } from '@tiptap/core'
import type { Node as ProseMirrorNode } from '@tiptap/pm/model'
import { ReactNodeViewRenderer } from '@tiptap/react'

import type { VariableSelectDropDownProps } from '../../'
import { VariableBlockComponent } from './Component'

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        variable: {
            setVariable: (path: VariableADTvalue) => ReturnType
        }
    }
}

export interface VariableExtensionOptions
    extends Pick<
        VariableSelectDropDownProps,
        'options' | 'userOption' | 'systemOption' | 'dataSourceOption' | 'viewOption' | 'enablePageLink'
    > {
    renderLabel: (node: ProseMirrorNode) => string
    dataSources: DataSourceAbstract[]
}

export const VariableBlock = Node.create<VariableExtensionOptions>({
    name: 'variable',

    group: 'inline',

    inline: true,

    selectable: false,

    atom: true,

    addOptions() {
        return {
            renderLabel() {
                return ''
            },
            dataSources: []
        }
    },

    addCommands() {
        return {
            setVariable:
                value =>
                ({ commands }) => {
                    return commands.insertContent({ type: this.name, attrs: { value } })
                }
        }
    },

    addAttributes() {
        return {
            value: {
                default: null,
                parseHTML: el => {
                    const value = el.getAttribute('data-value')
                    if (!value) {
                        return null
                    }
                    try {
                        return JSON.parse(value)
                    } catch {
                        return null
                    }
                },
                renderHTML: attr => {
                    if (!attr.value) {
                        return {}
                    }
                    return {
                        'data-value': JSON.stringify(attr.value)
                    }
                }
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'variable',
                getAttrs: node => {
                    if (!(node instanceof HTMLElement)) {
                        return false
                    }
                    const value = node.getAttribute('data-value')
                    if (!value) {
                        return false
                    }

                    try {
                        return { value: JSON.parse(value) }
                    } catch {
                        return null
                    }
                }
            }
        ]
    },

    renderHTML({ HTMLAttributes, node }) {
        return ['variable', HTMLAttributes, this.options.renderLabel(node)]
    },

    renderText({ node }) {
        return this.options.renderLabel(node)
    },

    addNodeView() {
        return ReactNodeViewRenderer(VariableBlockComponent)
    }
})
