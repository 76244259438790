import { IconFont } from '@byecode/ui'
import type { DataSourceAbstract, RecordLikeProtocol } from '@lighthouse/core'
import { find, reduce } from 'rambda'
import React, { useMemo } from 'react'

import { CURRENT_USER, USER_DATASOURCE } from '../../../../constants'
import { useApplicationContext } from '../../../../contexts'
import { SelectPlaceHolder } from '../../SelectPlaceHolder'
import * as CM from '../commonStyles'
import { getConvertValue } from './help'
import { RelationNormalTag } from './RelationNormalTag'
import * as SC from './styles'

interface RelationRecordPreviewProps {
    readOnly?: boolean
    value: string[]
    dsId: string
    dataSourceList: DataSourceAbstract[]
    records: RecordLikeProtocol[]
    userRecord?: RecordLikeProtocol
    fieldPointer: string
    placeholder?: string
    opened?: boolean

    onClick?: React.MouseEventHandler<HTMLButtonElement>
    onRemove: (value: string) => void
}

export const RelationRecordPreview = React.forwardRef<HTMLButtonElement, RelationRecordPreviewProps>(
    ({ readOnly, dataSourceList, fieldPointer, placeholder, records, value, dsId, userRecord, onClick, onRemove }, ref) => {
        const [dataSource, userDataSource] = useMemo(
            () => [find(item => item.id === dsId, dataSourceList), find(item => item.id === USER_DATASOURCE, dataSourceList)],
            [dataSourceList, dsId]
        )
        const { schema, viewOptions } = dataSource ?? {}
        const { personOptions, roleOptions, departmentOptions } = useApplicationContext()

        const [tagList, userTag] = useMemo(() => {
            return reduce<string, [string[], string | null]>(
                ([tagList, CurrentUser], curVal) => {
                    if (curVal.includes(CURRENT_USER.userId)) {
                        return [tagList, curVal]
                    }
                    return [[...tagList, curVal], CurrentUser]
                },
                [[], null],
                value
            )
        }, [value])

        const relationTagsEle = useMemo(() => {
            if (!dataSource || !schema || !schema[fieldPointer] || !fieldPointer) {
                return null
            }
            return tagList.map(recordId => {
                const cellValue = getConvertValue({
                    recordId,
                    fieldId: fieldPointer,
                    records,
                    schema,
                    personOptions,
                    roleOptions,
                    departmentOptions,
                    isReallyNumber: false
                })
                return (
                    cellValue && (
                        <RelationNormalTag enableDelete={!readOnly} key={recordId} title={cellValue} onRemove={() => onRemove(recordId)} />
                    )
                )
            })
        }, [dataSource, schema, fieldPointer, tagList, records, personOptions, roleOptions, departmentOptions, readOnly, onRemove])

        const userTagEle = useMemo(() => {
            if (!userDataSource || !userRecord || !userTag) {
                return null
            }
            const fieldPointer = userTag.replace(`${CURRENT_USER.userId}-`, '')
            const cellValue = getConvertValue({
                recordId: userRecord?.id,
                fieldId: fieldPointer,
                records: [userRecord],
                schema: userDataSource.schema,
                personOptions,
                roleOptions,
                departmentOptions,
                isReallyNumber: false
            })
            return cellValue && <RelationNormalTag enableDelete={!readOnly} title={cellValue} onRemove={() => onRemove(userTag)} />
        }, [departmentOptions, onRemove, personOptions, readOnly, roleOptions, userDataSource, userRecord, userTag])

        return (
            <SC.Relation type="button" onClick={onClick} isWrap ref={ref}>
                <SC.TagContain>
                    {userTagEle}
                    {relationTagsEle}
                    {value.length === 0 && <SelectPlaceHolder style={{ lineHeight: '28px', height: '100%' }} label={placeholder} />}
                </SC.TagContain>
                <CM.IconWrapper style={{height: 28}}>{!readOnly && <IconFont type="ArrowDownSmall" color="var(--color-gray-400)" size={16} />}</CM.IconWrapper>
            </SC.Relation>
        )
    }
)
