import type { FieldADTValue, FieldProtocol, SimpleTextValue } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'

import { Textarea } from '../CellInputField'
import type { EditorItemSize } from './types'

interface SimpleTextValueEditorProps {
    recordId: string
    data?: SimpleTextValue
    field: FieldProtocol
    placeholder?: string
    autoFocus?: boolean
    style?: React.CSSProperties
    size?: EditorItemSize
    editable?: boolean
    focusOutLineColor?: string
    enableBackgroundColor?: boolean
    onCellChange?: (recordId: string, field: FieldADTValue) => Promise<boolean>
}

export const SimpleTextValueEditor: React.FC<SimpleTextValueEditorProps> = ({
    recordId,
    data,
    field,
    placeholder,
    autoFocus,
    focusOutLineColor,
    enableBackgroundColor,
    style,
    size,
    editable,
    onCellChange
}) => {
    const textSize = useMemo(() => (size === 'large' ? '32px' : '14px'), [size])
    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
            onCellChange?.(recordId, { ...field, value: ev.target.value } as FieldADTValue)
        },
        [field, onCellChange, recordId]
    )

    const handleFocus = useCallback((e: React.FocusEvent<HTMLTextAreaElement>) => {
        const len = e.target.value.length
         // 解决safari 15.1 出现自动聚焦后 光标在首位的问题
         setTimeout(() => {
            e.target.setSelectionRange(len, len)
        }, 0);
    }, [])

    return (
        <div>
            <Textarea
                key={`${recordId}${field.id}`}
                autoFocus={autoFocus}
                autoSize
                textSize={textSize}
                highlightable={editable}
                style={style}
                bordered={false}
                defaultValue={data}
                enableBackgroundColor={enableBackgroundColor}
                placeholder={placeholder}
                focusOutLineColor={focusOutLineColor}
                onFocus={handleFocus}
                onTextareaChange={handleChange}
            />
        </div>
    )
}
