import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { Box } from '../Box'

interface DividerStylesParams {
    color?: string
    label?: React.ReactNode
    solidWidth?: number
    labelPosition?: 'left' | 'center' | 'right'
    direction?: 'horizontal' | 'vertical'
}

const useStyles = createStyles((params: DividerStylesParams) => {
    const { color, solidWidth = 1 } = params
    return {
        root: css({
            '&:where([data-orientation="horizontal"])': {
                borderTop: `${solidWidth}px solid ${color ?? '$colorGray200'}`
            },

            '&:where([data-orientation="vertical"])': {
                borderInlineStart: `${solidWidth}px solid ${color ?? '$colorGray200'}`,
                height: 'auto',
                alignSelf: 'stretch'
            },

            '&:where([data-with-label = true])': {
                border: 0
            }
        }),
        label: css({
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            color: color ?? '$colorBlack',
            whiteSpace: 'nowrap',
            '&:where([data-position="left"])::before': {
                display: 'none'
            },
            '&:where([data-position="right"])::after': {
                display: 'none'
            },
            '&::before': {
                content: '',
                flex: 1,
                height: 1,
                borderTop: `${solidWidth}px solid ${color ?? '$colorGray200'}`,
                marginInlineEnd: 8
            },
            '&::after': {
                content: '',
                flex: 1,
                height: 1,
                borderTop: `${solidWidth}px solid ${color ?? '$colorGray200'}`,
                marginInlineStart: 8
            }
        })
    }
})

export interface DividerProps
    extends StyleComponentProps<Selectors<typeof useStyles>>,
        React.ComponentPropsWithoutRef<'div'>,
        DividerStylesParams {}

export const Divider = forwardRef<HTMLDivElement, DividerProps>((props, ref) => {
    const { color, direction = 'horizontal', label, labelPosition, solidWidth, classNames, styles, className, unstyled, ...rest } = props
    const { classes } = useStyles({ color, labelPosition, direction, label, solidWidth }, { name: 'divider', classNames, styles, unstyled })

    return (
        <Box ref={ref} data-orientation={direction} data-with-label={!!label} className={clsx(classes.root, className)} {...rest}>
            {label && (
                <Box component="span" data-position={labelPosition} className={classes.label}>
                    {label}
                </Box>
            )}
        </Box>
    )
})
