import { getAssetUrl } from '@lighthouse/assets'
import { NoFound } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

const SCxContainer = styled.div`
    height: 100%;
`

export default function TrafficLimit() {
    return (
        <SCxContainer>
            <NoFound image={getAssetUrl('common', 'byecode-logo.png')} title='无法访问应用' desc="因为访问流量达到上限，请联系管理员" btnText='' />
        </SCxContainer>
    )
}
