import { backgroundTransitionOnClick, Button, IconFont, Popover, Text } from '@byecode/ui'
import type { RecordLikeProtocol } from '@lighthouse/core'
import { type ActionItem, type ButtonAction, type RichTextContentProtocol, ButtonPattern } from '@lighthouse/core'
import { ActionButton, ThemeButton, useActionRunningLoadings, VIEW_CELL_DATA_KEY } from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

const SCxRecordOperatorWrapper = styled.div`
    display: flex;
    align-items: center;
`

const SCxTableOperator = styled(Button)<{ open?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: ${({ open }) => open && 'var(--color-gray-200)'};
`

const SCxList = styled.div`
    /* padding: 8px 0; */
    max-height: 200px;
    overflow: hidden auto;
`

const SCxItem = styled(Text)`
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    font-size: var(--font-size-normal);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;

    ${backgroundTransitionOnClick}
`

const SCxItemIcon = styled(IconFont)`
    margin-right: 8px;
`

const SCxShownActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 8px;
`

interface RecordOperatorProps {
    blockId: string
    recordId: string
    record: RecordLikeProtocol
    dsId: string
    config: {
        canEdit: boolean
        canDelete: boolean
    }
    actions?: ActionItem[]
    customized?: boolean
    isMobile?: boolean
    preShowCount?: number
    onRecordEdit?: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol) => string
}

interface EditAndDeleteAction {
    id: 'edit' | 'delete'
    name: string
}

export const RecordOperator: React.FC<RecordOperatorProps> = ({
    blockId,
    recordId,
    record,
    dsId,
    config,
    actions,
    customized,
    isMobile,
    preShowCount,
    onRecordEdit,
    onRecordDelete,
    onActionTrigger,
    onRenderButtonTitle
}) => {
    const [operatorOpened, setOperatorOpened] = useState(false)
    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()

    const isShowOperator = useMemo(
        () => (customized ? actions?.length : Object.entries(config).some(item => item[1])),
        [actions?.length, config, customized]
    )

    const shownCount = isMobile ? 1 : preShowCount
    const [shownActions, moreActions] = useMemo(() => {
        if (customized && actions) {
            if (!preShowCount) {
                return [[], actions]
            }
            return [actions.slice(0, shownCount), actions.slice(shownCount)]
        }
        return [[], []]
    }, [actions, customized, preShowCount, shownCount])

    const [shownEditAndDeleteActions, moreEditAndDeleteActions] = useMemo(() => {
        const editAndDeleteActions = [
            config.canEdit && { id: 'edit', name: '编辑' },
            config.canDelete && { id: 'delete', name: '删除' }
        ].filter(Boolean) as EditAndDeleteAction[]
        if (!preShowCount) {
            return [[], editAndDeleteActions]
        }
        return [editAndDeleteActions.slice(0, shownCount), editAndDeleteActions.slice(shownCount)]
    }, [config.canDelete, config.canEdit, preShowCount, shownCount])

    const handleOperatorOpen = useCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.stopPropagation()
        // setOperatorOpened(true)
    }, [])

    useUpdateEffect(() => {
        const currentRecordKey = `[${VIEW_CELL_DATA_KEY}='${blockId}&${recordId}']`
        const recordBlockDom = document.querySelector(currentRecordKey)
        if (!recordBlockDom) {
            return
        }
        if (operatorOpened) {
            recordBlockDom.classList.add('record-active')
            return
        }
        recordBlockDom.classList.remove('record-active')
    }, [operatorOpened])

    if (!isShowOperator) {
        return null
    }

    return (
        <SCxRecordOperatorWrapper>
            <SCxShownActionsWrapper>
                {customized
                    ? shownActions.map(item => (
                          <ActionButton
                              key={item.id}
                              radius="8"
                              // loading={loadings[item.id]}
                              disabled={loadings[item.id]}
                              config={{ ...item }}
                              onClick={ev => {
                                  ev.stopPropagation()
                                  // startLoading(item.id)
                                  handleActionTriggerWithLoading({
                                      type: 'click',
                                      action: item.action,
                                      id: item.id,
                                      record,
                                      trigger: onActionTrigger
                                  })
                              }}
                              onRenderTitle={onRenderButtonTitle}
                          />
                      ))
                    : shownEditAndDeleteActions.map((item, index) => (
                          <ThemeButton
                              key={item.id}
                              title={item.name}
                              pattern={index === 0 ? ButtonPattern.primary : ButtonPattern.secondary}
                              styles={{
                                  container: {
                                      margin: 0,
                                      borderRadius: 8
                                  },
                                  wrapper: {
                                      height: 32
                                  }
                              }}
                              fillWay={isMobile ? 'contain' : 'auto'}
                              triggerEvent="click"
                              onClick={ev => {
                                  ev.stopPropagation()
                                  item.id === 'edit' ? onRecordEdit?.(recordId) : onRecordDelete?.(dsId, [recordId])
                                  // startLoading(item.id)
                                  // onActionTrigger?.(item.action)
                              }}
                          />
                      ))}
            </SCxShownActionsWrapper>
            {(moreEditAndDeleteActions.length > 0 || moreActions.length > 0) && (
                <Popover width={180} opened={operatorOpened} onChange={setOperatorOpened} position="bottom-end" withinPortal>
                    <Popover.Target>
                        <SCxTableOperator
                            open={operatorOpened}
                            type="text"
                            size="sm"
                            icon={<IconFont type="DotsThree" />}
                            onClick={handleOperatorOpen}
                        />
                    </Popover.Target>

                    <Popover.Dropdown>
                        {customized && moreActions.length > 0 && (
                            <SCxList onClick={ev => ev.stopPropagation()}>
                                {moreActions.map(item => (
                                    <SCxItem
                                        key={item.id}
                                        onClick={() => {
                                            setOperatorOpened(false)
                                            onActionTrigger?.(item.action)
                                        }}
                                        lineClamp={1}
                                        whiteSpace="nowrap"
                                    >
                                        {['icon', 'icon-name'].includes(item.showType) && item.icon && <SCxItemIcon type={item.icon} />}
                                        {['name', 'icon-name'].includes(item.showType) && onRenderButtonTitle(item.name)}
                                    </SCxItem>
                                ))}
                            </SCxList>
                        )}
                        {!customized && moreEditAndDeleteActions.length > 0 && (
                            <SCxList onClick={ev => ev.stopPropagation()}>
                                {moreEditAndDeleteActions.map(item => (
                                    <SCxItem
                                        key={item.id}
                                        lineClamp={1}
                                        whiteSpace="nowrap"
                                        onClick={() => {
                                            setOperatorOpened(false)
                                            item.id === 'edit' ? onRecordEdit?.(recordId) : onRecordDelete?.(dsId, [recordId])
                                        }}
                                    >
                                        <SCxItemIcon type={item.id === 'edit' ? 'PencilSimple' : 'Trash'} />
                                        {item.name}
                                    </SCxItem>
                                ))}
                            </SCxList>
                        )}
                    </Popover.Dropdown>
                </Popover>
            )}
        </SCxRecordOperatorWrapper>
    )
}
