import React from 'react'
import styled from 'styled-components'

import { useSortableMonitor } from '../Sortable/Context'

const Container = styled.div`
    position: absolute;
    transition: opacity 0.2s;
    opacity: 0;
    &[data-highlight='true'] {
        opacity: 1;
        background-color: #5551ff1f;
    }
`

interface PaddingProps extends React.ComponentPropsWithoutRef<'div'> {
    containerId?: string
}
export const Padding = ({ containerId = 'root', style, ...props }: PaddingProps) => {
    const { activeId } = useSortableMonitor()

    const isDragging = !!activeId

    return <Container style={{ ...style, pointerEvents: isDragging ? undefined : 'none' }} {...props} />
}
