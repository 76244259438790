import { Empty, IconFont, tinyButtons } from '@byecode/ui'
import { ButtonPattern } from '@lighthouse/core'
import React from 'react'
import styled from 'styled-components'

import type { MessageListData, MessageType } from '../../types'
import type { NotificationStatusProps } from '../NotificationDetail/NotificationMoment'
import { NotificationStatus } from '../NotificationDetail/NotificationMoment'
import { ThemeButton } from '../ThemeButton'

const auditStatusMap = {
    ACCEPT: '已同意',
    REJECT: '已拒绝'
}

const approveResultMap: Record<MessageType, string> = {
    CARBON_COPY: '',
    APPROVAL: '',
    APPROVE_PASSED: '已通过',
    APPROVE_REFUSED: '已拒绝',
    STATION_MESSAGE: ''
}

const approveResultTextMap: Record<MessageType, NotificationStatusProps['status']> = {
    CARBON_COPY: 'error',
    APPROVAL: 'error',
    APPROVE_PASSED: 'success',
    APPROVE_REFUSED: 'error',
    STATION_MESSAGE: 'success'
}

interface NotificationListProps {
    data?: MessageListData[]
    onNavigateTo?: (id: string, auditStatus?: 'agree' | 'reject') => void
}

const SCxMessageListWrapper = styled.div`
    max-height: 100%;
    overflow-y: auto;

    ${tinyButtons}
`

const SCxMessageWrapper = styled.div`
    display: flex;
    padding: 20px 12px;
    font-size: var(--font-size-normal);
    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1px solid var(--color-gray-200);
    }
`

const SCxMessageIconWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin-right: 8px;
    border-radius: 6px;
    border: 1px solid var(--color-gray-200);
`

const SCxMessageIcon = styled(IconFont)`
    color: var(--color-main);
    font-size: 20px;
`

const SCxMessageContentWrapper = styled.div`
    flex: 1;
`

const SCxMessageContentTitle = styled.div`
    margin-bottom: 4px;
`

const SCxMessageContentDescription = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
`

const SCxMessageContentDetail = styled.div`
    border-radius: 4px;
    width: 100%;
    margin: 12px 0;
    padding: 10px;
    background-color: var(--color-gray-100);
`

const SCxMessageContentDetailItem = styled.div`
    display: flex;

    &:not(:last-child) {
        margin-bottom: 10px;
    }
`

const SCxMessageContentDetailItemLabel = styled.div`
    width: 90px;
    margin-right: 12px;
    color: var(--color-gray-500);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const SCxMessageContentDetailItemValue = styled.div``

const SCxMessageContentActionWrapper = styled.div`
    display: flex;
`

export const NotificationList: React.FC<NotificationListProps> = ({ data, onNavigateTo }) => {
    if (data?.length === 0) {
        return (
            <Empty
                style={{ padding: '20px 0' }}
                description="暂无消息"
                icon={<IconFont type="Bell" size={24} color="var(--color-gray-500)" style={{ marginBottom: 8 }} />}
            />
        )
    }

    const renderMessageContent = (data: MessageListData) => {
        if (data.type === 'STATION_MESSAGE') {
            const { id, createdTime, appName, title, content } = data

            return (
                <SCxMessageWrapper key={id} onClick={() => onNavigateTo?.(id)}>
                    <SCxMessageIconWrapper>
                        <SCxMessageIcon type="Bell" style={{ color: 'var(--color-black)' }} />
                    </SCxMessageIconWrapper>
                    <SCxMessageContentWrapper>
                        <SCxMessageContentTitle>{title}</SCxMessageContentTitle>
                        <SCxMessageContentDescription>
                            {createdTime} · 来自 {appName}
                        </SCxMessageContentDescription>

                        <SCxMessageContentDetail>
                            <SCxMessageContentDetailItem>
                                <SCxMessageContentDetailItemLabel>{content}</SCxMessageContentDetailItemLabel>
                            </SCxMessageContentDetailItem>
                        </SCxMessageContentDetail>
                    </SCxMessageContentWrapper>
                </SCxMessageWrapper>
            )
        }

        const { id, type, auditStatus, username, createdTime, appName, values } = data
        const isApproval = type === 'APPROVAL'
        const isCC = type === 'CARBON_COPY'
        const isResult = type.includes('APPROVE_')
        return (
            <SCxMessageWrapper key={id} onClick={() => onNavigateTo?.(id)}>
                <SCxMessageIconWrapper>
                    <SCxMessageIcon type="Approval" style={{ color: isApproval ? 'var(--color-main)' : 'var(--color-black)' }} />
                    {isResult && (
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                borderRadius: '50%',
                                border: '1px solid var(--color-white)'
                            }}
                        >
                            <NotificationStatus status={approveResultTextMap[type]} />
                        </div>
                    )}
                </SCxMessageIconWrapper>
                <SCxMessageContentWrapper>
                    <SCxMessageContentTitle>
                        {isCC && <>抄送给我：</>}
                        {isResult && (
                            <>
                                您发起的审批 <span style={{ fontWeight: 'var(--font-weight-bold)' }}>{approveResultMap[type]}</span>
                            </>
                        )}
                        {!isResult && (
                            <>
                                <span style={{ fontWeight: 'var(--font-weight-bold)', marginRight: 4 }}>{username}</span>
                                发起了申请
                            </>
                        )}
                    </SCxMessageContentTitle>
                    <SCxMessageContentDescription>
                        {createdTime} · 来自 {appName}
                    </SCxMessageContentDescription>
                    {!isResult && (
                        <SCxMessageContentDetail>
                            {values?.slice(0, 3)?.map(({ fieldName, value }) => (
                                <SCxMessageContentDetailItem key={fieldName}>
                                    <SCxMessageContentDetailItemLabel>{fieldName}</SCxMessageContentDetailItemLabel>
                                    <SCxMessageContentDetailItemValue>{value}</SCxMessageContentDetailItemValue>
                                </SCxMessageContentDetailItem>
                            ))}
                            <SCxMessageContentDetailItem>
                                <SCxMessageContentDetailItemLabel>查看更多</SCxMessageContentDetailItemLabel>
                            </SCxMessageContentDetailItem>
                        </SCxMessageContentDetail>
                    )}
                    {isApproval && (
                        <SCxMessageContentActionWrapper onClick={ev => ev.stopPropagation()}>
                            {auditStatus ? (
                                auditStatusMap[auditStatus]
                            ) : (
                                <>
                                    <ThemeButton
                                        pattern={ButtonPattern.secondary}
                                        showType="name"
                                        styles={{
                                            container: {
                                                width: 'auto'
                                            },
                                            wrapper: {
                                                width: 'auto',
                                                height: 32,
                                                padding: '12px 26px'
                                            }
                                        }}
                                        triggerEvent="click"
                                        onClick={() => onNavigateTo?.(id, 'reject')}
                                        title="不同意"
                                    />
                                    <ThemeButton
                                        pattern={ButtonPattern.primary}
                                        showType="name"
                                        isSubmit
                                        styles={{
                                            container: {
                                                width: 'auto'
                                            },
                                            wrapper: {
                                                width: 'auto',
                                                height: 32,
                                                padding: '12px 26px'
                                            }
                                        }}
                                        triggerEvent="click"
                                        onClick={() => onNavigateTo?.(id, 'agree')}
                                        title="同意"
                                    />
                                </>
                            )}
                        </SCxMessageContentActionWrapper>
                    )}
                </SCxMessageContentWrapper>
            </SCxMessageWrapper>
        )
    }

    return <SCxMessageListWrapper>{data?.map(d => renderMessageContent(d))}</SCxMessageListWrapper>
}
