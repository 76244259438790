import { styled } from '../../theme/stitches.config'

export const WRAPPER_PADDING = 4

export const SegmentedControlWrapper = styled('div', {
    position: 'relative',
    display: 'inline-flex',
    padding: 2,
    borderRadius: 8,
    backgroundColor: '$colorGray100',

    variants: {
        fullWidth: {
            true: {
                width: '100%'
            }
        },
        size: {
            xs: {
                borderRadius: 4
            },
            sm: {
                borderRadius: 6
            },
            md: {
                borderRadius: 8
            },
            lg: {
                borderRadius: 10
            }
        }
    }
})

export const SegmentedControlItemIndicator = styled('span', {
    position: 'absolute',
    height: '100%',
    width: 100,
    top: 0,
    left: 0,
    borderRadius: 6,
    backgroundColor: '$colorWhite',
    boxShadow: '0px 1px 4px 0px rgba(16, 24, 40, 0.1)',
    // transition: 'all 0.2s ease',
    // transform: 'translateX(20px)',

    variants: {
        size: {
            xs: {
                borderRadius: 3
            },
            sm: {
                borderRadius: 5
            },
            md: {
                borderRadius: 7
            },
            lg: {
                borderRadius: 8
            }
        }
    }
})

export const SegmentedControlItem = styled('div', {
    position: 'relative',
    overflow: 'hidden'
})

export const SegmentedControlItemInput = styled('input', {
    width: 0,
    height: 0,
    overflow: 'hidden',
    position: 'absolute',
    visibility: 'hidden'
})

export const SegmentedControlItemLabel = styled('label', {
    width: '100%',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    userSelect: 'none',
    color: '$colorGray500',
    border: '0.5px solid transparent',
    cursor: 'pointer',
    display: 'flex',
    overflow: 'hidden',
    // @ 灿白
    lineHeight: 1,

    variants: {
        size: {
            xs: {
                height: 24,
                borderRadius: 2
            },
            sm: {
                height: 26,
                borderRadius: 4
            },
            md: {
                height: 28,
                borderRadius: 6
            },
            lg: {
                height: 32,
                borderRadius: 10
            }
        },
        fullWidth: {
            true: {
                display: 'flex'
            }
        },
        checked: {
            true: {
                color: '$colorGray900'
            },
            false: {
                color: '$colorGray500',
                '&:hover': {
                    backgroundColor: '$colorGray200'
                }
            }
        },
        disabled: {
            true: {
                color: '$colorGray300',
                cursor: 'not-allowed'
            }
        }
    },
    compoundVariants: [
        {
            fullWidth: 'false',
            size: 'xs',
            css: {
                padding: '0 8px'
            }
        },
        {
            fullWidth: 'false',
            size: 'sm',
            css: {
                padding: '0 8px'
            }
        },
        {
            fullWidth: 'false',
            size: 'md',
            css: {
                padding: '0 12px'
            }
        },
        {
            fullWidth: 'false',
            size: 'lg',
            css: {
                padding: '0 16px'
            }
        }
    ]
})

export const SegmentedControlItemContent = styled('div', {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4
})

export const SegmentedControlItemLabelWrapper = styled('span', { overflow: 'hidden', lineHeight: 1, textOverflow: 'ellipsis' })
