import React, { forwardRef, useCallback } from 'react'

import { useComponentConfig } from '../../hooks/useComponentConfig'
import { Loading } from '../Loading'
import { StyledButton } from './styles'
import type { ButtonProps } from './types'

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            type = 'default',
            className,
            style,
            size = 'md',
            icon,
            danger,
            ghost,
            loading,
            disabled: _disabled,
            block,
            children,
            htmlType = 'button',
            radius,
            suffix,
            highlighting,
            onClick,
            ...restProps
        },
        ref
    ) => {
        const { disabled } = useComponentConfig('button', { disabled: _disabled })
        const buttonIcon = (loading && <Loading outlined size={14} />) || icon
        const buttonDisabled = disabled || loading

        const existsIcon = !!buttonIcon
        const onlyIcon = existsIcon && !children

        const handleClick = useCallback(
            (e: React.MouseEvent<HTMLButtonElement>) => {
                if (disabled) {
                    return
                }
                onClick?.(e)
            },
            [disabled, onClick]
        )

        return (
            <StyledButton
                {...restProps}
                style={style}
                className={className}
                ref={ref}
                buttonType={type}
                type={htmlType}
                size={size}
                block={block}
                danger={danger}
                ghost={ghost}
                highlighting={highlighting}
                disabled={buttonDisabled}
                icon={onlyIcon}
                onClick={handleClick}
                css={{ borderRadius: radius }}
            >
                {buttonIcon}
                {children}
                {suffix}
            </StyledButton>
        )
    }
)
