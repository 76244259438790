import { clone } from 'rambda'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { directionMap } from '../../constant'
import type { RelativeOption } from '../../types'
import { RelationNormalTag } from './RelationNormalTag'

interface RelationTagPreviewProps {
    value?: string[]
    options: RelativeOption[]
    direction?: 'vertical' | 'horizontal'
    isMultiple?: boolean
    disabled?: boolean
    onChange: (v: string[]) => void
}

const SCxGroup = styled.div<Pick<React.CSSProperties, 'flexDirection'>>`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${({ flexDirection }) => flexDirection};
    align-items: ${({ flexDirection }) => (flexDirection === 'column' ? 'flex-start' : 'center')};
    gap: 12px;
`
export const RelationTagPreview: React.FunctionComponent<RelationTagPreviewProps> = ({
    value = [],
    options,
    direction = 'horizontal',
    isMultiple,
    disabled,
    onChange
}) => {
    const handleChange = useCallback(
        (v: string[]) => {
            onChange(isMultiple ? v : clone(v).slice(-1))
        },
        [isMultiple, onChange]
    )

    return (
        <SCxGroup flexDirection={directionMap[direction]}>
            {options.map(option => {
                const isActive = value?.includes(option.id)
                return (
                    <RelationNormalTag
                        key={option.value}
                        title={option.label}
                        isActive={isActive}
                        enableDelete={false}
                        disabled={option.disabled || disabled}
                        onClick={() =>
                            !option.disabled && !disabled && handleChange(isActive ? value?.filter(v => v !== option.id) : [...value, option.id])
                        }
                    />
                )
            })}
        </SCxGroup>
    )
}
