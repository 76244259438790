import { WechatPayStatus, WechatPayType } from '@lighthouse/core'
import { getWechatPayType } from '@lighthouse/shared'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMount } from 'react-use'
import styled from 'styled-components'
import wx from 'weixin-js-sdk'

import { wechatPayEmitter } from '@/actionsEngine/event'
import * as srv from '@/services'
import type { WechatPaySearch } from '@/services/types'
import { initWxSDK } from '@/utils/wechat'

import { Pay } from './Pay'
import { QrCode } from './QrCode'

interface AppAuthRedirectProps {}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-gray-50);
`

const payKeyParams: (keyof WechatPaySearch)[] = [
    'appId',
    'timeStamp',
    'nonceStr',
    'paySign',
    'packageVal',
    'signType',
    'pageId',
    'redirect',
    'outTradeNo',
    'codeUrl',
    'autoRedirectAfterPayment'
]

const WechatPay: React.FunctionComponent<AppAuthRedirectProps> = props => {
    const [isCheck, setIsCheck] = useState(false)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const wechatPayParams = useMemo(
        () =>
            Object.fromEntries(
                payKeyParams.map(key => {
                    const value = searchParams.get(key) ?? ''
                    return [key, key === 'autoRedirectAfterPayment' ? Number(value) : value]
                })
            ) as WechatPaySearch,
        [searchParams]
    )

    const weChatPayType = useMemo(() => getWechatPayType(), [])

    const handleRedirectUrl = useCallback(
        (weChatStatus: WechatPayStatus) => {
            const { redirect, outTradeNo, autoRedirectAfterPayment } = wechatPayParams
            if (!autoRedirectAfterPayment) {
                wechatPayEmitter.emit(`wechatPayCheck-${outTradeNo}`, weChatStatus)
                return
            }
            const newUrl = new URL(decodeURIComponent(redirect))
            redirect
                ? navigate(`${newUrl.pathname}?weChatStatus=${weChatStatus}&outTradeNo=${outTradeNo}`, {
                      replace: true
                  })
                : navigate('/')
        },
        [navigate, wechatPayParams]
    )

    // 发起jsapi支付
    const handleJsapiPay = useCallback(() => {
        const { appId, timeStamp, nonceStr, paySign, packageVal, signType, outTradeNo } = wechatPayParams
        if (!appId || !timeStamp || !nonceStr || !paySign || !packageVal || !signType) {
            return
        }
        const { VITE_SENTRY_ENVIRONMENT } = import.meta.env
        // @TODO: 暂时处理
        const isLocalDev = false
        wx.config({
            debug: isLocalDev,
            appId,
            timestamp: Number(timeStamp),
            nonceStr,
            signature: paySign,
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
        })
        wx.chooseWXPay({
            timestamp: Number(timeStamp), // 支付签名时间戳，注意微信jsSdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr, // 支付签名随机串，不长于 32 位
            package: packageVal, // 统一支付接口返回的prepay\_id参数值，提交格式如：prepay\_id=\\*\\*\\*）
            signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign, // 支付签名
            success: res => {
                // handleRedirectUrl(WechatPayStatus.SUCCESS)
                // wechatPayEmitter.emit(`wechatPayCheck-${outTradeNo}`, )
                initWxSDK()
            },
            fail: err => {
                // wechatPayEmitter.emit(`wechatPayCheck-${outTradeNo}`, WechatPayStatus.CLOSED)
                initWxSDK()
            }
        })
    }, [wechatPayParams])

    // 检查非jsapi微信支付，是否支付成功
    const checkWechatPayStatus = useCallback(async () => {
        const { pageId, outTradeNo } = wechatPayParams
        if (!pageId || !outTradeNo) {
            return null
        }
        const code = await srv.getWechatPayStatus({ outTradeNo, pageId })
        if (code === WechatPayStatus.SUCCESS) {
            handleRedirectUrl(WechatPayStatus.SUCCESS)
            // wechatPayEmitter.emit(`wechatPayCheck-${outTradeNo}`, WechatPayStatus.CLOSED)
        }
    }, [handleRedirectUrl, wechatPayParams])

    useMount(() => {
        if (weChatPayType === WechatPayType.JSAPI) {
            handleJsapiPay()
        }
        setIsCheck(true)
    })

    // 轮询检查非jsapi微信支付
    useEffect(() => {
        if (!isCheck) {
            return
        }
        const timer = setInterval(() => {
            checkWechatPayStatus()
        }, 3000)

        return () => {
            clearInterval(timer)
        }
    }, [checkWechatPayStatus, handleRedirectUrl, isCheck, weChatPayType])

    const ele = useMemo(() => {
        if (weChatPayType === WechatPayType.NATIVE && wechatPayParams.codeUrl) {
            return <QrCode url={wechatPayParams.codeUrl} />
        }
        return <Pay />
    }, [weChatPayType, wechatPayParams.codeUrl])

    return <SCxContainer>{ele}</SCxContainer>
}

export default WechatPay
