import { Button } from '@byecode/ui'
import { Divider, Input, Modal } from '@mantine/core'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { FileItemAction, WithStatusFile } from '../../../types'
import * as CM from './styles'

interface FileItemProps {
    value: WithStatusFile
    target?: string
    options: { label: string; value: FileItemAction }[]
    // onRemove: (id: string) => void
    onUpdate?: (name: string) => void
    // onPreview?: () => void
    handleAction: (action: FileItemAction) => void
    onClose?: () => void
}

const SCxDrawerContainer = styled.div`
    width: 100%;

    ${CM.Item}:not(:first-child) {
        border-top: 1px solid var(--color-gray-200);
    }
`
const SCxFooter = styled.div`
    margin-top: 16px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const FileItem: React.FunctionComponent<FileItemProps> = ({ value, target, options, handleAction, onUpdate, onClose }) => {
    const { uid = '', url, size, name, type, status } = value
    const [nameOpened, setNameOpened] = useState(false)
    const [filename, setFilename] = useState(name)

    const { t } = useTranslation()

    const handleRenameClose = useCallback(() => {
        setNameOpened(false)
        setFilename(name)
    }, [name])

    return (
        <CM.FileItem>
            <SCxDrawerContainer>
                {options.map(({ label, value }) => (
                    <CM.Item onClick={() => (value === 'rename' ? setNameOpened(true) : handleAction(value))} key={value}>
                        {label}
                    </CM.Item>
                ))}
            </SCxDrawerContainer>
            <Divider style={{ borderWidth: 8 }} color="var(--color-gray-100)" />
            <CM.Footer onClick={onClose}>{t('cancel')}</CM.Footer>
            <Modal
                opened={nameOpened}
                styles={{
                    header: {
                        justifyContent: 'center'
                    },
                    close: {
                        display: 'none'
                    }
                }}
                centered
                zIndex={200}
                withinPortal
                target={target}
                onClose={handleRenameClose}
                title={t('rename')}
            >
                <Input
                    value={filename}
                    styles={{
                        input: {
                            minHeight: 40,
                            lineHeight: 40
                        }
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilename(e.currentTarget.value)}
                    rightSection={
                        filename && (
                            <CM.Icon
                                size={12}
                                type="Close"
                                isAction
                                onClick={() => setFilename('')}
                                style={{ backgroundColor: 'var(--color-gray-400)', borderRadius: '100%', padding: 2 }}
                                color="#fff"
                            />
                        )
                    }
                />
                <SCxFooter>
                    <Button style={{ width: '45%' }} onClick={handleRenameClose}>
                        {t('cancel')}
                    </Button>
                    <Button
                        type="primary"
                        size="lg"
                        onClick={() => {
                            setNameOpened(false)
                            onUpdate?.(filename)
                        }}
                        style={{ width: '45%' }}
                    >
                        {t('sure')}
                    </Button>
                </SCxFooter>
            </Modal>
        </CM.FileItem>
    )
}

export default FileItem
