import React from 'react'

export interface TreeContextValue {
    checkable?: boolean

    checkedNodes: string[]
    expandedNodes: string[]

    expandTree: (id: string) => void
    unExpandTree: (id: string) => void
    toggleExpandedNode: (id: string) => void

    checkTree: (id: string) => void
    unCheckTree: (id: string) => void
    isNodeChecked: (id: string) => boolean
    isNodeIndeterminate: (id: string) => boolean
}

const TreeContext = React.createContext<TreeContextValue>({
    expandedNodes: [],
    checkable: false,
    checkedNodes: [],
    expandTree: () => void 0,
    unExpandTree: () => void 0,
    toggleExpandedNode: () => void 0,
    checkTree: () => void 0,
    unCheckTree: () => void 0,
    isNodeChecked: () => false,
    isNodeIndeterminate: () => false
})
export const TreeProvider = TreeContext.Provider
export const useTreeContext = () => React.useContext(TreeContext)
