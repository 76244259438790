import { Checkbox, Radio } from '@byecode/ui'
import { Flex, Text } from '@mantine/core'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { directionMap } from '../../constant'
import type { RelativeOption } from '../../types'

type Option = {
    label: string
    value: string
    color?: string
}

interface RelationCheckboxPreviewProps {
    value?: string[]
    options: RelativeOption[]
    direction?: 'vertical' | 'horizontal'
    isMultiple?: boolean
    disabled?:boolean
    onChange: (v: string[]) => void
}

const SCxGroup = styled.div`
    display: flex;
    gap: 12px;
`

const SCxContainer = styled.div<Pick<React.CSSProperties, 'flexDirection'>>`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${({ flexDirection }) => flexDirection};
    gap: ${({ flexDirection }) => (flexDirection === 'row' ? 12 : 16)}px;
`
export const RelationCheckboxPreview: React.FunctionComponent<RelationCheckboxPreviewProps> = ({
    value = [],
    options,
    direction = 'horizontal',
    isMultiple,
    disabled,
    onChange
}) => {
    const handleChange = useCallback(
        (v: string[]) => {
            onChange(isMultiple ? v : v.slice(-1))
        },
        [isMultiple, onChange]
    )

    return (
        <SCxContainer flexDirection={directionMap[direction]}>
            {options.map(option => {
                const checked = Boolean(value?.includes(option.id))
                return (
                    <SCxGroup
                        key={option.value}
                        onMouseDown={() =>
                            !option.disabled && !disabled && handleChange(checked ? value?.filter(v => v !== option.id) : [...value, option.id])
                        }
                    >
                        {isMultiple ? (
                            <Checkbox
                                checked={checked}
                                color="var(--color-app-main)"
                                disabled={option.disabled || disabled}
                                size="xs"
                                value={option.value}
                                label={option.label}
                            />
                        ) : (
                            <Radio
                                size="xs"
                                styles={{
                                    radio: {
                                        backgroundColor: 'var(--color-white)',
                                        borderRadius: '100%'
                                    },
                                    input: {
                                        width: '16px!important',
                                        height: '16px!important',
                                        borderWidth: checked ? 2 : 1,
                                        borderColor: `${checked ? 'var(--color-app-main)' : 'var(--color-theme-4)'}!important`
                                    },
                                    icon: checked
                                        ? {
                                              width: '8px!important',
                                              height: '8px!important',
                                              left: '4px!important',
                                              top: '4px!important'
                                          }
                                        : {
                                              width: '14px!important',
                                              height: '14px!important',
                                              left: '1px!important',
                                              top: '1px!important'
                                          }
                                }}
                                label={option.label}
                                disabled={option.disabled || disabled}
                                color={checked ? 'var(--color-app-main)' : 'var(--color-theme-2)'}
                                checked={checked}
                            />
                        )}
                    </SCxGroup>
                )
            })}
        </SCxContainer>
    )
}
