import { backgroundTransitionOnClick, IconFont } from '@byecode/ui'
import { ButtonPattern } from '@lighthouse/core'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { throttle } from 'throttle-debounce'

import { ThemeButton } from '../ThemeButton'

interface NotificationApprovalModalProps {
    opinion?: 'agree' | 'reject'
    onClose?: () => void
    onConfirm?: (comments: string) => void
}

const SCxNotificationApprovalModalWrapper = styled.div``

const SCxNotificationApprovalModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    font-weight: var(--font-weight-bold);
    border-bottom: 1px solid var(--color-gray-100);
`

const SCxNotificationApprovalModalTitle = styled.div``

const SCxNotificationApprovalModalCloseButton = styled.div`
    display: flex;
    padding: 4px;
    cursor: pointer;
    border-radius: 4px;

    ${backgroundTransitionOnClick}
`

const SCxNotificationApprovalModalContentWrapper = styled.div`
    padding: 16px 16px 6px 16px;
`

const SCxNotificationApprovalModalContentTextarea = styled.textarea`
    width: 100%;
    height: 150px;
    border-radius: 4px;
    padding: 10px;
    resize: none;
    background-color: var(--color-gray-100);
    font-size: var(--font-size-normal);

    ::placeholder {
        color: var(--color-gray-400);
        line-height: 16px;
    }
`

const SCxNotificationApprovalModalKeywordsWrapper = styled.div`
    padding: 0 16px;
    margin-bottom: 22px;
`

const SCxNotificationApprovalModalKeywordsTip = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-gray-500);
    margin-bottom: 8px;
`

const SCxNotificationApprovalModalKeywords = styled.div`
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
`

const SCxNotificationApprovalModalKeyword = styled.div`
    padding: 4px 6px;
    font-size: var(--font-size-normal);
    border-radius: 4px;
    background-color: var(--color-gray-100);
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;
`

const SCxNotificationApprovalModalFooter = styled.div`
    padding: 0 16px;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
`

const keywordsMap: Record<'agree' | 'reject', string[]> = {
    agree: ['已阅', '收到', '已核对', '情况属实', '确认', '知悉', '辛苦了'],
    reject: ['存在问题', '信息有误']
}

const opinionMap = {
    agree: '同意',
    reject: '不同意'
}

export const NotificationApprovalModal: React.FC<NotificationApprovalModalProps> = ({ opinion = 'agree', onClose, onConfirm }) => {
    const [value, setValue] = useState('')
    const keywords = keywordsMap[opinion]

    const handleConfirm = useMemo(() => {
        return throttle(600, () => {
            onConfirm?.(value)
        })
    }, [onConfirm, value])

    return (
        <SCxNotificationApprovalModalWrapper>
            <SCxNotificationApprovalModalHeader>
                <SCxNotificationApprovalModalTitle>审批意见</SCxNotificationApprovalModalTitle>
                <SCxNotificationApprovalModalCloseButton onClick={onClose}>
                    <IconFont type="Close" />
                </SCxNotificationApprovalModalCloseButton>
            </SCxNotificationApprovalModalHeader>
            <SCxNotificationApprovalModalContentWrapper>
                <SCxNotificationApprovalModalContentTextarea
                    placeholder="请输入审批意见"
                    value={value}
                    onChange={ev => setValue(ev.target.value)}
                />
            </SCxNotificationApprovalModalContentWrapper>
            <SCxNotificationApprovalModalKeywordsWrapper>
                <SCxNotificationApprovalModalKeywordsTip>{opinionMap[opinion]}的常见回复</SCxNotificationApprovalModalKeywordsTip>
                <SCxNotificationApprovalModalKeywords>
                    {keywords.map(keyword => (
                        <SCxNotificationApprovalModalKeyword key={keyword} onClick={() => setValue(val => `${val} ${keyword}`)}>
                            {keyword}
                        </SCxNotificationApprovalModalKeyword>
                    ))}
                </SCxNotificationApprovalModalKeywords>
            </SCxNotificationApprovalModalKeywordsWrapper>
            <SCxNotificationApprovalModalFooter>
                <ThemeButton
                    pattern={ButtonPattern.secondary}
                    showType="name"
                    styles={{
                        container: {
                            width: 'auto'
                        },
                        wrapper: {
                            width: 'auto',
                            height: 32,
                            padding: '12px 26px'
                        }
                    }}
                    triggerEvent="click"
                    onClick={onClose}
                    title="取消"
                />
                <ThemeButton
                    pattern={ButtonPattern.primary}
                    showType="name"
                    styles={{
                        container: {
                            width: 'auto'
                        },
                        wrapper: {
                            width: 'auto',
                            height: 32,
                            padding: '12px 26px'
                        }
                    }}
                    triggerEvent="click"
                    onClick={handleConfirm}
                    title="确定"
                />
            </SCxNotificationApprovalModalFooter>
        </SCxNotificationApprovalModalWrapper>
    )
}
