import type { DataSourceAbstract, FieldInputConfigProtocol, FieldInputType } from '@lighthouse/core'
import React from 'react'

import BaseInput from '../BaseInput'
import { FieldInputConfig } from '../types'
import { CheckboxField } from './CheckboxField'
import { SliderField } from './SliderField'

interface EmptyFieldProps {
    type: FieldInputType
    config?: FieldInputConfigProtocol
}

const EmptyField: React.FunctionComponent<EmptyFieldProps> = ({ type, config }) => {
    if (type === 'checkbox') {
        return <CheckboxField config={{ inputType: type }} readOnly />
    }
    if (type === 'slider' && config?.inputType === 'slider') {
        return <SliderField sliderConfig={config} config={{ inputType: type }} readOnly />
    }
    return <BaseInput value="-" readOnly style={{ borderRadius: 6 }} />
}

export default EmptyField
