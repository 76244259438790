import type { Option } from '@byecode/ui'
import type { IdRuleEnum } from '@lighthouse/core'

import type { IDConfigOption } from './types'

export const IdMaxLength = 64

export const DateFormatOptions: Option[] = [
    {
        label: '年/月/日 00:00',
        value: 'yyyyMMddHHmm'
    },
    {
        label: '年/月/日',
        value: 'yyyyMMdd'
    },
    {
        label: '年/月',
        value: 'yyyyMM'
    }
]

export const ID_CONFIG_LABEL: Record<IdRuleEnum, string> = {
    ID: '自增数字',
    DATE: '创建日期',
    FIXED_CHARACTER: '固定字符'
}

export const ID_CONFIG_OPTIONS: IDConfigOption[] = [
    {
        label: '自增数字',
        value: 'ID'
    },
    {
        label: '创建日期',
        value: 'DATE'
    },
    {
        label: '固定字符',
        value: 'FIXED_CHARACTER'
    }
]
