import clsx from 'clsx'
import React, { forwardRef, useEffect, useRef, useState } from 'react'

import { useComponentConfig } from '../../hooks/useComponentConfig'
import { mergeRefs } from '../../hooks/useMergedRef'
import { Box } from '../Box'
import { IconFont } from '../IconFont'
import { useStyles } from './styles'
import type { InputProps } from './types'

export const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            unstyled,
            styles,
            classNames,
            className,

            size = 'md',
            borderRadius = true,
            prefix,
            suffix,
            autoSelect,
            clearable,
            focusOutLine = true,
            style,
            onClear,
            disabled: _disabled,
            placeholder = '请输入',
            wrapperProps,
            highlighting,
            onFocus,
            onBlur,
            ...restProps
        },
        ref
    ) => {
        const { disabled } = useComponentConfig('input', { disabled: _disabled })
        const { classes } = useStyles(
            { size, focusOutLine, disabled, hasPrefix: !!prefix, highlighting },
            { name: 'Input', unstyled, classNames, styles }
        )

        const inputRef = useRef<HTMLInputElement>(null)

        useEffect(() => {
            if (autoSelect && inputRef.current) {
                inputRef.current.select()
            }
        }, [autoSelect])

        const [focused, setFocused] = useState(false)

        return (
            <Box
                className={clsx(classes.wrapper, className)}
                data-focused={focused || undefined}
                data-highlighting={highlighting}
                style={style}
                {...wrapperProps}
            >
                {prefix && <Box className={classes.prefix}>{prefix}</Box>}
                <input
                    className={classes.input}
                    // hasPrefix={!!prefix}
                    ref={mergeRefs(inputRef, ref)}
                    disabled={disabled}
                    placeholder={placeholder}
                    onFocus={e => {
                        onFocus?.(e)
                        setFocused(true)
                    }}
                    onBlur={e => {
                        onBlur?.(e)
                        setFocused(false)
                    }}
                    {...restProps}
                />
                {suffix && <Box className={classes.suffix}>{suffix}</Box>}
                {!disabled && clearable && restProps.value && (
                    <Box className={classes.suffix}>
                        <IconFont
                            type="CloseCircle"
                            style={{ color: 'var(--color-gray-500)', fontSize: 'var(--font-size-lg)' }}
                            onClick={onClear}
                        />
                    </Box>
                )}
            </Box>
        )
    }
)
