import type { Option } from '@byecode/ui'
import { Button, IconFont, Select, useContextPopoverHeight, usePopoverContext, useTheme } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext } from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { FieldType, SortableProtocol, Sorter, ViewFieldProtocol } from '@lighthouse/core'
import { arrayMove, nanoid } from '@lighthouse/tools'
import { clone, findIndex, length, reduce } from 'rambda'
import React, {
    useCallback, useEffect, useMemo
} from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { SORT_OPTIONS } from '../../constants'
import { getFieldIcon } from '../../utils'
import { FieldTypeTag } from '../FieldTypeTag'

interface SortProps {
    children?: React.ReactNode
    sorters: SortableProtocol['sorts']
    columns: ViewFieldProtocol[]
    primaryField: string
    noSettingFields?: Set<FieldType>
    disabled?: boolean
    dsName?: string
    disabledShowInnerType?: boolean
    isShowDsName?: boolean
    onChangeSorter?: (data: SortableProtocol['sorts']) => void
}

const PropertySettingWrapper = styled.div`
    padding: 4px 0;
    width: 360px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
`

const PropertySettingTitle = styled.p`
    padding: 0 12px;
    margin-bottom: 8px;
    font-size: var(--font-size-normal);
    font-weight: 600;
`

const PropertyAdder = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 12px 0 12px;
    font-size: var(--font-size-normal);
    cursor: pointer;

    &[data-disabled='true'] {
        cursor: not-allowed;
        opacity: 0.5;
    }
`

const AddIcon = styled(IconFont)`
    margin-right: 6px;
`

const SortList = styled.div`
    user-select: none;
`

const SortItem = styled.div`
    position: relative;
    display: flex;
    padding: 4px 12px;
    justify-content: space-between;
    align-items: center;
`

const SortItemSelect = styled.div<{ width: number }>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    margin-right: 8px;
`

const Empty = styled.div`
    padding: 6px 12px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`

const SCxDescribe = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    padding-left: 24px;
`

const Delete = styled.div`
    color: var(--color-gray-500);
    cursor: pointer;
    margin-right: 12px;
`

const SortableItem: React.FC<{ id: string; disabled?: boolean; children?: React.ReactNode }> = ({ id, disabled, children }) => {
    const { setNodeRef, attributes, listeners, transform, transition, isSorting, isDragging } = useSortable({
        id,
        disabled
    })

    const sortingStyle = {
        transform: CSS.Transform.toString(transform),
        transition: isSorting ? transition : 'none',
        // zIndex: isDragging ? 999 : 1,
        cursor: isDragging ? 'grabbing' : 'initial'
    }

    return (
        <SortItem style={sortingStyle} ref={setNodeRef} {...attributes}>
            <IconFont type="Move" style={{ marginRight: 4, cursor: isDragging ? 'grabbing' : 'grab' }} {...listeners} />
            {children}
        </SortItem>
    )
}

export const ViewSortConfigure: React.FC<SortProps> = ({
    isShowDsName,
    disabledShowInnerType,
    sorters,
    columns,
    primaryField,
    noSettingFields,
    disabled,
    onChangeSorter
}) => {
    const { colorPrimary } = useTheme()
    const { control, handleSubmit, setValue } = useForm({
        defaultValues: { options: sorters || [] }
    })
    const { context } = usePopoverContext()
    const [maxHeight, internalRef] = useContextPopoverHeight({ context })
    const { fields, remove, append, move } = useFieldArray({ control, name: 'options', keyName: 'key' })

    const list = useMemo(() => {
        return reduce<ViewFieldProtocol, Option[]>(
            (list, field) => {
                const { type, title, fieldId, innerType, dsName } = field
                // trim formula
                if (noSettingFields?.has(type)) {
                    return list
                }
                const newOption = {
                    value: fieldId,
                    label: title,
                    icon: `${getFieldIcon(fieldId, type, innerType) || ''}`,
                    extra: !disabledShowInnerType && <FieldTypeTag type={type} innerType={innerType} />,
                    describe: isShowDsName && dsName && <SCxDescribe>{dsName}</SCxDescribe>
                }
                return [...list, newOption]
            },
            [],
            columns
        )
    }, [columns, disabledShowInnerType, isShowDsName, noSettingFields])

    const sortersLength = length(fields ?? [])

    const handleAdd = useCallback(() => {
        const newSorters = Array.isArray(sorters) ? sorters : []
        const newSort: Sorter = {
            id: nanoid(),
            order: 'ASC',
            fieldId: primaryField
        }
        append(newSort)
        onChangeSorter?.([...newSorters, newSort])
    }, [append, primaryField, sorters, onChangeSorter])

    const handleDelete = useCallback(
        (index: number) => {
            const newSorters = Array.isArray(sorters) ? clone(sorters) : []
            newSorters.splice(index, 1)
            remove(index)
            onChangeSorter?.(newSorters)
        },
        [remove, sorters, onChangeSorter]
    )

    const handleChange = useCallback(
        (newSorter: SortableProtocol['sorts']) => {
            onChangeSorter?.(clone(newSorter))
        },
        [onChangeSorter]
    )

    const handleSort = useCallback(
        (ev: DragEndEvent) => {
            const {
                active: { id: activeId },
                over
            } = ev
            const overId = over?.id
            if (!overId) {
                return
            }

            const sourceIndex = findIndex(col => col.id === activeId, fields)
            const targetIndex = findIndex(col => col.id === overId, fields)

            const newSorters = arrayMove(fields, sourceIndex, targetIndex)

            move(sourceIndex, targetIndex)

            onChangeSorter?.(newSorters)
        },
        [fields, move, onChangeSorter]
    )

    const renderList = useCallback(() => {
        return (
            <DndContext onDragEnd={handleSort} modifiers={[restrictToParentElement]}>
                <SortableContext items={fields.map(({ id }) => id)}>
                    <SortList>
                        {fields.map((field, index) => (
                            <SortableItem key={field.id} id={field.id} disabled={disabled}>
                                <Controller
                                    name={`options.${index}.fieldId`}
                                    control={control}
                                    render={({ field }) => (
                                        <SortItemSelect width={165}>
                                            <Select
                                                value={field.value}
                                                searchable
                                                onChange={ev => {
                                                    field.onChange(ev)
                                                    handleSubmit(d => {
                                                        handleChange(d.options)
                                                    })()
                                                }}
                                                // disabledPortal
                                                size="md"
                                                options={list}
                                            />
                                        </SortItemSelect>
                                    )}
                                />
                                <Controller
                                    name={`options.${index}.order`}
                                    control={control}
                                    render={({ field }) => (
                                        <SortItemSelect width={118}>
                                            <Select
                                                onChange={ev => {
                                                    field.onChange(ev)
                                                    handleSubmit(d => {
                                                        handleChange(d.options)
                                                    })()
                                                }}
                                                value={field.value}
                                                size="md"
                                                // styles={{
                                                //     wrapper: { width: 118 }
                                                // }}
                                                options={SORT_OPTIONS}
                                            />
                                        </SortItemSelect>
                                    )}
                                />

                                <Button
                                    type="text"
                                    disabled={disabled}
                                    icon={<IconFont type="Trash" />}
                                    onClick={() => handleDelete(index)}
                                />
                            </SortableItem>
                        ))}
                    </SortList>
                </SortableContext>
            </DndContext>
        )
    }, [control, disabled, fields, handleChange, handleDelete, handleSort, handleSubmit, list])

    const renderEmpty = useCallback(() => {
        return <Empty>添加排序规则，让你的数据井然有序</Empty>
    }, [])

    useEffect(() => {
        if (sorters) {
            setValue('options', sorters)
        }
    }, [sorters, setValue])

    return (
        <PropertySettingWrapper ref={internalRef} style={{ maxHeight }}>
            <PropertySettingTitle>排序规则</PropertySettingTitle>
            {sortersLength <= 0 ? renderEmpty() : renderList()}
            <PropertyAdder
                style={{ color: colorPrimary || 'var(--color-main)' }}
                data-disabled={disabled}
                onClick={disabled ? undefined : handleAdd}
            >
                <AddIcon type="Add" fill={colorPrimary} />
                添加
            </PropertyAdder>
        </PropertySettingWrapper>
    )
}
