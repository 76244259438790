import type { DataSourceType, PageType } from '@lighthouse/core'

// 应用端登录注册页面
export const commonPages = ['/account/login', '/account/register']

export const commonPageList = [
    {
        label: '注册页面',
        value: '/account/register'
    },
    {
        label: '登录页面',
        value: '/account/login'
    }
]

/** 页面右侧栏类名 */
export const RIGHT_ASIDE_CLASS = 'page-design-aside'
/** 页面滑动内容 */
export const PAGE_SCROLL_CONTENT = 'page-content-scrollArea'
/** 页面滑动内容父容器 */
export const PAGE_SCROLL_PARENT_CONTENT = 'page-parent-content-scrollArea'

export const PageIconTypeMap: Record<PageType, string> = {
    creator: 'LayerFormPageNew',
    default: 'PageBroadContent',
    document: 'LayerDetailPageNew',
    edit: 'LayerEditPageNew'
}

export const FindUsePageIconTypeMap: Record<PageType, string> = {
    creator: 'LayerFormPageNew',
    default: 'PageBroadContent',
    document: 'LayerDetailPageNew',
    edit: 'LayerEditPageNew'
}

export const commonPageOptions = [
    {
        label: '注册页面',
        value: '/account/register',
        group: 'common'
    },
    {
        label: '登录页面',
        value: '/account/login',
        group: 'common'
    }
]
