import type { ModalProps } from '@byecode/ui/components/Modal/Modal'
import { Modal } from '@byecode/ui/components/Modal/Modal'
import { mergeDeepRight } from 'rambda'
import React from 'react'

export type PageOverlayState = {
    type: 'drawer' | 'modal'
    props: ModalProps
}

export interface PageOverlayProps {
    layerProps?: PageOverlayState
}

export const PageOverlay: React.FC<PageOverlayProps> = ({ layerProps }) => {
    if (!layerProps) {
        return null
    }

    if (layerProps.type === 'drawer') {
        return (
            <Modal
                open
                width="33.33%"
                styles={mergeDeepRight(
                    {
                        desktop: {
                            modal: {
                                maxWidth: '100%',
                                minWidth: 460,
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                bottom: 8
                            },
                            body: {
                                padding: '0px !important'
                            }
                        },
                        mobile: {
                            body: {
                                padding: '0px !important'
                            },
                            modal: {
                                height: '80%'
                            }
                        }
                    },
                    layerProps.props.styles ?? {}
                )}
                transition={{ desktop: { from: { x: '100%' }, to: { x: '0%' } } }}
                closeOnClickOverlay={false}
                {...layerProps.props}
            />
        )
    }

    if (layerProps.type === 'modal') {
        return (
            <Modal
                open
                styles={mergeDeepRight(
                    {
                        desktop: {
                            root: { padding: '48px 16px' },
                            modal: {
                                maxWidth: '100%',
                                height: '100%'
                            },
                            body: {
                                padding: '0px !important'
                            }
                        },
                        mobile: {
                            body: {
                                padding: '0px !important'
                            },
                            modal: {
                                height: '90%'
                            }
                        }
                    },
                    layerProps.props.styles ?? {}
                )}
                closeOnClickOverlay={false}
                {...layerProps.props}
            />
        )
    }

    return null
}
