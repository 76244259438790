import type { CascadeOption } from '@byecode/ui'
import { getCascadeFlatOption } from '@byecode/ui'
import type { FilterBlockValue, NumberRangeArray } from '@lighthouse/core'
import { FilterSelectWay, FilterWay } from '@lighthouse/core'
import { CascadeDrawer, NormalButton, useApplicationContext } from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useGetOption } from '../hook'
import type { FilterItemProps } from '../types'
import { DateDrawer } from './DateDrawer'
import { NumberDrawer } from './NumberDrawer'
import { TextDrawer } from './TextDrawer'

interface NormalFilterItemProps
    extends Pick<FilterItemProps, 'recordId' | 'config' | 'dataSourceList' | 'onChange' | 'onFetchTextOptions' | 'onFetchCascadeOptions'> {
    filterData: FilterBlockValue
}

const SCxContainer = styled.div<{ isActive?: boolean }>`
    flex-shrink: 0;
`

export const NormalFilterItem: React.FunctionComponent<NormalFilterItemProps> = ({
    config,
    dataSourceList,
    filterData,
    recordId,
    onChange,
    onFetchTextOptions,
    onFetchCascadeOptions
}) => {
    const { filterWay, id, title, selectWay } = config
    const [opened, setOpened] = useState(false)

    const { pageTarget } = useApplicationContext()
    const { value, customValue } = useMemo(() => filterData?.[id] ?? { customValue: [], value: [] }, [filterData, id])

    const { options } = useGetOption({ value, onFetchTextOptions, onFetchCascadeOptions, dataSourceList, recordId, config })
    const isMultiple = FilterSelectWay.multiSelect === selectWay

    const numberConfig = useMemo(() => {
        if (filterWay === FilterWay.numberFilter) {
            return config
        }
        return null
    }, [config, filterWay])

    const handleChange = useCallback(
        (val: string[], customValue?: NumberRangeArray) => {
            onChange?.(id, { type: filterWay, value: val, customValue: customValue ?? [undefined, undefined] })
        },
        [filterWay, id, onChange]
    )

    const isActive = useMemo(() => {
        const newOptions = options.reduce<CascadeOption[]>((pre, cur) => {
            const newOption = { ...cur, path: cur.value, labelPath: cur.label, isLast: (cur.children ?? []).length === 0 }
            return [...pre, newOption, ...getCascadeFlatOption(newOption, [])]
        }, [])
        return value.some(v => newOptions.some(option => option.value === v))
    }, [options, value])

    const drawerEle = useMemo(() => {
        switch (filterWay) {
            case FilterWay.personFilter:
            case FilterWay.selectFilter:
            case FilterWay.boolFilter:
            case FilterWay.textFilter: {
                return (
                    <TextDrawer
                        opened={opened}
                        value={value}
                        title={title}
                        target={pageTarget}
                        onFinish={handleChange}
                        onClose={() => setOpened(false)}
                        isMultiple={isMultiple}
                        options={options}
                    />
                )
            }
            case FilterWay.dateFilter: {
                return (
                    <DateDrawer
                        opened={opened}
                        title={title}
                        target={pageTarget}
                        customValue={customValue}
                        isMultiple={isMultiple}
                        options={options}
                        onClose={() => setOpened(false)}
                        onFinish={handleChange}
                    />
                )
            }
            case FilterWay.cascadeFilter: {
                return (
                    <CascadeDrawer
                        opened={opened}
                        value={value}
                        title={title}
                        target={pageTarget}
                        onFinish={handleChange}
                        isLastLevel
                        onClose={() => setOpened(false)}
                        isMultiple={isMultiple}
                        options={options}
                        showFooterBtn
                    />
                )
            }
            case FilterWay.numberFilter: {
                return (
                    <NumberDrawer
                        opened={opened}
                        value={value ?? []}
                        title={title}
                        target={pageTarget}
                        numberRangeMode={numberConfig?.numberRangeMode}
                        min={numberConfig?.numberRange?.[0]}
                        max={numberConfig?.numberRange?.[1]}
                        step={numberConfig?.step}
                        onFinish={handleChange}
                        onClose={() => setOpened(false)}
                        isMultiple={isMultiple}
                        options={options}
                    />
                )
            }
            default: {
                return null
            }
        }
    }, [
        filterWay,
        opened,
        value,
        customValue,
        title,
        pageTarget,
        handleChange,
        isMultiple,
        options,
        numberConfig?.numberRangeMode,
        numberConfig?.numberRange,
        numberConfig?.step
    ])

    return (
        <SCxContainer>
            <NormalButton
                title={title}
                active={isActive}
                onClick={() => {
                    setOpened(true)
                }}
            />
            {drawerEle}
        </SCxContainer>
    )
}
