import type { SelectedMode, TableColumnWidth, ViewField } from '@lighthouse/core'
import isDeepEqual from 'fast-deep-equal'
import React, { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { AdvancedTableField } from './AdvancedTableField'
import { AdvancedTableHeaderChecker } from './AdvancedTableHeaderChecker'

export interface AdvancedTableHeaderProps {
    id: string
    checkable?: boolean
    recordOpenable?: boolean
    viewFields: ViewField[]
    allRecordSelected?: boolean
    isMobile?: boolean
    primaryDataSourceFieldIds?: Set<string>
    onAllRecordSelect?: (selected: boolean) => void
    onChangePreventDrag?: (val: boolean) => void
    onSelectModeChange?: (mode?: SelectedMode) => void
    onTableColumnWidthChange: (val: TableColumnWidth) => void
}

export const SCxAdvancedTableHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    min-width: 100%;
    width: fit-content;

    & > div:last-child {
        border-right: none;
    }
`

export const SCxAdvancedTableHeaderContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    border-bottom: 1px solid var(--color-gray-200);
    background-color: var(--color-gray-50);
`

export const SCxAdvancedTableChecker = styled(AdvancedTableHeaderChecker)`
    background-color: var(--color-gray-50);
    border-bottom: 1px solid var(--color-gray-200);
`

const SCxRemain = styled.div`
    width: 40px;
    height: 100%;
`

const AdvancedTableHeader: React.FC<AdvancedTableHeaderProps> = ({
    id: blockId,
    checkable,
    recordOpenable,
    viewFields,
    allRecordSelected,
    isMobile,
    onAllRecordSelect,
    onChangePreventDrag,
    onSelectModeChange,
    onTableColumnWidthChange
}) => {
    // const ViewSettingCache
    const handleResizerChange = useCallback(
        (id: string, columnWidth: number) => {
            onTableColumnWidthChange?.({ [id]: columnWidth })
        },
        [onTableColumnWidthChange]
    )

    const fieldColumns = useMemo(() => viewFields.filter(item => item.visible), [viewFields])

    return (
        <SCxAdvancedTableHeader className="tableHeader">
            <SCxAdvancedTableChecker
                showOpenerIcon={recordOpenable}
                checkable={checkable}
                isStripe
                checked={allRecordSelected}
                onChange={onAllRecordSelect}
                onSelectModeChange={onSelectModeChange}
            />
            <SCxAdvancedTableHeaderContent>
                {fieldColumns.map((field, index) => {
                    return (
                        <AdvancedTableField
                            key={field.fieldId}
                            field={field}
                            isLast={index === fieldColumns.length - 1}
                            isMobile={isMobile}
                            onResizerChange={handleResizerChange}
                            onChangePreventDrag={onChangePreventDrag}
                        />
                    )
                })}
                <SCxRemain />
            </SCxAdvancedTableHeaderContent>
        </SCxAdvancedTableHeader>
    )
}

export default memo(AdvancedTableHeader, isDeepEqual)
