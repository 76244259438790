import { IconFont, Loading, Popover } from '@byecode/ui'
import type { Field, FieldADTValue, RecordLikeProtocol } from '@lighthouse/core'
import { useDebounce } from '@lighthouse/tools'
import { Divider, Text } from '@mantine/core'
import type { FloatingPosition } from '@mantine/core/lib/Floating'
import { max, min, reduce } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useClickAway, useLatest, useSetState, useUpdateEffect } from 'react-use'

import { fieldConvertValue, getFieldIcon } from '../../../../utils'
import FieldTable from '../../../FiledSelectTable'
import { minPopoverWidth, outlineWidth, popoverMaxHeight } from '../../constant'
import FieldList from './FieldList'
import { RelationRecordPreview } from './RelationRecordPreview'
import { SearchInput } from './SearchInput'
import * as SC from './styles'
import type { RelationRecordSelectProps, RelationRecordSelectState } from './types'
import { useRelativeDatasource } from './useRelativeDatasource'

export const RecordRecordSelect = React.forwardRef<HTMLDivElement, RelationRecordSelectProps>(
    (
        {
            tableWidth,
            dsId,
            dataSource,
            value: relationValue = [],
            records = [],
            userRecord,
            relativeSelectConfig,
            readOnly,
            opened,
            containerRef,
            containerRect,
            dataSourceList,
            personOptions,
            roleOptions,
            departmentOptions,
            onChangeOpened,
            onChange,
            onLoadMoreData,
            onOpenPage,
            onFetchDataSource
        },
        ref
    ) => {
        const { schema, viewOptions } = dataSource ?? {}
        const { pagination, tableProps: fieldProps = [] } = viewOptions ?? {}
        const { currentPage = 1 } = pagination ?? {}

        const { relativeSelect, placeholder } = relativeSelectConfig ?? {}
        const {
            relativePointer = '',
            relativeShowFieldPointer = '',
            relativeFieldPointer = '',
            canCreateRecord,
            viewFieldSettings = [],
            // viewPrimaryField,
            showType = 'table',
            filter,
            sorts,
            canMultipleChoice,
            creatingConfig
        } = relativeSelect ?? {}

        const { label: addText } = creatingConfig ?? {}

        const tableContentRef = useRef<HTMLDivElement>(null)

        const scrollRef = useRef<HTMLDivElement>(null)

        const [state, setState] = useSetState<RelationRecordSelectState>({
            isLookSelected: false,
            selectList: relationValue
        })

        const { isLookSelected, selectList } = state

        const {
            state: { loading, noNexData, searchRecordList, keywords, initLoading },
            handleFetchData,
            handleChangeSearch,
            handleLoadMoreData,
            handleChangeInitLoading
        } = useRelativeDatasource({ onFetchDataSource, onLoadMoreData, dsId, opened })

        const filterRecords = useMemo(() => {
            return isLookSelected ? records.filter(record => relationValue.includes(record.id)) : searchRecordList
        }, [isLookSelected, records, relationValue, searchRecordList])

        const options = useMemo(
            () => filterRecords
                ,
            [filterRecords]
        )

        const columns = useMemo(() => {
            if (!dataSource) {
                return []
            }
            const { schema } = dataSource
            if (fieldProps && fieldProps.length > 0) {
                return fieldProps.reduce<Field[]>((cols, col) => {
                    if (schema[col.id] && col.visible) {
                        cols.push(schema[col.id])
                    }
                    return cols
                }, [])
            }
            return Object.entries(schema).reduce<Field[]>((cols, [key, col]) => {
                cols.push({ ...col })
                return cols
            }, [])
        }, [dataSource, fieldProps])

        const inputWidth = useMemo(() => containerRect?.width ?? minPopoverWidth, [containerRect?.width])

        const popoverWidth = useMemo(() => (inputWidth < minPopoverWidth ? minPopoverWidth : inputWidth) + outlineWidth * 2, [inputWidth])

        const selectListRef = useLatest(relationValue)
        useUpdateEffect(() => {
            if (opened) {
                setState({ selectList: selectListRef.current })
            }
        }, [opened])

        const handleRemove = useCallback(
            (recordId: string) => {
                const newValue = relationValue.filter(item => item !== recordId)
                setState({ selectList: newValue })
                return onChange?.(newValue)
            },
            [onChange, relationValue, setState]
        )

        const [position, tableHeight]: [FloatingPosition, number] = useMemo(() => {
            if (!containerRef || !containerRef.current || (!containerRef?.current && !opened)) {
                return ['bottom-start', popoverMaxHeight]
            }
            const containRect = containerRef?.current?.getBoundingClientRect()
            const top = containRect.y
            const bottom = window.innerHeight - containRect.y - containRect.height
            const maxHeight = max(top, bottom)
            const usedHeight = max(50, maxHeight - 150)
            const position: FloatingPosition = top > bottom ? 'top-start' : 'bottom-start'
            return [position, min(popoverMaxHeight, usedHeight)]
        }, [containerRef, opened])

        const handleScrollPositionChange = useCallback(
            (position: { x: number; y: number }) => {
                const { y } = position
                if (!scrollRef.current) {
                    return
                }
                const { clientHeight, scrollHeight } = scrollRef.current
                const isBottom = y + clientHeight + 100 > scrollHeight

                if (isBottom && !loading && !noNexData) {
                    handleLoadMoreData(currentPage + 1)
                }
            },
            [currentPage, handleLoadMoreData, loading, noNexData, scrollRef]
        )

        const debouncedKeywords = useDebounce(keywords, 500)

        useUpdateEffect(() => {
            if (debouncedKeywords !== '') {
                handleFetchData(debouncedKeywords)
            }
        }, [debouncedKeywords])

        const invokeChange = useCallback(
            (val: string[]) => {
                onChange?.(val)
            },
            [onChange]
        )

        const handleItemClick = useCallback(
            (value: string) => {
                // const isExists = relationValue.includes(value)
                // let newValue: string[] = []
                if (canMultipleChoice) {
                    setState(({ selectList }) => {
                        const isExistRecordId = selectList.includes(value)
                        const newValue = isExistRecordId ? selectList.filter(item => item !== value) : [...selectList, value]
                        invokeChange(newValue)
                        return { selectList: newValue }
                    })
                    return
                }
                // newValue = isExists ? [] : [value]
                setState(({ selectList }) => {
                    const isExistRecordId = selectList.includes(value)
                    const newValue = isExistRecordId ? [] : [value]
                    invokeChange(newValue)
                    return { selectList: newValue }
                })
                // invokeChange(newValue)
                onChangeOpened(false)
            },
            [canMultipleChoice, invokeChange, onChangeOpened, setState]
        )

        const handleSearchValueChange = useCallback(
            (label: string) => {
                const search = label.trim()
                if (search === keywords) {
                    return
                }
                handleChangeSearch(search)
            },
            [handleChangeSearch, keywords]
        )

        useEffect(() => {
            if (opened) {
                handleChangeInitLoading(true)
                handleFetchData?.()
            }
        }, [handleChangeInitLoading, handleFetchData, opened])

        return (
            <Popover
                withinPortal
                opened={opened}
                width={popoverWidth}
                position={position}
                positionDependencies={[filterRecords]}
                onClose={() => onChangeOpened(false)}
                closeOnEscape
            >
                <Popover.Target>
                    <RelationRecordPreview
                        records={records}
                        onClick={() => {
                            !readOnly && relativeFieldPointer && relativeShowFieldPointer && onChangeOpened(!opened)
                        }}
                        fieldPointer={relativeShowFieldPointer}
                        value={relationValue}
                        readOnly={readOnly}
                        userRecord={userRecord}
                        onRemove={handleRemove}
                        dsId={relativePointer}
                        dataSourceList={dataSourceList}
                        placeholder={placeholder}
                        opened={opened}
                    />
                </Popover.Target>
                <Popover.Dropdown compact>
                    <SC.RecordSelectWrapper data-type="fl-node-part" ref={ref}>
                        <SearchInput
                            showType={showType}
                            value={keywords}
                            switchValue={isLookSelected}
                            // autoFocus
                            style={{ padding: 12 }}
                            onChange={handleSearchValueChange}
                            onChangeSwitch={val => setState({ isLookSelected: val })}
                        />
                        {initLoading ? (
                            <Loading />
                        ) : (
                            <>
                                {showType === 'table' ? (
                                    <SC.RecordSelector ref={tableContentRef}>
                                        <FieldTable
                                            ref={scrollRef}
                                            tableWidth={tableWidth}
                                            tableHeight={tableHeight}
                                            value={selectList}
                                            columns={columns}
                                            loading={loading}
                                            primaryField="ID"
                                            dataSourceList={dataSourceList}
                                            recordData={filterRecords}
                                            onRowClick={handleItemClick}
                                            onScrollPositionChange={handleScrollPositionChange}
                                        />
                                    </SC.RecordSelector>
                                ) : (
                                    <FieldList
                                        options={options}
                                        value={selectList}
                                        ref={scrollRef}
                                        dataSource={dataSource}
                                        tableWidth={tableWidth}
                                        tableHeight={tableHeight}
                                        onScrollPositionChange={handleScrollPositionChange}
                                        onChange={handleItemClick}
                                    />
                                )}
                            </>
                        )}
                        {canCreateRecord && (
                            <>
                                <Divider color="var(--color-gray-200)" />
                                <SC.Add
                                    justifyContent={showType === 'table' ? 'center' : 'flex-start'}
                                    onClick={() => {
                                        onChangeOpened(false)
                                        onOpenPage?.(creatingConfig)
                                    }}
                                >
                                    <IconFont type="Add" color="var(--color-app-main)" />
                                    <Text color="var(--color-app-main)" size={14}>
                                        {addText}
                                    </Text>
                                </SC.Add>
                            </>
                        )}
                    </SC.RecordSelectWrapper>
                </Popover.Dropdown>
            </Popover>
        )
    }
)
