import { singleTextEllipsis } from '@byecode/ui'
import * as React from 'react'
import styled from 'styled-components'

interface PlaceholderProps {
    value?: string
    style?: React.CSSProperties
}

const PlaceholderText = styled.div`
    width: 100%;
    /* height: 100%; */
    line-height: 100%;
    text-align: left;
    color: var(--color-gray-300);
    ${singleTextEllipsis}
`

export const Placeholder: React.FunctionComponent<PlaceholderProps> = ({ value, style }) => {
    return <PlaceholderText style={style}>{value}</PlaceholderText>
}
