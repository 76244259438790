import React from 'react'
import styled from 'styled-components'

import { FILE_EXCEL_ACCEPT } from '../../../constants'
import UploadArea from '../../UploadArea'

interface UploadClickDropZoneProps {
    file: File | null
    disabled?: boolean
    onFileLoaded?: (file: File) => Promise<void> | void
    onFileClear?: () => void
}

const TipWrapper = styled.div`
    padding: 8px 0;
`

const Tip = styled.div`
    position: relative;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    padding: 4px 10px;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: var(--color-gray-400);
    }
`

const UploaderAreaTitleTip = styled.div`
    margin: 8px 0;
`
const UploaderAreaDesTip = styled.div`
    font-size: var(--font-size-sm);
`

const UploaderAreaBoldTip = styled.span`
    font-weight: 500;
    color: var(--color-gray-600);
`

// 限制 10 M
const fileSizeLimit = 10
// 限制 2 万行
const recordLimit = 2
export const UploadClickDropZone: React.FC<UploadClickDropZoneProps> = ({ file, disabled, onFileLoaded, onFileClear }) => {
    return (
        <UploadArea
            file={file}
            accept={FILE_EXCEL_ACCEPT}
            disabled={disabled}
            sizeLimit={fileSizeLimit}
            extra={
                <TipWrapper>
                    <Tip>文件大小不能超过 {fileSizeLimit}MB</Tip>
                    <Tip>每次导入不能超过 {recordLimit} 万行数据</Tip>
                    <Tip>仅支持 .xlsx、.csv 格式的文件</Tip>
                    <Tip>文件中不能有空 Sheet</Tip>
                    <Tip>文件中不能存有合并的单元格</Tip>
                </TipWrapper>
            }
            description={
                <UploaderAreaTitleTip>
                    点击或拖拽<UploaderAreaBoldTip>.xlsx、.csv文件（10M以内）</UploaderAreaBoldTip>到此区域
                </UploaderAreaTitleTip>
            }
            tip={
                <UploaderAreaDesTip>
                    请确保：文件中不存在<UploaderAreaBoldTip>空工作表和合并单元格</UploaderAreaBoldTip>；
                </UploaderAreaDesTip>
            }
            onFileLoaded={onFileLoaded}
            onFileClear={onFileClear}
        />
    )
}

export default UploadClickDropZone
