import { RecordOpenType } from '@lighthouse/core'
import { getOpenTypeByUrlName, PAGE_URL_REG } from '@lighthouse/shared'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * 页面栈的操作
 * @returns
 */
export const usePageStackActions = () => {
    const location = useLocation()
    const navigate = useNavigate()

    /**
     * 关闭所有页面弹窗
     */
    const closeAllPageLayers = useCallback(() => {
        const regArr = location.pathname.match(PAGE_URL_REG)
        if (!regArr) {
            return
        }

        let newArr: string[] = []
        const l = regArr.length
        for (let index = l - 1; index >= 0; index--) {
            const [openTypeName] = regArr[index].split('/')
            const openType = getOpenTypeByUrlName(openTypeName)
            if (openType === RecordOpenType.page) {
                newArr = regArr.slice(0, index + 1)
                break
            }
        }
        const path = newArr.join('/')
        navigate(path)
    }, [location.pathname, navigate])

    /**
     * 关闭当前页面
     */
    const closeCurrentPageLayer = useCallback(() => {
        const regArr = location.pathname.match(PAGE_URL_REG)
        if (!regArr) {
            return
        }

        if (regArr.length === 1) {
            navigate(-1)
            return
        }

        navigate(regArr.slice(0, -1).join('/'))
    }, [location.pathname, navigate])

    /**
     *
     */
    const peekCurrentPageStack = useCallback(() => {
        const regArr = location.pathname.match(PAGE_URL_REG)
        if (!regArr) {
            return
        }

        const [openTypeName] = regArr[regArr.length - 1].split('/')
        const openType = getOpenTypeByUrlName(openTypeName)

        return {
            openType,
            pageId: regArr[regArr.length - 1]
        }
    }, [location.pathname])

    return { closeAllPageLayers, closeCurrentPageLayer, peekCurrentPageStack }
}
