import { ChartType } from '@lighthouse/core'

export const areaChartType: Set<ChartType> = new Set([ChartType.pie, ChartType.funnel, ChartType.striation])

export const disableAddMainAxisChartType: Set<ChartType> = new Set([ChartType.pie, ChartType.funnel])

export const enableSortConfiguratorChartType: Set<ChartType> = new Set([
    ChartType.line,
    ChartType.bar,
    ChartType.pie,
    ChartType.funnel,
    ChartType.composite,
    ChartType.striation
])

export const chartTypeLevel: Record<ChartType, number> = {
    bar: 1,
    line: 1,
    composite: 1,
    pie: 2,
    funnel: 2,
    striation: 2,
    indicator: 3
}
