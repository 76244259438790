import { IconFont } from '@byecode/ui'
import { Center } from '@mantine/core'
import type { FC } from 'react'
import React from 'react'

interface NotSupportFileProps {
    name: string
    icon: string
}

export const NotSupportFile: FC<NotSupportFileProps> = ({ name, icon }) => {
    return (
        <Center sx={{ height: '100%', flexDirection: 'column' }}>
            <IconFont type={icon} size={124} />
            <div>{name}</div>
        </Center>
    )
}
