/* eslint-disable promise/prefer-await-to-callbacks  */

import { nanoid } from '@lighthouse/tools'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Noop = (params?: any) => void

type SubscribeReturnType = {
    subscribeId: string
    unSubscribe: (id: string) => void
}

type Listener = {
    id: string
    subscribeId: string
    callback: Noop
}

type Operation = 'ADD' | 'UPDATE' | 'DELETE'

/**
 * 页面栈之间的事件订阅
 * @date 2023/10/19 - 15:16:59
 *
 * @class PageStackTask
 * @typedef {PageStackTask}
 */
class PageStackPubSub {
    #listeners: Listener[] = []

    subscribe<O extends `${string}-${Operation}`>(id: O, callback: Noop): SubscribeReturnType {
        const subscribeId = nanoid()
        this.#listeners.push({ id, subscribeId, callback })

        return {
            subscribeId,
            unSubscribe: (id: string) => {
                this.#listeners = this.#listeners.filter(item => item.subscribeId !== id)
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emit<O extends `${string}-${Operation}`>(id: O, params?: any): void {
        this.#listeners.forEach(listener => {
            if (listener.id === id) {
                listener.callback(params)
            }
        })
    }

    clear(): void {
        this.#listeners = []
    }
}

export const pageStackPubSub = new PageStackPubSub()
