import type { CascadeOption } from '../components/CascadeList'

export function getCascadeFlatOption(option: CascadeOption, list: CascadeOption[]) {
    const { value, label, path, labelPath, children = [] } = option
    let arrList = list
    for (const child of children) {
        const newChild = {
            ...child,
            path: `${path}/${child.value}`,
            labelPath: `${labelPath}/${child.label}`,
            isLast: (child.children ?? []).length === 0
        }
        arrList = [...arrList, newChild, ...getCascadeFlatOption(newChild, [])]
    }
    return arrList
}
