import type { ElementProps, FloatingContext } from '@floating-ui/react'
import { useMemo } from 'react'

export interface UseContextMenuProps {
    enabled?: boolean
}

export const useContextMenu = (context: FloatingContext, props: UseContextMenuProps) => {
    const { open, onOpenChange } = context

    const { enabled = true } = props

    const reference: ElementProps['reference'] = useMemo(
        () => ({
            onMouseDown(e: React.MouseEvent) {
                // e.preventDefault()

                if (e.button === 0 && open) {
                    onOpenChange(false)
                }
            },
            onContextMenu(e: React.MouseEvent) {
                e.preventDefault()
                onOpenChange(!open)
            }
        }),
        [onOpenChange, open]
    )

    return useMemo(() => (enabled ? { reference } : {}), [enabled, reference])
}
