import { Popover } from '@byecode/ui/components/Popover'
import type { NumberRangeArray } from '@lighthouse/core'
import { FilterSelectWay, FilterWay, NumberRangeMode } from '@lighthouse/core'
import { CheckButton, isDateValue, TooltipText } from '@lighthouse/shared'
import { lightFormat } from 'date-fns'
import { find, intersection } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { CUSTOM_VALUE, filterTitleDesktopWidth } from '../constants'
import { CustomDateFilter } from '../CustomDateFilter'
import { CustomNumberFilter } from '../CustomNumberFilter'
import { CustomNumberSliderFilter } from '../CustomNumberSliderFilter'
import { useGetOption } from '../hook'
import { TileCustomFilter } from '../TileCustomFilter'
import type { FilterItemProps } from '../types'
import { getChildrenOptions } from '../utils'
import { TileCascadeFilter } from './TileCascadeFilter'

const SCxTileFilterWrapper = styled.div`
    padding: 6px 0 6px 2px;
    display: flex;
    overflow: hidden;
`

const SCxTileFilterLabel = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
    font-size: 14px;
    flex-shrink: 0;
    font-weight: 600;
    color: var(--color-gray-700);
`

const SCxTileFilterList = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
    flex-wrap: wrap;
`

const SCxCustomNumberSliderFilterWrapper = styled.div`
    padding: 12px 16px;
`

const SCxCustomDateWrapper = styled.div`
    padding-top: 8px;
`

export const TileFilterItem: React.FC<FilterItemProps> = ({
    config,
    showLabel,
    labelWidth,
    value,
    customValue: propsCustomValue,
    recordId,
    dataSourceList,
    onChange,
    onFetchTextOptions,
    onFetchCascadeOptions
}) => {
    const { options } = useGetOption({ value, config, recordId, dataSourceList, onFetchTextOptions, onFetchCascadeOptions })
    const [customValue, setCustomValue] = useState(propsCustomValue)
    const customValueRef = useRef(propsCustomValue)
    const [open, setOpen] = useState(false)
    const handleSelect = useCallback(
        (val?: string) => {
            setCustomValue(customValueRef.current)
            if (!value) {
                return onChange?.(config.id, { type: config.filterWay, value: val ? [val] : [], customValue: customValueRef.current })
            }
            if (value.includes(val || '')) {
                const newValue = value.filter(v => v !== val)
                onChange?.(config.id, { type: config.filterWay, value: newValue, customValue: customValueRef.current })
                return
            }
            if (config.selectWay === FilterSelectWay.singleSelect) {
                return onChange?.(config.id, { type: config.filterWay, value: val ? [val] : [], customValue: customValueRef.current })
            }
            return onChange?.(config.id, {
                type: config.filterWay,
                value: val ? [...value, val] : value,
                customValue: customValueRef.current
            })
        },
        [config.filterWay, config.id, config.selectWay, onChange, value]
    )

    const handleCustomChange = useCallback((val: NumberRangeArray) => {
        // setCustomValue(val)
        customValueRef.current = val
    }, [])

    const regularOptions = useMemo(() => options.filter(item => item.value !== CUSTOM_VALUE), [options])

    const customOption = useMemo(() => find(item => item.value === CUSTOM_VALUE, options), [options])

    const customLabel = useMemo(() => {
        const [start, end] = customValue || []
        const isDateFilter = config.filterWay === FilterWay.dateFilter
        const isDateStartValue = isDateValue(start)
        const isDateEndValue = isDateValue(end)
        const startLabel = isDateFilter && isDateStartValue ? lightFormat(start, 'yyyy-MM-dd') : start ?? ''
        const endLabel = isDateFilter && isDateEndValue ? lightFormat(end, 'yyyy-MM-dd') : end ?? ''

        if (isDateStartValue && isDateEndValue) {
            return `${startLabel} ~ ${endLabel}`
        }
        if (isDateStartValue) {
            return `> ${startLabel}`
        }
        if (isDateEndValue) {
            return `< ${endLabel}`
        }
        return '自定义'
    }, [config.filterWay, customValue])

    const customFilter = useMemo(() => {
        if (config.filterWay === FilterWay.numberFilter) {
            if (config.numberRangeMode === NumberRangeMode.custom) {
                return <CustomNumberFilter customValue={customValueRef.current} onCustomChange={handleCustomChange} />
            }
            if (
                config.numberRangeMode === NumberRangeMode.slider &&
                config.numberRange?.[0] !== undefined &&
                config.numberRange?.[1] !== undefined
            ) {
                return (
                    <SCxCustomNumberSliderFilterWrapper>
                        <CustomNumberSliderFilter
                            customValue={customValueRef.current}
                            min={config.numberRange[0]}
                            max={config.numberRange[1]}
                            step={config.step}
                            onCustomChange={handleCustomChange}
                        />
                    </SCxCustomNumberSliderFilterWrapper>
                )
            }
        }
        if (config.filterWay === FilterWay.dateFilter) {
            return (
                <SCxCustomDateWrapper>
                    <CustomDateFilter customValue={customValue} onCustomChange={handleCustomChange} />
                </SCxCustomDateWrapper>
            )
        }

        return undefined
    }, [config, customValue, handleCustomChange])

    const optionsValues = useMemo(() => {
        if (config.filterWay === FilterWay.cascadeFilter) {
            const childrenOptions = getChildrenOptions(options)
            return childrenOptions.map(item => item.value)
        }
        return options.map(item => item.value)
    }, [config.filterWay, options])

    const handleChange = useCallback(
        (val: string[]) => {
            const realVal = intersection(optionsValues, val)
            return onChange?.(config.id, { type: config.filterWay, value: realVal, customValue: customValueRef.current })
        },
        [config.filterWay, config.id, onChange, optionsValues]
    )

    const handleClear = useCallback(() => {
        const newValue = value?.includes(CUSTOM_VALUE) ? value.filter(v => v !== CUSTOM_VALUE) : value || []
        const newCustomValue: NumberRangeArray = [undefined, undefined]
        setCustomValue(newCustomValue)
        customValueRef.current = newCustomValue
        onChange?.(config.id, { type: config.filterWay, value: newValue, customValue: newCustomValue })
        setOpen(false)
    }, [config.filterWay, config.id, onChange, value])

    const handleValueClear = useCallback(() => {
        onChange?.(config.id, { type: config.filterWay, value: [], customValue: customValueRef.current })
    }, [config.filterWay, config.id, onChange])

    const handleConfirm = useCallback(() => {
        if (!value?.includes(CUSTOM_VALUE)) {
            handleSelect(CUSTOM_VALUE)
            setOpen(false)
            return
        }
        setCustomValue(customValueRef.current)
        onChange?.(config.id, { type: config.filterWay, value, customValue: customValueRef.current })
        setOpen(false)
    }, [config.filterWay, config.id, handleSelect, onChange, value])

    const tileContent = useMemo(() => {
        if (config.filterWay === FilterWay.cascadeFilter) {
            return (
                <TileCascadeFilter
                    options={regularOptions}
                    config={config}
                    value={value || []}
                    onChange={handleChange}
                    onClear={handleValueClear}
                />
            )
        }
        const popoverWidth = config.filterWay === FilterWay.dateFilter ? 300 : 252
        return (
            <SCxTileFilterList>
                {regularOptions.length > 0 && <CheckButton label="不限" active={!value || value.length === 0} onClick={handleValueClear} />}
                {regularOptions.map(option => {
                    return (
                        <CheckButton
                            key={option.value}
                            label={option.label}
                            active={value?.includes(option.value)}
                            onClick={() => handleSelect(option.value)}
                        />
                    )
                })}
                {customOption && (
                    <Popover width={popoverWidth} opened={open} onChange={setOpen} withinPortal>
                        <Popover.Target>
                            <CheckButton
                                active={value?.includes(customOption.value)}
                                key={customOption.value}
                                label={customLabel}
                                isSuffixIcon
                            />
                        </Popover.Target>
                        <Popover.Dropdown>
                            <TileCustomFilter cancelText="清除" onCancel={handleClear} onConfirm={handleConfirm}>
                                {customFilter}
                            </TileCustomFilter>
                        </Popover.Dropdown>
                    </Popover>
                )}
            </SCxTileFilterList>
        )
    }, [
        config,
        customFilter,
        customLabel,
        customOption,
        handleChange,
        handleClear,
        handleConfirm,
        handleSelect,
        handleValueClear,
        open,
        regularOptions,
        value
    ])

    return (
        <SCxTileFilterWrapper>
            {showLabel && (
                <TooltipText
                    title={config.title}
                    render={ref => {
                        return (
                            <SCxTileFilterLabel ref={ref} style={{ width: labelWidth ?? filterTitleDesktopWidth }}>
                                {config.title}
                            </SCxTileFilterLabel>
                        )
                    }}
                />
            )}

            {tileContent}
        </SCxTileFilterWrapper>
    )
}
