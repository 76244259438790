import React, { cloneElement, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePrevious } from 'react-use'

import { getClientRectOmitTransform, parseTransform } from '../utils/transform'
import { useSortableMonitor } from './Context'

export const DragOverlay = (props: React.ComponentPropsWithoutRef<'div'>) => {
    const { activeId, activeRect, initialX, initialY, x, y } = useSortableMonitor()

    return (
        <DropAnimation>
            {createPortal(
                activeId && activeRect.current ? (
                    <div
                        {...props}
                        id={activeId}
                        style={{
                            opacity: 0.8,
                            position: 'fixed',
                            zIndex: 9999,
                            transform: `translate3d(${x - initialX}px, ${y - initialY}px, 0)`,
                            left: activeRect.current.left,
                            top: activeRect.current.top,
                            width: activeRect.current.width,
                            height: activeRect.current.height,
                            pointerEvents: 'none'
                        }}
                    />
                ) : null,
                document.body
            )}
        </DropAnimation>
    )
}

function DropAnimation({ children }: React.PropsWithChildren) {
    const { draggableNodes } = useSortableMonitor()

    const prevChildren = usePrevious<React.ReactElement>(children as React.ReactElement)

    const [element, setElement] = useState<HTMLElement | null>(null)
    const [clonedChildren, setClonedChildren] = useState<React.ReactElement | null>(null)

    if (!children && !clonedChildren && prevChildren) {
        setClonedChildren(prevChildren)
    }

    useEffect(() => {
        if (!element) {
            return
        }

        const id = clonedChildren?.props.id
        if (!id) {
            setClonedChildren(null)
        }

        const dragOverTransform = parseTransform(window.getComputedStyle(element).transform)
        if (!dragOverTransform) {
            return
        }
        const dragOverRect = element.getBoundingClientRect()

        const activeNode = draggableNodes.current.get(id)?.node
        if (!activeNode) {
            setClonedChildren(null)
            return
        }
        const activeRect = getClientRectOmitTransform(activeNode)

        const diff = {
            x: dragOverRect.left - activeRect.left,
            y: dragOverRect.top - activeRect.top
        }
        const final = {
            x: dragOverTransform.x - diff.x,
            y: dragOverTransform.y - diff.y
        }

        const keyframes: Keyframe[] = [
            {
                width: `${dragOverRect.width}px`,
                height: `${dragOverRect.height}px`,
                transform: `translate3d(${dragOverTransform.x}px, ${dragOverTransform.y}px, 0)`
            },
            {
                width: `${activeRect.width}px`,
                height: `${activeRect.height}px`,
                transform: `translate3d(${final.x}px, ${final.y}px, 0)`
            }
        ]
        const animation = element.animate(keyframes, { duration: 250, easing: 'ease' })
        animation.onfinish = () => {
            element.style.opacity = '0'
            setClonedChildren(null)
        }
    }, [clonedChildren?.props.id, draggableNodes, element])

    return (
        <>
            {children}
            {clonedChildren ? cloneElement(clonedChildren, { ref: setElement }) : null}
        </>
    )
}
