import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

interface IconTargetStylesParams {
    disabled?: boolean
    readonly?: boolean
    isEmpty?: boolean
    isCompact?: boolean
    size: number
}

export const useStyles = createStyles(({ size, isCompact }: IconTargetStylesParams) => {
    return {
        root: css({
            position: 'relative',
            borderRadius: 5,
            padding: isCompact ? 0 : 4,
            width: size,
            height: size,
            boxShadow: '0 0 0 1px $colorGray200',
            backgroundColor: '$colorWhite',
            color: '$colorBlack',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            '&:hover': {
                backgroundColor: '#F2F4F5'
            },
            variants: {
                disabled: {
                    true: {
                        cursor: 'not-allowed',
                        opacity: 0.4
                    }
                },
                readonly: {
                    true: {
                        boxShadow: 'none'
                    }
                }
            },
            '&[data-aria-readonly = true]': {
                backgroundColor: 'transparent'
            }
        }),
        close: css({
            position: 'absolute',
            right: -6,
            top: -6,
            width: 12,
            height: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '100%',
            backgroundColor: '$colorGray200'
        })
    }
})
