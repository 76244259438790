import { IconFont, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div``

export const QuickFilterDrawerWrapper = styled.div<{ tintMainColor?: string }>`
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${pointer}

    &:hover {
        background-color: ${({ tintMainColor }) => tintMainColor || 'var(--color-gray-100)'};
        /* transition: background 0.1s ease-in; */
    }
`

export const FilterDrawerIcon = styled(IconFont)``

export const Title = styled.div`
    height: 24px;
    margin-bottom: 16px;
    font-size: var(--font-size-normal);
`

export const List = styled.div`
    flex: 1;
    overflow-y: auto;
    width: 100%;
`

export const TagList = styled.div`
    flex: 1;
    overflow-y: auto;
    width: 100%;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
`

export const TagItem = styled.div<{ isShowBorder?: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 16px;
    color: var(--color-black);
    border-bottom: 1px solid var(--color-gray-200);

    :last-child {
        border: none;
    }
`

export const TagItemContent = styled.div`
    /* margin-right: 6px; */
    font-size: var(--font-size-normal);
    display: flex;
    align-items: center;
`

export const Check = styled(IconFont)`
    color: var(--color-black);
`

export const FinishButton = styled.div``
