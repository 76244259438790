import { IconFont } from '@byecode/ui'
import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

export type SettingButtonProps = {
    children?: React.ReactNode
    icon?: string
    style?: React.CSSProperties
    isActive?: boolean
    color?: string
    size?: number
} & React.DOMAttributes<HTMLDivElement>

const SettingButtonWrapper = styled.div`
    display: inline-flex;
    /* justify-content: center; */
    align-items: center;
    border-radius: 4px;
    height: 24px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    user-select: none;
`

const IconWrapper = styled(IconFont)``

const ButtonContent = styled.div`
    margin-left: 6px;
    color: var(--color-main);
`

const SettingButton = React.forwardRef<HTMLDivElement, SettingButtonProps>(
    ({ icon, style, isActive, color = 'var(--color-main)', size = 16, ...restProps }, ref) => {
        return (
            <SettingButtonWrapper ref={ref} style={style} {...restProps}>
                {icon && <IconWrapper fill={isActive ? color : ''} type={icon} size={size} />}
                {/* {Boolean(text) && <ButtonContent>{text}</ButtonContent>} */}
            </SettingButtonWrapper>
        )
    }
)

export default SettingButton
