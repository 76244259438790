
import type { DataPointerProtocol } from './datasource'
import type { FilterFormType } from './filter'
import type { ChartExportProtocol } from './general'
import type { Position } from './layout'

/**
 * 图表类型
 */
export enum ChartType {
    'bar' = 'bar',
    'line' = 'line',
    'composite' = 'composite',
    'pie' = 'pie',
    'funnel' = 'funnel',
    'indicator' = 'indicator',
    'striation' = 'striation'
}

export type ChartValueCalcType = 'sum' | 'average' | 'max' | 'min' | 'count' | 'countUnique'

export type ChartThemeType = 'single_classic' | 'single_modern' | 'single_business' | 'multi_classic' | 'multi_modern' | 'multi_business'

export interface MarkLine {
    id: string
    type: string
    value: number | undefined
    color: string
}
export interface ChartValue {
    id: string
    fieldId: string
    calcType?: ChartValueCalcType
    chartType?: string
    showArea?: boolean // 折线 显示面积
    showSymbol?: boolean // 折线 显示数据点
    lineType?: 'straight' | 'smooth' // 折线 类型
}

export interface ChartDimension {
    fieldId: string
    id: string
}

export type ChartDimensions = ChartDimension[]

/**
 * 堆叠方式
 */
export type StackType = 'group' | 'stack'

/**
 * 指标卡配置
 */
export interface IndicatorOption extends DataPointerProtocol, ChartExportProtocol {
    // id: string
    showHeadTitle?: boolean // 显示标题
    title?: string // 标题
    // indicator: ChartValue // 指标
    fieldId?: string
    calcType?: ChartValueCalcType
    showLabel?: boolean // 显示指标说明
    label?: string // 指标说明
    labelColor?: string // 指标说明颜色
    showThousandthSplit?: boolean // 显示千分位分隔符
    showUnit?: boolean // 显示单位
    unit?: string // 单位

    aggregate?: boolean // 是否聚合
    ruleFilter?: FilterFormType // 筛选器
    linkFilterController?: FilterFormType
}

/**
 * 图表设置项
 * @export
 * @interface ChartOptionProtocol
 */

export type BaseChartOption = DataPointerProtocol & ChartExportProtocol & {
    dimensions: ChartDimensions // 维度
    mainAxis: ChartValue[] // 主轴 原指标
    aggregate?: boolean // 是否聚合
    ruleFilter?: FilterFormType // 筛选器
    xyAxis?: 'X' | 'Y' // 排序依据  横轴值/纵轴值
    sortMode?: 'ASC' | 'DESC' // 排序规则 正序/倒序
    theme: string
    colors: string[]
    showHeadTitle?: boolean // 显示标题
    title?: string // 标题
    showLegend?: boolean // 显示图例
    legendTranspose?: Position // 图例方向
    linkFilterController?: FilterFormType
}

export type BarChartOption = {
    mainMarkLines: MarkLine[] // 主轴 辅助线
    showAxisTitle?: boolean // 显示坐标轴标题
    xAxisTitle?: string // x坐标轴标题
    yMainAxisTitle?: string // y主轴标题
    stacking?: StackType // 展示方式
    showGridLine?: boolean // 显示网格线
    showAbbreviationAxis?: boolean // 显示缩略轴
    showLabel?: boolean // 显示数值标签

    showSecondaryAxis?: boolean // 显示次轴
    secondaryAxis: ChartValue[] // 次轴
    secondaryMarkLines: MarkLine[] // 次轴 辅助线
    ySecondaryAxisTitle?: string // y次轴标题
}

export type StriationChartOption = {
    mainMarkLines: MarkLine[] // 主轴 辅助线
    showAxisTitle?: boolean // 显示坐标轴标题
    xAxisTitle?: string // x坐标轴标题
    yMainAxisTitle?: string // y主轴标题
    stacking?: StackType // 展示方式
    showGridLine?: boolean // 显示网格线
    showAbbreviationAxis?: boolean // 显示缩略轴
    showLabel?: boolean // 显示数值标签
}

export type BarChartOptionProtocol = Record<'chartType', ChartType.bar | ChartType.line | ChartType.composite> &
    BaseChartOption &
    BarChartOption
export type StriationChartOptionProtocol = Record<'chartType', ChartType.striation> & BaseChartOption & StriationChartOption
export type PieChartOptionProtocol = Record<'chartType', ChartType.pie | ChartType.funnel> & BaseChartOption
export type IndicatorOptionProtocol = Record<'chartType', ChartType.indicator> & IndicatorOption

export type SingleChartOptionProtocol = BarChartOptionProtocol | PieChartOptionProtocol | StriationChartOptionProtocol

export type ChartOptionProtocol = BarChartOptionProtocol | PieChartOptionProtocol | StriationChartOptionProtocol | IndicatorOptionProtocol

export interface Series {
    name: string
    value: string[]
    type: 'bar' | 'line'
    yAxisIndex?: number
}

export type YAxisParam = {
    min: number
    max: number
}
export interface ChartLikeProtocol {
    legend: string[]
    xAxis: string[][]
    // yAxis: YAxisParam[]
    series: Series[]
}
