import React, { createContext, useContext } from 'react'

interface TooltipListContextInterface {
    onWidthChange?: (width: number, id: number | string) => void
    children?: React.ReactNode
}

const TooltipListContext = createContext<TooltipListContextInterface>({})

export const TooltipListContextProvider = ({ children, ...rest }: TooltipListContextInterface) => {
    return <TooltipListContext.Provider value={rest}>{children}</TooltipListContext.Provider>
}

export const useTooltipListContext = () => useContext(TooltipListContext)
