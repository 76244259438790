import { noButtons } from '@byecode/ui'
import type { Field, SchemaProtocol, TableColumns } from '@lighthouse/core'
import { getCachedItem, setCachedItem } from '@lighthouse/tools'
import { clone } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { ApplicationPreviewEnum } from '../../types'
import { useFieldSelectTableContext } from './FiledSelectTableContext'
import { TableField } from './TableField'
import { TableIndex } from './TableIndex'

export interface TableHeaderProps {
    columns: Field[]
    primaryField: string
    scrollRef: React.RefObject<HTMLDivElement>
    onAllRecordSelect?: (selected: boolean) => void
}

export const SCxTableHeader = styled.div<{ backgroundColor?: string }>`
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    width: 100%;
    background-color: var(--color-white);
    overflow: auto hidden;
    ${noButtons('0px')}
`

export const TableHeader = React.forwardRef<HTMLDivElement, TableHeaderProps>(({ columns, primaryField, scrollRef }, ref) => {
    const { previewType } = useFieldSelectTableContext()
    const [firstColumn, ...afterColumns] = columns
    const isMobile = previewType === ApplicationPreviewEnum.mobile
    const [scrollDom, setScrollDom] = useState<HTMLDivElement | null>(scrollRef?.current)

    const estimateSize = useMemo(() => {
        if (!scrollDom || previewType === ApplicationPreviewEnum.desktop) {
            return 160
        }
        const { width } = scrollDom.getBoundingClientRect()
        const renderColumnsNum = columns.length - (isMobile ? 1 : 0)

        return width > renderColumnsNum * 160 ? width / renderColumnsNum : 160
    }, [columns.length, isMobile, previewType, scrollDom])

    useEffect(() => {
        if (!scrollDom) {
            setScrollDom(scrollRef?.current)
        }
    }, [scrollDom, scrollRef])

    return (
        <SCxTableHeader ref={ref}>
            {isMobile || <TableIndex background="var(--color-gray-100)" />}
            {afterColumns.map(field => {
                return <TableField key={field.id} field={field} isPrimaryField={field.id === primaryField} width={estimateSize} />
            })}
        </SCxTableHeader>
    )
})
