import { Popover } from '@byecode/ui/components/Popover'
import { isElement } from '@byecode/ui/utils/isElement'
import React, { forwardRef } from 'react'

export interface MenuTargetProps {
    children: React.ReactNode
}

const ERROR_MESSAGE = `[MenuTarget]'s children must be an React.ReactElement, not React Fragment`

export const MenuTarget = forwardRef<HTMLDivElement, MenuTargetProps>((props, ref) => {
    if (!isElement(props.children)) {
        throw new Error(ERROR_MESSAGE)
    }
    return <Popover.Target ref={ref} {...props} />
})

MenuTarget.displayName = 'Bui-MenuTarget'
