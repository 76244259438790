import clsx from 'clsx'
import type { ChangeEvent } from 'react'
import React, { forwardRef, useCallback, useId } from 'react'

import { useComponentConfig } from '../../hooks/useComponentConfig'
import { useUncontrolled } from '../../hooks/useUncontrolled'
import type { Selectors, StyleComponentProps } from '../../theme/types'
import type { ByecodeSize } from '../../types'
import { Box } from '../Box'
import { useRadioGroupContext } from './context'
import { RadioGroup } from './RadioGroup'
import { RadioStyle as useStyles } from './styles'

export interface RadioProps extends StyleComponentProps<Selectors<typeof useStyles>>, React.ComponentPropsWithoutRef<'div'> {
    size?: ByecodeSize
    label?: React.ReactNode
    name?: string
    checked?: boolean
    value?: string
    disabled?: boolean
    defaultValue?: string
    color?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void

    inputRef?: React.RefObject<HTMLInputElement> | null
    inputProps?: Omit<React.ComponentPropsWithoutRef<'input'>, 'value' | 'defaultValue' | 'onChange'>

    iconProps?: React.ComponentPropsWithoutRef<'div'>
}

type RadioComponent = React.ForwardRefExoticComponent<RadioProps & React.RefAttributes<HTMLDivElement>> & {
    Group: typeof RadioGroup
}

export const Radio = forwardRef<HTMLDivElement, RadioProps>((props, ref) => {
    const {
        color,
        size = 'md',
        name,
        label,
        children,
        inputRef,
        inputProps,
        iconProps,

        checked,
        value: propValue,
        defaultValue: propDefaultValue,
        disabled: _disabled,
        onChange: propOnChange,

        className,
        classNames,
        styles,
        unstyled,
        onClickCapture: propsOnClickCapture,
        ...rest
    } = props
    const { disabled } = useComponentConfig('radio', { disabled: _disabled })
    const [value, onChange] = useUncontrolled({ value: propValue, defaultValue: propDefaultValue })
    const id = useId()

    const ctx = useRadioGroupContext()

    const handleCheckedChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
            const { value } = ev.target
            onChange(value)
            propOnChange?.(ev)
        },
        [onChange, propOnChange]
    )

    const internalInputProps = ctx
        ? {
              name: ctx.name || name,
              checked: ctx.value === value,
              onChange: (e: ChangeEvent<HTMLInputElement>) => ctx.onChange(e.target.value)
          }
        : { checked, onChange: handleCheckedChange }

    const { classes } = useStyles(
        { size, color, checked: internalInputProps.checked, disabled },
        { name: 'radio', classNames, styles, unstyled }
    )
    const { className: inputClassName, ...restInputProps } = inputProps ?? {}
    const { className: iconClassName, ...restIconProps } = iconProps ?? {}

    const onClickCapture = useCallback(
        (e: React.MouseEvent<HTMLInputElement>) => {
            if (e.target instanceof Element && e.target.tagName !== 'INPUT') {
                e.stopPropagation()
            }

            propsOnClickCapture?.(e)
        },
        [propsOnClickCapture]
    )

    return (
        <Box className={clsx(className, classes.root)} ref={ref} {...rest} onClickCapture={onClickCapture}>
            <Box className={clsx(classes.radio)}>
                <input
                    ref={inputRef}
                    type="radio"
                    id={id}
                    disabled={disabled}
                    className={clsx(inputClassName, classes.input)}
                    value={value}
                    {...restInputProps}
                    {...internalInputProps}
                />
                <Box className={clsx(iconClassName, classes.icon)} {...restIconProps} />
                {children && <Box className={clsx(classes.inner)}>{children}</Box>}
            </Box>

            {label && (
                <Box component="label" htmlFor={id} className={clsx(classes.label)}>
                    {label}
                </Box>
            )}
        </Box>
    )
}) as RadioComponent

Radio.Group = RadioGroup
