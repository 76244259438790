import type { ApplicationSettingAuthenticationField, DataSourceAbstract, Field } from '@lighthouse/core'

import type { ApplicationPreviewEnum } from '../../types'
import { getEmptyCellValue, getFieldInputTypeByFieldType, getTranslation } from '../../utils'
import type { FieldInputConfig } from '../FieldInput'
import { commonFieldInputConfig } from './constant'

export const getFields = (fields: ApplicationSettingAuthenticationField[], dataSource?: DataSourceAbstract) =>
    fields.map(item => {
        const field = dataSource?.schema?.[item.fieldId]
        return { ...item, value: getEmptyCellValue(field?.type ?? 'text') }
    })

export const getFieldInputConfig = (params: {
    previewType?: ApplicationPreviewEnum
    field?: Field
    config: ApplicationSettingAuthenticationField
}):FieldInputConfig => {
    const { config, previewType, field } = params
    const { fieldId, title, placeHolder, required } = config
    switch (field?.type) {
        case 'role': {
            return {
                fieldPointer: fieldId,
                placeholder: placeHolder ?? getTranslation('pleaseFillOut'),
                title,
                previewType,
                required,
                ...commonFieldInputConfig,
                inputType: 'relativeSelect',
                relativeSelect: {
                    showType: 'list',
                    viewFieldSettings: [],
                    canCreateRecord: false,
                    direction: 'vertical',
                    showMode: 'input',
                    relativePointer: 'ROLE_DATASOURCE',
                    relativeFieldPointer: 'ID',
                    relativeShowFieldPointer: 'ROLE_NAME'
                }
            }
        }
        case 'department': {
            return  {
                fieldPointer: fieldId,
                placeholder: placeHolder ?? getTranslation('pleaseFillOut'),
                title,
                previewType,
                required,
                ...commonFieldInputConfig,
                inputType: 'cascade',
                cascade: {
                    cascadeFieldPointer: 'ID',
                    cascadeShowFieldPointer: 'DEPARTMENT_NAME',
                    cascadePointer: 'DEPARTMENT_DATASOURCE',
                    parentFieldPointer: 'PARENT_DEPARTMENT',
                    isLastLevel: false,
                    showPath: true
                }
            }
        }
        default: {
            return {
                fieldPointer: fieldId,
                placeholder: placeHolder ?? getTranslation('pleaseFillOut'),
                title,
                previewType,
                required,
                ...commonFieldInputConfig,
                inputType: getFieldInputTypeByFieldType(field?.type ?? 'text')
            } as FieldInputConfig
        }
    }
}
