import { MobileDatePickerView, MobileModal, pointer } from '@byecode/ui'
import type { DateValue } from '@lighthouse/core'
import type { DrawerProps } from '@mantine/core'
import { isValid } from 'date-fns'
import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'


const SCxDatePicker = styled(MobileDatePickerView)`

    .byecode-m-DatePicker-confirm {
        color: var(--color-app-main);
    }
    .byecode-m-DatePicker-cancel {
        color: var(--color-app-main);
    }
`
interface DateFieldDrawerProps {
    opened: boolean
    value?: DateValue
    target?: DrawerProps['target']
    showTime?: boolean
    maxDate?: Date
    minDate?: Date
    onChange?: (val: DateValue) => void
    onClose: () => void
}

export const DateDrawer: React.FunctionComponent<DateFieldDrawerProps> = ({
    opened,
    value,
    target,
    showTime,
    minDate,
    maxDate,
    onClose,
    onChange
}) => {
    const dateData = useMemo(() => (value && isValid(new Date(Number(value))) ? new Date(Number(value)) : undefined), [value])

    const handleFinish = useCallback(
        (date: Date) => {
            onChange?.(date.valueOf())
            onClose()
        },
        [onClose, onChange]
    )

    const handleCancel = useCallback(() => {
        onClose()
    }, [onClose])

    return (
        <MobileModal
            data-ignore-click-away
            target={target}
            open={opened}
            withCloseIcon={false}
            styles={{
                root: {
                    padding: 0
                },
                body: {
                    padding: '0!important',
                    overflow: 'hidden'
                },
                modal: {
                    height: '274px'
                }
            }}
            onClose={onClose}

        >
            <SCxDatePicker
                showTime={showTime}
                disableSecond
                value={dateData}
                maxDate={maxDate}
                minDate={minDate}
                onConfirm={handleFinish}
                onCancel={handleCancel}
            />
        </MobileModal>
    )
}
