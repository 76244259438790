import { Node } from '@tiptap/core'

export const UploadImagePlaceholder = Node.create({
    name: 'uploadImagePlaceholder',
    draggable: false,

    inline: false,
    group: 'block',

    addAttributes() {
        return {
            uploadId: {
                default: ''
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'div',
                getAttrs: node => {
                    const uploadId = node.getAttribute('data-upload-id')
                    if (!uploadId) {
                        return false
                    }

                    return { uploadId }
                }
            }
        ]
    },

    renderHTML({ node }) {
        const { uploadId } = node.attrs
        return ['div', { 'data-upload-id': uploadId }]
    }
})
