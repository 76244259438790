import type { ContainerRef, Scope, ScopeModel } from './types'

export const DEFAULT_SCOPE = 'toast'
export const SCOPE_KEY = 'toastStack'
declare global {
    interface Window {
        [SCOPE_KEY]: ScopeModel
    }
}

if (typeof window !== 'undefined' && !window[SCOPE_KEY]) {
    window[SCOPE_KEY] = {}
}

export const registerContainer = (scope: Scope, reference: ContainerRef) => {
    window[SCOPE_KEY][scope] = reference
    return reference
}

export const unregisterContainer = (scope: Scope) => {
    delete window[SCOPE_KEY][scope]
}

export const getContainer = (scope?: Scope) => {
    return window[SCOPE_KEY][scope || DEFAULT_SCOPE]
}
