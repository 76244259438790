import { Toast } from '@byecode/ui'
import { Button } from '@byecode/ui/components/Button'
import { Input } from '@byecode/ui/components/Input'
import { SegmentedControl } from '@byecode/ui/components/SegmentedControl'
import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import { Flex } from '@mantine/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { ApplicationPreviewEnum, I18TranslationKey } from '../../../types'
import { Code } from '../Code'
import { REGISTER_WAY_OPTIONS } from '../constant'
import { getFields } from '../helps'
import { useFormRuleMap, useSendCode } from '../hooks'
import LoginBox from '../LoginBox'
import { PerfectInfo } from '../PerfectInfo'
import * as CM from '../styles'
import { type AccountRegisterForm, type AppLoginMode, type SendCodeType, LoginAuthType } from '../types'

const SCxInput = styled(Input)`
    &:focus-within {
        border-color: var(--color-app-main);
    }
`
interface RegisterProps {
    authentication: ApplicationSettingAuthentication
    previewType?: ApplicationPreviewEnum
    disableEvent?: boolean
    language: string
    onChangeLanguage: (v: string) => void
    onRegister?: (params: AccountRegisterForm) => void
    onSendCode?: (v: SendCodeType, sendCodeInfo: string) => Promise<boolean> | undefined
    onChangeMode: (v: AppLoginMode) => void
}

interface RegisterFormProps {
    type: SendCodeType
    authentication: ApplicationSettingAuthentication
    previewType?: ApplicationPreviewEnum
    disableEvent?: boolean
    language: string
    onChangeLanguage: (v: string) => void
    onChangeMode: (v: AppLoginMode) => void
    handleGetCodeHandle: (resolve?: () => void, reject?: () => void) => Promise<void>
}
type History = {
    value: 'code' | 'perfect' | 'register'
    label: string
}
const RegisterForm: React.FunctionComponent<RegisterFormProps> = ({
    type,
    authentication,
    previewType,
    disableEvent,
    language,
    onChangeLanguage,
    handleGetCodeHandle,
    onChangeMode
}) => {
    const { state, getPhoneCode, setState } = useSendCode(type, handleGetCodeHandle)
    const {
        register,
        control,
        watch,
        reset,
        getValues,
        formState: { errors }
    } = useFormContext()

    const { t } = useTranslation()

    const formRuleMap = useFormRuleMap()

    const { name, describe, logo, perfect, login, register: registerInfo } = authentication

    const info = useMemo(() => ({ email: { placeholder: t('emailUrl') }, mobile: { placeholder: t('mobileCode') } }[type]), [t, type])

    const options = useMemo(() => REGISTER_WAY_OPTIONS.map(item => ({ ...item, label: t(item.label as I18TranslationKey) })), [t])

    const registerWays = useMemo(
        () => [login.email.isOpened, login.phone.isOpened].filter(Boolean),
        [login.email.isOpened, login.phone.isOpened]
    )

    const [history, setHistory] = useState<History[]>([])

    const lastPage = history.at(-1)

    const handleGetPhoneCode = useCallback(() => {
        if (!formRuleMap[type].pattern.value.test(getValues(type))) {
            Toast.error(formRuleMap[type].pattern.message)
            return
        }

        if (!disableEvent) {
            getPhoneCode()
            setHistory(draft => [...draft, { label: t('verificationCode'), value: 'code' }])
        }
    }, [formRuleMap, type, getValues, disableEvent, getPhoneCode, t])

    return useMemo(() => {
        switch (lastPage?.value) {
            case 'code': {
                return (
                    <Code
                        type={type}
                        account={getValues(type)}
                        authentication={authentication}
                        label={state.codeText}
                        disabled={state.codeStep > 0 || state.disable}
                        language={language}
                        onChangeLanguage={onChangeLanguage}
                        onSendCode={getPhoneCode}
                        onBack={() => setHistory(draft => [...draft, { label: t('register'), value: 'register' }])}
                    >
                        <Button
                            block
                            style={{
                                height: 44,
                                marginTop: 26,
                                backgroundColor: 'var(--color-app-main-tint)',
                                color: '#fff',
                                borderStyle: 'none'
                            }}
                            type="primary"
                            radius={10}
                            htmlType="submit"
                        >
                            {t('register')}
                        </Button>
                    </Code>
                )
            }
            default: {
                return (
                    <LoginBox
                        name={t('registerAccount')}
                        logo={logo}
                        describe={registerWays.length > 1 ? t('selectRegisterWay') : ''}
                        dividerLabel={t('alreadyHaveAnAccount')}
                        language={language}
                        previewType={previewType}
                        onChangeLanguage={onChangeLanguage}
                        onBack={() => {
                            onChangeMode('home')
                            reset({ email: '', mobile: '', code: '' })
                        }}
                        customBtn={
                            <Button
                                block
                                style={{
                                    height: 44,
                                    marginTop: 26,
                                    backgroundColor: 'var(--color-app-main-tint)',
                                    color: 'var(--color-app-main)',
                                    borderStyle: 'none'
                                }}
                                radius={10}
                                type="primary"
                                onClick={() => onChangeMode('home')}
                            >
                                {t('goLogin')}
                            </Button>
                        }
                    >
                        {registerWays.length > 1 && (
                            <Flex justify="center">
                                <Controller
                                    control={control}
                                    name="authType"
                                    render={({ field }) => (
                                        <SegmentedControl
                                            size="lg"
                                            data={options}
                                            value={field.value.toString()}
                                            onChange={v => {
                                                field.onChange(Number(v))
                                            }}
                                        />
                                    )}
                                />
                            </Flex>
                        )}
                        <SCxInput
                            {...register(type, formRuleMap[type])}
                            style={{ height: 44, marginTop: 32, borderRadius: 10, borderColor: 'var(--color-app-main)' }}
                            placeholder={info.placeholder}
                            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                        />
                        <Button
                            block
                            style={{
                                height: 44,
                                marginTop: 16,
                                backgroundColor: 'var(--color-app-main)',
                                color: 'var(--color-white)',
                                borderStyle: 'none'
                            }}
                            type="primary"
                            radius={10}
                            disabled={state.codeStep > 0 || state.disable}
                            onClick={handleGetPhoneCode}
                        >
                            {state.codeText}
                        </Button>
                    </LoginBox>
                )
            }
        }
    }, [
        lastPage?.value,
        type,
        getValues,
        authentication,
        state.codeText,
        state.codeStep,
        state.disable,
        language,
        onChangeLanguage,
        getPhoneCode,
        t,
        logo,
        registerWays.length,
        previewType,
        control,
        register,
        formRuleMap,
        info.placeholder,
        handleGetPhoneCode,
        onChangeMode,
        reset,
        options
    ])
}

export const Register: React.FunctionComponent<RegisterProps> = ({
    authentication,
    previewType,
    disableEvent,
    language,
    onChangeLanguage,
    onChangeMode,
    onSendCode,
    onRegister
}) => {
    const {
        perfect: { commonSetting },
        perfect,
        login
    } = authentication

    const defaultAuthType = useMemo(() => {
        const registerWays: [LoginAuthType, boolean][] = [
            [LoginAuthType.mobile, login.phone.isOpened],
            [LoginAuthType.email, login.email.isOpened]
        ]
        return registerWays.find(([authType, isOpened]) => isOpened)?.[0]
    }, [login.email.isOpened, login.phone.isOpened])

    const defaultValues: AccountRegisterForm = useMemo(
        () => ({
            code: '',
            email: '',
            mobile: '',
            authType: defaultAuthType ?? LoginAuthType.mobile
        }),
        [defaultAuthType]
    )

    const methods = useForm<AccountRegisterForm>({ mode: 'onSubmit', defaultValues })

    const { handleSubmit, reset, getValues, control, watch } = methods

    const authType = watch('authType')

    useEffect(() => {
        const { unsubscribe } = watch(value => {
            if ((value?.code ?? '').length === 6) {
                onRegister?.(value as AccountRegisterForm)
            }
        })
        return unsubscribe
    }, [onRegister, watch])

    const handleGetCodeHandle = useCallback(
        async (resolve?: () => void, reject?: () => void) => {
            if (authType === LoginAuthType.email) {
                const result = await onSendCode?.('email', getValues('email'))
                return result ? resolve?.() : reject?.()
            }
            if (authType === LoginAuthType.mobile) {
                const result = await onSendCode?.('mobile', getValues('mobile'))
                return result ? resolve?.() : reject?.()
            }
            return reject?.()
        },
        [authType, getValues, onSendCode]
    )

    const handleSubmitFrom = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            onRegister && handleSubmit(onRegister)()
        },
        [handleSubmit, onRegister]
    )

    const ele = useMemo(() => {
        if (authType === LoginAuthType.email) {
            return (
                <RegisterForm
                    type="email"
                    authentication={authentication}
                    previewType={previewType}
                    disableEvent={disableEvent}
                    language={language}
                    onChangeLanguage={onChangeLanguage}
                    handleGetCodeHandle={handleGetCodeHandle}
                    onChangeMode={onChangeMode}
                />
            )
        }
        if (authType === LoginAuthType.mobile) {
            return (
                <RegisterForm
                    type="mobile"
                    authentication={authentication}
                    previewType={previewType}
                    disableEvent={disableEvent}
                    language={language}
                    onChangeLanguage={onChangeLanguage}
                    handleGetCodeHandle={handleGetCodeHandle}
                    onChangeMode={onChangeMode}
                />
            )
        }
        return null
    }, [authType, authentication, disableEvent, handleGetCodeHandle, language, onChangeLanguage, onChangeMode, previewType])

    return (
        <CM.FormContainer onSubmit={handleSubmitFrom}>
            <FormProvider {...methods}>{ele}</FormProvider>
        </CM.FormContainer>
    )
}
