import type { ApplicationSettingNavbarButton, ApplicationSettingTheme, ButtonEvents, ButtonShowType } from '@lighthouse/core'
import { Collapse } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import React, { useEffect, useMemo } from 'react'

import { ApplicationLinkItem } from '../ApplicationLinkItem'
import { CollapseManager } from '../CollapseManager'
import { getNavBtnAccess } from '../help'
import * as SC from './styles'

export interface ApplicationMultistageCollapseProps {
    target?: React.ReactNode
    value: ApplicationSettingNavbarButton
    activePage?: string
    level: number
    // position: 'bottom' | 'right'
    theme?: ApplicationSettingTheme
    dataNavLink?: string
    showType?: ButtonShowType
    dataList: Record<string, ApplicationSettingNavbarButton>
    // collapseAtom: ReturnType<typeof atom<Record<string, boolean>>>
    style?: React.CSSProperties

    onCommonCallBack?: (params: ButtonEvents) => void
    onChangeSize?: (
        id: string,
        size: {
            width: number
            height: number
        }
    ) => void
}

interface SelectItemProps {
    dataList: Record<string, ApplicationSettingNavbarButton>
    value: ApplicationSettingNavbarButton
    level: number
    showType?: ButtonShowType
    activePage?: string
    opened: boolean
    collapseMap: Record<string, boolean>
    onCommonCallBack?: (params: ButtonEvents) => void
}

const SelectItem: React.FC<SelectItemProps> = ({ value, dataList, level, showType, opened, collapseMap, ...rest }) => {
    const { child = [], icon, id, isAccess } = value

    if (!getNavBtnAccess(value, dataList)) {
        return null
    }

    return (
        <SC.PrimaryContain data-level={level}>
            <ApplicationLinkItem
                style={{ paddingLeft: 30 * level }}
                value={value}
                isSubNode={child.length > 0}
                opened={opened}
                onFold={() => CollapseManager.setItemCollapse(id, !opened)}
                {...rest}
            />
            {opened && child.length > 0 && (
                <Collapse in={opened}>
                    {child.map(nodeId => (
                        <SelectItem
                            key={nodeId}
                            collapseMap={collapseMap}
                            opened={collapseMap[nodeId]}
                            level={level + 1}
                            value={dataList[nodeId]}
                            dataList={dataList}
                            {...rest}
                        />
                    ))}
                </Collapse>
            )}
        </SC.PrimaryContain>
    )
}

const ApplicationMultistageCollapse: React.FC<ApplicationMultistageCollapseProps> = props => {
    const { value, dataList, style, onChangeSize, level, ...rest } = props
    const collapseMap = CollapseManager.useCollapse()
    const { ref: linkBtnRef, width, height } = useElementSize<HTMLDivElement>()

    const { id, child = [], icon } = value
    const opened = collapseMap[id]

    useEffect(() => {
        onChangeSize?.(id, {
            width,
            height
        })
    }, [height, id, onChangeSize, width])

    const enableCollapse = useMemo(() => child.some(nodeId => dataList[nodeId].isAccess), [child, dataList])

    if (!getNavBtnAccess(value, dataList)) {
        return null
    }
    return (
        <>
            <SC.TargetContainer ref={linkBtnRef} style={style}>
                <ApplicationLinkItem
                    value={value}
                    onFold={() => CollapseManager.setItemCollapse(id, !opened)}
                    opened={opened}
                    isSubNode={child.length > 0}
                    {...rest}
                />
            </SC.TargetContainer>
            <Collapse in={opened && enableCollapse}>
                <SC.Container>
                    {child.map(nodeId => (
                        <SelectItem
                            key={nodeId}
                            opened={collapseMap[nodeId]}
                            collapseMap={collapseMap}
                            value={dataList[nodeId]}
                            dataList={dataList}
                            level={1}
                            {...rest}
                        />
                    ))}
                </SC.Container>
            </Collapse>
        </>
    )
}

export default ApplicationMultistageCollapse
