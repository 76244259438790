import { IconFont } from '@byecode/ui'
import { ActionIcon } from '@mantine/core'
import React, { useCallback } from 'react'
import styled from 'styled-components'

interface PageFooterProps {
    current: number
    count: number
    onPageChange?: (v: number) => void
}

export const SCxStyledFixedFooter = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: var(--color-gray-25);
    color: var(--color-gray-900);
    user-select: none;
`

export const SCxPageNumWrapper = styled.div`
    font-size: var(--font-size-normal);
`

export const PageFooter: React.FC<PageFooterProps> = ({ current, count, onPageChange }) => {
    const handlePrevPage = useCallback(() => {
        onPageChange?.(current - 1)
    }, [current, onPageChange])

    const handleNextPage = useCallback(() => {
        onPageChange?.(current + 1)
    }, [current, onPageChange])

    const prevDisabled = current <= 0
    const nextDisabled = current >= count

    return (
        <SCxStyledFixedFooter>
            <ActionIcon disabled={prevDisabled} onClick={handlePrevPage}>
                <IconFont size={18} color={prevDisabled ? 'var(--color-gray-300)' : 'var(--color-gray-600)'} type="ArrowLeftSmall" />
            </ActionIcon>
            <SCxPageNumWrapper>
                {current + 1} / {count + 1}
            </SCxPageNumWrapper>
            <ActionIcon disabled={nextDisabled} onClick={handleNextPage}>
                <IconFont size={18} color={nextDisabled ? 'var(--color-gray-300)' : 'var(--color-gray-600)'} type="ArrowRightSmall" />
            </ActionIcon>
        </SCxStyledFixedFooter>
    )
}
