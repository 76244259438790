import { Button, Flex, Input, MobileModal, Modal, Text, Toast } from '@byecode/ui'
import type { ApplicationSettingAuthentication, IconDecorationProtocol } from '@lighthouse/core'
import { clone } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useImmer } from 'use-immer'

import type { I18TranslationKey } from '../../../types'
import { ApplicationPreviewEnum } from '../../../types'
import { Code } from '../Code'
import { useFormRuleMap, useSendCode } from '../hooks'
import LoginBox from '../LoginBox'
import * as CM from '../styles'
import { type AccountBindAccountForm, type AccountMergeAccount, type AppLoginMode, LoginAuthType } from '../types'

interface BindMobileProps {
    authentication: ApplicationSettingAuthentication
    language: string
    previewType?: ApplicationPreviewEnum
    onChangeLanguage: (v: string) => void
    onChangeMode: (v: AppLoginMode) => void
    onSendCode?: (v: 'email' | 'mobile', sendCoeInfo: string) => Promise<boolean> | undefined
    onBindAccount?: (v: AccountBindAccountForm) => Promise<boolean>
    onMergeAccount?: (params: AccountMergeAccount) => void
}

const INIT_ROUTES = [{ label: '', value: 'mobile' }]

export const BindMobile: React.FunctionComponent<BindMobileProps> = ({
    authentication,
    language,
    previewType,
    onChangeLanguage,
    onChangeMode,
    onSendCode,
    onBindAccount
}) => {
    const {
        register: { allowRegister },
        name,
        logo,
        describe
    } = authentication

    const methods = useForm<AccountBindAccountForm>({
        mode: 'onSubmit',
        shouldFocusError: false,
        defaultValues: {
            type: LoginAuthType.mobile
        }
    })
    const { getValues, register, watch, setValue } = methods

    const { t } = useTranslation<I18TranslationKey>()

    // const [openedCode, setOpenedCode] = useState(false)
    const [routes, setRoutes] = useImmer(INIT_ROUTES)
    const [opened, setOpened] = useState(false)

    const route = routes.at(-1)

    const handleGetCodeHandle = useCallback(
        async (resolve?: () => void, reject?: () => void) => {
            setRoutes(draft => {
                draft.push({ label: '', value: 'code' })
            })
            const result = await onSendCode?.('mobile', getValues('account') ?? '')
            return result ? resolve?.() : reject?.()
        },
        [getValues, onSendCode, setRoutes]
    )

    const { state, getPhoneCode } = useSendCode('mobile', handleGetCodeHandle)

    const formRuleMap = useFormRuleMap()

    const handleGetPhoneCode = useCallback(() => {
        if (!formRuleMap['mobile'].pattern.value.test(getValues('account') ?? '')) {
            Toast.error(formRuleMap['mobile'].pattern.message)
            return
        }
        getPhoneCode()
    }, [formRuleMap, getPhoneCode, getValues])

    const handleBindMobile = useCallback(
        async (value: AccountBindAccountForm) => {
            const res = await onBindAccount?.(clone(value))
            if (!res) {
                if (previewType === ApplicationPreviewEnum.desktop) {
                    const res = await Modal.confirm({
                        title: t('bindMeetQuestion'),
                        okStatus: 'primary',
                        okText: t('changeAccount', { account: t('mobileNumber') }),
                        cancelText: t('cancel'),
                        content: t('bindAccountAfterAccountNotEnable', { account: t('mobileNumber') })
                    })
                    if (res) {
                        setValue('code', '')
                        setValue('account', '')
                        setRoutes(draft => {
                            draft.splice(-1)
                        })
                    }
                    return
                }
                setOpened(true)
            }
        },
        [onBindAccount, previewType, setRoutes, setValue, t]
    )

    useEffect(() => {
        const { unsubscribe } = watch(value => {
            if (value.code?.length === 6) {
                handleBindMobile(clone(value) as AccountBindAccountForm)
            }
        })
        return unsubscribe
    }, [handleBindMobile, watch])

    const ele = useMemo(() => {
        switch (route?.value) {
            case 'code': {
                return (
                    <Code
                        type="mobile"
                        authentication={authentication}
                        label={state.codeText}
                        account={getValues('account') ?? ''}
                        disabled={state.codeStep > 0 || state.disable}
                        language={language}
                        previewType={previewType}
                        onChangeLanguage={onChangeLanguage}
                        onSendCode={getPhoneCode}
                        onBack={() => {
                            setRoutes(draft => {
                                draft.splice(-1)
                            })
                        }}
                    />
                )
            }
            default: {
                return (
                    <LoginBox
                        name={t('bindMobile')}
                        logo={logo}
                        describe={t('firstLoginNeedAccount', { account: t('mobileNumber') })}
                        language={language}
                        previewType={previewType}
                        onChangeLanguage={onChangeLanguage}
                        onBack={() => onChangeMode('home')}
                    >
                        <Input
                            {...register('account', formRuleMap['mobile'])}
                            style={{ height: 44, borderRadius: 10 }}
                            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                            placeholder={t('pleaseInputYourMobile')}
                        />
                        <Button
                            block
                            style={{
                                height: 44,
                                marginTop: 16,
                                backgroundColor: 'var(--color-app-main)',
                                color: 'var(--color-white)',
                                borderStyle: 'none'
                            }}
                            type="primary"
                            radius={10}
                            disabled={state.codeStep > 0 || state.disable}
                            onClick={handleGetPhoneCode}
                        >
                            {state.codeText}
                        </Button>
                    </LoginBox>
                )
            }
        }
    }, [
        route?.value,
        authentication,
        state.codeText,
        state.codeStep,
        state.disable,
        getValues,
        language,
        previewType,
        onChangeLanguage,
        getPhoneCode,
        setRoutes,
        t,
        logo,
        register,
        formRuleMap,
        handleGetPhoneCode,
        onChangeMode
    ])

    return (
        <CM.FormContainer>
            <FormProvider {...methods}>{ele}</FormProvider>
            <MobileModal
                title={t('bindMeetQuestion')}
                open={opened}
                onClose={() => setOpened(false)}
                styles={{
                    modal: {
                        height: 'auto!important',
                        backgroundColor: 'var(--color-white)'
                    },
                    actions: {
                        marginLeft: 'unset!important'
                    }
                }}
            >
                <div>
                    <Text whiteSpace="pre-wrap" size={16} color="var(--color-gray-600)">
                        {t('bindAccountAfterAccountNotEnable', { account: t('mobileNumber') })}
                    </Text>
                    <Button
                        onClick={() => {
                            setValue('code', '')
                            setValue('account', '')
                            setOpened(false)
                            setRoutes(draft => {
                                draft.splice(-1)
                            })
                        }}
                        type="primary"
                        style={{ marginTop: 20, height: 40, backgroundColor: 'var(--color-app-main)' }}
                        block
                    >
                        {t('changeAccount', { account: t('mobileNumber') })}
                    </Button>
                </div>
            </MobileModal>
        </CM.FormContainer>
    )
}
