// import { IframeBlock } from '@lighthouse/block'
import type { IframeBlockAbstract } from '@lighthouse/core'
import React, { Suspense } from 'react'

const IframeBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.IframeBlock })))

interface IframeBlockControllerProps {
    blockData: IframeBlockAbstract
}

const IframeBlockController: React.FC<IframeBlockControllerProps> = ({ blockData }) => {
    return (
        <Suspense fallback={<div />}>
            <IframeBlock blockData={blockData} />
        </Suspense>
    )
}

export default IframeBlockController
