import {
    type DataSourceAbstract,
    type Field,
    type FieldADTValue,
    type FieldMember,
    type FieldValue,
    type RecordLikeProtocol
} from '@lighthouse/core'
import { COLORS, fieldConvertValue, getAppointField, getIsAppointField, NO_EDIT_SYSTEM_FIELD,PRESET_PALETTES } from '@lighthouse/shared'
import { eachDayOfInterval, format, getWeek, getYear, isAfter, isSameDay, parse } from 'date-fns'

import type { TimeLineInfo } from './TimeLine/TimeLine.type'

/**
 * 获取第一个选项字段的选项颜色
 * @param schema
 * @returns
 */
const getFirstSelectField = (schema: { [key: string]: Field }) => {
    // if (field) {
    //     const colors = field.select.options
    //         .map(item => item.color && COLORS.find(c => c.name === item.color)?.value)
    //         .filter(Boolean) as string[]
    //     if (colors.length > 0) {
    //         optionColors = colors.slice(0, maxLength)
    //     }
    // }

    // return optionColors
    return Object.values(schema).find(item => item.type === 'select' && !getIsAppointField(item.id, NO_EDIT_SYSTEM_FIELD)) as
        | undefined
        | FieldMember<'select'>
}

/**
 * 获取字段值
 * @param fieldValue
 * @param fieldSchema
 * @returns
 */
export const getFieldValue = (fieldValue: FieldValue | undefined, fieldSchema: Field | undefined): string => {
    if (!fieldValue || !fieldSchema) {
        return ''
    }

    return fieldConvertValue({ ...fieldSchema, value: fieldValue.value } as FieldADTValue)
}

/** 检查字段是否有效 */
export const checkField = (key: string | undefined, schema: DataSourceAbstract['schema']): key is string =>
    !(!key || !Object.hasOwn(schema, key))

/** 检查日期字段是否有效 */
export const checkDateField = ({
    key,
    schema,
    primaryDataSourceFieldIds
}: {
    key?: string
    schema: DataSourceAbstract['schema']
    primaryDataSourceFieldIds?: Set<string>
}): boolean => {
    if (!key) {
        return false
    }
    const field = schema[key]
    return field.type === 'date' && !getIsAppointField(field.id, NO_EDIT_SYSTEM_FIELD) && !!primaryDataSourceFieldIds?.has(field.id)
}

type GetTimeLinesConfig = {
    dataSource: DataSourceAbstract
    titleField?: string
    startField?: string
    endField?: string
    color?: string
    colorMode?: 'follow' | 'custom'
}

/**
 * 计算需要生成的日程数据
 * @param records 记录
 * @param config 日程配置
 * @returns
 */
export const getTimeLines = (records: RecordLikeProtocol[], config: GetTimeLinesConfig) => {
    const { dataSource, titleField, startField, endField, colorMode = 'follow', color = PRESET_PALETTES.purple } = config
    const { schema } = dataSource
    if (!checkField(titleField, schema) || !checkField(startField, schema)) {
        return []
    }

    // 获取第一个选项字段的选项颜色
    const selectField = getFirstSelectField(schema)

    const createTimeField = getAppointField(dataSource, 'createdTime')

    return (
        records
            .map(record => {
                const title = getFieldValue(record.content[titleField], schema[titleField])
                const startDateSchema = schema[startField]
                const endDateSchema = schema[endField || startField]
                const startDateStr = getFieldValue(record.content[startField], startDateSchema)
                const endDateStr = endField ? getFieldValue(record.content[endField], endDateSchema) : startDateStr

                const startDateFormat = startDateSchema.type === 'date' ? startDateSchema.date.format ?? 'yyyy-MM-dd' : undefined
                const endDateFormat = endField
                    ? endDateSchema.type === 'date'
                        ? endDateSchema.date.format ?? 'yyyy-MM-dd'
                        : undefined
                    : startDateFormat

                if (!startDateStr || !endDateStr) {
                    return false
                }
                const startDate = startDateFormat ? parse(startDateStr, startDateFormat, new Date()) : undefined
                const endDate = endDateFormat ? parse(endDateStr, endDateFormat, new Date()) : undefined

                if (!startDate || !endDate || isAfter(startDate, endDate) || !createTimeField) {
                    return false
                }

                const dates = eachDayOfInterval({ start: startDate, end: endDate })

                return {
                    id: record.id,
                    record,
                    index: 0,
                    title,
                    originDates: dates,
                    dates,
                    createdTime: record.content[createTimeField.id].value as number
                }
            })
            .filter(Boolean) as Omit<TimeLineInfo, 'color'>[]
    ).map(item => {
        // 12.29 经过克林指导获取第一个选项的label，然后通过label取字段的颜色
        if (colorMode === 'follow') {
            if (!selectField) {
                return {
                    ...item,
                    color: ''
                }
            }
            const firstSelectFieldValue = item.record.content?.[selectField.id]?.value
            const optionLabel = Array.isArray(firstSelectFieldValue) ? firstSelectFieldValue?.[0] : ''

            const selectColorName = selectField?.select.options.find(option => option.label === optionLabel)?.color ?? ''
            const selectColor = COLORS.find(item => item.name === selectColorName)?.value ?? ''
            return {
                ...item,
                color: selectColor
            }
        }

        return {
            ...item,
            color
            // color: colorMode === 'custom' ? color : optionColors[lastIndex === 0 ? 0 : index % lastIndex]
        }
    })
}

/**
 * 根据日程及当前日历生成 每周的日程数据
 * @param timeLines 所有日程
 * @param calendarDays 当前日历的单元格数据
 * @returns
 */
export const getSortedWeeks = (timeLines: TimeLineInfo[], calendarDays: Date[]) => {
    type WeekInfo = Record<string, TimeLineInfo[]>

    // 根据周分类
    const weekObj = timeLines.reduce<WeekInfo>((prev, next) => {
        return next.dates
            .filter(item => calendarDays.some(d => isSameDay(d, item)))
            .reduce((p, n) => {
                const week = `${getYear(n)}-${getWeek(n, { weekStartsOn: 0 })}`

                if (p[week]) {
                    const line = p[week].findIndex(i => i.id === next.id)
                    if (line === -1) {
                        p[week].push({ ...next, dates: [n] })
                    } else {
                        p[week][line].dates.push(n)
                    }
                } else {
                    p[week] = [{ ...next, dates: [n] }]
                }

                return prev
            }, prev)
    }, {})

    Object.keys(weekObj).forEach(k => {
        // 排序
        weekObj[k].sort((a, b) => a.originDates[0].getTime() - b.originDates[0].getTime())

        const virtualZone = [Array.from<boolean>({ length: 7 }).fill(false)]
        for (const line of weekObj[k]) {
            const start = line.dates[0]
            const end = line.dates[line.dates.length - 1]
            const startDay = Number(format(start, 'e', { weekStartsOn: 0 })) - 1
            const endDay = Number(format(end, 'e', { weekStartsOn: 0 })) - 1

            for (const [index, row] of virtualZone.entries()) {
                const isLast = index === virtualZone.length - 1
                // 该日期区间是否已有值插入
                const alreadyUsed = row.slice(startDay, endDay + 1).some(Boolean)

                if (alreadyUsed) {
                    // 如果最后一行也无法插入了，则插入到新行中
                    if (isLast) {
                        virtualZone.push(Array.from<boolean>({ length: 7 }).fill(false))
                    }
                    continue
                }

                // 记录行的使用情况
                for (let j = startDay; j < endDay + 1; j++) {
                    virtualZone[index][j] = true
                }
                // 设置最终插入到的行下标
                line.index = index

                break
            }
        }
    })

    return Object.entries(weekObj)
}
