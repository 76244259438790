import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React from 'react'

import { Box } from '../Box'
import { IconFont } from '../IconFont'

const useStyles = createStyles(() => ({
    root: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '8px'
    }),

    control: css({
        all: 'unset',
        boxSizing: 'border-box',
        borderRadius: 8,
        minWidth: 36,
        padding: '8px',
        alignSelf: 'stretch',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '$colorBlack',
        backgroundColor: '$colorWhite',
        cursor: 'pointer',
        border: '1px solid #eee',
        '&:not(:disabled):hover': {
            backgroundColor: '$colorGray100'
        },
        '&:disabled': {
            cursor: 'not-allowed'
        }
    }),

    item: css({
        all: 'unset',
        boxSizing: 'border-box',
        borderRadius: 8,
        minWidth: 36,
        padding: '8px',
        color: '$colorBlack',
        backgroundColor: '$colorWhite',
        textAlign: 'center',
        cursor: 'pointer',
        border: '1px solid transparent',
        '&.bui-pagination-item_active': {
            color: '$colorPrimary',
            backgroundColor: '$colorGray100'
        },
        '&:not(.bui-pagination-item_active):hover': {
            border: '1px solid #eee'
        }
    })
}))

export type PaginationStyleNames = Selectors<typeof useStyles>
export interface BasePaginationProps {
    /**
     * @default 10
     */
    pageSize?: number

    /**
     * @default 1
     */
    pageNum?: number

    /**
     * @default 0
     */
    total?: number

    disabled?: boolean

    onChange?: (pageNum: number) => void
}

export interface PaginationProps
    extends BasePaginationProps,
        Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>,
        StyleComponentProps<PaginationStyleNames> {}

export const Pagination = React.forwardRef<HTMLDivElement, PaginationProps>((props, ref) => {
    const { classNames, styles, unstyled, className, pageNum, pageSize = 10, onChange, total = 0, disabled, ...rest } = props
    const { classes } = useStyles({}, { name: 'pagination', styles, classNames, unstyled })

    const [_pageNum, _onChange] = useUncontrolled({ value: pageNum, onChange, defaultValue: 1 })

    const pageCount = Math.ceil(total / pageSize)

    const splitCount = 5

    const showPageNumIndex = Math.ceil(_pageNum / splitCount) - 1
    const currentPageArr = Array.from(
        { length: Math.min(splitCount, pageCount - showPageNumIndex * splitCount) },
        (_, v) => splitCount * showPageNumIndex + v + 1
    )

    return (
        <Box ref={ref} className={clsx(className, classes.root)} {...rest}>
            <Box
                disabled={disabled || _pageNum <= 1}
                component="button"
                className={classes.control}
                onClick={() => {
                    if (_pageNum <= 1) {
                        return
                    }
                    _onChange(_pageNum - 1)
                }}
            >
                <IconFont type="ArrowLeftSmall" />
            </Box>
            {currentPageArr.map(item => (
                <Box
                    key={item}
                    disabled={disabled}
                    component="button"
                    className={clsx(classes.item, { 'bui-pagination-item_active': _pageNum === item || _pageNum > pageCount })}
                    onClick={() => {
                        _onChange(item)
                    }}
                >
                    {item}
                </Box>
            ))}
            <Box
                disabled={disabled || _pageNum >= pageCount}
                component="button"
                className={classes.control}
                onClick={() => {
                    if (_pageNum >= pageCount) {
                        return
                    }
                    _onChange(_pageNum + 1)
                }}
            >
                <IconFont type="ArrowRightSmall" />
            </Box>
        </Box>
    )
})
