import type { CssParams } from '@byecode/ui/theme/types'
import type { AnyObject } from '@byecode/ui/types'
import { isElement } from '@byecode/ui/utils/isElement'
import { useMergeRefs } from '@floating-ui/react'
import type { ForwardedRef, Key } from 'react'
import React, { cloneElement, forwardRef } from 'react'

import { Box } from '../../Box'
import { useTooltipContext } from '../Tooltip.context'

export interface PopoverTargetProps extends React.HTMLProps<HTMLElement> {
    children: React.ReactNode
    styles?: Partial<Record<Key, CssParams>>
}

export const TooltipTarget = forwardRef<HTMLElement, PopoverTargetProps>(({ children, styles, ...props }, ref) => {
    const ctx = useTooltipContext()

    const childrenRef = (children as AnyObject).ref
    const _ref = useMergeRefs([ctx.refs.setReference, ref, childrenRef])

    if (isElement(children)) {
        return cloneElement(children, {
            ref: _ref,
            ...ctx.getReferenceProps({
                ...props,
                ...children.props
            })
        })
    }

    return (
        <Box ref={_ref as ForwardedRef<HTMLDivElement>} {...ctx.getReferenceProps(props)}>
            {children}
        </Box>
    )
})
