import { Empty } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import {
    type AiFieldStatus,
    type ButtonAction,
    type DataSourceAbstract,
    type RecordLikeProtocol,
    type RichTextContentProtocol,
    type TableColumns,
    type ViewBlockAbstract,
    type ViewField,
    ViewDesignStyle
} from '@lighthouse/core'
import { EmptyNoRecordSvg, getViewColumns } from '@lighthouse/shared'
import type { BreakPointSize } from '@lighthouse/tools'
import type { atomWithImmer } from 'jotai-immer'
import { find } from 'rambda'
import React, { memo, useCallback, useMemo } from 'react'

import { List } from './List'
import * as SC from './styles'
import { getDesignTypeMap } from './utils'

interface ListBlockProps {
    dataSource: DataSourceAbstract
    blockData: ViewBlockAbstract
    tablePropsCache?: TableColumns
    records?: RecordLikeProtocol[]
    selectedRecords: string[]
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    breakPoint: BreakPointSize
    blockWidth?: number
    onSelectedRecords: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete?: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol) => string
}

/**
 *
 * @param param
 * @returns
 * blockData: {
 *   fields: { id: string; type: string; name: string }[]
 *   records: { id: string; content: Record<number, string> }[]
 * }
 */

const ListBlock: React.FC<ListBlockProps> = ({
    dataSource,
    blockData,
    tablePropsCache,
    records,
    selectedRecords,
    aiFieldStatusListAtom,
    breakPoint,
    blockWidth,
    onSelectedRecords,
    onRecordClick,
    onRecordEdit,
    onRecordDelete,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle
}) => {
    const { schema, id: dsId, viewOptions } = dataSource
    const { tableProps } = viewOptions
    const { id, title = '无标题', config, appId } = blockData
    const isEmptyImg = blockWidth && blockWidth >= 200
    const {
        pointer,
        canDeleteRecord,
        canExport,
        canPrint,
        canViewRecord,
        canViewEdit,
        canDisplay,
        viewFieldSettings,
        highLightRules,
        designType,
        designStyle = ViewDesignStyle.simple,
        listAvatar,
        isShowListAvatar,
        avatarType,
        isShowEmphasizeText,
        emphasizeTextField,
        actions
    } = config

    const listItemCheckable = !!canDeleteRecord || !!canExport || !!canPrint
    // const [selectedRecords, setSelectedRecords] = useState<string[]>([])
    // const [selectedMode, setSelectedMode] = useState<SelectedMode>(undefined)
    const noData = !pointer

    const designConfig = useMemo(
        () => ({
            designStyle,
            listAvatar,
            isShowListAvatar,
            avatarType,
            isShowEmphasizeText,
            emphasizeTextField,
            designType
        }),
        [avatarType, designStyle, designType, emphasizeTextField, isShowEmphasizeText, isShowListAvatar, listAvatar]
    )

    const designTypeOption = useMemo(() => getDesignTypeMap(designType), [designType])

    const settingColumns = useMemo(
        () =>
            getViewColumns({
                blockId: id,
                tableProps,
                value: viewFieldSettings,
                schema
            }),
        [id, schema, tableProps, viewFieldSettings]
    )

    // 用户字段设置后的tableProps
    const [columns, userColumns] = useMemo(() => {
        if (!tablePropsCache || tablePropsCache.length === 0 || !canDisplay) {
            const list = settingColumns.filter(item => {
                return item.visible
            })
            return [list, list]
        }

        const columnsCache = tablePropsCache.reduce<ViewField[]>((prev, cur) => {
            const field = find(({ fieldId }) => fieldId === cur.id, settingColumns)
            if (field) {
                prev.push({
                    ...field,
                    visible: cur.visible
                })
            }
            return prev
        }, [])
        const columns = settingColumns.reduce<ViewField[]>((prev, cur) => {
            if (!cur.visible) {
                return prev
            }
            const field = find(({ fieldId }) => fieldId === cur.fieldId, prev)
            if (!field) {
                prev.push({
                    ...cur,
                    visible: false
                })
            }
            return prev
        }, columnsCache)

        const userColumns = columns.filter(item => item.visible)
        return [columns, userColumns]
    }, [tablePropsCache, canDisplay, settingColumns])

    const primaryField = useMemo(() => {
        const viewFieldId = find(item => item.visible, userColumns)?.fieldId
        if (!viewFieldId) {
            return
        }
        return schema[viewFieldId]
    }, [schema, userColumns])

    const handleRecordOperatorDelete = useCallback(
        async (dsId: string, ids: string[]) => {
            const isDelete = await onRecordDelete?.(dsId, ids)
            const newSelectedRecords = selectedRecords.reduce<string[]>((prev, cur) => {
                if (!ids.includes(cur)) {
                    prev.push(cur)
                }
                return prev
            }, [])
            onSelectedRecords(newSelectedRecords)
            return !!isDelete
        },
        [onRecordDelete, onSelectedRecords, selectedRecords]
    )

    const handleSelect = useCallback(
        (recordIds: string[]) => {
            onSelectedRecords(recordIds)
        },
        [onSelectedRecords]
    )

    return useMemo(() => {
        if (noData || !dataSource || records?.length === 0) {
            return (
                <SC.TableNoDataContent>
                    <Empty
                        icon={
                            isEmptyImg ? (
                                <EmptyNoRecordSvg color='var(--color-app-main)' />
                            ) : undefined
                        }
                        description="未找到数据"
                    />
                </SC.TableNoDataContent>
            )
        }

        return (
            <SC.ListScrollerContent border={designTypeOption.border} id={`content-${id}`} data-content-type="List">
                <List
                    blockId={id}
                    actions={actions}
                    checkable={listItemCheckable}
                    recordOpenable={canViewRecord}
                    canDeleteRecord={canDeleteRecord}
                    canRecordEdit={canViewEdit}
                    primaryField={primaryField}
                    schema={schema}
                    viewFields={userColumns}
                    records={records || []}
                    breakPoint={breakPoint}
                    selectedRecords={selectedRecords}
                    designConfig={designConfig}
                    // listAvatar={listAvatar}
                    // isShowListAvatar={isShowListAvatar}
                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                    onRecordSelect={handleSelect}
                    onRecordClick={onRecordClick}
                    onRecordEdit={onRecordEdit}
                    onRecordDelete={id => handleRecordOperatorDelete(dsId, [id])}
                    onAiGeneration={onAiGeneration}
                    highLightRules={highLightRules}
                    onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                    onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                    onRenderButtonTitle={onRenderButtonTitle}
                />
            </SC.ListScrollerContent>
        )
    }, [
        noData,
        dataSource,
        records,
        designTypeOption.border,
        id,
        actions,
        listItemCheckable,
        canViewRecord,
        canDeleteRecord,
        canViewEdit,
        primaryField,
        schema,
        userColumns,
        breakPoint,
        selectedRecords,
        designConfig,
        aiFieldStatusListAtom,
        handleSelect,
        onRecordClick,
        onRecordEdit,
        onAiGeneration,
        highLightRules,
        onRecordOperatorActionTrigger,
        onRecordClickedActionTrigger,
        onRenderButtonTitle,
        isEmptyImg,
        handleRecordOperatorDelete,
        dsId
    ])
}

export default memo(ListBlock)
