import { Enum } from '@lighthouse/tools'

import type { AlignMent, BackgroundNoImageProtocol, ButtonProtocols, RichTextContentProtocol, Shape } from '../../protocols'
import type { ActionItemFitType, ActionItemIconPosition, ActionItemPattern } from '../../protocols/actions'
import type { BlockProtocol, BlockVisibilityProtocol } from '../../protocols/blocks'
import { BlockType } from '../Basic'

export type ButtonBlockTriggerEvent = 'hover' | 'click'

export type ButtonBlockHandleEvent =
    | 'none'
    | 'openPage'
    | 'openFormPage'
    | 'openRecordPage'
    | 'openRecordEditPage'
    | 'openLink'
    | 'scrollTo'
    | 'control'
    | 'goBack'
    | 'notification'
    | 'copy'
    | 'createRecord'
    | 'updateRecord'
    | 'deleteRecord'
    | 'shareToWechat'
    | 'downloadFile'
    | 'iClick'
    | 'aliyunMessage'
    | 'call'
    | 'wechatPay'
    | 'wechatTemplateMsg'
    | 'stripe'
    | 'refreshPage'

export type ButtonBlockType = 'icon-name' | 'icon' | 'name'

export const ButtonSize = Enum('xs', 'sm', 'md', 'lg', 'xl', 'xxl')
export type ButtonSizeType = Enum<typeof ButtonSize>

export type ButtonItem = {
    id: string
    iconPosition: ActionItemIconPosition
    color?: BackgroundNoImageProtocol
    iconColor?: BackgroundNoImageProtocol
    backgroundColor?: BackgroundNoImageProtocol
    borderColor?: BackgroundNoImageProtocol
    fillWay: ActionItemFitType
    pattern: ActionItemPattern
    name: RichTextContentProtocol
} & Omit<ButtonProtocols, 'pattern' | 'name'>

export abstract class ButtonBlockAbstract implements BlockProtocol {
    type = BlockType.buttonGroup

    abstract title: string

    abstract id: string

    abstract config: BlockVisibilityProtocol & {
        size: ButtonSizeType

        align: AlignMent

        shape: Shape

        radius: string

        group: ButtonItem[]
    }
}
