import { createStyles } from '@byecode/ui/theme/createStyles';
import { css } from '@byecode/ui/theme/stitches.config';
import type { ByecodeSize } from '@byecode/ui/types';

const sizeStyles = {
    xs: {
        height: '24px',
        lineHeight: 'calc(24px - var(--byecode-select-border-width) * 2)',
        borderRadius: '5px'
    },
    sm: {
        height: '28px',
        lineHeight: 'calc(28px - var(--byecode-select-border-width) * 2)',
        borderRadius: '6px'
    },
    md: {
        height: '32px',
        lineHeight: 'calc(32px - var(--byecode-select-border-width) * 2)',
        borderRadius: '6px'
    },
    lg: {
        height: '36px',
        lineHeight: 'calc(36px - var(--byecode-select-border-width) * 2)',
        borderRadius: '8px'
    },
    xl: {
        height: '40px',
        lineHeight: 'calc(40px - var(--byecode-select-border-width) * 2)',
        borderRadius: '6px'
    }
}

type StylesParams = {
    size: ByecodeSize
    disabled?: boolean
    focused?: boolean
    clearable?: boolean
    focusOutLine?: boolean
}

export const useStyles = createStyles((params: StylesParams) => {
    const { size, disabled, focused, clearable, focusOutLine } = params
    const getInteractionCss = () => {
        if (disabled) {
            return { color: '$colorGray500', cursor: 'not-allowed' }
        }

        if (focused) {
            return {
                backgroundColor: '$colorWhite',
                borderColor: '$colorPrimary'
            }
        }

        return {
            '&:hover': {
                backgroundColor: '$colorGray200'
            },
            '&:has(input:focus)': {
                backgroundColor: '$colorWhite',
                borderColor: '$colorPrimary'
            }
        }
    }


    return {
        root: css({
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 'var(--font-size-normal)',
            backgroundColor: '$colorGray100',
            cursor: 'pointer',
            color: '$colorGray500',
            border: '1.5px solid transparent',
            // overflow: 'hidden',
            '&:hover .arrowIcon': {
                display: clearable ? 'none' : 'block'
            },

            '&:hover .clearIcon': {
                display: clearable ? 'block' : 'none'
            },

            '&:focus, &:focus-within': focusOutLine && {
                backgroundColor: '$colorWhite',
                borderColor: '$colorPrimary'
            },
            ...getInteractionCss(),
            ...sizeStyles[size]
        }),
        target: css({
            display: 'flex',
            width: '100%',
            height: ' 100%',
            padding: '0 8px',
            justifyContent: 'space-between',
            alignItems: 'center',

        }),
        placeholder: css({
            fontSize: '14px',
            color: '$colorGray400',
            textEllipsis: true
        }),
        iconWrapper: css({
            color: '$colorGray400',
            display: 'flex',
            alignItems: 'center',
        }),
        clearIcon: css({
            display: 'none',
        }),
        targetWrapper: css({
            display: 'flex',
            alignItems: 'center',
            gap: 6,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }),
        tag: css({
            display: 'inline-flex',
            alignItems: 'center',
            height: 22,
            borderRadius: '4px',
            padding: '2px 6px',
            color: '$colorGray900',
            backgroundColor: '$colorGray200',
            overflow: 'hidden',
            '&:not(.plain-container):': {
                /* background: #f4e7c3, */
                borderRadius: 4,
                padding: '2px 6px'
            }
        })
    }
})
