import { Button, IconFont } from '@byecode/ui'
import type { DataSourceAbstract, DTTextGeneration, Field, RichTextContentProtocol, TextGenerationField } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { InnerTypeMapByFieldType } from '../../../constants'
import { ErrorMessage } from '../../ErrorMessage'
import { isEmptyRichTextValue, RichTextEditor } from '../../RichText'
import type { ToolbarProps } from '../../RichText/Toolbar'
import { getDefaultDataSourceOptions } from '../../Variable'
import * as SC from '../styles'

const AiButton = styled(Button)`
    &:hover {
        background: radial-gradient(101.03% 174.38% at 0 0, #fea0c2 0%, #3834e2 100%);
    }
`
export interface TextGenerationConfigViewProps {
    config: TextGenerationField
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    onOk?: (data: Field) => void
    onCancel: () => void
}

export const TextGenerationConfigView: React.FC<TextGenerationConfigViewProps> = ({
    config,
    dataSource,
    dataSourceList,
    onOk,
    onCancel
}) => {
    const {
        control,
        getValues,
        trigger,
        formState: { errors }
    } = useForm<DTTextGeneration>({
        mode: 'onSubmit',
        defaultValues: {
            ...config?.textGeneration
        }
    })

    const dataSourceOption = useMemo(() => getDefaultDataSourceOptions(dataSource), [dataSource])

    const aiRichTextConfig: ToolbarProps['config'] = useMemo(
        () => ({
            variable: { dataSourceOption },
            heading: false,
            bulletList: false,
            orderedList: false,
            taskList: false,
            basic: false,
            line: false,
            link: false,
            image: false,
            quote: false,
            codeBlock: false
        }),
        [dataSourceOption]
    )

    const handleOk = useCallback(async () => {
        const isOk = await trigger()
        if (isOk) {
            onOk?.({ ...config, type: 'textGeneration', textGeneration: getValues(), innerType: InnerTypeMapByFieldType['textGeneration'] })
        }
    }, [config, getValues, onOk, trigger])

    const checkVariable = useCallback((json?: RichTextContentProtocol) => {
        if (!json) {
            return '请输入指令'
        }
        const isEmpty = isEmptyRichTextValue(json)
        if (isEmpty) {
            return '请输入指令'
        }
        return true
    }, [])

    return (
        <>
            <SC.FormItemWrapper>
                <SC.FormItemLabel>请输入指令</SC.FormItemLabel>
                <Controller
                    control={control}
                    name="variable"
                    rules={{
                        // required: '请输入指令'
                        validate: {
                            checkVariable
                        }
                    }}
                    render={({ field }) => {
                        return (
                            <ErrorMessage name="variable" errors={errors}>
                                <SC.RichTextContainer>
                                    <RichTextEditor
                                        shrink={false}
                                        config={aiRichTextConfig}
                                        value={field.value}
                                        onChange={json => {
                                            field.onChange(json)
                                            // const isEmpty = isEmptyRichTextValue(json)
                                            // if (isEmpty) {
                                            //     setError('variable', {
                                            //         message: '请输入指令'
                                            //     })
                                            // }
                                        }}
                                        autofocus="end"
                                    />
                                    {/* isEmptyRichTextValue */}
                                </SC.RichTextContainer>
                            </ErrorMessage>
                        )
                    }}
                />
            </SC.FormItemWrapper>
            <SC.ActionWrapper>
                <Button style={{ marginRight: 8 }} onClick={onCancel}>
                    取消
                </Button>
                <AiButton type="primary" onClick={handleOk} icon={<IconFont type="Magic" size={16} fill="var(--color-white)" />}>
                    确定
                </AiButton>
            </SC.ActionWrapper>
            {/* <SC.ConfigPreviewTip>示例：{dateTip}</SC.ConfigPreviewTip> */}
        </>
    )
}
