import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React from 'react'

import { Box } from '../Box'
import { IconFont } from '../IconFont'
import { useStyles } from './Empty.styles'

export type EmptyStylesNames = Selectors<typeof useStyles>

export interface EmptyProps extends React.ComponentPropsWithoutRef<'div'>, StyleComponentProps<EmptyStylesNames> {
    icon?: string | React.ReactNode
    title?: string
    description?: string | React.ReactNode
}

export const Empty = React.forwardRef<HTMLDivElement, EmptyProps>(
    ({ icon, styles, description, title, className, classNames, unstyled, ...rest }, ref) => {
        const { classes } = useStyles({}, { name: 'Empty', styles, classNames, unstyled })

        const emptyIcon = typeof icon === 'string' ? <IconFont size={32} type={icon} fill="var(--color-gray-400)" /> : icon

        return (
            <Box className={clsx(classes.root, className)} {...rest} ref={ref}>
                <Box className={classes.wrapper}>
                    {emptyIcon}
                    {title && <Box className={classes.title}>{title}</Box>}
                    <Box className={classes.content}>{description}</Box>
                </Box>
            </Box>
        )
    }
)
