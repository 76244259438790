import { closeModal, Empty, openModal, tinyButtons, Toast } from '@byecode/ui'
import { type DataSourceAbstract, type FieldInputADTValue, ButtonPattern } from '@lighthouse/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { fieldFileFilter } from '../../constants'
import type {
    ApprovePayload,
    MessageDetailApprovalMessageData,
    MessageDetailStationMessageData,
    MessageEvents,
    MessageListData,
    MessageType
} from '../../types'
import { getEmptyFieldInputValue, getFieldInputTypeByFieldType } from '../../utils'
import { NotificationApprovalModal } from '../NotificationApprovalModal'
import { ThemeButton } from '../ThemeButton'
import { NotificationForm } from './NotificationForm'

const auditStatusMap = {
    ACCEPT: '已同意',
    REJECT: '已拒绝'
}

const approveResultMap: Record<MessageType, string> = {
    CARBON_COPY: '',
    APPROVAL: '',
    APPROVE_PASSED: '已通过',
    APPROVE_REFUSED: '已拒绝',
    STATION_MESSAGE: ''
}

interface NotificationFormContentProps {
    appId?: string
    data: MessageListData
    auditStatus?: 'agree' | 'reject'
    messageEvents?: MessageEvents
    isMobile?: boolean
    onClose?: () => void
}

type FormInputList = {
    inputValueList: (FieldInputADTValue & { id: string })[]
    // appId: string
    // pointer: string
}

const SCxNotificationFormContentWrapper = styled.div`
    padding: 0 8px;
`

const SCxNotificationFormTitle = styled.div`
    font-weight: var(--font-weight-bold);
    padding: 0 12px 4px 12px;
`

const SCxNotificationContent = styled.div`
    padding: 10px;
    background-color: var(--color-gray-100);
    border-radius: 4px;
    margin-top: 12px;
`

const SCxNotificationDetailContentWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const SCxNotificationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    padding: 14px 16px;
    border-bottom: 1px solid var(--color-gray-100);
`

const SCxNotificationHeaderTitle = styled.div`
    font-weight: var(--font-weight-bold);
    color: var(--color-black);
`

const SCxMessageContentDescription = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
`

const SCxMessageDetailContentWrapper = styled.div`
    flex: 1;
    padding: 20px 0;
    overflow-y: auto;

    ${tinyButtons}
`

const SCxMessageDetailFooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 12px 16px;
    background-color: var(--color-gray-50);
`

const ApprovalMessageDetail: React.FC<NotificationFormContentProps> = ({ data: message, auditStatus, isMobile, appId, messageEvents }) => {
    const initialAuditStatus = useRef(auditStatus)
    const { id } = message
    const [data, setData] = useState<MessageDetailApprovalMessageData>()
    const remoteAuditStatus = data?.auditStatus
    const isApproval = data?.type === 'APPROVAL'
    const isCC = data?.type === 'CARBON_COPY'
    const isResult = data?.type.includes('APPROVE_')

    const dsId = data?.dsId ?? ''
    const rawInputList = data?.values

    // TODO: @张琪，这里看是不是必须 dataSource，只用 schema 可行？？ 我暂时这么做了，因为不需要其他的内容。
    const dataSource = { schema: data?.schema } as DataSourceAbstract
    const inputList = useMemo(() => {
        if (!rawInputList) {
            return []
        }

        return rawInputList.map(item => {
            const field = dataSource?.schema?.[item.fieldId ?? '']
            return {
                ...item,
                id: item.fieldId,
                inputType: getFieldInputTypeByFieldType(field?.type),
                canEdit: remoteAuditStatus ? false : item.permission === 'WRITE',
                fieldPointer: field?.id,
                title: field?.name,
                showTitle: true
            }
        })
    }, [dataSource?.schema, rawInputList, remoteAuditStatus])

    const initialInputListValue = useMemo(() => {
        if (!data?.values) {
            return inputList.map(item => {
                const field = dataSource?.schema?.[item.fieldPointer ?? '']
                return {
                    value: getEmptyFieldInputValue(item.inputType, field?.type),
                    type: item.inputType,
                    id: item.fieldPointer
                } as FieldInputADTValue & {
                    id: string
                }
            })
        }

        return inputList.map(item => {
            const { id, inputType, value } = item
            const field = dataSource?.schema?.[id ?? '']
            return {
                value: item ? value : getEmptyFieldInputValue(inputType, field?.type),
                type: inputType,
                id
            } as FieldInputADTValue & {
                id: string
            }
        })
    }, [data?.values, dataSource?.schema, inputList])

    const methods = useForm<FormInputList>({
        mode: 'onChange',
        defaultValues: {
            inputValueList: initialInputListValue
        }
    })

    useEffect(() => {
        methods.reset({
            inputValueList: initialInputListValue
        })
    }, [initialInputListValue, methods])

    const [isValid, setIsValid] = useState(false)

    const fetchMessageDetail = useCallback(async () => {
        const res = await messageEvents?.onFetchMessageDetail?.(id)
        if (res) {
            setData(res as MessageDetailApprovalMessageData)
        }
    }, [id, messageEvents])

    const handleOpenApprovalModal = useCallback(
        (opinion?: 'agree' | 'reject') => {
            openModal({
                id: 'notification-detail-approval',
                // target: '#host-page-container',
                children: (
                    <NotificationApprovalModal
                        opinion={opinion}
                        onClose={() => {
                            closeModal('notification-detail-approval')
                        }}
                        onConfirm={async comment => {
                            const invoke = opinion === 'reject' ? messageEvents?.approveReject : messageEvents?.approveAccept
                            if (!invoke) {
                                return
                            }

                            const params: ApprovePayload = {
                                msgId: id,
                                comment,
                                record: {
                                    dsId,
                                    recordId: data?.recordId ?? '',
                                    fields: methods.getValues().inputValueList.map(item => {
                                        return {
                                            fieldId: item.id,
                                            value: item.value
                                        }
                                    })
                                }
                            }
                            const isSuccess = await invoke?.(params)

                            if (isSuccess) {
                                Toast.success('审批完成')
                            } else {
                                Toast.error('操作失败')
                                return
                            }

                            fetchMessageDetail()

                            setIsValid(true)
                            closeModal('notification-detail-approval')
                        }}
                    />
                )
            })
        },
        [data?.recordId, dsId, fetchMessageDetail, id, messageEvents?.approveAccept, messageEvents?.approveReject, methods]
    )

    // 初始化时，如果有审批状态，就打开审批弹窗
    useEffect(() => {
        if (initialAuditStatus.current && data) {
            handleOpenApprovalModal(auditStatus)
            initialAuditStatus.current = undefined
        }
    }, [auditStatus, data, handleOpenApprovalModal])

    useEffect(() => {
        fetchMessageDetail()
    }, [fetchMessageDetail])

    useEffect(() => {
        return () => {
            methods.reset()
        }
    }, [id, methods])

    const detail = (
        <SCxMessageDetailContentWrapper>
            <SCxNotificationFormContentWrapper>
                <SCxNotificationFormTitle>详情</SCxNotificationFormTitle>
                <NotificationForm
                    appId={appId}
                    recordId={data?.recordId}
                    isValid={isValid}
                    dataSource={dataSource}
                    inputList={inputList}
                    control={methods.control}
                    uploadyOptions={{
                        // TODO: @kidrue id后续处理掉 不需要此参数
                        info: { label: dataSource.name, id: '', groupId: data?.dsId ?? '' },
                        options: {
                            destination: { url: `/lighthouse/api/v1/${appId}/oss/uploadInApp` },
                            fileFilter: fieldFileFilter
                        }
                    }}
                />
                {/* <SCxDivider />
    <SCxNotificationFormTitle>动态</SCxNotificationFormTitle>
    <NotificationMoment /> */}
            </SCxNotificationFormContentWrapper>
        </SCxMessageDetailContentWrapper>
    )

    return (
        <SCxNotificationDetailContentWrapper>
            <SCxNotificationHeader>
                <SCxNotificationHeaderTitle>
                    {isCC && <>抄送给我：</>}
                    {data && isResult && (
                        <>
                            您发起的审批 <span style={{ fontWeight: 'var(--font-weight-bold)' }}>{approveResultMap[data.type]}</span>
                        </>
                    )}
                    {!isResult && (
                        <>
                            <span style={{ fontWeight: 'var(--font-weight-bold)', marginRight: 4 }}>{data?.username}</span> 发起了申请
                        </>
                    )}
                </SCxNotificationHeaderTitle>
            </SCxNotificationHeader>
            {detail}
            {data && isApproval && (
                <SCxMessageDetailFooterWrapper>
                    {remoteAuditStatus ? (
                        auditStatusMap[remoteAuditStatus]
                    ) : (
                        <>
                            <ThemeButton
                                pattern={ButtonPattern.secondary}
                                showType="name"
                                styles={{
                                    container: {
                                        width: 'auto'
                                    },
                                    wrapper: {
                                        width: 'auto',
                                        height: 32,
                                        padding: '12px 26px'
                                    }
                                }}
                                triggerEvent="click"
                                onClick={() => handleOpenApprovalModal('reject')}
                                title="不同意"
                            />
                            <ThemeButton
                                pattern={ButtonPattern.primary}
                                showType="name"
                                styles={{
                                    container: {
                                        width: 'auto'
                                    },
                                    wrapper: {
                                        width: 'auto',
                                        height: 32,
                                        padding: '12px 26px'
                                    }
                                }}
                                triggerEvent="click"
                                onClick={() => handleOpenApprovalModal()}
                                title="同意"
                            />
                        </>
                    )}
                </SCxMessageDetailFooterWrapper>
            )}
        </SCxNotificationDetailContentWrapper>
    )
}

const StationMessageDetail: React.FC<NotificationFormContentProps> = ({ data: message, messageEvents }) => {
    const { id } = message
    const [data, setData] = useState<MessageDetailStationMessageData>()

    const fetchMessageDetail = useCallback(async () => {
        const res = await messageEvents?.onFetchMessageDetail?.(id)
        if (res) {
            setData(res as MessageDetailStationMessageData)
        }
    }, [id, messageEvents])

    useEffect(() => {
        fetchMessageDetail()
    }, [fetchMessageDetail])

    if (!data) {
        return <Empty description="暂无消息" />
    }

    const { appName, createdTime, content, title } = data

    return (
        <SCxNotificationDetailContentWrapper>
            <SCxNotificationHeader>
                <SCxNotificationHeaderTitle>{title}</SCxNotificationHeaderTitle>
            </SCxNotificationHeader>
            <SCxMessageDetailContentWrapper>
                <SCxNotificationFormContentWrapper>
                    <SCxMessageContentDescription>
                        {createdTime} · 来自 {appName}
                    </SCxMessageContentDescription>
                    <SCxNotificationContent>{content}</SCxNotificationContent>
                </SCxNotificationFormContentWrapper>
            </SCxMessageDetailContentWrapper>
        </SCxNotificationDetailContentWrapper>
    )
}

export const NotificationDetailContent: React.FC<NotificationFormContentProps> = props => {
    const { data: message } = props

    return message.type === 'STATION_MESSAGE' ? <StationMessageDetail {...props} /> : <ApprovalMessageDetail {...props} />
}
