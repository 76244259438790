import { backgroundTransitionOnClick } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const FileUploaderWrapper = styled.div`
    font-size: var(--font-size-normal);
    flex: 1;
    width: 100%;
`

export const UploaderArea = styled.p`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    border-radius: 5px;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    border: 1px dashed var(--color-gray-200);
    background-color: var(--color-gray-100);
    cursor: pointer;
`

export const Footer = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 16px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-gray-50);
    z-index: 1;
`
export const Item = styled.div`
    width: 100%;
    height: 52px;
    line-height: 52px;
    text-align: center;
    font-size: var(--font-size-base);
    color: var(--color-black);
    ${backgroundTransitionOnClick}
`

export const UploadButtonWrapper = styled.div`
    border-radius: 5px;
    > div {
        box-shadow: none !important;
    }
`
export const FileListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 8px;
`
