import type { FieldInputValue } from '@lighthouse/core'
import { RecordOpenType } from '@lighthouse/core'
import { ensureUrlParams, getMainTableRecordId, getSearchParams, getUrlNameByOpenType } from '@lighthouse/shared'
import { useAtomCallback } from 'jotai/utils'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { deleteRecordAtom } from '@/atoms/dataSource/action'
import { recordPoolAtom } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom, pageAtomFamily } from '@/atoms/page/state'
import { useCurrentPageContext } from '@/context/PageContext'
import * as srv from '@/services'

import { usePageStackActions } from './usePageStackActions'

export const useActionRuntimeParams = () => {
    const { refreshPage } = useCurrentPageContext()
    const { closeCurrentPageLayer, peekCurrentPageStack } = usePageStackActions()
    const navigate = useNavigate()
    const getCurrentPageDepsAtomCallback = useAtomCallback(
        useCallback(get => {
            return get(lastPageOfStackAtom)
        }, [])
    )

    const updateRecord = useAtomCallback(
        useCallback(
            async (
                get,
                set,
                manualDeps: {
                    appId: string
                    pageId: string
                    envId: string
                    dsId: string
                    recordId: string
                    fields: {
                        fieldId: string
                        value: FieldInputValue
                    }[]
                }
            ) => {
                const { appId, pageId, envId, dsId, recordId, fields } = manualDeps
                const MainTableRecordId = getMainTableRecordId(recordId)
                const record = await srv.updateRecordWithFields({
                    appId,
                    envId,
                    pageId,
                    dsId,
                    recordId,
                    fields
                })

                if (record) {
                    set(recordPoolAtom, draft => {
                        // const isEqualRecordId = recordIds.some(id => getMainTableRecordId(id) === getMainTableRecordId(draft[i].id))
                        const recordsIndex = draft.reduce<number[]>((prev, cur, index) => {
                            if (
                                cur.appId === appId &&
                                cur.envId === envId &&
                                cur.dsId === dsId &&
                                getMainTableRecordId(cur.id) === MainTableRecordId
                            ) {
                                prev.push(index)
                            }
                            return prev
                        }, [])
                        if (recordsIndex.length === 0) {
                            return
                        }
                        recordsIndex.forEach(i => {
                            fields.forEach(f => {
                                draft[i].content[f.fieldId] = { value: f.value }
                            })
                        })
                    })
                }
                return record
            },
            []
        )
    )

    const deleteRecord = useAtomCallback(
        useCallback(
            async (get, set, manualDeps?: { appId: string; dsId: string; pageId: string; recordId: string }) => {
                const pageDeps = getCurrentPageDepsAtomCallback()
                const deps = manualDeps ?? pageDeps

                if (!deps) {
                    return
                }

                const { appId, dsId, recordId, pageId } = deps

                if (!appId || !dsId || !recordId) {
                    return
                }

                // eslint-disable-next-line no-return-await
                return await set(deleteRecordAtom, {
                    appId,
                    dsId,
                    pageId,
                    recordIds: [recordId]
                })
            },
            [getCurrentPageDepsAtomCallback]
        )
    )

    const openPageAtomCallback = useAtomCallback(
        useCallback(
            (
                get,
                set,
                {
                    pageId,
                    openType,
                    appId,
                    dsId,
                    recordId,
                    onSaveCallback
                }: {
                    pageId: string
                    openType: RecordOpenType
                    appId?: string
                    dsId?: string
                    recordId?: string
                    onSaveCallback?: () => void
                }
            ) => {
                const page = get(pageAtomFamily(pageId))

                // 在支付页面打开页面时，路径需要单独处理
                const isPayPage = window.location.pathname.includes('/pay')
                const searchParams = new URLSearchParams(window.location.search)
                const redirectUrl = decodeURIComponent(searchParams.get('redirect') ?? '')

                const prefixPath =
                    page?.type === 'default' && openType === RecordOpenType.page
                        ? page.route
                            ? `/${page.route}`
                            : `/P/${page.id}`
                        : `${isPayPage ? redirectUrl : window.location.pathname}/${getUrlNameByOpenType(openType)}/${pageId}`

                // openPage({ id: pageId, name: pageName }, { openType, appId, dsId, recordId, onSaveCallback })
                const path = `${prefixPath}${ensureUrlParams('a', appId)}${ensureUrlParams('d', dsId)}${ensureUrlParams(
                    'r',
                    recordId
                )}/fromAction/1`
                navigate(path, { relative: 'path' })
            },
            [navigate]
        )
    )

    const actionEvents = useMemo(() => {
        return {
            openPage: openPageAtomCallback,
            peekCurrentPageStack,
            closePage: () => {
                const pageDeps = getCurrentPageDepsAtomCallback()
                if (!pageDeps) {
                    return
                }

                closeCurrentPageLayer()
            },
            closeCurrentPageLayer,
            refreshPage,
            deleteRecord,
            updateRecord,
            navigate
        }
    }, [
        closeCurrentPageLayer,
        deleteRecord,
        getCurrentPageDepsAtomCallback,
        navigate,
        openPageAtomCallback,
        peekCurrentPageStack,
        refreshPage,
        updateRecord
    ])

    return {
        actionEvents,
        getCurrentPageDeps: getCurrentPageDepsAtomCallback
    }
}
