import { IconFont } from '@byecode/ui'
import { Textarea } from '@mantine/core'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { MobileDrawer } from '../../MobileDrawer'

interface InputDrawerProps {
    opened: boolean
    leftIcon?: string
    value: string
    target?: string
    onClose: () => void
    onChange?: (val: string) => void
    onFinish?: (val: string) => void
}

const SCxTextarea = styled(Textarea)`
    width: 100%;
    height: 100%;
`
const FinishButton = styled.div`
    color: var(--color-black);
`

const textareaStyle = {
    wrapper: {
        height: '100%'
    },
    input: {
        height: '100%'
    }
}

export const InputDrawer: React.FC<InputDrawerProps> = ({ target, leftIcon, value, opened, onClose, onFinish, onChange }) => {
    const [text, setText] = useState(value)

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const val = e.target.value
            setText(e.target.value)
            onChange?.(val)
        },
        [onChange]
    )

    const handleFinish = useCallback(() => {
        onFinish?.(text)
    }, [onFinish, text])

    return (
        <MobileDrawer
            target={target}
            leftNode={!!leftIcon && <IconFont type={leftIcon} />}
            title="条件值"
            opened={opened}
            onClose={onClose}
            rightNode={<FinishButton onClick={handleFinish}>完成</FinishButton>}
        >
            <SCxTextarea styles={textareaStyle} placeholder="请输入" value={text} onChange={handleChange} />
        </MobileDrawer>
    )
}
