import { IconFont } from '@byecode/ui'
import styled from 'styled-components'

export const Notice = styled.div`
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: var(--font-size-normal);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    pointer-events: none;
`

export const NoticeMessage = styled.div`
    padding: 8px;
    text-align: center;
    position: relative;

    &.move {
        animation: move 0.3s linear;
        animation-fill-mode: forwards;

        @keyframes move {
            from {
                top: 0px;
            }

            to {
                top: -50px;
            }
        }
    }

    &.start {
        animation: fadein 0.3s linear;
        animation-fill-mode: forwards;

        @keyframes fadein {
            from {
                top: -10px;
                opacity: 0;
            }

            to {
                top: 0px;
                opacity: 1;
            }
        }
    }

    &.stop {
        animation: fadeout 0.3s linear;
        animation-fill-mode: forwards;

        @keyframes fadeout {
            from {
                top: 0px;
                opacity: 1;
            }

            to {
                top: -50px;
                opacity: 0;
            }
        }
    }
`

export const NoticeMessageContent = styled.div`
    display: inline-block;
    padding: 10px 16px;
    background: var(--color-white);
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    pointer-events: all;
`

export const NoticeFlex = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
`

export const NoticeIcon = styled(IconFont)`
    display: inline-flex;
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: 20px;
`

export const NoticeTxt = styled.span``

export const NoticeLinkText = styled.span`
    color: var(--color-theme-7);
    /* padding-left: 14px; */
    cursor: pointer;
`
