import { backgroundTransitionOnClick, IconFont } from '@byecode/ui'
import styled from 'styled-components'

export const ColorPatchContent = styled.div`
    width: 20px;
    height: 20px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${({ color }) => (color ? `var(--color-${color}-light)` : 'var(--color-dustRed)')};
`

export const ColorList = styled.div`
    width: 200px;
    padding: 6px 0;
`

export const ColorItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    cursor: pointer;
    ${backgroundTransitionOnClick}
`

export const InfoWrapper = styled.div`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
`

export const ColorPoint = styled.div`
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Icon = styled(IconFont)``
