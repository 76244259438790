import type { CascadeOption, CascadeValue } from '@byecode/ui'
import { CascadeList, IconFont } from '@byecode/ui'
import type { CascadeConfig, FieldInputADTValue } from '@lighthouse/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAsyncRetry, useClickAway, useToggle, useUpdateEffect } from 'react-use'

import { useApplicationContext } from '../../../../../contexts'
import { CascadeDrawer } from '../../../../FieldDrawer'
import { SelectPlaceHolder } from '../../../SelectPlaceHolder'
import type { FieldBaseProps } from '../../../types'
import * as CM from '../../commonStyles'
import * as SC from '../styles'

interface CascadeDrawerFieldProps extends FieldBaseProps {
    value: string
    cascadeConfig: CascadeConfig
}

export const CascadeDrawerField: React.FunctionComponent<CascadeDrawerFieldProps> = props => {
    const {
        onCellChange,
        value: data,
        isControlled,
        config: { placeholder, title = '' },
        readOnly,
        cascadeConfig,
        onSaveCellChange,
        onFetchCascadeOptions
    } = props
    const { cascade } = cascadeConfig ?? {}
    const {
        cascadeFieldPointer = '',
        cascadePointer = '',
        cascadeShowFieldPointer = '',
        showPath,
        isLastLevel,
        filter,
        parentFieldPointer = '',
        sortFieldPointer = ''
    } = cascade ?? {}

    const [value, setValue] = useState(data)
    const [path, setPath] = useState('')
    const [opened, setOpened] = useState(false)

    useUpdateEffect(() => {
        setValue(data)
    }, [data])

    const { value: options, retry } = useAsyncRetry(async () => {
        const data = await onFetchCascadeOptions?.({
            fieldPointer: cascadeFieldPointer,
            dsId: cascadePointer,
            sortFieldPointer,
            showFieldPointer: cascadeShowFieldPointer,
            filter,
            parentFieldPointer
        })
        return data ?? []
    }, [cascadeFieldPointer, cascadePointer, sortFieldPointer, filter, parentFieldPointer, onFetchCascadeOptions])

    const { pageTarget } = useApplicationContext()

    const flatOptions = useMemo(() => {
        function getChildOption(option: CascadeOption, list: CascadeOption[]) {
            const { value, label, path, labelPath, children = [] } = option
            let arrList = list
            for (const child of children) {
                const newChild = {
                    ...child,
                    path: `${path}/${child.value}`,
                    labelPath: `${labelPath}/${child.label}`,
                    isLast: (child.children ?? []).length === 0
                }
                arrList = [...arrList, newChild, ...getChildOption(newChild, [])]
            }
            return arrList
        }
        return (
            options?.reduce<CascadeOption[]>((pre, cur) => {
                const newOption = { ...cur, path: cur.value, labelPath: cur.label, isLast: (cur.children ?? []).length === 0 }
                return [...pre, newOption, ...getChildOption(newOption, [])]
            }, []) ?? []
        )
    }, [options])

    const tag = useMemo(() => flatOptions.find(option => option.value === value && value), [flatOptions, value])

    const cascadeListValue = useMemo(() => [value], [value])

    const handleChange = useCallback(
        (v: CascadeValue) => {
            const textValue = v.join(',')
            setValue(textValue)
            onCellChange?.({ type: 'cascade', value: textValue })
            isLastLevel && setOpened(false)
            setPath('')
        },
        [isLastLevel, onCellChange]
    )

    const onPathChange = useCallback(
        (data: CascadeOption) => {
            setValue('')
            setPath(showPath ? data.labelPath ?? '' : '')
        },
        [showPath]
    )
    const handleClear = useCallback(() => {
        setValue('')
        setPath('')
        onCellChange?.({ type: 'cascade', value: '' })
    }, [onCellChange])

    return (
        <>
            <SC.Container type="button" data-field-border={opened}>
                <SC.Wrapper
                    onClick={() => {
                        if (!readOnly) {
                            setOpened(true)
                            retry()
                        }
                    }}
                >
                    <SC.TagWrapper>
                        {tag ? showPath ? tag.labelPath : tag.label : <SelectPlaceHolder label={path || placeholder} />}
                    </SC.TagWrapper>
                </SC.Wrapper>
                {!readOnly && (
                    <CM.IconWrapper>
                        {tag && <IconFont type="CloseCircle" color="var(--color-gray-400)" size={16} onClick={handleClear} />}
                        <IconFont type="ArrowDownSmall" color="var(--color-gray-400)" size={16} />
                    </CM.IconWrapper>
                )}
            </SC.Container>
            <CascadeDrawer
                title={title}
                value={cascadeListValue}
                options={options}
                showPath={showPath}
                // isMultiple={canMultipleChoice}
                isLastLevel={isLastLevel}
                opened={opened}
                target={pageTarget}
                path={path}
                onPathChange={onPathChange}
                onFinish={handleChange}
                onClose={() => {
                    setOpened(false)
                    setPath('')
                }}
            />
        </>
    )
}
