import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

export const useStyles = createStyles(() => {
    return {
        root: css({
            color: '$colorPrimary',
            textDecoration: 'none',
            appearance: 'none',
            border: 'none',
            display: 'inline',
            padding: '0',
            margin: '0',
            backgroundColor: 'transparent',
            cursor: 'pointer',

            '&:where([data-underline="hover])': {
                '@mixin hover': {
                    textDecoration: 'underline'
                }
            },

            '&:where([data-underline="always])': {
                textDecoration: 'underline'
            },

            '&:where([data-variant="gradient])': {
                '&,&:hover': {
                    textDecoration: 'none'
                }
            },
            '&:where([data-line-clamp])': {
                display: '-webkit-box'
            }
        })
    }
})
