import { initBaiduTracker, initCnzzTracker } from '@lighthouse/shared'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

export function initSentry() {
    const { VITE_SENTRY_ENVIRONMENT } = import.meta.env

    // const sentryEnabled = VITE_SENTRY_ENVIRONMENT && VITE_SENTRY_ENVIRONMENT !== 'dev'
    const enabled = VITE_SENTRY_ENVIRONMENT === 'prod'
    const sentryMaxRate = VITE_SENTRY_ENVIRONMENT === 'prod' ? 0.1 : 0.5

    // 初始化百度统计
    enabled && initBaiduTracker('aef0c77fee9fba19a3e7e9d22eddbeb1')
    // 初始化友盟
    enabled && initCnzzTracker('1281318416')

    Sentry.init({
        dsn: 'https://693ba8172e464caf813fcdce48b4aa8e@op.byecode.com/4',
        integrations: [
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            }),
            Sentry.replayIntegration()
        ],
        enabled,
        environment: VITE_SENTRY_ENVIRONMENT,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: sentryMaxRate
    })
}
