import { Button } from '@byecode/ui'
import type { AggregateField, Field, FormulaField, SchemaProtocol } from '@lighthouse/core'
import { find } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import styled from 'styled-components'

import type { JSONContent } from './FormulaValueEditor'
import { createDsAndFieldMap, decodeExpression, FormulaPreviewer, FormulaView } from './FormulaValueEditor'
import * as SC from './styles'
// import { createDsAndFieldMap, decodeExpression } from './utils'

interface PersonConfigViewProps {
    config: FormulaField | AggregateField
    schema: SchemaProtocol['schema']
    fieldIds: string[]
    onOk?: (data: Field) => Promise<boolean>
    onCancel: () => void
}

const SCxMask = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    backdrop-filter: brightness(0.92) blur(0.25px);
    -webkit-backdrop-filter: brightness(0.92) blur(0.25px);

    transition: all 100ms ease-out;
`

export const FormulaConfigView: React.FC<PersonConfigViewProps> = ({
    config = { id: '', name: '' ,dsId: '', type: 'formula', formula: { expression: '' } },
    schema,
    fieldIds,
    onOk,
    onCancel
}) => {
    const formula = useMemo(() => {
        if (config.type === 'formula') {
            return (config as FormulaField).formula
        }
        return (config as AggregateField)?.aggregation?.formula
    }, [config])
    const [loading, setLoading] = useState(false)
    const [expression, setExpression] = useState(formula?.expression || '')
    const { id: fieldId } = config

    const domRef = useRef<HTMLDivElement>(null)
    const [opened, setOpened] = useState(false)
    const fields = useMemo(() => {
        const initList = fieldIds.reduce<Field[]>((prev,id) => {
            const field = schema[id]
            if(field){
                prev.push(field)
            }
            return prev
        }, [])

        return Object.values(schema).reduce<Field[]>((prev, cur) => {
            const field = find(item => item.id === cur.id, prev || [])
            if (!field) {
                prev.push(cur)
            }
            return prev
        }, initList)
    }, [fieldIds, schema])

    const dsAndFieldMap = useMemo(() => createDsAndFieldMap(fields), [fields])
    // const { fieldBiMap } = dsAndFieldMap
    const dsExpression: JSONContent | undefined = useMemo(() => {
        return decodeExpression(expression)(dsAndFieldMap)
    }, [dsAndFieldMap, expression])
    // const [value, setValue] = useState<JSONContent | undefined>(dsExpression)

    const parseExpression = useCallback((json: JSONContent) => {
        return (
            json.content?.reduce((prev, cur, index) => {
                if (cur?.type === 'paragraph' && index > 0) {
                    prev += '\n'
                }
                if (cur?.content) {
                    prev += parseExpression(cur)
                    return prev
                }
                const id: string = cur?.attrs?.id || ''
                if (cur?.type === 'field' && id) {
                    prev += `{${id}}`
                }
                if (cur?.type === 'datasource' && id) {
                    prev += `\${${id}}`
                }
                if (cur?.type === 'text') {
                    prev += cur.text
                }
                return prev
            }, '') || ''
        )
    }, [])

    const handleChange = useCallback(
        (json: JSONContent | undefined) => {
            const str = json ? parseExpression(json) : ''
            setExpression(str)
            setOpened(false)
        },
        [parseExpression]
    )

    const handleOk = useCallback(
        async (str: string) => {
            // const str = json ? parseExpression(json) : ''
            if (config.type === 'aggregation') {
                setLoading(true)
                const aggregationConfig = config as AggregateField
                const isOk = await onOk?.({
                    ...aggregationConfig,
                    type: config.type,
                    aggregation: {
                        ...aggregationConfig.aggregation,
                        formula: {
                            expression: str
                        }
                    }
                })
                setLoading(false)
                onCancel()
                return isOk
            }
            onCancel()
            return onOk?.({
                ...config,
                type: 'formula',
                formula: {
                    expression: str
                },
                innerType: 'NULL'
            })
        },
        [config, onCancel, onOk]
    )

    useHotkeys(
        'Enter',
        () => {
            handleOk(expression)
        },
        { enableOnTags: ['INPUT'] }
    )

    const getPreviewValue = useCallback((expressions: JSONContent[] | undefined) => {
        if (!expressions) {
            return ''
        }
        return expressions.reduce((prev, cur) => {
            const name: string = cur?.attrs?.name
            if (cur.type === 'field' && name) {
                prev += `{${name}}`
            }
            if (cur.type === 'text') {
                prev += cur.text
            }
            if (cur.content) {
                prev += getPreviewValue(cur.content)
            }
            return prev
        }, '')
    }, [])

    return (
        <SC.FieldForm ref={domRef}>
            <SC.FormItemWrapper>
                <SC.FormItemLabel>公式</SC.FormItemLabel>
                <SC.FormItemCell style={{ paddingBottom: '8px', flexFlow: 'column' }}>
                    <FormulaPreviewer formulaText={getPreviewValue(dsExpression?.content)} />
                    <Button size="md" style={{ marginTop: '8px', width: '100%' }} onClick={() => setOpened(true)}>
                        编 辑
                    </Button>
                    {opened ? (
                        <SCxMask>
                            <FormulaView
                                fieldId={fieldId}
                                fields={fields}
                                value={dsExpression}
                                onClose={() => setOpened(false)}
                                onOk={json => {
                                    handleChange(json)
                                }}
                                onCancel={() => {
                                    setOpened(false)
                                }}
                            />
                        </SCxMask>
                    ) : null}
                </SC.FormItemCell>
            </SC.FormItemWrapper>
            <SC.ActionWrapper>
                <Button style={{ marginRight: 8 }} onClick={onCancel}>
                    取消
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        handleOk(expression)
                    }}
                    loading={loading}
                >
                    确定
                </Button>
            </SC.ActionWrapper>
        </SC.FieldForm>
    )
}
