import { Button, Flex, IconFont, Loading, Tooltip } from '@byecode/ui'
import { getFileSizeWithUnit } from '@lighthouse/tools'
import { useRetry } from '@rpldy/retry-hooks'
import type { UploadOptions } from '@rpldy/uploady'
import { FILE_STATES, useAbortItem } from '@rpldy/uploady'
import type { FC } from 'react'
import React, { useEffect, useMemo, useState } from 'react'

import * as SC from './UploadItem.style'

interface UploadItemProps {
    id: string
    file: File
    status: FILE_STATES
    progress: number
    options?: UploadOptions
    onDeleteItem: (id: string) => void
    onPreview: (id: string) => void
}

export const UploadItem: FC<UploadItemProps> = ({ id, file, status, progress, options, onDeleteItem, onPreview }) => {
    const abortItem = useAbortItem()
    const retryItem = useRetry()

    const [imgSrc, setImgSrc] = useState('')

    useEffect(() => {
        if (file.type.includes('image')) {
            const url = URL.createObjectURL(file)
            setImgSrc(url)

            return () => URL.revokeObjectURL(url)
        }
    }, [file])

    const iconType = useMemo(() => {
        if (file.type.includes('xls')) {
            return 'FileXsl'
        } else if (file.type.includes('doc')) {
            return 'FileDoc'
        } else if (file.type.includes('pdf')) {
            return 'FilePdf'
        } else if (file.type.includes('ppt')) {
            return 'FilePpt'
        } else if (file.type.includes('zip')) {
            return 'FileZip'
        } else if (file.type.includes('txt')) {
            return 'FileText'
        } else if (file.type.includes('video')) {
            return 'FileVideo'
        } else if (file.type.includes('audio')) {
            return 'FileAudio'
        }
        return 'FileNoting'
    }, [file.type])

    const actionDom = useMemo(() => {
        switch (status) {
            case FILE_STATES.ADDED:
            case FILE_STATES.PENDING: {
                return (
                    <Tooltip title="取消上传">
                        <Button
                            type="text"
                            onClick={() => {
                                abortItem(id)
                                onDeleteItem(id)
                            }}
                            icon={<IconFont type="Close" color="var(--color-gray-400)" />}
                        />
                    </Tooltip>
                )
            }
            case FILE_STATES.UPLOADING: {
                return (
                    <Tooltip title="取消上传">
                        <Button type="text" onClick={() => abortItem(id)} icon={<IconFont type="Close" color="var(--color-gray-400)" />} />
                    </Tooltip>
                )
            }
            case FILE_STATES.ERROR: {
                return (
                    <Tooltip title="重试">
                        <Button type="text" onClick={() => retryItem(id, options)} icon={<IconFont type="ArrowsClockwise" />} />
                    </Tooltip>
                )
            }

            default: {
                return (
                    <Tooltip title="删除记录">
                        <Button
                            type="text"
                            onClick={() => onDeleteItem(id)}
                            icon={<IconFont type="Close" color="var(--color-gray-400)" />}
                        />
                    </Tooltip>
                )
            }
        }
    }, [abortItem, id, onDeleteItem, options, retryItem, status])

    const tipDom = useMemo(() => {
        switch (status) {
            case FILE_STATES.ADDED:
            case FILE_STATES.PENDING: {
                return <span>队列中</span>
            }

            case FILE_STATES.UPLOADING: {
                return (
                    <>
                        <Loading outlined size={12} />
                        <span>上传中</span>
                    </>
                )
            }

            case FILE_STATES.ERROR: {
                return <span style={{ color: '#F04438' }}>上传失败，请重试</span>
            }

            case FILE_STATES.FINISHED: {
                return (
                    <>
                        <IconFont type="TickCircle" color="var(--color-green-500)" size={12} />
                        <span>已上传</span>
                    </>
                )
            }

            case FILE_STATES.ABORTED: {
                return <span>已取消</span>
            }

            default: {
                return null
            }
        }
    }, [status])

    return (
        <SC.ListItem progress={status === FILE_STATES.UPLOADING ? Math.floor(progress) : 0}>
            <SC.ListItemIcon>
                {file.type.includes('image') ? (
                    !!imgSrc && (
                        <img
                            style={{ width: 20, height: 20, cursor: 'pointer' }}
                            onClick={() => onPreview(id)}
                            src={imgSrc}
                            alt={file.name}
                        />
                    )
                ) : (
                    <IconFont type={iconType} size={20} />
                )}
            </SC.ListItemIcon>

            <SC.ListItemContent>
                <Flex mb={3.5} justifyContent="space-between">
                    <SC.FileName>{file.name}</SC.FileName>
                    <SC.FileSize>{getFileSizeWithUnit(file.size)}</SC.FileSize>
                </Flex>
                <SC.FileStatus>{tipDom}</SC.FileStatus>
            </SC.ListItemContent>

            <SC.ListItemActions>{actionDom}</SC.ListItemActions>
        </SC.ListItem>
    )
}
