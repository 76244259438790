import styled from 'styled-components'

export const TabsController = styled.button`
    all: unset;
    cursor: pointer;
    font-size: 16px;
    padding: 0 10px;
    display: flex;
    align-items: center;
`
