import type { VariableADTvalue } from '@lighthouse/core'
import React, { forwardRef, Fragment } from 'react'

import type { ContainerHighlight, FlowLayoutNode, LayoutProperty, NodeRenderProps } from '../types'
import { Canvas } from './Canvas'
import { Gap } from './Gap'
import type { FlowLayoutNodeProps } from './Node'
import { Node } from './Node'

interface RenderProps {
    data?: FlowLayoutNode[]

    size?: LayoutProperty['size']
    gap?: LayoutProperty['gap']
    direction?: LayoutProperty['direction']
    padding?: LayoutProperty['padding']
    alignX?: LayoutProperty['alignX']
    alignY?: LayoutProperty['alignY']
    veins?: LayoutProperty['veins']
    background?: LayoutProperty['background']
    style?: React.CSSProperties

    parseBackgroundVariableImage?: (value: VariableADTvalue | undefined) => string

    disabled?: boolean
    highlight?: Record<string, ContainerHighlight>
    nodeRender?: (props: NodeRenderProps) => JSX.Element | null
    labelRender?: (id: string) => React.ReactNode
}

interface Props extends FlowLayoutNodeProps {
    unstyled?: boolean
    disabled?: boolean
}

export const NodeRender = forwardRef<HTMLDivElement, Props>(({ data, unstyled, disabled, ...rest }, ref) => {
    return (
        <Node ref={ref} data={data} unstyled={unstyled} disabled={disabled} {...rest}>
            {data.type === 'container' &&
                data.children?.map((item, index, array) => (
                    <Fragment key={item.id}>
                        <NodeRender data={item} />
                        {index !== array.length - 1 && <Gap />}
                    </Fragment>
                ))}
        </Node>
    )
})

export const Entry = forwardRef<HTMLDivElement, RenderProps>(({ data, ...rest }, ref) => {
    if (!data) {
        return null
    }

    return (
        <Canvas ref={ref} {...rest}>
            {data.map((item, index, array) => (
                <Fragment key={item.id}>
                    <NodeRender key={item.id} data={item} />
                    {index !== array.length - 1 && <Gap />}
                </Fragment>
            ))}
        </Canvas>
    )
})
