import { Input } from '@byecode/ui/components/Input'
import { Select } from '@byecode/ui/components/Select'
import chroma from 'chroma-js'
import equal from 'fast-deep-equal'
import React, { useEffect, useMemo, useState } from 'react'
import { useLatest } from 'react-use'
import styled from 'styled-components'

import { CopyButton } from '../../CopyButton'
import type { HsvaColor, RgbaColor } from '../type'

const Container = styled.div`
    display: flex;
    gap: 4px;
    padding: 8px 0;
`

const ColorFormatPicker = styled(Select)`
    width: 66px;
    font-weight: 600;
`

const ColorInputPrefix = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`
const ColorInputPreview = styled.div`
    border-radius: 4px;
    width: 20px;
    height: 20px;
`
const ColorHexPrefix = styled.span`
    width: 0.5em;
    color: #888;
`
const ColorInputCopyButton = styled(CopyButton)`
    all: unset;
    display: inline-flex;
    align-items: center;
    padding: 4px;
    color: var(--color-gray-400);
`

const RgbContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const COLOR_FORMAT_OPTIONS = [
    {
        label: 'Hex',
        value: 'hex'
    },
    {
        label: 'RGB',
        value: 'rgba'
    }
]

interface ColorInputContainerProps {
    value: HsvaColor
    onChange: (v: HsvaColor) => void
}

export const ColorInputContainer = ({ value, onChange }: ColorInputContainerProps) => {
    const [format, setFormat] = useState('hex')

    /** ************************** hex *************************************** */
    const [draftHex, setDraftHex] = useState(() => {
        return chroma(value.h, value.s, value.v, 'hsv').hex()
    })
    const latestValue = useLatest(draftHex)

    useEffect(() => {
        const newHex = chroma(value.h, value.s, value.v, 'hsv').hex()
        if (latestValue.current !== newHex) {
            setDraftHex(newHex)
        }
    }, [value, latestValue])

    const handleHexChange = (str: string) => {
        // eslint-disable-next-line unicorn/no-unsafe-regex
        const matches = /^([\dA-Fa-f]{3}){1,2}$/.exec(str)
        // const matches = /^([\da-f]{3}){1,2}$|^([\da-f]{4}){1,2}$/.exec(str)
        if (matches) {
            const fullValue = padHex(str)
            const color = chroma(fullValue)
            const [h, s, v] = color.hsv()
            setDraftHex(fullValue)
            onChange({ h, s, v, a: value.a })
        } else {
            const newHex = chroma(value.h, value.s, value.v, 'hsv').hex()
            setDraftHex(newHex)
        }
    }

    /** ************************** rgb *************************************** */
    const [draftRgbColor, setDraftRgbColor] = useState<Record<Exclude<keyof RgbaColor, 'a'>, number | string>>(() => {
        const [r, g, b] = chroma(value.h, value.s, value.v, 'hsv').rgb()
        return { r, g, b }
    })
    const latestRgbColor = useLatest(draftRgbColor)

    useEffect(() => {
        const [r, g, b] = chroma(value.h, value.s, value.v, 'hsv').rgb()
        const newRgbColor = { r, g, b }

        if (!equal(latestRgbColor.current, newRgbColor)) {
            setDraftRgbColor(newRgbColor)
        }
    }, [latestRgbColor, value.h, value.s, value.v])

    const handleRgbChange = (key: Exclude<keyof RgbaColor, 'a'>, str: string) => {
        const matches = /^\d+$/.exec(str)
        if (matches) {
            const keyValue = Math.max(0, Math.min(255, Number(str)))
            setDraftRgbColor(s => ({ ...s, [key]: keyValue }))
            const [h, s, v] = chroma(
                key === 'r' ? keyValue : Number(draftRgbColor.r),
                key === 'g' ? keyValue : Number(draftRgbColor.g),
                key === 'b' ? keyValue : Number(draftRgbColor.b),
                'rgb'
            ).hsv()
            onChange({ h, s, v, a: value.a })
        } else {
            const [r, g, b] = chroma(value.h, value.s, value.v, 'hsv').rgb()
            const rgbObj = { r, g, b }
            setDraftRgbColor(s => ({ ...s, [key]: rgbObj[key] }))
        }
    }

    /** ************************** alpha *************************************** */
    const [draftAlpha, setDraftAlpha] = useState(`${Math.round(value.a * 100)}%`)
    const alphaNumber = useMemo(() => {
        const reg = /(\d+)%?$/
        const match = reg.exec(draftAlpha)
        if (!match) {
            return 1
        }
        return Number(match[1]) / 100
    }, [draftAlpha])
    const latestDraftAlpha = useLatest(draftAlpha)

    useEffect(() => {
        if (latestDraftAlpha.current !== `${Math.round(value.a * 100)}%`) {
            setDraftAlpha(`${Math.round(value.a * 100)}%`)
        }
    }, [value.a, latestDraftAlpha])

    const handleAlphaChange = (str: string) => {
        const matches = /\d+(%?$)/.exec(str)
        if (matches) {
            const [v, percentage] = matches
            const number = percentage ? v.replace('%', '') : v
            const inRangeNumber = Math.max(0, Math.min(100, Number(number)))
            setDraftAlpha(`${inRangeNumber}%`)
            onChange({ ...value, a: inRangeNumber / 100 })
        } else {
            setDraftAlpha(`${Math.round(value.a * 100)}%`)
        }
    }

    const [copySuccess, setCopySuccess] = useState(false)

    return (
        <Container>
            <ColorFormatPicker
                options={COLOR_FORMAT_OPTIONS}
                size="lg"
                placeholder=""
                value={format}
                onChange={setFormat}
                styles={{
                    root: {
                        background: 'none',
                        '&:focus, &:focus-within': {
                            borderColor: 'transparent'
                        }
                    }
                }}
            />

            {format === 'hex' ? (
                <Input
                    styles={{
                        prefix: {
                            width: 40,
                            padding: '0 4px'
                        },
                        input: {
                            paddingLeft: 40
                        }
                    }}
                    size="lg"
                    placeholder=""
                    prefix={
                        <ColorInputPrefix>
                            <ColorInputPreview style={{ backgroundColor: draftHex }} />
                            <ColorHexPrefix>#</ColorHexPrefix>
                        </ColorInputPrefix>
                    }
                    suffix={<ColorInputCopyButton value={draftHex} />}
                    value={draftHex.replace('#', '')}
                    onChange={e => {
                        const { value } = e.currentTarget
                        setDraftHex(value)
                    }}
                    onBlur={e => {
                        const { value } = e.currentTarget
                        handleHexChange(value)
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            const { value } = e.currentTarget
                            handleHexChange(value)
                        }
                    }}
                    onFocus={e => {
                        e.target.select()
                    }}
                />
            ) : (
                <RgbContainer>
                    <Input
                        styles={{ input: { padding: '2px', textAlign: 'center' } }}
                        size="lg"
                        placeholder=""
                        value={draftRgbColor.r}
                        onChange={e => {
                            const { value } = e.currentTarget
                            setDraftRgbColor(s => ({ ...s, r: value }))
                        }}
                        onBlur={e => {
                            const { value } = e.currentTarget
                            handleRgbChange('r', value)
                        }}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                const { value } = e.currentTarget
                                handleRgbChange('r', value)
                            }
                        }}
                        onFocus={e => {
                            e.target.select()
                        }}
                    />
                    <Input
                        styles={{ input: { padding: '2px', textAlign: 'center' } }}
                        size="lg"
                        placeholder=""
                        value={draftRgbColor.g}
                        onChange={e => {
                            const { value } = e.currentTarget
                            setDraftRgbColor(s => ({ ...s, g: value }))
                        }}
                        onBlur={e => {
                            const { value } = e.currentTarget
                            handleRgbChange('g', value)
                        }}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                const { value } = e.currentTarget
                                handleRgbChange('g', value)
                            }
                        }}
                        onFocus={e => {
                            e.target.select()
                        }}
                    />
                    <Input
                        styles={{ input: { padding: '2px', textAlign: 'center' } }}
                        size="lg"
                        placeholder=""
                        value={draftRgbColor.b}
                        onChange={e => {
                            const { value } = e.currentTarget
                            setDraftRgbColor(s => ({ ...s, b: value }))
                        }}
                        onBlur={e => {
                            const { value } = e.currentTarget
                            handleRgbChange('b', value)
                        }}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                const { value } = e.currentTarget
                                handleRgbChange('b', value)
                            }
                        }}
                        onFocus={e => {
                            e.target.select()
                        }}
                    />
                </RgbContainer>
            )}

            <Input
                styles={{
                    wrapper: { flex: '0 0 58px', marginLeft: 4 },
                    input: {
                        padding: '2px 8px',
                        textAlign: 'center'
                    }
                }}
                size="lg"
                placeholder=""
                value={draftAlpha}
                onChange={e => {
                    const { value } = e.currentTarget
                    setDraftAlpha(value)
                }}
                onBlur={e => {
                    const { value } = e.currentTarget
                    handleAlphaChange(value)
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        const { value } = e.currentTarget
                        handleAlphaChange(value)
                    }
                }}
                onFocus={e => {
                    e.target.select()
                }}
            />
        </Container>
    )
}

function padHex(hex: string) {
    if (hex.length === 3) {
        const [a, b, c] = hex
        return a + a + b + b + c + c
    }

    // if (hex.length === 4) {
    //     const [a, b, c, d] = hex
    //     return a + a + b + b + c + c + d + d
    // }

    return hex
}
