export const numberReg = /^\d|-|\.$/u

// 手机号正则
export const mobileRegex = /^1([3-9])\d{9}$/u

// 正整数正则
export const intRegex = /^\d*$/u

// 密码正则
export const passwordRegex = /^(?=.*?[a-zA-Z]|[.!@#$%^_&*])(?=.*?[0-9])(?=.*?).{8,20}$/u

// 密码格式
export const passwordFormatRegex = /^(?=.*?[a-zA-Z]|[.!@#$%^_&*])(?=.*?[0-9])(?=.*?).*$/u

// 邮箱正则
// eslint-disable-next-line unicorn/no-unsafe-regex
export const emailRegex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/

// 网址正则
export const urlRegex = /([0-9a-z-]{2,}\.[0-9a-z-]{2,3}\.[0-9a-z-]{2,3}|[0-9a-z-]{2,}\.[0-9a-z-]{2,3}([\s\S]*))$/u

// 文件后缀正则
export const fileSuffixRegex = /\.([\da-z]+)(?:[#?]|$)/iu

export const pageUrlReg = /\/[page | P]\/[0-9a-zA-Z]*/u

// 文件名称正则
export const fileNameRegex = /\/[0-9a-z-]*\./iu

// 阿里云视频
export const aliyunVideoReg = /^[a-zA-Z0-9]+\/[^\/]+\.\w+\?.*$/u

// 域名正则
// eslint-disable-next-line unicorn/no-unsafe-regex
export const domainReg = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/u


// a. 不能以连字符开头或结尾；
export const domainReg1 = /^(?!-).*[^-]$/u
// b. 使用英文字母（a-z）、数字（0-9）和连字符（-）；
export const domainReg2 = /^[A-Za-z0-9-]+$/u

// 条形码内容正则
// eslint-disable-next-line no-control-regex
export const barCodeReg = /^[\u0000-\u007F]+$/u

// 新用户注册邀请码正则
export const inviteCodeReg = /^[\dA-Za-z]{6}$/
