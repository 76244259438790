import { NavigationShowMode } from '@lighthouse/core'
import { useState } from 'react'
import { useDebounce, useMeasure, useSize } from 'react-use'

import type { ApplicationContainerPreviewMode } from './type'

export const useCurrentShowMode = function (showMode: NavigationShowMode, previewType: ApplicationContainerPreviewMode) {
    const [ref, { width, height }] = useMeasure<HTMLDivElement>()

    const [debounceShowMode, setDebounceShowMode] = useState(showMode)
    useDebounce(
        () => {
            if (previewType === 'mobile' || previewType === 'simulationMobile') {
                setDebounceShowMode(showMode)
            }
            if (width) {
                const currentShowMode =
                    width < 720 && showMode === NavigationShowMode.verticalWide ? NavigationShowMode.horizontal : showMode
                setDebounceShowMode(currentShowMode)
            }
        },
        100,
        [showMode, width, showMode]
    )

    return { ref, showMode: debounceShowMode, width, height }
}
