import { add, divide, subtract } from 'rambda'
import React, { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

const SCxContent = styled.div<{ left: number }>`
    padding: 5px 10px;
    background-color: var(--color-black);
    color: var(--color-white);
    line-height: 1.55;
    border-radius: 6px;
    font-size: 12px;

    &:after {
        content: '';
        width: 0.25rem;
        height: 0.25rem;
        transform: rotate(45deg);
        position: absolute;
        border-bottom-right-radius: Orem;
        left: ${({ left }) => left}px;
        bottom: -0.125rem;
        border-top-color: transparent;
        border-left-color: transparent;
        background-color: inherit;
        border: 0px;
    }
`

interface AdvancedTableTooltipProps {
    target: HTMLDivElement
    label: string
}

export const AdvancedTableTooltip: React.FC<AdvancedTableTooltipProps> = ({ target, label }) => {
    const [floatElement, setFloatElement] = useState<HTMLDivElement | null>(null)
    const { x, y, width, height } = target.getBoundingClientRect()
    const floatWidth = useMemo(() => floatElement?.getBoundingClientRect().width || 0, [floatElement])
    const top = y - height + 5
    const left = useMemo(() => {
        if (floatElement) {
            return add(x, divide(subtract(width, floatWidth), 2))
        }
        return x
    }, [floatElement, floatWidth, width, x])

    const style: React.CSSProperties = {
        position: 'absolute',
        top: `${top}px`,
        left: `${left}px`,
        zIndex: 100
    }

    return createPortal(
        <SCxContent ref={setFloatElement} style={style} left={divide(floatWidth, 2)}>
            {label}
        </SCxContent>,
        document.body
    )
}
