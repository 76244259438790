import { useByecodeUIConfig } from '@byecode/ui/providers'
import { styled } from '@byecode/ui/theme/stitches.config'
import { formatMessage } from '@byecode/ui/utils/intl'
import { isAfter, isBefore, lightFormat } from 'date-fns'
import React, { useCallback, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { Flex } from '../../Flex'
import { IconFont } from '../../IconFont'
import type { BaseModalProps } from '../../Modal'
import { MobileModal } from '../../Modal'
import { Text } from '../../Text'
import { Toast } from '../../Toast'

const StyledCancel = styled('div', {
    color: '$colorGray400'
})
const StyledFinish = styled('div', {
    color: '$colorPrimary'
})
const StyledClearButton = styled('button', {
    appearance: 'none',
    width: '100%',
    height: 50,
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    color: '$colorError500',
    backgroundColor: '$colorWhite',
    borderRadius: 6,
    fontSize: '16px'
})

const StyledPickerWrapper = styled('div', {
    overflow: 'hidden',
    borderRadius: 12,
    backgroundColor: '$colorWhite'
})

const StyledPickerItem = styled('div', {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '14px 16px',
    '&:not(:last-of-type)': {
        borderBottom: '1px solid $colorGray200'
    }
})
const StyledDateInput = styled('input', {
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100px',
    height: '100%'
})

function getDateTime(value: Date | undefined) {
    const hasInitValue = !!value
    const d = value ?? new Date()
    const date = lightFormat(d, 'yyyy-MM-dd')
    const time = hasInitValue ? lightFormat(d, 'HH:mm') : '00:00'
    return { date, time }
}

interface MobileDatePickerModalProps extends Omit<BaseModalProps, 'value' | 'defaultValue' | 'onChange'> {
    value?: Date
    defaultValue?: Date
    onChange?: (v: Date | undefined) => void
    minDate?: Date | undefined
    maxDate?: Date | undefined
}

export const MobileDatePickerModal = ({
    open,
    value,
    defaultValue,
    onChange,
    onClose,
    minDate,
    maxDate,
    ...modalProps
}: MobileDatePickerModalProps) => {
    const [dateTime, setDateTime] = useState(() => getDateTime(value ?? defaultValue))

    const { locale } = useByecodeUIConfig()

    useUpdateEffect(() => {
        if (open) {
            setDateTime(getDateTime(value ?? defaultValue))
        }
    }, [open, defaultValue, value])

    const handleFinish = useCallback(() => {
        const { date, time } = dateTime
        if (!date || !time) {
            return
        }

        onChange?.(new Date(`${date} ${time}`))
        onClose?.()
    }, [dateTime, onClose, onChange])

    const inputDateRef = useRef<HTMLInputElement | null>(null)
    const inputTimeRef = useRef<HTMLInputElement | null>(null)

    const isIOS = /\(i[^;]+;( U;)? CPU.+Mac OS X/.test(navigator.userAgent)

    return (
        <MobileModal
            leftSlot={<StyledCancel onClick={() => onClose?.()}> {formatMessage('cancel', locale)}</StyledCancel>}
            extra={
                <StyledFinish onClick={handleFinish} style={{ opacity: !dateTime.date || !dateTime.time ? 0.2 : undefined }}>
                    {formatMessage('complete', locale)}
                </StyledFinish>
            }
            withCloseIcon={false}
            styles={{
                modal: { height: '40%', backgroundColor: '$colorGray50' },
                body: { display: 'flex', flexDirection: 'column' },
                title: { flex: 1, textAlign: 'center' }
            }}
            open={open}
            onClose={onClose}
            {...modalProps}
        >
            <StyledPickerWrapper>
                <StyledPickerItem
                    onClick={() => {
                        inputDateRef.current?.showPicker()
                    }}
                >
                    <Flex gap={4}>
                        <IconFont type="PropertyCalendar" fill="var(--color-gray-400)" />
                        <Text> {formatMessage('date', locale)}</Text>
                    </Flex>
                    <Text size={14} color="var(--color-gray-400)">
                        {dateTime.date}
                    </Text>
                    <StyledDateInput
                        ref={inputDateRef}
                        type="date"
                        // value={dateTime.date}
                        min={minDate && lightFormat(minDate, 'yyyy-MM-dd')}
                        max={maxDate && lightFormat(maxDate, 'yyyy-MM-dd')}
                        onInput={e => {
                            const { value } = e.currentTarget
                            if (value) {
                                if (minDate && isBefore(new Date(value), minDate)) {
                                    return
                                }

                                if (maxDate && isAfter(new Date(value), maxDate)) {
                                    return
                                }
                            }
                            setDateTime(s => ({ ...s, date: value }))
                        }}
                        onFocus={e => {
                            e.target.defaultValue = dateTime.date
                            e.target.value = dateTime.date
                        }}
                        css={{
                            pointerEvents: isIOS ? 'unset' : 'none',
                            width: isIOS ? '100%' : '100px'
                        }}
                    />
                </StyledPickerItem>
                <StyledPickerItem
                    onClick={() => {
                        inputTimeRef.current?.showPicker()
                    }}
                >
                    <Flex gap={4}>
                        <IconFont type="PropertyCreatTime" fill="var(--color-gray-400)" />
                        <Text> {formatMessage('time', locale)}</Text>
                    </Flex>
                    <Text size={14} color="var(--color-gray-400)">
                        {dateTime.time}
                    </Text>
                    <StyledDateInput
                        ref={inputTimeRef}
                        type="time"
                        // value={dateTime.time}
                        onInput={e => {
                            const { value } = e.currentTarget
                            if (value) {
                                if (minDate && isBefore(new Date(value), minDate)) {
                                    return
                                }

                                if (maxDate && isAfter(new Date(value), maxDate)) {
                                    return
                                }
                            }
                            setDateTime(s => ({ ...s, time: value }))
                        }}
                        onFocus={e => {
                            e.target.defaultValue = dateTime.time
                            e.target.value = dateTime.time
                        }}
                        css={{
                            pointerEvents: isIOS ? 'unset' : 'none',
                            width: isIOS ? '100%' : '100px'
                        }}
                    />
                </StyledPickerItem>
            </StyledPickerWrapper>

            <StyledClearButton
                onClick={() => {
                    onChange?.(undefined)
                    onClose?.()
                }}
            >
                <IconFont type="Trash" />
                <Text lineHeight="22px"> {formatMessage('clear', locale)}</Text>
            </StyledClearButton>
        </MobileModal>
    )
}
