export function initCnzzTracker(siteId: string) {
    // @ts-expect-error
    window._czc = window._czc || []
    ;(function () {
        const um = document.createElement('script')
        um.src = `https://v1.cnzz.com/z.js?id=${siteId}&async=1`
        const s = document.querySelectorAll('script')[0]
        s.parentNode?.insertBefore(um, s)
    })()
}
