import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import type { AnyObject } from '@byecode/ui/types'
import clsx from 'clsx'
import type { ForwardedRef } from 'react'
import React, { forwardRef, useMemo } from 'react'

import { Box } from '../Box'
import { Loading } from '../Loading'
import { Pagination } from '../Pagination'
import { TableContent } from './Content'
import { useStyles } from './Table.style'
import type { TableProps } from './Table.type'
import { TableToolbar } from './Toolbar'

export const _Table = <T extends AnyObject = AnyObject>(props: TableProps<T>, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        styles,
        classNames,
        unstyled,
        className,

        loading,
        data,
        columns,
        rowKey,
        tabs,
        filter,
        search,
        onSearchValueChange,
        actions,
        pagination,
        ...rest
    } = props

    const { classes } = useStyles({}, { name: 'table', styles, classNames, unstyled })

    const [_pageNum, _setPageNum] = useUncontrolled(
        pagination === false ? {} : { value: pagination?.pageNum, onChange: pagination?.onChange, defaultValue: 1 }
    )
    const _data = useMemo(() => {
        if (data && pagination !== false) {
            if (pagination?.total) {
                return data
            }
            const pageSize = pagination?.pageSize ?? 10
            return data.slice((_pageNum - 1) * pageSize, _pageNum * pageSize)
        }

        return data
    }, [data, _pageNum, pagination])

    return (
        <Box ref={ref} className={clsx(classes.root, className)} {...rest}>
            {loading && (
                <Box className={classes.loading}>
                    <Loading />
                </Box>
            )}
            {(tabs || search) && (
                <TableToolbar styles={styles} tabs={tabs} filter={filter} search={search} onSearchValueChange={onSearchValueChange} actions={actions} />
            )}
            <TableContent styles={styles} data={_data} columns={columns} rowKey={rowKey} />
            {data && data.length > 0 && pagination !== false && (
                <Pagination
                    className={classes.pagination}
                    {...pagination}
                    pageNum={_pageNum}
                    onChange={_setPageNum}
                    total={pagination?.total || data.length}
                />
            )}
        </Box>
    )
}

// eslint-disable-next-line etc/no-misused-generics
type TableGeneric = <T extends AnyObject = AnyObject>(props: TableProps<T> & { ref?: ForwardedRef<HTMLDivElement> }) => React.ReactElement

export const Table = forwardRef(_Table) as TableGeneric
