import { flex, pointer, singleTextEllipsis } from '@byecode/ui'
import type { ApplicationSettingNavbar, ApplicationSettingNavbarButton, ApplicationSettingTheme, ButtonEvents } from '@lighthouse/core'
import { ApplicationSettingThemeNavBarMode } from '@lighthouse/core'
import cls from 'classnames'
import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import * as CM from '../ApplicationHeader/styles'
import { useAppNavbarStylesContext } from '../AppNavbarStylesContext'
import { useAppThemeContext } from '../AppThemeContext'
import { getNavbarThemeStyles } from '../help'

export interface ApplicationLinkItemProps {
    value: ApplicationSettingNavbarButton
    activePage?: string
    opened?: boolean
    isSubNode?: boolean
    showRightSection?: boolean
    isAction?: boolean
    isUsedPx?: boolean
    style?: React.CSSProperties
    onCommonCallBack?: (params: ButtonEvents) => void
    onFold?: (id: string) => void
}

export interface LinkItemStyleProps {
    active?: boolean
    mode?: ApplicationSettingThemeNavBarMode
    children?: React.ReactNode
    color?: string
    style?: React.CSSProperties
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
}
export const Text = styled.span<{ color?: string; size: string }>`
    line-height: 20px;
    font-size: ${({ size }) => size};
    font-weight: 400;
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-style: normal;
    ${singleTextEllipsis}
`

export const Btn = styled.div`
    padding: 8px;
    ${flex}
    align-items: center;
    box-sizing: border-box;
    ${pointer}
`

export const SCXLinkItemStyle = styled.div<{ active?: boolean; color?: string; mode?: ApplicationSettingThemeNavBarMode }>`
    opacity: 0.8;
    color: ${({ color }) => color};
    border-radius: 8px;
    ${({ active, mode }) => {
        if (!active) {
            return
        }
        switch (mode) {
            case ApplicationSettingThemeNavBarMode.light: {
                return css`
                    background-color: var(--color-app-main-tint);
                    opacity: 1;
                `
            }
            case ApplicationSettingThemeNavBarMode.dark: {
                return css`
                    background-color: #ffffff15;
                    opacity: 1;
                `
            }
            default: {
                return css`
                    background-color: #00000015;
                    opacity: 1;
                `
            }
        }
    }}
    :hover {
        opacity: 1;

        ${({ mode }) => {
            switch (mode) {
                case ApplicationSettingThemeNavBarMode.light: {
                    return css`
                        background-color: var(--color-app-main-tint);
                    `
                }
                case ApplicationSettingThemeNavBarMode.dark: {
                    return css`
                        background-color: #ffffff15;
                    `
                }
                default: {
                    return css`
                        background-color: #00000015;
                    `
                }
            }
        }}
    }
`

export const LeftFill = styled.div<Pick<React.CSSProperties, 'justifyContent'>>`
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
    gap: 10px;
    overflow: hidden;
`
export const LinkItemStyles: React.FC<LinkItemStyleProps> = ({ active, children, ...rest }) => {
    return (
        <SCXLinkItemStyle {...rest} active={active} className={cls({ active })}>
            {children}
        </SCXLinkItemStyle>
    )
}

export const ApplicationLinkItem: React.FC<ApplicationLinkItemProps> = ({
    value,
    activePage,
    opened,
    isAction,
    showRightSection,
    isSubNode = false,
    style,
    isUsedPx = true,
    onCommonCallBack,
    onFold
}) => {
    const {
        id,
        icon,
        name,
        child = [],
        showType,
        events: { params, handleEvent },
        events
    } = value

    const pageId = params[0]
    const [isHover, setIsHover] = useState(false)

    const active = useMemo(
        () =>
            (handleEvent === 'openPage' && pageId.replace('/page/', '') === activePage?.replace('/page/', '') && !!pageId && !isSubNode) ||
            isAction,
        [activePage, handleEvent, isAction, isSubNode, pageId]
    )

    const themeStyle = useAppNavbarStylesContext()

    const color = active || isHover ? themeStyle?.stressColor : themeStyle?.color

    const isHideName = useMemo(() => showType === 'icon' || !name, [name, showType])

    const isHideIcon = useMemo(() => showType === 'name' || !icon, [icon, showType])

    return (
        <LinkItemStyles
            active={active}
            style={style}
            color={color}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            mode={themeStyle.themeMode}
        >
            <Btn
                onClick={e => {
                    child.length === 0 ? onCommonCallBack?.(events) : onFold?.(id)
                }}
            >
                <LeftFill>
                    {isHideIcon || <CM.Icon size={isUsedPx ? '16px' : '1rem'} type={icon ?? ''} />}
                    {isHideName || (
                        <Text color="inter" size={isUsedPx ? '16px' : '1rem'}>
                            {name}
                        </Text>
                    )}
                </LeftFill>
                {(child.length > 0 || showRightSection) && (
                    <CM.Icon size={isUsedPx ? '16px' : '1rem'} type={opened ? 'ArrowUpSmall' : 'ArrowDownSmall'} />
                )}
            </Btn>
        </LinkItemStyles>
    )
}
