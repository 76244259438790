import { Text } from '@mantine/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { COLORS_MAP, LIGHT_COLORS_MAP } from '../../../../../constants/color'
import { isValidColor } from '../../../help'

interface SelectItemValue {
    label: string
    color?: string
    value: string
}

interface ItemProps {
    data: SelectItemValue
    colorless?: boolean
    isActive?: boolean
    onClick?: React.MouseEventHandler<HTMLSpanElement>
}

const SCxCellValue = styled.span<Pick<React.CSSProperties, 'backgroundColor' | 'color' | 'borderColor'>>`
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    padding: 4px 8px;
    border-radius: 100px;
    height: 28px;
    line-height: 16px;
    gap: 6px;
    white-space: nowrap;
    border: 1px solid var(--color-gray-200);
    color: ${({ color }) => color ?? 'var(--color-white)'};
    border-color: ${({ borderColor }) => borderColor};
    max-width: 100%;
    background-color: ${({ backgroundColor }) => backgroundColor ?? 'var(--color-app-main)'};

    .active {
        background-color: var(--color-app-main);
        color: var(--color-white);
    }
`
export const Item: React.FC<ItemProps> = ({ data, colorless = false, isActive, onClick }) => {
    const { label, color: fontColor, value } = data
    const { color, backgroundColor, borderColor } = useMemo(() => {
        if (colorless) {
            return {}
        }
        const color = fontColor ? (isValidColor(fontColor) ? 'var(--color-white)' : `var(${COLORS_MAP[fontColor ?? ' ']})`) : undefined
        const lightColor = fontColor ? (isValidColor(fontColor) ? fontColor : `var(${LIGHT_COLORS_MAP[fontColor ?? ' ']})`) : undefined
        return {
            backgroundColor: lightColor,
            color,
            borderColor: fontColor && 'rgba(0,0,0,0.1)'
        }
    }, [fontColor, colorless])

    return (
        <SCxCellValue key={label} onClick={onClick} backgroundColor={backgroundColor} color={color} borderColor={borderColor}>
            <Text lineClamp={1}>{label} </Text>
        </SCxCellValue>
    )
}
