/**
 * 根据节点类型返回节点
 * @param node
 * @returns
 */
export const getElement = (node: string | Element | null | undefined) => {
    if (typeof node === 'string') {
        return document.querySelector(node)
    }

    return node
}
