import type { RangeSliderProps } from './RangeSlider'
import { RangeSlider } from './RangeSlider'
import { Slider as OriginSlider } from './Slider'

export type { RangeSliderValue } from './RangeSlider'

export const Slider = OriginSlider as typeof OriginSlider & {
    Range: typeof RangeSlider
}

Slider.Range = RangeSlider
