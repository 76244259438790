import type { TabsBlockAbstract, VariableADTvalue } from '@lighthouse/core'
import { useUncontrolled } from '@lighthouse/tools'
import React, { forwardRef, useMemo } from 'react'
import styled from 'styled-components'

import { Tabs } from './Tabs'

const Root = styled.div`
    padding: var(--block-padding);
`

interface TabsBlockProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'value' | 'onChange'> {
    blockData: TabsBlockAbstract
    scale?: number
    value?: string
    onChange?: (value: string) => void
    onClickTab?: (e: React.MouseEvent<HTMLButtonElement>, id: string) => void

    parseVariableImage?: (value: VariableADTvalue) => string
}

const TabsBlock = forwardRef<HTMLDivElement, TabsBlockProps>(
    ({ scale, blockData, value, onChange, parseVariableImage, onClickTab, ...rest }, ref) => {
        const { config } = blockData
        const { variantMap, variant, lineColor, font, baseList } = config
        const { styleList, align, design } = variantMap[variant]

        const [_value, _onChange] = useUncontrolled({ value, onChange, defaultValue: baseList?.[0]?.id })

        const list = useMemo(() => {
            return baseList.map(item => {
                const style = styleList.find(s => s.id === item.id)
                return {
                    ...item,
                    ...style
                }
            })
        }, [baseList, styleList])

        return (
            <Root ref={ref} {...rest}>
                <Tabs
                    mode={variant}
                    design={design}
                    lineColor={lineColor}
                    align={align}
                    scale={scale}
                    list={list}
                    font={font}
                    value={_value}
                    onChange={_onChange}
                    onClickTab={onClickTab}
                    parseVariableImage={parseVariableImage}
                    data-stop-action-propagation
                />
            </Root>
        )
    }
)

export default TabsBlock
