import { locale as cnLocale } from '../locale/cn'
import { locale as enLocale } from '../locale/en'
import { locale as twLocale } from '../locale/tw'

const MAP: Record<string, typeof cnLocale> = {
    'zh-CN': cnLocale,
    'zh-TW': twLocale,
    en: enLocale
}

export const formatMessage = (key: keyof typeof cnLocale, locale: string) => {
    return MAP[locale]?.[key]
}
