import { noButtons, tinyButtons } from '@byecode/ui'
import type { NavigationShowMode } from '@lighthouse/core'
import type { CSSProperties } from 'styled-components'
import styled from 'styled-components'

import { Background, BtnGroup, Header, Icon, LeftGroup, RightGroup, UserCenter } from '../ApplicationHeader/styles'
import { Btn, LeftFill, SCXLinkItemStyle, Text } from '../ApplicationLinkItem'
import { ApplicationName, LogoContain } from '../ApplicationLogo'
import { getRealPx } from '../help'

// ['horizontal', 'verticalNarrow', 'verticalWide']
type PropsType = {
    linkListAlgin?: CSSProperties['justifyContent']
    selected?: boolean
    disableEvent?: boolean
    backgroundPadding: number
    maxWidth?: number
    mode?: NavigationShowMode
} & Pick<React.CSSProperties, 'backgroundColor' | 'width' | 'height' | 'padding' | 'borderRadius'>

export const Container = styled.div<PropsType>`
    font-size: var(--font-size-normal);
    pointer-events: ${({ disableEvent }) => (disableEvent ? 'none' : 'auto')};
    overflow: hidden;
    position: relative;
    padding: ${({ padding }) => padding};

    /* 水平 */
    &.horizontal {
        /* flex: 1; */
        width: 100%;
        /* border-bottom: 1px solid var(--color-gray-200); */
        flex-shrink: 0;
        display: flex;
        justify-content: center;

        &.backgroundOverlay {
            position: absolute !important;
        }

        /* 是否吸顶，且不占空间 */
        &.isSticky {
            position: sticky;
            top: 0;
            z-index: 11;
        }

        &.selected {
            border: 2px solid var(--color-purple-500);
        }

        ${Background} {
            display: flex;
            justify-content: center;
            border-radius: ${({ borderRadius }) => borderRadius};
            background-color: ${({ backgroundColor }) => backgroundColor};
            padding: ${({ backgroundPadding }) => `0 ${getRealPx(backgroundPadding)}`};
        }

        ${Header} {
            height: ${({ height }) => height};
            overflow: hidden;
            max-width: ${({ maxWidth }) => maxWidth}px;
            ${LeftGroup} {
                justify-content: flex-start;

                ${BtnGroup} {
                    flex: 1;
                    justify-content: ${({ linkListAlgin }) => linkListAlgin};
                    ${Btn} {
                        > * {
                            margin-right: 0;
                        }
                    }

                    ${LeftFill} {
                        ${Text} {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }

    /* 左侧栏略窄 */
    &.verticalNarrow {
        width: ${({ width }) => width};
        height: 100%;
        flex: 1;

        ${Header} {
            padding: 24px 8px;
            height: 100%;
            flex-direction: column;
            justify-items: center;
            align-content: space-between;

            ${ApplicationName} {
                margin-top: 12px;
            }

            ${LeftGroup} {
                width: 100%;
                flex-direction: column;

                > *:not(:first-child) {
                    margin-left: 0px;
                }

                ${BtnGroup} {
                    width: 100%;
                    flex-direction: column;
                    margin-left: 0;
                    margin-top: 12px;
                    > * {
                        justify-content: center;
                        width: 100%;
                        margin-left: 0;
                        margin-top: 16px;
                    }
                    ${SCXLinkItemStyle} {
                        ${Btn} {
                            width: 100%;
                            padding: 6px 0 !important;
                            margin-left: 0px !important;
                            flex-direction: column;
                            justify-items: flex-start;

                            ${LeftFill} {
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                ${Icon} {
                                    margin-right: 0;
                                }

                                ${Text} {
                                    width: 100%;
                                    margin-left: 0px !important;
                                    text-align: center;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
            ${RightGroup} {
                flex-direction: column;

                > * {
                    margin-left: 0px;
                    margin-top: 16px;
                }

                button {
                    padding: 19px 0;
                    width: 41px;
                    height: auto;

                    span {
                        writing-mode: vertical-lr;
                        word-wrap: break-word;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        white-space: pre-wrap;
                    }
                }
            }
        }
    }

    /* 左侧栏略宽 */
    &.verticalWide {
        height: 100%;
        flex-shrink: 0;
        overflow: unset;
        overflow: unset;

        &.selected {
            border-radius: 0 0 0 12px;
            border: 2px solid var(--color-purple-500);
            overflow: hidden;
        }

        ${Header} {
            padding: 32px 16px;
            width: ${({ width }) => width}!important;
            border-radius: ${({ borderRadius }) => borderRadius};
            background-color: ${({ backgroundColor }) => backgroundColor};
            height: 100%;
            overflow: hidden auto;
            ${noButtons('0')};
            flex-direction: column;
            justify-items: center;
            align-content: space-between;

            ${LogoContain} {
                padding: 0;
                padding-left: 12px;
                flex-direction: column;
                align-items: flex-start;

                ${ApplicationName} {
                    margin-top: 12px;
                    padding-left: 0;
                }
            }

            ${LeftGroup} {
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 0;

                ${BtnGroup} {
                    width: 100%;
                    padding-top: 14px;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-left: 0;

                    > * {
                        width: 100%;
                        margin-top: 8px;
                        margin-left: 0px;

                        [data-level]:not(:first-child) {
                            margin-top: 8px;
                        }
                    }

                    ${SCXLinkItemStyle} {
                        width: 100%;

                        ${Btn} {
                            width: 100%;
                            margin-left: 0px;
                            padding: 8px 12px;
                            align-items: center;
                            justify-content: space-between;

                            ${LeftFill} {
                                gap: 6px ${Text} {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
            ${RightGroup} {
                width: 100%;
                flex-direction: column;
                align-items: flex-start;

                > * {
                    width: 100%;
                    margin-left: 0px;
                    margin-top: 16px;
                }

                > button {
                    width: 100%;
                }

                ${UserCenter} {
                    /*  */
                }
            }
        }
    }
`
