import { Box, Divider, Flex, IconFont, Text, tinyButtons } from '@byecode/ui'
import type { ApplicationSettingThemeColor } from '@lighthouse/core'
import chroma from 'chroma-js'
import React from 'react'
import styled from 'styled-components'

import { useFillPickerContext } from '../Provider'
import type { HsvaColor } from '../type'

const List = styled.div`
    /* min-height: 80px;
    max-height: 600px; */
    margin: 0 -8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    /* overflow: hidden auto;
    ${tinyButtons} */
`

const IconButton = styled.button`
    all: unset;
    appearance: none;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 5px;
    color: var(--color-gray-400);
    &:hover {
        background: rgba(38, 65, 90, 0.06);
    }
`

const Item = styled.div`
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    ${IconButton} {
        opacity: 0;
    }
    &:hover {
        ${IconButton} {
            opacity: 1;
        }
        background-color: var(--color-gray-100);
    }
`

const ColorPreview = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 50%;
`

interface ColorLibraryProps {
    onChange: (value: ApplicationSettingThemeColor) => void

    onClickCreateColor?: () => void
    onClickEditColor?: (id: string) => void
}

export const ColorLibrary = ({ onChange, onClickCreateColor, onClickEditColor }: ColorLibraryProps) => {
    const { palettes } = useFillPickerContext()

    // const listRef = useRef<HTMLDivElement | null>(null)
    // const [height, setHeight] = useState<number | undefined>(undefined)
    // useLayoutEffect(() => {
    //     const element = listRef.current
    //     if (!element) {
    //         return
    //     }

    //     const resizeHandle = throttle(1000 / 60, () => {
    //         const rect = element.getBoundingClientRect()
    //         setHeight(Math.min(600, Math.max(80, document.documentElement.clientHeight - rect.top)))
    //     })

    //     resizeHandle()

    //     window.addEventListener('resize', resizeHandle)

    //     return () => {
    //         window.removeEventListener('resize', resizeHandle)
    //     }
    // }, [])

    return (
        <Box>
            <Divider my={16} />
            <Flex alignItems="center" justifyContent="space-between" styles={{ root: { height: 36 } }}>
                <Text size={14} lineHeight="22px">
                    样式库
                </Text>
                <IconButton onClick={() => onClickCreateColor?.()}>
                    <IconFont type="Add" size={16} />
                </IconButton>
            </Flex>

            <List /* ref={listRef} style={{ height }} */>
                {palettes.map(item => (
                    <Item
                        key={item.id}
                        onClick={() => {
                            onChange(item)
                        }}
                    >
                        <Flex gap={8} alignItems="center">
                            <Box p={2}>
                                <ColorPreview style={{ background: item.value }} />
                            </Box>
                            <Text size={14} lineHeight="16px">
                                {item.label}
                            </Text>
                        </Flex>

                        <IconButton
                            onClick={e => {
                                e.stopPropagation()
                                onClickEditColor?.(item.id)
                            }}
                        >
                            <IconFont type="Regulation" size={16} />
                        </IconButton>
                    </Item>
                ))}
            </List>
        </Box>
    )
}
