import { tinyButtons } from '@byecode/ui'
import { Tabs, UnstyledButton } from '@mantine/core'
import styled from 'styled-components'

export const KanbanContainer = styled.div`
    padding: 6px;
`
export const KanbanBoardRoot = styled.div`
    display: flex;
    /* align-items: flex-start; */
    flex-wrap: nowrap;
    gap: 12px;

    overflow: auto hidden;
    ${tinyButtons}
`

export const KanbanBoardLoadMoreBtn = styled.div`
    padding: 12px;
    flex-shrink: 0;
    align-self: flex-start;
    font-size: 14px;
    color: var(--color-gray-500);
    background: var(--color-gray-50);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
`

export const DroppableContainerHead = styled.div<{ collapsed?: boolean }>`
    padding: ${p => (p.collapsed ? '8px 0' : '8px 12px')};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
`
export const DroppableContainerHeadTitle = styled.div<{ collapsed?: boolean }>`
    flex: 1;
    height: ${p => (p.collapsed ? undefined : '28px')};
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 8px;
    user-select: none;
    touch-action: manipulation;
`
export const DroppableContainerEmptyTitle = styled.div`
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const DroppableContainerHeadCount = styled.div`
    color: var(--color-gray-500);
`
export const DroppableContainerHeadActions = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

export const DroppableContainerRoot = styled.div<{ isOverContainer?: boolean; collapsed?: boolean }>`
    flex-shrink: 0;
    width: ${p => (p.collapsed ? '38px' : '290px')};
    background: var(--color-gray-100);
    border-radius: 8px;
    overflow: hidden;
    outline: ${p => (p.isOverContainer ? '1px solid var(--color-main)' : undefined)};
    outline-offset: -1px;

    ${DroppableContainerHeadTitle} {
        flex-direction: ${p => (p.collapsed ? 'column' : undefined)};
    }
`

export const RecordList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 12px;
    margin-bottom: 8px;
`

export const LoadMoreData = styled.div`
    cursor: pointer;
    text-align: center;
    color: var(--color-gray-500);
    font-size: 14px;
    padding: 12px 0;
`

export const DroppableContainerFoot: typeof UnstyledButton = styled(UnstyledButton)`
    width: calc(100% - 24px);
    height: 40px;
    margin: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-gray-900);
    background: var(--color-white);
    border: 1px solid var(--color-gray-200);
    border-radius: 12px;
    &:hover {
        box-shadow: 0px 4px 12px 0px rgba(16, 24, 40, 0.1);
    }
`

export const RecordItemWrapper = styled.div`
    position: relative;
    user-select: none;
`
export const RecordItemContainer = styled.div<{ hasHoverStyle?: boolean }>`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: var(--color-white);
    border: 1px solid var(--color-gray-200);
    border-radius: 12px;
    touch-action: manipulation;
    &:hover {
        box-shadow: ${p => p.hasHoverStyle && '0px 4px 12px 0px rgba(16, 24, 40, 0.10)'};
    }
`

export const OperatorContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 8px;
    top: 8px;
    padding: 4px;
    opacity: 0;

    ${RecordItemWrapper}:hover & {
        opacity: 1;
    }
`

export const RecordItemField = styled.div``

export const RecordItemFieldHead = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    margin-bottom: 4px;
    font-size: 12px;
    color: var(--color-gray-400);
`
