import { createStitches } from '@stitches/react'

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
    prefix: 'byecode',
    theme: {
        colors: {
            colorPrimary: '#5551ff',
            colorPrimaryHover: '#332eec',
            // 灿白说直接改在组件里边 加一个颜色
            colorGray5: '#26415A0F',
            colorGray25: '#fcfcfd',
            colorGray50: '#f9fafb',
            colorGray100: '#f2f4f7',
            colorGray200: '#e4e7ec',
            colorGray300: '#d0d5dd',
            colorGray400: '#98a2b3',
            colorGray500: '#667085',
            colorGray600: '#475467',
            colorGray700: '#344054',
            colorGray800: '#1d2939',
            colorGray900: '#101828',
            colorError25: '#fffbfa',
            colorError50: '#fef3f2',
            colorError100: '#fee4e2',
            colorError200: '#fecdca',
            colorError300: '#fda297',
            colorError400: '#f97066',
            colorError500: '#F04438',
            colorError600: '#d92020',
            colorError700: '#b42318',
            colorError800: '#912018',
            colorError900: '#7a271a',
            colorWhite: '#ffffff',
            colorBlack: '#101828'
        },
        shadows: {
            colorGray200: '#e4e7ec',
            colorPrimary: '#5551ff66'
        }
    },
    media: {
        bp1: '(min-width: 480px)'
    },
    utils: {
        marginX: (value: number | string) => ({ marginLeft: value, marginRight: value }),
        textEllipsis: (value: true) => ({
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }),
        flexCenter: (value: true) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }),
        prettyScroll: (value: boolean) => ({
            scrollbarWidth: '6px',

            '&::-webkit-scrollbar': {
                width: '6px',
                height: '6px'
            },

            '&::-webkit-scrollbar-thumb': {
                color: '#ccc',
                borderRadius: '5px',
                background: '#ccc',
                boxShadow: 'inset 0 0 1px rgba(0, 0, 0, 0.5)'
            },

            '&::-webkit-scrollbar-track': {
                borderRadius: '5px',
                background: '#fff0'
            },

            '&::-webkit-scrollbar-button': {
                color: '#ccc',
                height: 0
            }
        }),
        hiddenScroll: (value: boolean) => ({
            '&::-webkit-scrollbar': {
                display: 'none',
                scrollbarWidth: 'none'
            }
        })
    }
})
