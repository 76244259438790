import { IconFont, Tooltip } from '@byecode/ui'
import React from 'react'

import { useTipTapContext } from '../Context'
import { CustomActionIcon } from './styles'

export const UndoMenu = () => {
    const editor = useTipTapContext()
    const disableUndo = !editor?.can().chain().focus().undo().run()

    return (
        <Tooltip title="撤销">
            <CustomActionIcon
                variant={disableUndo ? 'transparent' : 'subtle'}
                disabled={disableUndo}
                onClick={() => {
                    editor?.chain().focus().undo().run()
                }}
            >
                <IconFont type="Undo" />
            </CustomActionIcon>
        </Tooltip>
    )
}

export const RedoMenu = () => {
    const editor = useTipTapContext()
    const disableRedo = !editor?.can().chain().focus().redo().run()

    return (
        <Tooltip title="重做">
            <CustomActionIcon
                variant={disableRedo ? 'transparent' : 'subtle'}
                disabled={disableRedo}
                onClick={() => {
                    editor?.chain().focus().redo().run()
                }}
            >
                <IconFont type="Redo" />
            </CustomActionIcon>
        </Tooltip>
    )
}
