import { useTheme } from '@byecode/ui/providers'
import { createTheme } from '@byecode/ui/theme/stitches.config'
import { getElement } from '@byecode/ui/utils/getElement'
import clsx from 'clsx'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'

import type { BaseModalProps } from './base'
import { DesktopModal } from './desktop'
import { MobileModal } from './mobile'

export interface ModalProps extends Omit<BaseModalProps, 'styles' | 'transition'> {
    styles?: {
        desktop?: BaseModalProps['styles']
        mobile?: BaseModalProps['styles']
    }
    transition?: {
        desktop?: BaseModalProps['transition']
        mobile?: BaseModalProps['transition']
    }

    disableMobile?: boolean
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
    const { target, styles, transition, className, disableMobile, ...rest } = props

    const [isMobile, setIsMobile] = useState(
        disableMobile ? false : (getElement(target) ?? document.body).getBoundingClientRect().width <= 640
    )


    useEffect(() => {
        if (disableMobile) {
            return
        }
        const el = getElement(target) ?? document.body

        const handler: ResizeObserverCallback = ([e]) => {
            setIsMobile(e.target.getBoundingClientRect().width <= 640)
        }

        const observer = new ResizeObserver(handler)

        observer.observe(el)

        return () => {
            observer.unobserve(el)
        }
    }, [disableMobile, target])

    const Component = useMemo(() => (isMobile ? MobileModal : DesktopModal), [isMobile])

    return (
        <Component
            ref={ref}
            className={clsx(
                className,
                {
                    mobile: isMobile,
                    desktop: !isMobile
                },

            )}
            styles={isMobile ? styles?.['mobile'] : styles?.['desktop']}
            target={target}
            transition={isMobile ? transition?.['mobile'] : transition?.['desktop']}
            {...rest}
        />
    )
})
