import { Empty, Flex, flex, IconFont, Text } from '@byecode/ui'
import type { SubFormBlockAbstract, SubFormColumn } from '@lighthouse/core'
import React from 'react'
import styled from 'styled-components'

import { SubFormTableRecord } from './SubFormRecord'
import type { SubFormRecord } from './types'

interface SubFormContentProps {
    data: SubFormRecord[]
    columns: SubFormColumn[]
}

export const SubFormContent: React.FunctionComponent<SubFormContentProps> = ({ data: dataList, columns }) => {
    return (
        <>
            {dataList.map((data, index) => (
                <SubFormTableRecord key={data.id} data={data} columns={columns} index={index} />
            ))}
        </>
    )
}
