import { styled } from '@byecode/ui/theme/stitches.config'

export const StyledButton = styled('button', {
    outline: 'none',
    border: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-flex',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',

    '& span.i-icon': {
        lineHeight: 0
    },

    '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.4
    },

    variants: {
        buttonType: {
            text: {
                color: '$colorGray500',

                '&:not(:disabled):hover': {
                    color: '$colorGray900',
                    backgroundColor: '$colorGray5',
                    transition: 'all 0.2s ease'
                }
            },
            tag: {
                color: '$colorGray500',
                backgroundColor: '$colorGray100',

                '&:not(:disabled):hover': {
                    color: '$colorGray900',
                    backgroundColor: '$colorGray200'
                }
            },
            default: {
                color: '$colorGray900',
                border: '1px solid $colorGray200',
                backgroundColor: '#fff',
                transition: 'all 0.2s ease',

                '&:not(:disabled):hover': {
                    color: '$colorGray900',
                    backgroundColor: '$colorGray100',
                    transition: 'all 0.2s ease'
                }
            },
            primary: {
                color: '#fff',
                backgroundColor: '$colorPrimary',

                '&:not(:disabled):hover': {
                    backgroundColor: '$colorPrimaryHover'
                }
            },
            ghost: {}
        },
        // 和惨白沟通后，改为icon时宽高相同尺寸
        // 12.29 惨败说padding调整一下
        size: {
            xs: {
                height: '22px',
                padding: '0 6px',
                borderRadius: '4px',
                fontSize: '12px'
            },
            sm: {
                height: '26px',
                padding: '0 8px',
                borderRadius: '6px'
            },
            md: {
                height: '32px',
                padding: '0 10px',
                borderRadius: '8px'
            },
            lg: {
                height: '36px',
                padding: '0 12px',
                borderRadius: '8px'
            }
        },
        block: {
            true: {
                display: 'flex',
                width: '100%',
                justifyContent: 'center'
            }
        },
        icon: {
            true: {
                padding: 0,
                justifyContent: 'center'
            }
        },
        danger: {
            true: {}
        },
        ghost: {
            true: {
                backgroundColor: 'transparent',

                '&:not(:disabled):hover': {
                    opacity: 0.6,
                    backgroundColor: 'transparent'
                }
            }
        },
        loading: {
            true: {}
        },
        highlighting: {
            true: {
                border: '1px solid $colorPrimary',
                boxShadow: '0 0 4px 0 $colorPrimary'
            }
        }
    },

    compoundVariants: [
        // default + ghost
        {
            buttonType: 'default',
            ghost: true,
            css: {
                color: '#ffffff',
                border: '1px solid #ffffff',

                '&:not(:disabled):hover': {
                    color: '#ffffff',
                    border: '1px solid #ffffff'
                }
            }
        },
        // primary + ghost
        {
            buttonType: 'primary',
            ghost: true,
            css: {
                color: '$colorPrimary',
                border: '1px solid $colorPrimary',

                '&:disabled': {
                    color: '$colorGray500',
                    border: '1px solid $colorGray200'
                }
            }
        },
        // default + danger
        {
            buttonType: 'default',
            danger: true,
            css: {
                color: '#B36869',
                backgroundColor: 'transparent',
                boxSizing: 'border-box',
                border: '1px solid $colorError500',

                '&:not(:disabled):hover': {
                    color: '#B36869',
                    border: '1px solid $colorError600'
                }
            }
        },
        // primary + danger
        {
            buttonType: 'primary',
            danger: true,
            css: {
                color: '#ffffff',
                backgroundColor: '$colorError500',
                boxSizing: 'border-box',
                border: '1px solid $colorError500',

                '&:not(:disabled):hover': {
                    color: '#ffffff',
                    border: '1px solid $colorError600',
                    backgroundColor: '$colorError600'
                }
            }
        },
        // size + icon
        {
            icon: true,
            size: 'xs',
            css: {
                width: '22px'
            }
        },
        {
            icon: true,
            size: 'sm',
            css: {
                width: '26px'
            }
        },
        {
            icon: true,
            size: 'md',
            css: {
                width: '32px'
            }
        },
        {
            icon: true,
            size: 'lg',
            css: {
                width: '36px'
            }
        }
    ],

    // 暂时其实不需要，在 props 里面设置默认值了
    defaultVariants: {
        buttonType: 'default',
        size: 'md'
    }
})
