import { ScrollArea, Tabs } from '@mantine/core'
import styled, { css } from 'styled-components'

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 6px 1px;
`

export const TableScrollerContent = styled(ScrollArea).withConfig<{ bordered?: boolean; margin?: boolean }>({
    shouldForwardProp: p => p !== 'margin' && p !== 'bordered'
})`
    display: flex;
    flex-direction: column;
    flex: 1;
    ${({ bordered }) =>
        bordered &&
        css`
            border: 1px solid var(--color-gray-200);
            border-radius: 8px;
        `}

    ${({ margin }) =>
        margin
            ? css`
                  margin: 0 5px;
              `
            : css`
                  width: 100%;
              `}
`

export const TableNoDataContent = styled.div<{ width?: number }>`
    width: ${({ width }) => (width ? `${width - 2}px` : '100%')};
    padding: 40px 0;
    position: sticky;
    left: 0;
    top: 0;
`

export const TableTitle = styled.p``
