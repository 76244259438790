import { Box } from '@byecode/ui/components/Box'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import { useFloatingTree } from '@floating-ui/react'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { useMenuContext } from '../Menu.context'
import { useStyles } from './MenuItem.styles'

export interface MenuItemProps extends StyleComponentProps<Selectors<typeof useStyles>>, React.ComponentPropsWithoutRef<'div'> {
    children: React.ReactNode

    closeMenuOnClick?: boolean

    icon?: React.ReactNode

    rightSection?: React.ReactNode

    disabled?: boolean
}

export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>((props, ref) => {
    const { closeMenuOnClick, icon, rightSection, disabled, children, className, classNames, styles, unstyled, ...rest } = props

    const { classes } = useStyles({ disabled }, { name: 'menu-item', classNames, styles, unstyled })

    const ctx = useMenuContext()
    const tree = useFloatingTree()

    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (disabled) {
            return
        }
        rest.onClick?.(e)
        if (typeof closeMenuOnClick === 'boolean' && !closeMenuOnClick) {
            return
        }
        if (closeMenuOnClick || ctx?.closeOnItemClick) {
            ctx.close()
            tree?.events.emit('closeAll')
        }
    }

    return (
        <Box ref={ref} role="menuitem" className={clsx(classes.item, className)} {...rest} onClick={onClick}>
            {icon && <div className={classes.itemIcon}>{icon}</div>}
            {children && <div className={classes.itemLabel}>{children}</div>}
            {rightSection && <div className={classes.itemRightSection}>{rightSection}</div>}
        </Box>
    )
})

MenuItem.displayName = 'Bui-MenuItem'
