import { IconFont, singleTextEllipsis, Tooltip } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { PERSON_ID, PERSON_MAP_ICON, PERSON_MAP_ICON_COLOR } from '../../constants'
import { useApplicationContext } from '../../contexts'
import { getColorById, getImageFullUrlInApplication } from '../../utils'

export interface SelectItemValue {
    label: string
    color?: string
}

interface TagItemPreviewerProps {
    data: AppUser
    clearable?: boolean
    isHiddenUserId?: boolean
    onRemove?: (userId: string) => void
}

const SCxAvatar = styled.img`
    width: 22px;
    min-width: 22px;
    height: 22px;
    margin-right: 4px;
    border-radius: 50%;
`

const SCxIcon = styled(IconFont)`
    margin-right: 4px;
`

const SCxAvatarText = styled.div<{ background?: string }>`
    display: flex;
    justify-content: center;
    width: 22px;
    min-width: 22px;
    height: 22px;
    align-items: center;
    font-size: var(--font-size-sm);
    margin-right: 4px;
    border-radius: 50%;
    overflow: hidden;
    color: var(--color-white);
    background-color: ${({ background }) => background || 'var(--color-gray-2)'};
`

const SCxText = styled.div`
    height: 100%;
    line-height: 22px;
    white-space: nowrap;
    color: var(--color-black);
    ${singleTextEllipsis}
`

const SCxClearIcon = styled(IconFont)`
    margin-left: 4px;
`

const SCxCellValue = styled.div`
    max-width: 100%;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 6px;
    overflow: hidden;

    ${SCxClearIcon} {
        opacity: 0.5;
    }
    :hover {
        ${SCxClearIcon} {
            opacity: 1;
        }
    }
`

const SCxPersonIcon = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    border: 1px solid var(--color-gray-200);
    background: var(--color-gray-50);
`

export const TagItemPreviewer: React.FC<TagItemPreviewerProps> = ({ data, clearable, isHiddenUserId, onRemove }) => {
    const { userId, username, avatar, uniqueUserId } = data
    const background = getColorById(uniqueUserId)
    const { appId } = useApplicationContext()
    const avatarContent = useMemo(() => {
        if (PERSON_ID.has(userId)) {
            return (
                <SCxPersonIcon>
                    <IconFont type={PERSON_MAP_ICON[userId]} fill={PERSON_MAP_ICON_COLOR[userId]} size={16} />
                </SCxPersonIcon>
            )
        }
        if (userId === '{currentUserId}') {
            return <SCxIcon size={22} type="UserCircle" color="var(--color-gray-400)" />
        }
        if (avatar) {
            return <SCxAvatar src={getImageFullUrlInApplication(appId, avatar)} />
        }
        return <SCxAvatarText background={background}>{username.charAt(0)}</SCxAvatarText>
    }, [appId, avatar, background, userId, username])

    return (
        <Tooltip title={username} key={userId}>
            <SCxCellValue>
                {avatarContent}
                <SCxText>{username}</SCxText>
                {!isHiddenUserId && userId !== '{currentUserId}' && <SCxText>{userId}</SCxText>}
                {clearable && (
                    <SCxClearIcon
                        onClick={event => {
                            event.stopPropagation()
                            onRemove?.(userId)
                        }}
                        type="Close"
                    />
                )}
            </SCxCellValue>
        </Tooltip>
    )
}
