import { Empty, noButtons } from '@byecode/ui'
import type { AppUser, DataSourceAbstract, Field, RecordLikeProtocol } from '@lighthouse/core'
import { mergeRefs } from '@lighthouse/tools'
import { useElementSize } from '@mantine/hooks'
// import { ScrollArea } from '@mantine/core'
import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ApplicationPreviewEnum } from '../../../types'
import { FieldSelectTableContextProvider } from '../FiledSelectTableContext'
import { TableContent } from '../TableContent'
import { TableHeader } from '../TableHeader'

const RelatedRecorderXArea = styled.div<{ height?: number }>`
    overflow: scroll hidden;
    max-width: 100%;
    width: max-content;
    min-width: 100%;
    ${noButtons('0px')}
    margin-top: 12px;
`

const RelatedRecorderYArea = styled.div<{ height?: number }>`
    overflow: hidden scroll;
    /* padding-bottom: 45px; */
    max-height: 100%;
    height: ${({ height }) => (height ? `${height}px` : '100%')};
    ${noButtons('0px')}
`

const SCxTableNoDataContent = styled.div`
    width: 100%;
    padding: 50px 0;
    position: sticky;
    top: 0;
    left: 0;
`
interface FiledTableProps {
    tableWidth?: number
    tableHeight?: number
    columns?: Field[]
    primaryField: string
    recordData?: RecordLikeProtocol[]
    value: string[]
    loading: boolean
    previewType?: ApplicationPreviewEnum
    personOptions?: AppUser[]
    dataSourceList: DataSourceAbstract[]
    onRowClick?: (id: string) => void
    onScrollPositionChange?: (position: { x: number; y: number }) => void
}

export const FieldTableMobile = React.forwardRef<HTMLDivElement, FiledTableProps>(
    (
        {
            tableWidth,
            columns = [],
            primaryField,
            recordData = [],
            tableHeight,
            value = [],
            loading,
            previewType = ApplicationPreviewEnum.desktop,
            personOptions = [],
            dataSourceList,
            onRowClick,
            onScrollPositionChange
        },
        ref
    ) => {
        const scrollYRef = useRef<HTMLDivElement>(null)
        const scrollXRef = useRef<HTMLDivElement>(null)
        const { t } = useTranslation()
        const { ref: tableHeaderRef, height: tableHeaderHeight } = useElementSize()
        const noData = !loading && recordData.length === 0

        const contextValue = useMemo(
            () => ({ onRowClick, previewType, value, loading, recordData, personOptions, dataSourceList }),
            [dataSourceList, loading, onRowClick, personOptions, previewType, recordData, value]
        )

        const tableContentHeight = useMemo(
            () => (tableHeight ? tableHeight - tableHeaderHeight : tableHeight),
            [tableHeaderHeight, tableHeight]
        )
        useEffect(() => {
            const handler = () => {
                if (scrollYRef.current) {
                    onScrollPositionChange?.({ x: scrollYRef.current?.scrollLeft ?? 0, y: scrollYRef.current?.scrollTop })
                }
            }

            if (scrollYRef.current) {
                scrollYRef.current.addEventListener('scroll', handler)
            }

            return () => {
                if (scrollYRef.current) {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    scrollYRef.current.removeEventListener('scroll', handler)
                }
            }
        }, [onScrollPositionChange, scrollYRef])

        return (
            <FieldSelectTableContextProvider {...contextValue}>
                <RelatedRecorderYArea ref={scrollYRef} height={tableHeight}>
                    <TableHeader ref={tableHeaderRef} primaryField={primaryField} scrollRef={scrollXRef} columns={columns} />
                    <RelatedRecorderXArea
                        ref={mergeRefs([ref, scrollXRef])}
                        height={tableContentHeight}
                        onScroll={e => {
                            const scrollLeft = e.currentTarget?.scrollLeft
                            requestAnimationFrame(() => {
                                tableHeaderRef?.current?.scroll(scrollLeft, 0)
                            })
                        }}
                    >
                        {noData ? (
                            <SCxTableNoDataContent>
                                <Empty icon="NoData" description={t('noFindData')} />
                            </SCxTableNoDataContent>
                        ) : (
                            <TableContent
                                tableWidth={tableWidth}
                                columns={columns}
                                tableHeight={tableContentHeight}
                                scrollXRef={scrollXRef}
                                scrollYRef={scrollYRef}
                            />
                        )}
                    </RelatedRecorderXArea>
                </RelatedRecorderYArea>
            </FieldSelectTableContextProvider>
        )
    }
)
