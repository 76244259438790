import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import type { Properties } from 'csstype'
import React, { forwardRef } from 'react'

import { Box } from '../Box'

interface FlexStylesParams {
    inline?: boolean
    direction?: Properties['flexDirection']
    justifyContent?: Properties['justifyContent']
    alignItems?: Properties['alignItems']
    gap?: Properties['gap'] | number
    grow?: boolean
    wrap?: Properties['flexWrap']
}

const useStyles = createStyles((params: FlexStylesParams) => {
    return {
        root: css({
            display: params.inline ? 'inline-flex' : 'flex',
            flexWrap: params.wrap,
            flexDirection: params.direction,
            justifyContent: params.justifyContent,
            alignItems: params.alignItems,
            gap: params.gap,
            '& > *': {
                flexGrow: params.grow ? 1 : undefined
            }
        })
    }
})

export interface FlexProps
    extends StyleComponentProps<Selectors<typeof useStyles>>,
        React.ComponentPropsWithoutRef<'div'>,
        FlexStylesParams {}

export const Flex = forwardRef<HTMLDivElement, FlexProps>((props, ref) => {
    const {
        inline,
        direction,
        justifyContent,
        alignItems,
        gap,
        grow,
        wrap = 'nowrap',
        className,
        classNames,
        styles,
        unstyled,
        ...rest
    } = props

    const { classes } = useStyles(
        { inline, direction, justifyContent, alignItems, gap, grow, wrap },
        { name: 'flex', classNames, styles, unstyled }
    )

    return <Box ref={ref} className={clsx(classes.root, className)} {...rest} />
})
