// import cls from 'classnames'
// import React from 'react'
// import { useFormContext, useWatch } from 'react-hook-form'
// import styled from 'styled-components'

// export interface UploaderNavigatorProps {
//     currentStep: number
//     onStepChange?: (step: number) => void
// }

// const NavigatorContainer = styled.div`
//     display: flex;
//     align-items: center;
//     margin-bottom: 20px;
// `

// const NavigatorStepContainer = styled.div`
//     display: flex;
//     align-items: center;
//     margin-right: 30px;
//     position: relative;
//     font-size: var(--font-size-normal);
//     color: var(--color-gray-400);
//     /* cursor: pointer; */

//     &:not(:nth-last-child(1)):after {
//         content: '';
//         position: absolute;
//         width: 20px;
//         height: 1px;
//         right: -25px;
//         top: 50%;
//         background-color: var(--color-gray-400);
//     }

//     &.past {
//         color: var(--color-main);

//         &:not(:nth-last-child(1)):after {
//             background-color: var(--color-main);
//         }
//     }

//     &.active {
//         color: var(--color-black);

//         &:not(:nth-last-child(1)):after {
//             background-color: var(--color-black);
//         }
//     }
// `

// const StepNum = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-size: var(--font-size-sm);
//     margin-right: 4px;
//     border-radius: 50%;
//     width: 18px;
//     height: 18px;
//     border: 1px solid var(--color-gray-400);

//     &.past {
//         border-color: var(--color-main);
//     }

//     &.active {
//         border-color: var(--color-black);
//     }
// `

// const steps = [
//     { label: '上传文件', step: 1 },
//     {
//         label: '导入方式',
//         step: 2
//     },
//     { label: '导入设置', step: 3 }
// ]

// export const UploaderNavigator: React.FC<UploaderNavigatorProps> = ({ currentStep, onStepChange }) => {
//     const { control, setValue } = useFormContext()
//     const { importType } = useWatch({ control })

//     const ultimateSteps = importType === '2' ? steps : steps.slice(0, 2)
//     return (
//         <NavigatorContainer>
//             {ultimateSteps.map(({ step, label }) => {
//                 const isActive = currentStep === step
//                 const isPast = currentStep > step
//                 return (
//                     <NavigatorStepContainer
//                         className={cls({ active: isActive, past: isPast })}
//                         key={step}
//                         onClick={() => onStepChange?.(step)}
//                     >
//                         <StepNum className={cls({ active: isActive, past: isPast })}>{step}</StepNum> {label}
//                     </NavigatorStepContainer>
//                 )
//             })}
//         </NavigatorContainer>
//     )
// }
import { IconFont } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

export interface UploaderNavigatorProps {
    type?: string
    onClose?: () => void
}

const SCxNavigatorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`

const SCxNavigatorTitle = styled.p`
    font-size: 20px;
    font-weight: 500;
`

const SCxNavigatorCloseIcon = styled(IconFont)`
    cursor: pointer;
`

export const UploaderNavigator: React.FC<UploaderNavigatorProps> = ({ type, onClose }) => {
    return (
        <SCxNavigatorContainer>
            <SCxNavigatorTitle>本地导入</SCxNavigatorTitle>
            <SCxNavigatorCloseIcon type="Close" size={16} onClick={onClose} />
        </SCxNavigatorContainer>
    )
}
