import type { ByecodeUIConfigContextValue } from '../providers'
import { useByecodeUIConfig } from '../providers'

export const useComponentConfig = <T extends keyof ByecodeUIConfigContextValue['component']>(
    componentName: T,
    config: ByecodeUIConfigContextValue['component'][T]
) => {
    const providedComponentConfig = useByecodeUIConfig()?.component?.[componentName] ?? { disabled: undefined }

    return {
        disabled: config.disabled ?? providedComponentConfig.disabled
    }
}
