import styled from 'styled-components'

export const Container = styled.div<{ maxHeight: number }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: 14px;

    & .formula-editor {
        display: flex;
        flex: 1;
        padding: 14px 0 14px 14px;
        min-height: 168px;
        max-height: ${({ maxHeight }) => `${maxHeight}px`};
    }

    & .ProseMirror {
        flex: 1;
        align-self: stretch;
        overflow-y: auto;
        line-height: 28px;
    }

    &.ProseMirror-separator {
        position: relative;
        z-index: 1;
    }

    & .field {
        height: 24px;
        line-height: 14px;
        padding: 4px 6px;
        margin: 2px;
        display: inline-block;
        background-color: var(--color-teal-light);
        color: var(--color-teal);
        border-radius: 5px;
    }

    & .datasource {
        position: relative;
        top: 2px;
        height: 24px;
        line-height: 16px;
        padding: 4px 6px;
        margin: 0 2px;
        display: inline-block;
        color: var(--color-wakaba);
        background: var(--color-wakaba-light);
        border-radius: 5px;
    }
`
