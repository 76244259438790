import { backgroundTransitionOnClick, IconFont } from '@byecode/ui'
import styled from 'styled-components'

export const ActionWrapper = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
`


export const ItemWrapper = styled.div<{ sortable?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: ${({ sortable }) => (sortable ? '0' : '8px')};
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    ${backgroundTransitionOnClick}

    &:hover .field-visible {
        display: flex;
    }

    &:hover .field-tag {
        display: none;
    }
`

export const InfoContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    overflow: hidden;
`

export const InfoWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    overflow: hidden;
`

export const Describe = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    padding-left: 24px;
    width: fit-content;
`

export const InfoIcon = styled(IconFont)`
    font-size: var(--font-size-base);
    width: 16px;
    margin-right: 8px;
    color: var(--color-gray-500);
`

export const InfoText = styled.div`
    flex: 1;

    /* max-width: 160px; */
    color: var(--color-gray-900);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

