import { Button, Flex } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

const SCxContainer = styled.div``

const SCxFooter = styled.div`
    padding: 8px 16px;
`

interface TileCustomFilterProps {
    children?: React.ReactNode
    cancelText?: string
    okText?: string
    onConfirm?: () => void
    onCancel?: () => void
}

export const TileCustomFilter: React.FC<TileCustomFilterProps> = ({
    children,
    cancelText = '取消',
    okText = '确定',
    onCancel,
    onConfirm
}) => {
    return (
        <SCxContainer>
            {children}
            <SCxFooter>
                <Flex justifyContent="flex-end" alignItems="center" gap="12px">
                    <Button type="default" style={{ flex: 1 }} onClick={onCancel}>
                        {cancelText}
                    </Button>
                    <Button type="primary" style={{ flex: 1 }} onClick={onConfirm}>
                        {okText}
                    </Button>
                </Flex>
            </SCxFooter>
        </SCxContainer>
    )
}
