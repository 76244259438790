import styled from 'styled-components'

export const LinkGroupContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const LinkItem = styled.a`
    text-decoration: none;
    padding: 6px 12px;
    margin: 0 12px;
    border-radius: 6px;
    font-size: var(--font-size-lg);
    color: var(--color-main);
`
