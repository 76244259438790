import { IconFont, singleTextEllipsis } from '@byecode/ui'
import type React from 'react'
import styled, { css } from 'styled-components'

export const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    /* padding: var(--block-padding); */
    display: flex;
    align-items: center;
    border-radius: inherit;
    overflow: hidden;

    .swiper-3d .swiper-slide-shadow {
        background-color: #fff;
    }
`
export const ImageTitle = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 0 10px;
    color: var(--color-white);
    font-size: var(--font-size-normal);
    height: 37px;
    line-height: 37px;
    background: rgba(56, 56, 56, 0.4);
    backdrop-filter: blur(1px);
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    ${singleTextEllipsis}
`

export const ImageContent = styled.div<Pick<React.CSSProperties, 'borderRadius'>>`
    position: relative;
    user-select: auto;
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-width: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
    ${({ borderRadius }) => css`
        border-radius: ${borderRadius};
    `}

    @media (any-hover: hover) {
        &:hover {
            .swiperNavigationRight,
            .swiperNavigationLeft {
                opacity: 1;
            }
        }
    }
`

export const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
`

export const Icon = styled(IconFont)`
    font-size: var(--font-size-base);
`

export const MImage = styled.img<Pick<React.CSSProperties, 'aspectRatio' | 'objectFit' | 'borderRadius'>>`
    display: block;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    /* cursor: pointer; */
    width: 100%;
    height: 100%;
    overflow-clip-margin: content-box;
    overflow: clip;
    -webkit-user-drag: none;

    ${({ aspectRatio, objectFit, borderRadius }) => css`
        aspect-ratio: ${aspectRatio};
        object-fit: ${objectFit};
    `}
`

export const FileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const FileName = styled.div`
    font-size: var(--font-size-base);
    text-align: center;
    color: var(--color-black);
`

export const Pagination = styled.div`
    background: rgba(0, 0, 0, 0.3);
    border-radius: 38px;
    bottom: 12px;
    position: absolute;
    z-index: 1;
    left: 50%!important;
    width: auto!important;
    transform: translateX(-50%);
    padding: 4px;
    display: flex;
    gap: 8px;
    align-items: center;

    .swiper-pagination-bullet-active {
        background: var(--color-white) !important;
    }

    .swiper-pagination-bullet {
        margin: 0 !important;
        position: relative;
        width: 8px;
        height: 8px;
        background: rgba(255, 255, 255, 0.5);
    }
`
export const Navigation = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 100%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    padding: 0 23px;

`
export const NavigationButton = styled.div`
    background: rgba(102, 102, 102, 0.6);
    font-size: 32px;
    border: none;
    border-radius: 100%;
    box-shadow: none;
    width: 32px;
    height: 32px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
`
