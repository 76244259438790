import { getAssetUrl } from '@lighthouse/assets'

export const DEFAULT_LANG = 'zh-CN'

export const CHINA_LANG_LIST = new Set(['zh-CN', 'zh-TW', 'zh', 'zh-HK'])

export const LANGUAGE_LIST = [
    {
        lang: 'zh-CN',
        name: '简体中文',
        flag: getAssetUrl('flag', 'cn.png'),
        langDemo: '中文简体'
    },
    {
        lang: 'zh-TW',
        name: '繁体中文',
        flag: getAssetUrl('flag', 'cn.png'),
        langDemo: '中文 (繁體)'
    },
    {
        lang: 'en',
        name: '英语',
        flag: getAssetUrl('flag', 'gb.png'),
        langDemo: 'English'
    }
    // {
    //     lang: 'de',
    //     name: '德语',
    //     flag: getAssetUrl('flag', 'de.png'),
    //     langDemo: 'Deutschland'
    // },
    // {
    //     lang: 'ja',
    //     name: '日语',
    //     flag: getAssetUrl('flag', 'jp.png'),
    //     langDemo: '日本語です'
    // },
    // {
    //     lang: 'ko',
    //     name: '韩语',
    //     flag: getAssetUrl('flag', 'kr.png'),
    //     langDemo: '한글'
    // },
    // {
    //     lang: 'it',
    //     name: '意大利语',
    //     flag: getAssetUrl('flag', 'it.png')
    // },
    // {
    //     lang: 'es',
    //     name: '西班牙语',
    //     flag: getAssetUrl('flag', 'es.png'),
    //     langDemo: 'Español'
    // },
    // {
    //     lang: 'ru',
    //     name: '俄语',
    //     flag: getAssetUrl('flag', 'ru.png'),
    //     langDemo: 'русский язык'
    // },
    // {
    //     lang: 'fr',
    //     name: '法语',
    //     flag: getAssetUrl('flag', 'fr.png'),
    //     langDemo: 'Français'
    // }
]

export const countryToLanguageMap: Record<string, string> = {
    AF: 'ps', // 普什图语
    AL: 'sq', // 阿尔巴尼亚语
    DZ: 'ar', // 阿拉伯语
    AD: 'ca', // 加泰罗尼亚语
    AO: 'pt', // 葡萄牙语
    AI: 'en', // 英语
    AG: 'en', // 英语
    AR: 'es', // 西班牙语
    AM: 'hy', // 亚美尼亚语
    AU: 'en', // 英语
    AT: 'de', // 德语
    AZ: 'az', // 阿塞拜疆语
    BS: 'en', // 英语
    BH: 'ar', // 阿拉伯语
    BD: 'bn', // 孟加拉语
    BB: 'en', // 英语
    BY: 'be', // 白俄罗斯语
    BE: 'nl', // 荷兰语
    BZ: 'en', // 英语
    BJ: 'fr', // 法语
    BT: 'dz', // 不丹语
    BO: 'es', // 西班牙语
    BA: 'bs', // 波斯尼亚语
    BW: 'en', // 英语
    BR: 'pt', // 葡萄牙语
    BN: 'ms', // 马来语
    BG: 'bg', // 保加利亚语
    BF: 'fr', // 法语
    BI: 'fr', // 法语
    KH: 'km', // 高棉语
    CM: 'fr', // 法语
    CA: 'en', // 英语
    CV: 'pt', // 葡萄牙语
    CF: 'fr', // 法语
    TD: 'fr', // 法语
    CL: 'es', // 西班牙语
    CN: 'zh-CN', // 汉语（简体）
    CO: 'es', // 西班牙语
    KM: 'fr', // 法语
    CG: 'fr', // 法语
    CD: 'fr', // 法语
    CR: 'es', // 西班牙语
    HR: 'hr', // 克罗地亚语
    CU: 'es', // 西班牙语
    CY: 'el', // 希腊语
    CZ: 'cs', // 捷克语
    DK: 'da', // 丹麦语
    DJ: 'fr', // 法语
    DM: 'en', // 英语
    DO: 'es', // 西班牙语
    EC: 'es', // 西班牙语
    EG: 'ar', // 阿拉伯语
    SV: 'es', // 西班牙语
    GQ: 'es', // 西班牙语
    ER: 'ti', // 提格利尼亚语
    EE: 'et', // 爱沙尼亚语
    ET: 'am', // 阿姆哈拉语
    FJ: 'en', // 英语
    FI: 'fi', // 芬兰语
    FR: 'fr', // 法语
    GA: 'fr', // 法语
    GM: 'en', // 英语
    GE: 'ka', // 格鲁吉亚语
    DE: 'de', // 德语
    GH: 'en', // 英语
    GR: 'el', // 希腊语
    GD: 'en', // 英语
    GT: 'es', // 西班牙语
    GN: 'fr', // 法语
    GW: 'pt', // 葡萄牙语
    GY: 'en', // 英语
    HT: 'fr', // 法语
    HN: 'es', // 西班牙语
    HK: 'zh-CN', // 汉语（香港）
    HU: 'hu', // 匈牙利语
    IS: 'is', // 冰岛语
    IN: 'hi', // 印地语
    ID: 'id', // 印尼语
    IR: 'fa', // 波斯语
    IQ: 'ar', // 阿拉伯语
    IE: 'ga', // 爱尔兰语
    IL: 'he', // 希伯来语
    IT: 'it', // 意大利语
    JM: 'en', // 英语
    JP: 'ja', // 日语
    JO: 'ar', // 阿拉伯语
    KZ: 'kk', // 哈萨克语
    KE: 'sw', // 斯瓦希里语
    KI: 'en', // 英语
    KW: 'ar', // 阿拉伯语
    KG: 'ky', // 吉尔吉斯语
    LA: 'lo', // 老挝语
    LV: 'lv', // 拉脱维亚语
    LB: 'ar', // 阿拉伯语
    LS: 'en', // 英语
    LR: 'en', // 英语
    LY: 'ar', // 阿拉伯语
    LI: 'de', // 德语
    LT: 'lt', // 立陶宛语
    LU: 'fr', // 法语
    MG: 'mg', // 马达加斯加语
    MO: 'zh-CN', // 中文
    MW: 'ny', // 尼扬扎语
    MY: 'ms', // 马来语
    MV: 'dv', // 迪维希语
    ML: 'bm', // 班巴拉语
    MT: 'mt', // 马耳他语
    MH: 'mh', // 马绍尔语
    MR: 'ar', // 阿拉伯语
    MU: 'mfe', // 毛里求斯克里奥尔语
    MX: 'es', // 西班牙语
    FM: 'en', // 英语
    MC: 'fr', // 法语
    MN: 'mn', // 蒙古语
    ME: 'sr', // 塞尔维亚语
    MA: 'ar', // 阿拉伯语
    MZ: 'pt', // 葡萄牙语
    MM: 'my', // 缅甸语
    NA: 'en', // 英语
    NR: 'na', // 纳乌鲁语
    NP: 'ne', // 尼泊尔语
    NL: 'nl', // 荷兰语
    NZ: 'en', // 英语
    NI: 'es', // 西班牙语
    NE: 'fr', // 法语
    NG: 'en', // 英语
    NO: 'no', // 挪威语
    OM: 'ar', // 阿拉伯语
    PK: 'ur', // 乌尔都语
    PW: 'en', // 英语
    PA: 'es', // 西班牙语
    PG: 'en', // 英语
    PY: 'gn', // 瓜拉尼语
    PE: 'es', // 西班牙语
    PH: 'tl', // 塔加路语
    PL: 'pl', // 波兰语
    PT: 'pt', // 葡萄牙语
    QA: 'ar', // 阿拉伯语
    KR: 'ko', // 朝鲜语
    MD: 'ro', // 罗马尼亚语
    RO: 'ro', // 罗马尼亚语
    RU: 'ru', // 俄语
    RW: 'rw', // 卢旺达语
    KN: 'en', // 英语
    LC: 'en', // 英语
    VC: 'en', // 英语
    WS: 'sm', // 萨摩亚语
    SM: 'it', // 意大利语
    ST: 'pt', // 葡萄牙语
    SA: 'ar', // 阿拉伯语
    SN: 'fr', // 法语
    RS: 'sr', // 塞尔维亚语
    SC: 'fr', // 法语
    SL: 'en', // 英语
    SG: 'zh', // 华语
    SK: 'sk', // 斯洛伐克语
    SI: 'sl', // 斯洛文尼亚语
    SB: 'en', // 英语
    SO: 'so', // 索马里语
    ZA: 'zu', // 南非语
    SS: 'en', // 英语
    ES: 'es', // 西班牙语
    LK: 'si', // 僧伽罗语
    PS: 'ar', // 阿拉伯语
    SD: 'ar', // 阿拉伯语
    SR: 'nl', // 荷兰语
    SE: 'sv', // 瑞典语
    CH: 'de', // 德语
    SY: 'ar', // 阿拉伯语
    TJ: 'tg', // 塔吉克语
    TW: 'zh-TW', // 中文（繁体）
    TZ: 'sw', // 斯瓦希里语
    TH: 'th', // 泰语
    TL: 'pt', // 葡萄牙语
    TG: 'fr', // 法语
    TO: 'to', // 汤加语
    TT: 'en', // 英语
    TN: 'ar', // 阿拉伯语
    TR: 'tr', // 土耳其语
    TM: 'tk', // 土库曼语
    TV: 'en', // 英语
    UG: 'sw', // 斯瓦希里语
    UA: 'uk', // 乌克兰语
    AE: 'ar', // 阿拉伯语
    // --- start
    // @灿白： 不区分英语语种
    GB: 'en', // 英语（英国）
    US: 'en', // 英语（美国）
    // --- end
    UY: 'es', // 西班牙语
    UZ: 'uz', // 乌兹别克语
    VU: 'bi', // 比斯拉马语
    VE: 'es', // 西班牙语
    VN: 'vi', // 越南语
    YE: 'ar', // 阿拉伯语
    ZM: 'en', // 英语
    ZW: 'en' // 英语
}
