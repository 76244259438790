import { useUncontrolled } from '@lighthouse/tools'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { useApplicationContext } from '../../../../contexts'
import { getDepartmentToOptions, getTreeOptions } from '../../../../utils'
import { TreeSelectDropDown } from '../../../TagTreeSelect/TreeSelectDropDown'

interface CustomDepartmentProps {
    value?: string[]
    multiple?: boolean
    onChange: (value: string[]) => void
    onClose: () => void
}

export const CustomDepartments: React.FunctionComponent<CustomDepartmentProps> = ({ value: data, multiple, onChange, onClose }) => {
    const [search, setSearch] = useState('')
    const [value, handleChangeValue] = useUncontrolled<string[]>({ defaultValue: data })

    const { departmentOptions } = useApplicationContext()

    const flatOptions = useMemo(() => getDepartmentToOptions(departmentOptions), [departmentOptions])

    const optionsTree = useMemo(
        () => (search ? flatOptions?.filter(v => v.label.toLowerCase().includes(search.toLowerCase())) : flatOptions),
        [flatOptions, search]
    )

    const handleSearchChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(ev.target.value)
    }, [])

    const handleChange = useCallback(() => {
        onChange?.(value)
        onClose()
        setSearch('')
    }, [onChange, onClose, value])

    useUpdateEffect(() => {
        handleChangeValue(data ?? [])
    }, [data])

    return (
        <TreeSelectDropDown
            options={optionsTree}
            value={value}
            search={search}
            multiple={multiple}
            onChange={handleChangeValue}
            onFinish={handleChange}
            onSearchChange={handleSearchChange}
        />
    )
}
