import { createStyles } from '../../../theme/createStyles'
import { css } from '../../../theme/stitches.config'

export const useStyles = createStyles(() => {
    const cell = css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        width: 24,
        height: 24,
        borderRadius: 12,
        '&[data-out-range]': {
            color: '$colorGray300'
        },
        '&[data-disabled = true]': {
            color: '$colorGray300',
            cursor: 'not-allowed'
        },
        '&[data-selected]': {
            color: '$colorWhite',
            backgroundColor: '$colorPrimary'
        }
    })

    return {
        dayBody: css({
            padding: 16,
            table: {
                width: '100%',
                tableLayout: 'fixed',
                borderCollapse: 'separate',
                borderSpacing: '4px',
                margin: '-4px'
            },
            'th, td': {
                position: 'relative',
                padding: 4,
                fontSize: 14,
                lineHeight: '16px',
                userSelect: 'none'
            },
            'tr > td': {
                textAlign: 'center',
                cursor: 'pointer',
                [`&:hover .${cell.className}:not([data-selected]):not([data-disabled = true])`]: {
                    color: '$colorBlack',
                    backgroundColor: '$colorGray200'
                }
            }
        }),

        dayCell: cell,

        dayIndicator: css({
            width: 4,
            height: 4,
            position: 'absolute',
            bottom: -4,
            left: '50%',
            transform: 'translateX(-50%)',
            borderRadius: '50%',
            backgroundColor: '$colorPrimary'
        })
    }
})
