import type { RecordLikeProtocol, VariableADTvalue } from '@lighthouse/core'
import { DIRECTION, FLEX_ALIGN, MAX_CONTAINER_WIDTH_SIZE } from '@lighthouse/core'
import React from 'react'

import { FLOW_LAYOUT_NODE_HEIGHT, FLOW_LAYOUT_NODE_ROWS } from '../../utils'
import type { ContainerHighlight, LayoutProperty, NodeRenderProps } from './types'

// 栅格基础属性
export interface FlowLayoutContextValue {
    rootElement?: HTMLDivElement | null
    disabled?: boolean
    isMobile: boolean
    rows: number
    unitHeight: number
    parseBackgroundVariableImage?: (value: VariableADTvalue, record?: RecordLikeProtocol) => string
    nodeRender?: (props: NodeRenderProps) => JSX.Element | null
    labelRender?: (id: string) => React.ReactNode
    highlight?: Record<string, ContainerHighlight | undefined>
    scale?: number
}
export const DEFAULT_LAYOUT_CONTEXT_VALUE: FlowLayoutContextValue = {
    rows: FLOW_LAYOUT_NODE_ROWS,
    unitHeight: FLOW_LAYOUT_NODE_HEIGHT,
    isMobile: false,
    disabled: false
}
const FlowLayoutContext = React.createContext(DEFAULT_LAYOUT_CONTEXT_VALUE)
export const FlowLayoutProvider = FlowLayoutContext.Provider
export const useFlowLayoutContext = () => React.useContext(FlowLayoutContext)

// 容器属性
export interface FlowContainerContextValue extends Omit<LayoutProperty, 'gap'> {
    fullWidth: number
    contentWidth: number
    nodeUnitWidth: number
    gap: number
}
export const DEFAULT_CONTAINER_CONTEXT: FlowContainerContextValue = {
    fullWidth: 0,
    contentWidth: 0,
    nodeUnitWidth: 0,
    size: MAX_CONTAINER_WIDTH_SIZE.lg,
    direction: DIRECTION.vertical,
    gap: 0,
    alignX: FLEX_ALIGN['flex-start'],
    alignY: FLEX_ALIGN['flex-start']
}
const FlowContainerContext = React.createContext(DEFAULT_CONTAINER_CONTEXT)
export const FlowContainerProvider = FlowContainerContext.Provider
export const useFlowContainerContext = () => React.useContext(FlowContainerContext)
