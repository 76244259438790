import type { DataSourceAbstract, DataSourceBase, RecordLikeProtocol } from '@lighthouse/core'
import React, { createContext, useContext } from 'react'

import { ApplicationPreviewEnum } from '../../types'

interface FieldSelectTableContextInterface {
    previewType: ApplicationPreviewEnum
    recordData: RecordLikeProtocol[]
    value: string[]
    loading: boolean
    datasourceList?: DataSourceAbstract[]
    children?: React.ReactNode

    onRowClick?: (id: string) => void
}

const FieldSelectTableContext = createContext<FieldSelectTableContextInterface>({
    recordData: [],
    previewType: ApplicationPreviewEnum.desktop,
    loading: false,
    value: []
})

export const FieldSelectTableContextProvider = ({ children, ...rest }: FieldSelectTableContextInterface) => {
    return <FieldSelectTableContext.Provider value={rest}>{children}</FieldSelectTableContext.Provider>
}

export const useFieldSelectTableContext = () => useContext(FieldSelectTableContext)
