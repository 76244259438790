import { IconFont, Tooltip } from '@byecode/ui'
import { type AppUser, type FieldProtocol, type HighLightConditions, type PersonValue,AppUserStatus } from '@lighthouse/core'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { CURRENT_USER, PERSON_ID, PERSON_MAP_ICON, PERSON_MAP_ICON_COLOR, PERSON_MAP_NAME } from '../../constants'
import { useApplicationContext } from '../../contexts'
import { getColorById, getImageFullUrlInApplication } from '../../utils'
import { CellHighlighter } from '../Highlighter'
import type { TooltipListItem } from '../TooltipList'
import { TooltipList, useWidth } from '../TooltipList'

const PersonListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean } & Pick<React.CSSProperties, 'justifyContent'>>`
    position: relative;
    max-width: 100%;
    display: flex;
    gap: 6px;
    justify-content: ${({ justifyContent }) => justifyContent};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    overflow: hidden;
`

const PersonItemWrapper = styled.div<{ enableOverFlow?: boolean; direction?: 'horizontal' | 'vertical' }>`
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    ${({ direction }) =>
        direction === 'vertical'
            ? css`
                  width: 22px;
                  margin-bottom: 6px;
              `
            : css`
                  height: 22px;
                  line-height: 22px;
              `}

    /* overflow: ${({ enableOverFlow }) => (enableOverFlow ? 'hidden' : 'unset')}; */
    /* overflow: hidden; */
    /* margin-bottom: 6px; */

    &:nth-last-of-type(1) {
        margin-right: 0;
    }
`

const PersonAvatar = styled.div<{ direction?: 'horizontal' | 'vertical'; url?: string }>`
    width: 22px;
    height: 22px;
    min-width: 22px;

    background: ${({ url }) => (url ? `url('${url}')` : '')};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    ${({ direction }) =>
        direction === 'vertical'
            ? css`
                  margin-bottom: 4px;
              `
            : css`
                  margin-right: 4px;
              `}
    border-radius: 50%;
`

const PersonTextAvatar = styled.div<{ background?: string; direction?: 'horizontal' | 'vertical' }>`
    display: flex;
    justify-content: center;
    width: 22px;
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: var(--font-size-sm);
    ${({ direction }) =>
        direction === 'vertical'
            ? css`
                  margin-bottom: 4px;
              `
            : css`
                  margin-right: 4px;
              `}
    border-radius: 50%;
    color: var(--color-white);
    background-color: ${({ background }) => background || 'var(--color-gray-2)'};
`

const PersonName = styled.div`
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
`

const PersonInfo = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
`

const SCxPersonIcon = styled.div<{ direction?: 'horizontal' | 'vertical' }>`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-gray-200);
    background: var(--color-gray-50);

    ${({ direction }) =>
        direction === 'vertical'
            ? css`
                  margin-bottom: 4px;
              `
            : css`
                  margin-right: 4px;
              `}
`

const PersonDescribe = styled.span`
    margin-left: 4px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
    overflow: hidden;
    text-overflow: ellipsis;
`

const PersonExtra = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const PersonHidden = styled.div`
    font-size: var(--font-size-normal);
    padding: 0 4px;
    height: 24px;
    line-height: 22px;
    background-color: var(--color-gray-200);
    color: var(--color-black);
    border-radius: 4px;
`

const PerSonToolTipItem = styled.div`
    height: 16px;
    font-size: var(--font-size-sm);
    color: var(--color-white);
`

const ToolTipContainer = styled.div<{ direction?: 'horizontal' | 'vertical' }>`
    ${({ direction }) =>
        direction === 'vertical'
            ? css`
                  height: 220px;
                  padding: 8px 4px;
              `
            : css`
                  width: 220px;
                  padding: 4px 8px;
              `}
    white-space: pre-wrap;
`

const ToolTipHeader = styled.div`
    font-weight: 600;
    color: var(--color-white);
    font-size: var(--font-size-sm);
`

const ToolTipContent = styled.div`
    color: var(--color-white);
    font-size: var(--font-size-sm);
`

interface notExistPersonPreviewProps {
    id: string
    isLast?: boolean
    direction?: 'horizontal' | 'vertical'
    onWidth?: (width: number) => void
}

interface PersonItemPreviewerProps {
    children?: React.ReactNode
    id: string
    user: AppUser
    isLast?: boolean
    direction?: 'horizontal' | 'vertical'
    visibleDescribe?: boolean
}

interface PersonPreviewerProps extends Pick<React.CSSProperties, 'justifyContent'> {
    field: FieldProtocol
    children?: React.ReactNode
    size?: 'default' | 'lg'
    data?: PersonValue
    width?: number
    isWrap?: boolean
    styleConditions?: HighLightConditions
    currentUserId?: string
    visibleDescribe?: boolean
    direction?: 'horizontal' | 'vertical'
    isHasCurrentUser?: boolean
}

export const NotExistPersonPreview: React.FC<notExistPersonPreviewProps> = ({ id, isLast, direction, onWidth }) => {
    // const [ref, rect] = useResizeObserver()
    // useUpdateEffect(() => {
    //     onWidth?.(rect.width)
    // }, [rect.width])
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)

    const tooltipContent = useMemo(() => {
        return (
            <ToolTipContainer direction={direction}>
                <ToolTipHeader>为什么会存在无权限用户？</ToolTipHeader>
                <ToolTipContent>使用跨应用同步数据表，且用户字段中 选择了非当前应用中的用户，就会提示 无权限</ToolTipContent>
                <ToolTipContent>如果您想要让用户拥有跨应用的权限， 可尝试功能「使用其他应用的用户」</ToolTipContent>
            </ToolTipContainer>
        )
    }, [direction])

    return (
        <PersonItemWrapper enableOverFlow={isLast} ref={widthRef} direction={direction}>
            <Tooltip title={tooltipContent} withArrow>
                <PersonInfo>
                    <SCxPersonIcon direction={direction}>?</SCxPersonIcon>
                    <PersonName>无权限用户</PersonName>
                </PersonInfo>
            </Tooltip>
        </PersonItemWrapper>
    )
}

export const PersonItemPreviewer: React.FC<PersonItemPreviewerProps> = ({ id, user, visibleDescribe, isLast, direction }) => {
    const { userId, username, avatar } = user
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)
    const { appId } = useApplicationContext()
    // const itemWrapperRef = useRef<HTMLDivElement>(null)
    // const [ref, rect] = useResizeObserver()

    // useUpdateEffect(() => {
    //     onWidth?.(rect.width)
    // }, [rect.width])

    // const avatarStyles = size === 'default' ? { width: 20, height: 20 } : { width: 32, height: 32 }
    const background = getColorById(userId)

    const avatarContent = useMemo(() => {
        if (PERSON_ID.has(userId)) {
            return (
                <SCxPersonIcon direction={direction}>
                    <IconFont type={PERSON_MAP_ICON[userId]} fill={PERSON_MAP_ICON_COLOR[userId]} size={16} />
                </SCxPersonIcon>
            )
        }
        if (userId === CURRENT_USER.userId) {
            return <IconFont size={22} type="UserCircle" />
        }
        if (avatar) {
            return <PersonAvatar direction={direction} url={getImageFullUrlInApplication(appId, avatar)} />
        }
        return (
            <PersonTextAvatar direction={direction} background={background}>
                {username.charAt(0)}
            </PersonTextAvatar>
        )
    }, [appId, avatar, background, direction, userId, username])

    return (
        <PersonItemWrapper key={userId} enableOverFlow={isLast} ref={widthRef} direction={direction}>
            {avatarContent}
            {/* <TooltipText
                label={`${username}  ${userId}`}
                disabled={isWrap || disableTooltip}
                render={ref => {
                    return (
                        <PersonInfo ref={ref}>
                            <PersonName>{username}</PersonName>
                            {visibleDescribe && userId !== ANONYMOUS && <PersonDescribe>{userId}</PersonDescribe>}
                        </PersonInfo>
                    )
                }}
            /> */}
            <PersonInfo>
                <PersonName>{username}</PersonName>
                {visibleDescribe && !PERSON_ID.has(userId) && <PersonDescribe>{userId}</PersonDescribe>}
            </PersonInfo>
        </PersonItemWrapper>
    )
}

export const PersonValuePreviewer: React.FC<PersonPreviewerProps> = ({
    field,
    data = [],
    width = 160,
    isWrap = true,
    justifyContent = 'flex-start',
    styleConditions,
    currentUserId,
    visibleDescribe,
    direction,
    isHasCurrentUser
}) => {
    const { personOptions: appPersonOption, appId } = useApplicationContext()
    const personOptions = useMemo(() => isHasCurrentUser ? [CURRENT_USER, ...appPersonOption]: appPersonOption, [appPersonOption, isHasCurrentUser])
    const { id } = field
    const text = data.join(',')
    const list = useMemo(() => {
        return data.reduce<TooltipListItem[]>((list, id) => {
            if (PERSON_ID.has(id)) {
                list.push({ label: PERSON_MAP_NAME[id], value: id })
                return list
            }
            const user = find(person => person.userId === id, personOptions)
            if (user) {
                const username = user.status?.[0] === AppUserStatus.DEPART ? `${user.username}(已注销)` : user.username
                list.push({
                    label: user.userId === CURRENT_USER.userId ? username : `${username} ${user.userId}`,
                    value: user.userId
                })
                return list
            }
            list.push({
                label: '无权限用户',
                value: id
            })
            return list
        }, [])
    }, [data, personOptions])

    return (
        <>
            <CellHighlighter currentUserId={currentUserId} fieldId={id} type="person" value={text} conditions={styleConditions} />
            <TooltipList
                data={list.map((item, index) => ({ label: item.label, value: String(index) }))}
                width={width}
                key={data.join('-')}
                disabled={isWrap}
                render={visibleNum => {
                    const renderList = data.slice(0, visibleNum)
                    return (
                        <PersonListWrapper isWrap={isWrap} justifyContent={justifyContent} isPadding={visibleNum < data.length}>
                            {renderList.map((id, index) => {
                                if (PERSON_ID.has(id)) {
                                    return (
                                        <PersonItemPreviewer
                                            id={String(index)}
                                            isLast={index === renderList.length - 1}
                                            key={id}
                                            user={{ userId: id, uniqueUserId: id, username: PERSON_MAP_NAME[id], mobile: '' }}
                                        />
                                    )
                                }
                                const user = find(person => person.userId === id, personOptions)
                                if (!user) {
                                    return <NotExistPersonPreview direction={direction} isLast={index === renderList.length - 1} key={id} id={String(index)} />
                                }
                                const userData = { ...user, username: user.status?.[0] === AppUserStatus.DEPART ? `${user.username}(已注销)` : user.username }
                                return (
                                    <PersonItemPreviewer
                                        id={String(index)}
                                        direction={direction}
                                        visibleDescribe={visibleDescribe}
                                        isLast={index === renderList.length - 1}
                                        key={id}
                                        user={userData}
                                    />
                                )
                            })}
                        </PersonListWrapper>
                    )
                }}
            />
        </>
    )
}
