import { Empty, IconFont, Input, pointer, Popover, tinyButtons, Tooltip } from '@byecode/ui'
import { type FieldType, type VariableADTvalue, VariableType } from '@lighthouse/core'
import { Text } from '@mantine/core'
import { difference } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { datasourceFieldTypes, FieldNameMap } from '../../../../constants'
import { getVariableValue, isVariableChildrenItemActive, isVariableItemActive } from '../../help'
import type { VariableChildren, VariableTree } from '../../types'
import { ListItem } from '../ListItem'

interface VariableItemProps {
    data: VariableTree
    value?: VariableADTvalue
    inputType?: FieldType
    opened: boolean
    onOpenChange: (v: boolean) => void
    onChange: (value: VariableADTvalue) => void
    onClose?: () => void
}

const SCxSearchWrapper = styled.div`
    padding: 4px 12px 0;
    margin-bottom: 8px;
`

const SCxListContainer = styled.div`
    width: 100%;
    height: auto;
    max-height: 600px;
    overflow: hidden auto;
    ${tinyButtons}
`

const SCxTooltipLabel = styled.div`
    word-break: break-word;
    white-space: pre-wrap;
    height: auto;
    width: 220px;
    line-height: 16px;
    font-size: var(--font-size-sm);

    li {
        padding-left: 6px;
    }
`

const SCxExtra = styled.div`
    padding: 0 16px;
    height: 36px;
    display: flex;
    align-items: center;
    color: var(--color-gray-500);
    font-size: var(--font-size-sm);
`
export const SCxIcon = styled(IconFont)<{ size?: number; isAction?: boolean; color?: string }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: transparent;
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};
    ${pointer}

    &:hover {
        color: ${({ isAction }) => isAction && 'var(--color-black)'};
    }
`
export const VariableItem: React.FunctionComponent<VariableItemProps> = ({ value, data, inputType, opened, onOpenChange: setOpened, onChange, onClose }) => {
    const active = useMemo(() => isVariableItemActive(data, value), [data, value])
    const [searchLabel, setSearchLabel] = useState('')
    // const [opened, setOpened] = useState(false)
    const handleChange = useCallback(
        (parentNode: VariableTree, childNode?: VariableChildren) => {
            const isActive = isVariableItemActive(parentNode, value) && isVariableChildrenItemActive(childNode, value)
            onChange?.(isActive ? { type: VariableType.VALUE } : getVariableValue(parentNode, childNode))
            onClose?.()
        },
        [onChange, onClose, value]
    )

    const disabledFieldType = useMemo(() => {
        return difference(datasourceFieldTypes, data?.disabledList ?? [])[0]
    }, [data?.disabledList])

    const listData = useMemo(() => {
        const searchVal = searchLabel.trim()
        return data.children.filter(item => !searchVal || item.title.includes(searchVal))
    }, [data.children, searchLabel])

    useEffect(() => {
        if (opened) {
            setSearchLabel('')
        }
    }, [opened])

    const prompts = useMemo(() => {
        if (!data?.disabledList || data.disabledList?.length === 0) {
            return null
        }
        return (
            <SCxExtra>
                <Text size={12} color="var(--color-gray-500)">
                    {datasourceFieldTypes.length - 1 === data.disabledList?.length
                        ? `仅支持${FieldNameMap[disabledFieldType as FieldType]}字段`
                        : '已隐藏不支持的字段'}
                </Text>
                {/* <Tooltip
                    title={
                        <SCxTooltipLabel>
                            不支持以下字段:
                            {data?.disabledList?.map(title => (
                                <li key={title}>{FieldNameMap[title as FieldType]}</li>
                            ))}
                        </SCxTooltipLabel>
                    }
                >
                    <SCxIcon size={16} isAction type="Question" color="var(--color-gray-500)" style={{ marginLeft: 4 }} />
                </Tooltip> */}
            </SCxExtra>
        )
    }, [data.disabledList, disabledFieldType])

    return (
        <Popover
            trigger="hover"
            position="left-start"
            opened={opened}
            onChange={setOpened}
            disabled={data.type === VariableType.PAGE_LINK}
            // closeClickOutside
        >
            <Popover.Target>
                <div style={{ background: opened ? 'var(--color-gray-100)' : undefined }}>
                    <ListItem
                        data={data}
                        active={active}
                        showArrowIcon={data.type !== VariableType.PAGE_LINK}
                        onChange={v => {
                            if (data.type === VariableType.PAGE_LINK) {
                                return handleChange(data)
                            }
                        }}
                    />
                </div>
            </Popover.Target>
            <Popover.Dropdown>
                {data.enableSearch && (
                    <SCxSearchWrapper>
                        <Input
                            value={searchLabel}
                            onChange={e => setSearchLabel(e.target.value)}
                            prefix={<IconFont type="Search" />}
                            placeholder="搜索"
                        />
                    </SCxSearchWrapper>
                )}
                {(!listData || listData.length === 0) &&
                    (data.empty ?? (
                        <Empty
                            styles={{
                                content: {
                                    width: 250,
                                    height: 80,
                                    lineHeight: '80px',
                                    padding: '0 16px',
                                    textAlign: 'center'
                                }
                            }}
                            description="没有数据"
                        />
                    ))}
                <SCxListContainer>
                    {listData?.map(item => {
                        const isActive = isVariableChildrenItemActive(item, value) && active
                        return (
                            <ListItem
                                key={item.id}
                                data={item}
                                active={isActive}
                                showArrowIcon={false}
                                styles={{
                                    rightFill: {
                                        gap: 8
                                    }
                                }}
                                onChange={() => handleChange(data, item)}
                            />
                        )
                    })}
                    {prompts}
                </SCxListContainer>
            </Popover.Dropdown>
        </Popover>
    )
}
