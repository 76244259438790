import type { MobileDatePickerMode } from '@byecode/ui'
import { MobileDatePicker } from '@byecode/ui'
import type { DateConfig, DateField, DateValue } from '@lighthouse/core'
import { useSetState } from '@mantine/hooks'
import { add, format, getDate, parse, parseISO } from 'date-fns'
import zhCN from 'date-fns/locale/zh-CN/index'
import { max } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { DATE_FORMAT_OPTIONS } from '../../../../../constants'
import { useApplicationContext } from '../../../../../contexts'
import { DateDrawer } from '../../../../FieldDrawer'
import { SelectPlaceHolder } from '../../../SelectPlaceHolder'
import type { FieldBaseProps } from '../../../types'
import { getRelativeDate, getTimestamp } from '../help'

interface DateDrawerFieldProps extends FieldBaseProps {
    value: DateValue
    dateConfig: DateConfig
    dateField?: DateField
}

interface FileValueEditorState {
    data: DateValue
    opened: boolean
    mode: MobileDatePickerMode
}

export const SCxContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 8px;
    min-height: 38px;
    font-size: var(--font-size-normal);
    border-radius: inherit;
`

export const DateDrawerField: React.FunctionComponent<DateDrawerFieldProps> = props => {
    const { onCellChange, value = '', isControlled, dateConfig, dateField, readOnly, language } = props
    const { date: fieldDate } = dateField ?? {}

    const { placeholder, date } = dateConfig ?? {}

    const { range = 'ALL', customDays } = date ?? {}

    const { format: dateFormat } = fieldDate ?? {}
    const showTime = !!dateFormat && dateFormat.includes('HH')
    const defaultDateFormat = dateFormat || DATE_FORMAT_OPTIONS[0].value
    const timestamp = useMemo(() => getTimestamp(value), [value])

    const { pageTarget } = useApplicationContext()

    const [{ data, opened, mode }, setState] = useSetState<FileValueEditorState>({
        opened: false,
        data: timestamp,
        mode: showTime ? 'full' : 'day'
    })

    const usedValue = useMemo(() => (isControlled ? value : data), [data, isControlled, value])

    const isEmpty = usedValue === ''

    const suffixText = useMemo(() => {
        const currentTimestamp = parseISO(format(Date.now(), 'yyyy-MM-dd')).valueOf()
        const previewTimeStamp = usedValue ? Number(usedValue) : ''
        return previewTimeStamp ? getRelativeDate(previewTimeStamp, currentTimestamp, language) : ''
    }, [language, usedValue])

    const [minDate, maxDate] = useMemo(() => {
        switch (range) {
            case 'AFTER_TODAY': {
                return [new Date(), undefined]
            }
            case 'BEFORE_TODAY': {
                return [undefined, new Date()]
            }
            case 'AFTER_CUSTOM': {
                return [
                    customDays
                        ? add(new Date(), {
                              days: max(customDays, 0)
                          })
                        : undefined,
                    undefined
                ]
            }
            default: {
                return []
            }
        }
    }, [customDays, range])

    const handleChange = useCallback(
        (updateData: DateValue) => {
            setState({ data: updateData, opened: false })
            onCellChange?.({ type: 'date', value: updateData })
        },
        [onCellChange, setState]
    )

    useUpdateEffect(() => {
        setState({ mode: showTime ? 'full' : 'day' })
    }, [showTime])

    return (
        <>
            <SCxContainer
                onClick={() => {
                    if (!readOnly) {
                        setState({ opened: true })
                    }
                }}
            >
                {isEmpty ? (
                    <SelectPlaceHolder label={placeholder} />
                ) : (
                    <>
                        {format(Number(usedValue), defaultDateFormat, {
                            locale: zhCN
                        })}
                        {suffixText}
                    </>
                )}
                <MobileDatePicker
                    target={pageTarget}
                    mode={mode}
                    style={{ opacity: 0, position: 'absolute', inset: 0 }}
                    maxDate={maxDate}
                    minDate={minDate}
                    onModeChange={v => setState({ mode: v })}
                    modalProps={{
                        // @ts-expect-error
                        'data-ignore-click-away': true
                    }}
                    onChange={v => handleChange(v ? v.valueOf() : '')}
                    onBlur={() => setState({ opened: false })}
                />
            </SCxContainer>
        </>
    )
}
