import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react'
import { throttle } from 'throttle-debounce'

import { Box } from '../Box'
import { Text } from '../Text'

const useStyles = createStyles(() => {
    return {
        root: css({
            position: 'relative'
        }),

        content: css({
            display: 'flex',
            gap: '8px',
            overflowX: 'auto',
            hiddenScroll: true
        }),
        tab: css({
            all: 'unset',
            boxSizing: 'border-box',
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 400,
            color: '$colorGray400',
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            borderRadius: 4,
            padding: '9px 8px',
            overflow: 'hidden',
            '&[data-active="true"]': {
                fontWeight: 600,
                color: '$colorGray700'
            }
        }),
        indicator: css({
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: 4,
            borderRadius: 2,
            transition: 'all 0.2s',
            backgroundColor: '$colorPrimary'
        })
    }
})

export type TabsStylesNames = Selectors<typeof useStyles>

export type TabsData = {
    label?: React.ReactNode
    icon?: React.ReactNode
    value: string
    disabled?: boolean
}

export interface TabsProps extends StyleComponentProps<TabsStylesNames>, Omit<React.ComponentPropsWithoutRef<'div'>, 'value' | 'onChange'> {
    data: TabsData[]
    value?: string
    onChange?: (v: string) => void
}

export const Tabs = forwardRef<HTMLDivElement, TabsProps>((props, ref) => {
    const { styles, unstyled, className, classNames, data, value, onChange, ...rest } = props

    const { classes } = useStyles({}, { name: 'Tabs', styles, unstyled, classNames })

    const [_value, _onChange] = useUncontrolled({ value, onChange, finalValue: data[0]?.value })

    const contentRef = useRef<null | HTMLDivElement>(null)

    const [indicatorInfo, setIndicatorInfo] = useState({ width: 0, left: 0 })

    const index = data.findIndex(item => item.value === _value)

    useLayoutEffect(() => {
        const contentEl = contentRef.current
        if (!contentEl) {
            return
        }

        // 检测是否出现滚动条、以及检测选中的tab位置和尺寸
        const handler: ResizeObserverCallback = ([e]) => {
            const activeEl = e.target.children.item(index)
            if (!activeEl) {
                return
            }
            const contentRect = contentEl.getBoundingClientRect()
            const rect = activeEl.getBoundingClientRect()
            const pl = Number.parseFloat(window.getComputedStyle(activeEl).paddingLeft)
            const pr = Number.parseFloat(window.getComputedStyle(activeEl).paddingRight)
            setIndicatorInfo({ width: rect.width - pl - pr, left: pl + rect.x - contentRect.x })
        }
        const observer = new ResizeObserver(handler)

        observer.observe(contentEl)

        // 滚动时更改指示器的定位
        const scrollHandler = throttle(100, (e: Event) => {
            if (e.target instanceof Element) {
                const activeEl = e.target.children.item(index)?.querySelector('*')
                if (!activeEl) {
                    return
                }
                const contentRect = contentEl.getBoundingClientRect()
                const rect = activeEl.getBoundingClientRect()
                const pl = Number.parseFloat(window.getComputedStyle(activeEl).paddingLeft)

                setIndicatorInfo(s => ({ ...s, left: pl + rect.x - contentRect.x }))
            }
        })
        contentEl.addEventListener('scroll', scrollHandler)

        return () => {
            observer.unobserve(contentEl)
            contentEl.removeEventListener('scroll', scrollHandler)
        }
    }, [index])

    return (
        <Box ref={ref} className={clsx(className, classes.root)} {...rest}>
            <Box ref={contentRef} className={classes.content}>
                {data.map(tab => (
                    <Box
                        component="button"
                        key={tab.value}
                        data-active={tab.value === _value || undefined}
                        className={classes.tab}
                        disabled={tab.disabled}
                        onClick={() => {
                            if (_value === tab.value) {
                                return
                            }

                            _onChange(tab.value)
                        }}
                    >
                        {tab.icon}
                        <Text>{tab.label}</Text>
                    </Box>
                ))}
            </Box>
            <Box
                className={classes.indicator}
                style={{
                    left: indicatorInfo.left,
                    width: indicatorInfo.width
                }}
            />
        </Box>
    )
})
