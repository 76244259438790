import { singleTextEllipsis, Tooltip } from '@byecode/ui'
import type { FieldProtocol, HighLightConditions, SimpleTextValue } from '@lighthouse/core'
import { mergeRefs, nanoid } from '@lighthouse/tools'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { CellHighlighter } from '../Highlighter'
import { TooltipList, useWidth } from '../TooltipList'
import { TooltipText } from '../TooltipText'

interface TextValuePreviewerProps {
    field: FieldProtocol
    data?: SimpleTextValue | SimpleTextValue[]
    isWrap?: boolean
    width?: number
    isBold?: boolean
    styleConditions?: HighLightConditions
}

interface TextListPreviewerProps {
    data: SimpleTextValue[]
    width: number
    isWrap: boolean
    isBold?: boolean
}
interface TextItemPreviewerProps {
    id: string
    data?: SimpleTextValue
    isBold?: boolean
    isWrap: boolean
    isLast?: boolean
    disableTooltip?: boolean
}

const SCxText = styled.div<{ isWrap: boolean; isBold?: boolean }>`
    position: relative;
    font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
    white-space: ${({ isWrap }) => (isWrap ? 'pre-wrap' : 'nowrap')};
    word-break: break-word;
    color: var(--color-black);
    overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'hidden')};
    text-overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'ellipsis')};
`

const SCxTextListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean }>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    overflow: hidden;
`

const SCxItem = styled.div<{ isLast?: boolean }>`
    display: flex;
    align-items: center;
    ${({ isLast }) => isLast && singleTextEllipsis}
`

const SCxSplit = styled.div<{ isLast?: boolean }>`
    display: flex;
    overflow: ${({ isLast }) => (isLast ? 'hidden' : 'unset')};
`

export const TextItemPreviewer: React.FC<TextItemPreviewerProps> = ({ id, data = '', isWrap, disableTooltip, isBold, isLast }) => {
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)
    return (
        <TooltipText
            title={data}
            disabled={disableTooltip || isWrap}
            render={(ref, isOmit, rang, target) => {
                return (
                    <SCxItem isLast={isLast} ref={mergeRefs([ref, widthRef])}>
                        <SCxText isWrap={isWrap} isBold={isBold}>
                            {data}
                        </SCxText>
                    </SCxItem>
                )
            }}
        />
    )
}

export const TextListPreviewer: React.FC<TextListPreviewerProps> = ({ data, width, isWrap, isBold }) => {
    return (
        <TooltipList
            data={data.map((label, index) => ({ label, value: String(index) }))}
            width={width}
            key={data.join('-')}
            disabled={isWrap}
            render={visibleNum => {
                const renderList = data.slice(0, visibleNum)
                return (
                    <SCxTextListWrapper isPadding={visibleNum < data.length} isWrap={isWrap}>
                        {renderList.map((item, index) => (
                            <SCxSplit key={index} isLast={index === renderList.length - 1}>
                                <TextItemPreviewer
                                    isLast={index === renderList.length - 1}
                                    id={String(index)}
                                    data={item}
                                    disableTooltip
                                    isWrap={isWrap}
                                    isBold={isBold}
                                />
                                {index !== renderList.length - 1 && ','}
                            </SCxSplit>
                        ))}
                    </SCxTextListWrapper>
                )
            }}
        />
    )
}

export const TextValuePreviewer: React.FC<TextValuePreviewerProps> = ({
    field,
    data,
    width = 160,
    isWrap = true,
    isBold,
    styleConditions
}) => {
    const { id, type } = field

    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (!data) {
            return ''
        }
        if (Array.isArray(data)) {
            return <TextListPreviewer data={data} width={width} isWrap={isWrap} isBold={isBold} />
        }
        const id = nanoid()
        return <TextItemPreviewer id={id} isWrap={isWrap} data={data} isBold={isBold} isLast />
    }, [data, isBold, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={id} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
