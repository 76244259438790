export const ConfiguratorOpItems = [
    {
        area: 'edit',
        items: [{ name: 'edit', icon: 'PencilSimple', value: '编辑', hotKey: 'EDIT' }]
    },
    {
        area: 'query',
        items: [
            // { name: 'setAsTitle', icon: 'TitleProperty', value: '设为主字段', hotKey: 'SETASTITLE' }
            // { name: 'filter', icon: 'Filter', value: '按「代表作」筛选', hotKey: 'FILTER' },
            // { name: 'group', icon: 'ArrowsDownUp', value: '按「代表作」分组', hotKey: 'GROUP' },
            // { name: 'sortASC', icon: 'SortAscending', value: '从 A→ Z 排序', hotKey: 'SORTASC' },
            // { name: 'sortDESC', icon: 'SortDescending', value: '从 Z → A 排序', hotKey: 'SORTDESC' }
        ]
    },
    {
        area: 'column-op',
        items: [
            { name: 'insertLeft', icon: 'ArrowLineLeft', value: '左插入新字段', hotKey: 'INSERTLEFT' },
            { name: 'insertRight', icon: 'ArrowLineRight', value: '右插入新字段', hotKey: 'INSERTRIGHT' },
            { name: 'findUse', icon: 'Search', value: '查找使用', hotKey: 'FINDUSE' }
            // { name: 'wrap', icon: 'ArrowUDownLeft', value: '自动换行', hotKey: 'WRAP' }
        ]
    },
    {
        area: 'column-setting',
        items: [
            // { name: 'show', icon: 'NoHide', value: '显示', hotKey: 'SHOW' },
            // { name: 'duplicate', icon: 'Duplicate', value: '复制', hotKey: 'DUPLICATE' },
            { name: 'delete', icon: 'Trash', value: '删除', hotKey: 'DELETE' }
        ]
    }
]
