import { IconFont, Loading, pointer, singleTextEllipsis } from '@byecode/ui'
import type { AiFieldState, FieldProtocol, HighLightConditions, SimpleTextValue } from '@lighthouse/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'
import { useWindupString } from 'windups'

import { CellHighlighter } from '../Highlighter'
import { TooltipText } from '../TooltipText'
import { TextListPreviewer } from './TextValuePreviewer'

// import type { FieldValuePreviewerProps } from './types'
interface TextGenerationValuePreviewerProps {
    field: FieldProtocol
    data: SimpleTextValue | SimpleTextValue[]
    isWrap?: boolean
    width?: number
    isBold?: boolean
    aiFieldStatus?: AiFieldState
    styleConditions?: HighLightConditions
    onAiGeneration?: (fieldId: string) => Promise<boolean>
}

interface TextGenerationItemPreviewerProps {
    field: FieldProtocol
    data: SimpleTextValue
    isWrap: boolean
    isBold?: boolean
    isLast?: boolean
    disableTooltip?: boolean
    aiFieldStatus?: AiFieldState
    onAiGeneration?: (fieldId: string) => Promise<boolean>
}

const SCxContainer = styled.div`
    display: flex;
    flex-flow: nowrap row;
    overflow: hidden;
    width: 100%;
`

const SCxPrefix = styled.div`
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    width: 20px;
    height: 20px;
`

const SCxClear = styled.div`
    clear: both;
`

const SCxIcon = styled(IconFont)`
    ${pointer}
`

const SCxWrap = styled.div`
    width: 100%;
    position: relative;
    word-break: break-word;
    color: var(--color-black);
`

const SCxText = styled.div<{ isBold?: boolean }>`
    position: relative;
    font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
    white-space: nowrap;
    word-break: break-word;
    color: var(--color-black);
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxParagraph = styled.p<{ isBold?: boolean }>`
    font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
    white-space: pre-wrap;
    /* line-height: 20px; */
`

const SCxCursor = styled.span`
    animation: flicker-cursor 1s infinite;
    position: relative;
    top: -1px;
    left: 1px;

    @keyframes flicker-cursor {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`

const SCxItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    ${singleTextEllipsis}
`

export const TextGenerationItemPreviewer: React.FC<TextGenerationItemPreviewerProps> = ({
    field,
    data,
    isWrap,
    isBold,
    disableTooltip,
    aiFieldStatus,
    onAiGeneration
}) => {
    // const typeWriteRef = useRef(data)
    const [state, setState] = useImmer({
        step: false,
        text: data
    })
    const { step, text } = state
    // const [step, setStep] = useState(false)
    const [loading, setLoading] = useState(false)
    const [windup, { isFinished }] = useWindupString(data, {
        pace: char => {
            if (char === '，') {
                return 1000
            }
            if (char === '、') {
                return 500
            }
            return 100
        },
        onFinished() {
            setState(draft => {
                draft.step = false
                draft.text = data
            })
        },
        skipped: text === data
    })

    useEffect(() => {
        if (!isFinished && !step) {
            setState(draft => {
                draft.step = true
            })
        }
    }, [isFinished, setState, step])

    useEffect(() => {
        setLoading(aiFieldStatus === 'STARTED')
    }, [aiFieldStatus])

    const handleAiGeneration = useCallback(
        async (e: React.MouseEvent) => {
            e.stopPropagation()
            if (onAiGeneration) {
                setLoading(true)
                const isSuccess = await onAiGeneration(field.id)
                if (!isSuccess) {
                    setLoading(false)
                }
            }
        },
        [field.id, onAiGeneration]
    )

    return (
        <SCxContainer>
            {isWrap ? (
                <SCxItem>
                    {/* <SCxText isBold={isBold}> */}
                    <SCxWrap>
                        <SCxPrefix>
                            {loading ? (
                                <Loading outlined size={18} />
                            ) : (
                                <SCxIcon type="PropertyAIText" size={16} fill="#FA7066" onPointerDown={handleAiGeneration} />
                            )}
                        </SCxPrefix>
                        <SCxParagraph isBold={isBold}>
                            {windup}
                            {step && <SCxCursor>|</SCxCursor>}
                        </SCxParagraph>
                    </SCxWrap>
                    <SCxClear />
                </SCxItem>
            ) : (
                <TooltipText
                    title={windup}
                    disabled={isWrap || disableTooltip}
                    render={ref => {
                        return (
                            <SCxItem>
                                <SCxPrefix>
                                    {loading ? (
                                        <Loading outlined size={18} />
                                    ) : (
                                        <SCxIcon type="PropertyAIText" size={16} fill="#FA7066" onPointerDown={handleAiGeneration} />
                                    )}
                                </SCxPrefix>
                                <SCxText ref={ref} isBold={isBold}>
                                    {windup}
                                    {step && <SCxCursor>|</SCxCursor>}
                                </SCxText>
                            </SCxItem>
                        )
                    }}
                />
            )}
        </SCxContainer>
    )
}

export const TextGenerationValuePreviewer: React.FC<TextGenerationValuePreviewerProps> = ({
    field,
    data,
    width = 160,
    isWrap = true,
    isBold,
    aiFieldStatus,
    styleConditions,
    onAiGeneration
}) => {
    const { id, type } = field

    const content = useMemo(() => {
        if (Array.isArray(data)) {
            return <TextListPreviewer data={data} width={width} isWrap={isWrap} isBold={isBold} />
        }
        return (
            <TextGenerationItemPreviewer
                field={field}
                aiFieldStatus={aiFieldStatus}
                isWrap={isWrap}
                data={data}
                isLast
                isBold={isBold}
                onAiGeneration={onAiGeneration}
            />
        )
    }, [aiFieldStatus, data, field, isBold, isWrap, onAiGeneration, width])

    return (
        <>
            <CellHighlighter fieldId={id} type={type} value={data} conditions={styleConditions} />
            {content}
        </>
    )
}
