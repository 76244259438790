import type { FilterBlockValue } from "@lighthouse/core"
import type { CacheValueWidthExpires } from "@lighthouse/shared"
import { atom } from "jotai"

import { filterBlockCacheAtom, filterCacheAtom, groupCacheAtom, kanbanColumnsSortCacheAtom, sortsCacheAtom, tableColumnCacheAtom, tablePropsCacheAtom } from "./state"


type CacheData<T> = Record<string, CacheValueWidthExpires<T>>

const clearExpiresData = <T>(data: CacheData<T>): CacheData<T> => {
    const dataEntries = Object.entries(data)
    const newDataEntries = dataEntries.filter(([key, data]) => {
        return !(Date.now() > data.expires);
    })
    return Object.fromEntries(newDataEntries)
}

const clearExpiresFilterCache = atom(null, (get, set) => {
    set(filterCacheAtom, clearExpiresData(get(filterCacheAtom)))
})

const clearTablePropsCache = atom(null, (get, set) => {
    set(tablePropsCacheAtom, clearExpiresData( get(tablePropsCacheAtom)))
})

const clearSortCache = atom(null, (get, set) => {
    set(sortsCacheAtom, clearExpiresData(get(sortsCacheAtom)))
})

const clearGroupCache = atom(null, (get, set) => {
    set(groupCacheAtom, clearExpiresData(get(groupCacheAtom)))
})

const clearTableColumnCache = atom(null, (get, set) => {
    set(tableColumnCacheAtom, clearExpiresData(get(tableColumnCacheAtom)))
})

const clearKanbanColumnsSortCache = atom(null, (get, set) => {
    set(kanbanColumnsSortCacheAtom, clearExpiresData(get(kanbanColumnsSortCacheAtom)))
})

const clearFilterBlockCache = atom(null, (get, set) => {
    set(filterBlockCacheAtom, clearExpiresData<FilterBlockValue>(get(filterBlockCacheAtom)))
})

export const clearExpiresCacheAtom = atom(null, (get, set) => {
    set(clearExpiresFilterCache)
    set(clearTablePropsCache)
    set(clearSortCache)
    set(clearGroupCache)
    set(clearTableColumnCache)
    set(clearKanbanColumnsSortCache)
    set(clearFilterBlockCache)
})
