import { Drawer, RingProgress } from '@mantine/core'
import cls from 'classnames'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FILE_ICON_MAP } from '../../../constants/common'
import { useApplicationContext } from '../../../contexts'
import type { EnableActionsParams, FileItemAction, WithStatusFile } from '../../../types'
import { ApplicationPreviewEnum } from '../../../types'
import { getImageFullUrlInApplication } from '../../../utils'
import { FILE_OPERATION_OPTIONS, FileItemActionLabelMap } from '../constants'
import FileItem from '../mobile/FileItem'
import type { FileOperationOption } from '../types'
import { getFileDrawerConfig } from '../WithoutPopperFileUploader'
import * as SC from './styles'
import { TooltipIcon } from './TooltipIcon'

const DEFAULT_ACTIONS: EnableActionsParams = { canDownload: true, canPreview: true, canRename: true, canDelete: true }

export interface FileListItemProps {
    children?: React.ReactNode
    data: WithStatusFile
    enableActions?: EnableActionsParams
    variant?: 'card' | 'normal'
    previewType: ApplicationPreviewEnum
    disableDownload?: boolean
    onRemove?: (fileId: WithStatusFile['uid']) => void
    onChange?: (name: string) => void
    onPreview?: () => void
}

const FileListItem: React.FC<FileListItemProps> = ({
    data,
    enableActions = true,
    variant = 'normal',
    previewType,
    disableDownload,
    onRemove,
    onChange,
    onPreview
}) => {
    const { uid, type, url, name, status, percent } = data
    const inputRef = useRef<HTMLInputElement>(null)
    const [opened, setOpened] = useState(false)
    const { pageTarget, appId } = useApplicationContext()
    const { t } = useTranslation()

    const menuData: EnableActionsParams = useMemo(() => {
        if (!enableActions) {
            return {}
        }
        return typeof enableActions === 'boolean' ? DEFAULT_ACTIONS : enableActions
    }, [enableActions])

    const filterOptions: FileOperationOption[] = useMemo(() => {
        const { canPreview, canDelete, canRename, canDownload } = menuData
        const actions: Record<FileItemAction, boolean | undefined> = {
            download: canDownload,
            preview: canPreview,
            remove: canDelete,
            rename: canRename
        }
        return FILE_OPERATION_OPTIONS.filter(item => {
            if (item.value === 'rename') {
                return previewType === ApplicationPreviewEnum.mobile && actions[item.value]
            }
            if (item.value === 'download') {
                return !(disableDownload || !actions[item.value])
            }
            return actions[item.value]
        })
    }, [disableDownload, menuData, previewType])

    const handlers = useMemo(() => {
        return {
            download() {
                window.open(url)
            },
            preview() {
                onPreview?.()
            },
            rename() {
                //
            },
            remove() {
                onRemove?.(uid)
            }
        }
    }, [onPreview, onRemove, uid, url])

    const handleAction = useCallback(
        (action: FileItemAction) => {
            handlers[action]()
            setOpened(false)
        },
        [handlers]
    )

    const handleDeleteFileItem = useCallback(() => {
        onRemove?.(uid)
    }, [onRemove, uid])

    const handlePreview = useCallback(
        (status: string) => {
            if (status === 'success') {
                handleAction('preview')
            }
        },
        [handleAction]
    )

    const fileIcon = useMemo(() => {
        return type === 'image' ? (
            url && (
                <SC.ImageThumb
                    title={name}
                    status={status}
                    src={getImageFullUrlInApplication(appId, url)}
                    onClick={() => handlePreview(status)}
                />
            )
        ) : FILE_ICON_MAP[type] ? (
            <SC.FileIcon type={FILE_ICON_MAP[type]} status={status} size={24} onClick={() => handlePreview(status)} />
        ) : (
            <SC.FileIcon type={FILE_ICON_MAP.other} status={status} size={24} onClick={() => handlePreview(status)} />
        )
    }, [appId, handlePreview, name, status, type, url])

    const fileActionIcon = useMemo(() => {
        switch (status) {
            case 'error': {
                return (
                    <SC.MoreErrorContainer>
                        <SC.ErrorIcon type="WarningCircle" />
                        <SC.CloseIcon type="Close" onClick={handleDeleteFileItem} />
                    </SC.MoreErrorContainer>
                )
            }
            case 'uploading': {
                return (
                    <SC.MoreUploadingContainer>
                        <SC.MoreUploadingPercent>{Math.ceil(percent ?? 0)}%</SC.MoreUploadingPercent>
                        <RingProgress size={20} thickness={4} sections={[{ value: percent ?? 0, color: 'var(--color-gray-500)' }]} />
                    </SC.MoreUploadingContainer>
                )
            }
            case 'success': {
                if (typeof enableActions === 'boolean') {
                    return
                }
                if (previewType === ApplicationPreviewEnum.mobile) {
                    return (
                        <SC.MoreSuccessContainer defaultOpacity={1} style={{ justifyContent: 'flex-end' }}>
                            <TooltipIcon key={name} label={t('more')} icon="DotsThree" onClick={() => setOpened(true)} />
                        </SC.MoreSuccessContainer>
                    )
                }
                return (
                    <SC.MoreSuccessContainer>
                        {filterOptions.map(({ value }) => {
                            const item = FileItemActionLabelMap[value]
                            return <TooltipIcon key={value} {...item} label={t(item.label)} onClick={() => handleAction(value)} />
                        })}
                    </SC.MoreSuccessContainer>
                )
            }
            default: {
                return null
            }
        }
    }, [enableActions, filterOptions, handleAction, handleDeleteFileItem, name, percent, previewType, status, t])

    return (
        <SC.FileListItemWrapper variant={variant}>
            <SC.FileInfo className={cls({ 'not-uploaded': status !== 'success' })} onClick={() => onPreview?.()}>
                {fileIcon}
                <SC.FileName key="info">{name}</SC.FileName>
            </SC.FileInfo>
            {fileActionIcon}
            {/* 移动端图片操作 */}
            <Drawer {...getFileDrawerConfig()} target={pageTarget} opened={opened} onClose={() => setOpened(false)}>
                <FileItem
                    value={data}
                    handleAction={handleAction}
                    options={filterOptions}
                    target={pageTarget}
                    onUpdate={val => {
                        onChange?.(val)
                        setOpened(false)
                    }}
                    onClose={() => setOpened(false)}
                />
            </Drawer>
        </SC.FileListItemWrapper>
    )
}

export default FileListItem
