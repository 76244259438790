import { IconFont, Input, pointer, singleTextEllipsis, tinyButtons } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    background-color: var(--color-gray-50);
`

export const FinishButton = styled.div`
    font-weight: 500;
    color: var(--color-app-main);
    margin-left: auto;
    ${pointer}

`

export const Cursor = styled.div``

export const SearchInput = styled(Input)`
    height: 40px;
    &:focus-within {
        border-color: var(--color-app-main)
    }
`
export const Path = styled.div`
    /* margin: 12px 0; */
`
