import type { BlockAbstract, FieldCellValue, FieldValue, FileValue } from '@lighthouse/core'
import { BlockType, DIRECTION } from '@lighthouse/core'
import { getFileTypeByUrl, VIEW_BLOCK_NAME_MAP } from '@lighthouse/shared'

import {
    BLOCK_NAME_MAP,
    BlockTypeIconMap,
    CHART_BLOCK_NAME_MAP,
    ChartTypeIconMap,
    CONTAINER_ICON_MAP,
    FIELD_BLOCK_ICON_MAP,
    FIELD_BLOCK_NAME_MAP,
    FILTER_BLOCK_ICON_MAP,
    QR_BARCODE_BLOCK_ICON_MAP,
    VIEW_BLOCK_ICON_MAP
} from './constants'

/** 是否为附件类型 */
function isFileFieldValue(value: FieldCellValue | unknown[]): value is FileValue {
    return Array.isArray(value) && value.some(k => typeof k === 'string')
}

/**
 * 根据字段获取image地址
 * @param {FieldValue} field 字段类型
 * @returns
 */
export function getImageFromField(field?: FieldValue) {
    if (!field || !field.value) {
        return ''
    }
    if (isFileFieldValue(field.value)) {
        return field.value.find(item => {
            const type = getFileTypeByUrl(item)
            return type === 'image'
        })
    }
    return ''
}

/**
 * 获取block icon
 *
 * 视图、字段及图表会根据内部类型而变化
 * @param block
 * @param dataSource
 */
export function getBlockIcon(block: BlockAbstract) {
    switch (block.type) {
        case BlockType.container: {
            const { direction = DIRECTION.horizontal } = block.config.design
            return CONTAINER_ICON_MAP[direction]
        }
        case BlockType.field: {
            const { inputType } = block.config

            return FIELD_BLOCK_ICON_MAP[inputType]
        }

        case BlockType.chart: {
            const { chartType } = block.config
            return ChartTypeIconMap[chartType]
        }

        case BlockType.view: {
            const { viewType } = block.config
            return VIEW_BLOCK_ICON_MAP[viewType]
        }

        case BlockType.qrBarcode: {
            const { codeType } = block.config
            return QR_BARCODE_BLOCK_ICON_MAP[codeType]
        }

        case BlockType.filter: {
            const { mode } = block.config
            return FILTER_BLOCK_ICON_MAP[mode]
        }

        default: {
            return BlockTypeIconMap[block.type]
        }
    }
}

/**
 * 获取block 名称
 *
 * 视图、字段及图表会根据内部类型而变化
 * @param block
 * @param dataSource
 * @returns
 */
export function getBlockName(block: BlockAbstract) {
    switch (block.type) {
        case BlockType.field: {
            const { inputType } = block.config
            return FIELD_BLOCK_NAME_MAP[inputType]
        }

        case BlockType.chart: {
            const { chartType } = block.config
            return CHART_BLOCK_NAME_MAP[chartType]
        }

        case BlockType.view: {
            const { viewType } = block.config
            return VIEW_BLOCK_NAME_MAP[viewType]
        }
        default: {
            return BLOCK_NAME_MAP[block.type]
        }
    }
}
