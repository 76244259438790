import type { ButtonAction, ViewBlockAbstract } from '@lighthouse/core'
import React from 'react'

type ForActionContextValue = {
    blockData: ViewBlockAbstract
    showAction?: boolean
    onTriggerAction?: (action: ButtonAction) => void
}

const ForActionContext = React.createContext<ForActionContextValue>({
    blockData: {} as ViewBlockAbstract,
    showAction: false,
    onTriggerAction: undefined
})
export const ForActionProvider = ForActionContext.Provider
export const useForActionContext = () => React.useContext(ForActionContext)
