import { Button } from '@byecode/ui/components/Button'
import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import { hidePhoneNumber } from '@lighthouse/tools'
import i18next from 'i18next'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { ApplicationPreviewEnum } from '../../../types'
import { CodeInput } from '../CodeInput'
import { appLoginLabelMap } from '../constant'
import LoginBox from '../LoginBox'

interface ICodeProps {
    type: 'email' | 'mobile'
    account: string
    authentication: ApplicationSettingAuthentication
    label: string
    disabled: boolean
    style?: React.CSSProperties
    children?: React.ReactNode
    language: string
    previewType?: ApplicationPreviewEnum
    onChangeLanguage: (v: string) => void
    onSendCode: () => void
    onBack: () => void
}

export const Code: React.FunctionComponent<ICodeProps> = ({
    type,
    account,
    authentication,
    label,
    disabled,
    style,
    children,
    language,
    previewType,
    onChangeLanguage,
    onSendCode,
    onBack
}) => {
    const {
        register: { allowRegister },
        name,
        logo,
        describe
    } = authentication
    const { watch, control } = useFormContext()

    const { t } = useTranslation()

    // 获取验证码
    return (
        <LoginBox
            logo={logo}
            name={t('inputVerificationCode', { v: t(appLoginLabelMap[type]?.name) })}
            describe={type === 'email' ? t('verificationMessage', { v: account }) : t('verificationSMS', { v: hidePhoneNumber(account) })}
            language={language}
            previewType={previewType}
            onChangeLanguage={onChangeLanguage}
            onBack={onBack}
            dividerLabel={t('noVerificationCodeReceived')}
            customBtn={
                <Button
                    block
                    style={{
                        height: 44,
                        marginTop: 16,
                        backgroundColor: 'var(--color-app-main-tint)',
                        color: 'var(--color-app-main) ',
                        borderStyle: 'none'
                    }}
                    type="primary"
                    radius={10}
                    disabled={disabled}
                    onClick={onSendCode}
                >
                    {label}
                </Button>
            }
            style={style}
        >
            <Controller name="code" control={control} render={({ field }) => <CodeInput maxLength={6} {...field} />} />
            {children}
        </LoginBox>
    )
}
