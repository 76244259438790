import { flex, IconFont, singleTextEllipsis } from '@byecode/ui'
import type { ButtonBlockTriggerEvent } from '@lighthouse/core'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

export const btnHoverTransition = (): FlattenSimpleInterpolation => css`
    position: relative;
    &:hover::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 6px;
        /* cursor: not-allowed; */
        z-index: 1;
    }
`

export const btnClickTransition = (): FlattenSimpleInterpolation => css`
    position: relative;
    &:active::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        /* cursor: not-allowed; */
        border-radius: 6px;
        z-index: 1;
    }
`

export const btnDisabled = (): FlattenSimpleInterpolation => css`
    position: relative;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        /* cursor: not-allowed; */
        border-radius: 6px;
        z-index: 3;
    }
`

export const Container = styled.button<{ event?: ButtonBlockTriggerEvent; disabled?: boolean }>`
    overflow: hidden;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
    ${({ event, disabled }) => event === 'hover' && !disabled && btnHoverTransition}
    ${({ event, disabled }) => event === 'click' && !disabled && btnClickTransition}
    &:disabled {
        cursor: not-allowed;
        ${btnDisabled}
    }
`

export const Text = styled.div<{ color?: string }>`
    position: relative;
    z-index: 2;
    font-size: var(--font-size-normal);
    color: ${({ color }) => color ?? '#fff'};
    white-space: nowrap;
    ${singleTextEllipsis}
`

export const Icon = styled(IconFont)<{ loading?: boolean }>`
    position: relative;
    z-index: 2;
    font-size: var(--font-size-base);
    animation: ${({ loading }) => loading && ' loading-circle 1s linear infinite;'};
    @keyframes loading-circle {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
`

export const Wrapper = styled.div<{ event?: ButtonBlockTriggerEvent }>`
    position: relative;
    width: 100%;
    /* height: 100%; */
    height: 41px;
    padding: 10px 20px;
    ${flex}
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    gap: 4px;
    /* ${Text} {
        margin-left: 4px;
    }
    ${Icon}:last-child {
        margin-left: 4px;
    } */
`
