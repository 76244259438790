import { CascadeSelect } from '@byecode/ui'
import type { Field } from '@lighthouse/core'
import { DateFieldRange, VariableType } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'
import type { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { useApplicationContext } from '../../contexts'
import { getDateConfig, getDepartmentToTreeOptions, isCheckboxValue, isIdsValue, isNumberValue, isTextValue } from '../../utils'
import { AggregationInnerTypeToFieldType, DEFAULT_FILTER_VALUE_VARIABLE } from '../'
import { PersonSelect } from '../PersonSelect'
import { BoolInput, BoolItem } from './BoolItem'
import { boolOption } from './constants'
import * as SC from './styles'

interface ParamsProps {
    field?: Field
    prefixName: string
    width?: string
    focusOutLine?: string
    enableCurrentUser?: boolean
    disabled?: boolean
    // cascadeOptions: CascadeSelectOption[]
}

export const Params: React.FC<ParamsProps> = ({
    field,
    prefixName,
    focusOutLine = 'var(--color-main)',
    width = 'auto',
    enableCurrentUser = true,
    disabled
}) => {
    const { control } = useFormContext()
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()

    const operator: string = useWatch({
        control,
        name: `${prefixName}.operator`
    })

    const realField = useMemo(() => {
        if (!field) {
            return
        }
        if ((field.type === 'formula' || field.type === 'aggregation') && field.innerType) {
            return {
                ...field,
                type: AggregationInnerTypeToFieldType[field.innerType]
            } as Field
        }
        return field
    }, [field])

    const options = useMemo(() => {
        if (!field) {
            return []
        }
        if (field.type === 'select' || field.type === 'singleSelect') {
            const options = field.type === 'select' ? field.select.options : field.singleSelect.options
            return options.map(item => ({
                label: item.label,
                value: item.label,
                color: item?.color
            }))
        }
        if (field.type === 'role') {
            return roleOptions.map(item => ({
                label: item.name,
                value: item.id,
                color: 'gray'
            }))
        }
        if (field.type === 'department') {
            return getDepartmentToTreeOptions(departmentOptions)
        }
        return []
    }, [departmentOptions, field, roleOptions])

    // const mode = useMemo(() => {
    //     const index = filterModeIndex[operator]
    //     if (!realField) {
    //         return 'input'
    //     }
    //     if (!index && index !== 0) {
    //         return
    //     }
    //     if (!realField.type) {
    //         return
    //     }
    //     return propertyMode?.[realField.type]?.[index]
    // }, [operator, realField])

    // const showTime = useMemo(() => {
    //     if (field?.type === 'formula' || field?.type === 'aggregation') {
    //         return true
    //     }

    //     return realField?.type === 'date' && !!realField?.date?.format && realField.date.format.includes('HH')
    // }, [field, realField])

    // const format = useMemo(() => {
    //     if (field?.type === 'date') {
    //         return field.date?.format || defaultDateFormat
    //     }
    // }, [field])

    const renderParams = useCallback(
        (ctlField: ControllerRenderProps<FieldValues, `${string}.paramList`>) => {
            const [firstParam = DEFAULT_FILTER_VALUE_VARIABLE, secondParam = DEFAULT_FILTER_VALUE_VARIABLE] = ctlField.value || [
                DEFAULT_FILTER_VALUE_VARIABLE,
                DEFAULT_FILTER_VALUE_VARIABLE
            ]

            const firstValue = firstParam?.valueVariable?.value
            const secondValue = secondParam?.valueVariable?.value

            switch (field?.innerType) {
                case 'DATE': {
                    const firstDateValue = isNumberValue(firstValue) ? new Date(Number(firstValue)) : undefined
                    const secondDateValue = isNumberValue(secondValue) ? new Date(Number(secondValue)) : undefined
                    const dateInfo = getDateConfig(field)
                    const range = dateInfo?.range
                    const format = dateInfo?.format
                    const calendarMode = range === DateFieldRange.MONTH ? 'month' : 'day'
                    const showTime = range === DateFieldRange.MINUTE

                    if (operator === 'between') {
                        return (
                            <>
                                <SC.ParamsDatePicker
                                    clearable
                                    key={`${prefixName}.paramList`}
                                    {...ctlField}
                                    popoverProps={{
                                        withinPortal: true
                                    }}
                                    disabled={!realField || disabled}
                                    showTime={showTime}
                                    format={format}
                                    // defaultMode={calendarMode}
                                    value={firstDateValue}
                                    placeholder="请选择"
                                    onChange={val => {
                                        if (!val) {
                                            return ctlField.onChange([DEFAULT_FILTER_VALUE_VARIABLE, secondParam])
                                        }
                                        ctlField.onChange([
                                            { type: VariableType.VALUE, valueVariable: { type: realField?.type, value: val.valueOf() } },
                                            secondParam
                                        ])
                                    }}
                                />
                                <SC.ParamsDatePicker
                                    clearable
                                    key={`${prefixName}.paramList`}
                                    {...ctlField}
                                    popoverProps={{
                                        withinPortal: true
                                    }}
                                    disabled={!realField || disabled}
                                    showTime={showTime}
                                    format={format}
                                    // defaultMode={calendarMode}
                                    // disablePortal
                                    value={secondDateValue}
                                    placeholder="请选择"
                                    // style={{ width }}
                                    onChange={val => {
                                        if (!val) {
                                            return ctlField.onChange([firstParam, DEFAULT_FILTER_VALUE_VARIABLE])
                                        }
                                        ctlField.onChange([
                                            firstParam,
                                            { type: VariableType.VALUE, valueVariable: { type: realField?.type, value: val.valueOf() } }
                                        ])
                                    }}
                                />
                            </>
                        )
                    }

                    return (
                        <SC.ParamsDatePicker
                            clearable
                            key={`${prefixName}.paramList`}
                            // {...ctlField}
                            // disablePortal
                            popoverProps={{
                                withinPortal: true
                            }}
                            disabled={!realField || disabled}
                            style={{ width: '100%' }}
                            showTime={showTime}
                            // defaultMode={calendarMode}
                            format={format}
                            value={firstDateValue}
                            // placeholder="请选择"
                            onChange={val => {
                                if (!val) {
                                    return ctlField.onChange([DEFAULT_FILTER_VALUE_VARIABLE])
                                }
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { type: realField?.type, value: val.valueOf() }
                                    }
                                ])
                            }}
                        />
                    )
                }
                case 'ARRAY': {
                    const originValue = isTextValue(firstValue) ? firstValue : ''
                    if (field.type === 'aggregation' || field.type === 'formula') {
                        return (
                            <SC.ParamsInput
                                key={`${prefixName}.paramList`}
                                width={width}
                                {...ctlField}
                                disabled={!realField || disabled}
                                placeholder="请输入"
                                value={originValue}
                                type="text"
                                onChange={ev => {
                                    const { value: newValue } = ev.target
                                    ctlField.onChange([
                                        {
                                            type: VariableType.VALUE,
                                            valueVariable: { type: realField?.type, value: newValue }
                                        }
                                    ])
                                }}
                            />
                        )
                    }
                    const selectValue = isIdsValue(firstValue) ? firstValue : []
                    if (field.type === 'person') {
                        return (
                            <PersonSelect
                                options={personOptions}
                                enableCurrentUser={enableCurrentUser}
                                color={focusOutLine}
                                isMultiple
                                tagClearable
                                placeholder="请选择"
                                focusOutLine={focusOutLine}
                                width={width}
                                disabled={!realField || disabled}
                                // minWidth={width}
                                styles={{ container: { height: 32 } }}
                                isHiddenUserId
                                value={selectValue}
                                onChange={val => {
                                    ctlField.onChange([
                                        {
                                            type: VariableType.VALUE,
                                            valueVariable: { type: realField?.type, value: val }
                                        }
                                    ])
                                }}
                            />
                        )
                    }
                    if (field.type === 'department') {
                        return (
                            <CascadeSelect
                                popoverWidth={424}
                                data={selectValue}
                                multiple
                                lastLevel={false}
                                onChange={val => {
                                    ctlField.onChange([
                                        {
                                            type: VariableType.VALUE,
                                            valueVariable: { type: realField?.type, value: val }
                                        }
                                    ])
                                }}
                                options={options}
                            />
                        )
                    }

                    return (
                        <SC.ParamsMultiSelect
                            clearable
                            key={`${prefixName}.paramList`}
                            style={{ width, minWidth: 160 }}
                            placeholder="请选择"
                            isMultiple={field.type === 'select'}
                            overlayStyle={{ maxHeight: 300, overflowY: 'auto' }}
                            themeColor={focusOutLine}
                            disabled={!realField || disabled}
                            value={selectValue}
                            onChange={val => {
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { type: realField?.type, value: val }
                                    }
                                ])
                            }}
                            onClear={() => ctlField.onChange([])}
                            options={options}
                        />
                    )
                }

                case 'BOOL': {
                    const selectValue = isCheckboxValue(firstValue) ? (firstValue ? '1' : '0') : ''
                    return (
                        <SC.ParamsSelect
                            clearable
                            disabled={!realField || disabled}
                            key={`${prefixName}.paramList`}
                            style={{ width }}
                            optionComponent={BoolItem}
                            customInputValueRender={BoolInput}
                            styles={{
                                root: {
                                    width: '100%',
                                    height: '32px'
                                }
                            }}
                            value={selectValue}
                            onChange={val => {
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { type: realField?.type, value: val === '' ? undefined : !!Number(val) }
                                    }
                                ])
                            }}
                            options={boolOption}
                        />
                    )
                }

                default: {
                    const inputType = ['NUMBER'].includes(realField?.innerType || '') ? 'number' : 'text'
                    const originValue = isTextValue(firstValue) ? firstValue : ''
                    return (
                        <SC.ParamsInput
                            key={`${prefixName}.paramList`}
                            width={width}
                            {...ctlField}
                            disabled={!realField || disabled}
                            placeholder="请输入"
                            value={originValue}
                            type={inputType}
                            onChange={ev => {
                                const { value: newValue } = ev.target
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { type: realField?.type, value: newValue }
                                    }
                                ])
                            }}
                        />
                    )
                }
            }
        },
        [field, operator, prefixName, realField, disabled, width, focusOutLine, options, personOptions, enableCurrentUser]
    )

    return (
        <Controller
            name={`${prefixName}.paramList`}
            control={control}
            render={({ field: ctlField }) => {
                const dummyComp = <div />
                if (operator === 'isEmpty' || operator === 'isNotEmpty') {
                    return dummyComp
                }
                // const timestamp = Number(ctlField.value)
                return <SC.Box key={`${prefixName}.paramList`}>{renderParams(ctlField)}</SC.Box>
            }}
        />
    )
}
