import type { VariableADTvalue } from '@lighthouse/core'
import type { FC } from 'react'
import React, { useCallback } from 'react'

import type { VariableSelectDropDownProps } from '../../'
import { VariablePicker } from '../../VariablePicker'
import { useTipTapContext } from '../Context'
import { DropdownActionButton } from './styles'

interface Props
    extends Partial<
        Pick<VariableSelectDropDownProps, 'options' | 'userOption' | 'systemOption' | 'dataSourceOption' | 'viewOption' | 'enablePageLink'>
    > {}

export const VariableMenu: FC<Props> = ({ options = [], userOption, systemOption, viewOption, dataSourceOption, enablePageLink }) => {
    const editor = useTipTapContext()

    const onSelect = useCallback(
        (value: VariableADTvalue) => {
            editor?.chain().focus().setVariable(value).run()
        },
        [editor]
    )

    return (
        <VariablePicker
            triggerWrapper={DropdownActionButton}
            options={options}
            userOption={userOption}
            systemOption={systemOption}
            dataSourceOption={dataSourceOption}
            viewOption={viewOption}
            enablePageLink={enablePageLink}
            onSelect={onSelect}
        />
    )
}
