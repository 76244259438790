import { Tabs } from '@mantine/core'
import styled from 'styled-components'

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 6px 1px;
`

// export const ListScrollerContent = styled.div`
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     flex: 1;
//     overflow: auto;
// `

export const ListScrollerContent = styled.div<{ border?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    flex: 1;
    background-color: var(--color-white);
    border-radius: 12px;
    border: ${({ border }) => (border ? '1px solid var(--color-gray-300)' : 'none')};
`

export const TableNoDataContent = styled.div<{ width?: number }>`
    width: ${({ width }) => (width ? `${width - 2}px` : '100%')};
    padding: 40px 0;
    position: sticky;
    left: 0;
    top: 0;
`
