/* eslint-disable promise/prefer-await-to-callbacks */
import type { FlowLayoutNode } from '@lighthouse/shared'

export function setDeadCopyNode(nodes: FlowLayoutNode[], isAlive?: boolean, virtualParentId?: string): FlowLayoutNode[] {
    return nodes.map(item => {
        return {
            ...item,
            static: !isAlive,
            disabled: !isAlive,
            virtualParentId,
            children: item.type === 'block' ? undefined : setDeadCopyNode(item.children ?? [], isAlive)
        }
    })
}

export function dispatchEventInShallowLayer(
    ev: React.MouseEvent<HTMLDivElement>,
    isInRunner: boolean,
    callback: (ev: React.MouseEvent<HTMLDivElement>) => void
) {
    const domPaths = ev.nativeEvent.composedPath()
    const domPathIndex = domPaths.indexOf(ev.currentTarget)
    const hasButton = domPaths.some((path: EventTarget) => {
        if (path instanceof HTMLElement) {
            return path.tagName === 'BUTTON'
        }
        return false
    })

    if (domPathIndex <= 2 || (isInRunner && domPathIndex > 2 && !hasButton)) {
        callback?.(ev)
    }
}
