import { pointer } from '@byecode/ui'
import styled from 'styled-components'

export const SettingBox = styled.div<{ isActive?: boolean; isOpen?: boolean; mainColor: string; tintMainColor: string }>`
    position: relative;
    padding: 4px 6px;
    border-radius: 6px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: ${({ isActive, mainColor }) => (isActive ? mainColor : 'var(--color-black)')};
    background-color: ${({ isOpen, tintMainColor, isActive }) => (isOpen ? isActive ? tintMainColor : 'var(--color-gray-100)' : 'unset')};
    ${pointer}

    &:hover {
        background-color: ${({ isActive, tintMainColor }) => (isActive ? tintMainColor : 'var(--color-gray-100)')};
    }
`

export const SettingText = styled.div`
    font-size: var(--font-size-normal);
    margin-left: 6px;
    white-space: nowrap;
`
