import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { FloatingTree, useFloatingParentNodeId } from '@floating-ui/react'
import React, { Fragment } from 'react'

import type { BasePopoverProps } from '../Popover'
import { Popover } from '../Popover'
import { MenuContextProvider } from './Menu.context'
import { MenuDropdown } from './MenuDropdown'
import { MenuItem } from './MenuItem'
import { MenuTarget } from './MenuTarget'

export interface MenuProps extends BasePopoverProps {
    children: React.ReactNode

    defaultOpened?: boolean
    opened?: boolean
    onChange?: (opened: boolean) => void

    closeOnItemClick?: boolean
    returnFocus?: boolean
}

export const Menu = (props: MenuProps) => {
    const { closeOnItemClick, trigger = 'click', defaultOpened, opened, onChange, onOpen, onClose, ...rest } = props

    const [_opened, _setOpened] = useUncontrolled({ value: opened, onChange, defaultValue: defaultOpened })

    const _onToggle = () => {
        _opened ? _onClose() : _onOpen()
    }

    const _onClose = () => {
        _setOpened(false)

        _opened && onClose?.()
    }

    const _onOpen = () => {
        _setOpened(true)

        _opened && onOpen?.()
    }

    const parentId = useFloatingParentNodeId()

    const Wrapper = parentId ? Fragment : FloatingTree

    return (
        <Wrapper>
            <MenuContextProvider value={{ closeOnItemClick, trigger, opened: _opened, open: _onOpen, close: _onClose, toggle: _onToggle }}>
                <Popover opened={_opened} onChange={_setOpened} onClose={_onClose} trigger={trigger} withinPortal {...rest} />
            </MenuContextProvider>
        </Wrapper>
    )
}

Menu.Target = MenuTarget
Menu.Dropdown = MenuDropdown
Menu.Item = MenuItem
