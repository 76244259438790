import React from 'react'

import { Account } from '@/containers/Account'

interface RegisterProps {}

const Register: React.FunctionComponent<RegisterProps> = () => {
    return <Account defaultMode="register" isFixedMode />
}

export default Register
