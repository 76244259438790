import type { DTFile, FieldADTValue, FieldProtocol, FileValue } from '@lighthouse/core'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import type { EnableActionsParams } from '../../types'
import { FileUploader } from '../FileUploader'
import type { UseUploadFileSParameter } from '../UploadManage'

const SCxDiv = styled.div`
    width: 100%;
`

interface VideoValueEditorProps {
    recordId: string
    field: FieldProtocol
    data?: FileValue
    enableFileDownload?: boolean
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    onCellChange?: (recordId: string, data: FieldADTValue) => Promise<boolean>
}

export const VideoValueEditor: React.FC<VideoValueEditorProps> = ({
    recordId,
    data,
    field,
    uploadyOptions,
    enableFileDownload,
    onCellChange
}) => {
    const enableItemActions: EnableActionsParams = { canDownload: enableFileDownload, canPreview: true, canRename: true, canDelete: true }

    const handleChange = useCallback(
        (data: DTFile[]) => {
            onCellChange?.(recordId, { ...field, type: 'video', value: data })
        },
        [field, onCellChange, recordId]
    )

    return (
        <SCxDiv>
            <FileUploader
                disableDownload
                accept="video/*"
                isPexels={false}
                isUsedLink={false}
                uploadyOptions={uploadyOptions}
                enableItemActions={enableItemActions}
                onChange={handleChange}
                value={data}
            />
        </SCxDiv>
    )
}
