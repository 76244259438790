import { backgroundTransitionOnClick, IconFont } from '@byecode/ui'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import cls from 'classnames'
import type { ReactNode } from 'react'
import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { COLORS_MAP, LIGHT_COLORS_MAP } from '../../constants/color'
import type { ListItemData } from './types'

export type ListItemProps<T> = {
    dataKey: string
    active?: boolean
    hovered?: boolean
    data: T
    itemStyle?: React.CSSProperties
    index: number
    showDragHandler?: boolean
    sortable?: boolean
    disabled?: boolean
    itemDraggerStyle?: React.CSSProperties
    itemRender?: (item: T, index: number) => ReactNode
    onClick?: (itemData: string | number) => void
} & Omit<React.DOMAttributes<HTMLDivElement>, 'onClick'>

export const ListItemWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 6px 8px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    user-select: none;
    ${backgroundTransitionOnClick}

    &.hovered {
        background-color: var(--color-gray-100);
    }

    &.sorting {
        z-index: 2;
    }
`

const DraggerWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`

export const Dragger = styled(IconFont)`
    margin-right: 6px;
    font-size: 16px;
    color: var(--color-gray-500);
    cursor: grab;
`

export const ListItemContent = styled.div`
    height: 24px;
    line-height: 22px;
    padding: 0 8px;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 100px;
    border: 1px solid transparent;
`

function ListItem<T extends ListItemData>({
    disabled = false,
    active,
    hovered,
    index,
    dataKey,
    data,
    sortable,
    showDragHandler,
    itemStyle,
    itemDraggerStyle,
    itemRender,
    onClick,
    ...restProps
}: ListItemProps<T>) {
    const listItemRef = useRef<HTMLDivElement | null>()
    const { color, label, value } = data
    const { setNodeRef, activeIndex, attributes, listeners, transform, transition, isSorting } = useSortable({
        id: dataKey,
        disabled
    })

    const sortingStyle = {
        transform: CSS.Transform.toString(transform),
        transition: isSorting ? transition : 'none',
        ...itemStyle
        // opacity: activeIndex === index ? 0 : 1
    }

    const itemContent = useMemo(() => {
        return itemRender ? (
            itemRender(data, index)
        ) : (
            <ListItemContent
                style={{
                    backgroundColor: color && `var(${LIGHT_COLORS_MAP[color]})`,
                    color: color && `var(${COLORS_MAP[color]})`,
                    borderColor: color && 'rgba(0,0,0,0.1)'
                }}
            >
                {label}
            </ListItemContent>
        )
    }, [color, data, index, itemRender, label])

    useEffect(() => {
        if (hovered && listItemRef.current) {
            const { current: currentItemDom } = listItemRef
            const { offsetTop: top, offsetHeight: height } = currentItemDom
            const { parentElement } = currentItemDom
            const scrollerDom = parentElement?.parentElement
            if (!scrollerDom) {
                return
            }

            const { offsetHeight: scrollerHeight } = scrollerDom
            scrollerDom.scrollTo({ top: top + height - scrollerHeight })
            // listItemRef.current?.focus()
        }
    }, [hovered])

    return (
        <ListItemWrapper
            style={sortingStyle}
            className={cls({ sorting: active, hovered })}
            ref={r => {
                setNodeRef(r)
                listItemRef.current = r
            }}
            {...attributes}
            onClick={() => onClick?.(value)}
            {...restProps}
        >
            {sortable && showDragHandler && (
                <DraggerWrapper style={itemDraggerStyle}>
                    <Dragger type="DotsSix" {...listeners} />
                </DraggerWrapper>
            )}
            {itemContent}
        </ListItemWrapper>
    )
}

export default ListItem
