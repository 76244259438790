import type { FieldInputType } from '@lighthouse/core'
import cls from 'classnames'
import React, { useMemo } from 'react'

import { silentInputTypes } from '../../../constants'
import { INPUT_FIELDS } from '../constant'
import { ErrorMessage } from '../ErrorMessage'
import type { FieldInputConfig, FieldInputError } from '../types'
import * as SC from './styles'

interface StyleContentProps {
    children?: React.ReactNode
    inputType: FieldInputType
    isReadOnly?: boolean
    config: FieldInputConfig
    error?: FieldInputError
}

// 输入框的边框背景及error样式
export const StyleContent = React.forwardRef<HTMLDivElement, StyleContentProps>(
    ({ children, inputType, config, isReadOnly, error }, ref) => {
        const { canEdit, showTitle } = config
        // 是否有边框
        const border = useMemo(() => {
            if (config.inputType === 'relativeSelect') {
                const { relativeSelect } = config
                const { showMode } = relativeSelect ?? {}
                return showMode === 'input' || !showMode
            }
            if (config.inputType === 'notes') {
                return Boolean(canEdit)
            }
            return !silentInputTypes.has(inputType)
        }, [canEdit, config, inputType])

        const anchorBorder = useMemo(() => {
            if (border) {
                return config.inputType === 'text' || config.inputType === 'phoneNumber'
            }
            return false
        }, [border, config.inputType])

        const isInput = useMemo(() => {
            return INPUT_FIELDS.includes(config.inputType)
        },[config.inputType])

        const isShowErrorBorder = useMemo(() => {
            const errorNum = error ? Object.keys(error).length : 0
            return border && errorNum > 0
        }, [border, error])

        const defaultBackgroundColor = useMemo(() => {
            return isReadOnly ? 'var(--color-gray-50)' : 'var(--color-white)'
        }, [isReadOnly])

        const errorTooltipProps = useMemo(() => {
            if (error?.required) {
                return { label: error?.required?.message, disabled: border }
            }
            const errorLabel = Object.entries(error ?? {}).find(Boolean)?.[1]?.message ?? ''
            return { label: errorLabel, disabled: !errorLabel }
        }, [error, border])

        return (
            <SC.FieldContentContainer ref={ref} isBoxShadow={border && !anchorBorder}>
                <SC.FieldPreviewerContainer
                    className={cls({ border: border && !anchorBorder, anchorBorder, readonly: isReadOnly, error: isShowErrorBorder, isInput })}
                    background={defaultBackgroundColor}
                    isInput={canEdit && !border}
                >
                    <ErrorMessage {...errorTooltipProps} style={{ top: showTitle ? -55 : -30 }}>
                        {children}
                    </ErrorMessage>
                </SC.FieldPreviewerContainer>
            </SC.FieldContentContainer>
        )
    }
)

export default StyleContent
