import { mergeRefs } from '@byecode/ui/hooks/useMergedRef'
import { useContextPopoverHeight } from '@byecode/ui/hooks/usePopoverHeight'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef, useCallback, useMemo, useState } from 'react'

import { Box } from '../../Box'
import { Button } from '../../Button'
import { Flex } from '../../Flex'
import { IconFont } from '../../IconFont'
import { Input } from '../../Input'
import { usePopoverContext } from '../../Popover'
import { MultiSelectItem } from '../MultiSelectItem/MultiSelectItem'
import type { MultiSelectItemStyleNames } from '../MultiSelectItem/MultiSelectItem.type'
import type { MultiOption, MultiSelectValueType } from '../types'
import { useStyles } from './MultiSelectDropdown.style'

export type MultiSelectDropdownStylesNames = Selectors<typeof useStyles> | MultiSelectItemStyleNames

export interface MultiSelectDropdownProps
    extends StyleComponentProps<MultiSelectDropdownStylesNames>,
        Omit<React.ComponentPropsWithoutRef<'div'>, 'onMultiSelect'> {
    searchable?: boolean
    value?: MultiSelectValueType
    options?: MultiOption[]
    isMulti?: boolean
    optionComponent?: React.FC<Omit<React.ComponentPropsWithRef<'div'>, 'value' | 'children'> & MultiOption>
    onMultiSelect: (value: string[]) => void
    onClose?: () => void
    themeColor?: string
    customComponent?: React.ReactNode
    extra?: React.ReactNode
    hiddenEmpty?: boolean
}

const MAX_HEIGHT = 640

export const MultiSelectDropdown = forwardRef<HTMLDivElement, MultiSelectDropdownProps>((props, ref) => {
    const {
        searchable,
        value: selectValue = [],
        options,
        isMulti = true,
        optionComponent: Item = MultiSelectItem,
        onMultiSelect,
        onClose,
        themeColor,
        extra,
        hiddenEmpty = false,
        className,
        classNames,
        styles,
        unstyled,
        ...rest
    } = props
    const [isAll, setIsAll] = useState(options?.length === selectValue.length)
    const [_value, _setValue] = useState(selectValue)
    const [searchLabel, setSearchLabel] = useState('')

    const { context } = usePopoverContext()
    const [maxHeight, internalRef] = useContextPopoverHeight({ context, initMaxHeight: MAX_HEIGHT })

    const filterOptions = useMemo(() => {
        if (!options) {
            return []
        }

        return options?.filter(item => (typeof item.label === 'string' ? item.label.includes(searchLabel) : true))
    }, [options, searchLabel])

    const handleConfirm = useCallback(() => {
        if (isAll) {
            const ids = filterOptions.map(item => item.value)
            onMultiSelect(ids)
            _setValue(ids)
            onClose?.()
            return
        }
        onMultiSelect(_value)
        onClose?.()
    }, [_value, filterOptions, isAll, onClose, onMultiSelect])

    const handleSelectValue = useCallback(
        (v: string) => {
            if (!isMulti) {
                setIsAll(false)
                if (!_value.includes(v)) {
                    _setValue([v])
                    return
                }
                _setValue([])
                return
            }
            const ids = filterOptions.map(item => item.value)
            if (isAll) {
                const val = ids.filter(id => id !== v)
                _setValue(val)
                setIsAll(false)
                return
            }
            if (!_value.includes(v)) {
                const val = [..._value, v]
                // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
                if (JSON.stringify([...ids].sort()) === JSON.stringify([...val].sort())) {
                    setIsAll(true)
                }
                _setValue(val)
                return
            }
            const newValue = _value.filter(item => item !== v)
            _setValue(newValue)
        },
        [_value, filterOptions, isAll, isMulti]
    )

    const handleAllSelect = useCallback(() => {
        if (isAll) {
            _setValue([])
        }
        setIsAll(!isAll)
    }, [isAll])

    const { classes } = useStyles({}, { name: 'MultiSelectDropdown', classNames, styles, unstyled })

    const selectContent = useMemo(() => {
        return filterOptions.length > 0 ? (
            <Box className={clsx(classes.itemsWrapper)}>
                {isMulti && (
                    <Item
                        className={clsx(className, classes.item)}
                        role="option"
                        styles={styles}
                        themeColor={themeColor}
                        isActive={isAll}
                        onItemChange={() => handleAllSelect()}
                        value="all"
                        label="全选"
                    />
                )}

                {filterOptions.map(item => {
                    const { value, label, children } = item
                    if (children) {
                        return (
                            <Box key={value} className={clsx(classes.groupItem)}>
                                <Box className={clsx(classes?.groupItemTitle)}>{label}</Box>
                                {children.map(option => (
                                    <Item
                                        key={option.value}
                                        className={clsx(className, classes.item)}
                                        role="option"
                                        themeColor={themeColor}
                                        styles={styles}
                                        isActive={_value?.includes(option.value) || isAll}
                                        onItemChange={handleSelectValue}
                                        {...option}
                                    />
                                ))}
                            </Box>
                        )
                    }
                    return (
                        <Item
                            key={value}
                            className={clsx(className, classes.item)}
                            role="option"
                            themeColor={themeColor}
                            styles={styles}
                            isActive={_value?.includes(value) || isAll}
                            onItemChange={handleSelectValue}
                            {...item}
                        />
                    )
                })}
            </Box>
        ) : (
            !hiddenEmpty && <Box className={clsx(classes.notFound)}>暂无数据</Box>
        )
    }, [
        filterOptions,
        classes.itemsWrapper,
        classes.item,
        classes.notFound,
        classes.groupItem,
        classes?.groupItemTitle,
        isMulti,
        Item,
        className,
        styles,
        themeColor,
        isAll,
        hiddenEmpty,
        handleAllSelect,
        _value,
        handleSelectValue
    ])

    return (
        <Box ref={mergeRefs(ref, internalRef)} className={clsx(classes.selectDropdown, className)} style={{ maxHeight }} {...rest}>
            {searchable && (
                <Box className={clsx(classes.searchWrapper)}>
                    <Input
                        onChange={e => setSearchLabel(e.target.value)}
                        className={clsx(classes.searchInput)}
                        prefix={<IconFont type="Search" />}
                        placeholder="搜索"
                    />
                </Box>
            )}
            {selectContent}
            {extra && <Box className={clsx(classes.dropdownExtra)}>{extra}</Box>}
            <Box className={clsx(classes.dropdownFooter)}>
                <Flex justifyContent="flex-end" alignItems="center" gap="12px">
                    <Button type="default" style={{ flex: 1 }} onClick={onClose}>
                        取消
                    </Button>
                    <Button type="primary" style={{ flex: 1, background: themeColor }} onClick={handleConfirm}>
                        确定
                    </Button>
                </Flex>
            </Box>
        </Box>
    )
})
