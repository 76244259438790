import { IconFont } from '@byecode/ui'
import styled from 'styled-components'

import { NumberInput } from '../NumberInput'

export const CodeInput = styled(NumberInput)<{ error?: boolean }>`
    width: 100%;
    padding: 8px 7px;
    height: 36px;
    line-height: 20px;
    font-size: var(--font-size-normal);
    box-shadow: 0 0 0 ${({ error }) => (error ? '2px var(--color-red-500)' : '1px var(--color-gray-200)')};
    border-radius: 8px;
    background-color: var(--color-white);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none !important;
        margin: 0;
    }

    ::placeholder {
        font-weight: var(--font-weight-normal);
        color: var(--color-gray-300);
    }
    :focus-within {
        box-shadow: 0 0 0 2px ${({ error }) => (error ? ' var(--color-red-500)' : 'var(--color-app-main)')};
    }
`
export const AccountInput = styled.input<{ error?: boolean }>`
    width: 100%;
    padding: 8px 7px;
    height: 36px;
    line-height: 20px;
    font-size: var(--font-size-normal);
    box-shadow: 0 0 0 ${({ error }) => (error ? '2px var(--color-red-500)' : '1px var(--color-gray-200)')};
    border-radius: 8px;
    background-color: var(--color-white);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none !important;
        margin: 0;
    }

    ::placeholder {
        font-weight: var(--font-weight-normal);
        color: var(--color-gray-300);
    }
    :focus-within {
        box-shadow: 0 0 0 2px ${({ error }) => (error ? ' var(--color-red-500)' : 'var(--color-app-main)')};
    }
`
export const CodeInputBox = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    gap: 8px;
    height: 36px;
`

export const Item = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 3px 6px 6px 6px;
`

export const ItemTitle = styled.div`
    color: var(--color-black);
    font-size: var(--font-size-normal);
    line-height: 24px;
`

export const Icon = styled(IconFont)``

export const Container = styled.div`
    padding: 50px 40px;
    max-height: 100%;
    width: 100%;
    background-color: #fff;
`

export const FormContainer = styled.form``
