import { getAssetUrl } from '@lighthouse/assets'
import type { Brand, SpaceVersion } from '@lighthouse/core'
import { spaceByeCodeVersion, spaceIClickVersion } from '@lighthouse/core'

interface Info {
    title: string
    webTagTitle: string
    space: {
        saleImage: string
        helpBuildUrl: string
        resolvedUrl: string
        gradeVersions: Readonly<SpaceVersion[]>
    }
    app: {
        isFilings: boolean
        domainSuffix: string
        isAdverting: boolean
    }
    account: {
        keyword: string
        backgroundImage: string
        privacyAgreement: string
        termsOfService: string
        titleStyleColor: React.CSSProperties
    }
    builderLogo: string
    themeColor: 'orange' | 'purple'
    isLogo: boolean
    isSurvey: boolean
}

export const BRAND_INFO_MAP: Record<Brand, Info> = {
    BYE_CODE: {
        title: 'ByeCode',
        webTagTitle: 'ByeCode',
        space: {
            saleImage: getAssetUrl('iclick', 'wx_sale.png'),
            helpBuildUrl: 'https://www.byecode.com/P/31d787f432af4e52a0481f3186472a6e',
            resolvedUrl: 'https://www.byecode.com/P/daf5585e781848e5b32890304ff1b2cb/a/e6406c8c749745eda2d2bc35330d0487',
            gradeVersions: spaceByeCodeVersion
        },
        app: {
            isFilings: true,
            domainSuffix: 'byecode.site',
            isAdverting: true
        },
        themeColor: 'purple',
        isLogo: true,
        account: {
            keyword: 'ByeCode',
            titleStyleColor: {
                backgroundColor: 'linear-gradient(272deg, #2621ff 0.69%, #a2a0ff 94.32%)'
            },
            backgroundImage: getAssetUrl('common', 'account_background_image.jpg'),
            termsOfService: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/roms434gcishenn7?singleDoc#',
            privacyAgreement: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/uqdtvqcyfpb00rhb?singleDoc#'
        },
        builderLogo: getAssetUrl('common', 'byecode-logo.jpg'),
        isSurvey: true
    },
    I_CLICK: {
        title: 'iClick',
        webTagTitle: 'iParllay',
        space: {
            saleImage: getAssetUrl('iclick', 'wx_sale.png'),
            helpBuildUrl: 'https://kt9vvu.byecode.site',
            resolvedUrl: 'https://iparllay2.byecode.site/P/728622b892f34c8aac5c6b9129643dec/a/a10d2602c55847649b021906cf577864',
            gradeVersions: spaceIClickVersion
        },
        app: {
            isFilings: false,
            domainSuffix: 'iclick.byecode.site',
            isAdverting: false
        },
        themeColor: 'orange',
        isLogo: false,
        account: {
            keyword: 'iParllay爱信来',
            backgroundImage: getAssetUrl('iclick', 'account_background_image.jpg'),
            termsOfService: 'https://m.parllay.cn/public/policy',
            privacyAgreement: 'https://m.parllay.cn/public/agreement',
            titleStyleColor: {
                color: 'var(--color-main)',
                background: 'transparent',
                '-webkit-text-fill-color': 'initial'
            } as React.CSSProperties
        },
        builderLogo: getAssetUrl('iclick', 'iClick-logo.png'),
        isSurvey: false
    }
}
