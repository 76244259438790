import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { isAfter, isBefore, lightFormat } from 'date-fns'
import React, { forwardRef, useMemo, useRef, useState } from 'react'

import { Box } from '../../Box'
import type { PanelMode } from '../../Calendar/Calendar.type'
import { Input } from '../../Input'
import type { BaseModalProps } from '../../Modal'
import type { DatePickerProps } from '../DatePicker'
import { MobileDatePickerModal } from './MobileDatePickerModal'

export type MobileDatePickerMode = Extract<PanelMode, 'day' | 'time'> | 'full'

function getRealFormat(format: string, mode: MobileDatePickerMode) {
    const [dateFormat, timeFormat] = format.split(' ')

    if (mode === 'day') {
        return dateFormat
    }

    if (mode === 'time') {
        return timeFormat
    }

    return format
}

export interface MobileDatePickerProps
    extends Omit<DatePickerProps, 'popoverProps' | 'inputRender' | 'mode' | 'defaultMode' | 'onModeChange'> {
    target?: HTMLElement | null | string
    mode?: MobileDatePickerMode
    defaultMode?: MobileDatePickerMode
    inputStyle?: React.CSSProperties
    onClose?: () => void
    onModeChange?: (type: MobileDatePickerMode) => void
    customRenderInputUi?: (date: Date | undefined, inputEle: React.MutableRefObject<HTMLInputElement | null>) => React.ReactNode
    modalProps?: Omit<BaseModalProps, 'onChange' | 'defaultValue' | 'value'>
}

export const MobileDatePicker = forwardRef<HTMLInputElement, MobileDatePickerProps>((props, ref) => {
    const {
        styles,
        style,
        classNames,
        unstyled,
        inputStyle,
        target,

        value,
        defaultValue,
        onChange,
        onClose,
        format = 'yyyy-MM-dd HH:mm:ss',

        // panel control props
        disabled,
        date,
        defaultDate,
        onDateChange,
        panelDate,
        defaultPanelDate,
        onPanelDateChange,
        mode,
        defaultMode,
        onModeChange,

        // panel settings props
        showTime = true,
        disableSecond,
        firstDayOfWeek,
        dayRowsCount,
        HeaderComponent,
        FooterComponent,
        CellComponent,
        onPrevYear,
        onPrevMonth,
        onNextYear,
        onNextMonth,

        minDate,
        maxDate,
        customRenderInputUi,

        modalProps,

        ...inputProps
    } = props

    const [_value, _setValue] = useUncontrolled({
        value,
        defaultValue,
        onChange
    })
    const [_mode, _setMode] = useUncontrolled({
        value: mode,
        defaultValue: defaultMode,
        onChange: onModeChange
    })

    const valueString = useMemo(() => {
        if (!_value) {
            return ''
        }

        return lightFormat(_value, getRealFormat(format, _mode))
    }, [_mode, _value, format])

    const inputType = useMemo<React.HTMLInputTypeAttribute>(() => {
        if (_mode === 'day') {
            return 'date'
        }

        if (_mode === 'time') {
            return 'time'
        }

        return 'date'
    }, [_mode])

    const isFullDateType = _mode === 'full'
    const [open, setOpen] = useState(false)

    const realInputRef = useRef<HTMLInputElement | null>(null)

    const isIOS = /\(i[^;]+;( U;)? CPU.+Mac OS X/.test(navigator.userAgent)

    return (
        <Box style={{ position: 'relative', ...style }}>
            {customRenderInputUi ? (
                customRenderInputUi(_value, realInputRef)
            ) : (
                <Input
                    value={valueString}
                    placeholder="请选择"
                    readOnly
                    disabled={disabled}
                    style={inputStyle}
                    {...inputProps}
                    onClick={
                        isFullDateType
                            ? () => {
                                  setOpen(true)
                              }
                            : () => {
                                  realInputRef.current?.showPicker()
                              }
                    }
                />
            )}

            {isFullDateType ? (
                <MobileDatePickerModal
                    open={open}
                    target={target}
                    onClose={() => {
                        onClose?.()
                        setOpen(false)
                    }}
                    value={_value}
                    onChange={_setValue}
                    onClick={e => e.stopPropagation()}
                    minDate={minDate}
                    maxDate={maxDate}
                    {...modalProps}
                />
            ) : (
                <input
                    ref={realInputRef}
                    type={inputType}
                    min={minDate && lightFormat(minDate, 'yyyy-MM-dd')}
                    max={maxDate && lightFormat(maxDate, 'yyyy-MM-dd')}
                    onFocus={e => {
                        e.target.defaultValue = valueString
                        e.target.value = valueString
                    }}
                    onInput={e => {
                        const target = e.currentTarget
                        const { value } = target

                        if (!value) {
                            _setValue(undefined)
                            return
                        }
                        const fullValue = inputType === 'time' ? `${lightFormat(new Date(), 'yyyy-MM-dd')} ${value}` : `${value} 00:00`
                        if (inputType === 'date') {
                            if (minDate && isBefore(new Date(fullValue), minDate)) {
                                return
                            }

                            if (maxDate && isAfter(new Date(fullValue), maxDate)) {
                                return
                            }
                        }
                        _setValue(new Date(fullValue))
                    }}
                    onBlur={onClose}
                    style={{
                        pointerEvents: isIOS ? 'unset' : 'none',
                        opacity: 0,
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%'
                    }}
                />
            )}
            {/* @todo 暂时这样写，等bui的modal组件完善后替换 */}
            {/* {opened && (
                <FloatingPortal root={target}>
                    <FloatingOverlay
                        style={{ zIndex: 200, backgroundColor: 'rgba(0,0,0,0.6)' }}
                        onPointerDown={() => setOpened(false)}
                        lockScroll
                    />
                    <div
                        style={{
                            position: 'fixed',
                            zIndex: 200,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: '#fff',
                            borderRadius: '4px 4px 0px 0px'
                        }}
                    >
                        <MobileDatePickerView
                            value={_value}
                            showTime={showTime}
                            disableSecond={disableSecond}
                            onConfirm={_setValue}
                            onCancel={() => setOpened(false)}
                        />
                    </div>
                </FloatingPortal>
            )} */}
        </Box>
    )
})
