import mitt from 'mitt'
import type { FC, PropsWithChildren } from 'react'
import React, { useEffect, useState } from 'react'

import type { BaseModalProps } from './base'
import type { DesktopModal } from './desktop'
import type { MobileModal } from './mobile'
import type { ModalProps } from './Modal'
import { Modal } from './Modal'

type ModalComponent = typeof Modal | typeof DesktopModal | typeof MobileModal

type OpenModalProps<C extends ModalComponent> = C extends typeof Modal ? ModalProps : BaseModalProps

type OpenModalParams<C extends ModalComponent = typeof Modal> = Omit<OpenModalProps<C>, 'open'> & { id: string; component?: C }

type EmitterParams<C extends ModalComponent = typeof Modal> = {
    openModal: OpenModalParams<C>
    closeModal: string
}

const emitter = mitt<EmitterParams>()

export const openModal = <C extends ModalComponent = typeof Modal>(cfg: OpenModalParams<C>) => {
    emitter.emit('openModal', cfg as OpenModalParams)
}

export const closeModal = (id: string) => {
    emitter.emit('closeModal', id)
}

class ModalManager {
    #modals: string[]

    constructor() {
        this.#modals = []
    }

    add(modal: string) {
        this.#modals.push(modal)
    }

    remove(modal: string) {
        const i = this.#modals.indexOf(modal)
        if (i === -1) {
            return
        }
        return this.#modals.splice(i)
    }

    isTop(modal: string) {
        return this.#modals.length > 0 && this.#modals[this.#modals.length - 1] === modal
    }

    get modals() {
        return this.#modals
    }
}

export const modalManager = new ModalManager()

export const ModalsProvider: FC<PropsWithChildren> = ({ children }) => {
    const [modals, setModals] = useState<OpenModalParams[]>([])

    useEffect(() => {
        const openModalHandler = (cfg: EmitterParams['openModal']) => {
            setModals(s => [...s, cfg])
        }

        const closeModalHandler = (id: string) => {
            setModals(s => {
                const i = s.findIndex(item => item.id === id)
                if (i === -1) {
                    return s
                }

                return [...s.slice(0, i), ...s.slice(i + 1)]
            })
        }

        emitter.on('openModal', openModalHandler)
        emitter.on('closeModal', closeModalHandler)

        return () => {
            emitter.off('openModal', openModalHandler)
            emitter.off('closeModal', closeModalHandler)
        }
    }, [])

    return (
        <>
            {modals.map(({ id, component: Component = Modal, ...props }) => (
                <Component
                    key={id}
                    open
                    onClose={() => {
                        setModals(s => {
                            const i = s.findIndex(item => item.id === id)
                            if (i === -1) {
                                return s
                            }

                            return [...s.slice(0, i), ...s.slice(i + 1)]
                        })
                        props.onClose?.()
                    }}
                    {...props}
                />
            ))}
            {children}
        </>
    )
}

ModalsProvider.displayName = 'ByecodeModalsProvider'
