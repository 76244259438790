import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import { DataQuoteDatasourceComponent, DataQuoteFieldComponent } from './Component'

interface DataQuoteOptions {}

export const DataQuoteField = Node.create<DataQuoteOptions>({
    name: 'field',
    group: 'inline',
    type: 'field',
    inline: true,
    parseHTML() {
        return [{ tag: 'Field' }]
    },
    renderHTML({ HTMLAttributes }) {
        return ['Field', mergeAttributes(HTMLAttributes)]
    },
    addAttributes() {
        return {
            id: {
                default: null,
                parseHTML: element => element.dataset.id,
                renderHTML: attributes => {
                    if (!attributes.id) {
                        return {}
                    }

                    return {
                        'data-id': attributes.id
                    }
                }
            },
            name: {
                default: null,
                parseHTML: element => element.dataset.name,
                renderHTML: attributes => {
                    if (!attributes.name) {
                        return {}
                    }

                    return {
                        'data-name': attributes.name
                    }
                }
            },
            icon: {
                default: null,
                parseHTML: element => element.dataset.icon,
                renderHTML: attributes => {
                    if (!attributes.icon) {
                        return {}
                    }

                    return {
                        'data-icon': attributes.icon
                    }
                }
            },
            extra: {
                default: null,
                parseHTML: element => element.dataset.extra,
                renderHTML: attributes => {
                    if (!attributes.extra) {
                        return {}
                    }

                    return {
                        'data-extra': attributes.extra
                    }
                }
            },
            dataType: {
                default: null,
                parseHTML: element => element.dataset.dataType,
                renderHTML: attributes => {
                    if (!attributes.dataType) {
                        return {}
                    }

                    return {
                        'data-type': attributes.dataType
                    }
                }
            }
        }
    },
    addNodeView() {
        return ReactNodeViewRenderer(DataQuoteFieldComponent)
    }
})

export const DataQuoteDatasource = Node.create<DataQuoteOptions>({
    name: 'datasource',
    group: 'inline',
    type: 'datasource',
    inline: true,
    parseHTML() {
        return [{ tag: 'Datasource' }]
    },
    renderHTML({ HTMLAttributes }) {
        return ['Datasource', mergeAttributes(HTMLAttributes)]
    },
    addAttributes() {
        return {
            id: {
                default: null,
                parseHTML: element => element.dataset.id,
                renderHTML: attributes => {
                    if (!attributes.id) {
                        return {}
                    }

                    return {
                        'data-id': attributes.id
                    }
                }
            },
            name: {
                default: null,
                parseHTML: element => element.dataset.name,
                renderHTML: attributes => {
                    if (!attributes.name) {
                        return {}
                    }

                    return {
                        'data-name': attributes.name
                    }
                }
            },
            dataType: {
                default: null,
                parseHTML: element => element.dataset.dataType,
                renderHTML: attributes => {
                    if (!attributes.dataType) {
                        return {}
                    }

                    return {
                        'data-type': attributes.dataType
                    }
                }
            }
        }
    },
    addNodeView() {
        return ReactNodeViewRenderer(DataQuoteDatasourceComponent)
    }
})
