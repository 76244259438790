import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import { animated, useSpring } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import React, { forwardRef, useEffect, useRef } from 'react'
import { useLatest } from 'react-use'

import { Box } from '../../Box'
import { useStyles } from './Column.style'

const minmax = (v: number, min: number, max: number) => {
    return Math.max(Math.min(v, max), min)
}
const snap = (v: number, snapSize: number) => {
    const remain = v % snapSize
    if (remain >= snapSize / 2) {
        return v + snapSize - remain
    }
    return v - remain
}
const ITEM_HEIGHT = 44

export type PickerColumnStylesName = Selectors<typeof useStyles>

export interface ColumnProps extends StyleComponentProps<PickerColumnStylesName> {
    data: React.Key[]
    index?: number
    defaultIndex?: number
    onIndexChange?: (index: number) => void
}

export const Column = forwardRef<HTMLDivElement, ColumnProps>((props, ref) => {
    const { data, index, defaultIndex, onIndexChange, classNames, styles, unstyled, ...rest } = props

    const { classes } = useStyles({}, { name: 'm-DatePicker', classNames, styles, unstyled })

    const [springStyle, api] = useSpring(() => ({ y: 0 }))

    const [_index, _setIndex] = useUncontrolled({
        value: index,
        defaultValue: defaultIndex ?? 0,
        onChange: onIndexChange
    })

    useEffect(() => {
        if (springStyle.y.get() !== _index * ITEM_HEIGHT) {
            api.start({ y: _index * -ITEM_HEIGHT, immediate: true })
        }
    }, [_index, api, springStyle.y])

    const scrollRef = useRef<HTMLDivElement | null>(null)

    const moveHandler = (offsetY: number, last: boolean) => {
        const scrollEl = scrollRef.current
        if (!scrollEl) {
            return
        }

        // const scrollRect = scrollEl.getBoundingClientRect()

        const maxOffset = 0
        // const minOffset = -scrollRect.height + ITEM_HEIGHT * 5 - data.length / 12 * ITEM_HEIGHT
        const minOffset = -(data.length - 1) * ITEM_HEIGHT

        const y = snap(Math.floor(minmax(offsetY, minOffset, maxOffset)), ITEM_HEIGHT)
        if (last) {
            _setIndex(Math.abs(y / ITEM_HEIGHT))
        }
        api.start({ y })
    }

    useGesture(
        {
            onDrag: state => {
                if (state.tap) {
                    return
                }
                moveHandler(state.offset[1], state.last)
            },
            onWheel: state => {
                moveHandler(state.offset[1], state.last)
            }
        },
        {
            target: scrollRef,
            eventOptions: { passive: false },
            drag: {
                axis: 'y',
                from: () => [0, springStyle.y.get()],
                filterTaps: true,
                pointer: {
                    touch: true,
                    mouse: true
                },
                preventDefault: true
            },
            wheel: {
                axis: 'y',
                from: () => [0, springStyle.y.get()],
                preventDefault: true
            }
        }
    )

    return (
        <Box className={classes.column} ref={ref} {...rest}>
            <animated.div style={{ ...springStyle, paddingTop: ITEM_HEIGHT * 2, paddingBottom: ITEM_HEIGHT * 2 }} ref={scrollRef}>
                {data.map(item => (
                    <Box key={item} className={classes.columnItem} style={{ height: ITEM_HEIGHT }}>
                        {item}
                    </Box>
                ))}
            </animated.div>
        </Box>
    )
})
