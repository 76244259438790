import { Empty, IconFont } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import { Tabs } from '@mantine/core'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import type { MessageEvents, MessageListData } from '../../types'
import { NotificationDetailContent } from './NotificationDetailContent'
import { NotificationLeftPanelList } from './NotificationLeftPanelList'

interface NotificationDetailProps {
    messageId?: string
    appId?: string
    data?: MessageListData[]
    auditStatus?: 'agree' | 'reject'
    device?: 'mobile' | 'desktop'
    messageEvents?: MessageEvents
    onClose?: () => void
}

const SCxNotificationDetailWrapper = styled.div`
    display: flex;
    height: 100%;
`

const SCxNotificationListWrapper = styled.div`
    height: 100%;
    flex-shrink: 0;
    border-right: 1px solid var(--color-gray-100);
`

const SCxNotificationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    padding: 14px 16px;
    border-bottom: 1px solid var(--color-gray-100);
`

const SCxNotificationHeaderTitle = styled.div`
    font-weight: var(--font-weight-bold);
    color: var(--color-black);
`

const SCxNotificationHeaderActionWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    top: 15px;
    right: 15px;
`

const SCxIconButton = styled(IconFont)`
    color: var(--color-gray-500);
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const NotificationDetail: React.FC<NotificationDetailProps> = ({
    messageId,
    appId,
    auditStatus,
    device,
    data,
    onClose,
    messageEvents
}) => {
    const isMobile = device === 'mobile'
    const initialIndex = data?.findIndex(item => item.id === messageId)
    const [currentMessageIndex, setCurrentMessageIndex] = useState(Math.max(initialIndex ?? 0, 0))
    const currentMessage = useMemo(() => data?.[currentMessageIndex], [currentMessageIndex, data])

    return (
        <SCxNotificationDetailWrapper>
            <SCxNotificationHeaderActionWrapper>
                <SCxIconButton type="Close" onClick={onClose} />
            </SCxNotificationHeaderActionWrapper>
            <SCxNotificationListWrapper style={{ width: isMobile ? 180 : 306 }}>
                <SCxNotificationHeader>
                    <SCxNotificationHeaderTitle>消息通知</SCxNotificationHeaderTitle>
                </SCxNotificationHeader>
                <Tabs defaultValue="all" style={{ height: 'calc(100% - 53px)' }} styles={{ tab: { margin: '0 16px' } }}>
                    <Tabs.List style={{ borderBottom: '1px solid var(--color-gray-100)' }}>
                        <Tabs.Tab value="all">全部</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="all" pt="xs" style={{ padding: 0, height: 'calc(100% - 35px)' }}>
                        <NotificationLeftPanelList data={data} activeIndex={currentMessageIndex} onActive={setCurrentMessageIndex} />
                    </Tabs.Panel>
                </Tabs>
            </SCxNotificationListWrapper>
            {!currentMessage?.id && (
                <Empty
                    description="暂无消息"
                    icon={<IconFont type="Bell" size={24} color="var(--color-gray-500)" style={{ marginBottom: 8 }} />}
                />
            )}
            {currentMessage?.id && (
                <NotificationDetailContent
                    appId={appId}
                    isMobile={isMobile}
                    auditStatus={auditStatus}
                    data={currentMessage}
                    onClose={onClose}
                    messageEvents={messageEvents}
                />
            )}
        </SCxNotificationDetailWrapper>
    )
}
