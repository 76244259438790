import type { ApplicationSettingTheme } from '@lighthouse/core'
import chroma from 'chroma-js'
import React, { createContext, useContext, useEffect, useMemo } from 'react'

import { defaultConfig } from './constant'
import type { ApplicationSettingThemeNavBarModeColor } from './type'

interface AppThemeContextProviderProps {
    theme?: ApplicationSettingTheme
    headerStyle?: ApplicationSettingThemeNavBarModeColor
    children?: React.ReactNode
}

export interface AppThemeContextInterface {
    mainColor: string
    tintMainColor: string
    theme: ApplicationSettingTheme
    children?: React.ReactNode
}

const AppThemeContext = createContext<AppThemeContextInterface>({
    mainColor: 'var(--color-main)',
    tintMainColor: 'var(--color-main-tint)',
    theme: defaultConfig.theme
})

export const AppThemeContextProvider = ({ children, theme = defaultConfig.theme }: AppThemeContextProviderProps) => {
    const appThemeValue = useMemo(() => {
        const mainColor = theme.palettes.find(item => item.id === theme.primaryPointer)?.value || ''

        const alpha = mainColor ? chroma(mainColor).alpha() : 1

        const tintMainColor =
            mainColor &&
            chroma(mainColor)
                .alpha(alpha * 0.1)
                .hex()

        const tintMainColor20 =
            mainColor &&
            chroma(mainColor)
                .alpha(alpha * 0.2)
                .hex()

        return { theme, mainColor, tintMainColor, tintMainColor20 }
    }, [theme])

    useEffect(() => {
        document.querySelector('body')?.style.setProperty('--color-app-main', appThemeValue.mainColor)
        document.querySelector('body')?.style.setProperty('--color-app-main-tint', appThemeValue.tintMainColor)
        document.querySelector('body')?.style.setProperty('--color-app-main-tint-20', appThemeValue.tintMainColor20)
    }, [appThemeValue])

    return <AppThemeContext.Provider value={appThemeValue}>{children}</AppThemeContext.Provider>
}

export const useAppThemeContext = () => useContext(AppThemeContext)
