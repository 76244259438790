import type { BoolValue, CheckboxField, FieldADTValue, HighLightConditions } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import type { ChangeEvent } from 'react'
import React, { useCallback, useMemo } from 'react'

import { CheckboxItemPreviewer, CheckboxListPreviewer } from '../FieldValuePreviewer/CheckboxValuePreviewer'
import { CellHighlighter } from '../Highlighter'

interface CheckboxValuePreviewerProps {
    field: CheckboxField
    data?: BoolValue | BoolValue[]
    isWrap?: boolean
    width?: number
    disabled?: boolean
    styleConditions?: HighLightConditions
    onCellChange?: (fieldValue: FieldADTValue) => Promise<boolean>
}

export const CheckboxValuePreviewer: React.FC<CheckboxValuePreviewerProps> = ({
    field,
    data = false,
    width = 160,
    isWrap = true,
    disabled,
    styleConditions,
    onCellChange
}) => {
    const { id, type } = field

    const handleChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
            onCellChange?.({
                ...field,
                value: ev.target.checked
            } as FieldADTValue)
        },
        [field, onCellChange]
    )

    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (Array.isArray(data)) {
            return <CheckboxListPreviewer data={data} width={width} isWrap={isWrap} disabled={disabled} />
        }
        const id = nanoid()
        return <CheckboxItemPreviewer id={id} data={data} readonly={disabled} onChange={handleChange} />
    }, [data, disabled, handleChange, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={id} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
