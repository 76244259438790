import { Enum } from '@lighthouse/tools'
import type { AnyObject } from 'immer/dist/internal'

import type { BlockType } from '../abstracts'
import type { FilterFormType } from './filter'
import type { CreatableProtocol } from './general'

export type BlockId = string
export interface BlockIdentity {
    blockId: string
    appId: string
}

/**
 * 类 Block 对象协议
 */
export type BlockLikeProtocol = CreatableProtocol & {
    id: string
    type: string
}

/**
 * Block 协议
 */
export interface BlockProtocol extends BlockLikeProtocol {
    type: BlockType
    title: string
    content?: unknown
    config?: AnyObject
}

export const BlockSize = Enum('xs', 'sm', 'md', 'lg', 'xl')
export type BlockSize = Enum<typeof BlockSize>

export const DeviceEnum = Enum('mobile', 'desktop')
export type Device = Enum<typeof DeviceEnum>
export interface BlockVisibilityProtocol {
    visibilityFilter?: FilterFormType
    visibilityDevice?: Device[]
}
