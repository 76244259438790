import { type CalendarRenderParameter, Button, Calendar, IconFont, Popover } from '@byecode/ui'
import { addMonths } from 'date-fns'
import React, { memo, useState } from 'react'
import styled, { css } from 'styled-components'

const StyledHeader = styled.div<{ isMobile: boolean }>`
    padding: 23px 20px;
    border-bottom: 1px solid var(--color-gray-200);
    border-right: 1px solid var(--color-gray-200);
    border-top-right-radius: 8px;
    display: flex;
    gap: 32px;
    align-items: center;
    ${({ isMobile }) =>
        isMobile &&
        css`
            justify-content: space-between;
        `}
`

const ControlPanel = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    line-height: 28px;
`

const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    color: var(--color-black);
    > * {
        color: var(--color-black);
    }
`

const ControlButton = styled(Button).withConfig<{ active: boolean }>({ shouldForwardProp: p => p !== 'active' })`
    font-size: 20px;
    &:hover {
        background-color: var(--color-gray-100) !important;
    }

    ${({ active }) =>
        active &&
        css`
            background-color: var(--color-gray-200) !important;
        `}

    &:active {
        background-color: var(--color-gray-200) !important;
    }
`

interface HeaderProps extends CalendarRenderParameter {
    isMobile: boolean
}
export const Header = memo((props: HeaderProps) => {
    const { panelViewDate, onPanelViewDate, isMobile } = props

    const [yearOpened, setYearOpened] = useState(false)
    const [monthOpened, setMonthOpened] = useState(false)

    return (
        <StyledHeader isMobile={isMobile}>
            <ControlPanel>
                <Button
                    size="xs"
                    type="text"
                    icon={<IconFont type="ArrowLeftSmall" />}
                    onClick={() => onPanelViewDate?.(addMonths(panelViewDate, -1))}
                />

                <HeaderTitle>
                    <Popover withinPortal width={280} opened={yearOpened} onChange={setYearOpened}>
                        <Popover.Target>
                            <ControlButton active={yearOpened} size="xs" type="text">
                                {panelViewDate.getFullYear()}
                            </ControlButton>
                        </Popover.Target>
                        <Popover.Dropdown compact style={{ overflow: 'hidden', border: '1px solid var(--color-gray-200)' }}>
                            <Calendar
                                mode="year"
                                panelDate={panelViewDate}
                                onPanelDateChange={onPanelViewDate}
                                onDateChange={() => {
                                    setYearOpened(false)
                                }}
                            />
                        </Popover.Dropdown>
                    </Popover>
                    <span>-</span>
                    <Popover withinPortal width={280} opened={monthOpened} onChange={setMonthOpened}>
                        <Popover.Target>
                            <ControlButton active={monthOpened} size="xs" type="text" style={{ width: 40, justifyContent: 'center' }}>
                                {(panelViewDate.getMonth() + 1).toString().padStart(2, '0')}
                            </ControlButton>
                        </Popover.Target>
                        <Popover.Dropdown compact style={{ overflow: 'hidden', border: '1px solid var(--color-gray-200)' }}>
                            <Calendar
                                mode="month"
                                panelDate={panelViewDate}
                                onPanelDateChange={onPanelViewDate}
                                onDateChange={() => {
                                    setMonthOpened(false)
                                }}
                            />
                        </Popover.Dropdown>
                    </Popover>
                </HeaderTitle>

                <Button
                    size="xs"
                    type="text"
                    icon={<IconFont type="ArrowRightSmall" />}
                    onClick={() => onPanelViewDate?.(addMonths(panelViewDate, 1))}
                />
            </ControlPanel>

            <Button style={{ whiteSpace: 'nowrap' }} size="sm" onClick={() => onPanelViewDate?.(new Date())}>
                今天
            </Button>
        </StyledHeader>
    )
})
