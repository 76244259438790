import { Button, Switch } from '@byecode/ui'
import type { DTPerson, Field, PersonField } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHotkeys } from 'react-hotkeys-hook'

import { InnerTypeMapByFieldType } from '../../constants'
import * as SC from './styles'

interface PersonConfigViewProps {
    children?: React.ReactNode
    config: PersonField
    onOk?: (data: Field) => void
    onCancel: () => void
}

const PersonConfigView: React.FC<PersonConfigViewProps> = ({ config, onOk, onCancel }) => {
    const selectConfig = useMemo(() => config?.person, [config])

    const { control, handleSubmit } = useForm<DTPerson>({
        defaultValues: {
            multiple: selectConfig?.multiple ?? false
        },
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true
    })

    const handleOk = useCallback(
        (person: DTPerson) => {
            onOk?.({ ...config, type: 'person', person, innerType: InnerTypeMapByFieldType['person'] })
        },
        [config, onOk]
    )

    useHotkeys(
        'Enter',
        () => {
            handleSubmit(handleOk)()
        },
        { enableOnTags: ['INPUT'] }
    )

    return (
        <SC.FieldForm>
            <SC.FieldConfigWrapper>
                <SC.FormItemWrapper>
                    <SC.FieldConfigHeader>设置</SC.FieldConfigHeader>
                    <SC.FormItemLimitCell>
                        <SC.FieldConfigLabel>多选</SC.FieldConfigLabel>
                        <Controller
                            control={control}
                            name="multiple"
                            render={({ field: { value, onChange } }) => (
                                <Switch
                                    checked={Boolean(value)}
                                    onChange={ev => {
                                        onChange(ev.target.checked)
                                    }}
                                />
                            )}
                        />
                    </SC.FormItemLimitCell>
                </SC.FormItemWrapper>
            </SC.FieldConfigWrapper>
            <SC.ActionWrapper>
                <Button style={{ marginRight: 8 }} onClick={onCancel}>
                    取消
                </Button>
                <Button type="primary" onClick={handleSubmit(handleOk)}>
                    确定
                </Button>
            </SC.ActionWrapper>
        </SC.FieldForm>
    )
}

export default PersonConfigView
