import type { DropAnimation } from '@dnd-kit/core'
import { defaultDropAnimationSideEffects, DragOverlay as DndDragOverlay } from '@dnd-kit/core'
import type { Field, FieldCellValue } from '@lighthouse/core'
import type { FC } from 'react'
import React from 'react'
import { createPortal } from 'react-dom'

import { KanbanBoardColumn } from './Column'
import type { RecordRenderProps } from './SortableRecordItem'
import { RecordItem } from './SortableRecordItem'
import type { SortItemsDataWithKey } from './utils'

const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.5'
            }
        }
    })
}

interface Props extends Omit<RecordRenderProps, 'record'> {
    draggingData: SortItemsDataWithKey | SortItemsDataWithKey[] | undefined

    collapsed?: boolean
    creatable?: boolean
    enableHidden?: boolean
    groupedFieldSchema: Field
    /** 分组字段的field value */
    groupedFieldValue?: FieldCellValue
}

export const DragOverlay: FC<Props> = ({
    draggingData,
    creatable,
    enableHidden,
    groupedFieldSchema,
    groupedFieldValue,
    collapsed,
    ...rest
}) => {
    if (!draggingData) {
        return null
    }
    return createPortal(
        <DndDragOverlay dropAnimation={dropAnimation}>
            {Array.isArray(draggingData)
                ? renderContainerDragOverlay(
                      draggingData,
                      { creatable, enableHidden, groupedFieldValue, groupedFieldSchema, ...rest },
                      collapsed
                  )
                : renderSortableItemDragOverlay({ ...rest, record: draggingData })}
        </DndDragOverlay>,
        document.body
    )
}

function renderSortableItemDragOverlay(props: RecordRenderProps) {
    return <RecordItem {...props} />
}

function renderContainerDragOverlay(
    items: SortItemsDataWithKey[],
    {
        creatable,
        enableHidden,
        groupedFieldValue,
        groupedFieldSchema,
        schema,
        ...restRecordProps
    }: {
        creatable?: boolean
        enableHidden?: boolean
        groupedFieldValue?: FieldCellValue
        groupedFieldSchema: Field
    } & Omit<RecordRenderProps, 'record'>,
    collapsed?: boolean
) {
    return (
        <KanbanBoardColumn
            isOverlay
            fieldSchema={groupedFieldSchema}
            groupedFieldValue={groupedFieldValue}
            list={items}
            schema={schema}
            collapsed={collapsed}
            creatable={creatable}
            enableHidden={enableHidden}
            style={{
                height: '100%',
                boxShadow: `var(--box-shadow)`
            }}
            {...restRecordProps}
        />
    )
}
