import { IconFont, Loading, MobileModal } from '@byecode/ui'
import type { DataSourceAbstract, Field, RecordLikeProtocol, RelativeSelectConfig } from '@lighthouse/core'
import { useDebounce } from '@lighthouse/tools'
import { type DrawerProps, Divider, Text } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useUpdateEffect } from 'react-use'

import { ApplicationPreviewEnum } from '../../../../../types'
import { FieldTableMobile } from '../../../../FiledSelectTable'
import type { RelativeFieldParam } from '../../../types'
import FieldList from '../FieldList'
import { SearchInput } from '../SearchInput'
import { useRelativeDatasource } from '../useRelativeDatasource'
import * as SC from './styles'

interface RelativeSelectDrawerProps
    extends Pick<RelativeFieldParam, 'onFetchDataSource' | 'dataSourceList' | 'onLoadMoreData' | 'onOpenPage'> {
    dsId: string
    records?: RecordLikeProtocol[]
    dataSource: DataSourceAbstract | null
    value?: string[]
    isMultiple?: boolean
    title: string
    target?: DrawerProps['target']
    relativeSelectConfig?: RelativeSelectConfig
    opened: boolean
    onChange?: (val: string[]) => void
    onClose: () => void
}

export const RelativeSelectDrawer: React.FunctionComponent<RelativeSelectDrawerProps> = ({
    dsId,
    records,
    dataSource,
    opened,
    value = [],
    title,
    isMultiple,
    target,
    relativeSelectConfig,
    dataSourceList,
    onChange,
    onClose,
    onOpenPage,
    onFetchDataSource,
    onLoadMoreData
}) => {
    const { schema, viewOptions } = dataSource ?? {}
    const { pagination, tableProps: fieldProps = [] } = viewOptions ?? {}
    const { currentPage = 1 } = pagination ?? {}
    const { relativeSelect } = relativeSelectConfig ?? {}
    const { canCreateRecord, showType = 'table', creatingConfig } = relativeSelect ?? {}

    const { label: addText } = creatingConfig ?? {}

    const {
        state: { loading, noNexData, searchRecordList, keywords, initLoading },
        handleFetchData,
        handleChangeSearch,
        handleLoadMoreData,
        handleChangeInitLoading
    } = useRelativeDatasource({ onFetchDataSource, onLoadMoreData, dsId })

    const containerRef = useRef<HTMLDivElement | null>(null)

    const { ref: scrollRef, height } = useElementSize<HTMLDivElement>()

    const columns = useMemo(() => {
        if (!dataSource) {
            return []
        }
        const { schema } = dataSource
        if (fieldProps && fieldProps.length > 0) {
            return fieldProps.reduce<Field[]>((cols, col) => {
                if (schema[col.id] && col.visible) {
                    cols.push(schema[col.id])
                }
                return cols
            }, [])
        }
        return Object.entries(schema).reduce<Field[]>((cols, [key, col]) => {
            cols.push({ ...col })
            return cols
        }, [])
    }, [dataSource, fieldProps])

    const debouncedKeywords = useDebounce(keywords, 500)

    useUpdateEffect(() => {
        opened && handleFetchData(debouncedKeywords)
    }, [debouncedKeywords, opened])

    const handleScrollPositionChange = useCallback(
        (position: { x: number; y: number }) => {
            const { y } = position
            if (!scrollRef.current) {
                return
            }
            const { clientHeight, scrollHeight } = scrollRef.current
            const isBottom = y + clientHeight + 100 > scrollHeight

            if (isBottom && !loading && !noNexData) {
                handleLoadMoreData(currentPage + 1)
            }
        },
        [currentPage, handleLoadMoreData, loading, noNexData, scrollRef]
    )

    const invokeChange = useCallback(
        (val: string[]) => {
            onChange?.(val)
        },
        [onChange]
    )

    const handleItemClick = useCallback(
        (outputValue: string) => {
            const isExists = value.includes(outputValue)
            let newValue: string[] = []
            if (isMultiple) {
                newValue = isExists ? value.filter(item => item !== outputValue) : [...value, outputValue]
                invokeChange(newValue)
                return
            }
            newValue = isExists ? [] : [outputValue]
            invokeChange(newValue)
            onClose()
        },
        [value, isMultiple, invokeChange, onClose]
    )

    useEffect(() => {
        if (opened) {
            handleChangeInitLoading(true)
            handleFetchData?.()
        }
        handleChangeSearch('')
    }, [handleChangeInitLoading, handleChangeSearch, handleFetchData, opened])

    return (
        <MobileModal
            data-ignore-click-away
            target={target}
            title={title}
            open={opened}
            styles={{
                modal: {
                    height: '85%'
                },
                body: {
                    paddingBottom: canCreateRecord ? '0px!important' : undefined,
                    backgroundColor:  showType === 'list' ? 'var(--color-gray-50)' : '#fff'
                }
            }}
            onClose={onClose}
        >
            <SC.RecordSelector ref={containerRef}>
                <SearchInput
                    value={keywords}
                    autoFocus={false}
                    showType="list"
                    style={{ backgroundColor: 'transparent', borderBottom: 'none' }}
                    onChange={handleChangeSearch}
                />
                {initLoading ? (
                    <Loading />
                ) : showType === 'table' ? (
                    <FieldTableMobile
                        ref={scrollRef}
                        value={value}
                        columns={columns}
                        loading={loading}
                        primaryField="ID"
                        previewType={ApplicationPreviewEnum.mobile}
                        recordData={searchRecordList}
                        dataSourceList={dataSourceList || []}
                        onRowClick={handleItemClick}
                        onScrollPositionChange={handleScrollPositionChange}
                    />
                ) : (
                    <FieldList
                        options={searchRecordList}
                        value={value}
                        ref={scrollRef}
                        isDriver
                        dataSource={dataSource}
                        onScrollPositionChange={handleScrollPositionChange}
                        onChange={handleItemClick}
                        style={{
                            border: '1px solid var(--color-gray-200)',
                            background: '#fff',
                            borderRadius: 8
                        }}
                    />
                )}
                {canCreateRecord && (
                    <>
                        <Divider color="var(--color-gray-200)" />
                        <SC.Add
                            justifyContent="center"
                            onClick={() => {
                                onClose()
                                onOpenPage?.(creatingConfig)
                            }}
                        >
                            <IconFont type="Add" color="var(--color-app-main)" size={16} />
                            <Text color="var(--color-app-main)" size={14}>
                                {addText}
                            </Text>
                        </SC.Add>
                    </>
                )}
            </SC.RecordSelector>
        </MobileModal>
    )
}
