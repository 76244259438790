import { backgroundTransitionOnClick, DatePicker, IconFont, Input, pointer, Select } from '@byecode/ui'
import { Divider } from '@mantine/core'
import styled from 'styled-components'

import { MultiSelect } from '../MultiSelect'

export const Box = styled.div`
    display: flex;
    flex-flow: row nowrap;
    flex: 1;
    min-width: 160px;
`

export const ParamsSelect = styled(Select) <{ focusOutLine?: string }>`
    width: 100%;
    height: 32px;
`

// export const ParamsCascadeSelect = styled(CascadeSelect) <{ focusOutLine?: string }>`
//     width: 100%;
//     height: 32px;
//     background-color: #fff;
//     border-radius: 6px;
//     border: 1px solid var(--color-gray-200);

//     &:hover {
//         border-color: ${({ focusOutLine }) => focusOutLine ?? 'var(--color-main)'};
//     }
// `

export const ParamsMultiSelect = styled(MultiSelect) <{ focusOutLine?: string }>`
    width: 100%;
    height: 32px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid var(--color-gray-200);

    &:hover {
        border-color: ${({ focusOutLine }) => focusOutLine ?? 'var(--color-main)'};
    }
`

export const ParamsDatePicker = styled(DatePicker) <{ theme?: string }>`
    height: 32px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #dfdfdd;
    padding-left: 0;

    &:hover {
        border-color: ${({ theme }) => theme ?? 'var(--color-main)'};
    }
`

export const ParamsInput = styled(Input) <{ width?: string }>`
    width: ${({ width }) => width || 'auto'};
    height: 32px;
`

export const ParamsCustomInput = styled.div<{ width?: string; focusOutLine?: string }>`
    position: relative;
    width: ${({ width }) => width || 'auto'};
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #dfdfdd;
    padding-right: 24px;
    overflow: hidden;
    border-radius: 4px;

    &:hover {
        border-color: ${({ focusOutLine }) => focusOutLine || 'var(--color-main)'};
    }

    &:focus-within {
        border-color: ${({ focusOutLine }) => `${focusOutLine}` || 'var(--color-main)'};
    }
`
export const MorePopoverWrapper = styled.div`
    display: flex;
    width: 30px;
    height: 30px;
    line-height: 31px;
    border-radius: 4px;
    justify-content: center;

    ${pointer}
`

export const CustomInputPopoverWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 4px;
    display: flex;
    width: 16px;
    justify-content: center;
    align-items: center;

    ${pointer}
`

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const List = styled.div`
    width: 160px;
`

export const Item = styled.div<{ disabled?: boolean }>`
    padding: 6px 16px;
    font-size: var(--font-size-normal);
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    ${backgroundTransitionOnClick}
`

export const Horizontal = styled(Divider)`
    margin: 8px 0;
`

export const Appoint = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 8px;
`

export const AppointText = styled.div`
    padding-left: 6px;
`

export const AppointIcon = styled(IconFont)``

export const Describe = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    padding-left: 24px;
`

export const SelectItemWrapper = styled.div``
