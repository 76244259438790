import type { BatchItem } from "@rpldy/shared"

import { getFileTypeByUrl } from "./helper"

export const getBatchToInnerFile = (data: BatchItem, status = 'uploading') => {
    const { file, id, completed } = data
    const { name, size } = file
    const fileType = getFileTypeByUrl(name)
    return {
        uid: id,
        name,
        label: name,
        value: `${name}-${status}`,
        type: fileType,
        status,
        url: '',
        size,
        percent: completed
    }
}
