import type { AiFieldStatus, DataSourceAbstract, ExcludesEnvRecordLikeProtocol, RecordLikeProtocol } from '@lighthouse/core'
import { DEPARTMENT_DATASOURCE, getMainTableRecordId, ROLE_DATASOURCE, USER_DATASOURCE } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import isDeepEqual from 'fast-deep-equal'
import { current } from 'immer'
import { atom } from 'jotai'
import { findIndex, mergeDeepRight } from 'rambda'

import * as srv from '@/services'

import { currentAppIdAtom, currentEnvIdAtom } from '../application/state'
import type { DeleteViewRecordAtomPayload } from '../blockRecordsDict/types'
import { aiFieldStatusListAtom, dataSourceAtomFamily, dataSourcePoolAtom, recordAtomFamily, recordPoolAtom } from './state'
import type {
    AddDataSourceAtomPayload,
    AddDataSourcesAtomPayload,
    AddRecordAtomPayload,
    CreateRecordAtomPayload,
    DeleteAiFieldStatusAtomPayload,
    DeleteRecordAtomPayload,
    FetchDataSourceListAtomPayload,
    SetDataSourceAtomPayload,
    SetRecordAtomPayload,
    SetRecordsAtomPayload,
    UpdateCelAtomOptions,
    UpdateCellAtomPayload
} from './types'

/** 设置数据源数据 */
export const setDataSourceAtom = atom(null, (get, set, payload: SetDataSourceAtomPayload) => {
    const envId = get(currentEnvIdAtom)
    const { appId, dsId, dataSource } = payload
    const dataSourceAtom = dataSourceAtomFamily({ appId, envId, dsId })
    set(dataSourceAtom, dataSource)
})

/** 添加单个数据源数据 */
export const addDataSourceAtom = atom(null, (_, set, payload: AddDataSourceAtomPayload) => {
    const { dataSource } = payload
    set(dataSourcePoolAtom, draft => {
        const { id, appId } = dataSource
        const index = draft.findIndex(item => item.id === id && item.appId === appId)
        if (index >= 0) {
            const originDataSource = current(draft[index])
            const nextDs = mergeDeepRight<DataSourceAbstract>(originDataSource, dataSource)
            if (isDeepEqual(originDataSource, nextDs)) {
                return
            }
            draft[index] = nextDs
        } else {
            draft.push(dataSource)
        }
    })
})

/** 添加多个数据源数据 */
export const addDataSourcesAtom = atom(null, (_, set, payload: AddDataSourcesAtomPayload) => {
    const { dataSources } = payload
    set(dataSourcePoolAtom, draft => {
        dataSources.forEach(dataSource => {
            const { id, appId } = dataSource
            const index = draft.findIndex(item => item.id === id && item.appId === appId)
            if (index >= 0) {
                const originDataSource = current(draft[index])
                const nextDs = mergeDeepRight<DataSourceAbstract>(originDataSource, dataSource)
                if (isDeepEqual(originDataSource, nextDs)) {
                    return
                }
                draft[index] = nextDs
            } else {
                draft.push(dataSource)
            }
        })
    })
})

/** 设置记录数据 */
export const setRecordAtom = atom(null, (_, set, payload: SetRecordAtomPayload) => {
    const { appId, envId, dsId, recordId, record } = payload
    const recordAtom = recordAtomFamily({ appId, recordId, dsId, envId })
    set(recordAtom, record)
})

/** 设置记录数据 */
export const setRecordsAtom = atom(null, (_, set, payload: SetRecordsAtomPayload) => {
    const { records } = payload
    set(recordPoolAtom, records)
})

/** 添加记录数据 */
export const addRecordAtom = atom(null, (get, set, payload: AddRecordAtomPayload) => {
    const { records } = payload
    set(recordPoolAtom, draft => {
        records.forEach(record => {
            const { id, appId, dsId } = record
            const index = draft.findIndex(item => item.id === id && item.appId === appId && item.dsId === dsId)
            if (index >= 0) {
                draft[index] = record
            } else {
                draft.push(record)
            }
        })
    })
})

/** 创建记录数据 */
export const createRecordAtom = atom(null, async (get, set, payload: CreateRecordAtomPayload) => {
    const envId = get(currentEnvIdAtom)
    const { appId, dsId, content, beforeId, pageId } = payload
    const newRecordId = nanoid(32)

    const record = await srv.createRecord({ appId, envId, id: newRecordId, dsId, pageId, content, beforeId })
    set(addRecordAtom, {
        records: [record]
    })

    return record
})

export const updateLocalRecordAtom = atom(null, (get, set, payload: RecordLikeProtocol) => {
    const record = findIndex(item => item.id === payload.id, get(recordPoolAtom))
    set(recordPoolAtom, pool => {
        if (record >= 0) {
            pool[record] = payload
        } else {
            pool.push(payload)
        }
    })
})

/** 更新一条记录的多个字段 */
export const updateRecordAtom = atom(null, async (_, set, payload: ExcludesEnvRecordLikeProtocol & { pageId: string }) => {
    // 解决连接表问题
    const { appId, dsId, id, content, pageId } = payload
    const backendFields = Object.keys(content).map(f => ({ fieldId: f, value: content[f].value }))

    const recordId = getMainTableRecordId(id)
    const res = await srv.updateRecord({ dsId, recordId, fields: backendFields, pageId })
    if (res) {
        set(recordPoolAtom, draft => {
            const recordsIndex = draft.reduce<number[]>((prev, cur, index) => {
                if (cur.appId === appId && cur.dsId === dsId && getMainTableRecordId(cur.id) === recordId) {
                    prev.push(index)
                }
                return prev
            }, [])
            if (recordsIndex.length === 0) {
                return
            }
            recordsIndex.forEach(i => {
                Object.keys(content).forEach(k => {
                    draft[i].content[k] = content[k]
                })
            })
        })
    }
    return res
})

/** 删除记录数据 */
export const deleteRecordAtom = atom(null, async (get, set, payload: DeleteRecordAtomPayload) => {
    const envId = get(currentEnvIdAtom)
    const { appId, dsId, recordIds, pageId, mode } = payload
    const dataSourceAtom = dataSourceAtomFamily({ appId, envId, dsId })
    const isDelete = await srv.deleteRecord(appId, dsId, pageId, recordIds, mode)
    if (!isDelete) {
        return false
    }
    set(dataSourceAtom, draft => {
        if (draft?.records) {
            const mainRecordIds = new Set(recordIds.map(id => getMainTableRecordId(id)))
            const records = mode === 'ALL' ? [] : draft.records.filter(id => !mainRecordIds.has(getMainTableRecordId(id)))
            draft.records = records
        }
    })
    set(setRecordsAtom, {
        records: draft => {
            if (mode === 'ALL') {
                for (let i = 0; i < draft.length; i++) {
                    if (draft[i].appId === appId && draft[i].dsId === dsId) {
                        draft.splice(i, 1)
                        i--
                    }
                }
                return
            }

            for (let i = 0; i < draft.length; i++) {
                const isEqualRecordId = recordIds.some(id => getMainTableRecordId(id) === getMainTableRecordId(draft[i].id))
                if (draft[i].appId === appId && isEqualRecordId) {
                    draft.splice(i, 1)
                    i--
                }
            }
        }
    })
    return isDelete
})

/** 批量删除视图记录数据 */
export const deleteViewRecordAtom = atom(null, async (get, set, payload: DeleteViewRecordAtomPayload) => {
    const envId = get(currentEnvIdAtom)
    const { appId, dsId, recordIds, pageId, mode } = payload
    const dataSourceAtom = dataSourceAtomFamily({ appId, envId, dsId })
    let isDeleted = false
    if (mode === 'CURRENT_PAGE' && recordIds && pageId) {
        isDeleted = await srv.deleteRecord(appId, pageId, dsId, recordIds)
    } else {
        isDeleted = await srv.deleteViewRecord(payload)
    }
    set(dataSourceAtom, draft => {
        if (draft?.records) {
            const mainRecordIds = new Set(recordIds.map(id => getMainTableRecordId(id)))
            const records = mode === 'ALL' ? [] : draft.records.filter(id => !mainRecordIds.has(getMainTableRecordId(id)))
            draft.records = records
        }
    })
    set(setRecordsAtom, {
        records: draft => {
            if (mode === 'ALL') {
                for (let i = 0; i < draft.length; i++) {
                    if (draft[i].appId === appId && draft[i].dsId === dsId) {
                        draft.splice(i, 1)
                        i--
                    }
                }
                return
            }

            for (let i = 0; i < draft.length; i++) {
                const isEqualRecordId = recordIds.some(id => getMainTableRecordId(id) === getMainTableRecordId(draft[i].id))
                if (draft[i].appId === appId && isEqualRecordId) {
                    draft.splice(i, 1)
                    i--
                }
            }
        }
    })
    return isDeleted
})

/** 更新单元格 */
export const updateCellAtom = atom(null, (get, set, payload: UpdateCellAtomPayload, options?: UpdateCelAtomOptions) => {
    const envId = get(currentEnvIdAtom)
    const { recordId, appId, dsId, fieldId, value, pageId } = payload
    set(setRecordAtom, {
        appId,
        envId,
        recordId,
        dsId,
        record: draft => {
            if (draft) {
                draft.content[fieldId] = value
            }
        }
    })

    if (options?.local) {
        return Promise.resolve(true)
    }

    return srv.updateCell({
        appId,
        envId,
        dsId,
        id: recordId,
        pageId,
        fieldId,
        content: value
    })
})

/** 获取应用下数据源列表 */
export const fetchDataSourceListAtom = atom(null, async (_, set, payload: FetchDataSourceListAtomPayload) => {
    const { appId } = payload
    const data = await srv.listDs(appId)
    set(addDataSourcesAtom, { dataSources: data })
})

/** 设置ai字段状态 */
export const addAiFieldStatusAtom = atom(null, (_, set, payload: AiFieldStatus) => {
    const { dataSourceId, recordId, fieldId } = payload
    set(aiFieldStatusListAtom, draft => {
        const index = findIndex(item => item.dataSourceId === dataSourceId && item.recordId === recordId && item.fieldId === fieldId, draft)
        if (index >= 0) {
            draft[index] = payload
            return
        }
        draft.push(payload)
    })
})

export const deleteAiFieldStatusAtom = atom(null, (_, set, payload: DeleteAiFieldStatusAtomPayload) => {
    const { dsId, recordId, fieldId } = payload
    set(aiFieldStatusListAtom, draft => {
        const index = findIndex(item => item.dataSourceId === dsId && item.recordId === recordId && item.fieldId === fieldId, draft)
        if (index >= 0) {
            draft.splice(index, 1)
        }
    })
})

export const fetchDataSourceRecordsAtom = atom(null, async (get, set, params: { dsId: string; pageId: string }) => {
    const { dsId, pageId } = params
    const appId = get(currentAppIdAtom)
    const envId = get(currentEnvIdAtom)
    const data = await srv.getDs({
        appId,
        envId,
        dsId,
        pageId,
        pagination: {
            currentPage: 1,
            pageSize: 2000
        }
    })
    if (!data) {
        return
    }
    const { records } = data
    set(addRecordAtom, { records })
    return records
})

export const fetchDataSourceRecordsExcludePageIdAtom = atom(null, async (get, set, params: { dsId: string }) => {
    const { dsId } = params
    const appId = get(currentAppIdAtom)
    const envId = get(currentEnvIdAtom)
    const data = await srv.getSystemDs({
        appId,
        envId,
        dsId,
        pagination: {
            currentPage: 1,
            pageSize: 2000
        }
    })
    if (!data) {
        return
    }
    const { records } = data
    set(addRecordAtom, { records })
    return records
})

/** 获取数据源下用户列表 */
export const fetchDataSourceUserAtom = atom(null, (get, set) => {
    return set(fetchDataSourceRecordsExcludePageIdAtom, { dsId: USER_DATASOURCE })
})
/** 获取数据源下用户组列表 */
export const fetchDataSourceRoleAtom = atom(null, (get, set) => {
    return set(fetchDataSourceRecordsExcludePageIdAtom, { dsId: ROLE_DATASOURCE })
})

/** 获取数据源下部门列表 */
export const fetchDataSourceDepartmentAtom = atom(null, (get, set) => {
    return set(fetchDataSourceRecordsExcludePageIdAtom, { dsId: DEPARTMENT_DATASOURCE })
})

export const fetchSystemDataSourceAtom = atom(null, (get, set) => {
    set(fetchDataSourceRecordsExcludePageIdAtom, { dsId: DEPARTMENT_DATASOURCE })
    set(fetchDataSourceRecordsExcludePageIdAtom, { dsId: ROLE_DATASOURCE })
    set(fetchDataSourceRecordsExcludePageIdAtom, { dsId: USER_DATASOURCE })
})
