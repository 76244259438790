import * as React from 'react'
import styled from 'styled-components'

interface SelectPlaceHolderProps {
    label?: string
    style?: React.CSSProperties
}
const SCxContainer = styled.div`
    width: 100%;
    height: 22px;
    line-height: 22px;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-normal);
    color: var(--color-gray-300);
`

export const SelectPlaceHolder: React.FunctionComponent<SelectPlaceHolderProps> = ({ label = '', style }) => {
    return <SCxContainer style={style}>{label}</SCxContainer>
}
