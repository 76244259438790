import type {
    AiFieldStatus,
    DataSourceAbstract,
    Field,
    FieldADTValue,
    FieldCellValue,
    FieldGroupBlockAbstract,
    RecordLikeProtocol,
    ViewField
} from '@lighthouse/core'
import { FieldStatusPreviewer, hasIconFieldType, isEmptyCellValue, TooltipText } from '@lighthouse/shared'
import { BreakPointSize, useBreakpoint } from '@lighthouse/tools'
import { Divider, Flex, Grid, Text } from '@mantine/core'
import type { atomWithImmer } from 'jotai-immer'
import { min } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

interface FieldGroupBlockProps {
    dsId: string
    blockData: FieldGroupBlockAbstract
    record?: RecordLikeProtocol
    columns: ViewField[]
    dataSource?: DataSourceAbstract
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
}

const SCxContainer = styled.div`
    width: 100%;
    height: auto;
`

const SCxCard = styled(Flex)`
    min-height: 32px;
    padding: 0 4px;
    align-items: baseline;
`

const SCxContent = styled.div<{ disabledPadding?: boolean }>`
    min-height: 32px;
    overflow: hidden;
    max-width: calc(100% - 76px);

    > div {
        padding: ${({ disabledPadding }) => (disabledPadding ? '0px' : '8px 0')};
        min-height: 32px;
    }
`

const SCxGridCol = styled(Grid.Col)`
    padding: 0;
`

const SCxGrid = styled(Grid)`
    margin: var(--block-padding);
    padding: 6px 2px;
`

const SCxText: typeof Text = styled(Text)`
    min-width: 64px;
    word-break: break-word;
    white-space: pre-wrap;
`

const FieldGroupBlock: React.FunctionComponent<FieldGroupBlockProps> = ({
    dsId,
    blockData,
    record,
    columns,
    dataSource,
    aiFieldStatusListAtom,
    onAiGeneration
}) => {
    const { ref, breakPoint, width } = useBreakpoint()

    const { schema } = dataSource ?? {}

    const columnItemNum = useMemo(() => {
        if (width > 1094) {
            return Math.floor(12 / min(Math.round((width - 1094) / 248) + 4, 12))
        }
        switch (breakPoint) {
            case BreakPointSize.md: {
                return 4
            }
            case BreakPointSize.lg: {
                return 3
            }
            default: {
                return 4
            }
        }
    }, [breakPoint, width])

    const currentColumns = useMemo(() => columns.filter(column => column.visible), [columns])

    const handleAiGeneration = useCallback(
        (fieldId: string) => {
            if (onAiGeneration && record) {
                return onAiGeneration?.(record.id, fieldId)
            }
            return Promise.resolve(false)
        },
        [onAiGeneration, record]
    )

    const renderCell = useCallback(
        (field?: Field, value?: FieldCellValue, justifyContent?: 'flex-end') => {
            const isEmptyValue =
                value === undefined ||
                (value &&
                    isEmptyCellValue({
                        value,
                        ...field
                    } as FieldADTValue))
            if (field?.type === 'checkbox') {
                return (
                    <SCxContent>
                        <Flex align="center">{value ? '是' : '否'}</Flex>
                    </SCxContent>
                )
            }

            return (
                <SCxContent>
                    {!field || (isEmptyValue && !hasIconFieldType.has(field.type)) ? (
                        <Flex style={{ color: 'var(--color-gray-400)' }} align="center">
                            -
                        </Flex>
                    ) : (
                        <FieldStatusPreviewer
                            dsId={dsId}
                            recordId={record?.id}
                            field={field}
                            data={value}
                            enableEdit={false}
                            justifyContent={justifyContent}
                            visiblePersonDescribe={false}
                            isWrap
                            aiFieldStatusListAtom={aiFieldStatusListAtom}
                            onAiGeneration={handleAiGeneration}
                        />
                    )}
                </SCxContent>
            )
        },
        [aiFieldStatusListAtom, dsId, handleAiGeneration, record?.id]
    )

    const mobileEle = useMemo(() => {
        return (
            <SCxGrid gutter={16}>
                {currentColumns.map((column, index) => {
                    const field = schema?.[column.fieldId]
                    const cellValue = record?.content?.[column.fieldId]
                    return (
                        <>
                            <SCxGridCol span={12} key={column.fieldId}>
                                <SCxCard direction="row" justify="space-between" gap={12}>
                                    <TooltipText
                                        title={column.title}
                                        render={ref => (
                                            <SCxText
                                                size={14}
                                                ref={ref}
                                                style={{
                                                    padding: '8px 0',
                                                    maxWidth: '50%'
                                                }}
                                                color="var(--color-gray-500)"
                                            >
                                                {column.title}
                                            </SCxText>
                                        )}
                                    />
                                    {renderCell(field, cellValue?.value, 'flex-end')}
                                </SCxCard>
                            </SCxGridCol>
                            {index + 1 !== currentColumns.length && (
                                <Divider style={{ margin: '8px 0', width: '100%' }} color="var(--color-gray-200)" />
                            )}
                        </>
                    )
                })}
            </SCxGrid>
        )
    }, [currentColumns, record?.content, renderCell, schema])

    const ele = useMemo(() => {
        return (
            <SCxGrid gutter={16} style={{ gap: '16px 0' }}>
                {currentColumns.map(column => {
                    const field = schema?.[column.fieldId]
                    const cellValue = record?.content?.[column.fieldId]
                    return (
                        <SCxGridCol span={columnItemNum} key={column.fieldId}>
                            <SCxCard direction="column">
                                <TooltipText
                                    title={column.title}
                                    render={ref => {
                                        return (
                                            <SCxText size={14} lineClamp={1} ref={ref} color="var(--color-gray-500)">
                                                {column.title}
                                            </SCxText>
                                        )
                                    }}
                                />
                                {renderCell(field, cellValue?.value)}
                            </SCxCard>
                        </SCxGridCol>
                    )
                })}
            </SCxGrid>
        )
    }, [columnItemNum, currentColumns, record?.content, renderCell, schema])

    return <SCxContainer ref={ref}>{width < 598 ? mobileEle : ele}</SCxContainer>
}

export default FieldGroupBlock
