import { Toast } from '@byecode/ui'
import { useAtomData } from '@lighthouse/shared'
import { isWechatBrowser } from '@lighthouse/tools'
import mitt from 'mitt'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useAsyncRetry } from 'react-use'

import { appAtom } from '@/atoms/application/state'
import * as srv from '@/services'

type Event = {
    sendWxRoot: void
}

export const emitter = mitt<Event>()

export const sendWxRoot = () => {
    emitter.emit('sendWxRoot')
}

export function useWxAuthRoot(isUsed?: boolean) {
    const isWechat = isWechatBrowser()
    const [search] = useSearchParams()

    const code = search.get('code')
    const state = search.get('state')

    // 检验是否授权
    useAsyncRetry(async () => {
        if (!isUsed) {
            return
        }
        if (!isWechat || state || code) {
            return
        }
        const isPermissionGranted = await srv.checkWxOfficialRoot()
        !isPermissionGranted && sendWxRoot()
    }, [code, isUsed])
}

export const useSubscriptWxRoot = function () {
    const [search] = useSearchParams()
    const code = search.get('code')
    const state = search.get('state')
    const isWechat = isWechatBrowser()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const account = useAtomData(
        appAtom,
        useCallback(s => s?.integrations?.weChatOfficialAccount, [])
    )

    // 发起微信授权
    const handleWxOfficialRoot = useCallback(() => {
        if (!isWechat || !account || loading) {
            return
        }
        setLoading(true)
        const { appId, scope = 'snsapi_userinfo' } = account
        setLoading(false)
        if (appId) {
            search.delete('code')
            search.delete('state')
            const wxRedirectUri = encodeURIComponent(`${window.location.origin}${window.location.pathname}`)
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${wxRedirectUri}&response_type=code&scope=${scope}&state=1&forcePopup=true#wechat_redirect`
        }
    }, [account, isWechat, loading, search])

    // 授权成功后发送code到服务端
    useAsyncRetry(async () => {
        if (!code || state === 'login') {
            return
        }
        const isSuccess = await srv.sendWxOfficialRootCode({ code, accountType: 'WECHAT_OFFICIAL_ACCOUNT' })
        if (isSuccess) {
            Toast.success('授权成功')
            navigate(window.location.pathname, { replace: true })
        }
    }, [])

    useEffect(() => {
        emitter.on('sendWxRoot', handleWxOfficialRoot)

        return () => {
            emitter.off('sendWxRoot', handleWxOfficialRoot)
        }
    }, [handleWxOfficialRoot])
}
