export const VIDEO_RATIO_OPTIONS = [
    {
        label: '1:1',
        value: '1'
    },
    {
        label: '3:1',
        value: '2'
    },

    {
        label: '4:3',
        value: '3'
    },
    {
        label: '16:9',
        value: '4'
    },
    {
        label: '9:16',
        value: '5'
    }
]
