import styled from 'styled-components'

export const LoginWay = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const LoginWayItem = styled.div`
    position: relative;
    height: 44px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--color-gray-200);
    justify-content: center;
    cursor: pointer;

    > .icon {
        position: absolute;
        left: 16px;
    }

    &:hover {
        background-color: var(--color-gray-100);
    }
`
export const LoginWayTitle = styled.div`
    font-size: 16px;
`
