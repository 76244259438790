import type { ChartLikeProtocol, ChartOptionProtocol, IndicatorOption } from '@lighthouse/core'

import type { IndicatorChartOption } from '../../components'

const getIndicatorOption = (params: IndicatorOption, chartData?: ChartLikeProtocol, themeColors?: string[]): IndicatorChartOption => {
    if (!chartData) {
        return {}
    }

    const { label, labelColor = '#101828', showThousandthSplit, showUnit, unit, title, showHeadTitle, showLabel } = params
    const { series } = chartData
    const [{ value }] = series
    const [result] = value

    let resultText = result || '0'
    if (showThousandthSplit) {
        resultText = Number(resultText).toLocaleString()
    }
    if (showUnit) {
        resultText = `${resultText}${unit || ''}`
    }

    return {
        color: [labelColor],
        label,
        title,
        value: resultText,
        showHeadTitle,
        showLabel
    }
}

export default getIndicatorOption
