import type { FC, PropsWithChildren } from 'react'
import React, { createContext, useContext, useMemo } from 'react'

export type PageContainerContextType = {
    endpoint: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
}

export const PageContainerContext = createContext<PageContainerContextType>({
    endpoint: 'xl'
})

type PageContainerProviderProps = PropsWithChildren<PageContainerContextType>

export const PageContainerProvider: FC<PageContainerProviderProps> = props => {
    const { children, endpoint } = props

    const contextValue = useMemo(() => ({ endpoint }), [endpoint])

    return <PageContainerContext.Provider value={contextValue}>{children}</PageContainerContext.Provider>
}

export const usePageContainer = () => useContext(PageContainerContext)
