import * as React from 'react'

import { Account } from '@/containers/Account'

interface BindWechatProps {}

const BindWechat: React.FunctionComponent<BindWechatProps> = props => {
    return <Account defaultMode="bindWechat" isFixedMode />
}

export default BindWechat
