import type { StyleComponentProps } from '@byecode/ui/theme/types'
import React, { forwardRef } from 'react'

import { Box } from '../Box'
import { type LoadingProps, Loading } from '../Loading'
import { useStyles } from './Spin.style'
import type { SpinStylesNames } from './Spin.type'

interface SpinProps extends StyleComponentProps<SpinStylesNames>, LoadingProps {
    loading?: boolean
    children?: React.ReactNode
}

export const Spin = forwardRef<HTMLInputElement, SpinProps>(({ loading, children, styles, classNames, unstyled, ...rest }, ref) => {
    const { classes } = useStyles({}, { name: 'Spin', styles, classNames, unstyled })

    return (
        <Box className={classes.root} ref={ref}>
            {loading && (
                <Box className={classes.spin}>
                    <Loading {...rest} />
                </Box>
            )}
            {children}
        </Box>
    )
})
