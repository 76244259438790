import { Loading } from '@byecode/ui'
import { StripePayStatus, WechatPayStatus } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAsync, useMount } from 'react-use'
import styled from 'styled-components'

import { stripePayEmitter } from '@/actionsEngine/event'
import { appAtom } from '@/atoms/application/state'
import * as srv from '@/services'
import type { StripePaySearch } from '@/services/types'

interface StripePayProps {}

const payKeyParams: (keyof StripePaySearch)[] = ['clientSecret', 'expiresAt', 'outTradeNo', 'redirect', 'pageId']

const SCxContainer = styled.div`
    .stripeContainer {
        height: 100vh !important;
        overflow: auto;
    }
`

export const StripePay: React.FunctionComponent<StripePayProps> = props => {
    const stripeAccount = useAtomData(
        appAtom,
        useCallback(s => s?.integrations?.stripe, [])
    )
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()

    const stripePayParams = useMemo(
        () =>
            Object.fromEntries(
                payKeyParams.map(key => {
                    const value = searchParams.get(key) ?? ''
                    return [key, key === 'autoRedirectAfterPayment' ? Number(value) : value]
                })
            ) as unknown as StripePaySearch,
        [searchParams]
    )

    const fetchClientSecret = useCallback(() => {
        return Promise.resolve(searchParams.get('clientSecret') || '')
    }, [searchParams])

    const handleRedirectUrl = useCallback(
        (stripeStatus: StripePayStatus) => {
            const { redirect, outTradeNo, autoRedirectAfterPayment } = stripePayParams
            if (!autoRedirectAfterPayment) {
                stripePayEmitter.emit(`stripePayCheck-${outTradeNo}`, stripeStatus)
                return
            }
            const newUrl = new URL(decodeURIComponent(redirect))
            redirect
                ? navigate(`${newUrl.pathname}?stripeStatus=${stripeStatus}&outTradeNo=${outTradeNo}`, {
                      replace: true
                  })
                : navigate('/')
        },
        [navigate, stripePayParams]
    )

    const checkOrderPayStatus = useCallback(async () => {
        const { pageId, outTradeNo } = stripePayParams
        if (!pageId || !outTradeNo) {
            return null
        }
        const code = await srv.getStripePayStatus({ outTradeNo, pageId })
        if (code === StripePayStatus.SUCCESS) {
            handleRedirectUrl(StripePayStatus.SUCCESS)
            return
        }
        if (code === StripePayStatus.CLOSED || code === StripePayStatus.REVOKED) {
            return
        }
        setTimeout(() => checkOrderPayStatus(), 3000)
    }, [handleRedirectUrl, stripePayParams])

    const onComplete = useCallback(() => {
        checkOrderPayStatus()
    }, [checkOrderPayStatus])

    const options = { fetchClientSecret, onComplete }

    const { loading, value: stripePromise } = useAsync(() => {
        return loadStripe(stripeAccount?.publicKey || '')
    }, [stripeAccount?.publicKey])

    if (loading || !stripePromise) {
        return <Loading />
    }

    return (
        <SCxContainer>
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout className="stripeContainer" />
            </EmbeddedCheckoutProvider>
        </SCxContainer>
    )
}
