/* eslint-disable react-hooks/exhaustive-deps */
import { off, on } from '@lighthouse/tools'
import cls from 'classnames'
import type { RefObject } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled, { css } from 'styled-components'
import { useImmer } from 'use-immer'

export interface ResizeOffset {
    x0: number
    x: number
}

export interface ResizeProps {
    children?: React.ReactNode
    onChange?: (offset: ResizeOffset, resizing: boolean) => void
}

export type ResizerRef = RefObject<HTMLDivElement> | undefined

export type ResizerResult = [ResizerRef, { resizing: boolean; offset: ResizeOffset }]

const SCxResize = styled.div<{ disableOffset?: boolean }>`
    width: 5px;
    height: 100%;
    transform: translateX(3px);
    transition: background 0.2s ease-out;
    cursor: col-resize;

    &:hover,
    &.dragging {
        background-color: var(--color-main);
        transition: background 0.2s ease-in;
    }
`

const useResizer = (): ResizerResult => {
    const resizerRef: ResizerRef = useRef(null)
    const [resizing, setResizing] = useState(false)
    const [offset, setOffset] = useImmer<ResizeOffset>({ x0: 0, x: 0 })

    const handleMouseMove = (ev: Event) => {
        // ev.stopPropagation()
        setOffset(o => {
            const { x0 } = o
            const evt = ev as MouseEvent
            o.x = evt.clientX - x0
        })
    }

    const handleMouseDown = (ev: Event) => {
        ev.stopPropagation()
        setResizing(true)
        setOffset(o => {
            o.x0 = (ev as MouseEvent).clientX
            o.x = 0
        })
        on<Document, MouseEvent>(document, 'mousemove', handleMouseMove)
    }

    const handleMouseUp = (ev: Event) => {
        // ev.stopPropagation()
        setResizing(false)
        // setOffset({ x0: 0, x: 0 })
        off<Document, MouseEvent>(document, 'mousemove', handleMouseMove)
    }

    useEffect(() => {
        const { current: resizerDom } = resizerRef
        if (!resizerDom) {
            return
        }
        on<HTMLDivElement, MouseEvent>(resizerDom, 'mousedown', handleMouseDown)
        on<Document, MouseEvent>(document, 'mouseup', handleMouseUp)

        return () => {
            off<HTMLDivElement, MouseEvent>(resizerDom, 'mousedown', handleMouseDown)
            off<Document, MouseEvent>(document, 'mouseup', handleMouseUp)
        }
    }, [resizerRef])

    const options = {
        resizing,
        offset
    }

    return [resizerRef, options]
}

export const Resize: React.FC<ResizeProps> = ({ onChange }) => {
    const [resizerRef, { resizing, offset }] = useResizer()
    useUpdateEffect(() => {
        onChange?.(offset, resizing)
    }, [offset, resizing])

    return (
        <SCxResize
            ref={resizerRef}
            onMouseDown={ev => {
                ev.stopPropagation()
            }}
            onPointerDown={ev => {
                ev.stopPropagation()
            }}
            className={cls({ dragging: resizing })}
        />
    )
}
