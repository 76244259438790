import type { DownloadFileActionConfig } from '@lighthouse/shared'
import { type ActionFlowNode, downloadFile, resolveDownloadFileUrls } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

export const handleDownloadFile = async (node: ActionFlowNode<DownloadFileActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return excParams
    }

    const downloadFileParams = resolveDownloadFileUrls(config, excParams)

    if (downloadFileParams) {
        downloadFile(downloadFileParams)
    }

    // eslint-disable-next-line no-return-await
    return await Promise.resolve(downloadFileParams)
}
