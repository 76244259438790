import { IconFont, Loading, pointer, singleTextEllipsis } from '@byecode/ui'
import type { AiFieldState, DTSelectItem, HighLightConditions, SelectGenerationByTextField, SelectValue } from '@lighthouse/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import { COLORS_MAP, LIGHT_COLORS_MAP } from '../../constants/color'
import { CellHighlighter } from '../Highlighter'
import { TooltipList, useWidth } from '../TooltipList'

// import type { FieldValuePreviewerProps } from './types'
interface SelectGenerationByTextValuePreviewerProps extends Pick<React.CSSProperties, 'justifyContent'> {
    field: SelectGenerationByTextField
    data: SelectValue
    isWrap?: boolean
    width?: number
    colorless?: boolean
    aiFieldStatus?: AiFieldState
    styleConditions?: HighLightConditions
    textStyles?: React.CSSProperties
    direction?: 'horizontal' | 'vertical'
    onAiGeneration?: (fieldId: string) => Promise<boolean>
}

interface SelectGenerationByTextItemPreviewerProps {
    children?: React.ReactNode
    id: string
    data: DTSelectItem
    colorless?: boolean
    isLast?: boolean
    isVertical?: boolean
    direction?: 'horizontal' | 'vertical'
    textStyles?: React.CSSProperties
}

const SCxPrefix = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
`

const SCxIcon = styled(IconFont)`
    ${pointer}
`

const SCxSelectListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean } & Pick<React.CSSProperties, 'justifyContent'>>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent};
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    overflow: hidden;
`

const SCxCellValue = styled.span<{ direction?: 'horizontal' | 'vertical'; isLast?: boolean }>`
    padding: ${({ direction }) => (direction === 'vertical' ? '8px 2px' : '2px 8px')};
    border-radius: 4px;

    ${({ direction }) =>
        direction === 'vertical'
            ? css`
                  width: 22px;
              `
            : css`
                  height: 22px;
              `}
    line-height: 16px;
    white-space: nowrap;
    border: 1px solid var(--color-gray-200);
    ${({ isLast }) => isLast && singleTextEllipsis}
`

export const SelectGenerationByTextItemPreviewer: React.FC<SelectGenerationByTextItemPreviewerProps> = ({
    id,
    data,
    colorless = false,
    isLast = false,
    direction,
    textStyles
}) => {
    const { label, color } = data
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)
    const itemBasicStyle: React.CSSProperties = colorless
        ? { border: '1px solid var(--color-gray-200)', backgroundColor: 'var(--color-white)' }
        : {
              backgroundColor: color && `var(${LIGHT_COLORS_MAP[color]})`,
              color: color && `var(${COLORS_MAP[color]})`,
              borderColor: color && 'rgba(0,0,0,0.1)'
          }
    return (
        <SCxCellValue key={label} ref={widthRef} direction={direction} isLast={isLast} style={{ ...itemBasicStyle, ...textStyles }}>
            {label}
        </SCxCellValue>
    )
}

export const SelectGenerationByTextValuePreviewer: React.FC<SelectGenerationByTextValuePreviewerProps> = ({
    field,
    data,
    width = 160,
    isWrap = true,
    justifyContent = 'flex-start',
    styleConditions,
    colorless = false,
    textStyles,
    direction,
    aiFieldStatus,
    onAiGeneration
}) => {
    const [loading, setLoading] = useState(false)
    const { id, type, selectGenerationByText } = field
    const { options = [] } = selectGenerationByText

    const selectedOptions = useMemo(
        () =>
            data.reduce<DTSelectItem[]>((list, val) => {
                const option = options.find(option => option.label === val)
                if (option) {
                    list.push(option)
                }
                return list
            }, []),
        [data, options]
    )

    useEffect(() => {
        setLoading(aiFieldStatus === 'STARTED')
    }, [aiFieldStatus])

    const handleAiGeneration = useCallback(
        async (e: React.MouseEvent) => {
            e.stopPropagation()
            if (onAiGeneration) {
                setLoading(true)
                const isSuccess = await onAiGeneration(id)
                if (!isSuccess) {
                    setLoading(false)
                }
            }
        },
        [id, onAiGeneration]
    )

    const tooltipListWidth = useMemo(() => (onAiGeneration ? width - 20 : width), [onAiGeneration, width])

    return (
        <>
            <CellHighlighter fieldId={id} type={type} value={data} conditions={styleConditions} />
            <TooltipList
                data={selectedOptions.map(({ label }, index) => ({ label, value: String(index) }))}
                width={tooltipListWidth}
                disabled={isWrap}
                key={selectedOptions.map(item => item.label).join('-')}
                render={visibleNum => {
                    const renderList = selectedOptions.slice(0, visibleNum)
                    return (
                        <SCxSelectListWrapper
                            justifyContent={justifyContent}
                            isPadding={visibleNum < selectedOptions.length}
                            isWrap={isWrap}
                        >
                            {onAiGeneration && (
                                <SCxPrefix>
                                    {loading ? (
                                        <Loading outlined size={18} />
                                    ) : (
                                        <SCxIcon type="PropertyAIText" size={16} fill="#FA7066" onPointerDown={handleAiGeneration} />
                                    )}
                                </SCxPrefix>
                            )}
                            {renderList.map((item, index) => (
                                <SelectGenerationByTextItemPreviewer
                                    key={item.id}
                                    id={String(index)}
                                    data={item}
                                    direction={direction}
                                    isLast={index === renderList.length - 1}
                                    textStyles={textStyles}
                                    colorless={colorless}
                                />
                            ))}
                        </SCxSelectListWrapper>
                    )
                }}
            />
        </>
    )
}
