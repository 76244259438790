import type { ApplicationSettingThemeColor } from '@lighthouse/core'
import { useUncontrolled } from '@lighthouse/tools'
import chroma from 'chroma-js'
import React, { useCallback, useEffect, useState } from 'react'
import { useLatest } from 'react-use'

import { APP_MAIN_COLOR_VARIABLE } from '../../../constants'
import { useFillPickerContext } from '../Provider'
import type { HsvaColor } from '../type'
import { isHexColor, isHsvaColor } from '../utils'
import { ColorInputContainer } from './ColorInputContainer'
import { ColorLibrary } from './ColorLibrary'
import { ColorPickerContainer } from './ColorPickerContainer'
import { SaturationValuePanel } from './SaturationValuePanel'
import { ThemeColorContainer } from './ThemeColorContainer'

// const HISTORY_COLORS_STORAGE_KEY = 'color-picker-recent-colors'

function hexToHsva(value: string): HsvaColor {
    const color = chroma(value)
    const [h, s, v] = color.hsv()
    const alpha = color.alpha()
    return { h: Number.isNaN(h) ? 0 : h, s, v, a: alpha }
}

function inputToHsva(value: string, palettes: ApplicationSettingThemeColor[]): HsvaColor {
    const hex = value === APP_MAIN_COLOR_VARIABLE ? window.getComputedStyle(document.body).getPropertyValue(APP_MAIN_COLOR_VARIABLE) : value

    if (isHexColor(hex)) {
        return hexToHsva(hex)
    }

    const libColor = palettes.find(item => item.id === value)
    if (!libColor) {
        return { h: 0, s: 0, v: 0, a: 1 }
    }

    return hexToHsva(libColor.value)
}

function hsvaToHex(value: HsvaColor): string {
    return chroma(value.h, value.s, value.v, value.a, 'hsv').hex()
}

function inputToValue(input: HsvaColor | typeof APP_MAIN_COLOR_VARIABLE | ApplicationSettingThemeColor) {
    if (input === APP_MAIN_COLOR_VARIABLE) {
        return input
    }

    if (isHsvaColor(input)) {
        return hsvaToHex(input)
    }

    return input.id
}

interface FillPickerCoreProps {
    defaultValue?: string
    value?: string
    onChange?: (value: string) => void
    onChangeEnd?: (value: string) => void
    hideLibrary?: boolean
    hideRecommend?: boolean
    onClickCreateColor?: () => void
    onClickEditColor?: (id: string) => void
}

export const FillPickerCore = ({
    defaultValue,
    value,
    onChange,
    onChangeEnd,
    hideRecommend,
    hideLibrary,
    onClickCreateColor,
    onClickEditColor
}: FillPickerCoreProps) => {
    const [_value, _onChange] = useUncontrolled({ defaultValue, value, onChange })
    const { palettes } = useFillPickerContext()

    const [hsvaColor, setHsvaColor] = useState<HsvaColor>(inputToHsva(_value, palettes))
    const latestHsva = useLatest(hsvaColor)
    const hexStr = hsvaToHex(hsvaColor)

    // 处理黑色、白色等无色阶的值
    useEffect(() => {
        if (hexStr === _value) {
            return
        }

        const newHsva = inputToHsva(_value, palettes)
        setHsvaColor({ ...newHsva, h: Number.isNaN(newHsva.h) ? latestHsva.current.h : newHsva.h })
    }, [hexStr, latestHsva, palettes, _value])

    const handleChange = useCallback(
        (value: HsvaColor | typeof APP_MAIN_COLOR_VARIABLE | ApplicationSettingThemeColor, shouldUpdate?: boolean) => {
            setHsvaColor(() => {
                if (value === APP_MAIN_COLOR_VARIABLE) {
                    const cssVariableHex = window.getComputedStyle(document.body).getPropertyValue(APP_MAIN_COLOR_VARIABLE)
                    const color = chroma(cssVariableHex)
                    const [h, s, v] = color.hsv()
                    const alpha = color.alpha()
                    return { h: Number.isNaN(h) ? latestHsva.current.h : h, s, v, a: alpha }
                }

                if (isHsvaColor(value)) {
                    return { ...value, h: Number.isNaN(value.h) ? latestHsva.current.h : value.h }
                }

                const color = chroma(value.value)
                const [h, s, v] = color.hsv()
                const alpha = color.alpha()
                return { h: Number.isNaN(h) ? latestHsva.current.h : h, s, v, a: alpha }
            })

            const newValue = inputToValue(value)
            _onChange(newValue)

            // 解决除了画板以外，其他值变化不触发 onChangeEnd 的问题
            if (shouldUpdate) {
                onChangeEnd?.(newValue)
            }
        },
        [_onChange, latestHsva, onChangeEnd]
    )

    const handleChangeAndEmitUpdate = useCallback(
        (value: HsvaColor | typeof APP_MAIN_COLOR_VARIABLE | ApplicationSettingThemeColor) => {
            handleChange(value, true)
        },
        [handleChange]
    )

    return (
        <>
            <SaturationValuePanel value={hsvaColor} onChange={handleChange} onChangeEnd={v => onChangeEnd?.(inputToValue(v))} />

            <ColorPickerContainer value={hsvaColor} onChange={handleChangeAndEmitUpdate} />

            <ColorInputContainer value={hsvaColor} onChange={handleChangeAndEmitUpdate} />

            {!hideRecommend && <ThemeColorContainer onChange={handleChangeAndEmitUpdate} />}

            {!hideLibrary && (
                <ColorLibrary
                    onChange={handleChangeAndEmitUpdate}
                    onClickCreateColor={onClickCreateColor}
                    onClickEditColor={onClickEditColor}
                />
            )}
        </>
    )
}
