import type { DTSelectItem, Field, FilterCommonCondition, InclusionRelation } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { DateParams } from './DateParams'
import { InputParams } from './InputParams'
import { PersonParams } from './PersonParams'
import { SelectParams } from './SelectParams'

interface FilterParamsProps {
    field?: Field
    operator: InclusionRelation
    selectOptions?: DTSelectItem[]
    filterItem: FilterCommonCondition
    onOpenParams?: (index?: 0 | 1) => void
    onClear?: (index?: 0 | 1) => void
}

const FilterParamsWrapper = styled.div`
    width: 100%;
    height: 36px;
`

const DateParamsWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
`

export const FilterParams: React.FC<FilterParamsProps> = ({ field, selectOptions = [], filterItem, operator, onOpenParams, onClear }) => {
    const content = useMemo(() => {
        if (field?.type === 'select') {
            return (
                <SelectParams filterItem={filterItem} selectOptions={selectOptions} onClick={() => onOpenParams?.(0)} onClear={onClear} />
            )
        }
        if (field?.type === 'person') {
            return <PersonParams filterItem={filterItem} onClick={() => onOpenParams?.(0)} />
        }

        if (field?.type === 'date') {
            if (operator === 'between') {
                return (
                    <DateParamsWrapper>
                        <DateParams index={0} filterItem={filterItem} onClick={() => onOpenParams?.(0)} onClear={() => onClear?.(0)} />
                        <DateParams index={1} filterItem={filterItem} onClick={() => onOpenParams?.(1)} onClear={() => onClear?.(1)} />
                    </DateParamsWrapper>
                )
            }
            return <DateParams filterItem={filterItem} onClick={() => onOpenParams?.(0)} onClear={onClear} />
        }
        return <InputParams filterItem={filterItem} onClick={() => onOpenParams?.(0)} onClear={onClear} />
    }, [field?.type, filterItem, onClear, onOpenParams, operator, selectOptions])

    return <FilterParamsWrapper>{content}</FilterParamsWrapper>
}
