import type { MenuOpItem } from '../../../components'

export const userMenuOpItems: MenuOpItem[] = [
    {
        area: 'info'
    },
    {
        area: 'setting',
        items: [{ name: '退出登录', iconfont: 'SignOut', action: 'SIGNOUT' }]
    }
]

export const excludePage = ['/account/login', '/account/register']
