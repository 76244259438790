import clsx from 'clsx'
import React, { forwardRef, useCallback, useMemo, useRef } from 'react'

import { useMove } from '../../hooks/useMove'
import { useUncontrolled } from '../../hooks/useUncontrolled'
import type { StyleComponentProps } from '../../theme/types'
import { Box } from '../Box'
import type { InputProps } from '../Input'
import type { SliderStyleNames } from './styles'
import { useStyles } from './styles'

export type RangeSliderValue = [number, number]

export interface RangeSliderProps
    extends StyleComponentProps<SliderStyleNames>,
        Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange' | 'defaultValue' | 'value'> {
    defaultValue?: RangeSliderValue
    value?: RangeSliderValue
    onChange?: (v: RangeSliderValue) => void
    onChangeStart?: (v: number) => void
    onChangeEnd?: (v: number) => void
    color?: string
    disabled?: boolean
    min: number
    max: number
    step?: number
    icon?: React.ReactNode
    hideIcon?: boolean
    hideInput?: boolean
    inputProps?: InputProps
}

export const RangeSlider = forwardRef<HTMLDivElement, RangeSliderProps>((props, ref) => {
    const {
        classNames,
        className,
        styles,
        unstyled,

        disabled,
        value: propValue,
        defaultValue,
        onChange: propOnChange,
        onChangeStart,
        onChangeEnd,
        color,
        min,
        max,
        step = 10,
        icon,
        hideIcon,
        hideInput,
        inputProps,

        ...rest
    } = props

    const { classes } = useStyles({ color }, { name: 'Slider', classNames, styles, unstyled })
    const actionIndexRef = useRef<number | undefined>(undefined)
    const activeIndexRef = useRef<number | undefined>(undefined)

    const handlePropChange = useCallback(
        (val: [number, number]) => {
            propOnChange?.(val.sort((a, b) => a - b))
        },
        [propOnChange]
    )

    const [value, onChange] = useUncontrolled<RangeSliderValue>({
        value: propValue,
        defaultValue,
        onChange: handlePropChange,
        finalValue: [min, max]
    })

    const getLimitVal = useCallback(
        (val: number) => {
            const limitMaxValue = val > max ? max : val
            return limitMaxValue < min ? min : limitMaxValue
        },
        [max, min]
    )

    const { ref: trackRef, active } = useMove(
        useCallback(
            ({ ratio: { x } }) => {
                const v = Math.round((x * (max - min)) / step) * step + min
                // const r = range(1, 6)
                const [startNum, endNum] = value
                const startRangeNum = Math.abs(startNum - v)
                const endRangeNum = Math.abs(endNum - v)
                const val = getLimitVal(v)
                if (actionIndexRef.current !== undefined) {
                    if (actionIndexRef.current === 1) {
                        if (val >= startNum) {
                            onChange([startNum, val])
                            return
                        }
                        actionIndexRef.current = 0
                        onChange([val, startNum])
                        return
                    }

                    if (val >= endNum) {
                        actionIndexRef.current = 1
                        onChange([endNum, val])
                        return
                    }
                    onChange([val, endNum])
                    return
                }
                if (startRangeNum > endRangeNum) {
                    activeIndexRef.current = 1
                    onChange([value[0], val])
                    return
                }
                activeIndexRef.current = 0
                onChange([val, value[1]])
            },
            [max, min, step, value, getLimitVal, onChange]
        ),
        {
            disabled,
            onStart: ({ ratio: { x } }) => {
                const v = Math.round((x * (max - min)) / step) * step + min
                onChangeStart?.(v)
            },
            onEnd: ({ ratio: { x } }) => {
                const v = Math.round((x * (max - min)) / step) * step + min
                actionIndexRef.current = undefined
                activeIndexRef.current = undefined
                onChangeEnd?.(v)
            }
        }
    )

    const thumbRef = useRef<HTMLDivElement>(null)

    const sortValue = useMemo(() => [...value].sort((a, b) => (a ?? 0) - (b ?? 0)), [value])
    const [left, right] = useMemo(() => {
        const start = ((sortValue?.[0] - min) / (max - min)) * 100
        const right = (1 - (sortValue[1] - min) / (max - min)) * 100
        return [start, right]
    }, [max, min, sortValue])

    const startRatio = ((value[0] - min) / (max - min)) * 100
    const endRatio = ((value[1] - min) / (max - min)) * 100

    return (
        <Box
            ref={ref}
            className={clsx(className, classes.root)}
            {...rest}
            onMouseDown={e => {
                e.stopPropagation()
            }}
        >
            {/* {!hideIcon && (icon ?? <IconFont type="MaxWidth" />)} */}
            <Box
                className={classes.track}
                ref={trackRef}
                tabIndex={-1}
                onMouseDownCapture={e => {
                    e.preventDefault()
                    thumbRef.current?.focus()
                }}
            >
                {/* <Box className={classes.rail} style={{ width: `${startRatio}%` }} /> */}
                <Box className={classes.rail} style={{ left: `${left}%`, right: `${right}%` }} />
                <Box
                    className={classes.thumb}
                    ref={thumbRef}
                    tabIndex={0}
                    role="slider"
                    onPointerDown={e => e.stopPropagation()}
                    onMouseDown={e => {
                        e.stopPropagation()
                        actionIndexRef.current = 0
                    }}
                    data-active={active && activeIndexRef.current === 0}
                    style={{ left: `${startRatio}%` }}
                />
                <Box
                    className={classes.thumb}
                    ref={thumbRef}
                    tabIndex={0}
                    role="slider"
                    onPointerDown={e => e.stopPropagation()}
                    onMouseDown={e => {
                        e.stopPropagation()
                        actionIndexRef.current = 1
                    }}
                    data-active={active && activeIndexRef.current === 1}
                    style={{ left: `${endRatio}%` }}
                />
            </Box>
            {/* {!hideInput && (
                <Input
                    style={{ width: 56 }}
                    placeholder=""
                    disabled={disabled}
                    value={draftInputValue}
                    onChange={e => setDraftInputValue(e.currentTarget.value)}
                    onBlur={e => {
                        const v = e.currentTarget.value
                        const number = Number(v)
                        const validNumber = Math.max(min, Math.min(max, Number.isNaN(number) ? min : (number / step) * step))
                        handleChange(validNumber)
                    }}
                    onKeyDownCapture={e => {
                        if (e.key === 'Enter') {
                            const v = e.currentTarget.value
                            const number = Number(v)
                            const validNumber = Math.max(min, Math.min(max, Number.isNaN(number) ? min : (number / step) * step))
                            handleChange(validNumber)
                        }
                    }}
                    {...inputProps}
                />
            )} */}
        </Box>
    )
})
