import type { Field, FieldType, TypeInstanceMap } from '@lighthouse/core'
import type { JSONContent } from '@tiptap/react'

import { innerTypeNameMap } from '../../../constants'
import { getFieldIcon } from '../../../utils'

export type { JSONContent } from '@tiptap/react'

export interface DataQuote {
    name: string
    type: string
    innerType?: TypeInstanceMap
}

export const parseExpressionToString = (json: JSONContent) => {
    return (
        json.content?.reduce((prev, cur, index) => {
            if (cur?.type === 'paragraph' && index > 0) {
                prev += '\n'
            }
            if (cur?.content) {
                prev += parseExpressionToString(cur)
                return prev
            }
            const id: string = cur?.attrs?.id || ''
            if (cur?.type === 'field' && id) {
                prev += `{${id}}`
            }
            if (cur?.type === 'datasource' && id) {
                prev += `\${${id}}`
            }
            if (cur?.type === 'text') {
                prev += cur.text
            }
            return prev
        }, '') || ''
    )
}

function parseExpression(expression: string, content: JSONContent[], index = 0) {
    return ({ fieldBiMap }: ReturnType<typeof createDsAndFieldMap>): JSONContent[] => {
        const str = expression.replace(/\$?\{(\w{2,70})\}/u, ($0: string, $1: string) => {
            const idx = expression.indexOf($0)
            if (idx !== 0) {
                const text = expression.slice(0, Math.max(0, idx))
                content.push({
                    type: 'text',
                    text: expression.slice(0, Math.max(0, idx))
                })
                index += text.length
            }
            const dataQuote = fieldBiMap.get($1)
            if (!dataQuote) {
                return ''
            }
            const { name, type, innerType } = dataQuote
            content.push({
                type: 'field',
                text: name,
                attrs: {
                    id: $1,
                    name,
                    icon: getFieldIcon($1, type as FieldType, innerType),
                    extra: innerType ? innerTypeNameMap[innerType] : '',
                    dataType: type
                }
            })

            return ''
        })

        const surplusStr = str.slice(Math.max(0, index))
        if (/\$?\{(\w{2,70})\}/u.test(surplusStr)) {
            return parseExpression(surplusStr, content, 0)({ fieldBiMap })
        }
        if (surplusStr.trim()) {
            content.push({
                type: 'text',
                text: surplusStr
            })
        }

        return content
    }
}

export function decodeExpression(expression: string) {
    return ({ fieldBiMap }: ReturnType<typeof createDsAndFieldMap>) => {
        if (!expression) {
            return { content: [{ content: [], type: 'doc' }] }
        }
        const arr = expression.split('\n')
        const content: JSONContent[] = []
        arr.forEach(item => {
            if (!item) {
                content.push({ type: 'paragraph' })
                return
            }
            const contentItem: JSONContent[] = parseExpression(item, [])({ fieldBiMap }) ?? []
            content.push({ type: 'paragraph', content: contentItem })
        })

        return {
            content,
            type: 'doc'
        }
    }
}

export function createDsAndFieldMap(fields: Field[]) {
    const fieldBiMap = new Map<string, DataQuote>()
    function generatorMap(DsFields: Field[]) {
        for (const field of DsFields) {
            const { id, name, type, innerType } = field
            const safeName = name.replaceAll('"', '\\"') || ''
            fieldBiMap.set(id, { name: safeName, type, innerType })
        }
    }
    generatorMap(fields)
    return { fieldBiMap }
}
