import type { DateRangeSystemVariableValue } from '@lighthouse/core'
import {
    addDays,
    addMonths,
    addWeeks,
    addYears,
    endOfDay,
    endOfMonth,
    endOfQuarter,
    endOfToday,
    endOfTomorrow,
    endOfWeek,
    endOfYear,
    startOfDay,
    startOfMonth,
    startOfQuarter,
    startOfToday,
    startOfTomorrow,
    startOfWeek,
    startOfYear,
    subDays,
    subMonths,
    subQuarters,
    subWeeks,
    subYears
} from 'date-fns'
/**
 * 日期范围生成器
 * @param range
 */
export const generateDataRangeByRangeValue = (range: DateRangeSystemVariableValue): [Date, Date] => {
    const now = new Date()
    switch (range) {
        case 'TODAY': {
            return [startOfToday(), endOfToday()]
        }
        case 'YESTERDAY': {
            const yesterday = subDays(now, 1)
            return [startOfDay(yesterday), endOfDay(yesterday)]
        }
        case 'TOMORROW': {
            return [startOfTomorrow(), endOfTomorrow()]
        }
        case 'THIS_WEEK': {
            return [startOfWeek(now), endOfWeek(now)]
        }
        case 'LAST_WEEK': {
            const lastWeek = subWeeks(now, 1)
            return [startOfWeek(lastWeek), endOfWeek(lastWeek)]
        }
        case 'NEXT_WEEK': {
            const nextWeek = addWeeks(now, 1)
            return [startOfWeek(nextWeek), endOfWeek(nextWeek)]
        }
        case 'THIS_MONTH': {
            return [startOfMonth(now), endOfMonth(now)]
        }
        case 'LAST_MONTH': {
            const lastMonth = subMonths(now, 1)
            return [startOfMonth(lastMonth), endOfMonth(lastMonth)]
        }
        case 'NEXT_MONTH': {
            const nextMonth = addMonths(now, 1)
            return [startOfMonth(nextMonth), endOfMonth(nextMonth)]
        }
        case 'THIS_QUARTER': {
            return [startOfQuarter(now), endOfQuarter(now)]
        }
        case 'LAST_QUARTER': {
            const lastQuarter = subQuarters(now, 1)
            return [startOfQuarter(lastQuarter), endOfQuarter(lastQuarter)]
        }
        case 'NEXT_QUARTER': {
            const nextQuarter = addMonths(now, 3)
            return [startOfQuarter(nextQuarter), endOfQuarter(nextQuarter)]
        }
        case 'THIS_YEAR': {
            return [startOfYear(now), endOfYear(now)]
        }
        case 'LAST_YEAR': {
            const lastYear = subYears(now, 1)
            return [startOfYear(lastYear), endOfYear(lastYear)]
        }
        case 'NEXT_YEAR': {
            const nextYear = addYears(now, 1)
            return [startOfYear(nextYear), endOfYear(nextYear)]
        }
        case 'PAST_SEVEN_DAYS': {
            return [subDays(now, 7), now]
        }
        case 'FUTURE_SEVEN_HEAVENS': {
            return [now, addDays(now, 7)]
        }
        case 'PAST_FOURTEEN_DAYS': {
            return [subDays(now, 14), now]
        }
        case 'FUTURE_FOURTEEN_HEAVENS': {
            return [now, addDays(now, 14)]
        }
        case 'PAST_THIRTY_DAYS': {
            return [subDays(now, 30), now]
        }
        case 'FUTURE_THIRTY_HEAVENS': {
            return [now, addDays(now, 30)]
        }
        default: {
            throw new Error('不支持的日期范围')
        }
    }
}
