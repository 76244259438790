// 匿名用户
export const ANONYMOUS = 'anonymous'

// 管理员
export const ADMIN = 'admin'

// 系统
export const SYSTEM = 'system'

export const PERSON_ID = new Set([ANONYMOUS, ADMIN, SYSTEM])

export const PERSON_MAP_ICON: Record<string, string> = {
    anonymous: 'User',
    admin: 'Wrench',
    system: 'Robot'
}

export const PERSON_MAP_ICON_COLOR: Record<string, string | undefined> = {
    anonymous: 'var(--color-gray-400)',
    admin: 'var(--color-blue-300)',
    system: 'var(--color-yellow-300)'
}

export const PERSON_MAP_NAME: Record<string, string> = {
    anonymous: '匿名',
    admin: '管理员',
    system: '系统'
}

// 应用管理者
export const DEVELOPER = 'developer'

// 游客
export const VISITOR = 'visitor'

export const SYSTEM_ROLE = new Set([DEVELOPER, VISITOR])
