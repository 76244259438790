import React, { useMemo } from 'react'
import styled from 'styled-components'

interface YoutubePlayerProps {
    source?: string
    isController?: boolean
    autoplay?: boolean
    isLoop?: boolean
}

const regex = /(?:youtube\.com\/(?:shorts\/)?|youtu\.be\/)([^?&/]+)/u

const SCxIframe = styled.iframe`
    pointer-events: auto;
    border: none;
    width: 100%;
    height: 100%;
`

export const YoutubePlayer: React.FunctionComponent<YoutubePlayerProps> = ({ source, isController, isLoop, autoplay }) => {
    const suffix = useMemo(() => {
        const searchParams = new URLSearchParams(source)
        const match = source?.match(regex)
        const videoId = match ? match[1] : ''
        searchParams.set('controls', Number(Boolean(isController)).toString())
        searchParams.set('loop', Number(Boolean(isLoop)).toString())
        searchParams.set('autoplay', Number(Boolean(autoplay)).toString())
        return `${videoId}?${searchParams.toString()}`
    }, [autoplay, isController, isLoop, source])

    return (
        <SCxIframe
            src={`https://www.youtube.com/embed/${suffix}`}
            title="YouTube video player"
            width={200}
            height={150}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
        />
    )
}
