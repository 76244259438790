import { Flex } from '@byecode/ui/components/Flex'
import { type ApplicationSettingTheme, type IconDecorationProtocol, ApplicationSettingThemeNavBarMode } from '@lighthouse/core'
import { Divider } from '@mantine/core'
import type { ReactNode } from 'react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useApplicationContext, useLanguageContext } from '../../../contexts'
import { ApplicationPreviewEnum } from '../../../types'
import { getFileNameByUrl, getImageFullUrlInApplication } from '../../../utils'
import { useAppThemeContext } from '../../ApplicationContainer'
import { LanguageBox } from '../../ApplicationContainer/LanguageBox'
import { TagIcon } from '../../TagIcon'
import * as CM from '../styles'
import * as SC from './styles'

interface BoxProps {
    logo?: IconDecorationProtocol
    name?: string
    describe?: string
    language: string
    hideBackBtn?: boolean
    dividerLabel?: string
    customBtn?: ReactNode
    previewType?: ApplicationPreviewEnum
    children?: React.ReactNode
    style?: React.CSSProperties
    onBack?: () => void
    onChangeLanguage: (v: string) => void
}

const LoginBox: React.FunctionComponent<BoxProps> = ({
    logo,
    name,
    describe,
    children,
    hideBackBtn,
    language,
    customBtn,
    style,
    previewType,
    dividerLabel: propDividerLabel,
    onChangeLanguage,
    onBack
}) => {
    const { theme } = useAppThemeContext()
    const { t } = useTranslation()
    const { languageList } = useLanguageContext()
    const { appId } = useApplicationContext()
    const dividerLabel = useMemo(() => propDividerLabel ?? t('noAccount'), [propDividerLabel, t])

    const iconEle = useMemo(() => {
        if (!logo || !logo?.value) {
            return null
        }
        return logo.type === 'icon' ? (
            <TagIcon
                iconSize={44}
                icon={logo?.value}
                size={60}
                background="var(--color-app-main)"
                iconColor="var(--color-white)"
                radius={12}
            />
        ) : (
            <img alt={getFileNameByUrl(logo.value ?? '')} height={60} src={getImageFullUrlInApplication(appId, logo.value)} />
        )
    }, [appId, logo])

    const defaultTheme: ApplicationSettingTheme = useMemo(
        () => ({
            ...theme,
            navbar: {
                mode: ApplicationSettingThemeNavBarMode.light
            }
        }),
        [theme]
    )
    return (
        <CM.Container style={style}>
            {!hideBackBtn && (
                <TagIcon
                    size={28}
                    iconSize={20}
                    radius={100}
                    enableHover
                    icon="ArrowLeftSmall"
                    iconColor="var(--color-gray-400)"
                    background="var(--color-gray-100)"
                    style={{
                        position: 'absolute',
                        left: 20,
                        top: 20
                    }}
                    onClick={onBack}
                />
            )}
            {logo && <Flex justifyContent="center"> {iconEle}</Flex>}

            {name && <SC.Title>{name}</SC.Title>}
            {describe && <SC.Describe>{describe}</SC.Describe>}
            {children}
            {customBtn && (
                <Divider
                    label={dividerLabel}
                    labelPosition="center"
                    styles={{ label: { color: 'var(--color-gray-400)!important', lineHeight: '20px', marginTop: '0px!important' } }}
                    color="var(--color-gray-200)"
                    style={{ marginTop: 26 }}
                />
            )}
            {customBtn}
            {languageList.length > 1 && (
                <Flex justifyContent="center" style={{ width: 'auto', marginTop: 32 }}>
                    <LanguageBox
                        language={language}
                        isMobile={previewType === ApplicationPreviewEnum.mobile}
                        showLabel
                        onChange={onChangeLanguage}
                        style={{ color: 'var(--color-gray-400)' }}
                    />
                </Flex>
            )}
        </CM.Container>
    )
}

export default LoginBox
