import { mergeRefs } from '@byecode/ui/hooks/useMergedRef'
import { useTheme } from '@byecode/ui/providers'
import { createTheme } from '@byecode/ui/theme/stitches.config'
import { getElement } from '@byecode/ui/utils/getElement'
import { animated, useSpring } from '@react-spring/web'
import clsx from 'clsx'
import React, { forwardRef, useCallback, useEffect, useId, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { Box } from '../../Box'
import { FocusTrap } from '../../FocusTrap'
import { IconFont } from '../../IconFont'
import { modalManager } from '../ModalsProvider'
import { useStyles } from './BaseModal.style'
import type { BaseModalProps } from './BaseModal.type'

const DEFAULT_MODAL_TRANSITION = {
    from: { opacity: 0 },
    to: { opacity: 1 }
}

const ModalOverlay = ({ duration, style, ...props }: React.ComponentPropsWithoutRef<'div'> & { duration?: number }) => {
    const springStyle = useSpring({
        from: { opacity: 0 },
        to: { opacity: 0.65 },
        config: {
            duration
        }
    })
    return <animated.div style={{ ...springStyle, ...style }} {...props} />
}

const ModalContent = forwardRef<
    HTMLDivElement,
    React.ComponentPropsWithoutRef<'div'> & { duration?: number } & Pick<BaseModalProps, 'transition'>
>(({ transition, duration, style, ...props }, ref) => {
    const springStyle = useSpring({
        ...transition,
        config: {
            duration
        }
    })
    return <animated.div ref={ref} style={{ ...springStyle, ...style }} {...props} />
})

export const BaseModal = forwardRef<HTMLDivElement, BaseModalProps>((props, ref) => {
    const {
        className,
        styles,
        classNames,
        unstyled,
        position = 'absolute',
        zIndex = 200,
        width = 872,
        withCloseIcon = true,
        withOverlay = true,
        closeOnClickOverlay = true,
        closeOnEscape = true,
        trapFocus = true,
        transition = DEFAULT_MODAL_TRANSITION,
        duration = 100,
        target,
        leftSlot,
        extra,
        title,
        footer,
        children,

        open,
        onClose,
        onKeyDown,
        ...rest
    } = props
    const { classes } = useStyles({ zIndex, width, position }, { name: 'Modal', styles, classNames, unstyled })
    const { colorPrimary, colorPrimaryHover } = useTheme()
    const customBuiTheme = createTheme({ colors: { colorPrimary, colorPrimaryHover } })
    const _target = getElement(target) ?? document.body

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        onKeyDown?.(e)
        if (e.key === 'Escape' && closeOnEscape) {
            e.stopPropagation()
            onClose?.()
        }
    }

    const id = useId()

    useEffect(() => {
        if (open) {
            modalManager.add(id)
        }

        return () => {
            modalManager.remove(id)
        }
    }, [id, open])

    // const isTopModal = useCallback(() => closeOnEscape && modalManager.isTop(id), [closeOnEscape, id])

    if (!open) {
        return null
    }

    return createPortal(
        <Box ref={ref} className={clsx(classes.root, className, customBuiTheme.toString())} onKeyDown={handleKeyDown} {...rest}>
            {withOverlay && (
                <ModalOverlay
                    duration={duration}
                    className={classes.overlay}
                    onClick={closeOnClickOverlay ? () => onClose?.() : undefined}
                />
            )}
            <FocusTrap enable={trapFocus}>
                <ModalContent tabIndex={-1} transition={transition} duration={duration} className={classes.modal}>
                    {(title || leftSlot || extra || withCloseIcon) && (
                        <Box className={classes.header}>
                            {leftSlot && <Box>{leftSlot}</Box>}
                            <Box className={classes.title}>{title}</Box>

                            <Box className={classes.actions}>
                                {extra}
                                {withCloseIcon && (
                                    <Box component="button" className={classes.close} onClick={() => onClose?.()}>
                                        <IconFont type="Close" />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                    <Box className={classes.body}>{children}</Box>
                    {footer && <Box className={classes.footer}>{footer}</Box>}
                </ModalContent>
            </FocusTrap>
        </Box>,
        _target
    )
})
