import { Button, Toast } from '@byecode/ui'
import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import { isWechatBrowser } from '@lighthouse/tools'
import { Text } from '@mantine/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { ApplicationPreviewEnum } from '../../../types'
import LoginBox from '../LoginBox'
import * as CM from '../styles'
import type { AppLoginMode } from '../types'
import * as SC from './styles'

interface HomeProps {
    authentication: ApplicationSettingAuthentication
    language: string
    previewType?: ApplicationPreviewEnum
    isWxOfficialAccount?: boolean
    disableEvent?: boolean
    onChangeLanguage: (v: string) => void
    onWeChatLogin?: () => void
    onHomeBack?: () => void
    onChangeMode: (v: AppLoginMode) => void
}

export const Home: React.FunctionComponent<HomeProps> = ({
    authentication,
    language,
    previewType,
    isWxOfficialAccount,
    disableEvent,
    onChangeLanguage,
    onChangeMode,
    onHomeBack,
    onWeChatLogin
}) => {
    const { login, register, name, logo, describe } = authentication
    const { phone, weChat, google, email } = login
    const { t } = useTranslation()

    const isNoLoginWay = useMemo(
        () => !phone.isOpened && !weChat.isOpened && !google.isOpened && !email.isOpened,
        [email.isOpened, google.isOpened, phone.isOpened, weChat.isOpened]
    )

    const isShowRegister = useMemo(
        () => register.allowRegister && (email.isOpened || phone.isOpened),
        [email.isOpened, phone.isOpened, register.allowRegister]
    )

    if (isNoLoginWay) {
        return (
            <LoginBox
                name={name}
                logo={logo}
                describe={describe}
                language={language}
                previewType={previewType}
                onChangeLanguage={onChangeLanguage}
                customBtn={
                    <Button
                        style={{
                            height: 94,
                            background: 'var(--color-gray-100)',
                            color: 'var(--color-gray-400)',
                            marginTop: 26,
                            border: 'none',
                            cursor: 'not-allowed'
                        }}
                        radius={10}
                        block
                    >
                        {t('noNeedLogin')}
                    </Button>
                }
            />
        )
    }

    return (
        <LoginBox
            name={name}
            logo={logo}
            describe={describe}
            hideBackBtn
            language={language}
            previewType={previewType}
            onChangeLanguage={onChangeLanguage}
            // customBtn={
            //     isShowRegister && (
            //         <Button
            //             style={{ height: 44, marginTop: 26, backgroundColor: 'var(--color-app-main-tint)', borderStyle: 'none' }}
            //             onClick={() => onChangeMode('register')}
            //             radius={10}
            //             block
            //         >
            //             <Text size={16} color="var(--color-app-main)">
            //                 {t('registerAccount')}
            //             </Text>
            //         </Button>
            //     )
            // }
        >
            <SC.LoginWay>
                {phone.isOpened && (
                    <SC.LoginWayItem onClick={() => onChangeMode('mobileLogin')}>
                        <CM.Icon type="SmartphoneIconLibrary" color="var(--color-blue-500)" size={20} className="icon" />
                        <SC.LoginWayTitle> {t('mobileLogin')}</SC.LoginWayTitle>
                    </SC.LoginWayItem>
                )}
                {email.isOpened && (
                    <SC.LoginWayItem onClick={() => onChangeMode('emailLogin')}>
                        <CM.Icon type="LetterIconLibrary" color="var(--color-theme-7)" size={20} className="icon" />
                        <SC.LoginWayTitle> {t('emailLogin')}</SC.LoginWayTitle>
                    </SC.LoginWayItem>
                )}
                {weChat.isOpened && isWxOfficialAccount && (
                    <SC.LoginWayItem
                        onClick={() => {
                            if (disableEvent) {
                                return
                            }
                            const isWechat = isWechatBrowser()
                            if (isWxOfficialAccount) {
                                //  在微信浏览器则一键登陆
                                if (isWechat) {
                                    onWeChatLogin?.()
                                    return
                                }
                                //
                                weChat.isOpenedH5QrcodeLogin ? onChangeMode('weChatLogin') : Toast.warning(t('pleaseWeChatLoginInWeChatBrowser'))
                            }
                        }}
                    >
                        <CM.Icon type="WeChatLogoIconLibrary" color="var(--color-green-500)" size={20} className="icon" />
                        <SC.LoginWayTitle> {t('wechatLogin')}</SC.LoginWayTitle>
                    </SC.LoginWayItem>
                )}
                <SC.LoginWayItem
                    onClick={onHomeBack}
                    style={{ backgroundColor: 'var(--color-app-main-tint)', borderStyle: 'none', color: 'var(--color-app-main)' }}
                >
                    <SC.LoginWayTitle>{t('goHome')}</SC.LoginWayTitle>
                </SC.LoginWayItem>
            </SC.LoginWay>
        </LoginBox>
    )
}
