import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import type { AnyObject } from '@byecode/ui/types'
import clsx from 'clsx'
import { clone } from 'rambda'
import React, { useMemo, useState } from 'react'

import { Box } from '../../Box'
import { Button } from '../../Button'
import { Empty } from '../../Empty'
import { IconFont } from '../../IconFont'
import type { TableProps } from '../Table.type'
import { TableTd } from '../Td'

const useStyles = createStyles(() => ({
    content: css({}),
    table: css({
        width: '100%',
        tableLayout: 'fixed',
        borderCollapse: 'separate',
        borderSpacing: 0
    }),
    header: css({}),
    th: css({
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'start',
        padding: '9px 16px',
        backgroundColor: '$colorGray100',
        transition: 'all 0.2s',
        textEllipsis: true,
        '&.hasSorter': {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '$colorGray200'
            }
        }
    }),
    tr: css({}),
    'th-wrapper': css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }),
    'th-title': css({
        flex: 1
    }),
    'th-sorter': css({
        marginLeft: 4,
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        '.active': {
            color: '$colorPrimary'
        }
    }),
    'expand-icon': css({
        float: 'left'
    }),
    pagination: css({
        marginTop: 16,
        padding: '10px 0'
    })
}))

export type TableContentStyleNames = Selectors<typeof useStyles>

export interface TableContentProps<T extends AnyObject = AnyObject>
    extends Pick<TableProps<T>, 'data' | 'columns' | 'rowKey'>,
        StyleComponentProps<TableContentStyleNames>,
        React.ComponentPropsWithoutRef<'div'> {}

type IndentOfFlatData<T> = (T & { indent: number })[]

function getAllCanExpandKeys<T extends AnyObject>(data: T[], getRowKey: (r: T) => React.Key) {
    function rec(children: T[]): React.Key[] {
        return children.reduce<React.Key[]>((prev, next) => {
            if (Array.isArray(next.children) && next.children.length > 0) {
                return [...prev, getRowKey(next), ...rec(next.children)]
            }

            return [...prev]
        }, [])
    }
    return rec(data)
}

const SORTER_ORDER = ['UP', 'DOWN', undefined] as const

export const TableContent = <T extends AnyObject>(props: TableContentProps<T>) => {
    const { styles, classNames, unstyled, className, data, rowKey, columns, ...rest } = props

    const { classes } = useStyles({}, { name: 'table-content', styles, classNames, unstyled })

    const [sorter, setSorter] = useState<{ index: number; key: PropertyKey }>({ index: SORTER_ORDER.length - 1, key: '' })

    const getRowKey = useMemo(() => {
        return (record: T) => (typeof rowKey === 'function' ? rowKey(record) : record[rowKey])
    }, [rowKey])

    const [expandKeys, setExpandKeys] = useState<React.Key[]>(data ? getAllCanExpandKeys(data, getRowKey) : [])

    const sorterData = useMemo(() => {
        const _data = data ?? []
        const sorterType = SORTER_ORDER[sorter.index]
        if (!sorterType) {
            return _data
        }

        const sortBy = columns.find(item => item.key === sorter.key)?.sorter
        if (!sortBy) {
            return _data
        }

        const _sort = (a: T, b: T) => sortBy(a, b) * (sorterType === 'UP' ? 1 : -1)
        const recursionSort = (mutList: T[]) => {
            mutList.sort(_sort).forEach(item => {
                if (item.children) {
                    recursionSort(item.children)
                }
            })
        }

        const res = clone(_data)
        recursionSort(res)
        return res
    }, [columns, data, sorter.index, sorter.key])

    const flatData = useMemo(() => {
        const arr: IndentOfFlatData<T> = []
        function recursionFlatData<T extends AnyObject>(data: T[], indent: number) {
            data.forEach(item => {
                const key = getRowKey(item)

                arr.push({ ...item, indent })

                if (Array.isArray(item.children) && expandKeys.includes(key)) {
                    recursionFlatData(item.children, indent + 1)
                }
            })
        }

        recursionFlatData(sorterData, 0)

        return arr
    }, [sorterData, expandKeys, getRowKey])

    return (
        <Box className={clsx(classes.content, className)} {...rest}>
            <Box component="table" className={classes.table}>
                <Box component="colgroup">
                    {columns.map((col, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Box key={index} component="col" width={col.width} />
                    ))}
                </Box>
                <Box className={classes.header} component="thead">
                    <Box component="tr">
                        {columns.map((col, index) => (
                            <Box
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                component="th"
                                className={clsx(classes.th, { hasSorter: !!col.sorter })}
                                style={{ textAlign: col.align }}
                            >
                                <Box
                                    className={classes['th-wrapper']}
                                    onClick={() =>
                                        setSorter(s => ({
                                            key: col.key,
                                            index: col.key === s.key ? (s.index + 1) % SORTER_ORDER.length : 0
                                        }))
                                    }
                                >
                                    <Box component="span" className={classes['th-title']}>
                                        {col.title}
                                    </Box>
                                    {col.sorter && (
                                        <Box className={classes['th-sorter']}>
                                            <IconFont
                                                type="CareUp"
                                                size={8}
                                                className={clsx({ active: sorter.key === col.key && sorter.index === 0 })}
                                            />
                                            <IconFont
                                                type="CareDown"
                                                size={8}
                                                className={clsx({ active: sorter.key === col.key && sorter.index === 1 })}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box component="tbody">
                    {flatData.map(item => {
                        const key = getRowKey(item)
                        return (
                            <Box key={key} component="tr" className={classes.tr}>
                                {columns.map((col, colIndex) => (
                                    <TableTd
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={colIndex}
                                        style={{
                                            textAlign: col.align,
                                            paddingLeft: colIndex === 0 && item.indent !== 0 ? 16 + item.indent * 32 : undefined
                                        }}
                                    >
                                        {colIndex === 0 && Array.isArray(item.children) && item.children.length > 0 ? (
                                            <Button
                                                className={classes['expand-icon']}
                                                type="text"
                                                size="xs"
                                                onClick={() => {
                                                    setExpandKeys(s => (s.includes(key) ? s.filter(item => item !== key) : [...s, key]))
                                                }}
                                                icon={
                                                    <IconFont type="ArrowRightSmall" size={16} rotate={expandKeys.includes(key) ? 90 : 0} />
                                                }
                                            />
                                        ) : null}

                                        {col.formatter ? col.formatter(item[col.key], item) : item[col.key]}
                                    </TableTd>
                                ))}
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            {flatData.length === 0 && <Empty styles={{ root: { backgroundColor: '$colorGray25' } }} p={60} description="暂无数据" />}
        </Box>
    )
}
