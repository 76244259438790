import { Tooltip } from '@byecode/ui'
import type { DepartmentValue, FieldProtocol } from '@lighthouse/core'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { useApplicationContext } from '../../contexts'
import type { AppDepartment } from '../../types'
import type { TooltipListItem } from '../TooltipList'
import { TooltipList, useWidth } from '../TooltipList'

export interface DepartmentValuePreviewerProps extends Pick<React.CSSProperties, 'justifyContent'> {
    field: FieldProtocol
    data?: DepartmentValue
    width?: number
    isWrap?: boolean
    direction?: 'horizontal' | 'vertical'
    isShowId?: boolean
}

export interface DepartmentItemPreviewerProps {
    id: string
    data: AppDepartment
    isLast?: boolean
    direction?: 'horizontal' | 'vertical'
    isShowId?: boolean
}

const SCxDepartmentListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean } & Pick<React.CSSProperties, 'justifyContent'>>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent};
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    overflow: hidden;
`

const SCxDepartmentItemWrapper = styled.div<{ enableOverFlow?: boolean; direction?: 'horizontal' | 'vertical' }>`
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    background-color: var(--color-gray-200);
    border-radius: 4px;
    padding: 0 6px;

    ${({ direction }) =>
        direction === 'vertical'
            ? css`
                  width: 22px;
                  margin-bottom: 6px;
              `
            : css`
                  height: 22px;
                  line-height: 22px;
              `}

    ∏
    &:nth-last-of-type(1) {
        margin-right: 0;
    }
`

const SCxDepartmentName = styled.div`
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
`

const SCxDepartmentInfo = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
`

const SCxDepartmentDescribe = styled.span`
    margin-left: 4px;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    overflow: hidden;
    text-overflow: ellipsis;
`
// const SCxCellValue = styled.div<{ isLast?: boolean }>`
//     line-height: 16px;
//     padding: 4px 6px;
//     height: 24px;
//     color: var(--color-black);
//     font-size: var(--font-size-normal);
//     border-radius: 4px;
//     background-color: var(--color-gray-200);
//     white-space: nowrap;
//     ${({ isLast }) => isLast && singleTextEllipsis}
// `

export const DepartmentItemPreviewer: React.FC<DepartmentItemPreviewerProps> = ({
    id,
    data,
    isLast = false,
    direction,
    isShowId
}) => {
    const { id: departmentId, name, labelPath } = data
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)
    return (
        <Tooltip title={labelPath}>
            <SCxDepartmentItemWrapper key={departmentId} enableOverFlow={isLast} ref={widthRef} direction={direction}>
                <SCxDepartmentInfo>
                    <SCxDepartmentName>{name}</SCxDepartmentName>
                    {isShowId && <SCxDepartmentDescribe>{departmentId}</SCxDepartmentDescribe>}
                </SCxDepartmentInfo>
            </SCxDepartmentItemWrapper>
        </Tooltip>
    )
}

export const DepartmentValuePreviewer: React.FC<DepartmentValuePreviewerProps> = ({
    field,
    data = [],
    width = 160,
    isWrap = true,
    justifyContent = 'flex-start',
    direction,
    isShowId
}) => {
    const { departmentOptions } = useApplicationContext()
    const list = useMemo(() => {
        return data.reduce<TooltipListItem[]>((list, id) => {
            const department = find(item => item.id === id, departmentOptions || [])
            if (!department) {
                return list
            }
            list.push({
                label: department.name,
                value: department.id
            })
            return list
        }, [])
    }, [data, departmentOptions])

    return (
        <TooltipList
            data={list.map((item, index) => ({ label: item.label, value: String(index) }))}
            width={width}
            key={data.join('-')}
            disabled={isWrap}
            render={visibleNum => {
                const renderList = list.slice(0, visibleNum)
                return (
                    <SCxDepartmentListWrapper justifyContent={justifyContent} isPadding={visibleNum < data.length} isWrap={isWrap}>
                        {renderList.map((item, index) => {
                            const department = find(department => department.id === item.value, departmentOptions || [])
                            if (!department) {
                                return null
                            }
                            return (
                                <DepartmentItemPreviewer
                                    key={item.value}
                                    id={String(index)}
                                    direction={direction}
                                    data={department}
                                    isLast={index === renderList.length - 1}
                                    isShowId={isShowId}
                                />
                            )
                        })}
                    </SCxDepartmentListWrapper>
                )
            }}
        />
    )
}
