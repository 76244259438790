import React, { useMemo } from 'react'

import type { FunctionObject } from '../../../constant'
import * as SC from './styles'

interface FormulaDescribeProps {
    data: FunctionObject | null
}

export const FormulaDescribe: React.FC<FormulaDescribeProps> = ({ data }) => {
    const isEmptyInput = useMemo(() => data?.input && data.input?.length > 0, [data?.input])

    return (
        <SC.FormulaDescribe>
            {data && (
                <>
                    <SC.Name>{data.title}</SC.Name>
                    <SC.Describe>{data.describe}</SC.Describe>
                    <SC.List>
                        {data.usage?.map((item, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <SC.Usage key={i}>
                                <SC.Example>
                                    <SC.Title>{item.title}</SC.Title>
                                    {item.describe && <SC.UsageContent>{item.describe}</SC.UsageContent>}
                                </SC.Example>
                                <SC.Help>
                                    {item?.examples.map((v, y) => (
                                        <SC.HelpContent key={y}>{v}</SC.HelpContent>
                                    ))}
                                </SC.Help>
                            </SC.Usage>
                        ))}
                    </SC.List>
                    {isEmptyInput && (
                        <SC.ParamDescribe>
                            <SC.Title>参数</SC.Title>
                            <SC.Table>
                                {data?.input?.map((item, i) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <SC.Tr key={i}>
                                        <SC.Td>
                                            <SC.InputTitle>{item.title}</SC.InputTitle>
                                        </SC.Td>
                                        <SC.Td>
                                            {typeof item.content === 'string' ? (
                                                <SC.InputDescribe>{item.content}</SC.InputDescribe>
                                            ) : (
                                                item.content.map((des, index) => <SC.InputDescribe key={index}>{des}</SC.InputDescribe>)
                                            )}
                                        </SC.Td>
                                    </SC.Tr>
                                ))}
                            </SC.Table>
                        </SC.ParamDescribe>
                    )}
                    {data?.output && (
                        <SC.ParamDescribe>
                            <SC.Title>返回</SC.Title>
                            <SC.Describe>{data?.output}</SC.Describe>
                        </SC.ParamDescribe>
                    )}
                </>
            )}
        </SC.FormulaDescribe>
    )
}
