import type {
    BarChartOptionProtocol,
    ChartLikeProtocol,
    ChartOptionProtocol,
    ChartType,
    IndicatorOption,
    PieChartOptionProtocol,
    StriationChartOptionProtocol
} from '@lighthouse/core'

import getBarOption from './bar'
import getFunnelOption from './funnel'
import getIndicatorOption from './indicator'
import getPieOption from './pie'
import getStriationOption from './striation'

export const getChartOption = (
    chartType: ChartType,
    chartViewOption: ChartOptionProtocol | IndicatorOption,
    chartData?: ChartLikeProtocol
) => {
    switch (chartType) {
        case 'composite': {
            return getBarOption(chartViewOption as BarChartOptionProtocol, chartData)
        }
        case 'striation': {
            return getStriationOption(chartViewOption as StriationChartOptionProtocol, chartData)
        }
        case 'bar': {
            return getBarOption(chartViewOption as BarChartOptionProtocol, chartData)
        }
        case 'line': {
            return getBarOption(chartViewOption as BarChartOptionProtocol, chartData)
        }
        case 'pie': {
            return getPieOption(chartViewOption as PieChartOptionProtocol, chartData)
        }
        case 'funnel': {
            return getFunnelOption(chartViewOption as PieChartOptionProtocol, chartData)
        }
        case 'indicator': {
            return getIndicatorOption(chartViewOption as IndicatorOption, chartData)
        }
        default: {
            return getBarOption(chartViewOption as BarChartOptionProtocol, chartData)
        }
    }
}
