import { useCallback, useEffect, useRef, useState } from 'react'

export function useTouch(drawerElement: HTMLDivElement | null) {
    const [distance, setDistance] = useState<number>(0)
    const positionRef = useRef({ startY: 0 })

    const handleTouchMove = useCallback(
        (event: TouchEvent) => {
            if (drawerElement) {
                const element = event.targetTouches[0]
                const { startY } = positionRef.current
                const offsetY = element.clientY - startY
                // const range = Math.abs(offsetY)
                setDistance(offsetY)
            }
        },
        [drawerElement]
    )

    const handleTouchEnd = useCallback(() => {
        document.removeEventListener('touchmove', handleTouchMove)
        document.removeEventListener('touchend', handleTouchEnd)
    }, [handleTouchMove])

    const handleTouchStart = useCallback(
        (event: TouchEvent) => {
            event.stopPropagation()
            const element = event.targetTouches[0]
            positionRef.current = { startY: element.clientY }
            document.addEventListener('touchmove', handleTouchMove)
            document.addEventListener('touchend', handleTouchEnd)
        },
        [handleTouchEnd, handleTouchMove]
    )

    useEffect(() => {
        if (drawerElement) {
            drawerElement.addEventListener('touchstart', handleTouchStart)
            return () => {
                drawerElement.removeEventListener('touchstart', handleTouchStart)
            }
        }
    }, [drawerElement, handleTouchStart])

    return distance
}
