import type { OffsetOptions, Placement, ReferenceType, ShiftOptions, Strategy } from '@floating-ui/react'
import {
    arrow,
    autoUpdate as autoUpdatePlugin,
    flip,
    FloatingNode,
    limitShift,
    offset,
    safePolygon,
    shift,
    size,
    useClick,
    useDismiss,
    useFloating,
    useFloatingNodeId,
    useFloatingTree,
    useHover,
    useInteractions,
    useRole
} from '@floating-ui/react'
import { max } from 'rambda'
import * as React from 'react'
import { useCallback, useEffect, useRef } from 'react'
import { useUpdateEffect } from 'react-use'

import { useUncontrolled } from '../../hooks/useUncontrolled'
import { useContextMenu } from './hooks/useContextMenu'
import type { PopoverOutsideEvent, PopoverTrigger, PopoverWidth } from './Popover.context'
import { PopoverProvider } from './Popover.context'
import { PopoverAnchorEl } from './PopoverAnchorEl/PopoverAnchorEl'
import { PopoverDropdown } from './PopoverDropdown'
import { PopoverTarget } from './PopoverTarget'

export interface BasePopoverProps {
    disabled?: boolean
    onClose?: () => void
    onOpen?: () => void
    target?: HTMLElement | null
    width?: PopoverWidth
    position?: Placement
    trigger?: PopoverTrigger
    onPositionChange?: (position: Placement) => void
    positionDependencies?: React.DependencyList
    zIndex?: React.CSSProperties['zIndex']
    offsetOptions?: OffsetOptions
    shiftOptions?: ShiftOptions
    withinPortal?: boolean
    withArrow?: boolean
    closeClickOutside?: boolean
    arrowOffset?: number
    arrowSize?: number
    closeOnEscape?: boolean
    outsidePressEvent?: PopoverOutsideEvent
    outsidePress?: boolean | ((event: MouseEvent) => boolean)
    enableClickToggle?: boolean
    strategy?: Strategy
    enableFlip?: boolean
    withinOverlay?: boolean
    trapFocus?: boolean
    arrowColor?: string
    minWidth?: number
    positionReference?: ReferenceType
    autoUpdate?: boolean
}

export interface PopoverProps extends BasePopoverProps {
    children: React.ReactNode
    defaultOpened?: boolean
    opened?: boolean
    onChange?: (opened: boolean) => void
    returnFocus?: boolean
}

const DEFAULT_POSITION_DEPS: unknown[] = []
const DEFAULT_SHIFT_OPTIONS = { limiter: limitShift() }

export const Popover = (props: PopoverProps) => {
    const {
        children,
        defaultOpened,
        opened,
        onChange,
        onClose,
        onOpen,
        target,
        width = 'target',
        position = 'bottom',
        trigger = 'click',
        onPositionChange,
        positionDependencies = DEFAULT_POSITION_DEPS,
        closeClickOutside,
        offsetOptions = 5,
        shiftOptions = DEFAULT_SHIFT_OPTIONS,
        withArrow = false,
        arrowSize = 5,
        arrowOffset = 5,
        zIndex = 200,
        closeOnEscape = true,
        outsidePressEvent,
        outsidePress = true,
        enableClickToggle = true,
        strategy,
        withinPortal = false,
        trapFocus = true,
        returnFocus = true,
        enableFlip = true,
        disabled = false,
        withinOverlay = false,
        minWidth = 0,
        positionReference,
        autoUpdate = true,
        arrowColor
    } = props

    const [_opened, _setOpened] = useUncontrolled({ value: opened, defaultValue: defaultOpened, onChange })

    const _onClose = () => {
        onClose?.()
        _setOpened(false)
    }

    const _onOpen = () => {
        onOpen?.()
        _setOpened(true)
    }

    const onToggle = () => {
        _opened ? _onClose() : _onOpen()
    }

    const arrowRef = useRef<SVGSVGElement | null>(null)

    const nodeId = useFloatingNodeId()
    const tree = useFloatingTree()

    const { context, refs, update, x, y, placement } = useFloating({
        nodeId,
        open: _opened,
        onOpenChange: v => {
            v ? _onOpen() : _onClose()
            // _setOpened(v)
        },
        placement: position,
        strategy,
        middleware: [
            offset(offsetOptions),
            shift(shiftOptions),
            enableFlip ? flip() : undefined,
            arrow({ element: arrowRef, padding: arrowOffset }),
            ...(width === 'target'
                ? [
                      size({
                          apply({ rects, elements }) {
                              Object.assign(elements.floating.style, { width: `${max(minWidth, rects.reference.width)}px` })
                          }
                      })
                  ]
                : [])
        ],
        whileElementsMounted: autoUpdate ? autoUpdatePlugin : undefined
    })

    useEffect(() => {
        if (positionReference) {
            refs.setPositionReference(positionReference)
        }
    }, [positionReference, refs])

    useEffect(() => {
        function closeAllHandler() {
            _setOpened(false)
        }
        tree?.events.on('closeAll', closeAllHandler)
        return () => {
            tree?.events.off('closeAll', closeAllHandler)
        }
    }, [_setOpened, tree])

    // const focus = useFocus(context, { enabled: !disabled })
    const hover = useHover(context, { enabled: !disabled && trigger === 'hover' && !closeClickOutside, delay: { open: 75 }, handleClose: safePolygon() })
    const click = useClick(context, { enabled: !disabled && trigger === 'click', toggle: enableClickToggle })
    const contextMenu = useContextMenu(context, { enabled: !disabled && trigger === 'contextMenu' })
    const role = useRole(context, { role: 'menu' })
    const dismiss = useDismiss(context, {
        enabled: !disabled,
        escapeKey: closeOnEscape,
        outsidePressEvent,
        outsidePress
    })
    const interactionProps = useInteractions([hover, click, contextMenu, role, dismiss])

    useUpdateEffect(() => {
        onPositionChange?.(placement)
    }, [onPositionChange, placement])

    useUpdateEffect(() => {
        update()
    }, positionDependencies)

    const reference = useCallback(
        (node: ReferenceType) => {
            refs.setReference(node)
        },
        [refs]
    )

    const floating = useCallback(
        (node: HTMLElement) => {
            refs.setFloating(node)
        },
        [refs]
    )

    return (
        <FloatingNode id={nodeId}>
            <PopoverProvider
                value={{
                    context,
                    x,
                    y,
                    interactionProps,
                    opened: _opened,
                    reference,
                    floating,
                    withArrow,
                    arrowSize,
                    arrowRef,
                    arrowOffset,
                    width,
                    trapFocus,
                    placement,
                    withinPortal,
                    target,
                    closeOnEscape,
                    zIndex,
                    onToggle,
                    onClose: _onClose,
                    disabled,
                    arrowColor,
                    returnFocus,
                    withinOverlay
                }}
            >
                {children}
            </PopoverProvider>
        </FloatingNode>
    )
}

Popover.Target = PopoverTarget
Popover.Dropdown = PopoverDropdown
Popover.AnchorEl = PopoverAnchorEl
