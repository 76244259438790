import { Empty, IconFont, MobileModal } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import type { DrawerProps } from '@mantine/core'
import { clone, findIndex } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect } from 'react-use'

import { UserAvatar } from '../../UserAvatar'
import * as SC from './styles'

interface PersonDrawerProps {
    opened: boolean
    isMultiple?: boolean
    leftIcon?: string
    selectOptions: AppUser[]
    value: string[]
    title: string
    target: DrawerProps['target']
    onChange?: (val: string[]) => void
    onClose: () => void
}

export const PersonDrawer: React.FC<PersonDrawerProps> = ({
    opened,
    leftIcon,
    isMultiple = false,
    selectOptions,
    title,
    value,
    target,
    onClose,
    onChange
}) => {
    const [selectValue, setSelectValue] = useState(value)
    const [searchVal, setSearchVal] = useState('')

    const { t } = useTranslation()

    useUpdateEffect(() => {
        if (value !== selectValue) {
            setSelectValue(value)
        }
    }, [value])

    const handleSelectVal = useCallback(
        (val: string) => {
            const cloneSelectValue = clone(selectValue)
            const index = findIndex(id => id === val, cloneSelectValue)
            if (index >= 0) {
                cloneSelectValue.splice(index, 1)
                return cloneSelectValue
            }
            if (isMultiple) {
                cloneSelectValue.push(val)
                return cloneSelectValue
            }
            return [val]
        },
        [isMultiple, selectValue]
    )

    const handleFinish = useCallback(() => {
        onChange?.(selectValue)
        onClose()
    }, [onChange, onClose, selectValue])

    const handleSelectChange = useCallback(
        (val: string) => {
            const newValue = handleSelectVal(val)
            setSelectValue(newValue)
            if (!isMultiple) {
                onChange?.([val])
                onClose()
            }
        },
        [handleSelectVal, isMultiple, onChange, onClose]
    )

    const list = useMemo(
        () =>
            selectOptions.filter(
                item =>
                    item.userId.toLocaleLowerCase().includes(searchVal.toLocaleLowerCase()) ||
                    item.username.toLocaleLowerCase().includes(searchVal.toLocaleLowerCase())
            ),
        [searchVal, selectOptions]
    )

    const renderAvatar = useCallback((item: AppUser) => {
        const { userId, username, avatar } = item
        if (userId === '{currentUserId}') {
            return <IconFont size={22} type="UserCircle" />
        }
        return <UserAvatar id={userId} name={username} avatar={avatar} />
    }, [])

    return (
        <MobileModal
            leftSlot={!!leftIcon && <IconFont type={leftIcon} />}
            withCloseIcon={!isMultiple}
            data-ignore-click-away
            target={target}
            title={title}
            open={opened}
            styles={{
                modal: {
                    height: '50%'
                }
            }}
            onClose={onClose}
            extra={
                isMultiple ? (
                    <SC.FinishButton role="button" onClick={handleFinish}>
                        完成
                    </SC.FinishButton>
                ) : null
            }
        >
            <SC.Container>
                <SC.SearchInput
                    value={searchVal}
                    placeholder={t('personNameOrPersonId')}
                    onChange={e => setSearchVal(e.currentTarget.value)}
                    onClear={() => setSearchVal('')}
                    size="md"
                    clearable
                    prefix={<IconFont type="SearchLine" />}
                    styles={{
                        wrapper: {
                            width: '100%'
                        }
                    }}
                />
                {list.length > 0 ? (
                    <SC.TagList>
                        <SC.TagWrapper>
                            {list.map(item => (
                                <SC.TagItem key={item.userId} onClick={() => handleSelectChange(item.userId)}>
                                    <SC.LeftFill>
                                        {renderAvatar(item)}
                                        <SC.Title>{item.username}</SC.Title>
                                    </SC.LeftFill>
                                    {selectValue.includes(item.userId) && <SC.Icon type="Tick" />}
                                </SC.TagItem>
                            ))}
                        </SC.TagWrapper>
                    </SC.TagList>
                ) : (
                    <Empty
                        styles={{
                            root: {
                                padding: '42px'
                            }
                        }}
                        icon="SearchLine"
                        description={t('noFindPerson')}
                    />
                )}
            </SC.Container>
        </MobileModal>
    )
}
