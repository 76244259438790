import type { ApplicationSettingNavbar, ApplicationSettingNavbarButton, ApplicationSettingTheme, ButtonEvents } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import { Collapse, Drawer, Text } from '@mantine/core'
import { useElementSize, useFocusReturn } from '@mantine/hooks'
import cls from 'classnames'
import { clone, reduce, splitAt } from 'rambda'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useCss, useMeasure, useWindowSize } from 'react-use'

import { useApplicationContext } from '../../../contexts'
import { RowMaxTabNum } from '../constant'
import type { ApplicationNavbarHierarchyLevelButton } from '../help'
import { getFlapChidList } from '../help'
import * as CM from '../styles'
import type { ApplicationContainerPreviewMode } from '../type'
import * as SC from './styles'

interface SelectItemProps {
    data: Record<string, ApplicationSettingNavbarButton>
    id: string
    level: number
    activePage?: string
    onCommonCallBack?: (params: ButtonEvents) => void
}

export const SelectItem: React.FunctionComponent<SelectItemProps> = ({ id, data, level, activePage, onCommonCallBack }) => {
    const {
        icon = '',
        name,
        child = [],
        showType,
        events: { handleEvent, params },
        events
    } = data[id]
    const defaultOpened = useMemo(
        () =>
            getFlapChidList(child, data, []).some(item => {
                const {
                    events: { params, handleEvent }
                } = item
                const pageId = params[0]
                return handleEvent === 'openPage' && pageId.replace('/page/', '') === activePage?.replace('/page/', '') && !!pageId
            }),
        [activePage, child, data]
    )
    const [open, setOpen] = useState(defaultOpened)

    const pageId = params[0]
    const active =
        handleEvent === 'openPage' && pageId.replace('/page/', '') === activePage?.replace('/page/', '') && !!pageId && child?.length === 0

    const isHideName = useMemo(() => showType === 'icon' || !name, [name, showType])

    const isHideIcon = useMemo(() => showType === 'name' || !icon, [icon, showType])

    return (
        <>
            <SC.Item
                level={level}
                className={cls({ active })}
                onClick={() => ((child?.length ?? 0) > 0 ? setOpen(!open) : onCommonCallBack?.(events))}
            >
                <SC.LeftFill>
                    {isHideIcon || <CM.Icon size={20} type={icon} />}
                    {isHideName || (
                        <CM.Text size={16} isOmit style={{ lineHeight: '20px' }}>
                            {name}
                        </CM.Text>
                    )}
                </SC.LeftFill>
                {child.length > 0 && <CM.Icon size={20} type={open ? 'ArrowUpSmall' : 'ArrowDownSmall'} />}
            </SC.Item>
            <Collapse in={open}>
                {child.map(nodeId => (
                    <SelectItem
                        key={nodeId}
                        level={level + 1}
                        activePage={activePage}
                        data={data}
                        id={nodeId}
                        onCommonCallBack={onCommonCallBack}
                    />
                ))}
            </Collapse>
        </>
    )
}

interface ApplicationTabBarProps {
    navbar: ApplicationSettingNavbar
    theme: ApplicationSettingTheme
    activePage?: string
    previewMode: ApplicationContainerPreviewMode
    disableEvent?: boolean
    selected?: boolean
    onCommonCallBack?: (params: ButtonEvents) => void
    onSelect?: (selected: boolean) => void
}

export const ApplicationTabBar: React.FC<ApplicationTabBarProps> = ({
    navbar,
    theme,
    activePage,
    disableEvent,
    previewMode,
    selected,
    onSelect,
    onCommonCallBack
}) => {
    const [activeId, setActiveId] = useState('')
    const { ref: containerRef, height } = useElementSize<HTMLDivElement>()
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [fold, setFold] = useState(false)
    const {
        linkList: { child: linkChild, list },
        isShow
    } = navbar

    const child = useMemo(() => linkChild.filter(linkId => list[linkId].isAccess), [linkChild, list])

    const { pageTarget } = useApplicationContext()

    const isHideTabBar = useMemo(() => !isShow || child.length < 2, [child.length, isShow])

    useEffect(() => {
        const mainEle = document.querySelector<HTMLElement>('#app-shell-screen')

        if (mainEle && isHideTabBar && previewMode === 'mobile') {
            mainEle.setAttribute('style', 'bottom: 0px!important')
        }
    }, [isHideTabBar, previewMode])

    const [firstChild, nextChild] = useMemo(() => {
        const [firstChild, nextChild] = splitAt(child.length > RowMaxTabNum ? RowMaxTabNum - 1 : RowMaxTabNum, child)
        const newNextChild = nextChild.reduce<string[][]>((preVal, curVal, curIndex) => {
            const curPage = Math.floor(curIndex / RowMaxTabNum)
            const numKey = curIndex % RowMaxTabNum
            const newPreVal = clone(preVal)
            if (numKey === 0) {
                newPreVal[curPage] = []
            }
            newPreVal[curPage].push(curVal)
            return newPreVal
        }, [])
        return [firstChild, newNextChild]
    }, [child])

    const tabBarList = useMemo(() => (fold ? [...nextChild.toReversed(), firstChild] : [firstChild]), [firstChild, fold, nextChild])

    const isActive = useMemo(() => {
        const newChildIds = reduce<string[], ApplicationNavbarHierarchyLevelButton[]>(
            (preVal, curVal) => [...preVal, ...getFlapChidList(curVal, list, [])],
            [],
            nextChild
        )
        if (newChildIds.length === 0) {
            return false
        }
        return newChildIds.some(item => {
            const {
                events: { params, handleEvent },
                child
            } = item
            const pageId = params[0]
            return (
                handleEvent === 'openPage' &&
                pageId.replace('/page/', '') === activePage?.replace('/page/', '') &&
                !!pageId &&
                child?.length === 0
            )
        })
    }, [activePage, list, nextChild])

    const getButtonActive = useCallback(
        (id: string) => {
            const node = list[id]
            if (!node) {
                return false
            }

            const {
                child = [],
                events: { handleEvent, params }
            } = node
            if (child?.length === 0 && handleEvent === 'openPage') {
                const pageId = params[0].replace('/page/', '')
                return pageId === activePage && !!pageId
            }
            const flatChild = getFlapChidList(child, list, [])
            return flatChild.some(item => {
                const {
                    events: { params, handleEvent },
                    child
                } = item
                const pageId = params[0]
                return handleEvent === 'openPage' && pageId.replace('/page/', '') === activePage?.replace('/page/', '') && !!pageId
            })
        },
        [activePage, list]
    )

    const handleCommonCallBack = useCallback(
        (events: ButtonEvents) => {
            setFold(false)
            setActiveId('')
            onCommonCallBack?.(events)
        },
        [onCommonCallBack, setFold, setActiveId]
    )

    // 渲染完成后滚动到底部
    useLayoutEffect(() => {
        requestAnimationFrame(() => {
            if (wrapperRef?.current) {
                wrapperRef.current.scrollTo({ top: wrapperRef.current.scrollHeight })
            }
        })
    }, [fold])

    if (isHideTabBar) {
        return null
    }

    return (
        <>
            <SC.Container
                disableEvent={disableEvent}
                isMobile={previewMode === 'mobile'}
                onClick={() => {
                    onSelect?.(true)
                }}
                ref={containerRef}
            >
                <SC.Wrapper
                    ref={wrapperRef}
                    selected={selected}
                    isActive={Boolean(activeId)}
                    className={cls({ simulationMobile: previewMode === 'simulationMobile' })}
                >
                    {tabBarList.map((row, index) => {
                        const rowNum =
                            tabBarList.length > 1
                                ? index === 0
                                    ? 0
                                    : index === tabBarList.length - 1
                                    ? RowMaxTabNum
                                    : row.length
                                : row.length
                        const className = `row_tab_${rowNum}`
                        return (
                            <SC.RowContain key={index} className={className}>
                                {row.map(id => {
                                    const node = list[id]
                                    const { child = [] } = node
                                    const color = getButtonActive(id) ? 'var(--color-app-main)' : 'var(--color-gray-400)'
                                    return (
                                        <SC.Box
                                            key={id}
                                            // 当前导航链接有子集时打开抽屉显示子链接，否则触发通用点击回调
                                            onClick={e => {
                                                if (child?.length > 0) {
                                                    setActiveId(activeId ? '' : id)
                                                    return
                                                }
                                                handleCommonCallBack?.(list[id].events)
                                            }}
                                        >
                                            <CM.Icon size={20} color={color} type={list[id].icon ?? 'Cube'} />
                                            <SC.BoxFooter>
                                                {(list[id].child?.length ?? 0) > 0 && <CM.Icon color={color} size={12} type="Move" />}
                                                <Text lineClamp={1} style={{ lineHeight: '14px' }} weight={500} color={color} size={10}>
                                                    {list[id].name}
                                                </Text>
                                            </SC.BoxFooter>
                                        </SC.Box>
                                    )
                                })}
                                {!fold && child.length > RowMaxTabNum && index === 0 && (
                                    <SC.Box onClick={() => setFold(true)}>
                                        <CM.Icon
                                            size={24}
                                            color={isActive ? 'var(--color-app-main)' : 'var(--color-gray-400)'}
                                            type="DotsThree"
                                        />
                                        <SC.BoxFooter>
                                            <Text
                                                lineClamp={1}
                                                style={{ lineHeight: '14px' }}
                                                color={isActive ? 'var(--color-app-main)' : 'var(--color-gray-400)'}
                                                weight={500}
                                                size={10}
                                            >
                                                更多
                                            </Text>
                                        </SC.BoxFooter>
                                    </SC.Box>
                                )}
                                {/* 在最后一行且抽屉打开时展示取消按钮 */}
                                {fold && child.length > RowMaxTabNum && index === tabBarList.length - 1 && (
                                    <SC.Box onClick={() => setFold(false)}>
                                        <CM.Icon size={24} color={fold ? 'var(--color-app-main)' : 'var(--color-gray-400)'} type="Close" />
                                        <SC.BoxFooter>
                                            <Text
                                                lineClamp={1}
                                                style={{ lineHeight: '14px' }}
                                                color={fold ? 'var(--color-app-main)' : 'var(--color-gray-400)'}
                                                weight={500}
                                                size={10}
                                            >
                                                取消
                                            </Text>
                                        </SC.BoxFooter>
                                    </SC.Box>
                                )}
                            </SC.RowContain>
                        )
                    })}
                    <Drawer
                        opened={Boolean(activeId)}
                        styles={{
                            root: {
                                // position: previewMode === 'mobile' ? 'fixed' : 'absolute',
                                // width: '100%',
                                bottom:
                                    previewMode === 'mobile'
                                        ? window.innerHeight - containerRef?.current?.getBoundingClientRect().top
                                        : height
                            },
                            drawer: {
                                position: 'absolute',
                                boxShadow: 'none',
                                height: 'auto',
                                borderRadius: '12px 12px 0 0',
                                border: previewMode === 'mobile' ? 'none' : '3px solid var(--color-main)',
                                borderBottom: 'none'
                            }
                        }}
                        target={pageTarget}
                        zIndex={200}
                        withinPortal
                        withCloseButton={false}
                        position="bottom"
                        onClose={() => setActiveId('')}
                    >
                        <SC.DrawerContain>
                            {(list[activeId]?.child ?? [])?.map(nodeId => {
                                return (
                                    <SelectItem
                                        key={nodeId}
                                        id={nodeId}
                                        data={list}
                                        activePage={activePage}
                                        level={0}
                                        onCommonCallBack={handleCommonCallBack}
                                    />
                                )
                            })}
                        </SC.DrawerContain>
                    </Drawer>
                </SC.Wrapper>
                {previewMode === 'simulationMobile' && <SC.IphoneSafeArea />}
            </SC.Container>
        </>
    )
}
