import type { RichTextContentProtocol } from '@lighthouse/core'
import {
    type ButtonAction,
    type DataSourceAbstract,
    type FilterCommonCondition,
    type GroupConfigure,
    type QuickFilterData,
    type Sorter,
    type TableColumns,
    type ViewActionGroupProtocol,
    type ViewField,
    type ViewType,
    DataSourceType} from '@lighthouse/core'
import { BreakPointSize } from '@lighthouse/tools'
import { find } from 'rambda'
import React, { useMemo, useRef } from 'react'

import type { ViewAppendParams } from '../../types'
import { getIsIncludesDisableDataSourceInWorkFlow } from '../../utils'
import { BlockTitle } from '../BlockTitle'
import { QuickFilterDrawer } from '../QuickFilterDrawer'
import { DebounceSearch } from '../Search'
import { Display, Group, Import, Sort } from '../TableSetting'
import { KanbanGroup } from '../TableSetting/KanbanGroup'
import { TailActionButtonGroup } from '../TailActionButtonGroup'
import { FilterActor } from '../ViewFieldFilterConfigure'
import * as SC from './styles'

const SIZE_MAP = new Map([
    [BreakPointSize.lg, 24],
    [BreakPointSize.md, 22],
    [BreakPointSize.sm, 22],
    [BreakPointSize.xs, 22]
])

type ViewSettingToolbarStyles = 'root'
export interface RelatedParams {
    recordId: string
    dataSourceId: string
    appId: string
    relatedDataSourceId: string
    relatedFieldId: string
    relatedAppId: string
}
export interface ViewSettingToolbarProps {
    appId: string
    envId: string
    dsId: string
    viewType: ViewType
    required?: boolean
    showTitle?: boolean
    title?: string
    search?: string
    // exportTemplateList?: Option[]
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    userImportSetting?: string[]
    userSortSetting?: string[]
    kanbanGroupConfig?: GroupConfigure
    onKanbanGroupConfigChange?: (v: GroupConfigure) => void
    quickFilter?: QuickFilterData
    settingColumns: ViewField[]
    columns: ViewField[]
    sort?: Sorter[]
    group?: GroupConfigure
    enableGroup?: boolean
    // selectedRecords?: string[]
    // selectedMode?: SelectedMode
    // rowTotal?: number
    enableAddRecord?: boolean
    // enableDeleteRecord?: boolean
    enableImport?: boolean
    // enableExport?: boolean
    // enablePrint?: boolean
    enableSort?: boolean
    enableDisplay?: boolean
    enableSetKanban?: boolean
    enableSearch?: boolean
    addRecordText?: string
    breakPoint: BreakPointSize
    styles?: Partial<Record<ViewSettingToolbarStyles, React.CSSProperties>>
    toolbarActions?: ViewActionGroupProtocol
    onFilter?: (data: FilterCommonCondition[]) => void
    onSearch?: (value: string) => void
    onAdd?: () => void
    // onSelectModeChange?: (mode?: SelectedMode) => void
    // onDelete?: (dsId: string, ids: string[], selectedMode?: SelectedMode) => void
    onChangeSorter?: (data?: Sorter[]) => void
    onChangeDisplay?: (data?: TableColumns) => void
    onChangeGroup?: (data: GroupConfigure) => void
    onAppend?: (params: ViewAppendParams) => void
    // onExport?: () => void
    // onPrint?: (templateId: string) => void
    onActionTrigger?: (params: ButtonAction) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol) => string
}

export const ViewSettingToolbar: React.FC<ViewSettingToolbarProps> = ({
    appId,
    envId,
    dsId,
    viewType,
    required,
    showTitle,
    title,
    search,
    dataSource,
    dataSourceList,
    userImportSetting,
    userSortSetting,
    quickFilter,
    settingColumns,
    columns,
    sort,
    group,
    enableGroup,
    kanbanGroupConfig,
    // exportTemplateList,
    // selectedRecords = [],
    // selectedMode,
    // rowTotal,
    enableAddRecord,
    // enableDeleteRecord,
    enableImport,
    // enableExport,
    // enablePrint,
    enableSort,
    enableDisplay,
    enableSetKanban,
    enableSearch = false,
    addRecordText = '添加',
    breakPoint,
    styles,
    toolbarActions,
    onKanbanGroupConfigChange,
    onFilter,
    onSearch,
    onAdd,
    // onDelete,
    // onSelectModeChange,
    onChangeSorter,
    onChangeDisplay,
    onChangeGroup,
    onAppend,
    // onExport,
    // onPrint,
    onActionTrigger,
    onRenderButtonTitle
}) => {
    // const [printModalOpen, setPrintModalOpen] = useToggle(false)
    const actionRef = useRef<HTMLDivElement>(null)
    const { schema } = dataSource
    const quickFilterRules = quickFilter?.rules
    const isExistedSetting = (quickFilterRules && quickFilterRules?.length > 0) || enableSearch
    const isNormalQuickFilter = quickFilter?.mode === 'normal'
    const isTileQuickFilter = quickFilter?.mode === 'tile'
    const noData = !dsId
    // const isBatchOperation = enableExport || enableDeleteRecord || enablePrint
    // const enableBatchOperation = isBatchOperation && ['table', 'advancedTable', 'list', 'gallery'].includes(viewType)
    // const isJoinDataSource = type === DataSourceType.joinDataSource
    // const joinDataSourcePrimaryDsId = getPrimaryDsIdByJoinDataSource(dataSource)
    const isUserDataSource = useMemo(() => getIsIncludesDisableDataSourceInWorkFlow(dataSource), [dataSource])
    const firstTableProps = useMemo(() => {
        return find(item => item.visible, columns || [])?.fieldId
    }, [columns])

    const isMobile = useMemo(() => breakPoint === BreakPointSize.sm || breakPoint === BreakPointSize.xs, [breakPoint])

    const mobileContentStyles: React.CSSProperties = useMemo(() => {
        return isMobile ? { width: '100%' } : {}
    }, [isMobile])

    const action = useMemo(() => {
        if (isUserDataSource) {
            return null
        }

        if (toolbarActions?.customized && toolbarActions?.actionGroup?.group?.length) {
            return (
                <TailActionButtonGroup
                    style={{ marginLeft: 8 }}
                    actions={toolbarActions.actionGroup.group}
                    onActionTrigger={onActionTrigger}
                    onRenderTitle={onRenderButtonTitle}
                />
            )
        }
        if (enableAddRecord && !toolbarActions?.customized) {
            return (
                <SC.SXcRecordAdd
                    size={isMobile ? 'lg' : 'md'}
                    style={{ marginLeft: 10, backgroundColor: 'var(--color-app-main)' }}
                    type="primary"
                    onClick={() => onAdd?.()}
                >
                    {addRecordText}
                </SC.SXcRecordAdd>
            )
        }
    }, [
        addRecordText,
        enableAddRecord,
        isMobile,
        isUserDataSource,
        onActionTrigger,
        onAdd,
        onRenderButtonTitle,
        toolbarActions?.actionGroup?.group,
        toolbarActions?.customized
    ])

    const settingContent = useMemo(() => {
        return (
            <SC.ViewSettingToolbarContent>
                <SC.ViewActionPositive isMobile={isMobile}>
                    {enableSearch && !isMobile && <DebounceSearch isTheme value={search} isFull={isMobile} onSearch={onSearch} />}
                    {!isMobile && quickFilterRules && quickFilterRules?.length > 0 && quickFilter?.mode === 'normal' && (
                        <FilterActor
                            mode={quickFilter?.mode}
                            columns={settingColumns}
                            dataSource={dataSource}
                            isMobile={isMobile}
                            quickFilter={quickFilter}
                            onFilter={onFilter}
                        />
                    )}
                </SC.ViewActionPositive>

                <SC.ViewActionContainer ref={actionRef} style={mobileContentStyles}>
                    {isMobile && showTitle && viewType !== 'custom' && <BlockTitle fontSize={SIZE_MAP.get(breakPoint)} title={title} />}
                    <SC.ViewSettingContent>
                        {isMobile && isNormalQuickFilter && quickFilterRules && quickFilter?.rules?.length > 0 && (
                            <QuickFilterDrawer columns={settingColumns} quickFilter={quickFilter} schema={schema} onFilter={onFilter} />
                        )}

                        {enableSort && (
                            <Sort
                                columns={settingColumns}
                                userSortSetting={userSortSetting}
                                primaryField={firstTableProps}
                                data={sort}
                                isMobile={isMobile}
                                isShowDsName={dataSource.type === DataSourceType.joinDataSource}
                                onChangeSorter={onChangeSorter}
                            />
                        )}
                        {enableDisplay && (
                            <Display
                                dataSource={dataSource}
                                dataSourceList={dataSourceList}
                                columns={columns}
                                primaryField={firstTableProps || ''}
                                isMobile={isMobile}
                                disableIconColor
                                onChangeDisplay={onChangeDisplay}
                            />
                        )}
                        {enableGroup && (
                            <Group columns={settingColumns} schema={schema} isMobile={isMobile} data={group} onChange={onChangeGroup} />
                        )}
                        {enableSetKanban && viewType === 'kanban' && (
                            <KanbanGroup
                                columns={settingColumns}
                                schema={schema}
                                isMobile={isMobile}
                                kanbanGroupConfig={kanbanGroupConfig}
                                onKanbanGroupConfigChange={onKanbanGroupConfigChange}
                            />
                        )}
                        {/* {!isJoinDataSource && enableImport && (
                            <Import
                                isMobile={isMobile}
                                appId={appId}
                                dataSource={dataSource}
                                userImportSetting={userImportSetting}
                                onAppend={onAppend}
                            />
                        )} */}
                        {/* 2024-04-29 暂时放开关联表可导入配置 */}
                        {enableImport && (
                            <Import
                                isMobile={isMobile}
                                viewColumns={columns}
                                appId={appId}
                                envId={envId}
                                dataSource={dataSource}
                                dataSourceList={dataSourceList}
                                userImportSetting={userImportSetting}
                                onAppend={onAppend}
                            />
                        )}
                        {/* {enableBatchOperation && (
                            <>
                                <Export
                                    isMobile={isMobile}
                                    enableExport={enableExport}
                                    enablePrint={enablePrint}
                                    onSelectModeChange={onSelectModeChange}
                                    onExport={handleQuickExportAll}
                                    onPrint={handleQuickPrintAll}
                                />
                            </>
                        )} */}
                        {action}
                    </SC.ViewSettingContent>
                </SC.ViewActionContainer>
                {enableSearch && isMobile && <DebounceSearch size="lg" isTheme isFull={isMobile} onSearch={onSearch} />}
            </SC.ViewSettingToolbarContent>
        )
    }, [
        action,
        appId,
        breakPoint,
        columns,
        dataSource,
        dataSourceList,
        enableDisplay,
        enableGroup,
        enableImport,
        enableSearch,
        enableSetKanban,
        enableSort,
        envId,
        firstTableProps,
        group,
        isMobile,
        isNormalQuickFilter,
        kanbanGroupConfig,
        mobileContentStyles,
        onAppend,
        onChangeDisplay,
        onChangeGroup,
        onChangeSorter,
        onFilter,
        onKanbanGroupConfigChange,
        onSearch,
        quickFilter,
        quickFilterRules,
        schema,
        search,
        settingColumns,
        showTitle,
        sort,
        title,
        userImportSetting,
        userSortSetting,
        viewType
    ])

    return (
        <SC.ViewSettingToolbarContainer style={styles?.root}>
            {!isMobile && isExistedSetting && showTitle && viewType !== 'custom' && (
                <BlockTitle fontSize={SIZE_MAP.get(breakPoint)} title={title} />
            )}
            {!noData && (
                <>
                    <SC.ViewSettingContainer style={{ flexDirection: isTileQuickFilter ? 'column' : 'row' }}>
                        {quickFilterRules && quickFilterRules?.length > 0 && isTileQuickFilter && (
                            <FilterActor
                                mode="tile"
                                dataSource={dataSource}
                                columns={settingColumns}
                                isMobile={isMobile}
                                quickFilter={quickFilter}
                                onFilter={onFilter}
                            />
                        )}
                        {!isMobile && !isExistedSetting && showTitle && viewType !== 'custom' && (
                            <BlockTitle fontSize={SIZE_MAP.get(breakPoint)} title={title} />
                        )}
                    </SC.ViewSettingContainer>
                    {settingContent}
                </>
            )}
        </SC.ViewSettingToolbarContainer>
    )
}
