import type { Field } from '@lighthouse/core'
import type { Editor } from '@tiptap/react'
import { findIndex } from 'rambda'
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { innerTypeNameMap } from '../../../../../constants'
import { getFieldIcon } from '../../../../../utils'
import { FieldTypeTag } from '../../../../FieldTypeTag'
import type { FunctionObject, MethodList } from '../../constant'
import { splitString } from '../constants'
// import { methodList } from '../../constant'
import { FormulaDescribe } from './FormulaDescribe'
import * as SC from './styles'

interface ListItem {
    id?: string
    name: string
    icon?: string
    extra?: string
}

export enum FormulaMode {
    all = 0b0111,
    field = 0b0001,
    function = 0b0010,
    none = 0b0000
}

interface FormulaDefinitionsProps {
    fieldId: string
    fields: Field[]
    editor: Editor | null
    mode: FormulaMode
    functionList: MethodList[]
    range: [number, number] | []
    activeItem: FunctionObject | null
    onHoverFunction: (data: FunctionObject, isScroll?: boolean) => void
    onHoverData: (data: Field, isScroll?: boolean) => void
}

export const FormulaDefinitions = forwardRef<HTMLDivElement, FormulaDefinitionsProps>(
    ({ fieldId, fields = [], activeItem, editor, mode, functionList, range, onHoverFunction, onHoverData }, ref) => {
        const [mouseState, setMouseState] = useState(true)
        const list = useMemo(() => {
            return functionList.reduce<FunctionObject[]>((prev, cur) => {
                return [...prev, ...cur.list]
            }, [])
        }, [functionList])

        const fieldList = useMemo(() => fields.filter(item => item.id !== fieldId), [fieldId, fields])

        const activateMouse = useCallback(() => {
            setMouseState(true)
        }, [])

        useEffect(() => {
            document.addEventListener('mousemove', activateMouse)
            return () => {
                document.removeEventListener('mousemove', activateMouse)
            }
        }, [activateMouse])

        const handleActive = useCallback(
            (active: FunctionObject, activeIndex: number) => {
                setMouseState(false)
                if (active.class === 'function') {
                    const index = findIndex(item => item.id === active.id, list)
                    if (index <= 0 && activeIndex < 0) {
                        if (FormulaMode.field & mode) {
                            onHoverData(fieldList[fieldList.length - 1])
                        }
                        return
                    }
                    if (index >= list.length - 1 && activeIndex > 0) {
                        return
                    }
                    onHoverFunction(list[index + activeIndex])
                    return
                }
                const index = findIndex(item => item.id === active.id, fieldList)
                if (index <= 0 && activeIndex < 0) {
                    return
                }
                if (index >= fieldList.length - 1 && activeIndex > 0) {
                    if (FormulaMode.function & mode) {
                        onHoverFunction(list[0])
                    }
                    return
                }
                onHoverData(fieldList[index + activeIndex])
            },
            [fieldList, mode, onHoverData, list, onHoverFunction]
        )

        useHotkeys(
            'up, down, tab',
            (event, handler) => {
                switch (handler.key) {
                    case 'up': {
                        if (!activeItem) {
                            return
                        }
                        handleActive(activeItem, -1)
                        break
                    }
                    case 'down': {
                        if (!activeItem) {
                            return
                        }
                        handleActive(activeItem, 1)
                        // scrollActiveItem(activeItem.id)
                        break
                    }
                    case 'tab': {
                        event.preventDefault()
                        // event.defaultPrevented()
                        if (!activeItem) {
                            return false
                        }
                        if (activeItem.class === 'function') {
                            handleClickAdapter(activeItem)
                            return false
                        }
                        handleClickData(activeItem, 'field', activeItem.type)
                        return false
                    }
                    default: {
                        break
                    }
                }
            },
            { enableOnTags: ['INPUT'], enableOnContentEditable: true },
            [activeItem, handleActive]
        )

        const getInstance = useCallback((nextText: string) => {
            for (const i of nextText) {
                if (splitString.has(i)) {
                    return -1
                }
            }
            const nextIndex = nextText.indexOf('(')
            if(nextText.startsWith('.')){
                return 0
            }
            if (nextIndex >= 0) {
                return nextIndex + 1
            }
            return -1
        }, [])

        const handleClickData = useCallback(
            (item: ListItem, type: 'field' | 'dataSource', dataType = 'dataSource') => {
                if (!editor) {
                    return
                }
                const {
                    view: {
                        state: { selection }
                    }
                } = editor
                const { $anchor, anchor } = selection
                const { nodeBefore, nodeAfter } = $anchor
                // 替换node
                if (nodeBefore && !nodeBefore.isText) {
                    editor
                        .chain()
                        .focus()
                        .deleteRange({ from: anchor - 1, to: anchor })
                        .insertContentAt(anchor - 1, [
                            {
                                type,
                                attrs: {
                                    id: item.id,
                                    icon: item.icon,
                                    name: item.name,
                                    extra: item.extra,
                                    dataType
                                }
                            }
                        ])
                        .run()
                    return
                }

                if (nodeAfter && nodeAfter.isText && nodeAfter.text) {
                    const nodeAfterText = nodeAfter.text
                    const distance = getInstance(nodeAfterText)
                    if (distance >= 0) {
                        editor
                            .chain()
                            .focus()
                            .insertContentAt(anchor + distance, [
                                {
                                    type,
                                    attrs: {
                                        id: item.id,
                                        icon: item.icon,
                                        name: item.name,
                                        extra: item.extra,
                                        dataType
                                    }
                                }
                            ])
                            .run()
                        return
                    }
                }

                // insertContentAt
                editor
                    .chain()
                    .focus()
                    .insertContent([
                        {
                            type,
                            attrs: {
                                id: item.id,
                                icon: item.icon,
                                name: item.name,
                                extra: item.extra,
                                dataType
                            }
                        }
                    ])
                    .run()
            },
            [editor, getInstance]
        )

        const handleClickAdapter = useCallback(
            (item: FunctionObject) => {
                if (!editor) {
                    return
                }
                const {
                    view: {
                        state: { selection }
                    }
                } = editor
                const { $anchor, anchor } = selection
                const { nodeBefore, nodeAfter } = $anchor
                let insertText = item.name
                if (range.length > 0) {
                    const startIndex = anchor - (range[0] || 0)
                    const stopIndex = anchor + (range[1] || 0)
                    editor
                        .chain()
                        .focus()
                        .deleteRange({ from: startIndex || 0, to: stopIndex })
                        .insertContentAt(startIndex, insertText)
                        .focus(startIndex + insertText.length - 1)
                        .run()
                    return
                }

                if (nodeAfter && nodeAfter.isText && nodeAfter.text) {
                    const nodeAfterText = nodeAfter.text
                    const distance = getInstance(nodeAfterText)
                    if (distance >= 0) {
                        editor
                            .chain()
                            .focus()
                            .insertContentAt(anchor + distance, insertText)
                            .focus(anchor + distance + insertText.length - 1)
                            .run()
                        return
                    }
                }
                // // 添加node  到 括号中
                // 光标前是node 则添加 .方法()

                if (nodeBefore && !nodeBefore.isText) {
                    insertText = `.${insertText}`
                }
                editor
                    .chain()
                    .focus()
                    .insertContent(insertText)
                    .focus(anchor + insertText.length - 1)
                    .run()
            },
            [editor, getInstance, range]
        )

        return (
            <SC.FormulaDefinitions tabIndex={-1}>
                {mode === FormulaMode.none ? (
                    <SC.FormulaEmpty>
                        <SC.FormulaEmptyText>试试输入「.」，即可获得公式提示</SC.FormulaEmptyText>
                    </SC.FormulaEmpty>
                ) : (
                    <>
                        <SC.FormulaAdapters ref={ref}>
                            {FormulaMode.field & mode ? (
                                <SC.AdaptersList>
                                    <SC.AdaptersHeader>字段</SC.AdaptersHeader>
                                    {fieldList.map(
                                        v =>
                                            v.id !== fieldId && (
                                                <SC.AdaptersLi
                                                    key={v.name}
                                                    data-adapter={v.id}
                                                    onMouseOver={() => mouseState && onHoverData(v, false)}
                                                    active={activeItem?.id === v.id}
                                                    onClick={() =>
                                                        handleClickData(
                                                            {
                                                                id: v.id,
                                                                name: v.name,
                                                                icon: getFieldIcon(v.id, v.type, v.innerType),
                                                                extra: v.innerType ? innerTypeNameMap[v.innerType] : ''
                                                            },
                                                            'field',
                                                            v.type
                                                        )
                                                    }
                                                >
                                                    <SC.AdaptersContent>
                                                        <SC.AdaptersIcon
                                                            fill="var(--color-gray-400)"
                                                            type={getFieldIcon(v.id, v.type, v.innerType) || ''}
                                                        />
                                                        <SC.AdaptersName>{v.name}</SC.AdaptersName>
                                                    </SC.AdaptersContent>
                                                    {v.innerType && (
                                                        <SC.AdaptersType>
                                                            <FieldTypeTag type={v.type} innerType={v.innerType} />
                                                            {/* <SC.AdaptersTagType>{innerTypeNameMap[v.innerType]}</SC.AdaptersTagType> */}
                                                        </SC.AdaptersType>
                                                    )}
                                                </SC.AdaptersLi>
                                            )
                                    )}
                                </SC.AdaptersList>
                            ) : null}

                            {FormulaMode.function & mode
                                ? functionList.map(item => (
                                      <SC.AdaptersList key={item.name}>
                                          <SC.AdaptersHeader top={8}>{item.name}</SC.AdaptersHeader>
                                          {item.list.map(v => (
                                              <SC.AdaptersLi
                                                  id={v.name}
                                                  key={v.name}
                                                  data-adapter={v.id}
                                                  onMouseOver={() => mouseState && onHoverFunction(v, false)}
                                                  active={activeItem?.name === v.name}
                                                  onClick={() => handleClickAdapter(v)}
                                              >
                                                  <SC.AdaptersContent>
                                                      <SC.AdaptersIcon fill="var(--color-blue-500)" type="PropertyFormula" />
                                                      <SC.AdaptersName>{v.name}</SC.AdaptersName>
                                                  </SC.AdaptersContent>
                                                  <SC.AdaptersType>
                                                      <SC.AdaptersTagTab active={activeItem?.id === v.id}>Tab</SC.AdaptersTagTab>
                                                  </SC.AdaptersType>
                                              </SC.AdaptersLi>
                                          ))}
                                      </SC.AdaptersList>
                                  ))
                                : null}
                        </SC.FormulaAdapters>
                        <FormulaDescribe data={activeItem} />
                    </>
                )}
            </SC.FormulaDefinitions>
        )
    }
)
