/**
 * 打开页面，替代 window.open
 * @param url
 */
export const openNewBrowserPage = (url: string) => {
    // 1.
    // window.open(url, '_blank')
    // 2.
    // const aDom = document.createElement('a')
    // document.body.append(aDom)
    // aDom.target = '_blank'
    // aDom.style.display = 'none'
    // aDom.href = url
    // aDom.click()
    // aDom.remove()
    // 3.
    window.location.href = url
}
