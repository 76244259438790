import styled from 'styled-components'

export const ListScroller = styled.div`
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
`

export const ListWrapper = styled.div`
    position: relative;
`

export const NormalListItem = styled.div`
    &.hovered {
        background-color: var(--color-gray-100);
    }
`

export const Description = styled.p`
    padding: 6px 12px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`
