import type { DataSourceAbstract, SchemaProtocol, VariableADTField } from '@lighthouse/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { getRealField, getVariableFieldId } from '../../utils'
import { CheckButton } from './CheckButton'

interface OperatorTileModeProps {
    schema: SchemaProtocol['schema']
    prefixName: string
    focusOutLine?: string
}

const SCxOperatorWrapper = styled.div`
    display: flex;
    margin: 0 12px;
    flex: 1;
    overflow: hidden;
`

export const OperatorTileMode: React.FC<OperatorTileModeProps> = ({ schema, prefixName, focusOutLine }) => {
    const { control } = useFormContext()
    const idVariable: VariableADTField = useWatch({
        control,
        name: `${prefixName}.idVariable`
    })

    const field = useMemo(() => {
        return getRealField(schema[getVariableFieldId(idVariable)])
    }, [idVariable, schema])

    if (!field) {
        return null
    }

    const { type, innerType } = field

    if (type !== 'file') {
        return null
    }

    return (
        <Controller
            name={`${prefixName}.operator`}
            control={control}
            render={({ field: ctlField }) => {
                const options: { label: string; value: string }[] = [
                    {
                        label: '为空',
                        value: 'isEmpty'
                    },
                    {
                        label: '不为空',
                        value: 'isNotEmpty'
                    }
                ]
                return (
                    <SCxOperatorWrapper>
                        {options.map(option => (
                            <CheckButton
                                key={option.value}
                                active={ctlField.value === option.value}
                                label={option.label}
                                onClick={() => (ctlField.value === option.value ? ctlField.onChange('') : ctlField.onChange(option.value))}
                            />
                        ))}
                    </SCxOperatorWrapper>
                )
            }}
        />
    )
}
