import type { RecordLikeProtocol } from '@lighthouse/core'
import { RecordStruct } from '@lighthouse/core'
import React from 'react'

export interface CustomViewBlockContextValue {
    name: string
    viewId: string
    pointer: string
    record: RecordLikeProtocol | undefined
    records: RecordLikeProtocol[]
}

const CustomViewBlockContext = React.createContext<CustomViewBlockContextValue>({
    name: '',
    viewId: '',
    pointer: '',
    record: undefined,
    records: []
})
export const CustomViewBlockProvider = CustomViewBlockContext.Provider
export const useCustomViewBlockContext = () => React.useContext(CustomViewBlockContext)
