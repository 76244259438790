import { Indicator, Popover } from '@byecode/ui'
import type { FieldType, Sorter, ViewFieldProtocol } from '@lighthouse/core'
import { isEmpty } from 'rambda'
import React, { useMemo, useState } from 'react'

import { useAppThemeContext } from '../ApplicationContainer'
import { ViewSortConfigure } from '../ViewFieldSortConfigure'
import SettingButton from './SettingButton'
import * as SC from './styles'

export interface SortProps {
    primaryField?: string
    columns?: ViewFieldProtocol[]
    data?: Sorter[]
    isMobile?: boolean
    userSortSetting?: string[]
    noSettingFields?: Set<FieldType>
    isShowDsName?: boolean
    onChangeSorter?: (data?: Sorter[]) => void
}

export const Sort: React.FC<SortProps> = ({
    primaryField = '',
    columns = [],
    data = [],
    isMobile,
    userSortSetting,
    noSettingFields,
    isShowDsName,
    onChangeSorter
}) => {
    const [open, setOpen] = useState(false)
    const sortsText = useMemo(() => data.length, [data.length])

    const isActive = useMemo(() => sortsText > 0, [sortsText])

    const theme = useAppThemeContext()

    const sortColumns = useMemo(() => {
        if (!userSortSetting || isEmpty(userSortSetting)) {
            return columns
        }
        return columns.reduce<ViewFieldProtocol[]>((prev, field) => {
            const { fieldId } = field
            if (userSortSetting?.includes(fieldId)) {
                prev.push(field)
            }
            return prev
        }, [])
    }, [columns, userSortSetting])

    return (
        <Popover opened={open} onChange={setOpen} position="bottom-start" withinPortal width={360}>
            <Popover.Target>
                <SC.SettingBox isOpen={open} isActive={isActive} mainColor={theme.mainColor} tintMainColor={theme.tintMainColor}>
                    <SettingButton isActive={isActive} color={theme.mainColor} icon="ArrowsDownUp" size={16} />
                    {!isMobile && (
                        <Indicator disabled={!isActive} color={theme.mainColor} label={String(sortsText)} size={16} radius={16} withBorder>
                            <SC.SettingText>排序</SC.SettingText>
                        </Indicator>
                    )}
                </SC.SettingBox>
            </Popover.Target>
            <Popover.Dropdown>
                <ViewSortConfigure
                    noSettingFields={noSettingFields}
                    columns={sortColumns}
                    primaryField={primaryField}
                    sorters={data}
                    isShowDsName={isShowDsName}
                    // disabledShowInnerType
                    onChangeSorter={onChangeSorter}
                />
            </Popover.Dropdown>
        </Popover>
    )
}
