import { Button, pointer } from '@byecode/ui'
import type { JSONContent } from '@tiptap/core'
import React from 'react'
import styled from 'styled-components'

const SCxFormulaDefinitionsFooter = styled.div`
    width: 100%;
    height: 60px;
    border-top: 1px solid var(--color-gray-200);
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const SCxFormulaDefinitionsFooterLeft = styled.div`
    flex: 1;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-flow: nowrap row;
    justify-content: flex-start;
    align-items: center;
`

const SCxFormulaDefinitionsFooterLeftExplainItem = styled.div`
    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    margin-right: 16px;
`

const SCxFormulaDefinitionsFooterLeftExplainItemText = styled.div`
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 5px;
    background-color: var(--color-gray-100);
    color: var(--color-black);
    margin-right: 6px;
`

const SCxFormulaDefinitionsFooterLeftExplainItemContent = styled.div`
    color: var(--color-gray-400);
`

const SCxFormulaDefinitionsFooterRight = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
`

const SCxFormulaDefinitionsFooterRightBtn = styled(Button)`
    margin-left: 8px;
`

const SCxFormulaSpecification = styled.div`
    color: var(--color-gray-400);
`

export const SCxExplain = styled.div`
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 50%;
    background-color: var(--color-gray-400);
    color: var(--color-white);
    font-size: 12px;
    text-align: center;
    margin-right: 4px;
    ${pointer}
`

const formulaProcessDescribe = [
    {
        text: 'Tab',
        content: '选中'
    },
    {
        text: '.',
        content: '对象表达式'
    }
]

interface FormulaFooterProps {
    value?: JSONContent
    onOk?: (val: JSONContent | undefined) => void
    onCancel?: () => void
}

export const FormulaFooter: React.FC<FormulaFooterProps> = ({ value, onOk, onCancel }) => {
    return (
        <SCxFormulaDefinitionsFooter>
            <SCxFormulaDefinitionsFooterLeft>
                {formulaProcessDescribe.map((item, index) => (
                    <SCxFormulaDefinitionsFooterLeftExplainItem key={item.text}>
                        <SCxFormulaDefinitionsFooterLeftExplainItemText>{item.text}</SCxFormulaDefinitionsFooterLeftExplainItemText>
                        <SCxFormulaDefinitionsFooterLeftExplainItemContent>
                            {item.content}
                        </SCxFormulaDefinitionsFooterLeftExplainItemContent>
                    </SCxFormulaDefinitionsFooterLeftExplainItem>
                ))}
            </SCxFormulaDefinitionsFooterLeft>
            <SCxFormulaDefinitionsFooterRight>
                <SCxFormulaDefinitionsFooterRightBtn className="btn-primary" type='primary' onClick={() => onOk?.(value)}>
                    确认
                </SCxFormulaDefinitionsFooterRightBtn>
                <SCxFormulaDefinitionsFooterRightBtn onClick={onCancel}>取消</SCxFormulaDefinitionsFooterRightBtn>
                <SCxFormulaSpecification>
                    <SCxExplain>?</SCxExplain>
                    了解公式
                </SCxFormulaSpecification>
            </SCxFormulaDefinitionsFooterRight>
        </SCxFormulaDefinitionsFooter>
    )
}
