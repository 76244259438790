import { Input } from '@byecode/ui'
import type { NumberValue, SliderConfig } from '@lighthouse/core'
import { clamp, isEmpty, max as maxFc } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { numberReg } from '../../../../constants'
import type { FieldBaseProps } from '../../types'
import { Slider } from './Slider'

interface SliderFieldProps extends FieldBaseProps {
    value?: NumberValue
    sliderConfig: SliderConfig
}

const SCxContainer = styled.div`
    width: 100%;
    padding: 4px 0px;
    border-radius: 6px;
`

const SCxSliderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

function strToNumberStr(text?: string | number) {
    if (typeof text === 'string') {
        if (text === '') {
            return ''
        }
        if (numberReg.test(text)) {
            return Number(text)
        }
    }

    if (typeof text === 'number') {
        return text
    }

    return ''
}

export const SliderField: React.FunctionComponent<SliderFieldProps> = props => {
    const { readOnly, sliderConfig, onCellChange, onSaveCellChange, value, isControlled } = props
    const { slider, fieldPointer } = sliderConfig
    const { color, colorMode, isInput } = slider ?? {}

    const [min, max, step] = useMemo(() => {
        const { min = 0, max = 100, step = 1 } = slider ?? {}
        let newMin = min
        let newMax = max
        let newStep = step
        if (newMin < 0) {
            newMin = 0
        }
        if (newMax <= newMin) {
            newMax = newMin + 1
        }
        if (newStep > newMax - newMin) {
            newStep = 1
        }
        return [strToNumberStr(newMin), strToNumberStr(newMax), strToNumberStr(newStep)].map(Number)
    }, [slider])

    const [data, setData] = useState(strToNumberStr(value))

    const sliderData = useMemo(() => (data === '' ? 0 : Number(data)), [data])

    const handleSliderChange = useCallback(
        (value: number) => {
            setData(value)
            onSaveCellChange?.({ type: 'slider', value })
            onCellChange?.({ type: 'slider', value })
        },
        [onCellChange, onSaveCellChange]
    )

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            const newValue = strToNumberStr(e.target.value)
            setData(newValue)
            onCellChange?.({ type: 'slider', value: newValue })
        },
        [onCellChange]
    )

    const handleChange = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            const newValue = strToNumberStr(e.target.value)
            setData(newValue)
            onSaveCellChange?.({ type: 'slider', value: newValue })
        },
        [onSaveCellChange]
    )

    useUpdateEffect(() => {
        setData(strToNumberStr(value))
    }, [value])

    return (
        <SCxContainer data-field-border={value !== ''} data-input-type="slider">
            <SCxSliderContainer>
                <Slider
                    min={min}
                    max={max}
                    step={step}
                    disabled={readOnly}
                    value={sliderData}
                    onChange={handleSliderChange}
                    color={colorMode === 'theme' ? 'var(--color-app-main)' : color}
                    style={{
                        flex: 1
                    }}
                />
                {isInput && (
                    <Input
                        type="number"
                        placeholder="请输入"
                        disabled={readOnly}
                        styles={{
                            wrapper: {
                                width: 68
                            }
                        }}
                        value={data}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                )}
            </SCxSliderContainer>
        </SCxContainer>
    )
}
