import {
    type Placement,
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    size,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole
} from '@floating-ui/react'
import { max } from 'rambda'
import React, { useRef } from 'react'
import { useUpdateEffect } from 'react-use'

import type { TooltipWidth } from './Tooltip.context'

export interface TooltipOptions {
    initialOpen?: boolean
    placement?: Placement
    open?: boolean
    offset?: number
    disabled?: boolean
    /**
     * 如果是可交互的，那么就算设置了受控 open，也依然会响应 hover 和 focus 事件
     */
    interactive?: boolean
    width?: TooltipWidth
    minWidth?: number
    arrowRef?: React.RefObject<SVGSVGElement>
    onOpenChange?: (open: boolean) => void
}

export const ARROW_WIDTH = 8
export const ARROW_HEIGHT = 4

export function useTooltip({
    initialOpen = false,
    placement = 'top',
    open: controlledOpen,
    width,
    minWidth = 0,
    disabled,
    interactive = false,
    arrowRef,
    offset: toolTipOffset = 5,
    onOpenChange: setControlledOpen
}: TooltipOptions = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)
    const disableRef = useRef(disabled)
    const open = controlledOpen ?? uncontrolledOpen
    const setOpen = setControlledOpen ?? setUncontrolledOpen
    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(toolTipOffset),
            flip({
                crossAxis: placement.includes('-'),
                fallbackAxisSideDirection: 'start',
                padding: 5
            }),
            shift({ padding: 5 }),
            arrowRef ? arrow({ element: arrowRef }) : null,
            ...(width === 'target'
                ? [
                      size({
                          apply({ rects, elements }) {
                              Object.assign(elements.floating.style, { width: `${max(minWidth, rects.reference.width)}px` })
                          }
                      })
                  ]
                : [])
        ]

    })

    const { context } = data
    const hover = useHover(context, {
        move: false,
        enabled: !disabled && (!controlledOpen || interactive)
    })
    const focus = useFocus(context, {
        enabled: !disabled && (!controlledOpen || interactive)
    })

    useUpdateEffect(() => {
        if (disableRef.current !== disabled) {
            open && setUncontrolledOpen(!disabled)
            disableRef.current = disabled
        }
    }, [disabled])

    const dismiss = useDismiss(context)
    const role = useRole(context, { role: 'tooltip' })

    const interactions = useInteractions([hover, focus, dismiss, role])

    return React.useMemo(
        () => ({
            open,
            setOpen,
            // isMounted,
            // styles,
            width,
            minWidth,
            ...interactions,
            ...data
        }),
        [open, setOpen, width, minWidth, interactions, data]
    )
}
