import { Flex, Image, Loading, Text } from '@byecode/ui'
import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import QRCode from 'qrcode.react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import { ApplicationPreviewEnum, ScanStatus } from '../../../types'
import LoginBox from '../LoginBox'
import * as CM from '../styles'
import type { AccountBindAccountForm, AppLoginMode } from '../types'

const SCxRetry = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    background-color: rgba(0, 0, 0, 0.6);
`
interface LoginProps {
    authentication: ApplicationSettingAuthentication
    language: string
    onWeChatLogin?: () => Promise<{ url: string; mark: string } | undefined>
    onChangeLanguage: (v: string) => void
    onCheckWechatLogin?: (mark: string) => Promise<ScanStatus>
    onChangeMode: (v: AppLoginMode) => void
}

export const WechatLogin: React.FunctionComponent<LoginProps> = ({
    authentication,
    language,
    onChangeLanguage,
    onWeChatLogin,
    onCheckWechatLogin,
    onChangeMode,
}) => {
    const { login, register, name, logo, describe } = authentication

    const [scanStatus, setScanStatus] = useState<ScanStatus>()

    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

    const { t } = useTranslation()

    const handlePollWechatLogin = useCallback(
        async (mark: string) => {
            const res = await onCheckWechatLogin?.(mark)
            setScanStatus(res)
            if (res === ScanStatus.NOT_SCAN) {
                const newTimer = setTimeout(() => handlePollWechatLogin(mark), 3000)
                setTimer(newTimer)
            }
        },
        [onCheckWechatLogin]
    )

    const {
        value: data,
        loading,
        retry
    } = useAsyncRetry(async () => {
        if (!onWeChatLogin) {
            return null
        }
        // 轮询检查非jsapi微信支付
        const res = await onWeChatLogin()
        res?.mark && handlePollWechatLogin(res?.mark)
        return res
    }, [])

    return (
        <LoginBox
            name={name}
            logo={logo}
            language={language}
            describe={describe}
            previewType={ApplicationPreviewEnum.desktop}
            onChangeLanguage={onChangeLanguage}
            onBack={() => onChangeMode('home')}
        >
            <Flex direction="column" alignItems="center" gap={16}>
                {loading ? (
                    <Loading />
                ) : (
                    <div style={{ position: 'relative' }}>
                        {scanStatus === ScanStatus.QR_EXPIRED && <SCxRetry>{t('expiredPleaseRefresh')}</SCxRetry>}
                        <Image width={160} height={160} src={data?.url ?? ''} alt="微信二维码" />
                    </div>
                )}
                <Flex alignItems="center" gap={8}>
                    <CM.Icon type="WeChatLogo" color="var(--color-green-500)" />
                    <Text size={14} lineHeight="22px">
                        {t('scanWechatLogin')}
                    </Text>
                </Flex>
                <Flex alignItems="center" gap={6}>
                    <CM.Icon type="ArrowsClockwise" color="var(--color-gray-500)" />
                    <Text
                        lineHeight="22px"
                        size={14}
                        color="var(--color-gray-500)"
                        onClick={() => {
                            timer && clearTimeout(timer)
                            retry()
                        }}
                    >
                        {t('refresh')}
                    </Text>
                </Flex>
            </Flex>
        </LoginBox>
    )
}
