import { backgroundTransitionOnClick, Divider, IconFont, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const TableFieldConfiguratorContainer = styled.div`
    width: 248px;
    padding: 6px 0;
`

export const ListArea = styled.div``

export const ListItem = styled.div<{ selected?: boolean }>`
    display: flex;
    height: 36px;
    padding: 0 14px;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-normal);
    ${pointer}
    ${backgroundTransitionOnClick};
`

export const ItemIcon = styled(IconFont)`
    font-size: 16px;
    color: var(--color-gray-400);
`

export const ListItemText = styled.span`
    flex: 1;
    margin-left: 8px;
    color: var(--color-black);
`

export const ItemDivider: typeof Divider = styled(Divider)`
    margin: 6px 0;
    border-color: var(--color-gray-100);
`

export const ItemExtraWrapper = styled.div`
    display: inline-flex;

    align-items: center;
`

export const EditGuideText = styled.span`
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    margin-right: 4px;
`

export const Arrow = styled(IconFont)`
    color: var(--color-gray-400);
    position: relative;
    top: 1px;
`

export const WrapSwitcher = styled.div``
