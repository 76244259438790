import { getContainer } from './helper'
import type { Property } from './types'

const commonToast = (content: string, property?: Property) => {
    getContainer()?.create(content, { icon: 'WarningCircle', iconColor: '#F79009', ...property })
}

export const Toast = {
    custom: (content: string, property?: Property) => {
        getContainer()?.create(content, property)
    },
    success: (content: string, property?: Property) => {
        getContainer()?.create(content, { icon: 'TickCircle', iconColor: '#12B76A', ...property })
    },
    error: (content: string, property?: Property) => {
        getContainer()?.create(content, { icon: 'ErrorCircle', iconColor: '#F04438', ...property })
    },
    info: (content: string, property?: Property) => {
        commonToast(content, property)
    },
    warning: (content: string, property?: Property) => {
        commonToast(content, property)
    }
}

Object.freeze(Toast)

export { ToastContainer } from './toast'
