import { Modal } from '@byecode/ui'
import type { FC } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { FileCarousel } from './FileCarousel'
import type { FilePreviewerProps } from './types'

const PreviewerModal = styled(Modal)`
    .byecode-Modal-modal {
        width: 100vw;
        height: 100vh;
        border-radius: 0;
        border: none;
        box-shadow: none;
    }
    /* position: absolute;

    .mantine-Modal-modal {
        width: 100%;
        display: flex;
        flex-direction: column;

        .mantine-Modal-header {
            padding: 13px 16px;
            border-bottom: 1px solid var(--color-gray-200);
            margin: 0;
            .mantine-Modal-close {
                width: 40px;
                height: 40px;
                color: var(--color-black);
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .mantine-Modal-body {
            flex: 1;
            height: unset;
            max-height: unset;
            background-color: var(--color-gray-50);
        }
    } */
`

// 文件预览弹窗主体
export const FilePreviewer: FC<FilePreviewerProps> = ({
    fileList,
    defaultIndex = 0,
    disableDownload,
    opened: propOpened,
    target,
    onClose,
    withControls,
    ...rest
}) => {
    const [opened, setOpened] = useState(propOpened ?? false)

    useEffect(() => {
        setOpened(propOpened ?? false)
    }, [propOpened])

    const [currentIndex, setCurrentIndex] = useState(defaultIndex)

    const title = useMemo(() => {
        if (fileList.length === 0) {
            return null
        }
        const filename = fileList[currentIndex]?.name || '未命名文件'
        const page = `(${currentIndex + 1}/${fileList.length})`
        return `${filename} ${page}`
    }, [currentIndex, fileList])

    return (
        <PreviewerModal target={target} zIndex={300} title={title} open={opened} onClose={onClose} {...rest}>
            <FileCarousel
                withControls={withControls}
                defaultIndex={defaultIndex}
                currentIndex={currentIndex}
                onSlideChange={setCurrentIndex}
                fileList={fileList}
            />
        </PreviewerModal>
    )
}
