import { Loading, Toast } from '@byecode/ui'
import { type PageAbstract,AppUserStatus } from '@lighthouse/core'
import { CUSTOM_ROUTE_PAGE_URL_REG, FillPickerProvider, PAGE_URL_REG, pageUrlReg, useAtomAction, useAtomData } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAsync, useAsyncRetry, useEffectOnce } from 'react-use'

import { setLanguageAtom } from '@/atoms/application/action'
import { appAtom, languageAtom } from '@/atoms/application/state'
import { fetchPageListAtom } from '@/atoms/page/action'
import { Page as PageContain } from '@/containers/Page'
import { useAccount } from '@/hooks/useAccount'
import { useApplication } from '@/hooks/useApplication'

function getPageId(url: string, pageList: PageAbstract[]) {
    const isCustomRoute = CUSTOM_ROUTE_PAGE_URL_REG.test(url)
    if (isCustomRoute) {
        const page = pageList.find(item => item.route === url.split('/')[0])
        return page?.id
    }

    const [, pageId] = url.split('/')

    return pageId
}

function getPageIdFromUrl(pageList: PageAbstract[]) {
    const matches = window.location.pathname.match(PAGE_URL_REG)
    if (!matches) {
        return [undefined, undefined]
    }
    // 获取弹窗页面id
    const lastPageId = matches.length > 1 ? matches?.at(-1)?.split('/')?.[1] : ''
    // 获取根页面，页面id
    const lastRootPageMatchIndex = matches.findLastIndex(match => match.includes('P/'))
    const lastRootPageIndex = lastRootPageMatchIndex === -1 ? 0 : lastRootPageMatchIndex
    const rootPageId  = matches ? getPageId(matches[lastRootPageIndex], pageList) : ''
    const lastPage = pageList.find(item => item.id === lastPageId)
    const rootPage = pageList.find(item => item.id === rootPageId)
    return [rootPage, lastPage]
}

const Page: React.FC = () => {
    const navigate = useNavigate()
    const redirectSearch = `?redirect=${encodeURIComponent(window.location.href)}`
    // const { id: pageId = '' } = useParams<{ id: string }>()
    const { pathname, search } = useLocation()
    const { t } = useTranslation()

    const language = useAtomData(languageAtom)
    const { run: setLanguage } = useAtomAction(setLanguageAtom)
    const user = useAccount()
    const { loggedIn, palettes } = useAtomData(
        appAtom,
        useCallback(s => ({ loggedIn: s?.loggedIn, palettes: s?.config.theme.palettes }), [])
    )
    const { run: fetchPageList } = useAtomAction(fetchPageListAtom)

    const { loading, value: isRenderOutlet } = useAsyncRetry(async () => {
        const pageList = await fetchPageList()

        if (pathname === 'noAccess') {
            return true
        }

        const matches = pathname.match(PAGE_URL_REG)

        const homePage = find(item => item.isHome && item.language === language, pageList)
        const redirectPage = homePage ?? pageList?.[0]

        let isForbiddenPath = false

        if (matches) {
            const pageId = getPageId(matches[0], pageList)
            isForbiddenPath = !pageList.some(item => item.id === pageId)
        }

        const [rootPage, lastPage] = getPageIdFromUrl(pageList)

        if ((language !== rootPage?.language) && rootPage) {
            setLanguage(rootPage?.language)
        }
        if (matches && !loggedIn) {
            if (lastPage && !lastPage.isAccess) {
                navigate(`/account/login${redirectSearch}`)
                return false
            }
            if (rootPage && !rootPage.isAccess) {
                navigate(`/account/login${redirectSearch}`)
                return false
            }
        }

        // 如果没有pathname，或者pathname没有对应的page，重定向到正确的地址
        if (!matches || isForbiddenPath) {
            // 跳转的页面需要登陆访问
            if (!loggedIn && !redirectPage?.isAccess) {
                navigate(`/account/login${redirectSearch}`)
                return false
            }
            const path = redirectPage.route ? `/${redirectPage.route}${search}` : `/P/${redirectPage.id}${search}`
            navigate(path, { replace: true })
        }
        return true
    }, [])

    // 当有登陆态通过返回或其他方式准备加载页面时，查看页面是否需要完善信息或绑定手机号等
    useEffectOnce(() => {
        if (!user.userId) {
            return
        }
        const { pathname, href } = window.location
        const normalUrlMatch = pageUrlReg.test(pathname) 
        const customPageUrlMath = CUSTOM_ROUTE_PAGE_URL_REG.test(pathname.slice(1))
        const isVisitPage = customPageUrlMath || normalUrlMatch
        const redirectUrl = encodeURIComponent(href)
        const search = isVisitPage ? `?redirect=${redirectUrl}` : ''
        if (user.bindMobile) {
            navigate(`/account/bindMobile${search}`, { replace: true })
            return
        }
        if (!user.checkPerfect) {
            navigate(`/account/perfect${search}`, { replace: true })
        }
        // if (user.status?.[0] === AppUserStatus.REVIEWED) {
        //     Toast.error(t('0011021'))
        //     navigate('/account/review', { replace: true })
        // }
    })

    const fillPickerPalates = useMemo(() => ({ palettes: palettes ?? [] }), [palettes])

    if (loading) {
        return <Loading />
    }

    return (
        <FillPickerProvider value={fillPickerPalates}>
            <PageContain>{isRenderOutlet && <Outlet context={isRenderOutlet} />}</PageContain>
        </FillPickerProvider>
    )
}

export default Page
