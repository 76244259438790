import type { DesignStyle, DesignType } from '@lighthouse/core'
import { ViewDesignStyle } from '@lighthouse/core'

interface ListStyle {
    isNumberOrder: boolean
    // 主键是否独立
    isPrimaryAlone: boolean
    // 整体内容布局配置
    // contentIsFullWidth: boolean
    contentDirection: React.CSSProperties['flexDirection'] // SCxListRecordContent-isColumns
    avatarMargin: number

    wrapperDirection: React.CSSProperties['flexDirection'] // SCxListRecordContent-isColumns
    titleBold: boolean
    titleSize: number
    titlePadding: number
    emphasizeTextSize: number
    emphasizeTextLineHeight: number
    emphasizeTextLineMarginBottom: number
    // 列表内容布局配置
    listIsFullWidth: boolean
    listJustify: React.CSSProperties['justifyContent']
    listDirection: React.CSSProperties['flexDirection'] // SCxListItemsWrapper-isColumns
    // 头像大小
    imageSize: number
    listItemIsWidth: boolean
    listItemIsFullWidth: boolean
    // 行 是否有间隔
    isDivider: boolean
    listItemMarginBottom: number

    // record 间隔大小
    gap: number
}

interface ListTypeStyle {
    border: boolean
    background: string | undefined
    dividerMargin: number
    isGap: boolean
    isDivider: boolean
}

// type ListDesign = `${DesignStyle}-${DesignType}`

export const getDesignTypeMap: (type: DesignType | undefined) => ListTypeStyle = type => {
    switch (type) {
        case 'card': {
            return {
                border: true,
                dividerMargin: 0,
                isGap: false,
                background: undefined,
                isDivider: true
            }
        }
        case 'colorBlock': {
            return {
                border: false,
                dividerMargin: 16,
                isGap: true,
                background: '--color-gray-50',
                isDivider: false
            }
        }
        // default
        default: {
            return {
                border: false,
                dividerMargin: 16,
                isGap: false,
                background: undefined,
                isDivider: true
            }
        }
    }
}

export const getDesignStyleMap: (design: ViewDesignStyle | undefined, isMobile?: boolean) => ListStyle = (design, isMobile) => {
    switch (design) {
        case ViewDesignStyle.simple: {
            return {
                isNumberOrder: !isMobile,
                isPrimaryAlone: !!isMobile,
                contentDirection: isMobile ? 'column' : 'row',
                avatarMargin: 12,
                wrapperDirection: isMobile ? 'column' : 'row',
                titleBold: true,
                titleSize: 16,
                titlePadding: 0,
                emphasizeTextSize: 12,
                emphasizeTextLineHeight: 20,
                emphasizeTextLineMarginBottom: isMobile ? 2 : 4,
                listIsFullWidth: false,
                listItemIsFullWidth: false,
                listJustify: isMobile ? 'flex-start' : 'flex-end',
                listDirection: 'row',
                imageSize: 48,
                listItemIsWidth: false,
                isDivider: false,
                listItemMarginBottom: 0,
                gap: 4
            }
        }

        case ViewDesignStyle.stripe: {
            return {
                isNumberOrder: false,
                isPrimaryAlone: true,
                contentDirection: 'column',
                avatarMargin: 20,
                wrapperDirection: 'column',
                titleBold: true,
                titleSize: 20,
                titlePadding: 2,
                emphasizeTextSize: 14,
                emphasizeTextLineHeight: 22,
                emphasizeTextLineMarginBottom: 4,
                listIsFullWidth: true,
                listItemIsFullWidth: true,
                listJustify: 'flex-start',
                listDirection: 'column',
                imageSize: 56,
                listItemIsWidth: true,
                isDivider: true,
                listItemMarginBottom: 8,
                gap: 12
            }
        }
        // classic
        default: {
            return {
                isNumberOrder: false,
                isPrimaryAlone: true,
                contentDirection: 'column',
                avatarMargin: 16,
                wrapperDirection: 'column',
                titleBold: true,
                titleSize: 20,
                titlePadding: 2,
                emphasizeTextSize: 14,
                emphasizeTextLineHeight: 22,
                emphasizeTextLineMarginBottom: 4,
                listIsFullWidth: false,
                listItemIsFullWidth: false,
                listJustify: 'flex-start',
                listDirection: 'row',
                imageSize: 56,
                listItemIsWidth: false,
                isDivider: true,
                listItemMarginBottom: 0,
                gap: 12
            }
        }
    }
}
