import { type FieldType, type FieldValue, type HighLightConditions, type ViewFieldProtocol } from '@lighthouse/core'
import React from 'react'

interface CellHighlighterProps {
    currentUserId?: string
    fieldId: string
    type: FieldType
    value?: string | string[]
    conditions?: HighLightConditions
}

interface RowHighlighterProps {
    currentUserId?: string
    recordConditions?: Record<string, HighLightConditions>
    recordContent?: Record<string, FieldValue>
    viewFields: ViewFieldProtocol[]
    zIndex?: number
}

export const CellHighlighter: React.FC<CellHighlighterProps> = ({ conditions, fieldId, type, value }) => {
    // const color = useMemo(() => {
    //     if (!conditions || conditions.length === 0) {
    //         return ''
    //     }
    //     return filterCellUnmatched(conditions, fieldId, type, value)
    // }, [conditions, fieldId, type, value])

    // return <Background backgroundColor={color} />
    return <></>
}

export const RowHighlighter: React.FC<RowHighlighterProps> = ({ recordConditions = {}, recordContent, viewFields, zIndex }) => {
    // const color = useMemo(() => {
    //     if (isEmpty(recordConditions) || !recordContent) {
    //         return ''
    //     }
    //     return filterRowUnmatched(recordConditions, recordContent, viewFields)
    // }, [recordConditions, recordContent, viewFields])
    return <></>
    // return <Background backgroundColor={color} zIndex={zIndex} />
}
