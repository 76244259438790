import { PAGE_SCROLL_CONTAINER_HOST } from '@lighthouse/shared'
import { useEffect, useRef, useState } from 'react'

export const useEnterVisibleArea = (areaSelector: string | Element = PAGE_SCROLL_CONTAINER_HOST) => {
    const [isIn, setIsIn] = useState(false)
    const ref = useRef<HTMLDivElement | null>(null)

    const scrollHandlerRef = useRef(() => {
        const top = ref.current?.getBoundingClientRect().top
        if (top && top < window.innerHeight - 300) {
            setIsIn(true)
        }
    })

    useEffect(() => {
        scrollHandlerRef.current()
    }, [])

    useEffect(() => {
        const scrollContainer = typeof areaSelector === 'string' ? document.querySelector(`#${areaSelector}`) : areaSelector

        if (!scrollContainer) {
            return
        }

        scrollContainer.addEventListener('scroll', scrollHandlerRef.current)
        scrollContainer.addEventListener('resize', scrollHandlerRef.current)
    }, [areaSelector])

    return { ref, isIn }
}
