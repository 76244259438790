import type { DTFile, FileProtocols, VariableFieldADTValue } from '@lighthouse/core'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { Pexels } from '../../../Pexels'

interface CustomPexelsProps {
    onChange: (value: VariableFieldADTValue) => void
}

const SCxContainer = styled.div`
    width: 480px;
    height: 400px;
    padding: 12px;
`

const CustomPexels: React.FunctionComponent<CustomPexelsProps> = ({ onChange }) => {
    const handleChange = useCallback(
        (file: FileProtocols) => {
            onChange({ type: 'file', value: [file.url] })
        },
        [onChange]
    )

    return (
        <SCxContainer>
            <Pexels onClick={handleChange} />
        </SCxContainer>
    )
}

export default CustomPexels
