import { IconFont, Tooltip } from '@byecode/ui'
import { autoUpdate, offset, shift, useFloating } from '@floating-ui/react'
import { DIRECTION } from '@lighthouse/core'
import { findParentByClassName, findParentById, stopPropagation } from '@lighthouse/tools'
import { useDrag } from '@use-gesture/react'
import React, { useRef } from 'react'
import { createPortal } from 'react-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import { useLatest } from 'react-use'
import styled from 'styled-components'

import { PAGE_CONTAINER_HOST_ID } from '../../../../constants'
import { useSharedConfigDisabledWithVersion } from '../../../../contexts/SharedConfigContext'
import { useFlowContainerContext, useFlowLayoutContext } from '../../Context'
import { useSensorContext, useSortableContext, useSortableMonitor } from '../../Sortable/Context'
import type { FlowLayoutNode } from '../../types'

interface ToolbarProps {
    data: FlowLayoutNode
    referenceRef: React.RefObject<HTMLDivElement | null>
    onDataDrawerVisible?: (val: boolean) => void
}

const Container = styled.div({
    padding: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    height: '28px',
    borderRadius: '4px',
    color: '#fff',
    background: 'var(--color-purple-900)',
    boxShadow: '0px 4px 12px 0px rgba(16, 24, 40, 0.10)',
    cursor: 'auto',
    zIndex: '200'
})

const Sep = styled.div({
    alignSelf: 'stretch',
    width: 1,
    margin: '6px 2px',
    background: 'rgba(255,255,255, 0.3)'
})

const IconButton = styled.div({
    touchAction: 'none',
    userSelect: 'none',
    padding: 2,
    display: 'flex',
    gap: '2px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '#fff',
    '&[data-enable-hover]': {
        color: '#E4E7EC'
    },
    '&[data-enable-hover]:hover': {
        borderRadius: '5px',
        color: '#fff',
        background: 'rgba(255, 255, 255, 0.25)'
    }
})

const Label = styled.span({
    marginLeft: 2,
    fontSize: 12,
    lineHeight: '20px'
})

function getBoundary(currentElement: null | HTMLElement, id: string) {
    // 如果在弹窗中
    const boundary = findParentByClassName(currentElement, 'byecode-Modal-modal')
    if (boundary) {
        return boundary
    }

    return findParentById(currentElement, id)
}

export const Toolbar = ({ data, referenceRef, onDataDrawerVisible }: ToolbarProps) => {
    const dragHandleRef = useRef<HTMLDivElement>(null)
    const { labelRender } = useFlowLayoutContext()
    const { parentId, items } = useSortableContext()
    const { direction } = useFlowContainerContext()
    const { onDuplicate, onRemove, onSelectedIdChange, onSwapNode } = useSortableMonitor()
    const { onStart } = useSensorContext()
    const latestEvents = useLatest({ onStart })
    const disabledWithVersion = useSharedConfigDisabledWithVersion()

    const { floatingStyles, refs } = useFloating<HTMLDivElement>({
        strategy: 'fixed',
        placement: 'top-start',
        elements: { reference: referenceRef.current },
        middleware: [
            offset(4),
            shift(() => {
                // const parentScroller = findParentScroller(referenceRef.current)
                // const boundaryBox = rootElement
                // let boundary: Boundary | undefined = boundaryBox || undefined
                // if (parentScroller && boundaryBox) {
                //     const rectP = parentScroller.getBoundingClientRect()
                //     const rectB = boundaryBox.getBoundingClientRect()
                //     boundary = {
                //         x: Math.max(rectP.x, rectB.x),
                //         y: rectP.y,
                //         width: Math.min(rectP.width, rectB.width),
                //         height: Math.min(rectP.height, rectB.height)
                //     }
                // }
                return {
                    // padding: { top: rootElement?.parentElement === parentScroller ? 0 : 60 }, // 28自身高度 * 2 + 4px offset
                    // boundary: findParentScroller(referenceRef.current) || undefined,
                    boundary: getBoundary(referenceRef.current, PAGE_CONTAINER_HOST_ID) || undefined,
                    crossAxis: true
                }
            })
        ],
        whileElementsMounted: autoUpdate
    })

    useDrag(
        state => {
            if (state.first) {
                const el = referenceRef.current
                if (!el) {
                    return
                }
                latestEvents.current.onStart({ x: state.initial[0], y: state.initial[1] }, data, el.getBoundingClientRect())
            }
        },
        {
            enabled: !disabledWithVersion,
            target: dragHandleRef,
            filterTaps: true,
            triggerAllEvents: true,
            pointer: { capture: false }
        }
    )

    useHotkeys(
        'shift+enter',
        () => {
            onSelectedIdChange?.(parentId)
        },
        [onSelectedIdChange, parentId]
    )

    useHotkeys(
        'up, down, left, right',
        (_, hotkeysEvent) => {
            switch (hotkeysEvent.key) {
                case 'up': {
                    if (direction === DIRECTION.horizontal) {
                        return
                    }
                    const currentIndex = items.indexOf(data.id)
                    if (currentIndex === 0) {
                        return
                    }
                    const prev = items[currentIndex - 1]
                    onSwapNode?.(data.id, prev)
                    break
                }
                case 'down': {
                    if (direction === DIRECTION.horizontal) {
                        return
                    }
                    const currentIndex = items.indexOf(data.id)
                    if (currentIndex === items.length - 1) {
                        return
                    }
                    const next = items[currentIndex + 1]
                    onSwapNode?.(data.id, next)
                    break
                }
                case 'left': {
                    if (!direction || direction === DIRECTION.vertical) {
                        return
                    }
                    const currentIndex = items.indexOf(data.id)
                    if (currentIndex === 0) {
                        return
                    }
                    const prev = items[currentIndex - 1]
                    onSwapNode?.(data.id, prev)
                    break
                }
                case 'right': {
                    if (!direction || direction === DIRECTION.vertical) {
                        return
                    }
                    const currentIndex = items.indexOf(data.id)
                    if (currentIndex === items.length - 1) {
                        return
                    }
                    const next = items[currentIndex + 1]
                    onSwapNode?.(data.id, next)
                    break
                }

                default: {
                    break
                }
            }
        },
        {
            enabled: !disabledWithVersion
        },
        [direction, items, data.id, onSwapNode]
    )

    const defaultDom = (
        <Container ref={refs.setFloating} style={floatingStyles} onMouseDown={stopPropagation}>
            <Tooltip ref={disabledWithVersion ? undefined : dragHandleRef} disabled={disabledWithVersion} title="移动">
                <IconButton style={{ cursor: disabledWithVersion ? 'default' : 'grab' }}>
                    {!disabledWithVersion && <IconFont type="DotsSix" />}
                    <Label>{labelRender?.(data.id)}</Label>
                </IconButton>
            </Tooltip>
            <Sep />

            <Tooltip title="选择上一级">
                <IconButton data-enable-hover onClickCapture={() => onSelectedIdChange?.(parentId)}>
                    <IconFont type="SelectHigher" />
                </IconButton>
            </Tooltip>

            <Tooltip title="组件绑定的数据">
                <IconButton data-enable-hover onClickCapture={() => onDataDrawerVisible?.(true)}>
                    <IconFont fill="var(--color-white)" type="SearchData2" />
                </IconButton>
            </Tooltip>

            {!disabledWithVersion && (
                <>
                    <Tooltip title="复制">
                        <IconButton data-enable-hover onClickCapture={() => onDuplicate?.(data.id)}>
                            <IconFont type="Duplicate" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="删除">
                        <IconButton
                            data-enable-hover
                            onClickCapture={() => {
                                onRemove?.(data.id)
                            }}
                        >
                            <IconFont type="Trash" />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </Container>
    )

    const target = getBoundary(referenceRef.current, PAGE_CONTAINER_HOST_ID)

    if (target) {
        return createPortal(defaultDom, target)
    }

    return defaultDom
}
