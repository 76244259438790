import { Button, IconFont } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

export const SCxButtonWrapper = styled.div`
    position: absolute;
    top: 20px;
    right: 28px;
    opacity: 0;
    background-color: var(--color-white);
`

interface ExportContentProps {
    onExport?: () => void
}

export const ExportContent: React.FC<ExportContentProps> = ({ onExport }) => {
    return (
        <SCxButtonWrapper>
            <Button type="text" icon={<IconFont type="ImportExcel" />} onClick={onExport}>
                导出
            </Button>
        </SCxButtonWrapper>
    )
}
