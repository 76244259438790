import { Input } from '@byecode/ui/components/Input'
import { type FilterCommonCondition, VariableType } from '@lighthouse/core'
import React, { useMemo } from 'react'

interface InputParamsProps {
    filterItem: FilterCommonCondition
    onClear?: () => void
    onClick?: () => void
}

export const InputParams: React.FC<InputParamsProps> = ({ filterItem, onClick, onClear }) => {
    const value = useMemo(() => {
        const param = filterItem.paramList?.[0]
        if (param && param.type === VariableType.VALUE && param.valueVariable && typeof param.valueVariable.value === 'string') {
            return param.valueVariable.value
        }
        return ''
    }, [filterItem])

    return <Input readOnly focusOutLine={false} onClick={onClick} value={value} onClear={onClear} />
}
