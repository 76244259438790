import type { SystemStyles } from '@byecode/ui/theme/types'
import { filter, isNil } from 'rambda'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractSystemStyles<T extends Record<string, any>>(props: SystemStyles & T) {
    const { m, mb, ml, mr, mt, mx, my, p, pb, pl, pr, pt, px, py, ...rest } = props

    const systemStyles: SystemStyles = filter(v => !isNil(v), {
        m,
        mb,
        ml,
        mr,
        mt,
        mx,
        my,
        p,
        pb,
        pl,
        pr,
        pt,
        px,
        py
    })

    return { systemStyles, rest: rest as T }
}
