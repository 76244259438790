import type { Position } from '@lighthouse/core'
import { POSITION } from '@lighthouse/core'
import React, { useEffect, useMemo, useRef } from 'react'
import { useLatest } from 'react-use'
import styled from 'styled-components'

import svg from '../assets/handle.svg'
import type { Coordinates } from './types'

const HandleContainer = styled.div`
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Img = styled.img`
    width: 24px;
    height: 51px;
    user-select: none;
    pointer-events: none;
`
interface ResizeHandlerProps extends React.ComponentPropsWithoutRef<'div'> {
    direction: Position
    disabled?: boolean
    onResizeStart?: (initial: Coordinates) => void
    onResizing?: (current: Coordinates) => void
    onResizeEnd?: () => void
}
export const ResizeHandler = ({ direction, disabled, style, onResizeStart, onResizing, onResizeEnd, ...props }: ResizeHandlerProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const latestProps = useLatest({ onResizeStart, onResizing, onResizeEnd })

    const isHorizontalDirection = direction === 'left' || direction === 'right'

    const resizingRef = useRef(false)

    useEffect(() => {
        const el = ref.current
        if (!el) {
            return
        }

        function moveHandle(e: MouseEvent) {
            e.preventDefault()
            if (!resizingRef.current) {
                return
            }

            latestProps.current.onResizing?.({ x: e.x, y: e.y })
        }
        function upHandle() {
            if (!resizingRef.current) {
                return
            }

            latestProps.current.onResizeEnd?.()
            resizingRef.current = false

            unbindEvents()
        }
        function bindEvents() {
            document.addEventListener('mousemove', moveHandle)
            document.addEventListener('mouseup', upHandle)
        }
        function unbindEvents() {
            document.removeEventListener('mousemove', moveHandle)
            document.removeEventListener('mouseup', upHandle)
        }

        function downHandle(e: MouseEvent) {
            e.stopPropagation()
            resizingRef.current = true
            bindEvents()

            latestProps.current.onResizeStart?.({ x: e.x, y: e.y })
        }

        el.addEventListener('mousedown', downHandle)

        return () => {
            el.removeEventListener('mousedown', downHandle)
            unbindEvents()
        }
    }, [latestProps])

    const containerStyle = useMemo(() => {
        let positionStyle: React.CSSProperties | undefined
        switch (direction) {
            case POSITION.right: {
                positionStyle = {
                    cursor: 'e-resize',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    transform: 'translate(50%, 0)'
                }
                break
            }
            case POSITION.bottom: {
                positionStyle = {
                    cursor: 's-resize',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: '24px',
                    transform: 'translate(0, 50%)'
                }
                break
            }
            case POSITION.left: {
                positionStyle = {
                    cursor: 'e-resize',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    transform: 'translate(-50%, 0)'
                }
                break
            }

            default: {
                break
            }
        }

        return { ...positionStyle, ...style, ...(disabled ? { display: 'none' } : {}) }
    }, [direction, disabled, style])

    const imgStyle = useMemo(() => {
        return isHorizontalDirection ? undefined : { transform: 'rotate(90deg)' }
    }, [isHorizontalDirection])

    return (
        <HandleContainer ref={ref} {...props} draggable="false" onDragStart={e => e.preventDefault()} style={containerStyle}>
            <Img src={svg} alt="right-resize-handle" style={imgStyle} />
        </HandleContainer>
    )
}
