import { backgroundTransitionOnClick, IconFont, Input, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const TagContainer = styled.span`
    overflow: hidden;
    ${pointer}
`

export const TagWrapper = styled.span`
    padding: 2px 4px;
    background-color: var(--color-theme-4);
    color: var(--color-main);
    border-radius: 4px;
    overflow: hidden;
    font-size: 14px;

    iconpark-icon: {
        vertical-align: -1px;
    }

    &.selected {
        box-shadow: 0 0 0 2px var(--color-theme-5);
    }
`

export const Text = styled.span<{ size?: number }>`
    font-size: ${({ size }) => (size ? `${size}px` : undefined)};
`

export const Icon = styled(IconFont)<{ size?: number; isAction?: boolean }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: transparent;
    color: var(--color-gray-400);
    ${pointer}

    ${({ isAction }) => isAction && `${backgroundTransitionOnClick}`}
`

export const TooltipLabel = styled.div`
    word-break: break-word;
    white-space: pre-wrap;
    height: auto;
    max-width: 220px;
    line-height: 16px;
    font-size: var(--font-size-sm);

    li {
        padding-left: 6px;
    }
`

export const ErrorTag = styled.span`
    padding: 2px 4px;
    margin: 2px -4px;
    height: 100%;
    line-height: 24px;
    color: var(--color-red-500);
    background-color: var(--color-red-200);
    border-radius: 4px;
    font-size: 14px;
    overflow: hidden;
`
