import { Button, Empty, Flex, Image, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

interface ReviewProps {}

const Review: React.FunctionComponent<ReviewProps> = props => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <Empty
            style={{ height: '100%' }}
            icon={<Image src={getAssetUrl('empty', 'empty_review.png')} width={195} height={200} />}
            title=""
            description={
                <Flex direction="column" gap={30}>
                    <Text>{t('youAccountUnderReview')}</Text>
                    <Button onClick={() => navigate('/account/login')}>{t('backLoginPage')}</Button>
                </Flex>
            }
        />
    )
}

export default Review
