import type {
    BlockAbstract,
    BlockType,
    DataSourceAbstract,
    FieldBlockAbstract,
    FieldInputADTValue,
    FieldInputValue,
    FieldType,
    InputValueItem,
    PageNode,
    RecordLikeProtocol,
    SubFormBlockAbstract,
    SubFormBlockConfig,
    SubFormColumn
} from '@lighthouse/core'
import type { FieldInputError, FieldInputErrors, SubFormErrors } from '@lighthouse/shared'
import {
    CURRENT_USER,
    getEmptyFieldInputValue,
    getFieldInputError,
    getFieldInputRules,
    isEmptyFieldInputValue,
    isSimpleValue,
    isTextValue
} from '@lighthouse/shared'
import { error } from 'console'
import { find, forEach, keys, reduce } from 'rambda'

import type { SubFormRecord } from '../../blocks'
import type { FieldBlockInfo } from './types'

export const getDiffInputValueList = function (longList: InputValueItem[], shortList: InputValueItem[]): InputValueItem[] {
    return reduce<InputValueItem, InputValueItem[]>(
        (preVal, curVal) => {
            const { type, id } = curVal
            const isExit = find(item => id === item.id, shortList)
            if (!isExit) {
                return [...preVal, { value: getEmptyFieldInputValue(type), type, id } as InputValueItem]
            }
            return preVal
        },
        [],
        longList
    )
}

/**
 * 转换携带过来的初始值给字段block
 * @param type
 * @param value
 * @returns
 */
export const transformInitialValue = (type: FieldType, value: string | number): FieldInputValue => {
    switch (type) {
        case 'select': {
            return [value]
        }

        case 'date': {
            return value
        }

        default: {
            return value
        }
    }
}

export const getFieldBlockInfo = (block: FieldBlockAbstract) => {
    const { config, id, title } = block
    const { fieldPointer = '', inputType, initialValue } = config
    return { id, fieldId: fieldPointer, inputType, initialValue, config: { ...config, title } } as FieldBlockInfo
}

export const getFormContainerChildBlocks = <T extends BlockAbstract>({
    blocks,
    node,
    blockType
}: {
    blocks: BlockAbstract[]
    node: PageNode
    blockType: BlockType
}): T[] => {
    const list: T[] = []

    const { children: childrenNodes = [] } = node

    function getChildNode({ blocks, nodes, list }: { blocks: BlockAbstract[]; nodes: PageNode[]; list: T[] }) {
        for (const node of nodes) {
            const { id, children: childrenNodes = [] } = node
            const block = blocks.find(item => item.id === id)

            // 如果是 formContainer 类型，直接返回列表
            if (block?.type === 'formContainer') {
                return list
            }

            // 如果类型匹配，则添加到列表
            if (block?.type === blockType) {
                list.push(block as T)
            }

            // 递归处理子节点
            getChildNode({ nodes: childrenNodes, blocks, list })
        }
        return list
    }

    return getChildNode({ nodes: childrenNodes, blocks, list })
}

export const getFocusErrorBlockId = function (params: {
    errors: FieldInputErrors
    schema: DataSourceAbstract['schema']
    inputList: InputValueItem[]
}) {
    const { inputList, errors, schema } = params
    return reduce<InputValueItem, string | null>(
        (preVal, curVal, index) => {
            const field = schema?.[curVal.fieldId]
            const isEmpty = isEmptyFieldInputValue(curVal, field?.type)
            return preVal ?? (isEmpty || errors[curVal.id]?.repeat ? curVal.id : preVal)
        },
        null,
        inputList
    )
}

export const getFormErrors = function (params: {
    configs: FieldBlockInfo[]
    value: InputValueItem[]
    schema: DataSourceAbstract['schema']
    repeatList?: string[]
}): FieldInputErrors {
    const { configs, value, schema, repeatList } = params
    // [isRequiredEmpty, isAllEmpty]
    const errors = reduce<InputValueItem, FieldInputErrors>(
        (preVal, curVal, index) => {
            const blockConfig = find(item => item.id === curVal.id, configs)
            const field = schema?.[curVal.fieldId]
            if (!field || !blockConfig) {
                return preVal
            }
            const isRepeat = repeatList?.includes(blockConfig?.fieldId ?? '')
            const error = getFieldInputError({
                value: { value: curVal.value, type: blockConfig.inputType } as FieldInputADTValue,
                rule: getFieldInputRules({
                    config: blockConfig?.config,
                    title: blockConfig.config.title ?? '',
                    id: blockConfig.id,
                    custom: [
                        'repeat',
                        {
                            label: `${blockConfig.config.title}不可重复`,
                            value: blockConfig?.config.noRepeat ?? false
                        }
                    ]
                }),
                fieldType: field.type,
                isRepeat
            })
            const errorNum = Object.keys(error).length
            if (errorNum > 0) {
                preVal[curVal.id] = error
            }
            return preVal
        },
        {},
        value
    )
    const errorsNum = Object.keys(errors).length
    if (errorsNum === 0) {
        const isAllEmpty = reduce<InputValueItem, boolean>(
            (preVal, curVal) => {
                const field = schema?.[curVal.fieldId]
                const isEmpty = isEmptyFieldInputValue(curVal, field?.type)
                return Boolean(isEmpty && preVal)
            },
            false,
            value
        )
        const errors: FieldInputErrors = {
            [`${value[0].id}`]: {
                required: {
                    message: '不能为空',
                    type: 'required'
                }
            }
        }
        return isAllEmpty ? errors : {}
    }

    return errors
}

function checkDuplicateValues(formData: SubFormRecord[]) {
    const valueMap: Record<string, string[]> = {} // 用于存储 value 和对应的 subFormId
    const duplicates: Record<string, string[]> = {} // 用于存储重复的 value
    // 使用 Ramda 的 forEach 遍历 subFormEntries
    forEach(entry => {
        const contentKeys = keys(entry.content) // 获取 content 的键
        forEach(fieldKey => {
            const field = entry.content[fieldKey]
            const { value, fieldId } = field
            if (isTextValue(value)) {
                // 跳过空值
                if (valueMap[value]) {
                    valueMap[value].push(`${entry.id}-${fieldId}`)
                } else {
                    valueMap[value] = [`${entry.id}-${fieldId}`]
                }
            }
        }, contentKeys) // 第二个参数是需要遍历的数组
    }, formData) // 第二个参数是需要遍历的数组

    // 遍历 valueMap，找出重复的 value
    for (const value in valueMap) {
        if (valueMap[value].length > 1) {
            duplicates[value] = valueMap[value]
        }
    }
    return duplicates
}
export const getSubFormErrors = function (params: {
    block: SubFormBlockAbstract
    value: SubFormRecord[]
    dataSourceList: DataSourceAbstract[]
    subFormRepeat?: {
        subFormId: string
        repeatRecord: {
            recordId: string
            repeatFieldIds: string[]
        }[]
    }
}): SubFormErrors {
    const { block, value, subFormRepeat, dataSourceList } = params
    const repeatFieldMapIds = checkDuplicateValues(value)
    return reduce<SubFormRecord, SubFormErrors>(
        (preErrors, record, index) => {
            const {
                id: blockId,
                config: { pointer, columns }
            } = block
            const dataSource = find(item => item.id === pointer, dataSourceList)
            if (!dataSource) {
                return preErrors
            }
            const { schema } = dataSource
            const repeatList = subFormRepeat?.repeatRecord.find(v => v.recordId === record.id)?.repeatFieldIds
            forEach(([columnId, input]) => {
                const { fieldId } = input
                const field = schema?.[fieldId]
                const column = columns.find(v => v.id === columnId)
                const valueKey = typeof input.value === 'number' || typeof input.value === 'string' ? input.value : ''
                const isValueRepeat = repeatFieldMapIds[valueKey]?.includes(`${record.id}-${fieldId}`) && column?.config?.noRepeat
                const isRepeat = repeatList?.includes(fieldId) || isValueRepeat
                if (!column) {
                    return
                }
                const error = getFieldInputError({
                    value: { value: input.value, type: input.type } as FieldInputADTValue,
                    rule: getFieldInputRules({
                        config: column?.config,
                        id: blockId,
                        title: column.config.title ?? '',
                        custom: [
                            'repeat',
                            {
                                label: `${column.config.title}不可重复`,
                                value: column.config.noRepeat ?? false
                            }
                        ]
                    }),
                    fieldType: field.type,
                    isRepeat,
                    repeatIds: isRepeat ? repeatFieldMapIds[valueKey] : []
                })
                const errorNum = Object.keys(error).length
                if (errorNum > 0) {
                    preErrors.push({ recordId: record.id, error, columnId, blockId })
                }
            }, Object.entries(record.content))
            return preErrors
        },
        [],
        value
    )
}

export const getSubFormListErrors = function (params: {
    blocks: SubFormBlockAbstract[]
    value: Record<string, SubFormRecord[]>
    dataSourceList: DataSourceAbstract[]
    subFormListRepeat?: {
        subFormId: string
        repeatRecord: {
            recordId: string
            repeatFieldIds: string[]
        }[]
    }[]
}): SubFormErrors {
    const { blocks, value, subFormListRepeat, dataSourceList } = params
    // [isRequiredEmpty, isAllEmpty]
    return reduce<[string, SubFormRecord[]], SubFormErrors>(
        (preErrors, [blockId, subRecords], index) => {
            const block = find(item => item.id === blockId, blocks)
            if (!block) {
                return preErrors
            }
            const {
                config: { pointer }
            } = block
            const dataSource = find(item => item.id === pointer, dataSourceList)
            if (!dataSource) {
                return preErrors
            }
            const subFormRepeat = subFormListRepeat?.find(v => v.subFormId === blockId)
            const errors = getSubFormErrors({ block, value: subRecords, dataSourceList, subFormRepeat })
            return [...preErrors, ...errors]
        },
        [],
        Object.entries(value)
    )
}

export const toErrorFocusBlockId = function (params: {
    errors: FieldInputErrors
    inputList: InputValueItem[]
    schema: DataSourceAbstract['schema']
}) {
    const { errors, inputList, schema } = params
    const focusBlockId = getFocusErrorBlockId({ inputList, errors, schema })
    const focusEle = document.querySelector(`#node-${focusBlockId}`)
    focusEle && focusEle.scrollIntoView(true)
}

export const getSaveFieldInputList = function (inputList: InputValueItem[], userRecord?: RecordLikeProtocol) {
    return inputList.map(item => {
        if (item.type === 'relativeSelect' && typeof item.value === 'string' && item.value.includes(CURRENT_USER.userId)) {
            const userValue = reduce<string, string[]>(
                (preVaL, label) => {
                    if (label.includes(CURRENT_USER.userId)) {
                        const userFieldPointer = label.replace(`${CURRENT_USER.userId}-`, '')
                        if (!userRecord || !userFieldPointer) {
                            return preVaL
                        }
                        const userValue = userRecord.content[userFieldPointer].value
                        return [...preVaL, isTextValue(userValue) ? userValue : '']
                    }
                    return [...preVaL, label]
                },
                [],
                item.value.split(',')
            )
            return { ...item, value: userValue.join(',') }
        }
        return item
    })
}

export function getPageNode(nodes: PageNode[], id: string): PageNode | undefined {
    for (const node of nodes) {
        if (node.id === id) {
            return node
        }
        const childNode = getPageNode(node.children ?? [], id)
        if (childNode) {
            return childNode
        }
    }
}

export function getFormOfFieldCodeValidator(list: InputValueItem[], blocks: BlockAbstract[]) {
    return reduce<InputValueItem, Record<string, string>>(
        (obj, cur) => {
            const { value, id, fieldId, code = '', fieldType } = cur
            const block = find(block => block.id === id, blocks)
            if (block?.type === 'field' && block.config.inputType === 'phoneNumber') {
                if (!block.config.phoneNumber?.isOpened) {
                    return obj
                }
                if (fieldType === 'phoneNumber' && typeof value === 'string' && value) {
                    obj[value] = code
                }
            }

            return obj
        },
        {},
        list
    )
}

export function getFormOfSubFormCodeValidator(list: SubFormRecord[], blocks: BlockAbstract[]) {
    return reduce<SubFormRecord, Record<string, string>>(
        (obj, cur) => {
            const { id, content } = cur
            Object.entries(content).forEach(([columnId, fieldInputData]) => {
                const { id, value, fieldType, code } = fieldInputData
                const block = find(block => block.id === id, blocks)
                if (block?.type === 'subForm') {
                    const column = find(v => v.id === columnId, block.config.columns ?? [])
                    if (column && column.config.inputType === 'phoneNumber') {
                        if (!column.config.phoneNumber?.isOpened) {
                            return
                        }
                        if (fieldType === 'phoneNumber' && typeof value === 'string' && value && code) {
                            obj[value] = code
                        }
                    }
                }
            })
            return obj
        },
        {},
        list
    )
}
