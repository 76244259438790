import type { ButtonAction, ButtonProtocols } from './button'
import type { BackgroundNoImageProtocol } from './design'
import type { RichTextContentProtocol } from './richtext'

export type ActionItemIconPosition = 'left' | 'right'

export type ActionItemFitType = 'auto' | 'contain'

export enum ActionItemPattern {
    primary = 'primary',
    secondary = 'secondary',
    custom = 'custom'
}

export type ActionItem = {
    id: string
    iconPosition: ActionItemIconPosition
    color?: BackgroundNoImageProtocol
    iconColor?: BackgroundNoImageProtocol
    backgroundColor?: BackgroundNoImageProtocol
    borderColor?: BackgroundNoImageProtocol
    fillWay: ActionItemFitType
    name: RichTextContentProtocol
    pattern: ActionItemPattern
} & Omit<ButtonProtocols, 'pattern'>

export interface ViewActionGroupProtocol {
    customized?: boolean
    actionGroup?: { group: ActionItem[] }
}

export interface ViewActionProtocol {
    customized?: boolean
    action?: ButtonAction
}

export interface ActionsProtocol {
    actions: {
        toolbar: ViewActionGroupProtocol
        recordOperator: ViewActionGroupProtocol
        recordClicked: ViewActionProtocol
    }
}
