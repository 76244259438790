import { createStyles } from '../../theme/createStyles'
import { css } from '../../theme/stitches.config'

interface CheckboxStylesParams {
    color?: string
}

const _icon = css({
    borderRadius: '50%',
    transition: 'all 0.2s ease-in-out',
    color: '#fff',
    width: 22,
    fontSize: 18
})

const _inner = css({
    display: 'flex',
    transition: 'all 0.2s ease-in-out',
    border: '1px solid transparent',
    justifyContent: 'center',
    borderRadius: 4
})

const _label = css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: 14
})

export const useStyles = createStyles(({ color }: CheckboxStylesParams) => ({
    wrapper: css({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',

        // size variants
        '&[data-size="xs"]': {
            [`& .${_inner}`]: {
                width: 16,
                height: 16
            },
            [`& .${_icon}`]: {
                width: 16,
                fontSize: 14
            }
        },
        '&[data-size="sm"]': {
            [`& .${_inner}`]: {
                width: 20,
                height: 20
            },
            [`& .${_icon}`]: {
                width: 20,
                fontSize: 16
            }
        },
        '&[data-size="md"]': {
            [`& .${_inner}`]: {
                width: 22,
                height: 22
            },
            [`& .${_icon}`]: {
                width: 22,
                fontSize: 18
            }
        },
        '&[data-size="lg"]': {
            [`& .${_inner}`]: {
                width: 26,
                height: 26
            },
            [`& .${_icon}`]: {
                width: 26,
                fontSize: 24
            }
        },

        // checked variants
        /** true */
        '&[data-checked="true"]': {
            [`& .${_inner}`]: {
                backgroundColor: color ?? '$colorPrimary',
                borderColor: color ?? '$colorPrimary'
            },

            [`& .${_icon}`]: {
                transform: 'translateY(0) scale(1)'
            }
        },
        /** false */
        '&[data-checked="false"]': {
            [`& .${_inner}`]: {
                backgroundColor: '$colorWhite',
                borderColor: '$colorGray200'
            },

            [`& .${_icon}`]: {
                transform: 'translateY(10px) scale(0)'
            }
        },

        // disabled variants
        /** true */
        '&[data-disabled="true"]': {
            [`& .${_label}`]: {
                opacity: 0.5,
                cursor: 'not-allowed'
            },
            [`& .${_inner}`]: {
                backgroundColor: '$colorGray100',
                borderColor: '$colorGray200'
            }
        }
    }),
    input: css({
        width: 0,
        height: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
        visibility: 'hidden'
    }),
    label: _label,
    inner: _inner,
    icon: _icon
}))
