import { type FieldType, type VariableADTvalue, VariableType } from '@lighthouse/core'
import { Divider, Text } from '@mantine/core'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { getVariableValue, isVariableChildrenItemActive, isVariableItemActive } from '../../help'
import type { VariableChildren, VariableTree } from '../../types'
import { ListItem } from '../ListItem'

interface FieldListProps {
    data?: VariableTree
    value?: VariableADTvalue
    inputType: FieldType
    onChange: (value: VariableADTvalue) => void
    onClose?: () => void
}

const SCxListContainer = styled.div``

export const FieldList: React.FunctionComponent<FieldListProps> = ({ data, value, inputType, onChange, onClose }) => {
    const handleChange = useCallback(
        (childNode: VariableChildren, parentNode: VariableTree) => {
            onChange?.(getVariableValue(parentNode, childNode))
            onClose?.()
        },
        [onChange, onClose]
    )

    return data ? (
        <>
            <SCxListContainer>
                <Text size={12} style={{ padding: '0 12px', lineHeight: '32px' }} color="var(--color-gray-500)">
                    当前数据表
                </Text>
                {data?.children?.map(item => {
                    const isActive = isVariableChildrenItemActive(item, value)
                    return (
                        <ListItem
                            key={item.id}
                            data={item}
                            active={isActive}
                            showArrowIcon={false}
                            onChange={() => handleChange(item, data)}
                        />
                    )
                })}
            </SCxListContainer>
        </>
    ) : null
}
