import { FieldNameMap } from '../../../constants/dataSource'
import type { ListGroup } from './types'

export const RELATION_ADD_MODE = 0b01
export const RELATION_RELATED_MODE = 0b10

// export const INPUTWAYS: Option[] = [
//     {
//         label: '仅可新增记录',
//         value: 1
//     },
//     {
//         label: '仅可关联已有记录',
//         value: 2
//     },
//     {
//         label: '可新增记录/选择已有记录',
//         value: 3
//     }
// ]

export const TYPELISTITEMS: ListGroup = [
    {
        groupName: '基础',
        groupDescription: '数据类型',
        items: [
            { name: 'text', value: FieldNameMap.text },
            { name: 'date', value: FieldNameMap.date },
            { name: 'url', value: FieldNameMap.url },
            { name: 'number', value: FieldNameMap.number },
            { name: 'phoneNumber', value: FieldNameMap.phoneNumber },
            { name: 'email', value: FieldNameMap.email },
            { name: 'select', value: FieldNameMap.select },
            { name: 'file', value: FieldNameMap.file },
            { name: 'video', value: FieldNameMap.video },
            { name: 'person', value: FieldNameMap.person },
            { name: 'checkbox', value: FieldNameMap.checkbox }
        ]
    },
    {
        groupName: 'AI',
        items: [
            { name: 'textGeneration', value: FieldNameMap.textGeneration, remark: 'Beta' },
            { name: 'selectGenerationByText', value: FieldNameMap.selectGenerationByText, remark: 'Beta' },
            { name: 'textGenerationByAudio', value: FieldNameMap.textGenerationByAudio, remark: '敬请期待', disable: true },
            { name: 'textGenerationByImage', value: FieldNameMap.textGenerationByImage, remark: '敬请期待', disable: true },
            { name: 'booleanGenerationByText', value: FieldNameMap.booleanGenerationByText, remark: '敬请期待', disable: true },
            { name: 'dateGenerationByText', value: FieldNameMap.dateGenerationByText, remark: '敬请期待', disable: true },
            { name: 'numberGenerationByText', value: FieldNameMap.numberGenerationByText, remark: '敬请期待', disable: true },
            { name: 'textGenerationByFile', value: FieldNameMap.textGenerationByFile, remark: '敬请期待', disable: true }
        ]
    },
    {
        groupName: '高级',
        items: [
            { name: 'notes', value: '笔记' },
            { name: 'formula', value: FieldNameMap.formula }
        ]
    }
    // {
    //     groupName: '系统',
    //     items: [
    //         { name: 'createdBy', value: FieldNameMap.createdBy },
    //         { name: 'createdTime', value: FieldNameMap.createdTime },
    //         { name: 'updatedBy', value: FieldNameMap.updatedBy },
    //         { name: 'updatedTime', value: FieldNameMap.updatedTime }
    //     ]
    // }
]
