import { useCallback, useRef } from 'react'

export const useHandleAbortPrevious = <T, V>(func: (data: T) => V) => {
    const abortRef = useRef<AbortController | undefined>(undefined)
    const mutation = useCallback(
        (params: T) => {
            if (abortRef.current) {
                abortRef.current.abort()
            }
            abortRef.current = new AbortController()
            return func({
                ...params,
                abort: abortRef.current
            })
        },
        [func]
    )

    return { mutation, abortRef }
}
