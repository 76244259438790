import equal from 'fast-deep-equal'
import { useEffect, useState } from 'react'

export function useEqualArrayValue<T extends unknown[]>(data: T) {
    const [value, setValue] = useState(data)

    useEffect(() => {
        setValue(list => {
            if (list.length !== data.length) {
                return data
            }
            // data.forEach((item, index) => {
            //     const oldItem = list[index]
            //     if (!equal(item, oldItem)) {
            //         list[index] = item
            //     }
            // })
            if (!equal(list, data)) {
                return data
            }
            return list
        })
    }, [data])

    return value
}
