import type { AppUser, Field, PersonConfig, PersonField as PersonConfigField } from '@lighthouse/core'
import { useElementSize } from '@mantine/hooks'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncRetry } from 'react-use'

import { useApplicationContext } from '../../../../contexts'
import { PersonSelect } from '../../../PersonSelect'
import { minPopoverWidth, outlineWidth } from '../../constant'
import type { FieldBaseProps } from '../../types'
import * as SC from './styles'

interface PersonFieldProps extends FieldBaseProps {
    value: string[]
    personConfig?: PersonConfig
    personField?: PersonConfigField
}

const PersonField: React.FunctionComponent<PersonFieldProps> = ({
    value: data,
    personConfig,
    isControlled,
    readOnly,
    config,
    personField,
    onFetchPersonOptions,
    onCellChange
}) => {
    const { placeholder, person: personInputConfig } = personConfig ?? {}
    const { person } = personField ?? {}
    const { multiple } = person ?? {}
    const { filter } = personInputConfig ?? {}
    const { personOptions } = useApplicationContext()

    const { value: filterPersonOptions } = useAsyncRetry(() => {
        if (!filter || (filter.expression?.conditions ?? []).length === 0 || !onFetchPersonOptions) {
            return Promise.resolve(undefined)
        }
        return onFetchPersonOptions?.(filter)
    }, [filter])

    const currentPersonOptions = useMemo(() => filterPersonOptions || personOptions, [filterPersonOptions, personOptions])

    const { t } = useTranslation()

    const [value, setValue] = useState(data ?? [])

    const [opened, setOpened] = useState(false)

    const usedValue = useMemo(() => (isControlled ? data : value), [data, isControlled, value])
    const handleMultiChange = useCallback(
        (value: string[]) => {
            setValue(value)
            onCellChange?.({ type: 'person', value })
        },
        [onCellChange]
    )
    const containerRef = useRef<HTMLDivElement>(null)

    const dropDownWidth = containerRef?.current?.getBoundingClientRect()?.width ?? minPopoverWidth

    return (
        <SC.Container ref={containerRef} data-field-border={opened}>
            <PersonSelect
                options={currentPersonOptions}
                value={usedValue}
                tagClearable
                withinPortal
                readOnly={readOnly}
                clearable={false}
                isHiddenUserId
                enableCheckBox={false}
                isMultiple={multiple}
                placeholder={placeholder}
                searchPlaceholder={t('personNameOrPersonId')}
                emptyDesc={t('noFindPerson')}
                width={`${dropDownWidth}px`}
                isBorder={false}
                isWrap
                styles={{
                    value: {
                        alignItems: 'flex-start',
                        padding: '0 8px'
                    }
                }}
                onVisibleChange={setOpened}
                onChange={handleMultiChange}
            />
        </SC.Container>
    )
}

export default PersonField
