import styled from 'styled-components'

import { NumberInput } from '../../NumberInput'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;
    overflow: hidden;
`

export const Item = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 55px;
    &.mobile {
        width: 32px;
        border-bottom: 1px solid var(--color-black);
    }

    &.desktop {
        width: 45px;
        border-radius: 10px;
        background: var(--color-gray-100);
    }

    /* */
`

export const Cursor = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
    height: 40%;
    animation: u-cursor-flicker 1s infinite;
    background-color: var(--color-black);

    @keyframes u-cursor-flicker {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`

export const Input = styled(NumberInput)`
    position: absolute;
    // 之所以需要input输入框，是因为有它才能唤起键盘
    // 这里将它设置为两倍的屏幕宽度，再将左边的一半移出屏幕，为了不让用户看到输入的内容
    inset: 0;
    left: -750px;
    opacity: 0;
    background-color: transparent;
    text-align: left;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none !important;
        margin: 0;
    }
`
