import { useAtomAction } from '@lighthouse/shared'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { setLanguageAtom } from '@/atoms/application/action'

import { usePageList } from './useApplication'

export const useToggleLang = () => {
    const pageList = usePageList()
    const navigate = useNavigate()
    const { run: setLanguage } = useAtomAction(setLanguageAtom)

    // 切换语言
    const handleToggleLang = useCallback(
        (v: string) => {
            setLanguage(v)
            const homePage = pageList.find(page => page.isHome && page.language === v)
            if (homePage) {
                const path = homePage.route ? `/${homePage.route}` : `/P/${homePage.id}`
                navigate(path)
            }
        },
        [navigate, pageList, setLanguage]
    )

    return { handleToggleLang }
}
