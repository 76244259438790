import { IconFont } from '@byecode/ui'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

interface NotificationMomentProps {}

export interface NotificationStatusProps {
    status: 'success' | 'pending' | 'error'
}

const SCxNotificationMomentWrapper = styled.div`
    padding: 16px 0;
`

const SCxNotificationMomentItem = styled.div`
    display: flex;

    &:not(:last-child) {
        padding-bottom: 16px;
    }
`

const SCxNotificationMomentItemAvatar = styled.div<{ isTail?: boolean }>`
    position: relative;
    margin-right: 8px;
    border-radius: 8px;

    ${props =>
        !props.isTail &&
        css`
            &:after {
                position: absolute;
                content: '';
                width: 0px;
                border: 1px dashed var(--color-gray-200);
                height: calc(100% - 35px);
                top: 45px;
                left: 50%;
                z-index: -1;
            }
        `}
`

const SCxNotificationMomentItemGroupAvatarIcon = styled.div`
    background-color: var(--color-main);
    position: relative;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &:after {
        position: absolute;
        content: '';
        width: 0px;
        border: 1px dashed var(--color-gray-200);
        height: calc(100% - 35px);
        top: 45px;
        left: 50%;
        z-index: -1;
    }
`

const SCxNotificationMomentItemAvatarIcon = styled.img`
    width: 36px;
    height: 36px;
    border-radius: 8px;
    overflow: hidden;
`

const SCxItemStatusWrapper = styled.div`
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`

const SCxItemStatus = styled(IconFont)`
    font-size: 8px;
    color: var(--color-white);
`

const SCxNotificationMomentItemContent = styled.div`
    flex: 1;
`

const SCxNotificationMomentItemTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    color: var(--color-gray-500);
    font-size: var(--font-size-sm);
`

const SCxNotificationMomentItemTitle = styled.div``

const SCxNotificationMomentItemTime = styled.div``

const SCxNotificationMomentItemUser = styled.div`
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
`

const SCxNotificationMomentItemInfo = styled.div`
    border-radius: 4px;
    width: 100%;
    margin: 12px 0;
    padding: 10px;
    font-size: var(--font-size-sm);
    background-color: var(--color-gray-100);
`

const SCxNotificationMomentItemSignItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-gray-500);

    margin-bottom: 12px;
`

type Data = { user: string; avatar: string; time: string; status: 'success' | 'pending'; comments: string }

const notifications: (
    | { type: 'single'; data: Data }
    | { type: 'multiple'; data: { type: 'USER' | 'ROLE'; name: string; avatar: string; status: 'success' | 'pending' }[] }
)[] = [
    {
        type: 'single',
        data: {
            user: '合一',
            avatar: 'https://picx.zhimg.com/v2-d4c2b4c1de6e36972bc5e16f6df3cf25_xl.jpg?source=32738c0c',
            time: '刚刚',
            status: 'success',
            comments: '“情况属实，予通过”'
        }
    },
    {
        type: 'single',
        data: {
            user: '合入',
            avatar: 'https://pica.zhimg.com/v2-4db1e8afab540d22238408723ebf3d4f_xl.jpg?source=32738c0c',
            time: '30s 前',
            status: 'pending',
            comments: '“行吧”'
        }
    },
    {
        type: 'multiple',
        data: [
            {
                type: 'USER',
                name: 'Billy',
                avatar: 'https://pica.zhimg.com/v2-4db1e8afab540d22238408723ebf3d4f_xl.jpg?source=32738c0c',
                status: 'success'
            },
            {
                type: 'USER',
                name: '合一',
                avatar: 'https://picx.zhimg.com/v2-d4c2b4c1de6e36972bc5e16f6df3cf25_xl.jpg?source=32738c0c',
                status: 'pending'
            },
            {
                type: 'ROLE',
                name: '学生',
                avatar: 'https://pica.zhimg.com/v2-4db1e8afab540d22238408723ebf3d4f_xl.jpg?source=32738c0c',
                status: 'pending'
            }
        ]
    },
    {
        type: 'single',
        data: {
            user: '合入',
            avatar: 'https://pica.zhimg.com/v2-4db1e8afab540d22238408723ebf3d4f_xl.jpg?source=32738c0c',
            time: '30s 前',
            status: 'pending',
            comments: '还可以”'
        }
    }
]

const STATUS_MAP = {
    pending: '等待审批',
    success: '已同意',
    error: '已拒绝'
}

export const NotificationStatus: React.FC<NotificationStatusProps> = ({ status }) => {
    const statusIcon = useMemo(() => {
        switch (status) {
            case 'success': {
                return 'Tick'
            }
            case 'pending': {
                return 'DotsThree'
            }
            case 'error': {
                return 'Close'
            }
            default: {
                return 'DotsThree'
            }
        }
    }, [status])
    const statusColor = useMemo(() => {
        switch (status) {
            case 'success': {
                return 'var(--color-green-500)'
            }
            case 'pending': {
                return 'var(--color-blue-500)'
            }
            case 'error': {
                return 'var(--color-red-500)'
            }
            default: {
                return 'var(--color-yellow-500)'
            }
        }
    }, [status])

    return (
        <SCxItemStatusWrapper style={{ backgroundColor: statusColor }}>
            <SCxItemStatus type={statusIcon} />
        </SCxItemStatusWrapper>
    )
}

export const NotificationMoment: React.FC<NotificationMomentProps> = () => {
    const notificationMomentContent = useMemo(() => {
        return notifications.map(({ type, data }, index) => {
            const isTail = notifications.length - 1 === index
            if (type === 'single') {
                const { user, avatar, status, comments } = data
                return (
                    <SCxNotificationMomentItem key={user}>
                        <SCxNotificationMomentItemAvatar isTail={isTail}>
                            <SCxNotificationMomentItemAvatarIcon src={avatar} />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 24,
                                    right: 0,
                                    borderRadius: '50%',
                                    border: '1px solid var(--color-white)'
                                }}
                            >
                                <NotificationStatus status={status} />
                            </div>
                        </SCxNotificationMomentItemAvatar>
                        <SCxNotificationMomentItemContent>
                            <SCxNotificationMomentItemTitleWrapper>
                                <SCxNotificationMomentItemTitle>发起申请</SCxNotificationMomentItemTitle>
                                <SCxNotificationMomentItemTime>2023.11.21 16:26</SCxNotificationMomentItemTime>
                            </SCxNotificationMomentItemTitleWrapper>
                            <SCxNotificationMomentItemUser>{user}</SCxNotificationMomentItemUser>
                            <SCxNotificationMomentItemInfo>{comments}</SCxNotificationMomentItemInfo>
                        </SCxNotificationMomentItemContent>
                    </SCxNotificationMomentItem>
                )
            }

            return (
                <SCxNotificationMomentItem key="pending">
                    <SCxNotificationMomentItemAvatar isTail={isTail}>
                        <SCxNotificationMomentItemGroupAvatarIcon>
                            <IconFont type="User" size={20} color="var(--color-white)" />
                        </SCxNotificationMomentItemGroupAvatarIcon>
                    </SCxNotificationMomentItemAvatar>
                    <SCxNotificationMomentItemContent>
                        <SCxNotificationMomentItemTitleWrapper>
                            <SCxNotificationMomentItemTitle>等待审批</SCxNotificationMomentItemTitle>
                        </SCxNotificationMomentItemTitleWrapper>
                        <SCxNotificationMomentItemUser>{data.length}人会签</SCxNotificationMomentItemUser>

                        <SCxNotificationMomentItemInfo>
                            {data.map(({ type, name, avatar, status }) => (
                                <SCxNotificationMomentItemSignItem key={name}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <NotificationStatus status={status} />
                                        <SCxNotificationMomentItemAvatar style={{ display: 'flex', alignItems: 'center', marginLeft: 6 }}>
                                            <SCxNotificationMomentItemAvatarIcon
                                                src={avatar}
                                                style={{ width: 24, height: 24, marginRight: 6, borderRadius: 4, flexShrink: 0 }}
                                            />
                                            {name}
                                        </SCxNotificationMomentItemAvatar>
                                    </div>
                                    {STATUS_MAP[status]}
                                </SCxNotificationMomentItemSignItem>
                            ))}
                        </SCxNotificationMomentItemInfo>
                    </SCxNotificationMomentItemContent>
                </SCxNotificationMomentItem>
            )
        })
    }, [])

    return <SCxNotificationMomentWrapper>{notificationMomentContent}</SCxNotificationMomentWrapper>
}
