import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

export interface TagStylesParams {
    color?: string
    background?: string
    radius?: React.CSSProperties['borderRadius']
    fill?: boolean
}

export const useStyles = createStyles(({ background, color, radius, fill }: TagStylesParams) => {
    return {
        root: css({
            color,
            background,
            fontSize: 14,
            padding: '4px 8px',
            borderRadius: radius ?? '100px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: fill ? 'auto' : 'fit-content'
        })
    }
})
