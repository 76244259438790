import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { Box } from '../Box'
import type { PickerColumnStylesName } from './Column'
import { Column } from './Column'
import { useStyles } from './PickerView.style'

type SelfStylesName = Selectors<typeof useStyles>
export type PickerViewStylesName = SelfStylesName | PickerColumnStylesName

export interface PickerViewProps
    extends StyleComponentProps<PickerViewStylesName>,
        Omit<React.ComponentPropsWithoutRef<'div'>, 'value' | 'onChange'> {
    data: React.Key[][]
    /**
     * @description 选中的索引列表
     * @date 9/4/2023 - 4:26:12 PM
     *
     * @type {number[]}
     */
    value?: number[]

    onChange?: (value: number[]) => void
    onConfirm?: (e: React.MouseEvent) => void
    onCancel?: (e: React.MouseEvent) => void
}

export const PickerView = forwardRef<HTMLDivElement, PickerViewProps>((props, ref) => {
    const { data, value, onChange, onConfirm, onCancel, className, classNames, styles, unstyled, ...rest } = props

    const { classes } = useStyles({}, { name: 'm-DatePicker', classNames, styles, unstyled })

    return (
        <Box className={clsx(classes.view, className)} ref={ref} {...rest}>
            <Box className={classes.header}>
                <Box role="button" className={classes.cancel} onClick={onCancel}>
                    取消
                </Box>
                <Box role="button" className={classes.confirm} onClick={onConfirm}>
                    确认
                </Box>
            </Box>
            <Box className={classes.body}>
                {data.map((col, index) => (
                    <Column
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        styles={styles}
                        data={col}
                        index={value?.[index]}
                        onIndexChange={i => value && onChange?.([...value.slice(0, index), i, ...value.slice(index + 1)])}
                    />
                ))}
            </Box>
            <Box className={classes.mask}>
                <Box className={classes.indicator} />
            </Box>
        </Box>
    )
})
