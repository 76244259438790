import { backgroundTransitionOnClick, Divider, IconFont, Input, pointer, Select } from '@byecode/ui'
import styled from 'styled-components'

export const DividerLine = styled(Divider)`
    margin: 12px 0;
`
/**
 * common styles
 */

export const ActionWrapper = styled.div`
    margin: 12px 16px;
    display: flex;
    justify-content: flex-end;
`

export const FieldForm = styled.div``

export const FieldConfigWrapper = styled.div`
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
`

export const FormItemWrapper = styled.div`
    padding: 0 16px;
`

export const FieldConfigHeaderWrapper = styled.div`
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const FieldConfigSorter = styled.div`
    display: flex;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    cursor: pointer;
`

export const FieldConfigHeader = styled.div`
    height: 24px;
    line-height: 24px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
    white-space: nowrap;
    margin-bottom: 4px;
`

export const FieldConfigLabel = styled.div`
    display: inline-block;
    font-size: var(--font-size-normal);
    color: var(--color-gray-900);
    white-space: nowrap;
    margin-right: 12px;
`

export const IdLengthUnit = styled.span`
    font-size: 14px;
    margin-left: 1px;
`

export const SortIcon = styled(IconFont)`
    margin-right: 6px;
`

export const OptionAdder = styled.div`
    display: inline-flex;
    align-items: center;
    height: 40px;
    color: var(--color-main);
    font-size: var(--font-size-normal);
    cursor: pointer;
`

export const GroupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const Icon = styled(IconFont)`
    margin-right: 4px;
`

export const OptionList = styled.div`
    /* margin: 0 14px; */
`

export const OptionInput = styled(Input)`
    margin-left: 6px;
    flex: 1;
    /* background-color: var(--color-white);

    & input {
        margin: 4px 0 0 6px;
    } */
`

export const FieldConfigSelect = styled(Select)`
    width: 100%;
`

export const FooterWrapper = styled.div<{ error?: boolean }>`
    width: 100%;
    height: 32px;
    background-color: var(--color-gray-50);
    padding: 8px 12px;
    font-size: var(--font-size-sm);
    color: ${({ error }) => (error ? 'var(--color-red-500)' : 'var(--color-gray-900)')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const ListWrapper = styled.div`
    padding: 8px 0;
    max-height: 300px;
    overflow-y: auto;
`

export const ItemInfoWrapper = styled.div`
    width: 224px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 9px 16px;

    ${pointer}
    ${backgroundTransitionOnClick}
`

export const ItemInfoLabel = styled.div`
    display: inline-block;
    font-size: var(--font-size-normal);
    color: var(--color-black);
`

export const OptionItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--color-gray-500);
`

export const MoveIcon = styled(IconFont)`
    user-select: none;
    cursor: grab;
    margin-right: 4px;
`

/**
 * common styles
 */

export const FormItemLabel = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 8px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
`

export const FormItemCell = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const FormItemLimitCell = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-normal);
`

export const ConfigPreviewTip = styled.p`
    padding: 8px 14px;
    margin-top: 8px;
    font-size: var(--font-size-sm);
    color: var(--color-black);
    background-color: var(--color-gray-1);
`

export const RichTextContainer = styled.div`
    margin: 8px 0;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    .ProseMirror {
        min-height: 120px !important;
    }
`
