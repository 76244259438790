export async function parseExcel(file: File, sheetName?: string) {
    const ExcelJS = await import('exceljs')
    const workbook = new ExcelJS.Workbook()

    // Read the file
    const arrayBuffer = await file.arrayBuffer()
    await workbook.xlsx.load(arrayBuffer)

    // Get the sheet by name or default to the first sheet
    const worksheet = sheetName ? workbook.getWorksheet(sheetName) : workbook.worksheets[0]

    if (!worksheet) {
        throw new Error('Worksheet not found')
    }

    // Process the data into records and columns
    const records: Record<string, string>[] = []
    const columns: { key: string, name: string }[] = []

    worksheet.eachRow((row, rowIndex) => {
        if (rowIndex === 1) {
            // Build the columns list from the first row (assumed to be the header)
            row.eachCell((cell, colNumber) => {
                columns.push({ key: String(colNumber), name: cell.text })
            })
        } else {
            // Add the data rows
            const record: Record<string, string> = {}
            row.eachCell((cell, colNumber) => {
                record[String(colNumber)] = cell.text
            })
            records.push(record)
        }
    })

    return { records, columns }
}
