import type { IconFontProps } from '@byecode/ui'
import { IconFont } from '@byecode/ui'
import type { FieldType, TypeInstanceMap } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { FieldTypeColorMap, signFieldIconColor, signFieldId } from '../../constants'
import { getFieldIcon, getIsAppointField } from '../../utils'

const SCxDiv = styled.div`
    position: relative;
    width: 18px;
    height: 18px;
`

const SCxUserIcon = styled(IconFont)`
    position: absolute;
    right: -2px;
    bottom: -1px;
    font-size: 12px;
`

const SCxIcon = styled(IconFont)``

export type FieldIconProps = {
    id: string
    type: FieldType
    innerType?: TypeInstanceMap
    isFormula?: boolean
    // isUserField?: boolean
    // isJoinPrimaryField?: boolean
    style?: React.CSSProperties
} & IconFontProps

export const FieldIcon: React.FC<FieldIconProps> = ({
    id,
    type,
    innerType,
    isFormula,
    style,
    className,
    ...restProps
}) => {
    const iconType = getFieldIcon(id, type, innerType, isFormula)
    // const isSignField = getIsAppointField(id, signFieldId)

    const styles = { fontSize: 18, ...style }

    return (
        <SCxDiv>
            {/* {isUserField && <SCxUserIcon type="Lightning-Mini" />} */}
            <SCxIcon type={iconType || ''} className={className} style={styles} {...restProps} />
        </SCxDiv>
    )
}
