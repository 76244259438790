import * as React from 'react'

import { Account } from '@/containers/Account'

interface BindMobileProps {}

const BindMobile: React.FunctionComponent<BindMobileProps> = props => {
    return <Account defaultMode="bindMobile" isFixedMode />
}

export default BindMobile
