import { IconFont, Menu, MenuItem } from '@byecode/ui'
import { SelectedMode } from '@lighthouse/core'
import { Checkbox } from '@mantine/core'
import cls from 'classnames'
import React, { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'

export interface TableHeaderCheckerProps {
    index?: number
    checked?: boolean
    isRecordSelecting?: boolean
    height?: number
    checkable?: boolean
    showOpenerIcon?: boolean
    className?: string
    borderTop?: boolean
    extraBorderTop?: boolean
    // borderBottom?: boolean
    // extraBorder?: boolean
    isStripe?: boolean
    // isLast?: boolean
    borderRadius?: boolean
    disableCheck?: boolean
    onChange?: (checked: boolean) => void
    onSelectModeChange?: (mode?: SelectedMode) => void
    onRecordOpen?: () => void
}

const SCxTableRecordIndex = styled.p`
    position: absolute;
    color: var(--color-gray-400);
    opacity: 1;
`

const SCxTableRecordCheckerWrapper = styled.div`
    display: flex;
    padding-left: 12px;
    align-items: center;
    height: 100%;
    position: absolute;
    opacity: 0;
    gap: 2px;
`

const SCxTableHeaderChecker = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 100%;
`

const SCxTableExtra = styled.div`
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
`

const SCxTableOpener = styled(IconFont)`
    cursor: pointer;
    z-index: 1;
`

const SCxIcon = styled(IconFont)`
    font-size: 18px;
    color: var(--color-black);
`

export const SCxTableHeaderCheckerContent = styled.div<{ borderTop?: boolean; isStripe?: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${({ isStripe }) => (isStripe ? 'var(--color-gray-100)' : 'none')};
    border-top: ${props => (props.borderTop ? '1px solid var(--color-gray-200)' : 'none')};
`

export const SCxCheckerPrefix = styled.div<{
    borderTop?: boolean
    isStripe?: boolean
    borderRadius?: boolean
    height?: number
}>`
    width: 8px;
    height: ${props => (props.height ? `${props.height}px` : '37px')};
    background-color: ${({ isStripe }) => (isStripe ? 'var(--color-gray-100)' : 'none')};
    border-top: ${({ borderTop }) => (borderTop ? '1px solid var(--color-gray-200)' : 'none')};

    ${props =>
        props.borderRadius &&
        css`
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        `}
`

const SCxTableHeaderCheckerWrapper = styled.div<{ height?: number; border?: boolean }>`
    position: sticky;
    top: 0px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: ${props => (props.height ? `${props.height}px` : '36px')};
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    background-color: var(--color-white);
    overflow: hidden;

    border-top: ${props => (props.border ? '1px solid var(--color-gray-200)' : 'none')};

    &:hover {
        ${SCxTableExtra} {
            opacity: 1;
        }
    }

    &.table-checkable {
        &.checked,
        &:not(.table-checked):hover {
            ${SCxTableRecordCheckerWrapper} {
                opacity: 1;
            }
            ${SCxTableRecordIndex} {
                opacity: 0;
            }
            ${SCxIcon} {
                display: none;
            }
        }
    }
`

const SCxArrowWrapper = styled.div`
    width: 12px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #26415a0f;
    }
`

export const SCXCheckBox = styled(Checkbox)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    [type='checkbox']:checked {
        border-color: var(--color-app-main);
        background-color: var(--color-app-main);
    }
`

export const TableHeaderChecker: React.FC<TableHeaderCheckerProps> = ({
    index,
    height,
    showOpenerIcon,
    checked,
    checkable,
    isRecordSelecting,
    className,
    borderTop,
    extraBorderTop,
    isStripe,
    borderRadius,
    disableCheck,
    onChange,
    onSelectModeChange,
    onRecordOpen
}) => {
    const handleChange = useCallback(
        (ev: React.MouseEvent | React.ChangeEvent) => {
            ev.stopPropagation()
            if (!checkable) {
                return
            }
            onChange?.(!checked)
        },
        [checkable, checked, onChange]
    )

    const order = useMemo(() => {
        if (index !== undefined) {
            return <SCxTableRecordIndex>{index + 1}</SCxTableRecordIndex>
        }
        if (!checkable) {
            return <SCxIcon type="PropertyNumber" />
        }
        return null
    }, [checkable, index])

    return (
        <SCxTableHeaderCheckerWrapper height={height} className={cls({ checked, 'table-checkable': checkable }, className)}>
            <SCxCheckerPrefix height={height} borderRadius={borderRadius} isStripe={isStripe} borderTop={extraBorderTop} />

            {!disableCheck && (
                <SCxTableHeaderCheckerContent borderTop={borderTop} isStripe={isStripe}>
                    <SCxTableHeaderChecker>
                        {order}
                        <SCxTableRecordCheckerWrapper style={isRecordSelecting || (index === undefined && checkable) ? { opacity: 1 } : {}}>
                            <SCXCheckBox size="xs" checked={checked} onChange={handleChange} />
                            <Menu width={204} position="bottom-start" closeOnItemClick withinPortal>
                                <Menu.Target>
                                    <SCxArrowWrapper>
                                        <IconFont type="CaretDown" />
                                    </SCxArrowWrapper>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <MenuItem onClick={() => onSelectModeChange?.(SelectedMode.CURRENT_PAGE)}>当前页</MenuItem>
                                    <MenuItem onClick={() => onSelectModeChange?.(SelectedMode.ALL)}>所有记录</MenuItem>
                                    <MenuItem onClick={() => onSelectModeChange?.()}>取消选择</MenuItem>
                                </Menu.Dropdown>
                            </Menu>
                        </SCxTableRecordCheckerWrapper>
                    </SCxTableHeaderChecker>
                    {showOpenerIcon && (index || index === 0) && (
                        <SCxTableExtra>
                            <SCxTableOpener size={16} type="Open" onClick={onRecordOpen} />
                        </SCxTableExtra>
                    )}
                </SCxTableHeaderCheckerContent>
            )}
        </SCxTableHeaderCheckerWrapper>
    )
}
