export const locale = {
    ok: '确定',
    cancel: '取消',
    now: '此刻',
    chooseDate: '选择日期',
    chooseTime: '选择时间',
    complete: '完成',
    date: '日期',
    time: '时间',
    clear: '清空'
}
