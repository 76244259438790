import { Box } from '@byecode/ui/components/Box'
import { Flex } from '@byecode/ui/components/Flex'
import { IconFont } from '@byecode/ui/components/IconFont'
import type { StyleComponentProps } from '@byecode/ui/theme/types'
import React, { forwardRef, useCallback } from 'react'

import { Checkbox } from '../../Checkbox'
import type { MultiOption } from '../types'
import { useStyles } from './MultiSelectItem.style'
import type { MultiSelectItemStyleNames } from './MultiSelectItem.type'

export interface MultiSelectItemProps
    extends MultiOption,
        StyleComponentProps<MultiSelectItemStyleNames>,
        Omit<React.ComponentPropsWithoutRef<'div'>, 'onMultiSelect' | 'children'> {
    isActive?: boolean
    themeColor?: string
    onItemChange?: (val: string) => void
}

export const MultiSelectItem = forwardRef<HTMLDivElement, MultiSelectItemProps>((props, ref) => {
    const { classNames, styles, unstyled, label, value, icon, extra, describe, isActive, themeColor, onItemChange, ...rest } = props
    const { classes } = useStyles({}, { name: 'selectItem', classNames, styles, unstyled })

    const handleSelect = useCallback(() => {
        onItemChange?.(value)
    }, [onItemChange, value])

    return (
        <Box ref={ref} {...rest} onMouseDown={handleSelect}>
            <Flex alignItems="center" justifyContent="space-between">
                <Flex gap="8px" alignItems="center" styles={{ root: { lineHeight: '16px', overflow: 'hidden' } }}>
                    <Checkbox size="xs" checked={isActive} color={themeColor} />
                    {typeof icon === 'string' ? <IconFont type={icon} size={16} className={classes.icon} /> : icon}
                    <Box style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{label}</Box>
                </Flex>
                {extra}
            </Flex>
            {describe && <Box className={classes.describe}>{describe}</Box>}
        </Box>
    )
})
