import type { StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import type { CSSProperties, SyntheticEvent } from 'react'
import React from 'react'

import { Box } from '../Box'
import { useStyles } from './Image.styles'

export type ImageStylesNames = 'root' | 'image'

interface ImageProps extends React.ComponentPropsWithoutRef<'div'>, StyleComponentProps<ImageStylesNames> {
    src?: string
    radius?: CSSProperties['borderRadius']
    alt?: string
    fit?: CSSProperties['objectFit']
    fallbackSrc?: string
    width?: number | string
    height?: number | string
    className?: string
    onError?: (event: SyntheticEvent<HTMLImageElement>) => void
}

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
    ({ src, radius, alt, fit, fallbackSrc, width, height, styles, style, className, classNames, onError }, ref) => {
        const { classes } = useStyles({ radius, fit, width, height }, { name: 'Image', styles, classNames })

        return (
            <Box className={clsx(className, classes.root)} style={style}>
                <img className={classes.image} alt={alt} src={src} onError={onError} ref={ref} />
            </Box>
        )
    }
)
