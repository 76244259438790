import type {
    ActionsProtocol,
    AiFieldStatus,
    ButtonAction,
    DataSourceAbstract,
    Field,
    HighLightConditions,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SchemaProtocol,
    TableViewOptions,
    ViewField
} from '@lighthouse/core'
import { conversionHighLightRecord } from '@lighthouse/shared'
import chroma from 'chroma-js'
import type { atomWithImmer } from 'jotai-immer'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { ListRecord } from './ListRecord'
import { getDesignStyleMap, getDesignTypeMap } from './utils'

export interface ListContentProps extends ActionsProtocol {
    blockId: string
    checkable?: boolean
    recordOpenable?: boolean
    canDeleteRecord?: boolean
    canRecordEdit?: boolean
    primaryField?: Field
    viewFields: ViewField[]
    schema: SchemaProtocol['schema']
    isMobile?: boolean
    records: RecordLikeProtocol[]
    selectedRecords?: string[]
    highLightRules?: HighLightConditions
    designConfig: TableViewOptions
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    onRecordSelect?: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete?: (recordId: string) => void
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

const SCxListContent = styled.div<{ gap: number }>`
    user-select: none;
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => `${gap}px`};
`

export const ListContent: React.FC<ListContentProps> = ({
    blockId,
    actions,
    checkable,
    recordOpenable,
    canDeleteRecord,
    canRecordEdit,
    primaryField,
    viewFields,
    schema,
    isMobile,
    records,
    selectedRecords,
    highLightRules = [],
    designConfig,
    aiFieldStatusListAtom,
    onRecordSelect,
    onRecordClick,
    onRecordEdit,
    onRecordDelete,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle
}) => {
    const documentStyles = getComputedStyle(document.body)
    const themeColor = documentStyles.getPropertyValue('--color-main').trim()
    const { background, isGap } = getDesignTypeMap(designConfig.designType)
    const gap = useMemo(() => {
        const { gap } = getDesignStyleMap(designConfig.designStyle)
        return isGap ? gap : 0
    }, [designConfig.designStyle, isGap])

    const hoverColor = useMemo(() => {
        const hoverColor = chroma(themeColor).alpha(0.06).hex()
        if (!background) {
            return hoverColor
        }
        const backgroundColor = documentStyles.getPropertyValue(background).trim()
        return chroma.mix(hoverColor, backgroundColor).hex()
    }, [background, documentStyles, themeColor])
    const { row: rowStyleConditions } = useMemo(() => conversionHighLightRecord(highLightRules), [highLightRules])
    // const recordWidth = viewFields.reduce((acc, cur) => {
    //     if(cur.visible){
    //         acc += (cur.width || 160)
    //     }
    //     return acc
    // }, 0)
    const recordCells = useMemo(() => {
        return records.map((record, i) => {
            const { id } = record
            return (
                <ListRecord
                    key={id}
                    actions={actions}
                    record={record}
                    blockId={blockId}
                    index={i}
                    viewFields={viewFields}
                    schema={schema}
                    hoverColor={hoverColor}
                    isMobile={isMobile}
                    isLast={records.length - 1 === i}
                    primaryField={primaryField}
                    rowStyleConditions={rowStyleConditions}
                    checkable={checkable}
                    recordOpenable={recordOpenable}
                    canDeleteRecord={canDeleteRecord}
                    canRecordEdit={canRecordEdit}
                    selectedRecords={selectedRecords}
                    designConfig={designConfig}
                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                    onRecordSelect={onRecordSelect}
                    onRecordClick={onRecordClick}
                    onRecordEdit={onRecordEdit}
                    onRecordDelete={onRecordDelete}
                    onAiGeneration={onAiGeneration}
                    onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                    onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                    onRenderButtonTitle={onRenderButtonTitle}
                />
            )
        })
    }, [
        records,
        actions,
        blockId,
        viewFields,
        schema,
        hoverColor,
        isMobile,
        primaryField,
        rowStyleConditions,
        checkable,
        recordOpenable,
        canDeleteRecord,
        canRecordEdit,
        selectedRecords,
        designConfig,
        aiFieldStatusListAtom,
        onRenderButtonTitle,
        onRecordSelect,
        onRecordClick,
        onRecordEdit,
        onRecordDelete,
        onAiGeneration,
        onRecordOperatorActionTrigger,
        onRecordClickedActionTrigger
    ])

    return <SCxListContent gap={gap}>{recordCells}</SCxListContent>
}
