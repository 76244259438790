import { DIRECTION } from '@lighthouse/core'
import React from 'react'
import styled from 'styled-components'

import { useFlowContainerContext, useFlowLayoutContext } from '../Context'
import { useSortableMonitor } from '../Sortable/Context'

const Container = styled.div`
    flex-shrink: 0;
    transition: opacity 0.2s;
    opacity: 0;
    &[data-highlight='true'] {
        opacity: 1;
        background-color: #5551ff1f;
    }
`

interface BaseGapProps extends React.ComponentPropsWithoutRef<'div'> {}
const BaseGap = ({ style, ...props }: BaseGapProps) => {
    const { isMobile } = useFlowLayoutContext()
    const { direction, mobileAdaptive } = useFlowContainerContext()
    const { activeId } = useSortableMonitor()

    const isDragging = !!activeId
    const isVertical = direction === DIRECTION.vertical || (isMobile && !!mobileAdaptive)

    return (
        <Container
            data-type="gap"
            style={{
                pointerEvents: isDragging ? undefined : 'none',
                width: isVertical ? '100%' : 'var(--container-gap)',
                height: isVertical ? 'var(--container-gap)' : '100%',
                alignSelf: isVertical ? undefined : 'unset',
                ...style
            }}
            {...props}
        />
    )
}

interface GapProps extends BaseGapProps {
    containerId?: string
}

const MISTAKE_SIZE = 10

export const Gap = ({ containerId = 'root', ...props }: GapProps) => {
    const { highlight, isMobile } = useFlowLayoutContext()
    const { direction, gap, mobileAdaptive } = useFlowContainerContext()

    const isVertical = direction === DIRECTION.vertical || (isMobile && !!mobileAdaptive)

    return gap ? (
        <BaseGap data-highlight={!!highlight?.[containerId]?.gap} {...props} />
    ) : (
        <div
            style={{
                position: 'relative',
                width: isVertical ? '100%' : undefined,
                height: isVertical ? undefined : '100%'
            }}
        >
            <BaseGap
                style={{
                    width: isVertical ? '100%' : MISTAKE_SIZE * 2,
                    height: isVertical ? MISTAKE_SIZE * 2 : '100%',
                    position: 'absolute',
                    left: isVertical ? 0 : -MISTAKE_SIZE,
                    top: isVertical ? -MISTAKE_SIZE : 0
                }}
                {...props}
            />
        </div>
    )
}
