import type { Direction, FieldInputType, FieldType } from '@lighthouse/core'
import type { DrawerProps } from '@mantine/core'

import type { Size } from './types'

export const INPUT_FIELDS: FieldInputType[] = ['email', 'notes', 'phoneNumber', 'text', 'number', 'phoneNumber']

export const drawerConfig: Pick<DrawerProps, 'zIndex' | 'styles' | 'position' | 'withinPortal'> = {
    zIndex: 201,
    styles: {
        header: {
            display: 'none'
        },
        drawer: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            overflow: 'hidden',
            height: 'auto'
        },
        body: {
            backgroundColor: 'var(--color-gray-50)'
        }
    },
    position: 'bottom'
    // withinPortal: true
}

export const SELECT_UPLOAD_OPTIONS = [
    {
        label: '拍照',
        capture: 'camera'
    },
    {
        label: '从手机相册选择',
        accept: 'image/*'
    },
    {
        label: '本地文件',
        accept: '*'
    }
]

export const fieldLabelMap = new Map<FieldInputType, string>([
    ['email', '发邮件'],
    ['phoneNumber', '拨打电话'],
    ['url', '跳转链接']
])

export const sizeMap: Record<Size, number> = {
    middle: 14,
    large: 32,
    small: 12
}

export const sizeFontWeighMap: Record<Size, number> = {
    middle: 400,
    large: 600,
    small: 400
}

// export const defaultDateFormat = 'yyyy/MM/dd HH:mm'

export const minPopoverWidth = 248

export const popoverMaxHeight = 560

export const outlineWidth = 2

export const directionMap: Record<Direction, React.CSSProperties['flexDirection']> = { vertical: 'column', horizontal: 'row' }

export const inputHeightMap: Record<Size, number> = {
    middle: 80,
    large: 120,
    small: 40
}
