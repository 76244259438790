import type { FieldInputType, FieldType } from '@lighthouse/core'

export const InputTypeToFieldTypeMap: Record<FieldInputType, FieldType> = {
    text: 'text',
    number: 'number',
    email: 'email',
    url: 'url',
    person: 'person',
    phoneNumber: 'phoneNumber',
    date: 'date',
    relativeSelect: 'text',
    file: 'file',
    notes: 'notes',
    checkbox: 'checkbox',
    slider: 'number',
    cascade: 'text'
}

export const FIELD_BLOCK_ICON_MAP: Record<FieldInputType, string> = {
    number: 'PropertyNumberBlock',
    file: 'PropertyFileBlock',
    text: 'PropertyTextBlock',
    email: 'PropertyEmailBlock',
    url: 'PropertyLinkBlock',
    person: 'PropertyPersonBlock',
    phoneNumber: 'PropertyPhoneBlock',
    date: 'PropertyCalendarBlock',
    relativeSelect: 'PropertySingleSelectBlock',
    notes: 'PropertyNoteBlock',
    checkbox: 'PropertyCheckboxBlock',
    slider: 'SliderBlock',
    cascade: 'PropertyCascade'
}
