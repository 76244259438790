import type { ViewField } from '@lighthouse/core'
import { type DataSourceAbstract, type FilterCommonCondition, type QuickFilterData, VariableType } from '@lighthouse/core'
import { useDebounce } from '@lighthouse/tools'
import isDeepEqual from 'fast-deep-equal'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { getVariableFieldId } from '../../utils'
import QuickFilterActor from './QuickFilterActor'

export interface FilterActorGroupProps {
    mode?: 'normal' | 'tile'
    enable?: boolean
    dataSource: DataSourceAbstract
    columns: ViewField[]
    quickFilter: QuickFilterData
    disablePortal?: boolean
    isMobile?: boolean
    onFilter?: (data: FilterCommonCondition[]) => void
}

export const FilterActor: React.FC<FilterActorGroupProps> = ({
    mode = 'normal',
    enable = true,
    dataSource,
    columns,
    quickFilter,
    disablePortal,
    isMobile,
    onFilter
}) => {
    const { schema } = dataSource
    const [filter, setFilter] = useState(quickFilter?.rules?.filter(item => item.idVariable?.type === VariableType.FIELD_ID) || [])
    const filteredQuickFilterRules = useMemo(
        () =>
            filter?.filter(item => {
                if (!item.idVariable) {
                    return false
                }
                const fieldId = getVariableFieldId(item.idVariable)
                return fieldId && schema[fieldId]
            }),
        [filter, schema]
    )

    useUpdateEffect(() => {
        if (quickFilter.mode === 'disable') {
            return
        }
        if (
            !isDeepEqual(
                quickFilter.rules.map(item => getVariableFieldId(item.idVariable)),
                filter.map(item => getVariableFieldId(item.idVariable))
            )
        ) {
            setFilter(quickFilter?.rules || [])
            onFilter?.(quickFilter?.rules || [])
        }
    }, [quickFilter])

    const debouncedFilter = useDebounce(filter, 500)

    useUpdateEffect(() => {
        if (!isDeepEqual(debouncedFilter, quickFilter?.rules)) {
            onFilter?.(debouncedFilter)
        }
    }, [debouncedFilter])

    const handleFilterItem = useCallback(
        (data: FilterCommonCondition) => {
            const newFilterItems = filteredQuickFilterRules.map(item => {
                if (getVariableFieldId(item?.idVariable) === getVariableFieldId(data?.idVariable)) {
                    return data
                }
                return item
            })
            setFilter?.(newFilterItems)
        },
        [filteredQuickFilterRules]
    )

    if (!enable || !filter || filteredQuickFilterRules.length === 0) {
        return null
    }

    return (
        <>
            {filteredQuickFilterRules.map(filterItem => {
                const { idVariable } = filterItem
                const fieldId = getVariableFieldId(idVariable)
                if (!fieldId) {
                    return null
                }
                const viewField = find(item => item.fieldId === fieldId, columns)
                if (viewField?.type === 'formula' && viewField?.innerType === 'NULL') {
                    return null
                }
                return (
                    viewField && (
                        <QuickFilterActor
                            mode={mode}
                            columns={columns}
                            dataSource={dataSource}
                            disablePortal={disablePortal}
                            key={fieldId}
                            viewField={viewField}
                            isMobile={isMobile}
                            filterItem={filterItem}
                            onFilter={handleFilterItem}
                        />
                    )
                )
            })}
        </>
    )
}
