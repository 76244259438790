import type { PropsWithChildren } from 'react'
import React, { createContext, useContext, useMemo } from 'react'

export interface ThemeContextValue {
    colorPrimary: string
    colorPrimaryHover: string
}

const DEFAULT_THEME_CONFIG: ThemeContextValue = {
    colorPrimary: '#5551ff',
    colorPrimaryHover: '#332eec'
}

const ThemeContext = createContext<ThemeContextValue>(DEFAULT_THEME_CONFIG)

export function useTheme() {
    return useContext(ThemeContext)
}

export interface ByecodeUIThemeProviderProps extends PropsWithChildren {
    colorPrimary?: string
    colorPrimaryHover?: string
}

export function ByecodeUIThemeProvider({
    children,
    colorPrimary = DEFAULT_THEME_CONFIG.colorPrimary,
    colorPrimaryHover = DEFAULT_THEME_CONFIG.colorPrimaryHover
}: ByecodeUIThemeProviderProps) {
    const value = useMemo(
        () => ({
            colorPrimary,
            colorPrimaryHover
        }),
        [colorPrimary, colorPrimaryHover]
    )
    return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}
