import { Button } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

interface ErrorFallbackProps {
    error: Error
    resetErrorBoundary: () => void
}

const Container = styled.div``

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
    return (
        <Container role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <Button type="reset" variant="white" onClick={resetErrorBoundary}>
                Try again
            </Button>
        </Container>
    )
}
