// import { ButtonBlock } from '@lighthouse/block'
import type { ButtonBlockAbstract } from '@lighthouse/core'
import React, { Suspense } from 'react'

import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'

const ButtonBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.ButtonBlock })))

interface ButtonBlockProps {
    blockData: ButtonBlockAbstract
}

const ButtonBlockController: React.FC<ButtonBlockProps> = ({ blockData }) => {
    const {
        config: { group }
    } = blockData

    const { handleActionTrigger } = useActionTrigger({ list: group })

    const { handleRenderTitle } = useRichTextToTitle()

    return (
        <Suspense fallback={<div />}>
            <ButtonBlock onActionTrigger={handleActionTrigger} onRenderTitle={handleRenderTitle} blockData={blockData} />
        </Suspense>
    )
}

export default ButtonBlockController
