import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { CSSProperties } from 'react'

interface TextStylesParams {
    inline?: boolean
    ellipsis?: boolean
    color?: string
    size?: number
    lineClamp?: CSSProperties['lineClamp']
    algin?: string
    weight?: number
    fontFamily?: string
    lineHeight?: string | number
    whiteSpace?: CSSProperties['whiteSpace']
}

export const useStyles = createStyles(
    ({ inline, ellipsis = true, size, color, lineClamp, algin, weight, whiteSpace, fontFamily, lineHeight }: TextStylesParams) => {
        return {
            root: css({
                display: inline ? 'inline-block' : 'block',
                verticalAlign: inline ? '-0.25em' : undefined,
                color,
                fontSize: size,
                lineClamp,
                whiteSpace,
                lineHeight,
                fontFamily,
                fontWeight: weight,
                textAlign: algin,
                ...(ellipsis ? { overflow: 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all' } : {})
            })
        }
    }
)
