import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import ShortUniqueId from 'short-unique-id'

import { Notice } from './components'
import { DEFAULT_SCOPE, registerContainer, unregisterContainer } from './helper'
import * as SC from './styles'
import type { Property, ToastCreator } from './types'

interface NoticeType extends Property {
    key: string
    content: string
}

interface NoticeStateType {
    notices: NoticeType
}

export const ToastContainer: React.FC = () => {
    const scope = DEFAULT_SCOPE
    const createRef = useRef<ToastCreator | null>(null)
    const [state, setState] = useState<NoticeStateType>({
        notices: {} as NoticeType
    })

    const create: ToastCreator = useCallback(
        (content, PropertyType) => {
            const keyId: string = new ShortUniqueId().stamp(11)
            setState({
                notices: {
                    key: keyId,
                    content,
                    ...PropertyType
                }
            })
        },
        [setState]
    )

    const onClose = useCallback(
        (keyId: string) => {
            setState({ notices: {} as NoticeType })
        },
        [setState]
    )

    useEffect(() => {
        createRef.current = create
    })

    useEffect(() => {
        registerContainer(scope, {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            create: createRef.current!
        })

        return () => {
            unregisterContainer(scope)
        }
    }, [scope])

    const renderToast = useMemo(() => {
        const key = state.notices?.key
        const withPortal = state.notices?.withPortal ?? true
        const container = (
            <SC.Container>
                <Notice
                    key={state.notices.key}
                    noticeKey={state.notices.key}
                    content={state.notices.content}
                    iconColor={state.notices.iconColor}
                    icon={state.notices.icon}
                    delayed={state.notices.delayed}
                    linkText={state.notices.linkText}
                    onLinkClick={state.notices.onLinkClick}
                    onClose={onClose}
                />
            </SC.Container>
        )
        return key ? (withPortal ? createPortal(container, document.body) : container) : null
    }, [onClose, state.notices])

    return <>{renderToast}</>
}
