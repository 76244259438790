import { createStyles } from '../../../theme/createStyles'
import { css } from '../../../theme/stitches.config'

export const useStyles = createStyles(() => ({
    column: css({
        flex: 1,
        height: '100%',
        overflow: 'hidden',
        userSelect: 'none',
        cursor: 'grabbing',
        hiddenScroll: true
    }),
    columnItem: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    })
}))
