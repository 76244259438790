import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { ByecodeSize } from '@byecode/ui/types'

interface RadioStylesParams {
    size?: ByecodeSize
    checked?: boolean
    disabled?: boolean
    color?: string
}

export const RadioStyle = createStyles(({ color, disabled }: RadioStylesParams) => {
    return {
        root: css({
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            padding: '8px 0',
            cursor: disabled ? 'not-allowed' : undefined,
            opacity: disabled ? 0.4 : undefined
        }),
        radio: css({
            position: 'relative',

            variants: {
                disabled: {
                    true: {
                        opacity: 0.4,
                        cursor: 'not-allowed'
                    }
                }
            }
        }),

        input: css({
            '--webkit-tap-highlight-color': 'transparent',
            appearance: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all 0.2s ease-in-out',
            border: '2px solid transparent',
            borderRadius: '50%',
            borderColor: color ?? '$colorGray200',
            cursor: 'pointer',

            '&:checked': {
                borderColor: color ?? '$colorPrimary'
            },

            variants: {
                size: {
                    xs: {
                        width: 18,
                        height: 18
                    },
                    sm: {
                        width: 20,
                        height: 20
                    },
                    md: {
                        width: 22,
                        height: 22
                    },
                    lg: {
                        width: 26,
                        height: 26
                    }
                }
            }
        }),

        icon: css({
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            backgroundColor: color ?? '$colorPrimary',
            transition: 'transform 0.2s ease-out',
            variants: {
                size: {
                    xs: {
                        width: 12,
                        height: 12,
                        left: 'calc(50% - 6px)',
                        top: 'calc(50% - 6px)'
                    },
                    sm: {
                        width: 14,
                        height: 14,
                        left: 'calc(50% - 7px)',
                        top: 'calc(50% - 7px)'
                    },
                    md: {
                        width: 16,
                        height: 16,
                        left: 'calc(50% - 8px)',
                        top: 'calc(50% - 8px)'
                    },
                    lg: {
                        width: 18,
                        height: 18,
                        left: 'calc(50% - 9px)',
                        top: 'calc(50% - 9px)'
                    }
                },
                checked: {
                    true: {
                        transition: 'transform 0.2s ease-in',
                        transform: 'scale(1)'
                    },
                    false: {
                        transition: 'transform 0.2s ease-out',
                        transform: 'scale(0)'
                    }
                }
            }
        }),

        label: css({
            flex: 1,
            cursor: 'pointer',
            fontSize: 14,
            lineHeight: '24px'
        }),

        inner: css({
            padding: 6,
            backgroundColor: '$colorGray100',
            '& > *': {
                borderRadius: 6
            }
        })
    }
})
