import { IconFont } from '@byecode/ui/components/IconFont'
import chroma from 'chroma-js'
import styled, { css } from 'styled-components'

export const MenuButton = styled(IconFont)`
    opacity: 0;
    margin-left: auto;
`

export const Line = styled.div.withConfig<{ color: string; hovered: boolean }>({
    shouldForwardProp: p => !['color', 'hovered'].includes(p)
})`
    position: absolute;
    /* display: flex;
    flex-direction: column;
    gap: 8px; */
    cursor: pointer;
    font-size: 12px;
    border-radius: 3px;
    overflow: hidden;
    ${({ color, hovered }) => {
        const isWhite = color ? chroma(color).hex() === chroma('#ffffff').hex() : true
        return css`
            /* color: ${isWhite ? 'var(--color-gray-600)' : color}; */
            background: ${isWhite ? 'var(--color-gray-100)' : color && chroma(color).luminance(0.9).hex()};

            ${hovered &&
            css`
                background: ${isWhite ? 'var(--color-gray-200)' : color && chroma(color).luminance(0.8).hex()};
                ${MenuButton} {
                    opacity: 1;
                }
            `}

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 3px;
                background: ${isWhite ? 'var(--color-gray-500)' : color};
            }
        `
    }}
`

export const LineWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 4px 8px;
`

export const LineResizeHandle = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    cursor: ew-resize;
`

export const LineTitle = styled.span`
    font-weight: 500;
    line-height: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
`

export const LineContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`
