import type { FieldADTValue, FieldProtocol, SimpleTextValue } from '@lighthouse/core'
import cls from 'classnames'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { Textarea } from '../CellInputField'
import type { EditorItemSize } from './types'

interface TextValueEditorProps {
    recordId: string
    data: SimpleTextValue
    field: FieldProtocol
    editable?: boolean
    style?: React.CSSProperties
    size?: EditorItemSize
    focusOutLineColor?: string
    enableBackgroundColor?: boolean
    placeholder?: string
    autoFocus?: boolean
    onCellChange?: (recordId: string, field: FieldADTValue) => Promise<boolean>
}

const SCXContainer = styled.div``

export const TextValueEditor: React.FC<TextValueEditorProps> = ({
    recordId,
    data,
    field,
    editable,
    style,
    size,
    enableBackgroundColor,
    focusOutLineColor,
    placeholder,
    autoFocus,
    onCellChange
}) => {
    const textSize = useMemo(() => (size === 'large' ? '32px' : '14px'), [size])
    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
            onCellChange?.(recordId, { ...field, type: 'text', value: ev.target.value })
        },
        [field, onCellChange, recordId]
    )

    const handleFocus = useCallback((e: React.FocusEvent<HTMLTextAreaElement>) => {
        const len = e.target.value.length

        // 解决safari 15.1 出现自动聚焦后 光标在首位的问题
        setTimeout(() => {
            e.target.setSelectionRange(len, len)
        }, 0);
    }, [])

    return (
        <SCXContainer>
            <Textarea
                readOnly={!editable}
                key={`${recordId}${field.id}`}
                autoFocus={autoFocus}
                autoSize
                textSize={textSize}
                highlightable={editable}
                style={style}
                bordered={false}
                defaultValue={data}
                className={cls({ large: size === 'large' })}
                enableBackgroundColor={enableBackgroundColor}
                placeholder={placeholder}
                focusOutLineColor={focusOutLineColor}
                onFocus={handleFocus}
                onTextareaChange={handleChange}
            />
        </SCXContainer>
    )
}
