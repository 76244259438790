import { getAssetUrl } from '@lighthouse/assets'

export const COUNTRY_LIST = [
    {
        short: 'AF',
        name: '阿富汗',
        en: 'Afghanistan',
        tel: '93',
        flag: getAssetUrl('flag', 'af.png')
    },
    {
        short: 'AL',
        name: '阿尔巴尼亚',
        en: 'Albania',
        tel: '355',
        flag: getAssetUrl('flag', 'al.png')
    },
    {
        short: 'DZ',
        name: '阿尔及利亚',
        en: 'Algeria',
        tel: '213',
        flag: getAssetUrl('flag', 'dz.png')
    },
    {
        short: 'AD',
        name: '安道尔',
        en: 'Andorra',
        tel: '376',
        flag: getAssetUrl('flag', 'ad.png')
    },
    {
        short: 'AO',
        name: '安哥拉',
        en: 'Angola',
        tel: '244',
        flag: getAssetUrl('flag', 'ao.png')
    },
    {
        short: 'AI',
        name: '安圭拉岛',
        en: 'Anguilla',
        tel: '1264',
        flag: getAssetUrl('flag', 'ai.png')
    },
    {
        short: 'AG',
        name: '安提瓜和巴布达',
        en: 'Antigua and Barbuda',
        tel: '1',
        flag: getAssetUrl('flag', 'ag.png')
    },
    {
        short: 'AR',
        name: '阿根廷',
        en: 'Argentina',
        tel: '54',
        flag: getAssetUrl('flag', 'ar.png')
    },
    {
        short: 'AM',
        name: '亚美尼亚',
        en: 'Armenia',
        tel: '374',
        flag: getAssetUrl('flag', 'am.png')
    },
    {
        short: 'AU',
        name: '澳大利亚',
        en: 'Australia',
        tel: '61',
        flag: getAssetUrl('flag', 'au.png')
    },
    {
        short: 'AT',
        name: '奥地利',
        en: 'Austria',
        tel: '43',
        flag: getAssetUrl('flag', 'at.png')
    },
    {
        short: 'AZ',
        name: '阿塞拜疆',
        en: 'Azerbaijan',
        tel: '994',
        flag: getAssetUrl('flag', 'az.png')
    },
    {
        short: 'BS',
        name: '巴哈马',
        en: 'Bahamas',
        tel: '1',
        flag: getAssetUrl('flag', 'bs.png')
    },
    {
        short: 'BH',
        name: '巴林',
        en: 'Bahrain',
        tel: '973',
        flag: getAssetUrl('flag', 'bh.png')
    },
    {
        short: 'BD',
        name: '孟加拉国',
        en: 'Bangladesh',
        tel: '880',
        flag: getAssetUrl('flag', 'bd.png')
    },
    {
        short: 'BB',
        name: '巴巴多斯',
        en: 'Barbados',
        tel: '1',
        flag: getAssetUrl('flag', 'bb.png')
    },
    {
        short: 'BY',
        name: '白俄罗斯',
        en: 'Belarus',
        tel: '375',
        flag: getAssetUrl('flag', 'by.png')
    },
    {
        short: 'BE',
        name: '比利时',
        en: 'Belgium',
        tel: '32',
        flag: getAssetUrl('flag', 'be.png')
    },
    {
        short: 'BZ',
        name: '伯利兹',
        en: 'Belize',
        tel: '501',
        flag: getAssetUrl('flag', 'bz.png')
    },
    {
        short: 'BJ',
        name: '贝宁',
        en: 'Benin',
        tel: '229',
        flag: getAssetUrl('flag', 'bj.png')
    },
    {
        short: 'BT',
        name: '不丹',
        en: 'Bhutan',
        tel: '975',
        flag: getAssetUrl('flag', 'bt.png')
    },
    {
        short: 'BO',
        name: '玻利维亚',
        en: 'Bolivia',
        tel: '591',
        flag: getAssetUrl('flag', 'bo.png')
    },
    {
        short: 'BA',
        name: '波斯尼亚和黑塞哥维那',
        en: 'Bosnia and Herzegovina',
        tel: '387',
        flag: getAssetUrl('flag', 'ba.png')
    },
    {
        short: 'BW',
        name: '博茨瓦纳',
        en: 'Botswana',
        tel: '267',
        flag: getAssetUrl('flag', 'bw.png')
    },
    {
        short: 'BR',
        name: '巴西',
        en: 'Brazil',
        tel: '55',
        flag: getAssetUrl('flag', 'br.png')
    },
    {
        short: 'BN',
        name: '文莱',
        en: 'Brunei',
        tel: '673',
        flag: getAssetUrl('flag', 'bn.png')
    },
    {
        short: 'BG',
        name: '保加利亚',
        en: 'Bulgaria',
        tel: '359',
        flag: getAssetUrl('flag', 'bg.png')
    },
    {
        short: 'BF',
        name: '布基纳法索',
        en: 'Burkina Faso',
        tel: '226',
        flag: getAssetUrl('flag', 'bf.png')
    },
    {
        short: 'BI',
        name: '布隆迪',
        en: 'Burundi',
        tel: '257',
        flag: getAssetUrl('flag', 'bi.png')
    },
    {
        short: 'KH',
        name: '柬埔寨',
        en: 'Cambodia',
        tel: '855',
        flag: getAssetUrl('flag', 'kh.png')
    },
    {
        short: 'CM',
        name: '喀麦隆',
        en: 'Cameroon',
        tel: '237',
        flag: getAssetUrl('flag', 'cm.png')
    },
    {
        short: 'CA',
        name: '加拿大',
        en: 'Canada',
        tel: '1',
        flag: getAssetUrl('flag', 'ca.png')
    },
    {
        short: 'CV',
        name: '佛得角',
        en: 'Cape Verde',
        tel: '238',
        flag: getAssetUrl('flag', 'cv.png')
    },
    {
        short: 'CF',
        name: '中非共和国',
        en: 'Central African Republic',
        tel: '236',
        flag: getAssetUrl('flag', 'cf.png')
    },
    {
        short: 'TD',
        name: '乍得',
        en: 'Chad',
        tel: '235',
        flag: getAssetUrl('flag', 'td.png')
    },
    {
        short: 'CL',
        name: '智利',
        en: 'Chile',
        tel: '56',
        flag: getAssetUrl('flag', 'cl.png')
    },
    {
        short: 'CN',
        name: '中国',
        en: 'China(中国)',
        tel: '86',
        flag: getAssetUrl('flag', 'cn.png')
    },
    {
        short: 'CO',
        name: '哥伦比亚',
        en: 'Colombia',
        tel: '57',
        flag: getAssetUrl('flag', 'co.png')
    },
    {
        short: 'KM',
        name: '科摩罗',
        en: 'Comoros',
        tel: '269',
        flag: getAssetUrl('flag', 'km.png')
    },
    {
        short: 'CG',
        name: '刚果共和国',
        en: 'Congo (Republic of the)',
        tel: '242',
        flag: getAssetUrl('flag', 'cg.png')
    },
    {
        short: 'CD',
        name: '刚果民主共和国',
        en: 'Congo (Democratic Republic of the)',
        tel: '243',
        flag: getAssetUrl('flag', 'cd.png')
    },
    {
        short: 'CR',
        name: '哥斯达黎加',
        en: 'Costa Rica',
        tel: '506',
        flag: getAssetUrl('flag', 'cr.png')
    },
    {
        short: 'HR',
        name: '克罗地亚',
        en: 'Croatia',
        tel: '385',
        flag: getAssetUrl('flag', 'hr.png')
    },
    {
        short: 'CU',
        name: '古巴',
        en: 'Cuba',
        tel: '53',
        flag: getAssetUrl('flag', 'cu.png')
    },
    {
        short: 'CY',
        name: '塞浦路斯',
        en: 'Cyprus',
        tel: '357',
        flag: getAssetUrl('flag', 'cy.png')
    },
    {
        short: 'CZ',
        name: '捷克共和国',
        en: 'Czech Republic',
        tel: '420',
        flag: getAssetUrl('flag', 'cz.png')
    },
    {
        short: 'DK',
        name: '丹麦',
        en: 'Denmark',
        tel: '45',
        flag: getAssetUrl('flag', 'dk.png')
    },
    {
        short: 'DJ',
        name: '吉布提',
        en: 'Djibouti',
        tel: '253',
        flag: getAssetUrl('flag', 'dj.png')
    },
    {
        short: 'DM',
        name: '多米尼克',
        en: 'Dominica',
        tel: '1',
        flag: getAssetUrl('flag', 'dm.png')
    },
    {
        short: 'DO',
        name: '多米尼加共和国',
        en: 'Dominican Republic',
        tel: '1',
        flag: getAssetUrl('flag', 'do.png')
    },
    {
        short: 'EC',
        name: '厄瓜多尔',
        en: 'Ecuador',
        tel: '593',
        flag: getAssetUrl('flag', 'ec.png')
    },
    {
        short: 'EG',
        name: '埃及',
        en: 'Egypt',
        tel: '20',
        flag: getAssetUrl('flag', 'eg.png')
    },
    {
        short: 'SV',
        name: '萨尔瓦多',
        en: 'El Salvador',
        tel: '503',
        flag: getAssetUrl('flag', 'sv.png')
    },
    {
        short: 'GQ',
        name: '赤道几内亚',
        en: 'Equatorial Guinea',
        tel: '240',
        flag: getAssetUrl('flag', 'gq.png')
    },
    {
        short: 'ER',
        name: '厄立特里亚',
        en: 'Eritrea',
        tel: '291',
        flag: getAssetUrl('flag', 'er.png')
    },
    {
        short: 'EE',
        name: '爱沙尼亚',
        en: 'Estonia',
        tel: '372',
        flag: getAssetUrl('flag', 'ee.png')
    },
    {
        short: 'ET',
        name: '埃塞俄比亚',
        en: 'Ethiopia',
        tel: '251',
        flag: getAssetUrl('flag', 'et.png')
    },
    {
        short: 'FJ',
        name: '斐济',
        en: 'Fiji',
        tel: '679',
        flag: getAssetUrl('flag', 'fj.png')
    },
    {
        short: 'FI',
        name: '芬兰',
        en: 'Finland',
        tel: '358',
        flag: getAssetUrl('flag', 'fi.png')
    },
    {
        short: 'FR',
        name: '法国',
        en: 'France',
        tel: '33',
        flag: getAssetUrl('flag', 'fr.png')
    },
    {
        short: 'GA',
        name: '加蓬',
        en: 'Gabon',
        tel: '241',
        flag: getAssetUrl('flag', 'ga.png')
    },
    {
        short: 'GM',
        name: '冈比亚',
        en: 'Gambia',
        tel: '220',
        flag: getAssetUrl('flag', 'gm.png')
    },
    {
        short: 'GE',
        name: '格鲁吉亚',
        en: 'Georgia',
        tel: '995',
        flag: getAssetUrl('flag', 'ge.png')
    },
    {
        short: 'DE',
        name: '德国',
        en: 'Germany',
        tel: '49',
        flag: getAssetUrl('flag', 'de.png')
    },
    {
        short: 'GH',
        name: '加纳',
        en: 'Ghana',
        tel: '233',
        flag: getAssetUrl('flag', 'gh.png')
    },
    {
        short: 'GR',
        name: '希腊',
        en: 'Greece',
        tel: '30',
        flag: getAssetUrl('flag', 'gr.png')
    },
    {
        short: 'GD',
        name: '格林纳达',
        en: 'Grenada',
        tel: '1',
        flag: getAssetUrl('flag', 'gd.png')
    },
    {
        short: 'GT',
        name: '危地马拉',
        en: 'Guatemala',
        tel: '502',
        flag: getAssetUrl('flag', 'gt.png')
    },
    {
        short: 'GN',
        name: '几内亚',
        en: 'Guinea',
        tel: '224',
        flag: getAssetUrl('flag', 'gn.png')
    },
    {
        short: 'GW',
        name: '几内亚比绍',
        en: 'Guinea-Bissau',
        tel: '245',
        flag: getAssetUrl('flag', 'gw.png')
    },
    {
        short: 'GY',
        name: '圭亚那',
        en: 'Guyana',
        tel: '592',
        flag: getAssetUrl('flag', 'gy.png')
    },
    {
        short: 'HT',
        name: '海地',
        en: 'Haiti',
        tel: '509',
        flag: getAssetUrl('flag', 'ht.png')
    },
    {
        short: 'HN',
        name: '洪都拉斯',
        en: 'Honduras',
        tel: '504',
        flag: getAssetUrl('flag', 'hn.png')
    },
    {
        short: 'HK',
        name: '香港（中国）',
        en: 'Hong Kong（中国）',
        tel: '852',
        flag: getAssetUrl('flag', 'hk.png')
    },
    {
        short: 'HU',
        name: '匈牙利',
        en: 'Hungary',
        tel: '36',
        flag: getAssetUrl('flag', 'hu.png')
    },
    {
        short: 'IS',
        name: '冰岛',
        en: 'Iceland',
        tel: '354',
        flag: getAssetUrl('flag', 'is.png')
    },
    {
        short: 'IN',
        name: '印度',
        en: 'India',
        tel: '91',
        flag: getAssetUrl('flag', 'in.png')
    },
    {
        short: 'ID',
        name: '印度尼西亚',
        en: 'Indonesia',
        tel: '62',
        flag: getAssetUrl('flag', 'id.png')
    },
    {
        short: 'IR',
        name: '伊朗',
        en: 'Iran',
        tel: '98',
        flag: getAssetUrl('flag', 'ir.png')
    },
    {
        short: 'IQ',
        name: '伊拉克',
        en: 'Iraq',
        tel: '964',
        flag: getAssetUrl('flag', 'iq.png')
    },
    {
        short: 'IE',
        name: '爱尔兰',
        en: 'Ireland',
        tel: '353',
        flag: getAssetUrl('flag', 'ie.png')
    },
    {
        short: 'IL',
        name: '以色列',
        en: 'Israel',
        tel: '972',
        flag: getAssetUrl('flag', 'il.png')
    },
    {
        short: 'IT',
        name: '意大利',
        en: 'Italy',
        tel: '39',
        flag: getAssetUrl('flag', 'it.png')
    },
    {
        short: 'JM',
        name: '牙买加',
        en: 'Jamaica',
        tel: '1',
        flag: getAssetUrl('flag', 'jm.png')
    },
    {
        short: 'JP',
        name: '日本',
        en: 'Japan',
        tel: '81',
        flag: getAssetUrl('flag', 'jp.png')
    },
    {
        short: 'JO',
        name: '约旦',
        en: 'Jordan',
        tel: '962',
        flag: getAssetUrl('flag', 'jo.png')
    },
    {
        short: 'KZ',
        name: '哈萨克斯坦',
        en: 'Kazakhstan',
        tel: '7',
        flag: getAssetUrl('flag', 'kz.png')
    },
    {
        short: 'KE',
        name: '肯尼亚',
        en: 'Kenya',
        tel: '254',
        flag: getAssetUrl('flag', 'ke.png')
    },
    {
        short: 'KI',
        name: '基里巴斯',
        en: 'Kiribati',
        tel: '686',
        flag: getAssetUrl('flag', 'ki.png')
    },
    {
        short: 'KW',
        name: '科威特',
        en: 'Kuwait',
        tel: '965',
        flag: getAssetUrl('flag', 'kw.png')
    },
    {
        short: 'KG',
        name: '吉尔吉斯斯坦',
        en: 'Kyrgyzstan',
        tel: '996',
        flag: getAssetUrl('flag', 'kg.png')
    },
    {
        short: 'LA',
        name: '老挝',
        en: 'Laos',
        tel: '856',
        flag: getAssetUrl('flag', 'la.png')
    },
    {
        short: 'LV',
        name: '拉脱维亚',
        en: 'Latvia',
        tel: '371',
        flag: getAssetUrl('flag', 'lv.png')
    },
    {
        short: 'LB',
        name: '黎巴嫩',
        en: 'Lebanon',
        tel: '961',
        flag: getAssetUrl('flag', 'lb.png')
    },
    {
        short: 'LS',
        name: '莱索托',
        en: 'Lesotho',
        tel: '266',
        flag: getAssetUrl('flag', 'ls.png')
    },
    {
        short: 'LR',
        name: '利比里亚',
        en: 'Liberia',
        tel: '231',
        flag: getAssetUrl('flag', 'lr.png')
    },
    {
        short: 'LY',
        name: '利比亚',
        en: 'Libya',
        tel: '218',
        flag: getAssetUrl('flag', 'ly.png')
    },
    {
        short: 'LI',
        name: '列支敦士登',
        en: 'Liechtenstein',
        tel: '423',
        flag: getAssetUrl('flag', 'li.png')
    },
    {
        short: 'LT',
        name: '立陶宛',
        en: 'Lithuania',
        tel: '370',
        flag: getAssetUrl('flag', 'lt.png')
    },
    {
        short: 'LU',
        name: '卢森堡',
        en: 'Luxembourg',
        tel: '352',
        flag: getAssetUrl('flag', 'lu.png')
    },
    {
        short: 'MG',
        name: '马达加斯加',
        en: 'Madagascar',
        tel: '261',
        flag: getAssetUrl('flag', 'mg.png')
    },
    {
        short: 'MO',
        name: '澳门（中国）',
        en: 'Macao（中国）',
        tel: '853',
        flag: getAssetUrl('flag', 'mo.png')
    },
    {
        short: 'MW',
        name: '马拉维',
        en: 'Malawi',
        tel: '265',
        flag: getAssetUrl('flag', 'mw.png')
    },
    {
        short: 'MY',
        name: '马来西亚',
        en: 'Malaysia',
        tel: '60',
        flag: getAssetUrl('flag', 'my.png')
    },
    {
        short: 'MV',
        name: '马尔代夫',
        en: 'Maldives',
        tel: '960',
        flag: getAssetUrl('flag', 'mv.png')
    },
    {
        short: 'ML',
        name: '马里',
        en: 'Mali',
        tel: '223',
        flag: getAssetUrl('flag', 'ml.png')
    },
    {
        short: 'MT',
        name: '马耳他',
        en: 'Malta',
        tel: '356',
        flag: getAssetUrl('flag', 'mt.png')
    },
    {
        short: 'MH',
        name: '马绍尔群岛',
        en: 'Marshall Islands',
        tel: '692',
        flag: getAssetUrl('flag', 'mh.png')
    },
    {
        short: 'MR',
        name: '毛里塔尼亚',
        en: 'Mauritania',
        tel: '222',
        flag: getAssetUrl('flag', 'mr.png')
    },
    {
        short: 'MU',
        name: '毛里求斯',
        en: 'Mauritius',
        tel: '230',
        flag: getAssetUrl('flag', 'mu.png')
    },
    {
        short: 'MX',
        name: '墨西哥',
        en: 'Mexico',
        tel: '52',
        flag: getAssetUrl('flag', 'mx.png')
    },
    {
        short: 'FM',
        name: '密克罗尼西亚联邦',
        en: 'Micronesia (Federated States of)',
        tel: '691',
        flag: getAssetUrl('flag', 'fm.png')
    },
    {
        short: 'MC',
        name: '摩纳哥',
        en: 'Monaco',
        tel: '377',
        flag: getAssetUrl('flag', 'mc.png')
    },
    {
        short: 'MN',
        name: '蒙古',
        en: 'Mongolia',
        tel: '976',
        flag: getAssetUrl('flag', 'mn.png')
    },
    {
        short: 'ME',
        name: '黑山',
        en: 'Montenegro',
        tel: '382',
        flag: getAssetUrl('flag', 'me.png')
    },
    {
        short: 'MA',
        name: '摩洛哥',
        en: 'Morocco',
        tel: '212',
        flag: getAssetUrl('flag', 'ma.png')
    },
    {
        short: 'MZ',
        name: '莫桑比克',
        en: 'Mozambique',
        tel: '258',
        flag: getAssetUrl('flag', 'mz.png')
    },
    {
        short: 'MM',
        name: '缅甸',
        en: 'Myanmar',
        tel: '95',
        flag: getAssetUrl('flag', 'mm.png')
    },
    {
        short: 'NA',
        name: '纳米比亚',
        en: 'Namibia',
        tel: '264',
        flag: getAssetUrl('flag', 'na.png')
    },
    {
        short: 'NR',
        name: '瑙鲁',
        en: 'Nauru',
        tel: '674',
        flag: getAssetUrl('flag', 'nr.png')
    },
    {
        short: 'NP',
        name: '尼泊尔',
        en: 'Nepal',
        tel: '977',
        flag: getAssetUrl('flag', 'np.png')
    },
    {
        short: 'NL',
        name: '荷兰',
        en: 'Netherlands',
        tel: '31',
        flag: getAssetUrl('flag', 'nl.png')
    },
    {
        short: 'NZ',
        name: '新西兰',
        en: 'New Zealand',
        tel: '64',
        flag: getAssetUrl('flag', 'nz.png')
    },
    {
        short: 'NI',
        name: '尼加拉瓜',
        en: 'Nicaragua',
        tel: '505',
        flag: getAssetUrl('flag', 'ni.png')
    },
    {
        short: 'NE',
        name: '尼日尔',
        en: 'Niger',
        tel: '227',
        flag: getAssetUrl('flag', 'ne.png')
    },
    {
        short: 'NG',
        name: '尼日利亚',
        en: 'Nigeria',
        tel: '234',
        flag: getAssetUrl('flag', 'ng.png')
    },
    {
        short: 'NO',
        name: '挪威',
        en: 'Norway',
        tel: '47',
        flag: getAssetUrl('flag', 'no.png')
    },
    {
        short: 'OM',
        name: '阿曼',
        en: 'Oman',
        tel: '968',
        flag: getAssetUrl('flag', 'om.png')
    },
    {
        short: 'PK',
        name: '巴基斯坦',
        en: 'Pakistan',
        tel: '92',
        flag: getAssetUrl('flag', 'pk.png')
    },
    {
        short: 'PW',
        name: '帕劳',
        en: 'Palau',
        tel: '680',
        flag: getAssetUrl('flag', 'pw.png')
    },
    {
        short: 'PA',
        name: '巴拿马',
        en: 'Panama',
        tel: '507',
        flag: getAssetUrl('flag', 'pa.png')
    },
    {
        short: 'PG',
        name: '巴布亚新几内亚',
        en: 'Papua New Guinea',
        tel: '675',
        flag: getAssetUrl('flag', 'pg.png')
    },
    {
        short: 'PY',
        name: '巴拉圭',
        en: 'Paraguay',
        tel: '595',
        flag: getAssetUrl('flag', 'py.png')
    },
    {
        short: 'PE',
        name: '秘鲁',
        en: 'Peru',
        tel: '51',
        flag: getAssetUrl('flag', 'pe.png')
    },
    {
        short: 'PH',
        name: '菲律宾',
        en: 'Philippines',
        tel: '63',
        flag: getAssetUrl('flag', 'ph.png')
    },
    {
        short: 'PL',
        name: '波兰',
        en: 'Poland',
        tel: '48',
        flag: getAssetUrl('flag', 'pl.png')
    },
    {
        short: 'PT',
        name: '葡萄牙',
        en: 'Portugal',
        tel: '351',
        flag: getAssetUrl('flag', 'pt.png')
    },
    {
        short: 'QA',
        name: '卡塔尔',
        en: 'Qatar',
        tel: '974',
        flag: getAssetUrl('flag', 'qa.png')
    },
    {
        short: 'KR',
        name: '韩国',
        en: 'Republic of Korea',
        tel: '82',
        flag: getAssetUrl('flag', 'kr.png')
    },
    {
        short: 'MD',
        name: '摩尔多瓦',
        en: 'Moldova',
        tel: '373',
        flag: getAssetUrl('flag', 'md.png')
    },
    {
        short: 'RO',
        name: '罗马尼亚',
        en: 'Romania',
        tel: '40',
        flag: getAssetUrl('flag', 'ro.png')
    },
    {
        short: 'RU',
        name: '俄罗斯',
        en: 'Russian Federation',
        tel: '7',
        flag: getAssetUrl('flag', 'ru.png')
    },
    {
        short: 'RW',
        name: '卢旺达',
        en: 'Rwanda',
        tel: '250',
        flag: getAssetUrl('flag', 'rw.png')
    },
    {
        short: 'KN',
        name: '圣基茨和尼维斯',
        en: 'Saint Kitts and Nevis',
        tel: '1',
        flag: getAssetUrl('flag', 'kn.png')
    },
    {
        short: 'LC',
        name: '圣卢西亚',
        en: 'Saint Lucia',
        tel: '1',
        flag: getAssetUrl('flag', 'lc.png')
    },
    {
        short: 'VC',
        name: '圣文森特和格林纳丁斯',
        en: 'Saint Vincent and the Grenadines',
        tel: '1',
        flag: getAssetUrl('flag', 'vc.png')
    },
    {
        short: 'WS',
        name: '萨摩亚',
        en: 'Samoa',
        tel: '685',
        flag: getAssetUrl('flag', 'ws.png')
    },
    {
        short: 'SM',
        name: '圣马力诺',
        en: 'San Marino',
        tel: '378',
        flag: getAssetUrl('flag', 'sm.png')
    },
    {
        short: 'ST',
        name: '圣多美和普林西比',
        en: 'Sao Tome and Principe',
        tel: '239',
        flag: getAssetUrl('flag', 'st.png')
    },
    {
        short: 'SA',
        name: '沙特阿拉伯',
        en: 'Saudi Arabia',
        tel: '966',
        flag: getAssetUrl('flag', 'sa.png')
    },
    {
        short: 'SN',
        name: '塞内加尔',
        en: 'Senegal',
        tel: '221',
        flag: getAssetUrl('flag', 'sn.png')
    },
    {
        short: 'RS',
        name: '塞尔维亚',
        en: 'Serbia',
        tel: '381',
        flag: getAssetUrl('flag', 'rs.png')
    },
    {
        short: 'SC',
        name: '塞舌尔',
        en: 'Seychelles',
        tel: '248',
        flag: getAssetUrl('flag', 'sc.png')
    },
    {
        short: 'SL',
        name: '塞拉利昂',
        en: 'Sierra Leone',
        tel: '232',
        flag: getAssetUrl('flag', 'sl.png')
    },
    {
        short: 'SG',
        name: '新加坡',
        en: 'Singapore',
        tel: '65',
        flag: getAssetUrl('flag', 'sg.png')
    },
    {
        short: 'SK',
        name: '斯洛伐克',
        en: 'Slovakia',
        tel: '421',
        flag: getAssetUrl('flag', 'sk.png')
    },
    {
        short: 'SI',
        name: '斯洛文尼亚',
        en: 'Slovenia',
        tel: '386',
        flag: getAssetUrl('flag', 'si.png')
    },
    {
        short: 'SB',
        name: '所罗门群岛',
        en: 'Solomon Islands',
        tel: '677',
        flag: getAssetUrl('flag', 'sb.png')
    },
    {
        short: 'SO',
        name: '索马里',
        en: 'Somalia',
        tel: '252',
        flag: getAssetUrl('flag', 'so.png')
    },
    {
        short: 'ZA',
        name: '南非',
        en: 'South Africa',
        tel: '27',
        flag: getAssetUrl('flag', 'za.png')
    },
    {
        short: 'SS',
        name: '南苏丹',
        en: 'South Sudan',
        tel: '211',
        flag: getAssetUrl('flag', 'ss.png')
    },
    {
        short: 'ES',
        name: '西班牙',
        en: 'Spain',
        tel: '34',
        flag: getAssetUrl('flag', 'es.png')
    },
    {
        short: 'LK',
        name: '斯里兰卡',
        en: 'Sri Lanka',
        tel: '94',
        flag: getAssetUrl('flag', 'lk.png')
    },
    {
        short: 'PS',
        name: '巴勒斯坦',
        en: 'State of Palestine',
        tel: '970',
        flag: getAssetUrl('flag', 'ps.png')
    },
    {
        short: 'SD',
        name: '苏丹',
        en: 'Sudan',
        tel: '249',
        flag: getAssetUrl('flag', 'sd.png')
    },
    {
        short: 'SR',
        name: '苏里南',
        en: 'Suriname',
        tel: '597',
        flag: getAssetUrl('flag', 'sr.png')
    },
    {
        short: 'SE',
        name: '瑞典',
        en: 'Sweden',
        tel: '46',
        flag: getAssetUrl('flag', 'se.png')
    },
    {
        short: 'CH',
        name: '瑞士',
        en: 'Switzerland',
        tel: '41',
        flag: getAssetUrl('flag', 'ch.png')
    },
    {
        short: 'SY',
        name: '叙利亚',
        en: 'Syria',
        tel: '963',
        flag: getAssetUrl('flag', 'sy.png')
    },
    {
        short: 'TJ',
        name: '塔吉克斯坦',
        en: 'Tajikistan',
        tel: '992',
        flag: getAssetUrl('flag', 'tj.png')
    },
    {
        short: 'TW',
        name: '台湾（中国）',
        en: 'Taiwan（中国）',
        tel: '886',
        flag: getAssetUrl('flag', 'tw.png')
    },
    {
        short: 'TZ',
        name: '坦桑尼亚',
        en: 'Tanzania',
        tel: '255',
        flag: getAssetUrl('flag', 'tz.png')
    },
    {
        short: 'TH',
        name: '泰国',
        en: 'Thailand',
        tel: '66',
        flag: getAssetUrl('flag', 'th.png')
    },
    {
        short: 'TL',
        name: '东帝汶',
        en: 'Timor-Leste',
        tel: '670',
        flag: getAssetUrl('flag', 'tl.png')
    },
    {
        short: 'TG',
        name: '多哥',
        en: 'Togo',
        tel: '228',
        flag: getAssetUrl('flag', 'tg.png')
    },
    {
        short: 'TO',
        name: '汤加',
        en: 'Tonga',
        tel: '676',
        flag: getAssetUrl('flag', 'to.png')
    },
    {
        short: 'TT',
        name: '特立尼达和多巴哥',
        en: 'Trinidad and Tobago',
        tel: '1',
        flag: getAssetUrl('flag', 'tt.png')
    },
    {
        short: 'TN',
        name: '突尼斯',
        en: 'Tunisia',
        tel: '216',
        flag: getAssetUrl('flag', 'tn.png')
    },
    {
        short: 'TR',
        name: '土耳其',
        en: 'Turkey',
        tel: '90',
        flag: getAssetUrl('flag', 'tr.png')
    },
    {
        short: 'TM',
        name: '土库曼斯坦',
        en: 'Turkmenistan',
        tel: '993',
        flag: getAssetUrl('flag', 'tm.png')
    },
    {
        short: 'TV',
        name: '图瓦卢',
        en: 'Tuvalu',
        tel: '688',
        flag: getAssetUrl('flag', 'tv.png')
    },
    {
        short: 'UG',
        name: '乌干达',
        en: 'Uganda',
        tel: '256',
        flag: getAssetUrl('flag', 'ug.png')
    },
    {
        short: 'UA',
        name: '乌克兰',
        en: 'Ukraine',
        tel: '380',
        flag: getAssetUrl('flag', 'ua.png')
    },
    {
        short: 'AE',
        name: '阿拉伯联合酋长国',
        en: 'United Arab Emirates',
        tel: '971',
        flag: getAssetUrl('flag', 'ae.png')
    },
    {
        short: 'GB',
        name: '英国',
        en: 'United Kingdom',
        tel: '44',
        flag: getAssetUrl('flag', 'gb.png')
    },
    {
        short: 'US',
        name: '美国',
        en: 'United States of America',
        tel: '1',
        flag: getAssetUrl('flag', 'us.png')
    },
    {
        short: 'UY',
        name: '乌拉圭',
        en: 'Uruguay',
        tel: '598',
        flag: getAssetUrl('flag', 'uy.png')
    },
    {
        short: 'UZ',
        name: '乌兹别克斯坦',
        en: 'Uzbekistan',
        tel: '998',
        flag: getAssetUrl('flag', 'uz.png')
    },
    {
        short: 'VU',
        name: '瓦努阿图',
        en: 'Vanuatu',
        tel: '678',
        flag: getAssetUrl('flag', 'vu.png')
    },
    {
        short: 'VE',
        name: '委内瑞拉',
        en: 'Venezuela',
        tel: '58',
        flag: getAssetUrl('flag', 've.png')
    },
    {
        short: 'VN',
        name: '越南',
        en: 'Vietnam',
        tel: '84',
        flag: getAssetUrl('flag', 'vn.png')
    },
    {
        short: 'YE',
        name: '也门',
        en: 'Yemen',
        tel: '967',
        flag: getAssetUrl('flag', 'ye.png')
    },
    {
        short: 'ZM',
        name: '赞比亚',
        en: 'Zambia',
        tel: '260',
        flag: getAssetUrl('flag', 'zm.png')
    },
    {
        short: 'ZW',
        name: '津巴布韦',
        en: 'Zimbabwe',
        tel: '263',
        flag: getAssetUrl('flag', 'zw.png')
    }
]
