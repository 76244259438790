import { flex, pointer, singleTextEllipsis } from '@byecode/ui'
import type { NavigationBaseItem, NavigationLogo } from '@lighthouse/core'
import { NavigationShowMode } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useApplicationContext } from '../../../contexts'
import { getImageFullUrlInApplication } from '../../../utils'
import { TooltipText } from '../../TooltipText'
import { useAppNavbarStylesContext } from '../AppNavbarStylesContext'
import { modeShowConfig, navbarModeMap } from '../constant'
import { getNavbarLogo } from '../help'
import { Icon } from '../styles'
import type { ApplicationSettingThemeNavBarModeColor } from '../type'

interface ApplicationLogoProps {
    logo?: NavigationLogo
    appName?: string
    appIcon?: string
    showMode?: NavigationShowMode
    isUsedPx?: boolean
    onToLink?: (navigation?: NavigationBaseItem) => void
}

export const SCxAppIcon = styled.div<{ bgColor?: string; isUsedPx: boolean }>`
    width: ${({ isUsedPx }) => (isUsedPx ? '36px' : '2.25rem')};
    height: ${({ isUsedPx }) => (isUsedPx ? '36px' : '2.25rem')};
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${({ bgColor }) => bgColor};

    ${Icon} {
        font-size: ${({ isUsedPx }) => (isUsedPx ? 'var(--h3-font-size)' : '1.25rem')};
        color: var(--color-theme-1);
    }
`

export const Logo = styled.img<{ isSquare: boolean; isUsedPx?: boolean }>`
    ${({ isSquare }) =>
        isSquare && {
            width: '48px'
        }}

    height: ${({ isUsedPx }) => (isUsedPx ? '48px' : '2.25rem')};
    ${pointer}
`

export const ApplicationName = styled.div<{ color?: string; isUsedPx: boolean }>`
    font-style: normal;
    font-weight: 700;
    padding: 5px 0px;
    box-sizing: border-box;
    font-size: ${({ isUsedPx }) => (isUsedPx ? 'var(--h3-font-size)' : '1.25rem')};
    color: ${({ color }) => color ?? 'var(--color-black)'};
    max-width: 208px;
    ${singleTextEllipsis}
`

export const LogoContain = styled.div`
    ${flex}
    align-items: center;
    ${ApplicationName} {
        padding-left: 6px;
    }
`

const ApplicationLogo = React.forwardRef<HTMLDivElement, ApplicationLogoProps>(
    ({ logo, showMode = NavigationShowMode.horizontal, appIcon, appName, isUsedPx = true, onToLink }, ref) => {
        const themeStyle = useAppNavbarStylesContext()
        const { appId } = useApplicationContext()
        const showConfig = useMemo(() => {
            const mode = navbarModeMap.get(showMode) ?? 'horizontal'
            return modeShowConfig[mode]
        }, [showMode])

        if (!logo) {
            return null
        }

        return (
            <LogoContain ref={ref}>
                {logo?.logoType === 1 ? (
                    <Logo
                        isSquare={showMode === NavigationShowMode.verticalNarrow}
                        src={getImageFullUrlInApplication(appId, getNavbarLogo(showMode, logo.imgUrl))}
                        onClick={() => onToLink?.({ ...logo, id: '' })}
                        isUsedPx={isUsedPx}
                    />
                ) : (
                    <>
                        <SCxAppIcon isUsedPx={isUsedPx} onClick={() => onToLink?.({ ...logo, id: '' })} bgColor={themeStyle?.logoBgcolor}>
                            <Icon type={appIcon || 'Cube'} color={themeStyle?.logoColor} />
                        </SCxAppIcon>
                        {showConfig.logoName && (
                            <TooltipText
                                title={appName ?? '无标题'}
                                render={ref => {
                                    return (
                                        <ApplicationName ref={ref} color={themeStyle?.color} isUsedPx={isUsedPx}>
                                            {appName ?? '无标题'}
                                        </ApplicationName>
                                    )
                                }}
                            />
                        )}
                    </>
                )}
            </LogoContain>
        )
    }
)

export default ApplicationLogo
