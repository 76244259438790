import { Enum } from '@lighthouse/tools'

import type { Arrangement, BlockProtocol, BlockSize, BlockVisibilityProtocol, ButtonAction, RichTextContentProtocol } from '../../protocols'
import type { IconDecorationProtocol } from '../../protocols/icon'
import { BlockType } from '../Basic'

export interface CardBlockItemConfig {
    id: string
    iconDecoration?: IconDecorationProtocol
    iconColor: string
    heading?: RichTextContentProtocol
    introduction?: RichTextContentProtocol
    action: ButtonAction
}

export const CARD_STYLE = Enum('default', 'block', 'card')
export type CardStyle = Enum<typeof CARD_STYLE>

export abstract class CardBlockAbstract implements BlockProtocol {
    type = BlockType.card

    abstract title: string

    abstract id: string

    abstract config: BlockVisibilityProtocol & {
        layout?: Arrangement
        cols?: number
        style?: CardStyle
        size?: BlockSize
        direction: 'vertical' | 'horizontal'
        list: CardBlockItemConfig[]
    }
}
