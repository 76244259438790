import { singleTextEllipsis } from '@byecode/ui'
import type { PositionConfig } from '@lighthouse/core'
import { GalleryCardStyle } from '@lighthouse/core'
import type { BoxProps, CardProps, TextProps } from '@mantine/core'
import { Box, Card, Divider, Text } from '@mantine/core'
import type { PolymorphicComponentProps } from '@mantine/utils'
import { createPolymorphicComponent } from '@mantine/utils'
import styled, { css } from 'styled-components'

export const GalleryOperatorWrapper = styled.div<{ checked?: boolean }>`
    position: absolute;
    z-index: 99;
    right: 8px;
    top: 8px;
    padding: 4px;
    opacity: ${p => (p.checked ? 1 : 0)};
`

type GalleryItemCardCustomProps = {
    enableHoverStyle?: boolean
    recordStyle?: GalleryCardStyle
}
type GalleryItemCardProps<C> = PolymorphicComponentProps<C, CardProps & GalleryItemCardCustomProps>

const boxShadowStyle = css`
    box-shadow: 0px 4px 12px 0px rgba(16, 24, 40, 0.1);
`
const outlineStyle = css`
    /* ios safari 12.0之前的不支持outline带圆角 */
    /* outline: 6px solid rgba(23, 69, 232, 0.06); */

    /* 兼容方案 */
    &::after {
        content: '';
        position: absolute;
        top: -6px;
        left: -6px;
        right: -6px;
        bottom: -6px;
        border: 6px solid rgba(23, 69, 232, 0.06);
        border-radius: 22px;
        /* z-index: -1; */
    }
`

export const GalleryItemCard = styled(Card).withConfig<GalleryItemCardProps<'div'>>({
    shouldForwardProp: p => !['enableHoverStyle', 'recordStyle'].includes(p)
})`
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: ${p => p.enableHoverStyle && 'pointer'};
    /* height: 100%; */

    /* overflow hidden在移动端时和grid布局有冲突，导致子项高度坍塌 */
    overflow: unset;

    padding: ${({ recordStyle }) => (!recordStyle || recordStyle === GalleryCardStyle.none ? 0 : '8px')};

    background-color: ${({ recordStyle }) =>
        recordStyle === GalleryCardStyle.block
            ? 'var(--color-gray-100)'
            : recordStyle === GalleryCardStyle.border
            ? 'var(--color-white)'
            : 'transparent'};

    ${({ enableHoverStyle, recordStyle }) => [
        enableHoverStyle &&
            recordStyle === GalleryCardStyle.border &&
            css`
                &:hover {
                    ${boxShadowStyle}/* ${recordStyle === GalleryCardStyle.border ? boxShadowStyle : outlineStyle} */
                }
            `
    ]}

    &:hover ${GalleryOperatorWrapper} {
        opacity: 1;
    }
`

type GalleryBodyProps = {
    avatarPosition?: PositionConfig['position']
}
export const GalleryBody = createPolymorphicComponent<'div', BoxProps & GalleryBodyProps>(styled(Box).withConfig({
    shouldForwardProp: prop => prop !== 'avatarPosition'
})<GalleryBodyProps>`
    display: flex;
    flex-direction: ${props =>
        !props.avatarPosition || props.avatarPosition === 'top' ? 'column' : props.avatarPosition === 'right' ? 'row-reverse' : 'row'};
    overflow: hidden;
    gap: 16px;
    font-size: 14px;
`)

export const GalleryHeader = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 8px;
    font-size: 14px;
    * {
        margin-bottom: 0;
    }
`

type CanEllipsisTextProps = {
    ellipsis?: boolean
}
export const CanEllipsisText = createPolymorphicComponent<'div', TextProps & CanEllipsisTextProps>(styled(Text).withConfig({
    shouldForwardProp: prop => prop !== 'ellipsis'
})<CanEllipsisTextProps>`
    word-break: break-word;
    ${props => props.ellipsis && singleTextEllipsis()}
`)

export const GalleryHeadTag = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    overflow: hidden;
    height: 100%;
`

export const WithTextDivider = styled(Divider)`
    transform: scaleY(0.5);
`

export const Label = styled.div`
    color: var(--color-gray-500);
    flex: none;
    ${singleTextEllipsis()};
`

export const GalleryActionWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 8px;
    padding: 8px;
    z-index: 1;
`
