import { useTheme } from '@byecode/ui/providers'
import { createTheme } from '@byecode/ui/theme/stitches.config'
import clsx from 'clsx'
import { mergeDeepRight } from 'rambda'
import React, { forwardRef } from 'react'

import type { BaseModalProps } from '../base'
import { BaseModal } from '../base'

export const MobileModal = forwardRef<HTMLDivElement, BaseModalProps>((props, ref) => {
    const { styles, width, className, ...rest } = props
    const { colorPrimary, colorPrimaryHover } = useTheme()
    const customBuiTheme = createTheme({ colors: { colorPrimary, colorPrimaryHover } })
    return (
        <BaseModal
            ref={ref}
            width="100%"
            className={clsx(className, customBuiTheme.toString())}
            styles={mergeDeepRight(
                {
                    root: {
                        alignItems: 'flex-end !important'
                    },
                    modal: {
                        marginTop: 'auto',
                        height: '80% !important',
                        borderRadius: '16px 16px 0 0 !important'
                    }
                },
                styles ?? {}
            )}
            transition={{
                from: { y: '100%' },
                to: { y: '0' }
            }}
            trapFocus={false}
            {...rest}
        />
    )
})
