import { IconFont, Popover } from '@byecode/ui'
import React, { useCallback, useState } from 'react'

import { COLORS } from '../../constants/color'
import * as SC from './styles'

export type Theme = 'dark' | 'light'

export interface ColorPatchProps {
    children?: React.ReactNode

    theme?: Theme
    value?: string
    onChange?: (color: string) => void
}

const ColorPatch: React.FC<ColorPatchProps> = ({ theme = '', value = 'white', onChange }) => {
    const [currentColor, setCurrentColor] = useState<string>(value)
    const [open, setOpen] = useState(false)
    const themedColor = `${value}`

    const handleItemClick = useCallback(
        (curColor: string) => {
            setCurrentColor(curColor)
            onChange?.(curColor)
            setOpen(false)
        },
        [onChange]
    )

    const renderThemeTextColor = useCallback((color: string) => {
        if (color === 'white') {
            return 'black'
        }
        return color
    }, [])

    return (
        <Popover opened={open} onChange={setOpen} width="auto" withinPortal>
            <Popover.Target>
                <SC.ColorPatchContent color={themedColor} style={{ border: value === 'white' ? '1px solid var(--color-gray-100)' : '' }}>
                    <SC.Icon type="TextA" size={14} fill={`var(--color-${renderThemeTextColor(themedColor)})`} />
                </SC.ColorPatchContent>
            </Popover.Target>
            <Popover.Dropdown>
                <SC.ColorList>
                    {COLORS.map(({ label, name }) => (
                        <SC.ColorItem
                            key={name}
                            onClick={() => {
                                handleItemClick(name)
                            }}
                        >
                            <SC.InfoWrapper>
                                <SC.ColorPoint
                                    style={{
                                        backgroundColor: `var(--color-${name}-light)`,
                                        border: name === 'white' ? '1px solid var(--color-gray-100)' : ''
                                    }}
                                >
                                    <SC.Icon type="TextA" fill={`var(--color-${renderThemeTextColor(name)})`} />
                                </SC.ColorPoint>
                                {label}
                            </SC.InfoWrapper>
                            {currentColor === name && <IconFont type="Tick" size={14} style={{ color: 'var(--color-gray-400)' }} />}
                        </SC.ColorItem>
                    ))}
                </SC.ColorList>
            </Popover.Dropdown>
        </Popover>
    )
}

export default ColorPatch
