import { Empty } from '@byecode/ui'
import type { AppUser, DataSourceAbstract, Field, RecordLikeProtocol } from '@lighthouse/core'
import { mergeRefs } from '@lighthouse/tools'
import { ScrollArea } from '@mantine/core'
import React, { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ApplicationPreviewEnum } from '../../types'
import { FieldSelectTableContextProvider } from './FiledSelectTableContext'
import { TableContent } from './TableContent'
import { TableHeader } from './TableHeader'

export { FieldTableMobile } from './mobile/FiledSelectTableMobile'

const RelatedRecorderContent = styled(ScrollArea)<{ height?: number }>`
    overflow: visible;
    width: 100%;
    height: 100%;
    position: relative;

    & .mantine-ScrollArea-viewport {
        height: auto;
        padding-bottom: 45px;

        max-height: ${({ height }) => (height ? `${height}px` : '100%')};
    }
`

const SCxTableNoDataContent = styled.div`
    width: 100%;
    padding: 50px 0;
    position: sticky;
    top: 0;
    left: 0;
`
interface FiledTableProps {
    tableWidth?: number
    tableHeight?: number
    columns?: Field[]
    primaryField: string
    recordData?: RecordLikeProtocol[]
    value: string[]
    loading: boolean
    previewType?: ApplicationPreviewEnum
    personOptions?: AppUser[]
    dataSourceList: DataSourceAbstract[]
    onRowClick?: (id: string) => void
    onScrollPositionChange?: (position: { x: number; y: number }) => void
}

const FieldTable = React.forwardRef<HTMLDivElement, FiledTableProps>(
    (
        {
            tableWidth,
            columns = [],
            primaryField,
            recordData = [],
            tableHeight,
            value = [],
            loading,
            previewType = ApplicationPreviewEnum.desktop,
            personOptions = [],
            dataSourceList,
            onRowClick,
            onScrollPositionChange
        },
        ref
    ) => {
        const scrollRef = useRef<HTMLDivElement>(null)
        const { t } = useTranslation()
        // const noData = !loading && recordData.length === 0
        const noData = !loading && !recordData

        const contextValue = useMemo(
            () => ({ onRowClick, previewType, value, loading, recordData, personOptions, dataSourceList }),
            [dataSourceList, loading, onRowClick, personOptions, previewType, recordData, value]
        )

        return (
            <FieldSelectTableContextProvider {...contextValue}>
                <RelatedRecorderContent
                    viewportRef={mergeRefs([ref, scrollRef])}
                    height={tableHeight}
                    onScrollPositionChange={onScrollPositionChange}
                >
                    <TableHeader primaryField={primaryField} scrollRef={scrollRef} columns={columns} />
                    {noData ? (
                        <SCxTableNoDataContent>
                            <Empty icon="NoData" description={t('noFindData')} />
                        </SCxTableNoDataContent>
                    ) : (
                        <TableContent
                            tableWidth={tableWidth}
                            columns={columns}
                            tableHeight={tableHeight}
                            scrollXRef={scrollRef}
                            scrollYRef={scrollRef}
                        />
                    )}
                </RelatedRecorderContent>
            </FieldSelectTableContextProvider>
        )
    }
)

export default FieldTable
