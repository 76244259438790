import { Button, IconFont } from '@byecode/ui'
import type { DTFile } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import type { DrawerProps } from '@mantine/core'
import { Divider, Drawer } from '@mantine/core'
import equal from 'fast-deep-equal'
import { current } from 'immer'
import { clone, find, findIndex } from 'rambda'
import React, { useCallback, useId, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState, useUpdateEffect } from 'react-use'
import { useImmer } from 'use-immer'

import type { UseUploadFileSParameter } from '../../..'
import {
    fileNameRegex,
    fileSuffixRegex,
    getImageFullUrlInApplication,
    UploadDropZone,
    useApplicationContext,
    useUploadBatchError,
    useUploadBatchFinished,
    useUploadBatchUploading
} from '../../..'
import type { EnableActionsParams, WithStatusFile } from '../../../types'
import { ApplicationPreviewEnum } from '../../../types'
import { getFileNameByUrl, getFileSizeByUrl, getFileTypeByFileName, getFileTypeByUrl } from '../../../utils/helper'
import { FilePreviewer } from '../../FilePreviewer'
import { useUploadBatchProgress } from '../../UploadManage/hooks/useUploadBatchProgress'
import FileListItem from '../FileListPreviewer/FileListItem'
import FileAdd from '../mobile/FileAdd'
import * as SC from './styles'

export const getFileDrawerConfig: (isClip?: boolean) => Pick<DrawerProps, 'zIndex' | 'styles' | 'position'> = isClip => ({
    zIndex: 200,
    styles: {
        root: { position: isClip ? 'absolute' : 'fixed' },
        header: {
            display: 'none'
        },
        drawer: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            overflow: 'hidden',
            height: 'auto'
        },
        body: {
            backgroundColor: 'var(--color-gray-50)'
        }
    },
    position: 'bottom'
})

interface State {
    openedAdd: boolean
    opened: boolean
}
export interface FileUploaderProps {
    children?: React.ReactNode
    enableItemActions?: EnableActionsParams
    files: DTFile[]
    disableUpload?: boolean
    previewType: ApplicationPreviewEnum
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    uploadFileType?: 'file' | 'video'
    onChange?: (files: DTFile[]) => void
}

export const WithoutPopperFileUploader: React.FC<FileUploaderProps> = ({
    files,
    enableItemActions,
    disableUpload,
    previewType,
    uploadFileType,
    uploadyOptions,
    onChange
}) => {
    const { appId, pageTarget, isRealityMobile } = useApplicationContext()
    const { t } = useTranslation()
    const [defaultIndex, setDefaultIndex] = useState(0)
    const uploadDropId = useId()
    const [{ opened, openedAdd }, setState] = useSetState<State>({
        openedAdd: false,
        opened: false
    })

    const newUploadyOptions = useMemo(
        () => ({ ...uploadyOptions, info: { ...uploadyOptions.info, id: uploadDropId } }),
        [uploadDropId, uploadyOptions]
    )

    const listData: WithStatusFile[] = useMemo(() => {
        return files.map(url => {
            const name = getFileNameByUrl(url) || ''
            const type = getFileTypeByFileName(name)
            const size = getFileSizeByUrl(url)
            return {
                uid: nanoid(),
                label: name,
                value: url,
                type,
                url: getImageFullUrlInApplication(appId, url),
                name: name ?? '',
                status: 'success',
                percent: 100,
                size: Number(size)
            }
        })
    }, [appId, files])
    const [innerFiles, setInnerFiles] = useImmer(listData)

    const oldFileRef = useRef(listData)

    useUpdateEffect(() => {
        if (!equal(oldFileRef.current, listData)) {
            oldFileRef.current = listData
            setInnerFiles(listData)
        }
    }, [listData])

    useUploadBatchUploading(uploadDropId, batchItems => {
        const items: WithStatusFile[] = batchItems.map(item => {
            const { file, id } = item
            const { name, type, size } = file
            const fileType = getFileTypeByUrl(name)
            return {
                uid: id,
                name,
                label: name,
                value: name,
                type: fileType,
                status: 'uploading',
                url: '',
                size,
                percent: 0
            }
        })
        setInnerFiles(draft => {
            items.forEach(item => {
                const index = findIndex(file => file.uid === item.uid, draft)
                if (index >= 0) {
                    draft[index] = item
                    return
                }
                draft.push(item)
            })
        })
    })

    useUploadBatchError(uploadDropId, batchItems => {
        setInnerFiles(draft => {
            return draft.map(item => {
                const { uid } = item
                const uploadingFileIndex = findIndex(({ id }) => uid === id, batchItems)
                if (uploadingFileIndex !== -1) {
                    const uploadFile = draft[uploadingFileIndex]
                    if (uploadFile) {
                        uploadFile.value = nanoid()
                        uploadFile.status = 'error'
                        uploadFile.percent = 100
                    }
                }
                return item
            })
        })
    })

    useUploadBatchProgress(uploadDropId, batchItems => {
        setInnerFiles(draft => {
            draft.forEach(item => {
                const { uid } = item
                const uploadingFile = find(({ id }) => uid === id, batchItems)
                if (uploadingFile) {
                    const uploadFile = find(file => file.uid === uploadingFile.id, draft)
                    if (uploadFile) {
                        uploadFile.status = 'uploading'
                        uploadFile.percent = uploadingFile.completed
                    }
                }
            })
        })
    })

    useUploadBatchFinished(uploadDropId, batchItems => {
        // // const uploadFileListUrl = batchList
        // //     .map(item => {
        // //         const { uploadResponse } = item
        // //         return uploadResponse.data.content?.url
        // //     })
        // //     .filter(Boolean)

        // setInnerFiles(draft => {
        //     batchItems.forEach(batch => {
        //         const { uploadResponse, id } = batch
        //         const uploadingFileIndex = findIndex(({ uid }) => uid === id, draft)
        //         if (uploadingFileIndex >= 0) {
        //             const uploadFile = draft[uploadingFileIndex]
        //             uploadFile.value = nanoid()
        //             uploadFile.percent = 100
        //             uploadFile.url = uploadResponse.data.content ? getImageFullUrlInApplication(appId, uploadResponse.data.content.url) : ''
        //             uploadFile.status = uploadResponse.data.content ? 'success' : 'error'
        //         }
        //     })
        //     const files = draft.filter(f => f.status === 'success').map(f => f.url)
        //     onChange?.(files)
        // })
        const uploadFileUrls = batchItems
            .map(item => {
                const { uploadResponse } = item
                return uploadResponse.data.content.url as string
            })
            .filter(Boolean)
        const fileUrls = [...(files || []), ...uploadFileUrls]
        onChange?.(fileUrls)
        setInnerFiles(draft => {
            batchItems.forEach(batch => {
                const { uploadResponse, id } = batch
                const uploadingFileIndex = findIndex(({ uid }) => uid === id, draft)
                if (uploadingFileIndex >= 0) {
                    const uploadFile = draft[uploadingFileIndex]
                    const url = uploadResponse.data.content ? getImageFullUrlInApplication(appId, uploadResponse.data.content.url) : ''
                    uploadFile.percent = 100
                    uploadFile.value = url || id
                    uploadFile.url = url
                    uploadFile.status = uploadResponse.data.content ? 'success' : 'error'
                }
            })
        })
    })

    const handleFileRemove = useCallback(
        (index: number) => {
            setInnerFiles(draft => {
                draft.splice(index, 1)
                onChange?.(clone(draft).map(item => item.url))
            })
        },
        [onChange, setInnerFiles]
    )

    const handleFileEdit = useCallback(
        (index: number, newName: string) => {
            setInnerFiles(draft => {
                const file = draft[index]
                if (file) {
                    draft.splice(index, 1, { ...file, name: newName })
                    onChange?.(
                        draft.map((item, i) => {
                            if (index === i) {
                                const name = getFileNameByUrl(item.url) ?? ''
                                const newUrl = name && item.url.replace(name, '$NAME$')
                                return newUrl.replace('$NAME$', newName)
                            }
                            return item.url
                        })
                    )
                }
            })
        },
        [onChange, setInnerFiles]
    )

    const filesContent = useMemo(
        () =>
            innerFiles.length > 0 && (
                <SC.FileListContainer>
                    {innerFiles.map((item, index) => {
                        const data = item
                        return (
                            <FileListItem
                                key={item.uid}
                                data={data}
                                // card 主要用于字段 block 中的文件列表变体
                                variant="card"
                                previewType={previewType}
                                enableActions={enableItemActions}
                                onPreview={() => {
                                    setState({ opened: true })
                                    setDefaultIndex(index)
                                }}
                                onRemove={() => {
                                    handleFileRemove(index)
                                }}
                                onChange={file => {
                                    handleFileEdit(index, file)
                                }}
                            />
                        )
                    })}
                </SC.FileListContainer>
            ),
        [enableItemActions, handleFileEdit, handleFileRemove, innerFiles, previewType, setState]
    )
    const fileUploaderContent = useMemo(() => {
        return (
            <>
                {previewType === ApplicationPreviewEnum.desktop ? (
                    <SC.UploadButtonWrapper>
                        <UploadDropZone
                            multiple
                            data-field-border={innerFiles.length === 0}
                            uploadOptions={newUploadyOptions}
                            disabled={disableUpload}
                            accept={uploadFileType === 'video' ? 'video/mp4' : '*'}
                        >
                            <Button
                                color="var(--color-black)"
                                size="lg"
                                icon={<IconFont type="Add" size={16} color="var(--color-black)" />}
                            >
                                {t('clickToUpload')}
                            </Button>
                        </UploadDropZone>
                    </SC.UploadButtonWrapper>
                ) : (
                    <SC.UploadButtonWrapper onClick={() => !disableUpload && setState({ openedAdd: true })}>
                        <Button
                            color="var(--color-black)"
                            data-field-border={innerFiles.length === 0}
                            size="lg"
                            icon={<IconFont type="Add" size={16} color="var(--color-black)" />}
                        >
                            {t('clickToUpload')}
                        </Button>
                    </SC.UploadButtonWrapper>
                )}
            </>
        )
    }, [previewType, innerFiles.length, newUploadyOptions, disableUpload, uploadFileType, t, setState])

    return (
        <SC.FileUploaderWrapper>
            {!disableUpload && fileUploaderContent}
            {filesContent}

            <FilePreviewer
                target={pageTarget}
                defaultIndex={defaultIndex}
                fileList={innerFiles}
                opened={opened}
                onClose={() => setState({ opened: false })}
            />
            {/* 移动端添加图片 */}
            <Drawer
                {...getFileDrawerConfig(Boolean(pageTarget))}
                target={pageTarget}
                opened={openedAdd}
                onClose={() => setState({ openedAdd: false })}
            >
                <FileAdd
                    uploadyOptions={newUploadyOptions}
                    uploadFileType={uploadFileType}
                    disableUpload={disableUpload}
                    isRealityMobile={isRealityMobile}
                />
                <Divider style={{ borderWidth: 8, marginBottom: 52 }} color="var(--color-gray-100)" />
                <SC.Footer style={{ height: 52 }} onClick={() => setState({ openedAdd: false })}>
                    {t('cancel')}
                </SC.Footer>
            </Drawer>
        </SC.FileUploaderWrapper>
    )
}
