import type {
    ApplicationSettingLanguageItem} from '@lighthouse/core'
import type { FC, PropsWithChildren } from 'react'
import React, { createContext, useContext } from 'react'

import { DEFAULT_LANG } from '../constants/language'

export type LanguageContextType = {
    languageList: ApplicationSettingLanguageItem[]
    language: string
}

export const LanguageContext = createContext<LanguageContextType>({
    languageList: [],
    language: DEFAULT_LANG
})

type LanguageProviderProps = PropsWithChildren<{
    value: LanguageContextType
}>

export const LanguageProvider: FC<LanguageProviderProps> = props => <LanguageContext.Provider {...props} />

export const useLanguageContext = () => useContext(LanguageContext)
