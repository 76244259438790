import { IconFont, Tooltip } from '@byecode/ui'
import type { FieldProtocol, HighLightConditions } from '@lighthouse/core'
import { divide, subtract } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { FILE_ICON_MAP } from '../../constants/common'
import { useApplicationContext } from '../../contexts'
import { getFileNameByUrl, getFileTypeByFileName, getImageFullUrlInApplication, getOSSThumbnailImageUrl } from '../../utils'
import { FilePreviewer } from '../FilePreviewer'
import { CellHighlighter } from '../Highlighter'

// import type { FieldValuePreviewerProps } from './types'

export interface VideoCellPreviewerProps {
    data?: string[]
    field?: FieldProtocol
    isWrap?: boolean
    enableEdit?: boolean
    width?: number
    styleConditions?: HighLightConditions
    itemClickable?: boolean
}

const SCxVideoList = styled.div<{ isWrap: boolean }>`
    position: relative;
    max-width: 100%;
    display: flex;
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    gap: 6px;
    /* &.flex-nowrap,
    .flex-nowrap {
        flex-wrap: nowrap;
        flex-shrink: 0;
        margin-bottom: 0;
    } */
`

const ImageVideo = styled.div`
    width: 24px;
    height: 24px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 2px;
    background-color: var(--color-gray-100);
    cursor: zoom-in;
`

const SCxVideoWrapper = styled.div`
    min-width: 24px;
    height: 24px;
    /* margin-bottom: 6px; */
    overflow: hidden;

    &:nth-last-of-type(1) {
        margin-right: 0;
    }
`

const SCxOtherVideo = styled.span`
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 2px;
    max-width: 100%;
    overflow: hidden;
    border-radius: 4px;
    background-color: var(--color-gray-100);
`

const SCxVideoIcon = styled(IconFont)`
    font-size: 20px;
    cursor: zoom-in;
`

export const VideoValuePreviewer: React.FC<VideoCellPreviewerProps> = ({
    field,
    data = [],
    isWrap = true,
    enableEdit,
    width = 160,
    styleConditions,
    itemClickable = true
}) => {
    const [opened, setOpened] = useState(false)
    const [defaultIndex, setDefaultIndex] = useState(0)
    const showLength = useMemo(() => (isWrap ? data.length : Math.floor(divide(width, 30)) - 1), [data.length, isWrap, width])
    const { appId } = useApplicationContext()
    const extraLength = useMemo(() => subtract(data.length, showLength), [data.length, showLength])

    const renderFile = useCallback((pathUrl: string) => {
        const name = getFileNameByUrl(pathUrl) || ''
        const type = getFileTypeByFileName(name)
        return type === 'image' ? (
            <ImageVideo style={{ backgroundImage: `url("${getOSSThumbnailImageUrl(pathUrl, { width: 24, height: 24, quality: 50 })}")` }} />
        ) : (
            <Tooltip withArrow title={name} placement="top">
                <SCxOtherVideo>
                    {FILE_ICON_MAP[type] ? <SCxVideoIcon type={FILE_ICON_MAP[type]} /> : <SCxVideoIcon type={FILE_ICON_MAP.other} />}
                </SCxOtherVideo>
            </Tooltip>
        )
    }, [])

    const fileList = useMemo(
        () =>
            data.map(url => {
                const name = getFileNameByUrl(url) || ''
                const type = getFileTypeByFileName(name)
                return {
                    name,
                    url: getImageFullUrlInApplication(appId, url),
                    type
                }
            }),
        [appId, data]
    )

    return (
        <>
            {field && (
                <CellHighlighter fieldId={field.id} type="date" value={data.map(item => item).join(',')} conditions={styleConditions} />
            )}
            <SCxVideoList
                isWrap={isWrap}
                onClick={ev => {
                    if (itemClickable) {
                        ev.stopPropagation()
                    }
                }}
                onMouseDown={ev => {
                    if (itemClickable) {
                        ev.stopPropagation()
                    }
                }}
            >
                {data.slice(0, showLength).map((fileUrl, i) => (
                    <SCxVideoWrapper
                        key={fileUrl}
                        style={{ cursor: itemClickable ? 'pointer' : 'default' }}
                        onClick={ev => {
                            setOpened(true)
                            setDefaultIndex(i)
                        }}
                    >
                        {renderFile(fileUrl)}
                    </SCxVideoWrapper>
                ))}
                {extraLength > 0 && (
                    <SCxVideoWrapper>
                        <SCxOtherVideo>+{extraLength}</SCxOtherVideo>
                    </SCxVideoWrapper>
                )}
                <FilePreviewer defaultIndex={defaultIndex} fileList={fileList} opened={opened} onClose={() => setOpened(false)} />
            </SCxVideoList>
        </>
    )
}
