import type { ActionItemFitType, ButtonShowType, ButtonTriggerEvent } from '@lighthouse/core'
import { ButtonPattern } from '@lighthouse/core'
import React, { useMemo, useState } from 'react'

import { btnStyleMap, btnTypeShowMap } from './constant'
import * as SC from './styles'

type ThemeButtonStyles = 'title' | 'icon' | 'container' | 'wrapper'
interface ThemeButtonProps {
    icon?: string
    title?: string
    pattern?: ButtonPattern
    showType?: ButtonShowType
    triggerEvent?: ButtonTriggerEvent
    fillWay?: ActionItemFitType
    disabled?: boolean
    loading?: boolean
    isSubmit?: boolean
    styles?: Partial<Record<ThemeButtonStyles, React.CSSProperties>>
    onMouseOver?: React.MouseEventHandler<HTMLButtonElement>
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const ThemeButton = React.forwardRef<HTMLButtonElement, ThemeButtonProps>(
    (
        {
            showType = 'icon-name',
            title,
            icon,
            triggerEvent,
            disabled,
            loading,
            isSubmit,
            fillWay = 'auto',
            pattern = ButtonPattern.primary,
            styles,
            onMouseOver,
            onClick
        },
        ref
    ) => {
        const { icon: showIcon, name: showName } = useMemo(() => btnTypeShowMap.get(showType) ?? { icon: true, name: true }, [showType])

        const style: React.CSSProperties = useMemo(() => {
            const patternStyle = btnStyleMap.get(pattern) ?? {}

            const fillWayStyle = fillWay === 'auto' ? {} : { width: '100%', flex: 1 }

            return {
                ...styles?.container,
                color: patternStyle.color,
                borderColor: patternStyle.borderColor,
                border: patternStyle.borderColor ? `1px solid ${patternStyle.borderColor}` : 'none',
                backgroundColor: patternStyle.backgroundColor,
                ...fillWayStyle
            }
        }, [fillWay, pattern, styles?.container])

        return (
            <SC.Container
                type={isSubmit ? 'submit' : 'button'}
                style={style}
                event={triggerEvent}
                disabled={disabled}
                onClick={loading ? undefined : onClick}
                onMouseOver={onMouseOver}
                ref={ref}
            >
                <SC.Wrapper style={styles?.wrapper}>
                    <>
                        {showIcon && loading && (
                            <SC.Icon style={styles?.icon} type={loading ? 'Spinner' : icon ?? ''} loading={loading} color={style.color} />
                        )}
                        {showName && (
                            <SC.Text style={styles?.title} color={style.color}>
                                {title}
                            </SC.Text>
                        )}
                    </>
                </SC.Wrapper>
            </SC.Container>
        )
    }
)
