import type { Field, FieldADTValue, FieldCellValue } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useApplicationContext } from '../../contexts'
import { getDepartmentToTreeOptions, isDateValue, isFileValue, isIdsValue } from '../../utils'
import { CascadeDrawer, DateDrawer, PersonDrawer, RoleDrawer, SelectDrawer } from '../FieldDrawer'
import type { UseUploadFileSParameter } from '../UploadManage'
import { FileDrawerField } from './FileDrawerField'

interface FieldValueMobileEditorProps {
    field: Field
    value?: FieldCellValue
    target?: string
    opened: boolean
    recordId: string
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    onClose: () => void
    onChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
}

const SCxContainer = styled.div``

export const FieldValueMobileEditor: React.FC<FieldValueMobileEditorProps> = ({
    field,
    value,
    target,
    opened,
    recordId,
    uploadyOptions,
    onClose,
    onChange
}) => {
    const { personOptions, departmentOptions, roleOptions } = useApplicationContext()

    // uploadyOptions, title, previewType
    const handleFinish = useCallback(
        (val: FieldCellValue) => {
            const fieldValue = {
                ...field,
                value: val
            } as FieldADTValue
            onChange?.(recordId, fieldValue)
        },
        [field, onChange, recordId]
    )

    const content = useMemo(() => {
        switch (field.type) {
            case 'date': {
                const showTime = !!field.date.format && field.date.format.includes('HH')
                return (
                    <DateDrawer
                        value={isDateValue(value) ? value : undefined}
                        target={target}
                        opened={opened}
                        showTime={showTime}
                        onClose={onClose}
                        onChange={handleFinish}
                    />
                )
            }
            case 'person': {
                return (
                    <PersonDrawer
                        value={isIdsValue(value) ? value : []}
                        selectOptions={personOptions}
                        isMultiple={field.person.multiple}
                        title={field.name}
                        target={target}
                        opened={opened}
                        onClose={onClose}
                        onChange={handleFinish}
                    />
                )
            }
            case 'role': {
                return (
                    <RoleDrawer
                        value={isIdsValue(value) ? value : []}
                        isMultiple={false}
                        options={roleOptions}
                        title={field.name}
                        target={target}
                        opened={opened}
                        onClose={onClose}
                        onChange={handleFinish}
                    />
                )
            }
            case 'department': {
                const optionTree = getDepartmentToTreeOptions(departmentOptions)
                return (
                    <CascadeDrawer
                        options={optionTree}
                        value={isIdsValue(value) ? value : []}
                        isMultiple
                        title={field.name}
                        target={target}
                        opened={opened}
                        onClose={onClose}
                        onFinish={handleFinish}
                    />
                )
            }
            case 'select': {
                return (
                    <SelectDrawer
                        value={isIdsValue(value) ? value : []}
                        isMultiple={field.select.multiple}
                        selectOptions={field.select.options}
                        title={field.name}
                        target={target}
                        opened={opened}
                        onClose={onClose}
                        onChange={handleFinish}
                    />
                )
            }
            case 'file': {
                return (
                    <FileDrawerField
                        id={`${field.dsId}-${field.id}-${recordId}`}
                        title={field.name}
                        target={target}
                        opened={opened}
                        value={isFileValue(value) ? value : []}
                        uploadyOptions={uploadyOptions}
                        onClose={onClose}
                        onChange={handleFinish}
                    />
                )
            }
            default: {
                return null
            }
        }
    }, [field, handleFinish, onClose, opened, target, uploadyOptions, recordId, value, departmentOptions, personOptions, roleOptions])

    return <SCxContainer>{content}</SCxContainer>
}
