import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

export const backgroundTransitionOnClick = (): FlattenSimpleInterpolation => css`
    /* transition: background 0.1s ease-out; */
    &:hover {
        background-color: var(--color-gray-100);
        /* transition: background 0.1s ease-in; */
    }

    &:active {
        background-color: var(--color-gray-200);
        /* transition: background 0.1s ease-in; */
    }
`

export const itemBackgroundTransitionOnClick = (): FlattenSimpleInterpolation => css`
    /* transition: background 0.1s ease-out; */
    &:hover {
        color: var(--color-gray-900);
        background-color: var(--color-gray-100);
        /* transition: background 0.1s ease-in; */
    }

    &:active {
        /* color: var(--color-main);
        background-color: var(--color-main-tint); */
        /* transition: background 0.1s ease-in; */
    }
`

export const backgroundAndColorOnClick = (): FlattenSimpleInterpolation => css`
    &:hover {
        background-color: var(--color-gray-100);
        color: var(--color-gray-900);
    }

    &:active {
        background-color: var(--color-gray-100);
        color: var(--color-gray-900);
    }
`

export const wideSpreadAnimation = (): FlattenSimpleInterpolation => css`
    @keyframes wideSpread {
        from {
            width: 0;
            height: 0;
            opacity: 1;
            box-shadow: 0 0 0 3px #ffcf54;
        }
        to {
            width: 36px;
            height: 36px;
            opacity: 0.5;
            box-shadow: 0 0 0 1px #ffcf54;
        }
    }

    &.active {
        position: relative;

        ::after {
            position: absolute;
            content: '';
            inset: 50%;
            border-radius: 100%;
            animation: wideSpread 1.3s linear infinite;
            transform: translate(-50%, -50%);
        }

        ::before {
            position: absolute;
            content: '';
            width: 26px;
            height: 26px;
            inset: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            background-color: rgba(255, 207, 84, 0.3);
            transform: translate(-50%, -50%);
        }
    }
`

export const spinAnimation = (): FlattenSimpleInterpolation => css`
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`
