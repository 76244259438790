import { Extension } from '@tiptap/core'

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        fontSize: {
            setFontSize: (size: number) => ReturnType
            unsetFontSize: () => ReturnType
        }
    }
}

export type FontSizeOptions = {
    types: string[]
    defaultFontSize: number
}

export const FontSize = Extension.create<FontSizeOptions>({
    name: 'fontSize',

    addOptions() {
        return {
            types: ['textStyle'],
            defaultFontSize: 16
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontSize: {
                        default: this.options.defaultFontSize,
                        parseHTML: element => {
                            return element.style.fontSize?.replace(/["'px]+/g, '') || this.options.defaultFontSize
                        },
                        renderHTML: attributes => {
                            if (attributes.fontSize === this.options.defaultFontSize) {
                                return {}
                            }

                            return {
                                style: `font-size: ${attributes.fontSize}px;`
                            }
                        }
                    }
                }
            }
        ]
    },

    addCommands() {
        return {
            setFontSize:
                fontSize =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { fontSize }).run()
                },
            unsetFontSize:
                () =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { fontSize: null }).removeEmptyTextStyle().run()
                }
        }
    }
})
