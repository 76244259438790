import React from 'react'

interface Props {
    width: number
    height: number
}

export const ContainerOutline = ({ width, height }: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`} style={{ width, height, verticalAlign: 'middle' }}>
            <rect x="0" y="0" width="100%" height="100%" fill="none" stroke="var(--color-main)" strokeWidth="2" strokeDasharray="6, 4" />
        </svg>
    )
}
