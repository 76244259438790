import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    flex: 1;
    align-self: stretch;
`

export const Placeholder = (props: React.ComponentPropsWithRef<'div'>) => {
    return <Container {...props} />
}
