import { getAssetUrl } from '@lighthouse/assets'
import {
    type BlockType,
    type ChartType,
    type CustomViewLayout,
    type FieldInputType,
    type QrBarcodeEnum,
    type ViewOptions,
    type ViewType,
    DIRECTION,
    FilterBlockMode} from '@lighthouse/core'
import type { MenuItem } from '@lighthouse/shared'

export type ListItemAction = 'DELETE' | 'EDIT'

// /////////////////////// block icon start //////////////////////////// //
export const ChartTypeIconMap: Record<ChartType, string> = {
    bar: 'ChartsBlock',
    pie: 'PieChart2Block',
    line: 'DiagramUpBlock',
    funnel: 'ChartFunnel',
    indicator: 'PlateBlock',
    composite: 'ChartCompound',
    striation: 'ChartBarHorizontal'
}
export const ChartTypePlaceHolderMap: Record<ChartType, string> = {
    bar: '建议 任意数量的维度和数值，1个次轴',
    pie: '建议 1个维度，1个数值或 0个维度，多个数值',
    line: '建议 任意数量的维度和数值，1个次轴',
    funnel: '建议 1个维度，1个数值或 0个维度，多个数值',
    indicator: '需要 1个数值',
    composite: '建议 任意数量的维度和数值，1个次轴',
    striation: '建议 1个维度，多个数值'
}
export const FIELD_BLOCK_ICON_MAP: Record<FieldInputType, string> = {
    number: 'PropertyNumberBlock',
    file: 'PropertyFileBlock',
    text: 'PropertyTextBlock',
    email: 'PropertyEmailBlock',
    url: 'PropertyLinkBlock',
    person: 'PropertyPersonBlock',
    phoneNumber: 'PropertyPhoneBlock',
    date: 'PropertyCalendarBlock',
    relativeSelect: 'PropertySingleSelectBlock',
    notes: 'PropertyNoteBlock',
    checkbox: 'PropertyCheckboxBlock',
    slider: 'SliderBlock',
    cascade: 'PropertyCascade'
}
export const VIEW_BLOCK_ICON_MAP: Record<ViewType, string> = {
    table: 'NewTableBlock',
    advancedTable: 'AdvanceTableLayer',
    list: 'ListBlock',
    gallery: 'GalleryBlock',
    kanban: 'PauseBlock',
    calendar: 'CalendarBlock',
    custom: 'CustomizingViews'
}

export const QR_BARCODE_BLOCK_ICON_MAP: Record<QrBarcodeEnum, string> = {
    QR_CODE: 'QRCode',
    BARCODE: 'Barcode'
}

export const CONTAINER_ICON_MAP = {
    [DIRECTION.horizontal]: 'ColumnContainer',
    [DIRECTION.vertical]: 'RowContainer'
}

export const FILTER_BLOCK_ICON_MAP: Record<FilterBlockMode, string> = {
    [FilterBlockMode.normal]: 'PropertySingleSelect',
    [FilterBlockMode.tile]: 'StackSimple'
}

export const BlockTypeIconMap: Record<Exclude<BlockType, 'container' | 'field' | 'chart' | 'view'>, string> = {
    // prompt: 'BlockCallout',
    // quote: 'BlockQuote',
    relatedView: 'BlockTable',
    image: 'ImageBlock',
    // tag: 'Group',
    // notice: 'MediaSpeakerLow',
    buttonGroup: 'ButtonBasicBlock',
    card: 'ImageText',
    divider: 'MinusSquareBlock',
    appNav: 'SquareArrowRightUpBlock',
    video: 'VideoCameraBlock',
    fieldGroup: 'ThreeSquaresBlock',
    breadcrumb: 'PostsCarouselHorizontalBlock',
    iframe: 'WindowFrameBlock',
    collapse: 'DocumentBlock',
    file: 'FileSendBlock',
    // container: 'LayerTagContainerBlock',
    formContainer: 'LayerFormContainerBlock',
    tabs: 'HorizontalLayoutBlock',
    text: 'LayerTextComponentBlock',
    filter: '',
    qrBarcode: '',
    subForm: ''
}
// /////////////////////// block icon end //////////////////////////// //

// /////////////////////// block 名称 start //////////////////////////// //
export const CHART_BLOCK_NAME_MAP: Record<ChartType, string> = {
    bar: '柱状图',
    line: '折线图',
    composite: '复合图表',
    pie: '饼图',
    funnel: '漏斗图',
    indicator: '指标卡',
    striation: '条形图'
}
export const FIELD_BLOCK_NAME_MAP: Record<FieldInputType, string> = {
    number: '数值',
    file: '附件',
    text: '文本',
    email: '邮箱',
    url: '链接',
    person: '人员',
    phoneNumber: '电话',
    date: '日期',
    relativeSelect: '选择器',
    notes: '笔记',
    checkbox: '复选框',
    slider: '滑动输入条',
    cascade: '级联'
}

export const BLOCK_NAME_MAP: Record<Exclude<BlockType, 'field' | 'chart' | 'view'>, string> = {
    appNav: '应用导航',
    breadcrumb: '面包屑',
    buttonGroup: '按钮组',
    card: '图文导航',
    collapse: '折叠面板',
    container: '空白容器',
    divider: '分割线',
    fieldGroup: '字段组',
    file: '文件预览',
    formContainer: '表单容器',
    iframe: '嵌入网页',
    image: '图片',
    relatedView: '关联视图',
    tabs: '标签栏',
    filter: '筛选控制器',
    text: '文本',
    video: '视频',
    qrBarcode: '条码',
    subForm: '子表单'
}
// /////////////////////// block 名称 end //////////////////////////// //

export const ChartBlockThumbImageMap: Record<ChartType, string> = {
    bar: getAssetUrl('blocks', 'bar_chart.svg'),
    pie: getAssetUrl('blocks', 'pie_chart.svg'),
    line: getAssetUrl('blocks', 'line_chart.svg'),
    indicator: getAssetUrl('blocks', 'indicator_card.svg'),
    funnel: getAssetUrl('blocks', 'funnel_card.svg'),
    composite: getAssetUrl('blocks', 'composite_chart.svg'),
    striation: getAssetUrl('blocks', 'striation_chart.svg')
}

export const BlockThumbImageMap: Record<Exclude<BlockType, 'field' | 'chart'> | ViewOptions['viewType'] | CustomViewLayout, string> = {
    view: getAssetUrl('blocks', 'board.svg'),
    relatedView: getAssetUrl('blocks', 'text_input.svg'),
    table: getAssetUrl('blocks', 'table.svg'),
    advancedTable: getAssetUrl('blocks', 'advanced_table.svg'),
    list: getAssetUrl('blocks', 'list.svg'),
    gallery: getAssetUrl('blocks', 'gallery.svg'),
    image: getAssetUrl('blocks', 'image.svg'),
    buttonGroup: getAssetUrl('blocks', 'button.svg'),
    card: getAssetUrl('blocks', 'card.svg'),
    divider: getAssetUrl('blocks', 'divider.svg'),
    appNav: getAssetUrl('blocks', 'jump_to_app.svg'),
    kanban: getAssetUrl('blocks', 'kanban.svg'),
    video: getAssetUrl('blocks', 'video.svg'),
    fieldGroup: getAssetUrl('blocks', 'field_group.svg'),
    calendar: getAssetUrl('blocks', 'calendar.svg'),
    breadcrumb: getAssetUrl('blocks', 'breadcrumb.svg'),
    iframe: getAssetUrl('blocks', 'iframe.svg'),
    collapse: getAssetUrl('blocks', 'collapse.svg'),
    file: getAssetUrl('blocks', 'file.svg'),
    tabs: getAssetUrl('blocks', 'tabs.svg'),
    container: getAssetUrl('blocks', 'empty_module.svg'),
    formContainer: getAssetUrl('blocks', 'form_container.svg'),
    text: getAssetUrl('blocks', 'text.svg'),
    custom: getAssetUrl('blocks', 'custom_view.svg'),
    waterfall: getAssetUrl('blocks', 'custom_view_waterfall.svg'),
    grid: getAssetUrl('blocks', 'custom_view_grid.svg'),
    filter: getAssetUrl('blocks', 'filter.svg'),
    qrBarcode: getAssetUrl('blocks', 'qr_barcode.svg'),
    subForm:  getAssetUrl('blocks', 'sub_form.svg')
}

export const FieldBlockThumbImageMap: Record<FieldInputType, string> = {
    text: getAssetUrl('blocks', 'text_input.svg'),
    number: getAssetUrl('blocks', 'number_input.svg'),
    email: getAssetUrl('blocks', 'email_input.svg'),
    url: getAssetUrl('blocks', 'url_input.svg'),
    file: getAssetUrl('blocks', 'file_input.svg'),
    relativeSelect: getAssetUrl('blocks', 'relative_select_input.svg'),
    person: getAssetUrl('blocks', 'person_input.svg'),
    phoneNumber: getAssetUrl('blocks', 'phone_number_input.svg'),
    date: getAssetUrl('blocks', 'date_input.svg'),
    notes: getAssetUrl('blocks', 'notes_input.svg'),
    checkbox: getAssetUrl('blocks', 'checkbox_input.svg'),
    slider: getAssetUrl('blocks', 'slider.svg'),
    cascade: getAssetUrl('blocks', 'cascade_input.svg')
}

export const menuOperationItems: MenuItem<ListItemAction>[] = [
    {
        section: '操作',
        items: [
            {
                name: '编辑',
                icon: 'PencilSimple',
                hotkey: '',
                action: 'EDIT'
            },
            {
                name: '删除',
                icon: 'Trash',
                hotkey: '',
                action: 'DELETE'
            }
        ]
    }
]
