import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { Box } from '../../Box'

const useStyles = createStyles(() => ({
    root: css({
        width: '100%',
        height: '100%'
    })
}))

export interface TabPanelProps extends StyleComponentProps<Selectors<typeof useStyles>>, React.ComponentPropsWithoutRef<'div'> {
    icon?: string
    tab: React.ReactNode
    key: string
    extra?: React.ReactNode
}

export const UnstableTabPanel = forwardRef<HTMLDivElement, TabPanelProps>(({ styles, className, unstyled, ...rest }, ref) => {
    const { classes } = useStyles({}, { name: 'tabPanel', styles, unstyled })

    return <Box ref={ref} className={clsx(classes.root, className)} {...rest} />
})

UnstableTabPanel.displayName = 'Bui-UnstableTabPanel'
