import { Button, Switch } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, DragOverlay } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext } from '@dnd-kit/sortable'
import type { DTSelect, Field, SelectField } from '@lighthouse/core'
import { SortEnum } from '@lighthouse/core'
import { nanoid, randomData } from '@lighthouse/tools'
import { find, findIndex, sortBy } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { ControllerRenderProps } from 'react-hook-form'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useHotkeys } from 'react-hotkeys-hook'
import { FixedSizeList } from 'react-window'

import { COLORS, InnerTypeMapByFieldType } from '../../constants'
import ColorPatch from '../ColorPatch'
import { ErrorMessage } from '../ErrorMessage'
import { SortableItem } from './SortableItem'
import * as SC from './styles'

interface SelectConfigViewProps {
    children?: React.ReactNode
    config: SelectField
    onOk?: (data: Field) => void
    onCancel: () => void
}

const SelectConfigView: React.FC<SelectConfigViewProps> = ({ config, onOk, onCancel }) => {
    const listRef = useRef<FixedSizeList>(null)
    const lengthRef = useRef(Number.NaN)
    const selectConfig = useMemo(() => config?.select, [config])

    const [overlay, setOverlay] = useState<React.ReactNode>(null)

    const {
        control,
        getValues,
        setValue,
        handleSubmit,
        formState: { errors }
    } = useForm<DTSelect>({
        defaultValues: {
            sort: selectConfig?.sort || SortEnum.normal,
            options: selectConfig?.options ?? [],
            multiple: selectConfig?.multiple ?? false
        },
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true
    })

    const { fields, remove, append, move, replace } = useFieldArray({ control, name: 'options', keyName: 'key' })

    const [fieldsCount, containerHeight] = useMemo(() => {
        const selectOptionsLength = fields.length
        if (selectOptionsLength < 5) {
            return [selectOptionsLength + 1, selectOptionsLength * 40]
        }
        return [6, 200]
    }, [fields.length])

    const handleOk = useCallback(
        (select: DTSelect) => {
            const validOptions = select.options.filter(item => item.label.trim())

            onOk?.({
                ...config,
                type: 'select',
                select: { ...select, options: validOptions },
                innerType: InnerTypeMapByFieldType['select']
            })
        },
        [config, onOk]
    )

    const handleSortByAlphabet = useCallback(
        (field: ControllerRenderProps<DTSelect, 'sort'>) => {
            const sort = field.value
            const options = getValues('options')
            const sortedByAlphabetFields = sortBy(item => item.label, options)
            if (sort === SortEnum.positive) {
                field.onChange(SortEnum.reverse)
                replace(sortedByAlphabetFields.reverse())
                return
            }
            field.onChange(SortEnum.positive)
            replace(sortedByAlphabetFields)
        },
        [getValues, replace]
    )

    useHotkeys(
        'Enter',
        () => {
            handleSubmit(handleOk)()
        },
        { enableOnTags: ['INPUT'] }
    )

    useEffect(() => {
        if (fields.length > lengthRef.current) {
            const num = fields.length
            listRef.current?.scrollTo(34 * num)
            lengthRef.current = Number.NaN
        }
    }, [fields.length])

    const handleDragStart = useCallback(
        (data: DragStartEvent) => {
            const { active } = data
            const { id: dragId } = active

            const options = getValues('options')
            const itemData = find(item => item.id === dragId, options)

            if (!itemData) {
                return null
            }
            setOverlay(
                <SC.OptionItem>
                    <SC.MoveIcon type="Move" fill="var(--color-gray-400)" />
                    <SC.OptionInput readOnly size="md" value={itemData.label} />
                    <ColorPatch value={itemData.color} />
                    <SC.Icon type="Close" fill="var(--color-gray-400)" />
                </SC.OptionItem>
            )
        },
        [getValues]
    )
    const handleDragCancel = () => setOverlay(null)

    const handleSortEnd = useCallback(
        (ev: DragEndEvent) => {
            const {
                active: { id: activeId },
                over
            } = ev
            const overId = over?.id
            if (!overId) {
                return
            }
            const activeIndex = findIndex(({ id }) => id === activeId, fields)
            const overIndex = findIndex(({ id }) => id === overId, fields)
            setValue('sort', SortEnum.normal)
            move(activeIndex, overIndex)
            setOverlay(null)
        },
        [fields, move, setValue]
    )

    const handleOptionAdd = useCallback(() => {
        lengthRef.current = fields.length
        const { name: color } = randomData(COLORS, 1)[0] ?? {}
        append({ label: '', id: nanoid(), color })
    }, [append, fields.length])

    const getSortIcon = useCallback((sort: SortEnum) => {
        if (sort === SortEnum.positive) {
            return 'ArrowsDownUpAsc'
        }
        if (sort === SortEnum.reverse) {
            return 'ArrowsDownUpDsc'
        }
        return 'ArrowsDownUp'
    }, [])

    return (
        <SC.FieldForm>
            <SC.FieldConfigWrapper>
                <SC.FormItemWrapper>
                    <SC.FieldConfigHeader>设置</SC.FieldConfigHeader>
                    <SC.FormItemLimitCell>
                        <SC.FieldConfigLabel>多选</SC.FieldConfigLabel>
                        <Controller
                            control={control}
                            name="multiple"
                            render={({ field: { value, onChange } }) => (
                                <Switch
                                    checked={Boolean(value)}
                                    onChange={ev => {
                                        onChange(ev.target.checked)
                                    }}
                                />
                            )}
                        />
                    </SC.FormItemLimitCell>
                </SC.FormItemWrapper>
                <SC.DividerLine />
                <SC.FormItemWrapper>
                    <SC.FieldConfigHeaderWrapper>
                        <SC.FieldConfigHeader>选项</SC.FieldConfigHeader>
                        <Controller
                            control={control}
                            name="sort"
                            render={({ field }) => {
                                const sort = field.value || SortEnum.normal
                                const sortIcon = getSortIcon(sort)
                                return (
                                    <SC.FieldConfigSorter onClick={() => handleSortByAlphabet(field)}>
                                        <SC.SortIcon size={14} type={sortIcon} />
                                        按字母顺序排列
                                    </SC.FieldConfigSorter>
                                )
                            }}
                        />
                    </SC.FieldConfigHeaderWrapper>
                    <SC.OptionList>
                        <DndContext
                            modifiers={[restrictToParentElement, restrictToFirstScrollableAncestor]}
                            autoScroll={{ layoutShiftCompensation: false }}
                            onDragStart={handleDragStart}
                            onDragCancel={handleDragCancel}
                            onDragEnd={handleSortEnd}
                        >
                            <SortableContext items={fields.map(d => d.id)}>
                                <FixedSizeList
                                    ref={listRef}
                                    width="100%"
                                    height={containerHeight}
                                    itemData={fields}
                                    itemKey={(index: number) => fields[index].id}
                                    itemCount={fields.length}
                                    itemSize={40}
                                    overscanCount={fieldsCount}
                                >
                                    {({ index, style, data: items }) => {
                                        const item = items[index]
                                        return (
                                            <SortableItem key={item.key} id={item.id} wrapperStyle={style}>
                                                <Controller
                                                    control={control}
                                                    rules={{
                                                        required: '请输入选项',
                                                        validate(val) {
                                                            const { options } = getValues()
                                                            const isRepeated = options.some(({ label }, i) => {
                                                                return label === val && i !== index
                                                            })
                                                            return isRepeated ? '重复啦！' : true
                                                        }
                                                    }}
                                                    name={`options.${index}.label`}
                                                    render={({ field }) => {
                                                        return (
                                                            <ErrorMessage name={`options.${index}.label`} errors={errors}>
                                                                <SC.OptionInput
                                                                    key={index}
                                                                    size="md"
                                                                    defaultValue={field.value}
                                                                    onChange={field.onChange}
                                                                    autoFocus={lengthRef.current === index}
                                                                />
                                                            </ErrorMessage>
                                                        )
                                                    }}
                                                />
                                                <Controller
                                                    control={control}
                                                    name={`options.${index}.color`}
                                                    render={({ field: { value, onChange } }) => (
                                                        <ColorPatch value={value} onChange={onChange} />
                                                    )}
                                                />
                                                <SC.Icon
                                                    type="Close"
                                                    onClick={() => {
                                                        remove(index)
                                                    }}
                                                    fill="var(--color-gray-400)"
                                                />
                                            </SortableItem>
                                        )
                                    }}
                                </FixedSizeList>
                            </SortableContext>
                            <DragOverlay dropAnimation={{ duration: 0, easing: 'ease' }}>{overlay}</DragOverlay>
                        </DndContext>
                    </SC.OptionList>
                    <SC.OptionAdder
                        onClick={() => {
                            handleOptionAdd()
                        }}
                    >
                        <SC.Icon fill="var(--color-main)" type="Add" />
                        添加选项
                    </SC.OptionAdder>
                </SC.FormItemWrapper>
            </SC.FieldConfigWrapper>
            <SC.ActionWrapper>
                <Button style={{ marginRight: 8 }} onClick={onCancel}>
                    取消
                </Button>
                <Button type="primary" onClick={handleSubmit(handleOk)}>
                    确定
                </Button>
            </SC.ActionWrapper>
        </SC.FieldForm>
    )
}

export default SelectConfigView
