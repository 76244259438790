import { createContext, useContext } from 'react'

import type { PopoverProps } from '../Popover'

export interface MenuContextValue {
    closeOnItemClick?: boolean
    trigger: PopoverProps['trigger']
    opened: boolean
    close: () => void
    open: () => void
    toggle: () => void
}

const MenuContext = createContext<MenuContextValue | null>(null) as React.Context<MenuContextValue>
export const MenuContextProvider = MenuContext.Provider
export const useMenuContext = () => useContext(MenuContext)
