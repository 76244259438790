import type { ConfirmProps } from './confirm'
import { confirm } from './confirm'
import type { InfoProps } from './info';
import { info } from './info'
import { Modal as OriginModal } from './Modal'

export * from './base'
export * from './confirm'
export * from './desktop'
export * from './info'
export * from './mobile'
export type { ModalProps } from './Modal'
export * from './ModalsProvider'

export const Modal = OriginModal as typeof OriginModal & {
    confirm: (cfg: ConfirmProps) => Promise<boolean>
    info: (cfg: InfoProps) => Promise<boolean>
}

Modal.confirm = confirm
Modal.info = info
