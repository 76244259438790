import type {
    ActionsProtocol,
    AiFieldStatus,
    ButtonAction,
    FieldADTValue,
    HighLightConditions,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SchemaProtocol,
    ViewField
} from '@lighthouse/core'
import type { ApplicationPreviewEnum } from '@lighthouse/shared'
import { conversionHighLightRecord, useActionRunningLoadings } from '@lighthouse/shared'
import type { Virtualizer } from '@tanstack/react-virtual'
import cls from 'classnames'
import type { atomWithImmer } from 'jotai-immer'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { RecordOperator } from '../../components/RecordOperator'
import { AdvancedTableChecker, SCxAdvancedTableCheckerWrapper } from './AdvancedTableChecker'
import type { AdvancedTableContentState, AdvancedTableTooltipState } from './AdvancedTableContent'
import { TableAdvancedValue } from './AdvancedTableValue'

interface AdvancedTableRecordProps extends ActionsProtocol {
    appId: string
    dsId: string
    blockId: string
    index: number
    record: RecordLikeProtocol
    checkable?: boolean
    recordOpenable?: boolean
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    selectedRecords?: string[]
    viewFields: ViewField[]
    schema: SchemaProtocol['schema']
    highLightRules?: HighLightConditions
    columnVirtualizer: Virtualizer<HTMLDivElement, Element>
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    hoverCellColor: string
    primaryDataSourceFieldIds?: Set<string>
    previewType?: ApplicationPreviewEnum
    onRecordClick?: (recordId: string) => void
    onRecordSelect?: (recordIds: string[]) => void
    onOpenEditor?: (data: AdvancedTableContentState) => void
    onOpenTooltip?: (data: AdvancedTableTooltipState) => void
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onRecordEdit?: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

const SCxTableDot = styled.div`
    /* position: sticky;
    top: 0;
    right: 8px;
    z-index: 1;

    background: var(--color-white);
    overflow-y: visible; */
    position: sticky;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-white);
    width: 36px;
    height: 100%;
`

const SCxTableOperator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

export const SCxTableRecord = styled.div`
    position: relative;
    display: flex;
    width: max-content;
    min-width: 100%;
    height: 41px;
    border-bottom: 1px solid var(--color-gray-200);
    background: var(--color-white);

    &.record-active {
        background-color: var(--color-gray-100);

        ${SCxAdvancedTableCheckerWrapper} {
            background-color: var(--color-gray-100);
        }

        ${SCxTableOperator} {
            background-color: var(--color-gray-100);
        }
    }

    &:last-child {
        height: 42px;
    }

    &:last-child ${SCxAdvancedTableCheckerWrapper} {
        height: 42px;
    }

    &:hover {
        background-color: var(--color-gray-100);
    }

    &:hover .table-check {
        background-color: var(--color-gray-100);
    }

    &:hover ${SCxTableOperator} {
        background-color: var(--color-gray-100);
    }
`

export const SCxTableRow = styled.div`
    width: 100%;
    display: flex;
`

const TableRecord: React.FC<AdvancedTableRecordProps> = ({
    appId,
    dsId,
    blockId,
    index,
    actions,
    record,
    checkable,
    recordOpenable,
    selectedRecords,
    viewFields,
    schema,
    highLightRules,
    columnVirtualizer,
    hoverCellColor,
    recordEditOpenable,
    recordDeleteAble,
    aiFieldStatusListAtom,
    primaryDataSourceFieldIds,
    previewType,
    onRecordClick,
    onRecordSelect,
    onOpenEditor,
    onOpenTooltip,
    onCellChange,
    onRecordDelete,
    onRecordEdit,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle
}) => {
    const { id, content: recordContent } = record
    const { cell: cellStyleConditions, row: rowStyleConditions } = useMemo(
        () => conversionHighLightRecord(highLightRules ?? []),
        [highLightRules]
    )
    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()
    const handleCheck = useCallback(
        (checked: boolean, id: string) => {
            if (!selectedRecords) {
                return
            }
            if (checked) {
                onRecordSelect?.([...selectedRecords, id])
            } else {
                onRecordSelect?.(selectedRecords.filter(rId => rId !== id))
            }
        },
        [onRecordSelect, selectedRecords]
    )

    const handleAiGeneration = useCallback(
        (fieldId: string) => {
            if (!onAiGeneration) {
                return Promise.resolve(false)
            }
            return onAiGeneration(id, fieldId)
        },
        [id, onAiGeneration]
    )

    const handleRecordOpen = useCallback(() => {
        const recordClickedActionParams = actions?.recordClicked
        if (recordClickedActionParams?.customized && recordClickedActionParams?.action) {
            handleActionTriggerWithLoading({
                type: 'click',
                id,
                action: recordClickedActionParams.action,
                record,
                trigger: onRecordClickedActionTrigger
            })
            return
        }
        onRecordClick?.(id)
    }, [actions?.recordClicked, handleActionTriggerWithLoading, id, onRecordClick, onRecordClickedActionTrigger, record])

    return (
        <SCxTableRecord key={id} data-key={`${blockId}&${id}`} className={cls({ 'record-active': selectedRecords?.includes(id) })}>
            {selectedRecords && (
                <AdvancedTableChecker
                    className="table-check"
                    checkable={checkable}
                    isRecordSelecting={selectedRecords.length > 0}
                    index={index}
                    showOpenerIcon={recordOpenable}
                    checked={selectedRecords.includes(id)}
                    onChange={checked => handleCheck(checked, id)}
                    onRecordOpen={handleRecordOpen}
                />
            )}
            <SCxTableRow>
                {columnVirtualizer.getVirtualItems().map(virtualColumn => {
                    const viewField = viewFields[virtualColumn.index]
                    const { fieldId: cId, width } = viewField
                    const field = schema[cId]
                    if (!recordContent || !field) {
                        return null
                    }
                    const cData = recordContent[cId]?.value
                    const styleConditions = cellStyleConditions?.[cId] || []
                    return (
                        <TableAdvancedValue
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: `${virtualColumn.size}px`,
                                transform: `translateX(${virtualColumn.start}px)`
                            }}
                            blockId={blockId}
                            dsId={dsId}
                            key={cId}
                            width={width}
                            recordId={id}
                            schema={schema}
                            field={field}
                            data={cData}
                            styleConditions={styleConditions}
                            aiFieldStatusListAtom={aiFieldStatusListAtom}
                            hoverCellColor={hoverCellColor}
                            primaryDataSourceFieldIds={primaryDataSourceFieldIds}
                            previewType={previewType}
                            onOpenEditor={onOpenEditor}
                            onOpenTooltip={onOpenTooltip}
                            onCellChange={onCellChange}
                            onAiGeneration={handleAiGeneration}
                        />
                    )
                })}
            </SCxTableRow>
            <SCxTableDot>
                <SCxTableOperator>
                    <RecordOperator
                        dsId={dsId}
                        blockId={blockId}
                        actions={actions?.recordOperator?.actionGroup?.group}
                        customized={actions?.recordOperator?.customized}
                        config={{ canEdit: recordEditOpenable, canDelete: recordDeleteAble }}
                        onRecordDelete={onRecordDelete}
                        onRecordEdit={onRecordEdit}
                        recordId={id}
                        record={record}
                        // eslint-disable-next-line no-return-await
                        onActionTrigger={async action => await onRecordOperatorActionTrigger?.(action, record)}
                        onRenderButtonTitle={v => onRenderButtonTitle(v, record)}
                    />
                </SCxTableOperator>
            </SCxTableDot>
        </SCxTableRecord>
    )
}

export default TableRecord
