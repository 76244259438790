/* eslint-disable @typescript-eslint/no-redeclare */
import { Enum } from '@lighthouse/tools'

import type { AlignMent, Arrangement, BlockSize, Direction, Position } from '../../protocols'

export const GalleryAvatarSize = Enum('sm', 'md', 'lg', 'xl')
export type GalleryAvatarSize = Enum<typeof GalleryAvatarSize>

export const GalleryAvatarShape = Enum('square', 'circle')
export type GalleryAvatarShape = Enum<typeof GalleryAvatarShape>

export const GalleryCardStyle = Enum('block', 'border', 'none')
export type GalleryCardStyle = Enum<typeof GalleryCardStyle>

/** 伸缩方式 */
export interface ObjectFitConfig {
    objectFit?: Extract<React.CSSProperties['objectFit'], 'contain' | 'cover'>
}
export interface RatioConfig {
    ratio?: string
}
/** 摆放位置 */
export interface PositionConfig {
    position?: Exclude<Position, 'bottom'>
}

/** 字段基本设置 */
export interface BaseFieldConfig {
    /** 是否显示 */
    show?: boolean
    /** 展示字段 */
    field?: string
    /** 对齐方式 */
    align?: AlignMent
}

/** 字段显示方式 */
export type GroupFieldValue = {
    // showTitle: boolean
    wrap: boolean
    // title: string
    id: string
}

/**
 * 画廊视图配置
 * @date 9/7/2023 - 2:15:12 PM
 *
 * @export
 * @interface GalleryViewOptions
 * @typedef {GalleryViewOptions}
 */
export interface GalleryViewOptions {
    /** 封面 */
    headCover?: Omit<BaseFieldConfig, 'align'> & ObjectFitConfig & RatioConfig
    /** 头像 */
    headAvatar?: BaseFieldConfig &
        PositionConfig & {
            size?: GalleryAvatarSize
            shape?: GalleryAvatarShape
        }
    /** 标题 */
    headTitle?: BaseFieldConfig
    /** 标签 */
    headTags?: {
        show?: boolean
        align?: AlignMent
        fields?: GroupFieldValue[]
        /** 显示字段名 */
        showLabel?: boolean
    }
    /** 内容 */
    contentTags?: {
        show?: boolean
        align?: AlignMent
        fields?: GroupFieldValue[]
        /** 显示字段名 */
        showLabel?: boolean
    }
    /** 尾部 */
    footTags?: {
        show?: boolean
        align?: AlignMent
        fields?: GroupFieldValue[]
        /** 显示字段名 */
        showLabel?: boolean
    }
    direction?: Direction
    /** 布局方式 */
    arrangement?: Arrangement
    /** 列数 */
    cols?: number
    /** 尺寸 */
    size?: Exclude<BlockSize, 'xs'>

    /**
     * 卡片风格
     * @date 6/28/2023 - 5:17:05 PM
     *
     * @type {?GalleryCardStyle}
     */
    style?: GalleryCardStyle
}
