import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext } from '@dnd-kit/sortable'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import ListItem from './ListItem'
import ListNormalItem from './ListNormalItem'
import * as SC from './styles'
import type { ListItemData, ListProps } from './types'

export function List<T extends ListItemData>({
    data = [],
    style,
    rowKey,
    sortable,
    hoveredIndex,
    showDragHandler = true,
    disableSelect = false,
    description,
    itemStyle,
    itemDraggerStyle,
    itemRender,
    onItemClick,
    onSortEnd,
    disabled
}: ListProps<T>) {
    const listRef = useRef<HTMLDivElement>(null)
    const sortItems = useMemo(() => data.map(d => (rowKey ? String(d[rowKey]) : d.value)), [data, rowKey])
    const [sortId, setSortId] = useState<string | null>(null)
    const sortIndex = useMemo(() => {
        return sortId ? sortItems.indexOf(sortId) : -1
    }, [sortId, sortItems])
    const distanceSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 1
        }
    })
    const sensors = useSensors(distanceSensor)
    const handleSortStart = useCallback((ev: DragStartEvent) => {
        if (!ev.active) {
            return
        }
        const id = ev.active.id as string
        setSortId(id)
    }, [])
    const handleSortEnd = useCallback(
        (ev: DragEndEvent) => {
            onSortEnd?.(ev)
            setSortId(null)
        },
        [onSortEnd]
    )
    const handleSortCancel = useCallback(() => {
        setSortId(null)
    }, [])

    const handleItemClick = useCallback(
        (itemData: string | number) => {
            if (!disableSelect) {
                onItemClick?.(`${itemData}`)
            }
        },
        [disableSelect, onItemClick]
    )

    const listItems = useMemo(() => {
        return data.map((d, i) => (
            <ListItem
                disabled={disabled}
                key={rowKey ? String(d[rowKey]) : d.value}
                dataKey={rowKey ? String(d[rowKey]) : d.value}
                showDragHandler={showDragHandler}
                sortable={sortable}
                active={sortId === d.value}
                hovered={hoveredIndex === i}
                data={d}
                index={i}
                itemStyle={itemStyle}
                itemDraggerStyle={itemDraggerStyle}
                itemRender={itemRender}
                onClick={handleItemClick}
            />
        ))
    }, [data, disabled, handleItemClick, hoveredIndex, itemDraggerStyle, itemRender, itemStyle, rowKey, showDragHandler, sortId, sortable])

    const normalList = useMemo(() => {
        return (
            <SC.ListWrapper ref={listRef}>
                {itemRender
                    ? data.map((d, i) => (
                          <ListNormalItem
                              key={rowKey ? String(d[rowKey]) : d.value}
                              hovered={hoveredIndex === i}
                              onClick={() => {
                                  handleItemClick(d.value)
                              }}
                          >
                              {itemRender(d, i)}
                          </ListNormalItem>
                      ))
                    : listItems}
            </SC.ListWrapper>
        )
    }, [itemRender, data, listItems, rowKey, hoveredIndex, handleItemClick])

    const draggingItem = useMemo(() => {
        if (!sortId) {
            return null
        }
        const activeData = data[sortIndex]

        if (!activeData) {
            return null
        }

        const activeDataKey = rowKey ? String(activeData[rowKey]) : activeData.value

        return (
            <ListItem
                disabled
                showDragHandler
                sortable
                dataKey={activeDataKey}
                data={activeData}
                index={sortIndex}
                itemStyle={itemStyle}
                itemRender={itemRender}
            />
        )
    }, [data, itemRender, itemStyle, rowKey, sortId, sortIndex])

    const sortableList = useMemo(() => {
        return (
            <DndContext
                sensors={sensors}
                modifiers={[restrictToParentElement]}
                onDragStart={handleSortStart}
                onDragEnd={handleSortEnd}
                onDragCancel={handleSortCancel}
            >
                <SC.ListWrapper ref={listRef}>
                    <SortableContext disabled={disabled} items={sortItems}>
                        {listItems}
                    </SortableContext>
                    {createPortal(<DragOverlay dropAnimation={{ duration: 0, easing: 'ease' }}>{draggingItem}</DragOverlay>, document.body)}
                </SC.ListWrapper>
            </DndContext>
        )
    }, [sensors, handleSortStart, handleSortEnd, handleSortCancel, disabled, sortItems, listItems, draggingItem])

    return (
        <>
            {!!description && <SC.Description>{description}</SC.Description>}
            <SC.ListScroller style={style}>{sortable ? sortableList : normalList}</SC.ListScroller>
        </>
    )
}
