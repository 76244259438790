import React, { forwardRef } from 'react'

export interface IconBaseProps extends IconParkIconAttributes<HTMLElement> {
    rotate?: number
}

export interface IconFontProps<T extends string = string> extends IconBaseProps {
    type: T
}

const rotateCss = (rotate?: number) =>
    typeof rotate === 'number'
        ? {
              transition: 'transform 0.2s',
              transform: `rotate(${rotate}deg)`
          }
        : {}

export const IconFont = forwardRef<HTMLElement, IconFontProps>(
    ({ type, rotate, className, color, style, fill = 'currentColor', ...rest }, ref) => {
        const mergeStyle = {
            ...rotateCss(rotate),
            ...style
        }
        const props = { class: className, fill: color ?? fill, ...rest, style: mergeStyle }

        return <iconpark-icon name={type} ref={ref} {...props} />
    }
)

IconFont.displayName = 'byecode-iconFont'
