import { Input, Select } from '@byecode/ui'
import type { IdRule } from '@lighthouse/core'
import { NumberInput } from '@mantine/core'
import React, { useMemo } from 'react'
import type { FieldErrorsImpl } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { ErrorMessage } from '../../ErrorMessage'
import { DateFormatOptions } from '../constants'

interface IdConfigItemProps {
    type: IdRule['type']
    index: number
    configLength: number
    errors: Partial<
        FieldErrorsImpl<{
            rules: NonNullable<IdRule>[]
        }>
    >
}

const SCxContainer = styled.div`
    margin-right: 4px;
`

const SCxOptionInput = styled(Input)`
    padding: 0;
`

const SCxOptionSelect = styled(Select)`
    padding: 0;
`

// const SCxIdLength = styled.div`
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
// `

const SCxNumberInput = styled(NumberInput)`
    width: 134px;
`

export const IdConfigItem: React.FC<IdConfigItemProps> = ({ type, index, configLength, errors }) => {
    const validText = useMemo(() => new RegExp(/^[\dA-Za-z-]*$/), [])
    const { control } = useFormContext()
    const content = useMemo(() => {
        switch (type) {
            case 'ID': {
                return (
                    <Controller
                        control={control}
                        rules={{
                            required: '请输入数字'
                        }}
                        name={`rules.${index}.length`}
                        render={({ field }) => {
                            return (
                                <ErrorMessage name={`rules.${index}.length`} errors={errors}>
                                    <SCxNumberInput
                                        size="xs"
                                        defaultValue={3}
                                        variant="filled"
                                        styles={{
                                            input: {
                                                border: 'none',
                                                background: 'var(--color-gray-100)'
                                            },
                                            control: {
                                                background: 'var(--color-gray-200)'
                                            }
                                        }}
                                        min={1}
                                        max={8}
                                        radius={6}
                                        onKeyDown={e => {
                                            e.preventDefault()
                                        }}
                                        autoFocus={configLength === index}
                                        {...field}
                                    />
                                </ErrorMessage>
                            )
                        }}
                    />
                )
            }
            case 'DATE': {
                return (
                    <Controller
                        control={control}
                        rules={{
                            required: '请选择日期格式'
                        }}
                        name={`rules.${index}.dateFormat`}
                        render={({ field }) => {
                            return (
                                <ErrorMessage name={`rules.${index}.dateFormat`} errors={errors}>
                                    <SCxOptionSelect
                                        style={{ width: '134px' }}
                                        key={index}
                                        size="sm"
                                        options={DateFormatOptions}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                </ErrorMessage>
                            )
                        }}
                    />
                )
            }
            default: {
                return (
                    <Controller
                        control={control}
                        rules={{
                            required: '请输入固定字符'
                        }}
                        name={`rules.${index}.fixedCharacter`}
                        render={({ field }) => {
                            return (
                                <ErrorMessage name={`rules.${index}.fixedCharacter`} errors={errors}>
                                    <SCxOptionInput
                                        style={{ width: '134px' }}
                                        key={index}
                                        size="sm"
                                        maxLength={20}
                                        placeholder="请输入"
                                        value={field.value}
                                        onKeyUp={e => e}
                                        onChange={e => {
                                            const val = e.target.value
                                            if (!validText.test(val)) {
                                                return
                                            }
                                            field.onChange(val)
                                        }}
                                        autoFocus={configLength === index}
                                    />
                                </ErrorMessage>
                            )
                        }}
                    />
                )
            }
        }
    }, [type, control, index, errors, configLength, validText])

    return <SCxContainer>{content}</SCxContainer>
}
