import type { PanelMode } from '@byecode/ui';
import { Calendar  } from '@byecode/ui'
import type { DateField, VariableFieldADTValue } from '@lighthouse/core'
import { useUncontrolled } from '@lighthouse/tools';
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { defaultFormat } from '../../../../constants'

interface DateCalendarProps {
    value?: Date
    config: DateField['date']
    mode?: PanelMode
    onModeChange?: (mode: PanelMode) => void
    onChange: (value: VariableFieldADTValue) => void
}

const DateCalendar: React.FunctionComponent<DateCalendarProps> = ({ value, config, mode, onChange, onModeChange }) => {
    const showTime = useMemo(() => (config?.format ? config.format.includes('HH:mm') : true), [config?.format])

    const [panelMode, setPanelMode] = useState(mode)
    // 各种事件变化中的预览日期
    const [internalDate, setInternalDate] = useState<Date | undefined>()

    const handleChange = useCallback(
        (v?: Date) => {
            onChange({ type: 'date', value: v?.valueOf() ?? '', format: defaultFormat })
        },
        [onChange]
    )

    useUpdateEffect(() => {
        setInternalDate(value)
    }, [value])

    return (
        <Calendar
            style={{ width: 320 }}
            showTime={showTime}
            date={internalDate}
            onDateChange={val => {
                setInternalDate(val)
            }}
            mode={panelMode}
            onModeChange={setPanelMode}
            onConfirm={handleChange}
        />
    )
}

export default DateCalendar
