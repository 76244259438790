import type { DataSourceAbstract, Field, FieldADTValue, FieldType, RecordLikeProtocol } from '@lighthouse/core'
import { format as formatUtils } from 'date-fns'
import { isNil } from 'rambda'
import { SolarDay, SolarTime } from 'tyme4ts'

import { InnerTypeMapByFieldType } from '../constants'
import type { FieldConvertValueOption } from './helper'
import { DATE_FORMAT, fieldConvertValue } from './helper'

export const matchWritableBetweenField = (writtenField: Field, sourceFieldType: FieldType) => {
    if (writtenField.type === 'person') {
        return sourceFieldType === 'person'
    }

    if (writtenField.type === 'date') {
        return sourceFieldType === 'date'
    }

    if (writtenField.type === 'checkbox') {
        return sourceFieldType === 'checkbox'
    }
    // notes field can not be source field
    if (sourceFieldType === 'notes') {
        return false
    }

    return InnerTypeMapByFieldType[writtenField.type] === InnerTypeMapByFieldType[sourceFieldType]
}

/**
 * 解析数据源字段为纯文本
 * @param dataSource 数据源
 * @param fieldId 字段id
 * @param record 记录
 * @returns 字符串值
 */
export function parseField2TextValue(
    dataSource: DataSourceAbstract,
    fieldId: string,
    record: RecordLikeProtocol,
    option?: FieldConvertValueOption
) {
    const { schema } = dataSource
    const field = schema?.[fieldId]
    if (!field) {
        return ''
    }
    const value = record.content?.[fieldId]?.value

    if (isNil(value) && field.type !== 'checkbox') {
        return ''
    }

    const fieldValue = { ...field, value } as FieldADTValue
    return variableFieldValueConvert2string(fieldValue, option)
}

/** 解析变量时间类型的值 */
export function parseVariableDate(timestamp: number | Date, format: string): string {
    const date = new Date(timestamp)
    switch (format) {
        case 'lunar-y': {
            const lunarDay = SolarDay.fromYmd(date.getFullYear(), date.getMonth() + 1, date.getDate()).getLunarDay()
            return `${lunarDay.getYearSixtyCycle().getName()}年`
        }

        case 'lunar-md': {
            const lunarDay = SolarDay.fromYmd(date.getFullYear(), date.getMonth() + 1, date.getDate()).getLunarDay()
            return `${lunarDay.getMonth().getName().replace('十二月', '腊月')}${lunarDay.getName()}`
        }

        case 'lunar-h': {
            const lunarHour = SolarTime.fromYmdHms(
                date.getFullYear(),
                date.getMonth() + 1,
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds()
            ).getLunarHour()
            return lunarHour.getName()
        }

        default: {
            return formatUtils(timestamp, format)
        }
    }
}
/** 变量字段值转换为纯文本 */
function variableFieldValueConvert2string(fieldValue: FieldADTValue, option?: FieldConvertValueOption): string {
    if (fieldValue.type === 'date') {
        const {
            date: { format = DATE_FORMAT },
            value
        } = fieldValue

        const mergeFormat = option?.format || format

        if (Array.isArray(value)) {
            return value
                .map(item => (item ? parseVariableDate(Number(item), mergeFormat) : ''))
                .filter(Boolean)
                .join(',')
        }
        return value ? parseVariableDate(Number(value), mergeFormat) : ''
    }

    return fieldConvertValue(fieldValue, option)
}
