import { Toast } from '@byecode/ui'
import type { DTFile, FileValue } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import { Divider, Drawer } from '@mantine/core'
import { FILE_STATES } from '@rpldy/shared'
import { useAbortAll } from '@rpldy/uploady'
import { current } from 'immer'
import { findIndex } from 'rambda'
import React, { useId, useMemo } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { useApplicationContext } from '../../contexts'
import { type WithStatusFile } from '../../types'
import {
    getBatchToInnerFile,
    getFileNameByUrl,
    getFileSizeByUrl,
    getFileTypeByFileName,
    getFileTypeByUrl,
    getImageFullUrlInApplication
} from '../../utils'
import FileAdd from '../FileUploader/mobile/FileAdd'
import { getFileDrawerConfig } from '../FileUploader/WithoutPopperFileUploader'
import { type UseUploadFileSParameter, useUploadBatchError, useUploadBatchFinished, useUploadBatchUploading } from '../UploadManage'
import { useUploadBatch } from '../UploadManage/hooks/useUploadBatch'

interface FileDrawerFieldProps {
    id: string
    value: FileValue
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    readOnly?: boolean
    isControlled?: boolean
    title?: string
    target?: string
    opened: boolean
    accept?: string
    multiple?: boolean
    onClose: () => void
    onChange?: (val: DTFile[]) => void
}

interface FileValueEditorState {
    value: WithStatusFile[]
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
`

export const SCxFooter = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 16px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-gray-50);
    z-index: 1;
`

export const FileDrawerField: React.FunctionComponent<FileDrawerFieldProps> = props => {
    const { id, value: data, accept, multiple, uploadyOptions, target, opened, onClose, onChange } = props
    const uId = useId()
    const uploadDropId = id || uId
    const { appId } = useApplicationContext()
    const { batchItems } = useUploadBatch(uploadDropId, FILE_STATES.UPLOADING)
    const listData = useMemo(() => {
        const list = batchItems?.map(batchItem => getBatchToInnerFile(batchItem)) || []
        return [
            ...data.map(url => {
                const name = getFileNameByUrl(url) || ''
                const type = getFileTypeByFileName(name)
                const size = getFileSizeByUrl(url)
                return {
                    uid: nanoid(),
                    label: name,
                    value: url,
                    size: Number(size),
                    type,
                    url: getImageFullUrlInApplication(appId, url),
                    name: name ?? '',
                    status: 'success',
                    percent: 100
                }
            }),
            ...list
        ]
    }, [appId, batchItems, data])

    const [innerFiles, setInnerFiles] = useImmer(listData)

    const newUploadyOptions = useMemo(
        () => ({ ...uploadyOptions, info: { ...uploadyOptions.info, id: uploadDropId } }),
        [uploadDropId, uploadyOptions]
    )

    // useUploadBatchUploading(uploadDropId, BatchItems => {
    //     const items = BatchItems.map(item => {
    //         const { file, id, completed } = item
    //         const { name, type, size } = file
    //         const fileType = getFileTypeByUrl(name)
    //         return {
    //             uid: id,
    //             name,
    //             label: name,
    //             value: id,
    //             type: fileType,
    //             status: 'uploading',
    //             url: '',
    //             size,
    //             percent: completed
    //         }
    //     })
    //     setInnerFiles(draft => {
    //         items.forEach(item => {
    //             const index = findIndex(file => file.uid === item.uid,draft)
    //             if(index >= 0){
    //                 draft[index] = item
    //                 return
    //             }
    //             draft.push(item)
    //         })
    //     })
    // })
    const abortAll = useAbortAll()

    useUploadBatchUploading(uploadDropId, (batchItems, batchId) => {
        const hasNotAllowFileType =
            accept && accept !== '*'
                ? batchItems.some(item => {
                      const { file } = item
                      const fileType = getFileTypeByUrl(file.name)
                      return !accept.includes(fileType)
                  })
                : false
        if (hasNotAllowFileType) {
            Toast.info('格式错误，仅支持上传图片')
            abortAll()
            return
        }
        const list = batchItems.map(item => {
            const { file, id, completed } = item
            const { name, type, size } = file
            const fileType = getFileTypeByUrl(name)
            return {
                uid: id,
                name,
                label: name,
                value: id,
                type: fileType,
                status: 'uploading',
                url: '',
                size,
                percent: completed
            }
        })
        const items = multiple ? list : list.slice(0, 1)
        setInnerFiles(draft => {
            items.forEach(item => {
                const index = findIndex(file => file.uid === item.uid, draft)
                if (index >= 0) {
                    draft[index] = item
                    return
                }
                draft.push(item)
            })
        })
    })
    useUploadBatchError(uploadDropId, batchItems => {
        setInnerFiles(draft => {
            return draft.map(item => {
                const { uid } = item
                const uploadingFileIndex = findIndex(({ id }) => uid === id, batchItems)
                if (uploadingFileIndex !== -1) {
                    const uploadFile = draft[uploadingFileIndex]
                    if (uploadFile) {
                        uploadFile.value = uid
                        uploadFile.status = 'error'
                        uploadFile.percent = 100
                    }
                }
                return item
            })
        })
    })

    useUploadBatchFinished(uploadDropId, batchItems => {
        const uploadFileUrls = batchItems
            .map(item => {
                const { uploadResponse } = item
                return uploadResponse.data.content.url as string
            })
            .filter(Boolean)
        const fileUrls = [...(data || []), ...uploadFileUrls]
        onChange?.(fileUrls)
        setInnerFiles(draft => {
            batchItems.forEach(batch => {
                const { uploadResponse, id } = batch
                const uploadingFileIndex = findIndex(({ uid }) => uid === id, draft)
                if (uploadingFileIndex >= 0) {
                    const uploadFile = draft[uploadingFileIndex]
                    const url = uploadResponse.data.content ? getImageFullUrlInApplication(appId, uploadResponse.data.content.url) : ''
                    uploadFile.percent = 100
                    uploadFile.value = url || id
                    uploadFile.url = url
                    uploadFile.status = uploadResponse.data.content ? 'success' : 'error'
                }
            })
        })
    })

    return (
        <SCxContainer>
            <Drawer {...getFileDrawerConfig(Boolean(target))} target={target} opened={opened} onClose={onClose}>
                <FileAdd uploadyOptions={newUploadyOptions} isRealityMobile />
                <Divider style={{ borderWidth: 8, marginBottom: 52 }} color="var(--color-gray-100)" />
                <SCxFooter style={{ height: 52 }} onClick={onClose}>
                    取消
                </SCxFooter>
            </Drawer>
        </SCxContainer>
    )
}
