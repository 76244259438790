import type { DataSourceAbstract, Field, QuickFilterConfig } from '@lighthouse/core'
import { type FilterCommonCondition, VariableType } from '@lighthouse/core'

import { filterTileOperator } from '../../constants'
import { getRealField } from '../../utils'

export function getInitQuickFilter(
    field: Field,
    mode: QuickFilterConfig['mode'],
    rule: FilterCommonCondition | undefined
): FilterCommonCondition {
    const realField = getRealField(field)

    const getInitOperator = (field: Field | undefined, operator?: string) => {
        if (field?.type === 'file') {
            return operator || ''
        }
        return field?.innerType ? filterTileOperator[field.innerType] : ''
    }
    if (rule) {
        const newRule = { ...rule }
        if (mode === 'tile') {
            newRule.operator = getInitOperator(realField, newRule.operator)
        }
        return newRule
    }
    return {
        idVariable: { type: VariableType.FIELD_ID, fieldIdVariable: { fieldId: realField?.id } },
        operator: getInitOperator(realField),
        paramList: undefined
    }
}
