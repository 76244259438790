import type { DepartmentProtocols, RoleProtocols, SpaceUser } from '@lighthouse/core'

export interface AppRole extends RoleProtocols {}

export enum ScanStatus {
    NOT_SCAN = 0,
    LOGIN = 1,
    REGISTER = 2,
    QR_EXPIRED = 3
}

export interface AddUsersPayload {
    teamIds: string[]
    userIds: string[]
}

export interface AppDepartment extends DepartmentProtocols {
    managerUserId?: string
    labelPath?: string
}

export interface AppDepartmentWidthChild extends AppDepartment {
    children?: AppDepartmentWidthChild[]
}

export interface TeamBase {
    teamId: string
    name: string
    parentId?: string
    avatar?: string
}

export interface Team extends TeamBase {
    teamUsers: SpaceUser[]
    spaceId: string
}

export interface TeamTree extends Team {
    children?: TeamTree[]
}

export interface LoginUser {
    accessToken: string
    refreshToken: string
}

export type ImportUser = {
    excelRow: number
    name: string
    mobile: string
    reason: string
}
