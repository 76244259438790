import { useByecodeUIConfig } from '@byecode/ui/providers'
import { formatMessage } from '@byecode/ui/utils/intl'
import clsx from 'clsx'
import type { FC } from 'react'
import React from 'react'

import type { Selectors, StyleComponentProps } from '../../../theme/types'
import { Box } from '../../Box'
import { Button } from '../../Button'
import { Flex } from '../../Flex'
import type { PanelMode } from '../Calendar.type'
import { useStyles } from './CalendarFooter.style'

export type CalendarFooterStylesName = Selectors<typeof useStyles>

interface CalendarFooterProps extends StyleComponentProps<CalendarFooterStylesName> {
    mode: PanelMode
    showTime?: boolean
    hideNow?: boolean
    onClickNow?: React.MouseEventHandler
    onClickShowTime?: React.MouseEventHandler
    onClickShowDay?: React.MouseEventHandler
    onConfirm?: React.MouseEventHandler
}

export const CalendarFooter: FC<CalendarFooterProps> = props => {
    const { mode, showTime, hideNow, onClickNow, onClickShowTime, onClickShowDay, onConfirm, classNames, styles, unstyled, ...rest } = props

    const { locale } = useByecodeUIConfig()
    const { classes } = useStyles({}, { name: 'Calendar', classNames, styles, unstyled })

    return (
        <Box className={clsx(classes.footer)} {...rest}>
            <Box>
                {!hideNow && (
                    <Button size="sm" onClick={onClickNow}>
                        {formatMessage('now', locale)}
                    </Button>
                )}
            </Box>

            <Flex alignItems="center" gap="8px">
                {showTime &&
                    (mode === 'time' ? (
                        <Button size="sm" onClick={onClickShowDay}>
                            {formatMessage('chooseDate', locale)}
                        </Button>
                    ) : (
                        <Button size="sm" type="primary" onClick={onClickShowTime}>
                            {formatMessage('chooseTime', locale)}
                        </Button>
                    ))}

                <Button size="sm" type="primary" onClick={onConfirm}>
                    {formatMessage('ok', locale)}
                </Button>
            </Flex>
        </Box>
    )
}
