import type { Field, HighLightConditions, SimpleTextValue, ViewField } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { SimpleTextItemPreviewer, SimpleTextListPreviewer } from '../FieldValuePreviewer/SimpleTextValuePreviewer'
import { CellHighlighter } from '../Highlighter'

// import type { FieldValuePreviewerProps } from './types'
interface SimpleTextValuePreviewerProps {
    field: Field
    data?: SimpleTextValue | SimpleTextValue[]
    isWrap?: boolean
    width?: number
    styleConditions?: HighLightConditions
    isBold?: boolean
}

export const SimpleTextValuePreviewer: React.FC<SimpleTextValuePreviewerProps> = ({
    field,
    data,
    width = 160,
    isWrap = true,
    isBold,
    styleConditions
}) => {
    const { id: fieldId, type } = field
    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (!data) {
            return ''
        }
        if (Array.isArray(data)) {
            return <SimpleTextListPreviewer data={data} field={field} width={width} isWrap={isWrap} isBold={isBold} />
        }
        const id = nanoid()
        return <SimpleTextItemPreviewer id={id} field={field} isWrap={isWrap} data={data} isBold={isBold} isLast />
    }, [data, field, isBold, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={fieldId} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
