import type { AnyFunction } from 'rambda'

const logMaps = new Map()

export class LogSender {
    collectLog(log: { logInvoker: AnyFunction; invokeId: string }) {
        const { invokeId, logInvoker } = log

        if (logMaps.has(invokeId)) {
            const logMap = logMaps.get(invokeId)
            logMap.queue.push(logInvoker)
        } else {
            logMaps.set(invokeId, { queue: [logInvoker], running: false })
        }
    }

    checkIsRunning(invokeId: string) {
        const logIns = logMaps.get(invokeId)
        if (logIns.running || logIns.queue.length === 0) {
            return
        }

        this.sendLog(invokeId)
    }

    async sendLog(invokeId: string) {
        const logIns = logMaps.get(invokeId)
        logIns.running = true

        const logInvoker = logIns.queue.shift()
        if (!logInvoker) {
            return
        }
        const { workflowInstanceId, queue } = logIns
        const extraParams = workflowInstanceId ? { workflowInstanceId } : undefined
        const res = await logInvoker(extraParams)
        if (!workflowInstanceId) {
            logIns.workflowInstanceId = res
        }
        if (queue.length) {
            this.sendLog(invokeId)
        }
    }
}
