import { type NotificationMessageData, useAtomAction } from '@lighthouse/shared'
import { useUpdateEffect } from 'react-use'

import { addAiFieldStatusAtom, deleteAiFieldStatusAtom, updateCellAtom } from '@/atoms/dataSource/action'

import { useCurrentAppId } from './useApplication'

export const useSetAiFieldStatus = (message: NotificationMessageData) => {
    const currentAppId = useCurrentAppId()
    const { run: addAiFieldStatus } = useAtomAction(addAiFieldStatusAtom)
    const { run: deleteAiFieldStatus } = useAtomAction(deleteAiFieldStatusAtom)
    const { run: updateCell } = useAtomAction(updateCellAtom)
    useUpdateEffect(() => {
        if (!message || message.type !== 'TEXT_GEN_INVOKE') {
            return
        }
        if (message.state === 'FINISHED') {
            deleteAiFieldStatus({
                recordId: message.recordId,
                dsId: message.dataSourceId,
                fieldId: message.fieldId
            })
            updateCell(
                {
                    pageId: '',
                    recordId: message.recordId,
                    dsId: message.dataSourceId,
                    appId: currentAppId,
                    fieldId: message.fieldId,
                    value: {
                        value: message.content || ''
                    }
                },
                { local: true }
            )
            return
        }
        addAiFieldStatus(message)
    }, [message])
}
