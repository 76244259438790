import type {
    ActionsProtocol,
    ButtonAction,
    CustomViewLayout,
    DesignProtocol,
    RecordLikeProtocol,
    ViewBlockAbstract
} from '@lighthouse/core'
import { CUSTOM_VIEW_LAYOUT } from '@lighthouse/core'
import type { FlowLayoutCustomNode, FlowLayoutNode } from '@lighthouse/shared'
import { checkActionEvent } from '@lighthouse/shared'
import { mergeRefs } from '@lighthouse/tools'
import React, { forwardRef, useCallback, useLayoutEffect, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import styled, { css } from 'styled-components'

import { Item } from './Item'
import { setDeadCopyNode } from './utils'

const SHOULD_NOT_FORWARD_PROPS = new Set(['layout', 'cols', 'gap', 'records'])
type StyleProps = {
    layout: CustomViewLayout
    cols: number
    gap: number
}
const Root = styled.div.withConfig<StyleProps>({ shouldForwardProp: p => !SHOULD_NOT_FORWARD_PROPS.has(p) })`
    ${({ layout, cols, gap }) => {
        const isWaterfall = layout === CUSTOM_VIEW_LAYOUT.waterfall
        const isGrid = layout === CUSTOM_VIEW_LAYOUT.grid

        if (isWaterfall) {
            return css`
                /* display: grid;
                grid-template-columns: repeat(${cols}, 1fr);
                column-gap: ${gap}rem;
                grid-auto-rows: 2px;
                align-items: end; */
                display: flex;
                flex-flow: column wrap;
                align-content: flex-start;
                column-gap: ${gap / 2}rem;
                row-gap: ${gap}rem;
            `
        }

        if (isGrid) {
            return css`
                display: grid;
                grid-template-columns: repeat(${cols}, 1fr);
                gap: ${gap}rem;
            `
        }
    }}
`

interface Props extends ActionsProtocol {
    blockData: ViewBlockAbstract
    node: FlowLayoutCustomNode
    showAction?: boolean
    title: string
    viewId: string
    pointer: string
    records: RecordLikeProtocol[]
    cols: number
    gap: number
    design?: DesignProtocol
    layout: CustomViewLayout
    childWidth: number
    baseRem: number
    readonly?: boolean

    onFilterNode?: (node: FlowLayoutNode, record: RecordLikeProtocol) => FlowLayoutNode
    scale?: number
    onRecordClick?: (recordId: string) => void
    onRecordClickedActionTrigger?: (action: ButtonAction, record: RecordLikeProtocol) => void
}

export const VerticalView = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        blockData,
        node,
        showAction,
        title,
        viewId,
        pointer,
        records,
        cols,
        gap,
        design,
        layout,
        childWidth,
        baseRem,
        readonly,
        scale = 1,
        actions,
        onFilterNode,
        onRecordClick,
        onRecordClickedActionTrigger
    } = props

    const isWaterfall = layout === CUSTOM_VIEW_LAYOUT.waterfall

    const innerRef = useRef<HTMLDivElement | null>(null)

    const [maxHeightColumn, setMaxHeightColumn] = useState<number>()
    const handleResize = useCallback(() => {
        const rootEl = innerRef.current
        if (!rootEl || !isWaterfall) {
            return
        }
        const columnHeights = Array.from<number>({ length: cols }).fill(0)
        let broken = false
        const children = rootEl.childNodes as NodeListOf<HTMLElement>
        children.forEach(childNode => {
            if (broken || childNode.dataset.class === 'masonry-line-break') {
                return
            }
            const { height } = childNode.getBoundingClientRect()
            if (height === 0) {
                broken = true
                return
            }

            const minColumnIndex = columnHeights.indexOf(Math.min(...columnHeights))
            columnHeights[minColumnIndex] += height + gap * baseRem
            childNode.style.order = `${1 + minColumnIndex}`
        })

        if (broken) {
            return
        }
        flushSync(() => {
            setMaxHeightColumn(Math.max(...columnHeights))
        })
    }, [baseRem, cols, gap, isWaterfall])

    useLayoutEffect(() => {
        const el = innerRef.current
        if (!el || !isWaterfall) {
            return
        }

        let raf: number
        const observer = new ResizeObserver(() => {
            raf = requestAnimationFrame(handleResize)
        })

        const children = el.childNodes as NodeListOf<HTMLElement>
        children.forEach(childNode => {
            observer.observe(childNode)
        })

        return () => {
            if (raf) {
                cancelAnimationFrame(raf)
            }
            if (observer) {
                observer.disconnect()
            }
        }
    }, [handleResize, isWaterfall, records])

    return (
        <div>
            <Root
                ref={mergeRefs([innerRef, ref])}
                layout={layout}
                cols={cols}
                gap={gap}
                style={{ height: isWaterfall ? maxHeightColumn && `${(maxHeightColumn - gap * baseRem) / scale}px` : undefined }}
            >
                {records.map((record, index) => (
                    <Item
                        key={record.id}
                        title={title}
                        viewId={viewId}
                        pointer={pointer}
                        record={record}
                        records={records}
                        blockData={blockData}
                        showAction={showAction}
                        onTriggerAction={action => onRecordClickedActionTrigger?.(action, record)}
                        onClick={ev => {
                            // 如果内部已有动作触发，则不执行操作
                            if (!checkActionEvent(ev)) {
                                return
                            }
                            if (actions.recordClicked.customized) {
                                if (actions.recordClicked.action && readonly) {
                                    onRecordClickedActionTrigger?.(actions.recordClicked.action, record)
                                }
                            } else {
                                blockData.config.canViewRecord && onRecordClick?.(record.id)
                            }
                        }}
                        data={{
                            id: record.id,
                            width: childWidth,
                            type: 'container',
                            disabled: index !== 0,
                            static: true,
                            virtual: true,
                            data: design ?? {},
                            children: setDeadCopyNode(node.children || [], index === 0, record.id)
                        }}
                        readonly={readonly}
                        gap={index < cols ? 0 : gap * baseRem}
                        isWaterfall={isWaterfall}
                        onFilterNode={onFilterNode}
                    />
                ))}
                {isWaterfall &&
                    Array.from({ length: cols - 1 }).map((_, index) => (
                        <span key={index} data-class="masonry-line-break" style={{ order: index + 1, flexBasis: '100%', width: 0 }} />
                    ))}
            </Root>
        </div>
    )
})
