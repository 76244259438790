import type {
    BasicPageStackMeta,
    BlockAbstract,
    BlockRuntimeState,
    Device,
    FilterFormType,
    FormContainerBlockAbstract,
    PageNode,
    RecordData,
    ViewBlockAbstract
} from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'
import { reduce } from 'rambda'

import type { FlowLayoutNode } from '../components'

/** 栅格格子数 */
export const FLOW_LAYOUT_NODE_ROWS = 12
/** 栅格高度单位 */
export const FLOW_LAYOUT_NODE_HEIGHT = 10

const filterNilLayoutNode = (node: FlowLayoutNode | undefined): node is FlowLayoutNode => {
    return !!node
}

/** 将页面节点转换为栅格节点格式 */
export function transformNode2FlowLayoutNode(
    nodes: PageNode[],
    blocks: BlockAbstract[],
    blockRuntimeState: BlockRuntimeState | undefined,
    getIsVisible?: (payload: {
        visibilityFilter?: FilterFormType | undefined
        viewRecord?: RecordData | undefined
        device?: Device[] | undefined
    }) => boolean
): FlowLayoutNode[] {
    function recursion(_nodes: PageNode[], parentIsCustomView?: boolean): FlowLayoutNode[] {
        return _nodes
            .map<FlowLayoutNode | undefined>(n => {
                const block = blocks.find(b => b.id === n.id)

                if (!block) {
                    return undefined
                }
                if (!parentIsCustomView && getIsVisible) {
                    const visible = getIsVisible?.({
                        visibilityFilter: block.config.visibilityFilter,
                        device: block.config.visibilityDevice
                    })
                    if (!visible) {
                        return undefined
                    }
                }

                if (block.type === BlockType.view && block.config.viewType === 'custom') {
                    return {
                        ...n,
                        type: 'custom',
                        data: {
                            // gap: block.config.gap,
                            // direction: block.config.direction
                            ...block.config.design
                        },
                        children: recursion(n.children ?? [], true)
                    }
                }

                if (block.type === BlockType.container) {
                    const view = n.children?.find(item => item.id === blockRuntimeState?.container?.[n.id].currentView)
                    return {
                        ...n,
                        type: 'container',
                        data: block.config.design,
                        children: recursion(view?.children ?? [], parentIsCustomView)
                    }
                }
                return block?.type === BlockType.formContainer
                    ? { ...n, type: 'container', data: block.config.design, children: recursion(n.children ?? [], parentIsCustomView) }
                    : { ...n, type: 'block' }
            })
            .filter(filterNilLayoutNode)
    }
    return recursion(nodes)
}

export function initBlockRuntimeState(stack: BasicPageStackMeta, blocks: BlockAbstract[]) {
    blocks.forEach(block => {
        switch (block.type) {
            case BlockType.container: {
                if (stack.blockRuntimeState.container?.[block.id]?.currentView) {
                    return
                }
                const { container } = stack.blockRuntimeState
                stack.blockRuntimeState.container = {
                    ...container,
                    [block.id]: { ...container?.[block.id], currentView: block.config.viewList[0].id }
                }
                break
            }

            case BlockType.tabs: {
                if (stack.blockRuntimeState.tabs?.[block.id]?.currentTab) {
                    return
                }
                const { tabs } = stack.blockRuntimeState
                stack.blockRuntimeState.tabs = {
                    ...tabs,
                    [block.id]: {
                        ...tabs?.[block.id],
                        currentTab: block.config.baseList?.[0]?.id
                    }
                }
                break
            }

            default: {
                break
            }
        }
    })
}

type FormContainerOfNode = [string, PageNode[]]

export const findFormContainerChildNode = (nodes: PageNode[], blocks: BlockAbstract[]): FormContainerOfNode[] => {
    function flattenNodes(nodes: PageNode[]): PageNode[] {
        const flattenedNodes: PageNode[] = []
        for (const node of nodes) {
            flattenedNodes.push(node)
            if (node.children) {
                flattenedNodes.push(...flattenNodes(node.children))
            }
        }
        return flattenedNodes
    }
    const flatNodes = flattenNodes(nodes)

    const formContainerOfNode: FormContainerOfNode[] = reduce<PageNode, FormContainerOfNode[]>(
        (prev, node) => {
            const block = blocks.find(block => block.id === node.id)
            if (block?.type === BlockType.formContainer) {
                return [...prev, [block.id, []]]
            }
            return prev
        },
        [],
        flatNodes
    )

    function getChildNode(node: PageNode) {
        const flattenedNodes: PageNode[] = []
        for (const node of nodes) {
            const block = blocks.find(block => block.id === node.id)
            if (block?.type !== BlockType.formContainer) {
                flattenedNodes.push(node)
                if (node.children) {
                    flattenedNodes.push(...flattenNodes(node.children))
                }
            }
        }
        return flattenedNodes
    }

    return formContainerOfNode.map(([id]) => {
        const node = flatNodes.find(node => node.id === id)
        return [id, node ? getChildNode(node) : []]
    })
}
/** 查找父级表单容器 */
export const findParentFormBlock = (id: string, blocks: BlockAbstract[]) => {
    let formBlock: FormContainerBlockAbstract | undefined

    const recursion = (tree: PageNode[]) => {
        for (const node of tree) {
            if (formBlock) {
                return
            }

            if (node.id === id) {
                return true
            }

            if (node.children) {
                const res = recursion(node.children)
                if (res) {
                    const block = blocks.find(item => item.id === node.id)
                    if (block && block.type === BlockType.formContainer) {
                        formBlock = block
                        return
                    }
                    return true
                }
            }
        }
    }

    return (nodes: PageNode[]) => {
        recursion(nodes)
        return formBlock
    }
}

/** 查找父级自定义视图 */
export const findParentCustomViewBlock = (id: string, blocks: BlockAbstract[]) => {
    let customViewBlock: ViewBlockAbstract | undefined

    const recursion = (tree: PageNode[]) => {
        for (const node of tree) {
            if (customViewBlock) {
                return
            }

            if (node.id === id) {
                return true
            }

            if (node.children) {
                const res = recursion(node.children)
                if (res) {
                    const block = blocks.find(item => item.id === node.id)
                    if (block && block.type === BlockType.view && block.config.viewType === 'custom') {
                        customViewBlock = block
                        return
                    }
                    return true
                }
            }
        }
    }

    return (nodes: PageNode[]) => {
        recursion(nodes)
        return customViewBlock
    }
}
