import type { TooltipProps } from '@byecode/ui'
import { Tooltip } from '@byecode/ui'
import { useTextOmit } from '@lighthouse/tools'
import * as React from 'react'
import styled from 'styled-components'

const SCxLabelContainer = styled.div`
    max-width: 400px;
    white-space: normal;
    word-wrap: break-word;
`

interface TooltipTextProps extends Omit<TooltipProps, 'children'> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (ref: React.MutableRefObject<any>, isOmit: boolean, range: number, target: number) => React.ReactElement
}

export const TooltipText = React.memo<TooltipTextProps>(({ title = '', disabled, render, ...rest }) => {
    const { ref, isOmit, targetWidth, rangeWidth } = useTextOmit()

    return (
        <Tooltip
            styles={{ target: { overflow: 'hidden' } }}
            disabled={disabled || title === '' || !isOmit}
            title={<SCxLabelContainer>{title}</SCxLabelContainer>}
            {...rest}
        >
            {render(ref, isOmit, rangeWidth, targetWidth)}
            {/* {render(ref, isOmit, rangeWidth, targetWidth)} */}
        </Tooltip>
    )
})
