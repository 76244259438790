import { IconFont } from '@byecode/ui'
import type { ViewField } from '@lighthouse/core'
import type { ResizeOffset } from '@lighthouse/shared'
import { FieldIcon, Resize } from '@lighthouse/shared'
import { usePopupState } from 'material-ui-popup-state/hooks'
import React, { useCallback, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'

export interface AdvancedTableFieldProps {
    field: ViewField
    required?: boolean
    isLast?: boolean
    isPrimaryField?: boolean
    configurable?: boolean
    isMobile?: boolean
    onResizerChange?: (id: string, width: number) => void
    onChangePreventDrag?: (val: boolean) => void
}

const SCxHeaderFieldItemWrapper = styled.div<{ configurable?: boolean; width?: number; borderRight?: boolean }>`
    flex-shrink: 0;
    position: relative;
    width: ${({ width }) => (width ? `${width}px` : '160px')};
    height: 40px;
    border-right: 1px solid var(--color-gray-200);

    ${props =>
        props.configurable &&
        css`
            &:hover {
                background-color: var(--color-gray-100);
            }
        `}
`

const FieldResizerWrapper = styled.div`
    position: absolute;
    height: 100%;
    z-index: 1;
    right: 0;
`

const SCxHeaderFieldItem = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
    padding: 0px 8px;
`

const SCxHeaderLabel = styled.p`
    width: 100%;
    margin-left: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size-normal);
`

export const SCxFieldRequiredTip = styled.span`
    margin-left: 6px;
    color: rgb(236, 118, 113);
`

const SCxTitleFieldFlagWrapper = styled.div`
    display: flex;
    position: absolute;
    top: -2px;
    right: 6px;
    font-size: 18px;
    color: var(--color-gray-300);
    pointer-events: none;
`

export const AdvancedTableField: React.FC<AdvancedTableFieldProps> = ({
    field,
    required,
    isLast,
    isPrimaryField,
    configurable,
    isMobile,
    onResizerChange,
    onChangePreventDrag
}) => {
    const { fieldId, title, type, width = 160 } = field
    const initWidthRef = useRef(width)
    const headerFieldWrapperRef = useRef<HTMLDivElement>(null)

    const handleResize = useCallback(
        (offset: ResizeOffset, resizing: boolean) => {
            const { x } = offset
            const result = Math.max(initWidthRef.current + x, 100)
            onResizerChange?.(fieldId, result)
            onChangePreventDrag?.(resizing)
            if (!resizing) {
                initWidthRef.current = result
            }
        },
        [fieldId, onChangePreventDrag, onResizerChange]
    )

    return (
        <SCxHeaderFieldItemWrapper ref={headerFieldWrapperRef} configurable={configurable} width={width}>
            <SCxHeaderFieldItem>
                {/* <FieldIcon id={fieldId} type={type} /> */}
                <SCxHeaderLabel>
                    {title}
                    {/* 高级表格表头显示该字段是否必填 */}
                    {required && <SCxFieldRequiredTip>*</SCxFieldRequiredTip>}
                </SCxHeaderLabel>
                {isPrimaryField && (
                    <SCxTitleFieldFlagWrapper>
                        <IconFont type="TitleProperty" />
                    </SCxTitleFieldFlagWrapper>
                )}
                {!isMobile && (
                    <FieldResizerWrapper>
                        <Resize onChange={handleResize} />
                    </FieldResizerWrapper>
                )}
            </SCxHeaderFieldItem>
        </SCxHeaderFieldItemWrapper>
    )
}
