import { IconFont, Tooltip } from '@byecode/ui'
import type { ActionIconProps } from '@mantine/core'
import { Menu, Text } from '@mantine/core'
import type { Editor } from '@tiptap/react'
import classNames from 'classnames'
import React, { forwardRef, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useTipTapContext } from '../Context'
import { DropdownActionButton } from './styles'
import type { ToolbarProps } from './Toolbar'

const HeadingText = styled.span`
    font-size: 12px;
    white-space: nowrap;
`

type HeadingOptions = {
    name: string
    label: string
    icon?: React.ReactNode
    hotKey?: string
    level?: 1 | 2 | 3
}

const HEADING_OPTIONS: HeadingOptions[] = [
    { name: 'paragraph', label: '正文', icon: <IconFont type="BlockText" /> },
    { name: 'heading', label: '一级标题', icon: <IconFont type="BlockH1" />, level: 1, hotKey: '⌘+Option+1' },
    { name: 'heading', label: '二级标题', icon: <IconFont type="BlockH2" />, level: 2, hotKey: '⌘+Option+2' },
    { name: 'heading', label: '三级标题', icon: <IconFont type="BlockH3" />, level: 3, hotKey: '⌘+Option+3' },
    { name: 'taskList', label: '待办列表', icon: <IconFont type="BlockTodo" />, hotKey: '⌘+Shift+9' },
    { name: 'orderedList', label: '有序列表', icon: <IconFont type="BlockNumList" />, hotKey: '⌘+Shift+7' },
    { name: 'bulletList', label: '无序列表', icon: <IconFont type="BlockBulletedList" />, hotKey: '⌘+Shift+8' }
]

const EXTRA_OPTIONS = new Set(['taskList', 'bulletList', 'orderedList'])

interface HeadingButtonProps extends ActionIconProps {
    editor: Editor | null
    isActive: (option: HeadingOptions) => boolean | undefined
}

const HeadingButton = forwardRef<HTMLButtonElement, HeadingButtonProps>((props, ref) => {
    const { isActive, editor, className, ...rest } = props

    // 可能会有性能问题
    let activeOption = HEADING_OPTIONS[0]

    for (const option of HEADING_OPTIONS) {
        if (isActive(option)) {
            activeOption = option
            break
        }
    }

    const notActive = activeOption.name === 'paragraph'

    return (
        <DropdownActionButton ref={ref} {...rest} className={classNames({ active: !notActive, className })}>
            <HeadingText>{activeOption.label}</HeadingText>
            <IconFont size={10} type="ArrowDownSmallGray" color="var(--color-gray-500)" />
        </DropdownActionButton>
    )
})

const MenuItem: typeof Menu.Item = styled(Menu.Item)`
    line-height: 16px;
    padding: 8px 16px;
    color: var(--color-black);
    &:hover {
        background: rgba(56, 56, 56, 0.05);
    }
    &.active {
        background: rgba(56, 56, 56, 0.05);
        &:hover {
            background: rgba(56, 56, 56, 0.1);
        }
    }
`

interface HeadingMenuProps extends Pick<ToolbarProps, 'shrink'> {
    taskList?: boolean
    orderedList?: boolean
    bulletList?: boolean
}

export const HeadingMenu = ({ shrink, taskList, bulletList, orderedList }: HeadingMenuProps) => {
    const editor = useTipTapContext()

    const isActive = useCallback(
        (option: HeadingOptions) => {
            switch (option.name) {
                case 'heading': {
                    return editor?.isActive('heading', { level: option.level })
                }
                case 'paragraph': {
                    return false
                }
                default: {
                    return editor?.isActive(option.name)
                }
            }
        },
        [editor]
    )

    const handleClickItem = useCallback(
        (option: HeadingOptions) => {
            switch (option.name) {
                // case 'paragraph': {
                //     editor?.chain().focus().setParagraph().run()
                //     break
                // }
                case 'heading': {
                    if (option.level) {
                        editor?.chain().focus().setHeading({ level: option.level }).run()
                    }
                    break
                }
                case 'taskList': {
                    editor?.chain().focus().toggleTaskList().run()
                    break
                }

                case 'orderedList': {
                    editor?.chain().focus().toggleOrderedList().run()
                    break
                }
                case 'bulletList': {
                    editor?.chain().focus().toggleBulletList().run()
                    break
                }

                default: {
                    editor?.chain().focus().setParagraph().run()
                    break
                }
            }
        },
        [editor]
    )

    const options = useMemo(() => {
        const opts = shrink ? HEADING_OPTIONS : HEADING_OPTIONS.filter(opt => !EXTRA_OPTIONS.has(opt.name))
        if (shrink) {
            return opts.filter(opt => {
                if (opt.name === 'taskList' && !taskList) {
                    return false
                }
                if (opt.name === 'bulletList' && !bulletList) {
                    return false
                }
                // eslint-disable-next-line sonarjs/prefer-single-boolean-return
                if (opt.name === 'orderedList' && !orderedList) {
                    return false
                }
                return true
            })
        }

        return opts
    }, [bulletList, orderedList, shrink, taskList])

    return (
        <Menu withinPortal width={165} shadow="md" position="bottom" middlewares={{ flip: false, shift: true }}>
            <Menu.Target>
                <HeadingButton isActive={isActive} editor={editor} />
            </Menu.Target>
            <Menu.Dropdown px={0}>
                {options.map(item => (
                    <Tooltip
                        key={item.label}
                        title={
                            <div style={{ textAlign: 'center' }}>
                                <Text>{item.label}</Text>
                                <Text color="var(--color-gray-400)">{item.hotKey}</Text>
                            </div>
                        }
                        placement="right"
                    >
                        <MenuItem
                            icon={item.icon}
                            className={classNames({
                                active: isActive(item)
                            })}
                            onClick={() => handleClickItem(item)}
                        >
                            {item.label}
                        </MenuItem>
                    </Tooltip>
                ))}
            </Menu.Dropdown>
        </Menu>
    )
}
