import { backgroundTransitionOnClick, Button, IconFont } from '@byecode/ui'
import type { DateField, DTDate, Field } from '@lighthouse/core'
import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHotkeys } from 'react-hotkeys-hook'
import styled, { css } from 'styled-components'

import { InnerTypeMapByFieldType } from '../../constants'
import * as SC from './styles'

interface DateConfigViewProps {
    children?: React.ReactNode
    config: DateField
    onOk?: (data: Field) => void
    onCancel: () => void
}

export const SCxCheckButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const SCxCheckButton = styled.div<{ checked?: boolean }>`
    display: inline-flex;
    align-items: center;
    padding: 2px 4px;
    margin-right: 6px;
    border: 1px dashed var(--color-gray-4);
    border-radius: 4px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-6);
    cursor: pointer;
    ${({ checked }) =>
        checked
            ? css`
                  border-style: solid;
                  border-color: var(--color-main);
                  color: var(--color-main);
              `
            : css`
                  ${backgroundTransitionOnClick()}
              `}
`

export const SCxCheckButtonIcon = styled(IconFont)`
    display: inline-flex;
    align-items: center;
    margin-right: 4px;
`

const DEFAULT_DATE_FORMAT = 'yyyy/MM/dd'
const DEFAULT_TIME_FORMAT = 'HH:mm'

const formatData = [
    { label: '年/月/日 00:00', value: 'yyyy/MM/dd HH:mm' },
    { label: '年-月-日 00:00', value: 'yyyy-MM-dd HH:mm' },
    { label: '日-月-年 00:00', value: 'dd/MM/yyyy HH:mm' },
    { label: '年/月/日', value: 'yyyy/MM/dd' },
    { label: '年-月-日', value: 'yyyy-MM-dd' },
    { label: '日/月/年', value: 'dd/MM/yyyy' },
    { label: '年/月', value: 'yyyy/MM' },
    { label: '年-月', value: 'yyyy-MM' }
]

const DateConfigView: React.FC<DateConfigViewProps> = ({ config, onOk, onCancel }) => {
    const { watch, control, getValues } = useForm<DTDate>({
        defaultValues: {
            ...config?.date
        }
    })
    // const [showTime, dateFormat = DEFAULT_DATE_FORMAT, timeFormat = DEFAULT_TIME_FORMAT] = watch(['timed', 'dateFormat', 'timeFormat'])

    // const dateTip = useMemo(() => {
    //     const formatStr = `${dateFormat} ${showTime ? timeFormat : ''}`
    //     return lightFormat(startOfToday(), formatStr)
    // }, [dateFormat, showTime, timeFormat])

    const handleOk = useCallback(() => {
        onOk?.({ ...config, type: 'date', date: getValues(), innerType: InnerTypeMapByFieldType['date'] })
    }, [config, getValues, onOk])

    useHotkeys(
        'Enter',
        () => {
            handleOk()
        },
        { enableOnTags: ['INPUT'] }
    )

    return (
        <>
            <SC.FormItemWrapper>
                <SC.FieldConfigHeader>日期格式</SC.FieldConfigHeader>
                <SC.FormItemLimitCell>
                    <Controller
                        control={control}
                        name="format"
                        defaultValue={DEFAULT_DATE_FORMAT}
                        render={({ field }) => {
                            return <SC.FieldConfigSelect {...field} options={formatData} styles={{ root: { width: '100%' } }} />
                        }}
                    />
                </SC.FormItemLimitCell>
            </SC.FormItemWrapper>
            <SC.ActionWrapper>
                <Button style={{ marginRight: 8 }} onClick={onCancel}>
                    取消
                </Button>
                <Button type="primary" onClick={handleOk}>
                    确定
                </Button>
            </SC.ActionWrapper>
            {/* <SC.ConfigPreviewTip>示例：{dateTip}</SC.ConfigPreviewTip> */}
        </>
    )
}

export default DateConfigView
