import { findParentScroller, mergeRefs } from '@lighthouse/tools'
import { clone } from 'rambda'
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useReducer, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useKey, useLatest } from 'react-use'

import type { FlowLayoutNode } from '../types'
import { isRefWithReactElement, noop } from '../utils/common'
import { ScrollContainerControl } from '../utils/scroll'
import type { DragPayload } from './store'
import { Action, reducer } from './store'
import type { Coordinates, Detection, InteractionState, OverDescriptor, ResizeEventsOptions } from './types'

// 内部拖拽抽象事件
export interface SensorContextValue {
    onStart: (coordinates: Coordinates, node: FlowLayoutNode, rect: DOMRect) => void
    onMove: (coordinates: Coordinates) => void
    onEnd: (coordinates: Coordinates, active: FlowLayoutNode, over: OverDescriptor | null) => void
    onCancel: () => void
}
const SensorContext = React.createContext<SensorContextValue>({
    onStart: noop,
    onMove: noop,
    onEnd: noop,
    onCancel: noop
})
export const SensorProvider = SensorContext.Provider
export const useSensorContext = () => React.useContext(SensorContext)

// 容器
const SortableContext = React.createContext<{ items: string[]; parentId?: string }>({ items: [] })
export const useSortableContext = () => React.useContext(SortableContext)
export function SortableContainerContext({ items, parentId, children }: { items: string[]; parentId?: string; children: React.ReactNode }) {
    const value = React.useMemo(() => ({ items, parentId }), [parentId, items])

    return <SortableContext.Provider value={value}>{children}</SortableContext.Provider>
}

// resize事件
export interface ResizeEventsContextValue {
    isResizing: boolean
    onResizeStart?: (options: ResizeEventsOptions) => void
    onResizing?: (options: ResizeEventsOptions) => void
    onResizeEnd?: (options: Omit<ResizeEventsOptions, 'coordinates'>) => void
    onFillSize?: (nodeId: string) => void
}
const ResizeEventsContext = React.createContext<ResizeEventsContextValue>({ isResizing: false })
export const ResizeEventsProvider = ResizeEventsContext.Provider
export const useResizeEventsContext = () => React.useContext(ResizeEventsContext)

export type SortableMonitorContextValue = InteractionState & {
    draggableNodes: React.MutableRefObject<Map<string, { node: HTMLElement; data: FlowLayoutNode }>>

    // 选中
    selectedId?: string
    onSelectedIdChange?: (id: string | undefined) => void

    // 框选
    boxSelectionIds?: string[]
    onBoxSelectionIdsChange?: (ids: string[]) => void

    onDuplicate?: (id: string) => void
    onRemove?: (id: string) => void
    onSwapNode?: (from: string, to: string) => void

    triggerActionId: React.MutableRefObject<string | null>
    setTriggerActionId: (id: string | null) => void

    activeRect: React.MutableRefObject<DOMRect | null>
    detection?: Detection
    onDragStart?: (active: FlowLayoutNode) => void
    onDragEnd?: (coordinates: Coordinates, active: FlowLayoutNode, over: OverDescriptor | null) => void
    onDragCancel?: () => void
}

// 拖拽监控事件
export interface SortableMonitorProps
    extends Omit<
        SortableMonitorContextValue,
        keyof InteractionState | 'triggerActionId' | 'setTriggerActionId' | 'draggableNodes' | 'activeRect'
    > {
    children: React.ReactNode
    dndSensorRef?: React.Ref<SensorContextValue>
}

const SortableMonitorContext = React.createContext<SortableMonitorContextValue>({
    draggableNodes: { current: new Map() },
    triggerActionId: { current: null },
    setTriggerActionId: () => void 0,
    activeRect: { current: null },
    activeId: null,
    overDescriptor: null,
    initialX: 0,
    initialY: 0,
    x: 0,
    y: 0
})
export const SortableMonitorProvider = ({
    children,

    dndSensorRef,

    onDragStart,
    onDragEnd,
    onDragCancel,
    detection,

    selectedId,
    onSelectedIdChange,
    boxSelectionIds,
    onBoxSelectionIdsChange,

    onDuplicate,
    onRemove,
    onSwapNode
}: SortableMonitorProps) => {
    const triggerActionId = useRef<string | null>(null)
    const setTriggerActionId = useCallback((id: string | null) => {
        triggerActionId.current = id
    }, [])
    const [internalState, dispatch] = useReducer<React.Reducer<InteractionState, DragPayload>>(reducer, {
        x: 0,
        y: 0,
        initialX: 0,
        initialY: 0,
        activeId: null,
        overDescriptor: null
    })

    const draggableNodes = useRef<Map<string, { node: HTMLElement; data: FlowLayoutNode }>>(new Map())
    const activeNodeRef = useRef<FlowLayoutNode | null>(null)
    const activeRect = useRef<DOMRect | null>(null)

    const latestProps = useLatest({
        onDragStart,
        onDragEnd,
        onDragCancel,
        detection,

        onDuplicate,
        onRemove
    })
    const latestOver = useLatest(internalState.overDescriptor)

    const scrollHandleRef = useRef<ScrollContainerControl>()

    useEffect(() => {
        const content = internalRef.current
        if (!content) {
            return
        }

        const scrollContainerEl = findParentScroller(content.parentElement)
        if (!scrollContainerEl) {
            return
        }

        scrollHandleRef.current = new ScrollContainerControl(scrollContainerEl)
    }, [])

    const sensorValue: SensorContextValue = useMemo(
        () => ({
            onStart(coordinates, node, rect) {
                window.getSelection()?.removeAllRanges()
                activeNodeRef.current = node
                activeRect.current = rect

                latestProps.current.onDragStart?.(node)
                // latestProps.current.onSelectedIdChange?.(undefined)
                dispatch({
                    type: Action.DragStart,
                    activeId: node.id,
                    initialX: coordinates.x,
                    initialY: coordinates.y
                })
            },
            onMove(coordinates) {
                if (!activeNodeRef.current) {
                    return
                }

                scrollHandleRef.current?.start(coordinates.y)
                dispatch({ type: Action.DragMove, x: coordinates.x, y: coordinates.y })

                const overDescriptor = latestProps.current.detection?.({
                    coordinates,
                    draggableNodes: draggableNodes.current,
                    active: activeNodeRef.current,
                    relativeId: undefined,
                    actualRelativeId: undefined,
                    virtualParentId: undefined,
                    overType: undefined
                })
                if (overDescriptor === undefined) {
                    return
                }
                dispatch({
                    type: Action.DragOver,
                    overDescriptor
                })
            },
            onEnd(coordinates, active, over) {
                if (!activeNodeRef.current) {
                    return
                }

                setTriggerActionId(null)

                scrollHandleRef.current?.stop()
                latestProps.current.onDragEnd?.(coordinates, active, over)
                activeNodeRef.current = null
                activeRect.current = null
                dispatch({ type: Action.DragEnd, overDescriptor: null })
            },
            onCancel() {
                if (!activeNodeRef.current) {
                    return
                }

                setTriggerActionId(null)

                scrollHandleRef.current?.stop()
                latestProps.current.onDragCancel?.()
                activeNodeRef.current = null
                activeRect.current = null
                dispatch({ type: Action.DragCancel })
            }
        }),
        [latestProps, setTriggerActionId]
    )

    const internalRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!internalState.activeId) {
            return
        }
        function move(e: MouseEvent) {
            sensorValue.onMove({ x: e.x, y: e.y })
        }

        document.addEventListener('mousemove', move)

        return () => {
            document.removeEventListener('mousemove', move)
        }
    }, [internalState.activeId, sensorValue])

    useEffect(() => {
        function end(e: MouseEvent) {
            setTriggerActionId(null)
            if (!activeNodeRef.current) {
                return
            }
            sensorValue.onEnd(
                { x: e.x, y: e.y },
                activeNodeRef.current && clone(activeNodeRef.current),
                latestOver.current && clone(latestOver.current)
            )
        }
        document.addEventListener('mouseup', end)

        return () => {
            document.removeEventListener('mouseup', end)
        }
    }, [latestOver, sensorValue, setTriggerActionId])

    // 拖拽碰撞检测
    useEffect(() => {
        const el = internalRef.current
        if (!el) {
            return
        }

        function overHandle(e: MouseEvent) {
            if (!activeNodeRef.current) {
                return
            }
            if (e.target instanceof HTMLElement) {
                const overType = e.target.getAttribute('data-type') as OverDescriptor['target']
                const relativeId = e.target.getAttribute('data-relative-id') || undefined
                const actualRelativeId = e.target.getAttribute('data-actual-relative-id') || undefined
                const virtualParentId = e.target.getAttribute('data-virtual-parent-id') || undefined

                const overDescriptor = latestProps.current.detection?.({
                    active: activeNodeRef.current,
                    coordinates: { x: e.x, y: e.y },
                    draggableNodes: draggableNodes.current,
                    overType,
                    relativeId,
                    actualRelativeId,
                    virtualParentId
                })
                if (overDescriptor === undefined) {
                    return
                }
                dispatch({
                    type: Action.DragOver,
                    overDescriptor
                })
            }
        }

        function outHandle() {
            if (!activeNodeRef.current) {
                return
            }
            dispatch({ type: Action.DragOver, overDescriptor: null })
        }

        el.addEventListener('mouseover', overHandle)
        el.addEventListener('mouseout', outHandle)

        return () => {
            el.removeEventListener('mouseover', overHandle)
            el.removeEventListener('mouseout', outHandle)
        }
    }, [latestProps])

    useKey('Escape', sensorValue.onCancel, undefined, [sensorValue.onCancel])

    const value: SortableMonitorContextValue = useMemo(() => {
        return {
            triggerActionId,
            setTriggerActionId,
            selectedId,
            boxSelectionIds,
            // 有bug，回调不是最新的，原因未知
            // ...latestProps.current,
            onDragStart,
            onDragEnd,
            onDragCancel,
            detection,

            onBoxSelectionIdsChange,
            onSelectedIdChange,
            onDuplicate,
            onRemove,
            onSwapNode,

            ...internalState,
            draggableNodes,
            activeRect
        }
    }, [
        boxSelectionIds,
        detection,
        internalState,
        onBoxSelectionIdsChange,
        onDragCancel,
        onDragEnd,
        onDragStart,
        onDuplicate,
        onRemove,
        onSelectedIdChange,
        onSwapNode,
        selectedId,
        setTriggerActionId
    ])

    useImperativeHandle(dndSensorRef, () => sensorValue, [sensorValue])

    return (
        <SortableMonitorContext.Provider value={value}>
            <SensorProvider value={sensorValue}>
                {isRefWithReactElement(children)
                    ? React.cloneElement(children, {
                          ref: mergeRefs([internalRef, children.ref])
                      })
                    : null}
            </SensorProvider>
        </SortableMonitorContext.Provider>
    )
}
export const useSortableMonitor = () => React.useContext(SortableMonitorContext)
