import { backgroundTransitionOnClick, IconFont } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const Indent = styled.div`
    width: 20px;
`


export const Placeholder = styled.div`
    color: var(--color-gray-400);
`

export const ClearIcon = styled(IconFont)`
    display: none;
`

export const ArrowIcon = styled(IconFont)`
`


export const MultiSelectContent = styled.div<{ open?: boolean, clearable?: boolean }>`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    justify-content: space-between;
    align-items: center;
    color: var(--color-gray-500);
    background-color:  ${({ open }) => open ? 'var(--color-white)' : 'var(--color-gray-100)'};
    border-width: 1.5px;
    border-style: solid;
    border-color: ${({ open }) => open ? 'var(--color-main)' : 'var(--color-gray-100)'};
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;

    &:hover{
        border-color: ${({ open }) => open ? 'var(--color-main)' : 'var(--color-gray-200)'};
        background-color: ${({ open }) => open ? 'var(--color-white)' : 'var(--color-gray-200)'};
    }

    &:hover ${ArrowIcon} {
        display: ${({ clearable }) => (clearable ? 'none' : 'flex')};
    }

    &:hover ${ClearIcon} {
        display: ${({ clearable }) => (clearable ? 'flex' : 'none')};
    }

    &.disabled {
        cursor: not-allowed;
    }
`

export const MultiSelectWrapper = styled.div<{ clearable: boolean }>`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    font-size: var(--font-size-normal);
    /* margin: 8px 14px 8px 14px; */
    border: 1.5px solid var(--color-gray-200);
    border-radius: 5px;
    background-color: var(--color-gray-100);
    cursor: pointer;
    overflow: hidden;

    &:hover ${ArrowIcon} {
        display: ${({ clearable }) => (clearable ? 'none' : 'flex')};
    }

    &:hover ${ClearIcon} {
        display: ${({ clearable }) => (clearable ? 'flex' : 'none')};
    }

    &.disabled {
        cursor: not-allowed;

        ${MultiSelectContent} {
            color: var(--color-gray-300);
        }
    }
`

export const MultiSelectedValue = styled.div`
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const Place = styled.span`
    color: var(--color-gray-400);
`

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const Tag = styled.div<{ background?: string; color?: string }>`
    padding: 0 8px;
    margin: 0 2px;
    border-radius: 100px;
    background-color: ${({ background }) => (background ? `var(${background})` : 'transparent')};
    color: ${({ color }) => (color ? `var(${color})` : 'var(--color-black)')};
    border: ${({ background }) => (background ? '1px solid rgba(0,0,0,0.1)' : 'transparent')};
`

export const MultiSelectOverlay = styled.div``

export const MultiSelectList = styled.div`
    /* width: 220px; */
    width: 100%;
`

export const MultiSelectItem = styled.div<{ color?: string }>`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    font-size: var(--font-size-normal);
    color: var(--color-black);

    cursor: pointer;
    ${backgroundTransitionOnClick}
`

export const MultiSelectBox = styled.div`
    flex: 1;
    overflow: hidden;
`

export const MultiSelectLabel = styled.div<{ background?: string; color?: string }>`
    padding: 0 8px;
    background-color: ${({ background }) => (background ? `var(${background})` : 'transparent')};
    border: ${({ background }) => (background ? '1px solid rgba(0,0,0,0.1)' : 'transparent')};
    color: ${({ color }) => (color ? `var(${color})` : 'var(--color-black)')};
    border-radius: 100px;
    max-width: 100%;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const MultiSelectItemIcon = styled(IconFont)`
    margin-right: 6px;
`

export const MultiSelectExtra = styled.div``

export const NoData = styled.div`
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: var(--color-black);
    font-size: 14px;
`
