import { IconFont } from '@byecode/ui'
import { ActionIcon } from '@mantine/core'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 8px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
`
const Button: typeof ActionIcon = styled(ActionIcon)`
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    color: var(--color-black);
    &[disabled] {
        border-color: transparent;
        background-color: unset;
    }
    &:hover {
        background-color: var(--color-gray-100);
    }
`

type ActionType =
    | {
          disabled?: boolean
          handler?: () => void
      }
    | false

interface FileControlProps {
    zoomIn?: ActionType
    zoomOut?: ActionType
    rotate?: ActionType
    // download?: ActionType
}

export const FileControl = forwardRef<HTMLDivElement, FileControlProps>(({ zoomIn, zoomOut, rotate }, ref) => {
    if (![zoomIn, zoomOut, rotate].every(Boolean)) {
        return null
    }

    return (
        <Container ref={ref}>
            {zoomOut ? (
                <Button disabled={zoomOut.disabled} onClick={zoomOut.handler}>
                    <IconFont type="Bigger" />
                </Button>
            ) : null}

            {zoomIn ? (
                <Button disabled={zoomIn.disabled} onClick={zoomIn.handler}>
                    <IconFont type="Smaller" />
                </Button>
            ) : null}

            {rotate ? (
                <Button disabled={rotate.disabled} onClick={rotate.handler}>
                    <IconFont type="ArrowCounterClockwise" />
                </Button>
            ) : null}

            {/* {download ? (
                <Button disabled={download.disabled} onClick={download.handler}>
                    <IconFont type="ArrowLineDown" />
                </Button>
            ) : null} */}
        </Container>
    )
})
