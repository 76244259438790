import '@lighthouse/shared/styles/base.scss'
import '@byecode/ui/styles/variable.scss'
import '@byecode/ui/styles/theme.scss'
import '@lighthouse/shared/styles/index.scss'
import './styles/index.scss'
import './polyfill'
import './i18'

import { isIosPlatform, isWechatBrowser } from '@lighthouse/tools'
import React from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './App'
import { initSentry } from './sentry'
import { FIRST_URL_FOR_IOS_WECHAT } from './utils/wechat'

initSentry()

const container = document.querySelector('#root') as HTMLElement
if (isWechatBrowser() && isIosPlatform()) {
    const firstUrl4IosWechat = window.location.href.split('#')[0]
    sessionStorage.setItem(FIRST_URL_FOR_IOS_WECHAT, firstUrl4IosWechat)
}

const root = createRoot(container)
root.render(<App />)
