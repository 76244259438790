export type Transform = {
    x: number
    y: number
    scaleX: number
    scaleY: number
}

export function applyTransform(transform: Transform | null) {
    return transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined
}

export function getClientRectOmitTransform(node: Element) {
    const rect = node.getBoundingClientRect()
    const { transform } = window.getComputedStyle(node)

    const obj = parseTransform(transform)
    if (obj) {
        return {
            x: rect.x - obj.x,
            y: rect.y - obj.y,
            left: rect.left - obj.x,
            right: rect.right,
            top: rect.top - obj.y,
            bottom: rect.bottom,
            width: rect.width,
            height: rect.height
        }
    }

    return rect
}

export function parseTransform(transform: string) {
    if (transform.startsWith('matrix3d(')) {
        const transformArray = transform.slice(9, -1).split(/, /)

        return {
            x: Number(transformArray[12]),
            y: Number(transformArray[13]),
            scaleX: Number(transformArray[0]),
            scaleY: Number(transformArray[5])
        }
    }
    if (transform.startsWith('matrix(')) {
        const transformArray = transform.slice(7, -1).split(/, /)

        return {
            x: Number(transformArray[4]),
            y: Number(transformArray[5]),
            scaleX: Number(transformArray[0]),
            scaleY: Number(transformArray[3])
        }
    }

    return null
}
