import type { StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React from 'react'

import { useStyles } from './Anchor.styles'

type AnchorStylesNames = 'root'

interface AnchorProps extends StyleComponentProps<AnchorStylesNames>, React.ComponentPropsWithoutRef<'a'> {
    to?: string
}

export const Anchor = React.forwardRef<HTMLAnchorElement, AnchorProps>(
    ({ className, classNames, styles, unstyled, children, to, ...rest }, ref) => {
        const { classes } = useStyles({}, { name: 'anchor', styles, classNames, unstyled })
        return (
            <a className={clsx(classes.root, className)} {...rest} href={to} ref={ref}>
                {children}
            </a>
        )
    }
)
