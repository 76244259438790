import type { FieldADTValue, NumberField, NumberValue } from '@lighthouse/core'
import { isEmpty } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { scientificNotationToString } from '../../utils'
import { Input } from '../CellInputField'
import type { TableCellShape } from '../TableCell/type'

const NumberInput = styled(Input)`
    border-color: transparent;
    outline: none;
    height: 100%;

    & input {
        margin: 8px 7px 5px 4px;
        height: 20px !important;
        min-height: 20px;
        line-height: 20px;
        box-sizing: border-box;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none !important;
            margin: 0;
        }
    }
`

interface NumberValueEditorProps {
    recordId: string
    data?: NumberValue
    field: NumberField
    shape?: TableCellShape
    focusOutLineColor?: string
    autoFocus?: boolean
    placeholder?: string
    enableBackgroundColor?: boolean
    onCellChange?: (recordId: string, field: FieldADTValue) => Promise<boolean>
}
export const NumberValueEditor: React.FC<NumberValueEditorProps> = ({
    recordId,
    data,
    field,
    focusOutLineColor,
    autoFocus,
    placeholder,
    enableBackgroundColor,
    shape = 'window',
    onCellChange
}) => {
    const [value, setValue] = useState<string>(scientificNotationToString(data))
    const inputRef = useRef<HTMLInputElement>(null)
    const [isComposition, setIsComposition] = useState<boolean | undefined>()
    const textStyles = { fontSize: 'var(--font-size-normal)' }
    // const defaultAccuracy = 2
    const formatNumber = useCallback((num: string) => {
        const ridReg = /^\d|-|\.$/u
        const dataNumber = num
        const judgeNum = [...dataNumber].filter(item => ridReg.test(item)).join('')
        if (isEmpty(judgeNum)) {
            return ''
        }
        const showNumArr = judgeNum
        return showNumArr ?? ''
    }, [])

    // const handleChange = useCallback(
    //     (ev: React.ChangeEvent<HTMLInputElement>) => {
    //         const val = ev.target.value
    //         const num = formatNumber(val)
    //         onCellChange?.(dsId, recordId, { ...field, type: 'number', value: Number(num) })
    //     },
    //     [dsId, field, formatNumber, onCellChange, recordId]
    // )
    const handleCompositionStart = useCallback(() => {
        setIsComposition(false)
    }, [])

    const handleCompositionEnd = useCallback(() => {
        setIsComposition(true)
    }, [])

    const handleInputChange = useCallback(
        /**  @ts-expect-error event error */
        event => {
            if (typeof onCellChange !== 'function') {
                return
            }
            // if (!(isComposition ?? true)) {
            //     return
            // }
            const val = event.target.value
            setValue(scientificNotationToString(val))
            const num = val
            const value = num ? Number(num) : ''
            onCellChange?.(recordId, { ...field, type: 'number', value: scientificNotationToString(value) })
        },
        [field, onCellChange, recordId]
    )

    const numberInputProps = useMemo(() => {
        if (shape === 'form') {
            return {
                value,
                onChange: handleInputChange
            }
        }
        return {
            defaultValue: value
        }
    }, [handleInputChange, shape, value])

    const handleIgnoreScroll = useCallback((e: WheelEvent) => {
        e.preventDefault()
    }, [])

    useEffect(() => {
        const inputEl = inputRef.current
        if (!inputEl || shape === 'form') {
            return
        }
        inputEl.addEventListener('compositionstart', handleCompositionStart)
        inputEl.addEventListener('compositionend', handleCompositionEnd)
        inputEl.addEventListener('change', handleInputChange)
        inputEl.addEventListener('wheel', handleIgnoreScroll)

        return () => {
            inputEl.removeEventListener('compositionstart', handleCompositionStart)
            inputEl.removeEventListener('compositionend', handleCompositionEnd)
            inputEl.removeEventListener('change', handleInputChange)
            inputEl.removeEventListener('wheel', handleIgnoreScroll)
        }
    }, [handleCompositionEnd, handleCompositionStart, handleIgnoreScroll, handleInputChange, shape])

    return (
        <NumberInput
            ref={inputRef}
            autoFocus={autoFocus}
            focusOutLineColor={focusOutLineColor}
            enableBackgroundColor={enableBackgroundColor}
            {...numberInputProps}
            type="number"
            style={textStyles}
            placeholder={placeholder}
        />
    )
}
