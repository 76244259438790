import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    font-size: var(--font-size-normal);
    border-radius: inherit;
    padding: 8px 0px;

    > * {
        background-color: transparent;
    }
`

export const Wrapper = styled.div`
    padding: 0px 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    overflow: hidden;
`

export const TagWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
`
