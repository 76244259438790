import type { SchemaProtocol, SelectedMode, TableColumnWidth, TableViewOptions, ViewField } from '@lighthouse/core'
// import { ViewSettingCache } from '@lighthouse/shared'
import { getCachedItem, setCachedItem } from '@lighthouse/tools'
import isDeepEqual from 'fast-deep-equal'
import { clone } from 'rambda'
import React, { memo, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { TableChecker } from './TableChecker'
import { TableField } from './TableField'
import { TableHeaderChecker } from './TableHeaderChecker'
import { getAvatarWidth, getDesignStyleMap } from './utils'

export interface TableHeaderProps {
    id: string
    checkable?: boolean
    isMobile?: boolean
    schema: SchemaProtocol['schema']
    viewFields: ViewField[]
    allRecordSelected?: boolean
    designConfig: TableViewOptions
    onAllRecordSelect?: (selected: boolean) => void
    onChangePreventDrag?: (val: boolean) => void
    onSelectModeChange?: (mode?: SelectedMode) => void
    onTableColumnWidthChange?: (val: TableColumnWidth) => void
}

// ${({ ceiling, parentWidth, scrollLeft }) =>
//         ceiling
//             ? css`
//                   position: absolute;
//                   top: 0;
//                   /* left: ${scrollLeft ? `${scrollLeft}px` : 0}; */
//                   z-index: 100;
//                   width: ${parentWidth ? `${parentWidth - 12}px` : '100%'};
//                   overflow: hidden;
//               `
//             : css`
//                   position: sticky;
//                   top: 0;
//               `}
/* ${({ scrollTop }) =>
        scrollTop &&
        css`
            transform: ${`translate3d(0px, ${scrollTop}px, 0px)`};
        `} */

export const SCxTableHeader = styled.div`
    display: flex;
    width: fit-content;
    overflow-y: visible;
    position: sticky;
    z-index: 5;
    top: 0;
    min-width: 100%;
`

export const SCxTableHeaderContent = styled.div<{ isClassic?: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    background-color: ${({ isClassic }) => (isClassic ? 'var(--color-gray-100)' : 'var(--color-white)')};
`

const SCxTableHeaderAvatar = styled.div<{ isMobile?: boolean; width: number; isClassic?: boolean }>`
    width: ${({ width }) => `${width}px`};
    background-color: ${({ isClassic }) => (isClassic ? 'var(--color-gray-100)' : 'var(--color-white)')};
    ${({ isMobile }) =>
        isMobile
            ? css`
                  position: sticky;
                  left: 8px;
                  top: 0;
                  z-index: 1;
              `
            : null}
`

export const SCxTableChecker = styled(TableHeaderChecker)``

const SCxRemain = styled.div`
    width: 8px;
    height: 100%;
`

const TableHeader: React.FC<TableHeaderProps> = ({
    id: blockId,
    checkable,
    isMobile,
    schema,
    viewFields,
    allRecordSelected,
    designConfig,
    onAllRecordSelect,
    onChangePreventDrag,
    onSelectModeChange,
    onTableColumnWidthChange
}) => {
    const { designStyle, listAvatar, isShowListAvatar } = designConfig
    const { isHeaderStripe } = useMemo(() => getDesignStyleMap(designStyle), [designStyle])
    const handleResizerChange = useCallback(
        (id: string, columnWidth: number) => {
            onTableColumnWidthChange?.({ [id]: columnWidth })
        },
        [onTableColumnWidthChange]
    )
    const avatarWidth = getAvatarWidth(isMobile)
    const avatarField = useMemo(() => {
        if (!isShowListAvatar || !listAvatar) {
            return
        }
        return schema?.[listAvatar]
    }, [isShowListAvatar, listAvatar, schema])
    const fieldColumns = useMemo(() => viewFields.filter(item => item.visible), [viewFields])

    return (
        <SCxTableHeader className="tableHeader">
            <SCxTableChecker
                disableCheck={isMobile}
                checkable={checkable}
                isStripe={isHeaderStripe}
                checked={allRecordSelected}
                onSelectModeChange={onSelectModeChange}
                onChange={onAllRecordSelect}
            />
            <SCxTableHeaderContent isClassic={isHeaderStripe}>
                {avatarField && (
                    <SCxTableHeaderAvatar width={avatarWidth} isMobile={isMobile} isClassic={isHeaderStripe}>
                        <TableField
                            field={
                                {
                                    ...avatarField,
                                    fieldId: avatarField.id,
                                    title: avatarField.name,
                                    width: avatarWidth,
                                    visible: true
                                } as ViewField
                            }
                            isMobile={isMobile}
                            onResizerChange={handleResizerChange}
                            onChangePreventDrag={onChangePreventDrag}
                        />
                    </SCxTableHeaderAvatar>
                )}
                {fieldColumns.map((field, index) => {
                    const { fieldId } = field
                    if (isMobile && index === 0) {
                        return null
                    }
                    if (isShowListAvatar && listAvatar === fieldId) {
                        return null
                    }
                    return (
                        field && (
                            <TableField
                                key={fieldId}
                                field={field}
                                isMobile={isMobile}
                                onResizerChange={handleResizerChange}
                                onChangePreventDrag={onChangePreventDrag}
                            />
                        )
                    )
                })}
                <SCxRemain />
            </SCxTableHeaderContent>
        </SCxTableHeader>
    )
}

export default memo(TableHeader, isDeepEqual)
