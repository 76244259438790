import { backgroundTransitionOnClick, Empty, IconFont, tinyButtons } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

import type { MessageListData, MessageType } from '../../types'
import type { NotificationStatusProps } from './NotificationMoment'
import { NotificationStatus } from './NotificationMoment'

interface NotificationLeftPanelListProps {
    data?: MessageListData[]
    activeIndex?: number
    onActive?: (index: number) => void
}

const SCxMessageListWrapper = styled.div`
    max-height: 100%;
    overflow-y: auto;

    ${tinyButtons}
`

const SCxMessageWrapper = styled.div<{ active?: boolean }>`
    display: flex;
    padding: 18px 16px;
    font-size: var(--font-size-normal);
    cursor: pointer;
    align-items: center;
    user-select: none;

    &:not(:last-child) {
        border-bottom: 1px solid var(--color-gray-200);
    }

    ${({ active }) => active && `background-color: var(--color-gray-100);`}

    ${backgroundTransitionOnClick}
`

const SCxMessageIconWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin-right: 8px;
    border-radius: 6px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
`

const SCxMessageIcon = styled(IconFont)`
    color: var(--color-main);
    font-size: 20px;
`

const SCxMessageContentWrapper = styled.div`
    flex: 1;
`

const SCxMessageContentTitle = styled.div`
    margin-bottom: 4px;
`

const SCxMessageContentDescription = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
`

const approveResultMap: Record<MessageType, string> = {
    CARBON_COPY: '',
    APPROVAL: '',
    APPROVE_PASSED: '已通过',
    APPROVE_REFUSED: '已拒绝',
    STATION_MESSAGE: ''
}

const approveResultTextMap: Record<MessageType, NotificationStatusProps['status']> = {
    CARBON_COPY: 'error',
    APPROVAL: 'error',
    APPROVE_PASSED: 'success',
    APPROVE_REFUSED: 'error',
    STATION_MESSAGE: 'success'
}

export const NotificationLeftPanelList: React.FC<NotificationLeftPanelListProps> = ({ data, activeIndex, onActive }) => {
    const handleClick = (index: number) => {
        onActive?.(index)
    }

    const renderMessageContent = (data: MessageListData) => {
        if (data.type === 'STATION_MESSAGE') {
            const { createdTime, appName, title } = data

            return (
                <>
                    <SCxMessageIconWrapper>
                        <SCxMessageIcon type="Bell" style={{ color: 'var(--color-black)' }} />
                    </SCxMessageIconWrapper>
                    <SCxMessageContentWrapper>
                        <SCxMessageContentTitle>{title}</SCxMessageContentTitle>
                        <SCxMessageContentDescription>
                            {createdTime} · 来自 {appName}
                        </SCxMessageContentDescription>
                    </SCxMessageContentWrapper>
                </>
            )
        }

        const { id, type, username, createdTime, appName } = data
        const isApproval = type === 'APPROVAL'
        const isCC = type === 'CARBON_COPY'
        const isResult = type.includes('APPROVE_')
        return (
            <>
                <SCxMessageIconWrapper>
                    <SCxMessageIcon type="Approval" style={{ color: isApproval ? 'var(--color-main)' : 'var(--color-black)' }} />
                    {isResult && (
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                borderRadius: '50%',
                                border: '1px solid var(--color-white)'
                            }}
                        >
                            <NotificationStatus status={approveResultTextMap[type]} />
                        </div>
                    )}
                </SCxMessageIconWrapper>
                <SCxMessageContentWrapper>
                    <SCxMessageContentTitle>
                        {isCC && <>抄送给我：</>}
                        {isResult && (
                            <>
                                您发起的审批 <span style={{ fontWeight: 'var(--font-weight-bold)' }}>{approveResultMap[type]}</span>
                            </>
                        )}
                        {isApproval && <>{username} 发起了申请</>}
                    </SCxMessageContentTitle>
                    <SCxMessageContentDescription>
                        {createdTime} · 来自 {appName}
                    </SCxMessageContentDescription>
                </SCxMessageContentWrapper>
            </>
        )
    }

    return (
        <SCxMessageListWrapper>
            {data?.length === 0 && (
                <Empty
                    styles={{ root: { marginTop: 200 } }}
                    description="暂无消息"
                    icon={<IconFont type="Bell" size={24} color="var(--color-gray-500)" style={{ marginBottom: 8 }} />}
                />
            )}
            {data?.map((d, i) => (
                <SCxMessageWrapper key={d.id} active={activeIndex === i} onClick={() => handleClick(i)}>
                    {renderMessageContent(d)}
                </SCxMessageWrapper>
            ))}
        </SCxMessageListWrapper>
    )
}
