import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

export const useStyles = createStyles(() => ({
    selectDropdown: css({
        display: 'flex',
        flexDirection: 'column'
    }),
    searchWrapper: css({
        padding: '4px 12px 0',
        marginBottom: 8
    }),
    searchInput: css(),
    itemsWrapper: css({
        flex: 1,
        overflowY: 'auto',
        prettyScroll: true
    }),
    item: css({
        padding: '10px 16px',
        wordBreak: 'break-all',
        cursor: 'pointer',
        fontSize: 14,
        '&:hover': {
            backgroundColor: '$colorGray100'
        }
    }),
    groupItem: css({
        marginBottom: '4px'
    }),
    groupItemTitle: css({
        fontSize: 'var(--font-size-normal)',
        color: 'var(--color-gray-500)',
        padding: '8px 16px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }),
    notFound: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '$colorGray600',
        fontSize: 14,
        fontWeight: 500,
        padding: '24px 0'
    }),
    dropdownExtra: css(),
    dropdownFooter: css({
        padding: '8px 16px'
    })
}))
