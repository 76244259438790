import { IconFont, pointer, Tooltip } from '@byecode/ui'
import type { FieldInputADTValue, SimpleTextValue } from '@lighthouse/core'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { FieldIconTypeMap } from '../../../constants'
import { getJumpUrl } from '../../../utils'
import BaseInput from '../BaseInput'
import { fieldLabelMap } from '../constant'
import type { FieldBaseProps } from '../types'

interface SimpleTextFieldProps extends FieldBaseProps {
    value: SimpleTextValue
}

const SCxIconWrapper = styled.div`
    display: flex;
    align-items: center;
    ${pointer}
`

type SimpleTextFieldType = 'phoneNumber' | 'url' | 'email'

const SimpleTextField: React.FunctionComponent<SimpleTextFieldProps> = props => {
    const {
        onCellChange,
        onSaveCellChange,
        value,
        config: { size, placeholder, inputType },
        readOnly,
        isControlled
    } = props
    const [text, setText] = useState(value)
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const [isHover, setIsHover] = useState(false)
    const [isFocus, setIsFocus] = useState(false)

    useUpdateEffect(() => {
        setText(value)
    }, [value])

    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = ev.target
            setText(value)
            onSaveCellChange?.({ type: inputType, value } as FieldInputADTValue)
        },
        [inputType, onSaveCellChange]
    )

    const handleBlur = useCallback(() => {
        setIsFocus(false)
        onCellChange?.({ type: inputType, value: text } as FieldInputADTValue)
    }, [inputType, onCellChange, setIsFocus, text])

    const href = useMemo(() => {
        if (!text) {
            return ''
        }
        switch (inputType) {
            case 'phoneNumber': {
                return `tel:${text}`
            }
            case 'email': {
                return `mailto:${text}`
            }
            case 'url': {
                return getJumpUrl(text)
            }
            default: {
                return ''
            }
        }
    }, [inputType, text])

    const isShowRightSection =  useMemo(() => (isHover && !readOnly) || isFocus, [isFocus, isHover, readOnly])

    const handleNavigate = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation()
            if (!href) {
                return
            }
            if (inputType === 'url') {
                window.open(href)
                return
            }
            window.location.href = href
        },
        [inputType, href]
    )

    return (
        <BaseInput
            value={text}
            size={size}
            placeholder={placeholder}
            onChange={handleChange}
            onMouseEnter={() => {
                !readOnly && setIsHover(true)
            }}
            onMouseLeave={() => {
                setIsHover(false)
            }}
            onFocus={() => setIsFocus(true)}
            onBlur={handleBlur}
            readOnly={readOnly}
            ref={inputRef}
            rightSection={
                isShowRightSection && (
                    <div
                    style={{display: 'flex', alignItems: 'center'}}
                        onMouseEnter={() => {
                            !readOnly && setIsHover(true)
                        }}
                        onMouseLeave={() => {
                            setIsHover(false)
                        }}
                    >
                        <Tooltip offset={16} title={fieldLabelMap.get(inputType)}>
                            <SCxIconWrapper onClick={handleNavigate}>
                                <IconFont color="var(--color-gray-500)" type={FieldIconTypeMap[inputType]} />
                            </SCxIconWrapper>
                        </Tooltip>
                    </div>
                )
            }
        />
    )
}

export default SimpleTextField
