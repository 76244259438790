import type { Direction, EdgeValue, FlexAlign, MaxContainerWidthSize } from '@lighthouse/core'
import { DIRECTION, FLEX_ALIGN } from '@lighthouse/core'
import { clamp } from 'rambda'

import { SIZE_OPTIONS } from '../hooks/useContainerWidth'
import type { OverDescriptor } from '../Sortable/types'
import type { FlowLayoutContainerNode, FlowLayoutNode } from '../types'
import { detectOrderForPointerOnBlock, findNodeParentById } from './common'

interface LayoutConfigure {
    direction?: Direction
    alignX?: FlexAlign
    alignY?: FlexAlign
    gap?: number
    padding?: EdgeValue
    rect: DOMRect
    size?: MaxContainerWidthSize
}

type PositionParams = {
    nodes: FlowLayoutNode[]
    draggableNodes: Map<
        string,
        {
            node: HTMLElement
            data: FlowLayoutNode
        }
    >
    overDescriptor: OverDescriptor
    coordinate: { x: number; y: number }
    activeRect: DOMRect
    remBase: number
    rootLayoutConfigure: LayoutConfigure
}

const INDICATOR_SIZE = 6

/** 单位转换为px */
const computedPaddingWithPx = (padding: undefined | EdgeValue, remBase: number) => {
    const [left = 0, top = 0, right = 0, bottom = 0] = padding || []

    return [left * remBase, top * remBase, right * remBase, bottom * remBase] as const
}

/** 获取父级节点布局信息 */
function getParentLayoutConfigure({
    nodes,
    draggableNodes,
    overDescriptor,
    rootLayoutConfigure
}: Omit<PositionParams, 'coordinate' | 'activeRect' | 'remBase'>) {
    // 如果是顶级下的子元素，则父级为root
    if (nodes.some(item => item.id === overDescriptor.id)) {
        return rootLayoutConfigure
    }

    const parentId = overDescriptor.virtualParentId ?? findNodeParentById(overDescriptor.id)(nodes)?.id
    if (!parentId) {
        return
    }
    const info = draggableNodes.get(parentId)
    if (!info) {
        return
    }

    const data = info.data as FlowLayoutContainerNode
    return {
        rect: info.node.getBoundingClientRect(),
        direction: data.data.direction,
        padding: data.data.padding,
        gap: data.data.gap,
        alignX: data.data.alignX,
        alignY: data.data.alignY,
        size: data.data.size
    }
}

/** 计算flex布局时的偏移量 */
function getOffsetByFlexAlign({
    direction = DIRECTION.vertical,
    alignX = FLEX_ALIGN['flex-start'],
    alignY = FLEX_ALIGN['flex-start'],
    padding,
    activeRect,
    parentRect
}: Omit<LayoutConfigure, 'gap' | 'padding' | 'rect'> & {
    padding: [number, number, number, number]
    parentRect: DOMRect
    activeRect: DOMRect
}) {
    const [pl, pt, pr, pb] = padding
    if (direction === DIRECTION.vertical) {
        // 因为垂直的都给了12宽度，不用减了
        if (alignX === FLEX_ALIGN['flex-start']) {
            return pl
        }

        if (alignX === FLEX_ALIGN['center']) {
            // return parentRect.width / 2 - activeRect.width / 2
            return pl
        }

        if (alignX === FLEX_ALIGN['flex-end']) {
            // return parentRect.width - activeRect.width
            return pr
        }

        return pl
    }

    if (alignY === FLEX_ALIGN['flex-start']) {
        return pt
    }

    if (alignY === FLEX_ALIGN['center']) {
        return Math.max(pt, parentRect.height / 2 - activeRect.height / 2)
    }

    if (alignY === FLEX_ALIGN['flex-end']) {
        return Math.max(parentRect.height - activeRect.height - pb, pb)
    }

    return pl
}

/** 获取over gap时的指示器位置 */
export function getGapPosition({ nodes, draggableNodes, overDescriptor, activeRect, rootLayoutConfigure, remBase }: PositionParams) {
    const info = draggableNodes.get(overDescriptor.id)
    if (!info) {
        return
    }
    const rect = info.node.getBoundingClientRect()

    const parentLayoutConfigure = getParentLayoutConfigure({ nodes, draggableNodes, overDescriptor, rootLayoutConfigure })
    if (!parentLayoutConfigure) {
        return
    }
    const {
        rect: parentNodeRect,
        gap = 0,
        padding,
        direction = DIRECTION.vertical,
        alignX = FLEX_ALIGN['flex-start'],
        alignY = FLEX_ALIGN['flex-start'],
        size
    } = parentLayoutConfigure
    const [pl, pt, pr, pb] = computedPaddingWithPx(padding, remBase)
    const offset = getOffsetByFlexAlign({ direction, alignX, alignY, padding: [pl, pt, pr, pb], parentRect: parentNodeRect, activeRect })
    const gapPx = gap * remBase

    const actualParentWidth = !!size && size !== 'unlimited' ? Math.min(SIZE_OPTIONS[size], parentNodeRect.width) : parentNodeRect.width
    const asideWidth = (parentNodeRect.width - actualParentWidth) / 2

    if (direction === DIRECTION.horizontal) {
        return {
            left:
                // asideWidth +
                clamp(
                    parentNodeRect.left - rootLayoutConfigure.rect.left,
                    parentNodeRect.right - rootLayoutConfigure.rect.left - INDICATOR_SIZE,
                    -rootLayoutConfigure.rect.left + rect.right + gapPx / 2 - INDICATOR_SIZE / 2
                ),
            top: parentNodeRect.top - rootLayoutConfigure.rect.top + offset,
            width: INDICATOR_SIZE,
            height: Math.min(parentNodeRect.height - pt - pb, activeRect.height)
        }
    }

    return {
        left: asideWidth + parentNodeRect.left - rootLayoutConfigure.rect.left + offset,
        top: clamp(
            parentNodeRect.top - rootLayoutConfigure.rect.top,
            parentNodeRect.bottom - rootLayoutConfigure.rect.top - INDICATOR_SIZE,
            -rootLayoutConfigure.rect.top + rect.bottom + gapPx / 2 - INDICATOR_SIZE / 2
        ),
        width: Math.min(actualParentWidth - pl - pr),
        height: INDICATOR_SIZE
    }
}

/** 获取over node时的指示器位置 */
export function getNodeMaskPosition({
    nodes,
    draggableNodes,
    coordinate,
    overDescriptor,
    activeRect,
    rootLayoutConfigure,
    remBase
}: PositionParams) {
    const info = draggableNodes.get(overDescriptor.id)
    if (!info) {
        return
    }
    const rect = info.node.getBoundingClientRect()

    const parentLayoutConfigure = getParentLayoutConfigure({ nodes, draggableNodes, overDescriptor, rootLayoutConfigure })
    if (!parentLayoutConfigure) {
        return
    }
    const {
        rect: parentNodeRect,
        gap = 0,
        padding,
        direction = DIRECTION.vertical,
        alignX = FLEX_ALIGN['flex-start'],
        alignY = FLEX_ALIGN['flex-start'],
        size
    } = parentLayoutConfigure
    const [pl, pt, pr, pb] = computedPaddingWithPx(padding, remBase)
    const offset = getOffsetByFlexAlign({ direction, alignX, alignY, padding: [pl, pt, pr, pb], parentRect: parentNodeRect, activeRect })
    const gapPx = gap * remBase
    const isInArea = coordinate.x >= rect.left && coordinate.x <= rect.right && coordinate.y >= rect.top && coordinate.y <= rect.bottom
    if (!isInArea) {
        return
    }
    const isOverBegin = detectOrderForPointerOnBlock(direction, coordinate, rect)

    const actualParentWidth = !!size && size !== 'unlimited' ? Math.min(SIZE_OPTIONS[size], parentNodeRect.width) : parentNodeRect.width
    const asideWidth = (parentNodeRect.width - actualParentWidth) / 2

    if (direction === DIRECTION.horizontal) {
        return {
            left:
                // asideWidth +
                clamp(
                    parentNodeRect.left - rootLayoutConfigure.rect.left,
                    parentNodeRect.right - rootLayoutConfigure.rect.left - INDICATOR_SIZE,
                    -rootLayoutConfigure.rect.left +
                        (isOverBegin ? rect.left - gapPx / 2 - INDICATOR_SIZE / 2 : rect.right + gapPx / 2 - INDICATOR_SIZE / 2)
                ),
            top: parentNodeRect.top - rootLayoutConfigure.rect.top + offset,
            width: INDICATOR_SIZE,
            height: Math.min(parentNodeRect.height - pt - pb, activeRect.height)
        }
    }

    return {
        left: asideWidth + parentNodeRect.left - rootLayoutConfigure.rect.left + offset,
        top: clamp(
            parentNodeRect.top - rootLayoutConfigure.rect.top,
            parentNodeRect.bottom - rootLayoutConfigure.rect.top - INDICATOR_SIZE,
            -rootLayoutConfigure.rect.top +
                (isOverBegin ? rect.top - gapPx / 2 - INDICATOR_SIZE / 2 : rect.bottom + gapPx / 2 - INDICATOR_SIZE / 2)
        ),
        width: Math.min(actualParentWidth - pl - pr),
        height: INDICATOR_SIZE
    }
}

/** 获取over 容器空白时的指示器位置 */
export function getContainerEmptyPosition({
    nodes,
    draggableNodes,
    overDescriptor,
    activeRect,
    rootLayoutConfigure,
    remBase
}: PositionParams) {
    let parentLayoutConfigure: LayoutConfigure
    let children: undefined | FlowLayoutNode[]
    if (overDescriptor.id === 'root') {
        parentLayoutConfigure = rootLayoutConfigure
        children = nodes
    } else {
        const info = draggableNodes.get(overDescriptor.id)
        if (!info) {
            return
        }
        const { data, children: _children } = info.data as FlowLayoutContainerNode
        parentLayoutConfigure = {
            direction: data.direction,
            alignX: data.alignX,
            alignY: data.alignY,
            gap: data.gap,
            padding: data.padding,
            size: data.size,
            rect: info.node.getBoundingClientRect()
        }
        children = _children
    }

    const gapPx = (parentLayoutConfigure.gap || 0) * remBase

    const [pl, pt, pr, pb] = computedPaddingWithPx(parentLayoutConfigure.padding, remBase)
    const offset = getOffsetByFlexAlign({
        direction: parentLayoutConfigure.direction,
        alignX: parentLayoutConfigure.alignX,
        alignY: parentLayoutConfigure.alignY,
        padding: [pl, pt, pr, pb],
        parentRect: parentLayoutConfigure.rect,
        activeRect
    })

    const actualParentWidth =
        !!parentLayoutConfigure.size && parentLayoutConfigure.size !== 'unlimited'
            ? Math.min(SIZE_OPTIONS[parentLayoutConfigure.size], parentLayoutConfigure.rect.width)
            : parentLayoutConfigure.rect.width
    const asideWidth = (parentLayoutConfigure.rect.width - actualParentWidth) / 2

    // 水平排列时
    if (parentLayoutConfigure.direction === DIRECTION.horizontal) {
        const height = Math.min(activeRect.height, parentLayoutConfigure.rect.height - pt - pb)
        const offsetTop = parentLayoutConfigure.rect.top - rootLayoutConfigure.rect.top + offset
        if (children && children.length > 0) {
            if (overDescriptor.target === 'padding-left' || overDescriptor.target === 'placeholder-begin') {
                const first = children[0]
                const firstNodeInfo = draggableNodes.get(first.id)
                if (!firstNodeInfo) {
                    return
                }

                const firstNodeRect = firstNodeInfo.node.getBoundingClientRect()
                return {
                    left:
                        // asideWidth +
                        clamp(
                            parentLayoutConfigure.rect.left - rootLayoutConfigure.rect.left,
                            parentLayoutConfigure.rect.right - rootLayoutConfigure.rect.left - INDICATOR_SIZE,
                            -rootLayoutConfigure.rect.left + firstNodeRect.left - gapPx / 2 - INDICATOR_SIZE / 2
                        ),
                    top: offsetTop,
                    width: INDICATOR_SIZE,
                    height
                }
            }

            if (overDescriptor.target === 'padding-right' || overDescriptor.target === 'placeholder-after') {
                const last = children[children.length - 1]
                const lastNodeInfo = draggableNodes.get(last.id)
                if (!lastNodeInfo) {
                    return
                }

                const lastNodeRect = lastNodeInfo.node.getBoundingClientRect()
                return {
                    left:
                        // asideWidth +
                        clamp(
                            parentLayoutConfigure.rect.left - rootLayoutConfigure.rect.left,
                            parentLayoutConfigure.rect.right - rootLayoutConfigure.rect.left - INDICATOR_SIZE,
                            -rootLayoutConfigure.rect.left + lastNodeRect.right + gapPx / 2 - INDICATOR_SIZE / 2
                        ),
                    top: offsetTop,
                    width: INDICATOR_SIZE,
                    height
                }
            }

            return
        }

        // 如果没有元素，则直接基于容器的padding
        return {
            left: asideWidth + parentLayoutConfigure.rect.left - rootLayoutConfigure.rect.left + pl,
            top: offsetTop,
            width: INDICATOR_SIZE,
            height
        }
    }

    // 垂直排列时
    const width = Math.min(actualParentWidth - pl - pr)
    const offsetLeft = parentLayoutConfigure.rect.left - rootLayoutConfigure.rect.left + offset
    if (children && children.length > 0) {
        if (overDescriptor.target === 'padding-top' || overDescriptor.target === 'placeholder-begin') {
            const first = children[0]
            const firstNodeInfo = draggableNodes.get(first.id)
            if (!firstNodeInfo) {
                return
            }

            const firstNodeRect = firstNodeInfo.node.getBoundingClientRect()
            return {
                left: asideWidth + offsetLeft,
                top: clamp(
                    parentLayoutConfigure.rect.top - rootLayoutConfigure.rect.top,
                    parentLayoutConfigure.rect.bottom - rootLayoutConfigure.rect.top - INDICATOR_SIZE,
                    -rootLayoutConfigure.rect.top + firstNodeRect.top - gapPx / 2 - INDICATOR_SIZE / 2
                ),
                width,
                height: INDICATOR_SIZE
            }
        }

        if (overDescriptor.target === 'padding-bottom' || overDescriptor.target === 'placeholder-after') {
            const last = children[children.length - 1]
            const lastNodeInfo = draggableNodes.get(last.id)
            if (!lastNodeInfo) {
                return
            }

            const lastNodeRect = lastNodeInfo.node.getBoundingClientRect()
            return {
                left: asideWidth + offsetLeft,
                top: clamp(
                    parentLayoutConfigure.rect.top - rootLayoutConfigure.rect.top,
                    parentLayoutConfigure.rect.bottom - rootLayoutConfigure.rect.top - INDICATOR_SIZE,
                    -rootLayoutConfigure.rect.top + lastNodeRect.bottom + gapPx / 2 - INDICATOR_SIZE / 2
                ),
                width,
                height: INDICATOR_SIZE
            }
        }

        return
    }

    return {
        left: asideWidth + offsetLeft,
        top: parentLayoutConfigure.rect.top - rootLayoutConfigure.rect.top + pt,
        width,
        height: INDICATOR_SIZE
    }
}

/** 获取over root时的指示器位置 */
export function getRootPosition({ nodes, draggableNodes, activeRect, rootLayoutConfigure, remBase }: PositionParams) {
    let lastNodeRect: DOMRect | undefined

    const last = nodes[nodes.length - 1]
    if (last) {
        const lastNode = draggableNodes.get(last.id)
        if (lastNode) {
            lastNodeRect = lastNode.node.getBoundingClientRect()
        }
    }

    const [pl, pt, pr, pb] = computedPaddingWithPx(rootLayoutConfigure.padding, remBase)
    const gapPx = (rootLayoutConfigure.gap || 0) * remBase
    return {
        left: getOffsetByFlexAlign({
            direction: DIRECTION.vertical,
            alignX: rootLayoutConfigure.alignX,
            alignY: rootLayoutConfigure.alignY,
            padding: [pl, pt, pr, pb],
            parentRect: rootLayoutConfigure.rect,
            activeRect
        }),
        top: clamp(
            pt,
            rootLayoutConfigure.rect.height - INDICATOR_SIZE,
            -rootLayoutConfigure.rect.top + (lastNodeRect ? lastNodeRect.bottom : 0) + gapPx / 2
        ),
        width: Math.min(rootLayoutConfigure.rect.width - pl - pr),
        height: INDICATOR_SIZE
    }
}
