import type { ChartType } from '@lighthouse/core'
import React, { Suspense } from 'react'

import IndicatorChart from './IndicatorChart'
import type { ECOption, IndicatorChartOption } from './types'

export * from './types'
// 注册必须的组件

export interface ChartRendererProps {
    option: ECOption | IndicatorChartOption
    chartType?: ChartType
}

// dynamic import，split echarts to another chunk
const CommonChart = React.lazy(() => import('./CommonChart'))

export const ChartRenderer: React.FC<ChartRendererProps> = ({ option, chartType }) => {
    let chart = null
    switch (chartType) {
        case 'indicator': {
            chart = <IndicatorChart option={option as IndicatorChartOption} />
            break
        }
        case 'striation':
        case 'composite':
        case 'bar':
        case 'line':
        case 'pie':
        case 'funnel': {
            chart = (
                <Suspense fallback={null}>
                    <CommonChart option={option as ECOption} />
                </Suspense>
            )
            break
        }
        default: {
            break
        }
    }

    return chart
}
