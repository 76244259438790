import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef, useCallback, useEffect, useState } from 'react'

import { useUncontrolled } from '../../../hooks/useUncontrolled'
import { Box } from '../../Box'
import { Flex } from '../../Flex'
import { IconFont } from '../../IconFont'
import { Text } from '../../Text'
import type { TabPanelProps } from '../TabPanel'

interface TabsStylesParams {
    tabPosition: 'top' | 'bottom' | 'left' | 'right'
}

function rootStyle(tabPosition: TabsStylesParams['tabPosition']) {
    if (tabPosition === 'top') {
        return css({
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        })
    }
    if (tabPosition === 'right') {
        return css({
            display: 'flex',
            flexFlow: 'row-reverse nowrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        })
    }
    if (tabPosition === 'bottom') {
        return css({
            display: 'flex',
            flexFlow: 'column-reverse',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        })
    }
    if (tabPosition === 'left') {
        return css({
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        })
    }
}

function tabsStyle(tabPosition: TabsStylesParams['tabPosition']) {
    if (tabPosition === 'top') {
        return css({
            paddingLeft: '12px',
            height: '60px',
            width: '100%',
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center'
        })
    }
    if (tabPosition === 'right') {
        return css({
            paddingTop: '12px',
            width: '300px',
            minWidth: '190px',
            height: '100%',
            overflowY: 'auto'
        })
    }
    if (tabPosition === 'bottom') {
        return css({
            paddingLeft: '12px',
            height: '60px',
            width: '100%',
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center'
        })
    }
    if (tabPosition === 'left') {
        return css({
            padding: '12px 6px 0',
            width: '300px',
            minWidth: '190px',
            height: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column'
        })
    }
}

function panelStyle(tabPosition: TabsStylesParams['tabPosition']) {
    if (tabPosition === 'top' || tabPosition === 'bottom') {
        return css({
            flex: 1,
            width: '100%'
        })
    }
    if (tabPosition === 'left' || tabPosition === 'right') {
        return css({
            flex: 1,
            height: '100%',
            overflowY: 'auto',
            padding: '16px 20px'
        })
    }
}

const useStyles = createStyles((params: TabsStylesParams) => {
    return {
        root: css({
            width: '100%',
            height: '100%',
            minWidth: '890px',
            overflow: 'hidden',
            ...rootStyle(params.tabPosition)
        }),
        title: css({
            color: '$colorGray400',
            fontSize: '12px',
            lineHeight: '20px',
            padding: '6px 20px'
        }),
        tabs: css({
            backgroundColor: '#fafafa',
            ...tabsStyle(params.tabPosition)
        }),
        tabsList: css({
        }),
        tab: css({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px',
            cursor: 'pointer',
            height: '32px',
            marginBottom: '4px',
            borderRadius: '8px',
            marginRight: params.tabPosition === 'top' || params.tabPosition === 'bottom' ? '8px' : undefined,
            '&:hover': {
                background: '$colorGray100',
            }
        }),
        tabIcon: css({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '6px 12px 6px 0',

            ':hover': {
                background: '#eee'
            }
        }),
        content: css({
            backgroundColor: '$colorWhite',
            ...panelStyle(params.tabPosition)
        }),
        panel: css({
            height: '100%'
        })
    }
})

interface TabsProps
    extends StyleComponentProps<Selectors<typeof useStyles>>,
        Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
    title?: string
    onChange?: (key: string) => void
    activeKey?: string
    defaultActiveKey?: string
    tabPosition?: 'top' | 'right' | 'bottom' | 'left'
    activeStyles?: React.CSSProperties
}

/** 从lighthouse ui迁移过来的，只完成了tabPosition = 'left' */
export const UnstableTabs: React.FC<TabsProps> = forwardRef<HTMLDivElement, TabsProps>(
    (
        {
            children,
            title,
            onChange,
            activeKey,
            defaultActiveKey,
            tabPosition = 'left',
            activeStyles,

            styles,
            unstyled,
            className,
            ...rest
        },
        ref
    ) => {
        const { classes } = useStyles({ tabPosition }, { name: 'Tabs', styles, unstyled })
        const [_value, handleChange] = useUncontrolled({ value: activeKey, defaultValue: defaultActiveKey ?? '', onChange })
        // const [tabsKey, setTabsKey] = useState(activeKey || defaultActiveKey || '')

        useEffect(() => {
            if (!children) {
                return
            }
            const keyArr: string[] = []
            React.Children.map(children, (child, index) => {
                if (!React.isValidElement(child)) {
                    return
                }
                const item = child
                keyArr.push(String(item.key))
            })
            if (_value && keyArr.includes(_value)) {
                handleChange(_value)
                return
            }
            const [activeTabKey] = keyArr
            activeTabKey && handleChange(activeTabKey)
        }, [_value, children, handleChange])

        const selectTab = useCallback(
            (key: string) => {
                handleChange(key)
            },
            [handleChange]
        )

        const checkSelect = useCallback((key: string) => key === _value, [_value])

        const renderTabChildren = useCallback(() => {
            // 是否为undefined
            if (!children) {
                return
            }
            return React.Children.map(children, (child, index) => {
                if (!React.isValidElement(child)) {
                    return null
                }

                const item = child as React.ReactElement<TabPanelProps>

                const itemProps = item.props

                const { icon, tab, extra } = itemProps
                return (
                    <Box
                        className={classes.tab}
                        style={checkSelect(String(item.key)) ? { background: '#EEE', ...activeStyles } : {}}
                        onClick={() => selectTab(String(item.key) || '')}
                    >
                        <Flex alignItems='center'>
                            {!!icon && (
                                <IconFont
                                    className={classes.tabIcon}
                                    style={checkSelect(String(item.key)) ? activeStyles : {}}
                                    size={16}
                                    type={icon}
                                />
                            )}
                            <Text style={checkSelect(String(item.key)) ? activeStyles : {}}>{tab || ''}</Text>
                        </Flex>
                        {extra}
                    </Box>
                )
            })
        }, [activeStyles, checkSelect, children, classes.tab, classes.tabIcon, selectTab])

        const renderTabPanelChildren = useCallback(() => {
            // 是否为undefined
            if (!children) {
                return
            }
            return React.Children.map(children, child => {
                if (!React.isValidElement(child)) {
                    return null
                }
                const item: React.ReactElement = child
                // if (item.type.displayName && item.type.displayName !== 'tabPanel') return
                const itemProps: { children: React.ReactNode } = item.props
                return checkSelect(String(item.key)) ? <Box className={classes.panel}>{itemProps.children || ''}</Box> : null
            })
        }, [checkSelect, children, classes.panel])

        return (
            <Box ref={ref} className={clsx(classes.root, className)} {...rest}>
                <Box className={classes.tabs}>
                    <Box className={classes.tabsList}>
                        {!!title && <Box className={classes.title}>{title}</Box>}
                        {renderTabChildren()}
                    </Box>
                </Box>
                <Box className={classes.content}>{renderTabPanelChildren()}</Box>
            </Box>
        )
    }
)
