import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Property } from 'csstype'

import type { BaseModalStylesNames } from './BaseModal.type'

type StylesParams = { zIndex: number; width: number | string; position: Property.Position }

export const useStyles = createStyles<BaseModalStylesNames, StylesParams>(({ zIndex, width, position }) => ({
    root: css({
        position,
        inset: 0,
        zIndex,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }),
    overlay: css({
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        backgroundColor: '$colorBlack'
    }),
    modal: css({
        // position: 'relative',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        width,
        borderRadius: 12,
        border: '1px solid $colorGray200',
        backgroundColor: '$colorWhite',
        boxShadow: '0px 4px 12px 0px rgba(16, 24, 40, 0.10)'
    }),
    header: css({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '6px',
        padding: '8px 16px',
        height: 60,
        borderBottom: '1px solid $colorGray200'
    }),
    title: css({
        fontSize: 16,
        lineHeight: '24px',
        textEllipsis: true
    }),
    actions: css({
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        gap: 4
    }),
    close: css({
        all: 'unset',
        display: 'flex',
        alignItems: 'center',
        padding: 4,
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: '$colorGray100',
        transition: 'backgroundColor 0.2s',
        fontSize: 20,
        '&:hover': {
            backgroundColor: '$colorGray200',
            'iconpark-icon': {
                color: '$colorGray900'
            }
        },
        'iconpark-icon': {
            color: '$colorGray400'
        }
    }),

    body: css({
        flex: 1,
        overflow: 'auto',
        padding: 16
    }),

    footer: css({
        borderRadius: '0 0 12px 12px'
    })
}))
