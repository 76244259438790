import { backgroundTransitionOnClick, IconFont, pointer, singleTextEllipsis } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    font-size: var(--font-size-normal);

    .ProseMirror {
        cursor: text;
    }
`

export const Header = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    height: 52px;
    padding: 0 16px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-gray-200);
    border: 1px solid var(--color-gray-200);
    > * {
        width: 33%;
    }
`

export const EditorContent = styled.div`
    height: calc(100% - 52px);
    overflow-y: auto;
    overflow-x: hidden;
`

export const Title = styled.div<{ size?: number; color?: string }>`
    position: relative;
    width: 100%;
    color: ${({ color }) => color ?? 'var(--color-black)'};
    text-align: center;
    font-size: ${({ size }) => size ?? '14'}px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 136px;
    height: 100%;

    ${singleTextEllipsis}
`

export const LeftExtra = styled.div<{ size?: number; color?: string }>`
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    color: ${({ color }) => color ?? 'var(--color-black)'};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ size }) => size ?? '14'}px;
    width: 40px;
    z-index: 2;

    ${singleTextEllipsis}
    ${pointer}
`

export const Text = styled.span<{ size?: number; color?: string; isOmit?: boolean; isPointer?: boolean }>`
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-size: ${({ size }) => size ?? '14'}px;

    ${({ isPointer }) => isPointer && pointer}
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

export const RightFill = styled.div`
    position: absolute;
    right: 16px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${Text} {
        margin-left: 16px;
    }
`
export const Icon = styled(IconFont)<{ isAction?: boolean }>`
    color: var(--color-black);

    ${({ isAction }) => isAction && `${backgroundTransitionOnClick}`}
`

export const RichTextWrapper = styled.div`
    /*  */
`
