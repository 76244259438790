import { IconFont, Input, Switch } from '@byecode/ui'
import { Text } from '@mantine/core'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import * as SC from './styles'

interface SearchInputProps {
    showType: 'table' | 'list'
    value: string
    switchValue?: boolean
    autoFocus?: boolean
    style?: React.CSSProperties
    onChange: (value: string) => void
    onChangeSwitch?: (value: boolean) => void
}

const SCxRecordSelectSearch = styled.div`
    display: flex;
    flex-wrap: nowrap;
    font-size: var(--font-size-normal);
    border-bottom: 1px solid var(--color-gray-200);
    width: 100%;
    padding: 12px 0;
    gap: 12px;
    background-color: var(--color-white);
    // TODO: input的styles不支持更改边框颜色 暂时做以下处理
    & > [class^='byecode'] {
        &:focus-within {
            border-color: var(--color-app-main);
        }
    }
`
export const SCxTIcon = styled(IconFont)`
    font-size: 12px;
    cursor: pointer;
    color: var(--color-gray-600);
`
const SCxIsSelectedButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8px;
    gap: 6px;

    height: 36px;
    border: 1px solid var(--color-gray-200);
    border-radius: 6px;
`
export const SearchInput: React.FunctionComponent<SearchInputProps> = ({
    showType,
    value,
    switchValue,
    autoFocus,
    style,
    onChange,
    onChangeSwitch
}) => {
     const { t } = useTranslation()
    return (
        <SCxRecordSelectSearch style={style}>
            <Input
                value={value}
                size="lg"
                autoFocus={autoFocus}
                placeholder={t('search')}
                onChange={e => onChange(e.target.value)}
                style={{ flex: 1 }}
                suffix={<SCxTIcon type="Search" />}
            />
            {showType === 'table' && (
                <SCxIsSelectedButton>
                    <Text style={{ wordBreak: 'keep-all' }}>{t('selected')}</Text>
                    <Switch color="var(--color-app-main)" checked={switchValue} onChange={e => onChangeSwitch?.(e.currentTarget.checked)} />
                </SCxIsSelectedButton>
            )}
        </SCxRecordSelectSearch>
    )
}
