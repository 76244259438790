import type { Field } from '@lighthouse/core'
import { useVirtualizer } from '@tanstack/react-virtual'
import { max } from 'rambda'
import React, { startTransition, useEffect, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { ApplicationPreviewEnum } from '../../types'
import { desktopRowHeight, mobileRowHeight } from './constant'
import { useFieldSelectTableContext } from './FiledSelectTableContext'
import { TableRecordMobile } from './mobile/TableRecordMobile'
import { TableIndex } from './TableIndex'
import { TableRecord } from './TableRecord'

export interface TableContentProps {
    columns: Field[]
    tableWidth?: number
    tableHeight?: number
    scrollXRef: React.RefObject<HTMLDivElement>
    scrollYRef: React.RefObject<HTMLDivElement>
}

export const SCTableBody = styled.div`
    width: 100%;
`

export const SCxTableContainer = styled.div`
    position: relative;
    min-width: 100%;
    width: 100%;
`

export const SCxTableRow = styled.div`
    width: 100%;
    height: 39px;
    border-bottom: 1px solid var(--color-gray-200);
`
const isMobile = true
export const TableBody: React.FC<TableContentProps> = ({ columns, tableWidth, tableHeight, scrollXRef, scrollYRef }) => {
    const [scrollDom, setScrollDom] = useState<HTMLDivElement | null>(scrollYRef.current)
    const { recordData: data, value, loading, previewType, onRowClick } = useFieldSelectTableContext()

    const estimateSize = useMemo(() => {
        if (!scrollDom || previewType === ApplicationPreviewEnum.desktop) {
            return 160
        }
        const { width } = scrollDom.getBoundingClientRect()
        const renderColumnsNum = columns.length - (isMobile ? 1 : 0)

        return width > renderColumnsNum * 160 ? width / renderColumnsNum : 160
    }, [columns.length, previewType, scrollDom])

    const currentRowHeight = useMemo(() => (isMobile ? mobileRowHeight : desktopRowHeight), [])

    const rowOverscan = useMemo(() => {
        if (!tableHeight) {
            return 300
        }
        return max(Math.floor(tableHeight / currentRowHeight) + 10, 300)
    }, [currentRowHeight, tableHeight])

    const rowVirtualizer = useVirtualizer({
        count: data.length,
        scrollPaddingStart: currentRowHeight,
        getScrollElement: () => scrollXRef.current,
        estimateSize: () => currentRowHeight,
        getItemKey: (index: number) => data[index].id,
        overscan: rowOverscan
    })

    const columnVirtualizer = useVirtualizer({
        horizontal: true,
        count: columns.length - (isMobile ? 1 : 0),
        getScrollElement: () => scrollYRef.current,
        paddingStart: isMobile ? 0 : 40,
        paddingEnd: isMobile ? 40 : 0,
        estimateSize: () => estimateSize,
        overscan: 8
    })

    useEffect(() => {
        if (!scrollDom) {
            setScrollDom(scrollXRef.current)
        }
    }, [scrollDom, scrollXRef])

    useUpdateEffect(() => {
        if (scrollDom) {
            startTransition(() => {
                columnVirtualizer.measure()
                rowVirtualizer.measure()
            })
        }
    }, [scrollDom])

    return (
        <SCTableBody>
            <SCxTableContainer
                style={{
                    width: `${columnVirtualizer.getTotalSize()}px`,
                    height: `${rowVirtualizer.getTotalSize()}px`
                }}
            >
                {rowVirtualizer.getVirtualItems().map(virtualRow => {
                    const { index } = virtualRow
                    const record = data[index]
                    const isMobile = previewType === ApplicationPreviewEnum.mobile
                    const recordProp = { key: record.id, record, value, index, tableWidth, columns, virtualRow, columnVirtualizer }
                    return (
                        <TableRecordMobile
                            {...recordProp}
                            key={record.id}
                            stickyLeft={isMobile ? 0 : 32}
                            isMobile={isMobile}
                            position={previewType === ApplicationPreviewEnum.mobile ? 'right' : 'left'}
                        />
                    )
                })}
            </SCxTableContainer>
            {loading && (
                <SCxTableRow>
                    <TableIndex loading />
                </SCxTableRow>
            )}
        </SCTableBody>
    )
}
