import type { Option } from '@byecode/ui'
import type { SystemVariableValue } from '@lighthouse/core'

import { FILTER_DATE_SYSTEM_LIST_1, FILTER_DATE_SYSTEM_LIST_2 } from '../Variable/constant'

export const boolOption: Option[] = [
    {
        value: '1',
        label: 'true'
    },
    {
        value: '0',
        label: 'false'
    }
]

export const systemMapList: Record<number, SystemVariableValue[]> = {
    0: FILTER_DATE_SYSTEM_LIST_2,
    1: FILTER_DATE_SYSTEM_LIST_1,
    2: FILTER_DATE_SYSTEM_LIST_1
}

export const needNumberParamSystemVariableList = ['BEFORE_SOMEWHAT_DAYS', 'AFTER_SOMEWHAT_DAYS']

export const FILTER_OPERATOR = 'filterController'
