import { getAssetUrl } from '@lighthouse/assets'
import { isIosPlatform } from '@lighthouse/tools'
import React from 'react'
import styled from 'styled-components'

interface AppInfoCardProps {
    appLogo?: string
    appTitle?: string
    appDescription?: string
}

interface StepCardProps {
    title: React.ReactNode
    stepTitle: string
    img?: string
    imgContainerStyle?: React.CSSProperties
    imgFullWidth?: boolean
    isIcon?: boolean
}

const SCxDownloadAppContainer = styled.div``

/**
 * App Card
 */

const SCxAppCard = styled.div`
    display: flex;
    height: 88px;
    padding: 16px;
    border-radius: 8px;
    align-items: center;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
    box-shadow: 0px 4px 12px 0px #1018281a;
`

const SCxAppLogo = styled.img`
    width: 56px;
    height: 56px;
    margin-right: 12px;
`

const SCxAppInfoContainer = styled.div`
    color: var(--color-gray-500);
`

const SCxAppInfoTitle = styled.h2`
    font-size: var(--font-size-normal);
    margin-bottom: 8px;
`

const SCxAppInfoDescription = styled.p`
    font-size: var(--font-size-sm);
`

/**
 * Step Card
 */

const SCxStepCard = styled.div`
    margin-top: 16px;
    padding: 16px;
    border-radius: 8px;
    align-items: center;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
    box-shadow: 0px 4px 12px 0px #1018281a;
`

const SCxStepTitle = styled.p`
    font-size: var(--font-size-normal);
    margin-right: 12px;
    margin-bottom: 12px;
`
const SCxStepTitleNumber = styled.span`
    color: var(--color-main);
`

const SCxStepInfoContainer = styled.div`
    text-align: center;
    border-radius: 12px;
`

const SCxStepImg = styled.img`
    width: 100%;
`

const AppInfoCard: React.FC<AppInfoCardProps> = ({ appTitle, appLogo, appDescription }) => {
    return (
        <SCxAppCard>
            <SCxAppLogo src={appLogo} alt="App Logo" />
            <SCxAppInfoContainer>
                <SCxAppInfoTitle>{appTitle}</SCxAppInfoTitle>
                <SCxAppInfoDescription>{appDescription}</SCxAppInfoDescription>
            </SCxAppInfoContainer>
        </SCxAppCard>
    )
}

const StepCard: React.FC<StepCardProps> = ({ stepTitle, title, img, imgFullWidth = true, isIcon }) => {
    return (
        <SCxStepCard>
            <SCxStepTitle>
                <SCxStepTitleNumber>{stepTitle}：</SCxStepTitleNumber>
                {title}
            </SCxStepTitle>
            {img && (
                <SCxStepInfoContainer
                    style={isIcon ? { border: '1px solid var(--color-gray-200)', backgroundColor: 'var(--color-gray-50)' } : undefined}
                >
                    <SCxStepImg src={img} style={{ width: isIcon && !imgFullWidth ? 56 : '100%' }} alt="Step Logo" />
                </SCxStepInfoContainer>
            )}
        </SCxStepCard>
    )
}

export const DownloadAppForIos: React.FC<AppInfoCardProps> = props => {
    return (
        <SCxDownloadAppContainer>
            <AppInfoCard {...props} />
            <StepCard
                stepTitle="第一步"
                title="使用 Safari 浏览器打开应用"
                img={getAssetUrl('common', 'safari.png')}
                isIcon
                imgFullWidth={false}
            />
            <StepCard
                stepTitle="第二步"
                title={
                    <span>
                        点击底部的标签栏的<b>发送按钮</b>
                    </span>
                }
                img={getAssetUrl('common', 'safari_bar.png')}
            />
            <StepCard
                stepTitle="第三步"
                title={
                    <span>
                        点击列表中的<b>添加到主屏幕</b>
                    </span>
                }
                img={getAssetUrl('common', 'safari_share.png')}
            />
        </SCxDownloadAppContainer>
    )
}

export const DownloadAppForAndroid: React.FC<AppInfoCardProps> = props => {
    return (
        <SCxDownloadAppContainer>
            <AppInfoCard {...props} />
            <StepCard
                stepTitle="第一步"
                title={
                    <span>
                        打开浏览器APP的系统权限设置，开启<b>“桌面快捷方式”的权限”</b>
                    </span>
                }
                img={getAssetUrl('common', 'android.png')}
            />
            <StepCard stepTitle="第二步" title="打开浏览器的设置（不同浏览器设置入口不一，通常入口会在右上角或底部栏中）" />
            <StepCard
                stepTitle="第三步"
                title={
                    <span>
                        点击<b>添加到主屏幕</b>（不同浏览器叫法不一样，有的浏览器也把这个功能称之为<b>“发送到桌面”</b>）
                    </span>
                }
            />
        </SCxDownloadAppContainer>
    )
}

export const DownloadApp: React.FC<AppInfoCardProps> = props => {
    const isIos = isIosPlatform()

    if (isIos) {
        return <DownloadAppForIos {...props} />
    }
    return <DownloadAppForAndroid {...props} />
}
