import styled from 'styled-components'

export const Title = styled.div`
    color: var(--color-app-main);
    text-align: center;
    word-break: break-word;
    /* 24/SC-Medium */
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    margin-top: 28px;
`

export const Describe = styled.div`
    color: var(--color-gray-500);
    text-align: center;
    /* 14/SC-Regular */
    word-break: break-word;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-top: 12px;
    margin-bottom: 32px;
`

export const LoginWay = styled.div`
    margin-top: 32px;
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const LoginWayItem = styled.div`
    position: relative;
    height: 44px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--color-gray-200);
    justify-content: center;

    > .icon {
        position: absolute;
        left: 16px;
    }
`
export const LoginWayTitle = styled.div`
    font-size: 16px;
`
