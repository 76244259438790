import { pointer, singleTextEllipsis } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100%;
`

export const Header = styled.div`
    width: 100%;
    height: 52px;
    padding: 0 16px;
    display: flex;
    border: 1px solid var(--color-gray-200);
    font-size: 14px;
    color: var(--color-black);
    position: relative;
`

export const Title = styled.div`
    flex: 1;
    position: relative;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    ${singleTextEllipsis}
`

export const TopBar = styled.div`
    position: absolute;
    top: 7px;
    left: 50%;
    margin-left: -16px;
    width: 32px;
    height: 4px;
    background: var(--color-gray-200);
`

export const LeftExtra = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 56px;

    ${singleTextEllipsis}
    ${pointer}
`

export const RightExtra = styled.div`
    width: 56px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ${singleTextEllipsis}
    ${pointer}
`

export const Content = styled.div<{ height?: string | number; disableContentPadding?: boolean }>`
    width: 100%;
    padding: ${({ disableContentPadding }) => (disableContentPadding ? 0 : 16)}px;
    flex: 1;
    height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
`
