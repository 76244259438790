import { createStyles } from '../../../theme/createStyles'
import { css } from '../../../theme/stitches.config'

export const useStyles = createStyles(() => ({
    monthBody: css({
        padding: 16,
        display: 'grid',
        gap: 16,
        gridTemplateColumns: 'repeat(3, 1fr)'
    }),
    monthCell: css({
        position: 'relative',
        width: 64,
        height: '24px !important',
        padding: '0 !important',
        borderRadius: '12px !important',
        lineHeight: '22px',
        fontWeight: 500,
        justifyContent: 'center'
    }),
    monthIndicator: css({
        width: 4,
        height: 4,
        borderRadius: '50%',
        position: 'absolute',
        left: '50%',
        bottom: '-10px',
        transform: 'translateX(-50%)',
        backgroundColor: '$colorPrimary'
    })
}))
