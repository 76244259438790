import type { useInteractions } from '@floating-ui/react'
import React, { useContext } from 'react'

import type { useTooltip } from './hook'

export type TooltipWidth = 'target' | number | 'auto'
// export type TooltipTrigger = 'hover' | 'click'

type ContextValue = ReturnType<typeof useTooltip> & {
    width?: TooltipWidth
    minWidth?: number
}

const TooltipContext = React.createContext<ContextValue | null>(null) as React.Context<ContextValue>

export const TooltipProvider = TooltipContext.Provider

export const useTooltipContext = () => useContext(TooltipContext)
