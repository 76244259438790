import { ClickAwayListener } from '@byecode/ui'
import type { Field } from '@lighthouse/core'
import type { JSONContent } from '@tiptap/react'
import React, { useCallback, useState } from 'react'

import { FormulaEditor } from '../FormulaEditor'
import * as SC from './styles'

interface FormulaEditorViewProps {
    fields: Field[]
    fieldId: string
    style?: React.CSSProperties
    value?: JSONContent
    onOk?: (json: JSONContent | undefined) => void
    onClose?: () => void
    onCancel?: () => void
}

const FormulaView = React.forwardRef<HTMLDivElement, FormulaEditorViewProps>(
    ({ fields, fieldId, style, value, onClose, onOk, onCancel }, ref) => {
        const [content, setContent] = useState<JSONContent | undefined>(value)
        // const otherDataSource = useMemo(() => {
        //     return otherDsIds.reduce<DataSourceList[]>((prev, id) => {
        //         const dataSource = find(item => item.id === id, allDataSources?.[appId] || [])
        //         if (dataSource) {
        //             const field = Object.entries(dataSource.schema).map(([key, value]) => value)
        //             prev.push({
        //                 id: dataSource.id,
        //                 name: dataSource.name,
        //                 field
        //             })
        //         }
        //         return prev
        //     }, [])
        // }, [allDataSources, appId, otherDsIds])

        const handleChange = useCallback(
            (json: JSONContent | undefined) => {
                setContent(json)
            },
            [setContent]
        )

        return (
            <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => onClose?.()}>
                <SC.Container style={style}>
                    <SC.CodeHeader>
                        <SC.CodeTitle>公式编辑</SC.CodeTitle>
                        <SC.CodeSpread type="ArrowsInSimple" size={20} onClick={onCancel} />
                    </SC.CodeHeader>
                    <SC.CodeContainer>
                        <FormulaEditor
                            fields={fields}
                            content={content}
                            fieldId={fieldId}
                            onChange={handleChange}
                            onOk={onOk}
                            onCancel={onCancel}
                        />
                    </SC.CodeContainer>
                </SC.Container>
            </ClickAwayListener>
        )
    }
)

export default FormulaView
