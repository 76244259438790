import type { DTSelectItem, HighLightConditions, SelectField, SelectValue } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { SelectItemPreviewer } from '../FieldValuePreviewer/SelectValuePreviewer'
import { CellHighlighter } from '../Highlighter'
import { TooltipList } from '../TooltipList'

interface SelectPreviewerProps {
    data?: SelectValue
    field: SelectField
    children?: React.ReactNode
    textStyles?: React.CSSProperties
    isWrap?: boolean
    width?: number
    colorless?: boolean
    direction?: 'horizontal' | 'vertical'
    styleConditions?: HighLightConditions
}

const SCxPersonListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean }>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
`

export const SelectValuePreviewer: React.FC<SelectPreviewerProps> = ({
    field,
    data = [],
    textStyles,
    isWrap = true,
    colorless = false,
    width = 160,
    direction,
    styleConditions
}) => {
    const { id: fieldId } = field
    const {
        select: { options = [] }
    } = field

    const selectedOptions = useMemo(
        () =>
            data.reduce<DTSelectItem[]>((list, val) => {
                const option = options.find(option => option.label === val)
                if (option) {
                    list.push(option)
                }
                return list
            }, []),
        [data, options]
    )

    const key = `${selectedOptions.map(item => item.label).join('-')}-${width}`
    const selectText = data.join(',')
    return (
        <>
            <CellHighlighter fieldId={fieldId} type="select" value={selectText} conditions={styleConditions} />
            {isWrap ? (
                <SCxPersonListWrapper isWrap={isWrap}>
                    {selectedOptions.map((item, index) => (
                        <SelectItemPreviewer
                            id={String(index)}
                            key={item.id}
                            data={item}
                            isWrap={isWrap}
                            textStyles={textStyles}
                            direction={direction}
                            colorless={colorless}
                        />
                    ))}
                </SCxPersonListWrapper>
            ) : (
                <TooltipList
                    width={width}
                    data={selectedOptions.map(({ label }, index) => ({ label, value: String(index) }))}
                    key={key}
                    render={visibleNum => {
                        const renderList = selectedOptions.slice(0, visibleNum)
                        return (
                            <SCxPersonListWrapper isPadding={visibleNum < selectedOptions.length} isWrap={isWrap}>
                                {renderList.map((item, index) => (
                                    <SelectItemPreviewer
                                        id={String(index)}
                                        key={item.id}
                                        data={item}
                                        isWrap={isWrap}
                                        isLast={index === renderList.length - 1}
                                        textStyles={textStyles}
                                        direction={direction}
                                        colorless={colorless}
                                    />
                                ))}
                            </SCxPersonListWrapper>
                        )
                    }}
                />
            )}
        </>
    )
}
