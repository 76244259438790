import { Flex } from '@byecode/ui'
import type {
    ApplicationAbstract,
    ApplicationSettingNavbar,
    ApplicationSettingNavbarButton,
    ApplicationSettingTheme,
    AppUser,
    ButtonEvents,
    NavigationBaseItem
} from '@lighthouse/core'
import { ButtonPattern, NavigationShowMode } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import { useElementSize } from '@mantine/hooks'
import cls from 'classnames'
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { splitWhen } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useImmer } from 'use-immer'

import type { LoginAuthType } from '../../AccountPage'
import ApplicationHeaderLayout from '../ApplicationHeaderLayout'
import { ApplicationLinkItem } from '../ApplicationLinkItem'
import ApplicationLogo from '../ApplicationLogo'
import ApplicationMultistageCollapse from '../ApplicationMultistageCollapse'
import { ApplicationNavBarButton } from '../ApplicationNavBarButton'
import { useAppNavbarStylesContext } from '../AppNavbarStylesContext'
import MultilevelSelect from '../MultilevelSelect'
import * as CM from '../styles'
import { UserCenter } from '../UserCenter'
import { excludePage } from './constant'
import * as SC from './styles'

interface PageHeaderProps {
    isSimulation?: boolean
    userData?: AppUser
    application?: ApplicationAbstract
    navbar: ApplicationSettingNavbar
    theme?: ApplicationSettingTheme
    style?: React.CSSProperties
    showMode: NavigationShowMode
    activePage?: string
    selected?: boolean
    disableEvent?: boolean
    notificationBox?: React.ReactNode
    languageBox?: React.ReactNode
    // collapseAtom: ReturnType<typeof atom<Record<string, boolean>>>

    onLogout?: () => void
    onCommonCallBack?: (params: ButtonEvents) => void
    onToLink?: (navigation?: NavigationBaseItem) => void
    onSelect?: (selected: boolean) => void
    onUpdateBindAccount?: (isBind: boolean, type: LoginAuthType) => Promise<boolean>
}

export const ApplicationHeaderVertical: React.FC<PageHeaderProps> = ({
    isSimulation = false,
    userData,
    application,
    theme,
    showMode = 0,
    selected = false,
    languageBox,
    navbar,
    // collapseAtom,
    activePage,
    disableEvent,
    style,
    onLogout,
    onCommonCallBack,
    onToLink,
    onSelect,
    onUpdateBindAccount,
    notificationBox
}) => {
    const { linkList, buttonList, backgroundOverlay, logo, backgroundBlur } = navbar

    const headerStyles = useAppNavbarStylesContext()
    const { backgroundColor, color } = headerStyles

    const leftBtnList = useMemo(
        () => ({ child: linkList?.child.filter(linkId => linkList?.list[linkId].isAccess) ?? [], list: linkList?.list ?? {} }),
        [linkList?.child, linkList?.list]
    )
    const { ref: navbarRef, width: navWid, height: navHei } = useElementSize<HTMLDivElement>()
    const { ref: leftBtnGroupRef, width: leftWid, height: leftHei } = useElementSize<HTMLDivElement>()
    const { ref: rightBtnGroupRef, width: rightWid, height: rightHei } = useElementSize<HTMLDivElement>()
    const { ref: logoRef, width: logoWid, height: logoHei } = useElementSize<HTMLDivElement>()

    const [splitIndex, setSplitIndex] = useState(leftBtnList.child.length)
    const [LeftLinkSizeList, setLeftLinkSizeList] = useImmer<Record<string, { width: number; height: number; id: string }>>({})
    const userPopupState = usePopupState({ variant: 'popover', popupId: 'userMenuId' })

    const [currentPage, setCurrentPage] = useState(activePage)

    const [showList, hideList] = useMemo(() => {
        const splitNodeId = leftBtnList.child[splitIndex]
        return splitWhen<string, string>(draft => draft === splitNodeId, leftBtnList.child)
    }, [leftBtnList.child, splitIndex])

    useEffect(() => {
        setCurrentPage(activePage)
    }, [activePage, setCurrentPage])

    useEffect(() => {
        let currentBtnSize = showMode === NavigationShowMode.horizontal ? rightWid + logoWid : rightHei + logoHei
        const navSize = showMode === NavigationShowMode.horizontal ? navWid : navHei
        if (leftBtnList.child.some(id => !LeftLinkSizeList[id]?.width)) {
            setSplitIndex(leftBtnList.child.length)
            return
        }
        // 当渲染后，当前按钮大小加链接大小依然小于导航栏大小，且导航栏渲染完毕
        for (let i = 0; i < leftBtnList.child.length; i++) {
            const nodeId = leftBtnList.child[i]
            const { width, height, id } = LeftLinkSizeList[nodeId]
            const eleSize = showMode === NavigationShowMode.horizontal ? width : height
            const eleMargin = NavigationShowMode.horizontal ? 12 : 14
            currentBtnSize = currentBtnSize + eleSize + eleMargin
            // 当前导航栏大小减去一个导航链接大小
            if (navSize < currentBtnSize) {
                const newSplitIndex = i - 1
                return setSplitIndex(newSplitIndex < 0 ? 0 : newSplitIndex)
            }
        }
        setSplitIndex(leftBtnList.child.length)
    }, [LeftLinkSizeList, leftBtnList.child, leftHei, leftWid, logoHei, logoWid, navHei, navWid, rightHei, rightWid, showList, showMode])

    const isFold = splitIndex + 1 <= leftBtnList.child.length

    const handleChangeSize = useCallback(
        (params: { width: number; height: number; id: string }) => {
            setLeftLinkSizeList(draft => {
                draft[params.id] = params
            })
        },
        [setLeftLinkSizeList]
    )

    const handleCommonCallback = useCallback(
        (buttonParams: ButtonEvents) => {
            const { handleEvent, params } = buttonParams
            if (handleEvent === 'openPage') {
                const pageId = params?.[0] ?? ''
                setCurrentPage(pageId)
            }
            onCommonCallBack?.(buttonParams)
        },
        [setCurrentPage, onCommonCallBack]
    )

    const renderLinkBtn = useMemo(() => {
        return (
            <>
                {showList.map((nodeId, i) => {
                    const value = leftBtnList.list[nodeId]
                    const { icon, showType, id } = value
                    const isHideIcon = !icon || showType === 'name'
                    return showMode === NavigationShowMode.verticalWide ? (
                        <ApplicationMultistageCollapse
                            key={nodeId}
                            value={value}
                            activePage={currentPage}
                            level={0}
                            theme={theme}
                            dataList={leftBtnList.list}
                            dataNavLink={nodeId}
                            showType={showType}
                            // collapseAtom={collapseAtom}
                            onCommonCallBack={handleCommonCallback}
                        />
                    ) : (
                        <MultilevelSelect
                            key={nodeId}
                            value={value}
                            activePage={currentPage}
                            level={0}
                            theme={theme}
                            dataList={leftBtnList.list}
                            position={showMode === NavigationShowMode.horizontal ? 'bottom' : 'right'}
                            dataNavLink={nodeId}
                            onCommonCallBack={handleCommonCallback}
                            target={<ApplicationLinkItem value={value} activePage={currentPage} onCommonCallBack={handleCommonCallback} />}
                        />
                    )
                })}
                {isFold && hideList.length > 0 && (
                    <MultilevelSelect
                        theme={theme}
                        dataList={leftBtnList.list}
                        value={{ id: nanoid(16), child: hideList }}
                        position={showMode === NavigationShowMode.horizontal ? 'bottom' : 'right'}
                        level={0}
                        activePage={currentPage}
                        onCommonCallBack={handleCommonCallback}
                        style={{padding: '0 12px'}}
                        target={<SC.Icon size={22} color={color} type="PickOn" />}
                    />
                )}
                {/* 隐藏显示导航栏左侧导航栏链接 */}
                {leftBtnList.child.map((nodeId, i) => {
                    const value = leftBtnList.list[nodeId]
                    const { icon, showType, id } = value
                    const isHideIcon = !icon || showType === 'name'
                    return (
                        <MultilevelSelect
                            key={nodeId}
                            value={value}
                            activePage={currentPage}
                            level={0}
                            theme={theme}
                            dataList={leftBtnList.list}
                            position={showMode === NavigationShowMode.horizontal ? 'bottom' : 'right'}
                            dataNavLink={nodeId}
                            style={{ position: 'absolute', visibility: 'hidden' }}
                            onChangeSize={(id, size) => handleChangeSize({ ...size, id: nodeId })}
                            onCommonCallBack={handleCommonCallback}
                            target={
                                <ApplicationLinkItem
                                    value={value}
                                    activePage={currentPage}
                                    onCommonCallBack={handleCommonCallback}
                                    // dataList={dataList}
                                />
                            }
                        />
                    )
                })}
            </>
        )
    }, [
        showList,
        isFold,
        hideList,
        theme,
        leftBtnList.list,
        leftBtnList.child,
        showMode,
        currentPage,
        handleCommonCallback,
        color,
        handleChangeSize
    ])

    const renderBtn = useCallback(
        (params: ApplicationSettingNavbarButton) => {
            if (params.events.handleEvent === 'openPage' && excludePage.includes(params.events.params?.[0]) && userData?.userId) {
                return
            }
            return (
                <ApplicationNavBarButton
                    name={params.name}
                    theme={theme}
                    icon={showMode === NavigationShowMode.verticalNarrow ? '' : params.icon}
                    type={params.pattern ?? ButtonPattern.primary}
                    showType={params.showType}
                    onClick={() => onCommonCallBack?.(params.events)}
                />
            )
        },
        [onCommonCallBack, showMode, userData?.userId, theme]
    )

    const toolEle = useMemo(() => {
        if (!userData?.userId) {
            return languageBox
        }
        return (
            <SC.UserCenter>
                <Flex style={{ flex: 1, overflow: 'hidden' }} {...bindTrigger(userPopupState)} alignItems="center">
                    <UserCenter onLogout={onLogout} userData={userData}  position='right-end' onUpdateBindAccount={onUpdateBindAccount} />
                    {showMode === NavigationShowMode.verticalWide && (
                        <CM.Text isOmit color={color} style={{ marginLeft: 8 }}>
                            {userData?.username}
                        </CM.Text>
                    )}
                </Flex>
                <Flex>
                    {languageBox}
                    {notificationBox}
                </Flex>
            </SC.UserCenter>
        )
    }, [color, languageBox, notificationBox, onLogout, showMode, userData, userPopupState, onUpdateBindAccount])

    // const [targetDom, setTargetDom] = useState<HTMLDivElement>()
    // const [isRender, setIsRender] = useState(false)

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         const dom = document.querySelector(`#${PAGE_ROOT_SCROLL_CONTENT}`)
    //         setIsRender(Boolean(dom))
    //         dom && clearInterval(timer)
    //     }, 1000)

    //     // 防止一直未拿到dom，强制清空定时器
    //     setTimeout(() => {
    //         clearInterval(timer)
    //     }, 5000)

    //     return () => {
    //         clearInterval(timer)
    //     }
    // }, [])

    // useEffect(() => {
    //     if (!isRender) {
    //         return
    //     }
    //     let headerTarget = document.querySelector(`#${PAGE_SCROLL_HEADER}`)
    //     const targetEle = document.querySelector(`#${PAGE_ROOT_SCROLL_CONTENT}`)
    //     if (!headerTarget && targetEle) {
    //         const div = document.createElement('div')
    //         div.id = PAGE_SCROLL_HEADER
    //         headerTarget = div
    //         targetEle?.insertBefore(div, targetEle.children[0])
    //         setTargetDom(div)
    //     }
    // }, [isRender])

    // if (targetDom && !isSticky && showMode === NavigationShowMode.horizontal) {
    //     return createPortal(ele, targetDom)
    // }

    return (
        <ApplicationHeaderLayout
            mode={showMode}
            navbar={navbar}
            selected={selected}
            style={style}
            disableEvent={disableEvent}
            onClick={() => onSelect?.(true)}
        >
            <SC.Header id="NAVBAR_HEADER" style={{ color }} ref={navbarRef} className={cls({ backgroundBlur })}>
                <SC.LeftGroup ref={leftBtnGroupRef} style={{overflow: 'unset'}}>
                    <ApplicationLogo
                        appIcon={application?.icon}
                        appName={application?.name}
                        showMode={showMode}
                        logo={logo}
                        onToLink={onToLink}
                        ref={logoRef}
                    />
                    <SC.BtnGroup>{renderLinkBtn}</SC.BtnGroup>
                </SC.LeftGroup>
                <SC.RightGroup ref={rightBtnGroupRef}>
                    {buttonList.map(item => (
                        <React.Fragment key={item.id}>{renderBtn(item)}</React.Fragment>
                    ))}
                    {toolEle}
                </SC.RightGroup>
            </SC.Header>
        </ApplicationHeaderLayout>
    )
}
