import { styled } from '@byecode/ui/theme/stitches.config'

import { Popover } from '../Popover'

export const ColorPickerContainer = styled('div', {
    width: '243px',
    padding: '4px 0',
    borderRadius: 4,
    background: 'var(--color-white)',
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 8px 24px 0px rgba(53, 64, 90, 0.12)'
})

export const ColorPickerHeader = styled('div', {
    padding: '0 4px 0 4px'
})

export const ColorPickerNoColorWrapper = styled('div', {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '4px',
    cursor: 'pointer',
    borderRadius: '4px',
    fontSize: 12
})

export const ColorPickerNoColor = styled('div', {
    position: 'relative',
    width: '20px',
    height: '20px',
    border: '1px solid #e5e5e5',
    borderRadius: '4px',
    marginRight: '6px',

    '&::after': {
        content: '',
        position: 'absolute',
        left: 0,
        top: '-3px',
        width: '1px',
        height: '24px',
        backgroundColor: 'red',
        transform: 'translateX(9px) rotate(45deg)'
    }
})

export const ColorPickerPreferredColorsWrapper = styled('div', {
    display: 'flex',
    padding: '4px 8px 8px',
    flexWrap: 'wrap'
})

export const Divider = styled('div', {
    margin: '0 6px',
    height: '1px',
    backgroundColor: '$colorGray100'
})

export const ColorPickerPreferredColorsRowWrapper = styled('div', {
    display: 'flex'
})

export const ColorPickerColorCard = styled('div', {
    width: '20px',
    height: '20px',
    marginRight: '3px',
    marginBottom: '3px',
    borderRadius: '4px',
    cursor: 'pointer',

    '&:nth-child(10)': {
        marginRight: 0
    }
})

export const ColorPickerRecentColorsWrapper = styled('div', {})

export const ColorPickerRecentColorsTitle = styled('div', {
    padding: '6px 8px',
    fontSize: 'var(--font-size-normal)',
    color: 'var(--color-gray-6)'
})

export const ColorPickerRecentColorsContent = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '4px 8px 8px'
})

export const ColorPickerRecentColor = styled('div', {})

export const ColorPickerMoreWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '4px 4px 0 4px',
    padding: '4px',
    cursor: 'pointer'
})

export const ColorPickerPalette = styled('img', {
    width: 16,
    marginRight: 6
})

export const ColorPickerMore = styled('div', {
    flex: 1,
    borderRadius: '4px',
    fontSize: 'var(--font-size-normal)',
    color: 'var(--color-gray-9)',

    '&:hover': {
        backgroundColor: 'var(--color-gray-2)'
    }
})

export const ColorPickerPopover = styled(Popover, {})

export const Anchor = styled('div', {})

export const ColorPickerPopoverContent = styled(Popover.Dropdown, {})

/** 色板 */
export const ColorPickerColorBoardWrapper = styled('div', {
    padding: '8px',
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 8px 24px 0px rgba(53, 64, 90, 0.12)',
    borderRadius: 6,
    backgroundColor: '$colorWhite',
    overflow: 'hidden',
    userSelect: 'none'
})

export const ColorPickerColorBoardContent = styled('div', {
    width: '100%'
})

export const ColorPickerColorBoard = styled('div', {
    position: 'relative',
    height: '150px',
    overflow: 'hidden'
})

export const ColorPickerColorBoardCanvas = styled('div', {
    height: '100%',
    backgroundImage: 'linear-gradient(0deg, #000, transparent), linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0))'
})

export const ColorPickerColorBoardBar = styled('div', {
    position: 'absolute',
    width: '4px',
    height: '4px',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: '50%',
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 1.5px, rgba(0, 0, 0, 0.3) 0px 0px 1px 1px inset, rgba(0, 0, 0, 0.4) 0px 0px 1px 2px'
})

export const ColorPickerSliderWrapper = styled('div', {
    display: 'flex',
    margin: '4px 0'
})

export const ColorPickerSliderContent = styled('div', {
    flex: 1,
    marginLeft: 6
})

export const ColorPickerHueSlider = styled('div', {
    position: 'relative',
    height: '10px',
    marginBottom: '4px'
})

export const ColorPickerHueCanvas = styled('div', {
    height: '100%',
    backgroundColor: '#00b388',
    backgroundImage: 'linear-gradient(to right, red 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red 100%)',
    cursor: 'move'
})

export const ColorPickerHueBar = styled('div', {
    position: 'absolute',
    width: '3px',
    height: '100%',
    left: 0,
    top: 0,
    borderRadius: '1px',
    backgroundColor: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.6) 0px 0px 2px',
    pointerEvents: 'none'
})

export const ColorPickerAlphaSlider = styled('div', {
    position: 'relative',
    height: '10px'
})

export const ColorPickerAlphaCanvas = styled('div', {
    position: 'relative',
    height: '100%',
    cursor: 'move',

    '&:after': {
        display: 'block',
        content: '',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill-opacity=".05"><rect x="4" width="4" height="4"/><rect y="4" width="4" height="4"/></svg>')`,
        backgroundRepeat: 'repeat'
    }
})

export const ColorPickerAlphaBar = styled('div', {
    position: 'absolute',
    width: '3px',
    height: '100%',
    left: 0,
    top: 0,
    borderRadius: '1px',
    backgroundColor: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.6) 0px 0px 2px',
    pointerEvents: 'none'
})

export const ColorPickerColorPreviewer = styled('div', {
    width: 24,
    height: 24,
    borderRadius: 2
})

export const ColorPickerColorInputWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4px 0',
    fontSize: 12
})

export const ColorPickerHexInputWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
})

export const ColorPickerHexInput = styled('input', {
    width: 90,
    padding: '4px 6px',
    border: '1px solid #e5e5e5',
    borderRadius: '4px',
    marginBottom: 4
})

export const ColorPickerRgbaInputWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column'
})

export const ColorPickerRgbaInputGroup = styled('div', {
    display: 'flex',
    marginBottom: 4,
    gap: 6
})

export const ColorPickerRgbaInputItem = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
})

export const ColorPickerRgbaInput = styled('input', {
    width: 36,
    padding: '4px 6px',
    border: '1px solid #e5e5e5',
    borderRadius: 4,
    marginBottom: 4
})

export const DefaultColorCheckerWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center'
})

export const DefaultColorChecker = styled('div', {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    color: '#ffffff',
    backgroundColor: '#383838',
    padding: 4,
    marginRight: 6,
    borderRadius: 4
})

export const DefaultColorLabel = styled('div', {})
