import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

import type { SpinStylesNames } from './Spin.type'

export const useStyles = createStyles<SpinStylesNames>(() => ({
    root: css({
        position: 'relative'
    }),
    spin: css({
        position: 'absolute',
        inset: 0,
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255,255,255,0.56)'
    })
}))
