import { css } from 'styled-components'

/** 单行文本省略 */
export const singleTextEllipsis = () => css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

/** 数字专用字体 */
export const numberFontFamily = () => css`
    font-family: DIN-Alternate-Bold;
`
