import type { DTSelectItem, FieldADTValue, SelectField, SelectValue } from '@lighthouse/core'
import { clone, findIndex } from 'rambda'
import React, { useCallback, useMemo } from 'react'

import type { SelectChangeEvent } from '../TagSelect'
import { TagSelect } from '../TagSelect'
import * as SC from './styles'

interface SelectValueEditorProps {
    recordId: string
    field: SelectField
    data: SelectValue
    style?: React.CSSProperties
    sortable?: boolean
    autoFocus?: boolean
    onCellChange?: (recordId: string, field: FieldADTValue) => Promise<boolean>
    onClose?: (isActive?: boolean) => void
}

export const getNewValuesAndLabelsFromContent = (data: SelectValue, option: string, options: DTSelectItem[]) => {
    const prevValues = data
    const values = [...prevValues, option]
    const optionValues = new Set(options.map(({ label }) => label))
    const validatedValues = values.filter((value, index) => optionValues.has(value) && values.indexOf(value) === index)
    return { values: validatedValues }
}

export const SelectValueEditor: React.FC<SelectValueEditorProps> = ({
    recordId,
    field,
    data,
    style,
    sortable = false,
    autoFocus,
    onCellChange,
    onClose
}) => {
    const [selectValues, setSelectValues] = React.useState<SelectValue>(data)
    const { select } = field
    const { options, multiple } = select

    const handleMultiChange = useCallback(
        (ev: SelectChangeEvent) => {
            const { action, option } = ev
            let value: string[] = []
            switch (action) {
                case 'add': {
                    const oldValue = selectValues || []
                    const { values } = getNewValuesAndLabelsFromContent(oldValue, option, options)
                    value = values
                    break
                }

                case 'delete': {
                    const values: string[] = clone(selectValues || [])
                    const deleteIndex = findIndex(val => val === option, values)
                    if (deleteIndex !== -1) {
                        values.splice(deleteIndex, 1)
                        value = values
                    }
                    break
                }
                default: {
                    break
                }
            }
            setSelectValues(value)
            onCellChange?.(recordId, { ...field, type: 'select', value })
        },
        [field, onCellChange, options, recordId, selectValues]
    )

    const handleSingleChange = useCallback(
        (ev: SelectChangeEvent) => {
            const { action, option } = ev
            let value: string[] = []

            switch (action) {
                case 'add': {
                    value = [option]
                    break
                }
                case 'delete': {
                    const values: string[] = clone(selectValues || [])
                    const deleteIndex = findIndex(val => val === option, values)
                    if (deleteIndex !== -1) {
                        values.splice(deleteIndex, 1)
                        value = values.slice(0, 1)
                    }
                    break
                }
                default: {
                    break
                }
            }
            setSelectValues(value)
            onCellChange?.(recordId, { ...field, type: 'select', value })
            onClose?.()
        },
        [field, onCellChange, onClose, recordId, selectValues]
    )

    const selectOptions = useMemo(
        () =>
            options.map(({ label, color }) => ({
                label,
                value: label,
                color
            })),
        [options]
    )

    return (
        <SC.TagSelectWrapper style={style}>
            <TagSelect
                description="搜索"
                sortable={sortable}
                multiple={multiple}
                value={selectValues}
                autoFocus={autoFocus}
                options={selectOptions}
                onChange={multiple ? handleMultiChange : handleSingleChange}
            />
        </SC.TagSelectWrapper>
    )
}
