import { Empty } from '@byecode/ui/components/Empty'
import { getAssetUrl } from '@lighthouse/assets'
import type { VariableADTvalue } from '@lighthouse/core'
import { mergeRefs } from '@lighthouse/tools'
import React, { forwardRef, Fragment, useMemo, useRef, useState } from 'react'
import { useLatest } from 'react-use'

import { useMediaQueryRemBase } from '../../../hooks/useMediaQueryRemBase'
import { Canvas } from '../Base/Canvas'
import { NodeRender } from '../Base/Entry'
import { Gap } from '../Base/Gap'
import { Padding } from '../Base/Padding'
import type { ContainerHighlight, FlowLayoutNode, LayoutProperty, NodeRenderProps } from '../types'
import { findNodeById } from '../utils/common'
import { getContainerEmptyPosition, getGapPosition, getNodeMaskPosition, getRootPosition } from '../utils/indicator'
import { ResizeEventsProvider, SortableContainerContext, useSortableMonitor } from './Context'
import { DragOverlay } from './DragOverlay'
import { SortableNodeRender } from './NodeRender'
import type { ResizeEventsOptions } from './types'

const CONTAINER_EMPTY_TARGETS = new Set([
    'padding-top',
    'padding-right',
    'padding-left',
    'padding-bottom',
    'placeholder-begin',
    'placeholder-after'
])

interface SortableEntryProps {
    data: FlowLayoutNode[]
    dragOverlay?: React.ReactNode

    size?: LayoutProperty['size']
    gap?: LayoutProperty['gap']
    direction?: LayoutProperty['direction']
    padding?: LayoutProperty['padding']
    alignX?: LayoutProperty['alignX']
    alignY?: LayoutProperty['alignY']
    veins?: LayoutProperty['veins']
    background?: LayoutProperty['background']
    style?: React.CSSProperties

    parseBackgroundVariableImage?: (value: VariableADTvalue | undefined) => string

    disabled?: boolean
    scale?: number
    highlight?: Record<string, ContainerHighlight>
    nodeRender?: (props: NodeRenderProps) => JSX.Element | null
    labelRender?: (id: string) => React.ReactNode

    onResizeStart?: (options: ResizeEventsOptions) => void
    onResizing?: (options: ResizeEventsOptions) => void
    onResizeEnd?: (options: Omit<ResizeEventsOptions, 'coordinates'>) => void
    onFillSize?: (nodeId: string) => void

    onDataDrawerVisible?: (val: boolean) => void
}

export const SortableEntry = forwardRef<HTMLDivElement, SortableEntryProps>(
    (
        {
            data,
            dragOverlay,

            size,
            gap,
            direction,
            padding,
            alignX,
            alignY,
            background,
            veins,
            style,

            parseBackgroundVariableImage,
            disabled,
            highlight,
            scale,
            nodeRender,
            labelRender,

            onResizeStart,
            onResizing,
            onResizeEnd,
            onFillSize,

            onDataDrawerVisible
        },
        ref
    ) => {
        const { x, y, activeId, activeRect, overDescriptor, onSelectedIdChange, onBoxSelectionIdsChange, draggableNodes } =
            useSortableMonitor()

        const activeNode = useMemo(() => (activeId ? findNodeById(activeId)(data) : null), [activeId, data])

        const [isResizing, setIsResizing] = useState(false)
        const latestProps = useLatest({
            isResizing,
            onResizeStart: (options: ResizeEventsOptions) => {
                setIsResizing(true)
                onResizeStart?.(options)
            },
            onResizing: (options: ResizeEventsOptions) => {
                window.getSelection()?.removeAllRanges()
                onResizing?.(options)
            },
            onResizeEnd: (options: Omit<ResizeEventsOptions, 'coordinates'>) => {
                setIsResizing(false)
                onResizeEnd?.(options)
            },
            onFillSize
        })

        const innerRef = useRef<HTMLDivElement>(null)

        const remBase = useMediaQueryRemBase()

        const indicatorInfo = useMemo(() => {
            if (!overDescriptor || !activeRect.current) {
                return
            }

            if (!innerRef.current) {
                return
            }

            const rootRect = innerRef.current.getBoundingClientRect()

            const params = {
                nodes: data,
                draggableNodes: draggableNodes.current,
                coordinate: { x, y },
                overDescriptor,
                activeRect: activeRect.current,
                rootLayoutConfigure: {
                    size,
                    rect: rootRect,
                    padding,
                    gap,
                    alignX,
                    alignY
                },
                remBase
            }
            if (CONTAINER_EMPTY_TARGETS.has(overDescriptor.target)) {
                return getContainerEmptyPosition(params)
            }

            if (overDescriptor.target === 'gap') {
                return getGapPosition(params)
            }

            if (overDescriptor.target === 'node-mask') {
                return getNodeMaskPosition(params)
            }

            if (overDescriptor.target === 'root') {
                return getRootPosition(params)
            }
        }, [activeRect, alignX, alignY, data, draggableNodes, gap, overDescriptor, padding, remBase, size, x, y])

        return (
            <ResizeEventsProvider value={latestProps.current}>
                <Canvas
                    ref={mergeRefs([innerRef, ref])}
                    data-type="root"
                    tabIndex={0}
                    size={size}
                    scale={scale}
                    gap={gap}
                    direction={direction}
                    padding={padding}
                    alignX={alignX}
                    alignY={alignY}
                    background={background}
                    veins={veins}
                    parseBackgroundVariableImage={parseBackgroundVariableImage}
                    disabled={disabled}
                    highlight={highlight}
                    style={style}
                    nodeRender={nodeRender}
                    labelRender={labelRender}
                    onClick={e => {
                        if (e.target === innerRef.current) {
                            onSelectedIdChange?.(undefined)
                            if (e.shiftKey) {
                                return
                            }
                            onBoxSelectionIdsChange?.([])
                        }
                    }}
                >
                    {data.length === 0 && !indicatorInfo && (
                        <Empty
                            style={{ pointerEvents: 'none' }}
                            icon={<img style={{ width: 315 }} src={getAssetUrl('empty', 'no_block.svg')} alt="未找到数据" />}
                            description="点击左侧【添加】，拖拽「组件」到此区域"
                        />
                    )}
                    <SortableContainerContext items={data.map(item => item.id)}>
                        {data.map((item, index, array) => (
                            <Fragment key={item.id}>
                                <SortableNodeRender data={item} onDataDrawerVisible={onDataDrawerVisible} />
                                {index !== array.length - 1 && <Gap data-relative-id={item.id} />}
                            </Fragment>
                        ))}
                        <DragOverlay>{dragOverlay || (activeNode && <NodeRender unstyled data={activeNode} />)}</DragOverlay>
                    </SortableContainerContext>

                    {indicatorInfo && (
                        <div
                            style={{
                                position: 'absolute',
                                pointerEvents: 'none',
                                borderRadius: 3,
                                background: 'var(--color-main)',
                                width: indicatorInfo.width,
                                height: indicatorInfo.height,
                                top: 0,
                                left: 0,
                                transform: `translate(${indicatorInfo.left / (scale ?? 1)}px, ${indicatorInfo.top / (scale ?? 1)}px)`
                            }}
                        />
                    )}
                    <Padding
                        data-type="padding-top"
                        data-highlight={!!highlight?.root?.paddingY}
                        style={{ top: 0, left: 0, width: '100%', height: 'var(--container-padding-top)' }}
                    />
                    <Padding
                        data-type="padding-right"
                        data-highlight={!!highlight?.root?.paddingX}
                        style={{ top: 0, right: 0, width: 'var(--container-padding-right)', height: '100%' }}
                    />
                    <Padding
                        data-type="padding-bottom"
                        data-highlight={!!highlight?.root?.paddingY}
                        style={{ bottom: 0, left: 0, width: '100%', height: 'var(--container-padding-bottom)' }}
                    />
                    <Padding
                        data-type="padding-left"
                        data-highlight={!!highlight?.root?.paddingX}
                        style={{ top: 0, left: 0, width: 'var(--container-padding-left)', height: '100%' }}
                    />
                </Canvas>
            </ResizeEventsProvider>
        )
    }
)
