import { Loading } from '@byecode/ui'
import cls from 'classnames'
import { filter, find, findIndex, isEmpty, length, max } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useUpdateEffect } from 'react-use'
import { useImmer } from 'use-immer'

import { COLORS_MAP, LIGHT_COLORS_MAP } from '../../constants/color'
import { List } from '../List'
import * as SC from './styles'
import type { ChangeAction, TagSelectItemValueType, TagSelectOption, TagSelectProps } from './types'

export * from './types'

const stringToArray = (value: string | undefined | string[]) => {
    const isArray = Array.isArray(value)
    if (isArray) {
        return value
    }
    return value ? [value] : []
}

const findOptionByValue = (options: TagSelectOption[], value: string) => {
    return find(item => item.value === value, options)
}

export const TagSelect = React.forwardRef<HTMLDivElement, TagSelectProps>(
    (
        {
            defaultValue,
            defaultSeaWord,
            multiple,
            sortable = true,
            description,
            loading,
            value,
            options = [],
            autoFocus = true,
            disabledRemove,
            disabledSearch,
            tagItemRender,
            emptyItemRender,
            listItemRender,
            onChange,
            onSortEnd
        },
        ref
    ) => {
        const [state, setState] = useImmer({
            value: stringToArray(value || defaultValue),
            innerOptions: options
        })
        // const [newColor, setNewColor] = useState(generateRandomColor())
        const { innerOptions } = state
        const [keywords, setKeywords] = useState(defaultSeaWord || '')
        const [hoveredIndex, setHoveredIndex] = useState(-1)
        const cursorRef = useRef<HTMLDivElement>(null)

        const optionAdderValue = useMemo(() => {
            return !find(option => option.label.includes(keywords), innerOptions) && keywords
            // return !find(label => label === keywords, innerOptions) && keywords
        }, [innerOptions, keywords])

        const filteredOptions = useMemo(() => {
            const { innerOptions } = state
            return filter(option => option.label?.includes?.(keywords), innerOptions)
        }, [state, keywords])

        const clearInput = useCallback(() => {
            setKeywords('')
            cursorRef.current?.textContent && (cursorRef.current.textContent = '')
        }, [])

        const invokeChange = useCallback(
            (action: ChangeAction, option: string | undefined) => {
                option && onChange?.({ action, option })
            },
            [onChange]
        )

        const handleItemClick = useCallback(
            (value: TagSelectItemValueType) => {
                setState(draft => {
                    const isExists = draft.value.includes(value)
                    if (multiple && !isExists) {
                        draft.value.push(value)
                        invokeChange('add', findOptionByValue(state.innerOptions, value)?.value)
                    }
                    if (!multiple && (!isExists || draft.value.length > 1)) {
                        draft.value = [value]
                        invokeChange('add', findOptionByValue(state.innerOptions, value)?.value)
                    }
                })
            },
            [invokeChange, multiple, setState, state.innerOptions]
        )

        useHotkeys(
            'up, down, enter, backspace',
            (event, handler) => {
                const adderExtraPit = optionAdderValue ? 1 : 0
                switch (handler.key) {
                    case 'up': {
                        event.preventDefault()
                        setHoveredIndex(index => {
                            const prevIndex = max(index - 1, -1)
                            const haveAdderExtraPitOptionsLength = length(filteredOptions) + adderExtraPit
                            return (prevIndex + haveAdderExtraPitOptionsLength) % haveAdderExtraPitOptionsLength
                        })
                        break
                    }

                    case 'down': {
                        event.preventDefault()
                        setHoveredIndex(index => {
                            const prevIndex = index + 1
                            return prevIndex % (length(filteredOptions) + adderExtraPit)
                        })
                        break
                    }

                    case 'enter': {
                        const cursorId = optionAdderValue
                        const hoveredId = hoveredIndex !== -1 && filteredOptions[hoveredIndex]?.value
                        // default to get label value from shortcut hovered
                        const id = hoveredId || cursorId
                        if (!id || !id?.trim()) {
                            return
                        }
                        const option = find(op => op.value === id, options)
                        // if option already in value, ensure select it
                        if (option) {
                            handleItemClick(option.value)
                            clearInput()
                        }
                        break
                    }

                    case 'backspace': {
                        if (cursorRef.current?.textContent) {
                            return
                        }
                        setState(draft => {
                            const deletedValue = draft.value.pop()
                            deletedValue && invokeChange('delete', findOptionByValue(filteredOptions, deletedValue)?.value)
                        })
                        break
                    }

                    default: {
                        break
                    }
                }
            },
            { enableOnTags: ['INPUT'], enableOnContentEditable: true },
            [optionAdderValue, keywords, hoveredIndex, options]
        )

        const handleRemove = useCallback(
            (id: TagSelectItemValueType) => {
                setState(draft => {
                    const { value: draftValue } = draft
                    const deletedIndex = findIndex(c => id === c, draftValue)
                    if (deletedIndex !== -1) {
                        const [deletedValue] = draftValue.splice(deletedIndex, 1)
                        if (deletedValue) {
                            invokeChange('delete', deletedValue)
                        }
                    }
                })
            },
            [invokeChange, setState]
        )

        const handleSearchValueChange = useCallback((label: string) => {
            setKeywords(label.trim())
        }, [])

        const inputFocus = useCallback(() => {
            cursorRef.current?.focus()
        }, [])

        const listView = loading ? (
            <Loading />
        ) : (
            <>
                <List
                    sortable={sortable}
                    hoveredIndex={hoveredIndex}
                    data={filteredOptions.filter(item => !item?.isHidden)}
                    description={description}
                    itemRender={listItemRender}
                    onItemClick={handleItemClick}
                    onSortEnd={onSortEnd}
                />
            </>
        )

        useUpdateEffect(() => {
            setState(draft => {
                draft.innerOptions = options
            })
        }, [options, setState])

        useEffect(() => {
            defaultSeaWord || clearInput()
            // need clear input value As long as the state changes
        }, [clearInput, state, defaultSeaWord])

        useEffect(() => {
            // 聚焦问题 会引起移动端drawer动画问题
            if (autoFocus) {
                inputFocus()
            }
        }, [autoFocus, inputFocus])

        return (
            <SC.TagSelectWrapper
                ref={ref}
                onMouseDown={ev => {
                    ev.stopPropagation()
                }}
            >
                <SC.TagSelectSearch onClick={inputFocus}>
                    {state.value.map(id => {
                        const option = find(item => item.value === id, state.innerOptions)
                        if (!option) {
                            if (emptyItemRender) {
                                return (
                                    <SC.TagItem key={id} className={cls({ 'plain-container': tagItemRender !== undefined })}>
                                        {emptyItemRender(id)}
                                        {!disabledRemove && (
                                            <SC.CloseIcon
                                                type="Close"
                                                onClick={() => {
                                                    handleRemove(id)
                                                }}
                                            />
                                        )}
                                    </SC.TagItem>
                                )
                            }
                            return null
                        }

                        const { color = '', label } = option
                        const colorLightStr = LIGHT_COLORS_MAP[color]
                        const colorStr = COLORS_MAP[color]
                        return (
                            <SC.TagItem
                                key={id}
                                className={cls({ 'plain-container': tagItemRender !== undefined })}
                                style={
                                    tagItemRender
                                        ? {}
                                        : {
                                              borderColor: 'rgba(0,0,0,0.1)',
                                              backgroundColor: colorLightStr && `var(${colorLightStr})`,
                                              color: colorStr && `var(${colorStr})`
                                          }
                                }
                            >
                                {tagItemRender ? tagItemRender(option) : <SC.TagItemContent>{label}</SC.TagItemContent>}
                                {!disabledRemove && (
                                    <SC.CloseIcon
                                        type="Close"
                                        onClick={() => {
                                            handleRemove(id)
                                        }}
                                    />
                                )}
                            </SC.TagItem>
                        )
                    })}
                    {!disabledSearch && (
                        <SC.Cursor
                            contentEditable
                            ref={cursorRef}
                            onInput={ev => handleSearchValueChange(ev.currentTarget.textContent ?? '')}
                        />
                    )}
                </SC.TagSelectSearch>
                {listView}
            </SC.TagSelectWrapper>
        )
    }
)
