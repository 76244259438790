import type { ActionFlowNode, CopyActionConfig } from '@lighthouse/shared'
import { generateVariableValue } from '@lighthouse/shared'
import copyToClipboard from 'copy-to-clipboard'
import type { AnyObject } from 'immer/dist/internal'

export const handleCopy = async (node: ActionFlowNode<CopyActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node
    const { copyText } = config as CopyActionConfig

    const text = generateVariableValue({  innerType: 'TEXT', jsonContent: copyText, extraParams: excParams })
    if (text) {
        copyToClipboard(Array.isArray(text) ? '' : text)
    }

    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        copyText: text
    })
}
