import type { AlignMent, BlockAbstract, ButtonItem, FieldBlockAbstract, InputValueItem, SubFormBlockAbstract } from '@lighthouse/core'
import { type FieldInputADTValue, BlockType, ButtonPattern, PageType, VariableType } from '@lighthouse/core'
import type { FieldInputErrors, FormModuleType, SubFormErrors } from '@lighthouse/shared'
import {
    ActionButton,
    FormModuleProvider,
    getEmptyFieldInputValue,
    isEmptyFieldInputValue,
    ThemeButton,
    useApplicationContext
} from '@lighthouse/shared'
import equal from 'fast-deep-equal'
import { clone, find, findIndex, forEach, mergeDeepRight, reduce } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAsync, useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import type { SubFormRecord } from '../../blocks'
import { ContainerLayout } from '../ContainerLayout'
import {
    getFieldBlockInfo,
    getFormContainerChildBlocks,
    getFormErrors,
    getPageNode,
    getSaveFieldInputList,
    getSubFormErrors,
    getSubFormListErrors,
    toErrorFocusBlockId
} from './help'
import type { FieldBlockInfo, PageModuleFormProps } from './types'

const SCxContainer = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: all 0.3s ease-in-out;
`

const SCxButtonContainer = styled.div<Pick<React.CSSProperties, 'justifyContent'>>`
    padding: 8px var(--block-padding) 0 var(--block-padding);
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent};
`

const buttonAlginMap: Record<AlignMent, string> = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
}

export const PageModuleForm: React.FC<PageModuleFormProps> = props => {
    const {
        children,
        blocks,
        node,
        userRecord,
        dataSource,
        isMobile,
        record,
        nodes,
        block,
        dataSourceList,
        pageType,
        onVariableValueRender,
        onRenderTitle,
        onSubmit
    } = props

    const { config, id: blockId } = block

    const { pointer = '', submitButton } = config ?? {}

    const formRef = useRef<HTMLFormElement>(null)
    const [usedBlocks, setUsedBlocks] = useImmer<BlockAbstract[]>(blocks)

    const { isBuilder } = useApplicationContext()

    const [isValid, setIsValid] = useState(false)
    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useImmer<FieldInputErrors>({})

    const fieldBlockInfoList = useMemo(() => {
        const node = getPageNode(nodes, blockId)
        return node
            ? getFormContainerChildBlocks<FieldBlockAbstract>({ node, blocks: usedBlocks, blockType: BlockType.field }).map(v =>
                  getFieldBlockInfo(v)
              )
            : []
    }, [blockId, usedBlocks, nodes])

    const subFormBlockConfigList = useMemo(() => {
        const node = getPageNode(nodes, blockId)
        return node ? getFormContainerChildBlocks<SubFormBlockAbstract>({ node, blocks, blockType: BlockType.subForm }) : []
    }, [blockId, blocks, nodes])

    useEffect(() => {
        setUsedBlocks(list => {
            if (list.length !== blocks.length) {
                return blocks
            }
            list.forEach((block, i) => {
                if (block.type === 'field' || block.type === 'formContainer') {
                    const newBlock = blocks.find(v => v.id === block.id)
                    if (newBlock && !equal(block, newBlock)) {
                        list[i] = newBlock
                    }
                }
            })
        })
    }, [blocks, setUsedBlocks])

    const { schema } = dataSource ?? {}

    const emptyInputValueList = useMemo(
        () =>
            fieldBlockInfoList.map(item => {
                const {
                    inputType,
                    id,
                    fieldId,
                    config: { noRepeat: isCheckRepeat }
                } = item
                const field = schema?.[fieldId]
                return {
                    value: getEmptyFieldInputValue(inputType, field?.type ?? 'text'),
                    type: inputType,
                    isCheckRepeat,
                    id,
                    fieldId,
                    source: 'form',
                    fieldType: field?.type
                } as InputValueItem
            }),
        [fieldBlockInfoList, schema]
    )

    const [initInputValueList, setInitInputValueList] = useImmer<InputValueItem[]>(emptyInputValueList)

    useAsync(async () => {
        const [normalFieldBLockList, selectDataSourceVariableFieldBlockList] = reduce<FieldBlockInfo, [FieldBlockInfo[], FieldBlockInfo[]]>(
            (pre, fieldBlockInfo) => {
                const isSelectDataSourceVariable = fieldBlockInfo?.initialValue?.type === VariableType.SELECT_DATASOURCE
                isSelectDataSourceVariable ? pre[1].push(fieldBlockInfo) : pre[0].push(fieldBlockInfo)
                return pre
            },
            [[], []],
            fieldBlockInfoList
        )
        const fieldValueList = await Promise.all(
            normalFieldBLockList.map(item => {
                return onVariableValueRender(item)
            })
        )
        const fieldValueMap: Record<string, InputValueItem> = Object.fromEntries(fieldValueList.map(item => [item.id, item]))
        const selectDataSourceFieldValueList = await Promise.all(
            selectDataSourceVariableFieldBlockList.map(item => {
                return onVariableValueRender(item, fieldValueMap)
            })
        )
        const allValueList = [...fieldValueList, ...selectDataSourceFieldValueList]
        setInitInputValueList(
            reduce<FieldBlockInfo, InputValueItem[]>(
                (preVal, curVal) => {
                    const valueItem = find(v => v.id === curVal.id, allValueList)
                    if (valueItem) {
                        preVal.push(valueItem)
                        return preVal
                    }
                    return preVal
                },
                [],
                fieldBlockInfoList
            )
        )
    }, [fieldBlockInfoList, onVariableValueRender])

    // 表单中输入框数据
    const [inputList, setInputList] = useImmer(initInputValueList)

    // 表单中子表单数据
    const [subFormDataMap, setSubFormDataMap] = useImmer<Record<string, SubFormRecord[]>>({})
    const [subFormErrors, setSubFormErrors] = useImmer<SubFormErrors>([])

    const getIsCanSubmit = useCallback(
        (inputList: InputValueItem[], errors: FieldInputErrors) => {
            const [isRequiredEmpty, isAllEmpty] = reduce<InputValueItem, [boolean, boolean]>(
                (preVal, curVal, index) => {
                    const blockConfig = find(item => item.id === curVal.id, fieldBlockInfoList)
                    const field = schema?.[curVal.fieldId]
                    const isEmpty = isEmptyFieldInputValue(curVal, field?.type)
                    return [Boolean((blockConfig?.config?.required && isEmpty) || preVal[0]), isEmpty && preVal[1]]
                },
                [false, true],
                inputList
            )
            const isExitError = Object.entries(errors).some(([blockId, error]) => error && Object.keys(error).length > 0)
            return isAllEmpty || isExitError
        },
        [fieldBlockInfoList, schema]
    )
    const getSubscribeFieldInput = useCallback(
        (blockId: string) =>
            fieldBlockInfoList.filter(item => {
                const conditions =
                    item.config?.initialValue?.type === VariableType.SELECT_DATASOURCE
                        ? item.config.initialValue.selectDataSourceVariable?.filter?.expression?.conditions
                        : []
                return conditions?.some(condition => {
                    const variable = condition.paramList?.[0]
                    if (variable?.type === VariableType.INPUT) {
                        return variable.inputVariable.blockId === blockId
                    }
                    return false
                })
            }),
        [fieldBlockInfoList]
    )

    const handleChangeInputItem = useCallback(
        async (blockId: string, inputList: InputValueItem[]) => {
            const fieldValueMap = Object.fromEntries(inputList.map(item => [item.id, item]))
            const changeInputList = getSubscribeFieldInput(blockId)
            const newInputList = await Promise.all(changeInputList.map(item => onVariableValueRender(item, fieldValueMap)))
            newInputList.forEach(input => {
                setInitInputValueList(list => {
                    const index = findIndex(item => item.id === input.id, list)
                    if (index !== -1) {
                        list[index].value = input.value
                    }
                })
            })
        },
        [getSubscribeFieldInput, onVariableValueRender, setInitInputValueList]
    )

    const prevInitInputValueListRef = useRef(initInputValueList)
    useUpdateEffect(() => {
        const isChangeNum = prevInitInputValueListRef.current.length !== initInputValueList.length
        if (isChangeNum) {
            setInputList(initInputValueList)
            prevInitInputValueListRef.current = initInputValueList
            return
        }
        forEach(item => {
            const prevInitInputValue = find(prev => prev.id === item.id, prevInitInputValueListRef.current)
            if (!equal(prevInitInputValue, item)) {
                setInputList(list => {
                    const index = findIndex(input => input.id === item.id, list)
                    if (index !== -1) {
                        list[index] = item
                    }
                })
                setErrors({})
            }
        }, initInputValueList)
        prevInitInputValueListRef.current = initInputValueList
    }, [initInputValueList])

    const handleChange = useCallback(
        (blockId: string, value: FieldInputADTValue) => {
            const isRequired = find(item => item.id === blockId, fieldBlockInfoList)
            setErrors(errors => {
                const blockError = errors[blockId]
                if (blockError) {
                    Reflect.deleteProperty(blockError, 'repeat')
                    !isEmptyFieldInputValue(value) && Reflect.deleteProperty(blockError, 'required')
                }
            })
            setInputList(draft => {
                const index = findIndex(item => item.id === blockId, draft)
                if (index !== -1) {
                    draft[index].value = value.value
                }
                handleChangeInputItem(blockId, draft)
            })
        },
        [fieldBlockInfoList, handleChangeInputItem, setErrors, setInputList]
    )

    const handleSubFormInputChange = useCallback(
        (blockId: string, recordId: string, columnId: string, fieldValue: FieldInputADTValue) => {
            setSubFormDataMap(draft => {
                const subFormRecords = draft[blockId]
                if (!subFormRecords) {
                    draft[blockId] = []
                }
                const record = find(i => i.id === recordId, subFormRecords)
                if (!record) {
                    return
                }
                record.content[columnId].value = fieldValue.value
                setSubFormErrors(errors => {
                    const blockErrorIndex = findIndex(
                        v => v.blockId === blockId && recordId === v.recordId && v.columnId === columnId,
                        errors
                    )
                    if (blockErrorIndex !== -1) {
                        errors.splice(blockErrorIndex, 1)
                    }
                })
            })
        },
        [setSubFormDataMap, setSubFormErrors]
    )

    const handleSubFormInputBlur = useCallback(
        (blockId: string, recordId: string, columnId: string) => {
            const block = subFormBlockConfigList.find(v => v.id === blockId)
            if (!block) {
                return
            }
            const {
                config: { columns }
            } = block
            const newBlock = mergeDeepRight<SubFormBlockAbstract>(block, {
                config: { columns: columns.map(column => ({ ...column, config: { ...column.config, required: false } })) }
            })
            const subFormErrors = getSubFormErrors({
                value: subFormDataMap[blockId],
                block: newBlock,
                dataSourceList: dataSourceList ?? []
            })
            setSubFormErrors(subFormErrors)
        },
        [dataSourceList, setSubFormErrors, subFormBlockConfigList, subFormDataMap]
    )

    const handleSmsCodeChange = useCallback(
        (blockId: string, code: string) => {
            setInputList(draft => {
                const index = findIndex(item => item.id === blockId, draft)
                if (index !== -1) {
                    draft[index].code = code
                }
            })
        },
        [setInputList]
    )

    const handleSubmit = useCallback(
        async (ev: React.FormEvent<HTMLFormElement>) => {
            ev.preventDefault()
            if (!schema) {
                return
            }
            setIsValid(true)
            const saveInputList = getSaveFieldInputList(inputList, userRecord)
            // 当还有未修改的error项
            const errors = getFormErrors({ value: saveInputList, configs: fieldBlockInfoList, schema, repeatList: [] })
            const subFormErrors = getSubFormListErrors({
                value: subFormDataMap,
                blocks: subFormBlockConfigList,
                dataSourceList: dataSourceList ?? [],
                subFormListRepeat: []
            })
            const isExistSubFormError = subFormErrors.some(v => v.error && Object.keys(v.error).length > 0)
            setErrors(errors)
            setSubFormErrors(subFormErrors)
            if (getIsCanSubmit(saveInputList, errors) || isExistSubFormError) {
                toErrorFocusBlockId({ inputList: saveInputList, errors, schema })
                return
            }
            if (!loading) {
                setLoading(true)
                const res = await onSubmit?.(saveInputList, pointer, subFormDataMap)
                const { repeatFieldIds, record, subFormRepeat } = res ?? {}
                if (record) {
                    if (pageType !== PageType.edit) {
                        setInputList(initInputValueList)
                        setSubFormDataMap(draft => {
                            Object.entries(draft).forEach(([blockId]) => {
                                draft[blockId] = []
                            })
                        })
                    }
                    setIsValid(false)
                }
                const errors = getFormErrors({ value: saveInputList, configs: fieldBlockInfoList, schema, repeatList: repeatFieldIds })
                const subFormErrors = getSubFormListErrors({
                    value: subFormDataMap,
                    blocks: subFormBlockConfigList,
                    dataSourceList: dataSourceList ?? [],
                    subFormListRepeat: subFormRepeat
                })
                if ((repeatFieldIds ?? []).length > 0) {
                    toErrorFocusBlockId({ inputList: saveInputList, errors, schema })
                }
                setErrors(errors)
                setSubFormErrors(subFormErrors)
                setLoading(false)
            }
        },
        [
            dataSourceList,
            fieldBlockInfoList,
            getIsCanSubmit,
            initInputValueList,
            inputList,
            loading,
            onSubmit,
            pageType,
            pointer,
            schema,
            setErrors,
            setInputList,
            setSubFormDataMap,
            setSubFormErrors,
            subFormBlockConfigList,
            subFormDataMap,
            userRecord
        ]
    )

    const formValue: FormModuleType = useMemo(
        () => ({
            defaultValue: {
                inputList,
                initInputList: initInputValueList,
                subFormDataMap
            },
            pointer,
            isValid,
            errors,
            subFormErrors,
            type: 'form',
            dataSource,
            record,
            onChange: handleChange,
            onCodeChange: handleSmsCodeChange,
            onChangeSubForm: setSubFormDataMap,
            onSubFormInputBlur: handleSubFormInputBlur,
            onSubFormInputChange: handleSubFormInputChange
        }),
        [
            inputList,
            initInputValueList,
            subFormDataMap,
            pointer,
            isValid,
            errors,
            subFormErrors,
            dataSource,
            record,
            handleChange,
            handleSmsCodeChange,
            setSubFormDataMap,
            handleSubFormInputBlur,
            handleSubFormInputChange
        ]
    )

    const buttonConfig: ButtonItem = useMemo(
        () => ({
            id: '',
            action: {
                type: 'none',
                trigger: 'click',
                data: {
                    none: {}
                }
            },
            events: {
                triggerEvent: 'click',
                handleEvent: 'openPage',
                params: []
            },
            ...submitButton,
            fillWay: isMobile ? 'contain' : 'auto'
        }),
        [isMobile, submitButton]
    )

    return (
        <FormModuleProvider value={formValue}>
            <SCxContainer ref={formRef} onSubmit={handleSubmit} data-stop-action-propagation>
                <ContainerLayout
                    node={node}
                    disabled={!isBuilder}
                    extraSlot={
                        node.type === 'container' &&
                        (node.children ?? []).length > 0 && (
                            <SCxButtonContainer justifyContent={buttonAlginMap[submitButton.align]}>
                                <ActionButton
                                    radius={submitButton.radius}
                                    size={submitButton.size}
                                    config={buttonConfig}
                                    loading={loading}
                                    isSubmit
                                    data-stop-action-propagation
                                    onRenderTitle={onRenderTitle}
                                />
                            </SCxButtonContainer>
                        )
                    }
                >
                    {children}
                </ContainerLayout>
            </SCxContainer>
        </FormModuleProvider>
    )
}
