import type { RecordLikeProtocol } from '@lighthouse/core'

export type SortItemsDataWithKey = RecordLikeProtocol & { key: string }

export const findContainer = (id: string, items: Record<PropertyKey, SortItemsDataWithKey[]>) => {
    if (id in items) {
        return id
    }
    return Object.keys(items).find(k => items[k].some(i => i.key === id))
}
