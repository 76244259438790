import styled from 'styled-components'

export const FallbackContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`

export const DividerContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`

export const DividerLine = styled.div`
    height: 1px;
    width: 100%;
    background: var(--color-gray-100);
`
