import type { OpenFormPageActionConfig } from '@lighthouse/shared'
import { type ActionFlowNode } from '@lighthouse/shared'
import { wait } from '@lighthouse/tools'
import type { AnyObject } from 'immer/dist/internal'

export const handleOpenFormPage = async (node: ActionFlowNode<OpenFormPageActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node
    const { actionEvents, clickTriggerNodeParams } = excParams

    const { formPageId, pageOpenType } = config as OpenFormPageActionConfig
    const { openPage, refreshPage } = actionEvents

    const { record } = clickTriggerNodeParams

    if (!formPageId || !actionEvents) {
        return excParams
    }

    openPage({
        pageId: formPageId,
        openType: pageOpenType,
        appId: record?.appId,
        dsId: record?.dsId,
        recordId: record?.id,
        onSaveCallback: () => {
            refreshPage()
        }
    })

    await wait(8)

    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        success: true
    })
}
