import React, { useMemo } from 'react'

import { StatusBar } from '../../StatusBar'
import { useAppNavbarStylesContext } from '../AppNavbarStylesContext'
import * as CM from '../styles'
import type { ApplicationContainerPreviewMode } from '../type'

interface ApplicationWrapperProps {
    previewMode: ApplicationContainerPreviewMode
    style?: React.CSSProperties
    children?: React.ReactNode
    scale?: number
    topChildren?: React.ReactNode
}

const ApplicationWrapper: React.FunctionComponent<ApplicationWrapperProps> = ({ previewMode, scale, style, children, topChildren }) => {
    const headerStyles = useAppNavbarStylesContext()
    const { color, backgroundColor } = headerStyles
    return useMemo(
        () => (
            <CM.Wrapper style={style} scale={scale} className="applicationWrapper">
                {topChildren}
                {previewMode === 'simulationMobile' && <StatusBar color={color} backgroundColor={backgroundColor} />}
                {children}
            </CM.Wrapper>
        ),
        [backgroundColor, children, color, previewMode, scale, style, topChildren]
    )
}

export default React.memo(ApplicationWrapper)
