import { useAtomData } from '@lighthouse/shared'
import { isWechatBrowser } from '@lighthouse/tools'
import React, { forwardRef, useEffect } from 'react'
import { useAsync } from 'react-use'

import { lastPageOfStackAtom, pageAtomFamily } from '@/atoms/page/state'
import { PageProvider, PageStackProvider } from '@/context/PageContext'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import * as srv from '@/services'
import { shareWechatMessageData } from '@/utils/wechat'

import { PageContent } from './PageContentV2'

interface PageContainerProviderProps {
    pageId: string
    stackId: string
    refreshPageManually?: () => void
}

type PageContainerProps = PageContainerProviderProps

export const PageContainer = forwardRef<HTMLDivElement, PageContainerProps>(({ pageId, stackId, refreshPageManually }, ref) => {
    useConfigureWechatShare({ pageId, stackId })

    return (
        <PageProvider value={{ pageId, refreshPage: refreshPageManually }}>
            <PageStackProvider value={stackId}>
                <PageContent ref={ref} />
            </PageStackProvider>
        </PageProvider>
    )
})

/**
 * 配置微信分享hooks
 * 根据自定义规则获取数据
 * 或者根据默认规则获取数据
 */
function useConfigureWechatShare(params: { pageId: string; stackId: string }) {
    const isWechat = isWechatBrowser()

    const currentPage = useAtomData(pageAtomFamily(params.pageId))

    const lastStack = useAtomData(lastPageOfStackAtom)
    const { prev, curr } = usePageDataSourceForVariableSelector(params)

    const defaultShareRes = useAsync(async () => {
        if (!isWechat) {
            return
        }
        const { title, subtitle, icon } = await srv.getWechatShareData({
            pageId: currentPage?.id || '',
            parentRecordId: prev.recordId,
            currentRecordId: curr.recordId
        })
        return { title, description: subtitle, image: icon }
    }, [currentPage?.id, curr.recordId])

    const isLastPage = !!lastStack && lastStack.stackId === params.stackId

    // 设置微信分享
    useEffect(() => {
        if (!isWechat) {
            return
        }
        if (isLastPage) {
            if (defaultShareRes.error || defaultShareRes.loading || !defaultShareRes.value) {
                return
            }
            const { title, description, image } = defaultShareRes.value

            shareWechatMessageData({ title, desc: description, imgUrl: image })
        }
    }, [defaultShareRes.error, defaultShareRes.loading, defaultShareRes.value, isLastPage, isWechat, curr.recordId])
}
