import type {
    ActionsProtocol,
    AiFieldStatus,
    ButtonAction,
    DataSourceAbstract,
    HighLightConditions,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SchemaProtocol,
    TableRowHeightProtocol,
    TableViewOptions,
    ViewField
} from '@lighthouse/core'
import type { atomWithImmer } from 'jotai-immer'
import React from 'react'
import styled from 'styled-components'

import { TableContent } from './TableContent'

export interface TableContainerProps extends ActionsProtocol {
    blockId: string
    checkable?: boolean
    recordOpenable?: boolean
    recordEditOpenable: boolean
    recordDeleteAble: boolean

    isMobile?: boolean
    viewFields: ViewField[]
    schema: SchemaProtocol['schema']
    records: RecordLikeProtocol[]
    scrollRef: React.RefObject<HTMLDivElement>
    selectedRecords?: string[]
    highLightRules?: HighLightConditions
    blockWidth?: number
    tableRowHeight?: TableRowHeightProtocol
    designConfig: TableViewOptions
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    onRecordSelect?: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

export const SCxTableNoDataContent = styled.div`
    width: 100%;
    padding: 40px 0;
    position: sticky;
    left: 0;
    top: 0;
`

export const TableContainer: React.FC<TableContainerProps> = ({
    blockId,
    actions,
    checkable,
    recordOpenable,
    recordEditOpenable,
    recordDeleteAble,
    isMobile,
    viewFields,
    schema,
    records,
    scrollRef,
    selectedRecords,
    highLightRules = [],
    blockWidth,
    tableRowHeight,
    designConfig,
    aiFieldStatusListAtom,
    onRecordSelect,
    onRecordClick,
    onRecordEdit,
    onRecordDelete,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle
}) => {
    if (!blockWidth || !scrollRef) {
        return null
    }
    return (
        <TableContent
            blockId={blockId}
            actions={actions}
            checkable={checkable}
            recordOpenable={recordOpenable}
            recordEditOpenable={recordEditOpenable}
            recordDeleteAble={recordDeleteAble}
            isMobile={isMobile}
            viewFields={viewFields}
            schema={schema}
            records={records}
            scrollRef={scrollRef}
            blockWidth={blockWidth}
            selectedRecords={selectedRecords}
            highLightRules={highLightRules}
            tableRowHeight={tableRowHeight}
            designConfig={designConfig}
            aiFieldStatusListAtom={aiFieldStatusListAtom}
            onRecordSelect={onRecordSelect}
            onRecordClick={onRecordClick}
            onRecordEdit={onRecordEdit}
            onRecordDelete={onRecordDelete}
            onAiGeneration={onAiGeneration}
            onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
            onRecordClickedActionTrigger={onRecordClickedActionTrigger}
            onRenderButtonTitle={onRenderButtonTitle}
        />
    )
}
