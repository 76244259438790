import { IconFont } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'

import type { AliPlayerConfig, PlayerAutoPayEvent, PlayerErrorEvent, PlayerInstance, PlayerProps, PlayerRef } from './types'

const playerScriptId = 'ali-player-js'
const playerLinkId = 'ali-player-css'

const eventNames = [
    'onReady',
    'onPlay',
    'onPause',
    'onCanplay',
    'onPlaying',
    'onHideBar',
    'onShowBar',
    'onWaiting',
    'onSnapshoted',
    'onTimeupdate',
    'onM3u8Retry',
    'onLiveStreamStop',
    'onCancelFullScreen',
    'onRequestFullScreen',
    'onError'
] as const
type EventNames = typeof eventNames[number]

const getPath = (path: string, version: string): string => {
    return path.replace(/{([^{]*?)%version(.*?)}/g, version.toString())
}
const SCxContainer = styled.div<{ isController?: boolean; isReady: boolean }>`
    position: relative;
    clip-path: inset(1px 2px);
    background-color: transparent !important;
    video {
        background-color: ${({ isReady }) => (isReady ? 'var(--color-black)' : 'transparent')};
    }

    &.prism-player .prism-big-play-btn {
        display: ${({ isController }) => (isController ? undefined : ' none!important')};
        transform: translate(-50%, -50%);
        left: 50% !important;
        top: 50% !important;
        width: 20%;
        height: 30%;
        .outter {
            border-color: transparent;
        }
    }
    .prism-loading {
        display: none;
    }
    p,
    .prism-info-display,
    .prism-info-left-bottom {
        opacity: ${({ isReady }) => (isReady ? 1 : 0)};
    }

    .prism-controlbar {
        display: ${({ isReady }) => (isReady ? 'block' : 'none')};
    }
`

const SCxPlayIcon = styled(IconFont)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(50%, -50%);
`
export const AliPlayer = React.forwardRef<PlayerRef, PlayerProps>((props, ref) => {
    const {
        prefixCls = 'pansy-aliplayer',
        className,
        style,
        hideController = false,
        options,
        loading,
        source,
        isLive,
        version = '2.13.4',
        // version = '2.9.22',
        cssLinkTemplate = getAssetUrl('aliplayer', 'aliplayer-min.css'),
        scriptSrcTemplate = getAssetUrl('aliplayer', 'aliplayer-min.js'),
        onPlay,
        onError,
        onPause,
        onReady,
        onAutoPlay
    } = props
    const playerInstance = useRef<PlayerInstance>()
    const playerId = useRef(`aliplayer-${Math.floor(Math.random() * 1000000)}`)
    const [isLoading, setIsLoading] = useState(true)
    const [isReady, setIsReady] = useState(false)
    const [isPlay, setIsPlay] = useState(false)
    const classes = [prefixCls, className, 'prism-player', hideController ? 'hide-controller' : null].filter(Boolean).join(' ').trim()

    const insertLinkTag = useCallback(() => {
        const playerLinkTag = document.querySelector(`#${playerLinkId}`)
        if (!playerLinkTag && version && cssLinkTemplate) {
            const link = document.createElement('link')
            link.type = 'text/css'
            link.rel = 'stylesheet'
            link.href = getPath(cssLinkTemplate, version)
            link.id = playerLinkId
            document.head.append(link)
        }
    }, [cssLinkTemplate, version])

    const handlePlay = useCallback(() => {
        // console.log('play')
        setIsPlay(true)
    }, [])

    const handlePause = useCallback(() => {
        // console.log('pause')
        setIsPlay(false)
    }, [])

    const handleReady = useCallback(() => {
        // console.log('ready')
        onReady?.()
        setIsReady(true)
    }, [onReady])

    const handleReplay = useCallback(() => {
        // console.log('replay')
    }, [])

    const handlePlayerError = useCallback(
        (e: PlayerErrorEvent) => {
            // console.log('error', e)
            onError?.(e)
        },
        [onError]
    )

    const handlePlayAutoplay = useCallback(
        (e: PlayerAutoPayEvent) => {
            // console.log('auto play', e)
            onAutoPlay?.(e)
        },
        [onAutoPlay]
    )

    const initEvents = useCallback(
        (player?: PlayerInstance) => {
            if (!player) {
                return
            }
            player.on('play', handlePlay)
            player.on('pause', handlePause)
            player.on('error', handlePlayerError)
            player.on('replay', handleReplay)
            player.on('ready', handleReady)
            player.on('autoplay', handlePlayAutoplay)
        },
        [handlePause, handlePlay, handlePlayAutoplay, handlePlayerError, handleReady, handleReplay]
    )

    const initAliPlayer = useCallback(() => {
        const config: Partial<AliPlayerConfig> = {
            ...options,
            useH5Prism: true,
            id: playerId.current,
            source,
            isLive: !!isLive
        }

        if (!config.width) {
            config.width = '100%'
        }

        if (!config.height) {
            config.height = '100%'
        }

        if (config.autoplay === undefined) {
            config.autoplay = false
        }

        // if (playerInstance.current) {
        //     playerInstance.current.dispose()
        // }

        if (window['Aliplayer']) {
            try {
                playerInstance.current = new Aliplayer(config)
                playerInstance?.current?.setVolume(0)
                initEvents(playerInstance.current)
            } catch (error) {
                console.log(`Aliplayer: ${JSON.stringify(error)}`)
            }
        }
    }, [initEvents, isLive, options, source])

    const insertScriptTag = useCallback(() => {
        let playerScriptTag = document.querySelector(`#${playerScriptId}`) as HTMLScriptElement
        if (!playerScriptTag) {
            playerScriptTag = document.createElement('script')
            playerScriptTag.type = 'text/javascript'
            playerScriptTag.src = getPath(scriptSrcTemplate, version)
            playerScriptTag.id = playerScriptId

            document.body.append(playerScriptTag)
        }
        playerScriptTag.addEventListener('load', e => {
            setIsLoading(true)
            initAliPlayer()
        })
    }, [initAliPlayer, scriptSrcTemplate, version])

    const init = useCallback(() => {
        if (window['Aliplayer']) {
            setIsLoading(false)
            initAliPlayer()
        } else {
            insertScriptTag()
        }
    }, [initAliPlayer, insertScriptTag])

    useEffect(() => {
        insertLinkTag()
        init()
        return () => {
            playerInstance.current && playerInstance.current.dispose()
        }
    }, [init, insertLinkTag])

    useEffect(() => {
        if (playerInstance.current && source) {
            playerInstance.current.loadByUrl(source)
        }
    }, [source])

    useImperativeHandle(
        ref,
        () => {
            return {
                getInstance: () => playerInstance.current
            }
        },
        []
    )

    return (
        <SCxContainer
            className={classes}
            style={style}
            isReady={isReady}
            id={playerId.current}
            isController={options?.controlBarVisibility !== 'never'}
        >
            {isLoading ? loading : null}
            {!isPlay && options?.controlBarVisibility && options?.controlBarVisibility === 'never' && <SCxPlayIcon size={48} color="var(--color-white)" type="BlockVideo" />}
        </SCxContainer>
    )
})
