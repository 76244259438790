import { Empty, singleTextEllipsis } from '@byecode/ui'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { getTreeOptions } from '../../utils/tree'
import * as SC from './styles'
import { TreeSelectItem } from './TreeSelectItem'
import type { TagTreeSelectOption } from './types'

interface TreeSelectProps {
    options?: TagTreeSelectOption[]
    value?: string[]
    disabledId?: string
    onChange?: (value: string) => void
}

const SCxDropDownHeader = styled.div`
    padding: 4px 12px;
    display: flex;
    justify-content: space-between;
    gap: 6px;
`

const SCxTag = styled.div<{ isOmit?: boolean }>`
    line-height: 16px;
    padding: 4px 6px;
    height: 24px;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    border-radius: 4px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

export const TreeSelect: React.FunctionComponent<TreeSelectProps> = ({ disabledId, options: data = [], value = [], onChange }) => {
    // const [options, setOptions] = useState(data)

    const optionsTree = useMemo(() => getTreeOptions(data), [data])

    const handleChange = useCallback(
        (ids: string[]) => {
            const id = ids[0]
            onChange?.(id)
        },
        [onChange]
    )

    return (
            <SC.DropDownList>
                {optionsTree.map(option => (
                    <TreeSelectItem
                        key={option.value}
                        data={option}
                        value={value}
                        disabledIds={disabledId ? [disabledId] : []}
                        level={0}
                        onChange={handleChange}
                    />
                ))}
                {data.length === 0 && <Empty icon="SpaceTeam" styles={{ root: { height: 100 } }} description="没有部门" />}
            </SC.DropDownList>
    )
}
