import type { FieldProtocol, HighLightConditions, TextValue } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import React, { useMemo } from 'react'

import { TextItemPreviewer, TextListPreviewer } from '../FieldValuePreviewer/TextValuePreviewer'
import { CellHighlighter } from '../Highlighter'

interface TextValuePreviewerProps {
    field: FieldProtocol
    data?: TextValue | TextValue[]
    isWrap?: boolean
    width?: number
    styleConditions?: HighLightConditions
    isBold?: boolean
}

export const TextValuePreviewer: React.FC<TextValuePreviewerProps> = ({
    field,
    data,
    width = 160,
    isWrap = true,
    isBold,
    styleConditions
}) => {
    const { id: fieldId, type } = field

    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (!data) {
            return ''
        }
        if (Array.isArray(data)) {
            return <TextListPreviewer data={data} width={width} isWrap={isWrap} isBold={isBold} />
        }
        const id = nanoid()
        return <TextItemPreviewer id={id} isWrap={isWrap} data={data} isBold={isBold} isLast />
    }, [data, isBold, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={fieldId} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
