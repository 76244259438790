import { pointer, Text } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.button`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: var(--font-size-normal);
    border-radius: inherit;
    padding: 0 8px;

    > * {
        background-color: transparent;
    }
`

export const IconWrapper = styled.div`
    ${pointer}
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
`

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 100%;
    min-height: 38px;
    overflow: hidden;
`

export const TagWrapper = styled.div`
    flex: 1;
    padding: 4px 0;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
`

export const Tag = styled.div<{ isOmit?: boolean }>`
    display: inline-flex;
    align-items: center;

    gap: 4px;
    padding: 0 6px;
    line-height: 16px;
    height: 24px;
    margin-right: 4px;
    border-radius: 4px;
    background-color: var(--color-gray-200);
    overflow: hidden;
`
export const TagLabel = styled(Text)<{ isOmit?: boolean }>`
    color: var(--color-black);
    font-size: var(--font-size-normal);
    overflow: hidden;
`
