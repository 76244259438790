import { createStyles } from '../../../theme/createStyles'
import { css } from '../../../theme/stitches.config'

export const useStyles = createStyles(() => ({
    timeBody: css({
        marginTop: 4,
        display: 'flex',
        height: 268
    }),
    timeCell: css({
        cursor: 'pointer',
        lineHeight: '22px',
        textAlign: 'center',
        '&[data-selected]': {
            backgroundColor: '$colorGray100'
        }
    })
}))
