import { createStyles } from '../../../theme/createStyles'
import { css } from '../../../theme/stitches.config'

export const useStyles = createStyles(() => ({
    header: css({
        display: 'flex',
        gap: '16px',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '6px 10px',
        backgroundColor: '$colorGray50',
        borderBottom: '1px solid $colorGray200'
    }),
    title: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '$colorGray900',
        fontSize: 14,
        lineHeight: '22px'
    })
}))
