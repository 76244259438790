import { styled } from '@byecode/ui/theme/stitches.config'
import React, { useMemo } from 'react'
import type { Root } from 'react-dom/client'
import { createRoot } from 'react-dom/client'

import { Button } from '../Button'
import type { BaseModalProps } from './base'
import { DesktopModal } from './desktop'

const ROOT = '__BUI__REACT__ROOT__'
type Container = Element & { [ROOT]?: Root }

const Title = styled('div', {
    marginBottom: 12,
    color: '$colorBlack',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600
})

const Footer = styled('div', {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px'
})

const ContentContainer = styled('div', {
    lineHeight: '22px',
    color: '$colorGray600'
})

export interface InfoProps extends Omit<BaseModalProps, 'children' | 'title' | 'open' | 'withCloseIcon' | 'footer'> {
    title: string
    content?: React.ReactNode
    okText?: React.ReactNode
    okStatus?: 'error' | 'primary'
    extra?: React.ReactNode
}

export interface ModalInfoProps extends InfoProps {
    onResolve: () => void
}

export const ModalInfo: React.FC<ModalInfoProps> = cfg => {
    const { content, title, okText, okStatus = 'error', extra, onResolve, ...props } = cfg

    const okButtonProps = useMemo(() => (okStatus === 'error' ? { danger: true } : {}), [okStatus])

    return (
        <DesktopModal
            open
            width={360}
            withCloseIcon={false}
            closeOnClickOverlay={false}
            styles={{
                body: {
                    padding: '20px 20px 0',
                    fontSize: 14,
                    color: '$colorGray600',
                    overflow: 'hidden'
                },
                footer: {
                    padding: '24px 20px'
                }
            }}
            footer={
                <Footer>
                    <Button size="lg" type="primary" {...okButtonProps} onClick={onResolve}>
                        {okText || '确认'}
                    </Button>
                </Footer>
            }
            {...props}
        >
            <Title>{title}</Title>
            <ContentContainer>{content}</ContentContainer>
            {extra}
        </DesktopModal>
    )
}

export const info = (cfg: InfoProps) => {
    const container = document.createDocumentFragment() as unknown as Container
    const root = container[ROOT] || createRoot(container)
    container[ROOT] = root

    const { content, title, okText, ...props } = cfg

    return new Promise<boolean>(resolve => {
        const handlerResolve = () => {
            root.unmount()
            resolve(true)
        }

        root.render(<ModalInfo onResolve={handlerResolve} {...cfg} />)
    })
}
