import { Empty } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import React from 'react'

interface PageNoAccessProps {}

export const PageNoAccess: React.FunctionComponent<PageNoAccessProps> = () => {
    return (
        <Empty
            styles={{
                root: {
                    flex: 1,
                    height: '100%',
                    background: 'var(--color-white)'
                },
                content: {
                    marginTop: 32
                }
            }}
            icon={<img src={getAssetUrl('empty', 'not_permission.svg')} alt="无访问权限" />}
            description="没有页面访问权限，请联系管理员"
        />
    )
}
