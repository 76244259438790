import { IconFont, Text } from '@byecode/ui'
import { mergeRefs } from '@lighthouse/tools'
import cls from 'classnames'
import React from 'react'
import styled from 'styled-components'

import { TooltipText } from '../TooltipText'

export interface CheckButtonProps {
    label?: string
    width?: string
    isSuffixIcon?: boolean
    isBackground?: boolean
    active?: boolean
    extra?: React.ReactNode
    onClick?: () => void
}

const SCxCheckButton = styled.div<{ width: string; isBackground?: boolean }>`
    flex-shrink: 0;
    /* min-width: 80px; */
    max-width: 168px;
    width: ${({ width }) => width};
    padding: 0 6px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    line-height: 24px;
    color: var(--color-gray-400);
    background-color: ${({ isBackground }) => (isBackground ? 'var(--color-gray-50)' : 'none')};
    border-radius: 5px;
    text-align: center;
    font-size: var(--font-size-normal);
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }

    iconpark-icon {
        color: var(--color-gray-400);
        vertical-align: -4px;
    }
    &.active {
        color: var(--color-app-main);
        background-color: var(--color-app-main-tint-20);
        iconpark-icon {
            color: var(--color-app-main);
        }
    }
`

export const CheckButton = React.forwardRef<HTMLDivElement, CheckButtonProps>(
    ({ label, active, width = 'auto', isSuffixIcon, isBackground, onClick }, ref) => {
        return (
            <TooltipText
                title={label}
                render={toolTipRef => (
                    <SCxCheckButton
                        ref={mergeRefs([toolTipRef, ref])}
                        isBackground={isBackground}
                        onClick={onClick}
                        width={width}
                        className={cls({ active })}
                    >
                        <Text>{label}</Text>
                        {isSuffixIcon && <IconFont size={16} type="ArrowDownSmall" />}
                    </SCxCheckButton>
                )}
            />
        )
    }
)
