import { isIosPlatform, isWechatBrowser } from '@lighthouse/tools'
import wx from 'weixin-js-sdk'

import { getWechatSignature } from '@/services'

/** ios微信浏览器设置签名时，需要传入进入页面时的地址 */
export const FIRST_URL_FOR_IOS_WECHAT = 'FIRST_URL_FOR_IOS_WECHAT'
/**
 * 微信 SDK 初始化
 * 要确保 url 正确，否则会导致签名失败
 * @export
 */
export async function initWxSDK() {
    const isWechat = isWechatBrowser()
    const isIos = isIosPlatform()

    if (!isWechat) {
        return
    }

    const defaultUrl = window.location.href.split('#')[0]
    const url = isIos ? sessionStorage.getItem(FIRST_URL_FOR_IOS_WECHAT) || defaultUrl : defaultUrl
    //
    const { appId, nonceStr, signature, timestamp } = await getWechatSignature(encodeURIComponent(url))

    const { VITE_SENTRY_ENVIRONMENT } = import.meta.env

    const isLocalDev = !VITE_SENTRY_ENVIRONMENT

    wx.config({
        // 暂时修改
        debug: isLocalDev,
        appId,
        timestamp,
        nonceStr,
        signature,
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'scanQRCode']
    })
}

interface ShareWechatParams {
    title: string
    desc: string
    imgUrl: string
}

export function shareWechatMessageData({ title, desc, imgUrl }: ShareWechatParams) {
    const isWechat = isWechatBrowser()

    if (!isWechat) {
        return
    }
    // wx.ready 注意要在 wechat sdk 初始化后调用
    wx.ready(() => {
        wx.updateAppMessageShareData({
            title,
            desc,
            link: window.location.href.split('#')[0],
            imgUrl,
            success() {
                // 设置成功
            }
        })

        wx.updateTimelineShareData({
            title,
            link: window.location.href.split('#')[0],
            imgUrl,
            success() {
                // 设置成功
            }
        })

    })
}
