import type { DataSourceAbstract, NavigationShowMode } from '@lighthouse/core'
import type { ApplicationContainerPreviewMode, MessageEvents, NotificationMessageData } from '@lighthouse/shared'
import { ProvidedNotification, useMessage } from '@lighthouse/shared'
import React, { useEffect } from 'react'

import { useAccount } from '@/hooks/useAccount'
import { useSetAiFieldStatus } from '@/hooks/useSetAiFieldStatus'

export interface NotificationContainerProps {
    appId: string
    device?: 'mobile' | 'desktop'
    showMode?: NavigationShowMode
    previewMode?: ApplicationContainerPreviewMode
    messageEvents?: MessageEvents
}

export const NotificationContainer: React.FC<NotificationContainerProps> = ({ ...rest }) => {
    const { uniqueUserId } = useAccount()
    const { lastMessage, getWebSocket } = useMessage<NotificationMessageData>(rest.appId, true, uniqueUserId)
    useSetAiFieldStatus(lastMessage)

    useEffect(() => {
        return () => getWebSocket()?.close()
    }, [getWebSocket])

    return <ProvidedNotification lastMessage={lastMessage} {...rest} />
}
