import { IconFont, Menu, MenuItem } from '@byecode/ui'
import { SelectedMode } from '@lighthouse/core'
import { Checkbox } from '@mantine/core'
import cls from 'classnames'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

export interface AdvancedTableHeaderCheckerProps {
    index?: number
    checked?: boolean
    checkable?: boolean
    showOpenerIcon?: boolean
    className?: string
    isStripe?: boolean
    isRecordSelecting?: boolean
    onChange?: (checked: boolean) => void
    onRecordOpen?: () => void
    onSelectModeChange?: (mode?: SelectedMode) => void
}

const SCxAdvancedTableRecordIndex = styled.p`
    position: absolute;
    color: var(--color-gray-400);
    opacity: 1;
`

const SCxAdvancedTableRecordCheckerWrapper = styled.div`
    display: flex;
    padding-left: 12px;
    align-items: center;
    height: 100%;
    position: absolute;
    opacity: 0;
    gap: 2px;
`

const SCxAdvancedTableHeaderChecker = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 59px;
    height: 100%;
`

const SCxAdvancedTableExtra = styled.div`
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
`

const SCxAdvancedTableOpener = styled(IconFont)`
    cursor: pointer;
    z-index: 1;
`

const SCxIcon = styled(IconFont)`
    font-size: 18px;
    color: var(--color-black);
`

const SCxAdvancedTableHeaderCheckerContent = styled.div<{ isStripe?: boolean }>`
    position: relative;
    width: 100%;
    background-color: ${({ isStripe }) => (isStripe ? 'var(--color-gray-50)' : 'none')};
`

export const SCxAdvancedTableHeaderCheckerWrapper = styled.div`
    position: sticky;
    left: 0;
    z-index: 8;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    width: 60px;
    flex-shrink: 0;
    background: var(--color-white);
    border-right: 1px solid var(--color-gray-200);
    font-size: var(--font-size-normal);

    &:hover {
        ${SCxAdvancedTableExtra} {
            opacity: 1;
        }
    }

    &.table-checkable {
        &.checked,
        &:not(.table-checked):hover {
            ${SCxAdvancedTableRecordCheckerWrapper} {
                opacity: 1;
            }
            ${SCxAdvancedTableRecordIndex} {
                opacity: 0;
            }
            ${SCxIcon} {
                display: none;
            }
        }
    }
`

const SCxArrowWrapper = styled.div`
    width: 12px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #26415a0f;
    }
`

export const SCXCheckBox = styled(Checkbox)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    [type='checkbox']:checked {
        border-color: var(--color-app-main);
        background-color: var(--color-app-main);
    }
`

export const AdvancedTableHeaderChecker: React.FC<AdvancedTableHeaderCheckerProps> = ({
    index,
    showOpenerIcon,
    checked,
    checkable,
    className,
    isStripe,
    isRecordSelecting,
    onChange,
    onRecordOpen,
    onSelectModeChange
}) => {
    const handleChange = useCallback(
        (ev: React.MouseEvent | React.ChangeEvent) => {
            ev.stopPropagation()
            if (!checkable) {
                return
            }
            onChange?.(!checked)
        },
        [checkable, checked, onChange]
    )

    const order = useMemo(() => {
        if (index !== undefined) {
            return <SCxAdvancedTableRecordIndex>{index + 1}</SCxAdvancedTableRecordIndex>
        }
        if (!checkable) {
            return <SCxIcon type="PropertyNumber" />
        }
        return null
    }, [checkable, index])

    return (
        <SCxAdvancedTableHeaderCheckerWrapper className={cls({ checked, 'table-checkable': checkable }, className)}>
            <SCxAdvancedTableHeaderCheckerContent isStripe={isStripe}>
                <SCxAdvancedTableHeaderChecker>
                    {order}
                    <SCxAdvancedTableRecordCheckerWrapper
                        style={isRecordSelecting || (index === undefined && checkable) ? { opacity: 1 } : {}}
                    >
                        <SCXCheckBox size="xs" checked={checked} onChange={handleChange} />
                        <Menu width={204} position="bottom-start" closeOnItemClick withinPortal>
                            <Menu.Target>
                                <SCxArrowWrapper>
                                    <IconFont type="CaretDown" />
                                </SCxArrowWrapper>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <MenuItem onClick={() => onSelectModeChange?.(SelectedMode.CURRENT_PAGE)}>当前页</MenuItem>
                                <MenuItem onClick={() => onSelectModeChange?.(SelectedMode.ALL)}>所有记录</MenuItem>
                                <MenuItem onClick={() => onSelectModeChange?.()}>取消选择</MenuItem>
                            </Menu.Dropdown>
                        </Menu>
                    </SCxAdvancedTableRecordCheckerWrapper>
                </SCxAdvancedTableHeaderChecker>
                {showOpenerIcon && (index || index === 0) && (
                    <SCxAdvancedTableExtra>
                        <SCxAdvancedTableOpener size={16} type="Open" onClick={onRecordOpen} />
                    </SCxAdvancedTableExtra>
                )}
            </SCxAdvancedTableHeaderCheckerContent>
        </SCxAdvancedTableHeaderCheckerWrapper>
    )
}
