import { IconFont } from '@byecode/ui'
import type { BoolValue } from '@lighthouse/core'
import { useUncontrolled } from '@lighthouse/tools'
import type { ChangeEvent } from 'react'
import React, { useCallback, useId } from 'react'
import styled, { css } from 'styled-components'

const SCxCheckboxWrapper = styled.div`
    position: relative;
    display: fixed;
    align-items: center;
`

const SCxCheckboxInner = styled.label<{ checked: boolean; defaultBgcolor: string; disabled?: boolean, color: string }>`
    display: flex;
    transition: all 0.2s ease-in-out;
    border: 1px solid transparent;
    justify-content: center;
    border-radius: 4px;
    width: 18px;
    height: 18px;
    background-color: red;

    &[disabled] {
        border-color: ${({ checked }) => (checked ? 'var(--color-theme-5)' : 'var(--color-gray-200)')}!important;
        background-color: ${({ checked }) => (checked ? 'var(--color-theme-4)' : 'var(--color-gray-100)')}!important;
    }

    ${({ checked, defaultBgcolor, color }) =>
        checked
            ? css`
                  background-color: ${color};
                  border-color: ${color};
              `
            : css`
                  background-color: ${defaultBgcolor};
                  border-color: var(--color-gray-400);
                  &:hover {
                      border-color: ${color};
                      background-color: var(--color-purple-25);
                  }
              `}
`

const SCxCheckboxInput = styled.input`
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    z-index: -1;
    visibility: hidden;
`

const SCxCheckboxIcon = styled(IconFont)<{ checked: boolean }>`
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    transform: translateX(0px);
    color: var(--color-white);

    ${({ checked }) =>
        checked
            ? css`
                  transform: translateY(0) scale(1);
              `
            : css`
                  transform: translateY(10px) scale(0);
              `}
`

interface FieldCheckboxProps {
    checked?: BoolValue
    disabled?: boolean
    defaultBgcolor?: string
    readonly?: boolean
    color?: string
    onChange?: (ev: ChangeEvent<HTMLInputElement>) => void
}

export const FieldCheckbox: React.FC<FieldCheckboxProps> = ({
    checked,
    disabled,
    defaultBgcolor = 'var(--color-white)',
    readonly,
    color = 'var(--color-main)',
    onChange
}) => {
    const domId = useId()

    const [_value, handleChange] = useUncontrolled({ value: checked, finalValue: false })
    const handleCheckedChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
            if (disabled || readonly) {
                return
            }
            handleChange(ev.target.checked)
            onChange?.(ev)
        },
        [disabled, handleChange, onChange, readonly]
    )

    return (
        <SCxCheckboxWrapper>
            <SCxCheckboxInner disabled={disabled} defaultBgcolor={defaultBgcolor} color={color} checked={!!checked} htmlFor={domId}>
                <SCxCheckboxInput disabled={readonly} type="checkbox" id={domId} checked={checked} onChange={handleCheckedChange} />
                <SCxCheckboxIcon size={18} type="CheckboxTick" checked={!!checked} />
            </SCxCheckboxInner>
        </SCxCheckboxWrapper>
    )
}
