import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { getDate, getDay, isValid, lightFormat, parse } from 'date-fns'
import React, { forwardRef, startTransition, useCallback, useEffect, useMemo, useState } from 'react'

import type { CalendarProps } from '../Calendar'
import { Calendar } from '../Calendar'
import { IconFont } from '../IconFont'
import { Input } from '../Input'
import type { InputSharedProps } from '../Input/types'
import { Popover } from '../Popover'
import { useStyles } from './DatePicker.style'
import type { BaseDatePickerProps } from './DatePicker.type'

export interface DatePickerProps
    extends BaseDatePickerProps,
        Omit<CalendarProps, 'onConfirm' | 'prefix' | 'value' | 'defaultValue' | 'onChange'>,
        InputSharedProps {
    disableInput?: boolean
    disableIcon?: boolean
}

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>((props, ref) => {
    const {
        width = 280,
        styles,
        classNames,
        unstyled,
        value,
        defaultValue,
        onChange,
        format = 'yyyy/MM/dd HH:mm:ss',
        popoverProps,
        inputRender,
        onModeChange,

        // panel control props
        disabled,
        date,
        defaultDate,
        onDateChange,
        panelDate,
        defaultPanelDate,
        onPanelDateChange,
        mode,
        defaultMode,
        minDate,
        maxDate,
        disableIcon,

        // panel settings props
        showTime = true,
        disableSecond,
        hideNow,
        firstDayOfWeek,
        dayRowsCount,
        HeaderComponent,
        FooterComponent,
        CellComponent,
        onPrevYear,
        onPrevMonth,
        onNextYear,
        onNextMonth,
        clearable,
        disableInput,
        ...inputProps
    } = props
    const calendarProps = useMemo(
        () => ({
            // panel control props
            disabled,
            panelDate,
            defaultPanelDate,
            onPanelDateChange,
            mode,
            defaultMode,
            onModeChange,

            // panel settings props
            firstDayOfWeek,
            dayRowsCount,
            HeaderComponent,
            FooterComponent,
            CellComponent,
            minDate,
            maxDate,
            onPrevYear,
            onPrevMonth,
            onNextYear,
            onNextMonth
        }),
        [
            disabled,
            panelDate,
            defaultPanelDate,
            onPanelDateChange,
            mode,
            defaultMode,
            onModeChange,
            firstDayOfWeek,
            dayRowsCount,
            HeaderComponent,
            FooterComponent,
            CellComponent,
            minDate,
            maxDate,
            onPrevYear,
            onPrevMonth,
            onNextYear,
            onNextMonth
        ]
    )

    const { classes } = useStyles({}, { name: 'DatePicker', classNames, styles, unstyled })

    const [opened, setOpened] = useUncontrolled({
        value: popoverProps?.opened,
        defaultValue: popoverProps?.defaultOpened,
        onChange: popoverProps?.onChange
    })

    // 各种事件变化中的预览日期
    const [internalDate, setInternalDate] = useUncontrolled({
        value: date,
        defaultValue: defaultDate,
        onChange: onDateChange
    })

    // 输入框中预览的日期
    const [inputValue, setInputValue] = useState('')

    // 真正确认选择的日期
    const [selectedValue, setSelectedValue] = useState<Date | undefined>(value || defaultValue)

    useEffect(() => {
        if (value) {
            setSelectedValue(value)
            setInternalDate(value)
        }
        setInputValue(value ? lightFormat(value, format) : '')
    }, [format, value, setInternalDate])

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setInputValue(value)

        if (value.length !== format.length) {
            return
        }
        const newDate = parse(value, format, new Date())
        if (isValid(newDate)) {
            setInternalDate(newDate)
        }
    }

    useEffect(() => {
        setInputValue(internalDate ? lightFormat(internalDate, format) : '')
    }, [format, internalDate])

    useEffect(() => {
        if (opened && !internalDate) {
            if (minDate && minDate.valueOf() > Date.now()) {
                setInternalDate(minDate)
                return
            }
            if (maxDate && maxDate.valueOf() < Date.now()) {
                setInternalDate(maxDate)
                return
            }
            setInternalDate(new Date())
        }
    }, [internalDate, maxDate, minDate, opened, setInternalDate])

    // input预览值跟随鼠标移入日期格子变化
    const [hoverValue, setHoverValue] = useState('')

    const onCellMouseEnter = useCallback(
        (date: Date) => {
            if (!opened) {
                return
            }
            startTransition(() => {
                setHoverValue(lightFormat(date, format))
            })
        },
        [format, opened]
    )

    const onCellMouseLeave = useCallback((date: Date) => {
        setHoverValue('')
    }, [])

    const onInternalConfirm = useCallback(
        (date: Date | undefined) => {
            setSelectedValue(date)
            onChange?.(date)

            setOpened(false)
        },
        [onChange, setOpened]
    )

    const onPopoverChange = useCallback(
        (v: boolean) => {
            setOpened(v)
            if (!v) {
                setHoverValue('')
                setInternalDate(selectedValue)
            }
        },
        [selectedValue, setInternalDate, setOpened]
    )

    const handleClear = useCallback(() => {
        onChange?.(undefined)
    }, [onChange])

    return (
        <Popover
            width={width}
            enableClickToggle={false}
            returnFocus
            trapFocus={false}
            withinPortal
            disabled={disabled}
            position="bottom-start"
            {...popoverProps}
            opened={opened}
            onChange={onPopoverChange}
        >
            <Popover.Target ref={ref}>
                {inputRender ? (
                    inputRender({ value: hoverValue || inputValue, onChange: onInputChange })
                ) : (
                    <Input
                        prefix={!disableIcon && <IconFont type="PropertyCalendar" />}
                        styles={{
                            input: {
                                // @todo input组件替换bui样式系统后，需要替换该css变量为bui样式系统token: $colorGray400
                                color: hoverValue ? 'var(--color-gray-400)' : undefined
                            }
                        }}
                        autoComplete='off'
                        value={hoverValue || inputValue}
                        onChange={onInputChange}
                        onClear={handleClear}
                        disabled={disabled}
                        readOnly={disableInput}
                        {...inputProps}
                        clearable={clearable && !!value}
                    />
                )}
            </Popover.Target>

            <Popover.Dropdown compact className={classes.dropdown} onMouseDown={e => e.preventDefault()}>
                <Calendar
                    showTime={showTime}
                    disableSecond={disableSecond}
                    date={internalDate}
                    hideNow={hideNow}
                    onDateChange={setInternalDate}
                    onCellMouseEnter={onCellMouseEnter}
                    onCellMouseLeave={onCellMouseLeave}
                    onConfirm={onInternalConfirm}
                    {...calendarProps}
                />
            </Popover.Dropdown>
        </Popover>
    )
})
