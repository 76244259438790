import type { ActionFlowNode, AliyunMessageActionConfig } from '@lighthouse/shared'
import { generateVariableValue, resolveVariable } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleAliyunMessage = async (node: ActionFlowNode<AliyunMessageActionConfig>, extraParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return extraParams
    }

    const { id, mobileList, signatureId, templateId, values } = config

    const pageId = extraParams?.getCurrentPageDeps()?.pageId ?? ''
    const mobileListValue = (resolveVariable(mobileList, extraParams) ?? '') as string
    const valuesListValue = values.map(value => ({
        variableName: value.variableName,
        value: generateVariableValue({  innerType: 'TEXT', jsonContent: value.value, extraParams }) as string
    }))

    const params = {
        id,
        pageId,
        mobileList: mobileListValue,
        signatureId,
        templateId,
        values: valuesListValue
    }

    await srv.activeAliyunMessageAction(params)

    return params
}
