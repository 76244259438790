import type { AppUser, FieldCellValue, FieldProtocol, TypeInstanceMap } from '@lighthouse/core'
import React from 'react'
import styled from 'styled-components'

import { DATE_FORMAT, isDateListValue } from '../../utils'
import { DateValuePreviewer } from './DateValuePreviewer'

const SCxText = styled.div<{ isWrap: boolean; isBold?: boolean }>`
    position: relative;
    font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
    white-space: ${({ isWrap }) => (isWrap ? 'pre-wrap' : 'nowrap')};
    word-break: break-word;
    color: var(--color-black);
    overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'hidden')};
    text-overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'ellipsis')};
`

const innerTypeRender: (
    field: FieldProtocol,
    innerType: TypeInstanceMap,
    data: FieldCellValue,
    width: number,
    isWrap: boolean,
    isBold?: boolean
) => React.ReactNode | string = (field, innerType, data, width, isWrap, isBold) => {
    switch (innerType) {
        case 'TEXT':
        case 'NUMBER':
        case 'BOOL': {
            return String(data)
        }
        case 'DATE': {
            const dateField = {
                ...field,
                date: {
                    format: DATE_FORMAT
                }
            }
            return (
                <DateValuePreviewer
                    field={dateField}
                    isWrap={isWrap}
                    width={width}
                    isBold={isBold}
                    data={isDateListValue(data) ? data : []}
                />
            )
        }
        case 'ARRAY': {
            if (Array.isArray(data)) {
                return data.join(',')
            }
            return String(data)
        }

        default: {
            return ''
        }
    }
}

export const innerOverview = (
    field: FieldProtocol,
    formulaData: unknown,
    type: TypeInstanceMap,
    width: number,
    personOptions: AppUser[],
    isWrap: boolean,
    isBold?: boolean
) => {
    if (!formulaData && formulaData !== 0 && !type.includes('BOOL')) {
        return ''
    }
    const data = formulaData as FieldCellValue

    return (
        <SCxText isBold={isBold} isWrap={isWrap}>
            {innerTypeRender(field, type, data, width, isWrap, isBold)}
        </SCxText>
    )
}
