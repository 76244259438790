import mitt from 'mitt'
import React, { useCallback, useEffect, useState } from 'react'

import { useDisclosure } from '../../hooks/useDisclosure'
import type { BaseModalProps } from '../Modal'
import { BaseModal, Modal } from '../Modal'
import { Scanner } from './Scanner'
import type { ScannerOption } from './Scanner.types'

export type ScannerEmitter = {
    openScanner:
        | {
              option?: ScannerOption
              success?: (v: string) => void
              error?: (v: string) => void
          }
        | undefined
    closeScanner: undefined
}

export const scannerEmitter = mitt<ScannerEmitter>()

export const openScanner = (params?: ScannerEmitter['openScanner']) => {
    scannerEmitter.emit('openScanner', params)
}

export const closeScanner = () => {
    scannerEmitter.emit('closeScanner')
}

export const ScannerProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [opened, { open, close }] = useDisclosure(false)
    const [scannerOption, setScannerOption] = useState<ScannerEmitter['openScanner']>()

    const handleOpenScanner = useCallback(
        (option?: ScannerEmitter['openScanner']) => {
            open()
            setScannerOption(option)
        },
        [open]
    )

    const handleCloseScanner = useCallback(() => {
        close()
    }, [close])

    useEffect(() => {
        scannerEmitter.on('openScanner', handleOpenScanner)
        scannerEmitter.on('closeScanner', handleCloseScanner)

        return () => {
            scannerEmitter.off('openScanner', handleOpenScanner)
            scannerEmitter.off('closeScanner', handleCloseScanner)
        }
    }, [handleCloseScanner, handleOpenScanner])

    return (
        <>
            <BaseModal
                open={opened}
                styles={{
                    header: {
                        display: 'none'
                    },
                    body: {
                        padding: 0,
                        overflow: 'hidden'
                    },
                    modal: {
                        borderRadius: 'unset',
                        borderStyle: 'none'
                    }
                }}
                onClose={close}
            >
                <Scanner onError={scannerOption?.error} onSuccess={scannerOption?.success} option={scannerOption?.option} onClose={close} />
            </BaseModal>
            {children}
        </>
    )
}
