import { Menu } from '@byecode/ui'
import { Collapse, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import cls from 'classnames'
import React, { useMemo } from 'react'

import type { TreeOption } from '../../types'
import * as SC from './styles'

interface TreeSelectItemProps {
    data: TreeOption
    level: number
    value?: string[]
    disabledIds?: string[]
    defaultOpened?: boolean
    enableMenu?: boolean
    style?: React.CSSProperties
    renderRightSection?: (data: TreeOption) => React.ReactNode
    onChange?: (value: string[]) => void
}

export const TreeSelectItem: React.FunctionComponent<TreeSelectItemProps> = ({
    data,
    level,
    disabledIds,
    defaultOpened = false,
    enableMenu = true,
    value: propsValue,
    style,
    onChange,
    renderRightSection
}) => {
    const { value, label, children = [] } = data
    const [opened, { toggle }] = useDisclosure(defaultOpened)

    // const active = activeDepartmentId === value

    const disabled = disabledIds?.includes(value)

    const ele = useMemo(
        () => (
            <SC.DropDownItem
                style={{ paddingLeft: 20 * level + 8 }}
                className={cls({ menu: !enableMenu, disabled })}
                onClick={() => !disabled && onChange?.([value])}
            >
                <SC.ItemWrapper style={style}>
                    <SC.ItemLeftFill>
                        <SC.IconWrapper style={{ width: 16 }}>
                            {children.length > 0 && (
                                <SC.Icon
                                    type={opened ? 'ArrowDownSmall' : 'ArrowRightSmall'}
                                    // color={active ? 'var(--color-theme-7)' : 'var(--color-gray-300)'}
                                    size={16}
                                    onClick={e => {
                                        e.stopPropagation()
                                        toggle()
                                    }}
                                />
                            )}
                        </SC.IconWrapper>
                        <Text style={{ whiteSpace: 'nowrap' }} size={14}>
                            {label}
                        </Text>
                    </SC.ItemLeftFill>
                </SC.ItemWrapper>
                {renderRightSection?.(data)}
            </SC.DropDownItem>
        ),
        [level, enableMenu, disabled, style, children.length, opened, label, renderRightSection, data, onChange, value, toggle]
    )

    return (
        <>
            {enableMenu ? (
                <Menu.Item style={{ padding: 0, opacity: disabled ? 0.5 : 1 }}  closeMenuOnClick={false}>
                    {ele}
                </Menu.Item>
            ) : (
                ele
            )}
            {children?.length > 0 && (
                <Collapse in={opened}>
                    {children?.map(item => (
                        <TreeSelectItem
                            key={item.value}
                            data={item}
                            level={level + 1}
                            enableMenu={enableMenu}
                            value={propsValue}
                            disabledIds={disabled ? [item.value] : disabledIds}
                            defaultOpened={defaultOpened}
                            style={style}
                            renderRightSection={renderRightSection}
                            onChange={onChange}
                        />
                    ))}
                </Collapse>
            )}
        </>
    )
}
