import type React from 'react'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'

/**
 * 将张琪的hooks迁移到bui，bui需要使用
 *
 * 获取当前文本dom文本是否被省略
 * 使用时建议直接绑定文本标签
 */
export const useDetectTextOverflow = function <T extends HTMLElement = HTMLDivElement>(ref: React.RefObject<T>, dependencies?: string[]) {
    const [isOmit, setIsOmit] = useState(false)
    const frameID = useRef(0)

    const changeIsOmit = useCallback((target: HTMLElement) => {
        const options = { size: getStyleToNumber(target, 'font-size'), family: getStyle(target, 'font-family') ?? 'Microsoft YaHei' }
        const rangeWidth = getActualWidthOfChars(target.textContent ?? '', options)
        const otherMargin =
            getStyleToNumber(target, 'padding-right') +
            getStyleToNumber(target, 'padding-left') +
            getStyleToNumber(target, 'border-left-width') +
            getStyleToNumber(target, 'border-right-width')
        const targetWidth = getStyleToNumber(target, 'width') - otherMargin

        setIsOmit(rangeWidth > targetWidth)
    }, [])

    const observerCallback = useCallback(
        (entries: MutationRecord[]) => {
            const entry = entries[0]
            if (entry) {
                cancelAnimationFrame(frameID.current)
                frameID.current = requestAnimationFrame(() => {
                    const { target } = entry
                    changeIsOmit(target as HTMLElement)
                })
            }
        },

        [changeIsOmit]
    )

    useLayoutEffect(() => {
        const el = ref.current

        if (!el) {
            return
        }

        const textNode = getTextNode(el.childNodes.values())
        if (!textNode) {
            return
        }
        frameID.current = requestAnimationFrame(() => {
            changeIsOmit(textNode)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeIsOmit])

    useLayoutEffect(() => {
        const el = ref.current

        if (!el) {
            return
        }

        const observer = new MutationObserver(observerCallback)

        const textNode = getTextNode(el.childNodes.values())
        if (!textNode) {
            return
        }

        observer?.observe(textNode, {
            childList: true,
            characterData: true,
            attributes: true,
            subtree: true
        })

        return () => {
            observer?.disconnect()
            if (frameID.current) {
                cancelAnimationFrame(frameID.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [observerCallback])

    return isOmit
}

function getTextNode(nodes: IterableIterator<ChildNode>): null | HTMLElement {
    for (const node of nodes) {
        if (node.nodeType === 3) {
            return node.parentElement
        }

        if (node.hasChildNodes()) {
            const res = getTextNode(node.childNodes.values())
            if (res) {
                return res
            }
        }
    }

    return null
}

const getStyle = function (target: HTMLElement, property: string) {
    const nodeStyle = window.getComputedStyle(target)
    return nodeStyle.getPropertyValue(property).replace('px', '')
}

const getStyleToNumber = function (target: HTMLElement, property: string) {
    const nodeStyle = window.getComputedStyle(target)
    return Number.parseInt(nodeStyle.getPropertyValue(property).replace('px', ''))
}

function getActualWidthOfChars(text: string, options: { size: number; family: string }) {
    const { size = 14, family = 'Microsoft YaHei' } = options
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (ctx) {
        ctx.font = `${size}px ${family}`
        const metrics = ctx.measureText(text)
        // const actual = Math.abs(metrics.actualBoundingBoxLeft) + Math.abs(metrics.actualBoundingBoxRight)
        // return Math.max(metrics.width, actual)
        return Math.floor(metrics.width)
    }
    return 0
}
