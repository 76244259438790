import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

export const useStyles = createStyles(() => ({
    menuDropdown: css({
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        prettyScroll: true
    })
}))
