/**
 * 给 HTML 元素绑定事件
 * @export
 * @template T
 * @param {(T | null)} obj
 * @param {(...Parameters<T['addEventListener']> | [string, Function | null, ...any])} args
 */
export function on<T extends Window | Document | HTMLElement | EventTarget, K = Event>(
    object: T | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: Parameters<T['addEventListener']> | [string, ((event?: K) => boolean | undefined) | null, ...any]
): void {
    object?.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>))
}

/**
 * 给 HTML 元素解绑事件
 * @export
 * @template T
 * @param {(T | null)} obj
 * @param {(...Parameters<T['removeEventListener']> | [string, Function | null, ...any])} args
 */

export function off<T extends Window | Document | HTMLElement | EventTarget, K = Event>(
    object: T | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: Parameters<T['removeEventListener']> | [string, ((event?: K) => boolean | undefined) | null, ...any]
): void {
    object?.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>))
}

export function setCaretToEnd(element: HTMLElement) {
    const range = document.createRange()
    const selection = window.getSelection()
    range.selectNodeContents(element)
    range.collapse(false)
    selection?.removeAllRanges()
    selection?.addRange(range)
    element.focus()
}

export function getCaretCoordinates(fromStart = true) {
    let x = 0
    let y = 0
    const isSupported = Object.hasOwn(window, 'getSelection')
    if (isSupported) {
        const selection = window.getSelection()
        if (selection !== undefined && selection?.rangeCount !== 0) {
            const range = selection?.getRangeAt(0).cloneRange()
            range?.collapse(fromStart)
            const rect = range?.getClientRects()[0]
            if (rect) {
                x = rect.left
                y = rect.top
            }
        }
    }
    return { x, y }
}

export function getSelection(element: HTMLElement) {
    let selectionStart = 0
    let selectionEnd = 0
    const isSupported = Object.hasOwn(window, 'getSelection')
    if (isSupported) {
        const range = window.getSelection()?.getRangeAt(0)
        if (!range) {
            return { selectionStart, selectionEnd }
        }
        const preSelectionRange = range.cloneRange()
        preSelectionRange.selectNodeContents(element)
        preSelectionRange.setEnd(range.startContainer, range.startOffset)
        selectionStart = preSelectionRange.toString().length
        selectionEnd = selectionStart + range.toString().length
    }
    return { selectionStart, selectionEnd }
}

export function checkEmpty(element: HTMLElement) {
    return !element.innerHTML
}

export function waitDOMContentLoaded(): Promise<void> {
    return new Promise(resolve => {
        switch (document.readyState) {
            case 'interactive':
            case 'complete': {
                resolve()
                break
            }
            default: {
                window.addEventListener('DOMContentLoaded', () => {
                    resolve()
                })
                break
            }
        }
    })
}

export const createContainer = (containerStyle?: Partial<CSSStyleDeclaration>, containerClassName?: string) => {
    const container = window.document.createElement('div')
    if (containerClassName) {
        container.className = containerClassName
    }
    Object.assign(container.style, containerStyle)
    return container
}

export function getRelativeOffset(target: HTMLElement, ancestor: HTMLElement = document.documentElement) {
    let x = 0
    let y = 0
    let current = target
    while (current && current !== ancestor) {
        x += current.offsetLeft
        y += current.offsetTop
        current = current.parentElement || ancestor
    }
    return { x, y }
}

export function scrollToAnchor(anchorElement: HTMLElement | null, animation: 'auto' | 'smooth' = 'auto') {
    if (!anchorElement) {
        return
    }
    anchorElement.scrollIntoView({
        behavior: animation,
        block: 'start'
    })
}

export function readCSSVarFromRoot(name: string) {
    const root = window.document.documentElement
    return getComputedStyle(root).getPropertyValue(`--${name}`)
}

export const stopPropagation = (e: Event | React.UIEvent) => e.stopPropagation()

export function disablePropagation(element: HTMLDivElement) {
    on(element, 'drag', stopPropagation)
    on(element, 'dragstart', stopPropagation)
    on(element, 'dragend', stopPropagation)
    on(element, 'dragover', stopPropagation)
    on(element, 'dragenter', stopPropagation)
    on(element, 'dragleave', stopPropagation)
    on(element, 'drop', stopPropagation)

    on(element, 'beforeinput', stopPropagation)
    on(element, 'input', stopPropagation)
    on(element, 'compositionstart', stopPropagation)
    on(element, 'compositionupdate', stopPropagation)
    on(element, 'compositionend', stopPropagation)
    on(element, 'beforecopy', stopPropagation)
    on(element, 'beforecut', stopPropagation)
    on(element, 'beforepaste', stopPropagation)
    on(element, 'paste', stopPropagation)
    on(element, 'copy', stopPropagation)
    on(element, 'cut', stopPropagation)

    on(element, 'keydown', stopPropagation)
    on(element, 'keyup', stopPropagation)
    on(element, 'keypress', stopPropagation)

    on(element, 'mouseenter', stopPropagation)
    on(element, 'mouseleave', stopPropagation)

    on(element, 'wheel', stopPropagation)
    on(element, 'scroll', stopPropagation)

    on(element, 'touchstart', stopPropagation)
    on(element, 'touchend', stopPropagation)
    on(element, 'touchmove', stopPropagation)
    on(element, 'touchcancel', stopPropagation)

    on(element, 'pointerdown', stopPropagation)
    on(element, 'pointerup', stopPropagation)
    on(element, 'pointermove', stopPropagation)
    on(element, 'pointerover', stopPropagation)
    on(element, 'pointerout', stopPropagation)
    on(element, 'pointerenter', stopPropagation)
    on(element, 'pointerleave', stopPropagation)
    on(element, 'pointerlockchange', stopPropagation)
    on(element, 'pointerlockerror', stopPropagation)

    on(element, 'select', stopPropagation)
    on(element, 'selectionchange', stopPropagation)
    on(element, 'selectstart', stopPropagation)
    on(element, 'selectend', stopPropagation)

    on(element, 'contextmenu', stopPropagation)
    on(element, 'focus', stopPropagation)
    on(element, 'blur', stopPropagation)
    on(element, 'focusin', stopPropagation)
    on(element, 'focusout', stopPropagation)
    on(element, 'resize', stopPropagation)
    on(element, 'scroll', stopPropagation)

    return () => {
        off(element, 'drag', stopPropagation)
        off(element, 'dragstart', stopPropagation)
        off(element, 'dragend', stopPropagation)
        off(element, 'dragover', stopPropagation)
        off(element, 'dragenter', stopPropagation)
        off(element, 'dragleave', stopPropagation)
        off(element, 'drop', stopPropagation)

        off(element, 'beforeinput', stopPropagation)
        off(element, 'input', stopPropagation)
        off(element, 'compositionstart', stopPropagation)
        off(element, 'compositionupdate', stopPropagation)
        off(element, 'compositionend', stopPropagation)
        off(element, 'beforecopy', stopPropagation)
        off(element, 'beforecut', stopPropagation)
        off(element, 'beforepaste', stopPropagation)
        off(element, 'paste', stopPropagation)
        off(element, 'copy', stopPropagation)
        off(element, 'cut', stopPropagation)

        off(element, 'keydown', stopPropagation)
        off(element, 'keyup', stopPropagation)
        off(element, 'keypress', stopPropagation)

        off(element, 'mouseenter', stopPropagation)
        off(element, 'mouseleave', stopPropagation)

        off(element, 'wheel', stopPropagation)
        off(element, 'scroll', stopPropagation)

        off(element, 'touchstart', stopPropagation)
        off(element, 'touchend', stopPropagation)
        off(element, 'touchmove', stopPropagation)
        off(element, 'touchcancel', stopPropagation)

        off(element, 'pointerdown', stopPropagation)
        off(element, 'pointerup', stopPropagation)
        off(element, 'pointermove', stopPropagation)
        off(element, 'pointerover', stopPropagation)
        off(element, 'pointerout', stopPropagation)
        off(element, 'pointerenter', stopPropagation)
        off(element, 'pointerleave', stopPropagation)
        off(element, 'pointerlockchange', stopPropagation)
        off(element, 'pointerlockerror', stopPropagation)

        off(element, 'select', stopPropagation)
        off(element, 'selectionchange', stopPropagation)
        off(element, 'selectstart', stopPropagation)
        off(element, 'selectend', stopPropagation)

        off(element, 'contextmenu', stopPropagation)
        off(element, 'focus', stopPropagation)
        off(element, 'blur', stopPropagation)
        off(element, 'focusin', stopPropagation)
        off(element, 'focusout', stopPropagation)
        off(element, 'resize', stopPropagation)
        off(element, 'scroll', stopPropagation)
    }
}

/**
 * 查找实际的父级可滚动的元素
 * @date 8/24/2023 - 5:11:05 PM
 *
 * @export
 * @param {(HTMLElement | null)} el
 * @returns {(HTMLElement | null)}
 */
export function findParentScroller(el: HTMLElement | null | undefined): HTMLElement | null {
    if (!el) {
        return document.body
    }
    const { overflowY } = window.getComputedStyle(el)
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden'

    if (isScrollable && el.scrollHeight >= el.clientHeight) {
        return el
    }

    return findParentScroller(el.parentElement)
}

/**
 * 查找指定className的父级
 * @param el 当前元素
 * @param className 要查找的父级元素
 * @returns 父元素
 */
export function findParentByClassName(el: HTMLElement | null, className: string): HTMLElement | null {
    if (!el) {
        return null
    }

    if (el.parentElement?.classList.contains(className)) {
        return el.parentElement
    }

    return findParentByClassName(el.parentElement, className)
}

export function findParentById(el: HTMLElement | null, id: string): HTMLElement | null {
    if (!el) {
        return null
    }

    if (el.parentElement?.id === id) {
        return el.parentElement
    }

    return findParentById(el.parentElement, id)
}
