import { createStyles } from '../../theme/createStyles'
import { css } from '../../theme/stitches.config'

export const useStyles = createStyles(() => ({
    view: css({
        position: 'relative'
        // backgroundColor: '$colorWhite'
    }),
    header: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 16
    }),
    cancel: css({
        cursor: 'pointer',
        color: '$colorPrimary'
    }),
    confirm: css({
        cursor: 'pointer',
        color: '$colorPrimary'
    }),
    body: css({
        display: 'flex',
        height: 220
    }),
    mask: css({
        pointerEvents: 'none',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 220,
        background:
            'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.65) 40%, rgba(255, 255, 255, 0.00) 40%, rgba(255, 255, 255, 0.00) 60%, rgba(255, 255, 255, 0.65) 60%, #FFF 100%)'
    }),
    indicator: css({
        position: 'absolute',
        top: '50%',
        left: 0,
        width: '100%',
        height: 44,
        transform: `translateY(-50%)`,
        borderTop: '1px solid $colorGray200',
        borderBottom: '1px solid $colorGray200'
    })
}))
