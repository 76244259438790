import { Empty, Loading } from '@byecode/ui'
import type { ChartLikeProtocol, IndicatorOption } from '@lighthouse/core'
import { ChartType } from '@lighthouse/core'
import { ChartRenderer, getChartOption } from '@lighthouse/shared'
import React, { useMemo } from 'react'

import { ChartTypeIconMap, ChartTypePlaceHolderMap } from '../../constants'
import * as SC from './styles'

interface ChartViewContentProps {
    title?: string
    loading?: boolean
    chartData?: ChartLikeProtocol
    chartOption: IndicatorOption
}

export const IndicatorChartViewContent: React.FC<ChartViewContentProps> = ({ title, loading, chartData, chartOption }) => {
    const { fieldId, calcType, pointer } = chartOption
    const isChartEmpty = useMemo(() => {
        return !fieldId || !calcType
    }, [calcType, fieldId])

    const option = useMemo(() => {
        if (isChartEmpty) {
            return {}
        }
        return getChartOption(ChartType.indicator, { ...chartOption, title }, chartData)
    }, [chartData, chartOption, isChartEmpty, title])

    const content = useMemo(() => {
        if (loading) {
            return <Loading />
        }
        if (!pointer || !chartData || isChartEmpty) {
            return <Empty icon={ChartTypeIconMap['indicator']} description={ChartTypePlaceHolderMap['indicator']} />
        }
        return <ChartRenderer chartType={ChartType.indicator} option={option} />
    }, [loading, pointer, chartData, isChartEmpty, option])

    return <SC.IndicatorChartViewContentWrapper>{content}</SC.IndicatorChartViewContentWrapper>
}
