
import type { BlockProtocol, BlockVisibilityProtocol, RichTextContentProtocol } from '../../protocols'
import { BlockType } from '../Basic'


export enum QrBarcodeEnum {
    'QR_CODE' = 'QR_CODE',
    'BARCODE' = 'BARCODE'
}

export interface QrBarcodeBlockConfig extends BlockVisibilityProtocol {
    codeType: QrBarcodeEnum
    content: {
        value: RichTextContentProtocol | undefined
    }
    barWidth?: number
}

export abstract class QrBarcodeBlockAbstract implements BlockProtocol {
    type = BlockType.qrBarcode

    abstract title: string

    abstract id: string

    abstract config: QrBarcodeBlockConfig
}
