import type { CSSProperties } from 'react'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

interface FormulaPreviewerProps {
    formulaText?: string
    style?: CSSProperties
    onClick?: () => void
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    min-height: 32px;
`

const Content = styled.pre`
    display: block;
    width: 100%;
    height: 100%;
    min-height: 32px;
    padding: 8px 4px;
    /* min-height: 25px; */
    overflow-x: hidden;
    word-break: break-word;
    white-space: pre-wrap;
    font-size: 12px;
    border-radius: 6px;
    background: #f5f5f5 !important;
`

const FormulaPreviewer = forwardRef<HTMLDivElement, FormulaPreviewerProps>(({ formulaText = '', style, onClick }, ref) => {
    return (
        <Container ref={ref} style={style} onClick={onClick}>
            <Content>{formulaText}</Content>
        </Container>
    )
})

export default FormulaPreviewer
