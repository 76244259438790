import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const SCxStyledInputWrapper = styled.div`
    position: relative;
    font-size: 14px;
    height: 38px;
    border-bottom: 1px solid var(--color-gray-200);

    &:focus,
    &:focus-within {
        border-color: var(--color-main);
    }
`

const SCxStyledInput = styled.input`
    width: 100%;
    height: 100%;
    padding: 2px 8px;
    border: none;
    outline: none;

    &::placeholder {
        font-size: 14px;
        color: var(--color-gray-300);
    }
`

export interface TableFieldInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    autoSelect?: boolean
}

export const TableFieldInput: React.FC<TableFieldInputProps> = ({ autoSelect, ...restProps }) => {
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (autoSelect && inputRef.current) {
            inputRef.current.select()
        }
    }, [autoSelect])
    return (
        <SCxStyledInputWrapper>
            <SCxStyledInput ref={inputRef} {...restProps} />
        </SCxStyledInputWrapper>
    )
}
