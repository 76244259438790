import React, { useEffect, useMemo, useState } from 'react'

import {
    StyledIndicatorLoadingWrapper,
    StyledLoadingCommonContent,
    StyledLoadingCommonWrapper,
    StyledLoadingDescription,
    StyledLoadingOutlinedContent,
    StyledLoadingOutlinedWrapper
} from './styles'
import type { LoadingProps, MarkerProps } from './types'

const Marker: React.FC<MarkerProps> = ({ size, transparentTail, iconColor = '#ccc' }) => {
    const loadingColor = transparentTail ? '#fff' : iconColor

    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 200 200">
            <g id="load">
                <linearGradient id="right" gradientUnits="userSpaceOnUse" x1="150" y1="20" x2="150" y2="180">
                    <stop offset="0" stopColor={loadingColor} stopOpacity="0" />
                    <stop offset="1" stopColor={loadingColor} stopOpacity="0.7" />
                </linearGradient>
                <path
                    fill="url(#right)"
                    d="M100,0v20c44.1,0,80,35.9,80,80c0,44.1-35.9,80-80,80v20c55.2,0,100-44.8,100-100S155.2,0,100,0z"
                />
                <linearGradient id="left" gradientUnits="userSpaceOnUse" x1="50" y1="0" x2="50" y2="180">
                    <stop offset="0" stopColor={loadingColor} />
                    <stop offset="1" stopColor={loadingColor} stopOpacity="0.7" />
                </linearGradient>
                <path
                    fill="url(#left)"
                    d="M20,100c0-44.1,35.9-80,80-80V0C44.8,0,0,44.8,0,100s44.8,100,100,100v-20C55.9,180,20,144.1,20,100z"
                />
                <circle fill={loadingColor} cx="100" cy="10" r="10" />
            </g>
        </svg>
    )
}

const Indicator: React.FC<{ description?: string | React.ReactNode; loadingColor?: string; background?: string }> = ({
    description = '正在删除',
    background,
    loadingColor = '#649BFA'
}) => {
    const [count, setCount] = useState(0)
    useEffect(() => {
        const timer = setInterval(() => {
            setCount(count => {
                if (count >= 98) {
                    clearInterval(timer)
                    return count
                }
                if (count >= 90) {
                    return count + Math.random()
                }
                return count + Math.random() * 10
            })
        }, 300)

        return () => {
            clearInterval(timer)
        }
    }, [])

    return (
        <StyledIndicatorLoadingWrapper style={{ background }}>
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="8" width="4" height="16" rx="2" fill="#EDEEFF" />
                <rect x="6" y="4" width="4" height="24" rx="2" fill="#EDEEFF" />
                <rect x="13" y="0.5" width="4" height="32" rx="2" fill="#EDEEFF" />
                <rect x="20" y="4" width="4" height="24" rx="2" fill="#EDEEFF" />
                <rect x="27" y="8" width="4" height="16" rx="2" fill="#EDEEFF" />
                <rect y="8" width="4" height="7" rx="2" fill={loadingColor}>
                    <animateMotion dur="1s" keyPoints="0;1;0" keyTimes="0;0.5;1" repeatCount="indefinite" path="M0,0 L0,9" />
                </rect>
                <rect x="6" y="4" width="4" height="11" rx="2" fill={loadingColor}>
                    <animateMotion dur="1s" keyPoints="0;1;0" keyTimes="0;0.5;1" repeatCount="indefinite" path="M0,13 L0,0" />
                </rect>
                <rect x="13" y="0.5" width="4" height="13" rx="2" fill={loadingColor}>
                    <animateMotion dur="1s" keyPoints="0;1;0" keyTimes="0;0.5;1" repeatCount="indefinite" path="M0,0 L0,19" />
                </rect>
                <rect x="20" y="4" width="4" height="10" rx="2" fill={loadingColor}>
                    <animateMotion dur="1s" keyPoints="0;1;0" keyTimes="0;0.5;1" repeatCount="indefinite" path="M0,14 L0,0" />
                </rect>
                <rect x="27" y="8" width="4" height="8" rx="2" fill={loadingColor}>
                    <animateMotion dur="1s" keyPoints="0;1;0" keyTimes="0;0.5;1" repeatCount="indefinite" path="M0,0 L0,8" />
                </rect>
            </svg>
            <StyledLoadingDescription>
                {description} ({count.toFixed(0)}%)...
            </StyledLoadingDescription>
        </StyledIndicatorLoadingWrapper>
    )
}

export const Loading = React.forwardRef<HTMLDivElement, LoadingProps>(
    (
        {
            background,
            iconColor = 'var(--color-gray-300)',
            outlined = false,
            shape = 'common',
            size = 20,
            description,
            loadingColor,
            className,
            transparentTail = false
        },
        ref
    ) => {
        if (shape === 'indicator') {
            return <Indicator background={background} loadingColor={loadingColor} description={description} />
        }

        return outlined ? (
            <StyledLoadingOutlinedWrapper className={className} ref={ref}>
                <StyledLoadingOutlinedContent style={{ width: size, height: size, fontSize: size - 4 }}>
                    <Marker size={size} transparentTail={transparentTail} iconColor={iconColor} />
                </StyledLoadingOutlinedContent>
                {description}
            </StyledLoadingOutlinedWrapper>
        ) : (
            <StyledLoadingCommonWrapper className={className} ref={ref}>
                <StyledLoadingCommonContent>
                    <div style={{ background: iconColor }} />
                    <div style={{ background: iconColor }} />
                    <div style={{ background: iconColor }} />
                    <div style={{ background: iconColor }} />
                    <div style={{ background: iconColor }} />
                </StyledLoadingCommonContent>
                {description && <StyledLoadingDescription>{description}</StyledLoadingDescription>}
            </StyledLoadingCommonWrapper>
        )
    }
)
