import type { ApplicationSettingNavbarButton, ApplicationSettingTheme, ButtonEvents } from '@lighthouse/core'
import { Popover } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { debounce } from 'throttle-debounce'

import * as CM from '../styles'
import * as SC from './styles'

export interface MultilevelSelectProps {
    target?: React.ReactNode
    value: Pick<ApplicationSettingNavbarButton, 'id' | 'child'>
    activePage?: string
    level: number
    position: 'bottom' | 'right'
    theme?: ApplicationSettingTheme
    dataNavLink?: string
    disabled?: boolean
    dataList: Record<string, ApplicationSettingNavbarButton>
    style?: React.CSSProperties

    onCommonCallBack?: (params: ButtonEvents) => void
    onChangeSize?: (
        id: string,
        size: {
            width: number
            height: number
        }
    ) => void
}

interface SelectItemProps {
    dataList: Record<string, ApplicationSettingNavbarButton>
    value: ApplicationSettingNavbarButton
    level: number

    onCommonCallBack?: (params: ButtonEvents) => void
}

const SelectItem: React.FC<SelectItemProps> = ({ value, dataList, level, ...rest }) => {
    const [opened, setOpen] = useState(false)

    const { id, icon = '', name, child = [], events, showType, isAccess } = value

    const isShowName = useMemo(() => showType !== 'icon' && name !== '', [name, showType])

    const isShowIcon = useMemo(() => showType !== 'name' && icon !== '', [icon, showType])

    const handleChangePopover = useMemo(() => debounce(300, (open: boolean) => setOpen(open)), [])

    if (!isAccess) {
        return null
    }

    return (
        <SC.PrimaryContain>
            <SC.Item
                onMouseEnter={() => handleChangePopover(true)}
                onClick={() => child.length === 0 && rest.onCommonCallBack?.(events)}
                onMouseLeave={() => handleChangePopover(false)}
            >
                <SC.LeftFill>
                    {isShowIcon && <CM.Icon type={icon} />}
                    {isShowName && <CM.Text isOmit>{name}</CM.Text>}
                </SC.LeftFill>
                {child.length > 0 && (
                    <SC.RightFill>
                        <CM.Icon type="ArrowRightSmall" />
                    </SC.RightFill>
                )}
            </SC.Item>
            {opened && child.length > 0 && (
                <SC.SecondaryContain onMouseEnter={() => handleChangePopover(true)} onMouseLeave={() => handleChangePopover(false)}>
                    {child.map(nodeId => (
                        <SelectItem key={nodeId} level={level + 1} value={dataList[nodeId]} dataList={dataList} {...rest} />
                    ))}
                </SC.SecondaryContain>
            )}
        </SC.PrimaryContain>
    )
}

const MultilevelSelect: React.FC<MultilevelSelectProps> = ({
    value,
    target,
    dataList,
    level,
    activePage,
    position,
    theme,
    style,
    disabled,
    onChangeSize,
    ...rest
}) => {
    const [opened, setOpen] = useState(false)
    const { ref: linkBtnRef, width, height } = useElementSize<HTMLDivElement>()

    const { id, child = [] } = value

    useEffect(() => {
        onChangeSize?.(id, {
            width,
            height
        })
    }, [height, id, onChangeSize, width])

    const handleChangePopover = useMemo(
        () =>
            debounce(300, (open: boolean) => {
                if (open && child.length === 0) {
                    return
                }
                setOpen(open)
            }),
        [child.length]
    )

    return (
        <Popover
            width={200}
            styles={{
                dropdown: {
                    padding: '8px 0'
                }
            }}
            position={position}
            withinPortal
            shadow="md"
            opened={opened}
            disabled={disabled}
        >
            <Popover.Target>
                <SC.TargetContainer
                    ref={linkBtnRef}
                    style={style}
                    onMouseEnter={() => handleChangePopover(true)}
                    onMouseLeave={() => handleChangePopover(false)}
                >
                    {target}
                </SC.TargetContainer>
            </Popover.Target>
            <Popover.Dropdown>
                <SC.Container onMouseEnter={() => handleChangePopover(true)} onMouseLeave={() => handleChangePopover(false)}>
                    {child.map(nodeId => (
                        <SelectItem key={nodeId} value={dataList[nodeId]} dataList={dataList} level={level + 1} {...rest} />
                    ))}
                </SC.Container>
            </Popover.Dropdown>
        </Popover>
    )
}


export default MultilevelSelect
