import type { PopoverProps } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'

import type { MenuOpItem } from '../../../components/UserMenu'
import type { LoginAuthType } from '../../AccountPage'
import { ModalUserCenter } from './ModalUserCenter'
import { PopoverUserCenter } from './PopoverUserCenter'

interface UserCenterProps extends Pick<PopoverProps, 'position'> {
    userData: AppUser
    isMobile?: boolean
    style?: React.CSSProperties
    onLogout?: () => void
    onUpdateBindAccount?: (isBind: boolean, type: LoginAuthType) => Promise<boolean>
}

export const UserCenter: React.FunctionComponent<UserCenterProps> = ({
    userData,
    style,
    isMobile,
    position,
    onLogout,
    onUpdateBindAccount
}) => {
    if (isMobile) {
        return <ModalUserCenter userData={userData} style={style} onLogout={onLogout} onUpdateBindAccount={onUpdateBindAccount} />
    }

    return (
        <PopoverUserCenter
            userData={userData}
            position={position}
            style={style}
            onLogout={onLogout}
            onUpdateBindAccount={onUpdateBindAccount}
        />
    )
}
