import { Empty, IconFont, Popover, usePopoverHeight } from '@byecode/ui'
import { find, reduce } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useClickAway } from 'react-use'

import type { I18TranslationKey } from '../../../../../types'
import { SelectItemPreviewer } from '../../../SelectItemPreviewer'
import { SelectPlaceHolder } from '../../../SelectPlaceHolder'
import * as CM from '../../commonStyles'
import * as SC from './styles'

type OptionItem = { label: string; value: string; color?: string; isDelete?: boolean }
interface MultiSelectProps {
    opened: boolean
    options: OptionItem[]
    onChangePopover: (v: boolean) => void
    onChange?: (value: string[]) => void
    value: string[]
    width?: number
    isMultiple?: boolean
    placeholder?: string
    readOnly?: boolean
}

const MultiSelect: React.FunctionComponent<MultiSelectProps> = ({
    options,
    onChangePopover,
    opened,
    onChange,
    isMultiple = false,
    value,
    width,
    placeholder,
    readOnly = false,
}) => {
    const [searchWord, setSearchWord] = useState('')
    const inputRef = useRef<HTMLInputElement>(null)
    const dropDownRef = useRef<HTMLDivElement>(null)

    const { t } = useTranslation<I18TranslationKey>()
    const { height: popoverMaxHeight, ref } = usePopoverHeight(opened, 560)

    const currentOptions = useMemo(() => options.filter(item => item.label.includes(searchWord)), [options, searchWord])

    const currentTag = useMemo(() => {
        const valueOptions = reduce<string, OptionItem[]>(
            (preVal, label) => {
                const item = find(item => item.label === label, options)
                if (!item) {
                    return preVal
                }
                return [...preVal, item]
            },
            [],
            value
        )
        return reduce<OptionItem, OptionItem[]>(
            (preVal, curVal) => {
                if (value.includes(curVal.value)) {
                    return [...preVal, curVal]
                }
                return preVal
            },
            [],
            valueOptions
        )
    }, [options, value])

    useEffect(() => {
        if (!opened) {
            setSearchWord('')
        }

        inputRef.current?.focus()
    }, [opened])


    const handMultipleChange = useCallback(
        (data: string) => {
            if (isMultiple) {
                const newValue = value.filter(item => item !== data)
                return onChange?.([...newValue, data])
            }
            onChange?.([data])
            onChangePopover(false)
        },
        [isMultiple, onChange, onChangePopover, value]
    )

    const handleRemove = useCallback(
        (data: string) => {
            onChange?.(value.filter(item => item !== data))
        },
        [onChange, value]
    )

    return (
        <Popover
            opened={opened}
            zIndex={200}
            positionDependencies={[options]}
            position="bottom-start"
            onChange={onChangePopover}
            width={width}
            closeOnEscape
            withinPortal
            disabled={readOnly ||  options.length === 0}
        >
            <Popover.Target>
                <SC.TargetContainer
                    type="button"
                    disabled={readOnly}
                >
                    <SC.ValueContainer ref={ref}>
                        <SC.TagWrapper>
                            {currentTag.map(item => (
                                <SelectItemPreviewer
                                    key={item.value}
                                    data={item}
                                    rightSection={
                                        item.isDelete && (
                                            <CM.IconWrapper
                                                onMouseDown={e => {
                                                    e.stopPropagation()
                                                    handleRemove(item.value)
                                                }}
                                            >
                                                <IconFont type="Close" size={12} />
                                            </CM.IconWrapper>
                                        )
                                    }
                                />
                            ))}
                            {currentTag.length === 0 && <SelectPlaceHolder label={placeholder} />}
                        </SC.TagWrapper>
                    </SC.ValueContainer>
                    <SC.RightSection>
                        {!readOnly && <IconFont type="ArrowDownSmall" color="var(--color-gray-400)" size={16} />}
                    </SC.RightSection>
                </SC.TargetContainer>
            </Popover.Target>
            <Popover.Dropdown compact>
                <SC.DropDownContainer data-type="fl-node-part" ref={dropDownRef}>
                    <SC.SearchInput
                        value={searchWord}
                        autoFocus
                        onChange={e => setSearchWord(e.target.value)}
                        onBlur={e => setSearchWord(e.target.value)}
                        placeholder={t('search')}
                        prefix={<IconFont type="Search" color="var(--color-gray-500)" />}
                    />
                    <SC.List style={{ maxHeight: (popoverMaxHeight ?? 0) - 40 }}>
                        {currentOptions.length === 0 && (
                            <Empty
                                styles={{
                                    root: {
                                        padding: '42px'
                                    }
                                }}
                                icon="SearchLine"
                                description={t('noFindData')}
                            />
                        )}
                        {currentOptions.map(item => (
                            <SC.DropDownItem key={item.value} onClick={() => handMultipleChange(item.value)}>
                                <SelectItemPreviewer data={item} />
                            </SC.DropDownItem>
                        ))}
                    </SC.List>
                </SC.DropDownContainer>
            </Popover.Dropdown>
        </Popover>
    )
}

export default MultiSelect
