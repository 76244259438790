import { enableAllPlugins, setAutoFreeze, setUseProxies } from 'immer'
// @ts-expect-error missing types
import * as hasOwn from 'object.hasown'
import ResizeObserver from 'resize-observer-polyfill'

setUseProxies(true)
setAutoFreeze(true)
enableAllPlugins()

if (!Object.hasOwn) {
    hasOwn.shim()
}

if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver
}
