import { useMove } from '@byecode/ui/hooks/useMove'
import React from 'react'
import styled from 'styled-components'

import type { HsvaColor } from '../type'

const Container = styled.div`
    padding: 8px 0;
`

const Stack = styled.div`
    position: relative;
    height: calc(268px - var(--thumb-size));
    margin: calc(var(--thumb-size) / 2);
    box-sizing: border-box;
    user-select: none;
    --thumb-size: 22px;
`

const Layer = styled.div`
    position: absolute;
    inset: calc(var(--thumb-size) / 2 * -1);
    border-radius: 4px;
`

const Thumb = styled.button`
    all: unset;
    box-sizing: border-box;
    position: absolute;
    width: var(--thumb-size);
    height: var(--thumb-size);
    border: 4px solid #fff;
    border-radius: 50%;
`

interface SaturationValuePanelProps {
    value: HsvaColor
    onChange: (value: HsvaColor) => void
    onChangeEnd?: (value: HsvaColor) => void
}

export const SaturationValuePanel = ({ value, onChange, onChangeEnd }: SaturationValuePanelProps) => {
    const { ref } = useMove(
        ({ ratio: { x, y } }) => {
            const s = Math.round(x * 100) / 100
            const v = Math.round((1 - y) * 100) / 100
            onChange({ ...value, s, v })
        },
        {
            onEnd: ({ ratio: { x, y } }) => {
                const s = Math.round(x * 100) / 100
                const v = Math.round((1 - y) * 100) / 100
                onChangeEnd?.({ ...value, s, v })
            }
        }
    )

    return (
        <Container>
            <Stack ref={ref}>
                <Layer style={{ background: `hsl(${value.h}, 100%, 50%)` }} />
                <Layer style={{ background: 'linear-gradient(90deg, rgb(255,255,255), transparent)' }} />
                <Layer style={{ background: 'linear-gradient(0deg, rgb(0,0,0), transparent)' }} />
                <Thumb
                    style={{
                        left: `calc(${value.s * 100}% - 11px)`,
                        top: `calc(${(1 - value.v) * 100}% - 11px)`
                    }}
                />
            </Stack>
        </Container>
    )
}
