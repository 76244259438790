import type { FilterOption, RecordLikeProtocol, ViewRecordListIdentity } from '@lighthouse/core'
import type { Draft } from 'immer'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { atomWithImmer } from 'jotai-immer'
import { find } from 'rambda'
import shallowEqual from 'shallowequal'

import { recordPoolAtom } from '../dataSource/state'
import type { ViewRecordPool } from './types'

// id 为 blockId-filterItemId
export const filterBlockOptionsAtom = atomWithImmer<Record<string, FilterOption[]>>({})

export const viewRecordPoolAtom = atomWithImmer<ViewRecordPool>({})

export const viewRecordListAtomFamily = atomFamily((viewId: ViewRecordListIdentity) => {
    return atom(
        get => {
            const ViewRecordPool = get(viewRecordPoolAtom)
            const dsRecords = get(recordPoolAtom)
            const viewRecords = ViewRecordPool?.[viewId]
            if (!viewRecords) {
                return undefined
            }
            return viewRecords.reduce<RecordLikeProtocol[]>((records, viewRecord) => {
                const record = find(
                    item => viewRecord.appId === item.appId && viewRecord.dsId === item.dsId && viewRecord.id === item.id,
                    dsRecords
                )
                if (record) {
                    const viewRecordData = record.content
                    records.push({
                        id: viewRecord.id,
                        envId: viewRecord?.envId,
                        appId: viewRecord.appId,
                        dsId: viewRecord.dsId,
                        content: viewRecordData
                    })
                }
                return records
            }, [])
        },
        (_, set, payload: RecordLikeProtocol[] | ((draft: Draft<RecordLikeProtocol[]>) => void)) =>
            set(viewRecordPoolAtom, draft => {
                if (typeof payload === 'function') {
                    payload(draft[viewId])
                    return
                }
                draft[viewId] = payload
            })
    )
}, shallowEqual)
