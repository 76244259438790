import { IconFont } from '@byecode/ui'
import { type DTSelectItem, type FilterCommonCondition, VariableType } from '@lighthouse/core'
import { filter } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { SelectItemPreviewer } from '../../FieldValuePreviewer/SelectValuePreviewer'

const SCxSelectParamsWrapper = styled.div`
    background-color: var(--color-gray-100);
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-200);
    }
`

const SCxContent = styled.div`
    height: 100%;
    color: var(--color-black);
    flex: 1;
    display: flex;
    gap: 6px;
    align-items: center;
    flex-flow: row nowrap;
    overflow: hidden;
`

const SCxIcon = styled(IconFont)`
    color: var(--color-gray-400);
    &:hover {
        color: var(--color-gray-800);
    }
`

interface SelectParamsProps {
    filterItem: FilterCommonCondition
    selectOptions: DTSelectItem[]
    onClick?: () => void
    onClear?: () => void
}

export const SelectParams: React.FC<SelectParamsProps> = ({ filterItem, selectOptions, onClick, onClear }) => {
    // const value = filterItem?.params as string[] | undefined
    const value = useMemo(() => {
        const { paramList = [] } = filterItem
        return paramList.reduce<string[]>((prev, cur) => {
            if (cur.type === VariableType.VALUE && cur.valueVariable?.value && Array.isArray(cur.valueVariable?.value)) {
                prev = cur.valueVariable?.value as string[]
            }
            return prev
        }, [])
    }, [filterItem])
    const selectedOptions = useMemo(() => filter(item => value.includes(item.label), selectOptions), [selectOptions, value])

    const selectSuffixDom = value ? (
        <SCxIcon
            type="CloseCircle"
            style={{ cursor: 'pointer' }}
            size={16}
            onClick={e => {
                e.stopPropagation()
                onClear?.()
            }}
        />
    ) : (
        <IconFont color="var(--color-gray-400)" size={16} type="ArrowDownSmall" />
    )

    return (
        <SCxSelectParamsWrapper>
            <SCxContent onClick={onClick}>
                {selectedOptions.map(item => (
                    <SelectItemPreviewer key={item.label} id={item.id} data={item} />
                ))}
            </SCxContent>
            {/* <SCxIcon type="ArrowDownSmallGray" /> */}
            {selectSuffixDom}
        </SCxSelectParamsWrapper>
    )
}
