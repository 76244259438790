import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

interface StylesParams {
    compact?: boolean
}

export const useStyles = createStyles<'dropdown', StylesParams>(({ compact }) => ({
    dropdown: css({
        background: '$colorWhite',
        boxShadow: '0px 4px 12px rgba(16, 24, 40, 0.1)',
        borderRadius: '8px',
        padding: compact ? undefined : '8px 0',
        border: '1px solid $colorGray200'
    })
}))
