import { globalCss, styled } from '@byecode/ui/theme/stitches.config'
import React, { useMemo } from 'react'
import type { Id, ToastOptions, UpdateOptions } from 'react-toastify'
import { toast, ToastContainer } from 'react-toastify'

import { Button } from '../Button'
import { IconFont } from '../IconFont'

export type MessageType = 'info' | 'success' | 'error' | 'warning'

const messageTypeInfoMap = {
    info: {
        icon: 'InfoCircle',
        backgroundColor: 'var(--color-gray-200)',
        color: 'var(--color-black)'
    },
    success: {
        icon: 'Tick',
        backgroundColor: '#F6FFED',
        color: '#52C41A'
    },
    error: {
        icon: 'CloseCircle',
        backgroundColor: '#FFF2F0',
        color: '#F5222D'
    },
    warning: {
        icon: 'WarningCircle',
        backgroundColor: '#FFF7E6',
        color: '#FAAD14'
    }
}

export interface MessageProps {
    type?: MessageType
    title?: React.ReactNode
    icon?: React.ReactNode
    description?: React.ReactNode
    autoClose?: ToastOptions['autoClose']
    containerId?: string
    device?: 'mobile' | 'desktop'
    onClick?: (event: React.MouseEvent) => void
}

export type MessageInfo = Omit<MessageProps, 'type'>

const MESSAGE_CONTAINER_ID = 'global-message-container'

const globalMessageContainerStyle = globalCss({
    [`#${MESSAGE_CONTAINER_ID}`]: {
        '.Toastify__toast': {
            borderRadius: '8px',
            boxShadow: '0px 4px 12px 0px #1018281A',
            border: '1px solid #E4E7EC',
            color: 'var(--color-black)'
        },
        '.Toastify__toast-body': {
            padding: '8px'
        }
    }
})

const SCxMessageWrapper = styled('div', {
    display: 'flex'
})

const SCxMessageIconWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '36px',
    height: '36px',
    marginTop: '4px',
    marginRight: '8px',
    fontSize: '20px',
    borderRadius: '8px'
})

const SCxMessageContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1
})

const SCxMessageTitle = styled('div', {
    color: 'var(--color-black)',
    fontWeight: 'var(--font-weight-bold)',
    fontSize: 'var(--font-size-normal)'
    // marginBottom: '4px'
})

const SCxMessageDescription = styled('div', {
    fontSize: 'var(--font-size-sm)'
})

export const MessageBody: React.FC<MessageProps> = ({ type = 'success', icon, title, description, onClick }) => {
    const messageInfo = messageTypeInfoMap[type]
    const { icon: iconType, backgroundColor, color } = messageInfo

    const iconStyle = useMemo(() => {
        if (icon) {
            return {
                border: '1px solid var(--color-gray-50)'
            }
        }

        return {
            color,
            backgroundColor
        }
    }, [backgroundColor, color, icon])

    return (
        <SCxMessageWrapper onClick={onClick}>
            {icon !== false && <SCxMessageIconWrapper style={iconStyle}>{icon || <IconFont type={iconType} />}</SCxMessageIconWrapper>}

            <SCxMessageContent>
                <SCxMessageTitle>{title}</SCxMessageTitle>
                <SCxMessageDescription>{description}</SCxMessageDescription>
            </SCxMessageContent>
        </SCxMessageWrapper>
    )
}

export interface MessageContainerProps {
    containerId?: string
    isMobile?: boolean
}

export const MessageContainer: React.FC<MessageContainerProps> = ({ containerId = MESSAGE_CONTAINER_ID, isMobile }) => {
    globalMessageContainerStyle()

    const messageProps = useMemo(() => {
        if (isMobile) {
            return {
                position: 'top-center',
                draggableDirection: 'y',
                style: {
                    top: 60
                }
            } as ToastOptions
        }
        if (containerId === 'host-page-container') {
            return {
                style: {
                    position: 'absolute'
                },
                position: 'top-right'
            } as ToastOptions
        }
    }, [containerId, isMobile])

    return <ToastContainer enableMultiContainer containerId={containerId} {...messageProps} />
}

const TOAST_CONFIG: ToastOptions = {
    icon: false,
    hideProgressBar: true,
    containerId: MESSAGE_CONTAINER_ID,
    autoClose: 3000,
    draggable: true,
    draggablePercent: 40,
    closeButton: () => <Button size="sm" type="text" icon={<IconFont type="Close" />} />
}

const closeButton = () => <Button size="sm" type="text" icon={<IconFont type="Close" />} />

const info = (messageInfo: MessageInfo, options?: ToastOptions) => {
    const { autoClose, containerId = TOAST_CONFIG.containerId, device } = messageInfo
    return toast(<MessageBody type="info" {...messageInfo} />, {
        ...TOAST_CONFIG,
        containerId,
        closeButton: autoClose ? false : closeButton,
        type: 'info',
        draggableDirection: device === 'mobile' ? 'y' : 'x',
        ...options
    })
}

const success = (messageInfo: MessageInfo, options?: ToastOptions) => {
    const { autoClose, containerId = TOAST_CONFIG.containerId, device } = messageInfo
    return toast(<MessageBody type="success" {...messageInfo} />, {
        ...TOAST_CONFIG,
        containerId,
        closeButton: autoClose ? false : closeButton,
        type: 'success',
        draggableDirection: device === 'mobile' ? 'y' : 'x',
        ...options
    })
}

const warning = (messageInfo: MessageInfo, options?: ToastOptions) => {
    const { autoClose, containerId = TOAST_CONFIG.containerId, device } = messageInfo
    return toast(<MessageBody type="warning" {...messageInfo} />, {
        ...TOAST_CONFIG,
        containerId,
        closeButton: autoClose ? false : closeButton,
        type: 'warning',
        draggableDirection: device === 'mobile' ? 'y' : 'x',
        ...options
    })
}

const error = (messageInfo: MessageInfo, options?: ToastOptions) => {
    const { autoClose, containerId = TOAST_CONFIG.containerId, device } = messageInfo
    return toast(<MessageBody type="error" {...messageInfo} />, {
        ...TOAST_CONFIG,
        containerId,
        closeButton: autoClose ? false : closeButton,
        type: 'error',
        draggableDirection: device === 'mobile' ? 'y' : 'x',
        ...options
    })
}

const update = (toastId: Id, options?: UpdateOptions | undefined) => {
    toast.update(toastId, { containerId: TOAST_CONFIG.containerId, ...options })
}

export const Message = {
    success,
    warning,
    error,
    info,
    update
}
