import type { ApplicationSettingDomain } from '@lighthouse/core'
import type { FC, PropsWithChildren } from 'react'
import React, { createContext, useContext } from 'react'

export type DomainContextType = ApplicationSettingDomain

export const defaultDomain: ApplicationSettingDomain = {
    isUsedCustomDomain: false,
    domainPrefix: '',
    customDomain: ''
}
export const DomainContext = createContext<DomainContextType>(defaultDomain)

type DomainProviderProps = PropsWithChildren<{
    value: ApplicationSettingDomain
}>

export const DomainProvider: FC<DomainProviderProps> = props => <DomainContext.Provider {...props} />

export const useDomainContext = () => useContext(DomainContext)
