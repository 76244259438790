import { useCallback, useEffect, useRef } from 'react'
import { useImmer } from 'use-immer'

export function useLoadings() {
    const [loadings, setLoadings] = useImmer<Record<string, boolean>>({})

    const timersRefs = useRef<Record<string, number>>({})

    const startLoading = useCallback(
        (id: string, autoStop = true) => {
            setLoadings(draft => {
                draft[id] = true
            })

            if (autoStop) {
                timersRefs.current[id] = window.setTimeout(() => {
                    setLoadings(draft => {
                        draft[id] = false
                    })
                    clearInterval(timersRefs.current[id])
                }, 800)
            }
        },
        [setLoadings]
    )

    const stopLoading = useCallback(
        (id: string) => {
            setLoadings(draft => {
                draft[id] = false
            })
        },
        [setLoadings]
    )

    useEffect(() => {
        const timers = timersRefs.current
        return () => {
            Object.values(timers).forEach(timer => {
                clearInterval(timer)
            })
        }
    }, [])

    return {
        loadings,
        startLoading,
        stopLoading
    }
}
