import { StripePayStatus } from '@lighthouse/core'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { stripePayEmitter, wechatPayEmitter } from '@/actionsEngine/event'

export const useActionPay = function () {
    const [searchParams] = useSearchParams()
    const weChatStatus = searchParams.get('weChatStatus')
    const stripeStatus = searchParams.get('stripeStatus')
    const outTradeNo = searchParams.get('outTradeNo')

    useEffect(() => {
        if (outTradeNo ) {
            weChatStatus &&  wechatPayEmitter.emit(`wechatPayCheck-${outTradeNo}`, weChatStatus)
            stripeStatus &&  stripePayEmitter.emit(`stripePayCheck-${outTradeNo}`, stripeStatus)
        }
    }, [outTradeNo, stripeStatus, weChatStatus])
}
