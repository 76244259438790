'use client'
import * as React from 'react'

// eslint-disable-next-line no-negated-condition
const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useEventCallback<Fn extends (...args: any[]) => any = (...args: unknown[]) => unknown>(fn: Fn): Fn
function useEventCallback<Args extends unknown[], Return>(fn: (...args: Args) => Return): (...args: Args) => Return
function useEventCallback<Args extends unknown[], Return>(fn: (...args: Args) => Return): (...args: Args) => Return {
    const ref = React.useRef(fn)
    useEnhancedEffect(() => {
        ref.current = fn
    })
    return React.useRef((...args: Args) => ref.current(...args)).current
}

export default useEventCallback
