import type { DataSourceAbstract, Field, ViewFieldProtocol } from '@lighthouse/core'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import type { DraftFunction } from 'use-immer'
import { useImmer } from 'use-immer'

import type { InsertDirection, TableFieldState } from '../../Table'
import { TableFieldSetting } from '../../Table/TableFieldSetting'

type MoreSettingState = TableFieldState & {
    open: boolean
}

const Dot = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
`

interface MoreSettingProps {
    columns: ViewFieldProtocol[]
    dataSource: DataSourceAbstract
    fieldId: string
    configurable?: boolean
    absolute?: boolean
    dataSourceList?: DataSourceAbstract[]
    disableHidden?: boolean
    onUpdateField?: (fieldId: string, data: Field) => Promise<boolean>
    onCreateField?: (data: Field, sourceId: string, direction: InsertDirection) => Promise<boolean>
    onDeleteField?: (data: Field) => Promise<boolean>
}

export const MoreSetting: React.FC<MoreSettingProps> = ({
    columns,
    dataSource,
    fieldId: currentFieldId,
    configurable = true,
    absolute,
    dataSourceList = [],
    disableHidden,
    onUpdateField,
    onCreateField,
    onDeleteField
}) => {
    const [state, setState] = useImmer<MoreSettingState>({
        mode: 'config',
        action: 'update',
        fieldId: currentFieldId,
        sourceId: '',
        direction: '',
        open: false
    })

    const { fieldId, sourceId, direction } = state

    const handleStateChange = useCallback(
        (state: TableFieldState | DraftFunction<TableFieldState>) => {
            setState(state)
        },
        [setState]
    )

    const handleUpdateField = useCallback(
        (data: Field) => {
            if (onUpdateField) {
                return onUpdateField(fieldId, data)
            }
            return Promise.resolve(false)
        },
        [fieldId, onUpdateField]
    )

    const handleCreateField = useCallback(
        (data: Field) => {
            onCreateField?.(data, sourceId, direction)
        },
        [direction, onCreateField, sourceId]
    )

    return (
        <Dot>
            <TableFieldSetting
                dataSource={dataSource}
                columns={columns}
                currentFieldId={currentFieldId}
                config={state}
                configurable={configurable}
                absolute={absolute}
                dataSourceList={dataSourceList}
                disableHidden={disableHidden}
                onStateChange={handleStateChange}
                onCreateField={handleCreateField}
                onUpdateField={handleUpdateField}
                onDeleteField={onDeleteField}
            />
        </Dot>
    )
}
