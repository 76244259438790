import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

interface BreadcrumbsStylesParams {}

export const useStyles = createStyles((params: BreadcrumbsStylesParams) => ({
    root: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 16px',
        minHeight: 48,
        fontSize: 14
    }),
    body: css({
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        color: 'var(--color-gray-500)'
    }),
    back: css({
        display: 'inline-flex',
        alignItems: 'center',
        gap: 4,
        cursor: 'pointer',
        color: 'var(--color-gray-900)'
    }),
    separator: css({
        fontSize: 12,
        margin: '0 4px'
    }),
    item: css({
        display: 'inline-flex',
        alignItems: 'center',
        overflow: 'hidden',
        gap: 4,
        '&:last-of-type': {
            color: 'var(--color-gray-900)',
            fontWeight: 'bold'
        }
    }),
    label: css({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    })
}))
