import { Empty, IconFont } from '@byecode/ui'
import JsBarcode from 'jsbarcode'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { barCodeReg } from '../../constants'

interface QRCodeBoxProps {
    code: string
    height?: number
    barWidth?: number
}

const SCxContainer = styled.div<{ height?: number }>`
    width: 100%;
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    overflow: hidden;
`

const SCxEmpty = styled(Empty)`
    height: 100%;
`

export const BarcodeBox: React.FC<QRCodeBoxProps> = ({ code, barWidth, height }) => {
    const barcodeRef = useRef<HTMLCanvasElement>(null)

    const isValidate = barCodeReg.test(code)

    useEffect(() => {
        if (!isValidate) {
            return
        }

        JsBarcode(barcodeRef.current, code, {
            height: 60,
            displayValue: false,
            width: barWidth
        })
    }, [barWidth, height, code, isValidate])

    return (
        <SCxContainer height={height}>
            {isValidate ? (
                <canvas ref={barcodeRef} />
            ) : (
                <SCxEmpty icon={<IconFont type="Barcode" fill="var(--color-gray-400)" size={20} />} description="内容格式错误" />
            )}
        </SCxContainer>
    )
}
