import type { OpenRecordPageActionConfig } from '@lighthouse/shared'
import { type ActionFlowNode, OpenDetailPageSelectedRecordType, resolveFilter } from '@lighthouse/shared'
import { wait } from '@lighthouse/tools'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleOpenRecordPage = async (node: ActionFlowNode<OpenRecordPageActionConfig>, extraParams: AnyObject) => {
    const {
        data: { config }
    } = node
    const { actionEvents, clickTriggerNodeParams, getCurrentPageDeps } = extraParams
    const { record, currentAppId, currentEnvId } = clickTriggerNodeParams
    const pageId = getCurrentPageDeps()?.pageId ?? ''

    const { recordPageId, dataSourceId, filter, pageOpenType, selectedRecordType, upstreamRecordNodeId } =
        config as OpenRecordPageActionConfig

    const { openPage, refreshPage } = actionEvents

    if (!recordPageId || !actionEvents) {
        return extraParams
    }

    let resolvedRecord

    switch (selectedRecordType) {
        case OpenDetailPageSelectedRecordType.CURRENT_RECORD: {
            resolvedRecord = record
            break
        }

        case OpenDetailPageSelectedRecordType.MATCHED_RECORD: {
            // if (filter?.expression?.conditions?.length) {
            const resolvedFilter = resolveFilter({ filter, extraParams, shouldUseEmptyPlaceholder: true })

            // if (resolvedFilter?.expression?.conditions?.length) {
            const data = await srv.getDs({
                appId: record?.appId || currentAppId,
                envId: record?.envId || currentEnvId,
                dsId: dataSourceId ?? '',
                pagination: {
                    currentPage: 1,
                    pageSize: 1
                },
                filter: resolvedFilter,
                pageId
            })
            if (data) {
                const { records } = data
                const resRecord = records[0]
                resolvedRecord = resRecord
            }
            // }
            // }
            break
        }
        case OpenDetailPageSelectedRecordType.UPSTREAM_RECORD: {
            if (upstreamRecordNodeId) {
                const upstreamRecord = extraParams[upstreamRecordNodeId]?.record
                if (upstreamRecord) {
                    resolvedRecord = upstreamRecord
                }
            }
            break
        }
        default: {
            break
        }
    }

    openPage({
        pageId: recordPageId,
        openType: pageOpenType,
        appId: resolvedRecord?.appId,
        dsId: resolvedRecord?.dsId,
        recordId: resolvedRecord?.id,
        onSaveCallback: () => {
            refreshPage()
        }
    })

    await wait(8)

    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        success: true
    })
}
