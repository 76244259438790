import type { FormContainerBlockAbstract } from '@lighthouse/core'
import React, { forwardRef } from 'react'

interface FormContainerBlockProps {
    blockData: FormContainerBlockAbstract
    children?: React.ReactNode
}
const FormContainerBlock = forwardRef<HTMLDivElement, FormContainerBlockProps>(({ blockData, children }, ref) => {
    return <>{children}</>
})

export default FormContainerBlock
