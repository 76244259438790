import { at } from '@lighthouse/tools'
import { proxy, useSnapshot } from 'valtio'

type State = {
    viewId: string
    recordId: string
}[]

class ActiveState {
    #state: State = proxy([])

    get state() {
        return this.#state
    }

    public useActiveRecord() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useSnapshot(this.#state)
    }

    public getActiveRecordId() {
        return at(-1, this.#state)?.recordId
    }

    public getActiveViewId() {
        return at(-1, this.#state)?.viewId
    }

    public pushActiveViewRecord(viewId: string, recordId: string) {
        this.#state.push({
            viewId,
            recordId
        })
    }

    public clearActiveRecord() {
        return this.#state.pop()
    }
}

export default new ActiveState()
