import type { RelativeSelectConfig } from '@lighthouse/core'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useApplicationContext } from '../../../../../contexts'
import type { FieldBaseProps } from '../../../types'
import { RelationCheckboxPreview } from '../RelationCheckboxPreview'
import { RelationRecordPreview } from '../RelationRecordPreview'
import { RelationTagPreview } from '../RelationTagPreview'
import { useRelativeDataController } from '../RelativeDataController'
import { RelativeSelectDrawer } from './RelativeSelectDrawer'

interface RelativeSelectDrawerFieldProps extends FieldBaseProps {
    value?: string
    relativeSelectConfig: RelativeSelectConfig
}
const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: inherit;
`

export const RelativeSelectDrawerField: React.FunctionComponent<RelativeSelectDrawerFieldProps> = props => {
    const {
        onCellChange,
        value,
        readOnly,
        isControlled,
        config,
        relativeDataSource,
        relativeSelectConfig,
        dataSourceList,
        onFetchDataSource,
        onLoadMoreData,
        onOpenPage
    } = props
    const { relativeSelect, placeholder, title = '' } = relativeSelectConfig
    const {
        relativePointer = '',
        relativeShowFieldPointer = '',
        relativeFieldPointer = '',
        canMultipleChoice,

        direction,
        showMode
    } = relativeSelect ?? {}

    const { pageTarget } = useApplicationContext()

    const [opened, setOpened] = useState(false)

    const {
        relativeDataSource: newRelativeDataSource,
        recordValue,
        userRecord,
        records,
        options,
        onRecordChange,
        onChange
    } = useRelativeDataController({ onCellChange, value, isControlled, config, relativeDataSource, relativeSelectConfig, dataSourceList })

    const handleRemove = useCallback(
        (recordId: string) => {
            return onRecordChange?.(recordValue.filter(item => item !== recordId))
        },
        [onRecordChange, recordValue]
    )

    return useMemo(() => {
        switch (showMode) {
            case 'checkbox': {
                return (
                    <SCxContainer>
                        <RelationCheckboxPreview
                            options={options}
                            isMultiple={canMultipleChoice}
                            direction={direction}
                            value={recordValue}
                            onChange={onChange}
                        />
                    </SCxContainer>
                )
            }
            case 'tag': {
                return (
                    <SCxContainer>
                        <RelationTagPreview
                            options={options}
                            isMultiple={canMultipleChoice}
                            direction={direction}
                            value={recordValue}
                            onChange={onChange}
                        />
                    </SCxContainer>
                )
            }
            default: {
                return (
                    <SCxContainer data-field-border={opened} onKeyDown={ev => ev.stopPropagation()}>
                        <RelationRecordPreview
                            records={records}
                            onClick={() => !readOnly && relativeFieldPointer && relativeShowFieldPointer && setOpened(true)}
                            fieldPointer={relativeShowFieldPointer}
                            dsId={relativePointer}
                            dataSourceList={dataSourceList || []}
                            userRecord={userRecord}
                            placeholder={placeholder}
                            readOnly={readOnly}
                            value={recordValue}
                            onRemove={handleRemove}
                        />
                        <RelativeSelectDrawer
                            value={recordValue}
                            title={title}
                            opened={opened}
                            dsId={relativePointer}
                            target={pageTarget}
                            dataSource={newRelativeDataSource}
                            isMultiple={canMultipleChoice}
                            relativeSelectConfig={relativeSelectConfig}
                            dataSourceList={dataSourceList}
                            onClose={() => setOpened(false)}
                            onChange={onRecordChange}
                            onFetchDataSource={onFetchDataSource}
                            onLoadMoreData={onLoadMoreData}
                            onOpenPage={onOpenPage}
                        />
                    </SCxContainer>
                )
            }
        }
    }, [
        canMultipleChoice,
        dataSourceList,
        direction,
        handleRemove,
        newRelativeDataSource,
        onChange,
        onFetchDataSource,
        onLoadMoreData,
        onOpenPage,
        onRecordChange,
        opened,
        options,
        pageTarget,
        placeholder,
        readOnly,
        recordValue,
        records,
        relativeFieldPointer,
        relativePointer,
        relativeSelectConfig,
        relativeShowFieldPointer,
        showMode,
        title,
        userRecord
    ])
}
