import { IconFont } from '@byecode/ui'
import type { Field } from '@lighthouse/core'
import { usePopupState } from 'material-ui-popup-state/hooks'
import React, { useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'

export interface TableFieldProps {
    field: Field
    width?: number
    isPrimaryField: boolean
    configurable?: boolean
}

const SCxHeaderFieldItemWrapper = styled.div<{ configurable?: boolean; width?: number }>`
    flex-shrink: 0;
    position: relative;
    width: ${({ width }) => (width ? `${width}px` : '160px')};
    height: 36px;
    padding-left: 8px;
`

const SCxHeaderFieldItem = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
`

const SCxHeaderLabel = styled.p`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size-normal);
`

const SCxTitleFieldFlagWrapper = styled.div`
    display: flex;
    position: absolute;
    top: -2px;
    right: 6px;
    font-size: 18px;
    pointer-events: none;
`

export const TableField: React.FC<TableFieldProps> = ({ field, width, isPrimaryField, configurable }) => {
    const headerFieldWrapperRef = useRef<HTMLDivElement>(null)

    const popoverState = usePopupState({
        variant: 'popover',
        popupId: 'TableFieldEditorPopover'
    })
    const { id, name, type } = field

    const openPopover = useCallback(() => {
        popoverState.open(headerFieldWrapperRef.current)
    }, [popoverState])

    const handleEditPopoverOpen = useCallback(() => {
        openPopover()
    }, [openPopover])

    return (
        <SCxHeaderFieldItemWrapper ref={headerFieldWrapperRef} configurable={configurable} width={width}>
            <SCxHeaderFieldItem onDoubleClick={handleEditPopoverOpen}>
                <SCxHeaderLabel>{name}</SCxHeaderLabel>
            </SCxHeaderFieldItem>
        </SCxHeaderFieldItemWrapper>
    )
}
