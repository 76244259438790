import styled, { css } from 'styled-components'

import type { InputProps } from './types'

export const InputWrapper = styled.div<Pick<InputProps, 'bordered' | 'highlightable' | 'focusOutLineColor' | 'enableBackgroundColor'>>`
    display: flex;
    border: 1px solid transparent;
    ${props =>
        props.bordered &&
        css`
            border: 1px solid var(--color-gray-200);
            border-radius: 8px;
        `};

    ${({ highlightable = true, enableBackgroundColor }) => {
        return (
            highlightable &&
            enableBackgroundColor &&
            css`
                background-color: var(--color-gray-50);
            `
        )
    }}

    &:focus-within {
        ${({ highlightable = true, focusOutLineColor, bordered, enableBackgroundColor }) => {
            const borderColor = focusOutLineColor ?? (bordered ? 'var(--color-gray-200)' : 'transparent')
            return (
                highlightable &&
                enableBackgroundColor &&
                css`
                    background-color: #fff;
                    border: 1px solid ${borderColor};
                    border-radius: 6px;
                `
            )
        }}
    }

    transition: background 0.2s ease;
`

export const InputContent = styled.input<
    InputProps & { inputSize: InputProps['size']; textSize: InputProps['textSize']; textAlign: InputProps['textAlign'] }
>`
    width: 100%;
    border: none;
    outline: none;
    height: ${({ inputSize }) => (inputSize === 'small' ? '18px' : '24px')};
    background-color: transparent;
    margin: ${({ bordered }) => (bordered ? '2px 8px' : '6px 14px')};
    font-size: ${({ textSize }) => textSize};
    text-align: ${({ textAlign }) => textAlign};
    color: var(--color-black);
    border: 1px solid transparent;

    ::placeholder {
        font-weight: var(--font-weight-normal);
        color: var(--color-gray-300);
    }
`
