import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React from 'react'

import * as SC from '../styles'

interface SortableItemProps {
    children?: React.ReactNode
    id: string
    wrapperStyle?: React.CSSProperties
    onMouseDown?: (ev: React.MouseEvent<HTMLDivElement>) => void
}

export const SortableItem: React.FC<SortableItemProps> = ({ id, children, wrapperStyle, onMouseDown }) => {
    const { setNodeRef, attributes, transform, transition, isSorting, listeners, isDragging } = useSortable({
        id
    })

    const sortingStyle: React.CSSProperties = {
        visibility: isDragging ? 'hidden' : 'visible',
        transform: CSS.Transform.toString(transform),
        transition: isSorting ? transition : 'none'
    }

    return (
        <SC.OptionItem key={id} style={{ ...wrapperStyle, ...sortingStyle }} ref={setNodeRef} {...attributes}>
            <SC.MoveIcon onMouseDown={onMouseDown} type="Move" {...listeners} fill="var(--color-gray-400)" />
            {children}
        </SC.OptionItem>
    )
}
