export enum WechatPayType {
    'JSAPI' = 'JSAPI',
    'H5' = 'H5',
    'NATIVE' = 'NATIVE'
}

export enum WechatPayStatus {
    'SUCCESS' = 'SUCCESS',
    'REFUND' = 'REFUND',
    'NOTPAY' = 'NOTPAY',
    'CLOSED' = 'CLOSED',
    'REVOKED' = 'REVOKED',
    'USERPAYING' = 'USERPAYING',
    'PAYERROR' = 'PAYERROR'
}


export enum StripePayStatus {
    'SUCCESS' = 'SUCCESS',
    'REFUND' = 'REFUND',
    'NOTPAY' = 'NOTPAY',
    'CLOSED' = 'CLOSED',
    'REVOKED' = 'REVOKED',
    'USERPAYING' = 'USERPAYING',
    'PAYERROR' = 'PAYERROR'
}

export type WechatPayOrderInfoProtocol = {
    outTradeNo: string
    originalPrice: number
    realPrice: number
    discount: number
    prodDesc: string
    jsApiResponse: {
        // 仅在jsapi支付的时候返回
        appId: string
        mchId: string
        description: string
        notifyUrl: string
        packageVal: string
        openId: string
        amount: number
        timeStamp: number
        nonceStr: string
        signType: string
        paySign: string
    }
    h5Response: {
        // 仅在h5支付的时候返回
        codeUrl: string
    }
    nativeResponse: {
        // 仅在native支付的时候返回
        codeUrl: string
    }
}
