import { useResizeObserver } from '@mantine/hooks'
import { useUpdateEffect } from 'react-use'

import { useTooltipListContext } from './TooltipListContext'

export const useWidth = function <T extends HTMLElement>(id: string | number) {
    const { onWidthChange } = useTooltipListContext()
    const [ref, { width }] = useResizeObserver<T>()

    useUpdateEffect(() => {
        onWidthChange?.(width, id)
    }, [width])

    return { ref }
}
