import { Button, Empty, Flex, IconFont, Input, MobileModal, pointer, singleTextEllipsis, Text, tinyButtons } from '@byecode/ui'
import type { AppUser, FilterOption } from '@lighthouse/core'
import type { DrawerProps } from '@mantine/core'
import { clone, findIndex } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

interface TextDrawerProps {
    opened: boolean
    isMultiple?: boolean
    leftIcon?: string
    options: FilterOption[]
    value: string[]
    title: string
    target: DrawerProps['target']
    onChange?: (val: string[]) => void
    onFinish?: (val: string[]) => void
    onClose: () => void
}
const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 10px;
`

const SCxTagList = styled.div`
    overflow-y: auto;
    ${tinyButtons}
    width: 100%;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
`

const SCxTagWrapper = styled.div`
    height: auto;
    overflow: hidden;
`

const SCxTagItem = styled.div<{ isShowBorder?: boolean }>`
    width: 100%;
    display: flex;
    /* height: 300px; */
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 16px;
    color: var(--color-black);
    border-bottom: 1px solid var(--color-gray-200);
    background: var(--color-white);

    &:last-child {
        border: none;
    }
`

const SCxCursor = styled.div``
export const TextDrawer: React.FC<TextDrawerProps> = ({
    opened,
    leftIcon,
    isMultiple = false,
    options,
    title,
    value,
    target,
    onClose,
    // onChange,
    onFinish
}) => {
    const [keywords, setKeywords] = useState('')
    const [selectValue, setSelectValue] = useState(value)

    useUpdateEffect(() => {
        if (value !== selectValue) {
            setSelectValue(value)
        }
    }, [value])

    const handleFinish = useCallback(() => {
        onFinish?.(selectValue)
        onClose()
    }, [onFinish, onClose, selectValue])

    const handleReset = useCallback(() => {
        onFinish?.([])
        setSelectValue([])
        onClose()
    }, [onClose, onFinish])

    const handleSelectChange = useCallback(
        (val: string) => {
            const isActive = selectValue.includes(val)
            if (isMultiple) {
                const newValue = isActive ? selectValue.filter(v => v !== val) : [...selectValue, val]
                setSelectValue(newValue)
                return
            }
            const newValue = isActive ? [] : [val]
            setSelectValue(newValue)
        },
        [isMultiple, selectValue]
    )

    const handleSearchValueChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const word = ev.target.value.trim()
        setKeywords(word)
    }, [])

    return (
        <MobileModal
            // leftSlot={!!leftIcon && <IconFont type={leftIcon} />}
            withCloseIcon={!isMultiple}
            data-ignore-click-away
            target={target}
            title={title}
            open={opened}
            styles={{
                modal: {
                    height: options.length > 0 ? '50%' : 'auto!important',
                    backgroundColor: 'var(--color-gray-50)'
                }
            }}
            onClose={onClose}
        >
            <SCxContainer>
                <SCxCursor>
                    <Input
                        value={keywords}
                        placeholder="搜索"
                        onChange={handleSearchValueChange}
                        prefix={<IconFont type="SearchLine" color="var(--color-gray-400)" size={16} />}
                        suffix={
                            keywords && (
                                <IconFont
                                    type="CloseCircle"
                                    color="var(--color-gray-400)"
                                    size={16}
                                    onClick={() => {
                                        setKeywords('')
                                    }}
                                />
                            )
                        }
                    />
                </SCxCursor>
                {options.some(option => option.label.includes(keywords)) ? (
                    <Flex
                        direction="column"
                        style={{
                            flex: 1,
                            overflow: 'hidden'
                        }}
                    >
                        <Flex style={{ flex: 1, overflow: 'hidden' }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch'
                                }}
                            >
                                <SCxTagList>
                                    <SCxTagWrapper>
                                        {options
                                            .filter(option => option.label?.includes(keywords))
                                            .map(item => (
                                                <SCxTagItem key={item.value} onClick={() => handleSelectChange(item.value)}>
                                                    <Text>{item.label}</Text>
                                                    {selectValue.includes(item.value) && (
                                                        <IconFont size={16} color="var(--color-app-main)" type="Tick" />
                                                    )}
                                                </SCxTagItem>
                                            ))}
                                    </SCxTagWrapper>
                                </SCxTagList>
                            </div>
                        </Flex>
                        <Flex gap={12} style={{ padding: '10px 0' }}>
                            <Button size="lg" block onClick={handleReset}>
                                清除
                            </Button>
                            <Button
                                size="lg"
                                block
                                type="primary"
                                style={{ backgroundColor: 'var(--color-app-main)' }}
                                onClick={handleFinish}
                            >
                                确定
                            </Button>
                        </Flex>
                    </Flex>
                ) : (
                    <Empty
                        styles={{
                            root: {
                                padding: '42px'
                            }
                        }}
                        icon="SearchLine"
                        description="无数据"
                    />
                )}
            </SCxContainer>
        </MobileModal>
    )
}
