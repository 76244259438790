import { IconFont } from '@byecode/ui'
import type { Node as ProseMirrorNode } from '@tiptap/pm/model'
import { NodeViewWrapper } from '@tiptap/react'
import React from 'react'
import styled from 'styled-components'

const SCxNode = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`

const Icon = styled(IconFont)`
    font-size: 14px;
    margin-right: 2px;
`

interface DataQuoteComponentProps {
    children?: React.ReactNode
    node: ProseMirrorNode
}

interface DataQuoteDatasourceComponentProps {
    children?: React.ReactNode
    node: ProseMirrorNode
}

export const DataQuoteFieldComponent: React.FC<DataQuoteComponentProps> = ({ children, node }) => {
    const { icon, extra, name } = node.attrs
    return (
        <NodeViewWrapper as="span" className="field">
            <SCxNode>
                {icon && <Icon fill="var(--color-teal)" type={icon} />}
                {name}
                {extra && <span>{`{${extra}}`}</span>}
            </SCxNode>
        </NodeViewWrapper>
    )
}

export const DataQuoteDatasourceComponent: React.FC<DataQuoteDatasourceComponentProps> = ({ children, node }) => {
    const iconName = 'DataSource'
    return (
        <NodeViewWrapper as="span" className="dataSource">
            <SCxNode>
                <Icon fill="var(--color-teal)" type={iconName} />
                {node.attrs.name}
            </SCxNode>
        </NodeViewWrapper>
    )
}
