import type { Direction } from '@lighthouse/core'
import { DIRECTION } from '@lighthouse/core'
import React from 'react'

import type { Coordinates } from '../Sortable/types'
import type { FlowLayoutContainerNode, FlowLayoutCustomNode, FlowLayoutNode } from '../types'

export const noop = () => void 0

/**
 * 根据id和tree查找node节点
 * @date 2024/1/8 - 12:07:55
 *
 * @export
 * @param {string} id
 * @returns {(tree: FlowLayoutNode[]) => FlowLayoutNode | undefined}
 */
export function findNodeById(id: string) {
    const recursion = (tree: FlowLayoutNode[]): undefined | FlowLayoutNode => {
        for (const node of tree) {
            if (node.id === id) {
                return node
            }

            if ((node.type === 'container' || node.type === 'custom') && node.children) {
                const res = recursion(node.children)
                if (res) {
                    return res
                }
            }
        }
    }

    return recursion
}

/**
 * 根据id和tree查找父级节点
 * @date 2024/3/22 - 14:56:09
 *
 * @export
 * @param {string} id
 * @returns {(tree: FlowLayoutNode[]) => FlowLayoutContainerNode | undefined}
 */
export function findNodeParentById(id: string) {
    let parent: FlowLayoutContainerNode | FlowLayoutCustomNode | undefined
    const recursion = (tree: FlowLayoutNode[]) => {
        for (const node of tree) {
            if (node.id === id) {
                return true
            }

            if ((node.type === 'container' || node.type === 'custom') && node.children) {
                const res = recursion(node.children)
                if (res) {
                    parent = node
                    break
                }
            }
        }
    }

    return (tree: FlowLayoutNode[]) => {
        recursion(tree)

        return parent
    }
}

export const isRefWithReactElement = (
    children: React.ReactNode
): children is React.ReactElement<React.ForwardRefExoticComponent<React.PropsWithoutRef<unknown>> & React.RefAttributes<HTMLDivElement>> &
    Required<React.RefAttributes<HTMLDivElement>> => {
    return React.isValidElement(children) && Object.hasOwn(children, 'ref')
}

/**
 * 检测鼠标在block上的区域
 * 水平方向时，检测是否在block左侧一半
 * 垂直方向时，检测是否在block上方一半
 * @date 2024/1/16 - 15:10:31
 *
 * @export
 * @param {Direction|undefined} direction
 * @param {Coordinates} coordinates
 * @param {DOMRect} rect
 * @returns {boolean}
 */
export function detectOrderForPointerOnBlock(direction: Direction | undefined, coordinates: Coordinates, rect: DOMRect) {
    return direction === DIRECTION.horizontal
        ? coordinates.x > rect.left &&
              coordinates.x < rect.left + rect.width * 0.5 &&
              coordinates.y > rect.top &&
              coordinates.y < rect.top + rect.height
        : coordinates.x >= rect.left &&
              coordinates.x < rect.left + rect.width &&
              coordinates.y > rect.top &&
              coordinates.y < rect.top + rect.height * 0.5
}
