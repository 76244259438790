import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import type { ByecodeSize } from '@byecode/ui/types'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { Box } from '../Box'
import { Checkbox } from './Checkbox'

const useStyles = createStyles(({ direction }: { direction?: 'vertical' | 'horizontal' }) => ({
    group: css({
        display: 'flex',
        flexDirection: direction === 'horizontal' ? 'row' : 'column'
    }),
    item: css({
        padding: '6px 0'
    })
}))

export type CheckBoxGroupStyleNames = Selectors<typeof useStyles>

export interface CheckBoxGroupProps
    extends StyleComponentProps<CheckBoxGroupStyleNames>,
        Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
    direction?: 'vertical' | 'horizontal'
    options: { label: React.ReactNode; value: string; disabled?: boolean }[]
    size?: ByecodeSize
    defaultValue?: string[]
    value?: string[]
    onChange?: (v: string[]) => void
}

const EMPTY: string[] = []

export const CheckBoxGroup = forwardRef<HTMLDivElement, CheckBoxGroupProps>((props, ref) => {
    const {
        className,
        classNames,
        styles,
        unstyled,

        direction = 'vertical',
        options,
        size,
        defaultValue = EMPTY,
        value,
        onChange,
        ...rest
    } = props
    const { classes } = useStyles({ direction }, { name: 'checkbox-group', classNames, styles, unstyled })

    const [_value, _onChange] = useUncontrolled({ defaultValue, value, onChange })

    return (
        <Box ref={ref} className={clsx(classes.group, className)} {...rest}>
            {options.map(item => (
                <Checkbox
                    key={item.value}
                    className={classes.item}
                    size={size}
                    label={item.label}
                    disabled={item.disabled}
                    checked={_value.includes(item.value)}
                    onChange={e => {
                        const { checked } = e.target
                        _onChange(checked ? [..._value, item.value] : _value.filter(v => v !== item.value))
                    }}
                />
            ))}
        </Box>
    )
})
