import { NoFound } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

const SCxContainer = styled.div`
    height: 100%;
`

export default function NotFoundTitle() {
    return (
        <SCxContainer>
            <NoFound />
        </SCxContainer>
    )
}
