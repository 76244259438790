import { singleTextEllipsis } from '@byecode/ui'
import styled from 'styled-components'

export const ListItem = styled.li.withConfig<{ progress: number }>({ shouldForwardProp: p => p !== 'progress' })`
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    padding: 8px;
    border-radius: 6px;
    background-color: var(--color-gray-50);
    /* ${({ progress }) =>
        progress > 0 &&
        `
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: ${progress}%;
            height: 2px;
            background-color: var(--color-green-500);
        }
    `} */
`

export const ListItemIcon = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    margin-right: 8px;
    border-radius: 3px;
    background-color: var(--color-white);
`

export const ListItemActions = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

export const ListItemContent = styled.div`
    flex: 1;
`

export const FileName = styled.div`
    max-width: 180px;
    ${singleTextEllipsis()}
    color: var(--color-gray-900);
    font-size: 14px;
    line-height: 16px;
`

export const FileSize = styled.div`
    font-size: 12px;
    white-space: nowrap;
    color: var(--color-gray-400);
`

export const FileStatus = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: var(--color-gray-500);
`
