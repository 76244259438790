import type { CascadeOption, CascadeValue } from '@byecode/ui'
import { CascadeList, IconFont, Popover, usePopoverHeight } from '@byecode/ui'
import type { CascadeConfig, Field } from '@lighthouse/core'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncRetry, useToggle, useUpdateEffect } from 'react-use'

import { useApplicationContext } from '../../../../contexts'
import { getDepartmentToTreeOptions } from '../../../../utils'
import { popoverMaxHeight } from '../../constant'
import { SelectPlaceHolder } from '../../SelectPlaceHolder'
import type { FieldBaseProps } from '../../types'
import * as SC from './styles'

interface CascadeFieldProps extends FieldBaseProps {
    writeField?: Field
    value: string
    cascadeConfig: CascadeConfig
}

const CascadeField: React.FunctionComponent<CascadeFieldProps> = props => {
    const {
        writeField,
        onCellChange,
        value: data,
        isControlled,
        config: { placeholder },
        readOnly,
        cascadeConfig,
        onSaveCellChange,
        onFetchCascadeOptions
    } = props
    const { cascade } = cascadeConfig ?? {}
    const {
        cascadeFieldPointer = '',
        cascadePointer = '=',
        showPath,
        isLastLevel,
        filter,
        parentFieldPointer = '',
        sortFieldPointer = '',
        cascadeShowFieldPointer = ''
    } = cascade ?? {}

    const [value, setValue] = useState(data)
    const [path, setPath] = useState('')
    const [opened, setOpened] = useToggle(false)
    const { departmentOptions } = useApplicationContext()
    const { t } = useTranslation()

    const isDepartmentField = useMemo(() => writeField?.type === 'department', [writeField?.type])

    useUpdateEffect(() => {
        setValue(data)
    }, [data])

    const { value: options, retry } = useAsyncRetry(async () => {
        if(isDepartmentField){
            return getDepartmentToTreeOptions(departmentOptions)
        }
        const data = await onFetchCascadeOptions?.({
            fieldPointer: cascadeFieldPointer,
            showFieldPointer: cascadeShowFieldPointer,
            dsId: cascadePointer,
            sortFieldPointer,
            filter,
            parentFieldPointer
        })
        return data ?? []
    }, [cascadeFieldPointer, cascadePointer, sortFieldPointer, filter, parentFieldPointer, cascadeShowFieldPointer, onFetchCascadeOptions])

    const { ref, height: maxHeight } = usePopoverHeight(opened, popoverMaxHeight)

    const flatOptions = useMemo(() => {
        function getChildOption(option: CascadeOption, list: CascadeOption[]) {
            const { value, label, path, labelPath, children = [] } = option
            let arrList = list
            for (const child of children) {
                const newChild = {
                    ...child,
                    path: `${path}/${child.value}`,
                    labelPath: `${labelPath}/${child.label}`,
                    isLast: (child.children ?? []).length === 0
                }
                arrList = [...arrList, newChild, ...getChildOption(newChild, [])]
            }
            return arrList
        }
        return (
            options?.reduce<CascadeOption[]>((pre, cur) => {
                const newOption = { ...cur, path: cur.value, labelPath: cur.label, isLast: (cur.children ?? []).length === 0 }
                return [...pre, newOption, ...getChildOption(newOption, [])]
            }, []) ?? []
        )
    }, [options])

    const tag = useMemo(() => flatOptions.find(option => option.value === value && value), [flatOptions, value])

    const cascadeListValue = useMemo(() => [value], [value])

    const handleChange = useCallback(
        (v: CascadeValue) => {
            const textValue = v.join(',')
            setValue(textValue)
            onCellChange?.({ type: 'cascade', value: textValue })
            setPath('')
        },
        [onCellChange]
    )

    const onPathChange = useCallback(
        (data: CascadeOption) => {
            setPath(showPath ? data.labelPath ?? '' : '');
            (data.children ?? []).length === 0 && setOpened(false)
        },
        [setOpened, showPath]
    )

    const handleClear = useCallback(() => {
        setValue('')
        setPath('')
        onCellChange?.({ type: 'cascade', value: '' })
    }, [onCellChange])

    return (
        <Popover opened={opened} zIndex={200} position="bottom-start" onChange={setOpened} withinPortal width="target" disabled={readOnly}>
            <Popover.Target>
                <SC.Container
                    type="button"
                    data-field-border={opened}
                    onClick={() => {
                        retry()
                        setOpened(true)
                    }}
                >
                    <SC.Wrapper ref={ref}>
                        <SC.TagWrapper>
                            {tag ? showPath ? tag.labelPath : tag.label : <SelectPlaceHolder label={path || placeholder} />}
                        </SC.TagWrapper>
                    </SC.Wrapper>
                    {!readOnly && (
                        <SC.IconWrapper>
                            {tag && <IconFont type="CloseCircle" color="var(--color-gray-400)" size={16} onClick={handleClear} />}
                            <IconFont type="ArrowDownSmall" color="var(--color-gray-400)" size={16} />
                        </SC.IconWrapper>
                    )}
                </SC.Container>
            </Popover.Target>
            <Popover.Dropdown compact>
                <CascadeList
                    data={cascadeListValue}
                    onChange={handleChange}
                    onPathChange={onPathChange}
                    searchable
                    emptyProps={{
                        description: t('noFindData')
                    }}
                    searchPlaceholder={t('search')}
                    lastLevel={isLastLevel}
                    options={options}
                    style={{ maxHeight }}
                    styles={{
                        columns: {
                            minHeight: 200
                        },
                        search:{
                            '&:focus-within':{
                                borderColor: 'var(--color-app-main)'
                            }
                        }
                    }}
                />
            </Popover.Dropdown>
        </Popover>
    )
}

export default CascadeField
