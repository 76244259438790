import type { PropsWithChildren } from 'react'
import React, { createContext, useContext, useMemo } from 'react'

interface SharedConfigContextValue {
    disabledWithVersion?: boolean
}

const SharedConfigContext = createContext<SharedConfigContextValue | null>(null)

interface ShardConfigProviderProps extends PropsWithChildren<SharedConfigContextValue> {}

function useSharedConfig() {
    return useContext(SharedConfigContext)
}

export function useSharedConfigDisabledWithVersion() {
    const ctx = useSharedConfig()

    return ctx?.disabledWithVersion
}

export function SharedConfigProvider({ children, disabledWithVersion }: ShardConfigProviderProps) {
    const ctx = useMemo(() => ({ disabledWithVersion }), [disabledWithVersion])

    return <SharedConfigContext.Provider value={ctx}>{children}</SharedConfigContext.Provider>
}
