import { singleTextEllipsis } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

import { COLORS_MAP, LIGHT_COLORS_MAP } from '../../constants/color'
import { IconWrapper } from './BaseField/commonStyles'

export interface SelectItemValue {
    label: string
    color?: string
}

interface SelectItemPreviewerProps {
    rightSection?: React.ReactNode
    data: SelectItemValue
    colorless?: boolean
    textStyles?: React.CSSProperties
}

const SCxCellValue = styled.span`
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    padding: 2px 8px;
    border-radius: 100px;
    height: 22px;
    line-height: 16px;
    gap: 4px;
    white-space: nowrap;
    border: 1px solid var(--color-gray-200);
    max-width: 100%;

    &:nth-last-of-type(1) {
        margin-right: 0;
    }

    ${IconWrapper} {
        opacity: 0.5;
    }
    :hover {
        ${IconWrapper} {
            opacity: 1;
        }
    }
`
const SCxText = styled.div`
    height: 100%;
    ${singleTextEllipsis}
`

export const SelectItemPreviewer: React.FC<SelectItemPreviewerProps> = ({ data, colorless = false, textStyles, rightSection }) => {
    const { label, color } = data
    const itemBasicStyle: React.CSSProperties = colorless
        ? { border: '1px solid var(--color-gray-200)', backgroundColor: 'var(--color-white)' }
        : {
              backgroundColor: color && `var(${LIGHT_COLORS_MAP[color]})`,
              color: color && `var(${COLORS_MAP[color]})`,
              borderColor: color && 'rgba(0,0,0,0.1)'
          }
    return (
        <SCxCellValue key={label} style={{ ...itemBasicStyle, ...textStyles }}>
            <SCxText>{label} </SCxText>
            {rightSection}
        </SCxCellValue>
    )
}
