import type { NodeInvokeResult } from '@lighthouse/shared'
import { ActionWorker, logMaker, LogSender } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

import { actionHandlers } from './actionHandlers'

export const actionWorker = new ActionWorker(actionHandlers)

const logSender = new LogSender()
actionWorker.use('initialize', data => {
    const initializeData = data as { flowId: string; invokeId: string; runtimeParams: { clickTriggerNodeParams: AnyObject } }
    const pageId = initializeData.runtimeParams.clickTriggerNodeParams.currentPageId

    logSender.collectLog({
        invokeId: initializeData.invokeId,
        logInvoker: async () => {
            // eslint-disable-next-line no-return-await
            return await srv.createWorkflowLog(initializeData.flowId, pageId)
        }
    })

    logSender.checkIsRunning(initializeData.invokeId)
})

actionWorker.use('afterInvokeNode', data => {
    const invokedNodeData = data as NodeInvokeResult
    const pageId = invokedNodeData.runtimeParams.clickTriggerNodeParams.currentPageId

    const nodeLog = logMaker({
        ...invokedNodeData,
        result: invokedNodeData.result
    })

    logSender.collectLog({
        invokeId: invokedNodeData.workflowInstanceId,
        logInvoker: async (extraParams: AnyObject) => {
            // eslint-disable-next-line no-return-await
            return await srv.updateWorkflowLog({
                ...nodeLog,
                ...extraParams,
                pageId
            })
        }
    })

    logSender.checkIsRunning(invokedNodeData.workflowInstanceId)
})

actionWorker.use('afterInvoke', () => {
    console.log('afterInvoke')
})
