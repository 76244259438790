import type { FloatingContext } from '@floating-ui/react'
import { max, min } from 'rambda'
import { startTransition, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useLatest } from 'react-use'
import { throttle } from 'throttle-debounce'

interface PopoverHeightParams {
    context: FloatingContext
    offset?: number
    initMaxHeight?: number
}

export function useContextPopoverHeight(params: PopoverHeightParams): [number | undefined, React.MutableRefObject<HTMLDivElement | null>] {
    const { context, offset = 0, initMaxHeight } = params
    const latest = useLatest(context.placement)
    const internalRef = useRef<HTMLDivElement | null>(null)
    const [maxHeight, setMaxHeight] = useState(initMaxHeight)
    const resizeHandle = useMemo(
        () =>
            throttle(30, () => {
                const el = internalRef.current
                if (!el) {
                    return
                }
                startTransition(() => {
                    const rect = el.getBoundingClientRect()

                    if (latest.current.startsWith('top')) {
                        const max = initMaxHeight ? Math.min(initMaxHeight, rect.bottom - offset) : rect.bottom
                        setMaxHeight(max - 10)
                    } else {
                        const bottomHeight = document.documentElement.clientHeight - rect.top - offset
                        const max = initMaxHeight ? Math.min(initMaxHeight, bottomHeight) : bottomHeight
                        setMaxHeight(max - 10)
                    }
                })
            }),
        [initMaxHeight, latest, offset]
    )

    useLayoutEffect(() => {
        if (context.isPositioned && context.open) {
            resizeHandle()
        }
    }, [context.isPositioned, context.open, resizeHandle])

    useLayoutEffect(() => {
        window.addEventListener('resize', resizeHandle)

        return () => window.removeEventListener('resize', resizeHandle)
    }, [latest, resizeHandle])

    return [maxHeight, internalRef]
}

export function usePopoverHeight<T extends HTMLElement = HTMLDivElement>(
    opened: boolean,
    popoverMaxHeight?: number
): {
    ref: React.RefObject<T>
    height: number | undefined
} {
    const containerRef = useRef<T>(null)

    const height = useMemo(() => {
        if (!containerRef || !containerRef.current || !opened) {
            return popoverMaxHeight
        }
        const { height: windowHeight } = document.body.getBoundingClientRect()
        const containRect = containerRef.current.getBoundingClientRect()
        const top = containRect.y
        const bottom = windowHeight - containRect.y - containRect.height
        const maxHeight = max(top, bottom)
        const usedHeight = max(50, maxHeight - 50)
        return popoverMaxHeight ? min(popoverMaxHeight, usedHeight) : usedHeight
    }, [opened, popoverMaxHeight])

    return {
        height,
        ref: containerRef
    }
}
