import { Tooltip, useElementSize } from '@byecode/ui'
import { clamp } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCounter, useMeasure, useUpdateEffect } from 'react-use'
import styled from 'styled-components'

interface SliderProps {
    value?: number
    disabled?: boolean
    min: number
    max: number
    step: number
    color?: string
    style?: React.CSSProperties
    onChange?: (v: number) => void
}

const SCxContainer = styled.div<{ bgColor?: string }>`
    position: relative;
    width: 100%;
    height: 5px;
    background-color: var(--color-gray-200);
    border-radius: 10px;
    --slider-track-bg: ${({ color }) => color ?? 'var(--color-main)'};
    &[aria-disabled='true'] {
        background-color: var(--color-gray-100);
    }
`

const SCxSliderTrack = styled.div<{ sliderWidth: number }>`
    position: absolute;
    inset: 0;
    height: 5px;
    width: ${({ sliderWidth }) => sliderWidth}px;
    max-width: calc(100% - 6px);
    min-width: 6px;
    background-color: var(--slider-track-bg);
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    z-index: 1;

    &[aria-disabled='true'] {
        background-color: var(--color-gray-200);
    }
`

const SCxSliderMark = styled.div`
    position: absolute;
    right: -6px;
    top: -3px;
    bottom: 0;
    z-index: 2;
    width: 12px;
    height: 12px;
    border: 3px solid var(--slider-track-bg);
    background-color: var(--color-white);
    border-radius: 100%;
    cursor: pointer;
    &[aria-disabled='true'] {
        border-color: var(--color-gray-200);
    }
    /* transform: translateX(var(--slider-offset-x)); */
`

function getShare(stepWidth: number, deltaX: number) {
    const isOffset = Math.abs(deltaX % stepWidth) > stepWidth / 2
    const modifyOffset = isOffset ? (deltaX > 0 ? 1 : -1) : 0
    const computeX = Math.floor(Math.abs(deltaX / stepWidth))
    return (deltaX > 0 ? computeX : -computeX) + modifyOffset
}

export const Slider: React.FunctionComponent<SliderProps> = ({ value = 0, min, max, step = 1, color, disabled, style, onChange }) => {
    const { ref: containerRef, width: containerWidth } = useElementSize<HTMLDivElement>()
    const markRef = useRef<HTMLDivElement>(null)
    const initMarkXRef = useRef(0)
    const [data, { set: setData }] = useCounter(value, max, min)
    /** x轴偏移量 */
    const [deltaX, setDeltaX] = useState(0)
    /** 是否拖拽结束  */
    const isDraggingRef = useRef(false)
    /** 总共可以偏移的份数 */
    const totalNum = useMemo(() => Math.floor((max - min) / step), [max, min, step])
    /** 每一份所占宽度  */
    const stepWidth = useMemo(() => Number((containerWidth / totalNum).toFixed(4)), [containerWidth, totalNum])
    /** 当前可以偏移的份数  */
    const currentShare = useMemo(() => Math.floor((data - min) / step), [data, min, step])
    /** 根据step计算出的当前偏移份数 */
    const shareX = useMemo(() => getShare(stepWidth, deltaX), [deltaX, stepWidth])
    /** 当前SliderTracker 所占宽度  */
    const sliderWidth = useMemo(
        () => Math.max(clamp(0, totalNum, currentShare + shareX) * stepWidth, 0),
        [currentShare, shareX, stepWidth, totalNum]
    )
    /** 计算最终值 */
    const finallyValue = useMemo(() => {
        return clamp(min, max, Math.floor((data + shareX * step) / step) * step)
    }, [data, max, min, shareX, step])

    useUpdateEffect(() => {
        setData(value)
    }, [value])

    const handleMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            const deltaX = e.pageX - (containerRef.current?.getBoundingClientRect().x ?? 0)
            const newData = getShare(stepWidth, deltaX) * step
            setData(newData)
            onChange?.(newData)
        },
        [containerRef, onChange, setData, step, stepWidth]
    )

    const handleStart = useCallback(() => {
        isDraggingRef.current = true
        initMarkXRef.current = markRef.current?.getBoundingClientRect().x ?? 0 - 12
    }, [])
    const handleMove = useCallback((e: MouseEvent | TouchEvent) => {
        requestAnimationFrame(() => {
            if (!isDraggingRef.current) {
                return
            }
            const markX = initMarkXRef.current
            const pointerX = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX
            setDeltaX(pointerX - markX)
        })
    }, [])

    const handleEnd = useCallback(() => {
        if (!isDraggingRef.current) {
            return
        }
        setData(finallyValue)
        onChange?.(finallyValue)
        setDeltaX(0)
        isDraggingRef.current = false
    }, [finallyValue, onChange, setData])

    useEffect(() => {
        markRef.current?.addEventListener('mousedown', handleStart)
        document?.addEventListener('mousemove', handleMove)
        document?.addEventListener('mouseup', handleEnd)
        markRef.current?.addEventListener('touchstart', handleStart)
        document?.addEventListener('touchmove', handleMove)
        document?.addEventListener('touchend', handleEnd)

        return () => {
            markRef.current?.removeEventListener('mousedown', handleStart)
            document?.removeEventListener('mousemove', handleMove)
            document?.removeEventListener('mouseup', handleEnd)

            markRef.current?.removeEventListener('touchstart', handleStart)
            document?.removeEventListener('touchmove', handleMove)
            document?.removeEventListener('touchend', handleEnd)
        }
    }, [handleEnd, handleMove, handleStart])
    return (
        <SCxContainer color={color} style={style} aria-disabled={disabled} onMouseDown={handleMouseDown} ref={containerRef}>
            <SCxSliderTrack sliderWidth={sliderWidth} aria-disabled={disabled}>
                <Tooltip title={finallyValue} disabled={isDraggingRef.current}>
                    <SCxSliderMark aria-disabled={disabled} ref={markRef} />
                </Tooltip>
            </SCxSliderTrack>
        </SCxContainer>
    )
}
