import { IconFont } from '@byecode/ui'
import { BoolValue, CheckboxShapeEnum } from '@lighthouse/core'
import { useUncontrolled } from '@lighthouse/tools'
import type { ChangeEvent } from 'react'
import React, { useCallback, useId, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { FieldCheckbox } from '../FieldCheckbox'

const SCxCheckboxIcon = styled(IconFont)``

const SCxCheckboxInner = styled.label<{ checked: boolean; defaultBgcolor: string; disabled?: boolean }>`
    display: flex;
    justify-content: center;
    border-radius: 4px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    ${SCxCheckboxIcon} {
        color: ${({ checked }) => (checked ?   'var(--color-app-main)' : 'var(--color-gray-400)')};
    }
    &:hover {
        &:not([disabled]) {
            ${SCxCheckboxIcon} {
                color:  ${({ checked }) => (checked ?   'var(--color-app-main)' : 'var(--color-gray-600)')};
            }
        }
    }
    &[disabled] {
        opacity: 0.4;
        cursor: not-allowed;
    }
`
const SCxCheckboxWrapper = styled.div`
    position: relative;
    display: fixed;
    align-items: center;
`

const SCxCheckboxInput = styled.input`
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    z-index: -1;
    visibility: hidden;
`

interface FieldInputCheckBoxProps {
    checked?: boolean
    disabled?: boolean
    defaultBgcolor?: string
    shape?: CheckboxShapeEnum
    onChange?: (ev: ChangeEvent<HTMLInputElement>) => void
}

export const checkBoxShape = [
    {
        icon: 'PropertyCheckboxBlock',
        value: CheckboxShapeEnum.checkbox
    },
    {
        icon: 'HeartFill',
        value: CheckboxShapeEnum.heart
    },
    {
        icon: 'StartFill',
        value: CheckboxShapeEnum.collect
    },
    {
        icon: 'FlagFill',
        value: CheckboxShapeEnum.flag
    },
    {
        icon: 'LikeFill',
        value: CheckboxShapeEnum.like
    }
]

export const checkBoxShapeMap: Record<CheckboxShapeEnum, string[]> = {
    collect: ['OutlineStar', 'StartFill'],
    heart: ['OutlineHeart', 'HeartFill'],
    checkbox: ['', 'PropertyCheckboxBlock'],
    flag: ['OutlineFlag', 'OutlineFlag'],
    like: ['OutlineLike', 'LikeFill']
}

export const FieldInputCheckBox: React.FunctionComponent<FieldInputCheckBoxProps> = ({
    checked,
    disabled,
    defaultBgcolor = 'var(--color-white)',
    shape = CheckboxShapeEnum.checkbox,
    onChange
}) => {
    const domId = useId()

    const [_value, handleChange] = useUncontrolled({ value: checked, finalValue: false })

    const handleCheckedChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
            if (disabled) {
                return
            }
            handleChange(ev.target.checked)
            onChange?.(ev)
        },
        [disabled, handleChange, onChange]
    )

    const iconType = useMemo(() => {
        const shapeIconList = checkBoxShapeMap[shape]
        return shapeIconList ? shapeIconList?.[Number(checked)] : 'CheckboxTick'
    }, [checked, shape])

    if (shape === CheckboxShapeEnum.checkbox) {
        return <FieldCheckbox checked={checked} color='var(--color-app-main)' disabled={disabled} onChange={onChange} />
    }

    return (
        <SCxCheckboxWrapper>
            <SCxCheckboxInner disabled={disabled} defaultBgcolor={defaultBgcolor} checked={!!checked} htmlFor={domId}>
                <SCxCheckboxInput disabled={disabled} type="checkbox" id={domId} checked={checked} onChange={handleCheckedChange} />
                <SCxCheckboxIcon size={16} type={iconType} />
            </SCxCheckboxInner>
        </SCxCheckboxWrapper>
    )
}
