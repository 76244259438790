import { type ActionFlowNode, type CreateRecordActionConfig, getCreateRecordVariableValue, pageStackPubSub } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleCreateRecord = async (node: ActionFlowNode<CreateRecordActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return excParams
    }
    const pageId = excParams?.getCurrentPageDeps()?.pageId ?? ''
    const { dataSourceId } = config

    const fieldsValue = getCreateRecordVariableValue(config, { ...excParams, sourceDataSourceId: dataSourceId })
    const { currentAppId, currentEnvId } = excParams.clickTriggerNodeParams
    const record = await srv.createRecordWithFields({
        appId: currentAppId,
        envId: currentEnvId,
        dsId: dataSourceId,
        fields: fieldsValue,
        pageId
    })

    if (!record?.id) {
        throw new Error('创建记录异常')
    }

    // 更新行以后，需要通知对应订阅更新数据
    pageStackPubSub.emit(`${dataSourceId}-ADD`, record)

    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        record
    })
}
