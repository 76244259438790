import { Button, Empty, Flex, IconFont, Input, MobileModal, pointer, singleTextEllipsis, Text, tinyButtons } from '@byecode/ui'
import type { FilterOption, NumberRangeArray } from '@lighthouse/core'
import { AppUser, NumberRangeMode } from '@lighthouse/core'
import type { DrawerProps } from '@mantine/core'
import { clone, findIndex } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { CUSTOM_VALUE } from '../constants'
import { CustomNumberFilter } from '../CustomNumberFilter'
import { CustomNumberSliderFilter } from '../CustomNumberSliderFilter'

interface NumberDrawerProps {
    opened: boolean
    isMultiple?: boolean
    leftIcon?: string
    options?: FilterOption[]
    value?: string[]
    customValue?: NumberRangeArray
    numberRangeMode?: NumberRangeMode
    title: string
    target: DrawerProps['target']
    min?: number
    max?: number
    step?: number
    onFinish: (val: string[], customValue?: NumberRangeArray) => void
    onClose: () => void
}
const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 10px;
`

const SCxTagList = styled.div`
    overflow-y: auto;
    ${tinyButtons}
    width: 100%;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
`

const SCxTagWrapper = styled.div`
    height: auto;
    overflow: hidden;
`

const SCxTagItem = styled.div<{ isShowBorder?: boolean }>`
    width: 100%;
    display: flex;
    /* height: 300px; */
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 16px;
    color: var(--color-black);
    border-bottom: 1px solid var(--color-gray-200);
    background: var(--color-white);

    &:last-child {
        border: none;
    }
`

const SCxCursor = styled.div``

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emptyArr: any[] = []
export const NumberDrawer: React.FC<NumberDrawerProps> = ({
    opened,
    leftIcon,
    isMultiple = false,
    options = emptyArr,
    title,
    value = emptyArr,
    numberRangeMode,
    min = 0,
    max = 1,
    step = 1,
    customValue,
    target,
    onClose,
    onFinish
}) => {
    const [customInputValue, setCustomInputValue] = useState(customValue)
    const [selectValue, setSelectValue] = useState(value)
    const [showCustom, setShowCustom] = useState(Boolean(customValue))

    const showSlide =
        options.length > 0 ? numberRangeMode === NumberRangeMode.slider && showCustom : numberRangeMode === NumberRangeMode.slider

    const showInput =
        options.length > 0 ? numberRangeMode === NumberRangeMode.custom && showCustom : numberRangeMode === NumberRangeMode.custom

    useUpdateEffect(() => {
        if (value !== selectValue) {
            setSelectValue(value)
        }
    }, [value])

    useUpdateEffect(() => {
        if (customValue !== customInputValue) {
            setCustomInputValue(customValue)
        }
    }, [customValue])

    const handleFinish = useCallback(() => {
        onFinish?.(
            selectValue.filter(v => v !== CUSTOM_VALUE),
            customInputValue
        )
        onClose()
    }, [onFinish, selectValue, customInputValue, onClose])

    const handleReset = useCallback(() => {
        onFinish?.([], undefined)
        setSelectValue([])
        setCustomInputValue(undefined)

        onClose()
    }, [onClose, onFinish])

    const handleSelectChange = useCallback(
        (val: string) => {
            const isActive = selectValue.includes(val)
            if (val === CUSTOM_VALUE) {
                setShowCustom(draft => !draft)
                !isMultiple && setSelectValue([])
                return
            }
            if (isMultiple) {
                const newValue = isActive ? selectValue.filter(v => v !== val) : [...selectValue, val]
                setSelectValue(newValue)
                return
            }
            const newValue = isActive ? [] : [val]
            setSelectValue(newValue)
            setShowCustom(false)
            setCustomInputValue(undefined)
        },
        [isMultiple, selectValue]
    )

    return (
        <MobileModal
            leftSlot={!!leftIcon && <IconFont type={leftIcon} />}
            withCloseIcon={!isMultiple}
            data-ignore-click-away
            target={target}
            title={title}
            open={opened}
            styles={{
                modal: {
                    height: options.length > 0 ? '50%' : 'auto!important',
                    backgroundColor: 'var(--color-gray-50)'
                }
            }}
            onClose={onClose}
        >
            <SCxContainer>
                <Flex
                    direction="column"
                    style={{
                        flex: 1,
                        overflow: 'hidden'
                    }}
                >
                    {options.length > 0 && (
                        <Flex style={{ flex: 1, overflow: 'hidden' }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch'
                                }}
                            >
                                <SCxTagList>
                                    <SCxTagWrapper>
                                        {options.map(item => {
                                            const active = selectValue.includes(item.value) || (item.value === CUSTOM_VALUE && showCustom)
                                            return (
                                                <SCxTagItem key={item.value} onClick={() => handleSelectChange(item.value)}>
                                                    <Text>{item.label}</Text>
                                                    {active && <IconFont size={16} color="var(--color-app-main)" type="Tick" />}
                                                </SCxTagItem>
                                            )
                                        })}
                                    </SCxTagWrapper>
                                </SCxTagList>
                            </div>
                        </Flex>
                    )}
                    {showSlide && (
                        <CustomNumberSliderFilter
                            min={min}
                            max={max}
                            step={step}
                            customValue={customInputValue}
                            onCustomChange={setCustomInputValue}
                            style={{
                                backgroundColor: '#fff',
                                padding: 16,
                                height: 64,
                                borderRadius: 8,
                                border: '1px solid var(--color-gray-200)'
                            }}
                        />
                    )}
                    {showInput && (
                        <CustomNumberFilter
                            customValue={customInputValue}
                            onCustomChange={setCustomInputValue}
                            style={{
                                backgroundColor: '#fff',
                                padding: 16,
                                height: 64,
                                borderRadius: 8,
                                border: '1px solid var(--color-gray-200)'
                            }}
                        />
                    )}
                    <Flex gap={12} style={{ padding: '10px 0' }}>
                        <Button size="lg" block onClick={handleReset}>
                            清除
                        </Button>
                        <Button size="lg" block type="primary" style={{ backgroundColor: 'var(--color-app-main)' }} onClick={handleFinish}>
                            确定
                        </Button>
                    </Flex>
                </Flex>
            </SCxContainer>
        </MobileModal>
    )
}
