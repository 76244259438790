import type { BatchItem } from '@rpldy/uploady'
import { FILE_STATES } from '@rpldy/uploady'
import { useEffect, useRef } from 'react'

import { useListenersContext } from '../UploadManage.context'

export const useUploadItemUploading = (id: string, func: (batchItem: BatchItem) => void) => {
    const { itemListener } = useListenersContext()

    const latestFunc = useRef<(data: BatchItem) => void>(func)
    latestFunc.current = func

    useEffect(() => {
        itemListener.add(latestFunc.current, id, FILE_STATES.UPLOADING)

        return () => {
            itemListener.remove(id)
        }
    }, [id, itemListener])
}
