import type { VariableADTvalue } from '@lighthouse/core'
import React from 'react'

import { Entry } from './Base/Entry'
import type { ResizeEventsContextValue } from './Sortable/Context'
import { SortableEntry } from './Sortable/Entry'
import type { ContainerHighlight, FlowLayoutNode, LayoutProperty, NodeRenderProps } from './types'

export interface FlowLayoutProps extends Omit<ResizeEventsContextValue, 'isResizing'> {
    data?: FlowLayoutNode[]
    size?: LayoutProperty['size']
    gap?: LayoutProperty['gap']
    direction?: LayoutProperty['direction']
    padding?: LayoutProperty['padding']
    alignX?: LayoutProperty['alignX']
    alignY?: LayoutProperty['alignY']
    veins?: LayoutProperty['veins']
    background?: LayoutProperty['background']
    style?: React.CSSProperties
    parseBackgroundVariableImage?: (value: VariableADTvalue | undefined) => string

    disabled?: boolean
    scale?: number
    highlight?: Record<string, ContainerHighlight>
    nodeRender?: (props: NodeRenderProps) => JSX.Element | null
    labelRender?: (id: string) => React.ReactNode
    dragOverlay?: React.ReactNode
    onDataDrawerVisible?: (val: boolean) => void
}

export const FlowLayout = React.forwardRef<HTMLDivElement, FlowLayoutProps>((props, ref) => {
    const {
        data,
        size,
        gap,
        direction,
        padding,
        alignX,
        alignY,
        veins,
        background,
        style,
        parseBackgroundVariableImage,

        highlight,
        scale,
        dragOverlay,
        disabled,
        nodeRender,
        labelRender,
        onResizeEnd,
        onResizeStart,
        onResizing,
        onFillSize,
        onDataDrawerVisible
    } = props

    if (!data) {
        return null
    }
    return disabled ? (
        <Entry
            ref={ref}
            data={data}
            size={size}
            gap={gap}
            direction={direction}
            padding={padding}
            alignX={alignX}
            alignY={alignY}
            veins={veins}
            background={background}
            style={style}
            parseBackgroundVariableImage={parseBackgroundVariableImage}
            highlight={highlight}
            disabled={disabled}
            nodeRender={nodeRender}
            labelRender={labelRender}
        />
    ) : (
        <SortableEntry
            ref={ref}
            data={data}
            size={size}
            gap={gap}
            direction={direction}
            padding={padding}
            alignX={alignX}
            alignY={alignY}
            veins={veins}
            background={background}
            style={style}
            parseBackgroundVariableImage={parseBackgroundVariableImage}
            highlight={highlight}
            scale={scale}
            disabled={disabled}
            nodeRender={nodeRender}
            labelRender={labelRender}
            dragOverlay={dragOverlay}
            onResizeStart={onResizeStart}
            onResizing={onResizing}
            onResizeEnd={onResizeEnd}
            onFillSize={onFillSize}
            onDataDrawerVisible={onDataDrawerVisible}
        />
    )
})
