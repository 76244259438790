import type { ButtonBlockHandleEvent, ButtonBlockType, ButtonSizeType } from '@lighthouse/core'
import { ButtonSize } from '@lighthouse/core'

export const btnTypeShowMap = new Map<
    ButtonBlockType,
    {
        icon: boolean
        name: boolean
    }
>([
    [
        'icon',
        {
            icon: true,
            name: false
        }
    ],
    [
        'icon-name',
        {
            icon: true,
            name: true
        }
    ],
    [
        'name',
        {
            icon: false,
            name: true
        }
    ]
])

export const useableConfigMap = new Map<ButtonBlockHandleEvent, number>([
    ['createRecord', 2],
    ['openLink', 1],
    ['openPage', 1],
    ['deleteRecord', 2],
    ['updateRecord', 2]
])

export const buttonSizeMap = new Map<ButtonSizeType, number>([
    [ButtonSize.sm, 32],
    [ButtonSize.md, 36],
    [ButtonSize.lg, 40],
    [ButtonSize.xl, 48],
    [ButtonSize.xxl, 60]
])

export const buttonIconSizeMap = new Map<ButtonSizeType, number>([
    [ButtonSize.sm, 16],
    [ButtonSize.md, 16],
    [ButtonSize.lg, 16],
    [ButtonSize.xl, 20],
    [ButtonSize.xxl, 24]
])

export const buttonTextSizeMap = new Map<ButtonSizeType, number>([
    [ButtonSize.sm, 15],
    [ButtonSize.md, 15],
    [ButtonSize.lg, 15],
    [ButtonSize.xl, 16],
    [ButtonSize.xxl, 18]
])
