import { backgroundTransitionOnClick, IconFont, Input, pointer } from '@byecode/ui'
import styled, { css } from 'styled-components'

import { FieldIcon } from '../../FieldIcon'

export const EditorContainer = styled.div`
    /* padding: 6px 0; */
    overflow: auto;
`

export const EditorHeader = styled.div`
    position: sticky;
    top: 0;
    background: var(--color-white);
    z-index: 1;
`

// export const PropertyTitle = styled(Input)`
//     width: 100%;
//     font-size: var(--font-size-normal);
// `

export const PropertyLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--color-gray-200);
`

export const SearchWrapper = styled.div`
    padding: 12px 12px 8px 12px;
`

export const TypeSearch = styled(Input)`
    font-size: var(--font-size-normal);
    /* margin: 8px 14px 4px 14px; */
    /* border: 1px solid var(--color-gray-200); */
`

export const List = styled.div`
    width: 280px;
    min-height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const ListGroup = styled.div`
    padding-bottom: 6px;
`

export const ListGroupHeader = styled.span`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 12px;
`

export const ListGroupTitle = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`

export const ListGroupDescription = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-600);
`

// export const CheckedIcon = styled(IconFont)`
//     opacity: 0%;
//     color: var(--color-main);
//     transition: opacity 0.2s ease-out;
// `

export const ListItem = styled.div<{ selected?: boolean }>`
    width: 100%;
    display: flex;
    height: 36px;
    padding: 0 12px;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-normal);
    ${pointer}
    ${backgroundTransitionOnClick};
    ${({ selected }) => css`
        background-color: ${selected && 'var(--color-gray-50)'};
    `}
`

export const ListItemInfo = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    gap: 8px;
`

export const DataSourceItem = styled.div<{ selected?: boolean }>`
    display: flex;
    height: 36px;
    padding: 0 14px;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-normal);
    ${pointer}
    ${backgroundTransitionOnClick};
    ${({ selected }) => css`
        background-color: ${selected && 'var(--color-gray-100)'};
        color: var(--color-gray-900);
    `}
`

export const ItemIcon = styled(FieldIcon)``

export const TableIcon = styled(IconFont)``

export const ListItemText = styled.span`
    color: var(--color-black);
`

export const Remark = styled.div`
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 6px;
    border-radius: 4px;
    background-color: var(--color-theme-3);
    color: var(--color-theme-7);
    font-size: 12px;
`

export const ListExtra = styled.div`
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const JoinFieldAmount = styled.div`
    height: 22px;
    line-height: 1.5;
    color: var(--color-gray-400);
    font-size: var(--font-size-normal);
    margin-right: 4px;
`

/**
 * set view styles
 */
export const SetView = styled.div<{ width: number }>`
    width: ${({ width }) => width}px;
    overflow: hidden;
    /* padding-bottom: 12px; */
`

export const TypeSwitcher = styled.div<{ disable?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    height: 32px;
    font-size: var(--font-size-normal);
    margin: 12px 16px;
    border-radius: 6px;
    background-color: var(--color-gray-100);
    cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
    flex: 1;

    &:hover {
        background-color: var(--color-gray-200);
    }
`

export const TypeDesc = styled.div`
    display: inline-flex;
    align-items: center;
`

export const TypeName = styled.span`
    margin-left: 6px;
    font-size: var(--font-size-normal);
    color: var(--color-gray-900);
`

export const Icon = styled(IconFont)``
