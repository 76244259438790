import { singleTextEllipsis } from '@byecode/ui'
import type { AggregationValue, FieldProtocol, HighLightConditions } from '@lighthouse/core'
import { mergeRefs, nanoid } from '@lighthouse/tools'
import { isValid, lightFormat } from 'date-fns'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { CellHighlighter } from '../Highlighter'
import { useWidth } from '../TooltipList'
import { TooltipText } from '../TooltipText'

interface AggregationValuePreviewerProps {
    field: FieldProtocol
    data?: AggregationValue
    isWrap?: boolean
    width?: number
    isBold?: boolean
    styleConditions?: HighLightConditions
}

interface AggregationItemPreviewerProps {
    id: string
    data?: string
    isBold?: boolean
    isWrap: boolean
    isLast?: boolean
    disableTooltip?: boolean
}

const SCxAggregation = styled.div<{ isWrap: boolean; isBold?: boolean }>`
    position: relative;
    font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
    white-space: ${({ isWrap }) => (isWrap ? 'pre-wrap' : 'nowrap')};
    word-break: break-word;
    color: var(--color-black);
    overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'hidden')};
    text-overflow: ${({ isWrap }) => (isWrap ? 'initial' : 'ellipsis')};
`

const SCxAggregationListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean }>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    overflow: hidden;
`

const SCxItem = styled.div<{ isLast?: boolean }>`
    display: flex;
    align-items: center;
    ${({ isLast }) => isLast && singleTextEllipsis}
`

export const AggregationItemPreviewer: React.FC<AggregationItemPreviewerProps> = ({
    id,
    data = '',
    isWrap,
    disableTooltip,
    isBold,
    isLast
}) => {
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)

    return (
        <TooltipText
            title={data}
            disabled={disableTooltip || isWrap}
            render={(ref, isOmit, rang, target) => {
                return (
                    <SCxItem isLast={isLast} ref={mergeRefs([ref, widthRef])}>
                        <SCxAggregation isWrap={isWrap} isBold={isBold}>
                            {data}
                        </SCxAggregation>
                    </SCxItem>
                )
            }}
        />
    )
}

export const AggregationValuePreviewer: React.FC<AggregationValuePreviewerProps> = ({
    field,
    data,
    width = 160,
    isWrap = false,
    isBold,
    styleConditions
}) => {
    const { id, type, innerType } = field

    const value = useMemo(() => {
        if (innerType === 'DATE') {
            if (Array.isArray(data)) {
                return data
                    .map(item => (typeof item === 'number' && isValid(item) ? lightFormat(item, 'yyyy/MM/dd HH:mm:ss') : ''))
                    .filter(Boolean)
                    .join(',')
            }
            return typeof data === 'number' && isValid(data) ? lightFormat(data, 'yyyy/MM/dd HH:mm:ss') : ''
        }
        if (Array.isArray(data)) {
            return data.map(String).join(',')
        }
        return String(data)
    }, [data, innerType])

    const content = useMemo(() => {
        const id = nanoid()
        return <AggregationItemPreviewer id={id} isWrap={isWrap} data={value} isBold={isBold} isLast />
    }, [isBold, isWrap, value])

    return (
        <>
            <CellHighlighter fieldId={id} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
