// import { AppNavBlock } from '@lighthouse/block'
import type { AppNavAbstract, AppNavBlockConfig, BaseApplicationAbstract } from '@lighthouse/core'
import type { FC } from 'react'
import React, { Suspense } from 'react'

const AppNavBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.AppNavBlock })))

interface AppNavBlockControllerProps {
    blockData: AppNavAbstract
}

const AppNavBlockController: FC<AppNavBlockControllerProps> = ({ blockData }) => {
    return (
        <Suspense fallback={<div />}>
            <AppNavBlock
                blockData={blockData}
                appList={(blockData.config as AppNavBlockConfig & { appList: BaseApplicationAbstract[] }).appList}
            />
        </Suspense>
    )
}

export default AppNavBlockController
