import type { Option } from '@byecode/ui'
import { IconFont, Popover } from '@byecode/ui'
import type { Field, ViewField } from '@lighthouse/core'
import {
    type DataSourceAbstract,
    type FilterCommonCondition,
    type ValueVariable,
    type ViewFieldProtocol,
    VariableType
} from '@lighthouse/core'
import cls from 'classnames'
import { lightFormat } from 'date-fns'
import isDeepEqual from 'fast-deep-equal'
// import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { filterOperatorText } from '../../constants/filter'
import { useApplicationContext } from '../../contexts'
import { getRealField } from '../../utils'
import { useAppThemeContext } from '../ApplicationContainer'
import { Operator, Params } from '../Condition'
import { OperatorTileMode } from '../Condition/OperatorTileMode'
import { ParamsTileMode } from '../Condition/ParamsTileMode'
import { FieldIcon } from '../FieldIcon'
import { AggregationInnerTypeToFieldType } from '../Variable'

export interface QuickFilterActorProps {
    dataSource: DataSourceAbstract
    viewField: ViewFieldProtocol
    columns: ViewField[]
    mode?: 'normal' | 'tile'
    filterItem: FilterCommonCondition
    disablePortal?: boolean
    isMobile?: boolean
    onFilter: (data: FilterCommonCondition) => void
}

const SCxQuickFilterActorTitle = styled.span`
    margin: 0 8px;
    white-space: nowrap;
    color: var(--color-gray-400);
`

const SCxArrowDownIcon = styled(IconFont)`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
`

const SCxQuickFilterActorWrapper = styled.div`
    margin: 4px 0;
`

const SCxQuickFilterActorPreviewer = styled.div<{
    theme: {
        color: string
        lightColor: string
    }
}>`
    display: inline-flex;
    align-items: center;
    height: 32px;
    padding: 0 8px;
    margin-right: 8px;
    border: 1px solid var(--color-gray-200);
    border-radius: 5px;
    color: var(--color-gray-400);
    font-size: var(--font-size-normal);
    cursor: pointer;

    &.active {
        background-color: ${({ theme }) => theme.lightColor};
        border-color: ${({ theme }) => theme.mainColor};

        & ${SCxQuickFilterActorTitle}, & ${SCxArrowDownIcon} {
            color: ${({ theme }) => theme.mainColor};
        }
    }
`

const SCxQuickFilterItem = styled.div`
    width: 300px;
    padding: 0 8px;
`

const SCxQuickFilterItemWrapper = styled.div`
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const SCxQuickFilterItemHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SCxParamsWrapper = styled.div``

const SCxTileQuickFilterWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
`

const SCxTileQuickFilterLabel = styled.div<{ isMobile?: boolean; isInput?: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '60px' : '120px')};
    line-height: ${({ isInput }) => (isInput ? '32px' : '24px')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    flex-shrink: 0;
    color: var(--color-gray-400);
`

const QuickFilterActor: React.FC<QuickFilterActorProps> = ({
    dataSource,
    columns,
    viewField,
    mode,
    filterItem,
    disablePortal = false,
    isMobile,
    onFilter
}) => {
    const { personOptions } = useApplicationContext()

    const isTileMode = mode === 'tile'
    const methods = useForm({
        defaultValues: filterItem
    })
    const { schema } = dataSource
    const { control, reset } = methods

    const formData = useWatch({ control })
    const { operator, paramList } = formData
    const theme = useAppThemeContext()

    useUpdateEffect(() => {
        if (formData) {
            onFilter(formData as FilterCommonCondition)
        }
    }, [formData])

    useUpdateEffect(() => {
        if (!isDeepEqual(filterItem, formData)) {
            reset(filterItem)
        }
    }, [filterItem])

    // const popoverState = usePopupState({
    //     variant: 'popover',
    //     popupId: 'quickFilterActorPopover'
    // })

    const paramValue = useMemo(() => {
        return paramList?.filter(item => item?.type === VariableType.VALUE) as ValueVariable[] | undefined
    }, [paramList])

    const realField = useMemo(() => {
        const field = schema?.[viewField.fieldId]
        if (!field) {
            return
        }
        const realField = getRealField(field)
        if (!realField) {
            return
        }
        if ((realField.type === 'formula' || realField.type === 'aggregation') && realField.innerType) {
            return {
                ...realField,
                type: AggregationInnerTypeToFieldType[field.innerType]
            } as Field
        }
        return realField
    }, [schema, viewField.fieldId])

    const active = useMemo(() => {
        if (viewField.type === 'date' && operator === 'between') {
            return paramValue?.[0]?.valueVariable?.value && paramValue[1]?.valueVariable?.value
        }
        if (operator === 'isEmpty' || operator === 'isNotEmpty') {
            return true
        }
        const value = paramValue?.[0]?.valueVariable?.value
        if (Array.isArray(value) && value.length === 0) {
            return false
        }
        if (typeof value === 'boolean') {
            return true
        }
        if (paramValue?.[0]?.valueVariable?.value) {
            return true
        }
    }, [viewField.type, operator, paramValue])

    const operatorText = useMemo(() => {
        if (!viewField.innerType) {
            return
        }
        const filterOperatorTextValue = filterOperatorText[viewField.innerType]
        if (paramValue?.[0]?.valueVariable?.value) {
            return filterOperatorTextValue[operator || ''] || ''
        }

        return operator ? filterOperatorTextValue[operator] : ''
    }, [viewField.innerType, paramValue, operator])

    const selectOptions: Option[] = useMemo(() => {
        if (realField && realField.type === 'select') {
            return realField.select.options.map(item => ({
                label: item.label,
                value: item.label,
                color: item.color
            }))
        }
        return []
    }, [realField])

    const isHasParams = useMemo(() => {
        if (!realField) {
            return false
        }
        return realField.type !== 'file' && realField.type !== 'video'
    }, [realField])

    const paramsText = useMemo(() => {
        if (!realField || !realField.innerType) {
            return ''
        }
        if (realField.innerType === 'DATE') {
            return (paramValue || [])
                .map(item => {
                    if (item.type !== VariableType.VALUE || !item?.valueVariable?.value) {
                        return ''
                    }
                    return lightFormat(new Date(Number(item?.valueVariable?.value)), 'yyyy-MM-dd')
                })
                .join(' ~ ')
        }
        const param = paramValue?.[0]?.valueVariable?.value
        if (realField.innerType === 'ARRAY') {
            if (!Array.isArray(param) || !param) {
                return ''
            }
            if (realField.type === 'select') {
                return param
                    .map(val => {
                        return find(option => option.value === String(val), selectOptions || [])?.label || ''
                    })
                    .join(', ')
            }
            if (realField.type === 'person') {
                return param
                    .map(val => {
                        if (val === '{currentUserId}') {
                            return '当前用户'
                        }
                        return find(option => option.userId === String(val), personOptions || [])?.username || ''
                    })
                    .filter(Boolean)
                    .join(', ')
            }
            return param.join(',')
        }

        if (realField.innerType === 'BOOL') {
            if (typeof param !== 'boolean') {
                return ''
            }
            return param ? 'true' : 'false'
        }

        return param
    }, [realField, paramValue, selectOptions, personOptions])

    const quickFilterContent = useMemo(() => {
        if (isTileMode) {
            return (
                <SCxTileQuickFilterWrapper>
                    <SCxTileQuickFilterLabel
                        isInput={realField?.innerType === 'TEXT' || realField?.innerType === 'DATE'}
                        isMobile={isMobile}
                    >
                        {viewField.title}
                    </SCxTileQuickFilterLabel>
                    <OperatorTileMode focusOutLine={theme.mainColor} schema={schema} prefixName="" />
                    {realField && (
                        <ParamsTileMode field={realField} schema={schema} width="210px" prefixName="" focusOutLine={theme.mainColor} />
                    )}
                </SCxTileQuickFilterWrapper>
            )
        }
        return (
            <SCxQuickFilterActorWrapper>
                <Popover width={300} position="bottom-start">
                    <Popover.Target>
                        <SCxQuickFilterActorPreviewer theme={theme} className={cls({ active })}>
                            <FieldIcon
                                id={viewField.fieldId}
                                type={viewField.type}
                                fill={active ? theme.mainColor : 'var(--color-gray-400)'}
                            />
                            <SCxQuickFilterActorTitle>
                                {viewField.title}
                                {active ? ` :${operatorText || ''} ${paramsText}` : ''}
                            </SCxQuickFilterActorTitle>
                            <SCxArrowDownIcon type="ArrowDownSmall" fill={active ? theme.mainColor : 'var(--color-gray-400)'} />
                        </SCxQuickFilterActorPreviewer>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <SCxQuickFilterItem style={{ width: 300 }}>
                            <SCxQuickFilterItemWrapper>
                                <SCxQuickFilterItemHeaderWrapper>
                                    <Operator
                                        prefixName=""
                                        field={realField}
                                        // schema={schema}
                                        clearable={realField?.type === 'file'}
                                        style={{ width: 'auto' }}
                                    />
                                </SCxQuickFilterItemHeaderWrapper>
                                {operator && isHasParams && (
                                    <SCxParamsWrapper>
                                        <Params width="100%" field={realField} prefixName="" focusOutLine={theme.mainColor} />
                                    </SCxParamsWrapper>
                                )}
                            </SCxQuickFilterItemWrapper>
                        </SCxQuickFilterItem>
                    </Popover.Dropdown>
                </Popover>
            </SCxQuickFilterActorWrapper>
        )
    }, [
        active,
        isMobile,
        isHasParams,
        isTileMode,
        operator,
        operatorText,
        paramsText,
        realField,
        schema,
        theme,
        viewField.fieldId,
        viewField.title,
        viewField.type
    ])

    return <FormProvider {...methods}>{quickFilterContent}</FormProvider>
}

export default QuickFilterActor
