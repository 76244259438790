import { backgroundTransitionOnClick, Flex, flex, IconFont, pointer, singleTextEllipsis, tinyButtons } from '@byecode/ui'
import type { DataSourceAbstract, FieldADTValue, RecordLikeProtocol } from '@lighthouse/core'
import { clsx, ScrollArea } from '@mantine/core'
import cls from 'classnames'
import * as React from 'react'
import styled from 'styled-components'

import { isEmptyCellValue } from '../../../../utils'
import FieldValuePreviewer from '../../../FieldValuePreviewer'

interface FieldListProps {
    value: string[]
    options: RecordLikeProtocol[]
    tableWidth?: number
    tableHeight?: number
    dataSource?: DataSourceAbstract | null
    isDriver?: boolean
    style?: React.CSSProperties
    onChange: (val: string) => void
    onScrollPositionChange?: (position: { x: number; y: number }) => void
}

export const SCxSelectContain = styled.div`
    padding: 8px 0px;
    max-height: 500px;
    width: 200px !important;
    overflow-y: auto;
    ${tinyButtons};
`
export const SCxText = styled.span<{ color?: string }>`
    font-size: var(--font-size-normal);
    color: ${({ color }) => color ?? 'var(--color-black)'};
    line-height: 16px;
    ${pointer}
    ${singleTextEllipsis}
`

export const SCxSelectItem = styled.div`
    width: 100%;
    padding: 12px 16px;
    ${flex}
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    /* height: 38px;
    line-height: 38px; */
    ${backgroundTransitionOnClick}
    font-size: var(--font-size-normal);
    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        ${SCxText} {
            color: var(--color-gray-400);
        }
    }
`

export const SCxIcon = styled(IconFont)<{ size?: number; color?: string; bgColor?: string; cursor?: string }>`
    font-size: ${({ size }) => size ?? 16}px;
    color: ${({ color }) => color ?? 'var(--color-black)'};
    cursor: ${({ cursor }) => cursor ?? 'pointer'};
`

export const SCxLeftFill = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
`

export const SCxRightFill = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
`

const RelatedRecorderContent = styled(ScrollArea)<{ height?: number }>`
    position: relative;
    overflow: visible;
    width: 100%;
    display: flex;
    justify-content: stretch;
    max-height: 100%;

    & .mantine-ScrollArea-viewport {
        height: auto;

        max-height: ${({ height }) => (height ? `${height}px` : '100%')};
        > * {
            display: block !important;
            max-width: 100%;
        }
    }

    &.driver {
        ${SCxSelectItem}:not(:first-child) {
            border-top: 1px solid var(--color-gray-200);
        }
    }
`

const SCxNextRow = styled(Flex)`
    height: 22px;
    width: max-content;
    > * {
        width: auto !important;
    }
`

const FieldList = React.forwardRef<HTMLDivElement, FieldListProps>(
    ({ options, value, tableHeight, dataSource, isDriver, style, onChange, onScrollPositionChange }, ref) => {
        return (
            <div style={{ flex: 1,  overflow: 'hidden' }}>
                <RelatedRecorderContent
                    ref={ref}
                    height={tableHeight}
                    style={style}
                    className={cls({ driver: isDriver, test: true })}
                    onScrollPositionChange={onScrollPositionChange}
                >
                    {options.map(({ id, content, disabled }, index) => {
                        const { schema } = dataSource ?? {}
                        const [first, ...next] = dataSource?.viewOptions.tableProps?.filter(v => v.visible) ?? []
                        const firstCellValue = content?.[first.id]
                        const firstField = schema?.[first.id]
                        const isEmptyOfFirst = firstCellValue
                            ? isEmptyCellValue({ ...firstField, value: firstCellValue } as FieldADTValue)
                            : true

                        const isNextEmpty = next.every(v => {
                            const cellValue = content?.[v.id]
                            const field = schema?.[v.id]
                            return cellValue ? isEmptyCellValue({ ...field, value: cellValue } as FieldADTValue) : true
                        })
                        if (!first || !schema) {
                            return null
                        }

                        return (
                            <SCxSelectItem key={id} className={cls({ disabled })} onClick={() => !disabled && onChange(id)}>
                                <SCxLeftFill>
                                    {isEmptyOfFirst ? (
                                        <div key={id}>-</div>
                                    ) : (
                                        <div style={{ height: 22 }}>
                                            <FieldValuePreviewer
                                                isHasCurrentUser
                                                // width={140}
                                                enableEdit={false}
                                                previewHost="lookupTable"
                                                field={schema?.[first.id]}
                                                data={content?.[first.id]?.value}
                                                isWrap={false}
                                                visiblePersonDescribe={false}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            />
                                        </div>
                                    )}

                                    {next.length > 0 &&
                                        (isNextEmpty ? (
                                            <div>-</div>
                                        ) : (
                                            <SCxNextRow alignItems="center" gap={12}>
                                                {next.map(v => {
                                                    const cellValue = content?.[v.id]
                                                    const field = schema?.[v.id]
                                                    const isEmpty = cellValue
                                                        ? isEmptyCellValue({ ...field, value: cellValue } as FieldADTValue)
                                                        : true
                                                    if (isEmpty) {
                                                        return null
                                                    }
                                                    return (
                                                        <FieldValuePreviewer
                                                            isHasCurrentUser
                                                            previewHost="lookupTable"
                                                            enableEdit={false}
                                                            key={v.id}
                                                            field={field}
                                                            data={content?.[v.id]?.value}
                                                            isWrap={false}
                                                            visiblePersonDescribe={false}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        />
                                                    )
                                                })}
                                            </SCxNextRow>
                                        ))}
                                </SCxLeftFill>
                                <SCxRightFill>{value.includes(id) && <SCxIcon type="Tick" color="var(--color-app-main)" />}</SCxRightFill>
                            </SCxSelectItem>
                        )
                    })}
                </RelatedRecorderContent>
            </div>
        )
    }
)

export default FieldList
