import type { UploadOptions } from '@rpldy/uploady'
import type { ImageOptions } from '@tiptap/extension-image'
import Image from '@tiptap/extension-image'
import { ReactNodeViewRenderer } from '@tiptap/react'

// import { uploadImages } from './helper'
import { ParseImagePlugin, UPLOAD_IMAGE_PLUGIN_KEY } from './plugin'
import { ResizeableImage } from './ResizeableImage'

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        customImg: {
            insertImg: (files: File[]) => ReturnType
        }
    }
}

export interface UploadImageOptions extends ImageOptions {
    appId: string
    uploadOptions: UploadOptions
}

export const UploadImage = Image.extend<UploadImageOptions>({
    draggable: false,

    addOptions() {
        return {
            ...this.parent?.(),
            uploadOptions: {
                destination: {}
            }
        }
    },

    addAttributes() {
        return {
            ...this.parent?.(),
            width: {
                default: null,
                parseHTML(element) {
                    return element.style.width
                },
                renderHTML(attributes) {
                    return {
                        style: {
                            width: attributes.width
                        }
                    }
                }
            },
            height: {
                default: null,
                parseHTML(element) {
                    return element.style.height
                },
                renderHTML(attributes) {
                    return {
                        style: {
                            width: attributes.height
                        }
                    }
                }
            }
        }
    },

    addCommands() {
        return {
            insertImg: files => {
                return ({ view }) => {
                    const { tr } = view.state
                    tr.setMeta(UPLOAD_IMAGE_PLUGIN_KEY, files)
                    view.dispatch(tr)

                    // TODO
                    // uploadImages(files, view, this.options.uploadOptions)
                    return true
                }
            }
        }
    },

    addProseMirrorPlugins() {
        return this.editor.isEditable
            ? [
                  ParseImagePlugin({
                      view: this.editor.view,
                      uploadOptions: this.options.uploadOptions
                  })
              ]
            : []
    },

    addNodeView(...args) {
        return ReactNodeViewRenderer(ResizeableImage)
    }
})
