import { IconFont, Tooltip } from '@byecode/ui'
import type { FC } from 'react'
import React from 'react'

import { useTipTapContext } from '../Context'
import { CustomActionIcon } from './styles'

export const EraserMenu: FC = () => {
    const editor = useTipTapContext()

    return (
        <Tooltip title="清除格式">
            <CustomActionIcon
                variant="subtle"
                onClick={() => {
                    if (editor?.state.selection.empty) {
                        return
                    }
                    editor?.chain().focus().unsetAllMarks().run()
                }}
            >
                <IconFont
                    type="Eraser"
                    color={editor?.state.selection.empty ? 'var(--color-gray-400)' : undefined}
                    style={{ cursor: editor?.state.selection.empty ? 'not-allowed' : undefined }}
                />
            </CustomActionIcon>
        </Tooltip>
    )
}
