import { Flex, Switch, Text } from '@byecode/ui'
import { type CheckboxConfig, type RichTextContentProtocol,CheckboxShapeEnum } from '@lighthouse/core'
import React from 'react'
import styled from 'styled-components'

import { isEmptyRichTextValue } from '../../RichText'
import { FieldInputCheckBox } from '../FieldInputCheckbox'
import type { FieldBaseProps } from '../types'

interface CheckboxFieldProps extends FieldBaseProps {
    value?: boolean
    checkBoxConfig?: CheckboxConfig
    onRenderTitle?: (v: RichTextContentProtocol) => string
}
const SCxContainer = styled.div``

export const CheckboxField: React.FunctionComponent<CheckboxFieldProps> = props => {
    const { value, onCellChange, readOnly, checkBoxConfig, onRenderTitle } = props
    const { checkbox } = checkBoxConfig ?? {}
    const { type, gapType, gap = 0, describe, shape, describePosition = 'left' } = checkbox ?? {}

    const justifyContent = gapType === 'auto' ? 'space-between' : 'flex-start'

    const domGap = gapType === 'auto' ? undefined : gap * 16

    return (
        <SCxContainer>
            <Flex alignItems="center" justifyContent={justifyContent} gap={domGap}>
                {type === 'checkbox' ? (
                    <FieldInputCheckBox
                        checked={value}
                        disabled={readOnly}
                        shape={shape}
                        onChange={e => {
                            onCellChange?.({ type: 'checkbox', value: e.currentTarget.checked })
                        }}
                    />
                ) : (
                    <Switch
                        checked={value}
                        disabled={readOnly}
                        color="var(--color-app-main)"
                        onChange={e => onCellChange?.({ type: 'checkbox', value: e.currentTarget.checked })}
                    />
                )}
                {describe && !isEmptyRichTextValue(describe) && <Text style={{ order: describePosition === 'left' ? -1 : 0 }} whiteSpace='pre-wrap' size={14}>{onRenderTitle?.(describe)}</Text>}
            </Flex>
        </SCxContainer>
    )
}
