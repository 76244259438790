import 'react-toastify/dist/ReactToastify.css'

import { MessageContainer } from '@byecode/ui'
import { ApplicationPreviewEnum } from '@lighthouse/shared'
import { useIosOpenKeyboardFix } from '@lighthouse/tools'
import zhCN from 'date-fns/locale/zh-CN/index'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import React from 'react'
import { RouterProvider } from 'react-router-dom'

import { usePreview } from './hooks/useApplication'
import { router } from './router'

setDefaultOptions({ locale: zhCN })

export const App: React.FC = () => {
    const previewType = usePreview()

    const isMobile = previewType === ApplicationPreviewEnum.mobile
    useIosOpenKeyboardFix()

    return (
        <>
            <MessageContainer isMobile={isMobile} />
            <RouterProvider router={router} />
        </>
    )
    // return <div>123</div>
}
