import { useCustomViewBlockContext } from '@lighthouse/block'
import type { QrBarcodeBlockAbstract } from '@lighthouse/core'
import { generateText } from '@lighthouse/shared'
import React, { Suspense, useMemo } from 'react'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

const QrBarcodeBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.QrBarcodeBlock })))

interface QrBarcodeBlockControllerProps {
    blockData: QrBarcodeBlockAbstract
}

const QrBarcodeBlockController: React.FC<QrBarcodeBlockControllerProps> = ({ blockData }) => {
    const { config } = blockData
    const { content } = config
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { record: customViewRecord } = useCustomViewBlockContext()

    const { renderLabel } = useVariableValueRender(pageId, prev.recordId, curr.recordId)

    const value = useMemo(
        () =>
            generateText(content?.value, {
                variable: {
                    renderLabel: v =>
                        renderLabel(v.attrs.value, {
                            viewRecord: customViewRecord
                        })
                }
            }),
        [content?.value, customViewRecord, renderLabel]
    )

    return (
        <Suspense fallback={<div />}>
            <QrBarcodeBlock blockData={blockData} code={value} />
        </Suspense>
    )
}

export default QrBarcodeBlockController
