import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

export const useStyles = createStyles(({ disabled }: { disabled?: boolean }) => ({
    item: css({
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        padding: '8px 16px',
        lineHeight: '16px',
        cursor: 'pointer',

        ...(disabled
            ? {
                  color: '$colorGray400',
                  pointerEvents: 'none',
                  userSelect: 'none'
              }
            : {}),

        '&:hover': {
            backgroundColor: '$colorGray100'
        },

        '&[aria-expanded="true"]': {
            backgroundColor: '$colorGray100'
        }
    }),

    itemLabel: css({
        fontSize: 14,
        flex: 1,
        textEllipsis: true
    }),

    itemIcon: css({
        flexCenter: true,
        opacity: disabled ? 0.5 : undefined
    }),

    itemRightSection: css({
        flexCenter: true
    })
}))
