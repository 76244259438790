import { Select } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import type { CheckboxProps } from '@mantine/core'
import { Checkbox, Radio } from '@mantine/core'
import { filter } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { disableAppendDataSourceType } from '../../../constants'
import type { SelectOption } from '../../../types'

interface ImportTypeSelectorProps {
    dataSourceList: DataSourceAbstract[]
    fileName?: string
    sheets?: {
        name: string
        columns: string[]
    }[]
    fileType: 'excel' | 'csv'
    disableSelectDataSource?: boolean
}

interface ImportTypeCheckGroupProps {
    value: string | string[]
    options?: SelectOption[]
    multiple?: boolean
    onChange?: (value: string | string[]) => void
}

const SCxImportTypeContainer = styled.div``

const SCxImportTypeTip = styled.p`
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    margin-bottom: 10px;
`

const SCxImportTypeSelectorWrapper = styled.p`
    display: flex;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    margin-bottom: 10px;
`

const SCxImportTypeSelect = styled(Select)``

const SCxImportSheetTip = styled.p`
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    margin: 20px 0 10px 0;
`

const SCxFileName = styled.span`
    color: var(--color-black);
`

const SCxImportTypeCheckGroupWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const SCxImportTypeCheckItemWrapper = styled.div<{ checked?: boolean }>`
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 0 12px 12px 0;
    width: 112px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: var(--font-size-sm);
    border: 1px solid var(--color-gray-100);
    border-radius: 6px;
    border-color: ${props => (props.checked ? 'var(--color-main)' : 'var(--color-gray-100)')};

    &:nth-of-type(4n) {
        margin-right: 0;
    }
`

const SCxImportTypeCheckbox = styled(Checkbox)`
    display: flex;
    pointer-events: none;
    color: var(--color-black);
`

const SCxImportTypeRadio = styled(Radio)`
    display: flex;
    pointer-events: none;
    color: var(--color-black);
`

const importTypeOptions = [
    {
        label: '创建新的表',
        value: '1'
    },
    {
        label: '追加导入到已有表',
        value: '2'
    }
]

const ImportTypeCheckGroup: React.FC<ImportTypeCheckGroupProps> = ({ value, multiple, options, onChange }) => {
    const handleChange = useCallback(
        (newValue: string) => {
            if (multiple) {
                const oldValues = Array.isArray(value) ? value : [value]
                const isRemoveAction = oldValues.includes(newValue)
                if (isRemoveAction) {
                    const newValues = filter(v => v !== newValue, oldValues)
                    onChange?.(newValues)
                    return
                }
                onChange?.([...oldValues, newValue])
                return
            }
            onChange?.(newValue)
        },
        [multiple, onChange, value]
    )
    return (
        <SCxImportTypeCheckGroupWrapper>
            {options?.map(({ label, value: optionValue }) => {
                const isChecked = multiple ? value.includes(optionValue) : value === optionValue
                const props: Pick<CheckboxProps, 'size' | 'checked' | 'readOnly' | 'label'> = {
                    size: 'xs',
                    checked: isChecked,
                    readOnly: true,
                    label
                }
                const labelStyle = { width: 80, overflow: 'hidden', textOverflow: 'ellipsis' }

                return (
                    <SCxImportTypeCheckItemWrapper key={optionValue} checked={isChecked} onClick={() => handleChange(optionValue)}>
                        {multiple ? (
                            <SCxImportTypeCheckbox {...props} styles={{ label: labelStyle }} />
                        ) : (
                            <SCxImportTypeRadio {...props} styles={{ label: labelStyle }} />
                        )}
                    </SCxImportTypeCheckItemWrapper>
                )
            })}
        </SCxImportTypeCheckGroupWrapper>
    )
}

export const ImportTypeSelector: React.FC<ImportTypeSelectorProps> = ({
    dataSourceList,
    fileName,
    sheets,
    fileType,
    disableSelectDataSource
}) => {
    const isCSVFile = fileType === 'csv'
    const { control } = useFormContext()
    const { importType } = useWatch({ control })

    const isAppendMode = importType === '2'

    const sheetOptions = useMemo(() => {
        if (!sheets) {
            return []
        }
        return sheets.map(sheet => ({ label: sheet.name, value: sheet.name }))
    }, [sheets])

    const dataSourceOptions = useMemo(
        () =>
            dataSourceList
                .filter(ds => !disableAppendDataSourceType.has(ds.type))
                .map(({ id, name }) => ({ label: name || '未命名数据源', value: id })),
        [dataSourceList]
    )

    return (
        <SCxImportTypeContainer>
            {!disableSelectDataSource && (
                <>
                    <SCxImportTypeTip>选择导入方式</SCxImportTypeTip>
                    <SCxImportTypeSelectorWrapper>
                        <Controller
                            name="importType"
                            render={({ field }) => (
                                <SCxImportTypeSelect styles={{ root: { width: '100%' } }} {...field} options={importTypeOptions} />
                            )}
                        />
                        {isAppendMode && (
                            <Controller
                                name="dsId"
                                render={({ field }) => (
                                    <SCxImportTypeSelect style={{ marginLeft: 8 }} options={dataSourceOptions} {...field} />
                                )}
                            />
                        )}
                    </SCxImportTypeSelectorWrapper>
                </>
            )}
            {!isCSVFile && (
                <>
                    <SCxImportSheetTip>
                        请在 <SCxFileName>{fileName}</SCxFileName> 中选择{isAppendMode ? '一个' : ''} Sheet
                    </SCxImportSheetTip>
                    <Controller
                        name="sheetName"
                        render={({ field }) => <ImportTypeCheckGroup multiple={!isAppendMode} options={sheetOptions} {...field} />}
                    />
                </>
            )}
        </SCxImportTypeContainer>
    )
}
