import { IconFont } from '@byecode/ui'
import cls from 'classnames'
import type { CSSProperties } from 'react'
import React from 'react'
import styled from 'styled-components'

interface RelationNormalTagProps {
    title: string | undefined
    style?: CSSProperties
    enableDelete?: boolean
    prefix?: React.ReactNode
    isActive?: boolean
    disabled?: boolean
    onRemove?: () => void
    onClick?: React.MouseEventHandler<HTMLSpanElement>
}
const SCxClearIconWrapper = styled.div``
const RelationItemWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    flex-wrap: nowrap;
    height: 28px;
    min-width: 28px;
    padding: 0 6px;
    gap: 4px;
    color: var(--color-black);
    text-align: center;
    overflow: hidden;
    border-radius: 100px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);

    ${SCxClearIconWrapper} {
        opacity: 0.5;
    }
    :hover {
        background-color: var(--color-gray-100);
        ${SCxClearIconWrapper} {
            opacity: 1;
        }
    }

    &.active{
        color: var(--color-white);
        border-color: transparent;
        background-color: var(--color-app-main);
    }

    &:not(.active)&.disabled {
        color: var(--color-gray-400) !important;
        border-color: transparent;
        background-color: var(--color-gray-200) !important;
    }

    &.disabled {
        cursor: not-allowed;
    }
`

const RelationName = styled.span`
    font-size: var(--font-size-normal);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const RelationNormalTag: React.FC<RelationNormalTagProps> = ({
    title,
    enableDelete = true,
    style,
    disabled,
    isActive,
    onClick,
    onRemove
}) => {
    return (
        <RelationItemWrapper onClick={onClick} style={style} className={cls({ active: isActive, disabled })}>
            <RelationName>{title ?? '未命名'}</RelationName>
            {enableDelete && (
                <SCxClearIconWrapper
                    onClick={ev => {
                        ev.stopPropagation()
                        onRemove?.()
                    }}
                >
                    <IconFont style={{ cursor: 'pointer' }} size={12} type="Close" color="var(--color-gray-600)" />
                </SCxClearIconWrapper>
            )}
        </RelationItemWrapper>
    )
}
