import { type ApplicationPreviewEnum, CHINA_LANG_LIST, DEFAULT_LANG } from '@lighthouse/shared'
import i18n from 'i18next'
import { atom } from 'jotai'
import { merge } from 'rambda'

import http from '@/http'
import * as srv from '@/services'

import { appAtom, languageAtom, previewAtom, websiteApplicationSettingAtom } from './state'

export const fetchApplicationAtom = atom(null, async (_, set) => {
    const app = await srv.getAppInfo()
    set(appAtom, app)
    return app
})

export const setPreviewAtom = atom(null, (_, set, payload: ApplicationPreviewEnum) => {
    set(previewAtom, payload)
})

export const fetchAppSettingAtom = atom(null, async (get, set) => {
    const data = await srv.getAppInfo()
    if (data) {
        set(appAtom, draft => {
            if (draft) {
                draft.config = data.config
            }
        })
    }
    return data
})

export const setLanguageAtom = atom(null, async (get, set, language?: string) => {
    const appSetting = get(websiteApplicationSettingAtom)
    const app = get(appAtom)
    const storageLanguage = get(languageAtom)

    const languageList = appSetting?.language.list
    const storageLang = languageList?.find(item => item.lang === storageLanguage)?.lang
    const currentLang = (language ?? storageLang) || app?.lang
    const matchLang = languageList?.find(item => item.lang === currentLang)?.lang
    const englishLang = languageList?.find(item => item.lang === 'en')?.lang
    const zhLang = languageList?.find(item => item.lang === 'zh-CN')?.lang
    // 默认切换为当前语言，当找不到时，国外有英文则切英语，国内则切中文，都没有默认切换为第一个语言
    const lang =
        matchLang ?? (currentLang && CHINA_LANG_LIST.has(currentLang) ? zhLang : englishLang) ?? languageList?.[0].lang ?? DEFAULT_LANG
    http.defaults.headers.common['lang'] = lang
    document.documentElement.lang = lang === 'zh-CN' ? 'zh-Hans-CN' : lang
    await i18n.changeLanguage(lang)

    set(languageAtom, lang)
    storageLanguage !== lang && storageLanguage && set(fetchAppSettingAtom)
})
