import { Flex } from '@byecode/ui'
import styled from 'styled-components'

export const CMxFirstIndex = styled(Flex)`
    position: sticky;
    left: 0;
    z-index: 2;
    width:  44px;
    font-size: var(--font-size-sm);
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);
`

export const CMxEndIndex = styled(Flex)`
    position: sticky;
    width: 70px;
    padding: 0 8px;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    background-color: var(--color-gray-50);
    right: 0;
    z-index: 2;
    border-left: 1px solid var(--color-gray-200);
`

export const CMxEmpty = styled.div`
    /* width: 100%; */
    /* flex: 1; */
    width: 240px;
`

export const CMxRecord = styled.div`
    display: inline-flex;
    align-items: stretch;
    min-height: 42px;
    border-top: 1px solid var(--color-gray-200);



    > * {
        flex-shrink: 0;
    }

    & > :not(:nth-last-child(-n + 2)) {
        border-right: 1px solid var(--color-gray-200);
    }
`
