import { format } from 'date-fns'
import en from 'date-fns/locale/en-US'
import zhCN from 'date-fns/locale/zh-CN'
import zhTW from 'date-fns/locale/zh-TW'

const locales: Record<string, Locale> = {
    'zh-CN': zhCN,
    'zh-TW': zhTW,
    en
}

export const dateFormat = (
    date: Date | number,
    formatStr: string,
    options?: {
        locale?: string
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
        firstWeekContainsDate?: number
        useAdditionalWeekYearTokens?: boolean
        useAdditionalDayOfYearTokens?: boolean
    }
) => {
    return format(date, formatStr, { ...options, locale: options?.locale ? locales[options.locale] : undefined })
}
