import { Text } from '@byecode/ui'
import type { DTSelectItem, HighLightConditions, SelectValue, SingleSelectField } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { CellHighlighter } from '../Highlighter'

interface SingleSelectValuePreviewerProps extends Pick<React.CSSProperties, 'justifyContent'> {
    data?: SelectValue
    field: SingleSelectField
    children?: React.ReactNode
    textStyles?: React.CSSProperties
    isWrap?: boolean
    width?: number
    colorless?: boolean
    styleConditions?: HighLightConditions
    direction?: 'horizontal' | 'vertical'
}

interface SelectItemPreviewerProps {
    children?: React.ReactNode
    id: string
    data: DTSelectItem
    isVertical?: boolean
    direction?: 'horizontal' | 'vertical'
    textStyles?: React.CSSProperties
}

const SCxPersonListWrapper = styled.div<{ isWrap: boolean } & Pick<React.CSSProperties, 'justifyContent'>>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    overflow: hidden;
`

const SCxCellValue = styled.div<{ direction?: 'horizontal' | 'vertical' }>`
    display: flex;
    gap: 6px;
    align-items: center;
    padding: ${({ direction }) => (direction === 'vertical' ? '8px 2px' : '2px 1px')};
    font-size: 14px;

    ${({ direction }) =>
        direction === 'vertical'
            ? css`
                  width: 22px;
              `
            : css`
                  height: 22px;
              `}
    white-space: nowrap;
`

const SCxDot = styled.div<{ background: string | undefined }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${({ background }) => background || 'none'};
`

export const SelectItemPreviewer: React.FC<SelectItemPreviewerProps> = ({ id, data, direction, textStyles }) => {
    const { label, color } = data

    return (
        <SCxCellValue key={label} direction={direction} style={textStyles}>
            <SCxDot background={color} />
            <Text>{label}</Text>
        </SCxCellValue>
    )
}

export const SingleSelectValuePreviewer: React.FC<SingleSelectValuePreviewerProps> = ({
    field,
    data = [],
    textStyles,
    width = 160,
    isWrap = true,
    justifyContent = 'flex-start',
    styleConditions,
    direction
}) => {
    const { id, singleSelect } = field
    const { options = [] } = singleSelect ?? {}

    const selectedOptions = useMemo(
        () =>
            data.reduce<DTSelectItem[]>((list, val) => {
                const option = options.find(option => option.label === val)
                if (option) {
                    list.push(option)
                }
                return list
            }, []),
        [data, options]
    )

    const selectText = data.join(',')

    return (
        <>
            <CellHighlighter fieldId={id} type="select" value={selectText} conditions={styleConditions} />
            <SCxPersonListWrapper justifyContent={justifyContent} isWrap={isWrap}>
                {selectedOptions.map((item, index) => (
                    <SelectItemPreviewer key={item.id} id={String(index)} data={item} direction={direction} textStyles={textStyles} />
                ))}
            </SCxPersonListWrapper>
        </>
    )
}
