import { IconFont } from '@byecode/ui/components/IconFont'
import { Menu } from '@byecode/ui/components/Menu'
import type { FC } from 'react'
import React from 'react'

import { useTipTapContext } from '../Context'
import { DropdownActionButton } from './styles'

const OPTIONS = [
    {
        label: '1.5（默认）',
        value: 1.5
    },
    {
        label: 1.7,
        value: 1.7
    },
    {
        label: 2,
        value: 2
    },
    {
        label: 3,
        value: 3
    },
    {
        label: 4,
        value: 4
    },
    {
        label: 5,
        value: 5
    }
]

export const LineHeightMenu: FC = () => {
    const editor = useTipTapContext()

    const currentSize = editor?.getAttributes('textStyle').lineHeight?.toString() || 1.5

    return (
        <Menu withinPortal width={120} trigger="hover" position="bottom">
            <Menu.Target>
                <DropdownActionButton>
                    <IconFont size={16} type="LineSpacing" />
                    <IconFont size={10} type="ArrowDownSmallGray" color="var(--color-gray-500)" />
                </DropdownActionButton>
            </Menu.Target>
            <Menu.Dropdown>
                {OPTIONS.map(item => (
                    <Menu.Item
                        key={item.value}
                        style={currentSize === item.value ? { backgroundColor: 'var(--color-gray-100)' } : {}}
                        onClick={() =>
                            editor?.isActive('textStyle', { lineHeight: item.value })
                                ? editor.chain().focus().unsetLineHeight().run()
                                : editor?.chain().focus().setLineHeight(item.value).run()
                        }
                    >
                        {item.label}
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    )
}
