import { getAssetUrl } from '@lighthouse/assets'
import { NoFound } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

const SCxContainer = styled.div`
    height: 100%;
`

export default function Unpublished() {
    return (
        <SCxContainer>
            <NoFound image={getAssetUrl('common', 'byecode-logo.png')} desc="应用已下线，请联系管理员" />
        </SCxContainer>
    )
}
