import { proxy, useSnapshot } from 'valtio'

interface State {
    cellCheckedId: string
    fieldCheckId: string
    selectedItems: Set<string>
}

class SelectionState {
    #state: State = proxy({
        selectedItems: new Set([]),
        cellCheckedId: '',
        fieldCheckId: ''
    })

    get state() {
        return this.#state
    }

    public useSelectedItems() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useSnapshot(this.#state)
    }
}

export default new SelectionState()
