import React from 'react'
import styled from 'styled-components'

import type { IndicatorChartOption } from './types'

interface IndicatorChartProps {
    option: IndicatorChartOption
}

const IndicatorChartWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const IndicatorTitle = styled.div`
    width: 100%;
    line-height: 22px;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    color: var(--color-gray-600);
    white-space: nowrap;
    text-overflow: ellipsis;
`

const IndicatorValue = styled.div`
    width: 100%;
    font-size: 36px;
    line-height: 1;
    font-family: Din pro, Din alternate;
    font-weight: var(--font-weight-little-bold);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s ease-in-out;
`

const IndicatorLabel = styled.div`
    width: 100%;
    line-height: 22px;
    font-size: var(--font-size-base);
    color: var(--color-gray-500);
    text-align: left;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
`

const IndicatorChart: React.FC<IndicatorChartProps> = ({ option }) => {
    const { value, label, color = [], title, showHeadTitle, showLabel } = option
    const [firstColor] = color
    // const { ref, width, height } = useIndicatorFontWidth()
    // const area = multiply(width, height)
    // const scale = 800
    // const size = Math.floor(divide(area, scale))
    // const fontSize = min(max(size, 60), 144)
    // const text = String(value)
    // const textLen = text.length
    // const singleTextSize = Math.floor(multiply(divide(width, textLen), 1.75))
    // const resultSize = min(min(singleTextSize, fontSize), height - 20)

    return (
        <IndicatorChartWrapper>
            {showHeadTitle && <IndicatorTitle>{title}</IndicatorTitle>}
            <IndicatorValue style={{ color: firstColor || 'var(--color-app-main)' }}>{value}</IndicatorValue>
            {showLabel && <IndicatorLabel>{label}</IndicatorLabel>}
        </IndicatorChartWrapper>
    )
}

export default IndicatorChart
