/* eslint-disable @typescript-eslint/no-explicit-any */
import type React from 'react'
import { useCallback } from 'react'

export function assignRef<T = any>(ref: React.ForwardedRef<T>, value: T | null) {
    if (typeof ref === 'function') {
        ref(value)
    } else if (typeof ref === 'object' && ref !== null && 'current' in ref) {
        ref.current = value
    }
}

type Ref<T> = React.Dispatch<React.SetStateAction<T>> | React.ForwardedRef<T>

export function mergeRefs<T = any>(...refs: Ref<T>[]) {
    return (node: T | null) => {
        refs.forEach(ref => {
            assignRef(ref as React.ForwardedRef<T>, node)
        })
    }
}

export function useMergedRef<T = any>(...refs: Ref<T>[]) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(mergeRefs(...refs), refs)
}
