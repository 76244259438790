import type { CascadeOption, CascadeValue } from '@byecode/ui'
import { IconFont } from '@byecode/ui'
import type { CascadeConfig } from '@lighthouse/core'
import React, { useCallback, useMemo, useState } from 'react'
import { useAsyncRetry, useUpdateEffect } from 'react-use'

import { useApplicationContext } from '../../../../../contexts'
import { CascadeDrawer } from '../../../../FieldDrawer'
import { SelectPlaceHolder } from '../../../SelectPlaceHolder'
import type { FieldBaseProps } from '../../../types'
import * as CM from '../../commonStyles'
import * as SC from '../styles'

interface DepartmentDrawerFieldProps extends FieldBaseProps {
    value: string[]
    cascadeConfig: CascadeConfig
}

export const DepartmentDrawerField: React.FunctionComponent<DepartmentDrawerFieldProps> = props => {
    const {
        onCellChange,
        value: data,
        isControlled,
        config: { placeholder, title = '' },
        readOnly,
        cascadeConfig,
        dataSource,
        record,
        onSaveCellChange,
        onFetchCascadeOptions
    } = props
    const { cascade, fieldPointer = '' } = cascadeConfig ?? {}
    const {
        cascadeFieldPointer = '',
        cascadePointer = '',
        cascadeShowFieldPointer = '',
        showPath,
        isLastLevel,
        filter,
        parentFieldPointer = '',
        sortFieldPointer = ''
    } = cascade ?? {}

    const fieldType = dataSource?.schema?.[fieldPointer]?.type

    const isDepartment = fieldType === 'department'
        const isParentDepartment = fieldType === 'parentDepartment'

    const [value, setValue] = useState(data)
    const [path, setPath] = useState('')
    const [opened, setOpened] = useState(false)

    useUpdateEffect(() => {
        setValue(data)
    }, [data])

    const { value: options, retry } = useAsyncRetry(async () => {
        const data = await onFetchCascadeOptions?.({
            fieldPointer: 'ID',
            showFieldPointer: 'DEPARTMENT_NAME',
            dsId: 'DEPARTMENT_DATASOURCE',
            sortFieldPointer,
            filter,
            disableId: isParentDepartment ?  record?.content?.['ID'].value?.toString() : '',
            parentFieldPointer: 'PARENT_DEPARTMENT'
        })
        return data ?? []
    }, [cascadeFieldPointer, cascadePointer, sortFieldPointer, filter, isParentDepartment, parentFieldPointer, onFetchCascadeOptions])

    const { pageTarget } = useApplicationContext()

    const flatOptions = useMemo(() => {
        function getChildOption(option: CascadeOption, list: CascadeOption[]) {
            const { value, label, path, labelPath, children = [] } = option
            let arrList = list
            for (const child of children) {
                const newChild = {
                    ...child,
                    path: `${path}/${child.value}`,
                    labelPath: `${labelPath}/${child.label}`,
                    isLast: (child.children ?? []).length === 0
                }
                arrList = [...arrList, newChild, ...getChildOption(newChild, [])]
            }
            return arrList
        }
        return (
            options?.reduce<CascadeOption[]>((pre, cur) => {
                const newOption = { ...cur, path: cur.value, labelPath: cur.label, isLast: (cur.children ?? []).length === 0 }
                return [...pre, newOption, ...getChildOption(newOption, [])]
            }, []) ?? []
        )
    }, [options])

    const tagList = useMemo(() => value.map(v => flatOptions.find(option => option.value === v)), [flatOptions, value])

    const handleChange = useCallback(
        (v: CascadeValue) => {
            setValue(v)
            onCellChange?.({ type: 'cascade', value: v })
            isLastLevel && setOpened(false)
            setPath('')
        },
        [isLastLevel, onCellChange]
    )

    const onPathChange = useCallback(
        (data: CascadeOption) => {
            setPath(showPath ? data.labelPath ?? '' : '')
        },
        [showPath]
    )
    const handleClear = useCallback(
        (id?: string) => {
            const newValue = id ? value.filter(v => v !== id) : []
            setValue(newValue)
            onCellChange?.({ type: 'cascade', value: newValue })
        },
        [onCellChange, value]
    )

    return (
        <>
            <SC.Container
                type="button"
                data-field-border={opened}
                onClick={() => {
                    if (!readOnly) {
                        setOpened(true)
                        retry()
                    }
                }}
            >
                <SC.Wrapper>
                    <SC.TagWrapper>
                        {tagList.map(v => (
                            <SC.Tag key={v?.value}>
                                <SC.TagLabel>{showPath ? v?.labelPath : v?.label}</SC.TagLabel>
                                <IconFont
                                    type="Close"
                                    color="var(--color-gray-400)"
                                    size={16}
                                    onClick={e => {
                                        e.stopPropagation()
                                        v?.value && handleClear(v?.value)
                                    }}
                                />
                            </SC.Tag>
                        ))}
                        {tagList.length === 0 && <SelectPlaceHolder label={placeholder} />}
                    </SC.TagWrapper>
                </SC.Wrapper>
                {!readOnly && (
                    <CM.IconWrapper>
                        {tagList.length > 0 && (
                            <IconFont
                                type="CloseCircle"
                                color="var(--color-gray-400)"
                                size={16}
                                onClick={e => {
                                    e.stopPropagation()
                                    handleClear()
                                }}
                            />
                        )}
                        <IconFont type="ArrowDownSmall" color="var(--color-gray-400)" size={16} />
                    </CM.IconWrapper>
                )}
            </SC.Container>
            <CascadeDrawer
                title={title}
                value={value}
                options={options}
                showPath={showPath}
                isMultiple={isDepartment}
                isLastLevel={isLastLevel}
                opened={opened}
                target={pageTarget}
                path={path}
                onPathChange={onPathChange}
                onFinish={handleChange}
                showFooterBtn
                onClose={() => {
                    setOpened(false)
                    setPath('')
                }}
            />
        </>
    )
}
