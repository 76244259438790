import type { TreeFlatOption, TreeOption } from "../types"

export const getTreeOptions = (list: TreeFlatOption[]) => {
    // 创建一个id到对象的映射
    const map = new Map()
    const arr: TreeOption[] = []
    // 初始化映射
    list.forEach(item => {
        map.set(item.value, {...item})
    })
    // 构建树
    list.forEach(item => {
        if (item.parentId?.[0]) {
            const parent = map.get(item.parentId[0])
            if (parent) {
                if (parent.children) {
                    parent.children.push(map.get(item.value))
                }
                if (!parent.children) {
                    {
                        parent.children = [map.get(item.value)]
                    }
                }
            }
            return
        }
        arr.push(map.get(item.value))
    })

    return arr
}
