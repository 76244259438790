import { Center } from '@mantine/core'
import React from 'react'

import type { FileItemProps } from '../types'

export const AudioFile = ({ file, ...rest }: FileItemProps) => {
    return (
        <Center sx={{ height: '100%' }} {...rest}>
            <audio src={file.url} controls controlsList="nodownload noplaybackrate" preload="metadata">
                您的浏览器版本较低，不支持该功能，请升级至最新版本
            </audio>
        </Center>
    )
}
