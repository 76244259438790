import { IconFont, MobileModal } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { AnimationItem } from 'lottie-web'
import lottie from 'lottie-web'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { PAGE_CONTAINER_HOST } from '../../constants'
import { checkIsInPwa, getBrandInfo } from '../../utils'
import { DownloadApp } from './DownloadApp'

interface PageLogoProps {
    isMobile?: boolean
    defaultEnd?: boolean
    style?: React.CSSProperties
    mobileBottom?: number
    isHideLogo?: boolean
    isPublishApp?: boolean
    appTitle?: string
    appLogo?: string
    appDescription?: string
    onEnd?: () => void
}

const SCxContainer = styled.div`
    position: absolute;
    bottom: 16px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const SCxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 116px;
    height: 32px;
    border-radius: 6px;
    background-color: var(--color-black);
    color: var(--color-gray-500);
    font-size: var(--font-size-sm);
    transition: all 0.4s ease-in-out;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        background-color: var(--color-white);
        box-shadow: 0px 4px 12px 0px rgba(16, 24, 40, 0.1);
    }
`

const SCxAnimationContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 116px;
    height: 32px;
    border-radius: 6px;
    overflow: hidden;
`

const SCxAnimation = styled.div`
    position: absolute;
    bottom: -20px;
    width: 120px;
    height: 72px;
    z-index: 0;
`

const SCxLogoImg = styled.img`
    position: relative;
    width: 120px;
    height: 72px;
    z-index: 1;
`

const SCxDownload = styled.div`
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    padding: 6px;
    border-radius: 100px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    box-shadow: 0px 4px 12px 0px #1018281a;
    cursor: pointer;
`

export const PageLogo: React.FunctionComponent<PageLogoProps> = ({
    isMobile,
    isHideLogo,
    isPublishApp,
    appTitle,
    appLogo,
    appDescription,
    defaultEnd = false,
    mobileBottom = 70,
    style,
    onEnd
}) => {
    const [isEnd, setIsEnd] = useState(defaultEnd)

    const [opened, setOpened] = useState(false)

    const [isHover, setIsHover] = useState(false)

    const targetRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (isEnd) {
            return
        }
        const timer = setTimeout(() => {
            onEnd?.()
            setIsEnd(true)
        }, 1500)
        return () => {
            clearTimeout(timer)
        }
    }, [isEnd, onEnd])

    const isHidePageLogoBadge = isHideLogo || !getBrandInfo().isLogo
    const isInPwa = checkIsInPwa()

    const animationRef = useRef<HTMLDivElement>(null)
    const lottieAnimationRef = useRef<AnimationItem | null>(null)

    useLayoutEffect(() => {
        const el = animationRef.current
        if (!el || isEnd) {
            return
        }
        lottieAnimationRef.current = lottie.loadAnimation({
            container: el,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: getAssetUrl('lottie', 'pageLogo.json')
        })
    }, [isEnd])

    useEffect(() => {
        return () => {
            lottieAnimationRef.current?.destroy()
        }
    }, [])

    return (
        <>
            <SCxContainer style={{ ...style, left: 16 }}>
                {!isHidePageLogoBadge && (
                    <SCxWrapper
                        style={{ padding: isEnd ? '6px 12px' : '0 5px' }}
                        onClick={() => window.open('https://www.byecode.com')}
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                    >
                        <SCxAnimationContainer>
                            {isEnd && (
                                <SCxLogoImg src={getAssetUrl('common', isHover ? 'font_logo_hover.svg' : 'font_logo_default.svg')} alt="" />
                            )}
                            <SCxAnimation ref={animationRef} />
                        </SCxAnimationContainer>
                    </SCxWrapper>
                )}
            </SCxContainer>
            <SCxContainer style={{ ...style, right: 16 }}>
                {isMobile && isPublishApp && !isInPwa && (
                    <>
                        <SCxDownload ref={targetRef} onClick={() => setOpened(true)}>
                            <IconFont type="Import" size={20} color="var(--color-gray-500)" />
                        </SCxDownload>
                        <MobileModal
                            data-ignore-click-away
                            target={document.querySelector<HTMLElement>(PAGE_CONTAINER_HOST)}
                            title={`将“${appTitle}”下载为 App`}
                            open={opened}
                            styles={{
                                modal: {
                                    height: '80%'
                                },
                                body: {
                                    backgroundColor: 'var(--color-gray-100)'
                                },
                                title: {
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    flex: 1
                                }
                            }}
                            onClose={() => setOpened(false)}
                        >
                            <DownloadApp appTitle={appTitle} appLogo={appLogo} appDescription={appDescription} />
                        </MobileModal>
                    </>
                )}
            </SCxContainer>
        </>
    )
}
