import type { DataSourceAbstract, SystemVariableValue, VariableADTvalue } from '@lighthouse/core'
import { type RecordLikeProtocol, matchFieldPI, VariableType } from '@lighthouse/core'
import type { FieldConvertValueOption } from '@lighthouse/shared'
import {
    getWindowUrl,
    parseField2TextValue,
    parseVariableDate,
    useAtomAction,
    useAtomData,
    USER_DATASOURCE,
    variableFieldConvertValue
} from '@lighthouse/shared'
import { addDays, lightFormat, subDays } from 'date-fns'
import { useAtomCallback } from 'jotai/utils'
import { useCallback } from 'react'
import useSWRMutation from 'swr/mutation'

import { addRecordAtom, setDataSourceAtom } from '@/atoms/dataSource/action'
import { dataSourceAtomFamily, dataSourcePoolAtom } from '@/atoms/dataSource/state'
import * as srv from '@/services'

import { useCurrentAppId, useCurrentEnvId } from './useApplication'
import { useRecordList } from './useDataSource'
import { useUserRecord } from './useUserRecord'

type ParseVariableOptions = FieldConvertValueOption & { viewRecord?: RecordLikeProtocol }

/**
 * 记录相应的数据源请求记录
 * @param {string} [recordId] - 变量依赖的行id
 */
const reqRecord: Record<string, Record<string, boolean>> = {}

export const useVariableValueRender = (pageId: string, parentRecordId?: string, recordId?: string) => {
    const datasourceList = useAtomData(dataSourcePoolAtom)

    const currentAppId = useCurrentAppId()
    const currentEnvId = useCurrentEnvId()
    const records = useRecordList()

    const { run: addRecord } = useAtomAction(addRecordAtom)
    const { run: setDataSource } = useAtomAction(setDataSourceAtom)

    const { trigger, isMutating } = useSWRMutation<RecordLikeProtocol | undefined, unknown, string, { appId: string; envId: string; dsId: string }>(
        `first-record`,
        async (_, { arg }) => {
            const { appId, envId, dsId } = arg
            const res = await srv.getDs({
                appId,
                envId,
                dsId,
                pageId,
                pagination: {
                    currentPage: 1,
                    pageSize: 1
                }
            })
            if (!res) {
                return
            }
            const { records, datasource } = res
            setDataSource({ appId, dsId, dataSource: { ...datasource, records: records.map(i => i.id) } })
            addRecord({ records })

            return records[0]
        }
    )

    /** 渲染字段值 */
    const getPageValue = useAtomCallback<string, ['parentPage' | 'page', string, string, FieldConvertValueOption]>(
        useCallback(
            (get, _, type, dsId, fieldId, option) => {
                if (isMutating) {
                    return ''
                }
                const ds = get(dataSourceAtomFamily({ appId: currentAppId, envId: currentEnvId, dsId }))
                const rId = type === 'page' ? recordId : parentRecordId

                if (!ds || !rId) {
                    return ''
                }

                const record = records.find(item => item.appId === currentAppId && item.dsId === dsId && item.id === rId)

                if (!record) {
                    if (!reqRecord[currentAppId]?.[dsId]) {
                        Reflect.set(reqRecord, currentAppId, { ...reqRecord[currentAppId], [dsId]: true })
                        trigger({ appId: currentAppId, envId: currentEnvId, dsId })
                    }
                    return ''
                }

                return parseField2TextValue(ds, fieldId, record, option)
            },
            [currentAppId, currentEnvId, isMutating, parentRecordId, recordId, records, trigger]
        )
    )

    const { record } = useUserRecord()

    /** 获取用户值 */
    const getUserValue = useCallback(
        (fieldId: string, option: ParseVariableOptions) => {
            if (!record) {
                return ''
            }

            const userDataSource = datasourceList.find(item => item.id === USER_DATASOURCE)
            if (!userDataSource) {
                return ''
            }

            return parseField2TextValue(userDataSource, fieldId, record, option)
        },
        [datasourceList, record]
    )

    /** 获取自定义视图的值 */
    const getCustomViewValue = useCallback((fieldId: string, dataSourceList: DataSourceAbstract[], option?: ParseVariableOptions) => {
        const viewRecord = option?.viewRecord
        if (!viewRecord) {
            return ''
        }
        const ds = dataSourceList.find(item => item.id === viewRecord.dsId)
        if (!ds) {
            return ''
        }

        return parseField2TextValue(ds, fieldId, viewRecord, option)
    }, [])

    const renderLabel = useCallback(
        (value: VariableADTvalue | undefined, option: ParseVariableOptions) => {
            if (!value) {
                return ''
            }
            return matchFieldPI(value)(
                {
                    [VariableType.SYSTEM]: v => {
                        const value = v.systemVariable?.value
                        if (!value) {
                            return ''
                        }
                        return getSystemVariableSingleValue(value, v.systemVariable?.format)
                    },
                    [VariableType.USER]: v => {
                        const fieldId = v.userVariable?.fieldId
                        if (!fieldId) {
                            return ''
                        }
                        return getUserValue(fieldId, { ...option, format: v.userVariable?.format })
                    },
                    [VariableType.PAGE]: v => {
                        const { type, dsId, fieldId } = v.pageVariable ?? {}
                        if (!type || !dsId || !fieldId) {
                            return ''
                        }
                        return getPageValue(type, dsId, fieldId, { ...option, format: v.pageVariable?.format })
                    },
                    [VariableType.VALUE]: v => {
                        if (v.valueVariable) {
                            return variableFieldConvertValue(v.valueVariable, option)
                        }
                        return ''
                    },
                    [VariableType.VIEW]: v => {
                        const fieldId = v.viewVariable?.fieldId
                        if (!fieldId) {
                            return ''
                        }
                        return getCustomViewValue(fieldId, datasourceList, { ...option, format: v.viewVariable?.format })
                    },
                    [VariableType.PAGE_LINK]: v => {
                        const value = v.pageLinkVariable?.value
                        if (!value) {
                            return ''
                        }
                        return getWindowUrl()
                    }
                },
                () => ''
            )
        },
        [datasourceList, getCustomViewValue, getPageValue, getUserValue]
    )

    return { renderLabel }
}

function getSystemVariableSingleValue(key: SystemVariableValue, format?: string): string {
    // eslint-disable-next-line unicorn/prefer-default-parameters
    const mergeFormat = format || 'yyyy-MM-dd'
    switch (key) {
        case 'NOW':
        case 'TODAY': {
            return parseVariableDate(new Date(), mergeFormat)
        }

        case 'YESTERDAY': {
            return parseVariableDate(subDays(new Date(), 1), mergeFormat)
        }

        case 'TOMORROW': {
            return parseVariableDate(addDays(new Date(), 1), mergeFormat)
        }

        // case 'LAST_WEEK': {
        //     return `${lightFormat(startOfWeek(subWeeks(new Date(), 1)), format)} ~ ${lightFormat(
        //         endOfWeek(subWeeks(new Date(), 1)),
        //         format
        //     )}`
        // }

        // case 'LAST_MONTH': {
        //     return getMonth(subMonths(new Date(), 1)).toString()
        // }

        // case 'THIS_WEEK': {
        //     return `${lightFormat(startOfWeek(new Date()), format)} ~ ${lightFormat(endOfWeek(new Date()), format)}`
        // }

        // case 'THIS_MONTH': {
        //     return getMonth(new Date()).toString()
        // }

        // case 'THIS_YEAR': {
        //     return getYear(new Date()).toString()
        // }

        default: {
            return ''
        }
    }
}
