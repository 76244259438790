import { mergeRefs } from '@lighthouse/tools'
import type { DropHandlerMethod, ShouldHandleDrag, ShouldRemoveDragOverMethod } from '@rpldy/upload-drop-zone'
import type { GetFileFromDragOptions } from 'html-dir-content'
import { getFilesFromDragEvent } from 'html-dir-content'
import React, { DetailedHTMLProps, forwardRef, useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import type { UseUploadFileSParameter } from '../../hooks'
import { useUploadFiles } from '../../hooks'
import type { UploadCapture } from '../../types'
import { useUploadManageContext } from '../../UploadManage.context'

const getShouldHandleDrag = (e: React.DragEvent<HTMLDivElement>, shouldHandle?: ShouldHandleDrag) =>
    shouldHandle === null ||
    shouldHandle === undefined ||
    shouldHandle === true ||
    (typeof shouldHandle === 'function' && shouldHandle(e.nativeEvent))

const isOnTarget = (e: React.DragEvent<HTMLDivElement>, containerElm?: Element | null, allowContains?: boolean) => {
    return e.target === containerElm || (allowContains && containerElm?.contains(e.target as Element))
}

export interface UploadDropZoneProps extends React.ComponentPropsWithoutRef<'div'> {
    accept?: string
    multiple?: boolean
    capture?: UploadCapture

    uploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    onDragOverClassName?: string
    dropHandler?: DropHandlerMethod
    htmlDirContentParams?: GetFileFromDragOptions
    shouldRemoveDragOver?: ShouldRemoveDragOverMethod
    shouldHandleDrag?: ShouldHandleDrag
    enableOnContains?: boolean
    disabled?: boolean
    disabledOpacity?: number
}

const SCxContainer = styled.div<{ disabled?: boolean; disabledOpacity?: number }>`
    &[disabled] {
        opacity: ${({ disabledOpacity }) => disabledOpacity};
    }
`

export const UploadDropZone = forwardRef<HTMLDivElement, UploadDropZoneProps>((props, ref) => {
    const {
        accept,
        multiple,
        uploadOptions,
        onDragOverClassName,
        disabled,
        capture,
        disabledOpacity = 0.6,
        dropHandler: propDropHandler,
        htmlDirContentParams,
        shouldRemoveDragOver,
        shouldHandleDrag,
        enableOnContains,
        onClick,
        ...rest
    } = props

    const upload = useUploadFiles()
    const uploady = useUploadManageContext()

    const optionsRef = useRef<Pick<UseUploadFileSParameter, 'info' | 'options'>>(uploadOptions)
    optionsRef.current = uploadOptions

    const containerRef = useRef<HTMLDivElement | null>(null)
    const inputRef = useRef<HTMLInputElement | null>(null)

    const clickHandler = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (disabled) {
                return
            }
            // uploady.showFileUpload({ autoUpload: false })
            inputRef.current?.click()
            onClick?.(e)
        },
        [disabled, onClick]
    )

    useEffect(() => {
        const input = inputRef.current

        if (!input) {
            return
        }

        function inputChange(e: Event) {
            const { files } = e.target as HTMLInputElement
            if (!files) {
                return
            }
            upload({ files: [...files], ...optionsRef.current })
            if (e.target) {
                ;(e.target as HTMLInputElement).value = ''
            }
        }

        input.addEventListener('change', inputChange)

        return () => {
            input.removeEventListener('change', inputChange)
        }
    }, [upload, uploady])

    const handleEnd = useCallback(() => {
        if (containerRef.current && onDragOverClassName) {
            containerRef.current.classList.remove(onDragOverClassName)
        }
    }, [onDragOverClassName, containerRef])

    const handleDropFile = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            const getFiles = () => getFilesFromDragEvent(e, htmlDirContentParams ?? true)

            return propDropHandler ? Promise.resolve(propDropHandler(e.nativeEvent, getFiles)) : getFiles()
        },
        [htmlDirContentParams, propDropHandler]
    )

    const handleDropUpload = useCallback(
        async (e: React.DragEvent<HTMLDivElement>) => {
            const files = (await handleDropFile(e)) as FileList
            upload({ files: [...files], ...optionsRef.current })
        },
        [handleDropFile, upload]
    )

    const dragEnterHandler = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            const isHandling = getShouldHandleDrag(e, shouldHandleDrag) && isOnTarget(e, containerRef.current, enableOnContains)

            if (isHandling && containerRef.current && onDragOverClassName) {
                containerRef.current.classList.add(onDragOverClassName)
            }
        },
        [enableOnContains, onDragOverClassName, shouldHandleDrag]
    )

    const dragOverHandler = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
    }, [])

    const dragLeaveHandler = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            if (e.target === containerRef.current || shouldRemoveDragOver?.(e.nativeEvent)) {
                handleEnd()
            }
        },
        [handleEnd, shouldRemoveDragOver]
    )

    const dragEndHandler = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault()
            e.stopPropagation()
            handleEnd()
        },
        [handleEnd]
    )

    const dropHandler = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            if (disabled) {
                return
            }
            e.preventDefault()
            e.persist()

            handleDropUpload(e)
        },
        [handleDropUpload, disabled]
    )

    return (
        <>
            <SCxContainer
                ref={mergeRefs([ref, containerRef])}
                {...rest}
                onClick={clickHandler}
                onDragOver={dragOverHandler}
                onDragEnter={dragEnterHandler}
                onDrop={dropHandler}
                onDragLeave={dragLeaveHandler}
                onDragEnd={dragEndHandler}
                disabled={disabled}
                disabledOpacity={disabledOpacity}
            />
            <input ref={inputRef} style={{ display: 'none' }} type="file" accept={accept} capture={capture} multiple={multiple} />
        </>
    )
})
