import { Toast } from '@byecode/ui'

import { getFileSizeToMB } from '../utils'

export const avatarMaxFileSize = 1024 * 1024 * 5

export const logoMaxUploadSize = 1024 * 1024 * 2

export const videoMaxUploadSize = 1024 * 1024 * 300

export const fileMaxUploadSize = 1024 * 1024 * 300

export const aliyunVideoProtocolList = ['mp4']

export const fieldFileFilter = (file: string | File, index: number) => {
    if (file instanceof File && file.size > fileMaxUploadSize) {
        Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
        return false
    }
    return true
}
