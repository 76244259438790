import { Empty, Modal } from '@byecode/ui'
import { nanoid } from '@lighthouse/tools'
import { animated, useTransition } from '@react-spring/web'
import type { BatchItem, UploadOptions } from '@rpldy/uploady'
import {
    FILE_STATES,
    useAbortAll,
    useBatchErrorListener,
    useBatchFinishListener,
    useBatchProgressListener,
    useBatchStartListener,
    useItemAbortListener,
    useItemCancelListener,
    useItemErrorListener,
    useItemFinishListener,
    useItemProgressListener,
    useItemStartListener,
    useUploady
} from '@rpldy/uploady'
import { clone } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLatest } from 'react-use'
import { useImmer } from 'use-immer'

import type { PreviewFile } from '../FilePreviewer'
import type { UseUploadFileSParameter } from './hooks'
import { uploadManageEvents } from './hooks/events'
import { UploadList } from './UploadList'
import { defaultFileFilter } from './UploadManage.context'
import * as SC from './UploadManage.style'

function getInitBatchItem(file: File): BatchItem {
    return {
        completed: 0,
        id: nanoid(),
        file,
        loaded: 0,
        state: FILE_STATES.ADDED,
        url: '',
        batchId: nanoid(),
        uploadStatus: 0,
        recycled: false
    }
}

enum TabValue {
    Progress,
    Complete
}

export const UploadManage = () => {
    const [opened, setOpened] = useState(false)
    const [collapsed, setCollapsed] = useState(true)
    const [activeTab, setActiveTab] = useState(TabValue.Progress)

    const uploady = useUploady()

    const abortAll = useAbortAll()

    const [groupItems, setGroupItems] = useImmer(
        () => new Map<string, { groupId: string; label: string; options?: UploadOptions; items: BatchItem[] }>()
    )

    useEffect(() => {
        // 外部调用上传方法
        function uploadEventsHandler({ info, files, options, inputRef }: UseUploadFileSParameter) {
            if (inputRef?.current) {
                uploady.setExternalFileInput(inputRef)
            }

            const formattedFiles = files.filter(options?.fileFilter ?? defaultFileFilter).map(getInitBatchItem)

            if (formattedFiles.length === 0) {
                return
            }

            setGroupItems(draft => {
                if (draft.size === 0) {
                    setCollapsed(false)
                }
                if (draft.has(info.id)) {
                    draft.set(info.id, {
                        groupId: info.groupId,
                        label: info.label,
                        options: clone(options),
                        items: [...(draft.get(info.id)?.items ?? []), ...formattedFiles]
                    })
                } else {
                    draft.set(info.id, {
                        groupId: info.groupId,
                        label: info.label,
                        options: clone(options),
                        items: formattedFiles
                    })
                }
            })

            uploady.upload(files, options)

            setOpened(true)
        }

        uploadManageEvents.on('upload', uploadEventsHandler)

        return () => {
            uploadManageEvents.off('upload', uploadEventsHandler)
        }
    }, [setGroupItems, uploady])

    // 解构参数为了防止immer对象引用
    useItemStartListener(({ file, ...batch }) => {
        let scopeId = ''

        setGroupItems(draft => {
            draft.forEach((d, k) => {
                const i = d.items.findIndex(item => item.file === file)
                if (i !== -1) {
                    scopeId = k
                    d.items[i] = { ...batch, file }
                }
            })
        })

        if (scopeId) {
            uploadManageEvents.emit('item-uploading', { batchItem: { file, ...batch }, id: scopeId })
        }
    })
    useItemCancelListener(({ file, ...batch }) => {
        setGroupItems(draft => {
            draft.forEach(d => {
                const i = d.items.findIndex(item => item.file === file)
                if (i !== -1) {
                    d.items[i] = { ...batch, file }
                }
            })
        })
    })
    useItemAbortListener(({ file, ...batch }) => {
        setGroupItems(draft => {
            draft.forEach(d => {
                const i = d.items.findIndex(item => item.file === file)
                if (i !== -1) {
                    d.items[i] = { ...batch, file }
                }
            })
        })
    })
    useItemProgressListener(({ file, ...batch }) => {
        setGroupItems(draft => {
            draft.forEach(d => {
                const i = d.items.findIndex(item => item.file === file)
                if (i !== -1) {
                    d.items[i] = { ...batch, file }
                }
            })
        })
    })
    useItemErrorListener(({ file, ...batch }) => {
        // const defaultMessage = '请在文件上传器中检查。'
        // Message.warning({
        //     title: '有文件上传失败',
        //     description: typeof batch.uploadResponse === 'string' ? batch.uploadResponse : defaultMessage
        // })
        setGroupItems(draft => {
            let scopeId = ''

            draft.forEach((d, k) => {
                const i = d.items.findIndex(item => item.file === file)
                if (i !== -1) {
                    scopeId = k
                    d.items[i] = { ...batch, file }
                }
            })

            if (scopeId) {
                uploadManageEvents.emit('item-error', { batchItem: { file, ...batch }, id: scopeId })
            }
        })
    })
    useItemFinishListener(({ file, ...batch }) => {
        setGroupItems(draft => {
            let scopeId = ''
            draft.forEach((d, k) => {
                const i = d.items.findIndex(item => item.file === file)
                if (i !== -1) {
                    scopeId = k
                    d.items[i] = { ...batch, file }
                }
            })

            if (scopeId) {
                uploadManageEvents.emit('item-finished', { batchItem: { file, ...batch }, id: scopeId })
            }
        })
    })

    // 为了触发batch级别的回调而增加的
    useBatchStartListener(batch => {
        const groupEntry = [...groupItems.entries()].find(([, item]) => item.items.some(i => batch.items.some(k => k.file === i.file)))
        if (!groupEntry) {
            return
        }

        const [scopeId] = groupEntry
        uploadManageEvents.emit('batch-uploading', { batch, id: scopeId })
    })

    useBatchProgressListener(batch => {
        const groupEntry = [...groupItems.entries()].find(([, item]) => item.items.some(i => batch.items.some(k => k.file === i.file)))
        if (!groupEntry) {
            return
        }
        const [scopeId] = groupEntry
        uploadManageEvents.emit('batch-progress', { batch, id: scopeId })
    })

    useBatchFinishListener(batch => {
        const groupEntry = [...groupItems.entries()].find(([, item]) => item.items.some(i => batch.items.some(k => k.file === i.file)))
        if (!groupEntry) {
            return
        }

        const [scopeId] = groupEntry
        uploadManageEvents.emit('batch-finished', { batch, id: scopeId })
    })
    useBatchErrorListener(batch => {
        const groupEntry = [...groupItems.entries()].find(([, item]) => item.items.some(i => batch.items.some(k => k.file === i.file)))
        if (!groupEntry) {
            return
        }

        const [scopeId] = groupEntry
        uploadManageEvents.emit('batch-error', { batch, id: scopeId })
    })


    /**
     * 删除记录
     * @date 8/18/2023 - 11:11:12 AM
     *
     * @type {*}
     */
    const onDeleteItem = useCallback(
        (scopeId: string, id: string) => {
            setGroupItems(draft => {
                const items = draft.get(scopeId)?.items ?? []
                const i = items.findIndex(item => item.id === id)
                if (i !== -1) {
                    if (items.length === 1) {
                        draft.delete(scopeId)
                        return
                    }
                    items.splice(i, 1)
                }
            })
        },
        [setGroupItems]
    )

    /**
     * 关闭并中止所有上传
     * @date 8/18/2023 - 11:11:32 AM
     */
    const handleClose = async () => {
        if (
            progressList.some(group =>
                group.items.some(item => [FILE_STATES.ADDED, FILE_STATES.PENDING, FILE_STATES.UPLOADING].includes(item.state))
            )
        ) {
            const confirmed = await Modal.confirm({
                title: '确认关闭',
                content: '关闭后上传也将被中断。',
                okStatus: 'error',
                okText: '关闭'
            })
            if (!confirmed) {
                return
            }

            abortAll()
        }

        setGroupItems(draft => draft.clear())
        setOpened(false)
    }

    const successCount = useMemo(() => {
        return [...groupItems.entries()].reduce((total, curr) => {
            return total + curr[1].items.filter(item => item.state === FILE_STATES.FINISHED).length
        }, 0)
    }, [groupItems])

    const progressList = useMemo(() => {
        const status = new Set([FILE_STATES.ADDED, FILE_STATES.PENDING, FILE_STATES.UPLOADING, FILE_STATES.ABORTED])
        const list: { groupId: string; scopeId: string; label: string; options?: UploadOptions; items: BatchItem[] }[] = []
        groupItems.forEach((group, key) => {
            const items = group.items.filter(item => status.has(item.state))
            if (items.length > 0) {
                const index = list.findIndex(item => item.groupId === group.groupId)
                if (index === -1) {
                    list.push({ groupId: group.groupId, scopeId: key, label: group.label, options: group.options, items })
                } else {
                    list[index].items.push(...group.items)
                }
            }
        })

        return list
    }, [groupItems])

    const completeList = useMemo(() => {
        const status = new Set([FILE_STATES.ERROR, FILE_STATES.FINISHED])
        const list: { groupId: string; scopeId: string; label: string; options?: UploadOptions; items: BatchItem[] }[] = []
        groupItems.forEach((group, key) => {
            const items = group.items.filter(item => status.has(item.state))
            if (items.length > 0) {
                const index = list.findIndex(item => item.groupId === group.groupId)
                if (index === -1) {
                    list.push({ groupId: group.groupId, scopeId: key, label: group.label, options: group.options, items })
                } else {
                    list[index].items.push(...group.items)
                }
            }
        })

        return list
    }, [groupItems])

    const [previewOpened, setPreviewOpened] = useState(false)
    const [previewList, setPreviewList] = useState<PreviewFile[]>([])
    const [previewIndex, setPreviewIndex] = useState(0)
    const latestPreviewList = useLatest(previewList)
    const onPreview = useCallback(
        (id: string) => {
            const list = activeTab === TabValue.Progress ? progressList : completeList
            const itemList = list.reduce<BatchItem[]>(
                (prev, curr) => [...prev, ...curr.items.filter(item => item.file.type.includes('image'))],
                []
            )
            const index = itemList.findIndex(item => item.id === id)

            if (index === -1) {
                return
            }

            const fileList = itemList.map(item => ({
                type: 'image',
                name: item.file.name,
                url: URL.createObjectURL(item.file as File)
            }))

            setPreviewOpened(true)
            setPreviewIndex(index)
            setPreviewList(fileList)
        },
        [activeTab, completeList, progressList]
    )

    useEffect(() => {
        const list = latestPreviewList.current

        return () => {
            list.forEach(item => {
                URL.revokeObjectURL(item.url)
            })
        }
    }, [latestPreviewList])

    const transitions = useTransition(activeTab, {
        from: {
            opacity: 0
        },
        enter: { opacity: 1 },
        leave: false,
        config: {
            duration: 150
        }
    })

    const headerDom = useMemo(() => {
        if (collapsed) {
            return <SC.CollapseHeader>{successCount}个文件上传成功 </SC.CollapseHeader>
        }

        return transitions((styles, tab) => {
            const list = tab === TabValue.Progress ? progressList : completeList

            return (
                <animated.div style={{ ...styles, overflowY: 'auto' }}>
                    {list.length === 0 ? (
                        <Empty description="暂无内容" styles={{ root: { padding: 20 } }} />
                    ) : (
                        list.map(item => (
                            <UploadList
                                key={item.groupId}
                                label={item.label}
                                options={item.options}
                                items={item.items}
                                onDeleteItem={id => onDeleteItem(item.scopeId, id)}
                                onPreview={id => onPreview(id)}
                            />
                        ))
                    )}
                </animated.div>
            )
        })
    }, [collapsed, transitions, successCount, progressList, completeList, onPreview, onDeleteItem])

    const tabs = useMemo(() => {
        return [
            {
                label: '进行中',
                value: TabValue.Progress,
                count: progressList.reduce((total, curr) => total + curr.items.length, 0)
            },
            {
                label: '已完成',
                value: TabValue.Complete,
                count: completeList.reduce((total, curr) => total + curr.items.length, 0)
            }
        ]
    }, [completeList, progressList])

    return null

    // return opened ? (
    //     <SC.Root>
    //         {headerDom}
    //         <SC.MainArea data-collapse={collapsed}>
    //             <SC.MainTabs>
    //                 {tabs.map(tab => (
    //                     <SC.MainTab
    //                         key={tab.value}
    //                         data-active={activeTab === tab.value}
    //                         onClick={() => {
    //                             setActiveTab(tab.value)
    //                         }}
    //                     >
    //                         <SC.MainTabLabel>{tab.label}</SC.MainTabLabel>
    //                         <SC.MainTabBadge>{tab.count}</SC.MainTabBadge>
    //                     </SC.MainTab>
    //                 ))}
    //             </SC.MainTabs>

    //             <SC.MainActions>
    //                 <Tooltip title={collapsed ? '展开' : '最小化 '}>
    //                     <Button
    //                         type="text"
    //                         onClick={() => setCollapsed(s => !s)}
    //                         icon={<IconFont type={collapsed ? 'Open' : 'BlockLine'} />}
    //                     />
    //                 </Tooltip>
    //                 <Tooltip title="全部取消">
    //                     <Button type="text" onClick={handleClose} icon={<IconFont type="Close" />} />
    //                 </Tooltip>
    //             </SC.MainActions>
    //         </SC.MainArea>
    //         <FilePreviewer
    //             opened={previewOpened}
    //             onClose={() => {
    //                 setPreviewOpened(false)
    //                 previewList.forEach(item => {
    //                     URL.revokeObjectURL(item.url)
    //                 })
    //                 setPreviewList([])
    //             }}
    //             fileList={previewList}
    //             defaultIndex={previewIndex}
    //         />
    //     </SC.Root>
    // ) : null
}
