import { MobileModal } from '@byecode/ui'
import type { QuickFilterData } from '@lighthouse/core'
import { type FilterCommonCondition, type SchemaProtocol, type ViewFieldProps, VariableType } from '@lighthouse/core'
import produce from 'immer'
import { find, findIndex } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'

import { useApplicationContext } from '../../contexts'
import { getVariableFieldId } from '../../utils'
import { useAppThemeContext } from '../ApplicationContainer'
import { FilterItem } from './FilterItem'
import * as SC from './styles'

interface QuickFilterDrawerProps {
    columns: ViewFieldProps[]
    quickFilter?: QuickFilterData
    schema: SchemaProtocol['schema']
    onFilter?: (val: FilterCommonCondition[]) => void
}

export const QuickFilterDrawer: React.FC<QuickFilterDrawerProps> = ({ columns, quickFilter, schema, onFilter }) => {
    const [opened, setOpened] = useState(false)
    const theme = useAppThemeContext()
    const { personOptions, pageTarget } = useApplicationContext()
    const rules = useMemo(() => {
        const list = quickFilter?.rules || []
        return list.filter(item => find(col => col.fieldId === getVariableFieldId(item.idVariable), columns))
    }, [columns, quickFilter?.rules])

    const persons = useMemo(
        () => [{ userId: '{currentUserId}', uniqueUserId: '{currentUserId}', username: '当前用户', mobile: '' }, ...personOptions],
        [personOptions]
    )

    const handleChange = useCallback(
        (itemData: FilterCommonCondition) => {
            const newRules = produce(rules, draft => {
                const index = findIndex(item => getVariableFieldId(item.idVariable) === getVariableFieldId(itemData.idVariable), draft)
                draft[index] = itemData
            })
            onFilter?.(newRules)
        },
        [onFilter, rules]
    )

    const isActive = useMemo(
        () => quickFilter?.rules.some(rule => rule.paramList?.some(item => item.type === VariableType.VALUE && item?.valueVariable?.value)),
        [quickFilter?.rules]
    )

    return (
        <SC.Container>
            <SC.QuickFilterDrawerWrapper onClick={() => setOpened(true)} tintMainColor={theme.tintMainColor}>
                <SC.FilterDrawerIcon fill={isActive ? theme.mainColor : ''} type="Filter" />
            </SC.QuickFilterDrawerWrapper>
            <MobileModal target={pageTarget} title="设置筛选条件" open={opened} onClose={() => setOpened(false)}>
                <SC.Title color="var(--color-gray-500)">符合以下所有条件</SC.Title>
                <SC.List>
                    {rules.map(item => (
                        <FilterItem
                            target={pageTarget}
                            key={getVariableFieldId(item.idVariable)}
                            columns={columns}
                            personOption={persons}
                            filterItem={item}
                            schema={schema}
                            onChange={handleChange}
                        />
                    ))}
                </SC.List>
            </MobileModal>
        </SC.Container>
    )
}
