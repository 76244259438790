import type { PaginationProtocol } from '@lighthouse/core'
import { BreakPointSize } from '@lighthouse/tools'
import { Pagination } from '@mantine/core'
import { divide, max } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled, { css } from 'styled-components'

import { useRegisterBlockListener } from '../../utils'

export * from './PageSizeList'

const TablePagination = styled.div<{ width?: number; top?: number; isMobile: boolean; enablePadding?: boolean }>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    margin-top: ${({ top }) => (top ? `${top}px` : '0px')};
    padding: ${({ enablePadding }) => (enablePadding ? '0 5px' : 'unset')};

    display: flex;
    ${props =>
        props.isMobile
            ? css`
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: center;
                  height: 60px;
              `
            : css`
                  height: 40px;
                  align-items: center;
                  justify-content: space-between;
              `};
`

const TablePageCount = styled.div`
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    color: var(--color-gray-400);
    :hover {
        color: var(--color-black);
    }
`

export const TablePageContent = styled.div<{ color?: string; background?: string }>`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    & button {
        border-color: var(--color-gray-200);
        color: var(--color-black);
    }

    & button[tabindex] {
        border: none;
        :hover {
            background-color: ${({ background }) => background || 'none'};
            color: ${({ color }) => color || 'none'};
        }
    }

    & button[data-active] {
        background-color: ${({ background }) => background || 'none'};
        color: ${({ color }) => color || 'none'};
    }
`

interface PaginationProps {
    viewId?: string
    pagination: PaginationProtocol['pagination']
    width?: number
    activeStyle?: {
        background?: string
        color?: string
    }
    top?: number
    breakPoint?: BreakPointSize
    enablePadding?: boolean
    enablePageSize?: boolean
    onChangePageNum?: (val: number) => void
    onChangePageSize?: (val: number, custom?: boolean) => void
}

export const PaginationFc: React.FC<PaginationProps> = ({
    viewId,
    pagination,
    width,
    activeStyle,
    top,
    breakPoint,
    enablePadding,
    enablePageSize = false,
    onChangePageNum,
    onChangePageSize
}) => {
    const { currentPage = 1, pageSize = 10, rowTotal, custom } = pagination
    const isMobile = breakPoint === BreakPointSize.sm || breakPoint === BreakPointSize.xs
    const pageTotal = useMemo(() => (rowTotal ? max(Math.ceil(divide(rowTotal, pageSize)), 1) : 1), [rowTotal, pageSize])
    const [num, setNum] = useState(currentPage)

    useUpdateEffect(() => {
        if (currentPage !== num) {
            setNum(currentPage)
        }
    }, [currentPage])

    const handleChangePageNum = useCallback(
        (val: number) => {
            if (currentPage === val) {
                return
            }
            setNum(val)
            onChangePageNum?.(val)
        },
        [currentPage, onChangePageNum]
    )

    useRegisterBlockListener(viewId, 'view', {
        paginateTo: payload => {
            const paginateToType = payload?.paginateToType

            if (!paginateToType) {
                return
            }

            const step = paginateToType === 'next' ? 1 : -1

            const newPage = currentPage + step
            if (newPage < 1 || newPage > pageTotal) {
                return
            }

            handleChangePageNum(newPage)
        }
    })

    // const debouncedTitle = useDebounce(num, 500)

    // useUpdateEffect(() => {
    //     if (debouncedTitle !== currentPage) {
    //         onChangePageNum?.(debouncedTitle)
    //     }
    // }, [debouncedTitle])

    // const handleChangePageSize = useCallback(
    //     (val: number, custom?: boolean) => {
    //         if (pageSize === val) {
    //             return
    //         }
    //         onChangePageSize?.(val, custom)
    //     },
    //     [onChangePageSize, pageSize]
    // )

    return (
        <TablePagination width={width} top={top} isMobile={isMobile} enablePadding={enablePadding}>
            <TablePageCount>{rowTotal ? `共${String(rowTotal)}行` : ''}</TablePageCount>
            <TablePageContent color={activeStyle?.color} background={activeStyle?.background}>
                <Pagination total={pageTotal} radius={8} page={num} onChange={handleChangePageNum} size={breakPoint} />
                {/* {enablePageSize && <PageSize size={pageSize} custom={custom} onChangePageSize={handleChangePageSize} />} */}
            </TablePageContent>
        </TablePagination>
    )
}
