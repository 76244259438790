import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import type { StyleComponentProps } from '@byecode/ui/theme/types'
import { getDate, getDaysInMonth, min } from 'date-fns'
import { max } from 'rambda'
import React, { forwardRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useEffectOnce, useLatest } from 'react-use'

import type { PickerViewStylesName } from '../../PickerView'
import { PickerView } from '../../PickerView'

export interface MobileDatePickerViewProps extends StyleComponentProps<PickerViewStylesName> {
    value?: Date
    defaultValue?: Date
    showTime?: boolean
    disableSecond?: boolean
    maxDate?: Date
    minDate?: Date
    onChange?: (value: Date | undefined) => void
    onConfirm: (val: Date) => void
    onCancel: () => void
}

export const MobileDatePickerView = forwardRef<HTMLInputElement, MobileDatePickerViewProps>((props, ref) => {
    const {
        value,
        defaultValue,
        showTime = true,
        disableSecond,
        minDate,
        maxDate,
        onChange,
        onCancel,
        onConfirm,
        ...pickerViewProps
    } = props

    const [_value, _setValue] = useUncontrolled({
        value,
        defaultValue,
        onChange
    })

    const initIndexes = useMemo(() => {
        const _date = _value ?? new Date()

        if (minDate && maxDate) {
            const minYear = minDate && minDate.getFullYear()
            const maxYear = maxDate && maxDate.getFullYear()
            const minMonth = minDate && minDate.getMonth() + 1
            const maxMonth = maxDate && maxDate.getMonth() + 1
            const minDay = minDate && minDate.getDate()
            const maxDay = maxDate && maxDate.getDate()
            if (minDate && _date.getTime() < minDate?.getTime()) {
                return showTime ? [0, 0, 0, 0, 0, 0] : [0, 0, 0]
            }
            if (maxDate && _date.getTime() > maxDate?.getTime()) {
                const diffYear = maxYear - minYear
                const diffMonth = maxYear === minYear ? maxMonth - minMonth : maxMonth
                const diffDay = maxYear === minYear && maxMonth === minMonth ? maxDay - minDay : maxDay
                return showTime ? [diffYear, diffMonth, diffDay, 0, 0, 0] : [diffYear, diffMonth, diffDay]
            }
            return []
        }

        if (minDate && _date.getTime() < minDate?.getTime()) {
            return showTime ? [0, 0, 0, 0, 0, 0] : [0, 0, 0]
        }
        if (maxDate && _date.getTime() > maxDate?.getTime()) {
            const month = maxDate.getMonth() + 1
            const day = maxDate.getDate() - 1
            const hour = maxDate.getHours()
            const minute = maxDate.getMinutes()
            const second = maxDate.getSeconds()
            return showTime ? [100, month, day, hour, minute, second] : [100, month, day]
        }
        return []
    }, [_value, maxDate, minDate, showTime])

    const [indexes, setIndexes] = useState<number[]>(initIndexes)
    const [year, month] = indexes

    const yearData = useMemo(() => {
        const now = new Date()
        const minYear = minDate && minDate.getFullYear()
        const maxYear = maxDate && maxDate.getFullYear()

        if (minYear && maxYear) {
            return Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i)
        }

        if (minYear) {
            return Array.from({ length: 100 }, (_, i) => now.getFullYear() + i)
        }
        if (maxYear) {
            return Array.from({ length: 100 }, (_, i) => maxYear - 100 + i + 1)
        }

        return Array.from({ length: 200 }, (_, i) => now.getFullYear() - 100 + i)
    }, [maxDate, minDate])

    const monthData = useMemo(() => {
        const now = new Date()
        const minYear = minDate && minDate.getFullYear()
        const maxYear = maxDate && maxDate.getFullYear()
        const minMonth = minDate && minDate.getMonth() + 1
        const maxMonth = maxDate && maxDate.getMonth() + 1

        const selectedYear = typeof year === 'undefined' ? now.getFullYear() : yearData[year]
        if (minMonth && maxMonth) {
            if (selectedYear === minYear) {
                const months = selectedYear === minYear ? 12 - minMonth : 12
                return Array.from({ length: months }, (_, i) => (selectedYear === minYear ? i + minMonth : i + 1))
            }
            if (selectedYear === maxYear) {
                const months = selectedYear === maxYear ? maxMonth : 12
                return Array.from({ length: months }, (_, i) => i + 1)
            }
            return Array.from({ length: 12 }, (_, i) => i + 1)
        }
        if (minMonth) {
            const months = selectedYear === minYear ? 12 - minMonth : 12
            return Array.from({ length: months }, (_, i) => (selectedYear === minYear ? i + minMonth : i + 1))
        }
        if (maxMonth) {
            const months = selectedYear === maxYear ? maxMonth : 12
            return Array.from({ length: months }, (_, i) => i + 1)
        }
        return Array.from({ length: 12 }, (_, i) => i + 1)
    }, [maxDate, minDate, year, yearData])

    const dayData = useMemo(() => {
        const now = new Date()
        const minYear = minDate && minDate.getFullYear()
        const maxYear = maxDate && maxDate.getFullYear()
        const minMonth = minDate && minDate.getMonth() + 1
        const maxMonth = maxDate && maxDate.getMonth() + 1
        const minDay = minDate && getDate(minDate)
        const maxDay = maxDate && getDate(maxDate)

        const selectedYear = typeof year === 'undefined' ? now.getFullYear() : yearData[year]
        const selectedMonth = typeof month === 'undefined' ? now.getMonth() + 1 : monthData[month]

        const selectedDate = selectedMonth < 10 ? `${selectedYear}-0${selectedMonth}` : `${selectedYear}-${selectedMonth}`
        const days = getDaysInMonth(new Date(selectedDate))

        if (minDay && maxDay) {
            if (selectedMonth === minMonth && selectedYear === minYear) {
                const computesDays = days - minDay
                return Array.from({ length: computesDays }, (_, i) => i + minDay)
            }
            if (selectedMonth === maxMonth && selectedYear === maxYear) {
                const computesDays = maxDay - 1
                return Array.from({ length: computesDays }, (_, i) => i + 1)
            }
            return Array.from({ length: days }, (_, i) => i + 1)
        }

        if (minDay) {
            const computesDays = selectedYear === minYear && selectedMonth === minMonth ? days - minDay : days
            return Array.from({ length: computesDays }, (_, i) =>
                selectedYear === minYear && selectedMonth === minMonth ? i + minDay : i + 1
            )
        }
        if (maxDay) {
            const computesDays = selectedYear === maxYear && selectedMonth === maxMonth ? maxDay : days
            return Array.from({ length: computesDays }, (_, i) => i + 1)
        }
        return Array.from({ length: days }, (_, i) => i + 1)
    }, [maxDate, minDate, month, monthData, year, yearData])

    const data = useMemo(() => {
        if (showTime) {
            return [
                yearData,
                monthData,
                dayData,
                Array.from({ length: 24 }, (_, i) => i),
                Array.from({ length: 60 }, (_, i) => i),
                Array.from({ length: 60 }, (_, i) => i)
            ]
        }
        return [yearData, monthData, dayData]
    }, [dayData, monthData, showTime, yearData])

    useEffectOnce(() => {
        const _date = _value ?? new Date()
        const year = _date.getFullYear()
        const month = _date.getMonth() + 1
        const day = _date.getDate()
        const hour = _date.getHours()
        const minute = _date.getMinutes()
        const second = _date.getSeconds()
        const yearIndex = max(data[0].indexOf(year), 0)
        const monthIndex = max(data[1].indexOf(month), 0)
        const dayIndex = max(data[2].indexOf(day), 0)
        if (showTime) {
            const hourIndex = data[3].indexOf(hour)
            const minuteIndex = data[4].indexOf(minute)
            const secondIndex = data[5].indexOf(second)
            setIndexes([yearIndex, monthIndex, dayIndex, hourIndex, minuteIndex, secondIndex])
            return
        }
        setIndexes([yearIndex, monthIndex, dayIndex])
    })

    useLayoutEffect(() => {
        // 检测当前选择的月下的日期是否越界
        setIndexes(s => {
            return s.map((v, i) => (s[i] > data[i].length - 1 ? 0 : v))
        })
    }, [data, maxDate, minDate])

    return (
        <PickerView
            data={disableSecond ? data.slice(0, 5) : data}
            value={indexes}
            {...pickerViewProps}
            onChange={setIndexes}
            onConfirm={() => {
                const [y, M, D = 0, h, m, s] = indexes
                const now = new Date()
                const year = typeof y === 'undefined' ? now.getFullYear() : data[0][y]
                const month = typeof M === 'undefined' ? now.getMonth() + 1 : data[1][M]
                const day = typeof D === 'undefined' ? now.getDate() : data[2][D] ?? 0
                const hour = typeof h === 'undefined' ? now.getHours() : data[3][h]
                const minute = typeof m === 'undefined' ? now.getMinutes() : data[4][m]
                const second = typeof s === 'undefined' ? now.getSeconds() : data[5][s]
                const date = new Date(
                    `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hour
                        .toString()
                        .padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`
                )
                onConfirm(date)
            }}
            onCancel={onCancel}
        />
    )
})
