import { subtract } from 'rambda'
import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useImmer } from 'use-immer'

import type { SendCodeType } from '../components'

interface VerificationCodeBtnState {
    disable: boolean
    count: number
    startTime: number | null
    codeStep: 0 | 1 | 2
    codeText: string
}

export const useSmsSendCode = function (
    onGetCodeHandle?: (resolve: () => void, reject: () => void) => Promise<void> | void
) {
    const { t } = useTranslation()

    const [state, setState] = useImmer<VerificationCodeBtnState>({
        disable: false,
        startTime: null,
        count: 60,
        codeStep: 0,
        codeText: t('getVerificationCode')
    })

    const { disable, count, codeStep, codeText, startTime } = state

    useEffect(() => {
        const currentTime = Math.floor(Date.now() / 1000)
        if (startTime === null) {
            return
        }
        const lastCount = 60 - (currentTime - startTime)
        if (codeStep === 1) {
            setState(draft => {
                draft.count = 60
                draft.codeText = t('resendTime', { v: String(60) })
                draft.codeStep = 2
            })
        }
        if (lastCount > 0 && count > lastCount) {
            setState(draft => {
                draft.count = lastCount
                draft.codeText = t('resendTime', { v: lastCount.toString() })
                draft.codeStep = 2
            })
            return undefined
        }
        if (codeStep === 2) {
            if (count > 0) {
                const codeTimer = setTimeout(() => {
                    setState(draft => {
                        draft.codeText = t('resendTime', { v: String(count - 1) })
                        draft.count = count - 1
                    })
                }, 1000)
                return () => {
                    const dateValue = subtract(60, count)
                    const nowStr = String(Date.now()).slice(0, 10)
                    const now = subtract(Number(nowStr), dateValue)
                    clearTimeout(codeTimer)
                }
            }
            setState(draft => {
                draft.count = 60
                draft.codeText = t('getAgain')
                draft.codeStep = 0
            })
        }
        return undefined
    }, [codeStep, count, setState, startTime, t])

    useEffect(() => {
        setState(draft => {
            draft.count = 60
            draft.codeStep = 0
            draft.codeText = t('getVerificationCode')
        })
    }, [setState, t])

    const startCodeDown = useCallback(() => {
        setState(draft => {
            draft.codeStep = 1
            draft.disable = false
        })
    }, [setState])

    const errorCodeDown = useCallback(() => {
        setState(draft => {
            draft.disable = false
        })
    }, [setState])

    const reset = useCallback(() => {
        setState(draft => {
            draft.disable = false
            draft.count = 60
            draft.codeStep = 0
            draft.startTime = null
            draft.codeText = t('getVerificationCode')
        })
    }, [setState, t])

    /**
     * 获取验证码
     */
    const getPhoneCode = useCallback(() => {
        if (codeStep > 0) {
            return
        }
        setState(draft => {
            draft.disable = true
            draft.startTime = Math.floor(Date.now() / 1000)
        })
        onGetCodeHandle?.(startCodeDown, errorCodeDown)
    }, [codeStep, errorCodeDown, onGetCodeHandle, setState, startCodeDown])

    return { getPhoneCode, setState, reset, state }
}
