import { addDays, startOfMonth, startOfWeek } from 'date-fns'
import { useMemo } from 'react'

interface UseCalendarDaysOptions {
    calendarDate: Date
    currentDate?: Date
    firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    dayRowsCount?: number
}

export const useCalendarDays = ({ calendarDate, currentDate, firstDayOfWeek, dayRowsCount = 6 }: UseCalendarDaysOptions) => {
    return useMemo(() => {
        const firstDayOfCurrentMonth = startOfMonth(calendarDate)

        const firstDayOfCurrentWeek = startOfWeek(firstDayOfCurrentMonth, { weekStartsOn: firstDayOfWeek })

        if (currentDate) {
            firstDayOfCurrentWeek.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds())
        }

        const days: Date[][] = []
        for (let i = 0; i < dayRowsCount; i++) {
            const cells: Date[] = []
            for (let j = 0; j < 7; j++) {
                cells.push(addDays(firstDayOfCurrentWeek, i * 7 + j))
            }
            days.push(cells)
        }

        return days
    }, [calendarDate, currentDate, dayRowsCount, firstDayOfWeek])
}
