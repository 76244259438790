import { type DataSourceAbstract, type ViewFieldProps } from '@lighthouse/core';
import { Modal } from '@mantine/core';
import React from 'react';

import type { AppendParams, ImportParams } from '../../types';
import { TableUploader } from './TableUploader';

export * from './TableUploader';
interface ImportModalProps {
    appId: string
    envId: string
    dataSource?: DataSourceAbstract
    open: boolean
    defaultImportType?: 'create' | 'append'
    dataSourceList?: DataSourceAbstract[]
    disableSelectDataSource?: boolean
    userImportSetting?: string[]
    isShowRepeatConfig?: boolean
    viewColumns?: ViewFieldProps[]
    onImport?: (params: ImportParams) => void
    onAppend?: (params: AppendParams) => void
    onClose: () => void
}

export const ImportModal: React.FC<ImportModalProps> = ({
    appId,
    envId,
    dataSource,
    open,
    defaultImportType = 'create',
    dataSourceList,
    disableSelectDataSource,
    userImportSetting,
    isShowRepeatConfig = true,
    viewColumns,
    onImport,
    onAppend,
    onClose
}) => {
    return (
        <Modal
            centered
            closeOnClickOutside={false}
            padding={0}
            radius={12}
            size={690}
            overlayColor="var(--color-black)"
            overlayOpacity={0.75}
            overlayBlur={3}
            withCloseButton={false}
            opened={open}
            onClose={onClose}
        >
             <TableUploader
                    appId={appId}
                    envId={envId}
                    dataSource={dataSource}
                    defaultImportType={defaultImportType}
                    dataSourceList={dataSourceList}
                    disableSelectDataSource={disableSelectDataSource}
                    userImportSetting={userImportSetting}
                    isShowRepeatConfig={isShowRepeatConfig}
                    viewColumns={viewColumns}
                    onImport={onImport}
                    onAppend={onAppend}
                    onClose={onClose}
                />
            {/* {dataSource?.type === DataSourceType.userDataSource && onImportAppUser ? (
                <UserImport
                    onOpenSpaceGrade={onOpenSpaceGrade}
                    onOpenSaleSpaceGrade={onOpenSaleSpaceGrade}
                    onImportAppUser={onImportAppUser}
                    onClose={onClose}
                />
            ) : (
                <TableUploader
                    appId={appId}
                    envId={envId}
                    dataSource={dataSource}
                    defaultImportType={defaultImportType}
                    dataSourceList={dataSourceList}
                    disableSelectDataSource={disableSelectDataSource}
                    userImportSetting={userImportSetting}
                    isShowRepeatConfig={isShowRepeatConfig}
                    viewColumns={viewColumns}
                    onImport={onImport}
                    onAppend={onAppend}
                    onClose={onClose}
                />
            )} */}
        </Modal>
    )
}
