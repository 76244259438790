import { backgroundTransitionOnClick, flex, pointer, tinyButtons } from '@byecode/ui'
import styled from 'styled-components'

import { Icon } from '../styles'

export const Container = styled.div`
    /* position: relative; */
    /* z-index: 9999999999999999999999; */
`

export const Text = styled.div``

export const Item = styled.div`
    width: 100%;
    ${flex}
    justify-content: space-between;
    padding: 10px 8px 10px 16px;
    box-sizing: border-box;
    gap: 4px;
    background-color: var(--color-white);
    ${backgroundTransitionOnClick}
    ${pointer}
    overflow: hidden;
`

export const LeftFill = styled.div`
    flex: 1;
    ${flex}
    align-items: center;
    overflow: hidden;

    ${Icon} {
        margin-right: 4px;
    }
`

export const RightFill = styled.div``

export const PrimaryContain = styled.div`
    position: relative;
    background: #ffffff;
`
export const SecondaryContain = styled.div`
    position: absolute;
    max-height: 376px;
    padding: 8px 0;
    width: 100%;
    left: 204px;
    top: 0;
    z-index: 2;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
    /* 阴影样式 01 */
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    overflow: hidden auto;
    ${tinyButtons}
`

export const TargetContainer = styled.div`
    ${flex}
    align-items: center;
`
