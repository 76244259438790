import type { TooltipProps } from '@mantine/core'
import { Tooltip } from '@mantine/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'

const SCxErrorMessageTooltip = styled.div`
    display: inline-block;
    position: absolute;
    top: -35px;
    left: 0;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform-origin: center bottom;
    margin-bottom: -12px !important;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: var(--color-red-500);
    border-radius: 4px;
    color: rgb(255, 255, 255);
    padding: 4px 8px;
    font-size: 14px;
    max-width: 300px;
    width: auto;
    margin: 0px;
    outline: none;
    overflow-wrap: break-word;
    white-space: nowrap;
    font-weight: 500;
`

const SCxArrow = styled.div`
    color: var(--color-red-500);
    left: 0;
    /* margin-bottom: -9px !important; */
    transform: translate3d(8px, 0px, 0px) !important;
    position: absolute;
    bottom: -6px;
    overflow: hidden;
    width: 1em;
    height: 0.71em;
    box-sizing: border-box;

    :before {
        content: '';
        margin: auto;
        display: block;
        width: 100%;
        height: 100%;
        background-color: currentcolor;
        transform: rotate(45deg);
        transform-origin: 100% 0px;
    }
`

interface ErrorMessageProps {
    label?: string
    disabled?: boolean
    withPortal?: boolean
    zIndex?: number
    style?: React.CSSProperties
    children: React.ReactNode
}

export const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({ children, disabled, label, zIndex, withPortal, style }) => {
    const arrowRef = useRef(null)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (disabled) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }, [disabled])

    const toolTip = useMemo(() => {
        if (withPortal) {
            return createPortal(
                <SCxErrorMessageTooltip style={style}>
                    {label}
                    <SCxArrow ref={arrowRef} />
                </SCxErrorMessageTooltip>,
                document.body
            )
        }
        return (
            <SCxErrorMessageTooltip style={style}>
                {label}
                <SCxArrow ref={arrowRef} />
            </SCxErrorMessageTooltip>
        )
    }, [label, style, withPortal])

    return (
        <>
            {open && toolTip}
            {children}
        </>
    )
}
