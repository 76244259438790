import clsx from 'clsx'
import React, { forwardRef, Fragment } from 'react'

import { Box } from '../Box'
import { IconFont } from '../IconFont'
import { useStyles } from './styles'
import type { BreadcrumbsProps } from './types'

export const Breadcrumbs = forwardRef<HTMLDivElement, BreadcrumbsProps>((props, ref) => {
    const { items, bodyProps, separator, separatorProps, extra, className, classNames, styles, unstyled, ...nativeProps } = props
    // TODO: useDefaultStyles，使用颜色体系
    const { classes } = useStyles({}, { name: 'breadcrumbs', classNames, styles, unstyled })

    const { className: bodyClassName, ...restBodyProps } = bodyProps ?? {}
    const { className: separatorClassName, ...restSeparatorClassName } = separatorProps ?? {}

    return (
        <Box ref={ref} className={clsx(className, classes.root)} {...nativeProps}>
            <Box className={clsx(bodyClassName, classes.body)} {...restBodyProps}>
                {items.map(({ label, icon, className: labelClassName, ...rest }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                        {index !== 0 && (
                            <Box {...restSeparatorClassName} className={clsx(separatorClassName, classes.separator)}>
                                {separator ?? <IconFont type="ArrowRightSmall" style={{ verticalAlign: 'middle' }} />}
                            </Box>
                        )}
                        <Box
                            {...rest}
                            className={clsx(labelClassName, classes.item)}
                            style={{ ...rest.style, maxWidth: `${100 / items.length}%`, cursor: rest.onClick ? 'pointer' : undefined }}
                        >
                            {icon}
                            <Box className={classes.label}>{label}</Box>
                        </Box>
                    </Fragment>
                ))}
            </Box>

            {extra}
        </Box>
    )
})
