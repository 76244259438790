import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    width: 100%;
    top: 50px;
    height: 100vh;
    background-color: transparent;
    color: #fff;
    z-index: 3000;
    pointer-events: none;
`
