import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import type { Transform } from '../utils/transform'

export function useReturnTransform({
    index,
    node,
    rect
}: {
    index: number
    node: React.MutableRefObject<HTMLElement | null>
    rect: React.MutableRefObject<DOMRect | null>
}) {
    const [transform, setTransform] = useState<Transform | null>(null)
    const prevIndex = useRef(index)

    useLayoutEffect(() => {
        if (index !== prevIndex.current) {
            if (rect.current && node.current) {
                const initial = rect.current

                const current = node.current.getBoundingClientRect()
                const delta: Transform = {
                    x: initial.left - current.left,
                    y: initial.top - current.top,
                    scaleX: initial.width / current.width,
                    scaleY: initial.height / current.height
                }

                if (delta.x || delta.y) {
                    setTransform(delta)
                }
            }
            prevIndex.current = index
        }
    }, [index, rect, node])

    useEffect(() => {
        if (transform) {
            setTransform(null)
        }
    }, [transform])

    return transform
}
