import type { NumberConfig, NumberField, NumberValue } from '@lighthouse/core'
import { isEmpty } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { numberReg } from '../../../constants'
import { scientificNotationToString } from '../../../utils'
import { NumberInput } from '../../NumberInput'
import { sizeMap } from '../constant'
import type { FieldBaseProps, Size } from '../types'

interface NumberFieldProps extends FieldBaseProps {
    numberConfig: NumberConfig
    value: NumberValue
    numberField?: NumberField
}

const SCxInput = styled(NumberInput)<{ textSize: Size }>`
    width: 100%;

    outline: none;
    font-size: ${({ textSize }) => sizeMap[textSize]}px;
    overflow: hidden;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none !important;
        margin: 0;
    }

    ::placeholder {
        font-weight: var(--font-weight-normal);
        color: var(--color-gray-300);
    }
`

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;
    height: 38px;
    line-height: 22px;
    align-items: center;
    gap: 6px;
    font-size: var(--font-size-normal);
`

const SCxSign = styled.div`
    padding: 10px 0px;
    min-width: 24px;
    text-align: center;
`

const NumberTextField: React.FunctionComponent<NumberFieldProps> = props => {
    const {
        readOnly,
        numberConfig,
        config: { size = 'middle', placeholder },
        onCellChange,
        onSaveCellChange,
        value,
        numberField,
        isControlled
    } = props
    const { number } = numberField ?? {}
    const mode = 'number'
    const { accuracy = 0, suffix, prefix } = number ?? {}
    const [text, setText] = useState<string | number>(scientificNotationToString(value))
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [isFocus, setIsFocus] = useState(false)

    const showValue = useMemo(() => {
        const isEmptyString = text === ''
        if (isEmptyString) {
            return ''
        }
        const finishNumber = mode === 'number' ? Number(text) : Number(text) * 100
        return finishNumber.toFixed(accuracy)
    }, [accuracy, mode, text])

    useUpdateEffect(() => {
        setText(value)
    }, [value])

    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            const targetValue = ev.target.value
            setText(targetValue)
            onSaveCellChange?.({ type: 'number', value: targetValue })
        },
        [onSaveCellChange]
    )

    const handleBlur = useCallback(() => {
        setIsFocus(false)
        setText(showValue)
        onCellChange?.({ type: 'number', value: scientificNotationToString(text) })
    }, [onCellChange, showValue, text])

    const handleFocus = useCallback(() => {
        if (readOnly) {
            return
        }
        setText(scientificNotationToString(value))
        setIsFocus(true)
    }, [readOnly, value])

    return (
        <SCxContainer>
            {prefix && !isFocus && <SCxSign>{prefix}</SCxSign>}
            <SCxInput
                ref={inputRef}
                readOnly={readOnly}
                // autoFocus
                placeholder={placeholder}
                value={text}
                type="number"
                // inputMode="numeric"
                textSize={size}
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {suffix && !isFocus && <SCxSign>{suffix}</SCxSign>}
        </SCxContainer>
    )
}

export default NumberTextField
