import { styled } from '@byecode/ui/theme/stitches.config'

export const SwitchWrapper = styled('div', {
    position: 'relative'
})

export const SwitchInput = styled('input', {
    width: 0,
    height: 0,
    overflow: 'hidden',
    position: 'absolute',
    visibility: 'hidden'
})

export const SwitchTrack = styled('label', {
    display: 'flex',
    padding: 2,
    borderRadius: '36px',
    transition: 'all 0.2s ease-in-out',
    cursor: 'pointer',

    variants: {
        disabled: {
            true: {
                cursor: 'not-allowed',
                opacity: 0.5
            }
        },
        size: {
            xs: {
                width: 20,
                height: 12
            },
            sm: {
                width: 28,
                height: 16
            },
            md: {
                width: 36,
                height: 20
            },
            lg: {
                width: 48,
                height: 28
            }
        }
    }
})

export const SwitchThumb = styled('div', {
    width: 16,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    transition: 'all 0.2s ease-in-out',
    transform: 'translateX(0px)',
    variants: {
        size: {
            xs: {
                width: 8
            },
            sm: {
                width: 12
            },
            md: {
                width: 16
            },
            lg: {
                width: 24
            }
        },
        checked: {
            true: {},
            false: {}
        }
    },
    compoundVariants: [
        {
            checked: true,
            size: 'xs',
            css: {
                transform: 'translateX(8px)'
            }
        },
        {
            checked: true,
            size: 'sm',
            css: {
                transform: 'translateX(12px)'
            }
        },
        {
            checked: true,
            size: 'md',
            css: {
                transform: 'translateX(16px)'
            }
        },
        {
            checked: true,
            size: 'lg',
            css: {
                transform: 'translateX(20px)'
            }
        }
    ]
})
