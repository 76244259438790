import type { ButtonBlockHandleEvent, ButtonBlockType } from '@lighthouse/core'
import { ButtonPattern } from '@lighthouse/core'
import type React from 'react'

export const btnTypeShowMap = new Map<
    ButtonBlockType,
    {
        icon: boolean
        name: boolean
    }
>([
    [
        'icon',
        {
            icon: true,
            name: false
        }
    ],
    [
        'icon-name',
        {
            icon: true,
            name: true
        }
    ],
    [
        'name',
        {
            icon: false,
            name: true
        }
    ]
])

export const useableConfigMap = new Map<ButtonBlockHandleEvent, number>([
    ['createRecord', 2],
    ['openLink', 1],
    ['openPage', 1],
    ['deleteRecord', 2],
    ['updateRecord', 2]
])

export const btnStyleMap = new Map<ButtonPattern, React.CSSProperties>([
    [
        ButtonPattern.primary,
        {
            color: '#fff',
            backgroundColor: 'var(--color-app-main)'
        }
    ],
    [
        ButtonPattern.secondary,
        {
            borderColor: 'var(--color-gray-200)',
            color: 'var(--color-black)',
            backgroundColor: '#fff'
        }
    ]
])
