import type { HighLightConditions, IdField, NumberValue } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import React, { useMemo } from 'react'

import { IdItemPreviewer, IdListPreviewer } from '../FieldValuePreviewer/IdValuePreviewer'
import { CellHighlighter } from '../Highlighter'

// import type { FieldValuePreviewerProps } from './types'
interface IdValuePreviewerProps {
    field: IdField
    data?: NumberValue | NumberValue[]
    isWrap?: boolean
    width?: number
    styleConditions?: HighLightConditions
    isBold?: boolean
}

export const IdValuePreviewer: React.FC<IdValuePreviewerProps> = ({
    field,
    data = '',
    isWrap = true,
    width = 160,
    isBold,
    styleConditions
}) => {
    const { id: fieldId, type } = field

    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (Array.isArray(data)) {
            return <IdListPreviewer data={data} isWrap={isWrap} width={width} isBold={isBold} />
        }
        const id = nanoid()
        return <IdItemPreviewer id={id} isWrap={isWrap} data={data} isBold={isBold} isLast />
    }, [data, isBold, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={fieldId} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
