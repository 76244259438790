// import { getCachedItem, setCachedItem } from '@lighthouse/tools'
import { getCachedItem, removeCachedItem, setCachedItem } from '@lighthouse/tools'
import { add, subtract } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useImmer } from 'use-immer'

import { emailRegex, mobileRegex } from '../../constants'
import type { SendCodeType } from './types'

// let codeTimer: number
export interface VerificationCodeBtnState {
    disable: boolean
    count: number
    prevTime: number
    codeStep: 0 | 1 | 2
    codeText: string
}

export const useSendCode = function (
    codeType: SendCodeType,
    onGetCodeHandle?: (resolve: () => void, reject: () => void) => Promise<void> | void
) {
    const currentTime = String(Date.now()).slice(0, 10)

    const { t } = useTranslation()

    const [state, setState] = useImmer<VerificationCodeBtnState>({
        disable: false,
        prevTime: Number(getCachedItem(`${codeType}-lastTime`, Date.now())),
        count: 60,
        codeStep: 0,
        codeText: t('getVerificationCode')
    })
    const lastCodeTypeRef = useRef(codeType)

    const { disable, count, codeStep, codeText, prevTime } = state

    useEffect(() => {
        const lastCount = add(60, subtract(Number(prevTime), Number(currentTime)))
        if (codeStep === 1) {
            setState(draft => {
                draft.count = 60
                draft.codeText = t('resendTime', { v: String(60) })
                draft.codeStep = 2
            })
        }
        if (lastCount > 0 && count > lastCount) {
            setState(draft => {
                draft.count = lastCount
                draft.codeText = t('resendTime', { v: lastCount.toString() })
                draft.codeStep = 2
            })
            return undefined
        }
        if (codeStep === 2) {
            if (count > 0) {
                const codeTimer = setTimeout(() => {
                    setState(draft => {
                        draft.codeText = t('resendTime', { v: String(count - 1) })
                        draft.count = count - 1
                    })
                }, 1000)
                return () => {
                    const dateValue = subtract(60, count)
                    const nowStr = String(Date.now()).slice(0, 10)
                    const now = subtract(Number(nowStr), dateValue)
                    setCachedItem<string>(`${lastCodeTypeRef.current}-lastTime`, String(now))
                    clearTimeout(codeTimer)
                }
            }
            setState(draft => {
                draft.count = 60
                draft.codeText = t('getAgain')
                draft.codeStep = 0
            })
        }
        return undefined
    }, [codeStep, count, currentTime, prevTime, setState, t])

    useEffect(() => {
        lastCodeTypeRef.current = codeType
        setState(draft => {
            draft.count = 60
            draft.codeStep = 0
            draft.codeText = t('getVerificationCode')
        })
    }, [codeType, setState, t])

    useEffect(() => {
        return () => {
            removeCachedItem<string>(`${lastCodeTypeRef.current}-lastTime`)
        }
    }, [])

    const startCodeDown = useCallback(() => {
        setState(draft => {
            draft.codeStep = 1
            draft.disable = false
        })
    }, [setState])

    const errorCodeDown = useCallback(() => {
        setState(draft => {
            draft.disable = false
        })
    }, [setState])

    const reset = useCallback(() => {
        setState(draft => {
            draft.disable = false
            draft.count = 60
            draft.codeStep = 0
            draft.codeText = t('getVerificationCode')
        })
    }, [setState, t])

    /**
     * 获取验证码
     */
    const getPhoneCode = useCallback(() => {
        if (codeStep > 0) {
            return
        }
        setState(draft => {
            draft.disable = true
        })
        onGetCodeHandle?.(startCodeDown, errorCodeDown)
    }, [codeStep, errorCodeDown, onGetCodeHandle, setState, startCodeDown])

    return { getPhoneCode, setState, reset, state }
}

export function useFormRuleMap(): Record<
    'email' | 'mobile',
    {
        required: string
        pattern: {
            value: RegExp
            message: string
        }
    }
> {
    const { t } = useTranslation()

    return useMemo(
        () => ({
            email: {
                required: t('pleaseInputYourEmail'),
                pattern: {
                    value: emailRegex,
                    message: t('pleaseInputYourValidEmail')
                }
            },
            mobile: {
                required: t('pleaseInputYourMobile'),
                pattern: {
                    value: mobileRegex,
                    message: t('pleaseInputYourValidMobile')
                }
            }
        }),
        [t]
    )
}
