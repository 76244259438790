import type { ControlActionWithPayload, ControlledActionBlockType } from '@lighthouse/core'
import { keys } from 'rambda'
import { useEffect } from 'react'

type ExtractPayload<CAP, CAT> = CAP extends { blockType: CAT; payload: infer P } ? P : never

type ExtractParams<CP, ActionType> = CP extends { type: ActionType; params: infer Params } ? Params : never

/**
 * 事件系统
 */
export class BlockListenerSystem {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private listeners: Record<string, Record<string, ((payload?: any) => void) | undefined>> = {}

    register<
        BlockType extends ControlledActionBlockType,
        // eslint-disable-next-line etc/no-misused-generics
        CP extends ExtractPayload<ControlActionWithPayload, BlockType>,
        E extends CP['type'],
        Params extends ExtractParams<CP, E>
    >(
        blockId: string,
        blockType: BlockType,
        listenerMap: {
            [K in E]?: (payload?: Params) => void
        }
    ) {
        const listenerEventKeys = keys(listenerMap)
        for (const listenerEventKey of listenerEventKeys) {
            if (!listenerMap[listenerEventKey]) {
                throw new Error(`Event listener for ${listenerEventKey} is not provided`)
            }

            if (typeof listenerMap[listenerEventKey] !== 'function') {
                throw new TypeError(`Event listener for ${listenerEventKey} is not a function`)
            }

            if (!this.listeners[blockId]) {
                this.listeners[blockId] = {}
            }

            // if (!this.listeners[blockId]?.[listenerEventKey]) {
            //     this.listeners[blockId][listenerEventKey] = listenerMap[listenerEventKey]
            // }
            if (listenerMap[listenerEventKey]) {
                this.listeners[blockId][listenerEventKey] = listenerMap[listenerEventKey]
            }
        }

        return (event?: CP['type']) => this.unregister(blockId, blockType, event)
    }

    unregister<BlockType extends ControlledActionBlockType, CP extends ExtractPayload<ControlActionWithPayload, BlockType>>(
        blockId: string,
        blockType: BlockType,
        event?: CP['type']
    ) {
        if (event) {
            return delete this.listeners[blockId]?.[event]
        }
        return delete this.listeners?.[blockId]
    }

    emit<
        BlockType extends ControlledActionBlockType,
        // eslint-disable-next-line etc/no-misused-generics
        CP extends ExtractPayload<ControlActionWithPayload, BlockType>,
        E extends CP['type'],
        Params extends ExtractParams<CP, E>
    >(blockId: string, blockType: BlockType, { event, params }: { event: E; params?: Params }) {
        const listener = this.listeners[blockId]?.[event]

        if (listener) {
            listener(params)
        }
    }
}

export const blockListenerSystem = new BlockListenerSystem()

export function useRegisterBlockListener<
    BlockType extends ControlledActionBlockType,
    CP extends ExtractPayload<ControlActionWithPayload, BlockType>,
    E extends CP['type'],
    Params extends ExtractParams<CP, E>
>(
    blockId: string | undefined,
    blockType: BlockType,
    listenerMap: {
        [K in E]?: (payload?: Params) => void
    }
) {
    useEffect(() => {
        if (!blockId) {
            return
        }
        // @ts-expect-error listenerMap is expected to be a valid listener map
        blockListenerSystem.register(blockId, blockType, listenerMap)

        return () => {
            blockListenerSystem.unregister(blockId, blockType)
        }
    }, [blockId, blockType, listenerMap])
}
