// import { FileBlock } from '@lighthouse/block'
import { useCustomViewBlockContext } from '@lighthouse/block'
import { type FileBlockAbstract } from '@lighthouse/core'
import { getVariableToFieldValue, isFileValue } from '@lighthouse/shared'
import React, { Suspense, useMemo } from 'react'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useCurrentAppId, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useRecord } from '@/hooks/useDataSource'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useUserRecord } from '@/hooks/useUserRecord'

const FileBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.FileBlock })))

interface FileBlockControllerProps {
    blockData: FileBlockAbstract
}

const FileBlockController: React.FunctionComponent<FileBlockControllerProps> = ({ blockData }) => {
    const { config, id } = blockData
    const { fileSource } = config

    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const stackId = useCurrentStackIdContext()
    const userRecord = useUserRecord()
    const { pageId } = useCurrentPageContext()
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { record, pointer } = useCustomViewBlockContext()
    const prevRecord = useRecord(appId, envId, prev.datasource?.id ?? '', prev.recordId ?? '')
    const currentRecord = useRecord(appId, envId, curr.datasource?.id ?? '', curr.recordId ?? '')

    const viewDataSource = useDataSource(appId, envId, pointer)

    const value = useMemo(() => {
        if (!fileSource) {
            return []
        }

        const file = getVariableToFieldValue({
            idVariable: fileSource,
            currentRecord: {
                datasource: curr.datasource,
                record: currentRecord
            },
            prevRecord: {
                datasource: prev.datasource,
                record: prevRecord
            },
            viewRecord: {
                record,
                datasource: viewDataSource
            },
            userRecord
        })
        if (file) {
            const fileValue = file.value

            return isFileValue(fileValue) ? fileValue : []
        }

        return []
    }, [curr.datasource, currentRecord, fileSource, prev.datasource, prevRecord, record, userRecord, viewDataSource])

    return (
        <Suspense fallback={<div />}>
            <FileBlock id={id} config={config} value={value} />
        </Suspense>
    )
}

export default FileBlockController
