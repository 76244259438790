import { Badge } from '@byecode/ui'
import type { GroupTab } from '@lighthouse/core'
import React from 'react'

import * as SC from './styles'

interface TableTabProps {
    tabList: GroupTab[]
    value?: string
    onTabChange?: (val: string) => void
}

export const TableTab: React.FC<TableTabProps> = ({ tabList, value: tabValue, onTabChange }) => {
    if (!tabList || tabList.length === 0) {
        return null
    }

    return (
        <SC.TableTab
            value={tabValue}
            styles={{
                tabsList: { border: 'none' }
            }}
            onTabChange={onTabChange}
        >
            <SC.TableTab.List>
                {tabList.map(({ label, value, count }) => {
                    const isActive = tabValue === value
                    const color = isActive ? 'var(--color-gray-900)' : 'var(--color-gray-500)'
                    return (
                        <SC.TableTab.Tab
                            key={value}
                            value={value}
                            rightSection={
                                (count || count === 0) && (
                                    <Badge color={color} backgroundColor="var(--color-gray-100)" radius="9px">
                                        {count}
                                    </Badge>
                                )
                            }
                        >
                            {label}
                        </SC.TableTab.Tab>
                    )
                })}
            </SC.TableTab.List>
        </SC.TableTab>
    )
}
