import type { FieldType, TypeInstanceMap } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { FieldInnerTypeOfDataTypeNameMap, FieldTypeOfDataTypeNameMap } from '../../constants'

const SCxTag = styled.div`
    padding: 2px 4px;
    border-radius: 4px;
    font-size: var(--font-size-sm);
    white-space: nowrap;
`
interface FieldTypeTagProps {
    type?: FieldType
    innerType?: TypeInstanceMap
    className?: string
}

const transformFieldType: Set<FieldType> = new Set(['formula', 'aggregation'])

export const FieldTypeTag: React.FC<FieldTypeTagProps> = ({ type, innerType, className }) => {
    const tagText = useMemo(() => {
        if (innerType) {
            return FieldInnerTypeOfDataTypeNameMap[innerType]
        }
        if (!type) {
            return ''
        }
        return FieldTypeOfDataTypeNameMap[type]
    }, [innerType, type])

    const background = useMemo(() => {
        if (!innerType && type && transformFieldType.has(type)) {
            return 'var(--color-yellow-100)'
        }
        if (innerType === 'NULL') {
            return 'var(--color-red-100)'
        }
        return 'var(--color-gray-100)'
    }, [innerType, type])

    const color = useMemo(() => {
        if (!innerType && type && transformFieldType.has(type)) {
            return 'var(--color-yellow-900)'
        }
        if (innerType === 'NULL') {
            return 'var(--color-red-900)'
        }
        return 'var(--color-gray-600)'
    }, [innerType, type])

    return tagText ? (
        <SCxTag className={className} style={{ color, background }}>
            {tagText}
        </SCxTag>
    ) : null
}
