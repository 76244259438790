import type { CreatableProtocol, IconicProtocol, PermissionProtocol } from '../protocols'
import type { BaseApplicationAbstract } from './Application'

/** 注意：该数组的index与SpaceVersionCode 匹配， 修改时请注意  - start */
export const spaceByeCodeVersion = ['FREE', 'BASIC', 'STANDARD', 'ADVANCED', 'PROFESSIONAL', 'ENTERPRISE', 'ULTIMATE'] as const

export const spaceIClickVersion = [
    'I_CLICK_FREE',
    'I_CLICK_BASIC',
    'I_CLICK_STANDARD',
    'I_CLICK_ADVANCED',
    'I_CLICK_PROFESSIONAL',
    'I_CLICK_ENTERPRISE',
    'I_CLICK_ULTIMATE'
] as const
/* * 注意：该数组的index与SpaceVersionCode 匹配， 修改时请注意  - end */

export const MaxVersionLevel = spaceByeCodeVersion.length - 1

export type SpaceVersion = typeof spaceByeCodeVersion[number] | typeof spaceIClickVersion[number]

export type SpaceVersionCode = 0 | 1 | 2 | 3 | 4 | 5 | 6
export abstract class WorkSpaceAbstract implements IconicProtocol, CreatableProtocol, PermissionProtocol {
    abstract id: string

    abstract name: string

    abstract icon: string

    abstract members: number

    abstract applications: BaseApplicationAbstract[]

    abstract version?: SpaceVersion

    abstract browseStart?: boolean

    abstract createdTime?: number

    abstract updatedTime?: number

    abstract createdBy?: string

    abstract updatedBy?: string

    abstract roleId?: string

    abstract apiKey?: string
}
