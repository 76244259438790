import type { SystemStyles } from '@byecode/ui/theme/types'
import type { Properties } from 'csstype'

const SYSTEM_STYLES_MAP = new Map<keyof SystemStyles, keyof Properties | (keyof Properties)[]>([
    ['m', ['marginLeft', 'marginRight', 'marginTop', 'marginBottom']],
    ['mb', 'marginBottom'],
    ['mt', 'marginTop'],
    ['ml', 'marginLeft'],
    ['mr', 'marginRight'],
    ['mx', ['marginLeft', 'marginRight']],
    ['my', ['marginTop', 'marginBottom']],

    ['p', ['paddingLeft', 'paddingRight', 'paddingTop', 'paddingBottom']],
    ['pb', 'paddingBottom'],
    ['pt', 'paddingTop'],
    ['pl', 'paddingLeft'],
    ['pr', 'paddingRight'],
    ['px', ['paddingLeft', 'paddingRight']],
    ['py', ['paddingTop', 'paddingBottom']]
])

export function parseSystemStyles(styles: SystemStyles): Properties {
    return Object.keys(styles).reduce<Properties>((prev, next) => {
        const propertiesValue = styles[next as keyof SystemStyles]
        if (propertiesValue !== undefined) {
            const properties = SYSTEM_STYLES_MAP.get(next as keyof SystemStyles)
            if (Array.isArray(properties)) {
                properties.forEach(item => {
                    prev[item] = propertiesValue
                })
            } else if (properties) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                prev[properties] = propertiesValue
            }
        }

        return prev
    }, {})
}
