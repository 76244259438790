import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

interface GroupStylesParams {}

export const useStyles = createStyles((params: GroupStylesParams) => ({
    root: css({ padding: '12px 0' }),
    wrapper: css({
        padding: '0 16px',
        height: 40,
        display: 'flex',
        alignItems: 'center'
    }),
    label: css({
        fontSize: 14,
        fontWeight: 'bold',
        color: 'var(--color-gray-900)',
        textEllipsis: true
    }),
    tooltip: css({ marginLeft: 8, fontSize: 16, color: 'var(--color-gray-500)' }),
    rightIcon: css({
        marginLeft: 'auto',
        color: '$colorGray500'
    }),
    collapse: css({
        padding: '0 16px'
    })
}))
