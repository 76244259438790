import { Button, IconFont, Switch } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, DragOverlay } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext } from '@dnd-kit/sortable'
import type {
    DataSourceAbstract,
    DTSelectGenerationByText,
    Field,
    RichTextContentProtocol,
    SelectGenerationByTextField
} from '@lighthouse/core'
import { nanoid, randomData } from '@lighthouse/tools'
import { find, findIndex } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useHotkeys } from 'react-hotkeys-hook'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components'

import { getDefaultDataSourceOptions } from '../../../components/Variable'
import { COLORS, InnerTypeMapByFieldType } from '../../../constants'
import ColorPatch from '../../ColorPatch'
import { ErrorMessage } from '../../ErrorMessage'
import { isEmptyRichTextValue, RichTextEditor } from '../../RichText'
import type { ToolbarProps } from '../../RichText/Toolbar'
import { SortableItem } from '../SortableItem'
import * as SC from '../styles'

const AiButton = styled(Button)`
    &:hover {
        background: radial-gradient(101.03% 174.38% at 0 0, #fea0c2 0%, #3834e2 100%);
    }
`
export interface SelectGenerationByTextConfigViewProps {
    config: SelectGenerationByTextField
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    onOk?: (data: Field) => void
    onCancel: () => void
}

export const SelectGenerationByTextConfigView: React.FC<SelectGenerationByTextConfigViewProps> = ({
    config,
    dataSource,
    dataSourceList,
    onOk,
    onCancel
}) => {
    const listRef = useRef<FixedSizeList>(null)
    const lengthRef = useRef(Number.NaN)
    const {
        control,
        getValues,
        trigger,
        handleSubmit,
        formState: { errors }
    } = useForm<DTSelectGenerationByText>({
        mode: 'onSubmit',
        defaultValues: {
            ...config?.selectGenerationByText
        }
    })

    const { fields, remove, append, move, replace } = useFieldArray({ control, name: 'options', keyName: 'key' })
    const [overlay, setOverlay] = useState<React.ReactNode>(null)

    const [fieldsCount, containerHeight] = useMemo(() => {
        const selectOptionsLength = fields.length
        if (selectOptionsLength < 5) {
            return [selectOptionsLength + 1, selectOptionsLength * 40]
        }
        return [6, 200]
    }, [fields.length])

    useHotkeys(
        'Enter',
        () => {
            handleSubmit(handleOk)()
        },
        { enableOnTags: ['INPUT'] }
    )

    useEffect(() => {
        if (fields.length > lengthRef.current) {
            const num = fields.length
            listRef.current?.scrollTo(34 * num)
            lengthRef.current = Number.NaN
        }
    }, [fields.length])

    const dataSourceOption = useMemo(() => getDefaultDataSourceOptions(dataSource), [dataSource])

    const aiRichTextConfig: ToolbarProps['config'] = useMemo(
        () => ({
            variable: { dataSourceOption },
            heading: false,
            bulletList: false,
            orderedList: false,
            taskList: false,
            basic: false,
            line: false,
            link: false,
            image: false,
            quote: false,
            codeBlock: false
        }),
        [dataSourceOption]
    )

    const handleOk = useCallback(async () => {
        const isOk = await trigger()
        if (isOk) {
            onOk?.({
                ...config,
                type: 'selectGenerationByText',
                selectGenerationByText: getValues(),
                innerType: InnerTypeMapByFieldType['selectGenerationByText']
            })
        }
    }, [config, getValues, onOk, trigger])

    const checkVariable = useCallback((json?: RichTextContentProtocol) => {
        if (!json) {
            return '请输入指令'
        }
        const isEmpty = isEmptyRichTextValue(json)
        if (isEmpty) {
            return '请输入指令'
        }
        return true
    }, [])

    const handleDragStart = useCallback(
        (data: DragStartEvent) => {
            const { active } = data
            const { id: dragId } = active

            const options = getValues('options')
            const itemData = find(item => item.id === dragId, options)

            if (!itemData) {
                return null
            }
            setOverlay(
                <SC.OptionItem>
                    <SC.MoveIcon type="Move" fill="var(--color-gray-400)" />
                    <SC.OptionInput readOnly size="md" value={itemData.label} />
                    <ColorPatch value={itemData.color} />
                    <SC.Icon type="Close" fill="var(--color-gray-400)" />
                </SC.OptionItem>
            )
        },
        [getValues]
    )
    const handleDragCancel = () => setOverlay(null)

    const handleSortEnd = useCallback(
        (ev: DragEndEvent) => {
            const {
                active: { id: activeId },
                over
            } = ev
            const overId = over?.id
            if (!overId) {
                return
            }
            const activeIndex = findIndex(({ id }) => id === activeId, fields)
            const overIndex = findIndex(({ id }) => id === overId, fields)
            move(activeIndex, overIndex)
            setOverlay(null)
        },
        [fields, move]
    )

    const handleOptionAdd = useCallback(() => {
        lengthRef.current = fields.length
        const { name: color } = randomData(COLORS, 1)[0] ?? {}
        append({ label: '', id: nanoid(), color })
    }, [append, fields.length])

    return (
        <>
            <SC.FormItemWrapper>
                <SC.FieldConfigHeader>请输入指令</SC.FieldConfigHeader>
                <Controller
                    control={control}
                    name="variable"
                    rules={{
                        // required: '请输入指令'
                        validate: {
                            checkVariable
                        }
                    }}
                    render={({ field }) => {
                        return (
                            <ErrorMessage name="variable" errors={errors}>
                                <SC.RichTextContainer>
                                    <RichTextEditor
                                        shrink={false}
                                        config={aiRichTextConfig}
                                        value={field.value}
                                        onChange={json => {
                                            field.onChange(json)
                                            // const isEmpty = isEmptyRichTextValue(json)
                                            // if (isEmpty) {
                                            //     setError('variable', {
                                            //         message: '请输入指令'
                                            //     })
                                            // }
                                        }}
                                        autofocus="end"
                                    />
                                    {/* isEmptyRichTextValue */}
                                </SC.RichTextContainer>
                            </ErrorMessage>
                        )
                    }}
                />
            </SC.FormItemWrapper>
            <SC.FormItemWrapper>
                <SC.FieldConfigHeaderWrapper>
                    <SC.FieldConfigHeader>匹配选项</SC.FieldConfigHeader>
                </SC.FieldConfigHeaderWrapper>
                <SC.OptionList>
                    <DndContext
                        modifiers={[restrictToParentElement, restrictToFirstScrollableAncestor]}
                        autoScroll={{ layoutShiftCompensation: false }}
                        onDragStart={handleDragStart}
                        onDragCancel={handleDragCancel}
                        onDragEnd={handleSortEnd}
                    >
                        <SortableContext items={fields.map(d => d.id)}>
                            <FixedSizeList
                                ref={listRef}
                                width="100%"
                                height={containerHeight}
                                itemData={fields}
                                itemKey={(index: number) => fields[index].id}
                                itemCount={fields.length}
                                itemSize={40}
                                overscanCount={fieldsCount}
                            >
                                {({ index, style, data: items }) => {
                                    const item = items[index]
                                    return (
                                        <SortableItem key={item.key} id={item.id} wrapperStyle={style}>
                                            <Controller
                                                control={control}
                                                rules={{
                                                    required: '请输入选项',
                                                    validate(val) {
                                                        const { options } = getValues()
                                                        const isRepeated = options.some(({ label }, i) => {
                                                            return label === val && i !== index
                                                        })
                                                        return isRepeated ? '重复啦！' : true
                                                    }
                                                }}
                                                name={`options.${index}.label`}
                                                render={({ field }) => {
                                                    return (
                                                        <ErrorMessage name={`options.${index}.label`} errors={errors}>
                                                            <SC.OptionInput
                                                                key={index}
                                                                size="md"
                                                                defaultValue={field.value}
                                                                onChange={field.onChange}
                                                                autoFocus={lengthRef.current === index}
                                                            />
                                                        </ErrorMessage>
                                                    )
                                                }}
                                            />
                                            <Controller
                                                control={control}
                                                name={`options.${index}.color`}
                                                render={({ field: { value, onChange } }) => (
                                                    <ColorPatch value={value} onChange={onChange} />
                                                )}
                                            />
                                            <SC.Icon
                                                type="Close"
                                                onClick={() => {
                                                    remove(index)
                                                }}
                                                fill="var(--color-gray-400)"
                                            />
                                        </SortableItem>
                                    )
                                }}
                            </FixedSizeList>
                        </SortableContext>
                        <DragOverlay dropAnimation={{ duration: 0, easing: 'ease' }}>{overlay}</DragOverlay>
                    </DndContext>
                </SC.OptionList>
                <SC.OptionAdder
                    onClick={() => {
                        handleOptionAdd()
                    }}
                >
                    <SC.Icon fill="var(--color-main)" type="Add" />
                    添加选项
                </SC.OptionAdder>
            </SC.FormItemWrapper>
            <SC.FormItemWrapper>
                <SC.FormItemLimitCell>
                    <SC.FieldConfigLabel>匹配多个选项</SC.FieldConfigLabel>
                    <Controller
                        control={control}
                        name="multipleMatch"
                        render={({ field: { value, onChange } }) => (
                            <Switch
                                checked={Boolean(value)}
                                onChange={ev => {
                                    onChange(ev.target.checked)
                                }}
                            />
                        )}
                    />
                </SC.FormItemLimitCell>
            </SC.FormItemWrapper>

            <SC.ActionWrapper>
                <Button style={{ marginRight: 8 }} onClick={onCancel}>
                    取消
                </Button>
                <AiButton type="primary" onClick={handleOk} icon={<IconFont type="Magic" size={16} fill="var(--color-white)" />}>
                    确定
                </AiButton>
            </SC.ActionWrapper>
            {/* <SC.ConfigPreviewTip>示例：{dateTip}</SC.ConfigPreviewTip> */}
        </>
    )
}
