import type { CalendarRenderParameter } from '@byecode/ui'
import { IconFont, singleTextEllipsis } from '@byecode/ui'
import type { RecordLikeProtocol } from '@lighthouse/core'
import type { MobileDrawerProps } from '@lighthouse/shared'
import { MobileDrawer } from '@lighthouse/shared'
import chroma from 'chroma-js'
import { isSameDay, isSameMonth, isWeekend, lightFormat } from 'date-fns'
import React, { memo, useState } from 'react'
import styled, { css } from 'styled-components'

import type { TimeLineInfo } from '../TimeLine/TimeLine.type'

export const CELL_HEIGHT = 32
export const CELL_BORDER_BOTTOM_WIDTH = 1
export const LINE_GAP = 2
export const MINI_CELL_HEIGHT = 24
export const DAY_MARGIN_TOP = 10
export const DAY_MARGIN_BOTTOM = 4

const Adder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    font-size: 16px;
    color: var(--color-gray-400);
    position: absolute;
    right: 18px;
    top: 18px;
    opacity: 0;

    border-radius: 5px;
    border: 1px solid var(--color-gray-200);
    background-color: #fff;
    transition: opacity 0.3s;
`

const CellWrapper = styled.div<{ isMobile: boolean; isWeekend: boolean; isSelecting: boolean; canCreate: boolean }>`
    min-height: 56px;
    height: 100%;
    position: relative;
    overflow: hidden;
    &:not(:last-of-type) {
        border-right: ${({ isMobile }) => !isMobile && '1px solid var(--color-gray-200)'};
    }
    transition: all 0.3s;

    ${({ isWeekend }) =>
        isWeekend &&
        css`
            background-color: var(--color-gray-50);
        `}

    &:hover {
        /* background-color: var(--color-gray-50); */
        ${({ isMobile, isSelecting, canCreate }) =>
            canCreate &&
            !isMobile &&
            !isSelecting &&
            css`
                background-color: var(--color-gray-200);
                ${Adder} {
                    opacity: 1;
                }
            `}
    }

    ${Adder} {
        ${({ isMobile }) =>
            isMobile &&
            css`
                display: none;
            `}
    }

    &[data-selected] {
        background-color: var(--color-gray-50);
    }
`

const CellDay = styled.div<{ outOfRange: boolean; today: boolean; isMobile: boolean }>`
    margin: ${DAY_MARGIN_TOP}px 10px ${DAY_MARGIN_BOTTOM}px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 50%;

    ${({ today, outOfRange, isMobile }) => [
        today &&
            css`
                border: 1px solid var(--color-main);
            `,

        css`
            font-size: ${isMobile ? 12 : 16}px;
            color: ${outOfRange ? 'var(--color-gray-300)' : 'var(--color-black)'};
            width: ${isMobile ? '24px' : '32px'};
            height: ${isMobile ? `${MINI_CELL_HEIGHT}px` : `${CELL_HEIGHT}px`};
        `
    ]}
`

const CellSchedule = styled.div.withConfig<{ color: string }>({ shouldForwardProp: p => p !== 'color' })`
    display: flex;
    align-items: center;
    margin-top: 4px;
    padding: 4px 0 4px 4px;
    background-color: ${({ color }) => color && chroma(color).luminance(0.8).hex()};
    border-left: 3px solid ${({ color }) => color};
    border-radius: 3px;
`
const CellScheduleLabel = styled.span`
    font-size: 12px;
    line-height: 20px;
    ${singleTextEllipsis()};
`

const MoreTips = styled.div`
    cursor: pointer;
    color: var(--color-main);
    padding: 4px 0;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    transform: scale(0.83);
`

const Content = styled.div`
    width: 100%;
    padding: 12px;
`

const Line = styled.div<{ color: string }>`
    position: relative;
    cursor: pointer;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 32px;
    padding-left: 4px;
    ${({ color }) => css`
        background-color: ${chroma(color).luminance(0.9).hex()};
        border-left: 1px solid ${color};
    `}
`
export function OutOfLineDrawerContent({ data, onClickSchedule }: { data: TimeLineInfo[]; onClickSchedule?: (id: string, record: RecordLikeProtocol) => void }) {
    return (
        <Content>
            {data.map(item => (
                <Line key={item.id} color={item.color} onClick={() => onClickSchedule?.(item.id, item.record)}>
                    {item.title}
                </Line>
            ))}
        </Content>
    )
}

interface CellProps extends CalendarRenderParameter, React.ComponentPropsWithoutRef<'div'> {
    target?: MobileDrawerProps['target']
    date: Date
    data: TimeLineInfo[]
    selected: boolean
    isMobile: boolean
    isSelecting: boolean
    canCreate: boolean
    schedules?: TimeLineInfo
    onClickSchedule?: (id: string, record: RecordLikeProtocol) => void
}
export const Cell = memo((props: CellProps) => {
    const {
        target,
        data,
        selected,
        date,
        panelViewDate,
        isMobile,
        isSelecting,
        canCreate,
        onClickSchedule,
        schedules,
        mode,
        onPanelViewDate,
        ...rest
    } = props

    const [opened, setOpened] = useState(false)

    if (!date) {
        return null
    }

    const currentDateSchedules = data.filter(item => item.dates.some(d => isSameDay(date, d)))

    return (
        <CellWrapper
            {...rest}
            isSelecting={isSelecting}
            isWeekend={isWeekend(date)}
            isMobile={isMobile}
            canCreate={canCreate}
            data-selected={selected || undefined}
            data-value={date && lightFormat(date, 'yyyy-MM-dd')}
        >
            <Adder>
                <IconFont type="Add" />
            </Adder>
            <CellDay isMobile={isMobile} today={isSameDay(date, new Date())} outOfRange={!isSameMonth(date, panelViewDate)}>
                {date?.getDate().toString() ?? null}
            </CellDay>

            {isMobile ? (
                <>
                    {currentDateSchedules.slice(0, 2).map(item => (
                        <CellSchedule
                            key={item.id}
                            color={item.color}
                            data-ignore-tap
                            onClick={() => {
                                onClickSchedule?.(item.id, item.record)
                            }}
                        >
                            <CellScheduleLabel>{item.title}</CellScheduleLabel>
                        </CellSchedule>
                    ))}

                    {currentDateSchedules.length > 2 ? (
                        <>
                            <MoreTips onClick={() => setOpened(true)} data-ignore-tap>
                                还有{currentDateSchedules.length - 2}条
                            </MoreTips>
                            <MobileDrawer
                                target={target}
                                title={lightFormat(date, 'yyyy-MM-dd')}
                                opened={opened}
                                onClose={() => setOpened(false)}
                            >
                                <OutOfLineDrawerContent data={currentDateSchedules} onClickSchedule={onClickSchedule} />
                            </MobileDrawer>
                        </>
                    ) : null}
                </>
            ) : null}
        </CellWrapper>
    )
})
