import { type ApplicationSettingAuthentication, ApplicationSettingPerfectMode, SMS_TYPE } from '@lighthouse/core'
import type { FC, PropsWithChildren } from 'react'
import React, { createContext, useContext } from 'react'

export type AuthenticationContextType = ApplicationSettingAuthentication

export const defaultAuthentication: AuthenticationContextType = {
    logo: {
        type: 'icon'
    },
    name: '',
    describe: '',
    login: {
        phone: {
            isOpened: false
        },
        email: {
            isOpened: false
        },
        weChat: {
            isOpened: false,
            isBindPhone: true,
            // isOpenedH5Auth: false,
            isOpenedH5QrcodeLogin: false
        },
        google: {
            isOpened: false,
            accountId: 'xxx'
        }
    },
    register: {
        allowRegister: true,
        isReviewed: false
    },
    perfect: {
        title: '',
        describe: '',
        selectRoleTitle: '',
        selectRoleDescribe: '',
        mode: ApplicationSettingPerfectMode.COMMON,
        isOpened: false,
        roleSetting: [],
        commonSetting: {
            fields: []
        }
    }
}

export const AuthenticationContext = createContext<AuthenticationContextType>(defaultAuthentication)

type AuthenticationProviderProps = PropsWithChildren<{
    value: AuthenticationContextType
}>

export const AuthenticationProvider: FC<AuthenticationProviderProps> = props => <AuthenticationContext.Provider {...props} />

export const useAuthenticationContext = () => useContext(AuthenticationContext)
