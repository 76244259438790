import type { FilterOption } from '@lighthouse/core'

export const getChildrenOptions = (opts: FilterOption[]): FilterOption[] => {
    return opts.reduce<FilterOption[]>((prev, opt) => {
        if (opt?.children && opt.children.length > 0) {
            const childrenOptions = getChildrenOptions(opt.children)
            return [...prev, ...childrenOptions]
        }
        prev.push(opt)
        return prev
    }, [])
}
