import { mergeRefs } from '@lighthouse/tools'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import * as SC from './styles'
import type { InputProps } from './types'

export const Input = React.forwardRef<
    HTMLInputElement,
    InputProps & { onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void }
>(
    (
        {
            className,
            placeholder,
            bordered = true,
            size,
            focusOutLineColor,
            textAlign = 'left',
            textSize = '14px',
            autoFocusAndSelect,
            autoComplete = 'off',
            enableBackgroundColor,
            onFocus,
            onBlur,
            onInputChange,
            ...restProps
        },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement>(null)
        const [isComposition, setIsComposition] = useState<boolean | undefined>()

        const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
            onFocus?.(event)
        }

        const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
            onBlur?.(event)
            setIsComposition(false)
        }

        const handleCompositionStart = useCallback(() => {
            setIsComposition(false)
        }, [])

        const handleCompositionEnd = useCallback(() => {
            setIsComposition(true)
        }, [])

        const handleInputChange = useCallback(
            /**  @ts-expect-error event error */
            event => {
                if (typeof onInputChange !== 'function') {
                    return
                }
                if (!(isComposition ?? true)) {
                    return
                }
                onInputChange(event)
            },
            [isComposition, onInputChange]
        )

        useEffect(() => {
            if (autoFocusAndSelect && inputRef.current) {
                inputRef.current.select()
            }
        }, [autoFocusAndSelect])

        useEffect(() => {
            const inputEl = inputRef.current
            if (!inputEl) {
                return
            }
            inputEl.addEventListener('compositionstart', handleCompositionStart)
            inputEl.addEventListener('compositionend', handleCompositionEnd)
            inputEl.addEventListener('change', handleInputChange)

            return () => {
                inputEl.removeEventListener('compositionstart', handleCompositionStart)
                inputEl.removeEventListener('compositionend', handleCompositionEnd)
                inputEl.removeEventListener('change', handleInputChange)
            }
        }, [handleCompositionEnd, handleCompositionStart, handleInputChange])

        return (
            <SC.InputWrapper
                focusOutLineColor={focusOutLineColor}
                bordered={bordered}
                enableBackgroundColor={enableBackgroundColor}
                highlightable={restProps.highlightable}
                className={className}
            >
                <SC.InputContent
                    ref={mergeRefs([ref, inputRef])}
                    inputSize={size}
                    textAlign={textAlign}
                    textSize={textSize}
                    // bordered={bordered}
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    autoComplete={autoComplete}
                    {...restProps}
                />
            </SC.InputWrapper>
        )
    }
)
