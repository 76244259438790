import { IconFont, Popover } from '@byecode/ui'
import type { GroupConfigure, SchemaProtocol, ViewFieldProps } from '@lighthouse/core'
import React, { useMemo, useState } from 'react'

import { useApplicationContext } from '../../contexts'
import { getFieldIcon } from '../../utils'
import { useAppThemeContext } from '../ApplicationContainer'
import { CAN_SELECT_OPTIONS, DataGroupConfigure } from '../DataGroupConfigure'
import { FieldTypeTag } from '../FieldTypeTag'
import SettingButton from './SettingButton'
import * as SC from './styles'

export interface GroupProps {
    columns?: ViewFieldProps[]
    schema: SchemaProtocol['schema']
    isMobile?: boolean
    data?: GroupConfigure
    onChange?: (data: GroupConfigure) => void
    // onChange?: (c: Required<Pick<ViewOptions, 'groupByFieldId' | 'groupConfig'>>) => void
}

export const Group: React.FC<GroupProps> = ({
    columns = [],
    schema,
    isMobile,
    data = { groupByFieldId: '', groupConfig: [] },
    onChange
}) => {
    const [open, setOpen] = useState(false)
    const { personOptions } = useApplicationContext()

    const { groupByFieldId = '', groupConfig = [] } = data

    const theme = useAppThemeContext()

    // const groupText = useMemo(() => length(filter(({ visible }) => visible, groupConfig)), [groupConfig])

    const isActive = useMemo(() => !!groupByFieldId, [groupByFieldId])

    const options = useMemo(
        () =>
            columns
                .filter(item => CAN_SELECT_OPTIONS.has(item.type))
                .map(item => ({
                    label: item.title,
                    value: item.fieldId,
                    icon: <IconFont fill='var(--color-gray-400)' type={getFieldIcon(item.fieldId, item.type, item.innerType)} size={16} />,
                    // extra: <FieldTypeTag type={item.type} innerType={item.innerType} />
                })),
        [columns]
    )

    return (
        <Popover opened={open} trapFocus={false} onChange={setOpen} withinPortal position="bottom-start" width={268}>
            <Popover.Target>
                <SC.SettingBox isOpen={open} isActive={isActive} mainColor={theme.mainColor} tintMainColor={theme.tintMainColor}>
                    <SettingButton isActive={isActive} color={theme.mainColor} icon="GroupBy" size={isMobile ? 20 : 16} />
                    {!isMobile && (
                        <SC.SettingText>分组</SC.SettingText>
                        // <Indicator disabled={!isActive} color={theme.mainColor} label={String(groupText)} size={16} radius={16} withBorder>
                        // </Indicator>
                    )}
                </SC.SettingBox>
            </Popover.Target>
            <Popover.Dropdown>
                <DataGroupConfigure
                    schema={schema}
                    options={options}
                    selectProps={{
                        clearable: true
                    }}
                    clearable
                    enableHiddenTab
                    enableAllGroup
                    value={data}
                    color={theme.mainColor}
                    onChange={onChange}
                    personOptions={personOptions}
                />
            </Popover.Dropdown>
        </Popover>
    )
}
