import type { DateValue } from '@lighthouse/core'
import { differenceInDays, format, isValid, startOfDay, toDate } from 'date-fns'
import enUS from 'date-fns/locale/en-US/index'
import zhCN from 'date-fns/locale/zh-CN/index'
import zhTW from 'date-fns/locale/zh-TW/index'
import i18next from 'i18next'

const dateFnsI18Map: Record<string, Locale> = {
    'zh-CN': zhCN,
    'zh-TW': zhTW,
    en: enUS
}

export const getTimestamp = function (value: DateValue) {
    if (value === '') {
        return ''
    }
    return isValid(value) ? toDate(Number(value)).getTime() : ''
}

export const getRelativeDate = function (date: number | Date, baseNumber: number | Date, language?: string) {
    const diffDay = differenceInDays(startOfDay(date), startOfDay(baseNumber))
    const week = format(date, 'iiii', {
        locale: dateFnsI18Map[language || i18next.language]
    })
    const negativeDistance = 2
    if (diffDay > negativeDistance || diffDay < -negativeDistance) {
        return `（${week}）`
    }

    return `（${
        ['beforeYesterday', 'yesterday', 'today', 'tomorrow', 'afterTomorrow'].map(key => i18next.t(key))[diffDay + negativeDistance]
    } ${week}）`
}
