import clsx from 'clsx'

import { config, css } from './stitches.config'
import type { CssParams } from './types'

export interface StylesOptions<Key extends string> {
    name: string
    unstyled?: boolean
    styles?: Partial<Record<Key, CssParams>>
    classNames?: Partial<Record<Key, string>>
}

export function createStyles<
    // eslint-disable-next-line etc/no-misused-generics
    Key extends string = string,
    Params = {},
    Input extends Record<Key, ReturnType<typeof css<{ [name: string]: unknown }[]>>> = Record<
        Key,
        ReturnType<typeof css<{ [name: string]: unknown }[]>>
    >
>(input: (params: Params) => Input) {
    const { prefix } = config

    function useStyles(params: Params, options: StylesOptions<Key>) {
        const cssObject = input(params)

        const componentStyles: Partial<Record<Key, CssParams>> = options.styles || {}

        const classes = Object.fromEntries(
            Object.keys(cssObject).map(key => {
                const fixedName = `${prefix}-${options.name}-${key}`
                const currentKeyStyle = componentStyles[key as Key]
                const mergedClassName = clsx(
                    fixedName,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    { [cssObject[key as Key](params)]: !options.unstyled },
                    options.classNames?.[key as Key],
                    { [currentKeyStyle && css(currentKeyStyle).toString()]: !!currentKeyStyle }
                )
                return [key, mergedClassName]
            })
        ) as { [key in keyof Input]: string }

        return { classes }
    }

    return useStyles
}
