import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { Box } from '../Box'
import { Checkbox } from '../Checkbox'
import { Flex } from '../Flex'
import { IconFont } from '../IconFont'
import { useTreeContext } from './context'

const useStyles = createStyles(({ isExpanded }: { isExpanded: boolean }) => {
    return {
        node: css({
            cursor: 'pointer'
        }),
        icon: css({
            fontSize: 12,
            color: '$colorGray400',
            transform: isExpanded ? undefined : `rotate(-90deg)`,
            transition: 'transform .2s'
        }),
        label: css({
            fontSize: 14,
            lineHeight: '22px',
            color: '$colorBlack'
        }),
        subTree: css({
            listStyle: 'none'
        })
    }
})

export type TreeNode = {
    label: string
    value: string
    icon?: React.ReactNode
    children?: TreeNode[]
}

export interface TreeItemProps extends StyleComponentProps<Selectors<typeof useStyles>>, React.ComponentPropsWithoutRef<'li'> {
    node: TreeNode
    level?: number
}

const INDENT_SIZE = 16

export const TreeItem = forwardRef<HTMLLIElement, TreeItemProps>((props, ref) => {
    const { styles, unstyled, className, classNames, node, level = 0, ...rest } = props

    const context = useTreeContext()
    const isExpanded = context.expandedNodes.includes(node.value)
    const isChecked = context.isNodeChecked(node.value)
    const indeterminate = context.isNodeIndeterminate(node.value)

    const handleClick = (e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation()
        if (node.children) {
            context.toggleExpandedNode(node.value)
        }
    }

    const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target
        checked ? context.checkTree(node.value) : context.unCheckTree(node.value)
    }

    const { classes } = useStyles({ isExpanded }, { name: 'tree', styles, unstyled, classNames })

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <Box component="li" role="treeitem" ref={ref} className={clsx(classes.node, className)} onClick={handleClick} {...rest}>
            <Flex alignItems="center" gap={8} p={8} pl={node.children ? 8 + level * INDENT_SIZE : 28 + level * INDENT_SIZE}>
                {node.children && <IconFont type="ArrowDownSmall" className={classes.icon} />}
                {context.checkable && (
                    <Checkbox
                        size="xs"
                        disabled={node.children && node.children.length === 0}
                        checked={isChecked}
                        indeterminate={indeterminate}
                        onChange={handleCheckChange}
                        onClick={e => e.stopPropagation()}
                    />
                )}
                {node.icon}
                <Box className={classes.label}>{node.label}</Box>
            </Flex>
            {isExpanded && node.children && node.children.length > 0 && (
                <Box component="ul" role="group" className={classes.subTree}>
                    {node.children.map(item => (
                        <TreeItem key={item.value} node={item} level={level + 1} />
                    ))}
                </Box>
            )}
        </Box>
    )
})
