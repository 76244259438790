// import { DividerBlock } from '@lighthouse/block'
import type { DividerBlockAbstract } from '@lighthouse/core'
import type { FC } from 'react'
import React, { Suspense } from 'react'

const DividerBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.DividerBlock })))

interface DividerBlockControllerProps {
    blockData: DividerBlockAbstract
}

const DividerBlockController: FC<DividerBlockControllerProps> = props => {
    return (
        <Suspense fallback={<div />}>
            <DividerBlock {...props} />
        </Suspense>
    )
}

export default DividerBlockController
