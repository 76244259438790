import type { RecordOpenType, ViewType } from '@lighthouse/core'

export const kanbanPageSize = 200

export const calendarPageSize = 500

export const viewPageSize = 10

export const VIEW_BLOCK_NAME_MAP: Record<ViewType, string> = {
    advancedTable: '高级表格',
    calendar: '日历',
    table: '表格',
    list: '列表',
    gallery: '画廊',
    kanban: '看板',
    custom: '自定义视图'
}

