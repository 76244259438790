import { IconFont, Input, pointer, singleTextEllipsis, tinyButtons } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const SearchInput: typeof Input = styled(Input)`
    width: 100%;
    margin-bottom: 17px;
    border-radius: 6px;
    flex-shrink: 0;
`

export const IconWrapper = styled.div`
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Icon = styled(IconFont)``

export const TagList = styled.div`
    flex: 1;
    overflow-y: auto;
    ${tinyButtons}
    width: 100%;
    margin-top: 10px;
`

export const TagWrapper = styled.div`
    height: auto;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    overflow: hidden;
`

export const TagItem = styled.div<{ isShowBorder?: boolean }>`
    width: 100%;
    display: flex;
    /* height: 300px; */
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 16px;
    color: var(--color-black);
    border-bottom: 1px solid var(--color-gray-200);
    background: var(--color-white);

    :last-child {
        border: none;
    }
`

export const TagItemContent = styled.div`
    /* margin-right: 6px; */
    font-size: var(--font-size-normal);
    display: flex;
    align-items: center;
    ${singleTextEllipsis}
`

export const LeftFill = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;
    gap: 4px;
    width: 100%;
    ${singleTextEllipsis}
`

export const Avatar = styled.img`
    width: 22px;
    height: 22px;
    min-width: 22px;
    margin-right: 4px;
    border-radius: 50%;
`
export const Title = styled.div`
    width: 100%;
    height: 100%;
    ${singleTextEllipsis}
`

export const FinishButton = styled.div`
    font-weight: 500;
    color: var(--color-app-main);
    ${pointer}
`
