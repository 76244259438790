const { VITE_SENTRY_ENVIRONMENT } = import.meta.env
// 开发环境 cdn 图片地址前缀
const CDN_TEST_IMAGE_PREFIX = 'https://cdn.data-test.byecode.com/userdata'
// 生产环境 cdn 图片地址前缀
const CDN__PROD_IMAGE_PREFIX = 'https://cdn.data.byecode.com/userdata'

export const CDN_IMAGE_PREFIX = VITE_SENTRY_ENVIRONMENT
    ? VITE_SENTRY_ENVIRONMENT === 'prod'
        ? CDN__PROD_IMAGE_PREFIX
        : CDN_TEST_IMAGE_PREFIX
    : CDN_TEST_IMAGE_PREFIX


export const getImageFullUrlInCommon = <T extends string | undefined>(url: T) => {
    if (!url) {
        return url
    }
    if (url.startsWith('http')) {
        return url
    }
    return `${CDN_IMAGE_PREFIX}/${url}` as T
}

export const getImageFullUrlInApplication = <T extends string | undefined>(appId: string | undefined, url: T) => {
    if (!url || !appId) {
        return url
    }
    if (url.startsWith('http')) {
        return url
    }
    const imageUrl = `${CDN_IMAGE_PREFIX}/${url}`
    const suffix = imageUrl.includes('?') ? `&appId=${appId}` : `?appId=${appId}`
    return `${imageUrl}${suffix}` as T
}
