import { keyframes, styled } from '@byecode/ui/theme/stitches.config'

const commonAnim = keyframes({
    '0%': {
        transform: 'scale(1)'
    },
    '50%': {
        transform: 'scale(1.8)'
    },
    '100%': {
        transform: 'scale(1)'
    }
})

const circleAnim = keyframes({
    '0%': {
        transform: 'rotate(0deg)'
    },
    '100%': {
        transform: 'rotate(1turn)'
    }
})

export const StyledLoadingCommonWrapper = styled('div', {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    flexDirection: 'column'
})

export const StyledIndicatorLoadingWrapper = styled('div', {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
})

export const StyledLoadingCommonContent = styled('div', {
    width: '60px',
    margin: '30px auto',
    display: 'flex',
    justifyContent: 'space-between',

    '& > div': {
        width: '6px',
        height: '6px',
        background: '$colorGray500',
        borderRadius: '50%',
        animation: `1s ${commonAnim} infinite`,

        '&:nth-of-type(1)': {
            animationDelay: '-1s'
        },

        '&:nth-of-type(2)': {
            animationDelay: '-0.9s'
        },

        '&:nth-of-type(3)': {
            animationDelay: '-0.8s'
        },

        '&:nth-of-type(4)': {
            animationDelay: '-0.7s'
        },

        '&:nth-of-type(5)': {
            animationDelay: '-0.6s'
        }
    }
})

export const StyledLoadingOutlinedWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px'
})

export const StyledLoadingOutlinedContent = styled('div', {
    width: '100%',
    height: '100%',
    display: 'block',
    fontSize: '16px',
    '>svg': {
        animation: `${circleAnim} 0.7s linear`,
        animationIterationCount: 'infinite'
    }
})

export const StyledLoadingDescription = styled('div', {
    fontSize: 14,
    textAlign: 'center',
    marginTop: '12px',
    color: '$colorGray500'
})
