import { IconFont, singleTextEllipsis, Tooltip } from '@byecode/ui'
import type { FieldADTValue, FieldProtocol, SelectValue } from '@lighthouse/core'
import cls from 'classnames'
import { filter, find } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { useApplicationContext } from '../../contexts'
import { getDepartmentToOptions, getTreeOptions } from '../../utils'
import { getOptionWithPath } from '../TagCascadeSelect/utils'
import { TreeSelect } from '../TagTreeSelect'
import * as SC from './styles'

interface ParentDepartmentValueEditorProps {
    recordId: string
    field: FieldProtocol
    data: SelectValue
    style?: React.CSSProperties
    autoFocus?: boolean
    isHiddenId?: boolean
    departmentId: string | undefined
    onCellChange?: (recordId: string, field: FieldADTValue) => Promise<boolean>
    onClose?: (isActive?: boolean) => void
}

const SCxTagCascadeSelectSearch = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-size: var(--font-size-normal);
    background-color: var(--color-gray-50);
    border-bottom: 1px solid var(--color-gray-200);
    width: 100%;
    min-height: 39px;
    padding: 5px 7px;
    overflow: hidden;
`

const SCxTagItem = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 24px;
    line-height: 20px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 4px;
    padding: 2px 14px 2px 6px;
    white-space: nowrap;
    overflow: hidden;
    color: var(--color-black);
    background-color: var(--color-gray-200);
    border-radius: 4px;
    border: 1px solid transparent;
    &:not(.plain-container) {
        border-radius: 100px;
        padding: 0 18px 0 8px;
        /* background: #f4e7c3; */
    }
`

export const SCxCursor = styled.div`
    display: inline-block;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 2px;
    margin-right: 4px;
    height: 22px;
    padding: 2px 4px 2px 0;
    background-color: transparent;
    /* line-height: 22px; */

    input {
        border: none;
        outline: none;
        background-color: transparent;
        color: var(--color-black);
        width: 100%;
        height: 100%;
    }
`

const SCxTagItemContent = styled.div`
    overflow: hidden;
    /* padding-right: 6px; */
`

const SCxCloseIcon = styled(IconFont)`
    position: absolute;
    right: 6px;
    top: 5px;
    font-size: 12px;
    cursor: pointer;
    color: var(--color-gray-400);
`

const SCxTagItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 4px;
    overflow: hidden;
`

const SCxItemLabel = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxItemName = styled.div`
    flex: 1;
    ${singleTextEllipsis}
    color: var(--color-black);
    font-size: var(--font-size-normal);
`

const SCxTagItemDescribe = styled.div`
    margin-left: 4px;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    overflow: hidden;
    text-overflow: ellipsis;
`

export const ParentDepartmentValueEditor: React.FC<ParentDepartmentValueEditorProps> = ({
    recordId,
    field,
    data,
    departmentId,
    style,
    autoFocus,
    isHiddenId,
    onCellChange,
    onClose
}) => {
    const { departmentOptions } = useApplicationContext()
    const cursorRef = useRef<HTMLDivElement>(null)
    const [keywords, setKeywords] = useState('')

    const originOptions = useMemo(() => {
        const opts = getDepartmentToOptions(departmentOptions)
        return getOptionWithPath(opts)
    }, [departmentOptions])

    const filterOptions = useMemo(() => {
        if (keywords.trim()) {
            return filter(option => option.labelPath?.includes?.(keywords), originOptions)
        }
        return originOptions
    }, [keywords, originOptions])

    const options = useMemo(() => getTreeOptions(filterOptions), [filterOptions])

    const handleSearchValueChange = useCallback((label: string) => {
        setKeywords(label.trim())
    }, [])

    const handleRemove = useCallback(
        (id: string) => {
            onCellChange?.(recordId, { ...field, type: 'parentDepartment', value: [] })
        },
        [field, onCellChange, recordId]
    )

    const renderEmptyItem = useCallback(
        (id: string) => {
            return (
                <SCxTagItemWrapper>
                    <SCxItemLabel>
                        <SCxItemName>找不到部门</SCxItemName>
                        {!isHiddenId && <SCxTagItemDescribe>{id}</SCxTagItemDescribe>}
                    </SCxItemLabel>
                </SCxTagItemWrapper>
            )
        },
        [isHiddenId]
    )

    const handleChange = useCallback(
        (val: string) => {
            onCellChange?.(recordId, { ...field, type: 'parentDepartment', value: [val] })
        },
        [onCellChange, recordId, field]
    )

    const tagContent = useMemo(() => {
        const parentId = data?.[0]
        if (!parentId) {
            return null
        }
        const option = find(item => item.value === parentId, originOptions)
        if (!option) {
            if (renderEmptyItem) {
                return (
                    <SCxTagItem key={parentId} className={cls({ 'plain-container': renderEmptyItem !== undefined })}>
                        {renderEmptyItem(parentId)}
                        <SCxCloseIcon
                            type="Close"
                            onClick={() => {
                                handleRemove(parentId)
                            }}
                        />
                    </SCxTagItem>
                )
            }
            return null
        }
        const { value, label, labelPath } = option
        return (
            <Tooltip key={value} title={labelPath}>
                <SCxTagItem
                    className="plain-container"
                    style={{
                        borderColor: 'rgba(0,0,0,0.1)'
                    }}
                >
                    <SCxTagItemWrapper>
                        <SCxItemLabel>
                            <SCxItemName>{label}</SCxItemName>
                            {!isHiddenId && <SCxTagItemDescribe>{value}</SCxTagItemDescribe>}
                        </SCxItemLabel>
                    </SCxTagItemWrapper>
                    <SCxCloseIcon
                        type="Close"
                        onClick={() => {
                            handleRemove(value)
                        }}
                    />
                </SCxTagItem>
            </Tooltip>
        )
    }, [data, handleRemove, isHiddenId, originOptions, renderEmptyItem])

    return (
        <SC.TagCascadeSelectWrapper
            onMouseDown={ev => {
                ev.stopPropagation()
            }}
        >
            <SCxTagCascadeSelectSearch>
                {tagContent}

                <SCxCursor contentEditable ref={cursorRef} onInput={ev => handleSearchValueChange(ev.currentTarget.textContent ?? '')} />
            </SCxTagCascadeSelectSearch>
            <TreeSelect disabledId={departmentId} options={options} value={data} onChange={handleChange} />
        </SC.TagCascadeSelectWrapper>
    )
}
