import { addYears, eachYearOfInterval, endOfDecade, format, isSameYear, isWithinInterval, startOfDecade } from 'date-fns'
import type { FC } from 'react'
import React, { forwardRef, useMemo } from 'react'

import type { Selectors, StyleComponentProps } from '../../../theme/types'
import { Box } from '../../Box'
import { Button } from '../../Button'
import type { CalendarSettingsProps } from '../Calendar.type'
import { useStyles } from './YearCalendar.style'

export type YearCalendarStylesName = Selectors<typeof useStyles>

interface YearCalendarProps
    extends Pick<CalendarSettingsProps, 'CellComponent' | 'onCellMouseEnter' | 'onCellMouseLeave'>,
        StyleComponentProps<YearCalendarStylesName> {
    disabled?: boolean

    date?: Date
    onSelect: (date: Date) => void

    panelViewDate: Date
}

export const YearCalendar: FC<YearCalendarProps> = forwardRef<HTMLDivElement, YearCalendarProps>((props, ref) => {
    const {
        disabled,
        date = new Date(),
        panelViewDate,
        onSelect,
        CellComponent,
        onCellMouseEnter,
        onCellMouseLeave,
        classNames,
        styles,
        unstyled,
        ...rest
    } = props
    const { classes } = useStyles({}, { name: 'Calendar', classNames, styles, unstyled })

    const years = useMemo(() => {
        const ls = eachYearOfInterval({ start: addYears(startOfDecade(panelViewDate), -1), end: addYears(endOfDecade(panelViewDate), 1) })

        ls.forEach(d => {
            d.setMonth(panelViewDate.getMonth(), panelViewDate.getDate())
            d.setHours(panelViewDate.getHours(), panelViewDate.getMinutes(), panelViewDate.getSeconds())
        })

        return ls
    }, [panelViewDate])

    return (
        <Box ref={ref} className={classes.yearBody} {...rest}>
            {years.map(year =>
                CellComponent ? (
                    <CellComponent key={year.getTime()} mode="year" date={year} panelViewDate={panelViewDate} />
                ) : (
                    <Button
                        key={year.getTime()}
                        className={classes.yearCell}
                        size="sm"
                        style={{ color: isSameYear(date, year) ? undefined : 'var(--color-gray-900)' }}
                        type={isSameYear(date, year) ? 'primary' : 'text'}
                        onClick={() => {
                            if (disabled) {
                                return
                            }
                            onSelect(year)
                        }}
                        onMouseEnter={e => {
                            if (disabled) {
                                return
                            }

                            onCellMouseEnter?.(year, e.nativeEvent)
                        }}
                        onMouseLeave={e => {
                            if (disabled) {
                                return
                            }
                            onCellMouseLeave?.(year, e.nativeEvent)
                        }}
                    >
                        {format(year, 'yyyy')}
                        {isSameYear(year, new Date()) && <div className={classes.yearIndicator} />}
                    </Button>
                )
            )}
        </Box>
    )
})
