import type { FilterFormType, Sorter } from '@lighthouse/core'
import { type BlockAbstract, BlockType, ChartType } from '@lighthouse/core'

export const getBlockDataSourceId = (
    block: BlockAbstract,
    pointer: string | undefined
): {
    dsId?: string
    filter?: FilterFormType
    sorts?: Sorter[]
} => {
    switch (block.type) {
        case BlockType.chart: {
            if (block.config.chartType === ChartType.indicator) {
                return { dsId: undefined }
            }
            return { dsId: block.config.pointer, filter: block.config.ruleFilter }
        }
        case BlockType.view: {
            return { dsId: block.config.pointer, filter: block.config.filter, sorts: block.config.sorts }
        }
        case BlockType.formContainer: {
            return { dsId: block.config.pointer || pointer }
        }
        case BlockType.fieldGroup:
        case BlockType.field: {
            return { dsId: pointer }
        }
        default: {
            return { dsId: pointer }
        }
    }
}

/**
 * 滚动到某个节点
 * @param id
 */
export function scroll2FlowNode(id: string, scrollIntoViewOptions?: ScrollIntoViewOptions) {
    requestAnimationFrame(() => {
        document.querySelector(`div[data-node-id="${id}"]`)?.scrollIntoView({ behavior: 'smooth', ...scrollIntoViewOptions })
    })
}

/**
 * 滚动到视图中指定的某个元素
 * @param viewId
 * @param index
 * @param scrollIntoViewOptions
 */
export function scroll2ViewItem(viewId: string, index: number, scrollIntoViewOptions?: ScrollIntoViewOptions) {
    requestAnimationFrame(() => {
        document
            .querySelector(`div[data-node-id="${viewId}"] div[data-view-item="${index}"]`)
            ?.scrollIntoView({ behavior: 'smooth', ...scrollIntoViewOptions })
    })
}

/**
 * 滚动到视图指定位置，左或右，到底
 * @param dom
 * @param scrollOptions
 */
export function scroll2EndLeftOrRight(dom: HTMLElement, scrollOptions?: ScrollToOptions) {
    requestAnimationFrame(() => {
        dom?.scrollTo(scrollOptions)
    })
}
