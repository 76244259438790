import { IconFont } from '@byecode/ui/components/IconFont'
import React from 'react'
import styled from 'styled-components'

interface PayProps {}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 18px;
    background-color: var(--color-gray-50);
`

const SCxText = styled.div<{ color?: string }>`
    color: ${({ color }) => color};
    font-size: var(--font-size-base);
    line-height: 24px;
`
export const Pay: React.FunctionComponent<PayProps> = () => {
    return (
        <SCxContainer>
            <div>
                <IconFont size={60} color="var(--color-green-500)" type="WechatPay" />
                <SCxText color="var(--color-green-500)">微信支付</SCxText>
            </div>

            <SCxText color="var(--color-black)">正在进行微信支付</SCxText>
        </SCxContainer>
    )
}
