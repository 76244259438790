import { backgroundTransitionOnClick, IconFont, Input } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const Empty = styled.div`
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: center;
    background-color: var(--color-gray-100);
    color: var(--color-gray-400);
`

export const FileListWrapper = styled.div`
    padding: 6px 0;
    width: 100%;
`

export const FileListDesc = styled.p`
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    padding: 0 10px 6px 10px;
    /* margin-bottom: 6px; */
`

/** file list item */

export const FileListItemWrapper = styled.div<{ variant: 'normal' | 'card' }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    overflow: hidden;

    ${props =>
        props.variant === 'card' &&
        css`
            padding: 4px 8px;
            border-radius: 8px;
            background-color: var(--color-gray-100);

            &:hover {
                background-color: var(--color-gray-200);
            }
        `}
`

export const FileInfo = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    height: 36px;
    transition: opacity 0.2s ease-out;

    &.not-uploaded {
        transition: opacity 0.2s ease-in;
        opacity: 0.5;
    }
`

export const ImageThumb = styled.img<{ status?: string }>`
    width: 28px;
    cursor: ${({ status }) => (status === 'success' ? 'zoom-in' : 'default')};
`

export const FileIcon = styled(IconFont)<{ status?: string }>`
    font-size: 24px;
    width: 28px;
    cursor: ${({ status }) => (status === 'success' ? 'zoom-in' : 'default')};
`

export const MoreActionIcon = styled(IconFont)<{ hoverColor?: string }>`
    font-size: var(--font-size-base);
    cursor: pointer;
    color: var(--color-gray-400);
    :hover {
        color: ${({ hoverColor }) => hoverColor};
    }
`

export const MoreErrorContainer = styled.div`
    color: var(--color-red-500);
`

export const ErrorIcon = styled(IconFont)`
    display: block;

    ${MoreErrorContainer}:hover & {
        display: none;
    }
`

export const CloseIcon = styled(IconFont)`
    display: none;

    ${MoreErrorContainer}:hover & {
        display: block;
    }
`

export const MoreUploadingContainer = styled.div`
    font-size: 14px;
    display: flex;
    align-items: center;
`

export const MoreUploadingPercent = styled.div`
    margin-right: 4px;
`

export const MoreSuccessContainer = styled.div<{ defaultOpacity?: number }>`
    width: 60px;
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    align-items: center;
    opacity: ${({ defaultOpacity }) => defaultOpacity ?? 0};

    ${FileListItemWrapper}:hover & {
        opacity: 1;
    }
`

export const FileName = styled.div`
    margin: 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const FileNameInput = styled(Input)`
    flex: 1;
    margin: 0 12px 0 4px;
`

export const FileListItemPopoverContent = styled.div`
    padding: 6px 0;
    width: 100px;
`

export const ActionItem = styled.div`
    width: 100%;
    padding: 6px 8px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    cursor: pointer;
    ${backgroundTransitionOnClick}
`

/** file add */

export const AddFileBtn = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 36px;
    color: var(--color-gray-500);
    cursor: pointer;
    ${backgroundTransitionOnClick}
`

export const AddIcon = styled(IconFont)`
    margin-right: 6px;
`

export const AddBtnText = styled.div`
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
`

export const UploadTip = styled.span`
    font-size: var(--font-size-sm);
`
export const FileListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`
