import { BreakPointSize, mergeRefs, useBreakpoint } from '@lighthouse/tools'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { ApplicationPreviewEnum } from '../../types'
import { BlockTitle } from '../BlockTitle'
import MobileField from './MobileField'
import type { FieldInputProps } from './types'
import WebsiteField from './WebsiteField'

const SCxFieldContainer = styled.div`
    width: 100%;
    padding:  var(--block-padding);
`

const SCxLayout = styled.div<{ direction?: 'column' | 'row' }>`
    display: flex;
    /* height: 100%; */
    ${({ direction }) =>
        direction === 'row'
            ? css`
                  flex-direction: row;
                  align-items: flex-start;
                  gap: 16px;
              `
            : css`
                  flex-direction: column;
                  gap: 0px;
              `}
`

const currentDirection = 'column'

export const FieldInput = React.forwardRef<HTMLDivElement, FieldInputProps>((props, ref) => {
    const { config, style } = props
    const { required, showTitle, previewType, title } = config

    const isMobile = previewType === ApplicationPreviewEnum.mobile

    return (
        <SCxFieldContainer ref={ref} style={style}>
            {useMemo(
                () => (
                    <SCxLayout direction={currentDirection}>
                        {showTitle && (
                            <BlockTitle
                                title={title}
                                required={required}
                                style={{
                                    width: 'calc(100% - 8px)'
                                }}
                                direction={currentDirection}
                            />
                        )}
                        {isMobile ? <MobileField {...props} /> : <WebsiteField {...props} />}
                    </SCxLayout>
                ),
                [isMobile, props, required, showTitle, title]
            )}
        </SCxFieldContainer>
    )
})
