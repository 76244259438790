import { Button, Flex, Modal, Text, Toast } from '@byecode/ui'
import type { ApplicationSettingAuthentication, AppUser } from '@lighthouse/core'
import { isWechatBrowser } from '@lighthouse/tools'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAuthenticationContext } from '../../../contexts'
import { LoginAuthType } from '../../AccountPage'
import { TagIcon } from '../../TagIcon'

interface BindUserInfoProps {
    userData: AppUser
    onUpdateBindAccount?: (isBind: boolean, type: LoginAuthType) => Promise<boolean>
}

const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: 400px;
    font-size: var(--font-size-normal);
`

const SCxItem = styled(Flex)``

const loginBindWayList: {
    icon: React.ReactNode
    label: string
    value: Exclude<keyof ApplicationSettingAuthentication['login'], 'landPageId' | 'google'>
}[] = [
    {
        icon: (
            <TagIcon
                size={44}
                iconSize={24}
                radius={100}
                iconColor="var(--color-blue-500)"
                background="var(--color-blue-50)"
                icon="SmartphoneIconLibrary"
            />
        ),
        label: 'mobileNumber',
        value: 'phone'
    },
    {
        icon: (
            <TagIcon
                size={44}
                iconSize={24}
                radius={100}
                iconColor="var(--color-theme-7)"
                background="var(--color-theme-2)"
                icon="LetterIconLibrary"
            />
        ),
        label: 'email',
        value: 'email'
    },
    {
        icon: (
            <TagIcon
                size={44}
                iconSize={24}
                radius={100}
                iconColor="var(--color-green-500)"
                background="var(--color-green-50)"
                icon="WeChatLogoIconLibrary"
            />
        ),
        label: 'wechat',
        value: 'weChat'
    }
]

export const BindUserInfo: React.FunctionComponent<BindUserInfoProps> = ({ userData, onUpdateBindAccount }) => {
    const { login } = useAuthenticationContext()
    const { t } = useTranslation()
    const loginWayList = loginBindWayList.map(i => ({ ...i, label: t(i.label) }))
    const isOneLoginType = useMemo(() => {
        return (
            loginWayList.filter(item => ({ email: userData.email, phone: userData.mobile, weChat: userData.wechatOpenId }[item.value]))
                .length === 1
        )
    }, [loginWayList, userData.email, userData.mobile, userData.wechatOpenId])

    const handleUpdateBindAccount = useCallback(
        async (isBind: boolean, type: Exclude<keyof ApplicationSettingAuthentication['login'], 'landPageId' | 'google'>) => {
            const loginType = {
                email: LoginAuthType.email,
                phone: LoginAuthType.mobile,
                weChat: isWechatBrowser() ? LoginAuthType.wechat : LoginAuthType.follow_wechat
            }[type]
            const name = loginWayList.find(i => i.value === type)?.label
            if (!isBind) {
                if (isOneLoginType) {
                    await Modal.confirm({
                        title: t('notBind'),
                        content: t('removeBindAccountDescribe', { account: name }),
                        okText: t('knew'),
                        cancelText: t('cancel'),
                        okStatus: 'primary'
                    })
                    return
                }
                const isConfirm = await Modal.confirm({
                    title: t('confirmUnbinding'),
                    content: t('unBindNotLoginConfirmDescribe', { account: name }),
                    okText: t('continueUnbinding'),
                    cancelText: t('cancel'),
                    okStatus: 'error'
                })
                if (isConfirm) {
                    const res = await onUpdateBindAccount?.(isBind, loginType)
                    if (res) {
                        Toast.custom(t('alreadyUnbindAccount', { account: name }), { icon: 'Tick', iconColor: 'var(--color-green-500)' })
                    }
                }

                return
            }
            const res = await onUpdateBindAccount?.(isBind, loginType)
        },
        [isOneLoginType, loginWayList, onUpdateBindAccount, t]
    )

    return (
        <SCxContainer>
            {loginWayList.map(item => {
                const isOpened = login[item.value]?.isOpened
                const isExitInfo = { email: userData.email, phone: userData.mobile, weChat: userData.wechatOpenId }[item.value]

                return (
                    <SCxItem key={item.value} justifyContent="space-between" alignItems="center">
                        <Flex gap={8} alignItems="center" style={{ width: '33%' }}>
                            {item.icon}
                            <Text>{item.label}</Text>
                        </Flex>
                        <Text color="var(--color-gray-500)" style={{ width: '33%' }}>
                            {isOpened
                                ? isExitInfo
                                    ? `${t('alreadyBind')}：${isExitInfo}`
                                    : t('bindAfterUsedAccountLogin', { account: item.label })
                                : t('notEnabledAccountLogin', { account: item.label })}
                        </Text>
                        <Flex justifyContent="flex-end" style={{ width: '33%' }}>
                            <div style={{ minWidth: 66 }}>
                                {isOpened ? (
                                    isExitInfo ? (
                                        <Button
                                            block
                                            style={{ background: '#FEF3F2', color: '#F04438', border: 'none' }}
                                            onClick={() => handleUpdateBindAccount?.(false, item.value)}
                                        >
                                            {t('unbind')}
                                        </Button>
                                    ) : (
                                        <Button
                                            block
                                            onClick={() => {
                                                handleUpdateBindAccount?.(true, item.value)
                                            }}
                                        >
                                            {t('goBind')}
                                        </Button>
                                    )
                                ) : (
                                    <Button type="text" block style={{ color: 'var(--color-gray-400)', pointerEvents: 'none' }}>
                                        {t('notEnable')}
                                    </Button>
                                )}
                            </div>
                        </Flex>
                    </SCxItem>
                )
            })}
        </SCxContainer>
    )
}

export const MobileBindUserInfo: React.FunctionComponent<BindUserInfoProps> = ({ userData, onUpdateBindAccount }) => {
    const { login } = useAuthenticationContext()
    const { t } = useTranslation()
    const loginWayList = loginBindWayList.map(i => ({ ...i, label: t(i.label) }))

    const isOneLoginType = useMemo(() => {
        return (
            loginWayList.filter(item => ({ email: userData.email, phone: userData.mobile, weChat: userData.wechatOpenId }[item.value]))
                .length === 1
        )
    }, [loginWayList, userData.email, userData.mobile, userData.wechatOpenId])

    const handleUpdateBindAccount = useCallback(
        async (isBind: boolean, type: Exclude<keyof ApplicationSettingAuthentication['login'], 'landPageId' | 'google'>) => {
            const loginType = {
                email: LoginAuthType.email,
                phone: LoginAuthType.mobile,
                weChat: isWechatBrowser() ? LoginAuthType.wechat : LoginAuthType.follow_wechat
            }[type]
            const name = loginWayList.find(i => i.value === type)?.label
            if (!isBind) {
                if (isOneLoginType) {
                    await Modal.confirm({
                        title: t('notBind'),
                        content: t('removeBindAccountDescribe', { account: name }),
                        okText: t('knew'),
                        cancelText: t('cancel'),
                        okStatus: 'primary'
                    })
                    return
                }
                const isConfirm = await Modal.confirm({
                    title: t('confirmUnbinding'),
                    content: t('unBindNotLoginConfirmDescribe', { account: name }),
                    okText: t('continueUnbinding'),
                    cancelText: t('cancel'),
                    okStatus: 'error'
                })
                if (isConfirm) {
                    const res = await onUpdateBindAccount?.(isBind, loginType)
                    if (res) {
                        Toast.custom(t('alreadyUnbindAccount', { account: name }), { icon: 'Tick', iconColor: 'var(--color-green-500)' })
                    }
                }

                return
            }
            onUpdateBindAccount?.(isBind, loginType)
        },
        [isOneLoginType, loginWayList, onUpdateBindAccount, t]
    )

    return (
        <SCxContainer style={{ marginTop: 8 }}>
            {loginWayList.map(item => {
                const isOpened = login[item.value]?.isOpened
                const isExitInfo = { email: userData.email, phone: userData.mobile, weChat: userData.wechatOpenId }[item.value]

                return (
                    <SCxItem key={item.value} justifyContent="space-between" alignItems="center" gap={12}>
                        <Flex gap={12} alignItems="center" style={{ flex: 1, overflow: 'hidden' }}>
                            {item.icon}
                            <Flex gap={8} direction="column" style={{ flex: 1, overflow: 'hidden' }}>
                                <Text weight={500} size={16}>
                                    {item.label}
                                </Text>
                                <Text color="var(--color-gray-500)" size={14}>
                                    {isOpened
                                        ? isExitInfo
                                            ? `${t('alreadyBind')}：${isExitInfo}`
                                            : t('bindAfterUsedAccountLogin', { account: item.label })
                                        : t('notEnabledAccountLogin', { account: item.label })}
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex justifyContent="flex-end" style={{ flexShrink: 0 }}>
                            <div style={{ minWidth: 66 }}>
                                {isOpened ? (
                                    isExitInfo ? (
                                        <Button
                                            block
                                            style={{ background: '#FEF3F2', color: '#F04438', border: 'none', fontSize: 16 }}
                                            onClick={() => {
                                                if (isOneLoginType) {
                                                    return
                                                }
                                                handleUpdateBindAccount?.(false, item.value)
                                            }}
                                        >
                                            {t('unbind')}
                                        </Button>
                                    ) : (
                                        <Button
                                            block
                                            onClick={() => {
                                                handleUpdateBindAccount?.(true, item.value)
                                            }}
                                            style={{ fontSize: 16 }}
                                        >
                                            {t('goBind')}
                                        </Button>
                                    )
                                ) : (
                                    <Button
                                        type="text"
                                        block
                                        style={{ color: 'var(--color-gray-400)', pointerEvents: 'none', fontSize: 16 }}
                                    >
                                        {t('notEnable')}
                                    </Button>
                                )}
                            </div>
                        </Flex>
                    </SCxItem>
                )
            })}
        </SCxContainer>
    )
}
