import { Toast } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import React from 'react'
import type { Accept } from 'react-dropzone'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

import { UploadFileViewer } from './UploadFileViewer'

const UploadFileDefault = getAssetUrl('common', 'UploadFileDefault.svg')
const UploadFileHover = getAssetUrl('common', 'UploadFileHover.svg')

interface UploadAreaProps {
    file: File | null
    disabled?: boolean
    accept?: Accept
    sizeLimit?: number
    description?: string | React.ReactNode
    tip?: string | React.ReactNode
    extra?: string | React.ReactNode
    onFileLoaded?: (file: File) => Promise<void> | void
    onFileClear?: () => void
}

const UploaderArea = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    font-size: var(--font-size-normal);
    border-radius: 5px;
    border: 1px dashed var(--color-gray-200);
    background-color: var(--color-gray-100);
    color: var(--color-gray-400);
    outline: none;
    cursor: pointer;

    &.drag-over {
        border-color: var(--color-main);
        background-color: var(--color-main-tint);
    }
`
const DropText = styled.div`
    margin: 8px 0;
    color: var(--color-gray-900);
`

const UploaderAreaImg = styled.img`
    width: 117px;
    height: 64px;
`

export const UploadArea: React.FC<UploadAreaProps> = ({
    file,
    disabled,
    sizeLimit = 5,
    accept,
    description,
    tip,
    extra,
    onFileLoaded,
    onFileClear
}) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept,
        multiple: false,
        maxFiles: 1,
        onDropAccepted(files, event) {
            const file = files[0]
            if (!file) {
                return
            }
            if (file.size > sizeLimit * 1024 * 1024) {
                Toast.error(`请上传小于 ${sizeLimit} MB 的文件`)
                return
            }
            onFileLoaded?.(file)
        },
        onDropRejected(files, event) {
            const fileNames = files.map(({ file }) => file.name)
            Toast.error(`${fileNames.join(',')} 文件不支持，仅支持上传.xlsx、.csv 格式的文件`)
        }
    })

    const haveFile = file !== null

    return (
        <div style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
            {haveFile ? (
                <UploadFileViewer file={file} onFileClear={onFileClear} />
            ) : (
                <>
                    <UploaderArea {...getRootProps({ className: isDragActive ? 'drag-over' : '' })}>
                        <input {...getInputProps()} />
                        <UploaderAreaImg src={isDragActive ? UploadFileHover : UploadFileDefault} alt="上传文件" />
                        {isDragActive ? <DropText>松开文件</DropText> : description}
                        {tip}
                    </UploaderArea>
                    {extra}
                </>
            )}
        </div>
    )
}

export default UploadArea
