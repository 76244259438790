import React from 'react'

import { Account } from '@/containers/Account'

interface PerfectProps {}

const Perfect: React.FC<PerfectProps> = () => {
    return <Account defaultMode="perfect" isFixedMode />
}

export default Perfect
