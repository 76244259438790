import { useAtomData } from '@lighthouse/shared'
import { isWechatBrowser, removeURLParameter } from '@lighthouse/tools'
import { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { appAtom } from '@/atoms/application/state'
import * as srv from '@/services'

export const useWechatLogin = function (wxRedirectUri: string) {
    const [search] = useSearchParams()
    const code = search.get('code')
    const state = search.get('state')
    const isWechat = isWechatBrowser()
    const [loading, setLoading] = useState(false)

    const wxAuthAccount = useAtomData(
        appAtom,
        useCallback(s => s?.integrations?.weChatOfficialAccount, [])
    )

    const wxQrcodeAuthAccount = useAtomData(
        appAtom,
        useCallback(s => s?.integrations?.wechatWebsite, [])
    )

    // 发起微信授权
    const handleWxBrowserWeChatLogin = useCallback((state: string) => {
        if (!isWechat || !wxAuthAccount || loading) {
            return
        }

        setLoading(true)
        const { appId, scope } = wxAuthAccount
        setLoading(false)
        if (appId) {
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(
                wxRedirectUri
            )}&response_type=code&scope=${scope}&state=${state}&forcePopup=true#wechat_redirect`
        }
    }, [isWechat, loading, wxAuthAccount, wxRedirectUri])

    const handleQrcodeWeChatLogin = useCallback((state: string) => {
        if (loading || !wxQrcodeAuthAccount) {
            return
        }
        setLoading(true)
        const { appId } = wxQrcodeAuthAccount
        setLoading(false)
        if (appId) {
            window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${encodeURIComponent(
                wxRedirectUri
            )}&response_type=code&scope=snsapi_login&state=${state}#wechat_redirect`
        }
    }, [loading, wxQrcodeAuthAccount, wxRedirectUri])

    return { handleWxBrowserWeChatLogin, handleQrcodeWeChatLogin, code }
}
