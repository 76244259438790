import { pointer } from '@byecode/ui'
import styled from 'styled-components'

export const RecordSelector = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

export const Add = styled.div<Pick<React.CSSProperties, 'justifyContent'>>`
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    padding: 0 16px;
    gap: 8px;
    background: var(--color-white);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent};

    ${pointer}
`
