import { isIosPlatform } from '@lighthouse/tools'
import { LoadingOverlay, Portal } from '@mantine/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { type UseUploadFileSParameter, UploadDropZone } from '../../UploadManage'
import { SELECT_FILE_UPLOAD_OPTIONS, SELECT_VIDEO_UPLOAD_OPTIONS } from '../constants'
import * as CM from '../WithoutPopperFileUploader/styles'

interface FileAddProps {
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    disableUpload?: boolean
    uploadFileType?: 'file' | 'video'
    isRealityMobile?: boolean
}

const SCxContainer = styled.div`
    width: 100%;

    > *:not(:first-child) > ${CM.Item} {
        border-top: 1px solid var(--color-gray-200);
    }
`

const FileAdd: React.FunctionComponent<FileAddProps> = ({ uploadyOptions, uploadFileType, disableUpload, isRealityMobile }) => {
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    const options = uploadFileType === 'video' ? SELECT_VIDEO_UPLOAD_OPTIONS : SELECT_FILE_UPLOAD_OPTIONS
    return (
        <SCxContainer>
            {options.map(({ label, capture, accept }, index) => {
                const isUseUpload = !isRealityMobile && accept !== '*'
                const isIos = isIosPlatform()
                if (isIos && capture === 'environment') {
                    return null
                }
                return (
                    <UploadDropZone
                        key={label}
                        capture={capture}
                        disabled={disableUpload || isUseUpload}
                        accept={accept}
                        multiple
                        uploadOptions={uploadyOptions}
                    >
                        <CM.Item>{t(label)}</CM.Item>
                    </UploadDropZone>
                )
            })}
            <Portal>
                <LoadingOverlay visible={loading} />
            </Portal>
        </SCxContainer>
    )
}

export default FileAdd
