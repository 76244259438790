import { isElement } from '@byecode/ui/utils/isElement'
import React, { useEffect, useRef } from 'react'

function focusable(element: HTMLElement): boolean {
    const name = element.nodeName.toLowerCase()
    const isFocusableNode = ['input', 'select', 'textarea', 'button'].includes(name)

    const tabindex = element.getAttribute('tabindex')
    const hasTabIndex = !Number.isNaN(Number.parseInt(tabindex as string))

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return !element.disabled && (isFocusableNode || hasTabIndex)
}

export interface FocusTrapProps {
    enable: boolean
    children?: React.ReactNode
}

/**
 * 捕捉焦点
 * @param props
 * @returns
 */
export const FocusTrap = (props: FocusTrapProps) => {
    const { enable, children } = props

    const ref = useRef<HTMLElement | null>(null)

    useEffect(() => {
        if (!enable || !ref.current) {
            return
        }

        const root = ref.current
        let focusElement = root.querySelector<HTMLElement>('input, select, textarea, button, [tabindex]')
        if (!focusElement && focusable(root)) {
            focusElement = root
        }
        focusElement?.focus({ preventScroll: true })
    }, [enable])

    if (!isElement(children)) {
        return <>{children}</>
    }

    return React.cloneElement(children, { ref })
}
