import { createContext, useContext } from 'react'

export interface RadioGroupContextValue {
    name: string
    value?: string
    onChange: (v: string) => void
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const RadioGroupContext = createContext<RadioGroupContextValue>(null)
export const RadioGroupProvider = RadioGroupContext.Provider
export const useRadioGroupContext = () => useContext(RadioGroupContext)
