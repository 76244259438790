export const locale = {
    ok: 'OK',
    cancel: 'Cancel',
    now: 'This moment',
    chooseDate: 'Select date',
    chooseTime: 'Select time',
    complete: 'Complete',
    date: 'Date',
    time: 'Time',
    clear: 'Clear'
}
