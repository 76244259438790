import { backgroundTransitionOnClick, IconFont, Input, pointer, tinyButtons } from '@byecode/ui'
import styled from 'styled-components'

export const DropDownContainer = styled.div<{ width?: string; popoverHeight?: number }>`
    width: ${({ width }) => width || '100%'};
    padding: 8px 0;
    min-width: 300px;
    font-size: var(--font-size-normal);
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    max-height: ${({ popoverHeight }) => (popoverHeight ? `${popoverHeight}px` : 'unset')};
`
export const DropDownItem = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    overflow: hidden;
    ${backgroundTransitionOnClick}
    ${pointer}
`

export const ArrowIcon = styled(IconFont)`
    /* transition: transform 0.2s ease; */
`

export const ClearIcon = styled(IconFont)``

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    min-width: 24px;
`

export const PersonContainer = styled.button<{ opened?: boolean, width?: string; isBorder?: boolean; focusOutLine?: string }>`
    display: flex;
    align-items: center;
    /* height: 100%; */
    font-size: var(--font-size-normal);
    /* margin: 8px 14px 8px 14px; */
    width: ${({ width }) => width || '100%'};
    border: ${({ isBorder }) => (isBorder ? ' 1.5px solid var(--color-gray-200)' : 'none')};
    border-radius: 5px;
    border-color: ${({ focusOutLine, opened }) => opened ? focusOutLine ?? 'var(--color-main)' : 'var(--color-gray-200)'};
    cursor: pointer;

    &:hover {
        border-color: ${({ focusOutLine }) => focusOutLine ?? 'var(--color-main)'};
    }
`
export const TagWrapper = styled.div<{ isWrap?: boolean }>`
    display: flex;
    align-items: center;
    gap: 6px;
    overflow: hidden;
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
`

export const ValueContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 0 8px;
    align-items: center;
    justify-content: space-between;
    color: var(--color-gray-500);
`

export const SearchInput = styled(Input) <{ borderColor?: string }>`
    margin: 4px 12px 8px 12px;
    flex-shrink: 0;

    &:focus-within {
        border-color: ${({ borderColor }) => borderColor || 'var(--color-app-main)'};
    }
`

export const RightSection = styled.div`
    width: 24px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    z-index: 1;
`

export const DropDownContent = styled.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
`

export const List = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    ${tinyButtons}
`

export const PlaceHolder = styled.div`
    width: 100%;
    height: 22px;
    line-height: 22px;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-normal);
    color: var(--color-gray-400);
`
