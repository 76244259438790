import type { BarChartOptionProtocol, ChartLikeProtocol, Series, StackType, YAxisParam } from '@lighthouse/core'
import type { BarSeriesOption, LineSeriesOption } from 'echarts/charts'
import { findIndex, findLastIndex, max, multiply } from 'rambda'

import type { ECOption } from '../../components'
import type { AxisOption, BarOption } from './common'
import {
    abbreviationAxisHeight,
    getCommonOption,
    getNumericalInterval,
    getXAxisDataArr,
    getXAxisItem,
    getYAxis,
    hasAbbreviationAxisChartType,
    HeadTitleHeight,
    legendOffset,
    legendWidth,
    titleGap
} from './common'

const getFirstNumber = (arr: string[]) => {
    return Number(arr[0])
}

export const getLegend = (params: BarChartOptionProtocol, chartData: ChartLikeProtocol): ECOption['legend'] => {
    const {
        showHeadTitle,
        showLegend,
        legendTranspose,
        showAbbreviationAxis,
        chartType,
        mainAxis,
        secondaryAxis = [],
        showAxisTitle,
        yMainAxisTitle,
        secondaryMarkLines
    } = params
    const { legend } = chartData
    const axis = [...mainAxis, ...secondaryAxis]
    const titleTop = showHeadTitle ? HeadTitleHeight + 7 : 0
    const yAxisTitleTop = showAxisTitle && (yMainAxisTitle || secondaryMarkLines) ? 30 : 0
    const legendConfigurator: ECOption['legend'] = {
        type: 'scroll',
        show: showLegend,
        // data: legend,
        bottom: legendOffset,
        data: legend.map((name, index) => ({
            name,
            icon:
                axis?.[index]?.chartType === 'line'
                    ? ''
                    : 'path://M8,0L32,0A8,8 0 0 1 40,8L40,32A8,8 0 0 1 32,40L8,40A8,8 0 0 1 0,32L0,8A8,8 0 0 1 8,0'
        }))
    }
    switch (legendTranspose) {
        case 'top': {
            legendConfigurator.top = 5 + titleTop
            legendConfigurator.right = 0
            break
        }
        case 'bottom': {
            if (showAbbreviationAxis && hasAbbreviationAxisChartType.includes(chartType)) {
                legendConfigurator.bottom = abbreviationAxisHeight + legendOffset
                break
            }
            legendConfigurator.bottom = legendOffset
            break
        }
        case 'left': {
            legendConfigurator.left = -3
            legendConfigurator.top = titleTop + yAxisTitleTop
            legendConfigurator.width = legendWidth
            legendConfigurator.orient = 'vertical'
            break
        }
        case 'right': {
            legendConfigurator.right = legendOffset
            legendConfigurator.width = legendWidth
            legendConfigurator.top = titleTop + yAxisTitleTop
            legendConfigurator.orient = 'vertical'
            break
        }
        default: {
            break
        }
    }

    return legendConfigurator
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const getXAxisData = (arr: BarOption[][], params: BarChartOptionProtocol) => {
    const { showAxisTitle, xAxisTitle } = params
    if (arr.length === 0) {
        return {}
    }
    const data = arr.map((item, index) => {
        const offsetY = multiply(40, index)
        const xName = index === arr.length - 1 && showAxisTitle && xAxisTitle ? xAxisTitle : ''
        const possessions = getXAxisItem(item)
        const data = item.map(item => item.label || ' ')
        const configurator: ECOption['xAxis'] | ECOption['yAxis'] = {
            data,
            name: xName,
            nameGap: xName ? 36 : undefined,
            nameLocation: 'middle',
            position: 'bottom',
            axisLabel: {
                color: '#98a2b3'
            },
            axisTick: {
                show: false
            },
            minorSplitLine: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: '#98A2B3'
                }
            },
            splitLine: {
                show: false,
                lineStyle: {
                    type: 'dashed'
                }
            }
        }

        // 子级 需要做label省略,倾斜处理
        if (index === 0 && configurator.axisLabel) {
            // configurator.axisLabel.overflow = 'breakAll'
            // configurator.axisLabel.align = 'center'
            // configurator.axisLabel.hideOverlap = false
            // configurator.axisLabel.showMaxLabel = true
            // configurator.axisLabel.showMinLabel = true
            // configurator.axisLabel.interval = 0
            configurator.axisLabel.hideOverlap = true
            // configurator.axisTick.show = true
            configurator.axisLabel.overflow = 'truncate'

            // configurator.axisLabel.rotate = item.length > 20 ? 45 : 0
            // configurator.axisLabel.formatter = (value: string) => {
            //     if (value.length > 5 && item.length > 10) {
            //         return `${value.slice(0, 3)}...`
            //     }
            //     return value
            // }
            if (configurator.axisTick) {
                configurator.axisTick.show = false
                configurator.axisTick.interval = 0
            }
        }
        if (index > 0) {
            if (configurator.axisLabel) {
                // configurator.axisLabel.overflow = 'breakAll'
                configurator.axisLabel.showMaxLabel = true
                configurator.axisLabel.interval = (i, value) => {
                    const parent = item[i]?.parent
                    const str = parent ? `${parent}-${value}` : value
                    if (possessions[str]) {
                        const num = getFirstNumber(possessions[str])

                        return i === num
                    }
                    return true
                }
                configurator.axisLabel.hideOverlap = true
                configurator.axisLabel.overflow = 'truncate'
                configurator.axisLabel.rotate = 0
            }
            if (configurator.axisTick) {
                configurator.axisTick.show = true
                configurator.axisTick.interval = (i, value) => {
                    const parent = item[i]?.parent
                    const str = parent ? `${parent}-${value}` : value
                    if (possessions[str]) {
                        const num = getFirstNumber(possessions[str])
                        return i === num
                    }
                    return true
                }
            }

            configurator.offset = offsetY
        }
        return configurator
    })
    if (data.length > 1) {
        data.splice(1, 0, { show: false })
    }
    return data
}

const getAxis = (params: BarChartOptionProtocol, chartData: ChartLikeProtocol, yAxisParams: YAxisParam[]): AxisOption & { len: number } => {
    const { showAxisTitle, yMainAxisTitle, ySecondaryAxisTitle, stacking, showGridLine, mainAxis, secondaryAxis } = params
    const { xAxis } = chartData

    const xAxisArr = getXAxisDataArr(xAxis).reverse()
    // const mainAxisBar = mainAxis.filter(item => item.chartType === 'bar')
    // const secondaryAxisBar = secondaryAxis.filter(item => item.chartType === 'bar')
    const yData = yAxisParams.map((item, index) => {
        const yLen = index === 0 ? mainAxis.length : secondaryAxis.length
        const { min, max } = item
        const maxNum = stacking === 'stack' ? multiply(max, yLen || 1) : max
        const { maxValue, step } = getNumericalInterval(min, maxNum, 5)
        const title = index === 0 ? yMainAxisTitle : ySecondaryAxisTitle
        return {
            name: showAxisTitle ? title : undefined,
            nameGap: titleGap,
            nameTextStyle: {
                align: 'left',
                padding: [0, 0, 0, -30]
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisLabel: {
                color: '#98a2b3'
            },
            min: min < 0 ? min : 0,
            max: maxValue,
            interval: step,
            alignTicks: true,
            splitLine: {
                show: showGridLine,
                lineStyle: {
                    type: [6, 6],
                    color: '#E4E7EC'
                }
            }
        }
    })

    const XAxisData = getXAxisData(xAxisArr, params)

    const yAxisData: ECOption['yAxis'] = yData as ECOption['yAxis']
    return { xAxis: XAxisData, yAxis: yAxisData, len: xAxisArr.length }
}

const getBarItemStyle = (stackType: StackType | undefined, index: number, firstBarIndex: number, lastBarIndex: number) => {
    if (firstBarIndex === lastBarIndex || stackType !== 'stack') {
        return {
            borderRadius: 14
        }
    }
    if (index === firstBarIndex) {
        return {
            borderRadius: [0, 0, 14, 14]
        }
    }
    if (index === lastBarIndex) {
        return {
            borderRadius: [14, 14, 0, 0]
        }
    }
    return {}
}

const getSeriesData = (series: Series[], params: BarChartOptionProtocol) => {
    const { mainAxis, stacking, showLabel, mainMarkLines, secondaryMarkLines, secondaryAxis, chartType: type } = params
    // const labelPosition = transpose ? 'right' : 'top'
    const defaultChartType = type === 'line' ? 'line' : 'bar'

    const list = series.map(({ yAxisIndex }, index) => {
        const axisConfig = yAxisIndex === 0 ? mainAxis : secondaryAxis
        return axisConfig?.[index]?.chartType === 'bar'
    })

    const firstBarIndex = findIndex(bool => bool, list)
    const lastBarIndex = findLastIndex(bool => bool, list)
    // const secondSeries = series.filter(({ yAxisIndex }, index) => {
    //     const axisConfig = yAxisIndex === 0 ? mainAxis : secondaryAxis
    //     return axisConfig?.[index].chartType === 'bar'
    // })

    return series.map(({ name, value, yAxisIndex }, index) => {
        const stack = stacking === 'stack' ? String(yAxisIndex) : undefined
        const axisConfig = yAxisIndex === 0 ? mainAxis : secondaryAxis
        const { chartType = defaultChartType, showArea, lineType, fieldId } = axisConfig?.[index] || {}
        const data: BarSeriesOption | LineSeriesOption = {
            name,
            type: chartType as 'bar' | 'line',
            yAxisIndex,
            stack,
            data: value,
            areaStyle: {
                opacity: showArea ? 0.3 : 0
            },
            smooth: lineType === 'smooth',
            symbol: 'roundRect',
            label: {
                show: showLabel,
                position: 'top'
            },
            emphasis: {
                label: {
                    show: true, // 鼠标悬停时显示 label
                    position: 'top', // 可根据需求调整 label 位置
                    formatter: '{c}' // 显示数值
                    // color: '#000'  // 鼠标悬停时 label 的颜色
                }
            },
            barMaxWidth: 20
        }
        if (data.type === 'bar') {
            data.itemStyle = getBarItemStyle(stacking, index, firstBarIndex, lastBarIndex)
        }
        if (index === 0 && mainMarkLines?.length > 0) {
            const isMainAxis = yAxisIndex === 0
            const lines = isMainAxis ? mainMarkLines : secondaryMarkLines
            const markLines = lines.filter(item => item.value)
            data.markLine = {
                symbol: ['none', 'none'],
                data: markLines.map(item => ({
                    type: 'average',
                    yAxis: item.value,
                    lineStyle: {
                        color: item.color
                    },
                    label: {
                        position: isMainAxis ? 'start' : 'end',
                        formatter: '{c|{c}}',
                        rich: {
                            c: {
                                color: '#fff',
                                fontSize: 12,
                                backgroundColor: item.color,
                                borderRadius: 3,
                                padding: [3, 6]
                            }
                        }
                    }
                }))
            }
        }
        return data
    })
}

const getSeries = (params: BarChartOptionProtocol, chartData: ChartLikeProtocol): ECOption['series'] => {
    const { series } = chartData
    const mainSeries = series.filter(item => item.yAxisIndex === 0)
    const secondSeries = series.filter(item => item.yAxisIndex === 1)
    const mainSeriesData: ECOption['series'] = getSeriesData(mainSeries, params)
    const secondSeriesData: ECOption['series'] = getSeriesData(secondSeries, params)
    return [...mainSeriesData, ...secondSeriesData]
}

const getBarOption = (params: BarChartOptionProtocol, chartData?: ChartLikeProtocol) => {
    if (!chartData) {
        return {}
    }
    // const { stacking } = params
    // const isStack = stacking === 'stack'
    const { series } = chartData
    const yAxisParams = getYAxis(series)

    const { xAxis, yAxis, len } = getAxis(params, chartData, yAxisParams)

    const offsetY = multiply(40, max(len, 1))
    const commonOption = getCommonOption(params, chartData, yAxisParams, offsetY)
    const mergedOption: ECOption = {
        tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        ...commonOption,
        legend: getLegend(params, chartData),
        xAxis,
        yAxis,
        series: getSeries(params, chartData)
    }

    return mergedOption
}

export default getBarOption
