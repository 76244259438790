import { Button, IconFont, Text } from '@byecode/ui'
import type { ApplicationSettingNavbar, ApplicationSettingTheme } from '@lighthouse/core'
import { type ApplicationSettingLanguage, ApplicationSettingThemeNavBarMode, NavigationShowMode } from '@lighthouse/core'
import React, { useMemo } from 'react'

import { LANGUAGE_LIST } from '../../../constants'
import { useApplicationContext, useLanguageContext } from '../../../contexts'
import { useAppContainerContext, useAppThemeContext } from '..'
import { useAppNavbarStylesContext } from '../AppNavbarStylesContext'
import { LanguageBox as WebsiteLanguageBox } from './LanguageBox'
import { MobileLanguageBox } from './mobile'

interface LanguageBoxProps {
    isMobile?: boolean
    language: string
    showLabel?: boolean
    style?: React.CSSProperties
    onChange: (v: string) => void
}

export const LanguageBox: React.FunctionComponent<LanguageBoxProps> = ({ isMobile, language, showLabel, style, onChange }) => {
    const { showMode } = useAppContainerContext()
    const { languageList } = useLanguageContext()
    const { themeMode } = useAppNavbarStylesContext()

    const options = useMemo(
        () =>
            languageList?.map(item => {
                const LANG = LANGUAGE_LIST.find(lang => lang.lang === item.lang)
                return {
                    value: item.lang,
                    label: item.title,
                    icon: LANG?.flag,
                    extra: item.lang === language ? <IconFont color="var(--color-main)" size={16} type="Tick" /> : undefined
                }
            }) ?? [],
        [language, languageList]
    )

    const color = useMemo(
        () => (themeMode === ApplicationSettingThemeNavBarMode.light ? 'var(--color-gray-400)' : 'var(--color-white)'),
        [themeMode]
    )

    const option = useMemo(() => options.find(option => option.value === language), [language, options])

    const showLanguageLabel = useMemo(() => {
        if (showLabel) {
            return true
        }
        if (isMobile) {
            return false
        }
        return showMode === NavigationShowMode.horizontal
    }, [isMobile, showLabel, showMode])

    if (options.length < 2) {
        return null
    }

    if (isMobile) {
        return (
            <MobileLanguageBox
                option={option}
                options={options}
                showLabel={showLanguageLabel}
                color={color}
                value={language}
                style={style}
                onChange={onChange}
            />
        )
    }

    return (
        <WebsiteLanguageBox
            option={option}
            options={options}
            showLabel={showLanguageLabel}
            color={color}
            value={language}
            style={style}
            onChange={onChange}
        />
    )
}
