import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

export const hideScrollBar = (): FlattenSimpleInterpolation => css`
    ::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
    }
`

export const tinyButtons = (): FlattenSimpleInterpolation => css`
    scrollbar-width: 6px;

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #ccc;
        background-clip: content-box;
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
    }

    ::-webkit-scrollbar-track {
        border-radius: 5px;
        background: #fff0;
    }

    ::-webkit-scrollbar-button {
        color: #ccc;
        height: 0;
    }

    scrollbar-width: whin;
`

export const noButtons = (
    width = '6px',
    color = 'rgba(0, 0, 0, 0.38)',
    hoverColor = 'rgba(0, 0, 0, 0.54)'
): FlattenSimpleInterpolation => css`
    &::-webkit-scrollbar {
        width: ${width};
        height: ${width};
    }

    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: ${color};

        border: 0 none #fff;
        border-radius: 0;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: ${hoverColor};
    }
`

export const hoverScrollBar = (): FlattenSimpleInterpolation => css`
    /* 为支持Webkit内核的浏览器定义滚动条样式 */

    scrollbar-width: none; /* 隐藏Firefox中的默认滚动条 */
    -ms-overflow-style: none; /* 隐藏IE和Edge中的默认滚动条 */
    /* 隐藏滚动条以不占用空间（Firefox） */
    scrollbar-color: rgba(0, 0, 0, 0.5) transparent;

    ::-webkit-scrollbar {
        width: 8px; /* 滚动条的宽度 */
        height: 8px; /* 滚动条的高度（用于水平滚动条） */
    }

    ::-webkit-scrollbar-track {
        background: transparent; /* 滚动条轨道背景透明 */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ccc; /* 滚动条颜色 */
        border-radius: 10px; /* 滚动条圆角 */
        border: 2px solid transparent; /* 使滚动条不占用内容空间 */
        background-clip: content-box; /* 确保滚动条在元素内 */
        transition: background-color 0.3s ease; /* 悬停效果 */
    }

    /* 悬停时显示滚动条 */
    :hover::-webkit-scrollbar-thumb {
        background-color: #aaa; /* 增强滚动条颜色 */
    }

    /* 悬停时显示滚动条（Firefox） */
    :hover {
        scrollbar-width: thin;
        scrollbar-color: #ccc;
    }
`
