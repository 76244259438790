import { createStyles } from '../../../theme/createStyles'
import { css } from '../../../theme/stitches.config'

export const useStyles = createStyles(() => ({
    footer: css({
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 8,
        borderTop: '1px solid $colorGray200'
    })
}))
