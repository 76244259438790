import { useAtomData, USER_DATASOURCE } from '@lighthouse/shared'

import { userRecordAtom } from '@/atoms/auth/state'

import { useCurrentAppId, useCurrentEnvId } from './useApplication'
import { useDataSource } from './useDataSource'

export const useUserRecord = function () {
    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const datasource = useDataSource(appId, envId, USER_DATASOURCE)
    const userRecord = useAtomData(userRecordAtom)

    return { datasource, record: userRecord }
}
