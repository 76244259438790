import type { ChartLikeProtocol, Series, StackType, StriationChartOptionProtocol, YAxisParam } from '@lighthouse/core'
import type { BarSeriesOption, LineSeriesOption } from 'echarts/charts'
import { findIndex, findLastIndex, multiply } from 'rambda'

import type { ECOption } from '../../components'
import type { AxisOption, BarOption } from './common'
import {
    abbreviationAxisHeight,
    getCommonOption,
    getNumericalInterval,
    getXAxisDataArr,
    getYAxis,
    hasAbbreviationAxisChartType,
    HeadTitleHeight,
    legendOffset,
    legendWidth
} from './common'

export const getLegend = (params: StriationChartOptionProtocol, chartData: ChartLikeProtocol): ECOption['legend'] => {
    const { showHeadTitle, showLegend, legendTranspose, showAbbreviationAxis, chartType, mainAxis, showAxisTitle, yMainAxisTitle } = params
    const { legend } = chartData
    const axis = mainAxis
    const titleTop = showHeadTitle ? HeadTitleHeight + 7 : 0
    const yAxisTitleTop = showAxisTitle && yMainAxisTitle ? 30 : 0
    const legendConfigurator: ECOption['legend'] = {
        type: 'scroll',
        show: showLegend,
        // data: legend,
        bottom: legendOffset,
        data: legend.map((name, index) => ({
            name,
            icon:
                axis?.[index]?.chartType === 'line'
                    ? ''
                    : 'path://M8,0L32,0A8,8 0 0 1 40,8L40,32A8,8 0 0 1 32,40L8,40A8,8 0 0 1 0,32L0,8A8,8 0 0 1 8,0'
        }))
    }
    switch (legendTranspose) {
        case 'top': {
            legendConfigurator.top = 5 + titleTop
            legendConfigurator.right = 0
            break
        }
        case 'bottom': {
            // if (showAbbreviationAxis && hasAbbreviationAxisChartType.includes(chartType)) {
            //     legendConfigurator.bottom = abbreviationAxisHeight + legendOffset
            //     break
            // }
            legendConfigurator.bottom = legendOffset
            break
        }
        case 'left': {
            legendConfigurator.left = -3
            legendConfigurator.top = titleTop + yAxisTitleTop
            legendConfigurator.width = legendWidth
            legendConfigurator.orient = 'vertical'
            break
        }
        case 'right': {
            legendConfigurator.right = legendOffset
            legendConfigurator.width = legendWidth
            legendConfigurator.top = titleTop + yAxisTitleTop
            legendConfigurator.orient = 'vertical'
            break
        }
        default: {
            break
        }
    }

    return legendConfigurator
}

const getXAxisData = (arr: BarOption[][], params: StriationChartOptionProtocol) => {
    const { showAxisTitle, yMainAxisTitle } = params
    if (arr.length === 0) {
        return {}
    }
    const data = arr.map((item, index) => {
        // const offsetY = multiply(40, index)
        const xName = index === arr.length - 1 && showAxisTitle && yMainAxisTitle ? yMainAxisTitle : ''
        // const possessions = getXAxisItem(item)
        const data = item.map(item => item.label || ' ')
        const configurator: ECOption['xAxis'] | ECOption['yAxis'] = {
            data,
            type: 'category',
            name: xName,
            nameGap: xName ? 36 : undefined,
            nameLocation: 'end',
            position: 'bottom',
            axisLabel: {
                width: 80,
                hideOverlap: true,
                overflow: 'truncate',
                color: '#98a2b3'
            },
            axisTick: {
                show: false
            },
            minorSplitLine: {
                show: false
            },
            axisLine: {
                show: false
            },
            splitLine: {
                show: false,
                lineStyle: {
                    type: 'dashed'
                }
            }
        }
        return configurator
    })
    if (data.length > 1) {
        data.splice(1, 0, { show: false })
    }
    return data
}

const getAxis = (
    params: StriationChartOptionProtocol,
    chartData: ChartLikeProtocol,
    yAxisParams: YAxisParam[]
): AxisOption & { len: number } => {
    const { showAxisTitle, xAxisTitle, stacking, showGridLine, mainAxis } = params
    const { xAxis } = chartData

    const xAxisArr = getXAxisDataArr(xAxis).reverse()
    // const mainAxisBar = mainAxis.filter(item => item.chartType === 'bar')
    // const secondaryAxisBar = secondaryAxis.filter(item => item.chartType === 'bar')
    const yData = yAxisParams.map(item => {
        const yLen = mainAxis.length
        const { min, max } = item
        const maxNum = stacking === 'stack' ? multiply(max, yLen) : max
        const { maxValue, step } = getNumericalInterval(min, maxNum, 5)
        const title = xAxisTitle
        return {
            name: showAxisTitle ? title : undefined,
            type: 'value',
            nameGap: 50,
            nameLocation: 'middle',
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisLabel: {
                color: '#98a2b3'
            },
            min: min < 0 ? min : 0,
            max: maxValue,
            interval: step,
            alignTicks: true,
            splitLine: {
                show: showGridLine,
                lineStyle: {
                    type: [6, 6],
                    color: '#E4E7EC'
                }
            }
        }
    })
    const yAxisData: ECOption['xAxis'] = yData as ECOption['xAxis']
    const xAxisData = getXAxisData(xAxisArr, params)
    return { yAxis: xAxisData, xAxis: yAxisData, len: xAxisArr.length }
}

const getBarItemStyle = (stackType: StackType | undefined, index: number, firstBarIndex: number, lastBarIndex: number) => {
    if (firstBarIndex === lastBarIndex || stackType !== 'stack') {
        return {
            borderRadius: 14
        }
    }
    if (index === firstBarIndex) {
        return {
            borderRadius: [0, 0, 14, 14]
        }
    }
    if (index === lastBarIndex) {
        return {
            borderRadius: [14, 14, 0, 0]
        }
    }
    return {}
}

const getSeriesData = (series: Series[], params: StriationChartOptionProtocol) => {
    const { mainAxis, stacking, showLabel, mainMarkLines, chartType: type } = params
    // const labelPosition = transpose ? 'right' : 'top'
    const defaultChartType = 'bar'

    const list = series.map(({ yAxisIndex }, index) => {
        const axisConfig = mainAxis
        return axisConfig?.[index]?.chartType === 'bar'
    })

    const firstBarIndex = findIndex(bool => bool, list)
    const lastBarIndex = findLastIndex(bool => bool, list)
    // const secondSeries = series.filter(({ yAxisIndex }, index) => {
    //     const axisConfig = yAxisIndex === 0 ? mainAxis : secondaryAxis
    //     return axisConfig?.[index].chartType === 'bar'
    // })

    return series.map(({ name, value, yAxisIndex }, index) => {
        const stack = stacking === 'stack' ? String(yAxisIndex) : undefined
        const axisConfig = mainAxis
        const { chartType = defaultChartType, showArea, lineType, fieldId } = axisConfig?.[index] || {}
        const data: BarSeriesOption | LineSeriesOption = {
            name,
            type: defaultChartType,
            yAxisIndex,
            stack,
            data: value,
            // areaStyle: {
            //     opacity: showArea ? 0.3 : 0
            // },
            // smooth: lineType === 'smooth',
            // symbol: 'roundRect',
            label: {
                show: showLabel,
                position: 'right'
            },
            emphasis: {
                label: {
                    show: true, // 鼠标悬停时显示 label
                    position: 'top', // 可根据需求调整 label 位置
                    formatter: '{c}' // 显示数值
                    // color: '#000'  // 鼠标悬停时 label 的颜色
                }
            },
            barMaxWidth: 20
        }
        if (data.type === 'bar') {
            data.itemStyle = getBarItemStyle(stacking, index, firstBarIndex, lastBarIndex)
        }
        if (index === 0 && mainMarkLines?.length > 0) {
            const lines = mainMarkLines
            const markLines = lines.filter(item => item.value)
            data.markLine = {
                symbol: ['none', 'none'],
                data: markLines.map(item => ({
                    type: 'average',
                    xAxis: item.value,
                    lineStyle: {
                        color: item.color
                    },
                    label: {
                        position: 'start',
                        formatter: '{c|{c}}',
                        rich: {
                            c: {
                                color: '#fff',
                                fontSize: 12,
                                backgroundColor: item.color,
                                borderRadius: 3,
                                padding: [3, 6]
                            }
                        }
                    }
                }))
            }
        }
        return data
    })
}

const getSeries = (params: StriationChartOptionProtocol, chartData: ChartLikeProtocol): ECOption['series'] => {
    const { series } = chartData
    const mainSeries = series.filter(item => item.yAxisIndex === 0)
    const secondSeries = series.filter(item => item.yAxisIndex === 1)
    const mainSeriesData: ECOption['series'] = getSeriesData(mainSeries, params)
    const secondSeriesData: ECOption['series'] = getSeriesData(secondSeries, params)
    return [...mainSeriesData, ...secondSeriesData]
}

const getStriationOption = (params: StriationChartOptionProtocol, chartData?: ChartLikeProtocol) => {
    if (!chartData) {
        return {}
    }
    // const { stacking } = params
    // const isStack = stacking === 'stack'

    const xAxisParams = getYAxis(chartData.series)
    const { yAxis, xAxis, len } = getAxis(params, chartData, xAxisParams)
    const offsetY = multiply(40, len)
    const commonOption = getCommonOption(params, chartData, xAxisParams, offsetY)
    const mergedOption: ECOption = {
        tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        ...commonOption,
        legend: getLegend(params, chartData),
        xAxis,
        yAxis,
        series: getSeries(params, chartData)
    }

    return mergedOption
}

export default getStriationOption
