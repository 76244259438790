import type { DataSourceAbstract, FieldADTValue, FieldValue, RecordLikeProtocol } from '@lighthouse/core'
import type { AnyObject } from 'immer/dist/internal'

import type { ActionFlowNode, NodeInvokeResult, UpdateWorkflowLogPayload } from '../../types'
import { fieldConvertValue } from '../helper'

export interface LogMakerPayload extends UpdateWorkflowLogPayload {
    data: ActionFlowNode
    result: Record<string, string> & { record: RecordLikeProtocol }
    runtimeParams: AnyObject
}

/**
 *
 * @param code
 * @param message
 * @param data
 * @returns
 */
export const logMaker = (payload: NodeInvokeResult): UpdateWorkflowLogPayload => {
    const { status, nodeStatus, workflowId, nodeId, workflowInstanceId, result, runtimeParams } = payload

    let extraInfo: { output?: { label: string; type: string; value: string }[] } = {}

    if (result && result.record) {
        const { dsId, content } = result.record
        const dataSourceList = runtimeParams.dataSourceList as DataSourceAbstract[]
        const dataSource = dataSourceList.find(ds => ds.id === dsId)

        extraInfo = {
            output: content
                ? (Object.entries(content)
                      .map(([key, value]) => {
                          const field = dataSource?.schema[key]
                          if (!field) {
                              return undefined
                          }
                          return {
                              label: field?.name || '',
                              type: field?.type || '',
                              value: fieldConvertValue({ ...field, ...(value as FieldValue) } as FieldADTValue)
                          }
                      })
                      .filter(Boolean) as { label: string; type: string; value: string }[])
                : []
        }
    }

    // const dsId = data?.data?.config && 'dsId' in data.data.config ? data.data.config.dsId : undefined

    return {
        workflowId,
        workflowInstanceId,
        status,
        nodeId,
        nodeStatus: {
            ...nodeStatus,
            input: [],
            output: extraInfo?.output || []
        }
    }
}
