import type { DataSourceAbstract, FieldInputADTValue, FieldInputConfigProtocol } from '@lighthouse/core'
import React from 'react'
import type { Control } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'

import { FieldInput } from '../FieldInput'
import type { UseUploadFileSParameter } from '../UploadManage'

type FormInputList = {
    inputValueList: (FieldInputADTValue & { id: string })[]
    // appId: string
    // pointer: string
}

interface NotificationFormProps {
    appId?: string
    envId?: string
    recordId?: string
    dataSource?: DataSourceAbstract
    inputList: (FieldInputConfigProtocol & {
        id: string
    })[]
    control: Control<FormInputList>
    isValid: boolean
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
}

const SCxNotificationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`

export const NotificationForm: React.FC<NotificationFormProps> = ({
    appId = '',
    envId = '',
    recordId,
    dataSource,
    inputList,
    control,
    isValid,
    uploadyOptions
}) => {
    return (
        <SCxNotificationWrapper>
            {inputList.map(({ id, ...restInputItem }, index) => (
                <Controller
                    key={restInputItem.fieldPointer}
                    control={control}
                    name={`inputValueList.${index}.value`}
                    rules={
                        {
                            // required: inputList[index]?.required
                        }
                    }
                    render={({ field }) => {
                        // const block = inputList[index].canEdit ? 'form' : 'field'
                        return (
                            <FieldInput
                                config={{
                                    ...restInputItem,
                                    showTitle: true,
                                    uploadyOptions
                                }}
                                dataSource={dataSource}
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val.value)
                                }}
                                mode="datasource"
                                recordId={recordId}
                                record={recordId ? { id: recordId, envId, content: {}, appId, dsId: '' } : undefined}
                                // blockType='form'
                                isValid={isValid}
                            />
                        )
                    }}
                />
            ))}
        </SCxNotificationWrapper>
    )
}
