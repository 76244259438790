import { Tooltip } from '@byecode/ui'
import { findIndex } from 'rambda'
import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { TooltipListContextProvider } from './TooltipListContext'

const SCxPersonExtra = styled.div`
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const SCxPersonHidden = styled.div`
    font-size: var(--font-size-normal);
    padding: 0 4px;
    height: 24px;
    line-height: 22px;
    background-color: var(--color-gray-200);
    color: var(--color-black);
    border-radius: 4px;
`

const SCxPerSonToolTipItem = styled.div`
    max-width: 400px;
    white-space: normal;
    word-wrap: break-word;
    line-height: 16px;
    font-size: var(--font-size-sm);
    color: var(--color-white);
`

export interface TooltipListItem {
    label: string | number
    value: string | number
}

interface TooltipListProps {
    data?: TooltipListItem[]
    width: number
    disabled?: boolean

    render: (
        visibleNum: number

        // ref: React.MutableRefObject<any>
    ) => React.ReactElement
}

export const TooltipList = React.memo<TooltipListProps>(({ data = [], width: containerWidth, disabled, render }) => {
    const [personsWidth, setPersonsWidth] = useState(data.map(({ value }, index) => ({ width: 0, id: value })))
    const handleChangeWidth = useCallback((width: number, id: number | string) => {
        const newWidth = width ? 16 + width + 2 : 0
        if (!newWidth) {
            return
        }
        setPersonsWidth(prev => {
            const index = findIndex(item => item.id === id, prev)
            if (index !== -1) {
                prev[index] = { width: newWidth, id }
                return [...prev]
            }
            return [...prev, { id, width: newWidth }]
        })
    }, [])

    const remainingWidth = useMemo(() => containerWidth - personsWidth[0]?.width || 0, [containerWidth, personsWidth])

    const { visibleNum } = disabled
        ? { visibleNum: data.length }
        : personsWidth.slice(1).reduce(
              (acc, { width }) => {
                  const { remainingWidth } = acc
                  // if (width === 0) {
                  //     return acc
                  // }
                  if (remainingWidth > 60) {
                      acc.remainingWidth -= width
                      acc.visibleNum++
                  }
                  return acc
              },
              { visibleNum: 1, remainingWidth }
          )

    const remainingIndex = data.length - visibleNum

    return (
        <TooltipListContextProvider onWidthChange={handleChangeWidth}>
            <>
                {render(visibleNum)}
                {remainingIndex > 0 && (
                    <Tooltip
                        disabled={data.length === 0}
                        title={
                            <>
                                {data.slice(-1 * remainingIndex).map(({ value, label }) => {
                                    return <SCxPerSonToolTipItem key={value}>{label}</SCxPerSonToolTipItem>
                                })}
                            </>
                        }
                    >
                        <SCxPersonExtra>
                            <SCxPersonHidden>+{remainingIndex}</SCxPersonHidden>
                        </SCxPersonExtra>
                    </Tooltip>
                )}
            </>
        </TooltipListContextProvider>
    )
})
