import styled from 'styled-components'

export const Root = styled.div`
    padding: 8px 16px;
    border-bottom: 1px solid var(--color-gray-100);
`

export const Label = styled.div`
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-gray-400);
`

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 8px;
`
