import { mergeRefs } from '@lighthouse/tools'
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

import * as SC from './styles'
import type { TextareaProps } from './types'

export const Textarea = React.forwardRef<
    HTMLTextAreaElement,
    TextareaProps & {
        onEnter?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
        onTextareaChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    }
>(
    (
        {
            className,
            placeholder,
            focusOutLineColor,
            bordered = true,
            enableBackgroundColor = true,
            textAlign = 'left',
            textSize = '14px',
            autoFocusAndSelect,
            autoFocus = false,
            autoComplete = 'off',
            autoSize = true,
            onFocus,
            onBlur,
            onTextareaChange,
            onEnter,
            ...restProps
        },
        ref
    ) => {
        const TextareaRef = useRef<HTMLTextAreaElement>(null)
        const BoxRef = useRef<HTMLDivElement>(null)
        const isComposition = useRef<boolean>(true)

        const fitToContent = useCallback(() => {
            const text = TextareaRef.current
            if (!text) {
                return
            }
            text.style.height = '25px'
            text.style.height = `${text.scrollHeight}px`
        }, [])

        const initScrollToBottom = useCallback(() => {
            const text = TextareaRef.current
            if (!text) {
                return
            }
            text.scrollTop = text.scrollHeight
        }, [])

        const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
            onFocus?.(event)
        }

        const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
            onBlur?.(event)
            // setIsComposition(false)
        }

        const handleCompositionStart = useCallback(() => {
            isComposition.current = false
        }, [])

        const handleCompositionEnd = useCallback(() => {
            isComposition.current = true
        }, [])

        const handleKeyDown = useCallback(
            (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                const textareaElement = TextareaRef.current
                const boxElement = BoxRef.current
                if (e.code === 'Enter' && !e.shiftKey && textareaElement && boxElement && isComposition.current) {
                    e.preventDefault()
                    TextareaRef.current.blur()
                    onEnter?.(e)
                }
            },
            [onEnter]
        )

        const handleTextareaChange = useCallback(
            /**  @ts-expect-error event error */
            event => {
                if (typeof onTextareaChange !== 'function') {
                    return
                }
                if (!(isComposition ?? true)) {
                    return
                }
                onTextareaChange(event)
            },
            [isComposition, onTextareaChange]
        )

        useEffect(() => {
            const TextareaObj = TextareaRef.current
            if (autoFocusAndSelect && TextareaObj) {
                TextareaRef.current.select()
            }
            if (autoFocus && TextareaObj) {
                TextareaObj.focus()
                TextareaObj.setSelectionRange(-1, -1)
            }
        }, [autoFocus, autoFocusAndSelect, restProps.value])

        useLayoutEffect(() => {
            fitToContent()
        }, [fitToContent])

        useEffect(() => {
            const TextareaEl = TextareaRef.current

            if (!TextareaEl) {
                return
            }
            TextareaEl.addEventListener('compositionstart', handleCompositionStart)
            TextareaEl.addEventListener('compositionend', handleCompositionEnd)
            TextareaEl.addEventListener('change', handleTextareaChange)
            // initScrollToBottom()
            return () => {
                TextareaEl.removeEventListener('compositionstart', handleCompositionStart)
                TextareaEl.removeEventListener('compositionend', handleCompositionEnd)
                TextareaEl.removeEventListener('change', handleTextareaChange)
            }
        }, [fitToContent, handleCompositionEnd, handleCompositionStart, handleTextareaChange, initScrollToBottom])

        return (
            <SC.TextareaWrapper
                focusOutLineColor={focusOutLineColor}
                bordered={bordered}
                highlightable={restProps.highlightable}
                enableBackgroundColor={enableBackgroundColor}
                className={className}
                ref={BoxRef}
            >
                <SC.TextareaContent
                    ref={mergeRefs([ref, TextareaRef])}
                    onKeyDown={handleKeyDown}
                    wrap="hard"
                    textAlign={textAlign}
                    textSize={textSize}
                    bordered={bordered}
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    autoComplete={autoComplete}
                    onChange={fitToContent}
                    {...restProps}
                />
            </SC.TextareaWrapper>
        )
    }
)
