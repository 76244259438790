import { Tabs } from '@mantine/core'
import styled from 'styled-components'

export const TableTab = styled(Tabs)`
    margin-bottom: 12px;
    padding: 0 5px;

    .mantine-Tabs-tab[data-active='true'] {
        font-weight: 600;
        color: var(--color-gray-900);
        border-color: var(--color-gray-900) !important;
    }

    .mantine-Tabs-tab {
        color: var(--color-gray-500);
        padding: 8px 0;
        margin-right: 16px;
    }

    .mantine-Tabs-tab:hover {
        background: transparent;
        border-color: transparent;
    }
`
