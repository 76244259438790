import { Empty } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { IframeBlockAbstract } from '@lighthouse/core'
import { getJumpUrl } from '@lighthouse/shared'
import React, { useMemo, useRef } from 'react'

import * as SC from './styles'

interface IframeBlockProps {
    blockData: IframeBlockAbstract
}

const IframeBlock: React.FC<IframeBlockProps> = ({ blockData }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const containRef = useRef<HTMLDivElement>(null)
    const { id, config } = blockData
    const { source = '' } = config
    // const { ref: containRef, width: containerWidth } = useElementSize()
    const isEmpty = useMemo(() => !source, [source])

    const sourceUrl = getJumpUrl(source)

    return (
        <SC.IframeContainer data-block-id={id} ref={containRef} data-stop-action-propagation>
            {isEmpty ? (
                <Empty
                    styles={{
                        root: {
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'var(--color-white)',
                            border: '1px solid var(--color-gray-200)',
                            borderRadius: 'inherit'
                        }
                    }}
                    icon={<SC.EmptyImg src={getAssetUrl('empty', 'no_iframe.svg')} alt="empty_iframe" />}
                    description="请在右侧填入网页链接"
                />
            ) : (
                <SC.Iframe ref={iframeRef} src={sourceUrl} title={id} />
            )}
        </SC.IframeContainer>
    )
}

export default IframeBlock
