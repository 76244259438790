import { Flex, IconFont, Popover, Text } from '@byecode/ui'
import type { VariableADTvalue } from '@lighthouse/core'
import { VariableType } from '@lighthouse/core'
import type { NodeView, NodeViewContent } from '@tiptap/react'
import { NodeViewWrapper } from '@tiptap/react'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { makeMatchPI } from 'ts-adt/MakeADT'

import { USER_DATASOURCE } from '../../../constants'
import { DATE_FORMAT } from '../../../utils/helper'
import { parseVariableDate } from '../../../utils/variable'
import { VariableTag } from '../../Variable/VariableTag'
import type { VariableExtensionOptions } from './Extension'

const Item = styled.div`
    height: 36px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
        background-color: var(--color-gray-100);
    }
`

interface OptionProps {
    active: boolean
    data: { label: string; value: string }
    onSelect?: (value: string) => void
    fieldFormat?: string
}

const Option = ({ active, data, onSelect, fieldFormat = DATE_FORMAT }: OptionProps) => {
    // const { context } = usePopoverContext()

    return (
        <Item
            onClick={() => {
                onSelect?.(data.value)
                // context.onOpenChange(false)
            }}
        >
            <Flex gap={8} alignItems="center">
                <IconFont style={{ opacity: active ? 1 : 0 }} type="Tick" color="var(--color-main)" />
                <Text size={14}>{data.label}</Text>
            </Flex>
            <Text color="var(--color-gray-400)" size={14}>
                {parseVariableDate(Date.now(), data.value || fieldFormat)}
            </Text>
        </Item>
    )
}

const matchPIVariable = makeMatchPI<'type'>('type')

const DATE_FORMAT_OPTIONS = [
    {
        label: '默认',
        value: ''
    },
    {
        label: '日期时间',
        value: 'yyyy-MM-dd HH:mm'
    },
    {
        label: '日期',
        value: 'yyyy-MM-dd'
    },
    {
        label: '时分',
        value: 'HH:mm'
    },
    {
        label: '年',
        value: 'yyyy'
    },
    {
        label: '月',
        value: 'MM'
    },
    {
        label: '日',
        value: 'dd'
    },
    {
        label: '月日',
        value: 'MM-dd'
    },
    {
        label: '星期',
        value: 'EEEE'
    },
    {
        label: '农历-年',
        value: 'lunar-y'
    },
    {
        label: '农历-月日',
        value: 'lunar-md'
    },
    {
        label: '农历-时辰',
        value: 'lunar-h'
    }
]

function isActive(value: VariableADTvalue, format: string) {
    return matchPIVariable(value)(
        {
            [VariableType.PAGE]: v => (v.pageVariable?.format || '') === format,
            [VariableType.USER]: v => (v.userVariable?.format || '') === format,
            [VariableType.VIEW]: v => (v.viewVariable?.format || '') === format,
            [VariableType.SYSTEM]: v => (v.systemVariable?.format || '') === format
        },
        () => false
    )
}

export const VariableBlockComponent: FC<NodeView<typeof NodeViewContent>> = props => {
    const { node, deleteNode, extension, editor, updateAttributes } = props

    const extensionOptions = extension.options as VariableExtensionOptions

    const value = node.attrs.value as VariableADTvalue

    const variableInfo = useMemo(() => {
        return matchPIVariable(value)(
            {
                [VariableType.PAGE]: v => {
                    const dsId = v.pageVariable?.dsId
                    const fieldId = v.pageVariable?.fieldId
                    if (!dsId || !fieldId) {
                        return
                    }
                    const ds = extensionOptions.dataSources.find(item => item.id === dsId)
                    if (!ds) {
                        return
                    }

                    const field = ds.schema[fieldId]

                    return {
                        dataSource: ds,
                        field,
                        showDateFormat: field?.type === 'date'
                    }
                },
                [VariableType.USER]: v => {
                    const fieldId = v.userVariable?.fieldId
                    if (!fieldId) {
                        return
                    }
                    const ds = extensionOptions.dataSources.find(item => item.id === USER_DATASOURCE)
                    if (!ds) {
                        return
                    }

                    const field = ds.schema[fieldId]

                    return {
                        dataSource: ds,
                        field,
                        showDateFormat: field?.type === 'date'
                    }
                },
                [VariableType.VIEW]: v => {
                    const fieldId = v.viewVariable?.fieldId
                    if (!fieldId) {
                        return
                    }

                    const ds = extensionOptions.dataSources.find(item => Object.keys(item.schema).includes(fieldId))
                    if (!ds) {
                        return
                    }

                    const field = ds.schema[fieldId]
                    return {
                        dataSource: ds,
                        field,
                        showDateFormat: field?.type === 'date'
                    }
                },
                [VariableType.SYSTEM]: () => ({
                    dataSource: undefined,
                    field: undefined,
                    showDateFormat: true
                })
            },
            () => ({
                dataSource: undefined,
                field: undefined,
                showDateFormat: false
            })
        )
    }, [extensionOptions.dataSources, value])
    const label = extensionOptions.renderLabel(node)
    // const tooltip = extensionOptions.renderTooltip?.(node)

    return (
        <NodeViewWrapper as="span" style={{ whiteSpace: 'inherit' }}>
            {editor.isEditable ? (
                <Popover
                    width={260}
                    trapFocus={false}
                    withinPortal
                    disabled={!variableInfo || !variableInfo.showDateFormat}
                    trigger="click"
                >
                    <Popover.Target>
                        <VariableTag
                            withClose
                            onClose={deleteNode}
                            value={value}
                            options={extensionOptions.options}
                            userDsOption={extensionOptions.userOption}
                            dataSourceOption={extensionOptions.dataSourceOption}
                            viewOption={extensionOptions.viewOption}
                        />
                    </Popover.Target>
                    <Popover.Dropdown>
                        {DATE_FORMAT_OPTIONS.map(item => (
                            <Option
                                key={item.value}
                                fieldFormat={
                                    variableInfo && variableInfo.field?.type === 'date' ? variableInfo.field.date.format : 'yyyy-MM-dd'
                                }
                                data={item}
                                active={isActive(value, item.value)}
                                onSelect={format => {
                                    updateAttributes({
                                        value: matchPIVariable(value)(
                                            {
                                                [VariableType.PAGE]: v => {
                                                    if (!v.pageVariable) {
                                                        return v
                                                    }
                                                    return {
                                                        ...v,
                                                        pageVariable: {
                                                            ...v.pageVariable,
                                                            format
                                                        }
                                                    }
                                                },
                                                [VariableType.USER]: v => {
                                                    if (!v.userVariable) {
                                                        return v
                                                    }
                                                    return {
                                                        ...v,
                                                        userVariable: {
                                                            ...v.userVariable,
                                                            format
                                                        }
                                                    }
                                                },
                                                [VariableType.VIEW]: v => {
                                                    if (!v.viewVariable) {
                                                        return v
                                                    }

                                                    return {
                                                        ...v,
                                                        viewVariable: {
                                                            ...v.viewVariable,
                                                            format
                                                        }
                                                    }
                                                },
                                                [VariableType.SYSTEM]: v => {
                                                    if (!v.systemVariable) {
                                                        return v
                                                    }

                                                    return {
                                                        ...v,
                                                        systemVariable: {
                                                            ...v.systemVariable,
                                                            format
                                                        }
                                                    }
                                                }
                                            },
                                            () => value
                                        )
                                    })
                                }}
                            />
                        ))}
                    </Popover.Dropdown>
                </Popover>
            ) : (
                label
            )}
        </NodeViewWrapper>
    )
}
