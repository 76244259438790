import { Tooltip, useTooltip } from '@byecode/ui'
import type { DataSourceAbstract, VariableADTvalue } from '@lighthouse/core'
import { VariableType } from '@lighthouse/core'
import cls from 'classnames'
import { find } from 'rambda'
import React, { forwardRef, useEffect, useMemo } from 'react'

import { variableSystemNameMap } from '../../../constants'
import { getVariableItem } from '../help'
import type { VariableOptions, VariableTree } from '../types'
import * as SC from './styles'

interface VariableTagProps {
    value?: VariableADTvalue
    options?: VariableOptions
    userDsOption?: VariableTree
    inputOption?: VariableTree
    dataSourceOption?: VariableTree
    formOption?: VariableTree
    viewOption?: VariableTree
    filterOptions?: VariableTree[]
    dataSourceList?: DataSourceAbstract[]
    withClose?: boolean
    isSelected?: boolean
    style?: React.CSSProperties
    onClose?: () => void
    onOpenSelectDataSourceModal?: () => void
}

export const VariableTag = forwardRef<HTMLElement, VariableTagProps>(
    (
        {
            value: data,
            options,
            userDsOption,
            viewOption,
            formOption,
            dataSourceOption,
            inputOption,
            filterOptions,
            withClose,
            isSelected,
            dataSourceList,
            style,
            onOpenSelectDataSourceModal,
            onClose,
            ...rest
        },
        ref
    ) => {
        const { open, setOpen } = useTooltip()

        useEffect(() => {
            if (isSelected) {
                setOpen(true)
            } else {
                setOpen(false)
            }
        }, [isSelected, setOpen])

        const tooltipData = useMemo(() => {
            switch (data?.type) {
                case VariableType.INPUT: {
                    const childNode = find(item => item.id === data.inputVariable?.blockId, inputOption?.children ?? [])
                    return childNode ? [`${inputOption?.title}/${childNode?.title}`] : []
                }

                case VariableType.SYSTEM: {
                    return [`常用日期/${data.systemVariable?.value ? variableSystemNameMap[data.systemVariable?.value] : ''}`]
                }
                case VariableType.USER: {
                    const childNode = find(item => item.id === data.userVariable?.fieldId, userDsOption?.children ?? [])
                    return childNode ? [`${userDsOption?.title}/${childNode?.title}`] : []
                }
                case VariableType.FORM: {
                    const childNode = find(item => item.id === data.formVariable?.fieldId, formOption?.children ?? [])
                    return childNode ? [`${formOption?.title}/${childNode?.title}`] : []
                }
                case VariableType.VIEW: {
                    const childNode = find(item => item.id === data.viewVariable?.fieldId, viewOption?.children ?? [])
                    return childNode ? [`${viewOption?.title}/${childNode?.title}`] : []
                }
                case VariableType.PAGE:
                case VariableType.VARIABLE:
                case VariableType.ARG_VARIABLE:
                case VariableType.UPSTREAM: {
                    const flatOptions = options?.flatMap(item => item.list)
                    const [parentNode, childNode] = getVariableItem(flatOptions ?? [], data) ?? []
                    return childNode ? [`${parentNode?.title}/${childNode?.title}`] : []
                }
                default: {
                    return []
                }
            }
        }, [
            data,
            formOption?.children,
            formOption?.title,
            inputOption?.children,
            inputOption?.title,
            options,
            userDsOption?.children,
            userDsOption?.title,
            viewOption?.children,
            viewOption?.title
        ])

        const errTagEle = useMemo(
            () => (
                <SC.ErrorTag>
                    <SC.Text color="var(--color-red-500)">找不到变量</SC.Text>
                    {withClose && <SC.Icon type="Close" color="var(--color-red-500)" size={12} onClick={onClose} />}
                </SC.ErrorTag>
            ),
            [onClose, withClose]
        )

        const ele = useMemo(() => {
            switch (data?.type) {
                case VariableType.SYSTEM: {
                    if (data.systemVariable) {
                        return (
                            <>
                                <SC.Text>
                                    常用日期/{data.systemVariable?.value && variableSystemNameMap[data.systemVariable?.value]}
                                </SC.Text>
                                {withClose && <SC.Icon type="Close" size={12} onClick={onClose} />}
                            </>
                        )
                    }
                    return null
                }
                case VariableType.UPSTREAM: {
                    const flatOptions = options?.flatMap(item => item.list)
                    const [parentNode, childNode] = getVariableItem(flatOptions ?? [], data) ?? []
                    if (parentNode && childNode) {
                        return (
                            <>
                                <SC.Text>
                                    {parentNode?.title}/{childNode?.title}
                                </SC.Text>
                                {withClose && <SC.Icon type="Close" size={12} onClick={onClose} />}
                            </>
                        )
                    }
                    return data?.upstreamVariable ? errTagEle : null
                }
                case VariableType.ARG_VARIABLE: {
                    const flatOptions = options?.flatMap(item => item.list)
                    const [parentNode, childNode] = getVariableItem(flatOptions ?? [], data) ?? []
                    if (parentNode && childNode) {
                        return (
                            <>
                                <SC.Text>
                                    {parentNode?.title}/{childNode?.title}
                                </SC.Text>
                                {withClose && <SC.Icon type="Close" size={12} onClick={onClose} />}
                            </>
                        )
                    }
                    return data?.argVariable ? errTagEle : null
                }
                case VariableType.VARIABLE: {
                    const flatOptions = options?.flatMap(item => item.list)
                    const [parentNode, childNode] = getVariableItem(flatOptions ?? [], data) ?? []
                    if (parentNode && childNode) {
                        const index = parentNode?.description?.indexOf(':') ?? -1
                        return (
                            <>
                                <SC.Text>
                                    {parentNode.title}/{childNode?.title}
                                </SC.Text>
                                {withClose && <SC.Icon type="Close" size={12} onClick={onClose} />}
                            </>
                        )
                    }
                    return data?.fieldVariable ? errTagEle : null
                }
                case VariableType.PAGE: {
                    const flatOptions = options?.flatMap(item => item.list)

                    const [parentNode, childNode] = getVariableItem(flatOptions ?? [], data) ?? []
                    if (parentNode && childNode) {
                        return (
                            <>
                                <SC.Text>
                                    {parentNode.title}/{childNode?.title}
                                </SC.Text>
                                {withClose && <SC.Icon type="Close" size={12} onClick={onClose} />}
                            </>
                        )
                    }
                    return data?.pageVariable ? errTagEle : null
                }
                case VariableType.USER: {
                    const childNode = find(item => item.id === data.userVariable?.fieldId, userDsOption?.children ?? [])
                    if (childNode) {
                        return (
                            <>
                                <SC.Text>
                                    {userDsOption?.title}/ {childNode?.title}
                                </SC.Text>
                                {withClose && <SC.Icon type="Close" size={12} onClick={onClose} />}
                            </>
                        )
                    }
                    return data.userVariable ? errTagEle : null
                }
                case VariableType.SELECT_DATASOURCE: {
                    const childNode = find(item => item.id === data.selectDataSourceVariable?.dsId, dataSourceList ?? [])
                    if (childNode) {
                        return (
                            <span
                                onClick={e => {
                                    e.stopPropagation()
                                    onOpenSelectDataSourceModal?.()
                                }}
                            >
                                <SC.Text>查询数据表: {childNode?.name}</SC.Text>
                                <SC.Icon type="edit" size={12} color="var(--color-main)" />
                            </span>
                        )
                    }
                    return data.selectDataSourceVariable?.fieldId ? errTagEle : null
                }
                case VariableType.FIELD_ID: {
                    const childNode = find(item => item.id === data.fieldIdVariable?.fieldId, dataSourceOption?.children ?? [])
                    if (childNode) {
                        return (
                            <>
                                <SC.Text>{childNode?.title}</SC.Text>
                            </>
                        )
                    }
                    return data.fieldIdVariable ? errTagEle : null
                }
                case VariableType.INPUT: {
                    const childNode = find(item => item.id === data.inputVariable?.blockId, inputOption?.children ?? [])
                    if (childNode) {
                        return (
                            <>
                                <SC.Text>
                                    {inputOption?.title}/{childNode.title}
                                </SC.Text>
                            </>
                        )
                    }
                    return data.inputVariable ? errTagEle : null
                }
                case VariableType.FORM: {
                    const childNode = find(item => item.id === data.formVariable?.fieldId, formOption?.children ?? [])
                    if (childNode) {
                        return (
                            <>
                                <SC.Text>
                                    {formOption?.title}/{childNode.title}
                                </SC.Text>
                            </>
                        )
                    }
                    return data.formVariable ? errTagEle : null
                }
                case VariableType.VIEW: {
                    const childNode = find(item => item.id === data.viewVariable?.fieldId, viewOption?.children ?? [])
                    if (childNode) {
                        return (
                            <>
                                <SC.Text>
                                    {viewOption?.title}/{childNode.title}
                                </SC.Text>
                                {withClose && <SC.Icon type="Close" size={12} onClick={onClose} />}
                            </>
                        )
                    }
                    return data.viewVariable ? errTagEle : null
                }
                case VariableType.PAGE_LINK: {
                    const text = data.pageLinkVariable?.value === 'CURRENT_PAGE' ? '当前页面链接' : ''
                    return (
                        <>
                            <SC.Text>{text}</SC.Text>
                            {withClose && <SC.Icon type="Close" size={12} onClick={onClose} />}
                        </>
                    )
                }
                case VariableType.FILTER: {
                    const parentNode = find(item => item.id === data.filterVariable?.blockId, filterOptions ?? [])
                    const childNode = find(item => item.id === data.filterVariable?.itemId, parentNode?.children ?? [])
                    if (childNode) {
                        return (
                            <>
                                <SC.Text>
                                    {parentNode?.title}/{childNode.title}
                                </SC.Text>
                                {withClose && <SC.Icon type="Close" size={12} onClick={onClose} />}
                            </>
                        )
                    }
                    return data.filterVariable ? errTagEle : null
                }
                default: {
                    return errTagEle
                }
            }
        }, [
            data,
            dataSourceList,
            dataSourceOption?.children,
            errTagEle,
            filterOptions,
            formOption?.children,
            formOption?.title,
            inputOption?.children,
            inputOption?.title,
            onClose,
            onOpenSelectDataSourceModal,
            options,
            userDsOption?.children,
            userDsOption?.title,
            viewOption?.children,
            viewOption?.title,
            withClose
        ])

        return (
            <Tooltip
                interactive
                ref={ref}
                open={open}
                disabled={tooltipData.filter(Boolean).length === 0}
                title={
                    <SC.TooltipLabel>
                        <SC.Text>变量详情</SC.Text>
                        {tooltipData.map((label, index) => (
                            <li key={index}>{label}</li>
                        ))}
                    </SC.TooltipLabel>
                }
                onOpenChange={setOpen}
            >
                <SC.TagContainer style={style} {...rest}>
                    <SC.TagWrapper className={cls({ selected: isSelected })}>{ele}</SC.TagWrapper>
                </SC.TagContainer>
            </Tooltip>
        )
    }
)
