import { Button, IconFont, Menu } from '@byecode/ui'
import type { ActionItem, ButtonAction, ButtonSizeType, RichTextContentProtocol, Shape } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useActionRunningLoadings } from '../../hooks'
import { ActionButton } from '../ActionButton'

interface TailButtonGroupProps {
    style?: React.CSSProperties
    size?: ButtonSizeType
    actions?: ActionItem[]
    shape?: Shape
    radius?: string
    onActionTrigger?: (params: ButtonAction) => Promise<boolean | undefined>
    onRenderTitle: (v: RichTextContentProtocol) => string
}

const SCxButtonsContainer = styled.div`
    display: flex;
    gap: 8px;
    text-align: left;
    color: var(--color-gray-900);
`
const EXTRA_BUTTON_SIZE_MAP = new Map<ButtonSizeType, number>([
    ['xxl', 62],
    ['xl', 50],
    ['lg', 42],
    ['md', 38],
    ['sm', 34],
    ['xs', 30]
])

export const TailActionButtonGroup: React.FC<TailButtonGroupProps> = ({
    actions,
    size,
    radius = '8',
    shape = 'rectangle',
    style,
    onActionTrigger,
    onRenderTitle
}) => {
    const extraButtonSize = EXTRA_BUTTON_SIZE_MAP.get(size ?? 'sm')

    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()

    return useMemo(() => {
        const actionList = actions
        if (!actionList?.length) {
            return null
        }
        const firstTwoActions = actionList.slice(0, 2)
        const extraActions = actionList.slice(2)
        return (
            <SCxButtonsContainer style={style}>
                {firstTwoActions.map(item => {
                    const { action, id } = item
                    return (
                        <ActionButton
                            key={id}
                            size={size}
                            radius={shape === 'rectangle' ? radius : '100'}
                            config={item}
                            // loading={loadings[id]}
                            disabled={loadings[id]}
                            onClick={() => handleActionTriggerWithLoading({ action, id, type: 'click', trigger: onActionTrigger })}
                            onRenderTitle={onRenderTitle}
                        />
                    )
                })}
                {extraActions.length > 0 && (
                    <Menu width={180} position="bottom-end" closeOnItemClick>
                        <Menu.Target>
                            <Button
                                radius={100}
                                style={{ width: extraButtonSize, height: extraButtonSize }}
                                icon={<IconFont type="DotsThree" />}
                            />
                        </Menu.Target>
                        <Menu.Dropdown styles={{ dropdown: { maxHeight: 200, overflowY: 'auto' } }}>
                            {extraActions.map(item => {
                                const { action, name, id } = item
                                return (
                                    <Menu.Item key={id} onClick={() => onActionTrigger?.(action)}>
                                        {onRenderTitle(name)}
                                    </Menu.Item>
                                )
                            })}
                        </Menu.Dropdown>
                    </Menu>
                )}
            </SCxButtonsContainer>
        )
    }, [actions, extraButtonSize, handleActionTriggerWithLoading, loadings, onActionTrigger, onRenderTitle, radius, shape, size, style])
}
