import type { InteractionState, OverDescriptor } from './types'

export enum Action {
    DragStart,
    DragMove,
    DragOver,
    DragEnd,
    DragCancel
}

export type DragStartPayload = {
    type: Action.DragStart
    activeId: string
    initialX: number
    initialY: number
}

export type DragMovePayload = {
    type: Action.DragMove
    x: number
    y: number
}

export type DragOverPayload = {
    type: Action.DragOver
    overDescriptor: OverDescriptor | null
}

export type DragEndPayload = {
    type: Action.DragEnd
    overDescriptor: OverDescriptor | null
}

export type DragCancelPayload = {
    type: Action.DragCancel
}

export type DragPayload = DragStartPayload | DragMovePayload | DragOverPayload | DragEndPayload | DragCancelPayload

export const reducer: React.Reducer<InteractionState, DragPayload> = (state, payload): InteractionState => {
    switch (payload.type) {
        case Action.DragStart: {
            return {
                ...state,
                activeId: payload.activeId,
                initialX: payload.initialX,
                initialY: payload.initialY,
                x: payload.initialX,
                y: payload.initialY
            }
        }
        case Action.DragMove: {
            return {
                ...state,
                x: payload.x,
                y: payload.y
            }
        }
        case Action.DragOver: {
            return {
                ...state,
                overDescriptor: payload.overDescriptor
            }
        }
        case Action.DragEnd: {
            return {
                ...state,
                activeId: null,
                overDescriptor: payload.overDescriptor,
                x: 0,
                y: 0
            }
        }
        case Action.DragCancel: {
            return {
                ...state,
                activeId: null,
                overDescriptor: null,
                initialX: 0,
                initialY: 0,
                x: 0,
                y: 0
            }
        }

        default: {
            return state
        }
    }
}
