import { useCustomViewBlockContext } from '@lighthouse/block'
import type { RichTextContentProtocol } from '@lighthouse/core'
import { generateText, useAppContainerContext, useApplicationContext } from '@lighthouse/shared'
import { useCallback } from 'react'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'

import { usePageDataSourceForVariableSelector } from './usePage'
import { useVariableValueRender } from './useVariableValueRender'

export function useRichTextToTitle() {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { renderLabel } = useVariableValueRender(pageId, prev.recordId, curr.recordId)
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()

    const { record: viewRecord } = useCustomViewBlockContext()

    const handleRenderTitle = useCallback(
        (v: RichTextContentProtocol) => {
            return generateText(v, { variable: { renderLabel: v => renderLabel(v.attrs.value, { personOptions, roleOptions, departmentOptions, viewRecord }) } })
        },
        [departmentOptions, personOptions, renderLabel, roleOptions, viewRecord]
    )

    return { handleRenderTitle }
}
