// import { ErrorFallback } from '@lighthouse/block'
import { Loading } from '@byecode/ui'
import { RecordOpenType } from '@lighthouse/core'
import type { ErrorCode } from '@lighthouse/shared'
import {
    CUSTOM_ROUTE_PAGE_URL_REG,
    initBlockRuntimeState,
    noAccessCodeList,
    PAGE_URL_REG,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import type { AxiosError } from 'axios'
import { current } from 'immer'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAsyncRetry } from 'react-use'

import { addRecordAtom } from '@/atoms/dataSource/action'
import { pageAtomFamily, pageBlocksAtom, pageNodesAtom, pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { applyDraftPayload } from '@/atoms/utils/applyDraftPayload'
import { updateWorkflowListAtom } from '@/atoms/workflow/action'
import { PageContainer } from '@/containers/Page/PageContainer'
import { useCurrentAppId, useCurrentEnvId, useDefaultPageList } from '@/hooks/useApplication'
import { getCustomRoutePageUrl, parseUrlParams } from '@/hooks/useSubscriptRouteForPageStack'
import * as srv from '@/services'
import { initWxSDK } from '@/utils/wechat'

import NoAccess from '../NoAccess'

const Page: React.FC = () => {
    const [errCode, setErrCode] = useState('')
    const location = useLocation()
    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const pageList = useDefaultPageList()
    const pageStackInfoByPath = useMemo(() => {
        const path = location.pathname.slice(1)
        const urlParams = path.match(PAGE_URL_REG)
        if (!urlParams) {
            return
        }
        // 找到最后一个展现形式为页面的stack，并返回pageId
        return urlParams
            .map((item, index) => {
                const isCustomRoute = index === 0 && CUSTOM_ROUTE_PAGE_URL_REG.test(item)
                const data = isCustomRoute ? getCustomRoutePageUrl(item.split('/')[0], pageList) : item
                return parseUrlParams(data, appId)
            })
            .findLast(item => {
                return item.stackDisplayType === RecordOpenType.page
            })
    }, [appId, location.pathname, pageList])

    const pageStack = useAtomData(pageStackAtomFamily({ pageId: pageStackInfoByPath?.pageId ?? "" }))
    const hasCurrentPageStack = !!pageStack

    const lastPageTypeStackId = useAtomData(
        pageStackAtom,
        useCallback(s => s.findLast(item => item.stackDisplayType === RecordOpenType.page)?.stackId, [])
    )

    const pageId = pageStackInfoByPath?.pageId ?? ''
    const a = pageStackInfoByPath?.appId
    const d = pageStackInfoByPath?.dsId
    const r = pageStackInfoByPath?.recordId

    const { run: addRecord } = useAtomAction(addRecordAtom)
    const { run: setPage } = useAtomAction(pageAtomFamily(pageId))
    const { run: setPageNodes } = useAtomAction(pageNodesAtom)
    const { run: setPageBlocks } = useAtomAction(pageBlocksAtom)
    const { run: updateWorkflowList } = useAtomAction(updateWorkflowListAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)

    // useEffect(() => {
    //     return () => {
    //         console.log('clear stack')
    //         setPageStack([])
    //     }
    // }, [setPageStack])

    const pageRes = useAsyncRetry(async () => {
        if (!pageId || !hasCurrentPageStack) {
            return
        }
        const res = await srv.getPageContent(pageId)
        if (!res.success) {
            setErrCode(res.code)
            return
        }
        setErrCode('')
        const { blocks, nodes, code, success, ...page } = res
        setPage(draft => applyDraftPayload(draft, page))
        setPageNodes(draft => void (draft[pageId] = nodes))
        setPageBlocks(draft => void (draft[pageId] = blocks))
        setPageStack(draft => {
            console.log(current(draft), pageId)
            const stack = draft.findLast(item => item.pageId === pageId)
            if (stack) {
                initBlockRuntimeState(stack, blocks)
            }
        })
        return { ...page, id: pageId }
    }, [pageId, hasCurrentPageStack])

    const recordRes = useAsyncRetry(async () => {
        if (!pageRes?.value || pageRes.value?.id !== pageId) {
            return
        }
        if (a && d && r) {
            const { dataSource, record } = await srv.getRecord({ appId: a, envId, dsId: d, recordId: r, pageId })
            addRecord({ records: [record] })
            return { dataSource, record }
        }
        return null
    }, [a, d, r, pageId, pageRes.value])

    const refreshPageManually = useCallback(() => {
        pageRes.retry()
        recordRes.retry()
    }, [pageRes, recordRes])

    const isNotRecordData = useMemo(() => a && d && r && !recordRes.value, [a, d, r, recordRes.value])

    useEffect(() => {
        updateWorkflowList()
    }, [updateWorkflowList])

    useEffect(() => {
        initWxSDK()
    }, [pageId, r])

    if (pageRes.error) {
        if ((pageRes.error as AxiosError)?.response?.status === 401) {
            return null
        }
        return null
        // return <ErrorFallback error={pageRes.error} resetErrorBoundary={pageRes.retry} />
    }

    if (pageRes.loading) {
        return <Loading />
    }

    if (noAccessCodeList.includes(errCode as ErrorCode)) {
        return <NoAccess />
    }

    if (!pageRes.value || isNotRecordData || !lastPageTypeStackId) {
        return null
    }

    return (
        <PageContainer
            key={`${pageRes.value.id}-${r}`}
            pageId={pageRes.value.id}
            stackId={lastPageTypeStackId}
            refreshPageManually={refreshPageManually}
        />
    )
}

export default Page
