import type { ButtonItem, ButtonSizeType, RichTextContentProtocol } from '@lighthouse/core'
import { ActionItemPattern, ButtonSize } from '@lighthouse/core'
import React, { useMemo } from 'react'

import { btnStyleMap } from '../../constants'
import { useApplicationContext } from '../../contexts'
import { getBackgroundStyle, getBorderStyle, getTextColorStyle } from '../../utils'
import { applyFillPickerValue4CssColorValue, useFillPickerContext } from '../FillPicker'
import { TooltipText } from '../TooltipText'
import { btnTypeShowMap, buttonIconSizeMap, buttonTextSizeMap } from './constant'
import * as SC from './styles'
// import { generateText } from '../RichText'

// generateText()

type ActionButtonStyles = 'title' | 'icon' | 'container' | 'wrapper'
interface ActionButtonProps {
    config: ButtonItem
    radius?: string
    size?: ButtonSizeType
    loading?: boolean
    isSubmit?: boolean
    disabled?: boolean
    styles?: Partial<Record<ActionButtonStyles, React.CSSProperties>>
    onMouseOver?: React.MouseEventHandler<HTMLButtonElement>
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    onRenderTitle: (v: RichTextContentProtocol) => string
}

export const ActionButton = React.forwardRef<HTMLButtonElement, ActionButtonProps>(
    (
        {
            loading,
            isSubmit,
            config,
            styles,
            radius = '0',
            disabled = false,
            size = ButtonSize.sm,
            onMouseOver,
            onClick,
            onRenderTitle,
            ...rest
        },
        ref
    ) => {
        const {
            showType = 'icon-name',
            name,
            icon,
            events,
            pattern = ActionItemPattern.primary,
            iconPosition = 'left',
            backgroundColor,
            iconColor,
            color,
            borderColor,
            fillWay = 'auto'
        } = config
        const triggerEvent = events?.triggerEvent
        const { appId } = useApplicationContext()
        const { icon: showIcon, name: showName } = useMemo(() => btnTypeShowMap.get(showType) ?? { icon: true, name: true }, [showType])

        const title = useMemo(() => onRenderTitle(name), [name, onRenderTitle])
        const { palettes } = useFillPickerContext()

        const isCustom = pattern === ActionItemPattern.custom
        const patternStyle = btnStyleMap.get(pattern) ?? {}

        const style: React.CSSProperties = useMemo(() => {
            const flex = fillWay === 'auto' ? undefined : { flex: 1 }
            return {
                ...styles?.container,
                ...flex,
                ...(isCustom
                    ? getBackgroundStyle(appId, backgroundColor, undefined, palettes)
                    : { backgroundColor: patternStyle.backgroundColor }),
                ...(isCustom
                    ? getBorderStyle(borderColor && { ...borderColor, all: 1 }, palettes)
                    : { border: patternStyle.borderColor ? `1px solid ${patternStyle.borderColor}` : 'none' })
            }
        }, [
            appId,
            backgroundColor,
            borderColor,
            fillWay,
            isCustom,
            palettes,
            patternStyle.backgroundColor,
            patternStyle.borderColor,
            styles?.container
        ])

        const iconColorText = isCustom ? applyFillPickerValue4CssColorValue(iconColor?.color, palettes) : patternStyle.color

        return (
            <SC.Container
                type={isSubmit ? 'submit' : 'button'}
                size={size}
                radius={`${radius}px`}
                style={style}
                event={triggerEvent}
                onClick={onClick}
                onMouseOver={onMouseOver}
                disabled={disabled}
                ref={ref}
                {...rest}
            >
                <SC.Wrapper style={styles?.wrapper}>
                    {loading ? (
                        <SC.Icon
                            style={styles?.icon}
                            order={0}
                            type="Spinner"
                            loading={loading}
                            size={buttonIconSizeMap.get(size)}
                            color={iconColorText}
                        />
                    ) : (
                        showIcon &&
                        icon && (
                            <SC.Icon
                                style={styles?.icon}
                                order={iconPosition === 'left' ? 0 : 1}
                                type={icon}
                                size={buttonIconSizeMap.get(size)}
                                color={iconColorText}
                            />
                        )
                    )}

                    {showName && (
                        <TooltipText
                            title={title}
                            render={ref => (
                                <SC.Text
                                    ref={ref}
                                    size={buttonTextSizeMap.get(size)}
                                    style={{
                                        ...styles?.title,
                                        ...(isCustom ? getTextColorStyle(color, palettes) : { color: patternStyle.color })
                                    }}
                                >
                                    {title}
                                </SC.Text>
                            )}
                        />
                    )}
                </SC.Wrapper>
            </SC.Container>
        )
    }
)
