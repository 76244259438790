import { pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Title = styled.div`
    color: var(--color-app-main);
    text-align: center;

    /* 24/SC-Medium */
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    /* margin-top: 28px; */
    margin-bottom: 24px;
`

export const Describe = styled.div`
    color: var(--color-gray-500);
    font-size: 14px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 24px;
`

export const TagList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`

export const Tag = styled.div`
    position: relative;
    display: flex;
    width: calc(50% - 6px);
    padding: 8px 0;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 100px;
    border: 1px solid var(--color-gray-200);
    background: #fff;
    ${pointer}

    &.active {
        color: var(--color-app-main);
        background-color: var(--color-app-main-tint);
        border-color: var(--color-app-main);

        iconpark-icon {
            position: absolute;
            top: -6px;
            right: -6px;
            background-color: var(--color-white);
            border-radius: 100%;
        }
    }
`
