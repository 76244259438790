import type { Field, FieldCellValue, HighLightConditions } from '@lighthouse/core'
import fastEqual from 'fast-deep-equal'
import React, { memo } from 'react'
import styled from 'styled-components'

import FieldValuePreviewer from '../FieldValuePreviewer'

export interface TableCellProps {
    field: Field
    data?: FieldCellValue
    width?: number
    style?: React.CSSProperties
    isBold?: boolean
    styleConditions?: HighLightConditions
}

const SCxTableCell = styled.div<{ width?: number }>`
    position: relative;
    width: ${({ width }) => (width ? `${width}px` : '160px')};
    min-height: 36px;
    flex-shrink: 0;
    font-size: var(--font-size-normal);

    &.active {
        border: 1px solid var(--color-main);
    }
`

const TableValue: React.FC<TableCellProps> = ({ field, data, width, style, styleConditions, isBold }) => {
    return (
        <SCxTableCell width={width} style={style}>
            <FieldValuePreviewer
                field={field}
                data={data}
                previewHost="selectTable"
                isWrap={false}
                width={width}
                styleConditions={styleConditions}
                isBold={isBold}
            />
        </SCxTableCell>
    )
}

export default memo(TableValue, fastEqual)
