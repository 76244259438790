import { mergeDeepRight } from 'rambda'
import type { PropsWithChildren } from 'react'
import React, { createContext, useContext, useMemo } from 'react'

interface ComponentCommonConfig {
    disabled?: boolean
}

export interface InputComponentConfig extends ComponentCommonConfig {
    placeholder?: string
}

export interface ComponentConfig {
    checkbox: ComponentCommonConfig
    segmentedControl: ComponentCommonConfig
    button: ComponentCommonConfig
    input: InputComponentConfig
    select: InputComponentConfig
    radio: ComponentCommonConfig
    switch: ComponentCommonConfig
    slider: ComponentCommonConfig
}

const DEFAULT_COMPONENT_CONFIG: ComponentConfig = {
    checkbox: {},
    segmentedControl: {},
    button: {},
    input: {
        placeholder: '请输入'
    },
    select: {
        placeholder: '请选择'
    },
    radio: {},
    switch: {},
    slider: {}
}

export interface ByecodeUIConfigContextValue {
    component: ComponentConfig
    locale: string
}

const ByecodeUIConfigContext = createContext<ByecodeUIConfigContextValue>({ component: DEFAULT_COMPONENT_CONFIG, locale: 'zh-CN' })

export function useByecodeUIConfig() {
    return useContext(ByecodeUIConfigContext)
}

export interface ByecodeUIConfigProviderProps extends PropsWithChildren {
    component?: ComponentConfig
    locale?: string
}

export function ByecodeUIConfigProvider({ children, locale = 'zh-CN', component }: ByecodeUIConfigProviderProps) {
    const value = useMemo(
        () => ({
            component: component ? mergeDeepRight<ComponentConfig>(DEFAULT_COMPONENT_CONFIG, component) : DEFAULT_COMPONENT_CONFIG,
            locale
        }),
        [component, locale]
    )

    return <ByecodeUIConfigContext.Provider value={value}>{children}</ByecodeUIConfigContext.Provider>
}
