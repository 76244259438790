import { IconFont } from '@byecode/ui/components/IconFont'
import QRCode from 'qrcode.react'
import React from 'react'
import styled from 'styled-components'

interface QrCodeProps {
    url: string
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    background-color: var(--color-gray-50);
`

const SCxText = styled.div<{ color?: string }>`
    color: ${({ color }) => color};
    font-size: var(--font-size-base);
    line-height: 24px;
`

export const QrCode: React.FunctionComponent<QrCodeProps> = ({ url }) => {
    return (
        <SCxContainer>
            <div style={{ marginBottom: 12 }}>
                <IconFont size={60} color="var(--color-green-500)" type="WechatPay" />
                <SCxText color="var(--color-green-500)">微信支付</SCxText>
            </div>
            <QRCode size={150} value={url} renderAs="canvas" level="H" />
            <SCxText color="var(--color-black)">请使用微信扫一扫进行支付</SCxText>
        </SCxContainer>
    )
}
