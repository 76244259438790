import type { BoolValue, CheckboxField, FieldADTValue, HighLightConditions } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import type { ChangeEvent } from 'react'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { FieldCheckbox } from '../FieldCheckbox'
import { CellHighlighter } from '../Highlighter'
import { TooltipList, useWidth } from '../TooltipList'

interface CheckboxValuePreviewerProps {
    field: CheckboxField
    data?: BoolValue | BoolValue[]
    isWrap?: boolean
    width?: number
    disabled?: boolean
    styleConditions?: HighLightConditions
    onCellChange?: (fieldValue: FieldADTValue) => Promise<boolean>
}

interface CheckboxListPreviewerProps {
    data: BoolValue[]
    width: number
    isWrap: boolean
    disabled?: boolean
}
interface CheckboxItemPreviewerProps {
    id: string
    data?: BoolValue
    disabled?: boolean
    readonly?: boolean
    defaultBgcolor?: string
    onChange?: (ev: ChangeEvent<HTMLInputElement>) => void
}

const SCxCheckboxListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean }>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    overflow: hidden;
`

const SCxItem = styled.div`
    display: flex;
    align-items: center;
    padding-right: 6px;
`

export const CheckboxItemPreviewer: React.FC<CheckboxItemPreviewerProps> = ({
    id,
    data,
    disabled,
    defaultBgcolor = 'var(--color-white)',
    readonly,
    onChange
}) => {
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)

    return (
        <SCxItem ref={widthRef}>
            <FieldCheckbox disabled={disabled} readonly={readonly} defaultBgcolor={defaultBgcolor} checked={!!data} onChange={onChange} />
        </SCxItem>
    )
}

export const CheckboxListPreviewer: React.FC<CheckboxListPreviewerProps> = ({ data, width, isWrap, disabled }) => {
    return (
        <TooltipList
            data={data.map((_, index) => ({ label: String(index), value: String(index) }))}
            width={width}
            key={data.join('-')}
            disabled={isWrap}
            render={visibleNum => {
                const renderList = data.slice(0, visibleNum)
                return (
                    <SCxCheckboxListWrapper isPadding={visibleNum < data.length} isWrap={isWrap}>
                        {renderList.map((item, index) => (
                            <CheckboxItemPreviewer id={String(index)} key={index} data={item} readonly={disabled} />
                        ))}
                    </SCxCheckboxListWrapper>
                )
            }}
        />
    )
}

export const CheckboxValuePreviewer: React.FC<CheckboxValuePreviewerProps> = ({
    field,
    data,
    width = 160,
    isWrap = true,
    disabled,
    styleConditions,
    onCellChange
}) => {
    const { id, type } = field

    const handleChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
            onCellChange?.({
                ...field,
                value: ev.target.checked
            } as FieldADTValue)
        },
        [field, onCellChange]
    )

    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (Array.isArray(data)) {
            return <CheckboxListPreviewer data={data} width={width} isWrap={isWrap} disabled={disabled} />
        }

        // if (typeof data !== 'boolean') {
        //     return null
        // }
        const id = nanoid()
        return <CheckboxItemPreviewer id={id} data={data} readonly={disabled} onChange={handleChange} />
    }, [data, disabled, handleChange, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={id} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
