export const locale = {
    ok: '確定',
    cancel: '取消',
    now: '此刻',
    chooseDate: '選擇日期',
    chooseTime: '選擇時間',
    complete: '完成',
    date: '日期',
    time: '時間',
    clear: '清空'
}
