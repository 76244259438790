import type { ApplicationSettingAuthentication, ApplicationSettingAuthenticationField, DataSourceAbstract } from '@lighthouse/core'
import { ApplicationSettingPerfectMode } from '@lighthouse/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import type { ApplicationPreviewEnum, AppRole } from '../../../types'
import { getEmptyCellValue } from '../../../utils'
import type { CascadeParam, RelativeFieldParam } from '../../FieldInput'
import { getFields } from '../helps'
import { PerfectInfo } from '../PerfectInfo'
import * as CM from '../styles'
import type { AccountPerfectForm } from '../types'

interface PerfectProps extends RelativeFieldParam, CascadeParam {
    authentication: ApplicationSettingAuthentication
    dataSource?: DataSourceAbstract
    previewType?: ApplicationPreviewEnum
    roles?: AppRole[]
    onPerfect?: (params: AccountPerfectForm) => void
}

export const Perfect: React.FunctionComponent<PerfectProps> = ({
    authentication,
    dataSource,
    roles,
    previewType,
    relativeDataSource,
    dataSourceList,
    onPerfect,
    onFetchCascadeOptions,
    onFetchDataSource,
    onLoadMoreData
}) => {
    const { perfect } = authentication
    const { commonSetting, mode } = perfect

    const defaultValues: AccountPerfectForm = useMemo(
        () => ({
            roleId: '',
            fields: getFields(commonSetting.fields)
        }),
        [commonSetting.fields]
    )

    const methods = useForm<AccountPerfectForm>({ mode: 'onSubmit', defaultValues })

    const { handleSubmit, setValue } = methods

    const handleSubmitFrom = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            onPerfect && handleSubmit(onPerfect)()
        },
        [handleSubmit, onPerfect]
    )

    useEffect(() => {
        setValue('fields', getFields(mode === ApplicationSettingPerfectMode.COMMON ? commonSetting.fields : []))
    }, [commonSetting.fields, mode, setValue])

    return (
        <CM.FormContainer onSubmit={handleSubmitFrom}>
            <FormProvider {...methods}>
                <PerfectInfo
                    authentication={authentication}
                    dataSource={dataSource}
                    roles={roles}
                    previewType={previewType}
                    mode="login"
                    relativeDataSource={relativeDataSource}
                    dataSourceList={dataSourceList}
                    onFetchCascadeOptions={onFetchCascadeOptions}
                    onFetchDataSource={onFetchDataSource}
                    onLoadMoreData={onLoadMoreData}
                />
            </FormProvider>
        </CM.FormContainer>
    )
}
